<template>
  <Scene>
    <Background skyColor="0.949019608 0.941176471 0.901960784"></Background>
    <Viewpoint
      fieldOfView="0.3"
      position="1.75975065471184033e+05 -1.06781054673455830e+05 1.10033421751270362e+05"
      description="Default View"
      orientation="8.62703928496432515e-01 2.49954488097040572e-01 4.39618795818569164e-01 -5.11989278265888892e+00"
      centerOfRotation="6.88103916885579383e+04 6.28641806397626278e+04 -9.88283376435462560e+03"
    ></Viewpoint>
    <NavigationInfo
      type='"EXAMINE" "FLY" "ANY"'
      speed="4.00000000000000000e+00"
      headlight="true"
    ></NavigationInfo>
    <DirectionalLight
      ambientIntensity="1.00000000000000000e+00"
      intensity="0.00000000000000000e+00"
      color="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
    ></DirectionalLight>
    <Transform
      DEF="ROOT"
      translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
    >
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+01"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="8.23529411764705871e-02 3.41176470588235303e-01 1.41176470588235292e-01"
              specularColor="4.11764705882352970e-03 1.70588235294117652e-02 7.05882352941176495e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            21 20 24 -1 
            30 8 21 -1 
            8 20 21 -1 
            11 29 23 -1 
            12 11 23 -1 
            11 12 29 -1 
            17 28 24 -1 
            17 16 28 -1 
            16 17 3 -1 
            28 9 23 -1 
            9 12 23 -1 
            26 27 25 -1 
            12 1 29 -1 
            22 30 25 -1 
            27 22 25 -1 
            18 17 24 -1 
            14 9 28 -1 
            0 26 29 -1 
            1 0 29 -1 
            10 27 26 -1 
            0 10 26 -1 
            10 0 1 -1 
            22 10 3 -1 
            10 22 27 -1 
            8 7 20 -1 
            7 8 30 -1 
            22 7 30 -1 
            20 19 24 -1 
            19 18 24 -1 
            5 19 20 -1 
            16 15 28 -1 
            15 14 28 -1 
            15 16 3 -1 
            14 15 3 -1 
            2 14 3 -1 
            10 2 3 -1 
            2 10 1 -1 
            6 5 20 -1 
            7 6 20 -1 
            5 6 22 -1 
            6 7 22 -1 
            4 19 5 -1 
            19 4 18 -1 
            4 22 3 -1 
            4 5 22 -1 
            17 4 3 -1 
            18 4 17 -1 
            13 1 12 -1 
            13 2 1 -1 
            9 13 12 -1 
            14 13 9 -1 
            2 13 14 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0000"
              point="
              4.92209000000000015e+04 9.12128000000000029e+04 -1.49259999999999991e+03,
              5.25736999999999971e+04 8.18655000000000000e+04 -1.73090000000000009e+03,
              6.06000999999999985e+04 7.42455000000000000e+04 -2.10150000000000000e+03,
              6.95410000000000000e+04 6.40855000000000000e+04 -3.20000000000000000e+03,
              7.69578000000000029e+04 5.65670999999999985e+04 -3.04119999999999982e+03,
              8.68130000000000000e+04 5.21983000000000029e+04 -2.33969999999999982e+03,
              8.81338000000000029e+04 5.16903000000000029e+04 -1.98240000000000009e+03,
              9.16898000000000029e+04 5.04710999999999985e+04 -1.88970000000000005e+03,
              9.51441999999999971e+04 4.92519000000000015e+04 -1.79709999999999991e+03,
              4.73108000000000029e+04 6.16876999999999971e+04 -2.37009999999999991e+03,
              7.08413999999999942e+04 8.27798999999999942e+04 -1.95820000000000005e+03,
              3.47530999999999985e+04 6.64426000000000058e+04 -1.97500000000000000e+03,
              4.41409000000000015e+04 6.51015000000000000e+04 -2.30450000000000000e+03,
              4.93835000000000000e+04 6.38823000000000029e+04 -2.40680000000000018e+03,
              5.62110000000000000e+04 6.02246999999999971e+04 -2.48640000000000009e+03,
              6.20631999999999971e+04 5.58355999999999985e+04 -3.35000000000000000e+03,
              6.30385000000000000e+04 5.49820999999999985e+04 -3.44090000000000009e+03,
              6.75496000000000058e+04 5.07149000000000015e+04 -3.23640000000000009e+03,
              7.27921999999999971e+04 4.76669000000000015e+04 -2.69090000000000009e+03,
              7.42551999999999971e+04 4.68135000000000000e+04 -2.52050000000000000e+03,
              7.97416000000000058e+04 4.24243000000000029e+04 -2.21359999999999991e+03,
              9.31528000000000029e+04 2.98665999999999985e+04 -2.27050000000000000e+03,
              9.05518999999999942e+04 7.11873999999999942e+04 -1.95000000000000000e+03,
              3.12174000000000015e+04 5.02170999999999985e+04 -2.60000000000000000e+03,
              6.64930000000000000e+04 2.64832999999999993e+04 -2.60000000000000000e+03,
              1.06645000000000000e+05 8.25666000000000058e+04 -1.50000000000000000e+03,
              7.65716999999999971e+04 1.04837000000000000e+05 -1.50000000000000000e+03,
              8.77883999999999942e+04 9.36206999999999971e+04 -2.10000000000000000e+03,
              4.21089000000000015e+04 3.29856999999999971e+04 -3.40000000000000000e+03,
              3.64193000000000029e+04 8.82561999999999971e+04 -1.45000000000000000e+03,
              1.05345000000000000e+05 6.12711999999999971e+04 -1.40000000000000000e+03,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+01"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000000e+00 8.43137254901960786e-01 0.00000000000000000e+00"
              specularColor="5.00000000000000028e-02 4.21568627450980421e-02 0.00000000000000000e+00"
              shininess="3.90625000000000000e-02"
              transparency="2.00000002980232239e-01"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="true"
            normalPerVertex="false"
            coordIndex="
            96 48 19 -1 
            96 221 48 -1 
            961 567 0 -1 
            8472 8491 8501 -1 
            8491 8472 8443 -1 
            8366 8491 8443 -1 
            8366 8241 8289 -1 
            317 192 240 -1 
            9 38 19 -1 
            17 8 7 -1 
            2710 3277 2316 -1 
            567 173 0 -1 
            346 173 567 -1 
            29 10 0 -1 
            10 29 58 -1 
            740 865 817 -1 
            740 567 961 -1 
            8380 8255 8428 -1 
            8380 8332 8255 -1 
            8457 8380 8428 -1 
            8332 8380 8457 -1 
            8241 8164 8289 -1 
            5766 6727 6333 -1 
            4459 4632 4238 -1 
            4286 4459 4238 -1 
            8433 8462 8385 -1 
            8462 8337 8385 -1 
            8491 8462 8481 -1 
            8462 8433 8481 -1 
            307 432 384 -1 
            144 96 19 -1 
            96 144 221 -1 
            317 442 394 -1 
            317 269 192 -1 
            144 269 221 -1 
            269 144 192 -1 
            221 269 394 -1 
            269 317 394 -1 
            192 115 240 -1 
            18 9 8 -1 
            6 5 15 -1 
            788 1355 394 -1 
            1355 1749 2316 -1 
            3277 3671 4238 -1 
            1 10 20 -1 
            10 1 0 -1 
            3 2 12 -1 
            173 125 0 -1 
            10 39 20 -1 
            87 39 10 -1 
            692 740 817 -1 
            740 692 567 -1 
            1038 913 961 -1 
            913 740 961 -1 
            740 913 865 -1 
            471 519 596 -1 
            519 346 567 -1 
            519 471 346 -1 
            548 471 596 -1 
            375 500 452 -1 
            3714 3762 3839 -1 
            6727 6506 6333 -1 
            8409 8457 8486 -1 
            8361 8409 8486 -1 
            8409 8332 8457 -1 
            8332 8409 8284 -1 
            8409 8361 8284 -1 
            8467 8486 8496 -1 
            8419 8467 8496 -1 
            8342 8467 8419 -1 
            8337 8260 8385 -1 
            4420 4468 4545 -1 
            3882 3757 3805 -1 
            5026 5199 4632 -1 
            4632 5199 4238 -1 
            5420 5199 5247 -1 
            4853 5026 4632 -1 
            5026 4853 4901 -1 
            4603 4651 4728 -1 
            5314 5266 5189 -1 
            5266 5314 5391 -1 
            5199 5122 5247 -1 
            4507 4555 4632 -1 
            4459 4507 4632 -1 
            4507 4459 4382 -1 
            4334 4459 4286 -1 
            4459 4334 4382 -1 
            4334 4257 4382 -1 
            6948 7121 6554 -1 
            5295 5420 5247 -1 
            8472 8395 8443 -1 
            8347 8395 8472 -1 
            8453 8376 8501 -1 
            8366 8414 8491 -1 
            8414 8462 8491 -1 
            8462 8414 8337 -1 
            8414 8366 8289 -1 
            8337 8414 8289 -1 
            8482 8453 8501 -1 
            8136 8261 8213 -1 
            8502 8492 8501 -1 
            8492 8482 8501 -1 
            365 442 317 -1 
            365 317 240 -1 
            38 67 19 -1 
            115 67 38 -1 
            67 144 19 -1 
            144 67 192 -1 
            67 115 192 -1 
            18 28 9 -1 
            17 27 8 -1 
            6 16 7 -1 
            1112 1035 1160 -1 
            776 699 824 -1 
            25 6 15 -1 
            1554 1477 1602 -1 
            113 161 238 -1 
            777 854 729 -1 
            442 615 394 -1 
            615 788 394 -1 
            2306 2181 2229 -1 
            2537 2710 2316 -1 
            2749 2797 2874 -1 
            2 31 12 -1 
            22 3 12 -1 
            183 308 260 -1 
            308 183 231 -1 
            212 164 87 -1 
            39 164 116 -1 
            164 39 87 -1 
            164 241 116 -1 
            39 68 20 -1 
            68 39 116 -1 
            894 769 817 -1 
            769 692 817 -1 
            644 721 596 -1 
            692 644 567 -1 
            769 644 692 -1 
            644 769 721 -1 
            644 519 567 -1 
            519 644 596 -1 
            298 173 346 -1 
            471 423 346 -1 
            423 298 346 -1 
            298 423 375 -1 
            375 423 500 -1 
            423 548 500 -1 
            548 423 471 -1 
            404 327 452 -1 
            327 375 452 -1 
            3762 3887 3839 -1 
            6410 6285 6333 -1 
            8294 8342 8419 -1 
            8438 8361 8486 -1 
            8467 8438 8486 -1 
            7162 7239 7114 -1 
            6893 7018 6970 -1 
            6970 7018 7095 -1 
            8452 8500 8481 -1 
            8490 8500 8471 -1 
            8500 8491 8481 -1 
            8491 8500 8501 -1 
            8308 8433 8385 -1 
            8260 8308 8385 -1 
            2883 3844 3450 -1 
            3844 3623 3450 -1 
            4536 4613 4488 -1 
            4584 4536 4411 -1 
            4536 4488 4411 -1 
            5151 4978 5372 -1 
            6585 6537 6460 -1 
            8488 8497 8498 -1 
            6555 6632 6507 -1 
            6632 6555 6680 -1 
            6758 6835 6710 -1 
            7229 7152 7277 -1 
            7162 7085 7210 -1 
            7056 6931 6979 -1 
            6988 6911 7036 -1 
            7104 7056 6979 -1 
            7229 7104 7152 -1 
            4622 4497 4545 -1 
            4497 4420 4545 -1 
            4218 4343 4295 -1 
            4343 4420 4295 -1 
            4468 4343 4391 -1 
            4420 4343 4468 -1 
            4170 4218 4295 -1 
            3757 3680 3805 -1 
            4680 4853 4632 -1 
            4853 4680 4728 -1 
            4680 4603 4728 -1 
            4555 4680 4632 -1 
            4603 4680 4555 -1 
            4776 4853 4728 -1 
            4824 4776 4699 -1 
            4776 4824 4901 -1 
            4853 4776 4901 -1 
            4776 4651 4699 -1 
            4651 4776 4728 -1 
            4507 4430 4555 -1 
            4430 4507 4382 -1 
            4603 4526 4651 -1 
            4526 4401 4449 -1 
            5074 5199 5026 -1 
            5074 5122 5199 -1 
            4401 4324 4449 -1 
            4257 4305 4382 -1 
            4430 4305 4353 -1 
            4305 4430 4382 -1 
            4305 4228 4353 -1 
            4161 4286 4238 -1 
            4180 4257 4132 -1 
            4055 4180 4132 -1 
            4305 4180 4228 -1 
            4180 4305 4257 -1 
            4343 4266 4391 -1 
            4266 4343 4218 -1 
            8495 8485 8504 -1 
            8484 8494 8465 -1 
            6775 6948 6554 -1 
            6948 6996 7121 -1 
            6996 7044 7121 -1 
            6996 6948 6871 -1 
            7515 8082 7121 -1 
            5343 5266 5391 -1 
            5295 5343 5420 -1 
            5343 5391 5468 -1 
            5420 5343 5468 -1 
            5593 5199 5420 -1 
            5593 5420 5468 -1 
            8424 8347 8472 -1 
            8424 8472 8501 -1 
            8376 8424 8501 -1 
            8328 8376 8453 -1 
            8203 8328 8280 -1 
            8366 8318 8241 -1 
            8318 8366 8443 -1 
            8395 8318 8443 -1 
            8126 8251 8203 -1 
            8251 8328 8203 -1 
            8328 8251 8376 -1 
            8184 8261 8136 -1 
            8357 8482 8434 -1 
            8482 8463 8434 -1 
            8492 8463 8482 -1 
            307 355 432 -1 
            288 365 240 -1 
            259 307 384 -1 
            115 163 240 -1 
            163 288 240 -1 
            288 163 211 -1 
            163 115 38 -1 
            134 259 211 -1 
            288 413 365 -1 
            47 18 95 -1 
            47 28 18 -1 
            316 441 393 -1 
            200 325 277 -1 
            46 27 17 -1 
            94 46 17 -1 
            161 286 238 -1 
            286 363 238 -1 
            14 5 4 -1 
            910 862 785 -1 
            862 737 785 -1 
            1112 987 1035 -1 
            987 910 1035 -1 
            910 987 862 -1 
            1035 1083 1160 -1 
            1256 1381 1333 -1 
            776 651 699 -1 
            997 1045 1122 -1 
            1074 997 1122 -1 
            25 73 150 -1 
            102 25 150 -1 
            1429 1477 1554 -1 
            113 36 161 -1 
            65 36 113 -1 
            36 17 7 -1 
            36 65 17 -1 
            228 305 180 -1 
            257 132 180 -1 
            305 257 180 -1 
            257 305 382 -1 
            431 556 508 -1 
            2187 2110 2235 -1 
            2459 2536 2411 -1 
            538 413 461 -1 
            509 461 384 -1 
            432 509 384 -1 
            595 672 547 -1 
            2181 2104 2229 -1 
            1797 1970 1749 -1 
            1749 2143 2316 -1 
            1970 2143 1749 -1 
            2354 2306 2229 -1 
            2210 2287 2162 -1 
            1768 1816 1893 -1 
            1768 1720 1643 -1 
            1816 1941 1893 -1 
            3498 3671 3277 -1 
            2758 2931 2710 -1 
            2364 2537 2316 -1 
            2210 2335 2287 -1 
            2749 2672 2797 -1 
            2627 2704 2579 -1 
            2704 2656 2579 -1 
            79 31 2 -1 
            310 435 387 -1 
            337 212 260 -1 
            500 577 452 -1 
            29 106 58 -1 
            154 106 29 -1 
            106 183 58 -1 
            106 154 231 -1 
            183 106 231 -1 
            77 154 29 -1 
            77 29 0 -1 
            125 77 0 -1 
            77 125 202 -1 
            154 77 202 -1 
            183 135 58 -1 
            135 10 58 -1 
            135 87 10 -1 
            135 212 87 -1 
            212 135 260 -1 
            135 183 260 -1 
            356 308 231 -1 
            846 769 894 -1 
            769 846 721 -1 
            1528 1922 1701 -1 
            1922 2883 2489 -1 
            1922 1528 961 -1 
            913 990 865 -1 
            990 913 1038 -1 
            250 298 375 -1 
            327 250 375 -1 
            250 327 202 -1 
            298 250 173 -1 
            250 125 173 -1 
            125 250 202 -1 
            4163 4240 4115 -1 
            3810 3887 3762 -1 
            3810 3935 3887 -1 
            4367 4290 4415 -1 
            4683 4731 4808 -1 
            5404 5529 5481 -1 
            5481 5529 5606 -1 
            5087 4962 5010 -1 
            4904 5029 4981 -1 
            5231 5356 5308 -1 
            5356 5404 5481 -1 
            5183 5231 5308 -1 
            5356 5433 5308 -1 
            5433 5356 5481 -1 
            6099 6051 5974 -1 
            6176 6051 6099 -1 
            8116 8039 8164 -1 
            8116 8164 8241 -1 
            7914 7789 7837 -1 
            7953 8078 8030 -1 
            8078 8126 8203 -1 
            7694 7646 7569 -1 
            7425 7473 7550 -1 
            7473 7598 7550 -1 
            7790 7867 7742 -1 
            7665 7790 7742 -1 
            7415 7540 7492 -1 
            7444 7492 7569 -1 
            6410 6458 6535 -1 
            6506 6458 6333 -1 
            6458 6410 6333 -1 
            6679 6506 6727 -1 
            6679 6631 6506 -1 
            6679 6756 6631 -1 
            6631 6583 6506 -1 
            6458 6583 6535 -1 
            6583 6458 6506 -1 
            6583 6660 6535 -1 
            5939 5766 6333 -1 
            6487 6410 6535 -1 
            6314 6391 6266 -1 
            6391 6343 6266 -1 
            6468 6343 6391 -1 
            5584 5661 5536 -1 
            5767 5892 5844 -1 
            8294 8217 8342 -1 
            8217 8265 8342 -1 
            8342 8390 8467 -1 
            8390 8438 8467 -1 
            8265 8390 8342 -1 
            8236 8159 8284 -1 
            8361 8236 8284 -1 
            8015 8140 8092 -1 
            8140 8217 8092 -1 
            8140 8188 8265 -1 
            8217 8140 8265 -1 
            8207 8034 8255 -1 
            8034 8207 8159 -1 
            8159 8207 8284 -1 
            8332 8207 8255 -1 
            8207 8332 8284 -1 
            7861 7688 8255 -1 
            8034 7861 8255 -1 
            7938 8063 8015 -1 
            8140 8063 8188 -1 
            8063 8140 8015 -1 
            6555 6603 6680 -1 
            6478 6603 6555 -1 
            6228 6276 6353 -1 
            8499 8490 8480 -1 
            8499 8500 8490 -1 
            8499 8489 8498 -1 
            7018 7143 7095 -1 
            7143 7220 7095 -1 
            7114 7066 6989 -1 
            7066 7143 7018 -1 
            6941 7018 6893 -1 
            6941 6864 6989 -1 
            7066 6941 6989 -1 
            6941 7066 7018 -1 
            6365 6490 6442 -1 
            5702 5654 5577 -1 
            5529 5654 5606 -1 
            5654 5529 5577 -1 
            8500 8423 8471 -1 
            8490 8461 8480 -1 
            8413 8461 8490 -1 
            8442 8490 8471 -1 
            8394 8442 8471 -1 
            8356 8308 8231 -1 
            8433 8356 8481 -1 
            8308 8356 8433 -1 
            8497 8487 8496 -1 
            5113 5036 5161 -1 
            4815 4940 4892 -1 
            4815 4690 4738 -1 
            4690 4613 4738 -1 
            4719 4671 4594 -1 
            4642 4719 4594 -1 
            4940 5017 4892 -1 
            5017 4940 5065 -1 
            4719 4796 4671 -1 
            4575 4700 4652 -1 
            4191 4316 4268 -1 
            5766 5545 5372 -1 
            5430 5555 5507 -1 
            4306 4383 4258 -1 
            4383 4306 4431 -1 
            4392 4517 4469 -1 
            4517 4642 4594 -1 
            4469 4517 4594 -1 
            4546 4469 4594 -1 
            4671 4546 4594 -1 
            5353 5478 5430 -1 
            5478 5555 5430 -1 
            4930 5007 4882 -1 
            5151 5103 4978 -1 
            5007 4959 4882 -1 
            4834 4959 4911 -1 
            4959 4834 4882 -1 
            4959 5036 4911 -1 
            5036 5084 5161 -1 
            4959 5084 5036 -1 
            5084 4959 5007 -1 
            6643 6595 6518 -1 
            6335 6383 6460 -1 
            6662 6585 6710 -1 
            6662 6614 6537 -1 
            6585 6662 6537 -1 
            6441 6566 6518 -1 
            6566 6643 6518 -1 
            6566 6614 6691 -1 
            6643 6566 6691 -1 
            6537 6412 6460 -1 
            6412 6335 6460 -1 
            8469 8488 8498 -1 
            5892 5969 5844 -1 
            5988 6113 6065 -1 
            6305 6228 6353 -1 
            6257 6305 6382 -1 
            6632 6584 6507 -1 
            6584 6632 6709 -1 
            6584 6459 6507 -1 
            6459 6584 6536 -1 
            6459 6382 6507 -1 
            6450 6373 6498 -1 
            6575 6450 6498 -1 
            6325 6450 6402 -1 
            6450 6325 6373 -1 
            6623 6575 6498 -1 
            6786 6709 6834 -1 
            6911 6786 6834 -1 
            6585 6633 6710 -1 
            6633 6758 6710 -1 
            6614 6739 6691 -1 
            6662 6739 6614 -1 
            6864 6912 6989 -1 
            7037 7162 7114 -1 
            7037 7085 7162 -1 
            7037 7114 6989 -1 
            6912 7037 6989 -1 
            7699 7747 7824 -1 
            7688 7294 6727 -1 
            7294 6900 6727 -1 
            6844 6796 6719 -1 
            6796 6844 6921 -1 
            7584 7459 7507 -1 
            7171 7248 7123 -1 
            7248 7200 7123 -1 
            7152 7200 7277 -1 
            4516 4468 4391 -1 
            3459 3507 3584 -1 
            4170 4093 4218 -1 
            4064 4112 4189 -1 
            4112 4064 3987 -1 
            4131 4006 4054 -1 
            4006 4131 4083 -1 
            3680 3728 3805 -1 
            3632 3680 3757 -1 
            3680 3632 3555 -1 
            3507 3632 3584 -1 
            3632 3507 3555 -1 
            3901 3853 3776 -1 
            3728 3853 3805 -1 
            3853 3728 3776 -1 
            4526 4574 4651 -1 
            4574 4622 4699 -1 
            4651 4574 4699 -1 
            4574 4497 4622 -1 
            4497 4574 4449 -1 
            4574 4526 4449 -1 
            4478 4603 4555 -1 
            4478 4526 4603 -1 
            4430 4478 4555 -1 
            4526 4478 4401 -1 
            4401 4478 4353 -1 
            4478 4430 4353 -1 
            5314 5439 5391 -1 
            5410 5285 5333 -1 
            5045 5170 5122 -1 
            5122 5170 5247 -1 
            5170 5295 5247 -1 
            4949 5026 4901 -1 
            4949 5074 5026 -1 
            4824 4949 4901 -1 
            5074 4997 5122 -1 
            4997 5045 5122 -1 
            4949 4997 5074 -1 
            4420 4372 4295 -1 
            4324 4372 4449 -1 
            4372 4497 4449 -1 
            4497 4372 4420 -1 
            4084 4007 4132 -1 
            4007 4055 4132 -1 
            4007 4084 3959 -1 
            3882 4007 3959 -1 
            3719 3892 3671 -1 
            4161 4209 4286 -1 
            4209 4334 4286 -1 
            4334 4209 4257 -1 
            4257 4209 4132 -1 
            4209 4084 4132 -1 
            4209 4161 4084 -1 
            3572 3620 3697 -1 
            3620 3745 3697 -1 
            3822 3774 3697 -1 
            3745 3822 3697 -1 
            4487 4362 4410 -1 
            4381 4333 4256 -1 
            4804 4727 4852 -1 
            8466 8485 8495 -1 
            8485 8456 8504 -1 
            8465 8417 8340 -1 
            8494 8417 8465 -1 
            8369 8417 8494 -1 
            8447 8495 8476 -1 
            8399 8447 8476 -1 
            8351 8399 8476 -1 
            8475 8494 8504 -1 
            7342 7515 7121 -1 
            6967 7092 7044 -1 
            5593 5814 5987 -1 
            6063 6140 6015 -1 
            5938 6063 6015 -1 
            8222 8270 8347 -1 
            8270 8395 8347 -1 
            8270 8318 8395 -1 
            8145 8270 8222 -1 
            8174 8251 8126 -1 
            8097 8145 8222 -1 
            8174 8097 8222 -1 
            8299 8222 8347 -1 
            8251 8299 8376 -1 
            8299 8174 8222 -1 
            8174 8299 8251 -1 
            8424 8299 8347 -1 
            8299 8424 8376 -1 
            2542 2619 2494 -1 
            2417 2542 2494 -1 
            1965 2013 2090 -1 
            2445 2397 2320 -1 
            2110 2158 2235 -1 
            2542 2667 2619 -1 
            2667 2542 2590 -1 
            4032 3984 3907 -1 
            4109 3984 4032 -1 
            3618 3743 3695 -1 
            8088 8040 7963 -1 
            8088 8136 8213 -1 
            8155 8203 8280 -1 
            8155 8078 8203 -1 
            8155 8107 8030 -1 
            8078 8155 8030 -1 
            8059 8184 8136 -1 
            8184 8059 8107 -1 
            8309 8357 8434 -1 
            8184 8309 8261 -1 
            8482 8405 8453 -1 
            8357 8405 8482 -1 
            8405 8328 8453 -1 
            8328 8405 8280 -1 
            8405 8357 8280 -1 
            8463 8386 8434 -1 
            8386 8309 8434 -1 
            8309 8386 8261 -1 
            499 422 547 -1 
            422 499 374 -1 
            163 86 211 -1 
            86 134 211 -1 
            86 163 38 -1 
            86 38 9 -1 
            134 86 9 -1 
            336 413 288 -1 
            336 259 384 -1 
            461 336 384 -1 
            413 336 461 -1 
            336 288 211 -1 
            259 336 211 -1 
            47 76 28 -1 
            710 758 835 -1 
            210 287 162 -1 
            37 18 8 -1 
            37 66 18 -1 
            268 345 220 -1 
            268 316 393 -1 
            345 268 393 -1 
            200 248 325 -1 
            123 248 200 -1 
            344 267 392 -1 
            296 344 421 -1 
            267 315 392 -1 
            363 315 238 -1 
            142 94 17 -1 
            65 142 17 -1 
            431 383 306 -1 
            383 431 508 -1 
            268 191 316 -1 
            316 364 441 -1 
            5 34 15 -1 
            651 574 699 -1 
            574 651 526 -1 
            574 526 449 -1 
            497 574 449 -1 
            564 487 612 -1 
            16 26 7 -1 
            468 516 593 -1 
            545 468 593 -1 
            3 13 4 -1 
            13 23 4 -1 
            13 3 32 -1 
            398 446 523 -1 
            658 783 735 -1 
            766 814 891 -1 
            814 737 862 -1 
            843 766 891 -1 
            516 641 593 -1 
            564 641 516 -1 
            987 939 862 -1 
            814 939 891 -1 
            939 814 862 -1 
            939 1016 891 -1 
            1256 1131 1179 -1 
            728 651 776 -1 
            901 776 824 -1 
            651 603 526 -1 
            478 603 555 -1 
            603 478 526 -1 
            728 603 651 -1 
            430 478 555 -1 
            305 430 382 -1 
            526 401 449 -1 
            478 401 526 -1 
            1381 1458 1333 -1 
            997 920 1045 -1 
            843 920 795 -1 
            1189 1064 1112 -1 
            1064 987 1112 -1 
            1064 939 987 -1 
            939 1064 1016 -1 
            1237 1112 1160 -1 
            1237 1189 1112 -1 
            1285 1237 1160 -1 
            25 54 6 -1 
            102 54 25 -1 
            54 102 179 -1 
            1477 1525 1602 -1 
            660 737 612 -1 
            737 660 785 -1 
            660 708 785 -1 
            1400 1323 1448 -1 
            1400 1275 1323 -1 
            1525 1400 1448 -1 
            1400 1525 1477 -1 
            36 84 161 -1 
            132 84 7 -1 
            84 36 7 -1 
            209 286 161 -1 
            257 209 132 -1 
            84 209 161 -1 
            209 84 132 -1 
            334 257 382 -1 
            209 334 286 -1 
            334 209 257 -1 
            373 296 421 -1 
            373 248 296 -1 
            248 373 325 -1 
            373 450 325 -1 
            844 767 892 -1 
            748 796 873 -1 
            546 671 623 -1 
            671 546 594 -1 
            671 748 623 -1 
            748 671 796 -1 
            834 959 911 -1 
            767 815 892 -1 
            815 690 738 -1 
            690 815 767 -1 
            883 931 1008 -1 
            758 883 835 -1 
            931 1056 1008 -1 
            1056 1104 1181 -1 
            902 950 1027 -1 
            902 854 777 -1 
            2024 2101 1976 -1 
            3649 3572 3697 -1 
            3774 3649 3697 -1 
            3313 3236 3361 -1 
            1180 1257 1132 -1 
            1180 1132 1055 -1 
            1103 1180 1055 -1 
            2968 2920 2843 -1 
            2536 2661 2613 -1 
            2661 2738 2613 -1 
            2738 2661 2786 -1 
            2344 2392 2469 -1 
            2392 2517 2469 -1 
            2517 2565 2642 -1 
            2594 2517 2642 -1 
            2594 2642 2719 -1 
            2594 2546 2469 -1 
            2517 2594 2469 -1 
            2786 2834 2911 -1 
            2882 2834 2757 -1 
            3074 3026 2949 -1 
            980 903 1028 -1 
            490 615 442 -1 
            490 538 615 -1 
            538 490 413 -1 
            365 490 442 -1 
            413 490 365 -1 
            1585 1508 1633 -1 
            739 864 816 -1 
            1085 960 1008 -1 
            960 883 1008 -1 
            960 912 835 -1 
            883 960 835 -1 
            989 864 912 -1 
            557 509 432 -1 
            682 557 605 -1 
            720 672 595 -1 
            2248 2325 2200 -1 
            2373 2248 2296 -1 
            2248 2373 2325 -1 
            2325 2373 2450 -1 
            2373 2498 2450 -1 
            2200 2152 2075 -1 
            2104 2152 2229 -1 
            2325 2277 2200 -1 
            2152 2277 2229 -1 
            2277 2152 2200 -1 
            2277 2354 2229 -1 
            1970 1845 1893 -1 
            1797 1845 1970 -1 
            1845 1768 1893 -1 
            1845 1797 1720 -1 
            1768 1845 1720 -1 
            2066 2191 2143 -1 
            2143 2191 2316 -1 
            2354 2402 2479 -1 
            2402 2325 2450 -1 
            2402 2277 2325 -1 
            2277 2402 2354 -1 
            1624 1672 1749 -1 
            1672 1797 1749 -1 
            1797 1672 1720 -1 
            2258 2181 2306 -1 
            2258 2335 2210 -1 
            2037 2085 2162 -1 
            2085 2210 2162 -1 
            2114 2037 2162 -1 
            2114 2191 2066 -1 
            2018 2143 1970 -1 
            2018 2066 2143 -1 
            2018 1941 2066 -1 
            2018 1970 1893 -1 
            1941 2018 1893 -1 
            1691 1768 1643 -1 
            1768 1691 1816 -1 
            1912 1960 2037 -1 
            2085 1960 2008 -1 
            1960 2085 2037 -1 
            1989 1912 2037 -1 
            1941 1989 2066 -1 
            2114 1989 2037 -1 
            1989 2114 2066 -1 
            1864 1941 1816 -1 
            1864 1989 1941 -1 
            1989 1864 1912 -1 
            3325 3498 3277 -1 
            3344 3469 3421 -1 
            3296 3344 3421 -1 
            2767 2844 2719 -1 
            2642 2767 2719 -1 
            2633 2758 2710 -1 
            2758 2806 2931 -1 
            2844 2796 2719 -1 
            2700 2825 2777 -1 
            2825 2902 2777 -1 
            2498 2575 2450 -1 
            2537 2412 2460 -1 
            2364 2412 2537 -1 
            2412 2335 2460 -1 
            2412 2364 2287 -1 
            2335 2412 2287 -1 
            2431 2354 2479 -1 
            2354 2431 2306 -1 
            2624 2672 2749 -1 
            2566 2691 2643 -1 
            2770 2847 2722 -1 
            2645 2770 2722 -1 
            2587 2635 2712 -1 
            3028 3153 3105 -1 
            3153 3230 3105 -1 
            3076 3153 3028 -1 
            3153 3076 3201 -1 
            3278 3201 3326 -1 
            3278 3153 3201 -1 
            3153 3278 3230 -1 
            3230 3182 3105 -1 
            2701 2624 2749 -1 
            2701 2653 2576 -1 
            2624 2701 2576 -1 
            3252 3204 3127 -1 
            3204 3079 3127 -1 
            3771 3646 3694 -1 
            2243 2195 2118 -1 
            2195 2243 2320 -1 
            2800 2723 2848 -1 
            2608 2733 2685 -1 
            2733 2608 2656 -1 
            156 108 31 -1 
            79 156 31 -1 
            108 156 233 -1 
            60 137 12 -1 
            31 60 12 -1 
            108 60 31 -1 
            339 291 214 -1 
            291 339 416 -1 
            464 339 387 -1 
            339 464 416 -1 
            310 185 233 -1 
            185 108 233 -1 
            185 60 108 -1 
            60 185 137 -1 
            1 11 2 -1 
            337 289 212 -1 
            289 164 212 -1 
            164 289 241 -1 
            308 385 260 -1 
            385 337 260 -1 
            385 462 337 -1 
            147 99 22 -1 
            22 51 3 -1 
            99 51 22 -1 
            51 128 3 -1 
            291 166 214 -1 
            2502 2627 2579 -1 
            2166 2243 2118 -1 
            2243 2166 2291 -1 
            1733 1810 1685 -1 
            2031 2108 1983 -1 
            683 635 558 -1 
            721 673 596 -1 
            673 548 596 -1 
            279 356 231 -1 
            327 279 202 -1 
            279 327 404 -1 
            356 279 404 -1 
            279 154 202 -1 
            154 279 231 -1 
            846 798 721 -1 
            798 673 721 -1 
            673 798 750 -1 
            1807 1855 1932 -1 
            1086 1038 961 -1 
            1528 1134 961 -1 
            1307 1134 1528 -1 
            1134 1086 961 -1 
            1807 1884 1759 -1 
            1884 1807 1932 -1 
            990 942 865 -1 
            865 942 817 -1 
            942 894 817 -1 
            942 990 1067 -1 
            3990 4038 4115 -1 
            4038 4163 4115 -1 
            4163 4038 4086 -1 
            4009 4134 4086 -1 
            3327 3404 3279 -1 
            3202 3327 3279 -1 
            4240 4192 4115 -1 
            2886 2761 2809 -1 
            2415 2492 2367 -1 
            2415 2338 2463 -1 
            2511 2588 2463 -1 
            2588 2511 2636 -1 
            2492 2444 2367 -1 
            2569 2444 2492 -1 
            3935 3858 3983 -1 
            3858 3906 3983 -1 
            3810 3858 3935 -1 
            3819 3771 3694 -1 
            4223 4271 4348 -1 
            3906 4031 3983 -1 
            4252 4204 4127 -1 
            4061 3984 4109 -1 
            4109 4157 4234 -1 
            4157 4109 4032 -1 
            4722 4597 4645 -1 
            4770 4722 4645 -1 
            4760 4683 4808 -1 
            5298 5423 5375 -1 
            5423 5500 5375 -1 
            5500 5452 5375 -1 
            5452 5327 5375 -1 
            5327 5452 5404 -1 
            5452 5529 5404 -1 
            5529 5452 5577 -1 
            5452 5500 5577 -1 
            5500 5625 5577 -1 
            5625 5702 5577 -1 
            4962 4885 5010 -1 
            4885 4760 4808 -1 
            5039 4962 5087 -1 
            5164 5039 5087 -1 
            4856 4904 4981 -1 
            4731 4856 4808 -1 
            5250 5298 5375 -1 
            5327 5250 5375 -1 
            5279 5356 5231 -1 
            5356 5279 5404 -1 
            5279 5327 5404 -1 
            5135 5087 5010 -1 
            5029 5106 4981 -1 
            5183 5106 5231 -1 
            5433 5385 5308 -1 
            6052 6100 6177 -1 
            6042 6119 5994 -1 
            5658 5533 5581 -1 
            5783 5860 5735 -1 
            5658 5783 5735 -1 
            5350 5475 5427 -1 
            5475 5552 5427 -1 
            5465 5590 5542 -1 
            5503 5551 5628 -1 
            5878 6003 5955 -1 
            5955 6003 6080 -1 
            6128 6051 6176 -1 
            6003 6128 6080 -1 
            6128 6003 6051 -1 
            6022 6099 5974 -1 
            6137 6060 6185 -1 
            5809 5761 5684 -1 
            5761 5886 5838 -1 
            5886 5761 5809 -1 
            7204 7252 7329 -1 
            7252 7204 7127 -1 
            7502 7425 7550 -1 
            7406 7454 7531 -1 
            7454 7406 7329 -1 
            7233 7185 7108 -1 
            7185 7233 7310 -1 
            7156 7233 7108 -1 
            7031 7156 7108 -1 
            7281 7204 7329 -1 
            7406 7281 7329 -1 
            7281 7156 7204 -1 
            7156 7281 7233 -1 
            6964 7041 6916 -1 
            6666 6541 6589 -1 
            6935 6810 6858 -1 
            6685 6810 6762 -1 
            6839 6916 6791 -1 
            6839 6964 6916 -1 
            6983 7031 7108 -1 
            6983 6935 6858 -1 
            7146 7021 7069 -1 
            6464 6541 6416 -1 
            6541 6464 6589 -1 
            6464 6512 6589 -1 
            8212 8135 8260 -1 
            8164 8212 8289 -1 
            8212 8337 8289 -1 
            8212 8260 8337 -1 
            8039 8087 8164 -1 
            8087 8212 8164 -1 
            8212 8087 8135 -1 
            8135 8183 8260 -1 
            8308 8183 8231 -1 
            8183 8308 8260 -1 
            8087 8010 8135 -1 
            7741 7664 7789 -1 
            7326 7403 7278 -1 
            7643 7768 7720 -1 
            7768 7845 7720 -1 
            7595 7643 7720 -1 
            7672 7595 7720 -1 
            7624 7576 7499 -1 
            7076 7153 7028 -1 
            7230 7153 7278 -1 
            7307 7355 7432 -1 
            7403 7355 7278 -1 
            7355 7230 7278 -1 
            7230 7355 7307 -1 
            7528 7576 7653 -1 
            7838 7886 7963 -1 
            7934 7886 7809 -1 
            7857 7934 7809 -1 
            7377 7454 7329 -1 
            7425 7377 7300 -1 
            7377 7502 7454 -1 
            7502 7377 7425 -1 
            7377 7252 7300 -1 
            7252 7377 7329 -1 
            7531 7579 7656 -1 
            7454 7579 7531 -1 
            7502 7579 7454 -1 
            7867 7915 7992 -1 
            8040 7915 7963 -1 
            7915 8040 7992 -1 
            7915 7838 7963 -1 
            7915 7790 7838 -1 
            7915 7867 7790 -1 
            7732 7857 7809 -1 
            7857 7732 7780 -1 
            7790 7713 7838 -1 
            7665 7713 7790 -1 
            7415 7367 7290 -1 
            7242 7367 7319 -1 
            7367 7242 7290 -1 
            7367 7444 7319 -1 
            7367 7415 7492 -1 
            7444 7367 7492 -1 
            7194 7242 7319 -1 
            7242 7194 7117 -1 
            7194 7146 7069 -1 
            7117 7194 7069 -1 
            7165 7242 7117 -1 
            7165 7117 7040 -1 
            7165 7213 7290 -1 
            7242 7165 7290 -1 
            7223 7098 7146 -1 
            7098 7021 7146 -1 
            6973 7098 7050 -1 
            7021 7098 6973 -1 
            7636 7588 7511 -1 
            7540 7588 7665 -1 
            7713 7588 7636 -1 
            7588 7713 7665 -1 
            7463 7540 7415 -1 
            7588 7463 7511 -1 
            7463 7588 7540 -1 
            7617 7540 7665 -1 
            7617 7694 7569 -1 
            7492 7617 7569 -1 
            7540 7617 7492 -1 
            7694 7617 7742 -1 
            7617 7665 7742 -1 
            7521 7444 7569 -1 
            7521 7598 7473 -1 
            7646 7521 7569 -1 
            7598 7521 7646 -1 
            7348 7425 7300 -1 
            7425 7348 7473 -1 
            7223 7348 7300 -1 
            6660 6612 6535 -1 
            6612 6487 6535 -1 
            6487 6612 6564 -1 
            6189 6314 6266 -1 
            6093 6045 5968 -1 
            6170 6045 6093 -1 
            6362 6285 6410 -1 
            6487 6362 6410 -1 
            6516 6468 6391 -1 
            6468 6516 6593 -1 
            6545 6468 6593 -1 
            5709 5661 5584 -1 
            5661 5709 5786 -1 
            5709 5834 5786 -1 
            5834 5709 5757 -1 
            5738 5661 5786 -1 
            5767 5815 5892 -1 
            7967 8015 8092 -1 
            8169 8217 8294 -1 
            8217 8169 8092 -1 
            8313 8390 8265 -1 
            8313 8236 8361 -1 
            8438 8313 8361 -1 
            8390 8313 8438 -1 
            8188 8313 8265 -1 
            8236 8313 8188 -1 
            8371 8294 8419 -1 
            8323 8371 8448 -1 
            8371 8419 8496 -1 
            8448 8371 8496 -1 
            8371 8246 8294 -1 
            8246 8371 8323 -1 
            8169 8246 8121 -1 
            8246 8169 8294 -1 
            8400 8323 8448 -1 
            8150 8227 8102 -1 
            8477 8448 8496 -1 
            8429 8477 8458 -1 
            8477 8400 8448 -1 
            8487 8477 8496 -1 
            8477 8487 8458 -1 
            8381 8429 8458 -1 
            8111 8034 8159 -1 
            8063 8111 8188 -1 
            8236 8111 8159 -1 
            8111 8236 8188 -1 
            7986 8063 7938 -1 
            7986 7861 8034 -1 
            7986 7938 7861 -1 
            8111 7986 8034 -1 
            7986 8111 8063 -1 
            6478 6430 6353 -1 
            6430 6305 6353 -1 
            6305 6430 6382 -1 
            6382 6430 6507 -1 
            6430 6555 6507 -1 
            6430 6478 6555 -1 
            6401 6478 6353 -1 
            6276 6401 6353 -1 
            6420 6343 6468 -1 
            6420 6545 6497 -1 
            6545 6420 6468 -1 
            7220 7172 7095 -1 
            6932 6807 6855 -1 
            6845 6893 6970 -1 
            6922 6845 6970 -1 
            6797 6845 6922 -1 
            7797 7672 7720 -1 
            7845 7797 7720 -1 
            7797 7845 7922 -1 
            7845 7970 7922 -1 
            7181 7229 7306 -1 
            7258 7181 7306 -1 
            7104 7181 7056 -1 
            7181 7104 7229 -1 
            7239 7191 7114 -1 
            7191 7066 7114 -1 
            7066 7191 7143 -1 
            6528 6480 6403 -1 
            6480 6528 6605 -1 
            6192 6269 6144 -1 
            6192 6115 6240 -1 
            6048 6000 5923 -1 
            5424 5501 5376 -1 
            5299 5424 5376 -1 
            5424 5549 5501 -1 
            5549 5424 5472 -1 
            5654 5731 5606 -1 
            5568 5491 5616 -1 
            8173 8221 8298 -1 
            8221 8144 8269 -1 
            8202 8250 8327 -1 
            8250 8173 8298 -1 
            8250 8125 8173 -1 
            8125 8250 8202 -1 
            8423 8375 8298 -1 
            8375 8250 8298 -1 
            8250 8375 8327 -1 
            8327 8375 8452 -1 
            8375 8500 8452 -1 
            8375 8423 8500 -1 
            8346 8423 8298 -1 
            8394 8346 8269 -1 
            8346 8394 8471 -1 
            8423 8346 8471 -1 
            8346 8221 8269 -1 
            8221 8346 8298 -1 
            8144 8192 8269 -1 
            8336 8461 8413 -1 
            8317 8394 8269 -1 
            8317 8442 8394 -1 
            8192 8317 8269 -1 
            8317 8192 8240 -1 
            8365 8413 8490 -1 
            8442 8365 8490 -1 
            8317 8365 8442 -1 
            8365 8317 8240 -1 
            8279 8356 8231 -1 
            8279 8202 8327 -1 
            8404 8327 8452 -1 
            8404 8452 8481 -1 
            8356 8404 8481 -1 
            8404 8279 8327 -1 
            8279 8404 8356 -1 
            8016 8064 8141 -1 
            8112 8064 7987 -1 
            7997 8122 8074 -1 
            8122 7997 8045 -1 
            5238 5113 5161 -1 
            4940 4988 5065 -1 
            5113 4988 5036 -1 
            4988 5113 5065 -1 
            5036 4988 4911 -1 
            4863 4940 4815 -1 
            4863 4815 4738 -1 
            4988 4863 4911 -1 
            4863 4988 4940 -1 
            4767 4690 4815 -1 
            4767 4815 4892 -1 
            4690 4767 4642 -1 
            4767 4719 4642 -1 
            5017 4969 4892 -1 
            4969 5017 5094 -1 
            4796 4844 4921 -1 
            4969 4844 4892 -1 
            4844 4969 4921 -1 
            4844 4767 4892 -1 
            4844 4796 4719 -1 
            4767 4844 4719 -1 
            4873 4796 4921 -1 
            4796 4748 4671 -1 
            4700 4748 4825 -1 
            4748 4873 4825 -1 
            4873 4748 4796 -1 
            3796 3623 3844 -1 
            3796 3748 3623 -1 
            3595 3470 3518 -1 
            3691 3739 3816 -1 
            3614 3489 3537 -1 
            3614 3739 3691 -1 
            4604 4527 4652 -1 
            4527 4575 4652 -1 
            3864 3912 3989 -1 
            3739 3864 3816 -1 
            5939 5891 5766 -1 
            5632 5584 5507 -1 
            5555 5632 5507 -1 
            5632 5709 5584 -1 
            5709 5632 5757 -1 
            4306 4354 4431 -1 
            4354 4306 4229 -1 
            4344 4392 4469 -1 
            4440 4392 4315 -1 
            4440 4517 4392 -1 
            4517 4565 4642 -1 
            4565 4690 4642 -1 
            4690 4565 4613 -1 
            4613 4565 4488 -1 
            4565 4440 4488 -1 
            4440 4565 4517 -1 
            4450 4527 4402 -1 
            4527 4450 4575 -1 
            4623 4546 4671 -1 
            4575 4623 4700 -1 
            4748 4623 4671 -1 
            4623 4748 4700 -1 
            4354 4277 4402 -1 
            4277 4354 4229 -1 
            4152 4277 4229 -1 
            4498 4450 4373 -1 
            4450 4498 4575 -1 
            4498 4623 4575 -1 
            4623 4498 4546 -1 
            4363 4190 4411 -1 
            4190 4363 4315 -1 
            4363 4440 4315 -1 
            4488 4363 4411 -1 
            4440 4363 4488 -1 
            4017 4190 4142 -1 
            4017 3969 3844 -1 
            4017 3844 4411 -1 
            4190 4017 4411 -1 
            4613 4661 4738 -1 
            4709 4661 4584 -1 
            4661 4536 4584 -1 
            4536 4661 4613 -1 
            4786 4709 4834 -1 
            4786 4863 4738 -1 
            4661 4786 4738 -1 
            4786 4661 4709 -1 
            4786 4834 4911 -1 
            4863 4786 4911 -1 
            4834 4757 4882 -1 
            4709 4757 4834 -1 
            4757 4709 4584 -1 
            5324 5151 5372 -1 
            5324 5276 5151 -1 
            5449 5324 5372 -1 
            5276 5228 5151 -1 
            5103 5228 5180 -1 
            5228 5103 5151 -1 
            5228 5276 5353 -1 
            5257 5382 5334 -1 
            5382 5430 5507 -1 
            5055 5103 5180 -1 
            5103 5055 4978 -1 
            5055 4930 4978 -1 
            4930 5055 5007 -1 
            5084 5209 5161 -1 
            5209 5257 5334 -1 
            6547 6672 6624 -1 
            6672 6547 6595 -1 
            6624 6672 6749 -1 
            6672 6797 6749 -1 
            6528 6451 6576 -1 
            6451 6528 6403 -1 
            6499 6624 6576 -1 
            6499 6547 6624 -1 
            6451 6499 6576 -1 
            6499 6451 6374 -1 
            6556 6479 6604 -1 
            6479 6556 6431 -1 
            6479 6354 6402 -1 
            6354 6479 6431 -1 
            7027 7104 6979 -1 
            7104 7027 7152 -1 
            6931 6854 6979 -1 
            6873 6950 6825 -1 
            6873 6796 6921 -1 
            7017 6969 6892 -1 
            6844 6969 6921 -1 
            6969 6844 6892 -1 
            7046 7171 7123 -1 
            6969 7046 6921 -1 
            6556 6508 6431 -1 
            6383 6508 6460 -1 
            6431 6508 6383 -1 
            6508 6585 6460 -1 
            6508 6633 6585 -1 
            6633 6508 6556 -1 
            6422 6499 6374 -1 
            6499 6422 6547 -1 
            6470 6422 6345 -1 
            6422 6470 6547 -1 
            6595 6470 6518 -1 
            6547 6470 6595 -1 
            6268 6345 6220 -1 
            6393 6316 6441 -1 
            6393 6470 6345 -1 
            6268 6393 6345 -1 
            6393 6268 6316 -1 
            6393 6441 6518 -1 
            6470 6393 6518 -1 
            6489 6412 6537 -1 
            6489 6566 6441 -1 
            6614 6489 6537 -1 
            6566 6489 6614 -1 
            6316 6364 6441 -1 
            6364 6489 6441 -1 
            6489 6364 6412 -1 
            6364 6316 6239 -1 
            4316 4393 4268 -1 
            4729 4604 4652 -1 
            4729 4681 4604 -1 
            4806 4729 4854 -1 
            4729 4806 4681 -1 
            7556 7681 7633 -1 
            7402 7354 7277 -1 
            7229 7354 7306 -1 
            7354 7229 7277 -1 
            7479 7354 7402 -1 
            7960 8085 8037 -1 
            8036 8084 8161 -1 
            8113 8036 8161 -1 
            5969 6017 6094 -1 
            6017 5969 5892 -1 
            6459 6334 6382 -1 
            6334 6257 6382 -1 
            6151 6276 6228 -1 
            6103 6151 6228 -1 
            6132 6180 6257 -1 
            6305 6180 6228 -1 
            6180 6305 6257 -1 
            6180 6103 6228 -1 
            6055 6132 6007 -1 
            6180 6055 6103 -1 
            6055 6180 6132 -1 
            6036 6113 5988 -1 
            6209 6132 6257 -1 
            6334 6209 6257 -1 
            6440 6488 6565 -1 
            6488 6440 6363 -1 
            6296 6248 6171 -1 
            6248 6325 6200 -1 
            6325 6248 6373 -1 
            6248 6296 6373 -1 
            6623 6700 6575 -1 
            6671 6594 6719 -1 
            6796 6671 6719 -1 
            6546 6623 6498 -1 
            6546 6671 6623 -1 
            6671 6546 6594 -1 
            6863 6786 6911 -1 
            6988 6863 6911 -1 
            6681 6556 6604 -1 
            6681 6633 6556 -1 
            6633 6681 6758 -1 
            6739 6787 6864 -1 
            6912 6787 6835 -1 
            6787 6912 6864 -1 
            6835 6787 6710 -1 
            6787 6662 6710 -1 
            6787 6739 6662 -1 
            7008 6931 7056 -1 
            6959 6911 6834 -1 
            6911 6959 7036 -1 
            7603 7526 7651 -1 
            7728 7603 7651 -1 
            8045 7920 7968 -1 
            7997 7920 8045 -1 
            7920 7843 7968 -1 
            7467 7294 7688 -1 
            6900 6852 6727 -1 
            6767 6815 6892 -1 
            6844 6767 6892 -1 
            6767 6844 6719 -1 
            6767 6690 6815 -1 
            7142 7190 7267 -1 
            7190 7315 7267 -1 
            7411 7363 7286 -1 
            7219 7142 7267 -1 
            6940 7017 6892 -1 
            6940 6863 6988 -1 
            6815 6940 6892 -1 
            6863 6940 6815 -1 
            7113 6988 7036 -1 
            7171 7296 7248 -1 
            7219 7296 7171 -1 
            7440 7315 7363 -1 
            4670 4622 4545 -1 
            4641 4516 4564 -1 
            4718 4641 4766 -1 
            4843 4718 4766 -1 
            5025 5102 4977 -1 
            4833 4910 4785 -1 
            5083 5006 5131 -1 
            4247 4170 4295 -1 
            4199 4247 4324 -1 
            4372 4247 4295 -1 
            4247 4372 4324 -1 
            4276 4199 4324 -1 
            4228 4276 4353 -1 
            4276 4401 4353 -1 
            4276 4324 4401 -1 
            4180 4103 4228 -1 
            4103 4180 4055 -1 
            3507 3430 3555 -1 
            4141 4064 4189 -1 
            4093 4141 4218 -1 
            4266 4141 4189 -1 
            4141 4266 4218 -1 
            4045 4093 4170 -1 
            4045 3968 4093 -1 
            4208 4131 4256 -1 
            4131 4208 4083 -1 
            4333 4208 4256 -1 
            4035 4112 3987 -1 
            3910 4035 3987 -1 
            3862 3910 3987 -1 
            3968 4016 4093 -1 
            4141 4016 4064 -1 
            4016 4141 4093 -1 
            3209 3257 3334 -1 
            3257 3209 3132 -1 
            3228 3180 3103 -1 
            3180 3257 3132 -1 
            3257 3180 3305 -1 
            3180 3228 3305 -1 
            3728 3651 3776 -1 
            3882 3834 3757 -1 
            3834 3882 3959 -1 
            3517 3594 3469 -1 
            3594 3719 3671 -1 
            5237 5314 5189 -1 
            5285 5237 5160 -1 
            5458 5410 5333 -1 
            5439 5362 5487 -1 
            5362 5410 5487 -1 
            5362 5439 5314 -1 
            5410 5362 5285 -1 
            5237 5362 5314 -1 
            5362 5237 5285 -1 
            5179 5256 5131 -1 
            5256 5179 5304 -1 
            5266 5141 5189 -1 
            5141 5064 5189 -1 
            5064 5141 5016 -1 
            5170 5218 5295 -1 
            5343 5218 5266 -1 
            5218 5343 5295 -1 
            5218 5141 5266 -1 
            4872 4949 4824 -1 
            4872 4997 4949 -1 
            4084 4036 3959 -1 
            4161 4036 4084 -1 
            3671 4065 4238 -1 
            3892 4065 3671 -1 
            5303 5178 5226 -1 
            4783 4706 4831 -1 
            5302 5350 5427 -1 
            5447 5572 5524 -1 
            5293 5418 5370 -1 
            4716 4841 4793 -1 
            4245 4370 4322 -1 
            4524 4572 4649 -1 
            4572 4697 4649 -1 
            4697 4572 4620 -1 
            4620 4572 4495 -1 
            4370 4447 4322 -1 
            4447 4370 4495 -1 
            4572 4447 4495 -1 
            4447 4572 4524 -1 
            3591 3639 3716 -1 
            5349 5397 5474 -1 
            5397 5349 5272 -1 
            4302 4379 4254 -1 
            4052 3975 4100 -1 
            4177 4302 4254 -1 
            4177 4052 4100 -1 
            4379 4331 4254 -1 
            4331 4206 4254 -1 
            4206 4331 4283 -1 
            4331 4379 4456 -1 
            4379 4504 4456 -1 
            4552 4475 4600 -1 
            4158 4206 4283 -1 
            4206 4158 4081 -1 
            4158 4235 4110 -1 
            4235 4158 4283 -1 
            4360 4235 4283 -1 
            3668 3745 3620 -1 
            3668 3591 3716 -1 
            3841 3793 3716 -1 
            3793 3668 3716 -1 
            3668 3793 3745 -1 
            3889 3966 3841 -1 
            4168 4245 4120 -1 
            4314 4266 4189 -1 
            4266 4314 4391 -1 
            4237 4314 4189 -1 
            4314 4237 4362 -1 
            4112 4237 4189 -1 
            4285 4333 4410 -1 
            4362 4285 4410 -1 
            4237 4285 4362 -1 
            4285 4208 4333 -1 
            4439 4362 4487 -1 
            4439 4516 4391 -1 
            4314 4439 4391 -1 
            4439 4314 4362 -1 
            4516 4439 4564 -1 
            4439 4487 4564 -1 
            8466 8437 8485 -1 
            8312 8264 8187 -1 
            8408 8456 8485 -1 
            8446 8369 8494 -1 
            8446 8475 8398 -1 
            8475 8446 8494 -1 
            8447 8370 8495 -1 
            8245 8197 8120 -1 
            8197 8072 8120 -1 
            8351 8303 8226 -1 
            8082 8303 8476 -1 
            8303 8351 8476 -1 
            7928 7851 7976 -1 
            7851 7928 7803 -1 
            7515 7909 8082 -1 
            6369 6292 6417 -1 
            6369 6417 6494 -1 
            6446 6369 6494 -1 
            5909 5832 5957 -1 
            5909 5784 5832 -1 
            5784 5909 5861 -1 
            6034 5909 5957 -1 
            5813 5765 5688 -1 
            5938 5813 5861 -1 
            5765 5640 5688 -1 
            6331 6456 6408 -1 
            5860 5985 5937 -1 
            5937 6014 5889 -1 
            6948 6823 6871 -1 
            6775 6823 6948 -1 
            7370 7418 7495 -1 
            6707 6784 6659 -1 
            6919 6996 6871 -1 
            6794 6919 6871 -1 
            6996 6919 7044 -1 
            6919 6967 7044 -1 
            7217 7092 7140 -1 
            7342 7390 7515 -1 
            7755 7678 7803 -1 
            7707 7755 7832 -1 
            7582 7457 7505 -1 
            7582 7707 7659 -1 
            7015 7092 6967 -1 
            7140 7015 7063 -1 
            7092 7015 7140 -1 
            5689 5564 5612 -1 
            5564 5439 5487 -1 
            5612 5564 5487 -1 
            6937 7014 6889 -1 
            6188 6063 6111 -1 
            6063 6188 6140 -1 
            6169 6217 6294 -1 
            8193 8270 8145 -1 
            8193 8116 8241 -1 
            8318 8193 8241 -1 
            8270 8193 8318 -1 
            8193 8145 8068 -1 
            8116 8193 8068 -1 
            8078 8001 8126 -1 
            8001 8078 7953 -1 
            2638 2590 2513 -1 
            2638 2686 2763 -1 
            2465 2417 2340 -1 
            2388 2465 2340 -1 
            2465 2388 2513 -1 
            2417 2465 2542 -1 
            2590 2465 2513 -1 
            2542 2465 2590 -1 
            2215 2263 2340 -1 
            2388 2263 2311 -1 
            2263 2388 2340 -1 
            2263 2186 2311 -1 
            2138 2215 2090 -1 
            2013 2138 2090 -1 
            2263 2138 2186 -1 
            2138 2263 2215 -1 
            2292 2215 2340 -1 
            2417 2292 2340 -1 
            1917 1994 1869 -1 
            1965 1888 2013 -1 
            2368 2445 2320 -1 
            2416 2368 2291 -1 
            2243 2368 2320 -1 
            2368 2243 2291 -1 
            2445 2522 2397 -1 
            2599 2522 2647 -1 
            2109 2061 1984 -1 
            2061 2138 2013 -1 
            2061 2109 2186 -1 
            2138 2061 2186 -1 
            2744 2667 2792 -1 
            2667 2744 2619 -1 
            2744 2696 2619 -1 
            2696 2571 2619 -1 
            2619 2571 2494 -1 
            2418 2543 2495 -1 
            2389 2437 2514 -1 
            2610 2562 2485 -1 
            2562 2437 2485 -1 
            2437 2562 2514 -1 
            2514 2562 2639 -1 
            2784 2832 2909 -1 
            2764 2812 2889 -1 
            2783 2908 2860 -1 
            2908 2985 2860 -1 
            2985 2908 3033 -1 
            2831 2783 2706 -1 
            2831 2908 2783 -1 
            2783 2658 2706 -1 
            2562 2687 2639 -1 
            2687 2562 2610 -1 
            2687 2764 2639 -1 
            2687 2812 2764 -1 
            3495 3620 3572 -1 
            3447 3495 3572 -1 
            3763 3811 3888 -1 
            3811 3936 3888 -1 
            3888 3936 4013 -1 
            3936 4061 4013 -1 
            4061 3936 3984 -1 
            3676 3753 3628 -1 
            2869 2744 2792 -1 
            3850 3773 3898 -1 
            3975 3850 3898 -1 
            3581 3533 3456 -1 
            3677 3552 3600 -1 
            3589 3666 3541 -1 
            3666 3618 3541 -1 
            3666 3589 3714 -1 
            3618 3666 3743 -1 
            3320 3397 3272 -1 
            8175 8300 8252 -1 
            8069 7944 7992 -1 
            7944 7867 7992 -1 
            8367 8242 8290 -1 
            8165 8088 8213 -1 
            8088 8165 8040 -1 
            8290 8165 8213 -1 
            8242 8165 8290 -1 
            8040 8117 7992 -1 
            8117 8069 7992 -1 
            8165 8117 8040 -1 
            8117 8165 8242 -1 
            8059 8011 7934 -1 
            7886 8011 7963 -1 
            8011 7886 7934 -1 
            8011 8088 7963 -1 
            8088 8011 8136 -1 
            8011 8059 8136 -1 
            8232 8309 8184 -1 
            8232 8155 8280 -1 
            8357 8232 8280 -1 
            8309 8232 8357 -1 
            8232 8184 8107 -1 
            8155 8232 8107 -1 
            8435 8483 8464 -1 
            8483 8435 8358 -1 
            8483 8454 8502 -1 
            8338 8386 8463 -1 
            8386 8338 8261 -1 
            8261 8338 8213 -1 
            8338 8290 8213 -1 
            8319 8396 8271 -1 
            8319 8242 8367 -1 
            8444 8367 8492 -1 
            8319 8444 8396 -1 
            8444 8319 8367 -1 
            8396 8348 8271 -1 
            8377 8329 8252 -1 
            8300 8377 8252 -1 
            8454 8377 8502 -1 
            8377 8454 8329 -1 
            451 528 403 -1 
            499 451 374 -1 
            326 451 403 -1 
            451 326 374 -1 
            326 249 374 -1 
            249 326 201 -1 
            220 172 95 -1 
            172 47 95 -1 
            585 710 662 -1 
            537 585 662 -1 
            691 739 816 -1 
            441 518 393 -1 
            566 518 441 -1 
            489 566 441 -1 
            364 489 441 -1 
            2237 2285 2362 -1 
            2449 2526 2401 -1 
            2718 2593 2641 -1 
            2612 2535 2660 -1 
            2535 2583 2660 -1 
            2592 2467 2515 -1 
            2563 2486 2611 -1 
            1871 1746 1794 -1 
            1621 1746 1698 -1 
            114 37 162 -1 
            37 114 66 -1 
            114 191 66 -1 
            37 85 162 -1 
            85 210 162 -1 
            210 85 133 -1 
            133 85 8 -1 
            85 37 8 -1 
            27 56 8 -1 
            104 56 27 -1 
            56 133 8 -1 
            56 181 133 -1 
            56 104 181 -1 
            152 104 27 -1 
            152 200 277 -1 
            46 75 27 -1 
            123 75 46 -1 
            75 152 27 -1 
            75 123 200 -1 
            152 75 200 -1 
            142 219 94 -1 
            219 142 267 -1 
            219 344 296 -1 
            344 219 267 -1 
            190 65 113 -1 
            190 142 65 -1 
            190 113 238 -1 
            142 190 267 -1 
            315 190 238 -1 
            190 315 267 -1 
            383 258 306 -1 
            258 181 306 -1 
            258 210 133 -1 
            181 258 133 -1 
            287 239 162 -1 
            239 114 162 -1 
            114 239 191 -1 
            191 239 316 -1 
            239 364 316 -1 
            364 239 287 -1 
            191 143 66 -1 
            18 143 95 -1 
            66 143 18 -1 
            143 220 95 -1 
            143 268 220 -1 
            143 191 268 -1 
            412 364 287 -1 
            489 412 537 -1 
            412 489 364 -1 
            622 497 545 -1 
            622 574 497 -1 
            574 622 699 -1 
            208 333 285 -1 
            45 26 16 -1 
            14 24 5 -1 
            33 14 4 -1 
            255 332 207 -1 
            130 255 207 -1 
            840 888 965 -1 
            782 705 830 -1 
            446 571 523 -1 
            494 571 446 -1 
            1994 1946 1869 -1 
            1898 1946 2023 -1 
            1773 1850 1725 -1 
            1850 1773 1898 -1 
            1398 1475 1350 -1 
            1495 1620 1572 -1 
            708 833 785 -1 
            833 910 785 -1 
            641 689 766 -1 
            814 689 737 -1 
            689 814 766 -1 
            737 689 612 -1 
            689 564 612 -1 
            689 641 564 -1 
            910 958 1035 -1 
            958 1083 1035 -1 
            833 958 910 -1 
            958 833 881 -1 
            1208 1131 1256 -1 
            1208 1285 1160 -1 
            1083 1208 1160 -1 
            1131 1208 1083 -1 
            1285 1208 1333 -1 
            1208 1256 1333 -1 
            901 853 776 -1 
            853 728 776 -1 
            728 853 805 -1 
            949 901 824 -1 
            901 949 1026 -1 
            949 1074 1026 -1 
            949 997 1074 -1 
            430 353 478 -1 
            353 401 478 -1 
            353 430 305 -1 
            228 353 305 -1 
            1410 1285 1333 -1 
            1458 1410 1333 -1 
            1074 1151 1026 -1 
            1151 1103 1026 -1 
            920 968 1045 -1 
            968 1093 1045 -1 
            1093 968 1016 -1 
            1016 968 891 -1 
            968 843 891 -1 
            968 920 843 -1 
            1141 1064 1189 -1 
            1141 1093 1016 -1 
            1064 1141 1016 -1 
            44 25 15 -1 
            44 73 25 -1 
            131 54 179 -1 
            131 208 83 -1 
            131 83 6 -1 
            54 131 6 -1 
            35 16 6 -1 
            83 35 6 -1 
            189 266 141 -1 
            208 160 83 -1 
            160 35 83 -1 
            35 160 112 -1 
            160 208 285 -1 
            1323 1371 1448 -1 
            1371 1496 1448 -1 
            1496 1544 1621 -1 
            1544 1669 1621 -1 
            1746 1669 1794 -1 
            1669 1746 1621 -1 
            1496 1573 1448 -1 
            1573 1525 1448 -1 
            1573 1496 1621 -1 
            1573 1621 1698 -1 
            1304 1256 1179 -1 
            1256 1304 1381 -1 
            1304 1429 1381 -1 
            554 679 631 -1 
            804 679 727 -1 
            487 535 612 -1 
            535 660 612 -1 
            325 402 277 -1 
            450 402 325 -1 
            527 402 450 -1 
            498 373 421 -1 
            498 450 373 -1 
            546 498 421 -1 
            498 546 623 -1 
            690 642 565 -1 
            517 642 594 -1 
            642 517 565 -1 
            642 690 767 -1 
            459 334 382 -1 
            459 584 536 -1 
            546 469 594 -1 
            469 517 594 -1 
            469 546 421 -1 
            517 469 392 -1 
            344 469 421 -1 
            469 344 392 -1 
            825 748 873 -1 
            825 902 777 -1 
            950 825 873 -1 
            902 825 950 -1 
            652 777 729 -1 
            604 652 729 -1 
            527 652 604 -1 
            786 834 911 -1 
            863 786 911 -1 
            863 815 738 -1 
            786 863 738 -1 
            1104 1229 1181 -1 
            1152 1104 1027 -1 
            1152 1229 1104 -1 
            1152 1200 1277 -1 
            1229 1152 1277 -1 
            806 883 758 -1 
            854 806 729 -1 
            931 806 854 -1 
            883 806 931 -1 
            979 1056 931 -1 
            979 902 1027 -1 
            1104 979 1027 -1 
            1056 979 1104 -1 
            979 931 854 -1 
            902 979 854 -1 
            1133 1085 1008 -1 
            1056 1133 1008 -1 
            1133 1056 1181 -1 
            1258 1133 1181 -1 
            1133 1210 1085 -1 
            1210 1133 1258 -1 
            998 950 873 -1 
            1248 1171 1296 -1 
            1123 1248 1200 -1 
            1248 1123 1171 -1 
            2149 2197 2274 -1 
            2024 2149 2101 -1 
            2005 2130 2082 -1 
            2063 2140 2015 -1 
            1938 2063 2015 -1 
            1986 2063 1938 -1 
            2697 2649 2572 -1 
            2649 2697 2774 -1 
            2553 2630 2505 -1 
            2899 2851 2774 -1 
            2841 2764 2889 -1 
            2966 2841 2889 -1 
            2841 2966 2918 -1 
            2918 2966 3043 -1 
            2995 2918 3043 -1 
            2870 2995 2947 -1 
            2995 2870 2918 -1 
            3141 3266 3218 -1 
            3266 3141 3189 -1 
            3862 3785 3910 -1 
            2862 2785 2910 -1 
            3525 3400 3448 -1 
            3285 3160 3208 -1 
            3630 3678 3755 -1 
            3678 3803 3755 -1 
            2506 2631 2583 -1 
            3063 3188 3140 -1 
            3188 3236 3313 -1 
            3553 3678 3630 -1 
            3236 3284 3361 -1 
            3159 3284 3236 -1 
            3524 3447 3572 -1 
            3649 3524 3572 -1 
            1277 1325 1402 -1 
            1200 1325 1277 -1 
            1248 1325 1200 -1 
            1007 1084 959 -1 
            1132 1007 1055 -1 
            1084 1007 1132 -1 
            1449 1497 1574 -1 
            1526 1449 1574 -1 
            1180 1305 1257 -1 
            2296 2219 2344 -1 
            2661 2709 2786 -1 
            2834 2709 2757 -1 
            2709 2834 2786 -1 
            2738 2690 2613 -1 
            2690 2565 2613 -1 
            2565 2690 2642 -1 
            2690 2767 2642 -1 
            2690 2738 2815 -1 
            2767 2690 2815 -1 
            2488 2536 2613 -1 
            2565 2488 2613 -1 
            2536 2488 2411 -1 
            2488 2363 2411 -1 
            2324 2449 2401 -1 
            2238 2190 2113 -1 
            2238 2315 2190 -1 
            2238 2363 2315 -1 
            2132 2180 2257 -1 
            2209 2132 2257 -1 
            3007 3055 3132 -1 
            3055 3180 3132 -1 
            3180 3055 3103 -1 
            2978 3026 3103 -1 
            3055 2978 3103 -1 
            932 1009 884 -1 
            788 1182 1355 -1 
            1009 1182 788 -1 
            932 1057 1009 -1 
            1057 932 980 -1 
            1057 1182 1009 -1 
            730 682 605 -1 
            557 634 509 -1 
            634 557 682 -1 
            787 864 739 -1 
            787 710 835 -1 
            912 787 835 -1 
            864 787 912 -1 
            710 787 662 -1 
            787 739 662 -1 
            989 941 864 -1 
            816 941 893 -1 
            864 941 816 -1 
            960 1037 912 -1 
            1037 989 912 -1 
            1037 960 1085 -1 
            989 1037 1114 -1 
            480 528 605 -1 
            557 480 605 -1 
            480 557 432 -1 
            528 480 403 -1 
            355 480 432 -1 
            480 355 403 -1 
            672 624 547 -1 
            624 499 547 -1 
            720 768 845 -1 
            768 816 893 -1 
            845 768 893 -1 
            768 691 816 -1 
            2421 2344 2469 -1 
            2373 2421 2498 -1 
            2421 2296 2344 -1 
            2421 2373 2296 -1 
            2546 2421 2469 -1 
            2498 2421 2546 -1 
            1979 2027 2104 -1 
            2152 2027 2075 -1 
            2027 2152 2104 -1 
            2056 1979 2104 -1 
            2056 2104 2181 -1 
            2287 2239 2162 -1 
            2239 2114 2162 -1 
            2114 2239 2191 -1 
            2191 2239 2316 -1 
            2239 2364 2316 -1 
            2364 2239 2287 -1 
            1547 1672 1624 -1 
            2085 2133 2210 -1 
            2258 2133 2181 -1 
            2133 2258 2210 -1 
            2133 2056 2181 -1 
            2133 2085 2008 -1 
            2056 2133 2008 -1 
            1960 1883 2008 -1 
            1681 1604 1729 -1 
            1729 1777 1854 -1 
            1508 1556 1633 -1 
            1556 1681 1633 -1 
            1681 1556 1604 -1 
            1604 1652 1729 -1 
            1777 1652 1700 -1 
            1652 1777 1729 -1 
            1652 1575 1700 -1 
            1575 1652 1527 -1 
            1652 1604 1527 -1 
            3498 3373 3421 -1 
            3325 3373 3498 -1 
            3373 3296 3421 -1 
            3248 3171 3296 -1 
            3373 3248 3296 -1 
            3248 3373 3325 -1 
            3392 3517 3469 -1 
            3344 3392 3469 -1 
            3084 3007 3132 -1 
            3209 3084 3132 -1 
            2959 3036 2911 -1 
            3007 2959 2882 -1 
            3084 2959 3007 -1 
            2959 3084 3036 -1 
            2834 2959 2911 -1 
            2959 2834 2882 -1 
            2767 2892 2844 -1 
            2892 2767 2815 -1 
            2892 2969 2844 -1 
            2585 2537 2460 -1 
            2537 2585 2710 -1 
            2585 2633 2710 -1 
            2556 2431 2479 -1 
            2806 2854 2931 -1 
            2902 2854 2777 -1 
            2969 2921 2844 -1 
            2921 2796 2844 -1 
            2796 2921 2873 -1 
            2873 2921 2998 -1 
            2796 2671 2719 -1 
            2671 2594 2719 -1 
            2594 2671 2546 -1 
            2431 2383 2306 -1 
            2383 2258 2306 -1 
            2258 2383 2335 -1 
            2335 2383 2460 -1 
            2883 3056 3008 -1 
            3056 2883 3450 -1 
            3229 3056 3450 -1 
            3133 3085 3008 -1 
            3056 3133 3008 -1 
            2624 2547 2672 -1 
            2518 2566 2643 -1 
            2393 2345 2268 -1 
            2345 2393 2470 -1 
            2393 2518 2470 -1 
            2374 2326 2249 -1 
            2326 2201 2249 -1 
            2201 2326 2278 -1 
            2297 2374 2249 -1 
            2422 2345 2470 -1 
            2547 2422 2470 -1 
            2422 2297 2345 -1 
            2297 2422 2374 -1 
            2883 2662 2489 -1 
            2835 2662 2883 -1 
            4249 4201 4124 -1 
            3643 3720 3595 -1 
            3643 3595 3518 -1 
            3864 3941 3816 -1 
            3941 3864 3989 -1 
            4066 3941 3989 -1 
            2491 2568 2443 -1 
            2626 2751 2703 -1 
            2952 3077 3029 -1 
            3191 3268 3143 -1 
            3191 3114 3239 -1 
            3268 3220 3143 -1 
            3220 3297 3172 -1 
            3489 3412 3537 -1 
            3412 3460 3537 -1 
            2797 2922 2874 -1 
            2845 2922 2797 -1 
            3143 3095 3018 -1 
            3047 3095 3172 -1 
            3095 3220 3172 -1 
            3220 3095 3143 -1 
            2951 3076 3028 -1 
            2980 3028 3105 -1 
            3076 3124 3201 -1 
            3124 3047 3172 -1 
            3432 3480 3557 -1 
            3384 3336 3259 -1 
            3461 3336 3384 -1 
            3278 3355 3230 -1 
            3432 3355 3480 -1 
            2347 2395 2472 -1 
            2222 2347 2299 -1 
            2376 2251 2299 -1 
            2961 3009 3086 -1 
            3009 3134 3086 -1 
            3134 3182 3259 -1 
            2942 3067 3019 -1 
            2990 3067 2942 -1 
            3067 3144 3019 -1 
            3733 3858 3810 -1 
            3464 3589 3541 -1 
            2906 2829 2954 -1 
            3348 3300 3223 -1 
            3252 3300 3377 -1 
            1830 1782 1705 -1 
            1782 1830 1907 -1 
            2272 2224 2147 -1 
            2195 2272 2147 -1 
            2272 2195 2320 -1 
            2397 2272 2320 -1 
            1830 1955 1907 -1 
            1878 1955 1830 -1 
            2157 2080 2205 -1 
            2032 2109 1984 -1 
            2032 1955 2080 -1 
            2032 2157 2109 -1 
            2157 2032 2080 -1 
            2032 1984 1907 -1 
            1955 2032 1907 -1 
            2752 2829 2704 -1 
            2752 2704 2627 -1 
            2925 2800 2848 -1 
            2973 2925 2848 -1 
            3050 2925 2973 -1 
            2829 2781 2704 -1 
            2781 2656 2704 -1 
            2781 2733 2656 -1 
            2906 2781 2829 -1 
            2464 2541 2416 -1 
            435 512 387 -1 
            512 464 387 -1 
            512 435 560 -1 
            262 339 214 -1 
            262 185 310 -1 
            262 310 387 -1 
            339 262 387 -1 
            137 262 214 -1 
            185 262 137 -1 
            30 11 1 -1 
            318 193 241 -1 
            241 193 116 -1 
            193 68 116 -1 
            49 1 20 -1 
            97 49 20 -1 
            68 145 20 -1 
            145 97 20 -1 
            193 145 68 -1 
            145 222 97 -1 
            481 356 404 -1 
            366 318 241 -1 
            289 366 241 -1 
            462 587 539 -1 
            70 147 22 -1 
            70 118 195 -1 
            147 70 195 -1 
            147 224 99 -1 
            224 349 301 -1 
            243 166 291 -1 
            243 320 195 -1 
            118 243 195 -1 
            166 243 118 -1 
            41 166 118 -1 
            70 41 118 -1 
            41 22 12 -1 
            41 70 22 -1 
            2329 2252 2377 -1 
            2252 2300 2377 -1 
            2300 2223 2348 -1 
            2175 2252 2127 -1 
            2050 2175 2127 -1 
            2300 2175 2223 -1 
            2175 2300 2252 -1 
            2656 2531 2579 -1 
            2608 2531 2656 -1 
            2435 2560 2512 -1 
            2560 2608 2685 -1 
            2464 2387 2512 -1 
            2387 2435 2512 -1 
            2214 2137 2262 -1 
            2166 2214 2291 -1 
            1589 1714 1666 -1 
            1714 1791 1666 -1 
            1714 1839 1791 -1 
            1858 1733 1781 -1 
            1858 1810 1733 -1 
            1858 1983 1935 -1 
            1810 1858 1935 -1 
            2108 2060 1983 -1 
            2060 2012 1935 -1 
            1983 2060 1935 -1 
            2060 2137 2012 -1 
            2185 2108 2233 -1 
            2137 2185 2262 -1 
            2060 2185 2137 -1 
            2185 2060 2108 -1 
            2252 2204 2127 -1 
            2329 2204 2252 -1 
            2002 2050 2127 -1 
            1877 2002 1954 -1 
            1608 1685 1560 -1 
            1608 1733 1685 -1 
            1733 1656 1781 -1 
            1608 1656 1733 -1 
            1944 1819 1867 -1 
            926 974 1051 -1 
            1589 1541 1464 -1 
            1541 1589 1666 -1 
            1522 1647 1599 -1 
            1647 1522 1570 -1 
            1224 1272 1349 -1 
            1272 1397 1349 -1 
            1397 1272 1320 -1 
            481 606 558 -1 
            606 683 558 -1 
            673 625 548 -1 
            548 625 500 -1 
            625 577 500 -1 
            625 702 577 -1 
            702 625 750 -1 
            625 673 750 -1 
            798 875 750 -1 
            2105 1980 2028 -1 
            2057 1980 2105 -1 
            1980 2057 1932 -1 
            1855 1980 1932 -1 
            2153 2105 2028 -1 
            2153 2201 2278 -1 
            1134 1211 1086 -1 
            1653 1528 1701 -1 
            1653 1605 1528 -1 
            2095 2047 1922 -1 
            2268 2095 2489 -1 
            2095 1922 2489 -1 
            1922 1874 1701 -1 
            1874 1826 1701 -1 
            1874 1951 1826 -1 
            1125 1202 1077 -1 
            1000 1125 1077 -1 
            1836 1884 1961 -1 
            1884 1836 1759 -1 
            1202 1327 1279 -1 
            3913 4038 3990 -1 
            4076 4028 3951 -1 
            4201 4076 4124 -1 
            3692 3740 3817 -1 
            4163 4288 4240 -1 
            4557 4432 4480 -1 
            4307 4432 4384 -1 
            3404 3356 3279 -1 
            3212 3289 3164 -1 
            3222 3097 3145 -1 
            3808 3856 3933 -1 
            3712 3635 3760 -1 
            3712 3587 3635 -1 
            3318 3366 3443 -1 
            4269 4394 4346 -1 
            4144 4192 4269 -1 
            4019 4144 4096 -1 
            3971 4019 4096 -1 
            3971 3894 4019 -1 
            4048 3971 4096 -1 
            4077 4125 4202 -1 
            4173 4048 4096 -1 
            4048 4173 4125 -1 
            4231 4183 4106 -1 
            2088 2136 2213 -1 
            2098 2050 1973 -1 
            2021 2098 1973 -1 
            2175 2098 2223 -1 
            2098 2175 2050 -1 
            2761 2713 2636 -1 
            2588 2713 2665 -1 
            2713 2588 2636 -1 
            2540 2588 2665 -1 
            2588 2540 2463 -1 
            2540 2415 2463 -1 
            2415 2540 2492 -1 
            4328 4203 4251 -1 
            4087 4010 4135 -1 
            4010 4087 3962 -1 
            2742 2819 2694 -1 
            3396 3473 3348 -1 
            3357 3232 3280 -1 
            3887 3964 3839 -1 
            3896 3819 3944 -1 
            3819 3896 3771 -1 
            3954 3906 3829 -1 
            3954 4031 3906 -1 
            4146 4069 4194 -1 
            4098 4146 4223 -1 
            4271 4146 4194 -1 
            4146 4271 4223 -1 
            4021 4098 3973 -1 
            4069 4021 3944 -1 
            4146 4021 4069 -1 
            4021 4146 4098 -1 
            4021 3896 3944 -1 
            3896 4021 3973 -1 
            4050 3925 3973 -1 
            4098 4050 3973 -1 
            4319 4271 4194 -1 
            4444 4319 4367 -1 
            4492 4367 4415 -1 
            4492 4444 4367 -1 
            4175 4252 4127 -1 
            4175 4098 4223 -1 
            4050 4175 4127 -1 
            4175 4050 4098 -1 
            4300 4223 4348 -1 
            4300 4175 4223 -1 
            4175 4300 4252 -1 
            4300 4377 4252 -1 
            3955 4032 3907 -1 
            4925 5050 5002 -1 
            5501 5453 5376 -1 
            5482 5434 5357 -1 
            5021 5146 5098 -1 
            4473 4598 4550 -1 
            4598 4473 4521 -1 
            5069 5146 5021 -1 
            4992 5040 5117 -1 
            5069 4992 5117 -1 
            4080 4157 4032 -1 
            3955 4080 4032 -1 
            4080 4128 4205 -1 
            4157 4080 4205 -1 
            4685 4733 4810 -1 
            4731 4606 4654 -1 
            4606 4731 4683 -1 
            4395 4472 4347 -1 
            4597 4549 4472 -1 
            5490 5615 5567 -1 
            5394 5269 5317 -1 
            5548 5625 5500 -1 
            5423 5548 5500 -1 
            4837 4885 4962 -1 
            4885 4837 4760 -1 
            5212 5164 5087 -1 
            5135 5212 5087 -1 
            5164 5116 5039 -1 
            5068 5116 5193 -1 
            5116 5241 5193 -1 
            5241 5116 5164 -1 
            4933 4856 4981 -1 
            4885 4933 5010 -1 
            4933 4885 4808 -1 
            4856 4933 4808 -1 
            4904 4779 4827 -1 
            4856 4779 4904 -1 
            4779 4856 4731 -1 
            4779 4702 4827 -1 
            4779 4731 4654 -1 
            4702 4779 4654 -1 
            4952 4904 4827 -1 
            4904 4952 5029 -1 
            5029 4952 5077 -1 
            4952 5000 5077 -1 
            5173 5221 5298 -1 
            5250 5173 5298 -1 
            5202 5250 5327 -1 
            5279 5202 5327 -1 
            5135 5058 5183 -1 
            5058 5106 5183 -1 
            5058 5135 5010 -1 
            5106 5058 4981 -1 
            4933 5058 5010 -1 
            5058 4933 4981 -1 
            5558 5481 5606 -1 
            5558 5433 5481 -1 
            6052 6129 6004 -1 
            6129 6052 6177 -1 
            6004 5956 5879 -1 
            6148 6100 6023 -1 
            6253 6128 6176 -1 
            6311 6359 6436 -1 
            6292 6167 6215 -1 
            6167 6119 6042 -1 
            6138 6263 6215 -1 
            6340 6465 6417 -1 
            6292 6340 6417 -1 
            6340 6292 6215 -1 
            6263 6340 6215 -1 
            5533 5610 5485 -1 
            5658 5610 5533 -1 
            5610 5658 5735 -1 
            5831 5754 5879 -1 
            5956 5831 5879 -1 
            5706 5783 5658 -1 
            5706 5658 5581 -1 
            5831 5706 5754 -1 
            5706 5831 5783 -1 
            5648 5523 5571 -1 
            5725 5773 5850 -1 
            5773 5725 5648 -1 
            5927 6004 5879 -1 
            5927 6052 6004 -1 
            5754 5802 5879 -1 
            5802 5927 5879 -1 
            5927 5802 5850 -1 
            5802 5725 5850 -1 
            5677 5802 5754 -1 
            5725 5802 5677 -1 
            5830 5878 5955 -1 
            5907 5830 5955 -1 
            5830 5907 5782 -1 
            5705 5830 5782 -1 
            5494 5619 5571 -1 
            5619 5494 5542 -1 
            5811 5686 5734 -1 
            5398 5475 5350 -1 
            5398 5523 5475 -1 
            5273 5398 5350 -1 
            5590 5638 5715 -1 
            5638 5561 5686 -1 
            5417 5494 5369 -1 
            5494 5417 5542 -1 
            5417 5465 5542 -1 
            5465 5513 5590 -1 
            5638 5513 5561 -1 
            5513 5638 5590 -1 
            5455 5532 5407 -1 
            5580 5503 5628 -1 
            5705 5580 5628 -1 
            5455 5580 5532 -1 
            5580 5455 5503 -1 
            5695 5647 5570 -1 
            5551 5676 5628 -1 
            5686 5609 5734 -1 
            5561 5609 5686 -1 
            5820 5695 5743 -1 
            6224 6272 6349 -1 
            6224 6176 6099 -1 
            6272 6195 6320 -1 
            5926 6003 5878 -1 
            5926 5849 5974 -1 
            6051 5926 5974 -1 
            6003 5926 6051 -1 
            5926 5878 5801 -1 
            5849 5926 5801 -1 
            6512 6387 6435 -1 
            6464 6387 6512 -1 
            6166 6041 6089 -1 
            6041 5964 6089 -1 
            5964 6012 6089 -1 
            6137 6012 6060 -1 
            6012 6137 6089 -1 
            6012 5964 5887 -1 
            6377 6502 6454 -1 
            6329 6377 6454 -1 
            6531 6406 6454 -1 
            6406 6329 6454 -1 
            6281 6358 6233 -1 
            6406 6281 6329 -1 
            6281 6406 6358 -1 
            6223 6175 6098 -1 
            6175 6223 6300 -1 
            6300 6223 6348 -1 
            6223 6271 6348 -1 
            7175 7252 7127 -1 
            7175 7098 7223 -1 
            7175 7223 7300 -1 
            7252 7175 7300 -1 
            7175 7127 7050 -1 
            7098 7175 7050 -1 
            7483 7406 7531 -1 
            7358 7435 7310 -1 
            7358 7281 7406 -1 
            7358 7483 7435 -1 
            7483 7358 7406 -1 
            7233 7358 7310 -1 
            7281 7358 7233 -1 
            7079 7156 7031 -1 
            7204 7079 7127 -1 
            7156 7079 7204 -1 
            6820 6945 6897 -1 
            6964 7089 7041 -1 
            7435 7387 7310 -1 
            7060 6983 7108 -1 
            6983 7060 6935 -1 
            7060 7185 7137 -1 
            7185 7060 7108 -1 
            6849 6772 6897 -1 
            6772 6820 6897 -1 
            6772 6849 6724 -1 
            6772 6724 6647 -1 
            6695 6772 6647 -1 
            6772 6695 6820 -1 
            6570 6695 6647 -1 
            6637 6685 6762 -1 
            6512 6637 6589 -1 
            6810 6733 6858 -1 
            6733 6810 6685 -1 
            6608 6733 6685 -1 
            6839 6887 6964 -1 
            6887 6810 6935 -1 
            6810 6887 6762 -1 
            6887 6839 6762 -1 
            6368 6445 6320 -1 
            6666 6618 6541 -1 
            6618 6695 6570 -1 
            6906 6983 6858 -1 
            6983 6906 7031 -1 
            6925 6973 7050 -1 
            8058 8183 8135 -1 
            8010 8058 8135 -1 
            7933 8058 8010 -1 
            7885 7933 8010 -1 
            7962 8087 8039 -1 
            7962 8010 8087 -1 
            7914 7962 8039 -1 
            7962 7885 8010 -1 
            7962 7914 7837 -1 
            7885 7962 7837 -1 
            7347 7270 7395 -1 
            7605 7528 7653 -1 
            6800 6877 6752 -1 
            6800 6925 6877 -1 
            6992 7117 7069 -1 
            7117 6992 7040 -1 
            7107 7184 7059 -1 
            7001 7126 7078 -1 
            7213 7338 7290 -1 
            7338 7415 7290 -1 
            7338 7463 7415 -1 
            7991 8116 8068 -1 
            8116 7991 8039 -1 
            7991 7914 8039 -1 
            7876 8001 7953 -1 
            6915 6963 7040 -1 
            6992 6915 7040 -1 
            7143 7268 7220 -1 
            7191 7268 7143 -1 
            7374 7422 7499 -1 
            7489 7566 7441 -1 
            7691 7768 7643 -1 
            7566 7691 7643 -1 
            7547 7595 7672 -1 
            7624 7547 7672 -1 
            7547 7624 7499 -1 
            7595 7547 7470 -1 
            7422 7547 7499 -1 
            7547 7422 7470 -1 
            7153 7105 7028 -1 
            7105 7153 7230 -1 
            7528 7480 7403 -1 
            7355 7480 7432 -1 
            7480 7355 7403 -1 
            7605 7480 7528 -1 
            7451 7528 7403 -1 
            7451 7374 7499 -1 
            7576 7451 7499 -1 
            7528 7451 7576 -1 
            7451 7326 7374 -1 
            7326 7451 7403 -1 
            7857 7982 7934 -1 
            7982 8059 7934 -1 
            8107 7982 8030 -1 
            8059 7982 8107 -1 
            7627 7502 7550 -1 
            7627 7579 7502 -1 
            7694 7771 7646 -1 
            7684 7732 7809 -1 
            7713 7761 7838 -1 
            7886 7761 7809 -1 
            7761 7886 7838 -1 
            7761 7684 7809 -1 
            7761 7713 7636 -1 
            7684 7761 7636 -1 
            7184 7136 7059 -1 
            7136 7011 7059 -1 
            7271 7223 7146 -1 
            7194 7271 7146 -1 
            7271 7194 7319 -1 
            7271 7348 7223 -1 
            7348 7396 7473 -1 
            7396 7521 7473 -1 
            7521 7396 7444 -1 
            7444 7396 7319 -1 
            7396 7271 7319 -1 
            7271 7396 7348 -1 
            6218 6170 6093 -1 
            6343 6218 6266 -1 
            6189 6237 6314 -1 
            6362 6237 6285 -1 
            6237 6362 6314 -1 
            6045 5920 5968 -1 
            6122 6045 6170 -1 
            6314 6439 6391 -1 
            6362 6439 6314 -1 
            6439 6362 6487 -1 
            6439 6516 6391 -1 
            6439 6487 6564 -1 
            6516 6439 6564 -1 
            5719 5767 5844 -1 
            5815 5940 5892 -1 
            5940 6017 5892 -1 
            5940 5988 6065 -1 
            6017 5940 6065 -1 
            5834 5911 5786 -1 
            5911 6036 5988 -1 
            5719 5642 5767 -1 
            5440 5517 5392 -1 
            5440 5392 5315 -1 
            5392 5267 5315 -1 
            7919 7996 7871 -1 
            7967 7919 7842 -1 
            7919 7794 7842 -1 
            7794 7919 7871 -1 
            8044 7967 8092 -1 
            7996 8044 8121 -1 
            7919 8044 7996 -1 
            8044 7919 7967 -1 
            8044 8169 8121 -1 
            8169 8044 8092 -1 
            7890 7938 8015 -1 
            7967 7890 8015 -1 
            7890 7967 7842 -1 
            7765 7890 7842 -1 
            8198 8246 8323 -1 
            8246 8198 8121 -1 
            8131 8179 8256 -1 
            8227 8179 8102 -1 
            8179 8054 8102 -1 
            8054 8179 8131 -1 
            8179 8304 8256 -1 
            8304 8179 8227 -1 
            8304 8381 8256 -1 
            8381 8304 8429 -1 
            8362 8237 8285 -1 
            8237 8362 8314 -1 
            8381 8333 8256 -1 
            8333 8381 8458 -1 
            6401 6526 6478 -1 
            6478 6526 6603 -1 
            6603 6526 6651 -1 
            6526 6574 6651 -1 
            6420 6295 6343 -1 
            6295 6218 6343 -1 
            6218 6295 6170 -1 
            7326 7249 7374 -1 
            6932 6980 7057 -1 
            7105 6980 7028 -1 
            6980 7105 7057 -1 
            6980 6903 7028 -1 
            6980 6855 6903 -1 
            6980 6932 6855 -1 
            6826 6701 6749 -1 
            6624 6701 6576 -1 
            6701 6624 6749 -1 
            6701 6653 6576 -1 
            6653 6528 6576 -1 
            6528 6653 6605 -1 
            6807 6730 6855 -1 
            6653 6730 6605 -1 
            7047 6970 7095 -1 
            7172 7047 7095 -1 
            7047 6922 6970 -1 
            7047 6999 6922 -1 
            6999 6874 6922 -1 
            6874 6797 6922 -1 
            6874 6826 6749 -1 
            6797 6874 6749 -1 
            6951 7076 7028 -1 
            6951 6999 7076 -1 
            6903 6951 7028 -1 
            6951 6874 6999 -1 
            6826 6951 6903 -1 
            6874 6951 6826 -1 
            7749 7624 7672 -1 
            7797 7749 7672 -1 
            7768 7893 7845 -1 
            7893 7970 7845 -1 
            7989 8037 8114 -1 
            7989 7941 7864 -1 
            7893 8018 7970 -1 
            8018 7893 7941 -1 
            8230 8307 8182 -1 
            8037 8162 8114 -1 
            8085 8162 8037 -1 
            8489 8479 8498 -1 
            8018 8095 7970 -1 
            8460 8335 8383 -1 
            8460 8479 8489 -1 
            7181 7133 7056 -1 
            7133 7008 7056 -1 
            7008 7133 7085 -1 
            7085 7133 7210 -1 
            7133 7258 7210 -1 
            7133 7181 7258 -1 
            6490 6567 6442 -1 
            6365 6413 6490 -1 
            6413 6538 6490 -1 
            6288 6365 6240 -1 
            6288 6413 6365 -1 
            6413 6288 6336 -1 
            6317 6365 6442 -1 
            6365 6317 6240 -1 
            6317 6192 6240 -1 
            6317 6269 6192 -1 
            6096 6173 6048 -1 
            6375 6327 6250 -1 
            6327 6375 6452 -1 
            6278 6326 6403 -1 
            6326 6451 6403 -1 
            6451 6326 6374 -1 
            5894 5846 5769 -1 
            5721 5846 5798 -1 
            5846 5721 5769 -1 
            5846 5923 5798 -1 
            6019 5894 5942 -1 
            6096 6019 6144 -1 
            5750 5827 5702 -1 
            5625 5750 5702 -1 
            6029 5981 5904 -1 
            6106 5981 6029 -1 
            6029 5952 6077 -1 
            5952 6000 6077 -1 
            5952 6029 5904 -1 
            5827 5952 5904 -1 
            5731 5683 5606 -1 
            5683 5558 5606 -1 
            5558 5683 5635 -1 
            5779 5827 5904 -1 
            5827 5779 5702 -1 
            5779 5654 5702 -1 
            5779 5731 5654 -1 
            5808 5683 5731 -1 
            5885 5808 5933 -1 
            5837 5885 5962 -1 
            5857 5905 5982 -1 
            8221 8096 8144 -1 
            8096 8221 8173 -1 
            7654 7529 7577 -1 
            7529 7654 7606 -1 
            7510 7635 7587 -1 
            7933 7808 7856 -1 
            7885 7808 7933 -1 
            8077 8125 8202 -1 
            8125 8077 8000 -1 
            7952 8077 8029 -1 
            8077 7952 8000 -1 
            8259 8336 8211 -1 
            8307 8259 8182 -1 
            8461 8432 8480 -1 
            8336 8288 8211 -1 
            8288 8365 8240 -1 
            8288 8336 8413 -1 
            8365 8288 8413 -1 
            8026 8074 8151 -1 
            8103 8026 8151 -1 
            7853 7930 7805 -1 
            7728 7853 7805 -1 
            8093 8045 7968 -1 
            8016 8093 7968 -1 
            8093 8016 8141 -1 
            8333 8208 8256 -1 
            8208 8333 8285 -1 
            8208 8131 8256 -1 
            8035 8112 7987 -1 
            8218 8093 8141 -1 
            8199 8247 8324 -1 
            8247 8199 8122 -1 
            8074 8199 8151 -1 
            8122 8199 8074 -1 
            8372 8420 8497 -1 
            8247 8372 8324 -1 
            8468 8420 8343 -1 
            8468 8487 8497 -1 
            8420 8468 8497 -1 
            8449 8372 8497 -1 
            8372 8449 8324 -1 
            5238 5363 5315 -1 
            5363 5440 5315 -1 
            5113 5190 5065 -1 
            5238 5190 5113 -1 
            5190 5238 5315 -1 
            5267 5190 5315 -1 
            4873 4950 4825 -1 
            3950 3902 3825 -1 
            3902 3777 3825 -1 
            3777 3902 3854 -1 
            3902 3950 4027 -1 
            3873 3950 3825 -1 
            3748 3873 3825 -1 
            3796 3873 3748 -1 
            4046 4171 4123 -1 
            3700 3748 3825 -1 
            3777 3700 3825 -1 
            3652 3700 3777 -1 
            3748 3700 3623 -1 
            3499 3374 3422 -1 
            3374 3297 3422 -1 
            3201 3249 3326 -1 
            3249 3374 3326 -1 
            3374 3249 3297 -1 
            3297 3249 3172 -1 
            3249 3124 3172 -1 
            3124 3249 3201 -1 
            3470 3393 3518 -1 
            3585 3633 3710 -1 
            3460 3585 3537 -1 
            3710 3758 3835 -1 
            3806 3758 3681 -1 
            3758 3633 3681 -1 
            3633 3758 3710 -1 
            3787 3710 3835 -1 
            3787 3864 3739 -1 
            3912 3787 3835 -1 
            3864 3787 3912 -1 
            4479 4527 4604 -1 
            4527 4479 4402 -1 
            4479 4354 4402 -1 
            4354 4479 4431 -1 
            4075 4152 4027 -1 
            3950 4075 4027 -1 
            4191 4239 4316 -1 
            4316 4239 4364 -1 
            4239 4287 4364 -1 
            3931 3806 3854 -1 
            3960 3912 3835 -1 
            4152 4104 4027 -1 
            4104 4152 4229 -1 
            4085 3960 4008 -1 
            5449 5497 5574 -1 
            5545 5497 5372 -1 
            5497 5449 5372 -1 
            5526 5449 5574 -1 
            5401 5478 5353 -1 
            5401 5324 5449 -1 
            5401 5526 5478 -1 
            5526 5401 5449 -1 
            5276 5401 5353 -1 
            5324 5401 5276 -1 
            5718 5545 5766 -1 
            5718 5670 5545 -1 
            5622 5699 5574 -1 
            5670 5622 5545 -1 
            5497 5622 5574 -1 
            5622 5497 5545 -1 
            5728 5853 5805 -1 
            4421 4344 4469 -1 
            4421 4498 4373 -1 
            4421 4373 4296 -1 
            4344 4421 4296 -1 
            4546 4421 4469 -1 
            4498 4421 4546 -1 
            4392 4267 4315 -1 
            4344 4267 4392 -1 
            4190 4267 4142 -1 
            4267 4190 4315 -1 
            4373 4248 4296 -1 
            4248 4171 4296 -1 
            4171 4248 4123 -1 
            4805 4757 4584 -1 
            4805 4584 4411 -1 
            4930 4805 4978 -1 
            4978 4805 5372 -1 
            4805 4930 4882 -1 
            4757 4805 4882 -1 
            4805 5766 5372 -1 
            3844 4805 4411 -1 
            5305 5228 5353 -1 
            5305 5382 5257 -1 
            5305 5257 5180 -1 
            5228 5305 5180 -1 
            5305 5353 5430 -1 
            5382 5305 5430 -1 
            5459 5411 5334 -1 
            5382 5459 5334 -1 
            5459 5382 5507 -1 
            5411 5459 5536 -1 
            5584 5459 5507 -1 
            5459 5584 5536 -1 
            5132 5084 5007 -1 
            5055 5132 5007 -1 
            5132 5055 5180 -1 
            5132 5209 5084 -1 
            5257 5132 5180 -1 
            5209 5132 5257 -1 
            5286 5209 5334 -1 
            5286 5363 5238 -1 
            5286 5238 5161 -1 
            5209 5286 5161 -1 
            5411 5286 5334 -1 
            5363 5286 5411 -1 
            6768 6643 6691 -1 
            6845 6768 6893 -1 
            6527 6450 6575 -1 
            6479 6527 6604 -1 
            6450 6527 6402 -1 
            6527 6479 6402 -1 
            6326 6249 6374 -1 
            6143 6268 6220 -1 
            7075 7027 6950 -1 
            7027 7075 7152 -1 
            7075 7200 7152 -1 
            7200 7075 7123 -1 
            6950 6902 6825 -1 
            6854 6902 6979 -1 
            6902 7027 6979 -1 
            7027 6902 6950 -1 
            7046 7094 7171 -1 
            7219 7094 7142 -1 
            7094 7219 7171 -1 
            7094 7017 7142 -1 
            7094 6969 7017 -1 
            7094 7046 6969 -1 
            6287 6364 6239 -1 
            6287 6210 6335 -1 
            6412 6287 6335 -1 
            6364 6287 6412 -1 
            4345 4422 4297 -1 
            4393 4345 4268 -1 
            4345 4220 4268 -1 
            4220 4345 4297 -1 
            4422 4374 4297 -1 
            4374 4249 4297 -1 
            4403 4528 4480 -1 
            4287 4412 4364 -1 
            4479 4556 4431 -1 
            4556 4479 4604 -1 
            4681 4556 4604 -1 
            4508 4383 4431 -1 
            4508 4460 4383 -1 
            4556 4508 4431 -1 
            4566 4691 4643 -1 
            4691 4566 4614 -1 
            4537 4412 4460 -1 
            4700 4777 4652 -1 
            4777 4729 4652 -1 
            4777 4700 4825 -1 
            4729 4777 4854 -1 
            8469 8440 8488 -1 
            8392 8440 8469 -1 
            8440 8363 8488 -1 
            8421 8469 8498 -1 
            7681 7729 7806 -1 
            7729 7652 7777 -1 
            7931 7979 8056 -1 
            7806 7931 7883 -1 
            7825 7902 7777 -1 
            7960 8008 8085 -1 
            8008 7931 8056 -1 
            8008 7960 7883 -1 
            7931 8008 7883 -1 
            7960 7835 7883 -1 
            7710 7835 7787 -1 
            7700 7652 7575 -1 
            7652 7700 7777 -1 
            7700 7825 7777 -1 
            7825 7700 7748 -1 
            7479 7604 7556 -1 
            7604 7681 7556 -1 
            7604 7729 7681 -1 
            7729 7604 7652 -1 
            7988 8036 8113 -1 
            7988 7911 8036 -1 
            6267 6392 6344 -1 
            6296 6219 6344 -1 
            6219 6267 6344 -1 
            6219 6296 6171 -1 
            6219 6171 6094 -1 
            6113 6190 6065 -1 
            6171 6046 6094 -1 
            6046 5969 6094 -1 
            5930 6055 6007 -1 
            5853 5930 5805 -1 
            6132 6084 6007 -1 
            6209 6084 6132 -1 
            6411 6488 6363 -1 
            6411 6334 6459 -1 
            6411 6459 6536 -1 
            6488 6411 6536 -1 
            6700 6652 6575 -1 
            6527 6652 6604 -1 
            6652 6527 6575 -1 
            6748 6700 6623 -1 
            6671 6748 6623 -1 
            6748 6671 6796 -1 
            6700 6748 6825 -1 
            6748 6873 6825 -1 
            6873 6748 6796 -1 
            6546 6469 6594 -1 
            6392 6469 6344 -1 
            6594 6642 6719 -1 
            6642 6767 6719 -1 
            6767 6642 6690 -1 
            6690 6642 6565 -1 
            6690 6738 6815 -1 
            6738 6863 6815 -1 
            6863 6738 6786 -1 
            6902 6777 6825 -1 
            6777 6902 6854 -1 
            6777 6700 6825 -1 
            6777 6652 6700 -1 
            6681 6806 6758 -1 
            6806 6854 6931 -1 
            7008 6883 6931 -1 
            6883 6806 6931 -1 
            6758 6883 6835 -1 
            6806 6883 6758 -1 
            6960 7037 6912 -1 
            6960 6883 7008 -1 
            7037 6960 7085 -1 
            6960 7008 7085 -1 
            6960 6912 6835 -1 
            6883 6960 6835 -1 
            6959 7084 7036 -1 
            6930 6978 7055 -1 
            6978 7103 7055 -1 
            7103 7180 7055 -1 
            6574 6699 6651 -1 
            7651 7574 7699 -1 
            7526 7574 7651 -1 
            7574 7526 7449 -1 
            7680 7728 7805 -1 
            7728 7680 7603 -1 
            7680 7555 7603 -1 
            7872 7920 7997 -1 
            7747 7872 7824 -1 
            7891 8016 7968 -1 
            7843 7891 7968 -1 
            7891 7766 7814 -1 
            7766 7891 7843 -1 
            7670 7795 7747 -1 
            7795 7872 7747 -1 
            7795 7843 7920 -1 
            7872 7795 7920 -1 
            7996 7948 7871 -1 
            8073 7996 8121 -1 
            8073 7948 7996 -1 
            8198 8073 8121 -1 
            8073 8198 8150 -1 
            7544 7592 7669 -1 
            7592 7544 7467 -1 
            7640 7467 7688 -1 
            7640 7592 7467 -1 
            7765 7640 7688 -1 
            7861 7813 7688 -1 
            7813 7765 7688 -1 
            7813 7890 7765 -1 
            7938 7813 7861 -1 
            7890 7813 7938 -1 
            6804 6852 6929 -1 
            6852 6804 6727 -1 
            6804 6679 6727 -1 
            6679 6804 6756 -1 
            7294 7073 6900 -1 
            6708 6785 6660 -1 
            6756 6708 6631 -1 
            6708 6583 6631 -1 
            6708 6660 6583 -1 
            7190 7238 7315 -1 
            7363 7238 7286 -1 
            7315 7238 7363 -1 
            7113 7238 7190 -1 
            7065 7190 7142 -1 
            7065 7113 7190 -1 
            7017 7065 7142 -1 
            7113 7065 6988 -1 
            7065 6940 6988 -1 
            6940 7065 7017 -1 
            7450 7498 7575 -1 
            7344 7219 7267 -1 
            7344 7296 7219 -1 
            4583 4458 4506 -1 
            4458 4381 4506 -1 
            4333 4458 4410 -1 
            4381 4458 4333 -1 
            4679 4727 4804 -1 
            4679 4602 4727 -1 
            4756 4679 4804 -1 
            4881 4756 4804 -1 
            4833 4756 4881 -1 
            4487 4612 4564 -1 
            4670 4593 4718 -1 
            4593 4641 4718 -1 
            4593 4670 4545 -1 
            4641 4593 4516 -1 
            4468 4593 4545 -1 
            4516 4593 4468 -1 
            4622 4747 4699 -1 
            4670 4747 4622 -1 
            4747 4824 4699 -1 
            4747 4872 4824 -1 
            5025 5150 5102 -1 
            4910 4862 4785 -1 
            4641 4689 4766 -1 
            4689 4814 4766 -1 
            4689 4641 4564 -1 
            4612 4689 4564 -1 
            4891 4843 4766 -1 
            4814 4891 4766 -1 
            5006 4958 4881 -1 
            4958 4833 4881 -1 
            4833 4958 4910 -1 
            4958 5006 5083 -1 
            5102 5054 4977 -1 
            5006 5054 5131 -1 
            5054 5179 5131 -1 
            5179 5054 5102 -1 
            4929 5006 4881 -1 
            4977 4929 4852 -1 
            5054 4929 4977 -1 
            4929 5054 5006 -1 
            4929 4804 4852 -1 
            4929 4881 4804 -1 
            4103 4151 4228 -1 
            4151 4276 4228 -1 
            4276 4151 4199 -1 
            4151 4074 4199 -1 
            4074 4122 4199 -1 
            4247 4122 4170 -1 
            4122 4247 4199 -1 
            4122 4045 4170 -1 
            4122 4074 3997 -1 
            4045 4122 3997 -1 
            4074 3949 3997 -1 
            3949 3872 3997 -1 
            3978 4103 4055 -1 
            3978 3853 3901 -1 
            3430 3382 3305 -1 
            3257 3382 3334 -1 
            3382 3257 3305 -1 
            3382 3459 3334 -1 
            3382 3507 3459 -1 
            3382 3430 3507 -1 
            3430 3478 3555 -1 
            4208 4160 4083 -1 
            4160 4035 4083 -1 
            4035 4160 4112 -1 
            4160 4237 4112 -1 
            4285 4160 4208 -1 
            4160 4285 4237 -1 
            3958 4006 4083 -1 
            4035 3958 4083 -1 
            3958 4035 3910 -1 
            4179 4131 4054 -1 
            4131 4179 4256 -1 
            3891 3843 3766 -1 
            3843 3891 3968 -1 
            3891 4016 3968 -1 
            3872 3920 3997 -1 
            3920 4045 3997 -1 
            4045 3920 3968 -1 
            3920 3843 3968 -1 
            3843 3920 3795 -1 
            3920 3872 3795 -1 
            3824 3901 3776 -1 
            3824 3949 3901 -1 
            3949 3824 3872 -1 
            3497 3574 3449 -1 
            3497 3622 3574 -1 
            3372 3497 3449 -1 
            3228 3353 3305 -1 
            3353 3430 3305 -1 
            3353 3478 3430 -1 
            3478 3353 3401 -1 
            3401 3324 3449 -1 
            3324 3372 3449 -1 
            3266 3343 3218 -1 
            3237 3160 3285 -1 
            3747 3670 3795 -1 
            3670 3747 3622 -1 
            3872 3747 3795 -1 
            3824 3747 3872 -1 
            3603 3728 3680 -1 
            3603 3651 3728 -1 
            3603 3680 3555 -1 
            3478 3603 3555 -1 
            3661 3709 3786 -1 
            3834 3709 3757 -1 
            3709 3834 3786 -1 
            3709 3632 3757 -1 
            3632 3709 3584 -1 
            3709 3661 3584 -1 
            3738 3661 3786 -1 
            3738 3815 3690 -1 
            3815 3767 3690 -1 
            3719 3767 3892 -1 
            3767 3815 3892 -1 
            3815 3940 3892 -1 
            3940 4065 3892 -1 
            4065 3940 3988 -1 
            4036 3911 3959 -1 
            3911 4036 3988 -1 
            3911 3834 3959 -1 
            3834 3911 3786 -1 
            3498 3546 3671 -1 
            3546 3594 3671 -1 
            3594 3546 3469 -1 
            3469 3546 3421 -1 
            3546 3498 3421 -1 
            5640 5563 5688 -1 
            5563 5640 5515 -1 
            6160 5593 5987 -1 
            5593 6160 5199 -1 
            7121 6160 6554 -1 
            6083 6035 5958 -1 
            5910 6035 5987 -1 
            6035 5910 5958 -1 
            6035 6160 5987 -1 
            6160 6035 6083 -1 
            6006 6083 5958 -1 
            5602 5477 5525 -1 
            5881 6006 5958 -1 
            5179 5227 5304 -1 
            5352 5227 5275 -1 
            5227 5352 5304 -1 
            5227 5150 5275 -1 
            5227 5179 5102 -1 
            5150 5227 5102 -1 
            5477 5400 5525 -1 
            5400 5477 5352 -1 
            5400 5352 5275 -1 
            5256 5208 5131 -1 
            5208 5083 5131 -1 
            5083 5208 5160 -1 
            5208 5285 5160 -1 
            5285 5208 5333 -1 
            5208 5256 5333 -1 
            5141 5093 5016 -1 
            5093 5170 5045 -1 
            5093 5218 5170 -1 
            5218 5093 5141 -1 
            5064 5112 5189 -1 
            5112 5237 5189 -1 
            5237 5112 5160 -1 
            4968 4891 5016 -1 
            4891 4968 4843 -1 
            4968 5093 5045 -1 
            5093 4968 5016 -1 
            4036 4113 3988 -1 
            4113 4065 3988 -1 
            4113 4036 4161 -1 
            4113 4161 4238 -1 
            4065 4113 4238 -1 
            5265 5313 5390 -1 
            5255 5178 5303 -1 
            5207 5159 5082 -1 
            5159 5207 5284 -1 
            5130 5207 5082 -1 
            5255 5130 5178 -1 
            5130 5255 5207 -1 
            5428 5476 5553 -1 
            5601 5476 5524 -1 
            5476 5601 5553 -1 
            5332 5409 5284 -1 
            5207 5332 5284 -1 
            5255 5332 5207 -1 
            5332 5457 5409 -1 
            4908 4956 5033 -1 
            4956 4908 4831 -1 
            4908 4783 4831 -1 
            4783 4908 4860 -1 
            4360 4485 4437 -1 
            5321 5244 5369 -1 
            5321 5398 5273 -1 
            5302 5225 5350 -1 
            5225 5273 5350 -1 
            5302 5379 5254 -1 
            5379 5331 5254 -1 
            5379 5302 5427 -1 
            5158 5206 5283 -1 
            5331 5206 5254 -1 
            5206 5331 5283 -1 
            5206 5129 5254 -1 
            5322 5274 5197 -1 
            5447 5322 5370 -1 
            5389 5264 5312 -1 
            5293 5168 5216 -1 
            5495 5572 5447 -1 
            5495 5447 5370 -1 
            5418 5495 5370 -1 
            4870 4745 4793 -1 
            4745 4697 4620 -1 
            4947 4870 4995 -1 
            4947 5024 4899 -1 
            4937 4812 4860 -1 
            4889 4812 4937 -1 
            4274 4351 4226 -1 
            4340 4388 4465 -1 
            4148 4196 4273 -1 
            4513 4561 4638 -1 
            4561 4513 4436 -1 
            4513 4388 4436 -1 
            4388 4513 4465 -1 
            4782 4734 4657 -1 
            4734 4609 4657 -1 
            4369 4417 4494 -1 
            4542 4417 4465 -1 
            4417 4542 4494 -1 
            4417 4340 4465 -1 
            4417 4369 4292 -1 
            4340 4417 4292 -1 
            4321 4398 4273 -1 
            4196 4321 4273 -1 
            4446 4369 4494 -1 
            4446 4494 4571 -1 
            4321 4446 4398 -1 
            4446 4321 4369 -1 
            4494 4619 4571 -1 
            4542 4619 4494 -1 
            5397 5522 5474 -1 
            5647 5522 5570 -1 
            5032 4984 4907 -1 
            4965 5090 5042 -1 
            4004 4081 3956 -1 
            3879 4004 3956 -1 
            4206 4129 4254 -1 
            4129 4177 4254 -1 
            4129 4206 4081 -1 
            4177 4129 4052 -1 
            4004 4129 4081 -1 
            4129 4004 4052 -1 
            4581 4533 4456 -1 
            4504 4581 4456 -1 
            4475 4350 4398 -1 
            4398 4350 4273 -1 
            4427 4552 4504 -1 
            4427 4475 4552 -1 
            4427 4504 4379 -1 
            4427 4350 4475 -1 
            4302 4427 4379 -1 
            4350 4427 4302 -1 
            4312 4360 4437 -1 
            4360 4312 4235 -1 
            3889 4014 3966 -1 
            3937 4014 3889 -1 
            3812 3735 3860 -1 
            3937 3812 3860 -1 
            3812 3937 3889 -1 
            3966 3918 3841 -1 
            3918 3793 3841 -1 
            3687 3639 3562 -1 
            3812 3687 3735 -1 
            3658 3533 3581 -1 
            4053 4130 4005 -1 
            3928 4053 4005 -1 
            4178 4101 4226 -1 
            4130 4178 4255 -1 
            4178 4053 4101 -1 
            4053 4178 4130 -1 
            4381 4429 4506 -1 
            4602 4650 4727 -1 
            4650 4602 4525 -1 
            4650 4573 4698 -1 
            4573 4650 4525 -1 
            4832 4707 4755 -1 
            4601 4524 4649 -1 
            4726 4601 4649 -1 
            4601 4726 4678 -1 
            8389 8466 8341 -1 
            8389 8437 8466 -1 
            8437 8389 8312 -1 
            8264 8389 8341 -1 
            8389 8264 8312 -1 
            8293 8370 8245 -1 
            8437 8360 8485 -1 
            8360 8408 8485 -1 
            8360 8437 8312 -1 
            8274 8351 8226 -1 
            8351 8274 8399 -1 
            8418 8466 8495 -1 
            8370 8418 8495 -1 
            8466 8418 8341 -1 
            8418 8293 8341 -1 
            8293 8418 8370 -1 
            8101 8149 8226 -1 
            8149 8274 8226 -1 
            8274 8149 8197 -1 
            8197 8149 8072 -1 
            8216 8264 8341 -1 
            8293 8216 8341 -1 
            8072 7995 8120 -1 
            8139 8062 8187 -1 
            8139 8216 8091 -1 
            8264 8139 8187 -1 
            8216 8139 8264 -1 
            7678 7726 7803 -1 
            7726 7851 7803 -1 
            7601 7726 7678 -1 
            8303 8178 8226 -1 
            8178 8101 8226 -1 
            7928 7880 7803 -1 
            7880 7755 7803 -1 
            7755 7880 7832 -1 
            8101 8053 7976 -1 
            8053 7928 7976 -1 
            8178 8053 8101 -1 
            8484 8503 8494 -1 
            8494 8503 8504 -1 
            7877 7800 7925 -1 
            7877 7954 7829 -1 
            8002 7877 7925 -1 
            7954 7877 8002 -1 
            6369 6244 6292 -1 
            6244 6167 6292 -1 
            6119 6244 6196 -1 
            6167 6244 6119 -1 
            7244 7369 7321 -1 
            6571 6446 6494 -1 
            6571 6523 6446 -1 
            6946 6821 6869 -1 
            7042 7167 7119 -1 
            7167 7244 7119 -1 
            6619 6571 6494 -1 
            6571 6619 6696 -1 
            8456 8379 8504 -1 
            8273 8321 8398 -1 
            8321 8446 8398 -1 
            8446 8321 8369 -1 
            8302 8379 8254 -1 
            7793 7745 7668 -1 
            7620 7745 7697 -1 
            7745 7620 7668 -1 
            7543 7620 7495 -1 
            7620 7543 7668 -1 
            7418 7543 7495 -1 
            6034 5986 5909 -1 
            5986 5938 5861 -1 
            5909 5986 5861 -1 
            5986 6063 5938 -1 
            6063 5986 6111 -1 
            5986 6034 6111 -1 
            5736 5784 5861 -1 
            5813 5736 5861 -1 
            5736 5813 5688 -1 
            5544 5621 5496 -1 
            5621 5669 5746 -1 
            5544 5669 5621 -1 
            5813 5890 5765 -1 
            5890 5842 5765 -1 
            5890 5813 5938 -1 
            5890 5938 6015 -1 
            5870 5793 5918 -1 
            5793 5841 5918 -1 
            5841 5764 5889 -1 
            6014 5966 5889 -1 
            5966 5841 5889 -1 
            5841 5966 5918 -1 
            6717 6794 6669 -1 
            6592 6717 6669 -1 
            6650 6698 6775 -1 
            6698 6823 6775 -1 
            7101 7178 7053 -1 
            7178 7101 7226 -1 
            7014 6966 6889 -1 
            6947 7024 6899 -1 
            7072 6947 6995 -1 
            6947 7072 7024 -1 
            6803 6928 6880 -1 
            6736 6784 6861 -1 
            6784 6736 6659 -1 
            7332 7207 7255 -1 
            7207 7159 7082 -1 
            6957 7005 7082 -1 
            7005 6928 7053 -1 
            7005 6957 6880 -1 
            6928 7005 6880 -1 
            6957 6832 6880 -1 
            6832 6784 6707 -1 
            6909 6986 6861 -1 
            6909 6832 6957 -1 
            6784 6909 6861 -1 
            6832 6909 6784 -1 
            7159 7034 7082 -1 
            7034 6957 7082 -1 
            7034 6909 6957 -1 
            6909 7034 6986 -1 
            6890 7015 6967 -1 
            7611 7563 7486 -1 
            7909 7784 7832 -1 
            7707 7784 7659 -1 
            7784 7707 7832 -1 
            7409 7486 7361 -1 
            7457 7409 7332 -1 
            7169 7342 7121 -1 
            7169 7217 7342 -1 
            7217 7169 7092 -1 
            7044 7169 7121 -1 
            7092 7169 7044 -1 
            7486 7438 7361 -1 
            7390 7438 7515 -1 
            7438 7563 7515 -1 
            7563 7438 7486 -1 
            7217 7265 7342 -1 
            7265 7390 7342 -1 
            7265 7217 7140 -1 
            7630 7582 7505 -1 
            7582 7630 7707 -1 
            7630 7755 7707 -1 
            7755 7630 7678 -1 
            7582 7534 7457 -1 
            7409 7534 7486 -1 
            7534 7409 7457 -1 
            7534 7611 7486 -1 
            7611 7534 7659 -1 
            7534 7582 7659 -1 
            7236 7284 7361 -1 
            7284 7409 7361 -1 
            7409 7284 7332 -1 
            7284 7207 7332 -1 
            7284 7236 7159 -1 
            7207 7284 7159 -1 
            7457 7380 7505 -1 
            7380 7428 7505 -1 
            7380 7457 7332 -1 
            7380 7332 7255 -1 
            7601 7553 7476 -1 
            7428 7553 7505 -1 
            7553 7428 7476 -1 
            7553 7630 7505 -1 
            7553 7601 7678 -1 
            7630 7553 7678 -1 
            7620 7572 7495 -1 
            7572 7620 7697 -1 
            6246 6169 6294 -1 
            6602 6775 6554 -1 
            6602 6650 6775 -1 
            5689 5737 5814 -1 
            5737 5689 5612 -1 
            5641 5814 5593 -1 
            5641 5689 5814 -1 
            5641 5564 5689 -1 
            5910 5862 5785 -1 
            5862 5737 5785 -1 
            5737 5862 5814 -1 
            5814 5862 5987 -1 
            5862 5910 5987 -1 
            5516 5593 5468 -1 
            5564 5516 5439 -1 
            5516 5641 5593 -1 
            5641 5516 5564 -1 
            5391 5516 5468 -1 
            5439 5516 5391 -1 
            6985 6937 6860 -1 
            6812 6937 6889 -1 
            6937 6812 6860 -1 
            6188 6265 6140 -1 
            6265 6217 6140 -1 
            6265 6188 6313 -1 
            6044 6092 6169 -1 
            6217 6092 6140 -1 
            6092 6217 6169 -1 
            6140 6092 6015 -1 
            8001 8049 8126 -1 
            8049 8174 8126 -1 
            8049 8097 8174 -1 
            8049 7972 8097 -1 
            2715 2667 2590 -1 
            2638 2715 2590 -1 
            2715 2638 2763 -1 
            2667 2715 2792 -1 
            2388 2436 2513 -1 
            2436 2388 2311 -1 
            2436 2561 2513 -1 
            2561 2436 2484 -1 
            2561 2638 2513 -1 
            2638 2561 2686 -1 
            2657 2532 2580 -1 
            2042 2119 1994 -1 
            2042 1965 2090 -1 
            1917 2042 1994 -1 
            2042 1917 1965 -1 
            2215 2167 2090 -1 
            2167 2042 2090 -1 
            2042 2167 2119 -1 
            2119 2167 2244 -1 
            2167 2292 2244 -1 
            2292 2167 2215 -1 
            2119 2071 1994 -1 
            1946 2071 2023 -1 
            2071 1946 1994 -1 
            2071 2148 2023 -1 
            2359 2436 2311 -1 
            2436 2359 2484 -1 
            2301 2349 2426 -1 
            2349 2272 2397 -1 
            2349 2301 2224 -1 
            2272 2349 2224 -1 
            2253 2330 2205 -1 
            2378 2301 2426 -1 
            2253 2378 2330 -1 
            2378 2253 2301 -1 
            1888 1936 2013 -1 
            1936 2061 2013 -1 
            2061 1936 1984 -1 
            1917 1840 1965 -1 
            1840 1888 1965 -1 
            2839 2762 2887 -1 
            2522 2474 2397 -1 
            2474 2349 2397 -1 
            2349 2474 2426 -1 
            2474 2551 2426 -1 
            2474 2522 2599 -1 
            2551 2474 2599 -1 
            2206 2129 2254 -1 
            2658 2581 2706 -1 
            2648 2696 2773 -1 
            2571 2648 2523 -1 
            2648 2571 2696 -1 
            2591 2514 2639 -1 
            2591 2668 2543 -1 
            2466 2389 2514 -1 
            2418 2466 2543 -1 
            2591 2466 2514 -1 
            2466 2591 2543 -1 
            2312 2187 2235 -1 
            2312 2264 2187 -1 
            2312 2437 2389 -1 
            2264 2312 2389 -1 
            3187 3139 3062 -1 
            3158 3110 3033 -1 
            3110 2985 3033 -1 
            2985 3110 3062 -1 
            3110 3187 3062 -1 
            3110 3158 3235 -1 
            3187 3110 3235 -1 
            2937 2985 3062 -1 
            2985 2937 2860 -1 
            2812 2937 2889 -1 
            2937 2812 2860 -1 
            2735 2783 2860 -1 
            2812 2735 2860 -1 
            2687 2735 2812 -1 
            2735 2658 2783 -1 
            2658 2735 2610 -1 
            2735 2687 2610 -1 
            2754 2831 2706 -1 
            2879 2754 2802 -1 
            2754 2879 2831 -1 
            3331 3379 3456 -1 
            3379 3504 3456 -1 
            3504 3581 3456 -1 
            3552 3475 3600 -1 
            3081 3158 3033 -1 
            3254 3177 3302 -1 
            3379 3254 3302 -1 
            3331 3254 3379 -1 
            3495 3543 3620 -1 
            3543 3668 3620 -1 
            3668 3543 3591 -1 
            3763 3686 3811 -1 
            3859 3936 3811 -1 
            3859 3782 3907 -1 
            3984 3859 3907 -1 
            3936 3859 3984 -1 
            3763 3840 3715 -1 
            3840 3792 3715 -1 
            3840 3763 3888 -1 
            3792 3869 3744 -1 
            3657 3705 3782 -1 
            3753 3705 3628 -1 
            3705 3580 3628 -1 
            3580 3705 3657 -1 
            3580 3503 3628 -1 
            3330 3455 3407 -1 
            3580 3455 3503 -1 
            3215 3167 3090 -1 
            3215 3263 3340 -1 
            2869 2821 2744 -1 
            2696 2821 2773 -1 
            2744 2821 2696 -1 
            3167 3042 3090 -1 
            3927 3850 3975 -1 
            3927 4004 3879 -1 
            3927 3879 3802 -1 
            3850 3927 3802 -1 
            4052 3927 3975 -1 
            4004 3927 4052 -1 
            3648 3696 3773 -1 
            3696 3619 3744 -1 
            3533 3408 3456 -1 
            3408 3331 3456 -1 
            3677 3725 3802 -1 
            3725 3850 3802 -1 
            3850 3725 3773 -1 
            3725 3648 3773 -1 
            3725 3677 3600 -1 
            3648 3725 3600 -1 
            3570 3618 3695 -1 
            3445 3320 3368 -1 
            3320 3445 3397 -1 
            3253 3330 3205 -1 
            3224 3176 3099 -1 
            7954 8079 8031 -1 
            8079 7954 8002 -1 
            8021 7944 8069 -1 
            8050 8002 7925 -1 
            8050 8098 8175 -1 
            8098 8223 8175 -1 
            8175 8223 8300 -1 
            8348 8223 8271 -1 
            8223 8348 8300 -1 
            8146 8021 8069 -1 
            8021 8146 8098 -1 
            8223 8146 8271 -1 
            8146 8223 8098 -1 
            8454 8406 8329 -1 
            8281 8406 8358 -1 
            8406 8281 8329 -1 
            8406 8483 8358 -1 
            8406 8454 8483 -1 
            7935 7887 7810 -1 
            8329 8204 8252 -1 
            8281 8204 8329 -1 
            8435 8310 8358 -1 
            7868 7743 7791 -1 
            8415 8338 8463 -1 
            8415 8463 8492 -1 
            8367 8415 8492 -1 
            8415 8367 8290 -1 
            8338 8415 8290 -1 
            8348 8425 8300 -1 
            8425 8377 8300 -1 
            8377 8425 8502 -1 
            345 297 220 -1 
            297 172 220 -1 
            172 297 249 -1 
            249 297 374 -1 
            297 422 374 -1 
            297 345 422 -1 
            57 134 9 -1 
            28 57 9 -1 
            105 57 28 -1 
            230 355 307 -1 
            124 249 201 -1 
            124 172 249 -1 
            76 124 201 -1 
            124 76 47 -1 
            172 124 47 -1 
            633 758 710 -1 
            585 633 710 -1 
            556 633 508 -1 
            633 585 508 -1 
            691 643 566 -1 
            518 643 595 -1 
            643 518 566 -1 
            643 720 595 -1 
            643 768 720 -1 
            768 643 691 -1 
            614 691 566 -1 
            614 537 662 -1 
            739 614 662 -1 
            691 614 739 -1 
            614 489 537 -1 
            489 614 566 -1 
            470 595 547 -1 
            470 518 595 -1 
            422 470 547 -1 
            518 470 393 -1 
            470 345 393 -1 
            345 470 422 -1 
            2237 2189 2112 -1 
            2160 2208 2285 -1 
            2237 2160 2285 -1 
            2160 2237 2112 -1 
            2160 2083 2208 -1 
            2016 2064 2141 -1 
            2189 2064 2112 -1 
            2064 2189 2141 -1 
            2064 1987 2112 -1 
            1939 2016 1891 -1 
            2064 1939 1987 -1 
            1939 2064 2016 -1 
            2449 2574 2526 -1 
            2593 2516 2641 -1 
            2574 2497 2622 -1 
            2497 2574 2449 -1 
            2670 2593 2718 -1 
            1901 1853 1776 -1 
            1584 1661 1536 -1 
            1661 1709 1786 -1 
            1584 1709 1661 -1 
            2938 2986 3063 -1 
            2813 2938 2890 -1 
            2986 3111 3063 -1 
            3111 3188 3063 -1 
            3111 3159 3236 -1 
            3188 3111 3236 -1 
            3207 3284 3159 -1 
            2621 2573 2496 -1 
            2573 2621 2698 -1 
            2688 2563 2611 -1 
            2371 2419 2496 -1 
            2294 2419 2371 -1 
            2544 2467 2592 -1 
            2544 2621 2496 -1 
            2419 2544 2496 -1 
            2544 2419 2467 -1 
            2448 2371 2496 -1 
            2448 2525 2400 -1 
            2573 2448 2496 -1 
            2525 2448 2573 -1 
            2352 2429 2304 -1 
            2227 2352 2304 -1 
            2448 2323 2371 -1 
            2323 2448 2400 -1 
            2554 2506 2429 -1 
            2554 2631 2506 -1 
            2285 2410 2362 -1 
            2535 2458 2583 -1 
            2458 2506 2583 -1 
            2410 2458 2535 -1 
            2525 2477 2400 -1 
            2352 2477 2429 -1 
            2477 2352 2400 -1 
            2477 2554 2429 -1 
            2477 2525 2602 -1 
            2554 2477 2602 -1 
            2265 2217 2140 -1 
            2169 2217 2294 -1 
            2044 2169 2121 -1 
            2044 1919 1967 -1 
            1967 1919 1842 -1 
            1919 1871 1794 -1 
            1842 1919 1794 -1 
            2467 2390 2515 -1 
            2390 2265 2313 -1 
            2399 2351 2274 -1 
            2726 2649 2774 -1 
            2851 2726 2774 -1 
            2486 2534 2611 -1 
            2409 2534 2486 -1 
            1823 1746 1871 -1 
            1823 1871 1948 -1 
            1746 1823 1698 -1 
            1823 1775 1698 -1 
            104 229 181 -1 
            152 229 104 -1 
            229 152 277 -1 
            181 229 306 -1 
            219 171 94 -1 
            171 46 94 -1 
            171 123 46 -1 
            123 171 248 -1 
            248 171 296 -1 
            171 219 296 -1 
            335 258 383 -1 
            335 412 287 -1 
            210 335 287 -1 
            258 335 210 -1 
            460 383 508 -1 
            412 460 537 -1 
            460 335 383 -1 
            335 460 412 -1 
            585 460 508 -1 
            460 585 537 -1 
            34 63 15 -1 
            111 63 34 -1 
            159 111 34 -1 
            159 236 111 -1 
            775 698 823 -1 
            650 775 727 -1 
            775 650 698 -1 
            698 746 823 -1 
            718 843 795 -1 
            843 718 766 -1 
            641 718 593 -1 
            718 641 766 -1 
            622 747 699 -1 
            699 747 824 -1 
            506 554 631 -1 
            74 45 122 -1 
            45 74 26 -1 
            74 151 26 -1 
            141 93 16 -1 
            93 45 16 -1 
            55 132 7 -1 
            132 55 180 -1 
            26 55 7 -1 
            349 426 301 -1 
            205 80 128 -1 
            3 80 32 -1 
            128 80 3 -1 
            676 628 551 -1 
            273 398 350 -1 
            24 43 72 -1 
            168 43 91 -1 
            43 24 14 -1 
            91 43 14 -1 
            168 293 245 -1 
            149 24 72 -1 
            33 62 14 -1 
            33 81 158 -1 
            81 33 4 -1 
            543 495 418 -1 
            754 677 802 -1 
            1061 1013 936 -1 
            888 1013 965 -1 
            1013 888 936 -1 
            888 811 936 -1 
            744 821 696 -1 
            792 744 667 -1 
            571 619 696 -1 
            744 619 667 -1 
            619 744 696 -1 
            619 571 494 -1 
            1071 1148 1023 -1 
            1138 1013 1061 -1 
            1619 1542 1667 -1 
            1619 1494 1542 -1 
            1975 1898 2023 -1 
            1975 1850 1898 -1 
            1620 1697 1572 -1 
            2187 2062 2110 -1 
            1369 1494 1446 -1 
            1225 1100 1148 -1 
            1148 1100 1023 -1 
            783 860 735 -1 
            978 901 1026 -1 
            978 853 901 -1 
            978 1103 1055 -1 
            1103 978 1026 -1 
            872 920 997 -1 
            949 872 997 -1 
            872 949 824 -1 
            920 872 795 -1 
            747 872 824 -1 
            872 747 795 -1 
            1670 1795 1747 -1 
            1853 1728 1776 -1 
            1362 1237 1285 -1 
            1410 1362 1285 -1 
            1362 1410 1487 -1 
            1535 1583 1660 -1 
            1583 1535 1458 -1 
            1410 1535 1487 -1 
            1535 1410 1458 -1 
            1506 1583 1458 -1 
            1506 1429 1554 -1 
            1429 1506 1381 -1 
            1506 1458 1381 -1 
            1631 1506 1554 -1 
            1506 1631 1583 -1 
            1151 1199 1276 -1 
            1247 1199 1122 -1 
            1199 1074 1122 -1 
            1199 1151 1074 -1 
            1170 1247 1122 -1 
            1045 1170 1122 -1 
            1093 1170 1045 -1 
            73 198 150 -1 
            35 64 16 -1 
            64 35 112 -1 
            64 141 16 -1 
            64 189 141 -1 
            189 64 112 -1 
            237 189 112 -1 
            160 237 112 -1 
            237 160 285 -1 
            362 237 285 -1 
            1371 1419 1496 -1 
            1544 1419 1467 -1 
            1419 1544 1496 -1 
            1419 1342 1467 -1 
            1784 1832 1909 -1 
            1592 1669 1544 -1 
            1592 1544 1467 -1 
            1765 1890 1842 -1 
            1890 1967 1842 -1 
            1890 1938 2015 -1 
            1967 1890 2015 -1 
            1736 1813 1688 -1 
            1813 1765 1688 -1 
            1890 1813 1938 -1 
            1813 1890 1765 -1 
            1525 1650 1602 -1 
            1573 1650 1525 -1 
            1650 1573 1698 -1 
            1775 1650 1698 -1 
            1400 1352 1275 -1 
            1304 1352 1429 -1 
            1429 1352 1477 -1 
            1352 1400 1477 -1 
            756 833 708 -1 
            804 756 679 -1 
            833 756 881 -1 
            756 804 881 -1 
            756 708 631 -1 
            679 756 631 -1 
            535 583 660 -1 
            708 583 631 -1 
            660 583 708 -1 
            583 506 631 -1 
            583 535 458 -1 
            506 583 458 -1 
            354 431 306 -1 
            402 354 277 -1 
            229 354 306 -1 
            354 229 277 -1 
            527 479 402 -1 
            354 479 431 -1 
            479 354 402 -1 
            479 556 431 -1 
            556 479 604 -1 
            479 527 604 -1 
            575 527 450 -1 
            498 575 450 -1 
            575 498 623 -1 
            575 652 527 -1 
            719 642 767 -1 
            671 719 796 -1 
            719 671 594 -1 
            642 719 594 -1 
            719 844 796 -1 
            844 719 767 -1 
            488 440 363 -1 
            315 440 392 -1 
            440 315 363 -1 
            440 517 392 -1 
            517 440 565 -1 
            440 488 565 -1 
            459 411 334 -1 
            411 363 286 -1 
            334 411 286 -1 
            411 488 363 -1 
            488 411 536 -1 
            411 459 536 -1 
            507 584 459 -1 
            507 430 555 -1 
            430 507 382 -1 
            507 459 382 -1 
            680 728 805 -1 
            603 680 555 -1 
            680 603 728 -1 
            757 680 805 -1 
            584 661 536 -1 
            661 786 738 -1 
            652 700 777 -1 
            700 825 777 -1 
            825 700 748 -1 
            748 700 623 -1 
            700 575 623 -1 
            575 700 652 -1 
            1306 1258 1181 -1 
            1229 1306 1181 -1 
            1306 1383 1258 -1 
            1383 1335 1258 -1 
            1210 1335 1287 -1 
            1335 1210 1258 -1 
            1239 1191 1114 -1 
            1210 1162 1085 -1 
            1162 1037 1085 -1 
            1037 1162 1114 -1 
            1162 1239 1114 -1 
            1162 1210 1287 -1 
            1239 1162 1287 -1 
            969 844 892 -1 
            1152 1075 1200 -1 
            1075 1123 1200 -1 
            1075 1152 1027 -1 
            1123 1075 998 -1 
            950 1075 1027 -1 
            998 1075 950 -1 
            1459 1584 1536 -1 
            2293 2245 2168 -1 
            2245 2120 2168 -1 
            2120 2245 2197 -1 
            2216 2293 2168 -1 
            2091 2216 2168 -1 
            2341 2264 2389 -1 
            2293 2341 2418 -1 
            2341 2216 2264 -1 
            2216 2341 2293 -1 
            2466 2341 2389 -1 
            2341 2466 2418 -1 
            2149 2072 2197 -1 
            2072 2120 2197 -1 
            2072 2149 2024 -1 
            2034 1986 1909 -1 
            2053 2130 2005 -1 
            2101 2053 1976 -1 
            2178 2053 2101 -1 
            2053 2178 2130 -1 
            2620 2668 2745 -1 
            2697 2620 2745 -1 
            2620 2697 2572 -1 
            2668 2620 2543 -1 
            2495 2620 2572 -1 
            2543 2620 2495 -1 
            2726 2601 2649 -1 
            2428 2553 2505 -1 
            2841 2716 2764 -1 
            2764 2716 2639 -1 
            2716 2591 2639 -1 
            2591 2716 2668 -1 
            2966 3091 3043 -1 
            3091 3168 3043 -1 
            2870 2822 2745 -1 
            2697 2822 2774 -1 
            2822 2697 2745 -1 
            2822 2899 2774 -1 
            2899 2822 2947 -1 
            2822 2870 2947 -1 
            2793 2841 2918 -1 
            2870 2793 2918 -1 
            2793 2870 2745 -1 
            2793 2716 2841 -1 
            2668 2793 2745 -1 
            2716 2793 2668 -1 
            3122 3045 3170 -1 
            3045 2920 2968 -1 
            4006 3929 4054 -1 
            3929 3977 4054 -1 
            4265 4188 4313 -1 
            4111 4188 4063 -1 
            4159 4111 4034 -1 
            3986 4111 4063 -1 
            4034 4111 3986 -1 
            4207 4130 4255 -1 
            4207 4284 4159 -1 
            4082 4159 4034 -1 
            4130 4082 4005 -1 
            4207 4082 4130 -1 
            4082 4207 4159 -1 
            3304 3429 3381 -1 
            3429 3304 3352 -1 
            3948 3871 3996 -1 
            2881 2756 2804 -1 
            2718 2766 2843 -1 
            2766 2718 2641 -1 
            3256 3304 3381 -1 
            3880 3928 4005 -1 
            3803 3880 3755 -1 
            3928 3880 3803 -1 
            3851 3928 3803 -1 
            3678 3726 3803 -1 
            3851 3726 3774 -1 
            3726 3851 3803 -1 
            3726 3649 3774 -1 
            3400 3323 3448 -1 
            2746 2794 2871 -1 
            2621 2746 2698 -1 
            2794 2919 2871 -1 
            3217 3092 3140 -1 
            3169 3092 3217 -1 
            3265 3342 3217 -1 
            3265 3188 3313 -1 
            3265 3217 3140 -1 
            3188 3265 3140 -1 
            3294 3342 3419 -1 
            3342 3294 3217 -1 
            3294 3169 3217 -1 
            3169 3294 3246 -1 
            3342 3467 3419 -1 
            3438 3361 3486 -1 
            3438 3313 3361 -1 
            3553 3601 3678 -1 
            3726 3601 3649 -1 
            3601 3726 3678 -1 
            3601 3524 3649 -1 
            3505 3553 3630 -1 
            3553 3505 3428 -1 
            3476 3553 3428 -1 
            3476 3601 3553 -1 
            3601 3476 3524 -1 
            1661 1613 1536 -1 
            1469 1546 1421 -1 
            1402 1450 1527 -1 
            1450 1575 1527 -1 
            1325 1450 1402 -1 
            1430 1305 1353 -1 
            1401 1353 1276 -1 
            1401 1449 1526 -1 
            1449 1372 1497 -1 
            1228 1305 1180 -1 
            1228 1151 1276 -1 
            1353 1228 1276 -1 
            1305 1228 1353 -1 
            1228 1180 1103 -1 
            1151 1228 1103 -1 
            2219 2267 2344 -1 
            2267 2392 2344 -1 
            2392 2267 2315 -1 
            2315 2267 2190 -1 
            2334 2459 2411 -1 
            2334 2209 2257 -1 
            2709 2632 2757 -1 
            2805 2882 2757 -1 
            2603 2478 2526 -1 
            2526 2478 2401 -1 
            2478 2353 2401 -1 
            2353 2478 2430 -1 
            3026 2901 2949 -1 
            2978 2901 3026 -1 
            2699 2574 2622 -1 
            2440 2565 2517 -1 
            2440 2488 2565 -1 
            2392 2440 2517 -1 
            2488 2440 2363 -1 
            2440 2392 2315 -1 
            2363 2440 2315 -1 
            2324 2372 2449 -1 
            2497 2372 2420 -1 
            2372 2497 2449 -1 
            2161 2238 2113 -1 
            2036 2161 2113 -1 
            2084 2161 2036 -1 
            2084 2132 2209 -1 
            2161 2084 2209 -1 
            1057 1105 1182 -1 
            1153 1105 1028 -1 
            1105 980 1028 -1 
            1105 1057 980 -1 
            653 730 605 -1 
            528 653 605 -1 
            653 701 778 -1 
            730 653 778 -1 
            807 932 884 -1 
            730 807 682 -1 
            634 586 509 -1 
            586 538 461 -1 
            509 586 461 -1 
            1585 1460 1508 -1 
            1460 1383 1508 -1 
            1460 1335 1383 -1 
            922 797 845 -1 
            720 797 672 -1 
            797 720 845 -1 
            941 1018 893 -1 
            1547 1595 1672 -1 
            1720 1595 1643 -1 
            1672 1595 1720 -1 
            1595 1518 1643 -1 
            1230 1105 1153 -1 
            1105 1230 1182 -1 
            1182 1230 1355 -1 
            1883 1931 2008 -1 
            1931 2056 2008 -1 
            2056 1931 1979 -1 
            1979 1931 1854 -1 
            1681 1758 1633 -1 
            1864 1787 1912 -1 
            1383 1431 1508 -1 
            1431 1556 1508 -1 
            1306 1431 1383 -1 
            1575 1623 1700 -1 
            1719 1594 1642 -1 
            1594 1517 1642 -1 
            1594 1469 1517 -1 
            1469 1594 1546 -1 
            2094 2142 2219 -1 
            2267 2142 2190 -1 
            2142 2267 2219 -1 
            2123 2200 2075 -1 
            2123 2248 2200 -1 
            3248 3123 3171 -1 
            2950 3027 2902 -1 
            2950 2873 2998 -1 
            2825 2950 2902 -1 
            2950 2825 2873 -1 
            3027 2979 2902 -1 
            2854 2979 2931 -1 
            2979 2854 2902 -1 
            3104 2979 3027 -1 
            2979 3104 2931 -1 
            3104 3277 2710 -1 
            2931 3104 2710 -1 
            3094 3046 2969 -1 
            2921 3046 2998 -1 
            3046 2921 2969 -1 
            3046 3123 2998 -1 
            3046 3094 3171 -1 
            3123 3046 3171 -1 
            3267 3392 3344 -1 
            2863 2786 2911 -1 
            2863 2738 2786 -1 
            2738 2863 2815 -1 
            2988 3113 3065 -1 
            3113 2988 3036 -1 
            3036 2988 2911 -1 
            2988 2863 2911 -1 
            2892 3017 2969 -1 
            3017 3094 2969 -1 
            2585 2508 2633 -1 
            2508 2556 2633 -1 
            2508 2585 2460 -1 
            2556 2508 2431 -1 
            2383 2508 2460 -1 
            2508 2383 2431 -1 
            2604 2556 2479 -1 
            2748 2671 2796 -1 
            2748 2825 2700 -1 
            2748 2796 2873 -1 
            2825 2748 2873 -1 
            2575 2623 2700 -1 
            2623 2748 2700 -1 
            2748 2623 2671 -1 
            2671 2623 2546 -1 
            2623 2498 2546 -1 
            2623 2575 2498 -1 
            3383 3335 3258 -1 
            3335 3383 3460 -1 
            3412 3335 3460 -1 
            2768 2893 2845 -1 
            2691 2768 2643 -1 
            3133 3181 3258 -1 
            3181 3056 3229 -1 
            3181 3133 3056 -1 
            3335 3210 3258 -1 
            3210 3133 3258 -1 
            3133 3210 3085 -1 
            2595 2547 2470 -1 
            2518 2595 2470 -1 
            2595 2518 2643 -1 
            2547 2595 2672 -1 
            2441 2268 2489 -1 
            2441 2393 2268 -1 
            2393 2441 2518 -1 
            2566 2441 2489 -1 
            2518 2441 2566 -1 
            2451 2326 2374 -1 
            2691 2614 2739 -1 
            2614 2662 2739 -1 
            2614 2691 2566 -1 
            2614 2566 2489 -1 
            2662 2614 2489 -1 
            3480 3605 3557 -1 
            3605 3653 3730 -1 
            3653 3778 3730 -1 
            3874 3826 3749 -1 
            3826 3874 3951 -1 
            3566 3614 3691 -1 
            3643 3566 3691 -1 
            3566 3643 3518 -1 
            3614 3566 3489 -1 
            3768 3691 3816 -1 
            3768 3643 3691 -1 
            3643 3768 3720 -1 
            3768 3845 3720 -1 
            3500 3452 3375 -1 
            3452 3327 3375 -1 
            3327 3452 3404 -1 
            3452 3529 3404 -1 
            3001 2953 2876 -1 
            3097 3020 3145 -1 
            3020 3068 3145 -1 
            2645 2693 2770 -1 
            2568 2693 2645 -1 
            2520 2568 2645 -1 
            2568 2520 2443 -1 
            2520 2395 2443 -1 
            2395 2520 2472 -1 
            2664 2587 2712 -1 
            2664 2539 2587 -1 
            2674 2626 2549 -1 
            2674 2751 2626 -1 
            2453 2578 2530 -1 
            2578 2626 2703 -1 
            2847 2799 2722 -1 
            2799 2674 2722 -1 
            2674 2799 2751 -1 
            2751 2799 2876 -1 
            2971 2923 2846 -1 
            3154 3077 3202 -1 
            3154 3202 3279 -1 
            3154 3106 3029 -1 
            3077 3154 3029 -1 
            2904 2952 3029 -1 
            2808 2933 2885 -1 
            2933 2808 2856 -1 
            2923 2798 2846 -1 
            2798 2923 2875 -1 
            2779 2904 2856 -1 
            2922 2970 3047 -1 
            3095 2970 3018 -1 
            2970 3095 3047 -1 
            2970 2893 3018 -1 
            2893 2970 2845 -1 
            2970 2922 2845 -1 
            2951 2999 3076 -1 
            3124 2999 3047 -1 
            2999 3124 3076 -1 
            2999 2922 3047 -1 
            2922 2999 2874 -1 
            2999 2951 2874 -1 
            3057 3009 2932 -1 
            2980 3057 2932 -1 
            3057 2980 3105 -1 
            3057 3134 3009 -1 
            3182 3057 3105 -1 
            3134 3057 3182 -1 
            2855 2980 2932 -1 
            3163 3288 3240 -1 
            3240 3288 3365 -1 
            3634 3509 3557 -1 
            3509 3432 3557 -1 
            3432 3509 3384 -1 
            3509 3461 3384 -1 
            3461 3413 3336 -1 
            3288 3413 3365 -1 
            3413 3288 3336 -1 
            3538 3413 3461 -1 
            3355 3307 3230 -1 
            3182 3307 3259 -1 
            3307 3182 3230 -1 
            3307 3384 3259 -1 
            3307 3432 3384 -1 
            3307 3355 3432 -1 
            3403 3278 3326 -1 
            3403 3355 3278 -1 
            3355 3403 3480 -1 
            2414 2539 2491 -1 
            2376 2328 2251 -1 
            2328 2376 2453 -1 
            2347 2424 2299 -1 
            2424 2376 2299 -1 
            2424 2347 2472 -1 
            2549 2424 2472 -1 
            1895 1972 1847 -1 
            2769 2894 2846 -1 
            2894 2971 2846 -1 
            2894 2942 3019 -1 
            2971 2894 3019 -1 
            1991 2068 1943 -1 
            2010 1885 1933 -1 
            1913 1836 1961 -1 
            1836 1913 1788 -1 
            3038 2961 3086 -1 
            3163 3038 3086 -1 
            3038 2990 2913 -1 
            2961 3038 2913 -1 
            3733 3781 3858 -1 
            3906 3781 3829 -1 
            3858 3781 3906 -1 
            3781 3704 3829 -1 
            3714 3637 3762 -1 
            3589 3637 3714 -1 
            3464 3512 3589 -1 
            3512 3637 3589 -1 
            3637 3512 3560 -1 
            3416 3368 3291 -1 
            3416 3464 3541 -1 
            3339 3416 3291 -1 
            3416 3339 3464 -1 
            3050 3175 3127 -1 
            3175 3252 3127 -1 
            3175 3300 3252 -1 
            3300 3175 3223 -1 
            2705 2657 2580 -1 
            2781 2858 2733 -1 
            2858 2781 2906 -1 
            2983 2858 2906 -1 
            2983 3031 3108 -1 
            3079 3031 2954 -1 
            3031 2906 2954 -1 
            3031 2983 2906 -1 
            3060 2983 3108 -1 
            3281 3156 3204 -1 
            3204 3156 3079 -1 
            3031 3156 3108 -1 
            3156 3031 3079 -1 
            3156 3233 3108 -1 
            3233 3156 3281 -1 
            3195 3320 3272 -1 
            3195 3070 3118 -1 
            1811 1936 1888 -1 
            1542 1590 1667 -1 
            1609 1734 1686 -1 
            1734 1811 1686 -1 
            1561 1609 1686 -1 
            2109 2234 2186 -1 
            2157 2234 2109 -1 
            2186 2234 2311 -1 
            2234 2359 2311 -1 
            2224 2099 2147 -1 
            2080 2128 2205 -1 
            2128 2253 2205 -1 
            1955 2003 2080 -1 
            2003 2128 2080 -1 
            2128 2003 2051 -1 
            2051 2003 1926 -1 
            2003 1878 1926 -1 
            2003 1955 1878 -1 
            2925 2877 2800 -1 
            2877 2752 2800 -1 
            2752 2877 2829 -1 
            2829 2877 2954 -1 
            3002 2925 3050 -1 
            3002 3079 2954 -1 
            2877 3002 2954 -1 
            3002 2877 2925 -1 
            3079 3002 3127 -1 
            3002 3050 3127 -1 
            2541 2493 2416 -1 
            2493 2368 2416 -1 
            2368 2493 2445 -1 
            2839 2714 2762 -1 
            2637 2560 2685 -1 
            2560 2637 2512 -1 
            2762 2637 2685 -1 
            2714 2637 2762 -1 
            2589 2464 2512 -1 
            2589 2541 2464 -1 
            2637 2589 2512 -1 
            2589 2637 2714 -1 
            1070 945 993 -1 
            1022 945 1070 -1 
            772 695 820 -1 
            1088 1011 1136 -1 
            1213 1088 1136 -1 
            174 49 97 -1 
            222 174 97 -1 
            481 433 356 -1 
            356 433 308 -1 
            433 385 308 -1 
            433 481 558 -1 
            529 404 452 -1 
            529 481 404 -1 
            577 529 452 -1 
            529 606 481 -1 
            462 414 337 -1 
            414 289 337 -1 
            414 366 289 -1 
            366 414 491 -1 
            414 539 491 -1 
            414 462 539 -1 
            510 587 462 -1 
            510 433 558 -1 
            635 510 558 -1 
            587 510 635 -1 
            510 462 385 -1 
            433 510 385 -1 
            587 664 539 -1 
            224 176 99 -1 
            176 51 99 -1 
            51 176 128 -1 
            176 224 301 -1 
            224 272 349 -1 
            272 397 349 -1 
            397 272 320 -1 
            320 272 195 -1 
            272 147 195 -1 
            272 224 147 -1 
            41 89 166 -1 
            89 137 214 -1 
            166 89 214 -1 
            137 89 12 -1 
            89 41 12 -1 
            2675 2598 2723 -1 
            2675 2752 2627 -1 
            2675 2723 2800 -1 
            2752 2675 2800 -1 
            2502 2550 2627 -1 
            2550 2675 2627 -1 
            2675 2550 2598 -1 
            2598 2646 2723 -1 
            2646 2569 2694 -1 
            2454 2502 2579 -1 
            2531 2454 2579 -1 
            2502 2454 2377 -1 
            2454 2329 2377 -1 
            2483 2560 2435 -1 
            2483 2531 2608 -1 
            2560 2483 2608 -1 
            2387 2339 2262 -1 
            2214 2339 2291 -1 
            2339 2214 2262 -1 
            2339 2416 2291 -1 
            2339 2464 2416 -1 
            2339 2387 2464 -1 
            2012 1887 1935 -1 
            1887 1810 1935 -1 
            1839 1916 1791 -1 
            1753 1830 1705 -1 
            1753 1878 1830 -1 
            1253 1301 1378 -1 
            1301 1224 1349 -1 
            1157 1282 1234 -1 
            1810 1762 1685 -1 
            1714 1762 1839 -1 
            1887 1762 1810 -1 
            1762 1887 1839 -1 
            1637 1714 1589 -1 
            1685 1637 1560 -1 
            1762 1637 1685 -1 
            1637 1762 1714 -1 
            1906 1858 1781 -1 
            1858 1906 1983 -1 
            1906 2031 1983 -1 
            2031 1906 1954 -1 
            2031 2156 2108 -1 
            2108 2156 2233 -1 
            2281 2204 2329 -1 
            2156 2281 2233 -1 
            2281 2156 2204 -1 
            2079 2031 1954 -1 
            2002 2079 1954 -1 
            2079 2002 2127 -1 
            2079 2156 2031 -1 
            2204 2079 2127 -1 
            2156 2079 2204 -1 
            1656 1531 1579 -1 
            1531 1656 1608 -1 
            1675 1627 1550 -1 
            1800 1675 1723 -1 
            1704 1656 1579 -1 
            1627 1704 1579 -1 
            1656 1704 1781 -1 
            1800 1925 1877 -1 
            1925 2002 1877 -1 
            2050 1925 1973 -1 
            2002 1925 2050 -1 
            1848 1800 1723 -1 
            1925 1848 1973 -1 
            1848 1925 1800 -1 
            801 926 878 -1 
            801 676 724 -1 
            1118 1070 993 -1 
            1618 1570 1493 -1 
            1541 1618 1493 -1 
            1618 1541 1666 -1 
            1397 1474 1349 -1 
            1474 1397 1522 -1 
            1474 1522 1599 -1 
            1368 1416 1493 -1 
            1416 1541 1493 -1 
            1541 1416 1464 -1 
            1416 1368 1291 -1 
            1147 1272 1224 -1 
            1147 1022 1070 -1 
            1368 1445 1320 -1 
            1445 1397 1320 -1 
            1445 1368 1493 -1 
            1397 1445 1522 -1 
            1570 1445 1493 -1 
            1522 1445 1570 -1 
            702 654 577 -1 
            654 529 577 -1 
            529 654 606 -1 
            654 702 779 -1 
            875 827 750 -1 
            702 827 779 -1 
            827 702 750 -1 
            827 904 779 -1 
            971 846 894 -1 
            1086 1163 1038 -1 
            1211 1163 1086 -1 
            1259 1134 1307 -1 
            1259 1211 1134 -1 
            1826 1778 1701 -1 
            1778 1653 1701 -1 
            1586 1509 1634 -1 
            1548 1500 1423 -1 
            1692 1769 1644 -1 
            2095 2172 2047 -1 
            2172 2297 2249 -1 
            2047 1999 1922 -1 
            1999 1874 1922 -1 
            1874 1999 1951 -1 
            1951 1903 1826 -1 
            1778 1903 1855 -1 
            1903 1778 1826 -1 
            1903 1980 1855 -1 
            1980 1903 2028 -1 
            1903 1951 2028 -1 
            1125 1250 1202 -1 
            1250 1327 1202 -1 
            952 875 1000 -1 
            952 1000 1077 -1 
            952 827 875 -1 
            827 952 904 -1 
            1500 1375 1423 -1 
            1423 1375 1298 -1 
            1375 1250 1298 -1 
            1250 1375 1327 -1 
            1327 1404 1279 -1 
            3913 3961 4038 -1 
            3961 4009 4086 -1 
            4038 3961 4086 -1 
            3740 3865 3817 -1 
            3865 3913 3990 -1 
            4028 3903 3951 -1 
            3903 3826 3951 -1 
            3903 3778 3826 -1 
            4076 4153 4028 -1 
            4153 4105 4028 -1 
            4153 4076 4201 -1 
            4105 4153 4230 -1 
            4182 4105 4230 -1 
            4307 4182 4230 -1 
            4057 4134 4009 -1 
            3932 4057 4009 -1 
            4057 4182 4134 -1 
            4182 4057 4105 -1 
            4461 4336 4384 -1 
            4355 4307 4230 -1 
            4307 4355 4432 -1 
            4355 4403 4480 -1 
            4432 4355 4480 -1 
            3260 3308 3385 -1 
            3183 3308 3260 -1 
            3529 3481 3404 -1 
            3481 3356 3404 -1 
            3462 3337 3385 -1 
            3337 3260 3385 -1 
            3337 3212 3260 -1 
            3337 3289 3212 -1 
            3270 3222 3145 -1 
            3251 3299 3376 -1 
            3366 3491 3443 -1 
            3664 3587 3712 -1 
            3664 3741 3616 -1 
            3510 3462 3385 -1 
            3510 3587 3462 -1 
            3587 3510 3635 -1 
            3510 3558 3635 -1 
            3644 3721 3596 -1 
            4067 4144 4019 -1 
            4067 3990 4115 -1 
            4192 4067 4115 -1 
            4144 4067 4192 -1 
            3894 3942 4019 -1 
            4067 3942 3990 -1 
            3942 4067 4019 -1 
            3942 3865 3990 -1 
            3942 3894 3817 -1 
            3865 3942 3817 -1 
            4077 4029 3952 -1 
            4029 3904 3952 -1 
            4154 4077 4202 -1 
            4154 4231 4106 -1 
            4029 4154 4106 -1 
            4154 4029 4077 -1 
            4279 4154 4202 -1 
            4231 4154 4279 -1 
            4144 4221 4096 -1 
            4221 4173 4096 -1 
            4221 4144 4269 -1 
            4221 4269 4346 -1 
            4125 4250 4202 -1 
            4173 4250 4125 -1 
            3808 3885 3760 -1 
            3885 3808 3933 -1 
            4010 3885 3933 -1 
            3885 4010 3962 -1 
            4058 4010 3933 -1 
            4010 4058 4135 -1 
            4058 4183 4135 -1 
            4183 4058 4106 -1 
            2338 2290 2213 -1 
            2242 2290 2367 -1 
            2290 2415 2367 -1 
            2415 2290 2338 -1 
            2223 2271 2348 -1 
            2271 2396 2348 -1 
            2405 2453 2530 -1 
            2405 2328 2453 -1 
            2434 2309 2357 -1 
            2357 2309 2232 -1 
            2309 2184 2232 -1 
            2088 2011 2136 -1 
            2838 2761 2886 -1 
            2838 2713 2761 -1 
            2511 2559 2636 -1 
            2434 2559 2511 -1 
            2617 2569 2492 -1 
            2540 2617 2492 -1 
            2617 2540 2665 -1 
            2569 2617 2694 -1 
            2617 2742 2694 -1 
            2742 2617 2665 -1 
            2569 2521 2444 -1 
            2521 2396 2444 -1 
            2646 2521 2569 -1 
            2521 2646 2598 -1 
            4424 4549 4501 -1 
            4549 4424 4472 -1 
            4472 4424 4347 -1 
            4453 4501 4578 -1 
            4299 4222 4347 -1 
            4424 4299 4347 -1 
            4059 4011 3934 -1 
            4011 4059 4136 -1 
            4328 4280 4203 -1 
            4280 4155 4203 -1 
            3838 3886 3963 -1 
            3886 4011 3963 -1 
            4011 3886 3934 -1 
            3934 3886 3809 -1 
            3742 3819 3694 -1 
            3915 3838 3963 -1 
            4040 3915 3963 -1 
            4607 4732 4684 -1 
            4732 4809 4684 -1 
            4482 4559 4434 -1 
            4559 4482 4607 -1 
            4559 4607 4684 -1 
            4636 4559 4684 -1 
            4213 4338 4290 -1 
            4290 4338 4415 -1 
            4338 4463 4415 -1 
            4242 4319 4194 -1 
            4367 4242 4290 -1 
            4319 4242 4367 -1 
            4069 4117 4194 -1 
            4117 4242 4194 -1 
            2723 2771 2848 -1 
            2819 2771 2694 -1 
            2771 2646 2694 -1 
            2646 2771 2723 -1 
            2896 2819 2944 -1 
            2896 2973 2848 -1 
            2771 2896 2848 -1 
            2896 2771 2819 -1 
            2896 3021 2973 -1 
            3021 2896 2944 -1 
            3213 3136 3261 -1 
            3338 3213 3261 -1 
            3924 3847 3972 -1 
            3847 3924 3799 -1 
            3857 3934 3809 -1 
            4030 4155 4107 -1 
            3136 3184 3261 -1 
            3232 3184 3107 -1 
            3732 3857 3809 -1 
            3703 3751 3828 -1 
            3799 3751 3674 -1 
            3328 3251 3376 -1 
            3357 3309 3232 -1 
            3184 3309 3261 -1 
            3309 3184 3232 -1 
            3935 4012 3887 -1 
            4012 3964 3887 -1 
            3964 4012 4089 -1 
            3704 3579 3627 -1 
            3579 3502 3627 -1 
            3608 3560 3483 -1 
            3502 3454 3377 -1 
            3579 3454 3502 -1 
            3425 3502 3377 -1 
            3473 3425 3348 -1 
            3300 3425 3377 -1 
            3425 3300 3348 -1 
            3598 3550 3473 -1 
            3550 3425 3473 -1 
            3425 3550 3502 -1 
            3502 3550 3627 -1 
            3723 3646 3771 -1 
            3723 3598 3646 -1 
            3877 3954 3829 -1 
            3877 3800 3925 -1 
            3954 4079 4031 -1 
            4204 4079 4127 -1 
            4050 4002 3925 -1 
            4002 3877 3925 -1 
            3877 4002 3954 -1 
            4002 4079 3954 -1 
            4002 4050 4127 -1 
            4079 4002 4127 -1 
            4396 4319 4444 -1 
            4396 4473 4348 -1 
            4271 4396 4348 -1 
            4319 4396 4271 -1 
            4473 4396 4521 -1 
            4396 4444 4521 -1 
            4809 4761 4684 -1 
            4761 4636 4684 -1 
            4886 4761 4809 -1 
            4463 4540 4415 -1 
            4540 4492 4415 -1 
            4540 4463 4588 -1 
            4540 4617 4492 -1 
            4540 4588 4665 -1 
            4617 4540 4665 -1 
            4454 4579 4531 -1 
            4454 4329 4377 -1 
            4252 4329 4204 -1 
            4377 4329 4252 -1 
            4425 4300 4348 -1 
            4425 4377 4300 -1 
            4473 4425 4348 -1 
            4425 4473 4550 -1 
            4877 4925 5002 -1 
            4051 3926 3974 -1 
            3878 3830 3753 -1 
            3705 3830 3782 -1 
            3830 3705 3753 -1 
            3782 3830 3907 -1 
            3830 3955 3907 -1 
            3830 3878 3955 -1 
            3926 3849 3974 -1 
            3849 3897 3974 -1 
            4474 4349 4397 -1 
            4733 4858 4810 -1 
            4954 5002 5079 -1 
            4954 4877 5002 -1 
            4954 4906 4829 -1 
            4877 4954 4829 -1 
            5453 5328 5376 -1 
            5270 5145 5193 -1 
            5145 5068 5193 -1 
            5395 5520 5472 -1 
            5261 5309 5386 -1 
            5309 5434 5386 -1 
            5434 5309 5357 -1 
            5405 5482 5357 -1 
            5405 5328 5453 -1 
            5280 5405 5357 -1 
            5328 5405 5280 -1 
            5040 5165 5117 -1 
            5338 5261 5386 -1 
            5463 5338 5386 -1 
            5194 5069 5117 -1 
            5069 5194 5146 -1 
            5434 5511 5386 -1 
            5511 5463 5386 -1 
            5511 5588 5463 -1 
            4646 4598 4521 -1 
            4973 5050 4925 -1 
            4973 5021 5098 -1 
            5050 4973 5098 -1 
            4915 4963 5040 -1 
            4992 4915 5040 -1 
            4915 4992 4867 -1 
            4790 4915 4867 -1 
            4253 4301 4378 -1 
            4330 4253 4378 -1 
            4253 4330 4205 -1 
            4128 4253 4205 -1 
            4282 4359 4234 -1 
            4330 4282 4205 -1 
            4282 4157 4205 -1 
            4157 4282 4234 -1 
            5522 5445 5570 -1 
            5445 5522 5397 -1 
            4762 4685 4810 -1 
            4618 4743 4695 -1 
            4693 4770 4645 -1 
            4597 4520 4645 -1 
            4520 4597 4472 -1 
            4520 4395 4443 -1 
            4395 4520 4472 -1 
            4712 4837 4789 -1 
            4837 4712 4760 -1 
            4462 4385 4510 -1 
            4558 4606 4683 -1 
            4934 4886 4809 -1 
            4953 4905 4828 -1 
            4703 4751 4828 -1 
            4770 4847 4722 -1 
            4847 4972 4924 -1 
            4924 4972 5049 -1 
            4751 4876 4828 -1 
            4876 4953 4828 -1 
            4222 4270 4347 -1 
            4270 4395 4347 -1 
            4674 4597 4722 -1 
            4674 4549 4597 -1 
            4866 4991 4943 -1 
            4991 5068 4943 -1 
            4991 5116 5068 -1 
            5116 4991 5039 -1 
            5365 5413 5490 -1 
            5240 5365 5317 -1 
            5538 5615 5490 -1 
            5413 5538 5490 -1 
            5615 5692 5567 -1 
            5346 5423 5298 -1 
            5394 5346 5269 -1 
            5221 5346 5298 -1 
            5269 5346 5221 -1 
            5144 5269 5221 -1 
            5721 5673 5596 -1 
            5548 5673 5625 -1 
            5673 5548 5596 -1 
            5673 5750 5625 -1 
            5673 5721 5798 -1 
            5750 5673 5798 -1 
            4914 4837 4962 -1 
            4914 4991 4866 -1 
            4914 4866 4789 -1 
            4837 4914 4789 -1 
            5039 4914 4962 -1 
            4991 4914 5039 -1 
            5212 5260 5337 -1 
            5385 5260 5308 -1 
            5260 5385 5337 -1 
            5260 5183 5308 -1 
            5260 5135 5183 -1 
            5260 5212 5135 -1 
            5241 5289 5366 -1 
            5289 5212 5337 -1 
            5212 5289 5164 -1 
            5289 5241 5164 -1 
            5318 5241 5366 -1 
            5318 5395 5270 -1 
            5318 5270 5193 -1 
            5241 5318 5193 -1 
            5000 5125 5077 -1 
            5125 5202 5077 -1 
            5125 5173 5250 -1 
            5202 5125 5250 -1 
            4702 4750 4827 -1 
            4625 4750 4702 -1 
            5106 5154 5231 -1 
            5154 5279 5231 -1 
            5154 5202 5279 -1 
            5202 5154 5077 -1 
            5154 5029 5077 -1 
            5154 5106 5029 -1 
            5385 5462 5337 -1 
            6379 6302 6427 -1 
            6331 6379 6456 -1 
            6273 6148 6196 -1 
            6129 6081 6004 -1 
            5956 6081 6033 -1 
            6081 5956 6004 -1 
            6081 6158 6033 -1 
            5908 5985 5860 -1 
            5908 5831 5956 -1 
            5985 5908 6033 -1 
            5908 5956 6033 -1 
            5783 5908 5860 -1 
            5831 5908 5783 -1 
            5860 5812 5735 -1 
            5764 5812 5889 -1 
            5812 5937 5889 -1 
            5812 5860 5937 -1 
            5716 5668 5591 -1 
            5716 5793 5668 -1 
            5716 5841 5793 -1 
            5841 5716 5764 -1 
            6071 6148 6023 -1 
            6119 6071 5994 -1 
            6071 6119 6196 -1 
            6148 6071 6196 -1 
            6071 5946 5994 -1 
            5946 6071 6023 -1 
            6253 6378 6330 -1 
            6378 6455 6330 -1 
            6378 6503 6455 -1 
            6205 6253 6330 -1 
            6253 6205 6128 -1 
            6205 6157 6080 -1 
            6128 6205 6080 -1 
            6282 6205 6330 -1 
            6205 6282 6157 -1 
            6013 6061 6138 -1 
            6455 6407 6330 -1 
            6407 6282 6330 -1 
            6282 6407 6359 -1 
            6532 6407 6455 -1 
            6090 6167 6042 -1 
            6090 6013 6138 -1 
            6090 6138 6215 -1 
            6167 6090 6215 -1 
            5965 6090 6042 -1 
            6013 6090 5965 -1 
            6263 6186 6311 -1 
            6186 6263 6138 -1 
            6186 6061 6109 -1 
            6061 6186 6138 -1 
            6340 6388 6465 -1 
            6388 6311 6436 -1 
            6388 6263 6311 -1 
            6388 6340 6263 -1 
            5610 5562 5485 -1 
            5629 5677 5754 -1 
            5706 5629 5754 -1 
            5629 5706 5581 -1 
            5677 5629 5552 -1 
            5600 5677 5552 -1 
            5648 5600 5523 -1 
            5600 5725 5677 -1 
            5725 5600 5648 -1 
            5475 5600 5552 -1 
            5523 5600 5475 -1 
            5946 5898 5821 -1 
            5898 5773 5821 -1 
            5773 5898 5850 -1 
            5898 5946 6023 -1 
            5696 5773 5648 -1 
            5696 5619 5744 -1 
            5696 5744 5821 -1 
            5773 5696 5821 -1 
            5696 5648 5571 -1 
            5619 5696 5571 -1 
            5666 5618 5541 -1 
            5618 5695 5570 -1 
            5618 5666 5743 -1 
            5695 5618 5743 -1 
            5666 5791 5743 -1 
            5619 5667 5744 -1 
            5667 5590 5715 -1 
            5590 5667 5542 -1 
            5667 5619 5542 -1 
            5811 5763 5686 -1 
            5763 5638 5686 -1 
            5638 5763 5715 -1 
            5763 5840 5715 -1 
            5782 5859 5734 -1 
            5859 5811 5734 -1 
            5907 5859 5782 -1 
            5398 5446 5523 -1 
            5523 5446 5571 -1 
            5446 5494 5571 -1 
            5494 5446 5369 -1 
            5446 5321 5369 -1 
            5321 5446 5398 -1 
            5484 5609 5561 -1 
            5609 5484 5532 -1 
            5532 5484 5407 -1 
            5321 5196 5244 -1 
            5196 5321 5273 -1 
            4975 5023 5100 -1 
            5599 5676 5551 -1 
            5599 5522 5647 -1 
            5599 5551 5474 -1 
            5522 5599 5474 -1 
            5753 5676 5801 -1 
            5753 5830 5705 -1 
            5753 5705 5628 -1 
            5676 5753 5628 -1 
            5878 5753 5801 -1 
            5830 5753 5878 -1 
            5724 5849 5801 -1 
            5676 5724 5801 -1 
            5724 5599 5647 -1 
            5599 5724 5676 -1 
            5609 5657 5734 -1 
            5657 5782 5734 -1 
            5657 5705 5782 -1 
            5657 5580 5705 -1 
            5580 5657 5532 -1 
            5657 5609 5532 -1 
            5849 5897 5974 -1 
            5897 6022 5974 -1 
            5916 6041 5993 -1 
            6041 5916 5964 -1 
            6301 6253 6176 -1 
            6224 6301 6176 -1 
            6301 6224 6349 -1 
            6301 6378 6253 -1 
            6118 6195 6070 -1 
            6118 6041 6166 -1 
            6118 6070 5993 -1 
            6041 6118 5993 -1 
            6243 6368 6320 -1 
            6195 6243 6320 -1 
            6243 6118 6166 -1 
            6118 6243 6195 -1 
            6224 6147 6272 -1 
            6147 6195 6272 -1 
            6147 6224 6099 -1 
            6195 6147 6070 -1 
            6022 6147 6099 -1 
            6147 6022 6070 -1 
            6262 6137 6185 -1 
            6117 6165 6242 -1 
            6117 5992 6040 -1 
            6165 6117 6040 -1 
            6184 6309 6261 -1 
            6136 6184 6261 -1 
            6213 6136 6261 -1 
            6088 6165 6040 -1 
            6088 6213 6165 -1 
            6213 6088 6136 -1 
            6059 6184 6136 -1 
            6579 6502 6627 -1 
            6579 6531 6454 -1 
            6502 6579 6454 -1 
            6598 6473 6521 -1 
            6483 6406 6531 -1 
            6608 6483 6531 -1 
            6358 6483 6435 -1 
            6406 6483 6358 -1 
            6060 6108 6185 -1 
            6108 6233 6185 -1 
            6281 6204 6329 -1 
            5800 5925 5877 -1 
            5925 6002 5877 -1 
            5944 5867 5992 -1 
            5598 5473 5521 -1 
            5473 5396 5521 -1 
            5992 5915 6040 -1 
            5867 5915 5992 -1 
            5915 5790 5838 -1 
            5790 5915 5867 -1 
            7887 7762 7810 -1 
            7637 7762 7714 -1 
            7041 6993 6916 -1 
            6868 6945 6820 -1 
            6916 6868 6791 -1 
            6993 6868 6916 -1 
            6868 6993 6945 -1 
            7387 7262 7310 -1 
            7185 7262 7137 -1 
            7262 7185 7310 -1 
            7060 7012 6935 -1 
            7012 6887 6935 -1 
            6887 7012 6964 -1 
            7012 7089 6964 -1 
            7089 7012 7137 -1 
            7012 7060 7137 -1 
            6695 6743 6820 -1 
            6868 6743 6791 -1 
            6743 6868 6820 -1 
            6743 6666 6791 -1 
            6743 6618 6666 -1 
            6618 6743 6695 -1 
            6714 6637 6762 -1 
            6666 6714 6791 -1 
            6714 6666 6589 -1 
            6637 6714 6589 -1 
            6714 6839 6791 -1 
            6839 6714 6762 -1 
            6560 6637 6512 -1 
            6560 6483 6608 -1 
            6560 6608 6685 -1 
            6637 6560 6685 -1 
            6560 6512 6435 -1 
            6483 6560 6435 -1 
            6877 6829 6752 -1 
            6829 6704 6752 -1 
            6752 6704 6627 -1 
            6704 6579 6627 -1 
            6445 6397 6320 -1 
            6272 6397 6349 -1 
            6397 6272 6320 -1 
            6445 6493 6570 -1 
            6618 6493 6541 -1 
            6493 6618 6570 -1 
            6541 6493 6416 -1 
            6493 6368 6416 -1 
            6493 6445 6368 -1 
            6954 6829 6877 -1 
            6829 6954 6906 -1 
            6954 7079 7031 -1 
            6906 6954 7031 -1 
            8183 8106 8231 -1 
            8058 8106 8183 -1 
            7933 7981 8058 -1 
            8106 7981 8029 -1 
            7981 8106 8058 -1 
            7981 7933 7856 -1 
            7664 7539 7587 -1 
            7270 7318 7395 -1 
            7222 7145 7270 -1 
            7222 7270 7347 -1 
            7222 7174 7097 -1 
            7145 7222 7097 -1 
            7413 7336 7461 -1 
            7980 7855 7903 -1 
            7855 7778 7903 -1 
            7807 7884 7759 -1 
            6925 6848 6973 -1 
            6800 6848 6925 -1 
            7107 7232 7184 -1 
            7232 7309 7184 -1 
            7126 7203 7078 -1 
            7443 7520 7395 -1 
            7318 7443 7395 -1 
            7568 7443 7491 -1 
            7443 7568 7520 -1 
            6251 6299 6376 -1 
            6299 6222 6347 -1 
            6251 6203 6126 -1 
            6078 6203 6155 -1 
            6203 6078 6126 -1 
            6953 7078 7030 -1 
            6953 7001 7078 -1 
            6915 6838 6963 -1 
            7732 7655 7780 -1 
            7568 7645 7520 -1 
            7972 8020 8097 -1 
            8145 8020 8068 -1 
            8097 8020 8145 -1 
            7828 7876 7953 -1 
            7876 7924 8001 -1 
            8049 7924 7972 -1 
            7924 8049 8001 -1 
            6867 6915 6992 -1 
            7508 7556 7633 -1 
            7364 7489 7441 -1 
            7258 7335 7210 -1 
            7595 7518 7643 -1 
            7518 7566 7643 -1 
            7518 7595 7470 -1 
            7566 7518 7441 -1 
            7518 7393 7441 -1 
            7518 7470 7393 -1 
            7691 7816 7768 -1 
            7816 7893 7768 -1 
            7941 7816 7864 -1 
            7893 7816 7941 -1 
            7710 7585 7633 -1 
            7585 7508 7633 -1 
            7508 7585 7460 -1 
            7662 7710 7787 -1 
            7662 7585 7710 -1 
            6577 6529 6452 -1 
            6375 6500 6452 -1 
            6500 6375 6423 -1 
            6500 6577 6452 -1 
            7182 7230 7307 -1 
            7105 7182 7057 -1 
            7182 7105 7230 -1 
            7905 7982 7857 -1 
            7905 7828 7953 -1 
            7905 7953 8030 -1 
            7982 7905 8030 -1 
            7905 7857 7780 -1 
            7828 7905 7780 -1 
            7877 7752 7800 -1 
            7752 7877 7829 -1 
            7944 7819 7867 -1 
            7867 7819 7742 -1 
            7819 7694 7742 -1 
            7819 7771 7694 -1 
            7896 7819 7944 -1 
            7819 7896 7771 -1 
            8021 7896 7944 -1 
            7723 7598 7646 -1 
            7771 7723 7646 -1 
            7309 7261 7184 -1 
            7136 7261 7213 -1 
            7261 7136 7184 -1 
            7261 7338 7213 -1 
            7165 7088 7213 -1 
            7088 7136 7213 -1 
            7088 7165 7040 -1 
            7136 7088 7011 -1 
            6963 7088 7040 -1 
            7011 7088 6963 -1 
            6141 6218 6093 -1 
            6141 6064 6189 -1 
            6141 6189 6266 -1 
            6218 6141 6266 -1 
            6064 6016 5939 -1 
            5891 6016 5968 -1 
            6016 5891 5939 -1 
            6016 6093 5968 -1 
            6016 6141 6093 -1 
            6141 6016 6064 -1 
            6112 5939 6333 -1 
            6112 6064 5939 -1 
            6285 6112 6333 -1 
            6237 6112 6285 -1 
            6112 6237 6189 -1 
            6064 6112 6189 -1 
            5997 5920 6045 -1 
            5949 5997 6074 -1 
            5997 6122 6074 -1 
            6122 5997 6045 -1 
            5872 5997 5949 -1 
            5997 5872 5920 -1 
            5796 5748 5671 -1 
            5719 5796 5671 -1 
            5796 5719 5844 -1 
            5796 5873 5748 -1 
            5959 5911 5834 -1 
            5911 5959 6036 -1 
            6084 5959 6007 -1 
            5959 6084 6036 -1 
            5911 5863 5786 -1 
            5863 5738 5786 -1 
            5863 5815 5738 -1 
            5863 5940 5815 -1 
            5940 5863 5988 -1 
            5863 5911 5988 -1 
            5517 5469 5392 -1 
            5421 5469 5546 -1 
            5642 5594 5517 -1 
            5469 5594 5546 -1 
            5594 5469 5517 -1 
            5594 5671 5546 -1 
            5594 5719 5671 -1 
            5594 5642 5719 -1 
            5565 5642 5517 -1 
            5440 5565 5517 -1 
            5488 5411 5536 -1 
            5488 5565 5440 -1 
            5488 5363 5411 -1 
            5363 5488 5440 -1 
            5219 5171 5094 -1 
            5296 5171 5219 -1 
            8275 8198 8323 -1 
            8400 8275 8323 -1 
            8275 8227 8150 -1 
            8198 8275 8150 -1 
            7910 8035 7987 -1 
            8189 8237 8314 -1 
            8237 8189 8112 -1 
            8064 8189 8141 -1 
            8112 8189 8064 -1 
            8487 8410 8458 -1 
            8362 8410 8487 -1 
            8410 8333 8458 -1 
            8410 8362 8285 -1 
            8333 8410 8285 -1 
            6449 6526 6401 -1 
            6574 6449 6497 -1 
            6526 6449 6574 -1 
            6372 6295 6420 -1 
            6372 6420 6497 -1 
            6449 6372 6497 -1 
            6324 6401 6276 -1 
            6324 6449 6401 -1 
            6324 6372 6449 -1 
            6247 6122 6170 -1 
            6295 6247 6170 -1 
            6372 6247 6295 -1 
            6324 6247 6372 -1 
            7201 7326 7278 -1 
            7201 7249 7326 -1 
            7153 7201 7278 -1 
            7201 7153 7076 -1 
            7297 7422 7374 -1 
            7249 7297 7374 -1 
            7297 7172 7220 -1 
            7297 7249 7172 -1 
            6634 6682 6759 -1 
            6682 6807 6759 -1 
            6682 6730 6807 -1 
            6730 6682 6605 -1 
            6730 6778 6855 -1 
            6855 6778 6903 -1 
            6778 6826 6903 -1 
            6778 6701 6826 -1 
            6778 6653 6701 -1 
            6778 6730 6653 -1 
            6999 7124 7076 -1 
            7047 7124 6999 -1 
            7124 7047 7172 -1 
            7124 7201 7076 -1 
            7249 7124 7172 -1 
            7201 7124 7249 -1 
            7749 7701 7624 -1 
            7576 7701 7653 -1 
            7624 7701 7576 -1 
            7701 7778 7653 -1 
            7701 7826 7778 -1 
            7826 7701 7749 -1 
            7778 7826 7903 -1 
            7826 7951 7903 -1 
            7912 7960 8037 -1 
            7989 7912 8037 -1 
            7912 7989 7864 -1 
            7912 7835 7960 -1 
            7787 7912 7864 -1 
            7835 7912 7787 -1 
            8451 8403 8326 -1 
            8451 8499 8480 -1 
            8403 8451 8480 -1 
            8403 8278 8326 -1 
            8278 8201 8326 -1 
            8499 8470 8489 -1 
            8422 8470 8499 -1 
            8210 8162 8085 -1 
            8287 8210 8335 -1 
            8210 8287 8162 -1 
            8402 8479 8354 -1 
            8479 8431 8354 -1 
            8431 8460 8383 -1 
            8460 8431 8479 -1 
            7951 7999 8076 -1 
            8066 7989 8114 -1 
            7989 8066 7941 -1 
            8066 8018 7941 -1 
            8460 8412 8335 -1 
            8412 8287 8335 -1 
            8287 8412 8364 -1 
            8364 8412 8489 -1 
            8412 8460 8489 -1 
            6711 6634 6759 -1 
            6711 6586 6634 -1 
            6346 6471 6423 -1 
            6461 6586 6538 -1 
            6413 6461 6538 -1 
            6461 6413 6336 -1 
            6355 6278 6403 -1 
            6480 6355 6403 -1 
            6230 6307 6182 -1 
            6355 6230 6278 -1 
            6230 6355 6307 -1 
            6288 6211 6336 -1 
            6394 6317 6442 -1 
            6394 6471 6346 -1 
            6394 6346 6269 -1 
            6317 6394 6269 -1 
            6125 6173 6250 -1 
            6173 6125 6048 -1 
            6125 6000 6048 -1 
            6000 6125 6077 -1 
            6346 6221 6269 -1 
            6269 6221 6144 -1 
            6221 6096 6144 -1 
            6221 6173 6096 -1 
            6327 6202 6250 -1 
            6202 6125 6250 -1 
            6125 6202 6077 -1 
            6010 5885 5933 -1 
            5885 6010 5962 -1 
            6067 6019 5942 -1 
            6019 6067 6144 -1 
            6067 6192 6144 -1 
            6067 6115 6192 -1 
            6019 5971 5894 -1 
            5846 5971 5923 -1 
            5971 5846 5894 -1 
            5971 6048 5923 -1 
            5971 6096 6048 -1 
            5971 6019 6096 -1 
            6000 5875 5923 -1 
            5952 5875 6000 -1 
            5875 5952 5827 -1 
            5923 5875 5798 -1 
            5875 5750 5798 -1 
            5750 5875 5827 -1 
            5491 5539 5616 -1 
            5539 5664 5616 -1 
            5808 5856 5933 -1 
            5856 5981 5933 -1 
            5981 5856 5904 -1 
            5856 5779 5904 -1 
            5779 5856 5731 -1 
            5856 5808 5731 -1 
            5885 5760 5808 -1 
            5837 5760 5885 -1 
            5760 5837 5712 -1 
            5808 5760 5683 -1 
            5760 5712 5635 -1 
            5683 5760 5635 -1 
            6174 6126 6049 -1 
            6174 6251 6126 -1 
            6174 6299 6251 -1 
            6299 6174 6222 -1 
            6212 6289 6164 -1 
            6260 6212 6135 -1 
            6337 6212 6260 -1 
            6212 6337 6289 -1 
            5905 6030 5982 -1 
            6030 6078 6155 -1 
            5953 6030 5905 -1 
            6030 5953 6078 -1 
            5857 5780 5905 -1 
            8125 8048 8173 -1 
            8048 8096 8173 -1 
            8048 8125 8000 -1 
            7510 7558 7635 -1 
            7760 7885 7837 -1 
            7760 7808 7885 -1 
            7683 7760 7635 -1 
            7760 7683 7808 -1 
            7683 7558 7606 -1 
            7558 7683 7635 -1 
            7904 7856 7779 -1 
            7904 7981 7856 -1 
            7904 7952 8029 -1 
            7981 7904 8029 -1 
            8432 8384 8307 -1 
            8384 8259 8307 -1 
            8259 8384 8336 -1 
            8336 8384 8461 -1 
            8384 8432 8461 -1 
            8355 8432 8307 -1 
            8355 8278 8403 -1 
            8355 8403 8480 -1 
            8432 8355 8480 -1 
            8230 8355 8307 -1 
            8278 8355 8230 -1 
            8026 7978 7901 -1 
            7978 7853 7901 -1 
            7853 7978 7930 -1 
            7978 8026 8103 -1 
            7949 8026 7901 -1 
            7949 7872 7997 -1 
            7949 7997 8074 -1 
            8026 7949 8074 -1 
            7949 7901 7824 -1 
            7872 7949 7824 -1 
            7776 7853 7728 -1 
            7776 7699 7824 -1 
            7901 7776 7824 -1 
            7853 7776 7901 -1 
            7776 7651 7699 -1 
            7776 7728 7651 -1 
            8237 8160 8285 -1 
            8160 8208 8285 -1 
            8160 8237 8112 -1 
            8035 8160 8112 -1 
            8218 8266 8343 -1 
            8266 8189 8314 -1 
            8266 8218 8141 -1 
            8189 8266 8141 -1 
            8170 8247 8122 -1 
            8218 8170 8093 -1 
            8170 8122 8045 -1 
            8093 8170 8045 -1 
            8295 8372 8247 -1 
            8295 8218 8343 -1 
            8420 8295 8343 -1 
            8372 8295 8420 -1 
            8170 8295 8247 -1 
            8295 8170 8218 -1 
            8468 8439 8487 -1 
            8439 8362 8487 -1 
            8362 8439 8314 -1 
            8478 8449 8497 -1 
            8478 8459 8430 -1 
            8353 8478 8430 -1 
            8488 8478 8497 -1 
            8459 8478 8488 -1 
            8238 8113 8161 -1 
            8238 8190 8113 -1 
            8363 8411 8488 -1 
            8411 8459 8488 -1 
            8459 8411 8334 -1 
            5190 5142 5065 -1 
            5017 5142 5094 -1 
            5142 5017 5065 -1 
            5142 5219 5094 -1 
            5142 5267 5219 -1 
            5142 5190 5267 -1 
            4950 4902 4825 -1 
            4902 4777 4825 -1 
            4777 4902 4854 -1 
            4998 4873 4921 -1 
            4998 4950 4873 -1 
            4094 4017 4142 -1 
            4046 4094 4171 -1 
            4017 4094 3969 -1 
            4094 4046 3969 -1 
            3873 3998 3950 -1 
            3998 4075 3950 -1 
            3998 4046 4123 -1 
            4075 3998 4123 -1 
            3729 3652 3777 -1 
            3729 3806 3681 -1 
            3729 3777 3854 -1 
            3806 3729 3854 -1 
            3383 3508 3460 -1 
            3508 3585 3460 -1 
            3585 3508 3633 -1 
            3604 3729 3681 -1 
            3729 3604 3652 -1 
            3547 3624 3499 -1 
            3547 3470 3595 -1 
            3470 3547 3422 -1 
            3547 3499 3422 -1 
            3624 3672 3749 -1 
            3720 3672 3595 -1 
            3672 3547 3595 -1 
            3547 3672 3624 -1 
            3624 3576 3499 -1 
            3345 3470 3422 -1 
            3345 3393 3470 -1 
            3297 3345 3422 -1 
            3393 3345 3268 -1 
            3345 3220 3268 -1 
            3220 3345 3297 -1 
            3364 3412 3489 -1 
            3662 3585 3710 -1 
            3614 3662 3739 -1 
            3662 3614 3537 -1 
            3585 3662 3537 -1 
            3787 3662 3710 -1 
            3662 3787 3739 -1 
            4095 4143 4220 -1 
            4143 4191 4268 -1 
            4220 4143 4268 -1 
            4143 4066 4191 -1 
            4018 4095 3970 -1 
            4018 3941 4066 -1 
            4143 4018 4066 -1 
            4018 4143 4095 -1 
            4172 4095 4220 -1 
            4172 4249 4124 -1 
            4172 4220 4297 -1 
            4249 4172 4297 -1 
            4095 4047 3970 -1 
            4047 4172 4124 -1 
            4172 4047 4095 -1 
            4162 4085 4210 -1 
            4287 4162 4210 -1 
            4239 4162 4287 -1 
            3883 3931 4008 -1 
            3960 3883 4008 -1 
            3883 3960 3835 -1 
            3931 3883 3806 -1 
            3758 3883 3835 -1 
            3883 3758 3806 -1 
            4104 3979 4027 -1 
            3979 3902 4027 -1 
            3902 3979 3854 -1 
            3979 3931 3854 -1 
            4133 4085 4008 -1 
            4210 4133 4258 -1 
            4085 4133 4210 -1 
            5478 5603 5555 -1 
            5526 5603 5478 -1 
            5718 5795 5670 -1 
            5872 5795 5920 -1 
            4267 4219 4142 -1 
            4219 4094 4142 -1 
            4094 4219 4171 -1 
            4171 4219 4296 -1 
            4219 4344 4296 -1 
            4219 4267 4344 -1 
            4200 4277 4152 -1 
            4248 4200 4123 -1 
            4200 4075 4123 -1 
            4075 4200 4152 -1 
            4325 4248 4373 -1 
            4277 4325 4402 -1 
            4200 4325 4277 -1 
            4325 4200 4248 -1 
            4325 4450 4402 -1 
            4450 4325 4373 -1 
            6768 6720 6643 -1 
            6643 6720 6595 -1 
            6720 6672 6595 -1 
            6672 6720 6797 -1 
            6720 6845 6797 -1 
            6720 6768 6845 -1 
            6739 6816 6691 -1 
            6816 6768 6691 -1 
            6816 6739 6864 -1 
            6768 6816 6893 -1 
            6816 6941 6893 -1 
            6941 6816 6864 -1 
            6335 6258 6383 -1 
            6210 6258 6335 -1 
            6249 6297 6374 -1 
            6297 6422 6374 -1 
            6422 6297 6345 -1 
            6345 6297 6220 -1 
            6162 6287 6239 -1 
            6287 6162 6210 -1 
            5326 5451 5403 -1 
            6998 7075 6950 -1 
            7046 6998 6921 -1 
            6998 7046 7123 -1 
            7075 6998 7123 -1 
            6998 6873 6921 -1 
            6873 6998 6950 -1 
            4288 4365 4240 -1 
            4461 4586 4538 -1 
            4586 4663 4538 -1 
            4278 4355 4230 -1 
            4355 4278 4403 -1 
            4153 4278 4230 -1 
            4278 4153 4201 -1 
            4605 4557 4480 -1 
            4528 4605 4480 -1 
            4499 4374 4422 -1 
            4547 4499 4422 -1 
            4499 4547 4624 -1 
            4460 4335 4383 -1 
            4412 4335 4460 -1 
            4335 4412 4287 -1 
            4383 4335 4258 -1 
            4335 4210 4258 -1 
            4335 4287 4210 -1 
            4518 4566 4643 -1 
            4595 4518 4643 -1 
            4412 4489 4364 -1 
            4537 4489 4412 -1 
            4566 4489 4614 -1 
            4489 4537 4614 -1 
            4806 4758 4681 -1 
            4710 4758 4835 -1 
            4758 4633 4681 -1 
            4633 4758 4710 -1 
            4633 4556 4681 -1 
            4633 4508 4556 -1 
            4739 4691 4614 -1 
            5671 5623 5546 -1 
            5748 5623 5671 -1 
            4720 4595 4643 -1 
            4720 4672 4595 -1 
            4547 4672 4624 -1 
            4595 4672 4547 -1 
            8056 8104 8181 -1 
            7979 8104 8056 -1 
            8277 8402 8354 -1 
            8344 8421 8296 -1 
            8344 8267 8392 -1 
            8344 8392 8469 -1 
            8421 8344 8469 -1 
            8267 8344 8219 -1 
            8344 8296 8219 -1 
            7902 7854 7777 -1 
            7854 7729 7777 -1 
            7729 7854 7806 -1 
            7854 7931 7806 -1 
            7931 7854 7979 -1 
            7854 7902 7979 -1 
            7758 7835 7710 -1 
            7758 7681 7806 -1 
            7758 7806 7883 -1 
            7835 7758 7883 -1 
            7681 7758 7633 -1 
            7758 7710 7633 -1 
            7527 7604 7479 -1 
            7527 7450 7575 -1 
            7652 7527 7575 -1 
            7604 7527 7652 -1 
            7527 7479 7402 -1 
            7450 7527 7402 -1 
            7738 7863 7815 -1 
            7863 7940 7815 -1 
            7863 7988 7940 -1 
            7863 7911 7988 -1 
            8123 8171 8248 -1 
            8296 8171 8219 -1 
            8171 8296 8248 -1 
            7940 7892 7815 -1 
            7892 7969 7844 -1 
            6219 6142 6267 -1 
            6142 6190 6267 -1 
            6142 6219 6094 -1 
            6190 6142 6065 -1 
            6142 6017 6065 -1 
            6017 6142 6094 -1 
            6190 6315 6267 -1 
            6315 6392 6267 -1 
            6440 6315 6363 -1 
            6392 6315 6440 -1 
            6286 6411 6363 -1 
            6411 6286 6334 -1 
            6286 6209 6334 -1 
            6152 6075 6200 -1 
            6046 6123 5998 -1 
            6123 6075 5998 -1 
            6123 6046 6171 -1 
            6075 6123 6200 -1 
            6123 6248 6200 -1 
            6248 6123 6171 -1 
            6026 5949 6074 -1 
            6026 5901 5949 -1 
            6151 6026 6074 -1 
            6026 6151 6103 -1 
            6161 6084 6209 -1 
            6286 6161 6209 -1 
            6036 6161 6113 -1 
            6084 6161 6036 -1 
            6421 6546 6498 -1 
            6421 6469 6546 -1 
            6373 6421 6498 -1 
            6469 6421 6344 -1 
            6421 6296 6344 -1 
            6296 6421 6373 -1 
            6469 6517 6594 -1 
            6642 6517 6565 -1 
            6517 6642 6594 -1 
            6517 6440 6565 -1 
            6517 6392 6440 -1 
            6517 6469 6392 -1 
            6786 6661 6709 -1 
            6738 6661 6786 -1 
            6584 6661 6536 -1 
            6661 6584 6709 -1 
            6729 6806 6681 -1 
            6777 6729 6652 -1 
            6729 6777 6854 -1 
            6806 6729 6854 -1 
            6729 6681 6604 -1 
            6652 6729 6604 -1 
            7180 7132 7055 -1 
            6709 6757 6834 -1 
            6805 6757 6680 -1 
            6757 6632 6680 -1 
            6632 6757 6709 -1 
            7007 6930 7055 -1 
            7007 7084 6959 -1 
            7132 7007 7055 -1 
            7007 7132 7084 -1 
            6882 6959 6834 -1 
            6930 6882 6805 -1 
            6882 7007 6959 -1 
            7007 6882 6930 -1 
            6757 6882 6834 -1 
            6882 6757 6805 -1 
            6622 6699 6574 -1 
            6699 6622 6747 -1 
            6545 6622 6497 -1 
            6622 6574 6497 -1 
            6612 6689 6564 -1 
            7103 7026 7151 -1 
            7026 7103 6978 -1 
            7458 7333 7381 -1 
            7603 7478 7526 -1 
            7555 7478 7603 -1 
            7305 7257 7180 -1 
            7257 7132 7180 -1 
            7574 7622 7699 -1 
            7622 7747 7699 -1 
            7622 7670 7747 -1 
            7468 7343 7391 -1 
            7757 7680 7805 -1 
            7757 7834 7709 -1 
            7632 7709 7584 -1 
            7680 7632 7555 -1 
            7632 7757 7709 -1 
            7757 7632 7680 -1 
            7632 7584 7507 -1 
            7555 7632 7507 -1 
            7939 7891 7814 -1 
            7891 7939 8016 -1 
            8064 7939 7987 -1 
            8016 7939 8064 -1 
            7718 7795 7670 -1 
            7718 7766 7843 -1 
            7795 7718 7843 -1 
            7948 8025 7900 -1 
            8073 8025 7948 -1 
            8025 8150 8102 -1 
            8025 8073 8150 -1 
            7621 7544 7669 -1 
            7621 7698 7573 -1 
            7621 7573 7496 -1 
            7544 7621 7496 -1 
            7717 7640 7765 -1 
            7717 7794 7669 -1 
            7592 7717 7669 -1 
            7640 7717 7592 -1 
            7717 7765 7842 -1 
            7794 7717 7842 -1 
            7439 7487 7564 -1 
            7314 7439 7391 -1 
            7179 7054 7102 -1 
            7467 7419 7294 -1 
            7419 7371 7294 -1 
            7371 7419 7496 -1 
            7419 7544 7496 -1 
            7544 7419 7467 -1 
            7794 7746 7669 -1 
            7746 7621 7669 -1 
            7621 7746 7698 -1 
            7746 7794 7871 -1 
            7698 7650 7573 -1 
            7602 7650 7727 -1 
            7650 7775 7727 -1 
            7775 7650 7698 -1 
            6977 7054 6929 -1 
            7054 6977 7102 -1 
            6977 6852 6900 -1 
            6852 6977 6929 -1 
            7150 7198 7275 -1 
            7198 7150 7073 -1 
            7198 7323 7275 -1 
            7450 7373 7498 -1 
            7296 7373 7248 -1 
            7498 7623 7575 -1 
            7623 7700 7575 -1 
            7700 7623 7748 -1 
            7892 7767 7815 -1 
            7767 7892 7844 -1 
            7344 7392 7469 -1 
            7440 7392 7315 -1 
            7315 7392 7267 -1 
            7392 7344 7267 -1 
            4679 4554 4602 -1 
            4429 4554 4506 -1 
            4756 4631 4679 -1 
            4631 4554 4679 -1 
            4631 4583 4506 -1 
            4554 4631 4506 -1 
            4535 4612 4487 -1 
            4535 4458 4583 -1 
            4535 4487 4410 -1 
            4458 4535 4410 -1 
            4660 4535 4583 -1 
            4535 4660 4612 -1 
            4747 4795 4872 -1 
            4843 4795 4718 -1 
            4795 4670 4718 -1 
            4795 4747 4670 -1 
            5150 5198 5275 -1 
            4900 4977 4852 -1 
            4900 5025 4977 -1 
            4746 4823 4698 -1 
            4823 4746 4871 -1 
            5073 5150 5025 -1 
            5198 5073 5121 -1 
            5073 5198 5150 -1 
            5342 5265 5390 -1 
            5342 5217 5265 -1 
            5217 5342 5294 -1 
            5169 5217 5294 -1 
            5044 5169 5121 -1 
            4689 4737 4814 -1 
            4862 4737 4785 -1 
            4737 4862 4814 -1 
            4737 4660 4785 -1 
            4737 4689 4612 -1 
            4660 4737 4612 -1 
            4862 4939 4814 -1 
            4939 4891 4814 -1 
            4939 5064 5016 -1 
            4891 4939 5016 -1 
            3978 4026 4103 -1 
            4026 4151 4103 -1 
            4151 4026 4074 -1 
            4026 3949 4074 -1 
            3949 4026 3901 -1 
            4026 3978 3901 -1 
            4007 3930 4055 -1 
            3930 3978 4055 -1 
            3930 4007 3882 -1 
            3978 3930 3853 -1 
            3930 3882 3805 -1 
            3853 3930 3805 -1 
            3785 3833 3910 -1 
            3833 3958 3910 -1 
            4102 4179 4054 -1 
            4102 4025 4150 -1 
            4102 4150 4227 -1 
            4179 4102 4227 -1 
            3977 4102 4054 -1 
            4025 4102 3977 -1 
            4150 4275 4227 -1 
            3814 3891 3766 -1 
            3814 3766 3689 -1 
            3939 3862 3987 -1 
            3891 3939 4016 -1 
            3939 3814 3862 -1 
            3814 3939 3891 -1 
            4064 3939 3987 -1 
            4016 3939 4064 -1 
            3766 3641 3689 -1 
            3641 3564 3689 -1 
            3420 3497 3372 -1 
            3420 3343 3468 -1 
            3545 3670 3622 -1 
            3497 3545 3622 -1 
            3545 3420 3468 -1 
            3420 3545 3497 -1 
            3161 3084 3209 -1 
            3084 3161 3036 -1 
            3161 3113 3036 -1 
            3353 3276 3401 -1 
            3276 3324 3401 -1 
            3276 3353 3228 -1 
            3747 3699 3622 -1 
            3622 3699 3574 -1 
            3699 3651 3574 -1 
            3651 3699 3776 -1 
            3699 3824 3776 -1 
            3699 3747 3824 -1 
            3526 3603 3478 -1 
            3574 3526 3449 -1 
            3651 3526 3574 -1 
            3603 3526 3651 -1 
            3526 3401 3449 -1 
            3526 3478 3401 -1 
            3661 3536 3584 -1 
            3536 3459 3584 -1 
            3392 3440 3517 -1 
            3440 3565 3517 -1 
            3565 3440 3488 -1 
            3565 3613 3690 -1 
            3613 3738 3690 -1 
            3738 3613 3661 -1 
            3613 3536 3661 -1 
            3613 3565 3488 -1 
            3536 3613 3488 -1 
            3642 3594 3517 -1 
            3565 3642 3517 -1 
            3642 3565 3690 -1 
            3594 3642 3719 -1 
            3767 3642 3690 -1 
            3642 3767 3719 -1 
            3940 3863 3988 -1 
            3863 3911 3988 -1 
            3863 3940 3815 -1 
            3911 3863 3786 -1 
            3863 3738 3786 -1 
            3738 3863 3815 -1 
            5438 5515 5390 -1 
            5438 5563 5515 -1 
            5313 5438 5390 -1 
            5458 5381 5506 -1 
            5381 5458 5333 -1 
            5256 5381 5333 -1 
            5381 5256 5304 -1 
            6208 6160 6083 -1 
            6102 6150 6227 -1 
            6179 6102 6227 -1 
            6102 6179 6054 -1 
            5977 6102 6054 -1 
            5602 5650 5727 -1 
            5650 5602 5525 -1 
            5583 5458 5506 -1 
            5631 5583 5506 -1 
            5554 5631 5506 -1 
            5602 5554 5477 -1 
            5679 5602 5727 -1 
            5679 5727 5804 -1 
            5679 5554 5602 -1 
            5554 5679 5631 -1 
            5708 5583 5631 -1 
            5929 5881 5804 -1 
            5881 5929 6006 -1 
            5929 5977 6054 -1 
            6006 5929 6054 -1 
            5400 5448 5525 -1 
            5448 5371 5496 -1 
            4968 4920 4843 -1 
            4920 4795 4843 -1 
            4795 4920 4872 -1 
            4872 4920 4997 -1 
            4997 4920 5045 -1 
            4920 4968 5045 -1 
            5236 5159 5284 -1 
            4832 4957 4909 -1 
            5351 5428 5303 -1 
            5351 5476 5428 -1 
            5351 5303 5226 -1 
            5274 5351 5226 -1 
            5505 5428 5553 -1 
            5505 5582 5457 -1 
            5380 5457 5332 -1 
            5428 5380 5303 -1 
            5505 5380 5428 -1 
            5380 5505 5457 -1 
            5380 5255 5303 -1 
            5380 5332 5255 -1 
            4985 4937 4860 -1 
            4908 4985 4860 -1 
            4985 4908 5033 -1 
            4985 5062 4937 -1 
            4956 5081 5033 -1 
            5081 5158 5033 -1 
            5081 5206 5158 -1 
            5206 5081 5129 -1 
            5052 4975 5100 -1 
            4408 4485 4360 -1 
            4408 4331 4456 -1 
            4533 4408 4456 -1 
            4485 4408 4533 -1 
            4331 4408 4283 -1 
            4408 4360 4283 -1 
            5177 5302 5254 -1 
            5177 5225 5302 -1 
            5129 5177 5254 -1 
            5225 5177 5100 -1 
            5177 5052 5100 -1 
            5052 5177 5129 -1 
            5552 5504 5427 -1 
            5504 5379 5427 -1 
            5504 5629 5581 -1 
            5629 5504 5552 -1 
            5158 5110 5033 -1 
            5110 4985 5033 -1 
            4985 5110 5062 -1 
            5014 4889 4937 -1 
            5062 5014 4937 -1 
            5245 5322 5197 -1 
            5245 5168 5293 -1 
            5245 5293 5370 -1 
            5322 5245 5370 -1 
            5120 5245 5197 -1 
            5168 5245 5120 -1 
            5437 5389 5312 -1 
            5562 5437 5485 -1 
            5341 5418 5293 -1 
            5389 5341 5264 -1 
            5418 5341 5466 -1 
            5341 5389 5466 -1 
            5341 5293 5216 -1 
            5264 5341 5216 -1 
            5668 5543 5591 -1 
            5543 5466 5591 -1 
            5543 5418 5466 -1 
            5543 5495 5418 -1 
            4668 4745 4620 -1 
            4668 4716 4793 -1 
            4745 4668 4793 -1 
            5072 5120 5197 -1 
            5120 5072 4995 -1 
            5072 4947 4995 -1 
            5072 5024 4947 -1 
            5178 5101 5226 -1 
            4764 4889 4841 -1 
            4764 4812 4889 -1 
            4716 4764 4841 -1 
            4639 4764 4716 -1 
            4485 4562 4437 -1 
            4245 4197 4120 -1 
            4197 4245 4322 -1 
            4274 4197 4322 -1 
            4245 4293 4370 -1 
            4168 4293 4245 -1 
            4399 4447 4524 -1 
            4447 4399 4322 -1 
            4399 4274 4322 -1 
            4399 4351 4274 -1 
            4340 4263 4388 -1 
            4225 4177 4100 -1 
            4148 4225 4100 -1 
            4225 4148 4273 -1 
            4177 4225 4302 -1 
            4350 4225 4273 -1 
            4225 4350 4302 -1 
            4148 4071 4196 -1 
            4619 4696 4571 -1 
            4802 4725 4850 -1 
            4869 4946 4821 -1 
            4705 4782 4657 -1 
            4811 4859 4936 -1 
            4984 4859 4907 -1 
            4859 4984 4936 -1 
            4859 4782 4907 -1 
            4782 4859 4734 -1 
            4859 4811 4734 -1 
            4888 4811 4936 -1 
            4888 4965 4840 -1 
            4811 4686 4734 -1 
            4686 4609 4734 -1 
            4561 4686 4638 -1 
            4609 4686 4561 -1 
            4369 4244 4292 -1 
            4321 4244 4369 -1 
            4244 4321 4196 -1 
            4523 4475 4398 -1 
            4446 4523 4398 -1 
            4523 4446 4571 -1 
            4475 4523 4600 -1 
            4792 4715 4840 -1 
            5455 5378 5503 -1 
            5032 5109 4984 -1 
            5311 5186 5234 -1 
            5186 5109 5234 -1 
            4139 4187 4264 -1 
            4187 4312 4264 -1 
            4312 4187 4235 -1 
            4235 4187 4110 -1 
            4216 4139 4264 -1 
            4216 4293 4168 -1 
            4341 4216 4264 -1 
            4293 4216 4341 -1 
            4043 4168 4120 -1 
            4043 3918 3966 -1 
            4014 4062 4139 -1 
            4187 4062 4110 -1 
            4062 4187 4139 -1 
            3937 4062 4014 -1 
            4081 4033 3956 -1 
            4033 4158 4110 -1 
            4158 4033 4081 -1 
            3764 3889 3841 -1 
            3764 3812 3889 -1 
            3764 3841 3716 -1 
            3764 3687 3812 -1 
            3639 3764 3716 -1 
            3687 3764 3639 -1 
            3870 3947 3822 -1 
            3918 3870 3793 -1 
            3870 3822 3745 -1 
            3793 3870 3745 -1 
            3687 3610 3735 -1 
            3610 3658 3735 -1 
            3610 3687 3562 -1 
            3658 3610 3533 -1 
            3677 3629 3552 -1 
            3629 3504 3552 -1 
            3504 3629 3581 -1 
            3831 3879 3956 -1 
            4304 4381 4256 -1 
            4304 4429 4381 -1 
            4179 4304 4256 -1 
            4304 4179 4227 -1 
            4621 4573 4496 -1 
            4573 4621 4698 -1 
            4621 4746 4698 -1 
            4746 4621 4669 -1 
            4592 4717 4669 -1 
            4342 4419 4294 -1 
            4217 4342 4294 -1 
            4265 4342 4217 -1 
            4851 4774 4899 -1 
            4774 4851 4726 -1 
            4697 4774 4649 -1 
            4774 4726 4649 -1 
            4188 4236 4313 -1 
            4236 4361 4313 -1 
            4361 4236 4284 -1 
            4284 4236 4159 -1 
            4111 4236 4188 -1 
            4236 4111 4159 -1 
            4390 4265 4313 -1 
            4390 4342 4265 -1 
            4409 4361 4284 -1 
            4659 4582 4707 -1 
            4659 4736 4611 -1 
            4582 4630 4707 -1 
            4707 4630 4755 -1 
            4630 4678 4755 -1 
            4486 4611 4563 -1 
            4409 4486 4361 -1 
            8322 8197 8245 -1 
            8322 8274 8197 -1 
            8370 8322 8245 -1 
            8274 8322 8399 -1 
            8322 8447 8399 -1 
            8322 8370 8447 -1 
            8216 8168 8091 -1 
            8168 8245 8120 -1 
            8168 8293 8245 -1 
            8168 8216 8293 -1 
            7995 8043 8120 -1 
            8043 8168 8120 -1 
            8168 8043 8091 -1 
            8043 7966 8091 -1 
            8043 7995 7918 -1 
            7966 8043 7918 -1 
            7745 7822 7697 -1 
            8024 8101 7976 -1 
            8024 8149 8101 -1 
            8149 8024 8072 -1 
            8081 8004 8129 -1 
            8081 7956 8004 -1 
            7956 8081 8033 -1 
            7966 8014 8091 -1 
            8014 8139 8091 -1 
            8139 8014 8062 -1 
            7793 7841 7918 -1 
            7841 7966 7918 -1 
            7649 7726 7601 -1 
            7649 7572 7697 -1 
            8130 8303 8082 -1 
            8130 8178 8303 -1 
            8130 8053 8178 -1 
            7909 7957 8082 -1 
            7957 7909 7832 -1 
            7880 7957 7832 -1 
            8483 8493 8464 -1 
            8503 8493 8502 -1 
            8493 8483 8502 -1 
            8436 8484 8465 -1 
            7474 7599 7551 -1 
            7954 7906 7829 -1 
            7906 7781 7829 -1 
            7906 7954 8031 -1 
            7552 7427 7475 -1 
            7552 7504 7427 -1 
            7350 7302 7225 -1 
            7273 7350 7225 -1 
            7427 7350 7475 -1 
            7302 7350 7427 -1 
            7436 7561 7513 -1 
            6475 6552 6427 -1 
            6552 6504 6427 -1 
            6504 6379 6427 -1 
            6379 6504 6456 -1 
            6648 6571 6696 -1 
            6571 6648 6523 -1 
            6629 6504 6552 -1 
            7148 7273 7225 -1 
            7100 7148 7225 -1 
            7177 7100 7225 -1 
            7302 7177 7225 -1 
            6917 6965 7042 -1 
            6792 6917 6869 -1 
            7023 7148 7100 -1 
            6994 7042 7119 -1 
            6994 6917 7042 -1 
            6994 6946 6869 -1 
            6917 6994 6869 -1 
            6821 6773 6696 -1 
            6773 6648 6696 -1 
            6648 6773 6725 -1 
            7167 7292 7244 -1 
            7244 7292 7369 -1 
            7138 7013 7061 -1 
            6619 6744 6696 -1 
            6744 6821 6696 -1 
            6744 6792 6869 -1 
            6821 6744 6869 -1 
            8427 8475 8504 -1 
            8379 8427 8504 -1 
            8302 8427 8379 -1 
            8408 8331 8456 -1 
            8331 8379 8456 -1 
            8379 8331 8254 -1 
            8350 8273 8398 -1 
            8350 8427 8302 -1 
            8475 8350 8398 -1 
            8427 8350 8475 -1 
            8350 8225 8273 -1 
            8225 8350 8302 -1 
            8004 8052 8129 -1 
            7716 7793 7668 -1 
            7764 7716 7639 -1 
            7716 7841 7793 -1 
            7841 7716 7764 -1 
            7543 7591 7668 -1 
            7591 7716 7668 -1 
            7716 7591 7639 -1 
            5515 5467 5390 -1 
            5467 5342 5390 -1 
            5717 5640 5765 -1 
            5842 5717 5765 -1 
            5592 5669 5544 -1 
            5640 5592 5515 -1 
            5717 5592 5640 -1 
            5592 5717 5669 -1 
            5592 5467 5515 -1 
            5467 5592 5544 -1 
            5842 5967 5919 -1 
            5890 5967 5842 -1 
            5967 5890 6015 -1 
            5967 6044 5919 -1 
            6092 5967 6015 -1 
            5967 6092 6044 -1 
            5774 5697 5822 -1 
            5966 6043 5918 -1 
            5851 5803 5726 -1 
            5774 5851 5726 -1 
            5697 5649 5572 -1 
            5649 5601 5524 -1 
            5572 5649 5524 -1 
            5601 5649 5726 -1 
            5649 5774 5726 -1 
            5774 5649 5697 -1 
            5697 5745 5822 -1 
            5870 5745 5793 -1 
            5745 5870 5822 -1 
            5793 5745 5668 -1 
            5620 5697 5572 -1 
            5620 5543 5668 -1 
            5745 5620 5668 -1 
            5620 5745 5697 -1 
            5495 5620 5572 -1 
            5543 5620 5495 -1 
            6082 6034 5957 -1 
            6409 6486 6361 -1 
            6284 6409 6361 -1 
            6582 6707 6659 -1 
            6456 6533 6408 -1 
            6533 6485 6408 -1 
            6610 6533 6658 -1 
            6533 6610 6485 -1 
            6283 6331 6408 -1 
            6312 6437 6389 -1 
            6254 6379 6331 -1 
            6379 6254 6302 -1 
            6302 6254 6177 -1 
            6254 6129 6177 -1 
            6698 6746 6823 -1 
            6746 6794 6871 -1 
            6823 6746 6871 -1 
            6794 6746 6669 -1 
            6746 6621 6669 -1 
            6621 6746 6698 -1 
            6342 6419 6294 -1 
            6217 6342 6294 -1 
            6265 6342 6217 -1 
            6544 6592 6669 -1 
            6621 6544 6669 -1 
            6640 6563 6688 -1 
            6640 6717 6592 -1 
            6765 6640 6688 -1 
            6717 6640 6765 -1 
            6563 6611 6688 -1 
            6736 6611 6659 -1 
            6611 6736 6688 -1 
            6611 6563 6486 -1 
            6515 6640 6592 -1 
            6640 6515 6563 -1 
            7178 7130 7053 -1 
            7130 7005 7053 -1 
            7005 7130 7082 -1 
            7130 7207 7082 -1 
            7130 7178 7255 -1 
            7207 7130 7255 -1 
            7322 7274 7197 -1 
            7447 7370 7495 -1 
            7447 7322 7370 -1 
            7572 7447 7495 -1 
            7024 6976 6899 -1 
            6928 6976 7053 -1 
            6976 7101 7053 -1 
            7101 6976 7024 -1 
            7072 7120 7197 -1 
            7120 7043 7168 -1 
            7043 7120 6995 -1 
            7120 7072 6995 -1 
            7149 7101 7024 -1 
            7072 7149 7024 -1 
            7149 7072 7197 -1 
            7101 7149 7226 -1 
            7274 7149 7197 -1 
            7149 7274 7226 -1 
            6803 6851 6928 -1 
            6976 6851 6899 -1 
            6851 6976 6928 -1 
            6755 6832 6707 -1 
            6755 6803 6880 -1 
            6832 6755 6880 -1 
            7111 7034 7159 -1 
            7236 7111 7159 -1 
            6986 7111 7063 -1 
            7034 7111 6986 -1 
            6890 6842 6765 -1 
            6717 6842 6794 -1 
            6842 6717 6765 -1 
            6842 6919 6794 -1 
            6919 6842 6967 -1 
            6842 6890 6967 -1 
            6813 6890 6765 -1 
            6813 6736 6861 -1 
            6813 6765 6688 -1 
            6736 6813 6688 -1 
            6890 6938 7015 -1 
            6938 6986 7063 -1 
            7015 6938 7063 -1 
            6986 6938 6861 -1 
            6938 6813 6861 -1 
            6813 6938 6890 -1 
            7736 7563 7611 -1 
            7736 7611 7659 -1 
            7784 7736 7659 -1 
            7563 7736 7515 -1 
            7736 7909 7515 -1 
            7736 7784 7909 -1 
            7265 7313 7390 -1 
            7438 7313 7361 -1 
            7313 7438 7390 -1 
            7313 7236 7361 -1 
            7188 7265 7140 -1 
            7188 7111 7236 -1 
            7313 7188 7236 -1 
            7188 7313 7265 -1 
            7188 7140 7063 -1 
            7111 7188 7063 -1 
            6246 6121 6169 -1 
            6121 6044 6169 -1 
            6255 6303 6380 -1 
            7562 7514 7437 -1 
            7514 7389 7437 -1 
            7514 7562 7639 -1 
            7591 7514 7639 -1 
            7370 7293 7418 -1 
            7216 7293 7168 -1 
            2609 2561 2484 -1 
            2532 2609 2484 -1 
            2609 2532 2657 -1 
            2561 2609 2686 -1 
            2609 2734 2686 -1 
            2734 2609 2657 -1 
            2148 2100 2023 -1 
            2100 1975 2023 -1 
            1975 2100 2052 -1 
            2100 2148 2225 -1 
            2196 2119 2244 -1 
            2321 2196 2244 -1 
            2196 2071 2119 -1 
            2071 2196 2148 -1 
            2350 2302 2225 -1 
            2369 2292 2417 -1 
            2369 2417 2494 -1 
            2292 2369 2244 -1 
            2369 2321 2244 -1 
            2475 2398 2523 -1 
            2398 2475 2350 -1 
            2532 2455 2580 -1 
            2378 2455 2330 -1 
            1792 1917 1869 -1 
            1792 1840 1917 -1 
            2129 2177 2254 -1 
            2177 2302 2254 -1 
            2302 2177 2225 -1 
            2177 2100 2225 -1 
            2177 2129 2052 -1 
            2100 2177 2052 -1 
            2754 2677 2802 -1 
            2437 2360 2485 -1 
            2360 2408 2485 -1 
            2312 2360 2437 -1 
            2360 2312 2235 -1 
            2331 2206 2254 -1 
            2408 2533 2485 -1 
            2533 2610 2485 -1 
            2533 2658 2610 -1 
            2533 2581 2658 -1 
            3187 3264 3139 -1 
            3014 2937 3062 -1 
            3014 3091 2966 -1 
            3014 2966 2889 -1 
            2937 3014 2889 -1 
            3139 3014 3062 -1 
            3091 3014 3139 -1 
            2927 2879 2802 -1 
            2879 2927 3004 -1 
            2927 3052 3004 -1 
            3052 2927 2975 -1 
            2831 2956 2908 -1 
            2879 2956 2831 -1 
            2956 2879 3004 -1 
            2908 2956 3033 -1 
            3081 2956 3004 -1 
            2956 3081 3033 -1 
            3158 3283 3235 -1 
            3283 3360 3235 -1 
            3283 3408 3360 -1 
            3408 3283 3331 -1 
            3427 3504 3379 -1 
            3427 3379 3302 -1 
            3504 3427 3552 -1 
            3427 3475 3552 -1 
            3254 3129 3177 -1 
            3129 3052 3177 -1 
            3052 3129 3004 -1 
            3129 3081 3004 -1 
            3388 3311 3436 -1 
            3263 3388 3340 -1 
            3311 3388 3263 -1 
            3734 3657 3782 -1 
            3686 3734 3811 -1 
            3859 3734 3782 -1 
            3734 3859 3811 -1 
            3638 3763 3715 -1 
            3638 3686 3763 -1 
            3686 3638 3561 -1 
            3869 3821 3744 -1 
            3821 3696 3744 -1 
            3696 3821 3773 -1 
            3773 3821 3898 -1 
            3840 3917 3792 -1 
            3917 3869 3792 -1 
            3994 3917 4042 -1 
            3917 3994 3869 -1 
            3609 3686 3561 -1 
            3734 3609 3657 -1 
            3609 3734 3686 -1 
            3455 3532 3407 -1 
            3532 3455 3580 -1 
            3532 3580 3657 -1 
            3609 3532 3657 -1 
            3186 3311 3263 -1 
            3042 2965 3090 -1 
            3378 3455 3330 -1 
            3253 3378 3330 -1 
            3378 3426 3503 -1 
            3455 3378 3503 -1 
            3311 3359 3436 -1 
            3475 3523 3600 -1 
            3523 3648 3600 -1 
            3523 3398 3446 -1 
            3398 3523 3475 -1 
            3408 3485 3360 -1 
            3485 3408 3533 -1 
            3485 3610 3562 -1 
            3610 3485 3533 -1 
            3445 3522 3397 -1 
            3522 3445 3570 -1 
            3301 3253 3176 -1 
            3224 3301 3176 -1 
            3378 3301 3426 -1 
            3301 3378 3253 -1 
            7973 8021 8098 -1 
            8050 7973 8098 -1 
            7973 8050 7925 -1 
            7973 7896 8021 -1 
            8117 8194 8069 -1 
            8194 8146 8069 -1 
            8194 8117 8242 -1 
            8146 8194 8271 -1 
            8194 8319 8271 -1 
            8319 8194 8242 -1 
            8108 7983 8031 -1 
            7983 7906 8031 -1 
            8156 8204 8281 -1 
            8204 8156 8079 -1 
            8156 8108 8031 -1 
            8079 8156 8031 -1 
            8127 8204 8079 -1 
            8127 8050 8175 -1 
            8127 8175 8252 -1 
            8204 8127 8252 -1 
            8127 8079 8002 -1 
            8050 8127 8002 -1 
            7724 7599 7647 -1 
            8473 8348 8396 -1 
            8473 8425 8348 -1 
            8444 8473 8396 -1 
            8473 8444 8492 -1 
            8473 8492 8502 -1 
            8425 8473 8502 -1 
            230 182 105 -1 
            57 182 134 -1 
            182 57 105 -1 
            134 182 259 -1 
            259 182 307 -1 
            182 230 307 -1 
            326 278 201 -1 
            230 278 355 -1 
            355 278 403 -1 
            278 326 403 -1 
            153 230 105 -1 
            153 76 201 -1 
            278 153 201 -1 
            153 278 230 -1 
            76 153 28 -1 
            153 105 28 -1 
            633 681 758 -1 
            806 681 729 -1 
            681 806 758 -1 
            681 604 729 -1 
            681 556 604 -1 
            681 633 556 -1 
            1881 1929 2006 -1 
            1987 2035 2112 -1 
            2035 2160 2112 -1 
            2160 2035 2083 -1 
            2093 2016 2141 -1 
            2218 2093 2141 -1 
            1997 2045 2122 -1 
            2314 2237 2362 -1 
            2314 2189 2237 -1 
            2468 2516 2593 -1 
            2343 2468 2420 -1 
            2439 2314 2362 -1 
            2516 2564 2641 -1 
            2439 2564 2516 -1 
            2170 2093 2218 -1 
            2045 2170 2122 -1 
            2093 2170 2045 -1 
            2132 2055 2180 -1 
            1709 1632 1757 -1 
            1632 1709 1584 -1 
            3015 2938 3063 -1 
            3015 3092 2967 -1 
            3015 2967 2890 -1 
            2938 3015 2890 -1 
            3015 3063 3140 -1 
            3092 3015 3140 -1 
            2861 2938 2813 -1 
            2861 2784 2909 -1 
            2986 2861 2909 -1 
            2938 2861 2986 -1 
            3111 3034 3159 -1 
            3034 3111 2986 -1 
            3034 2986 2909 -1 
            2736 2688 2611 -1 
            2688 2736 2813 -1 
            2861 2736 2784 -1 
            2736 2861 2813 -1 
            2246 2294 2371 -1 
            2323 2246 2371 -1 
            2169 2246 2121 -1 
            2246 2169 2294 -1 
            2352 2275 2400 -1 
            2275 2323 2400 -1 
            2275 2352 2227 -1 
            1996 2073 1948 -1 
            1996 1919 2044 -1 
            1996 2044 2121 -1 
            2073 1996 2121 -1 
            1871 1996 1948 -1 
            1919 1996 1871 -1 
            2179 2102 2227 -1 
            2179 2227 2304 -1 
            2256 2179 2304 -1 
            2025 2102 1977 -1 
            2073 2025 1948 -1 
            2506 2381 2429 -1 
            2458 2381 2506 -1 
            2429 2381 2304 -1 
            2381 2256 2304 -1 
            2092 2169 2044 -1 
            2140 2092 2015 -1 
            2217 2092 2140 -1 
            2169 2092 2217 -1 
            2092 1967 2015 -1 
            2092 2044 1967 -1 
            2361 2409 2486 -1 
            2419 2342 2467 -1 
            2342 2390 2467 -1 
            2342 2419 2294 -1 
            2390 2342 2265 -1 
            2217 2342 2294 -1 
            2265 2342 2217 -1 
            2390 2438 2515 -1 
            2438 2563 2515 -1 
            2438 2486 2563 -1 
            2438 2361 2486 -1 
            2438 2390 2313 -1 
            2361 2438 2313 -1 
            2447 2495 2572 -1 
            2803 2726 2851 -1 
            2707 2832 2784 -1 
            2707 2755 2832 -1 
            2755 2707 2630 -1 
            2457 2534 2409 -1 
            82 159 34 -1 
            82 34 5 -1 
            130 82 5 -1 
            82 130 207 -1 
            159 82 207 -1 
            332 284 207 -1 
            284 159 207 -1 
            284 236 159 -1 
            63 140 15 -1 
            217 140 265 -1 
            256 333 208 -1 
            256 131 179 -1 
            131 256 208 -1 
            602 679 554 -1 
            679 602 727 -1 
            602 650 727 -1 
            650 573 698 -1 
            573 448 496 -1 
            428 351 476 -1 
            428 303 351 -1 
            505 630 582 -1 
            670 747 622 -1 
            718 670 593 -1 
            670 718 795 -1 
            747 670 795 -1 
            670 545 593 -1 
            670 622 545 -1 
            256 381 333 -1 
            381 458 333 -1 
            381 506 458 -1 
            199 74 122 -1 
            74 199 151 -1 
            266 218 141 -1 
            218 93 141 -1 
            468 391 516 -1 
            151 103 26 -1 
            103 55 26 -1 
            55 103 180 -1 
            103 228 180 -1 
            103 151 228 -1 
            362 410 487 -1 
            410 535 487 -1 
            535 410 458 -1 
            458 410 333 -1 
            333 410 285 -1 
            410 362 285 -1 
            330 282 205 -1 
            225 273 350 -1 
            100 225 177 -1 
            398 321 446 -1 
            273 321 398 -1 
            321 273 196 -1 
            216 168 91 -1 
            216 293 168 -1 
            43 120 72 -1 
            120 43 168 -1 
            120 168 245 -1 
            514 389 437 -1 
            495 370 418 -1 
            370 293 418 -1 
            293 370 245 -1 
            370 322 245 -1 
            351 399 476 -1 
            24 53 5 -1 
            53 130 5 -1 
            303 226 351 -1 
            52 100 177 -1 
            23 52 4 -1 
            100 52 23 -1 
            71 148 23 -1 
            148 100 23 -1 
            148 71 196 -1 
            148 225 100 -1 
            273 148 196 -1 
            225 148 273 -1 
            110 33 158 -1 
            110 62 33 -1 
            62 110 187 -1 
            235 110 158 -1 
            110 235 187 -1 
            139 62 187 -1 
            139 216 91 -1 
            139 91 14 -1 
            62 139 14 -1 
            139 187 264 -1 
            216 139 264 -1 
            206 254 331 -1 
            81 206 158 -1 
            475 398 523 -1 
            398 475 350 -1 
            475 427 350 -1 
            254 379 331 -1 
            379 456 331 -1 
            610 658 735 -1 
            687 610 735 -1 
            543 591 668 -1 
            591 514 639 -1 
            620 572 495 -1 
            543 620 495 -1 
            620 543 668 -1 
            466 543 418 -1 
            466 389 514 -1 
            591 466 514 -1 
            466 591 543 -1 
            706 658 581 -1 
            706 783 658 -1 
            600 475 523 -1 
            763 888 840 -1 
            763 811 888 -1 
            907 782 830 -1 
            782 657 705 -1 
            821 773 696 -1 
            898 773 821 -1 
            869 821 744 -1 
            792 869 744 -1 
            1119 1042 1167 -1 
            1119 994 1042 -1 
            994 1119 1071 -1 
            1186 1138 1061 -1 
            1311 1186 1234 -1 
            1138 1090 1013 -1 
            1090 1042 965 -1 
            1013 1090 965 -1 
            1042 1090 1167 -1 
            1090 1215 1167 -1 
            1090 1138 1215 -1 
            1503 1455 1378 -1 
            1407 1455 1532 -1 
            1455 1580 1532 -1 
            1455 1503 1580 -1 
            1629 1581 1504 -1 
            1523 1600 1475 -1 
            1523 1398 1446 -1 
            1398 1523 1475 -1 
            1677 1600 1725 -1 
            1552 1629 1504 -1 
            1600 1552 1475 -1 
            1552 1677 1629 -1 
            1677 1552 1600 -1 
            1957 1880 2005 -1 
            1957 2034 1909 -1 
            1832 1957 1909 -1 
            1880 1957 1832 -1 
            1957 2005 2082 -1 
            2034 1957 2082 -1 
            1928 1851 1976 -1 
            1880 1928 2005 -1 
            2053 1928 1976 -1 
            1928 2053 2005 -1 
            1851 1803 1726 -1 
            1803 1928 1880 -1 
            1928 1803 1851 -1 
            1659 1784 1736 -1 
            1745 1697 1620 -1 
            1822 1745 1870 -1 
            1745 1822 1697 -1 
            2139 2062 2187 -1 
            2139 2216 2091 -1 
            2264 2139 2187 -1 
            2216 2139 2264 -1 
            2129 2004 2052 -1 
            2081 2004 2129 -1 
            2004 2081 1956 -1 
            2081 2206 2158 -1 
            2081 2129 2206 -1 
            1879 2004 1956 -1 
            1581 1658 1533 -1 
            1246 1371 1323 -1 
            1227 1150 1275 -1 
            1227 1304 1179 -1 
            1352 1227 1275 -1 
            1227 1352 1304 -1 
            1006 958 881 -1 
            1006 1131 1083 -1 
            958 1006 1083 -1 
            1302 1225 1350 -1 
            1302 1379 1254 -1 
            1273 1225 1148 -1 
            1273 1398 1350 -1 
            1225 1273 1350 -1 
            1215 1292 1167 -1 
            1398 1321 1446 -1 
            1321 1369 1446 -1 
            1273 1321 1398 -1 
            879 754 802 -1 
            927 879 802 -1 
            879 927 1004 -1 
            956 879 1004 -1 
            1004 1052 1129 -1 
            927 1052 1004 -1 
            1177 1100 1225 -1 
            1177 1254 1129 -1 
            1052 1177 1129 -1 
            1177 1052 1100 -1 
            1177 1302 1254 -1 
            1302 1177 1225 -1 
            687 764 639 -1 
            1081 1004 1129 -1 
            1081 956 1004 -1 
            956 1081 1033 -1 
            860 985 937 -1 
            937 985 1062 -1 
            853 930 805 -1 
            978 930 853 -1 
            1007 930 1055 -1 
            930 978 1055 -1 
            1699 1622 1747 -1 
            1622 1670 1747 -1 
            1622 1699 1574 -1 
            1497 1622 1574 -1 
            1795 1718 1843 -1 
            1670 1718 1795 -1 
            1593 1468 1516 -1 
            1593 1718 1670 -1 
            1805 1728 1853 -1 
            1266 1141 1189 -1 
            1439 1362 1487 -1 
            1439 1564 1516 -1 
            1564 1439 1487 -1 
            1612 1535 1660 -1 
            1612 1564 1487 -1 
            1535 1612 1487 -1 
            1170 1295 1247 -1 
            1295 1372 1247 -1 
            323 198 246 -1 
            323 448 400 -1 
            44 121 73 -1 
            121 198 73 -1 
            198 121 246 -1 
            515 640 592 -1 
            1765 1640 1688 -1 
            1784 1861 1736 -1 
            1861 1813 1736 -1 
            1861 1784 1909 -1 
            1813 1861 1938 -1 
            1986 1861 1909 -1 
            1861 1986 1938 -1 
            757 632 680 -1 
            680 632 555 -1 
            632 507 555 -1 
            507 632 584 -1 
            661 613 536 -1 
            488 613 565 -1 
            613 488 536 -1 
            613 690 565 -1 
            690 613 738 -1 
            613 661 738 -1 
            1354 1229 1277 -1 
            1354 1306 1229 -1 
            1354 1277 1402 -1 
            1354 1431 1306 -1 
            1076 1153 1028 -1 
            1364 1239 1287 -1 
            921 998 873 -1 
            969 921 844 -1 
            796 921 873 -1 
            844 921 796 -1 
            815 940 892 -1 
            863 940 815 -1 
            1171 1219 1296 -1 
            1094 1219 1171 -1 
            1305 1382 1257 -1 
            1430 1382 1305 -1 
            2370 2293 2418 -1 
            2370 2245 2293 -1 
            2370 2418 2495 -1 
            2447 2370 2495 -1 
            1899 2024 1976 -1 
            1851 1899 1976 -1 
            2072 1995 2120 -1 
            2351 2226 2274 -1 
            2226 2149 2274 -1 
            2149 2226 2101 -1 
            2226 2178 2101 -1 
            2678 2601 2726 -1 
            2678 2755 2630 -1 
            2553 2678 2630 -1 
            2601 2678 2553 -1 
            2678 2803 2755 -1 
            2803 2678 2726 -1 
            2159 2034 2082 -1 
            2476 2601 2553 -1 
            2428 2476 2553 -1 
            2399 2476 2351 -1 
            2476 2428 2351 -1 
            2899 2976 2851 -1 
            3543 3466 3591 -1 
            3045 3093 3170 -1 
            3170 3093 3218 -1 
            3093 3141 3218 -1 
            3093 3045 2968 -1 
            2997 3122 3074 -1 
            2997 3045 3122 -1 
            2997 3074 2949 -1 
            3045 2997 2920 -1 
            3564 3612 3689 -1 
            3314 3266 3189 -1 
            3237 3314 3189 -1 
            3333 3410 3285 -1 
            3333 3256 3381 -1 
            3333 3285 3208 -1 
            3256 3333 3208 -1 
            3631 3679 3756 -1 
            3410 3458 3535 -1 
            3458 3333 3381 -1 
            3333 3458 3410 -1 
            3929 3852 3977 -1 
            4092 4140 4217 -1 
            4265 4140 4188 -1 
            4140 4265 4217 -1 
            4188 4140 4063 -1 
            4140 4015 4063 -1 
            4140 4092 4015 -1 
            3477 3400 3525 -1 
            3400 3477 3352 -1 
            3477 3429 3352 -1 
            3573 3650 3525 -1 
            3573 3525 3448 -1 
            3496 3573 3448 -1 
            3592 3467 3515 -1 
            2708 2785 2660 -1 
            2756 2708 2631 -1 
            2583 2708 2660 -1 
            2631 2708 2583 -1 
            2881 2833 2756 -1 
            2708 2833 2785 -1 
            2833 2708 2756 -1 
            2785 2833 2910 -1 
            2958 3035 2910 -1 
            2958 2881 3006 -1 
            2833 2958 2910 -1 
            2958 2833 2881 -1 
            2987 2862 2910 -1 
            3035 2987 2910 -1 
            4072 4024 3947 -1 
            4197 4072 4120 -1 
            4024 3899 3947 -1 
            3947 3899 3822 -1 
            3822 3899 3774 -1 
            3899 3851 3774 -1 
            3976 4053 3928 -1 
            3976 3899 4024 -1 
            4053 3976 4101 -1 
            3976 4024 4101 -1 
            3851 3976 3928 -1 
            3899 3976 3851 -1 
            3121 3169 3246 -1 
            3304 3227 3352 -1 
            3150 3227 3102 -1 
            2842 2919 2794 -1 
            2717 2842 2794 -1 
            2967 2842 2890 -1 
            2919 2842 2967 -1 
            2871 2996 2948 -1 
            2919 2996 2871 -1 
            2996 3073 2948 -1 
            2996 3121 3073 -1 
            3073 3025 2948 -1 
            2977 3025 3102 -1 
            3150 3025 3073 -1 
            3025 3150 3102 -1 
            2900 2977 2852 -1 
            2775 2900 2852 -1 
            3025 2900 2948 -1 
            2900 3025 2977 -1 
            2977 2929 2852 -1 
            2852 2929 2804 -1 
            2929 2881 2804 -1 
            2881 2929 3006 -1 
            3054 2977 3102 -1 
            2929 3054 3006 -1 
            3054 2929 2977 -1 
            2746 2823 2698 -1 
            2823 2775 2698 -1 
            2823 2746 2871 -1 
            2823 2900 2775 -1 
            2823 2871 2948 -1 
            2900 2823 2948 -1 
            2775 2650 2698 -1 
            2650 2573 2698 -1 
            2525 2650 2602 -1 
            2650 2525 2573 -1 
            2679 2554 2602 -1 
            2554 2679 2631 -1 
            2756 2679 2804 -1 
            2679 2756 2631 -1 
            3371 3323 3246 -1 
            3294 3371 3246 -1 
            3371 3294 3419 -1 
            3323 3371 3448 -1 
            3371 3496 3448 -1 
            3496 3371 3419 -1 
            3438 3390 3313 -1 
            3390 3265 3313 -1 
            3265 3390 3342 -1 
            3390 3467 3342 -1 
            3467 3390 3515 -1 
            3390 3438 3515 -1 
            3582 3505 3630 -1 
            3457 3582 3534 -1 
            3582 3457 3505 -1 
            3524 3399 3447 -1 
            3476 3399 3524 -1 
            1613 1488 1536 -1 
            1392 1440 1517 -1 
            1469 1392 1517 -1 
            1392 1267 1315 -1 
            1440 1392 1315 -1 
            1450 1498 1575 -1 
            1498 1623 1575 -1 
            1623 1498 1546 -1 
            1546 1498 1421 -1 
            1373 1450 1325 -1 
            1421 1373 1296 -1 
            1498 1373 1421 -1 
            1373 1498 1450 -1 
            1373 1248 1296 -1 
            1373 1325 1248 -1 
            1430 1478 1555 -1 
            1478 1603 1555 -1 
            1603 1478 1526 -1 
            1478 1401 1526 -1 
            1478 1430 1353 -1 
            1401 1478 1353 -1 
            1507 1430 1555 -1 
            1459 1507 1584 -1 
            1382 1507 1459 -1 
            1507 1382 1430 -1 
            1632 1507 1555 -1 
            1507 1632 1584 -1 
            1603 1651 1728 -1 
            1651 1699 1776 -1 
            1728 1651 1776 -1 
            1699 1651 1574 -1 
            1651 1526 1574 -1 
            1651 1603 1526 -1 
            1372 1420 1497 -1 
            1420 1343 1468 -1 
            1420 1295 1343 -1 
            1295 1420 1372 -1 
            1401 1324 1449 -1 
            1324 1372 1449 -1 
            1324 1401 1276 -1 
            1372 1324 1247 -1 
            1199 1324 1276 -1 
            1324 1199 1247 -1 
            2334 2382 2459 -1 
            2382 2334 2257 -1 
            2334 2286 2209 -1 
            2286 2161 2209 -1 
            2161 2286 2238 -1 
            2238 2286 2363 -1 
            2363 2286 2411 -1 
            2286 2334 2411 -1 
            2382 2507 2459 -1 
            2507 2382 2430 -1 
            2584 2709 2661 -1 
            2584 2632 2709 -1 
            2584 2661 2536 -1 
            2584 2507 2632 -1 
            2584 2536 2459 -1 
            2507 2584 2459 -1 
            2805 2930 2882 -1 
            2930 3007 2882 -1 
            2930 3055 3007 -1 
            2930 2978 3055 -1 
            2670 2747 2622 -1 
            2747 2699 2622 -1 
            2632 2680 2757 -1 
            2680 2805 2757 -1 
            2199 2247 2324 -1 
            2247 2372 2324 -1 
            2247 2199 2122 -1 
            2170 2247 2122 -1 
            2190 2065 2113 -1 
            2142 2065 2190 -1 
            576 624 701 -1 
            653 576 701 -1 
            576 653 528 -1 
            624 576 499 -1 
            451 576 528 -1 
            576 451 499 -1 
            855 730 778 -1 
            855 807 730 -1 
            903 855 778 -1 
            807 855 932 -1 
            980 855 903 -1 
            932 855 980 -1 
            759 634 682 -1 
            807 759 682 -1 
            759 807 884 -1 
            1537 1460 1585 -1 
            1662 1537 1585 -1 
            1566 1691 1643 -1 
            1518 1566 1643 -1 
            874 797 922 -1 
            999 874 922 -1 
            903 951 1028 -1 
            951 1076 1028 -1 
            1076 951 999 -1 
            951 874 999 -1 
            1191 1066 1114 -1 
            1066 989 1114 -1 
            1066 941 989 -1 
            1066 1018 941 -1 
            1499 1547 1624 -1 
            1547 1499 1422 -1 
            1451 1403 1326 -1 
            1374 1451 1326 -1 
            1374 1297 1422 -1 
            1499 1374 1422 -1 
            1374 1499 1451 -1 
            1278 1230 1153 -1 
            1230 1278 1355 -1 
            1278 1403 1355 -1 
            1403 1278 1326 -1 
            1758 1806 1883 -1 
            1931 1806 1854 -1 
            1806 1931 1883 -1 
            1806 1729 1854 -1 
            1806 1681 1729 -1 
            1806 1758 1681 -1 
            1787 1739 1662 -1 
            1691 1739 1816 -1 
            1739 1864 1816 -1 
            1739 1787 1864 -1 
            1710 1787 1662 -1 
            1758 1710 1633 -1 
            1710 1585 1633 -1 
            1710 1662 1585 -1 
            1835 1758 1883 -1 
            1787 1835 1912 -1 
            1835 1710 1758 -1 
            1710 1835 1787 -1 
            1835 1960 1912 -1 
            1835 1883 1960 -1 
            1825 1777 1700 -1 
            1998 2123 2075 -1 
            1431 1479 1556 -1 
            1604 1479 1527 -1 
            1556 1479 1604 -1 
            1479 1402 1527 -1 
            1479 1354 1402 -1 
            1354 1479 1431 -1 
            1844 1796 1719 -1 
            1796 1671 1719 -1 
            1671 1594 1719 -1 
            1671 1623 1546 -1 
            1594 1671 1546 -1 
            2171 2094 2219 -1 
            2123 2171 2248 -1 
            2248 2171 2296 -1 
            2171 2219 2296 -1 
            3123 3075 2998 -1 
            3075 2950 2998 -1 
            2950 3075 3027 -1 
            3113 3190 3065 -1 
            3094 3219 3171 -1 
            3171 3219 3296 -1 
            3219 3344 3296 -1 
            3219 3267 3344 -1 
            2940 3017 2892 -1 
            2988 2940 2863 -1 
            2940 2988 3065 -1 
            3017 2940 3065 -1 
            2940 2892 2815 -1 
            2863 2940 2815 -1 
            2527 2604 2479 -1 
            2575 2527 2450 -1 
            2402 2527 2479 -1 
            2527 2402 2450 -1 
            2652 2575 2700 -1 
            2652 2700 2777 -1 
            2652 2527 2575 -1 
            2527 2652 2604 -1 
            2556 2681 2633 -1 
            2604 2681 2556 -1 
            2633 2681 2758 -1 
            2681 2806 2758 -1 
            3306 3383 3258 -1 
            3354 3306 3229 -1 
            3181 3306 3258 -1 
            3306 3181 3229 -1 
            3402 3229 3450 -1 
            3402 3354 3229 -1 
            2720 2845 2797 -1 
            2720 2768 2845 -1 
            2672 2720 2797 -1 
            2768 2720 2643 -1 
            2720 2595 2643 -1 
            2595 2720 2672 -1 
            3085 2960 3008 -1 
            2960 2883 3008 -1 
            2960 2835 2883 -1 
            2960 2912 2835 -1 
            2768 2816 2893 -1 
            2816 2691 2739 -1 
            2816 2768 2691 -1 
            2912 2787 2835 -1 
            2662 2787 2739 -1 
            2787 2662 2835 -1 
            3114 3162 3239 -1 
            3210 3162 3085 -1 
            2989 3037 3114 -1 
            3162 3037 3085 -1 
            3037 3162 3114 -1 
            3037 2960 3085 -1 
            3037 2989 2912 -1 
            2960 3037 2912 -1 
            3066 2989 3114 -1 
            3066 3143 3018 -1 
            3066 3191 3143 -1 
            3191 3066 3114 -1 
            2499 2422 2547 -1 
            2451 2499 2576 -1 
            2422 2499 2374 -1 
            2499 2451 2374 -1 
            2499 2624 2576 -1 
            2499 2547 2624 -1 
            3701 3778 3653 -1 
            3701 3624 3749 -1 
            3826 3701 3749 -1 
            3778 3701 3826 -1 
            3576 3701 3653 -1 
            3701 3576 3624 -1 
            3845 3893 3970 -1 
            3893 4018 3970 -1 
            4018 3893 3941 -1 
            3941 3893 3816 -1 
            3893 3768 3816 -1 
            3893 3845 3768 -1 
            3797 3874 3749 -1 
            3845 3797 3720 -1 
            3672 3797 3749 -1 
            3797 3672 3720 -1 
            3922 3845 3970 -1 
            4047 3922 3970 -1 
            3797 3922 3874 -1 
            3922 3797 3845 -1 
            3423 3500 3375 -1 
            3423 3548 3500 -1 
            3298 3423 3375 -1 
            3423 3298 3346 -1 
            2780 2828 2905 -1 
            2953 2828 2876 -1 
            2828 2953 2905 -1 
            2828 2751 2876 -1 
            2751 2828 2703 -1 
            2828 2780 2703 -1 
            2857 2780 2905 -1 
            2982 2857 2905 -1 
            2780 2655 2703 -1 
            2655 2578 2703 -1 
            2655 2607 2530 -1 
            2578 2655 2530 -1 
            2559 2684 2636 -1 
            2684 2559 2607 -1 
            2761 2684 2809 -1 
            2684 2761 2636 -1 
            3059 2982 3107 -1 
            3011 3059 3136 -1 
            3184 3059 3107 -1 
            3059 3184 3136 -1 
            2934 3011 2886 -1 
            2934 2857 2982 -1 
            2934 3059 3011 -1 
            3059 2934 2982 -1 
            2934 2886 2809 -1 
            2857 2934 2809 -1 
            3289 3241 3164 -1 
            3241 3116 3164 -1 
            3241 3366 3318 -1 
            3366 3241 3289 -1 
            2924 3001 2876 -1 
            2924 3049 3001 -1 
            2799 2924 2876 -1 
            2924 2799 2847 -1 
            3049 2972 3097 -1 
            2972 3020 3097 -1 
            2924 2972 3049 -1 
            2972 2924 2847 -1 
            2895 2847 2770 -1 
            2895 2972 2847 -1 
            2972 2895 3020 -1 
            3116 3039 3164 -1 
            2597 2520 2645 -1 
            2597 2674 2549 -1 
            2597 2549 2472 -1 
            2520 2597 2472 -1 
            2597 2645 2722 -1 
            2674 2597 2722 -1 
            2616 2693 2568 -1 
            2664 2616 2539 -1 
            2693 2616 2741 -1 
            2616 2664 2741 -1 
            2539 2616 2491 -1 
            2616 2568 2491 -1 
            2981 2904 3029 -1 
            3106 2981 3029 -1 
            2981 2933 2856 -1 
            2904 2981 2856 -1 
            2760 2837 2712 -1 
            2635 2760 2712 -1 
            2837 2760 2885 -1 
            2760 2808 2885 -1 
            2750 2798 2875 -1 
            2750 2673 2798 -1 
            2683 2760 2635 -1 
            2760 2683 2808 -1 
            2558 2683 2635 -1 
            2808 2731 2856 -1 
            2731 2779 2856 -1 
            2683 2731 2808 -1 
            3077 3125 3202 -1 
            3250 3173 3298 -1 
            3250 3327 3202 -1 
            3125 3250 3202 -1 
            3250 3125 3173 -1 
            3327 3250 3375 -1 
            3250 3298 3375 -1 
            3298 3221 3346 -1 
            3173 3221 3298 -1 
            2855 2903 2980 -1 
            2903 2951 3028 -1 
            2980 2903 3028 -1 
            2807 2855 2932 -1 
            3211 3288 3163 -1 
            3211 3134 3259 -1 
            3336 3211 3259 -1 
            3288 3211 3336 -1 
            3211 3163 3086 -1 
            3134 3211 3086 -1 
            3586 3538 3461 -1 
            3509 3586 3461 -1 
            3586 3509 3634 -1 
            3538 3586 3663 -1 
            3586 3711 3663 -1 
            3711 3586 3634 -1 
            2510 2635 2587 -1 
            2510 2558 2635 -1 
            2558 2510 2433 -1 
            2347 2270 2395 -1 
            2222 2270 2347 -1 
            2280 2357 2232 -1 
            2280 2405 2357 -1 
            2405 2280 2328 -1 
            2376 2501 2453 -1 
            2424 2501 2376 -1 
            2501 2424 2549 -1 
            2501 2578 2453 -1 
            2626 2501 2549 -1 
            2578 2501 2626 -1 
            1972 2020 2097 -1 
            2068 2020 1943 -1 
            2020 1895 1943 -1 
            1895 2020 1972 -1 
            2673 2721 2798 -1 
            2721 2769 2846 -1 
            2798 2721 2846 -1 
            2769 2721 2644 -1 
            2990 2865 2913 -1 
            2865 2990 2942 -1 
            2692 2769 2644 -1 
            2836 2961 2913 -1 
            2058 2010 1933 -1 
            2010 1962 1885 -1 
            1808 1683 1731 -1 
            1885 1808 1933 -1 
            1769 1721 1644 -1 
            1865 1740 1788 -1 
            1913 1865 1788 -1 
            2096 2048 1971 -1 
            2326 2403 2278 -1 
            2451 2403 2326 -1 
            2230 2153 2278 -1 
            2153 2230 2105 -1 
            2019 2096 1971 -1 
            2067 2019 1942 -1 
            2019 2144 2096 -1 
            2144 2019 2067 -1 
            3115 3038 3163 -1 
            3115 3163 3240 -1 
            3115 3067 2990 -1 
            3038 3115 2990 -1 
            3493 3445 3368 -1 
            3416 3493 3368 -1 
            3493 3416 3541 -1 
            3445 3493 3570 -1 
            3618 3493 3541 -1 
            3570 3493 3618 -1 
            3387 3512 3464 -1 
            3339 3387 3464 -1 
            2676 2551 2599 -1 
            2858 2810 2733 -1 
            2733 2810 2685 -1 
            2810 2762 2685 -1 
            2762 2810 2887 -1 
            3060 2935 2983 -1 
            2935 2858 2983 -1 
            2810 2935 2887 -1 
            2935 2810 2858 -1 
            2964 2839 2887 -1 
            3233 3185 3108 -1 
            3185 3060 3108 -1 
            3060 3185 3137 -1 
            3185 3233 3310 -1 
            3243 3195 3118 -1 
            3166 3243 3118 -1 
            3243 3166 3291 -1 
            3195 3243 3320 -1 
            3368 3243 3291 -1 
            3320 3243 3368 -1 
            3185 3262 3137 -1 
            3262 3185 3310 -1 
            3387 3262 3310 -1 
            3262 3387 3339 -1 
            3147 3070 3195 -1 
            3147 3224 3099 -1 
            3224 3147 3272 -1 
            3147 3195 3272 -1 
            3070 2993 3118 -1 
            1859 1782 1907 -1 
            1811 1859 1936 -1 
            1859 1734 1782 -1 
            1734 1859 1811 -1 
            1984 1859 1907 -1 
            1936 1859 1984 -1 
            1840 1763 1888 -1 
            1763 1811 1888 -1 
            1811 1763 1686 -1 
            1657 1734 1609 -1 
            1657 1580 1705 -1 
            1782 1657 1705 -1 
            1734 1657 1782 -1 
            1580 1657 1532 -1 
            1657 1609 1532 -1 
            1340 1292 1215 -1 
            1638 1561 1686 -1 
            1763 1638 1686 -1 
            2234 2282 2359 -1 
            2330 2282 2205 -1 
            2282 2157 2205 -1 
            2282 2234 2157 -1 
            2099 2176 2051 -1 
            2176 2128 2051 -1 
            2176 2099 2224 -1 
            2128 2176 2253 -1 
            2301 2176 2224 -1 
            2253 2176 2301 -1 
            2618 2493 2541 -1 
            733 781 858 -1 
            358 310 233 -1 
            310 358 435 -1 
            358 483 435 -1 
            483 358 406 -1 
            435 483 560 -1 
            531 483 406 -1 
            204 156 79 -1 
            175 300 252 -1 
            175 50 98 -1 
            666 714 791 -1 
            445 397 320 -1 
            464 541 416 -1 
            522 445 570 -1 
            445 522 397 -1 
            743 666 791 -1 
            695 743 820 -1 
            618 695 570 -1 
            618 541 666 -1 
            743 618 666 -1 
            618 743 695 -1 
            1338 1290 1213 -1 
            1531 1454 1579 -1 
            1665 1588 1713 -1 
            1290 1367 1242 -1 
            1367 1319 1242 -1 
            1319 1367 1444 -1 
            1194 1117 1242 -1 
            1194 1271 1146 -1 
            1319 1194 1242 -1 
            1271 1194 1319 -1 
            1117 1165 1242 -1 
            1165 1290 1242 -1 
            1290 1165 1213 -1 
            1165 1088 1213 -1 
            1165 1117 1040 -1 
            1088 1165 1040 -1 
            973 1021 1098 -1 
            1021 1146 1098 -1 
            1261 1213 1136 -1 
            1261 1338 1213 -1 
            1261 1309 1386 -1 
            1338 1261 1386 -1 
            1184 1261 1136 -1 
            1261 1184 1309 -1 
            1309 1232 1357 -1 
            1232 1280 1357 -1 
            1184 1232 1309 -1 
            1232 1184 1107 -1 
            1202 1154 1077 -1 
            1154 1202 1279 -1 
            155 107 30 -1 
            107 155 232 -1 
            155 280 232 -1 
            376 328 251 -1 
            270 347 222 -1 
            270 193 318 -1 
            270 145 193 -1 
            145 270 222 -1 
            347 299 222 -1 
            174 299 251 -1 
            299 174 222 -1 
            299 376 251 -1 
            395 270 318 -1 
            270 395 347 -1 
            49 126 1 -1 
            174 126 49 -1 
            126 174 251 -1 
            712 587 635 -1 
            712 664 587 -1 
            2310 2387 2262 -1 
            2387 2310 2435 -1 
            2310 2185 2233 -1 
            2185 2310 2262 -1 
            2406 2454 2531 -1 
            2483 2406 2531 -1 
            2454 2406 2329 -1 
            2406 2281 2329 -1 
            1887 1964 1839 -1 
            1964 1916 1839 -1 
            1964 1887 2012 -1 
            2099 2022 2147 -1 
            1503 1628 1580 -1 
            1580 1628 1705 -1 
            1628 1753 1705 -1 
            1628 1676 1753 -1 
            1647 1724 1599 -1 
            1724 1676 1599 -1 
            1724 1647 1772 -1 
            1849 1724 1772 -1 
            1330 1253 1378 -1 
            1282 1330 1407 -1 
            1455 1330 1378 -1 
            1330 1455 1407 -1 
            1359 1282 1407 -1 
            1359 1311 1234 -1 
            1282 1359 1234 -1 
            1157 1205 1282 -1 
            1330 1205 1253 -1 
            1205 1330 1282 -1 
            2222 2174 2097 -1 
            2251 2174 2299 -1 
            2174 2222 2299 -1 
            2155 2280 2232 -1 
            1512 1435 1560 -1 
            1512 1589 1464 -1 
            1637 1512 1560 -1 
            1512 1637 1589 -1 
            1483 1435 1358 -1 
            1435 1483 1560 -1 
            1483 1608 1560 -1 
            1483 1531 1608 -1 
            916 868 791 -1 
            868 743 791 -1 
            743 868 820 -1 
            868 945 820 -1 
            945 868 993 -1 
            868 916 993 -1 
            839 916 791 -1 
            887 839 762 -1 
            714 839 791 -1 
            839 714 762 -1 
            810 887 762 -1 
            810 733 858 -1 
            685 810 762 -1 
            733 810 685 -1 
            887 935 1012 -1 
            935 810 858 -1 
            810 935 887 -1 
            1704 1829 1781 -1 
            1829 1906 1781 -1 
            1829 1877 1954 -1 
            1906 1829 1954 -1 
            1771 1848 1723 -1 
            1771 1694 1819 -1 
            1646 1771 1723 -1 
            1694 1771 1646 -1 
            1896 1819 1944 -1 
            1848 1896 1973 -1 
            1896 1771 1819 -1 
            1771 1896 1848 -1 
            1896 2021 1973 -1 
            2021 1896 1944 -1 
            753 878 830 -1 
            753 801 878 -1 
            705 753 830 -1 
            801 753 676 -1 
            628 753 705 -1 
            753 628 676 -1 
            926 849 974 -1 
            801 849 926 -1 
            772 849 724 -1 
            849 801 724 -1 
            1166 1214 1291 -1 
            1089 1214 1166 -1 
            1214 1339 1291 -1 
            1339 1214 1262 -1 
            1339 1416 1291 -1 
            1416 1339 1464 -1 
            1310 1262 1185 -1 
            1435 1310 1358 -1 
            1137 1089 1012 -1 
            1137 1214 1089 -1 
            1262 1137 1185 -1 
            1214 1137 1262 -1 
            1118 1041 1166 -1 
            1041 1089 1166 -1 
            1041 1118 993 -1 
            916 1041 993 -1 
            1791 1743 1666 -1 
            1743 1618 1666 -1 
            1426 1503 1378 -1 
            1474 1426 1349 -1 
            1426 1301 1349 -1 
            1301 1426 1378 -1 
            1551 1474 1599 -1 
            1551 1628 1503 -1 
            1426 1551 1503 -1 
            1551 1426 1474 -1 
            1676 1551 1599 -1 
            1628 1551 1676 -1 
            1099 1147 1224 -1 
            1147 1099 1022 -1 
            974 1099 1051 -1 
            1022 1099 974 -1 
            1272 1195 1320 -1 
            1147 1195 1272 -1 
            1118 1195 1070 -1 
            1195 1147 1070 -1 
            606 731 683 -1 
            654 731 606 -1 
            731 654 779 -1 
            731 808 683 -1 
            875 923 1000 -1 
            971 923 846 -1 
            923 798 846 -1 
            923 875 798 -1 
            1269 1192 1317 -1 
            1394 1269 1317 -1 
            1163 1115 1038 -1 
            1115 990 1038 -1 
            990 1115 1067 -1 
            1115 1192 1067 -1 
            1653 1730 1605 -1 
            1778 1730 1653 -1 
            1807 1730 1855 -1 
            1730 1778 1855 -1 
            1432 1557 1509 -1 
            1509 1557 1634 -1 
            1259 1336 1211 -1 
            1740 1663 1788 -1 
            1346 1423 1298 -1 
            1346 1269 1394 -1 
            1471 1394 1519 -1 
            1471 1548 1423 -1 
            1346 1471 1423 -1 
            1471 1346 1394 -1 
            1663 1538 1586 -1 
            1596 1519 1644 -1 
            1548 1596 1673 -1 
            1596 1471 1519 -1 
            1471 1596 1548 -1 
            1721 1596 1644 -1 
            1596 1721 1673 -1 
            2220 2095 2268 -1 
            2220 2172 2095 -1 
            2172 2220 2297 -1 
            2345 2220 2268 -1 
            2297 2220 2345 -1 
            2172 2124 2047 -1 
            2124 1999 2047 -1 
            2201 2124 2249 -1 
            2124 2172 2249 -1 
            386 434 511 -1 
            434 559 511 -1 
            559 434 482 -1 
            434 386 309 -1 
            818 943 895 -1 
            789 712 837 -1 
            712 789 664 -1 
            1895 1818 1943 -1 
            1837 1962 1914 -1 
            1962 1837 1885 -1 
            1433 1481 1558 -1 
            1443 1568 1520 -1 
            1375 1452 1327 -1 
            1452 1404 1327 -1 
            1452 1375 1500 -1 
            1577 1452 1500 -1 
            1404 1356 1279 -1 
            1356 1433 1308 -1 
            1356 1404 1481 -1 
            1433 1356 1481 -1 
            1798 1875 1750 -1 
            1798 1750 1673 -1 
            1721 1798 1673 -1 
            3980 3903 4028 -1 
            3980 4057 3932 -1 
            4105 3980 4028 -1 
            4057 3980 4105 -1 
            3778 3855 3730 -1 
            3903 3855 3778 -1 
            3855 3980 3932 -1 
            3980 3855 3903 -1 
            3682 3605 3730 -1 
            3682 3634 3557 -1 
            3605 3682 3557 -1 
            3836 3961 3913 -1 
            3413 3490 3365 -1 
            3490 3413 3538 -1 
            3519 3644 3596 -1 
            4211 4288 4163 -1 
            4211 4336 4288 -1 
            4211 4163 4086 -1 
            4134 4211 4086 -1 
            4259 4307 4384 -1 
            4336 4259 4384 -1 
            4211 4259 4336 -1 
            4259 4182 4307 -1 
            4182 4259 4134 -1 
            4259 4211 4134 -1 
            3231 3308 3183 -1 
            3231 3154 3279 -1 
            3356 3231 3279 -1 
            3308 3231 3356 -1 
            3231 3183 3106 -1 
            3154 3231 3106 -1 
            3481 3433 3356 -1 
            3308 3433 3385 -1 
            3433 3308 3356 -1 
            3433 3510 3385 -1 
            3433 3481 3558 -1 
            3510 3433 3558 -1 
            3299 3424 3376 -1 
            3424 3501 3376 -1 
            3549 3424 3472 -1 
            3501 3424 3549 -1 
            3395 3318 3443 -1 
            3395 3270 3318 -1 
            3520 3395 3443 -1 
            3395 3520 3472 -1 
            3078 2953 3001 -1 
            3299 3174 3222 -1 
            3251 3174 3299 -1 
            3222 3174 3097 -1 
            3174 3049 3097 -1 
            3155 3232 3107 -1 
            3232 3155 3280 -1 
            3587 3539 3462 -1 
            3491 3539 3616 -1 
            3539 3664 3616 -1 
            3664 3539 3587 -1 
            3414 3491 3366 -1 
            3414 3337 3462 -1 
            3539 3414 3462 -1 
            3414 3539 3491 -1 
            3414 3366 3289 -1 
            3337 3414 3289 -1 
            3491 3568 3443 -1 
            3568 3520 3443 -1 
            3568 3491 3616 -1 
            3568 3645 3520 -1 
            3798 3875 3750 -1 
            3673 3548 3596 -1 
            3721 3673 3596 -1 
            3673 3798 3750 -1 
            3798 3673 3721 -1 
            3769 3644 3692 -1 
            3769 3721 3644 -1 
            3769 3692 3817 -1 
            3894 3769 3817 -1 
            3654 3606 3529 -1 
            3606 3481 3529 -1 
            3481 3606 3558 -1 
            3779 3904 3856 -1 
            3904 3827 3952 -1 
            3827 3875 3952 -1 
            3779 3827 3904 -1 
            3875 3827 3750 -1 
            3875 4000 3952 -1 
            4000 4077 3952 -1 
            4000 4048 4125 -1 
            4077 4000 4125 -1 
            4221 4298 4173 -1 
            4298 4250 4173 -1 
            4298 4221 4346 -1 
            4250 4298 4375 -1 
            4327 4250 4375 -1 
            4327 4375 4452 -1 
            4327 4279 4202 -1 
            4250 4327 4202 -1 
            3837 3885 3962 -1 
            3837 3712 3760 -1 
            3885 3837 3760 -1 
            4356 4231 4279 -1 
            3981 4029 4106 -1 
            4058 3981 4106 -1 
            3981 4058 3933 -1 
            4029 3981 3904 -1 
            3856 3981 3933 -1 
            3904 3981 3856 -1 
            2117 2165 2242 -1 
            2290 2165 2213 -1 
            2165 2290 2242 -1 
            2165 2088 2213 -1 
            2146 2098 2021 -1 
            2098 2146 2223 -1 
            2146 2271 2223 -1 
            2069 2021 1944 -1 
            2069 2146 2021 -1 
            2165 2040 2088 -1 
            2040 2165 2117 -1 
            2319 2242 2367 -1 
            2271 2319 2396 -1 
            2444 2319 2367 -1 
            2396 2319 2444 -1 
            2261 2338 2213 -1 
            2309 2261 2184 -1 
            2136 2261 2213 -1 
            2184 2261 2136 -1 
            2386 2309 2434 -1 
            2338 2386 2463 -1 
            2261 2386 2338 -1 
            2386 2261 2309 -1 
            2386 2511 2463 -1 
            2386 2434 2511 -1 
            1934 2011 1886 -1 
            3088 3011 3136 -1 
            3213 3088 3136 -1 
            2838 2790 2713 -1 
            2790 2742 2665 -1 
            2713 2790 2665 -1 
            2915 2790 2838 -1 
            2482 2559 2434 -1 
            2482 2405 2530 -1 
            2607 2482 2530 -1 
            2559 2482 2607 -1 
            2482 2434 2357 -1 
            2405 2482 2357 -1 
            2425 2502 2377 -1 
            2425 2550 2502 -1 
            2425 2300 2348 -1 
            2300 2425 2377 -1 
            4376 4453 4328 -1 
            4376 4299 4424 -1 
            4376 4424 4501 -1 
            4453 4376 4501 -1 
            4376 4328 4251 -1 
            4299 4376 4251 -1 
            4145 4270 4222 -1 
            4088 4011 4136 -1 
            4213 4088 4136 -1 
            4088 4040 3963 -1 
            4011 4088 3963 -1 
            4155 4232 4107 -1 
            4280 4232 4155 -1 
            3915 3790 3838 -1 
            3992 3915 4040 -1 
            3992 4069 3944 -1 
            3992 4117 4069 -1 
            4117 3992 4040 -1 
            4530 4453 4578 -1 
            4482 4530 4607 -1 
            4559 4511 4434 -1 
            4463 4511 4588 -1 
            4511 4636 4588 -1 
            4511 4559 4636 -1 
            3069 3021 2944 -1 
            3194 3069 3117 -1 
            3098 3050 2973 -1 
            3021 3098 2973 -1 
            3175 3098 3223 -1 
            3098 3175 3050 -1 
            3415 3338 3463 -1 
            3511 3588 3463 -1 
            3434 3309 3357 -1 
            3511 3434 3559 -1 
            3271 3348 3223 -1 
            3271 3396 3348 -1 
            3290 3415 3367 -1 
            3415 3290 3338 -1 
            3290 3213 3338 -1 
            3924 3876 3799 -1 
            3751 3876 3828 -1 
            3876 3751 3799 -1 
            4087 4039 3962 -1 
            4030 3982 3905 -1 
            3857 3982 3934 -1 
            3982 3857 3905 -1 
            3982 4059 3934 -1 
            4059 3982 4107 -1 
            3982 4030 4107 -1 
            4174 4299 4251 -1 
            4299 4174 4222 -1 
            3626 3549 3674 -1 
            3578 3626 3703 -1 
            3626 3501 3549 -1 
            3501 3626 3578 -1 
            3751 3626 3674 -1 
            3626 3751 3703 -1 
            3645 3597 3520 -1 
            3597 3549 3472 -1 
            3520 3597 3472 -1 
            3549 3597 3674 -1 
            3780 3703 3828 -1 
            3732 3780 3857 -1 
            3905 3780 3828 -1 
            3857 3780 3905 -1 
            3636 3511 3559 -1 
            3511 3636 3588 -1 
            3405 3357 3280 -1 
            3328 3405 3280 -1 
            4012 4137 4089 -1 
            4031 4108 3983 -1 
            3791 3868 3743 -1 
            3791 3714 3839 -1 
            3791 3666 3714 -1 
            3666 3791 3743 -1 
            3964 3916 3839 -1 
            3916 3791 3839 -1 
            3791 3916 3868 -1 
            3743 3820 3695 -1 
            3868 3820 3743 -1 
            4310 4233 4358 -1 
            4435 4310 4358 -1 
            3897 4022 3974 -1 
            4214 4262 4339 -1 
            4137 4214 4089 -1 
            4214 4137 4262 -1 
            4224 4349 4301 -1 
            4608 4733 4685 -1 
            3608 3656 3733 -1 
            3656 3781 3733 -1 
            3781 3656 3704 -1 
            3656 3579 3704 -1 
            3685 3608 3733 -1 
            3637 3685 3762 -1 
            3685 3637 3560 -1 
            3608 3685 3560 -1 
            3685 3810 3762 -1 
            3685 3733 3810 -1 
            3454 3329 3377 -1 
            3329 3252 3377 -1 
            3329 3204 3252 -1 
            3329 3281 3204 -1 
            3329 3406 3281 -1 
            3406 3329 3454 -1 
            3531 3454 3579 -1 
            3531 3608 3483 -1 
            3406 3531 3483 -1 
            3531 3406 3454 -1 
            3656 3531 3579 -1 
            3531 3656 3608 -1 
            3800 3848 3925 -1 
            3925 3848 3973 -1 
            3848 3896 3973 -1 
            3896 3848 3771 -1 
            3848 3723 3771 -1 
            3848 3800 3723 -1 
            3704 3752 3829 -1 
            3752 3877 3829 -1 
            3877 3752 3800 -1 
            3752 3704 3627 -1 
            4761 4713 4636 -1 
            4588 4713 4665 -1 
            4636 4713 4588 -1 
            4713 4790 4665 -1 
            4454 4502 4579 -1 
            4502 4425 4550 -1 
            4502 4454 4377 -1 
            4425 4502 4377 -1 
            4233 4281 4358 -1 
            4329 4281 4204 -1 
            4406 4329 4454 -1 
            4406 4454 4531 -1 
            4281 4406 4358 -1 
            4406 4281 4329 -1 
            4003 4080 3955 -1 
            4051 4003 3926 -1 
            4080 4003 4128 -1 
            4003 4051 4128 -1 
            4003 3878 3926 -1 
            3878 4003 3955 -1 
            3849 3801 3724 -1 
            3801 3676 3724 -1 
            3676 3801 3753 -1 
            3801 3878 3753 -1 
            3878 3801 3926 -1 
            3801 3849 3926 -1 
            4781 4858 4733 -1 
            4781 4704 4829 -1 
            4906 4781 4829 -1 
            4858 4781 4906 -1 
            4742 4790 4867 -1 
            4790 4742 4665 -1 
            4742 4617 4665 -1 
            4617 4742 4694 -1 
            5395 5347 5270 -1 
            5347 5424 5299 -1 
            5424 5347 5472 -1 
            5347 5395 5472 -1 
            5395 5443 5520 -1 
            5568 5443 5491 -1 
            5520 5443 5568 -1 
            5491 5443 5366 -1 
            5443 5318 5366 -1 
            5318 5443 5395 -1 
            5136 5011 5059 -1 
            5011 4934 5059 -1 
            5011 4963 4886 -1 
            4934 5011 4886 -1 
            5184 5309 5261 -1 
            5136 5184 5261 -1 
            5184 5136 5059 -1 
            5165 5213 5290 -1 
            5338 5213 5261 -1 
            5213 5338 5290 -1 
            5213 5136 5261 -1 
            5088 5165 5040 -1 
            5088 5011 5136 -1 
            5213 5088 5136 -1 
            5088 5213 5165 -1 
            4963 5088 5040 -1 
            5011 5088 4963 -1 
            5415 5338 5463 -1 
            5290 5415 5367 -1 
            5338 5415 5290 -1 
            5242 5290 5367 -1 
            5242 5165 5290 -1 
            5165 5242 5117 -1 
            5242 5194 5117 -1 
            5319 5242 5367 -1 
            5242 5319 5194 -1 
            5559 5434 5482 -1 
            5559 5511 5434 -1 
            5761 5636 5684 -1 
            5636 5559 5684 -1 
            5636 5588 5511 -1 
            5559 5636 5511 -1 
            5396 5444 5521 -1 
            5444 5319 5367 -1 
            5319 5444 5396 -1 
            4569 4617 4694 -1 
            4646 4569 4694 -1 
            4569 4646 4521 -1 
            4617 4569 4492 -1 
            4444 4569 4521 -1 
            4492 4569 4444 -1 
            4771 4646 4694 -1 
            4838 4915 4790 -1 
            4838 4761 4886 -1 
            4963 4838 4886 -1 
            4915 4838 4963 -1 
            4838 4713 4761 -1 
            4713 4838 4790 -1 
            5320 5397 5272 -1 
            5320 5445 5397 -1 
            5493 5416 5541 -1 
            5445 5493 5570 -1 
            5493 5618 5570 -1 
            5618 5493 5541 -1 
            5176 5301 5253 -1 
            5301 5378 5253 -1 
            4955 5032 4907 -1 
            5032 4955 5080 -1 
            4955 5003 5080 -1 
            5176 5051 5099 -1 
            5051 4974 5099 -1 
            4974 5022 5099 -1 
            5147 5022 5070 -1 
            5022 5147 5099 -1 
            5022 4974 4897 -1 
            4974 4849 4897 -1 
            4849 4772 4897 -1 
            4868 4820 4743 -1 
            4743 4820 4695 -1 
            4820 4772 4695 -1 
            4772 4820 4897 -1 
            4618 4666 4743 -1 
            4666 4589 4714 -1 
            4666 4618 4541 -1 
            4589 4666 4541 -1 
            4772 4647 4695 -1 
            4426 4349 4474 -1 
            4301 4426 4378 -1 
            4349 4426 4301 -1 
            4741 4693 4616 -1 
            4866 4741 4789 -1 
            4520 4568 4645 -1 
            4568 4693 4645 -1 
            4693 4568 4616 -1 
            4568 4520 4443 -1 
            4760 4635 4683 -1 
            4712 4635 4760 -1 
            4635 4558 4683 -1 
            4558 4635 4510 -1 
            4857 4934 4809 -1 
            4732 4857 4809 -1 
            4982 4857 4905 -1 
            4857 4982 4934 -1 
            4934 4982 5059 -1 
            5203 5328 5280 -1 
            5155 5203 5280 -1 
            5203 5155 5078 -1 
            5001 4924 5049 -1 
            4953 5001 5078 -1 
            5001 4876 4924 -1 
            4876 5001 4953 -1 
            5097 5174 5049 -1 
            4972 5097 5049 -1 
            5222 5145 5270 -1 
            5174 5222 5299 -1 
            5222 5097 5145 -1 
            5097 5222 5174 -1 
            5222 5347 5299 -1 
            5347 5222 5270 -1 
            4655 4732 4607 -1 
            4703 4655 4578 -1 
            4655 4530 4578 -1 
            4530 4655 4607 -1 
            4780 4703 4828 -1 
            4780 4857 4732 -1 
            4655 4780 4732 -1 
            4780 4655 4703 -1 
            4905 4780 4828 -1 
            4857 4780 4905 -1 
            4847 4799 4722 -1 
            4799 4674 4722 -1 
            4674 4799 4751 -1 
            4799 4876 4751 -1 
            4799 4847 4924 -1 
            4876 4799 4924 -1 
            4895 4847 4770 -1 
            4895 4972 4847 -1 
            5068 5020 4943 -1 
            5020 4895 4943 -1 
            4895 5020 4972 -1 
            5020 5097 4972 -1 
            5145 5020 5068 -1 
            5097 5020 5145 -1 
            4366 4289 4414 -1 
            4289 4366 4241 -1 
            4674 4626 4549 -1 
            4501 4626 4578 -1 
            4549 4626 4501 -1 
            4626 4703 4578 -1 
            4703 4626 4751 -1 
            4626 4674 4751 -1 
            5442 5365 5490 -1 
            5442 5519 5394 -1 
            5442 5394 5317 -1 
            5365 5442 5317 -1 
            5442 5490 5567 -1 
            5519 5442 5567 -1 
            5365 5288 5413 -1 
            5288 5365 5240 -1 
            5711 5586 5634 -1 
            5740 5692 5615 -1 
            5865 5740 5788 -1 
            5692 5644 5567 -1 
            5644 5519 5567 -1 
            5519 5644 5596 -1 
            5644 5721 5596 -1 
            5721 5644 5769 -1 
            5644 5692 5769 -1 
            5519 5471 5394 -1 
            5346 5471 5423 -1 
            5471 5346 5394 -1 
            5471 5548 5423 -1 
            5548 5471 5596 -1 
            5471 5519 5596 -1 
            5653 5778 5730 -1 
            5759 5711 5634 -1 
            5173 5096 5221 -1 
            5096 5144 5221 -1 
            5144 5096 5019 -1 
            5220 5297 5172 -1 
            5009 5086 4961 -1 
            4730 4855 4807 -1 
            4951 4903 4826 -1 
            4903 4951 5028 -1 
            4577 4702 4654 -1 
            4577 4625 4702 -1 
            4663 4788 4740 -1 
            4394 4471 4346 -1 
            5462 5414 5337 -1 
            5414 5289 5337 -1 
            5289 5414 5366 -1 
            5414 5491 5366 -1 
            5414 5539 5491 -1 
            5539 5414 5462 -1 
            5510 5385 5433 -1 
            5510 5462 5385 -1 
            5558 5510 5433 -1 
            5510 5558 5635 -1 
            6225 6302 6177 -1 
            6273 6225 6148 -1 
            6100 6225 6177 -1 
            6148 6225 6100 -1 
            6321 6369 6446 -1 
            6321 6244 6369 -1 
            6244 6321 6196 -1 
            6321 6273 6196 -1 
            6225 6350 6302 -1 
            6350 6225 6273 -1 
            6302 6350 6427 -1 
            6350 6475 6427 -1 
            6676 6753 6628 -1 
            6744 6667 6792 -1 
            6667 6744 6619 -1 
            6378 6426 6503 -1 
            6426 6301 6349 -1 
            6301 6426 6378 -1 
            5840 5792 5715 -1 
            5667 5792 5744 -1 
            5792 5667 5715 -1 
            5840 5888 5965 -1 
            5888 6013 5965 -1 
            5888 5763 5811 -1 
            5763 5888 5840 -1 
            6484 6407 6532 -1 
            6609 6484 6532 -1 
            6359 6484 6436 -1 
            6407 6484 6359 -1 
            6186 6234 6311 -1 
            6234 6359 6311 -1 
            6234 6282 6359 -1 
            6282 6234 6157 -1 
            6157 6234 6109 -1 
            6234 6186 6109 -1 
            5466 5514 5591 -1 
            5514 5437 5562 -1 
            5389 5514 5466 -1 
            5437 5514 5389 -1 
            5514 5639 5591 -1 
            5639 5514 5562 -1 
            5639 5716 5591 -1 
            5716 5639 5764 -1 
            5975 5927 5850 -1 
            5898 5975 5850 -1 
            5975 5898 6023 -1 
            5927 5975 6052 -1 
            6100 5975 6023 -1 
            6052 5975 6100 -1 
            6012 5935 6060 -1 
            5935 6012 5887 -1 
            5839 5916 5791 -1 
            5964 5839 5887 -1 
            5916 5839 5964 -1 
            5810 5935 5887 -1 
            5935 5810 5858 -1 
            4869 4994 4946 -1 
            5023 5148 5100 -1 
            5148 5225 5100 -1 
            5225 5148 5273 -1 
            5148 5196 5273 -1 
            5196 5119 5244 -1 
            5119 4994 5042 -1 
            4879 4956 4831 -1 
            4898 4946 5023 -1 
            4975 4898 5023 -1 
            4898 4975 4850 -1 
            4946 4898 4821 -1 
            5724 5772 5849 -1 
            5772 5897 5849 -1 
            5897 5772 5820 -1 
            5820 5772 5695 -1 
            5695 5772 5647 -1 
            5772 5724 5647 -1 
            5897 5945 6022 -1 
            6070 5945 5993 -1 
            6022 5945 6070 -1 
            5945 5897 5820 -1 
            6368 6291 6416 -1 
            6243 6291 6368 -1 
            6291 6243 6166 -1 
            6262 6310 6387 -1 
            6310 6358 6435 -1 
            6387 6310 6435 -1 
            6358 6310 6233 -1 
            6233 6310 6185 -1 
            6310 6262 6185 -1 
            6194 6117 6242 -1 
            5973 6021 6098 -1 
            6059 5934 5982 -1 
            5934 5857 5982 -1 
            5934 5886 5809 -1 
            5857 5934 5809 -1 
            6088 6011 6136 -1 
            6011 6059 6136 -1 
            5934 6011 5886 -1 
            6011 5934 6059 -1 
            6319 6194 6242 -1 
            6194 6319 6271 -1 
            6675 6752 6627 -1 
            6675 6800 6752 -1 
            6569 6646 6521 -1 
            6646 6598 6521 -1 
            6473 6425 6348 -1 
            6425 6300 6348 -1 
            6425 6377 6300 -1 
            6377 6425 6502 -1 
            6550 6473 6598 -1 
            6502 6550 6627 -1 
            6425 6550 6502 -1 
            6550 6425 6473 -1 
            6550 6675 6627 -1 
            6675 6550 6598 -1 
            6252 6175 6300 -1 
            6204 6252 6329 -1 
            6377 6252 6300 -1 
            6252 6377 6329 -1 
            6127 6204 6079 -1 
            6002 6127 6079 -1 
            6252 6127 6175 -1 
            6127 6252 6204 -1 
            6204 6156 6079 -1 
            6079 6156 6031 -1 
            6156 6108 6031 -1 
            6108 6156 6233 -1 
            6156 6281 6233 -1 
            6156 6204 6281 -1 
            5954 6079 6031 -1 
            5954 6002 6079 -1 
            6002 5954 5877 -1 
            5800 5675 5723 -1 
            5675 5598 5723 -1 
            5752 5800 5877 -1 
            5752 5675 5800 -1 
            5752 5704 5627 -1 
            5675 5752 5627 -1 
            5704 5579 5627 -1 
            5579 5502 5627 -1 
            5925 5848 5973 -1 
            5848 5925 5800 -1 
            5848 5800 5723 -1 
            5742 5790 5867 -1 
            5598 5646 5723 -1 
            5646 5598 5521 -1 
            7839 7762 7887 -1 
            7714 7839 7791 -1 
            7762 7839 7714 -1 
            7608 7531 7656 -1 
            7608 7483 7531 -1 
            7512 7387 7435 -1 
            7762 7685 7810 -1 
            7637 7685 7762 -1 
            7214 7089 7137 -1 
            7262 7214 7137 -1 
            6656 6733 6608 -1 
            6704 6656 6579 -1 
            6656 6608 6531 -1 
            6579 6656 6531 -1 
            6781 6704 6829 -1 
            6733 6781 6858 -1 
            6656 6781 6733 -1 
            6781 6656 6704 -1 
            6781 6906 6858 -1 
            6781 6829 6906 -1 
            7002 6925 7050 -1 
            6954 7002 7079 -1 
            6925 7002 6877 -1 
            7002 6954 6877 -1 
            7127 7002 7050 -1 
            7079 7002 7127 -1 
            8106 8154 8231 -1 
            8154 8279 8231 -1 
            8279 8154 8202 -1 
            8154 8077 8202 -1 
            8077 8154 8029 -1 
            8154 8106 8029 -1 
            7414 7539 7491 -1 
            7337 7414 7289 -1 
            7145 7193 7270 -1 
            7193 7318 7270 -1 
            6664 6539 6587 -1 
            6703 6751 6828 -1 
            7490 7365 7413 -1 
            7384 7307 7432 -1 
            7336 7384 7461 -1 
            7663 7711 7788 -1 
            7711 7634 7759 -1 
            8134 8259 8211 -1 
            8259 8134 8182 -1 
            8192 8115 8240 -1 
            7884 7836 7759 -1 
            7711 7836 7788 -1 
            7836 7711 7759 -1 
            7865 7740 7788 -1 
            7740 7663 7788 -1 
            7740 7692 7615 -1 
            7663 7740 7615 -1 
            7654 7702 7779 -1 
            7702 7654 7577 -1 
            6308 6356 6433 -1 
            6308 6231 6356 -1 
            6404 6327 6452 -1 
            6529 6404 6452 -1 
            7423 7500 7375 -1 
            7423 7548 7500 -1 
            7548 7423 7471 -1 
            7692 7567 7615 -1 
            7567 7490 7615 -1 
            6896 7021 6973 -1 
            6848 6896 6973 -1 
            6896 6848 6771 -1 
            7203 7155 7078 -1 
            7078 7155 7030 -1 
            7155 7107 7030 -1 
            7155 7232 7107 -1 
            7232 7155 7280 -1 
            7155 7203 7280 -1 
            7251 7126 7174 -1 
            7251 7203 7126 -1 
            7539 7616 7491 -1 
            7616 7568 7491 -1 
            7616 7539 7664 -1 
            7741 7616 7664 -1 
            7453 7328 7376 -1 
            7328 7251 7376 -1 
            7203 7328 7280 -1 
            7251 7328 7203 -1 
            7328 7405 7280 -1 
            7405 7328 7453 -1 
            7424 7472 7549 -1 
            7520 7472 7395 -1 
            7472 7347 7395 -1 
            7472 7424 7347 -1 
            7501 7453 7376 -1 
            7424 7501 7376 -1 
            7501 7424 7549 -1 
            7501 7578 7453 -1 
            7626 7501 7549 -1 
            7578 7501 7626 -1 
            6953 6905 6828 -1 
            6905 6953 7030 -1 
            6886 7011 6963 -1 
            6838 6886 6963 -1 
            6395 6472 6347 -1 
            6184 6232 6309 -1 
            6232 6357 6309 -1 
            7684 7607 7732 -1 
            7607 7655 7732 -1 
            7674 7626 7549 -1 
            7943 7991 8068 -1 
            8020 7943 8068 -1 
            7693 7770 7645 -1 
            7693 7616 7741 -1 
            7693 7645 7568 -1 
            7616 7693 7568 -1 
            7364 7412 7489 -1 
            7412 7335 7460 -1 
            7393 7316 7441 -1 
            7316 7364 7441 -1 
            7268 7316 7393 -1 
            7364 7316 7239 -1 
            7316 7191 7239 -1 
            7316 7268 7191 -1 
            7383 7508 7460 -1 
            7335 7383 7460 -1 
            7383 7258 7306 -1 
            7383 7335 7258 -1 
            7287 7364 7239 -1 
            7335 7287 7210 -1 
            7287 7412 7364 -1 
            7412 7287 7335 -1 
            7287 7162 7210 -1 
            7162 7287 7239 -1 
            7739 7816 7691 -1 
            7739 7662 7787 -1 
            7739 7787 7864 -1 
            7816 7739 7864 -1 
            7614 7691 7566 -1 
            7614 7566 7489 -1 
            7614 7739 7691 -1 
            7739 7614 7662 -1 
            6961 7009 7086 -1 
            7009 6932 7057 -1 
            6836 6711 6759 -1 
            7781 7704 7829 -1 
            7704 7752 7829 -1 
            7704 7781 7656 -1 
            7752 7704 7627 -1 
            7579 7704 7656 -1 
            7627 7704 7579 -1 
            7896 7848 7771 -1 
            7723 7848 7800 -1 
            7848 7723 7771 -1 
            7800 7848 7925 -1 
            7848 7973 7925 -1 
            7973 7848 7896 -1 
            7723 7675 7598 -1 
            7598 7675 7550 -1 
            7675 7627 7550 -1 
            7675 7752 7627 -1 
            7752 7675 7800 -1 
            7675 7723 7800 -1 
            7434 7386 7309 -1 
            7261 7386 7338 -1 
            7386 7261 7309 -1 
            7338 7386 7463 -1 
            7463 7386 7511 -1 
            7386 7434 7511 -1 
            5776 5901 5853 -1 
            5728 5776 5853 -1 
            5873 5921 5998 -1 
            6046 5921 5969 -1 
            5921 6046 5998 -1 
            5969 5921 5844 -1 
            5921 5796 5844 -1 
            5796 5921 5873 -1 
            5882 5959 5834 -1 
            5930 5882 5805 -1 
            5882 5930 6007 -1 
            5959 5882 6007 -1 
            5882 5757 5805 -1 
            5882 5834 5757 -1 
            5267 5344 5219 -1 
            5344 5296 5219 -1 
            5344 5267 5392 -1 
            5296 5344 5421 -1 
            5469 5344 5392 -1 
            5344 5469 5421 -1 
            5498 5421 5546 -1 
            5450 5498 5575 -1 
            5623 5498 5546 -1 
            5498 5623 5575 -1 
            5527 5450 5575 -1 
            5527 5575 5652 -1 
            5661 5613 5536 -1 
            5613 5488 5536 -1 
            5488 5613 5565 -1 
            5738 5613 5661 -1 
            5171 5248 5123 -1 
            5248 5171 5296 -1 
            8352 8304 8227 -1 
            8275 8352 8227 -1 
            8352 8275 8400 -1 
            8304 8352 8429 -1 
            8352 8477 8429 -1 
            8477 8352 8400 -1 
            7833 7785 7708 -1 
            7785 7660 7708 -1 
            7660 7785 7737 -1 
            7910 7785 7833 -1 
            8006 8054 8131 -1 
            8006 7929 8054 -1 
            6247 6199 6122 -1 
            6199 6151 6074 -1 
            6122 6199 6074 -1 
            6151 6199 6276 -1 
            6199 6324 6276 -1 
            6199 6247 6324 -1 
            7297 7345 7422 -1 
            7470 7345 7393 -1 
            7422 7345 7470 -1 
            7345 7268 7393 -1 
            7268 7345 7220 -1 
            7345 7297 7220 -1 
            7874 7749 7797 -1 
            7874 7826 7749 -1 
            7874 7797 7922 -1 
            7826 7874 7951 -1 
            7999 7874 7922 -1 
            7874 7999 7951 -1 
            8201 8249 8326 -1 
            8028 7951 8076 -1 
            7951 8028 7903 -1 
            8028 7980 7903 -1 
            8153 8278 8230 -1 
            8278 8153 8201 -1 
            8201 8153 8076 -1 
            8153 8028 8076 -1 
            8374 8451 8326 -1 
            8249 8374 8326 -1 
            8374 8422 8499 -1 
            8451 8374 8499 -1 
            8191 8066 8114 -1 
            8133 8210 8085 -1 
            8133 8056 8181 -1 
            8133 8008 8056 -1 
            8008 8133 8085 -1 
            8258 8133 8181 -1 
            8133 8258 8210 -1 
            8335 8258 8383 -1 
            8210 8258 8335 -1 
            8479 8450 8498 -1 
            8402 8450 8479 -1 
            8095 8047 7970 -1 
            7970 8047 7922 -1 
            8047 7999 7922 -1 
            6586 6663 6538 -1 
            6711 6663 6586 -1 
            6471 6519 6596 -1 
            6519 6644 6596 -1 
            6644 6519 6567 -1 
            6567 6519 6442 -1 
            6519 6394 6442 -1 
            6394 6519 6471 -1 
            6548 6471 6596 -1 
            6673 6548 6596 -1 
            6548 6500 6423 -1 
            6471 6548 6423 -1 
            6586 6509 6634 -1 
            6461 6509 6586 -1 
            6038 6163 6115 -1 
            6115 6163 6240 -1 
            6163 6288 6240 -1 
            6163 6211 6288 -1 
            6211 6259 6336 -1 
            6307 6259 6182 -1 
            6259 6134 6182 -1 
            6134 6259 6211 -1 
            6298 6221 6346 -1 
            6298 6375 6250 -1 
            6173 6298 6250 -1 
            6221 6298 6173 -1 
            6298 6346 6423 -1 
            6375 6298 6423 -1 
            6231 6183 6106 -1 
            6183 6260 6135 -1 
            6183 6308 6260 -1 
            6308 6183 6231 -1 
            6231 6279 6356 -1 
            6404 6279 6327 -1 
            6279 6404 6356 -1 
            6279 6202 6327 -1 
            6154 6231 6106 -1 
            6202 6154 6077 -1 
            6279 6154 6202 -1 
            6154 6279 6231 -1 
            6154 6029 6077 -1 
            6154 6106 6029 -1 
            6010 6058 6135 -1 
            6058 6183 6135 -1 
            6183 6058 6106 -1 
            6058 5981 6106 -1 
            5981 6058 5933 -1 
            6058 6010 5933 -1 
            6087 6039 5962 -1 
            6010 6087 5962 -1 
            6087 6010 6135 -1 
            6039 6087 6164 -1 
            6087 6212 6164 -1 
            6212 6087 6135 -1 
            5990 6038 6115 -1 
            6067 5990 6115 -1 
            5990 6067 5942 -1 
            5865 5990 5942 -1 
            5539 5587 5664 -1 
            5712 5587 5635 -1 
            5664 5587 5712 -1 
            5587 5510 5635 -1 
            5587 5539 5462 -1 
            5510 5587 5462 -1 
            6116 6039 6164 -1 
            6068 6193 6145 -1 
            6068 6116 6193 -1 
            6039 5914 5962 -1 
            5914 5837 5962 -1 
            6289 6241 6164 -1 
            6241 6116 6164 -1 
            6116 6241 6193 -1 
            6366 6241 6289 -1 
            6107 6030 6155 -1 
            6059 6107 6184 -1 
            6107 6059 5982 -1 
            6030 6107 5982 -1 
            6232 6107 6155 -1 
            6107 6232 6184 -1 
            5953 6001 6078 -1 
            6126 6001 6049 -1 
            6078 6001 6126 -1 
            6001 5924 6049 -1 
            5732 5857 5809 -1 
            5732 5780 5857 -1 
            5732 5809 5684 -1 
            5828 5953 5905 -1 
            5780 5828 5905 -1 
            7404 7452 7529 -1 
            7452 7500 7577 -1 
            7529 7452 7577 -1 
            7500 7452 7375 -1 
            7481 7529 7606 -1 
            7558 7481 7606 -1 
            7481 7404 7529 -1 
            7760 7712 7635 -1 
            7712 7664 7587 -1 
            7635 7712 7587 -1 
            7664 7712 7789 -1 
            7789 7712 7837 -1 
            7712 7760 7837 -1 
            7683 7731 7808 -1 
            7856 7731 7779 -1 
            7808 7731 7856 -1 
            7731 7654 7779 -1 
            7654 7731 7606 -1 
            7731 7683 7606 -1 
            7613 7661 7738 -1 
            7709 7661 7584 -1 
            7411 7488 7363 -1 
            7488 7440 7363 -1 
            7517 7594 7469 -1 
            7392 7517 7469 -1 
            7517 7392 7440 -1 
            7690 7613 7738 -1 
            7690 7738 7815 -1 
            7767 7690 7815 -1 
            8083 8160 8035 -1 
            8083 8006 8131 -1 
            8208 8083 8131 -1 
            8160 8083 8208 -1 
            8391 8468 8343 -1 
            8391 8439 8468 -1 
            8439 8391 8314 -1 
            8266 8391 8343 -1 
            8391 8266 8314 -1 
            8478 8401 8449 -1 
            8401 8478 8353 -1 
            8449 8401 8324 -1 
            8238 8286 8363 -1 
            8286 8411 8363 -1 
            8411 8286 8334 -1 
            8286 8238 8161 -1 
            8315 8267 8190 -1 
            8238 8315 8190 -1 
            8315 8238 8363 -1 
            8267 8315 8392 -1 
            8440 8315 8363 -1 
            8315 8440 8392 -1 
            8084 8209 8161 -1 
            8209 8286 8161 -1 
            8209 8257 8334 -1 
            8286 8209 8334 -1 
            8305 8353 8430 -1 
            8180 8305 8257 -1 
            5027 4902 4950 -1 
            4969 5046 4921 -1 
            5046 4998 4921 -1 
            5046 4969 5094 -1 
            4998 5046 5123 -1 
            5171 5046 5094 -1 
            5046 5171 5123 -1 
            3921 3998 3873 -1 
            3969 3921 3844 -1 
            4046 3921 3969 -1 
            3998 3921 4046 -1 
            3921 3796 3844 -1 
            3921 3873 3796 -1 
            3402 3479 3354 -1 
            3576 3451 3499 -1 
            3451 3374 3499 -1 
            3374 3451 3326 -1 
            3451 3403 3326 -1 
            3605 3528 3653 -1 
            3528 3576 3653 -1 
            3528 3605 3480 -1 
            3528 3451 3576 -1 
            3403 3528 3480 -1 
            3451 3528 3403 -1 
            3316 3393 3268 -1 
            3364 3316 3239 -1 
            3316 3191 3239 -1 
            3191 3316 3268 -1 
            3441 3364 3489 -1 
            3393 3441 3518 -1 
            3316 3441 3393 -1 
            3441 3316 3364 -1 
            3441 3566 3518 -1 
            3566 3441 3489 -1 
            3287 3335 3412 -1 
            3364 3287 3412 -1 
            3287 3364 3239 -1 
            3287 3210 3335 -1 
            3162 3287 3239 -1 
            3287 3162 3210 -1 
            4162 4037 4085 -1 
            4085 4037 3960 -1 
            3912 4037 3989 -1 
            3960 4037 3912 -1 
            4056 4133 4008 -1 
            4056 3979 4104 -1 
            3931 4056 4008 -1 
            3979 4056 3931 -1 
            4181 4104 4229 -1 
            4133 4181 4258 -1 
            4181 4056 4104 -1 
            4056 4181 4133 -1 
            4181 4306 4258 -1 
            4306 4181 4229 -1 
            5603 5651 5728 -1 
            5776 5651 5699 -1 
            5651 5776 5728 -1 
            5699 5651 5574 -1 
            5651 5526 5574 -1 
            5651 5603 5526 -1 
            5680 5603 5728 -1 
            5632 5680 5757 -1 
            5680 5632 5555 -1 
            5603 5680 5555 -1 
            5757 5680 5805 -1 
            5680 5728 5805 -1 
            5747 5795 5872 -1 
            5795 5747 5670 -1 
            5747 5622 5670 -1 
            5622 5747 5699 -1 
            5843 5718 5766 -1 
            5843 5795 5718 -1 
            5891 5843 5766 -1 
            5795 5843 5920 -1 
            5843 5891 5968 -1 
            5920 5843 5968 -1 
            5470 5393 5518 -1 
            5662 5739 5614 -1 
            5960 5835 5883 -1 
            5835 5960 5912 -1 
            5912 5960 6037 -1 
            5989 5912 6037 -1 
            5816 5893 5768 -1 
            5816 5941 5893 -1 
            5566 5643 5518 -1 
            5566 5489 5614 -1 
            5739 5691 5614 -1 
            5691 5566 5614 -1 
            5566 5691 5643 -1 
            5643 5691 5768 -1 
            5691 5816 5768 -1 
            5816 5691 5739 -1 
            5835 5758 5883 -1 
            5758 5835 5710 -1 
            5950 5873 5998 -1 
            6075 5950 5998 -1 
            5383 5508 5460 -1 
            6277 6325 6402 -1 
            6354 6277 6402 -1 
            6325 6277 6200 -1 
            6277 6152 6200 -1 
            6133 6258 6210 -1 
            6143 6191 6268 -1 
            6316 6191 6239 -1 
            6268 6191 6316 -1 
            5999 5922 6047 -1 
            6124 5999 6047 -1 
            5749 5874 5826 -1 
            5874 5951 5826 -1 
            5874 5999 5951 -1 
            5999 5874 5922 -1 
            5797 5874 5749 -1 
            5874 5797 5922 -1 
            5672 5797 5749 -1 
            6095 6143 6220 -1 
            5941 6018 5893 -1 
            6095 6018 6143 -1 
            5720 5643 5768 -1 
            5720 5797 5672 -1 
            5278 5326 5403 -1 
            5278 5201 5326 -1 
            5374 5297 5422 -1 
            5374 5451 5326 -1 
            5470 5547 5422 -1 
            5528 5451 5576 -1 
            5451 5528 5403 -1 
            5653 5528 5576 -1 
            5278 5355 5230 -1 
            5355 5278 5403 -1 
            4365 4317 4240 -1 
            4317 4192 4240 -1 
            4192 4317 4269 -1 
            4317 4394 4269 -1 
            4317 4442 4394 -1 
            4442 4317 4365 -1 
            4413 4461 4538 -1 
            4413 4336 4461 -1 
            4336 4413 4288 -1 
            4413 4365 4288 -1 
            4634 4509 4557 -1 
            4586 4509 4634 -1 
            4509 4586 4461 -1 
            4509 4432 4557 -1 
            4432 4509 4384 -1 
            4509 4461 4384 -1 
            4374 4326 4249 -1 
            4249 4326 4201 -1 
            4326 4278 4201 -1 
            4278 4326 4403 -1 
            4682 4605 4730 -1 
            4682 4759 4634 -1 
            4682 4634 4557 -1 
            4605 4682 4557 -1 
            4682 4730 4807 -1 
            4759 4682 4807 -1 
            4653 4605 4528 -1 
            4605 4653 4730 -1 
            4451 4528 4403 -1 
            4499 4451 4374 -1 
            4326 4451 4403 -1 
            4451 4326 4374 -1 
            4576 4499 4624 -1 
            4576 4653 4528 -1 
            4451 4576 4528 -1 
            4576 4451 4499 -1 
            4518 4470 4393 -1 
            4470 4345 4393 -1 
            4345 4470 4422 -1 
            4470 4547 4422 -1 
            4470 4595 4547 -1 
            4470 4518 4595 -1 
            4489 4441 4364 -1 
            4441 4316 4364 -1 
            4441 4393 4316 -1 
            4441 4518 4393 -1 
            4518 4441 4566 -1 
            4441 4489 4566 -1 
            4585 4633 4710 -1 
            4633 4585 4508 -1 
            4508 4585 4460 -1 
            4585 4537 4460 -1 
            4662 4739 4614 -1 
            4662 4585 4710 -1 
            4537 4662 4614 -1 
            4585 4662 4537 -1 
            4787 4710 4835 -1 
            4787 4662 4710 -1 
            4662 4787 4739 -1 
            4739 4816 4691 -1 
            4797 4720 4845 -1 
            4797 4672 4720 -1 
            4922 4797 4845 -1 
            4970 4922 4845 -1 
            8027 8104 7979 -1 
            7902 8027 7979 -1 
            8152 8027 8075 -1 
            8104 8027 8152 -1 
            8277 8229 8152 -1 
            8104 8229 8181 -1 
            8229 8104 8152 -1 
            8229 8277 8354 -1 
            8200 8277 8152 -1 
            8200 8123 8248 -1 
            8200 8152 8075 -1 
            8123 8200 8075 -1 
            8296 8373 8248 -1 
            8421 8373 8296 -1 
            8373 8421 8498 -1 
            8450 8373 8498 -1 
            7978 8055 7930 -1 
            8055 8007 7930 -1 
            8055 7978 8103 -1 
            8180 8055 8103 -1 
            7834 7786 7709 -1 
            7786 7661 7709 -1 
            7661 7786 7738 -1 
            7786 7863 7738 -1 
            7863 7786 7911 -1 
            7786 7834 7911 -1 
            7834 7959 7911 -1 
            7911 7959 8036 -1 
            8036 7959 8084 -1 
            7959 8007 8084 -1 
            7930 7882 7805 -1 
            7882 7757 7805 -1 
            7757 7882 7834 -1 
            7882 7959 7834 -1 
            8007 7882 7930 -1 
            7959 7882 8007 -1 
            8171 8094 8219 -1 
            6238 6315 6190 -1 
            6238 6161 6286 -1 
            6315 6238 6363 -1 
            6238 6286 6363 -1 
            6238 6190 6113 -1 
            6161 6238 6113 -1 
            5979 6027 6104 -1 
            6027 6152 6104 -1 
            6152 6027 6075 -1 
            6027 5950 6075 -1 
            5930 5978 6055 -1 
            6055 5978 6103 -1 
            5978 6026 6103 -1 
            6026 5978 5901 -1 
            5901 5978 5853 -1 
            5978 5930 5853 -1 
            6661 6613 6536 -1 
            6613 6488 6536 -1 
            6488 6613 6565 -1 
            6613 6690 6565 -1 
            6613 6738 6690 -1 
            6613 6661 6738 -1 
            6622 6670 6747 -1 
            6670 6545 6593 -1 
            6670 6622 6545 -1 
            6939 6891 6814 -1 
            7064 6939 6987 -1 
            6689 6737 6814 -1 
            6785 6737 6660 -1 
            6737 6612 6660 -1 
            6737 6689 6612 -1 
            6689 6641 6564 -1 
            6516 6641 6593 -1 
            6641 6516 6564 -1 
            7170 7093 7218 -1 
            6872 6920 6997 -1 
            6824 6699 6747 -1 
            6872 6824 6747 -1 
            7256 7304 7381 -1 
            7333 7256 7381 -1 
            7256 7179 7304 -1 
            7266 7343 7218 -1 
            7266 7314 7391 -1 
            7343 7266 7391 -1 
            7535 7410 7458 -1 
            7410 7535 7487 -1 
            7410 7333 7458 -1 
            7382 7257 7305 -1 
            7459 7382 7507 -1 
            7228 7305 7180 -1 
            7276 7228 7151 -1 
            7228 7103 7151 -1 
            7103 7228 7180 -1 
            7526 7401 7449 -1 
            7478 7401 7526 -1 
            7199 7276 7151 -1 
            7324 7247 7372 -1 
            7324 7401 7276 -1 
            7324 7199 7247 -1 
            7199 7324 7276 -1 
            7324 7372 7449 -1 
            7401 7324 7449 -1 
            7132 7209 7084 -1 
            7257 7209 7132 -1 
            7372 7497 7449 -1 
            7497 7574 7449 -1 
            7497 7622 7574 -1 
            7343 7295 7218 -1 
            7295 7170 7218 -1 
            7170 7295 7247 -1 
            7247 7295 7372 -1 
            7420 7343 7468 -1 
            7420 7497 7372 -1 
            7295 7420 7372 -1 
            7420 7295 7343 -1 
            7862 7939 7814 -1 
            7862 7785 7910 -1 
            7862 7910 7987 -1 
            7939 7862 7987 -1 
            7737 7862 7814 -1 
            7785 7862 7737 -1 
            7593 7718 7670 -1 
            8025 7977 7900 -1 
            7929 7977 8054 -1 
            8054 7977 8102 -1 
            7977 8025 8102 -1 
            7660 7583 7708 -1 
            7583 7660 7535 -1 
            7583 7535 7458 -1 
            7756 7833 7708 -1 
            7881 7804 7929 -1 
            8006 7881 7929 -1 
            7756 7881 7833 -1 
            7881 7756 7804 -1 
            7852 7804 7727 -1 
            7775 7852 7727 -1 
            7852 7775 7900 -1 
            7804 7852 7929 -1 
            7977 7852 7900 -1 
            7852 7977 7929 -1 
            7179 7227 7304 -1 
            7352 7227 7275 -1 
            7227 7352 7304 -1 
            7227 7150 7275 -1 
            7227 7179 7102 -1 
            7150 7227 7102 -1 
            6958 7083 7035 -1 
            7477 7525 7602 -1 
            7650 7525 7573 -1 
            7525 7650 7602 -1 
            7477 7429 7352 -1 
            7304 7429 7381 -1 
            7352 7429 7304 -1 
            7823 7775 7698 -1 
            7746 7823 7698 -1 
            7823 7746 7871 -1 
            7775 7823 7900 -1 
            7948 7823 7871 -1 
            7823 7948 7900 -1 
            6977 7025 7102 -1 
            7025 7150 7102 -1 
            7150 7025 7073 -1 
            7073 7025 6900 -1 
            7025 6977 6900 -1 
            7246 7323 7198 -1 
            7246 7073 7294 -1 
            7246 7198 7073 -1 
            7371 7246 7294 -1 
            7323 7246 7371 -1 
            6804 6881 6756 -1 
            6881 6804 6929 -1 
            7054 7006 6929 -1 
            7006 6881 6929 -1 
            6881 7006 6958 -1 
            7006 7083 6958 -1 
            6862 6939 6814 -1 
            6939 6862 6987 -1 
            6862 6737 6785 -1 
            6737 6862 6814 -1 
            7325 7450 7402 -1 
            7325 7373 7450 -1 
            7325 7402 7277 -1 
            7373 7325 7248 -1 
            7200 7325 7277 -1 
            7325 7200 7248 -1 
            7373 7421 7498 -1 
            7421 7344 7469 -1 
            7344 7421 7296 -1 
            7421 7373 7296 -1 
            7546 7623 7498 -1 
            7594 7546 7469 -1 
            7546 7421 7469 -1 
            7421 7546 7498 -1 
            7719 7844 7796 -1 
            7719 7767 7844 -1 
            4477 4554 4429 -1 
            4602 4477 4525 -1 
            4554 4477 4602 -1 
            4708 4631 4756 -1 
            4660 4708 4785 -1 
            4631 4708 4583 -1 
            4708 4660 4583 -1 
            4708 4833 4785 -1 
            4708 4756 4833 -1 
            5198 5323 5275 -1 
            5323 5400 5275 -1 
            5448 5323 5371 -1 
            5323 5448 5400 -1 
            4996 4871 4919 -1 
            5044 4996 4919 -1 
            4996 5044 5121 -1 
            5073 4996 5121 -1 
            4823 4775 4698 -1 
            4775 4650 4698 -1 
            4650 4775 4727 -1 
            4727 4775 4852 -1 
            4775 4900 4852 -1 
            4775 4823 4900 -1 
            4871 4794 4919 -1 
            4746 4794 4871 -1 
            4794 4746 4669 -1 
            4717 4794 4669 -1 
            5246 5169 5294 -1 
            5246 5323 5198 -1 
            5246 5198 5121 -1 
            5169 5246 5121 -1 
            5371 5246 5294 -1 
            5323 5246 5371 -1 
            4987 4862 4910 -1 
            4987 4939 4862 -1 
            4987 5112 5064 -1 
            4939 4987 5064 -1 
            3833 3881 3958 -1 
            3958 3881 4006 -1 
            3881 3929 4006 -1 
            3881 3833 3756 -1 
            4044 4121 3996 -1 
            4198 4275 4150 -1 
            4198 4121 4246 -1 
            4198 4246 4323 -1 
            4275 4198 4323 -1 
            3718 3641 3766 -1 
            3670 3718 3795 -1 
            3718 3843 3795 -1 
            3843 3718 3766 -1 
            3545 3593 3670 -1 
            3593 3718 3670 -1 
            3718 3593 3641 -1 
            3593 3545 3468 -1 
            3151 3228 3103 -1 
            3151 3276 3228 -1 
            3026 3151 3103 -1 
            3151 3026 3074 -1 
            3267 3315 3392 -1 
            3315 3440 3392 -1 
            3190 3315 3267 -1 
            5457 5534 5409 -1 
            5582 5534 5457 -1 
            5361 5438 5313 -1 
            5409 5361 5284 -1 
            5361 5236 5284 -1 
            5236 5361 5313 -1 
            5381 5429 5506 -1 
            5429 5554 5506 -1 
            5554 5429 5477 -1 
            5477 5429 5352 -1 
            5352 5429 5304 -1 
            5429 5381 5304 -1 
            6131 6208 6083 -1 
            6179 6131 6054 -1 
            6131 6179 6256 -1 
            6208 6131 6256 -1 
            6131 6006 6054 -1 
            6006 6131 6083 -1 
            6208 6381 6160 -1 
            6381 6429 6554 -1 
            6160 6381 6554 -1 
            6381 6208 6256 -1 
            5650 5775 5727 -1 
            5900 5775 5823 -1 
            5573 5650 5525 -1 
            5621 5573 5496 -1 
            5573 5448 5496 -1 
            5448 5573 5525 -1 
            5535 5612 5487 -1 
            5583 5535 5458 -1 
            5410 5535 5487 -1 
            5458 5535 5410 -1 
            5679 5756 5631 -1 
            5756 5708 5631 -1 
            5756 5679 5804 -1 
            5881 5756 5804 -1 
            5737 5660 5785 -1 
            5660 5708 5785 -1 
            5660 5737 5612 -1 
            5708 5660 5583 -1 
            5535 5660 5612 -1 
            5660 5535 5583 -1 
            5900 6025 5977 -1 
            6025 6102 5977 -1 
            6150 6025 6073 -1 
            6102 6025 6150 -1 
            5996 6121 6073 -1 
            6121 5996 6044 -1 
            6044 5996 5919 -1 
            5852 5900 5977 -1 
            5929 5852 5977 -1 
            5852 5929 5804 -1 
            5852 5775 5900 -1 
            5727 5852 5804 -1 
            5775 5852 5727 -1 
            4986 4861 4909 -1 
            4861 4986 4938 -1 
            5351 5399 5476 -1 
            5399 5447 5524 -1 
            5476 5399 5524 -1 
            5399 5322 5447 -1 
            5322 5399 5274 -1 
            5399 5351 5274 -1 
            5081 5004 5129 -1 
            5004 5052 5129 -1 
            5004 5081 4956 -1 
            4879 5004 4956 -1 
            5359 5311 5234 -1 
            5484 5359 5407 -1 
            5388 5513 5465 -1 
            5379 5456 5331 -1 
            5504 5456 5379 -1 
            5533 5456 5581 -1 
            5456 5504 5581 -1 
            5264 5187 5312 -1 
            5110 5187 5062 -1 
            4889 4966 4841 -1 
            5014 4966 4889 -1 
            5101 5149 5226 -1 
            5149 5274 5226 -1 
            5274 5149 5197 -1 
            5149 5072 5197 -1 
            5072 5149 5024 -1 
            5149 5101 5024 -1 
            5130 5053 5178 -1 
            5053 5101 5178 -1 
            4658 4581 4706 -1 
            4581 4658 4533 -1 
            4783 4658 4706 -1 
            4610 4485 4533 -1 
            4610 4562 4485 -1 
            4658 4610 4533 -1 
            4601 4476 4524 -1 
            4476 4399 4524 -1 
            4399 4476 4351 -1 
            4476 4428 4351 -1 
            4505 4582 4457 -1 
            4505 4630 4582 -1 
            4428 4303 4351 -1 
            4351 4303 4226 -1 
            4178 4303 4255 -1 
            4303 4178 4226 -1 
            4380 4505 4457 -1 
            4505 4380 4428 -1 
            4303 4380 4255 -1 
            4380 4303 4428 -1 
            4061 4138 4013 -1 
            4138 4090 4013 -1 
            4263 4311 4388 -1 
            4388 4311 4436 -1 
            4311 4359 4436 -1 
            4359 4311 4234 -1 
            4023 4148 4100 -1 
            4023 4071 4148 -1 
            3975 4023 4100 -1 
            4023 3975 3898 -1 
            4138 4215 4090 -1 
            4215 4138 4263 -1 
            4215 4340 4292 -1 
            4215 4263 4340 -1 
            4696 4744 4821 -1 
            4869 4744 4792 -1 
            4744 4869 4821 -1 
            4744 4696 4619 -1 
            4725 4773 4850 -1 
            4773 4898 4850 -1 
            4898 4773 4821 -1 
            4773 4696 4821 -1 
            4648 4725 4600 -1 
            4696 4648 4571 -1 
            4773 4648 4696 -1 
            4648 4773 4725 -1 
            4648 4523 4571 -1 
            4523 4648 4600 -1 
            4677 4725 4802 -1 
            4677 4552 4600 -1 
            4725 4677 4600 -1 
            4917 4792 4840 -1 
            4917 4869 4792 -1 
            4965 4917 4840 -1 
            4917 4994 4869 -1 
            4917 4965 5042 -1 
            4994 4917 5042 -1 
            4753 4676 4801 -1 
            4071 4119 4196 -1 
            4119 4244 4196 -1 
            4119 3994 4042 -1 
            3994 4119 4071 -1 
            4715 4590 4638 -1 
            4590 4513 4638 -1 
            4590 4542 4465 -1 
            4513 4590 4465 -1 
            4686 4763 4638 -1 
            4763 4715 4638 -1 
            4763 4686 4811 -1 
            4715 4763 4840 -1 
            4763 4888 4840 -1 
            4888 4763 4811 -1 
            5378 5426 5503 -1 
            5551 5426 5474 -1 
            5503 5426 5551 -1 
            5426 5349 5474 -1 
            5426 5301 5349 -1 
            5301 5426 5378 -1 
            5253 5330 5205 -1 
            5378 5330 5253 -1 
            5330 5455 5407 -1 
            5330 5378 5455 -1 
            4984 5061 4936 -1 
            5109 5061 4984 -1 
            5186 5061 5109 -1 
            5157 5032 5080 -1 
            5157 5109 5032 -1 
            5205 5157 5080 -1 
            5109 5157 5234 -1 
            5090 5138 5215 -1 
            5138 5061 5186 -1 
            5263 5186 5311 -1 
            5388 5263 5311 -1 
            5138 5263 5215 -1 
            5263 5138 5186 -1 
            4043 4091 4168 -1 
            4091 4216 4168 -1 
            4216 4091 4139 -1 
            4091 4014 4139 -1 
            4014 4091 3966 -1 
            4091 4043 3966 -1 
            4033 3908 3956 -1 
            3908 3831 3956 -1 
            3985 4062 3937 -1 
            3985 3908 4033 -1 
            4062 3985 4110 -1 
            3985 4033 4110 -1 
            3985 3937 3860 -1 
            3908 3985 3860 -1 
            3658 3783 3735 -1 
            3735 3783 3860 -1 
            3783 3908 3860 -1 
            3908 3783 3831 -1 
            3879 3754 3802 -1 
            3831 3754 3879 -1 
            3754 3677 3802 -1 
            3754 3629 3677 -1 
            4371 4419 4496 -1 
            4419 4371 4294 -1 
            4371 4246 4294 -1 
            4246 4371 4323 -1 
            4592 4467 4515 -1 
            4467 4390 4515 -1 
            4342 4467 4419 -1 
            4390 4467 4342 -1 
            4813 4861 4938 -1 
            4813 4736 4861 -1 
            4774 4822 4899 -1 
            4947 4822 4870 -1 
            4822 4947 4899 -1 
            4822 4745 4870 -1 
            4745 4822 4697 -1 
            4822 4774 4697 -1 
            4832 4784 4707 -1 
            4784 4659 4707 -1 
            4659 4784 4736 -1 
            4736 4784 4861 -1 
            4784 4832 4909 -1 
            4861 4784 4909 -1 
            4717 4640 4765 -1 
            4592 4640 4717 -1 
            4640 4515 4563 -1 
            4640 4592 4515 -1 
            4486 4534 4611 -1 
            4534 4659 4611 -1 
            4659 4534 4582 -1 
            4582 4534 4457 -1 
            4534 4409 4457 -1 
            4534 4486 4409 -1 
            4515 4438 4563 -1 
            4438 4486 4563 -1 
            4390 4438 4515 -1 
            4486 4438 4361 -1 
            4361 4438 4313 -1 
            4438 4390 4313 -1 
            7995 7870 7918 -1 
            7870 7793 7918 -1 
            7870 7745 7793 -1 
            7870 7822 7745 -1 
            7851 7899 7976 -1 
            7899 8024 7976 -1 
            7726 7774 7851 -1 
            7774 7899 7851 -1 
            7899 7774 7822 -1 
            7822 7774 7697 -1 
            7774 7649 7697 -1 
            7649 7774 7726 -1 
            7908 7956 8033 -1 
            7956 7908 7831 -1 
            8081 8158 8033 -1 
            7889 8014 7966 -1 
            7841 7889 7966 -1 
            7889 7841 7764 -1 
            7957 8005 8082 -1 
            8005 8130 8082 -1 
            8130 8005 8053 -1 
            8053 8005 7928 -1 
            8005 7880 7928 -1 
            8005 7957 7880 -1 
            8474 8503 8426 -1 
            8474 8493 8503 -1 
            8493 8416 8464 -1 
            8416 8493 8368 -1 
            8388 8465 8340 -1 
            8388 8436 8465 -1 
            8263 8388 8340 -1 
            8311 8388 8263 -1 
            8388 8311 8436 -1 
            8186 8263 8138 -1 
            8186 8311 8263 -1 
            8282 8157 8205 -1 
            8157 8080 8205 -1 
            8157 8109 8032 -1 
            8080 8157 8032 -1 
            8503 8378 8426 -1 
            7811 7763 7686 -1 
            7888 7763 7811 -1 
            7906 7858 7781 -1 
            7858 7935 7810 -1 
            7858 7983 7935 -1 
            7983 7858 7906 -1 
            7062 7139 7014 -1 
            6937 7062 7014 -1 
            6985 7062 6937 -1 
            7139 7264 7216 -1 
            7485 7562 7437 -1 
            7802 7725 7850 -1 
            7465 7417 7340 -1 
            7292 7417 7369 -1 
            7417 7292 7340 -1 
            7590 7465 7513 -1 
            7436 7484 7561 -1 
            7484 7407 7532 -1 
            6600 6648 6725 -1 
            6648 6600 6523 -1 
            6600 6475 6523 -1 
            6475 6600 6552 -1 
            6754 6879 6831 -1 
            6879 6956 6831 -1 
            6677 6754 6629 -1 
            6677 6600 6725 -1 
            6677 6629 6552 -1 
            6600 6677 6552 -1 
            6956 6908 6831 -1 
            6783 6908 6860 -1 
            6908 6783 6831 -1 
            6908 6985 6860 -1 
            6985 6908 7033 -1 
            6908 6956 7033 -1 
            7177 7052 7100 -1 
            7244 7196 7119 -1 
            7148 7196 7273 -1 
            7273 7196 7321 -1 
            7196 7244 7321 -1 
            6975 7052 6927 -1 
            6975 7023 7100 -1 
            7052 6975 7100 -1 
            7023 7071 7148 -1 
            7196 7071 7119 -1 
            7071 7196 7148 -1 
            7071 6994 7119 -1 
            7071 7023 6946 -1 
            6994 7071 6946 -1 
            6773 6850 6725 -1 
            6850 6975 6927 -1 
            7090 7167 7042 -1 
            7138 7090 7013 -1 
            6965 7090 7042 -1 
            7013 7090 6965 -1 
            8360 8283 8408 -1 
            8283 8331 8408 -1 
            8225 8177 8100 -1 
            8052 8177 8129 -1 
            8177 8052 8100 -1 
            8177 8254 8129 -1 
            8177 8302 8254 -1 
            8177 8225 8302 -1 
            7735 7783 7860 -1 
            7783 7908 7860 -1 
            7908 7783 7831 -1 
            7687 7764 7639 -1 
            7562 7687 7639 -1 
            7658 7783 7735 -1 
            7658 7533 7581 -1 
            7956 7879 8004 -1 
            7879 7956 7831 -1 
            7879 7831 7754 -1 
            7802 7879 7754 -1 
            7927 7802 7850 -1 
            7927 7879 7802 -1 
            7927 8052 8004 -1 
            7879 7927 8004 -1 
            8196 8321 8273 -1 
            5419 5544 5496 -1 
            5419 5467 5544 -1 
            5371 5419 5496 -1 
            5467 5419 5342 -1 
            5419 5371 5294 -1 
            5342 5419 5294 -1 
            5794 5717 5842 -1 
            5794 5842 5919 -1 
            5669 5794 5746 -1 
            5717 5794 5669 -1 
            6245 6370 6322 -1 
            5995 5870 5918 -1 
            6043 5995 5918 -1 
            5832 5880 5957 -1 
            5755 5880 5832 -1 
            5880 5755 5803 -1 
            6053 5976 6101 -1 
            6178 6303 6255 -1 
            6178 6053 6101 -1 
            6332 6255 6380 -1 
            6332 6409 6284 -1 
            6457 6332 6380 -1 
            6409 6332 6457 -1 
            6034 6159 6111 -1 
            6082 6159 6034 -1 
            6313 6236 6361 -1 
            6236 6284 6361 -1 
            6188 6236 6313 -1 
            6236 6159 6284 -1 
            6236 6188 6111 -1 
            6159 6236 6111 -1 
            6582 6534 6457 -1 
            6409 6534 6486 -1 
            6534 6409 6457 -1 
            6534 6611 6486 -1 
            6534 6582 6659 -1 
            6611 6534 6659 -1 
            6582 6630 6707 -1 
            6630 6755 6707 -1 
            6629 6581 6504 -1 
            6504 6581 6456 -1 
            6581 6533 6456 -1 
            6533 6581 6658 -1 
            6437 6514 6389 -1 
            6014 6062 6139 -1 
            6062 6187 6139 -1 
            5985 6062 5937 -1 
            6062 6014 5937 -1 
            6360 6283 6408 -1 
            6360 6437 6312 -1 
            6485 6360 6408 -1 
            6437 6360 6485 -1 
            6187 6235 6312 -1 
            6235 6360 6312 -1 
            6360 6235 6283 -1 
            6283 6235 6158 -1 
            6283 6206 6331 -1 
            6206 6254 6331 -1 
            6206 6283 6158 -1 
            6254 6206 6129 -1 
            6206 6081 6129 -1 
            6081 6206 6158 -1 
            6573 6698 6650 -1 
            6573 6621 6698 -1 
            6544 6496 6419 -1 
            6496 6573 6448 -1 
            6496 6544 6621 -1 
            6573 6496 6621 -1 
            6323 6371 6448 -1 
            6371 6496 6448 -1 
            6496 6371 6419 -1 
            6419 6371 6294 -1 
            6371 6246 6294 -1 
            6371 6323 6246 -1 
            6275 6352 6227 -1 
            6150 6275 6227 -1 
            6400 6323 6448 -1 
            6275 6400 6352 -1 
            6400 6275 6323 -1 
            6544 6467 6592 -1 
            6467 6515 6592 -1 
            6467 6544 6419 -1 
            6342 6467 6419 -1 
            6563 6438 6486 -1 
            6515 6438 6563 -1 
            6486 6438 6361 -1 
            6438 6313 6361 -1 
            7524 7447 7572 -1 
            7524 7601 7476 -1 
            7524 7649 7601 -1 
            7649 7524 7572 -1 
            7399 7274 7322 -1 
            7447 7399 7322 -1 
            7399 7524 7476 -1 
            7524 7399 7447 -1 
            6706 6754 6831 -1 
            6783 6706 6831 -1 
            6706 6783 6658 -1 
            6754 6706 6629 -1 
            6581 6706 6658 -1 
            6706 6581 6629 -1 
            6918 7043 6995 -1 
            6918 6966 7043 -1 
            6764 6812 6889 -1 
            6764 6687 6812 -1 
            6476 6428 6351 -1 
            6303 6428 6380 -1 
            6428 6303 6351 -1 
            7514 7466 7389 -1 
            7466 7543 7418 -1 
            7466 7591 7543 -1 
            7466 7514 7591 -1 
            7293 7341 7418 -1 
            7341 7466 7418 -1 
            7466 7341 7389 -1 
            7341 7264 7389 -1 
            7341 7293 7216 -1 
            7264 7341 7216 -1 
            7322 7245 7370 -1 
            7245 7293 7370 -1 
            7245 7322 7197 -1 
            7293 7245 7168 -1 
            7245 7120 7168 -1 
            7120 7245 7197 -1 
            2686 2811 2763 -1 
            2734 2811 2686 -1 
            2705 2782 2657 -1 
            2782 2734 2657 -1 
            2782 2705 2830 -1 
            3176 3051 3099 -1 
            2273 2196 2321 -1 
            2273 2350 2225 -1 
            2148 2273 2225 -1 
            2196 2273 2148 -1 
            2273 2398 2350 -1 
            2398 2273 2321 -1 
            2369 2446 2321 -1 
            2446 2398 2321 -1 
            2446 2369 2494 -1 
            2398 2446 2523 -1 
            2571 2446 2494 -1 
            2446 2571 2523 -1 
            2407 2532 2484 -1 
            2407 2455 2532 -1 
            2359 2407 2484 -1 
            2455 2407 2330 -1 
            2407 2282 2330 -1 
            2282 2407 2359 -1 
            2455 2503 2580 -1 
            2551 2503 2426 -1 
            2503 2378 2426 -1 
            2503 2455 2378 -1 
            1773 1821 1898 -1 
            1946 1821 1869 -1 
            1821 1946 1898 -1 
            1696 1821 1773 -1 
            2677 2629 2552 -1 
            2629 2504 2552 -1 
            2504 2629 2581 -1 
            2581 2629 2706 -1 
            2629 2754 2706 -1 
            2629 2677 2754 -1 
            2725 2648 2773 -1 
            2677 2725 2802 -1 
            2600 2677 2552 -1 
            2648 2600 2523 -1 
            2725 2600 2648 -1 
            2600 2725 2677 -1 
            2600 2475 2523 -1 
            2475 2600 2552 -1 
            2331 2283 2206 -1 
            2158 2283 2235 -1 
            2206 2283 2158 -1 
            2283 2360 2235 -1 
            2360 2283 2408 -1 
            2283 2331 2408 -1 
            2331 2456 2408 -1 
            2456 2533 2408 -1 
            2533 2456 2581 -1 
            2456 2504 2581 -1 
            2379 2456 2331 -1 
            2456 2379 2504 -1 
            2302 2379 2254 -1 
            2379 2331 2254 -1 
            3264 3216 3139 -1 
            3216 3091 3139 -1 
            3091 3216 3168 -1 
            3216 3264 3341 -1 
            3312 3187 3235 -1 
            3312 3264 3187 -1 
            3360 3312 3235 -1 
            3206 3254 3331 -1 
            3283 3206 3331 -1 
            3206 3283 3158 -1 
            3206 3129 3254 -1 
            3081 3206 3158 -1 
            3129 3206 3081 -1 
            3427 3350 3475 -1 
            3350 3398 3475 -1 
            3350 3427 3302 -1 
            3052 3100 3177 -1 
            3100 3052 2975 -1 
            3590 3638 3715 -1 
            3965 3840 3888 -1 
            3965 3917 3840 -1 
            3965 3888 4013 -1 
            3917 3965 4042 -1 
            4090 3965 4013 -1 
            4042 3965 4090 -1 
            3532 3484 3407 -1 
            3484 3359 3407 -1 
            3359 3484 3436 -1 
            3436 3484 3561 -1 
            3484 3609 3561 -1 
            3484 3532 3609 -1 
            3109 3186 3061 -1 
            3157 3080 3205 -1 
            3186 3138 3061 -1 
            3138 3215 3090 -1 
            3215 3138 3263 -1 
            3138 3186 3263 -1 
            3061 3013 2936 -1 
            2965 3013 3090 -1 
            3013 3138 3090 -1 
            3138 3013 3061 -1 
            2840 2715 2763 -1 
            2715 2840 2792 -1 
            3282 3330 3407 -1 
            3359 3282 3407 -1 
            3330 3282 3205 -1 
            3282 3157 3205 -1 
            3119 3042 3167 -1 
            3071 3119 3196 -1 
            3244 3119 3167 -1 
            3119 3244 3196 -1 
            2946 2821 2869 -1 
            3523 3571 3648 -1 
            3648 3571 3696 -1 
            3571 3619 3696 -1 
            3571 3523 3446 -1 
            3522 3474 3397 -1 
            3647 3570 3695 -1 
            3647 3522 3570 -1 
            3349 3224 3272 -1 
            3349 3301 3224 -1 
            3397 3349 3272 -1 
            3301 3349 3426 -1 
            3474 3349 3397 -1 
            3349 3474 3426 -1 
            8147 8070 8195 -1 
            8272 8147 8195 -1 
            8147 8272 8224 -1 
            7407 7455 7532 -1 
            7455 7580 7532 -1 
            7561 7609 7686 -1 
            7609 7484 7532 -1 
            7484 7609 7561 -1 
            7695 7618 7743 -1 
            8185 8233 8310 -1 
            8233 8281 8358 -1 
            8310 8233 8358 -1 
            8233 8156 8281 -1 
            8156 8233 8108 -1 
            8233 8185 8108 -1 
            7935 8012 7887 -1 
            7618 7666 7743 -1 
            7666 7714 7791 -1 
            7743 7666 7791 -1 
            7599 7522 7647 -1 
            7474 7522 7599 -1 
            7397 7522 7474 -1 
            1814 1939 1891 -1 
            1833 1756 1881 -1 
            1824 1901 1776 -1 
            1699 1824 1776 -1 
            1824 1699 1747 -1 
            1997 1920 2045 -1 
            1920 1795 1843 -1 
            2218 2266 2343 -1 
            2314 2266 2189 -1 
            2189 2266 2141 -1 
            2266 2218 2141 -1 
            2545 2468 2593 -1 
            2497 2545 2622 -1 
            2545 2497 2420 -1 
            2468 2545 2420 -1 
            2545 2670 2622 -1 
            2670 2545 2593 -1 
            2391 2468 2343 -1 
            2439 2391 2314 -1 
            2468 2391 2516 -1 
            2391 2439 2516 -1 
            2266 2391 2343 -1 
            2391 2266 2314 -1 
            2487 2564 2439 -1 
            2487 2410 2535 -1 
            2487 2535 2612 -1 
            2564 2487 2612 -1 
            2410 2487 2362 -1 
            2487 2439 2362 -1 
            2276 2199 2324 -1 
            2228 2276 2353 -1 
            2276 2324 2401 -1 
            2353 2276 2401 -1 
            1988 1911 2036 -1 
            1988 2065 1940 -1 
            1988 2036 2113 -1 
            2065 1988 2113 -1 
            1738 1661 1786 -1 
            1738 1613 1661 -1 
            1815 1940 1892 -1 
            2103 2228 2180 -1 
            2055 2103 2180 -1 
            2084 2007 2132 -1 
            2007 2055 2132 -1 
            1603 1680 1555 -1 
            1680 1632 1555 -1 
            1680 1603 1728 -1 
            1632 1680 1757 -1 
            1680 1805 1757 -1 
            1805 1680 1728 -1 
            2717 2669 2592 -1 
            2669 2544 2592 -1 
            2544 2669 2621 -1 
            2669 2746 2621 -1 
            2746 2669 2794 -1 
            2669 2717 2794 -1 
            2765 2688 2813 -1 
            2765 2842 2717 -1 
            2765 2813 2890 -1 
            2842 2765 2890 -1 
            2640 2717 2592 -1 
            2688 2640 2563 -1 
            2765 2640 2688 -1 
            2640 2765 2717 -1 
            2640 2592 2515 -1 
            2563 2640 2515 -1 
            2198 2246 2323 -1 
            2275 2198 2323 -1 
            2198 2073 2121 -1 
            2246 2198 2121 -1 
            2179 2054 2102 -1 
            2102 2054 1977 -1 
            1929 2054 2006 -1 
            2054 1929 1977 -1 
            2131 2256 2208 -1 
            2131 2179 2256 -1 
            2083 2131 2208 -1 
            2131 2054 2179 -1 
            2131 2083 2006 -1 
            2054 2131 2006 -1 
            2333 2458 2410 -1 
            2333 2381 2458 -1 
            2333 2410 2285 -1 
            2381 2333 2256 -1 
            2208 2333 2285 -1 
            2256 2333 2208 -1 
            2736 2659 2784 -1 
            2659 2707 2784 -1 
            2659 2736 2611 -1 
            2534 2659 2611 -1 
            2332 2457 2409 -1 
            92 44 15 -1 
            140 92 15 -1 
            92 140 217 -1 
            188 63 111 -1 
            188 140 63 -1 
            236 188 111 -1 
            140 188 265 -1 
            602 525 650 -1 
            525 573 650 -1 
            448 525 400 -1 
            573 525 448 -1 
            380 428 505 -1 
            428 380 303 -1 
            255 380 332 -1 
            303 380 255 -1 
            304 381 256 -1 
            304 256 179 -1 
            247 199 122 -1 
            151 276 228 -1 
            199 276 151 -1 
            276 353 228 -1 
            353 276 401 -1 
            218 170 93 -1 
            45 170 122 -1 
            93 170 45 -1 
            170 247 122 -1 
            170 218 295 -1 
            247 170 295 -1 
            391 343 266 -1 
            343 218 266 -1 
            218 343 295 -1 
            343 391 468 -1 
            439 362 487 -1 
            391 439 516 -1 
            439 564 516 -1 
            439 487 564 -1 
            314 391 266 -1 
            314 237 362 -1 
            439 314 362 -1 
            314 439 391 -1 
            189 314 266 -1 
            237 314 189 -1 
            282 157 205 -1 
            157 80 205 -1 
            80 157 32 -1 
            157 109 32 -1 
            253 330 205 -1 
            253 176 301 -1 
            253 205 128 -1 
            176 253 128 -1 
            426 378 301 -1 
            378 253 301 -1 
            253 378 330 -1 
            407 359 282 -1 
            330 407 282 -1 
            407 484 359 -1 
            302 254 177 -1 
            225 302 177 -1 
            302 225 350 -1 
            302 379 254 -1 
            427 302 350 -1 
            379 302 427 -1 
            369 494 446 -1 
            321 369 446 -1 
            465 340 388 -1 
            312 235 360 -1 
            312 389 264 -1 
            187 312 264 -1 
            235 312 187 -1 
            312 360 437 -1 
            389 312 437 -1 
            197 120 245 -1 
            322 197 245 -1 
            197 149 72 -1 
            120 197 72 -1 
            293 341 418 -1 
            341 466 418 -1 
            466 341 389 -1 
            389 341 264 -1 
            341 216 264 -1 
            216 341 293 -1 
            370 447 322 -1 
            447 399 322 -1 
            447 370 495 -1 
            572 447 495 -1 
            226 101 149 -1 
            149 101 24 -1 
            101 53 24 -1 
            283 206 331 -1 
            235 283 360 -1 
            283 235 158 -1 
            206 283 158 -1 
            254 129 177 -1 
            206 129 254 -1 
            129 206 81 -1 
            129 52 177 -1 
            129 81 4 -1 
            52 129 4 -1 
            456 408 331 -1 
            408 283 331 -1 
            283 408 360 -1 
            562 514 437 -1 
            514 562 639 -1 
            562 687 639 -1 
            562 610 687 -1 
            629 706 581 -1 
            629 677 754 -1 
            706 629 754 -1 
            504 379 427 -1 
            379 504 456 -1 
            456 504 581 -1 
            504 629 581 -1 
            811 859 936 -1 
            907 859 782 -1 
            715 638 763 -1 
            715 792 667 -1 
            792 715 840 -1 
            715 763 840 -1 
            513 465 388 -1 
            638 513 561 -1 
            619 542 667 -1 
            542 619 494 -1 
            686 638 561 -1 
            763 686 811 -1 
            638 686 763 -1 
            677 725 802 -1 
            600 725 677 -1 
            898 850 773 -1 
            725 850 802 -1 
            850 725 773 -1 
            850 927 802 -1 
            898 946 1023 -1 
            946 1071 1023 -1 
            946 994 1071 -1 
            946 869 994 -1 
            946 898 821 -1 
            869 946 821 -1 
            917 792 840 -1 
            917 869 792 -1 
            917 840 965 -1 
            869 917 994 -1 
            1042 917 965 -1 
            994 917 1042 -1 
            1109 1186 1061 -1 
            1109 1032 1157 -1 
            1109 1157 1234 -1 
            1186 1109 1234 -1 
            1138 1263 1215 -1 
            1186 1263 1138 -1 
            1263 1186 1311 -1 
            1263 1340 1215 -1 
            1379 1427 1504 -1 
            1552 1427 1475 -1 
            1427 1552 1504 -1 
            1475 1427 1350 -1 
            1427 1302 1350 -1 
            1302 1427 1379 -1 
            1485 1408 1533 -1 
            1774 1851 1726 -1 
            1822 1774 1697 -1 
            1774 1899 1851 -1 
            1899 1774 1822 -1 
            1678 1601 1726 -1 
            1803 1678 1726 -1 
            1447 1495 1572 -1 
            1524 1447 1572 -1 
            1601 1649 1726 -1 
            1649 1774 1726 -1 
            1774 1649 1697 -1 
            1697 1649 1572 -1 
            1649 1524 1572 -1 
            1649 1601 1524 -1 
            1966 2014 2091 -1 
            2014 2139 2091 -1 
            2139 2014 2062 -1 
            1543 1620 1495 -1 
            1485 1562 1437 -1 
            1975 1927 1850 -1 
            1879 1927 2004 -1 
            1927 1975 2052 -1 
            2004 1927 2052 -1 
            1677 1754 1629 -1 
            1275 1198 1323 -1 
            1198 1246 1323 -1 
            1150 1198 1275 -1 
            1246 1198 1121 -1 
            1131 1054 1179 -1 
            1006 1054 1131 -1 
            1169 1246 1121 -1 
            1294 1217 1342 -1 
            1246 1294 1371 -1 
            1169 1294 1246 -1 
            1294 1169 1217 -1 
            1294 1419 1371 -1 
            1419 1294 1342 -1 
            1217 1265 1342 -1 
            1611 1736 1688 -1 
            1611 1659 1736 -1 
            1284 1361 1236 -1 
            1244 1292 1369 -1 
            1321 1244 1369 -1 
            1244 1119 1167 -1 
            1292 1244 1167 -1 
            879 831 754 -1 
            706 831 783 -1 
            831 706 754 -1 
            831 879 956 -1 
            975 1052 927 -1 
            975 898 1023 -1 
            1100 975 1023 -1 
            1052 975 1100 -1 
            850 975 927 -1 
            975 850 898 -1 
            764 716 639 -1 
            591 716 668 -1 
            716 591 639 -1 
            812 687 735 -1 
            812 764 687 -1 
            860 812 735 -1 
            812 860 937 -1 
            841 966 918 -1 
            841 716 764 -1 
            908 956 1033 -1 
            985 908 1033 -1 
            908 985 860 -1 
            908 831 956 -1 
            908 860 783 -1 
            831 908 783 -1 
            930 882 805 -1 
            882 757 805 -1 
            757 882 834 -1 
            882 959 834 -1 
            882 1007 959 -1 
            882 930 1007 -1 
            1622 1545 1670 -1 
            1545 1593 1670 -1 
            1545 1622 1497 -1 
            1593 1545 1468 -1 
            1420 1545 1497 -1 
            1545 1420 1468 -1 
            1439 1314 1362 -1 
            1362 1314 1237 -1 
            1237 1314 1189 -1 
            1314 1266 1189 -1 
            1679 1756 1631 -1 
            1679 1554 1602 -1 
            1679 1631 1554 -1 
            1804 1929 1881 -1 
            1756 1804 1881 -1 
            1679 1804 1756 -1 
            1756 1708 1631 -1 
            1583 1708 1660 -1 
            1631 1708 1583 -1 
            1708 1785 1660 -1 
            1708 1833 1785 -1 
            1833 1708 1756 -1 
            1218 1295 1170 -1 
            1266 1218 1141 -1 
            1218 1266 1343 -1 
            1295 1218 1343 -1 
            1141 1218 1093 -1 
            1218 1170 1093 -1 
            227 304 179 -1 
            304 227 352 -1 
            227 102 150 -1 
            102 227 179 -1 
            371 323 246 -1 
            448 371 496 -1 
            323 371 448 -1 
            457 505 582 -1 
            380 457 332 -1 
            457 380 505 -1 
            563 486 611 -1 
            515 563 640 -1 
            688 563 611 -1 
            640 563 688 -1 
            438 515 390 -1 
            563 438 486 -1 
            438 563 515 -1 
            669 544 592 -1 
            1592 1717 1669 -1 
            1640 1717 1592 -1 
            1717 1640 1765 -1 
            1669 1717 1794 -1 
            1717 1842 1794 -1 
            1717 1765 1842 -1 
            709 632 757 -1 
            661 709 786 -1 
            709 661 584 -1 
            632 709 584 -1 
            786 709 834 -1 
            709 757 834 -1 
            1249 1374 1326 -1 
            1374 1249 1297 -1 
            1297 1345 1422 -1 
            1345 1297 1220 -1 
            1335 1412 1287 -1 
            1412 1364 1287 -1 
            1460 1412 1335 -1 
            1364 1412 1489 -1 
            1412 1537 1489 -1 
            1537 1412 1460 -1 
            1123 1046 1171 -1 
            1046 1094 1171 -1 
            1046 1123 998 -1 
            1094 1046 969 -1 
            921 1046 998 -1 
            1046 921 969 -1 
            1267 1190 1315 -1 
            2370 2322 2245 -1 
            2197 2322 2274 -1 
            2245 2322 2197 -1 
            2322 2399 2274 -1 
            2322 2447 2399 -1 
            2322 2370 2447 -1 
            1995 2043 2120 -1 
            2043 2091 2168 -1 
            2120 2043 2168 -1 
            2043 1966 2091 -1 
            1947 2072 2024 -1 
            1947 1995 2072 -1 
            1899 1947 2024 -1 
            1995 1947 1870 -1 
            1947 1822 1870 -1 
            1947 1899 1822 -1 
            1918 1995 1870 -1 
            2043 1918 1966 -1 
            1918 2043 1995 -1 
            2159 2111 2034 -1 
            2111 2063 1986 -1 
            2034 2111 1986 -1 
            2476 2524 2601 -1 
            2649 2524 2572 -1 
            2601 2524 2649 -1 
            2524 2447 2572 -1 
            2447 2524 2399 -1 
            2524 2476 2399 -1 
            2832 2957 2909 -1 
            2957 3034 2909 -1 
            2957 3082 3034 -1 
            3082 2957 3005 -1 
            3082 3207 3159 -1 
            3034 3082 3159 -1 
            3024 2899 2947 -1 
            3024 2976 2899 -1 
            2928 2803 2851 -1 
            2976 2928 2851 -1 
            3005 2928 3053 -1 
            2928 2976 3053 -1 
            3399 3322 3447 -1 
            3322 3399 3274 -1 
            3466 3514 3591 -1 
            3639 3514 3562 -1 
            3591 3514 3639 -1 
            3612 3737 3689 -1 
            3737 3814 3689 -1 
            3737 3785 3862 -1 
            3814 3737 3862 -1 
            3660 3612 3535 -1 
            3737 3660 3785 -1 
            3660 3737 3612 -1 
            3343 3391 3468 -1 
            3391 3314 3439 -1 
            3391 3343 3266 -1 
            3314 3391 3266 -1 
            3516 3439 3564 -1 
            3516 3593 3468 -1 
            3391 3516 3468 -1 
            3516 3391 3439 -1 
            3641 3516 3564 -1 
            3593 3516 3641 -1 
            3439 3487 3564 -1 
            3612 3487 3535 -1 
            3487 3612 3564 -1 
            3487 3410 3535 -1 
            3410 3362 3285 -1 
            3362 3237 3285 -1 
            3362 3314 3237 -1 
            3314 3362 3439 -1 
            3487 3362 3410 -1 
            3362 3487 3439 -1 
            3554 3679 3631 -1 
            3477 3554 3429 -1 
            3506 3554 3631 -1 
            3554 3506 3429 -1 
            3429 3506 3381 -1 
            3506 3458 3381 -1 
            3852 3900 3977 -1 
            3900 4025 3977 -1 
            4025 3900 3948 -1 
            3871 3919 3996 -1 
            3919 4044 3996 -1 
            3746 3621 3669 -1 
            3621 3573 3496 -1 
            3582 3659 3534 -1 
            2862 2737 2785 -1 
            2814 2737 2862 -1 
            2737 2612 2660 -1 
            2785 2737 2660 -1 
            2939 2814 2862 -1 
            2987 2939 2862 -1 
            3064 2939 2987 -1 
            3141 3064 3189 -1 
            3995 4072 3947 -1 
            4043 3995 3918 -1 
            3995 4043 4120 -1 
            4072 3995 4120 -1 
            3870 3995 3947 -1 
            3995 3870 3918 -1 
            4149 4072 4197 -1 
            4101 4149 4226 -1 
            4024 4149 4101 -1 
            4072 4149 4024 -1 
            4149 4274 4226 -1 
            4149 4197 4274 -1 
            3044 3092 3169 -1 
            3121 3044 3169 -1 
            2996 3044 3121 -1 
            3092 3044 2967 -1 
            3044 2919 2967 -1 
            3044 2996 2919 -1 
            3256 3179 3304 -1 
            3179 3227 3304 -1 
            3227 3179 3102 -1 
            3179 3054 3102 -1 
            3150 3275 3227 -1 
            3227 3275 3352 -1 
            3275 3400 3352 -1 
            3275 3323 3400 -1 
            2727 2650 2775 -1 
            2679 2727 2804 -1 
            2650 2727 2602 -1 
            2727 2679 2602 -1 
            2727 2852 2804 -1 
            2727 2775 2852 -1 
            3361 3409 3486 -1 
            3409 3534 3486 -1 
            3409 3457 3534 -1 
            3284 3409 3361 -1 
            3332 3409 3284 -1 
            3409 3332 3457 -1 
            3332 3207 3255 -1 
            3207 3332 3284 -1 
            3505 3380 3428 -1 
            3457 3380 3505 -1 
            3332 3380 3457 -1 
            3380 3303 3428 -1 
            3303 3380 3255 -1 
            3380 3332 3255 -1 
            3399 3351 3274 -1 
            3226 3351 3303 -1 
            3351 3226 3274 -1 
            3303 3351 3428 -1 
            3351 3476 3428 -1 
            3351 3399 3476 -1 
            1363 1440 1315 -1 
            1363 1488 1440 -1 
            1440 1565 1517 -1 
            1488 1565 1440 -1 
            1565 1488 1613 -1 
            1517 1565 1642 -1 
            1344 1469 1421 -1 
            1344 1392 1469 -1 
            1344 1421 1296 -1 
            1392 1344 1267 -1 
            1219 1344 1296 -1 
            1344 1219 1267 -1 
            2305 2353 2430 -1 
            2382 2305 2430 -1 
            2305 2382 2257 -1 
            2305 2228 2353 -1 
            2180 2305 2257 -1 
            2228 2305 2180 -1 
            2507 2555 2632 -1 
            2680 2555 2603 -1 
            2555 2680 2632 -1 
            2555 2478 2603 -1 
            2478 2555 2430 -1 
            2555 2507 2430 -1 
            2920 2795 2843 -1 
            2795 2718 2843 -1 
            2795 2670 2718 -1 
            2795 2747 2670 -1 
            2901 2824 2949 -1 
            2747 2824 2699 -1 
            2699 2651 2574 -1 
            2651 2603 2526 -1 
            2574 2651 2526 -1 
            2247 2295 2372 -1 
            2295 2343 2420 -1 
            2372 2295 2420 -1 
            2295 2218 2343 -1 
            2295 2170 2218 -1 
            2295 2247 2170 -1 
            1969 1844 1892 -1 
            711 586 634 -1 
            759 711 634 -1 
            836 759 884 -1 
            1009 836 884 -1 
            836 1009 788 -1 
            711 836 788 -1 
            836 711 759 -1 
            1566 1614 1691 -1 
            1614 1739 1691 -1 
            1739 1614 1662 -1 
            1614 1537 1662 -1 
            1537 1614 1489 -1 
            1614 1566 1489 -1 
            826 903 778 -1 
            701 826 778 -1 
            826 951 903 -1 
            951 826 874 -1 
            1047 999 922 -1 
            1143 1066 1191 -1 
            1095 1143 1220 -1 
            1143 1095 1018 -1 
            1066 1143 1018 -1 
            1576 1499 1624 -1 
            1499 1576 1451 -1 
            1576 1403 1451 -1 
            1403 1576 1355 -1 
            1576 1749 1355 -1 
            1576 1624 1749 -1 
            1748 1796 1873 -1 
            1825 1748 1873 -1 
            1748 1825 1700 -1 
            1748 1671 1796 -1 
            1623 1748 1700 -1 
            1671 1748 1623 -1 
            2027 1950 2075 -1 
            1950 1998 2075 -1 
            1950 1825 1873 -1 
            1998 1950 1873 -1 
            3152 3104 3027 -1 
            3075 3152 3027 -1 
            3104 3152 3277 -1 
            3142 3219 3094 -1 
            3190 3142 3065 -1 
            3142 3190 3267 -1 
            3219 3142 3267 -1 
            3142 3017 3065 -1 
            3017 3142 3094 -1 
            2652 2729 2604 -1 
            2729 2681 2604 -1 
            2729 2652 2777 -1 
            2681 2729 2806 -1 
            2854 2729 2777 -1 
            2729 2854 2806 -1 
            2989 2864 2912 -1 
            2864 2787 2912 -1 
            2864 2816 2739 -1 
            2787 2864 2739 -1 
            3999 3922 4047 -1 
            3999 4076 3951 -1 
            3874 3999 3951 -1 
            3922 3999 3874 -1 
            4076 3999 4124 -1 
            3999 4047 4124 -1 
            2655 2732 2607 -1 
            2732 2684 2607 -1 
            2732 2655 2780 -1 
            2684 2732 2809 -1 
            2732 2857 2809 -1 
            2857 2732 2780 -1 
            3241 3193 3116 -1 
            3068 3193 3145 -1 
            3116 3193 3068 -1 
            3193 3270 3145 -1 
            3270 3193 3318 -1 
            3193 3241 3318 -1 
            3087 3212 3164 -1 
            3039 3087 3164 -1 
            2991 3116 3068 -1 
            2991 3039 3116 -1 
            2827 2779 2702 -1 
            2750 2827 2702 -1 
            2827 2750 2875 -1 
            2779 2827 2904 -1 
            2952 2827 2875 -1 
            2904 2827 2952 -1 
            2606 2683 2558 -1 
            2606 2731 2683 -1 
            3125 3048 3173 -1 
            3048 2923 2971 -1 
            3269 3394 3346 -1 
            3221 3269 3346 -1 
            3269 3221 3144 -1 
            2951 2826 2874 -1 
            2903 2826 2951 -1 
            2826 2749 2874 -1 
            2826 2701 2749 -1 
            2318 2193 2241 -1 
            2193 2318 2270 -1 
            2395 2318 2443 -1 
            2270 2318 2395 -1 
            2289 2337 2414 -1 
            2289 2241 2164 -1 
            2337 2462 2414 -1 
            2414 2462 2539 -1 
            2539 2462 2587 -1 
            2462 2510 2587 -1 
            2212 2289 2164 -1 
            2289 2212 2337 -1 
            2193 2145 2068 -1 
            2020 2145 2097 -1 
            2145 2020 2068 -1 
            2145 2222 2097 -1 
            2145 2270 2222 -1 
            2145 2193 2270 -1 
            2116 2193 2068 -1 
            1991 2116 2068 -1 
            2241 2116 2164 -1 
            2193 2116 2241 -1 
            2529 2404 2452 -1 
            2404 2327 2452 -1 
            2865 2817 2740 -1 
            2692 2817 2769 -1 
            2817 2692 2740 -1 
            2817 2894 2769 -1 
            2894 2817 2942 -1 
            2817 2865 2942 -1 
            2836 2788 2711 -1 
            2788 2865 2740 -1 
            2865 2788 2913 -1 
            2788 2836 2913 -1 
            2884 2807 2932 -1 
            2836 2884 2961 -1 
            3009 2884 2932 -1 
            2961 2884 3009 -1 
            2759 2836 2711 -1 
            2634 2759 2711 -1 
            2884 2759 2807 -1 
            2759 2884 2836 -1 
            2519 2394 2442 -1 
            2567 2519 2442 -1 
            2567 2692 2644 -1 
            2519 2567 2644 -1 
            2481 2558 2433 -1 
            2481 2404 2529 -1 
            2481 2606 2558 -1 
            2606 2481 2529 -1 
            1683 1635 1558 -1 
            2250 2327 2202 -1 
            2125 2250 2202 -1 
            2183 2058 2106 -1 
            2039 1991 1914 -1 
            1962 2039 1914 -1 
            2116 2039 2164 -1 
            2039 2116 1991 -1 
            1856 1808 1731 -1 
            1808 1856 1933 -1 
            1981 2058 1933 -1 
            2058 1981 2106 -1 
            1856 1981 1933 -1 
            1981 1856 1904 -1 
            2144 2221 2096 -1 
            2221 2144 2269 -1 
            1865 1817 1740 -1 
            1817 1692 1740 -1 
            1692 1817 1769 -1 
            1817 1865 1942 -1 
            2192 2144 2067 -1 
            2144 2192 2269 -1 
            2413 2336 2461 -1 
            2038 1913 1961 -1 
            3387 3435 3512 -1 
            3560 3435 3483 -1 
            3512 3435 3560 -1 
            3435 3387 3310 -1 
            2676 2628 2551 -1 
            2503 2628 2580 -1 
            2628 2503 2551 -1 
            2628 2705 2580 -1 
            2705 2753 2830 -1 
            2801 2753 2676 -1 
            2628 2753 2705 -1 
            2753 2628 2676 -1 
            2724 2801 2676 -1 
            2772 2724 2647 -1 
            2724 2599 2647 -1 
            2724 2676 2599 -1 
            2926 3051 3003 -1 
            2964 3012 3089 -1 
            3089 3012 3137 -1 
            3012 3060 3137 -1 
            3012 2935 3060 -1 
            2935 3012 2887 -1 
            3012 2964 2887 -1 
            3262 3214 3137 -1 
            3214 3089 3137 -1 
            3214 3166 3089 -1 
            3166 3214 3291 -1 
            3214 3339 3291 -1 
            3214 3262 3339 -1 
            2916 2993 2868 -1 
            2964 2916 2839 -1 
            3041 2964 3089 -1 
            2993 3041 3118 -1 
            3041 2916 2964 -1 
            2916 3041 2993 -1 
            3041 3166 3118 -1 
            3166 3041 3089 -1 
            1792 1715 1840 -1 
            1715 1763 1840 -1 
            1715 1792 1667 -1 
            1715 1638 1763 -1 
            1590 1715 1667 -1 
            1638 1715 1590 -1 
            1359 1436 1311 -1 
            1465 1590 1542 -1 
            1292 1417 1369 -1 
            1340 1417 1292 -1 
            1465 1417 1340 -1 
            1369 1417 1494 -1 
            1494 1417 1542 -1 
            1417 1465 1542 -1 
            2618 2570 2493 -1 
            2570 2522 2445 -1 
            2493 2570 2445 -1 
            2522 2570 2647 -1 
            2589 2666 2541 -1 
            2666 2618 2541 -1 
            2666 2589 2714 -1 
            521 473 396 -1 
            492 367 415 -1 
            59 136 11 -1 
            30 59 11 -1 
            107 59 30 -1 
            184 107 232 -1 
            184 232 309 -1 
            184 59 107 -1 
            59 184 136 -1 
            348 271 396 -1 
            473 348 396 -1 
            300 377 252 -1 
            175 223 300 -1 
            348 223 271 -1 
            223 348 300 -1 
            223 175 98 -1 
            175 127 50 -1 
            127 79 2 -1 
            50 127 2 -1 
            127 204 79 -1 
            204 127 252 -1 
            127 175 252 -1 
            368 445 320 -1 
            368 291 416 -1 
            368 243 291 -1 
            243 368 320 -1 
            589 714 666 -1 
            541 589 666 -1 
            512 589 464 -1 
            589 541 464 -1 
            541 493 416 -1 
            493 368 416 -1 
            368 493 445 -1 
            445 493 570 -1 
            493 618 570 -1 
            618 493 541 -1 
            522 474 397 -1 
            474 426 349 -1 
            397 474 349 -1 
            426 474 551 -1 
            695 647 570 -1 
            647 522 570 -1 
            647 772 724 -1 
            647 695 772 -1 
            1502 1454 1377 -1 
            1454 1502 1579 -1 
            1627 1502 1550 -1 
            1502 1627 1579 -1 
            1406 1483 1358 -1 
            1406 1454 1531 -1 
            1483 1406 1531 -1 
            1675 1598 1723 -1 
            1598 1646 1723 -1 
            1598 1675 1550 -1 
            1598 1521 1646 -1 
            1473 1598 1550 -1 
            1521 1598 1473 -1 
            1761 1838 1713 -1 
            1838 1761 1886 -1 
            1790 1915 1867 -1 
            1790 1838 1915 -1 
            1790 1665 1713 -1 
            1838 1790 1713 -1 
            1742 1694 1617 -1 
            1665 1742 1617 -1 
            1790 1742 1665 -1 
            1694 1742 1819 -1 
            1819 1742 1867 -1 
            1742 1790 1867 -1 
            1338 1415 1290 -1 
            1415 1367 1290 -1 
            1050 973 1098 -1 
            1021 1069 1146 -1 
            1069 1194 1146 -1 
            1194 1069 1117 -1 
            944 1069 1021 -1 
            1011 1059 1136 -1 
            1059 1184 1136 -1 
            1184 1059 1107 -1 
            1280 1405 1357 -1 
            914 789 837 -1 
            155 203 280 -1 
            328 203 251 -1 
            203 328 280 -1 
            203 126 251 -1 
            232 357 309 -1 
            280 357 232 -1 
            357 434 309 -1 
            434 357 482 -1 
            443 366 491 -1 
            568 443 491 -1 
            366 443 318 -1 
            443 395 318 -1 
            395 472 347 -1 
            78 30 1 -1 
            126 78 1 -1 
            78 155 30 -1 
            78 203 155 -1 
            203 78 126 -1 
            683 760 635 -1 
            760 712 635 -1 
            808 760 683 -1 
            712 760 837 -1 
            2358 2483 2435 -1 
            2358 2406 2483 -1 
            2310 2358 2435 -1 
            2406 2358 2281 -1 
            2281 2358 2233 -1 
            2358 2310 2233 -1 
            1916 1868 1791 -1 
            1868 1743 1791 -1 
            1743 1868 1820 -1 
            1897 1849 1772 -1 
            1820 1897 1772 -1 
            2137 2089 2012 -1 
            2089 1964 2012 -1 
            2089 2214 2166 -1 
            2214 2089 2137 -1 
            1676 1801 1753 -1 
            1724 1801 1676 -1 
            1801 1724 1849 -1 
            1753 1801 1878 -1 
            1878 1801 1926 -1 
            1801 1849 1926 -1 
            984 1061 936 -1 
            1032 984 907 -1 
            984 1109 1061 -1 
            1109 984 1032 -1 
            859 984 936 -1 
            984 859 907 -1 
            1003 926 1051 -1 
            926 1003 878 -1 
            1080 1205 1157 -1 
            1032 1080 1157 -1 
            2126 2174 2251 -1 
            1953 2001 2078 -1 
            2001 2126 2078 -1 
            2030 1953 2078 -1 
            2155 2030 2078 -1 
            2059 1934 1982 -1 
            1934 2059 2011 -1 
            2059 2184 2136 -1 
            2011 2059 2136 -1 
            1655 1530 1578 -1 
            1530 1453 1578 -1 
            1530 1405 1453 -1 
            1463 1338 1386 -1 
            1463 1415 1338 -1 
            1934 1857 1982 -1 
            781 906 858 -1 
            1281 1406 1358 -1 
            1108 983 1031 -1 
            983 906 1031 -1 
            983 935 858 -1 
            906 983 858 -1 
            1752 1829 1704 -1 
            1752 1675 1800 -1 
            1752 1800 1877 -1 
            1829 1752 1877 -1 
            1675 1752 1627 -1 
            1752 1704 1627 -1 
            849 897 974 -1 
            897 1022 974 -1 
            897 945 1022 -1 
            945 897 820 -1 
            897 772 820 -1 
            897 849 772 -1 
            1387 1339 1262 -1 
            1310 1387 1262 -1 
            1387 1310 1435 -1 
            1339 1387 1464 -1 
            1387 1512 1464 -1 
            1512 1387 1435 -1 
            1089 964 1012 -1 
            1041 964 1089 -1 
            964 1041 916 -1 
            964 887 1012 -1 
            839 964 916 -1 
            964 839 887 -1 
            1743 1695 1618 -1 
            1695 1647 1570 -1 
            1618 1695 1570 -1 
            1647 1695 1772 -1 
            1695 1820 1772 -1 
            1695 1743 1820 -1 
            1195 1243 1320 -1 
            1368 1243 1291 -1 
            1243 1368 1320 -1 
            1243 1166 1291 -1 
            1243 1118 1166 -1 
            1243 1195 1118 -1 
            1048 1125 1000 -1 
            1096 1048 971 -1 
            923 1048 1000 -1 
            1048 923 971 -1 
            1221 1346 1298 -1 
            1346 1221 1269 -1 
            1019 1096 971 -1 
            1019 942 1067 -1 
            942 1019 894 -1 
            1019 971 894 -1 
            1192 1144 1067 -1 
            1144 1019 1067 -1 
            1019 1144 1096 -1 
            1144 1221 1096 -1 
            1269 1144 1192 -1 
            1221 1144 1269 -1 
            1730 1682 1605 -1 
            1557 1682 1634 -1 
            1682 1557 1605 -1 
            1634 1682 1759 -1 
            1682 1807 1759 -1 
            1682 1730 1807 -1 
            1480 1557 1432 -1 
            1480 1307 1528 -1 
            1480 1432 1307 -1 
            1605 1480 1528 -1 
            1557 1480 1605 -1 
            1384 1432 1509 -1 
            1432 1384 1307 -1 
            1384 1259 1307 -1 
            1384 1336 1259 -1 
            1192 1240 1317 -1 
            1115 1240 1192 -1 
            1240 1115 1163 -1 
            1711 1586 1634 -1 
            1711 1663 1586 -1 
            1711 1634 1759 -1 
            1663 1711 1788 -1 
            1836 1711 1759 -1 
            1711 1836 1788 -1 
            1394 1442 1519 -1 
            1442 1394 1317 -1 
            1615 1538 1663 -1 
            1692 1615 1740 -1 
            1615 1663 1740 -1 
            1461 1538 1413 -1 
            1461 1384 1509 -1 
            1586 1461 1509 -1 
            1538 1461 1586 -1 
            1461 1413 1336 -1 
            1384 1461 1336 -1 
            2124 2076 1999 -1 
            1951 2076 2028 -1 
            1999 2076 1951 -1 
            2076 2153 2028 -1 
            2153 2076 2201 -1 
            2076 2124 2201 -1 
            626 549 674 -1 
            607 559 482 -1 
            530 607 482 -1 
            607 530 655 -1 
            559 607 684 -1 
            386 261 309 -1 
            261 184 309 -1 
            184 261 136 -1 
            50 21 98 -1 
            11 21 2 -1 
            21 50 2 -1 
            770 818 895 -1 
            722 799 674 -1 
            549 597 674 -1 
            597 722 674 -1 
            472 597 549 -1 
            732 607 655 -1 
            607 732 684 -1 
            876 924 1001 -1 
            799 924 876 -1 
            1049 972 1097 -1 
            1001 1049 1126 -1 
            924 1049 1001 -1 
            1049 924 972 -1 
            972 1020 1097 -1 
            1068 1020 943 -1 
            943 1020 895 -1 
            1020 972 895 -1 
            1664 1539 1587 -1 
            1818 1866 1943 -1 
            1866 1991 1943 -1 
            1991 1866 1914 -1 
            1866 1818 1741 -1 
            1674 1626 1549 -1 
            1760 1808 1885 -1 
            1837 1760 1885 -1 
            1808 1760 1683 -1 
            1760 1635 1683 -1 
            1548 1625 1500 -1 
            1625 1577 1500 -1 
            1625 1548 1673 -1 
            1750 1625 1673 -1 
            1241 1116 1164 -1 
            1116 1039 1164 -1 
            1212 1289 1164 -1 
            1289 1241 1164 -1 
            1289 1212 1337 -1 
            1289 1337 1414 -1 
            1433 1385 1308 -1 
            1222 1174 1097 -1 
            1049 1174 1126 -1 
            1174 1049 1097 -1 
            1145 1222 1097 -1 
            1020 1145 1097 -1 
            1145 1020 1068 -1 
            1539 1491 1414 -1 
            1443 1491 1568 -1 
            1529 1452 1577 -1 
            1654 1529 1577 -1 
            1404 1529 1481 -1 
            1452 1529 1404 -1 
            2000 2048 2125 -1 
            1875 1827 1750 -1 
            1683 1606 1731 -1 
            1606 1654 1731 -1 
            1606 1683 1558 -1 
            1606 1529 1654 -1 
            1481 1606 1558 -1 
            1529 1606 1481 -1 
            1798 1923 1875 -1 
            1923 2000 1875 -1 
            2048 1923 1971 -1 
            2000 1923 2048 -1 
            1894 1817 1942 -1 
            1817 1894 1769 -1 
            1894 2019 1971 -1 
            2019 1894 1942 -1 
            1846 1721 1769 -1 
            1846 1798 1721 -1 
            1894 1846 1769 -1 
            1846 1923 1798 -1 
            1923 1846 1971 -1 
            1846 1894 1971 -1 
            3855 3807 3730 -1 
            3807 3682 3730 -1 
            3807 3855 3932 -1 
            3836 3788 3711 -1 
            3788 3740 3663 -1 
            3711 3788 3663 -1 
            3788 3865 3740 -1 
            3865 3788 3913 -1 
            3788 3836 3913 -1 
            3442 3519 3394 -1 
            3490 3442 3365 -1 
            3548 3471 3596 -1 
            3471 3519 3596 -1 
            3423 3471 3548 -1 
            3519 3471 3394 -1 
            3394 3471 3346 -1 
            3471 3423 3346 -1 
            3644 3567 3692 -1 
            3519 3567 3644 -1 
            3567 3442 3490 -1 
            3442 3567 3519 -1 
            3347 3424 3299 -1 
            3395 3347 3270 -1 
            3424 3347 3472 -1 
            3347 3395 3472 -1 
            3270 3347 3222 -1 
            3347 3299 3222 -1 
            3078 3030 2953 -1 
            2953 3030 2905 -1 
            3030 2982 2905 -1 
            2982 3030 3107 -1 
            3030 3155 3107 -1 
            3155 3030 3078 -1 
            3126 3174 3251 -1 
            3174 3126 3049 -1 
            3049 3126 3001 -1 
            3126 3078 3001 -1 
            3155 3203 3280 -1 
            3203 3328 3280 -1 
            3328 3203 3251 -1 
            3203 3126 3251 -1 
            3203 3155 3078 -1 
            3126 3203 3078 -1 
            3798 3923 3875 -1 
            3923 4000 3875 -1 
            4048 3923 3971 -1 
            4000 3923 4048 -1 
            3548 3625 3500 -1 
            3673 3625 3548 -1 
            3625 3673 3750 -1 
            3846 3798 3721 -1 
            3769 3846 3721 -1 
            3846 3769 3894 -1 
            3846 3923 3798 -1 
            3971 3846 3894 -1 
            3923 3846 3971 -1 
            3731 3606 3654 -1 
            3779 3731 3654 -1 
            3808 3731 3856 -1 
            3731 3779 3856 -1 
            2194 2117 2242 -1 
            2146 2194 2271 -1 
            2194 2069 2117 -1 
            2069 2194 2146 -1 
            2319 2194 2242 -1 
            2194 2319 2271 -1 
            1963 2011 2088 -1 
            2040 1963 2088 -1 
            1915 1963 2040 -1 
            2011 1963 1886 -1 
            1963 1838 1886 -1 
            1838 1963 1915 -1 
            1992 1915 2040 -1 
            1992 2069 1944 -1 
            1992 1944 1867 -1 
            1915 1992 1867 -1 
            2069 1992 2117 -1 
            1992 2040 2117 -1 
            3165 3088 3213 -1 
            3290 3165 3213 -1 
            3040 3165 3117 -1 
            3088 3165 3040 -1 
            2915 2963 3040 -1 
            3088 2963 3011 -1 
            2963 3088 3040 -1 
            3011 2963 2886 -1 
            2963 2838 2886 -1 
            2963 2915 2838 -1 
            2992 2915 3040 -1 
            2992 3069 2944 -1 
            2992 3040 3117 -1 
            3069 2992 3117 -1 
            2867 2790 2915 -1 
            2819 2867 2944 -1 
            2867 2819 2742 -1 
            2790 2867 2742 -1 
            2867 2992 2944 -1 
            2992 2867 2915 -1 
            2521 2473 2396 -1 
            2396 2473 2348 -1 
            2473 2425 2348 -1 
            2425 2473 2550 -1 
            2550 2473 2598 -1 
            2473 2521 2598 -1 
            4183 4260 4135 -1 
            4165 4117 4040 -1 
            4088 4165 4040 -1 
            4165 4088 4213 -1 
            4117 4165 4242 -1 
            4165 4213 4290 -1 
            4242 4165 4290 -1 
            4261 4213 4136 -1 
            4261 4338 4213 -1 
            4386 4309 4434 -1 
            4338 4386 4463 -1 
            4261 4386 4338 -1 
            4386 4261 4309 -1 
            4511 4386 4434 -1 
            4386 4511 4463 -1 
            4357 4232 4280 -1 
            4232 4357 4309 -1 
            4357 4482 4434 -1 
            4309 4357 4434 -1 
            4232 4184 4107 -1 
            4184 4059 4107 -1 
            4059 4184 4136 -1 
            4184 4261 4136 -1 
            4184 4232 4309 -1 
            4261 4184 4309 -1 
            3636 3713 3588 -1 
            3790 3713 3838 -1 
            3867 3790 3915 -1 
            3819 3867 3944 -1 
            3742 3867 3819 -1 
            3790 3867 3742 -1 
            3867 3992 3944 -1 
            3992 3867 3915 -1 
            3069 3146 3021 -1 
            3146 3098 3021 -1 
            3146 3069 3194 -1 
            3098 3146 3223 -1 
            3146 3271 3223 -1 
            3271 3146 3194 -1 
            3588 3540 3463 -1 
            3540 3415 3463 -1 
            3434 3386 3309 -1 
            3386 3338 3261 -1 
            3309 3386 3261 -1 
            3338 3386 3463 -1 
            3386 3511 3463 -1 
            3386 3434 3511 -1 
            3319 3271 3194 -1 
            3271 3319 3396 -1 
            3444 3319 3367 -1 
            3319 3444 3396 -1 
            4212 4087 4135 -1 
            4260 4212 4135 -1 
            3914 3837 3962 -1 
            4039 3914 3962 -1 
            3789 3866 3741 -1 
            3837 3789 3712 -1 
            3914 3789 3837 -1 
            3789 3914 3866 -1 
            3789 3664 3712 -1 
            3664 3789 3741 -1 
            4001 3876 3924 -1 
            3646 3569 3694 -1 
            3396 3521 3473 -1 
            3444 3521 3396 -1 
            3569 3521 3444 -1 
            3521 3598 3473 -1 
            3598 3521 3646 -1 
            3521 3569 3646 -1 
            3607 3655 3732 -1 
            3780 3655 3703 -1 
            3655 3780 3732 -1 
            3655 3578 3703 -1 
            3578 3655 3530 -1 
            3655 3607 3530 -1 
            3607 3684 3559 -1 
            3684 3636 3559 -1 
            3684 3607 3732 -1 
            3684 3732 3809 -1 
            3405 3453 3530 -1 
            3453 3578 3530 -1 
            3453 3501 3578 -1 
            3501 3453 3376 -1 
            3453 3328 3376 -1 
            3453 3405 3328 -1 
            3482 3434 3357 -1 
            3405 3482 3357 -1 
            3482 3405 3530 -1 
            3434 3482 3559 -1 
            3482 3607 3559 -1 
            3607 3482 3530 -1 
            4108 4156 4233 -1 
            4281 4156 4204 -1 
            4156 4281 4233 -1 
            4156 4079 4204 -1 
            4079 4156 4031 -1 
            4156 4108 4031 -1 
            4185 4108 4233 -1 
            4137 4185 4262 -1 
            4310 4185 4233 -1 
            4185 4310 4262 -1 
            4060 4137 4012 -1 
            4108 4060 3983 -1 
            4060 4185 4137 -1 
            4185 4060 4108 -1 
            4060 3935 3983 -1 
            4060 4012 3935 -1 
            3916 3993 3868 -1 
            4070 3993 4118 -1 
            3820 3772 3695 -1 
            3647 3772 3724 -1 
            3772 3647 3695 -1 
            3772 3849 3724 -1 
            3849 3772 3897 -1 
            3772 3820 3897 -1 
            4387 4310 4435 -1 
            4262 4387 4339 -1 
            4310 4387 4262 -1 
            4589 4637 4714 -1 
            4762 4637 4685 -1 
            4637 4762 4714 -1 
            4070 4147 4022 -1 
            4214 4166 4089 -1 
            4051 4176 4128 -1 
            4176 4253 4128 -1 
            4253 4176 4301 -1 
            4176 4224 4301 -1 
            4608 4483 4531 -1 
            4483 4406 4531 -1 
            4483 4435 4358 -1 
            4406 4483 4358 -1 
            4512 4387 4435 -1 
            4512 4637 4589 -1 
            4608 4656 4733 -1 
            4656 4781 4733 -1 
            4781 4656 4704 -1 
            4704 4656 4579 -1 
            4579 4656 4531 -1 
            4656 4608 4531 -1 
            3406 3358 3281 -1 
            3358 3233 3281 -1 
            3233 3358 3310 -1 
            3358 3435 3310 -1 
            3358 3406 3483 -1 
            3435 3358 3483 -1 
            3800 3675 3723 -1 
            3752 3675 3800 -1 
            3675 3752 3627 -1 
            3723 3675 3598 -1 
            3550 3675 3627 -1 
            3675 3550 3598 -1 
            4858 4935 4810 -1 
            5262 5137 5185 -1 
            5137 5262 5214 -1 
            5184 5232 5309 -1 
            5232 5280 5357 -1 
            5309 5232 5357 -1 
            5232 5155 5280 -1 
            5578 5453 5501 -1 
            5713 5636 5761 -1 
            5636 5713 5588 -1 
            5713 5761 5838 -1 
            5790 5713 5838 -1 
            5617 5742 5694 -1 
            5415 5492 5367 -1 
            5492 5444 5367 -1 
            4742 4819 4694 -1 
            4819 4771 4694 -1 
            4819 4742 4867 -1 
            4848 4973 4925 -1 
            4646 4723 4598 -1 
            4771 4723 4646 -1 
            4848 4723 4771 -1 
            5224 5301 5176 -1 
            5224 5147 5272 -1 
            5349 5224 5272 -1 
            5301 5224 5349 -1 
            5147 5224 5099 -1 
            5224 5176 5099 -1 
            4782 4830 4907 -1 
            4830 4955 4907 -1 
            4705 4830 4782 -1 
            4753 4830 4705 -1 
            5128 5051 5176 -1 
            5128 5205 5080 -1 
            5003 5128 5080 -1 
            5051 5128 5003 -1 
            5128 5253 5205 -1 
            5128 5176 5253 -1 
            5300 5348 5425 -1 
            5348 5473 5425 -1 
            5473 5348 5396 -1 
            5175 5050 5098 -1 
            5550 5473 5598 -1 
            5502 5550 5627 -1 
            5473 5550 5425 -1 
            5550 5502 5425 -1 
            5675 5550 5598 -1 
            5550 5675 5627 -1 
            5406 5454 5531 -1 
            5454 5579 5531 -1 
            5579 5454 5502 -1 
            5252 5175 5300 -1 
            5195 5147 5070 -1 
            5147 5195 5272 -1 
            5195 5320 5272 -1 
            5195 5243 5320 -1 
            5243 5368 5320 -1 
            5320 5368 5445 -1 
            5493 5368 5416 -1 
            5368 5493 5445 -1 
            5108 5233 5185 -1 
            5368 5291 5416 -1 
            5291 5368 5243 -1 
            4618 4493 4541 -1 
            4493 4416 4541 -1 
            4522 4599 4474 -1 
            4522 4647 4599 -1 
            4522 4474 4397 -1 
            4445 4522 4397 -1 
            4676 4724 4801 -1 
            4724 4849 4801 -1 
            4849 4724 4772 -1 
            4724 4647 4772 -1 
            4647 4724 4599 -1 
            4724 4676 4599 -1 
            4580 4705 4657 -1 
            4664 4741 4616 -1 
            4664 4712 4789 -1 
            4741 4664 4789 -1 
            4693 4818 4770 -1 
            4741 4818 4693 -1 
            4818 4741 4866 -1 
            4818 4895 4770 -1 
            4818 4866 4943 -1 
            4895 4818 4943 -1 
            4587 4462 4510 -1 
            4635 4587 4510 -1 
            4587 4635 4712 -1 
            4664 4587 4712 -1 
            5155 5030 5078 -1 
            5030 4953 5078 -1 
            4953 5030 4905 -1 
            5030 4982 4905 -1 
            5203 5251 5328 -1 
            5251 5299 5376 -1 
            5328 5251 5376 -1 
            5251 5174 5299 -1 
            5126 5203 5078 -1 
            5174 5126 5049 -1 
            5251 5126 5174 -1 
            5126 5251 5203 -1 
            5126 5001 5049 -1 
            5001 5126 5078 -1 
            4270 4318 4395 -1 
            4395 4318 4443 -1 
            4318 4366 4443 -1 
            4366 4318 4241 -1 
            5461 5538 5413 -1 
            5461 5586 5538 -1 
            5663 5740 5615 -1 
            5663 5586 5711 -1 
            5663 5711 5788 -1 
            5740 5663 5788 -1 
            5538 5663 5615 -1 
            5586 5663 5538 -1 
            5817 5740 5865 -1 
            5817 5894 5769 -1 
            5692 5817 5769 -1 
            5740 5817 5692 -1 
            5894 5817 5942 -1 
            5817 5865 5942 -1 
            5951 5903 5826 -1 
            5903 5778 5826 -1 
            5701 5653 5576 -1 
            5653 5701 5778 -1 
            5701 5749 5826 -1 
            5778 5701 5826 -1 
            5682 5759 5634 -1 
            5682 5730 5807 -1 
            5759 5682 5807 -1 
            5932 5884 5807 -1 
            5884 5759 5807 -1 
            5999 6076 5951 -1 
            6076 5999 6124 -1 
            6009 5884 5932 -1 
            5884 6009 5961 -1 
            5913 5865 5788 -1 
            5913 5990 5865 -1 
            5913 5961 6038 -1 
            5990 5913 6038 -1 
            4952 4875 5000 -1 
            4875 4923 5000 -1 
            4875 4952 4827 -1 
            4923 4875 4798 -1 
            4750 4875 4827 -1 
            4875 4750 4798 -1 
            5192 5115 5240 -1 
            5144 5192 5269 -1 
            5269 5192 5317 -1 
            5192 5240 5317 -1 
            5067 5144 5019 -1 
            4942 5067 5019 -1 
            5067 5192 5144 -1 
            5192 5067 5115 -1 
            4913 4836 4961 -1 
            4913 4788 4836 -1 
            5086 5038 4961 -1 
            5038 4913 4961 -1 
            4846 4798 4721 -1 
            4846 4923 4798 -1 
            5096 4971 5019 -1 
            4846 4971 4923 -1 
            5076 4951 4999 -1 
            4951 5076 5028 -1 
            5297 5249 5172 -1 
            5201 5249 5326 -1 
            5374 5249 5297 -1 
            5249 5374 5326 -1 
            5076 5153 5028 -1 
            5153 5076 5201 -1 
            5153 5278 5230 -1 
            5278 5153 5201 -1 
            5335 5383 5460 -1 
            5335 5287 5210 -1 
            5287 5364 5239 -1 
            4836 4884 4961 -1 
            4884 5009 4961 -1 
            4884 4759 4807 -1 
            4759 4884 4836 -1 
            4404 4327 4452 -1 
            4327 4404 4279 -1 
            4404 4356 4279 -1 
            4711 4788 4663 -1 
            4759 4711 4634 -1 
            4788 4711 4836 -1 
            4711 4759 4836 -1 
            4711 4586 4634 -1 
            4586 4711 4663 -1 
            4471 4423 4346 -1 
            4423 4298 4346 -1 
            4298 4423 4375 -1 
            4615 4663 4740 -1 
            4692 4615 4740 -1 
            4663 4615 4538 -1 
            6350 6398 6475 -1 
            6523 6398 6446 -1 
            6475 6398 6523 -1 
            6398 6321 6446 -1 
            6321 6398 6273 -1 
            6398 6350 6273 -1 
            6542 6619 6494 -1 
            6542 6667 6619 -1 
            6417 6542 6494 -1 
            6465 6542 6417 -1 
            6753 6705 6628 -1 
            6724 6599 6647 -1 
            6676 6599 6724 -1 
            6522 6445 6570 -1 
            6522 6397 6445 -1 
            6522 6570 6647 -1 
            6599 6522 6647 -1 
            5917 5840 5965 -1 
            5917 5792 5840 -1 
            5917 6042 5994 -1 
            5917 5965 6042 -1 
            6013 5936 6061 -1 
            5888 5936 6013 -1 
            5859 5936 5811 -1 
            5936 5888 5811 -1 
            5687 5562 5610 -1 
            5687 5639 5562 -1 
            5687 5610 5735 -1 
            5639 5687 5764 -1 
            5812 5687 5735 -1 
            5687 5812 5764 -1 
            5589 5512 5637 -1 
            5589 5666 5541 -1 
            5637 5560 5685 -1 
            5512 5560 5637 -1 
            5435 5560 5512 -1 
            5983 5935 5858 -1 
            5935 5983 6060 -1 
            5983 6108 6060 -1 
            6108 5983 6031 -1 
            5714 5791 5666 -1 
            5714 5839 5791 -1 
            5589 5714 5666 -1 
            5714 5589 5637 -1 
            5071 5148 5023 -1 
            5119 5071 4994 -1 
            5148 5071 5196 -1 
            5071 5119 5196 -1 
            4946 5071 5023 -1 
            4994 5071 4946 -1 
            5119 5167 5244 -1 
            5167 5090 5215 -1 
            5090 5167 5042 -1 
            5167 5119 5042 -1 
            5868 5945 5820 -1 
            5916 5868 5791 -1 
            5868 5916 5993 -1 
            5945 5868 5993 -1 
            5868 5820 5743 -1 
            5791 5868 5743 -1 
            6291 6339 6416 -1 
            6339 6464 6416 -1 
            6339 6387 6464 -1 
            6339 6262 6387 -1 
            6214 6291 6166 -1 
            6262 6214 6137 -1 
            6339 6214 6262 -1 
            6214 6339 6291 -1 
            6214 6166 6089 -1 
            6137 6214 6089 -1 
            6021 6146 6098 -1 
            6146 6223 6098 -1 
            6223 6146 6271 -1 
            6146 6194 6271 -1 
            6011 5963 5886 -1 
            5886 5963 5838 -1 
            5963 5915 5838 -1 
            5915 5963 6040 -1 
            5963 6088 6040 -1 
            5963 6011 6088 -1 
            6271 6396 6348 -1 
            6319 6396 6271 -1 
            6396 6473 6348 -1 
            6473 6396 6521 -1 
            6646 6694 6771 -1 
            6694 6646 6569 -1 
            6723 6675 6598 -1 
            6646 6723 6598 -1 
            6723 6646 6771 -1 
            6675 6723 6800 -1 
            6848 6723 6771 -1 
            6723 6848 6800 -1 
            6050 6127 6002 -1 
            6050 5973 6098 -1 
            6175 6050 6098 -1 
            6127 6050 6175 -1 
            6050 5925 5973 -1 
            5925 6050 6002 -1 
            5733 5810 5685 -1 
            5810 5733 5858 -1 
            5733 5781 5858 -1 
            5829 5752 5877 -1 
            5954 5829 5877 -1 
            5829 5781 5704 -1 
            5752 5829 5704 -1 
            5848 5896 5973 -1 
            5896 6021 5973 -1 
            6021 5896 5944 -1 
            7512 7560 7637 -1 
            7560 7685 7637 -1 
            7685 7560 7608 -1 
            7608 7560 7483 -1 
            7483 7560 7435 -1 
            7560 7512 7435 -1 
            7685 7733 7810 -1 
            7733 7858 7810 -1 
            7858 7733 7781 -1 
            7781 7733 7656 -1 
            7733 7608 7656 -1 
            7733 7685 7608 -1 
            7291 7416 7368 -1 
            7368 7416 7493 -1 
            7118 6993 7041 -1 
            7414 7462 7539 -1 
            7462 7510 7587 -1 
            7539 7462 7587 -1 
            7462 7414 7337 -1 
            7443 7366 7491 -1 
            7366 7414 7491 -1 
            7366 7443 7318 -1 
            7414 7366 7289 -1 
            7193 7241 7318 -1 
            7366 7241 7289 -1 
            7241 7366 7318 -1 
            6270 6222 6145 -1 
            6193 6270 6145 -1 
            6222 6270 6347 -1 
            6270 6395 6347 -1 
            6635 6558 6683 -1 
            6654 6577 6702 -1 
            6577 6654 6529 -1 
            6481 6558 6433 -1 
            6481 6404 6529 -1 
            6356 6481 6433 -1 
            6404 6481 6356 -1 
            6539 6462 6587 -1 
            6933 7058 7010 -1 
            7010 7058 7135 -1 
            6760 6635 6683 -1 
            6923 6798 6846 -1 
            6703 6655 6578 -1 
            6530 6453 6578 -1 
            6453 6530 6405 -1 
            6655 6530 6578 -1 
            6530 6655 6607 -1 
            6924 7049 7001 -1 
            7001 7049 7126 -1 
            7174 7049 7097 -1 
            7126 7049 7174 -1 
            6953 6876 7001 -1 
            6876 6924 7001 -1 
            6876 6953 6828 -1 
            6924 6876 6799 -1 
            6751 6876 6828 -1 
            6876 6751 6799 -1 
            6626 6703 6578 -1 
            6626 6751 6703 -1 
            6616 6693 6568 -1 
            6664 6616 6539 -1 
            6616 6491 6539 -1 
            6491 6616 6568 -1 
            7288 7336 7413 -1 
            7365 7288 7413 -1 
            7288 7211 7336 -1 
            7211 7288 7163 -1 
            7211 7163 7086 -1 
            7480 7557 7432 -1 
            7557 7480 7605 -1 
            7682 7807 7759 -1 
            7634 7682 7759 -1 
            7682 7557 7605 -1 
            7557 7682 7634 -1 
            7663 7586 7711 -1 
            7586 7634 7711 -1 
            8086 8134 8211 -1 
            8028 8105 7980 -1 
            8153 8105 8028 -1 
            8105 8230 8182 -1 
            8105 8153 8230 -1 
            8067 8192 8144 -1 
            8067 8115 8192 -1 
            7625 7702 7577 -1 
            7625 7548 7673 -1 
            7500 7625 7577 -1 
            7548 7625 7500 -1 
            7596 7548 7471 -1 
            7548 7596 7673 -1 
            6944 6867 6992 -1 
            6896 6944 7021 -1 
            7021 6944 7069 -1 
            6944 6992 7069 -1 
            6819 6896 6771 -1 
            6867 6819 6742 -1 
            6944 6819 6867 -1 
            6819 6944 6896 -1 
            6819 6694 6742 -1 
            6694 6819 6771 -1 
            7251 7299 7376 -1 
            7424 7299 7347 -1 
            7299 7424 7376 -1 
            7299 7222 7347 -1 
            7222 7299 7174 -1 
            7299 7251 7174 -1 
            7434 7357 7482 -1 
            7357 7405 7482 -1 
            7357 7434 7309 -1 
            7405 7357 7280 -1 
            7357 7232 7280 -1 
            7232 7357 7309 -1 
            7578 7530 7453 -1 
            7405 7530 7482 -1 
            7530 7405 7453 -1 
            7530 7607 7482 -1 
            7530 7578 7655 -1 
            7607 7530 7655 -1 
            7011 6934 7059 -1 
            6886 6934 7011 -1 
            6934 6886 6809 -1 
            6501 6453 6376 -1 
            6453 6501 6578 -1 
            6501 6626 6578 -1 
            6520 6472 6395 -1 
            6357 6280 6405 -1 
            6232 6280 6357 -1 
            6203 6280 6155 -1 
            6280 6232 6155 -1 
            7607 7559 7482 -1 
            7434 7559 7511 -1 
            7559 7434 7482 -1 
            7559 7636 7511 -1 
            7559 7684 7636 -1 
            7559 7607 7684 -1 
            7597 7674 7549 -1 
            7645 7597 7520 -1 
            7472 7597 7549 -1 
            7597 7472 7520 -1 
            7770 7722 7645 -1 
            7722 7597 7645 -1 
            7597 7722 7674 -1 
            7674 7751 7626 -1 
            7828 7751 7876 -1 
            7991 7866 7914 -1 
            7943 7866 7991 -1 
            7914 7866 7789 -1 
            7866 7741 7789 -1 
            7383 7431 7508 -1 
            7431 7479 7556 -1 
            7508 7431 7556 -1 
            7479 7431 7354 -1 
            7354 7431 7306 -1 
            7431 7383 7306 -1 
            7662 7537 7585 -1 
            7614 7537 7662 -1 
            7537 7614 7489 -1 
            7585 7537 7460 -1 
            7412 7537 7489 -1 
            7537 7412 7460 -1 
            6644 6721 6596 -1 
            6721 6673 6596 -1 
            6769 6721 6644 -1 
            6721 6798 6673 -1 
            6721 6769 6846 -1 
            6798 6721 6846 -1 
            6836 6788 6711 -1 
            6663 6788 6740 -1 
            6788 6663 6711 -1 
            6836 6884 6961 -1 
            7009 6884 6932 -1 
            6884 7009 6961 -1 
            6932 6884 6807 -1 
            6807 6884 6759 -1 
            6884 6836 6759 -1 
            6692 6769 6644 -1 
            6692 6644 6567 -1 
            6769 6894 6846 -1 
            6913 6836 6961 -1 
            6913 6788 6836 -1 
            5700 5777 5652 -1 
            5700 5623 5748 -1 
            5575 5700 5652 -1 
            5623 5700 5575 -1 
            5873 5825 5748 -1 
            5825 5700 5748 -1 
            5700 5825 5777 -1 
            5950 5825 5873 -1 
            5777 5729 5652 -1 
            5729 5777 5854 -1 
            5604 5556 5479 -1 
            5527 5604 5479 -1 
            5604 5527 5652 -1 
            5556 5604 5681 -1 
            5729 5604 5652 -1 
            5604 5729 5681 -1 
            5565 5690 5642 -1 
            5613 5690 5565 -1 
            5690 5613 5738 -1 
            5642 5690 5767 -1 
            5690 5815 5767 -1 
            5815 5690 5738 -1 
            5373 5296 5421 -1 
            5373 5248 5296 -1 
            5498 5373 5421 -1 
            5373 5498 5450 -1 
            7612 7660 7737 -1 
            7660 7612 7535 -1 
            7487 7612 7564 -1 
            7535 7612 7487 -1 
            7910 7958 8035 -1 
            7958 8083 8035 -1 
            8083 7958 8006 -1 
            7958 7881 8006 -1 
            7958 7910 7833 -1 
            7881 7958 7833 -1 
            8441 8364 8489 -1 
            8470 8441 8489 -1 
            8239 8287 8364 -1 
            8287 8239 8162 -1 
            8162 8239 8114 -1 
            8239 8191 8114 -1 
            6625 6548 6673 -1 
            6577 6625 6702 -1 
            6500 6625 6577 -1 
            6548 6625 6500 -1 
            6509 6557 6634 -1 
            6682 6557 6605 -1 
            6557 6682 6634 -1 
            6557 6480 6605 -1 
            6557 6432 6480 -1 
            6432 6557 6509 -1 
            6432 6355 6480 -1 
            6355 6432 6307 -1 
            6086 6134 6211 -1 
            6163 6086 6211 -1 
            6086 6163 6038 -1 
            6086 6009 6134 -1 
            5961 6086 6038 -1 
            6009 6086 5961 -1 
            6097 6174 6049 -1 
            6222 6097 6145 -1 
            6174 6097 6222 -1 
            6116 5991 6039 -1 
            6068 5991 6116 -1 
            5991 6068 5943 -1 
            5991 5914 6039 -1 
            6068 6020 5943 -1 
            6020 6068 6145 -1 
            6097 6020 6145 -1 
            5847 5722 5770 -1 
            5597 5549 5472 -1 
            5520 5597 5472 -1 
            5732 5655 5780 -1 
            5828 5876 5953 -1 
            5876 6001 5953 -1 
            6001 5876 5924 -1 
            5876 5828 5751 -1 
            7798 7750 7673 -1 
            7750 7625 7673 -1 
            7625 7750 7702 -1 
            7827 7904 7779 -1 
            7904 7827 7952 -1 
            7702 7827 7779 -1 
            7750 7827 7702 -1 
            7488 7536 7613 -1 
            7661 7536 7584 -1 
            7536 7661 7613 -1 
            7536 7459 7584 -1 
            7536 7411 7459 -1 
            7536 7488 7411 -1 
            7690 7565 7613 -1 
            7565 7488 7613 -1 
            7488 7565 7440 -1 
            7565 7517 7440 -1 
            8209 8132 8257 -1 
            8132 8180 8257 -1 
            8132 8209 8084 -1 
            8132 8055 8180 -1 
            8007 8132 8084 -1 
            8055 8132 8007 -1 
            8401 8276 8324 -1 
            8276 8401 8353 -1 
            8199 8276 8151 -1 
            8276 8199 8324 -1 
            8382 8305 8430 -1 
            8459 8382 8430 -1 
            8382 8459 8334 -1 
            8257 8382 8334 -1 
            8305 8382 8257 -1 
            4998 5075 4950 -1 
            5075 5027 4950 -1 
            5075 4998 5123 -1 
            4902 4979 4854 -1 
            5027 4979 4902 -1 
            5104 4979 5027 -1 
            3431 3508 3383 -1 
            3479 3431 3354 -1 
            3306 3431 3383 -1 
            3431 3306 3354 -1 
            3556 3479 3604 -1 
            3508 3556 3633 -1 
            3431 3556 3508 -1 
            3556 3431 3479 -1 
            3633 3556 3681 -1 
            3556 3604 3681 -1 
            3623 3575 3450 -1 
            3700 3575 3623 -1 
            3575 3700 3652 -1 
            4114 4037 4162 -1 
            4066 4114 4191 -1 
            4114 4066 3989 -1 
            4037 4114 3989 -1 
            4114 4239 4191 -1 
            4114 4162 4239 -1 
            5824 5747 5872 -1 
            5776 5824 5901 -1 
            5824 5776 5699 -1 
            5747 5824 5699 -1 
            5901 5824 5949 -1 
            5824 5872 5949 -1 
            5393 5441 5518 -1 
            5441 5566 5518 -1 
            5566 5441 5489 -1 
            5441 5364 5489 -1 
            5345 5470 5422 -1 
            5345 5393 5470 -1 
            5297 5345 5422 -1 
            5393 5345 5268 -1 
            5220 5345 5297 -1 
            5345 5220 5268 -1 
            5835 5787 5710 -1 
            5662 5787 5739 -1 
            5787 5662 5710 -1 
            5787 5835 5912 -1 
            5662 5585 5710 -1 
            5508 5585 5460 -1 
            5979 5931 5854 -1 
            5556 5431 5479 -1 
            5508 5431 5556 -1 
            5431 5508 5383 -1 
            5306 5431 5383 -1 
            6258 6306 6383 -1 
            6306 6431 6383 -1 
            6306 6354 6431 -1 
            5960 6085 6037 -1 
            6085 6162 6037 -1 
            6162 6085 6210 -1 
            6085 6133 6210 -1 
            6056 5979 6104 -1 
            6056 5931 5979 -1 
            6152 6229 6104 -1 
            6277 6229 6152 -1 
            6229 6277 6354 -1 
            6306 6229 6354 -1 
            5797 5845 5922 -1 
            5893 5845 5768 -1 
            5845 5720 5768 -1 
            5720 5845 5797 -1 
            6172 6124 6047 -1 
            6095 6172 6047 -1 
            6172 6095 6220 -1 
            6124 6172 6249 -1 
            6297 6172 6220 -1 
            6172 6297 6249 -1 
            6018 5970 5893 -1 
            5970 5845 5893 -1 
            5845 5970 5922 -1 
            5922 5970 6047 -1 
            5970 6095 6047 -1 
            5970 6018 6095 -1 
            5989 6066 5941 -1 
            6066 6018 5941 -1 
            6066 6191 6143 -1 
            6018 6066 6143 -1 
            5547 5595 5672 -1 
            5720 5595 5643 -1 
            5595 5720 5672 -1 
            5643 5595 5518 -1 
            5595 5470 5518 -1 
            5595 5547 5470 -1 
            5624 5547 5672 -1 
            5624 5701 5576 -1 
            5624 5672 5749 -1 
            5701 5624 5749 -1 
            5547 5499 5422 -1 
            5499 5374 5422 -1 
            5374 5499 5451 -1 
            5451 5499 5576 -1 
            5499 5624 5576 -1 
            5624 5499 5547 -1 
            5528 5480 5403 -1 
            5480 5355 5403 -1 
            5355 5480 5432 -1 
            4519 4471 4394 -1 
            4442 4519 4394 -1 
            4519 4596 4471 -1 
            4490 4442 4365 -1 
            4413 4490 4365 -1 
            4490 4413 4538 -1 
            4615 4490 4538 -1 
            4653 4778 4730 -1 
            4778 4855 4730 -1 
            4903 4778 4826 -1 
            4855 4778 4903 -1 
            5114 4989 5037 -1 
            5085 5037 4960 -1 
            5085 5133 5210 -1 
            5306 5258 5181 -1 
            5133 5258 5210 -1 
            5258 5133 5181 -1 
            5258 5335 5210 -1 
            5258 5306 5383 -1 
            5335 5258 5383 -1 
            4893 4816 4941 -1 
            4893 4970 4845 -1 
            4720 4768 4845 -1 
            4768 4893 4845 -1 
            4893 4768 4816 -1 
            4816 4768 4691 -1 
            4691 4768 4643 -1 
            4768 4720 4643 -1 
            4787 4864 4739 -1 
            4864 4816 4739 -1 
            4864 4989 4941 -1 
            4816 4864 4941 -1 
            4701 4576 4624 -1 
            4576 4701 4653 -1 
            4778 4701 4826 -1 
            4701 4778 4653 -1 
            4874 4797 4922 -1 
            4874 4951 4826 -1 
            4874 4922 4999 -1 
            4951 4874 4999 -1 
            5095 5220 5172 -1 
            8306 8229 8354 -1 
            8258 8306 8383 -1 
            8306 8258 8181 -1 
            8229 8306 8181 -1 
            8306 8431 8383 -1 
            8431 8306 8354 -1 
            8373 8325 8248 -1 
            8325 8200 8248 -1 
            8200 8325 8277 -1 
            8277 8325 8402 -1 
            8325 8450 8402 -1 
            8325 8373 8450 -1 
            8094 8142 8219 -1 
            8142 8267 8219 -1 
            8267 8142 8190 -1 
            8046 8171 8123 -1 
            8046 8094 8171 -1 
            8094 8046 7969 -1 
            6718 6670 6593 -1 
            6641 6718 6593 -1 
            7016 7093 6968 -1 
            7016 6939 7064 -1 
            6891 7016 6968 -1 
            6939 7016 6891 -1 
            7016 7141 7093 -1 
            7141 7016 7064 -1 
            7093 7141 7218 -1 
            7141 7266 7218 -1 
            7170 7045 7093 -1 
            7093 7045 6968 -1 
            7045 6920 6968 -1 
            6920 7045 6997 -1 
            7026 7074 7151 -1 
            7074 7199 7151 -1 
            6699 6776 6651 -1 
            6824 6776 6699 -1 
            6901 6776 6824 -1 
            6901 7026 6978 -1 
            7208 7256 7333 -1 
            7362 7314 7237 -1 
            7362 7439 7314 -1 
            7439 7362 7487 -1 
            7362 7410 7487 -1 
            7430 7382 7305 -1 
            7382 7430 7507 -1 
            7430 7555 7507 -1 
            7430 7478 7555 -1 
            7382 7334 7257 -1 
            7209 7334 7286 -1 
            7334 7209 7257 -1 
            7334 7411 7286 -1 
            7411 7334 7459 -1 
            7334 7382 7459 -1 
            7401 7353 7276 -1 
            7228 7353 7305 -1 
            7353 7228 7276 -1 
            7353 7430 7305 -1 
            7353 7401 7478 -1 
            7430 7353 7478 -1 
            7238 7161 7286 -1 
            7161 7209 7286 -1 
            7161 7238 7113 -1 
            7209 7161 7084 -1 
            7161 7113 7036 -1 
            7084 7161 7036 -1 
            7516 7593 7468 -1 
            7516 7439 7564 -1 
            7516 7468 7391 -1 
            7439 7516 7391 -1 
            7718 7641 7766 -1 
            7593 7641 7718 -1 
            7641 7516 7564 -1 
            7516 7641 7593 -1 
            7593 7545 7468 -1 
            7545 7420 7468 -1 
            7420 7545 7497 -1 
            7497 7545 7622 -1 
            7622 7545 7670 -1 
            7545 7593 7670 -1 
            7679 7602 7727 -1 
            7804 7679 7727 -1 
            7756 7679 7804 -1 
            7506 7583 7458 -1 
            7506 7458 7381 -1 
            7429 7506 7381 -1 
            7179 7131 7054 -1 
            7131 7006 7054 -1 
            7006 7131 7083 -1 
            7131 7208 7083 -1 
            7256 7131 7179 -1 
            7208 7131 7256 -1 
            7400 7477 7352 -1 
            7400 7525 7477 -1 
            7400 7352 7275 -1 
            7323 7400 7275 -1 
            6833 6881 6958 -1 
            6881 6833 6756 -1 
            6833 6708 6756 -1 
            6708 6833 6785 -1 
            6862 6910 6987 -1 
            6987 6910 7035 -1 
            6910 6958 7035 -1 
            6910 6833 6958 -1 
            6910 6862 6785 -1 
            6833 6910 6785 -1 
            7719 7671 7594 -1 
            7671 7546 7594 -1 
            7546 7671 7623 -1 
            7623 7671 7748 -1 
            7748 7671 7796 -1 
            7671 7719 7796 -1 
            7642 7690 7767 -1 
            7719 7642 7767 -1 
            7642 7719 7594 -1 
            7642 7565 7690 -1 
            7517 7642 7594 -1 
            7565 7642 7517 -1 
            4304 4352 4429 -1 
            4352 4477 4429 -1 
            4275 4352 4227 -1 
            4352 4304 4227 -1 
            4996 4948 4871 -1 
            4948 4823 4871 -1 
            4823 4948 4900 -1 
            4900 4948 5025 -1 
            4948 5073 5025 -1 
            4948 4996 5073 -1 
            5015 4890 4938 -1 
            4813 4890 4765 -1 
            4890 4813 4938 -1 
            4958 5035 4910 -1 
            5035 4987 4910 -1 
            5035 4958 5083 -1 
            4987 5035 5112 -1 
            5035 5083 5160 -1 
            5112 5035 5160 -1 
            3804 3852 3929 -1 
            3881 3804 3929 -1 
            3804 3881 3756 -1 
            3852 3804 3727 -1 
            3804 3679 3727 -1 
            3679 3804 3756 -1 
            4025 4073 4150 -1 
            4073 4198 4150 -1 
            4198 4073 4121 -1 
            4121 4073 3996 -1 
            4073 3948 3996 -1 
            4073 4025 3948 -1 
            4121 4169 4246 -1 
            4169 4217 4294 -1 
            4246 4169 4294 -1 
            4169 4092 4217 -1 
            4169 4044 4092 -1 
            4169 4121 4044 -1 
            3276 3199 3324 -1 
            3151 3199 3276 -1 
            3122 3199 3074 -1 
            3199 3151 3074 -1 
            3440 3363 3488 -1 
            3315 3363 3440 -1 
            5630 5505 5553 -1 
            5505 5630 5582 -1 
            5659 5534 5582 -1 
            5736 5659 5784 -1 
            5438 5486 5563 -1 
            5534 5486 5409 -1 
            5486 5361 5409 -1 
            5361 5486 5438 -1 
            6304 6352 6429 -1 
            6381 6304 6429 -1 
            6304 6381 6256 -1 
            6352 6304 6227 -1 
            6179 6304 6256 -1 
            6304 6179 6227 -1 
            5698 5775 5650 -1 
            5698 5621 5746 -1 
            5823 5698 5746 -1 
            5775 5698 5823 -1 
            5698 5573 5621 -1 
            5573 5698 5650 -1 
            5833 5756 5881 -1 
            5833 5910 5785 -1 
            5708 5833 5785 -1 
            5756 5833 5708 -1 
            5910 5833 5958 -1 
            5833 5881 5958 -1 
            5948 6025 5900 -1 
            5948 5900 5823 -1 
            6025 5948 6073 -1 
            5948 5996 6073 -1 
            5092 5169 5044 -1 
            5169 5092 5217 -1 
            5236 5111 5159 -1 
            5034 4957 5082 -1 
            5034 5111 4986 -1 
            4957 5034 4909 -1 
            5034 4986 4909 -1 
            5159 5034 5082 -1 
            5111 5034 5159 -1 
            4880 4957 4832 -1 
            4880 4832 4755 -1 
            4927 5004 4879 -1 
            4975 4927 4850 -1 
            5052 4927 4975 -1 
            5004 4927 5052 -1 
            4927 4802 4850 -1 
            4927 4879 4802 -1 
            5359 5436 5311 -1 
            5436 5388 5311 -1 
            5436 5359 5484 -1 
            5388 5436 5513 -1 
            5513 5436 5561 -1 
            5436 5484 5561 -1 
            5417 5340 5465 -1 
            5340 5388 5465 -1 
            5263 5340 5215 -1 
            5340 5263 5388 -1 
            5331 5408 5283 -1 
            5456 5408 5331 -1 
            5408 5533 5485 -1 
            5408 5456 5533 -1 
            5235 5110 5158 -1 
            5235 5187 5110 -1 
            5235 5158 5283 -1 
            5187 5235 5312 -1 
            5139 5264 5216 -1 
            5139 5187 5264 -1 
            5139 5014 5062 -1 
            5187 5139 5062 -1 
            4870 4918 4995 -1 
            4966 4918 4841 -1 
            4841 4918 4793 -1 
            4918 4870 4793 -1 
            5005 5130 5082 -1 
            5005 5053 5130 -1 
            4957 5005 5082 -1 
            5053 5005 4928 -1 
            4880 5005 4957 -1 
            5005 4880 4928 -1 
            4976 5053 4928 -1 
            5024 4976 4899 -1 
            5101 4976 5024 -1 
            5053 4976 5101 -1 
            4976 4851 4899 -1 
            4976 4928 4851 -1 
            4389 4466 4341 -1 
            4389 4312 4437 -1 
            4389 4341 4264 -1 
            4312 4389 4264 -1 
            4562 4514 4437 -1 
            4514 4389 4437 -1 
            4389 4514 4466 -1 
            4514 4562 4639 -1 
            4466 4418 4341 -1 
            4293 4418 4370 -1 
            4418 4293 4341 -1 
            4370 4418 4495 -1 
            4591 4514 4639 -1 
            4514 4591 4466 -1 
            4591 4639 4716 -1 
            4668 4591 4716 -1 
            4562 4687 4639 -1 
            4610 4687 4562 -1 
            4764 4687 4812 -1 
            4687 4764 4639 -1 
            4476 4553 4428 -1 
            4553 4505 4428 -1 
            4553 4476 4601 -1 
            4505 4553 4630 -1 
            4553 4601 4678 -1 
            4630 4553 4678 -1 
            4409 4332 4457 -1 
            4332 4380 4457 -1 
            4332 4409 4284 -1 
            4380 4332 4255 -1 
            4332 4207 4255 -1 
            4207 4332 4284 -1 
            4138 4186 4263 -1 
            4311 4186 4234 -1 
            4186 4311 4263 -1 
            4186 4109 4234 -1 
            4186 4061 4109 -1 
            4186 4138 4061 -1 
            3946 3994 4071 -1 
            4023 3946 4071 -1 
            3946 4023 3898 -1 
            3994 3946 3869 -1 
            3821 3946 3898 -1 
            3946 3821 3869 -1 
            4667 4744 4619 -1 
            4667 4590 4715 -1 
            4667 4715 4792 -1 
            4744 4667 4792 -1 
            4667 4619 4542 -1 
            4590 4667 4542 -1 
            4754 4677 4802 -1 
            4706 4754 4831 -1 
            4754 4879 4831 -1 
            4879 4754 4802 -1 
            4581 4629 4706 -1 
            4629 4754 4706 -1 
            4754 4629 4677 -1 
            4677 4629 4552 -1 
            4552 4629 4504 -1 
            4629 4581 4504 -1 
            4119 4167 4244 -1 
            4244 4167 4292 -1 
            4167 4215 4292 -1 
            4215 4167 4090 -1 
            4167 4042 4090 -1 
            4167 4119 4042 -1 
            5330 5282 5205 -1 
            5157 5282 5234 -1 
            5282 5157 5205 -1 
            5282 5359 5234 -1 
            5359 5282 5407 -1 
            5282 5330 5407 -1 
            5061 5013 4936 -1 
            5013 4888 4936 -1 
            4888 5013 4965 -1 
            5013 5090 4965 -1 
            5013 5138 5090 -1 
            5138 5013 5061 -1 
            3783 3706 3831 -1 
            3706 3754 3831 -1 
            3706 3783 3658 -1 
            3754 3706 3629 -1 
            3706 3658 3581 -1 
            3629 3706 3581 -1 
            4544 4467 4592 -1 
            4544 4621 4496 -1 
            4419 4544 4496 -1 
            4467 4544 4419 -1 
            4621 4544 4669 -1 
            4544 4592 4669 -1 
            4736 4688 4611 -1 
            4611 4688 4563 -1 
            4688 4640 4563 -1 
            4640 4688 4765 -1 
            4688 4813 4765 -1 
            4813 4688 4736 -1 
            7899 7947 8024 -1 
            7947 7995 8072 -1 
            8024 7947 8072 -1 
            7947 7870 7995 -1 
            7870 7947 7822 -1 
            7947 7899 7822 -1 
            8158 8110 8033 -1 
            8062 8110 8187 -1 
            8206 8081 8129 -1 
            8206 8158 8081 -1 
            8254 8206 8129 -1 
            8206 8283 8158 -1 
            8331 8206 8254 -1 
            8283 8206 8331 -1 
            8014 7937 8062 -1 
            7889 7937 8014 -1 
            7812 7889 7764 -1 
            7812 7735 7860 -1 
            7937 7812 7860 -1 
            7812 7937 7889 -1 
            7687 7812 7764 -1 
            7812 7687 7735 -1 
            8243 8291 8368 -1 
            8291 8416 8368 -1 
            8166 8291 8243 -1 
            8061 8186 8138 -1 
            8186 8061 8109 -1 
            8234 8157 8282 -1 
            8186 8234 8311 -1 
            8234 8186 8109 -1 
            8157 8234 8109 -1 
            7349 7397 7474 -1 
            7224 7349 7301 -1 
            7379 7302 7427 -1 
            7504 7379 7427 -1 
            7091 7216 7168 -1 
            7091 7139 7216 -1 
            7043 7091 7168 -1 
            7139 7091 7014 -1 
            7091 6966 7014 -1 
            6966 7091 7043 -1 
            7187 7264 7139 -1 
            7062 7187 7139 -1 
            7283 7206 7331 -1 
            7485 7408 7533 -1 
            7408 7283 7331 -1 
            7504 7629 7581 -1 
            7552 7629 7504 -1 
            7282 7330 7407 -1 
            7330 7455 7407 -1 
            7763 7638 7686 -1 
            7638 7561 7686 -1 
            7561 7638 7513 -1 
            7638 7590 7513 -1 
            7542 7417 7465 -1 
            7590 7542 7465 -1 
            7052 7004 6927 -1 
            7004 6879 6927 -1 
            6879 7004 6956 -1 
            6667 6715 6792 -1 
            6888 7013 6965 -1 
            6946 6898 6821 -1 
            6898 6773 6821 -1 
            6898 6850 6773 -1 
            6850 6898 6975 -1 
            7023 6898 6946 -1 
            6975 6898 7023 -1 
            6850 6802 6725 -1 
            6802 6677 6725 -1 
            6677 6802 6754 -1 
            6802 6879 6754 -1 
            6879 6802 6927 -1 
            6802 6850 6927 -1 
            7263 7215 7138 -1 
            7090 7215 7167 -1 
            7215 7090 7138 -1 
            7215 7292 7167 -1 
            7292 7215 7340 -1 
            7215 7263 7340 -1 
            7263 7388 7340 -1 
            7388 7465 7340 -1 
            7465 7388 7513 -1 
            7388 7436 7513 -1 
            7484 7359 7407 -1 
            7359 7484 7436 -1 
            7359 7282 7407 -1 
            7359 7234 7282 -1 
            8283 8235 8158 -1 
            8110 8235 8187 -1 
            8235 8110 8158 -1 
            8235 8312 8187 -1 
            8235 8360 8312 -1 
            8235 8283 8360 -1 
            7687 7610 7735 -1 
            7610 7658 7735 -1 
            7610 7687 7562 -1 
            7658 7610 7533 -1 
            7485 7610 7562 -1 
            7610 7485 7533 -1 
            8215 8263 8340 -1 
            8263 8215 8138 -1 
            8215 8090 8138 -1 
            8090 8215 8167 -1 
            7648 7773 7725 -1 
            7725 7773 7850 -1 
            8167 8244 8119 -1 
            8244 8196 8119 -1 
            8321 8244 8369 -1 
            8196 8244 8321 -1 
            6399 6476 6351 -1 
            6293 6370 6245 -1 
            6168 6293 6245 -1 
            6370 6293 6418 -1 
            6293 6341 6418 -1 
            6197 6149 6072 -1 
            6197 6245 6322 -1 
            6091 6168 6043 -1 
            6091 6014 6139 -1 
            6091 5966 6014 -1 
            6091 6043 5966 -1 
            6264 6312 6389 -1 
            6341 6264 6389 -1 
            6187 6264 6139 -1 
            6264 6187 6312 -1 
            5870 5947 5822 -1 
            5995 5947 5870 -1 
            5947 5995 6072 -1 
            6053 5928 5976 -1 
            5928 5851 5976 -1 
            5851 5928 5803 -1 
            5928 5880 5803 -1 
            6130 6178 6255 -1 
            6178 6130 6053 -1 
            6303 6226 6351 -1 
            6178 6226 6303 -1 
            6149 6226 6101 -1 
            6226 6178 6101 -1 
            6159 6207 6284 -1 
            6332 6207 6255 -1 
            6207 6332 6284 -1 
            6207 6130 6255 -1 
            6207 6159 6082 -1 
            6130 6207 6082 -1 
            6466 6514 6591 -1 
            6514 6466 6389 -1 
            6466 6341 6389 -1 
            6341 6466 6418 -1 
            6562 6437 6485 -1 
            6562 6514 6437 -1 
            6610 6562 6485 -1 
            6687 6562 6610 -1 
            6062 6110 6187 -1 
            6235 6110 6158 -1 
            6110 6235 6187 -1 
            6158 6110 6033 -1 
            6110 5985 6033 -1 
            6110 6062 5985 -1 
            6429 6477 6554 -1 
            6477 6602 6554 -1 
            6352 6477 6429 -1 
            6400 6477 6352 -1 
            6323 6198 6246 -1 
            6275 6198 6323 -1 
            6198 6275 6150 -1 
            6198 6121 6246 -1 
            6198 6150 6073 -1 
            6121 6198 6073 -1 
            6467 6390 6515 -1 
            6390 6438 6515 -1 
            6390 6467 6342 -1 
            6438 6390 6313 -1 
            6390 6265 6313 -1 
            6390 6342 6265 -1 
            7303 7380 7255 -1 
            7380 7303 7428 -1 
            7178 7303 7255 -1 
            7303 7178 7226 -1 
            7428 7351 7476 -1 
            7351 7399 7476 -1 
            7303 7351 7428 -1 
            7399 7351 7274 -1 
            7274 7351 7226 -1 
            7351 7303 7226 -1 
            6687 6735 6812 -1 
            6812 6735 6860 -1 
            6735 6783 6860 -1 
            6783 6735 6658 -1 
            6735 6610 6658 -1 
            6735 6687 6610 -1 
            6822 6947 6899 -1 
            6841 6764 6889 -1 
            6841 6918 6793 -1 
            6966 6841 6889 -1 
            6918 6841 6966 -1 
            6668 6716 6793 -1 
            6716 6841 6793 -1 
            6841 6716 6764 -1 
            6716 6668 6591 -1 
            2984 3061 2936 -1 
            2984 3109 3061 -1 
            3080 2955 3003 -1 
            2859 2811 2734 -1 
            2782 2859 2734 -1 
            2811 2859 2936 -1 
            2859 2984 2936 -1 
            1523 1648 1600 -1 
            1600 1648 1725 -1 
            1648 1773 1725 -1 
            1648 1696 1773 -1 
            1744 1619 1667 -1 
            1744 1696 1619 -1 
            1792 1744 1667 -1 
            1696 1744 1821 -1 
            1744 1792 1869 -1 
            1821 1744 1869 -1 
            2379 2427 2504 -1 
            2427 2475 2552 -1 
            2504 2427 2552 -1 
            2475 2427 2350 -1 
            2427 2302 2350 -1 
            2427 2379 2302 -1 
            3485 3437 3360 -1 
            3437 3312 3360 -1 
            3437 3485 3562 -1 
            3514 3437 3562 -1 
            3350 3273 3398 -1 
            3417 3292 3340 -1 
            3292 3215 3340 -1 
            3215 3292 3167 -1 
            3292 3244 3167 -1 
            3667 3590 3715 -1 
            3619 3667 3744 -1 
            3792 3667 3715 -1 
            3667 3792 3744 -1 
            3542 3667 3619 -1 
            3667 3542 3590 -1 
            3465 3542 3417 -1 
            3542 3465 3590 -1 
            3465 3417 3340 -1 
            3388 3465 3340 -1 
            3638 3513 3561 -1 
            3590 3513 3638 -1 
            3465 3513 3590 -1 
            3513 3436 3561 -1 
            3513 3388 3436 -1 
            3513 3465 3388 -1 
            3051 3128 3003 -1 
            3128 3080 3003 -1 
            3128 3051 3176 -1 
            3080 3128 3205 -1 
            3128 3253 3205 -1 
            3253 3128 3176 -1 
            2840 2888 2965 -1 
            3013 2888 2936 -1 
            2888 3013 2965 -1 
            2888 2811 2936 -1 
            2811 2888 2763 -1 
            2888 2840 2763 -1 
            2917 2840 2965 -1 
            2917 2965 3042 -1 
            2917 2869 2792 -1 
            2840 2917 2792 -1 
            3234 3359 3311 -1 
            3234 3282 3359 -1 
            3186 3234 3311 -1 
            3282 3234 3157 -1 
            3234 3109 3157 -1 
            3109 3234 3186 -1 
            2946 2994 3071 -1 
            3119 2994 3042 -1 
            2994 3119 3071 -1 
            2994 2917 3042 -1 
            2994 2946 2869 -1 
            2917 2994 2869 -1 
            3023 2946 3071 -1 
            3023 3100 2975 -1 
            2821 2898 2773 -1 
            2946 2898 2821 -1 
            2898 3023 2975 -1 
            3023 2898 2946 -1 
            3599 3474 3522 -1 
            3647 3599 3522 -1 
            3676 3599 3724 -1 
            3599 3647 3724 -1 
            8243 8320 8195 -1 
            8320 8272 8195 -1 
            8320 8243 8368 -1 
            8378 8301 8426 -1 
            8253 8301 8378 -1 
            8301 8176 8224 -1 
            8176 8301 8253 -1 
            7772 7724 7647 -1 
            7695 7772 7647 -1 
            8118 8243 8195 -1 
            8118 8166 8243 -1 
            8070 8118 8195 -1 
            7907 7859 7782 -1 
            7955 8080 8032 -1 
            7907 7955 8032 -1 
            7455 7503 7580 -1 
            7609 7734 7686 -1 
            7734 7811 7686 -1 
            7734 7859 7811 -1 
            7859 7734 7782 -1 
            7830 7907 7782 -1 
            7830 7955 7907 -1 
            7772 7849 7724 -1 
            7849 7772 7897 -1 
            7599 7676 7551 -1 
            7724 7676 7599 -1 
            7705 7830 7782 -1 
            7830 7705 7753 -1 
            7589 7637 7714 -1 
            7666 7589 7714 -1 
            7589 7512 7637 -1 
            7522 7570 7647 -1 
            7570 7695 7647 -1 
            7695 7570 7618 -1 
            7618 7570 7493 -1 
            7445 7522 7397 -1 
            7445 7368 7493 -1 
            7570 7445 7493 -1 
            7445 7570 7522 -1 
            7445 7320 7368 -1 
            7320 7445 7397 -1 
            2083 1958 2006 -1 
            2035 1958 2083 -1 
            1958 1881 2006 -1 
            1958 1833 1881 -1 
            1939 1862 1987 -1 
            1814 1862 1939 -1 
            1612 1689 1564 -1 
            1843 1766 1891 -1 
            1766 1814 1891 -1 
            1718 1766 1843 -1 
            1766 1689 1814 -1 
            1824 1949 1901 -1 
            1920 1968 2045 -1 
            2093 1968 2016 -1 
            1968 2093 2045 -1 
            2016 1968 1891 -1 
            1968 1843 1891 -1 
            1968 1920 1843 -1 
            1815 1863 1940 -1 
            1863 1988 1940 -1 
            1988 1863 1911 -1 
            1911 1863 1786 -1 
            1863 1738 1786 -1 
            1863 1815 1738 -1 
            1844 1767 1892 -1 
            1767 1815 1892 -1 
            1767 1844 1719 -1 
            1767 1719 1642 -1 
            2103 2151 2228 -1 
            2276 2151 2199 -1 
            2151 2276 2228 -1 
            1959 2084 2036 -1 
            1959 2007 2084 -1 
            1911 1959 2036 -1 
            2150 2275 2227 -1 
            2150 2198 2275 -1 
            2102 2150 2227 -1 
            2198 2150 2073 -1 
            2150 2025 2073 -1 
            2025 2150 2102 -1 
            2707 2582 2630 -1 
            2659 2582 2707 -1 
            2582 2659 2534 -1 
            2630 2582 2505 -1 
            2582 2457 2505 -1 
            2457 2582 2534 -1 
            2332 2380 2457 -1 
            2380 2428 2505 -1 
            2457 2380 2505 -1 
            2255 2380 2332 -1 
            2178 2255 2130 -1 
            2236 2361 2313 -1 
            2236 2111 2159 -1 
            169 92 217 -1 
            121 169 246 -1 
            169 121 44 -1 
            92 169 44 -1 
            669 794 746 -1 
            919 794 842 -1 
            794 717 842 -1 
            717 794 669 -1 
            640 717 592 -1 
            717 669 592 -1 
            477 602 554 -1 
            477 525 602 -1 
            477 352 400 -1 
            525 477 400 -1 
            1140 1265 1217 -1 
            1140 1015 1063 -1 
            1014 937 1062 -1 
            678 755 630 -1 
            678 601 726 -1 
            755 803 880 -1 
            803 678 726 -1 
            678 803 755 -1 
            1015 938 1063 -1 
            957 832 880 -1 
            832 755 880 -1 
            247 324 199 -1 
            324 276 199 -1 
            401 324 449 -1 
            276 324 401 -1 
            484 436 359 -1 
            311 436 388 -1 
            436 311 359 -1 
            436 513 388 -1 
            436 484 561 -1 
            513 436 561 -1 
            378 455 330 -1 
            455 407 330 -1 
            369 417 494 -1 
            417 542 494 -1 
            542 417 465 -1 
            417 340 465 -1 
            244 321 196 -1 
            244 369 321 -1 
            71 119 196 -1 
            119 244 196 -1 
            244 119 167 -1 
            167 90 215 -1 
            340 263 388 -1 
            263 311 388 -1 
            263 340 215 -1 
            359 234 282 -1 
            311 234 359 -1 
            234 157 282 -1 
            157 234 109 -1 
            274 226 149 -1 
            197 274 149 -1 
            274 197 322 -1 
            226 274 351 -1 
            274 399 351 -1 
            399 274 322 -1 
            524 447 572 -1 
            524 601 476 -1 
            399 524 476 -1 
            447 524 399 -1 
            178 101 226 -1 
            178 255 130 -1 
            53 178 130 -1 
            101 178 53 -1 
            178 303 255 -1 
            178 226 303 -1 
            360 485 437 -1 
            408 485 360 -1 
            485 562 437 -1 
            562 485 610 -1 
            475 552 427 -1 
            552 504 427 -1 
            600 552 475 -1 
            504 552 629 -1 
            552 600 677 -1 
            629 552 677 -1 
            513 590 465 -1 
            590 542 465 -1 
            590 513 638 -1 
            542 590 667 -1 
            590 715 667 -1 
            715 590 638 -1 
            609 686 561 -1 
            484 609 561 -1 
            734 657 782 -1 
            686 734 811 -1 
            734 609 657 -1 
            609 734 686 -1 
            859 734 782 -1 
            734 859 811 -1 
            648 725 600 -1 
            648 571 696 -1 
            773 648 696 -1 
            725 648 773 -1 
            571 648 523 -1 
            648 600 523 -1 
            1456 1379 1504 -1 
            1408 1456 1533 -1 
            1456 1581 1533 -1 
            1581 1456 1504 -1 
            1678 1553 1601 -1 
            1428 1553 1505 -1 
            1784 1707 1832 -1 
            1659 1707 1784 -1 
            1543 1668 1620 -1 
            1668 1543 1591 -1 
            1668 1745 1620 -1 
            1764 1639 1687 -1 
            1639 1562 1687 -1 
            2014 1937 2062 -1 
            1399 1447 1524 -1 
            1418 1543 1495 -1 
            1562 1610 1687 -1 
            1658 1610 1533 -1 
            1610 1485 1533 -1 
            1610 1562 1485 -1 
            1783 1735 1658 -1 
            1610 1735 1687 -1 
            1735 1610 1658 -1 
            1754 1802 1879 -1 
            1927 1802 1850 -1 
            1802 1927 1879 -1 
            1850 1802 1725 -1 
            1802 1677 1725 -1 
            1802 1754 1677 -1 
            1831 1754 1879 -1 
            1831 1879 1956 -1 
            1706 1783 1658 -1 
            1754 1706 1629 -1 
            1706 1831 1783 -1 
            1831 1706 1754 -1 
            1706 1581 1629 -1 
            1706 1658 1581 -1 
            804 929 881 -1 
            929 1006 881 -1 
            929 1054 1006 -1 
            1054 929 977 -1 
            900 775 823 -1 
            948 900 823 -1 
            1025 900 948 -1 
            900 1025 977 -1 
            1361 1313 1236 -1 
            1515 1592 1467 -1 
            1515 1640 1592 -1 
            1640 1563 1688 -1 
            1563 1611 1688 -1 
            1515 1563 1640 -1 
            966 1043 918 -1 
            1428 1303 1351 -1 
            1457 1409 1332 -1 
            1284 1409 1361 -1 
            1409 1284 1332 -1 
            1611 1534 1659 -1 
            1534 1409 1457 -1 
            1196 1321 1273 -1 
            1196 1244 1321 -1 
            1196 1273 1148 -1 
            1244 1196 1119 -1 
            1071 1196 1148 -1 
            1119 1196 1071 -1 
            841 889 966 -1 
            1014 889 937 -1 
            889 1014 966 -1 
            889 812 937 -1 
            812 889 764 -1 
            889 841 764 -1 
            716 793 668 -1 
            841 793 716 -1 
            793 841 918 -1 
            1391 1314 1439 -1 
            1343 1391 1468 -1 
            1266 1391 1343 -1 
            1314 1391 1266 -1 
            1468 1391 1516 -1 
            1391 1439 1516 -1 
            1650 1727 1602 -1 
            1727 1679 1602 -1 
            1727 1650 1775 -1 
            1727 1804 1679 -1 
            1929 1852 1977 -1 
            1804 1852 1929 -1 
            1852 1727 1775 -1 
            1727 1852 1804 -1 
            323 275 198 -1 
            198 275 150 -1 
            275 227 150 -1 
            227 275 352 -1 
            352 275 400 -1 
            275 323 400 -1 
            294 371 246 -1 
            169 294 246 -1 
            294 169 217 -1 
            294 419 371 -1 
            371 419 496 -1 
            419 544 496 -1 
            534 457 582 -1 
            486 534 611 -1 
            534 659 611 -1 
            659 534 582 -1 
            438 361 486 -1 
            284 361 236 -1 
            621 544 669 -1 
            573 621 698 -1 
            621 573 496 -1 
            544 621 496 -1 
            621 746 698 -1 
            621 669 746 -1 
            1201 1278 1153 -1 
            1076 1201 1153 -1 
            1278 1201 1326 -1 
            1201 1249 1326 -1 
            1124 1201 1076 -1 
            1201 1124 1249 -1 
            1124 1076 999 -1 
            1047 1124 999 -1 
            1345 1470 1422 -1 
            1470 1547 1422 -1 
            1470 1595 1547 -1 
            1595 1470 1518 -1 
            1470 1393 1518 -1 
            1393 1470 1345 -1 
            1142 1219 1094 -1 
            1219 1142 1267 -1 
            1142 1190 1267 -1 
            1257 1209 1132 -1 
            1209 1084 1132 -1 
            3178 3303 3255 -1 
            3178 3226 3303 -1 
            2995 3072 2947 -1 
            3072 3024 2947 -1 
            2976 3101 3053 -1 
            3024 3101 2976 -1 
            3101 3178 3053 -1 
            3178 3101 3226 -1 
            2928 2880 2803 -1 
            2755 2880 2832 -1 
            2803 2880 2755 -1 
            2880 2957 2832 -1 
            2957 2880 3005 -1 
            2880 2928 3005 -1 
            3466 3418 3341 -1 
            3418 3543 3495 -1 
            3418 3466 3543 -1 
            3583 3660 3535 -1 
            3583 3506 3631 -1 
            3458 3583 3535 -1 
            3506 3583 3458 -1 
            3708 3631 3756 -1 
            3660 3708 3785 -1 
            3708 3583 3631 -1 
            3583 3708 3660 -1 
            3833 3708 3756 -1 
            3708 3833 3785 -1 
            3554 3602 3679 -1 
            3602 3650 3727 -1 
            3679 3602 3727 -1 
            3650 3602 3525 -1 
            3602 3477 3525 -1 
            3602 3554 3477 -1 
            3823 3871 3948 -1 
            3900 3823 3948 -1 
            3823 3746 3871 -1 
            3919 3967 4044 -1 
            4092 3967 4015 -1 
            4044 3967 4092 -1 
            3746 3794 3871 -1 
            3794 3919 3871 -1 
            3794 3746 3669 -1 
            3698 3621 3746 -1 
            3823 3698 3746 -1 
            3573 3698 3650 -1 
            3621 3698 3573 -1 
            3544 3592 3669 -1 
            3621 3544 3669 -1 
            3544 3621 3496 -1 
            3592 3544 3467 -1 
            3544 3496 3419 -1 
            3467 3544 3419 -1 
            3534 3611 3486 -1 
            3659 3611 3534 -1 
            3707 3630 3755 -1 
            3707 3582 3630 -1 
            3707 3659 3582 -1 
            3640 3592 3515 -1 
            3880 3832 3755 -1 
            3832 3707 3755 -1 
            3909 4034 3986 -1 
            2689 2814 2766 -1 
            2689 2737 2814 -1 
            2689 2766 2641 -1 
            2737 2689 2612 -1 
            2564 2689 2641 -1 
            2689 2564 2612 -1 
            2939 2891 2814 -1 
            2766 2891 2843 -1 
            2814 2891 2766 -1 
            2891 2968 2843 -1 
            3064 3016 2939 -1 
            2891 3016 2968 -1 
            3016 2891 2939 -1 
            3016 3093 2968 -1 
            3093 3016 3141 -1 
            3016 3064 3141 -1 
            3112 2987 3035 -1 
            3112 3064 2987 -1 
            3112 3035 3160 -1 
            3064 3112 3189 -1 
            3112 3237 3189 -1 
            3237 3112 3160 -1 
            3131 3256 3208 -1 
            3131 3179 3256 -1 
            3054 3131 3006 -1 
            3179 3131 3054 -1 
            3198 3275 3150 -1 
            3198 3121 3246 -1 
            3323 3198 3246 -1 
            3275 3198 3323 -1 
            3121 3198 3073 -1 
            3198 3150 3073 -1 
            1363 1411 1488 -1 
            1411 1459 1536 -1 
            1488 1411 1536 -1 
            1286 1411 1363 -1 
            2795 2872 2747 -1 
            2872 2824 2747 -1 
            2872 2795 2920 -1 
            2824 2872 2949 -1 
            2872 2997 2949 -1 
            2997 2872 2920 -1 
            2853 2930 2805 -1 
            2853 2901 2978 -1 
            2930 2853 2978 -1 
            2824 2776 2699 -1 
            2776 2651 2699 -1 
            2776 2824 2901 -1 
            2853 2776 2901 -1 
            1969 2017 2094 -1 
            2017 2142 2094 -1 
            2017 2065 2142 -1 
            2065 2017 1940 -1 
            1940 2017 1892 -1 
            2017 1969 1892 -1 
            2046 1969 2094 -1 
            1998 2046 2123 -1 
            2171 2046 2094 -1 
            2046 2171 2123 -1 
            1921 1998 1873 -1 
            1969 1921 1844 -1 
            1921 2046 1998 -1 
            2046 1921 1969 -1 
            1796 1921 1873 -1 
            1844 1921 1796 -1 
            711 663 586 -1 
            538 663 615 -1 
            586 663 538 -1 
            615 663 788 -1 
            663 711 788 -1 
            874 749 797 -1 
            826 749 874 -1 
            749 826 701 -1 
            797 749 672 -1 
            749 624 672 -1 
            624 749 701 -1 
            1095 970 1018 -1 
            1047 970 1095 -1 
            970 1047 922 -1 
            1018 970 893 -1 
            970 845 893 -1 
            970 922 845 -1 
            1902 2027 1979 -1 
            1902 1950 2027 -1 
            1902 1979 1854 -1 
            1950 1902 1825 -1 
            1777 1902 1854 -1 
            1825 1902 1777 -1 
            3200 3075 3123 -1 
            3200 3152 3075 -1 
            3200 3123 3248 -1 
            3152 3200 3277 -1 
            3200 3325 3277 -1 
            3200 3248 3325 -1 
            2941 2864 2989 -1 
            2893 2941 3018 -1 
            2816 2941 2893 -1 
            2864 2941 2816 -1 
            2941 3066 3018 -1 
            3066 2941 2989 -1 
            3087 3135 3212 -1 
            3135 3183 3260 -1 
            3212 3135 3260 -1 
            2962 3087 3039 -1 
            2962 2837 2885 -1 
            3020 2943 3068 -1 
            2943 2991 3068 -1 
            2895 2943 3020 -1 
            2654 2606 2529 -1 
            2606 2654 2731 -1 
            2779 2654 2702 -1 
            2731 2654 2779 -1 
            3000 3125 3077 -1 
            3000 3048 3125 -1 
            3000 3077 2952 -1 
            3048 3000 2923 -1 
            3000 2952 2875 -1 
            2923 3000 2875 -1 
            3096 2971 3019 -1 
            3096 3048 2971 -1 
            3144 3096 3019 -1 
            3048 3096 3173 -1 
            3221 3096 3144 -1 
            3096 3221 3173 -1 
            3269 3317 3394 -1 
            3442 3317 3365 -1 
            3317 3442 3394 -1 
            3317 3240 3365 -1 
            3192 3269 3144 -1 
            3192 3115 3240 -1 
            3317 3192 3240 -1 
            3192 3317 3269 -1 
            3067 3192 3144 -1 
            3115 3192 3067 -1 
            2807 2730 2855 -1 
            2538 2413 2461 -1 
            2366 2318 2241 -1 
            2289 2366 2241 -1 
            2366 2289 2414 -1 
            2318 2366 2443 -1 
            2366 2491 2443 -1 
            2366 2414 2491 -1 
            2385 2462 2337 -1 
            2385 2308 2433 -1 
            2510 2385 2433 -1 
            2462 2385 2510 -1 
            2404 2279 2327 -1 
            2327 2279 2202 -1 
            2279 2154 2202 -1 
            2548 2596 2673 -1 
            2721 2596 2644 -1 
            2596 2721 2673 -1 
            2596 2519 2644 -1 
            2625 2548 2673 -1 
            2625 2750 2702 -1 
            2750 2625 2673 -1 
            2394 2346 2269 -1 
            2221 2346 2298 -1 
            2346 2221 2269 -1 
            2346 2423 2298 -1 
            2394 2317 2442 -1 
            2317 2394 2269 -1 
            2317 2192 2240 -1 
            2192 2317 2269 -1 
            2077 2125 2202 -1 
            2154 2077 2202 -1 
            2077 2000 2125 -1 
            2423 2375 2298 -1 
            2375 2250 2298 -1 
            2250 2375 2327 -1 
            2327 2375 2452 -1 
            2423 2471 2548 -1 
            2596 2471 2519 -1 
            2471 2596 2548 -1 
            2471 2394 2519 -1 
            2471 2346 2394 -1 
            2346 2471 2423 -1 
            2154 2231 2106 -1 
            2231 2183 2106 -1 
            2279 2231 2154 -1 
            2183 2231 2308 -1 
            2260 2183 2308 -1 
            2212 2260 2337 -1 
            2385 2260 2308 -1 
            2260 2385 2337 -1 
            2058 2135 2010 -1 
            2183 2135 2058 -1 
            2135 2260 2212 -1 
            2260 2135 2183 -1 
            2221 2173 2096 -1 
            2173 2048 2096 -1 
            2048 2173 2125 -1 
            2173 2250 2125 -1 
            2250 2173 2298 -1 
            2173 2221 2298 -1 
            2480 2355 2403 -1 
            2403 2355 2278 -1 
            2355 2230 2278 -1 
            2230 2355 2307 -1 
            2528 2403 2451 -1 
            2528 2480 2403 -1 
            2528 2451 2576 -1 
            2653 2528 2576 -1 
            2259 2336 2211 -1 
            2384 2259 2307 -1 
            2259 2384 2336 -1 
            2336 2384 2461 -1 
            2336 2288 2211 -1 
            2288 2336 2413 -1 
            1990 2067 1942 -1 
            2038 1990 1913 -1 
            1865 1990 1942 -1 
            1990 1865 1913 -1 
            1990 2115 2067 -1 
            2115 1990 2038 -1 
            2192 2115 2240 -1 
            2115 2192 2067 -1 
            2057 2009 1932 -1 
            1884 2009 1961 -1 
            2009 1884 1932 -1 
            2182 2057 2105 -1 
            2230 2182 2105 -1 
            2182 2230 2307 -1 
            2259 2182 2307 -1 
            2926 2878 2801 -1 
            2753 2878 2830 -1 
            2878 2753 2801 -1 
            2878 2955 2830 -1 
            2878 2926 3003 -1 
            2955 2878 3003 -1 
            2820 2897 2772 -1 
            1484 1359 1407 -1 
            1484 1436 1359 -1 
            1484 1407 1532 -1 
            1436 1484 1561 -1 
            1609 1484 1532 -1 
            1561 1484 1609 -1 
            1436 1388 1311 -1 
            1388 1263 1311 -1 
            1263 1388 1340 -1 
            1388 1465 1340 -1 
            1513 1436 1561 -1 
            1465 1513 1590 -1 
            1513 1388 1436 -1 
            1388 1513 1465 -1 
            1513 1638 1590 -1 
            1638 1513 1561 -1 
            2791 2714 2839 -1 
            2791 2666 2714 -1 
            2791 2916 2868 -1 
            2916 2791 2839 -1 
            608 685 560 -1 
            608 733 685 -1 
            483 608 560 -1 
            531 608 483 -1 
            646 521 569 -1 
            521 444 569 -1 
            444 492 569 -1 
            444 521 396 -1 
            492 444 367 -1 
            540 492 415 -1 
            925 877 800 -1 
            877 925 1002 -1 
            1050 925 973 -1 
            925 1050 1002 -1 
            704 829 781 -1 
            829 906 781 -1 
            627 704 579 -1 
            627 579 502 -1 
            752 675 800 -1 
            752 829 704 -1 
            627 752 704 -1 
            752 627 675 -1 
            877 752 800 -1 
            829 752 877 -1 
            348 425 300 -1 
            425 377 300 -1 
            425 348 473 -1 
            377 425 502 -1 
            454 377 502 -1 
            454 531 406 -1 
            579 454 502 -1 
            531 454 579 -1 
            329 204 252 -1 
            377 329 252 -1 
            329 454 406 -1 
            454 329 377 -1 
            589 637 714 -1 
            637 685 762 -1 
            714 637 762 -1 
            685 637 560 -1 
            637 512 560 -1 
            637 589 512 -1 
            647 599 522 -1 
            474 599 551 -1 
            599 474 522 -1 
            599 676 551 -1 
            676 599 724 -1 
            599 647 724 -1 
            1521 1569 1646 -1 
            1694 1569 1617 -1 
            1569 1694 1646 -1 
            1569 1521 1444 -1 
            1540 1463 1588 -1 
            1463 1540 1415 -1 
            1540 1665 1617 -1 
            1665 1540 1588 -1 
            1367 1492 1444 -1 
            1415 1492 1367 -1 
            1540 1492 1415 -1 
            1492 1569 1444 -1 
            1569 1492 1617 -1 
            1492 1540 1617 -1 
            1425 1348 1473 -1 
            1425 1502 1377 -1 
            1425 1473 1550 -1 
            1502 1425 1550 -1 
            1348 1396 1473 -1 
            1521 1396 1444 -1 
            1396 1521 1473 -1 
            1396 1319 1444 -1 
            1396 1271 1319 -1 
            1396 1348 1271 -1 
            1078 1001 1126 -1 
            1203 1078 1126 -1 
            953 876 1001 -1 
            953 828 876 -1 
            1078 953 1001 -1 
            953 1078 1030 -1 
            1251 1203 1126 -1 
            1174 1251 1126 -1 
            1405 1328 1453 -1 
            1328 1376 1453 -1 
            1328 1405 1280 -1 
            1328 1251 1376 -1 
            1203 1328 1280 -1 
            1251 1328 1203 -1 
            1376 1501 1453 -1 
            1453 1501 1578 -1 
            1626 1501 1549 -1 
            1501 1626 1578 -1 
            1501 1424 1549 -1 
            1424 1501 1376 -1 
            914 962 1039 -1 
            962 914 837 -1 
            991 914 1039 -1 
            991 1068 943 -1 
            1116 991 1039 -1 
            991 1116 1068 -1 
            866 943 818 -1 
            914 866 789 -1 
            866 991 943 -1 
            991 866 914 -1 
            856 933 808 -1 
            904 856 779 -1 
            856 731 779 -1 
            731 856 808 -1 
            933 1058 1010 -1 
            1058 1135 1010 -1 
            1029 952 1077 -1 
            952 1029 904 -1 
            1154 1029 1077 -1 
            1029 1154 1106 -1 
            1135 1087 1010 -1 
            962 1087 1039 -1 
            1087 962 1010 -1 
            1039 1087 1164 -1 
            1087 1212 1164 -1 
            1087 1135 1212 -1 
            1135 1260 1212 -1 
            1212 1260 1337 -1 
            1385 1260 1308 -1 
            1260 1385 1337 -1 
            1231 1154 1279 -1 
            1154 1231 1106 -1 
            1356 1231 1279 -1 
            1231 1356 1308 -1 
            405 530 482 -1 
            357 405 482 -1 
            328 405 280 -1 
            405 357 280 -1 
            472 424 347 -1 
            299 424 376 -1 
            424 299 347 -1 
            424 472 549 -1 
            443 520 395 -1 
            520 472 395 -1 
            520 443 568 -1 
            520 597 472 -1 
            1868 1945 1820 -1 
            1945 1897 1820 -1 
            1897 1945 2022 -1 
            1974 1897 2022 -1 
            1974 2051 1926 -1 
            1849 1974 1926 -1 
            1897 1974 1849 -1 
            1974 2099 2051 -1 
            1974 2022 2099 -1 
            955 1032 907 -1 
            1003 955 878 -1 
            955 1080 1032 -1 
            1080 955 1003 -1 
            878 955 830 -1 
            955 907 830 -1 
            1301 1176 1224 -1 
            1176 1301 1253 -1 
            1099 1176 1051 -1 
            1176 1099 1224 -1 
            2203 2155 2078 -1 
            2126 2203 2078 -1 
            2203 2126 2251 -1 
            2155 2203 2280 -1 
            2328 2203 2251 -1 
            2280 2203 2328 -1 
            2001 2049 2126 -1 
            2174 2049 2097 -1 
            2126 2049 2174 -1 
            2049 1972 2097 -1 
            1674 1751 1626 -1 
            1703 1751 1828 -1 
            1751 1703 1626 -1 
            1626 1703 1578 -1 
            1703 1655 1578 -1 
            1972 1924 1847 -1 
            2049 1924 1972 -1 
            1924 2049 2001 -1 
            2107 2030 2155 -1 
            2059 2107 2184 -1 
            2030 2107 1982 -1 
            2107 2059 1982 -1 
            2184 2107 2232 -1 
            2107 2155 2232 -1 
            1588 1636 1713 -1 
            1636 1761 1713 -1 
            1463 1511 1588 -1 
            1511 1636 1588 -1 
            1636 1511 1559 -1 
            1511 1463 1386 -1 
            1607 1530 1655 -1 
            1607 1655 1732 -1 
            1434 1511 1386 -1 
            1511 1434 1559 -1 
            1309 1434 1386 -1 
            1434 1309 1357 -1 
            1857 1809 1732 -1 
            1761 1809 1886 -1 
            1809 1934 1886 -1 
            1809 1857 1934 -1 
            1780 1857 1732 -1 
            1780 1703 1828 -1 
            1655 1780 1732 -1 
            1703 1780 1655 -1 
            1905 1828 1953 -1 
            1857 1905 1982 -1 
            1905 1780 1828 -1 
            1780 1905 1857 -1 
            1905 2030 1982 -1 
            2030 1905 1953 -1 
            906 954 1031 -1 
            954 877 1002 -1 
            954 829 877 -1 
            829 954 906 -1 
            1108 1233 1185 -1 
            1233 1310 1185 -1 
            1310 1233 1358 -1 
            1233 1281 1358 -1 
            1060 983 1108 -1 
            1060 1137 1012 -1 
            935 1060 1012 -1 
            983 1060 935 -1 
            1060 1108 1185 -1 
            1137 1060 1185 -1 
            1221 1173 1096 -1 
            1048 1173 1125 -1 
            1173 1048 1096 -1 
            1173 1250 1125 -1 
            1250 1173 1298 -1 
            1173 1221 1298 -1 
            1413 1288 1336 -1 
            1336 1288 1211 -1 
            1288 1163 1211 -1 
            1288 1240 1163 -1 
            1538 1490 1413 -1 
            1615 1490 1538 -1 
            530 578 655 -1 
            194 319 271 -1 
            271 319 396 -1 
            319 444 396 -1 
            444 319 367 -1 
            319 242 367 -1 
            242 319 194 -1 
            21 146 98 -1 
            146 223 98 -1 
            223 146 271 -1 
            146 194 271 -1 
            146 69 194 -1 
            69 146 21 -1 
            770 847 722 -1 
            847 799 722 -1 
            847 770 895 -1 
            847 924 799 -1 
            972 847 895 -1 
            924 847 972 -1 
            751 626 674 -1 
            799 751 674 -1 
            828 751 876 -1 
            751 799 876 -1 
            645 770 722 -1 
            597 645 722 -1 
            645 520 568 -1 
            520 645 597 -1 
            780 732 655 -1 
            684 809 761 -1 
            732 809 684 -1 
            809 886 761 -1 
            1664 1616 1539 -1 
            1616 1491 1539 -1 
            1491 1616 1568 -1 
            1616 1664 1741 -1 
            1664 1789 1741 -1 
            1789 1866 1741 -1 
            1789 1837 1914 -1 
            1866 1789 1914 -1 
            1568 1645 1520 -1 
            1760 1712 1635 -1 
            1635 1712 1587 -1 
            1712 1664 1587 -1 
            1712 1789 1664 -1 
            1712 1760 1837 -1 
            1789 1712 1837 -1 
            1510 1433 1558 -1 
            1510 1385 1433 -1 
            1635 1510 1558 -1 
            1510 1635 1587 -1 
            1289 1366 1241 -1 
            1366 1318 1241 -1 
            1366 1289 1414 -1 
            1318 1366 1443 -1 
            1491 1366 1414 -1 
            1366 1491 1443 -1 
            1145 1270 1222 -1 
            1222 1270 1347 -1 
            1395 1318 1443 -1 
            1395 1443 1520 -1 
            1270 1395 1347 -1 
            1395 1270 1318 -1 
            1827 1702 1750 -1 
            1702 1625 1750 -1 
            1702 1654 1577 -1 
            1625 1702 1577 -1 
            3759 3836 3711 -1 
            3807 3759 3682 -1 
            3759 3711 3634 -1 
            3682 3759 3634 -1 
            3884 3807 3932 -1 
            3836 3884 3961 -1 
            3759 3884 3836 -1 
            3884 3759 3807 -1 
            3884 3932 4009 -1 
            3961 3884 4009 -1 
            3567 3615 3692 -1 
            3740 3615 3663 -1 
            3692 3615 3740 -1 
            3615 3538 3663 -1 
            3615 3490 3538 -1 
            3615 3567 3490 -1 
            3625 3577 3500 -1 
            3452 3577 3529 -1 
            3577 3452 3500 -1 
            3577 3654 3529 -1 
            3702 3625 3750 -1 
            3702 3779 3654 -1 
            3577 3702 3654 -1 
            3702 3577 3625 -1 
            3827 3702 3750 -1 
            3702 3827 3779 -1 
            3731 3683 3606 -1 
            3558 3683 3635 -1 
            3606 3683 3558 -1 
            3635 3683 3760 -1 
            3683 3808 3760 -1 
            3683 3731 3808 -1 
            4337 4260 4385 -1 
            4289 4337 4414 -1 
            4212 4337 4289 -1 
            4337 4212 4260 -1 
            4337 4462 4414 -1 
            4462 4337 4385 -1 
            4260 4308 4385 -1 
            4356 4308 4231 -1 
            4231 4308 4183 -1 
            4308 4260 4183 -1 
            4453 4405 4328 -1 
            4405 4280 4328 -1 
            4405 4357 4280 -1 
            4357 4405 4482 -1 
            4530 4405 4453 -1 
            4405 4530 4482 -1 
            3713 3761 3838 -1 
            3886 3761 3809 -1 
            3761 3886 3838 -1 
            3761 3684 3809 -1 
            3761 3713 3636 -1 
            3684 3761 3636 -1 
            3540 3492 3415 -1 
            3415 3492 3367 -1 
            3492 3444 3367 -1 
            3492 3569 3444 -1 
            3242 3319 3194 -1 
            3242 3165 3290 -1 
            3242 3290 3367 -1 
            3319 3242 3367 -1 
            3242 3194 3117 -1 
            3165 3242 3117 -1 
            4145 4193 4270 -1 
            4318 4193 4241 -1 
            4193 4318 4270 -1 
            4193 4116 4241 -1 
            3914 3991 3866 -1 
            3991 3943 3866 -1 
            3991 3914 4039 -1 
            4116 3991 4039 -1 
            3693 3770 3645 -1 
            3741 3693 3616 -1 
            3693 3568 3616 -1 
            3568 3693 3645 -1 
            3866 3818 3741 -1 
            3818 3693 3741 -1 
            3693 3818 3770 -1 
            3943 3818 3866 -1 
            3770 3722 3645 -1 
            3597 3722 3674 -1 
            3722 3597 3645 -1 
            3722 3799 3674 -1 
            3722 3847 3799 -1 
            3722 3770 3847 -1 
            3953 4030 3905 -1 
            4001 3953 3876 -1 
            3953 3905 3828 -1 
            3876 3953 3828 -1 
            4203 4126 4251 -1 
            4126 4174 4251 -1 
            4126 4049 4174 -1 
            4049 4126 4001 -1 
            4049 3924 3972 -1 
            4049 4001 3924 -1 
            3713 3665 3588 -1 
            3665 3540 3588 -1 
            3665 3790 3742 -1 
            3665 3713 3790 -1 
            4041 3916 3964 -1 
            4041 3993 3916 -1 
            4041 3964 4089 -1 
            3993 4041 4118 -1 
            4166 4041 4089 -1 
            4041 4166 4118 -1 
            3945 4070 4022 -1 
            3945 3993 4070 -1 
            3945 4022 3897 -1 
            3993 3945 3868 -1 
            3820 3945 3897 -1 
            3945 3820 3868 -1 
            4147 4099 4022 -1 
            4099 4051 3974 -1 
            4022 4099 3974 -1 
            4099 4176 4051 -1 
            4099 4147 4224 -1 
            4176 4099 4224 -1 
            4195 4070 4118 -1 
            4195 4147 4070 -1 
            4483 4560 4435 -1 
            4560 4512 4435 -1 
            4560 4483 4608 -1 
            4512 4560 4637 -1 
            4560 4608 4685 -1 
            4637 4560 4685 -1 
            4887 4762 4810 -1 
            4935 4887 4810 -1 
            4887 4935 5012 -1 
            4983 4858 4906 -1 
            4983 4935 4858 -1 
            5742 5665 5790 -1 
            5665 5713 5790 -1 
            5617 5665 5742 -1 
            5713 5665 5588 -1 
            5588 5540 5463 -1 
            5540 5415 5463 -1 
            5540 5492 5415 -1 
            5492 5540 5617 -1 
            5665 5540 5588 -1 
            5540 5665 5617 -1 
            5569 5492 5617 -1 
            5569 5646 5521 -1 
            5444 5569 5521 -1 
            5492 5569 5444 -1 
            5646 5569 5694 -1 
            5569 5617 5694 -1 
            4992 4944 4867 -1 
            4944 4819 4867 -1 
            4944 5069 5021 -1 
            4944 4992 5069 -1 
            4598 4675 4550 -1 
            4723 4675 4598 -1 
            4955 4878 5003 -1 
            4830 4878 4955 -1 
            4878 4753 4801 -1 
            4878 4830 4753 -1 
            5223 5348 5300 -1 
            5175 5223 5300 -1 
            5146 5223 5098 -1 
            5223 5175 5098 -1 
            5175 5127 5050 -1 
            5002 5127 5079 -1 
            5050 5127 5002 -1 
            5127 5204 5079 -1 
            5127 5252 5204 -1 
            5252 5127 5175 -1 
            5281 5329 5406 -1 
            5329 5454 5406 -1 
            5329 5281 5204 -1 
            5252 5329 5204 -1 
            5156 5233 5108 -1 
            5204 5156 5079 -1 
            5281 5156 5204 -1 
            5233 5156 5281 -1 
            5483 5406 5531 -1 
            5483 5560 5435 -1 
            5483 5358 5406 -1 
            5358 5483 5435 -1 
            5358 5281 5406 -1 
            5358 5233 5281 -1 
            5416 5464 5541 -1 
            5464 5589 5541 -1 
            5589 5464 5512 -1 
            5387 5435 5512 -1 
            5464 5387 5512 -1 
            4512 4464 4387 -1 
            4387 4464 4339 -1 
            4464 4416 4339 -1 
            4416 4464 4541 -1 
            4464 4589 4541 -1 
            4464 4512 4589 -1 
            4522 4570 4647 -1 
            4570 4618 4695 -1 
            4647 4570 4695 -1 
            4570 4493 4618 -1 
            4493 4570 4445 -1 
            4570 4522 4445 -1 
            4580 4628 4705 -1 
            4628 4753 4705 -1 
            4753 4628 4676 -1 
            4407 4282 4330 -1 
            4282 4407 4359 -1 
            4484 4561 4436 -1 
            4484 4609 4561 -1 
            4359 4484 4436 -1 
            4407 4484 4359 -1 
            4609 4532 4657 -1 
            4532 4580 4657 -1 
            4484 4532 4609 -1 
            4532 4484 4407 -1 
            4462 4539 4414 -1 
            4587 4539 4462 -1 
            4539 4664 4616 -1 
            4539 4587 4664 -1 
            5107 5030 5155 -1 
            5107 5184 5059 -1 
            4982 5107 5059 -1 
            5030 5107 4982 -1 
            5232 5107 5155 -1 
            5107 5232 5184 -1 
            5288 5336 5413 -1 
            5336 5461 5413 -1 
            5461 5336 5384 -1 
            5211 5336 5288 -1 
            5903 5855 5778 -1 
            5730 5855 5807 -1 
            5778 5855 5730 -1 
            5855 5932 5807 -1 
            5855 5980 5932 -1 
            5855 5903 5980 -1 
            5884 5836 5759 -1 
            5711 5836 5788 -1 
            5759 5836 5711 -1 
            5836 5913 5788 -1 
            5836 5884 5961 -1 
            5913 5836 5961 -1 
            6076 6028 5951 -1 
            6028 5903 5951 -1 
            5903 6028 5980 -1 
            6201 6124 6249 -1 
            6201 6076 6124 -1 
            6201 6326 6278 -1 
            6201 6249 6326 -1 
            6134 6057 6182 -1 
            6009 6057 6134 -1 
            5980 6057 5932 -1 
            6057 6009 5932 -1 
            5211 5163 5086 -1 
            5163 5038 5086 -1 
            5038 5163 5115 -1 
            5115 5163 5240 -1 
            5163 5288 5240 -1 
            5163 5211 5288 -1 
            5307 5355 5432 -1 
            5384 5307 5432 -1 
            5355 5307 5230 -1 
            5134 5211 5086 -1 
            5009 5134 5086 -1 
            5134 5009 5057 -1 
            4788 4865 4740 -1 
            4913 4865 4788 -1 
            4990 5067 4942 -1 
            5038 4990 4913 -1 
            5067 4990 5115 -1 
            4990 5038 5115 -1 
            4865 4990 4942 -1 
            4990 4865 4913 -1 
            5048 4971 5096 -1 
            5048 5125 5000 -1 
            4923 5048 5000 -1 
            4971 5048 4923 -1 
            5125 5048 5173 -1 
            5048 5096 5173 -1 
            5124 5076 4999 -1 
            5076 5124 5201 -1 
            5249 5124 5172 -1 
            5124 5249 5201 -1 
            5153 5105 5028 -1 
            5105 5153 5230 -1 
            5335 5412 5287 -1 
            5412 5364 5287 -1 
            5412 5335 5460 -1 
            5364 5412 5489 -1 
            5316 5393 5268 -1 
            5364 5316 5239 -1 
            5316 5441 5393 -1 
            5441 5316 5364 -1 
            5009 4932 5057 -1 
            4884 4932 5009 -1 
            4855 4932 4807 -1 
            4932 4884 4807 -1 
            4404 4481 4356 -1 
            4558 4481 4606 -1 
            4548 4423 4471 -1 
            4596 4548 4471 -1 
            4673 4596 4721 -1 
            4673 4750 4625 -1 
            4548 4673 4625 -1 
            4673 4548 4596 -1 
            4798 4673 4721 -1 
            4750 4673 4798 -1 
            6513 6388 6436 -1 
            6388 6513 6465 -1 
            6609 6657 6734 -1 
            6657 6609 6532 -1 
            6426 6551 6503 -1 
            6503 6551 6628 -1 
            6551 6676 6628 -1 
            6551 6599 6676 -1 
            6522 6474 6397 -1 
            6397 6474 6349 -1 
            6474 6426 6349 -1 
            6474 6551 6426 -1 
            6474 6522 6599 -1 
            6551 6474 6599 -1 
            5917 5869 5792 -1 
            5744 5869 5821 -1 
            5792 5869 5744 -1 
            5869 5946 5821 -1 
            5946 5869 5994 -1 
            5869 5917 5994 -1 
            6061 5984 6109 -1 
            5936 5984 6061 -1 
            5984 5859 5907 -1 
            5984 5936 5859 -1 
            5906 5983 5858 -1 
            5906 5829 5954 -1 
            5906 5954 6031 -1 
            5983 5906 6031 -1 
            5781 5906 5858 -1 
            5829 5906 5781 -1 
            5714 5762 5839 -1 
            5839 5762 5887 -1 
            5762 5810 5887 -1 
            5810 5762 5685 -1 
            5762 5637 5685 -1 
            5762 5714 5637 -1 
            6069 6146 6021 -1 
            6117 6069 5992 -1 
            6194 6069 6117 -1 
            6146 6069 6194 -1 
            6069 5944 5992 -1 
            6069 6021 5944 -1 
            6790 6713 6838 -1 
            6790 6867 6742 -1 
            6790 6838 6915 -1 
            6867 6790 6915 -1 
            6665 6790 6742 -1 
            6790 6665 6713 -1 
            6713 6665 6588 -1 
            6338 6213 6261 -1 
            5781 5656 5704 -1 
            5733 5656 5781 -1 
            5579 5656 5531 -1 
            5656 5579 5704 -1 
            5944 5819 5867 -1 
            5896 5819 5944 -1 
            5819 5742 5867 -1 
            5742 5819 5694 -1 
            7176 7224 7301 -1 
            7205 7330 7282 -1 
            7512 7464 7387 -1 
            7589 7464 7512 -1 
            7339 7416 7291 -1 
            7339 7262 7387 -1 
            7464 7339 7387 -1 
            7339 7464 7416 -1 
            7339 7214 7262 -1 
            7339 7291 7214 -1 
            7320 7272 7195 -1 
            7272 7349 7224 -1 
            7272 7320 7397 -1 
            7349 7272 7397 -1 
            7243 7291 7368 -1 
            7320 7243 7368 -1 
            7243 7320 7195 -1 
            7118 7243 7195 -1 
            7089 7166 7041 -1 
            7166 7118 7041 -1 
            7214 7166 7089 -1 
            7166 7243 7118 -1 
            7291 7166 7214 -1 
            7243 7166 7291 -1 
            6993 7070 6945 -1 
            7118 7070 6993 -1 
            7070 7118 7195 -1 
            7260 7385 7337 -1 
            7462 7385 7510 -1 
            7385 7462 7337 -1 
            7116 7241 7193 -1 
            6847 6924 6799 -1 
            6270 6318 6395 -1 
            6318 6241 6366 -1 
            6241 6318 6193 -1 
            6318 6270 6193 -1 
            6558 6606 6683 -1 
            6606 6731 6683 -1 
            6731 6606 6654 -1 
            6654 6606 6529 -1 
            6606 6481 6529 -1 
            6481 6606 6558 -1 
            6462 6510 6587 -1 
            6510 6635 6587 -1 
            6635 6510 6558 -1 
            6558 6510 6433 -1 
            6491 6414 6539 -1 
            6414 6462 6539 -1 
            6414 6491 6366 -1 
            6462 6414 6337 -1 
            6337 6414 6289 -1 
            6414 6366 6289 -1 
            6385 6462 6337 -1 
            6385 6308 6433 -1 
            6510 6385 6433 -1 
            6385 6510 6462 -1 
            6385 6337 6260 -1 
            6308 6385 6260 -1 
            7308 7385 7260 -1 
            7452 7327 7375 -1 
            7327 7452 7404 -1 
            6885 6933 7010 -1 
            6760 6885 6837 -1 
            7154 7106 7029 -1 
            7231 7106 7154 -1 
            6981 7058 6933 -1 
            6981 6933 6856 -1 
            7106 6981 7029 -1 
            6981 7106 7058 -1 
            6760 6712 6635 -1 
            6712 6664 6587 -1 
            6635 6712 6587 -1 
            6712 6760 6837 -1 
            6761 6684 6809 -1 
            6713 6761 6838 -1 
            6886 6761 6809 -1 
            6761 6886 6838 -1 
            6636 6713 6588 -1 
            6636 6761 6713 -1 
            6761 6636 6684 -1 
            6684 6732 6809 -1 
            6655 6732 6607 -1 
            6732 6684 6607 -1 
            6482 6530 6607 -1 
            6482 6357 6405 -1 
            6530 6482 6405 -1 
            7019 6942 7067 -1 
            7019 6894 6942 -1 
            7163 7038 7086 -1 
            7115 7038 7163 -1 
            7038 6961 7086 -1 
            7038 6913 6961 -1 
            7240 7288 7365 -1 
            7240 7115 7163 -1 
            7288 7240 7163 -1 
            7211 7259 7336 -1 
            7384 7259 7307 -1 
            7259 7384 7336 -1 
            7259 7182 7307 -1 
            7134 7211 7086 -1 
            7182 7134 7057 -1 
            7259 7134 7182 -1 
            7134 7259 7211 -1 
            7134 7009 7057 -1 
            7009 7134 7086 -1 
            7682 7730 7807 -1 
            7855 7730 7778 -1 
            7807 7730 7855 -1 
            7778 7730 7653 -1 
            7730 7605 7653 -1 
            7730 7682 7605 -1 
            7538 7663 7615 -1 
            7538 7586 7663 -1 
            7490 7538 7615 -1 
            7586 7538 7461 -1 
            7538 7413 7461 -1 
            7538 7490 7413 -1 
            7509 7557 7634 -1 
            7586 7509 7634 -1 
            7509 7586 7461 -1 
            7557 7509 7432 -1 
            7509 7384 7432 -1 
            7384 7509 7461 -1 
            8163 8115 8038 -1 
            8086 8163 8038 -1 
            8163 8086 8211 -1 
            8115 8163 8240 -1 
            8288 8163 8211 -1 
            8163 8288 8240 -1 
            7913 7865 7788 -1 
            7836 7913 7788 -1 
            7932 7855 7980 -1 
            7932 7807 7855 -1 
            7932 7884 7807 -1 
            8086 8009 8134 -1 
            7932 8009 7884 -1 
            8048 7971 8096 -1 
            7519 7596 7471 -1 
            7394 7519 7471 -1 
            7644 7567 7692 -1 
            7769 7644 7692 -1 
            7644 7519 7567 -1 
            7519 7644 7596 -1 
            6934 6982 7059 -1 
            7107 6982 7030 -1 
            6982 7107 7059 -1 
            6982 6905 7030 -1 
            6857 6934 6809 -1 
            6732 6857 6809 -1 
            6982 6857 6905 -1 
            6857 6982 6934 -1 
            6424 6501 6376 -1 
            6472 6424 6347 -1 
            6424 6299 6347 -1 
            6299 6424 6376 -1 
            6501 6549 6626 -1 
            6549 6424 6472 -1 
            6424 6549 6501 -1 
            6443 6520 6395 -1 
            6491 6443 6366 -1 
            6443 6491 6568 -1 
            6520 6443 6568 -1 
            6443 6318 6366 -1 
            6318 6443 6395 -1 
            6280 6328 6405 -1 
            6453 6328 6376 -1 
            6328 6453 6405 -1 
            6328 6251 6376 -1 
            6328 6203 6251 -1 
            6328 6280 6203 -1 
            7751 7703 7626 -1 
            7703 7578 7626 -1 
            7578 7703 7655 -1 
            7655 7703 7780 -1 
            7703 7828 7780 -1 
            7703 7751 7828 -1 
            7722 7799 7674 -1 
            7799 7751 7674 -1 
            7799 7924 7876 -1 
            7751 7799 7876 -1 
            7818 7866 7943 -1 
            7866 7818 7741 -1 
            7818 7693 7741 -1 
            7693 7818 7770 -1 
            6615 6692 6567 -1 
            6663 6615 6538 -1 
            6615 6663 6740 -1 
            6692 6615 6740 -1 
            6538 6615 6490 -1 
            6615 6567 6490 -1 
            6817 6894 6769 -1 
            6894 6817 6942 -1 
            6817 6692 6740 -1 
            6692 6817 6769 -1 
            5902 5825 5950 -1 
            5902 5979 5854 -1 
            5777 5902 5854 -1 
            5825 5902 5777 -1 
            5902 6027 5979 -1 
            6027 5902 5950 -1 
            5806 5729 5854 -1 
            5758 5806 5883 -1 
            5806 5758 5681 -1 
            5729 5806 5681 -1 
            5806 5931 5883 -1 
            5931 5806 5854 -1 
            8143 8220 8095 -1 
            8191 8143 8066 -1 
            8143 8095 8018 -1 
            8066 8143 8018 -1 
            8316 8239 8364 -1 
            8239 8316 8191 -1 
            8441 8316 8364 -1 
            8172 8047 8095 -1 
            8220 8172 8095 -1 
            8345 8470 8422 -1 
            6384 6509 6461 -1 
            6384 6432 6509 -1 
            6384 6461 6336 -1 
            6432 6384 6307 -1 
            6259 6384 6336 -1 
            6384 6259 6307 -1 
            6020 5895 5943 -1 
            5895 5818 5943 -1 
            5895 5847 5770 -1 
            5818 5895 5770 -1 
            5818 5866 5943 -1 
            5866 5991 5943 -1 
            5991 5866 5914 -1 
            5722 5645 5770 -1 
            5597 5645 5722 -1 
            5645 5520 5568 -1 
            5645 5597 5520 -1 
            5597 5674 5549 -1 
            5674 5597 5722 -1 
            5559 5607 5684 -1 
            5607 5732 5684 -1 
            5607 5655 5732 -1 
            5607 5559 5482 -1 
            5655 5703 5780 -1 
            5828 5703 5751 -1 
            5703 5828 5780 -1 
            5703 5655 5578 -1 
            5799 5847 5924 -1 
            5876 5799 5924 -1 
            5799 5876 5751 -1 
            5799 5722 5847 -1 
            5674 5799 5751 -1 
            5799 5674 5722 -1 
            7721 7798 7673 -1 
            7721 7644 7769 -1 
            7596 7721 7673 -1 
            7644 7721 7596 -1 
            8228 8305 8180 -1 
            8276 8228 8151 -1 
            8305 8228 8353 -1 
            8228 8276 8353 -1 
            8228 8103 8151 -1 
            8228 8180 8103 -1 
            5075 5152 5027 -1 
            5152 5104 5027 -1 
            4931 4806 4854 -1 
            4979 4931 4854 -1 
            3479 3527 3604 -1 
            3604 3527 3652 -1 
            3527 3575 3652 -1 
            3575 3527 3450 -1 
            3527 3402 3450 -1 
            3527 3479 3402 -1 
            5864 5787 5912 -1 
            5816 5864 5941 -1 
            5864 5816 5739 -1 
            5787 5864 5739 -1 
            5864 5989 5941 -1 
            5989 5864 5912 -1 
            5585 5633 5710 -1 
            5758 5633 5681 -1 
            5633 5758 5710 -1 
            5633 5556 5681 -1 
            5633 5508 5556 -1 
            5633 5585 5508 -1 
            5537 5662 5614 -1 
            5537 5585 5662 -1 
            5489 5537 5614 -1 
            5585 5537 5460 -1 
            5537 5412 5460 -1 
            5412 5537 5489 -1 
            6085 6008 6133 -1 
            6008 6056 6133 -1 
            6008 6085 5960 -1 
            6056 6008 5931 -1 
            6008 5960 5883 -1 
            5931 6008 5883 -1 
            6229 6181 6104 -1 
            6181 6056 6104 -1 
            6056 6181 6133 -1 
            6133 6181 6258 -1 
            6181 6306 6258 -1 
            6181 6229 6306 -1 
            6114 5989 6037 -1 
            6114 6066 5989 -1 
            6162 6114 6037 -1 
            6066 6114 6191 -1 
            6114 6162 6239 -1 
            6191 6114 6239 -1 
            5605 5528 5653 -1 
            5605 5480 5528 -1 
            5605 5653 5730 -1 
            5682 5605 5730 -1 
            4519 4644 4596 -1 
            4596 4644 4721 -1 
            4567 4490 4615 -1 
            4567 4644 4519 -1 
            4567 4519 4442 -1 
            4490 4567 4442 -1 
            4567 4615 4692 -1 
            4644 4567 4692 -1 
            4989 5066 4941 -1 
            5114 5066 4989 -1 
            5162 5114 5037 -1 
            5085 5162 5037 -1 
            5162 5085 5210 -1 
            5114 5162 5239 -1 
            5287 5162 5210 -1 
            5162 5287 5239 -1 
            4912 4787 4835 -1 
            4912 4864 4787 -1 
            4960 4912 4835 -1 
            4864 4912 4989 -1 
            5037 4912 4960 -1 
            4989 4912 5037 -1 
            4672 4749 4624 -1 
            4749 4701 4624 -1 
            4797 4749 4672 -1 
            4701 4749 4826 -1 
            4749 4874 4826 -1 
            4874 4749 4797 -1 
            5095 5047 4970 -1 
            4922 5047 4999 -1 
            4970 5047 4922 -1 
            5047 5124 4999 -1 
            5047 5095 5172 -1 
            5124 5047 5172 -1 
            8017 8094 7969 -1 
            8017 8142 8094 -1 
            8017 7892 7940 -1 
            8017 7969 7892 -1 
            7998 8123 8075 -1 
            7998 8046 8123 -1 
            6891 6766 6814 -1 
            6766 6689 6814 -1 
            6766 6641 6689 -1 
            6766 6718 6641 -1 
            6670 6795 6747 -1 
            6718 6795 6670 -1 
            6795 6872 6747 -1 
            6795 6920 6872 -1 
            7189 7141 7064 -1 
            7141 7189 7266 -1 
            7314 7189 7237 -1 
            7266 7189 7314 -1 
            7122 7045 7170 -1 
            7074 7122 7199 -1 
            7045 7122 6997 -1 
            7122 7074 6997 -1 
            7122 7170 7247 -1 
            7199 7122 7247 -1 
            6776 6728 6651 -1 
            6603 6728 6680 -1 
            6728 6603 6651 -1 
            6728 6805 6680 -1 
            6901 6853 6776 -1 
            6728 6853 6805 -1 
            6853 6728 6776 -1 
            6853 6930 6805 -1 
            6930 6853 6978 -1 
            6853 6901 6978 -1 
            6949 6824 6872 -1 
            6949 6901 6824 -1 
            6949 6872 6997 -1 
            6901 6949 7026 -1 
            7074 6949 6997 -1 
            6949 7074 7026 -1 
            7285 7208 7333 -1 
            7285 7362 7237 -1 
            7410 7285 7333 -1 
            7362 7285 7410 -1 
            7189 7112 7237 -1 
            7112 7189 7064 -1 
            7112 6987 7035 -1 
            7112 7064 6987 -1 
            7641 7689 7766 -1 
            7766 7689 7814 -1 
            7689 7737 7814 -1 
            7689 7612 7737 -1 
            7612 7689 7564 -1 
            7689 7641 7564 -1 
            7506 7631 7583 -1 
            7583 7631 7708 -1 
            7631 7756 7708 -1 
            7631 7679 7756 -1 
            7400 7448 7525 -1 
            7573 7448 7496 -1 
            7525 7448 7573 -1 
            7448 7371 7496 -1 
            7448 7323 7371 -1 
            7448 7400 7323 -1 
            4477 4400 4525 -1 
            4352 4400 4477 -1 
            4400 4275 4323 -1 
            4400 4352 4275 -1 
            4794 4842 4919 -1 
            4890 4842 4765 -1 
            4842 4717 4765 -1 
            4842 4794 4717 -1 
            4967 4890 5015 -1 
            4967 5044 4919 -1 
            4842 4967 4919 -1 
            4967 4842 4890 -1 
            4967 5092 5044 -1 
            5092 4967 5015 -1 
            3324 3247 3372 -1 
            3199 3247 3324 -1 
            3247 3122 3170 -1 
            3247 3199 3122 -1 
            3363 3411 3488 -1 
            3536 3411 3459 -1 
            3411 3536 3488 -1 
            3459 3411 3334 -1 
            3238 3315 3190 -1 
            3238 3363 3315 -1 
            3161 3238 3113 -1 
            3238 3190 3113 -1 
            5630 5678 5755 -1 
            5803 5678 5726 -1 
            5755 5678 5803 -1 
            5678 5601 5726 -1 
            5601 5678 5553 -1 
            5678 5630 5553 -1 
            5659 5611 5534 -1 
            5486 5611 5563 -1 
            5611 5486 5534 -1 
            5563 5611 5688 -1 
            5611 5736 5688 -1 
            5611 5659 5736 -1 
            5659 5707 5784 -1 
            5784 5707 5832 -1 
            5707 5755 5832 -1 
            5707 5630 5755 -1 
            5630 5707 5582 -1 
            5707 5659 5582 -1 
            5996 5871 5919 -1 
            5948 5871 5996 -1 
            5871 5948 5823 -1 
            5871 5794 5919 -1 
            5871 5823 5746 -1 
            5794 5871 5746 -1 
            5188 5111 5236 -1 
            5188 5313 5265 -1 
            5188 5236 5313 -1 
            4928 4803 4851 -1 
            4880 4803 4928 -1 
            4803 4880 4755 -1 
            4851 4803 4726 -1 
            4678 4803 4755 -1 
            4726 4803 4678 -1 
            5292 5417 5369 -1 
            5292 5340 5417 -1 
            5244 5292 5369 -1 
            5340 5292 5215 -1 
            5167 5292 5244 -1 
            5292 5167 5215 -1 
            5408 5360 5283 -1 
            5360 5235 5283 -1 
            5235 5360 5312 -1 
            5360 5437 5312 -1 
            5437 5360 5485 -1 
            5360 5408 5485 -1 
            5168 5091 5216 -1 
            5091 5139 5216 -1 
            5091 4966 5014 -1 
            5139 5091 5014 -1 
            4591 4543 4466 -1 
            4418 4543 4495 -1 
            4543 4418 4466 -1 
            4543 4620 4495 -1 
            4543 4668 4620 -1 
            4543 4591 4668 -1 
            4735 4610 4658 -1 
            4735 4687 4610 -1 
            4735 4658 4783 -1 
            4687 4735 4812 -1 
            4735 4783 4860 -1 
            4812 4735 4860 -1 
            7937 7985 8062 -1 
            7985 8110 8062 -1 
            8110 7985 8033 -1 
            7985 7908 8033 -1 
            7908 7985 7860 -1 
            7985 7937 7860 -1 
            8416 8339 8464 -1 
            8291 8339 8416 -1 
            8109 7984 8032 -1 
            8061 7984 8109 -1 
            7984 7907 8032 -1 
            7984 7859 7907 -1 
            8407 8359 8282 -1 
            8359 8234 8282 -1 
            8234 8359 8311 -1 
            8311 8359 8436 -1 
            8436 8359 8484 -1 
            8359 8407 8484 -1 
            8455 8378 8503 -1 
            8455 8503 8484 -1 
            8407 8455 8484 -1 
            8330 8407 8282 -1 
            8330 8253 8378 -1 
            8455 8330 8378 -1 
            8330 8455 8407 -1 
            8330 8282 8205 -1 
            8253 8330 8205 -1 
            7206 7254 7331 -1 
            7379 7254 7302 -1 
            7254 7379 7331 -1 
            7254 7177 7302 -1 
            7187 7110 7235 -1 
            7110 7187 7062 -1 
            7110 6985 7033 -1 
            7110 7062 6985 -1 
            7379 7456 7331 -1 
            7456 7408 7331 -1 
            7456 7379 7504 -1 
            7408 7456 7533 -1 
            7456 7504 7581 -1 
            7533 7456 7581 -1 
            7360 7485 7437 -1 
            7360 7408 7485 -1 
            7283 7360 7235 -1 
            7408 7360 7283 -1 
            7600 7648 7725 -1 
            7600 7552 7475 -1 
            7706 7658 7581 -1 
            7629 7706 7581 -1 
            7706 7629 7754 -1 
            7658 7706 7783 -1 
            7831 7706 7754 -1 
            7783 7706 7831 -1 
            7186 7263 7138 -1 
            7186 7138 7061 -1 
            7715 7638 7763 -1 
            7638 7715 7590 -1 
            7417 7494 7369 -1 
            7542 7494 7417 -1 
            7129 7052 7177 -1 
            7129 7004 7052 -1 
            7254 7129 7177 -1 
            7129 7254 7206 -1 
            6811 6686 6734 -1 
            6686 6609 6734 -1 
            6542 6590 6667 -1 
            6590 6715 6667 -1 
            6590 6542 6465 -1 
            6513 6590 6465 -1 
            6888 6763 6811 -1 
            6763 6686 6811 -1 
            7311 7388 7263 -1 
            7359 7311 7234 -1 
            7388 7311 7436 -1 
            7311 7359 7436 -1 
            7186 7311 7263 -1 
            7311 7186 7234 -1 
            7840 7763 7888 -1 
            7840 7715 7763 -1 
            7715 7840 7792 -1 
            7965 7840 7888 -1 
            7696 7773 7648 -1 
            8071 7994 8119 -1 
            8196 8071 8119 -1 
            7773 7898 7850 -1 
            8052 7975 8100 -1 
            7975 8023 8100 -1 
            7927 7975 8052 -1 
            7975 7898 8023 -1 
            7975 7927 7850 -1 
            7898 7975 7850 -1 
            8215 8292 8167 -1 
            8292 8244 8167 -1 
            8292 8215 8340 -1 
            8244 8292 8369 -1 
            8417 8292 8340 -1 
            8369 8292 8417 -1 
            6120 6168 6245 -1 
            6197 6120 6245 -1 
            6120 6197 6072 -1 
            6168 6120 6043 -1 
            5995 6120 6072 -1 
            6120 5995 6043 -1 
            6216 6293 6168 -1 
            6264 6216 6139 -1 
            6293 6216 6341 -1 
            6216 6264 6341 -1 
            6216 6091 6139 -1 
            6091 6216 6168 -1 
            5851 5899 5976 -1 
            5947 5899 5822 -1 
            5899 5774 5822 -1 
            5899 5851 5774 -1 
            6024 5947 6072 -1 
            5976 6024 6101 -1 
            5899 6024 5976 -1 
            6024 5899 5947 -1 
            6149 6024 6072 -1 
            6024 6149 6101 -1 
            6005 5928 6053 -1 
            6005 6082 5957 -1 
            5880 6005 5957 -1 
            5928 6005 5880 -1 
            6005 6130 6082 -1 
            6130 6005 6053 -1 
            6226 6274 6351 -1 
            6399 6274 6322 -1 
            6274 6399 6351 -1 
            6274 6197 6322 -1 
            6197 6274 6149 -1 
            6274 6226 6149 -1 
            6505 6582 6457 -1 
            6505 6630 6582 -1 
            6505 6457 6380 -1 
            6428 6505 6380 -1 
            6639 6562 6687 -1 
            6639 6716 6591 -1 
            6514 6639 6591 -1 
            6562 6639 6514 -1 
            6764 6639 6687 -1 
            6716 6639 6764 -1 
            6477 6525 6602 -1 
            6602 6525 6650 -1 
            6525 6573 6650 -1 
            6573 6525 6448 -1 
            6525 6400 6448 -1 
            6525 6477 6400 -1 
            6745 6822 6697 -1 
            6745 6668 6793 -1 
            6918 6870 6793 -1 
            6870 6745 6793 -1 
            6745 6870 6822 -1 
            6822 6870 6947 -1 
            6947 6870 6995 -1 
            6870 6918 6995 -1 
            6726 6851 6803 -1 
            6572 6620 6697 -1 
            6620 6745 6697 -1 
            6745 6620 6668 -1 
            6524 6601 6476 -1 
            6399 6524 6476 -1 
            6495 6620 6572 -1 
            6495 6370 6418 -1 
            3032 2955 3080 -1 
            2984 3032 3109 -1 
            3109 3032 3157 -1 
            3032 3080 3157 -1 
            2907 2782 2830 -1 
            2907 2859 2782 -1 
            2955 2907 2830 -1 
            2859 2907 2984 -1 
            2907 3032 2984 -1 
            3032 2907 2955 -1 
            1571 1648 1523 -1 
            1619 1571 1494 -1 
            1696 1571 1619 -1 
            1648 1571 1696 -1 
            1494 1571 1446 -1 
            1571 1523 1446 -1 
            3437 3389 3312 -1 
            3264 3389 3341 -1 
            3312 3389 3264 -1 
            3389 3466 3341 -1 
            3389 3514 3466 -1 
            3389 3437 3514 -1 
            3273 3148 3196 -1 
            3148 3071 3196 -1 
            3148 3023 3071 -1 
            3023 3148 3100 -1 
            3398 3321 3446 -1 
            3273 3321 3398 -1 
            3244 3321 3196 -1 
            3321 3273 3196 -1 
            3494 3571 3446 -1 
            3542 3494 3417 -1 
            3571 3494 3619 -1 
            3494 3542 3619 -1 
            2898 2850 2773 -1 
            2850 2725 2773 -1 
            2725 2850 2802 -1 
            2850 2927 2802 -1 
            2927 2850 2975 -1 
            2850 2898 2975 -1 
            3599 3551 3474 -1 
            3426 3551 3503 -1 
            3474 3551 3426 -1 
            3503 3551 3628 -1 
            3551 3676 3628 -1 
            3551 3599 3676 -1 
            8493 8445 8368 -1 
            8445 8320 8368 -1 
            8474 8445 8493 -1 
            8128 8051 8176 -1 
            8080 8128 8205 -1 
            8128 8253 8205 -1 
            8128 8176 8253 -1 
            8051 8099 8176 -1 
            8176 8099 8224 -1 
            8099 8147 8224 -1 
            7772 7820 7897 -1 
            7945 7820 7868 -1 
            7820 7945 7897 -1 
            7820 7743 7868 -1 
            7820 7695 7743 -1 
            7820 7772 7695 -1 
            8060 8137 8012 -1 
            8060 7983 8108 -1 
            8185 8060 8108 -1 
            8137 8060 8185 -1 
            7983 8060 7935 -1 
            8060 8012 7935 -1 
            7964 7839 7887 -1 
            8012 7964 7887 -1 
            8118 8041 8166 -1 
            7955 8003 8080 -1 
            8003 8128 8080 -1 
            8128 8003 8051 -1 
            8051 8003 7926 -1 
            7330 7378 7455 -1 
            7378 7503 7455 -1 
            7830 7878 7955 -1 
            8003 7878 7926 -1 
            7878 8003 7955 -1 
            7878 7830 7753 -1 
            7849 7801 7724 -1 
            7801 7676 7724 -1 
            7676 7801 7753 -1 
            7801 7878 7753 -1 
            7801 7849 7926 -1 
            7878 7801 7926 -1 
            7705 7657 7580 -1 
            7580 7657 7532 -1 
            7657 7609 7532 -1 
            7657 7734 7609 -1 
            7734 7657 7782 -1 
            7657 7705 7782 -1 
            7628 7676 7753 -1 
            7705 7628 7753 -1 
            7628 7705 7580 -1 
            7676 7628 7551 -1 
            7628 7503 7551 -1 
            7503 7628 7580 -1 
            7541 7666 7618 -1 
            7541 7589 7666 -1 
            7541 7618 7493 -1 
            7541 7464 7589 -1 
            7416 7541 7493 -1 
            7464 7541 7416 -1 
            1862 1910 1987 -1 
            1910 2035 1987 -1 
            1910 1958 2035 -1 
            1958 1910 1833 -1 
            1833 1910 1785 -1 
            1910 1862 1785 -1 
            1689 1737 1814 -1 
            1862 1737 1785 -1 
            1737 1862 1814 -1 
            1785 1737 1660 -1 
            1737 1612 1660 -1 
            1737 1689 1612 -1 
            1593 1641 1718 -1 
            1641 1766 1718 -1 
            1766 1641 1689 -1 
            1689 1641 1564 -1 
            1564 1641 1516 -1 
            1641 1593 1516 -1 
            1872 1949 1824 -1 
            1920 1872 1795 -1 
            1872 1920 1997 -1 
            1949 1872 1997 -1 
            1795 1872 1747 -1 
            1872 1824 1747 -1 
            1978 2103 2055 -1 
            1978 1853 1901 -1 
            1815 1690 1738 -1 
            1767 1690 1815 -1 
            1690 1767 1642 -1 
            1738 1690 1613 -1 
            1565 1690 1642 -1 
            1690 1565 1613 -1 
            1834 1911 1786 -1 
            1834 1959 1911 -1 
            1709 1834 1786 -1 
            1834 1709 1757 -1 
            2207 2255 2332 -1 
            2255 2207 2130 -1 
            2130 2207 2082 -1 
            2207 2159 2082 -1 
            2226 2303 2178 -1 
            2303 2255 2178 -1 
            2303 2226 2351 -1 
            2255 2303 2380 -1 
            2428 2303 2351 -1 
            2380 2303 2428 -1 
            2236 2188 2111 -1 
            2063 2188 2140 -1 
            2111 2188 2063 -1 
            2188 2265 2140 -1 
            2265 2188 2313 -1 
            2188 2236 2313 -1 
            871 794 919 -1 
            871 948 823 -1 
            746 871 823 -1 
            794 871 746 -1 
            948 871 996 -1 
            871 919 996 -1 
            506 429 554 -1 
            429 477 554 -1 
            381 429 506 -1 
            477 429 352 -1 
            429 304 352 -1 
            304 429 381 -1 
            1188 1140 1063 -1 
            1140 1188 1265 -1 
            1313 1188 1236 -1 
            1188 1313 1265 -1 
            967 919 842 -1 
            553 630 505 -1 
            601 553 476 -1 
            553 678 630 -1 
            678 553 601 -1 
            553 428 476 -1 
            428 553 505 -1 
            755 707 630 -1 
            630 707 582 -1 
            707 659 582 -1 
            832 707 755 -1 
            851 803 726 -1 
            1207 1082 1130 -1 
            1284 1207 1332 -1 
            1082 1034 957 -1 
            736 688 611 -1 
            659 736 611 -1 
            736 813 688 -1 
            372 247 295 -1 
            372 324 247 -1 
            372 497 449 -1 
            324 372 449 -1 
            503 378 426 -1 
            503 455 378 -1 
            503 426 551 -1 
            628 503 551 -1 
            580 503 628 -1 
            503 580 455 -1 
            580 628 705 -1 
            657 580 705 -1 
            407 532 484 -1 
            455 532 407 -1 
            580 532 455 -1 
            532 609 484 -1 
            609 532 657 -1 
            532 580 657 -1 
            244 292 369 -1 
            292 417 369 -1 
            417 292 340 -1 
            340 292 215 -1 
            292 167 215 -1 
            292 244 167 -1 
            61 13 32 -1 
            109 61 32 -1 
            119 42 167 -1 
            42 90 167 -1 
            42 119 71 -1 
            42 71 23 -1 
            13 42 23 -1 
            90 42 13 -1 
            90 138 215 -1 
            138 263 215 -1 
            138 90 13 -1 
            61 138 13 -1 
            533 408 456 -1 
            533 485 408 -1 
            533 456 581 -1 
            485 533 610 -1 
            658 533 581 -1 
            610 533 658 -1 
            1254 1206 1129 -1 
            1206 1081 1129 -1 
            1755 1678 1803 -1 
            1707 1755 1832 -1 
            1755 1880 1832 -1 
            1755 1803 1880 -1 
            1716 1639 1764 -1 
            1716 1668 1591 -1 
            1639 1716 1591 -1 
            1860 1735 1783 -1 
            2081 2033 1956 -1 
            2033 2158 2110 -1 
            2033 2081 2158 -1 
            1889 2014 1966 -1 
            1889 1937 2014 -1 
            1476 1399 1524 -1 
            1476 1553 1428 -1 
            1476 1428 1351 -1 
            1399 1476 1351 -1 
            1601 1476 1524 -1 
            1553 1476 1601 -1 
            1303 1226 1351 -1 
            1226 1303 1178 -1 
            1399 1322 1447 -1 
            1341 1466 1418 -1 
            1543 1466 1591 -1 
            1418 1466 1543 -1 
            900 852 775 -1 
            852 804 727 -1 
            775 852 727 -1 
            852 929 804 -1 
            929 852 977 -1 
            852 900 977 -1 
            1073 996 1121 -1 
            1025 1073 1150 -1 
            1073 948 996 -1 
            1073 1025 948 -1 
            1198 1073 1121 -1 
            1073 1198 1150 -1 
            1102 1054 977 -1 
            1025 1102 977 -1 
            1102 1025 1150 -1 
            1054 1102 1179 -1 
            1102 1227 1179 -1 
            1227 1102 1150 -1 
            1265 1390 1342 -1 
            1313 1390 1265 -1 
            1342 1390 1467 -1 
            1390 1515 1467 -1 
            803 928 880 -1 
            851 928 803 -1 
            1014 1091 966 -1 
            1091 1043 966 -1 
            1043 995 918 -1 
            1072 995 1120 -1 
            995 1043 1120 -1 
            1360 1283 1408 -1 
            1283 1360 1235 -1 
            1360 1485 1437 -1 
            1360 1408 1485 -1 
            1380 1303 1428 -1 
            1380 1457 1332 -1 
            1457 1380 1505 -1 
            1380 1428 1505 -1 
            1563 1486 1611 -1 
            1486 1534 1611 -1 
            1409 1486 1361 -1 
            1534 1486 1409 -1 
            1582 1707 1659 -1 
            1534 1582 1659 -1 
            1582 1457 1505 -1 
            1582 1534 1457 -1 
            745 620 668 -1 
            793 745 668 -1 
            1852 1900 1977 -1 
            2025 1900 1948 -1 
            1900 2025 1977 -1 
            1900 1823 1948 -1 
            1823 1900 1775 -1 
            1900 1852 1775 -1 
            515 467 390 -1 
            419 467 544 -1 
            467 515 592 -1 
            544 467 592 -1 
            342 419 294 -1 
            390 342 265 -1 
            467 342 390 -1 
            342 467 419 -1 
            342 217 265 -1 
            342 294 217 -1 
            361 409 486 -1 
            409 534 486 -1 
            534 409 457 -1 
            457 409 332 -1 
            409 284 332 -1 
            409 361 284 -1 
            313 438 390 -1 
            313 361 438 -1 
            313 390 265 -1 
            361 313 236 -1 
            188 313 265 -1 
            313 188 236 -1 
            1124 1172 1249 -1 
            1297 1172 1220 -1 
            1249 1172 1297 -1 
            1172 1095 1220 -1 
            1172 1047 1095 -1 
            1172 1124 1047 -1 
            1441 1364 1489 -1 
            1393 1441 1518 -1 
            1566 1441 1489 -1 
            1441 1566 1518 -1 
            1268 1345 1220 -1 
            1268 1393 1345 -1 
            1268 1143 1191 -1 
            1143 1268 1220 -1 
            1017 1094 969 -1 
            1017 1142 1094 -1 
            1017 969 892 -1 
            940 1017 892 -1 
            3178 3130 3053 -1 
            3130 3005 3053 -1 
            3130 3082 3005 -1 
            3082 3130 3207 -1 
            3207 3130 3255 -1 
            3130 3178 3255 -1 
            3120 2995 3043 -1 
            3120 3072 2995 -1 
            3168 3120 3043 -1 
            3245 3120 3168 -1 
            3072 3149 3024 -1 
            3149 3101 3024 -1 
            3226 3149 3274 -1 
            3101 3149 3226 -1 
            3418 3293 3341 -1 
            3293 3216 3341 -1 
            3216 3293 3168 -1 
            3293 3245 3168 -1 
            3775 3900 3852 -1 
            3775 3823 3900 -1 
            3775 3852 3727 -1 
            3775 3698 3823 -1 
            3650 3775 3727 -1 
            3698 3775 3650 -1 
            3842 3967 3919 -1 
            3794 3842 3919 -1 
            3563 3640 3515 -1 
            3611 3563 3486 -1 
            3563 3438 3486 -1 
            3438 3563 3515 -1 
            3736 3611 3659 -1 
            3861 3909 3986 -1 
            3736 3861 3813 -1 
            3909 3957 4034 -1 
            4082 3957 4005 -1 
            3957 4082 4034 -1 
            3957 3880 4005 -1 
            3957 3832 3880 -1 
            3957 3909 3832 -1 
            3160 3083 3208 -1 
            3083 3131 3208 -1 
            3035 3083 3160 -1 
            3131 3083 3006 -1 
            3083 2958 3006 -1 
            2958 3083 3035 -1 
            1190 1238 1315 -1 
            1238 1363 1315 -1 
            1238 1286 1363 -1 
            1286 1334 1411 -1 
            1334 1382 1459 -1 
            1411 1334 1459 -1 
            1382 1334 1257 -1 
            1334 1209 1257 -1 
            1334 1286 1209 -1 
            2776 2728 2651 -1 
            2728 2680 2603 -1 
            2651 2728 2603 -1 
            2680 2728 2805 -1 
            2728 2853 2805 -1 
            2728 2776 2853 -1 
            3010 3135 3087 -1 
            2962 3010 3087 -1 
            2933 3010 2885 -1 
            3010 2962 2885 -1 
            2837 2789 2712 -1 
            2789 2664 2712 -1 
            2664 2789 2741 -1 
            2818 2895 2770 -1 
            2818 2943 2895 -1 
            2693 2818 2770 -1 
            2818 2693 2741 -1 
            2730 2778 2855 -1 
            2778 2903 2855 -1 
            2778 2826 2903 -1 
            2826 2778 2701 -1 
            2701 2778 2653 -1 
            2778 2730 2653 -1 
            2759 2682 2807 -1 
            2682 2730 2807 -1 
            2682 2759 2634 -1 
            2557 2682 2634 -1 
            2586 2538 2461 -1 
            2586 2634 2711 -1 
            2538 2490 2413 -1 
            2490 2567 2442 -1 
            2663 2586 2711 -1 
            2586 2663 2538 -1 
            2788 2663 2711 -1 
            2663 2788 2740 -1 
            2356 2279 2404 -1 
            2308 2356 2433 -1 
            2231 2356 2308 -1 
            2356 2231 2279 -1 
            2356 2481 2433 -1 
            2481 2356 2404 -1 
            2625 2500 2548 -1 
            2500 2423 2548 -1 
            2375 2500 2452 -1 
            2500 2375 2423 -1 
            2317 2365 2442 -1 
            2365 2490 2442 -1 
            2490 2365 2413 -1 
            2365 2288 2413 -1 
            2365 2317 2240 -1 
            2288 2365 2240 -1 
            2077 1952 2000 -1 
            2000 1952 1875 -1 
            1827 1952 1904 -1 
            1952 1827 1875 -1 
            2135 2087 2010 -1 
            2087 1962 2010 -1 
            2087 2039 1962 -1 
            2039 2087 2164 -1 
            2087 2212 2164 -1 
            2087 2135 2212 -1 
            2605 2557 2480 -1 
            2528 2605 2480 -1 
            2605 2528 2653 -1 
            2605 2682 2557 -1 
            2730 2605 2653 -1 
            2682 2605 2730 -1 
            2355 2432 2307 -1 
            2432 2384 2307 -1 
            2432 2355 2480 -1 
            2557 2432 2480 -1 
            2163 2115 2038 -1 
            2115 2163 2240 -1 
            2163 2288 2240 -1 
            2288 2163 2211 -1 
            2134 2259 2211 -1 
            2134 2182 2259 -1 
            2134 2009 2057 -1 
            2182 2134 2057 -1 
            2849 2926 2801 -1 
            2897 2849 2772 -1 
            2849 2724 2772 -1 
            2724 2849 2801 -1 
            2849 2974 2926 -1 
            2974 2849 2897 -1 
            3051 2974 3099 -1 
            2926 2974 3051 -1 
            2945 2820 2868 -1 
            2945 2897 2820 -1 
            2993 2945 2868 -1 
            2945 2993 3070 -1 
            2666 2743 2618 -1 
            2791 2743 2666 -1 
            2820 2743 2868 -1 
            2743 2791 2868 -1 
            704 656 579 -1 
            656 531 579 -1 
            656 608 531 -1 
            608 656 733 -1 
            733 656 781 -1 
            656 704 781 -1 
            425 550 502 -1 
            550 425 473 -1 
            550 627 502 -1 
            627 550 675 -1 
            463 540 415 -1 
            463 386 511 -1 
            559 636 511 -1 
            636 559 684 -1 
            636 684 761 -1 
            713 636 761 -1 
            329 281 204 -1 
            156 281 233 -1 
            204 281 156 -1 
            281 358 233 -1 
            358 281 406 -1 
            281 329 406 -1 
            1348 1223 1271 -1 
            1146 1223 1098 -1 
            1271 1223 1146 -1 
            1329 1252 1377 -1 
            1281 1329 1406 -1 
            1204 1329 1281 -1 
            1329 1204 1252 -1 
            1454 1329 1377 -1 
            1406 1329 1454 -1 
            925 848 973 -1 
            848 925 800 -1 
            675 723 800 -1 
            723 848 800 -1 
            944 992 1069 -1 
            1117 992 1040 -1 
            1069 992 1117 -1 
            1030 1155 1107 -1 
            1078 1155 1030 -1 
            1155 1078 1203 -1 
            1155 1232 1107 -1 
            1232 1155 1280 -1 
            1155 1203 1280 -1 
            1251 1299 1376 -1 
            1424 1299 1347 -1 
            1299 1424 1376 -1 
            1299 1222 1347 -1 
            1299 1174 1222 -1 
            1299 1251 1174 -1 
            885 933 1010 -1 
            962 885 1010 -1 
            885 962 837 -1 
            933 885 808 -1 
            760 885 837 -1 
            885 760 808 -1 
            1029 981 904 -1 
            981 856 904 -1 
            856 981 933 -1 
            981 1058 933 -1 
            1058 981 1106 -1 
            981 1029 1106 -1 
            1231 1183 1106 -1 
            1183 1058 1106 -1 
            1058 1183 1135 -1 
            1183 1260 1135 -1 
            1260 1183 1308 -1 
            1183 1231 1308 -1 
            1993 1868 1916 -1 
            1993 1945 1868 -1 
            1176 1128 1051 -1 
            1128 1003 1051 -1 
            1128 1080 1003 -1 
            1080 1128 1205 -1 
            1205 1128 1253 -1 
            1128 1176 1253 -1 
            1799 1751 1674 -1 
            1924 1799 1847 -1 
            1684 1636 1559 -1 
            1607 1684 1559 -1 
            1684 1607 1732 -1 
            1636 1684 1761 -1 
            1809 1684 1732 -1 
            1684 1809 1761 -1 
            1434 1482 1559 -1 
            1607 1482 1530 -1 
            1482 1607 1559 -1 
            1530 1482 1405 -1 
            1405 1482 1357 -1 
            1482 1434 1357 -1 
            1365 1288 1413 -1 
            1365 1442 1317 -1 
            1240 1365 1317 -1 
            1288 1365 1240 -1 
            1490 1365 1413 -1 
            1365 1490 1442 -1 
            1567 1490 1615 -1 
            1519 1567 1644 -1 
            1442 1567 1519 -1 
            1490 1567 1442 -1 
            1567 1692 1644 -1 
            1567 1615 1692 -1 
            405 453 530 -1 
            453 578 530 -1 
            453 328 376 -1 
            453 405 328 -1 
            290 242 165 -1 
            367 290 415 -1 
            242 290 367 -1 
            69 117 194 -1 
            242 117 165 -1 
            117 242 194 -1 
            40 21 11 -1 
            40 69 21 -1 
            40 117 69 -1 
            117 40 165 -1 
            751 703 626 -1 
            578 703 655 -1 
            703 578 626 -1 
            703 780 655 -1 
            703 751 828 -1 
            780 703 828 -1 
            770 693 818 -1 
            645 693 770 -1 
            693 645 568 -1 
            953 905 828 -1 
            905 780 828 -1 
            905 953 1030 -1 
            1597 1674 1549 -1 
            1645 1597 1520 -1 
            1597 1722 1674 -1 
            1722 1597 1645 -1 
            1799 1722 1847 -1 
            1722 1799 1674 -1 
            1616 1693 1568 -1 
            1693 1645 1568 -1 
            1693 1616 1741 -1 
            1818 1693 1741 -1 
            1510 1462 1385 -1 
            1337 1462 1414 -1 
            1385 1462 1337 -1 
            1462 1539 1414 -1 
            1539 1462 1587 -1 
            1462 1510 1587 -1 
            1193 1270 1145 -1 
            1193 1116 1241 -1 
            1318 1193 1241 -1 
            1270 1193 1318 -1 
            1116 1193 1068 -1 
            1193 1145 1068 -1 
            1472 1424 1347 -1 
            1395 1472 1347 -1 
            1472 1395 1520 -1 
            1424 1472 1549 -1 
            1472 1597 1549 -1 
            1597 1472 1520 -1 
            1779 1702 1827 -1 
            1779 1856 1731 -1 
            1654 1779 1731 -1 
            1702 1779 1654 -1 
            1856 1779 1904 -1 
            1779 1827 1904 -1 
            4164 4039 4087 -1 
            4164 4116 4039 -1 
            4212 4164 4087 -1 
            4116 4164 4241 -1 
            4164 4289 4241 -1 
            4164 4212 4289 -1 
            4068 3991 4116 -1 
            4020 4068 4145 -1 
            3943 4068 4020 -1 
            3991 4068 3943 -1 
            4068 4193 4145 -1 
            4068 4116 4193 -1 
            3895 3818 3943 -1 
            3847 3895 3972 -1 
            3770 3895 3847 -1 
            3818 3895 3770 -1 
            3895 4020 3972 -1 
            3895 3943 4020 -1 
            4126 4078 4001 -1 
            3953 4078 4030 -1 
            4078 3953 4001 -1 
            4030 4078 4155 -1 
            4155 4078 4203 -1 
            4078 4126 4203 -1 
            4049 4097 4174 -1 
            4097 4145 4222 -1 
            4174 4097 4222 -1 
            4097 4020 4145 -1 
            4020 4097 3972 -1 
            4097 4049 3972 -1 
            3665 3617 3540 -1 
            3617 3492 3540 -1 
            3492 3617 3569 -1 
            3569 3617 3694 -1 
            3617 3742 3694 -1 
            3617 3665 3742 -1 
            4291 4214 4339 -1 
            4291 4166 4214 -1 
            4416 4291 4339 -1 
            4320 4445 4397 -1 
            4762 4839 4714 -1 
            4887 4839 4762 -1 
            4983 5031 5108 -1 
            5156 5031 5079 -1 
            5031 5156 5108 -1 
            5031 4954 5079 -1 
            4954 5031 4906 -1 
            5031 4983 4906 -1 
            5060 4983 5108 -1 
            5060 5137 5012 -1 
            4935 5060 5012 -1 
            4983 5060 4935 -1 
            5137 5060 5185 -1 
            5060 5108 5185 -1 
            5291 5166 5214 -1 
            5166 5291 5243 -1 
            5137 5089 5012 -1 
            5089 5137 5214 -1 
            5166 5089 5214 -1 
            5022 4945 5070 -1 
            4945 4993 5070 -1 
            4945 5022 4897 -1 
            4993 4945 4868 -1 
            4820 4945 4897 -1 
            4945 4820 4868 -1 
            4944 4896 4819 -1 
            4819 4896 4771 -1 
            4896 4848 4771 -1 
            4848 4896 4973 -1 
            4973 4896 5021 -1 
            4896 4944 5021 -1 
            4675 4627 4550 -1 
            4627 4502 4550 -1 
            4502 4627 4579 -1 
            4627 4704 4579 -1 
            4627 4752 4704 -1 
            4752 4627 4675 -1 
            4752 4877 4829 -1 
            4704 4752 4829 -1 
            4878 4926 5003 -1 
            4926 5051 5003 -1 
            5051 4926 4974 -1 
            4926 4849 4974 -1 
            4849 4926 4801 -1 
            4926 4878 4801 -1 
            5223 5271 5348 -1 
            5271 5319 5396 -1 
            5348 5271 5396 -1 
            5319 5271 5194 -1 
            5194 5271 5146 -1 
            5271 5223 5146 -1 
            5329 5377 5454 -1 
            5502 5377 5425 -1 
            5454 5377 5502 -1 
            5377 5300 5425 -1 
            5377 5252 5300 -1 
            5377 5329 5252 -1 
            5387 5339 5262 -1 
            5262 5339 5214 -1 
            5339 5291 5214 -1 
            5291 5339 5416 -1 
            5339 5464 5416 -1 
            5339 5387 5464 -1 
            5310 5358 5435 -1 
            5387 5310 5435 -1 
            5310 5387 5262 -1 
            5358 5310 5233 -1 
            5310 5262 5185 -1 
            5233 5310 5185 -1 
            4676 4551 4599 -1 
            4628 4551 4676 -1 
            4599 4551 4474 -1 
            4551 4426 4474 -1 
            4539 4491 4414 -1 
            4491 4366 4414 -1 
            4366 4491 4443 -1 
            4491 4568 4443 -1 
            4568 4491 4616 -1 
            4491 4539 4616 -1 
            6105 6230 6182 -1 
            6057 6105 6182 -1 
            6028 6105 5980 -1 
            6105 6057 5980 -1 
            5134 5259 5211 -1 
            5259 5336 5211 -1 
            5336 5259 5384 -1 
            5259 5307 5384 -1 
            4817 4692 4740 -1 
            4865 4817 4740 -1 
            4817 4865 4942 -1 
            4932 4980 5057 -1 
            5105 4980 5028 -1 
            4980 5105 5057 -1 
            4980 4903 5028 -1 
            4980 4855 4903 -1 
            4980 4932 4855 -1 
            4481 4433 4356 -1 
            4308 4433 4385 -1 
            4433 4308 4356 -1 
            4385 4433 4510 -1 
            4433 4558 4510 -1 
            4433 4481 4558 -1 
            4529 4404 4452 -1 
            4529 4481 4404 -1 
            4577 4529 4452 -1 
            4481 4529 4606 -1 
            4606 4529 4654 -1 
            4529 4577 4654 -1 
            4577 4500 4625 -1 
            4500 4548 4625 -1 
            4500 4577 4452 -1 
            4548 4500 4423 -1 
            4375 4500 4452 -1 
            4423 4500 4375 -1 
            6705 6580 6628 -1 
            6657 6580 6705 -1 
            6580 6657 6532 -1 
            6580 6503 6628 -1 
            6580 6532 6455 -1 
            6503 6580 6455 -1 
            5984 6032 6109 -1 
            6157 6032 6080 -1 
            6032 6157 6109 -1 
            6032 5955 6080 -1 
            6032 5907 5955 -1 
            6032 5984 5907 -1 
            6694 6617 6742 -1 
            6617 6665 6742 -1 
            6617 6694 6569 -1 
            6492 6617 6569 -1 
            6338 6290 6213 -1 
            6165 6290 6242 -1 
            6213 6290 6165 -1 
            6540 6463 6588 -1 
            6540 6617 6492 -1 
            6665 6540 6588 -1 
            6617 6540 6665 -1 
            6367 6319 6242 -1 
            6290 6367 6242 -1 
            6444 6569 6521 -1 
            6444 6492 6569 -1 
            6396 6444 6521 -1 
            6444 6367 6492 -1 
            6444 6396 6319 -1 
            6367 6444 6319 -1 
            5656 5608 5531 -1 
            5608 5483 5531 -1 
            5483 5608 5560 -1 
            5560 5608 5685 -1 
            5608 5733 5685 -1 
            5608 5656 5733 -1 
            5771 5896 5848 -1 
            5771 5819 5896 -1 
            5771 5848 5723 -1 
            5819 5771 5694 -1 
            5646 5771 5723 -1 
            5771 5646 5694 -1 
            7176 7099 7224 -1 
            7205 7253 7330 -1 
            7378 7253 7301 -1 
            7253 7378 7330 -1 
            7253 7176 7301 -1 
            7128 7205 7080 -1 
            7253 7128 7176 -1 
            7128 7253 7205 -1 
            7234 7157 7282 -1 
            7157 7205 7282 -1 
            7205 7157 7080 -1 
            6859 6811 6734 -1 
            6984 6859 6907 -1 
            6849 6801 6724 -1 
            6801 6676 6724 -1 
            6801 6753 6676 -1 
            7385 7433 7510 -1 
            7433 7558 7510 -1 
            7433 7481 7558 -1 
            7308 7433 7385 -1 
            6847 6972 6924 -1 
            7049 6972 7097 -1 
            6972 7049 6924 -1 
            7241 7164 7289 -1 
            7116 7164 7241 -1 
            6549 6674 6626 -1 
            6751 6674 6799 -1 
            6626 6674 6751 -1 
            6914 6789 6837 -1 
            6789 6712 6837 -1 
            6712 6789 6664 -1 
            7279 7327 7404 -1 
            7279 7231 7154 -1 
            7250 7125 7173 -1 
            7327 7250 7375 -1 
            6962 6885 7010 -1 
            6962 6914 6837 -1 
            6885 6962 6837 -1 
            6808 6885 6760 -1 
            6808 6731 6856 -1 
            6933 6808 6856 -1 
            6885 6808 6933 -1 
            6808 6760 6683 -1 
            6731 6808 6683 -1 
            7183 7106 7231 -1 
            7183 7260 7135 -1 
            7058 7183 7135 -1 
            7106 7183 7058 -1 
            7183 7308 7260 -1 
            7308 7183 7231 -1 
            6904 6981 6856 -1 
            6904 6827 6952 -1 
            6904 6952 7029 -1 
            6981 6904 7029 -1 
            6731 6779 6856 -1 
            6779 6904 6856 -1 
            6904 6779 6827 -1 
            6827 6779 6702 -1 
            6779 6654 6702 -1 
            6779 6731 6654 -1 
            6827 6875 6952 -1 
            6875 6798 6923 -1 
            6750 6827 6702 -1 
            6798 6750 6673 -1 
            6875 6750 6798 -1 
            6750 6875 6827 -1 
            6625 6750 6702 -1 
            6750 6625 6673 -1 
            6559 6482 6607 -1 
            6684 6559 6607 -1 
            6636 6559 6684 -1 
            6559 6434 6482 -1 
            6357 6434 6309 -1 
            6482 6434 6357 -1 
            7077 7154 7029 -1 
            6952 7077 7029 -1 
            7125 7048 7173 -1 
            7038 6990 6913 -1 
            6942 6990 7067 -1 
            6990 7115 7067 -1 
            6990 7038 7115 -1 
            8009 8057 8134 -1 
            8134 8057 8182 -1 
            8057 8105 8182 -1 
            8105 8057 7980 -1 
            8057 7932 7980 -1 
            8057 8009 7932 -1 
            7961 8086 8038 -1 
            7961 8009 8086 -1 
            7913 7961 8038 -1 
            8009 7961 7884 -1 
            7961 7836 7884 -1 
            7961 7913 7836 -1 
            7923 8048 8000 -1 
            7923 7971 8048 -1 
            7442 7519 7394 -1 
            7442 7365 7490 -1 
            7567 7442 7490 -1 
            7519 7442 7567 -1 
            6780 6857 6732 -1 
            6780 6703 6828 -1 
            6905 6780 6828 -1 
            6857 6780 6905 -1 
            6780 6655 6703 -1 
            6780 6732 6655 -1 
            7847 7722 7770 -1 
            7847 7799 7722 -1 
            7924 7847 7972 -1 
            7799 7847 7924 -1 
            6817 6865 6942 -1 
            6865 6990 6942 -1 
            6990 6865 6913 -1 
            6913 6865 6788 -1 
            6788 6865 6740 -1 
            6865 6817 6740 -1 
            5373 5325 5248 -1 
            5325 5373 5450 -1 
            5431 5354 5479 -1 
            5354 5431 5306 -1 
            8316 8268 8191 -1 
            8268 8143 8191 -1 
            8143 8268 8220 -1 
            8268 8345 8220 -1 
            8172 8124 8047 -1 
            7999 8124 8076 -1 
            8047 8124 7999 -1 
            8124 8201 8076 -1 
            8124 8249 8201 -1 
            8124 8172 8249 -1 
            8345 8297 8220 -1 
            8172 8297 8249 -1 
            8297 8172 8220 -1 
            8297 8374 8249 -1 
            8374 8297 8422 -1 
            8297 8345 8422 -1 
            8393 8316 8441 -1 
            8393 8441 8470 -1 
            8345 8393 8470 -1 
            8393 8268 8316 -1 
            8268 8393 8345 -1 
            5972 5895 6020 -1 
            5924 5972 6049 -1 
            5847 5972 5924 -1 
            5895 5972 5847 -1 
            5972 6097 6049 -1 
            5972 6020 6097 -1 
            5741 5866 5818 -1 
            5664 5741 5616 -1 
            5655 5530 5578 -1 
            5607 5530 5655 -1 
            5530 5607 5482 -1 
            5578 5530 5453 -1 
            5405 5530 5482 -1 
            5530 5405 5453 -1 
            5626 5703 5578 -1 
            5674 5626 5549 -1 
            5626 5674 5751 -1 
            5703 5626 5751 -1 
            5549 5626 5501 -1 
            5626 5578 5501 -1 
            5152 5200 5277 -1 
            5325 5200 5248 -1 
            5200 5325 5277 -1 
            5248 5200 5123 -1 
            5200 5075 5123 -1 
            5200 5152 5075 -1 
            5229 5152 5277 -1 
            5229 5306 5181 -1 
            5104 5229 5181 -1 
            5152 5229 5104 -1 
            5229 5354 5306 -1 
            5354 5229 5277 -1 
            4931 4883 4806 -1 
            4758 4883 4835 -1 
            4883 4758 4806 -1 
            4883 4960 4835 -1 
            5056 4979 5104 -1 
            5056 4931 4979 -1 
            5133 5056 5181 -1 
            5056 5104 5181 -1 
            5480 5557 5432 -1 
            5605 5557 5480 -1 
            5557 5682 5634 -1 
            5557 5605 5682 -1 
            5066 5018 4941 -1 
            5018 4893 4941 -1 
            4893 5018 4970 -1 
            5018 5095 4970 -1 
            5191 5114 5239 -1 
            5191 5066 5114 -1 
            5316 5191 5239 -1 
            5191 5316 5268 -1 
            8017 8065 8142 -1 
            8190 8065 8113 -1 
            8142 8065 8190 -1 
            8065 7988 8113 -1 
            7988 8065 7940 -1 
            8065 8017 7940 -1 
            7950 7902 7825 -1 
            7950 8027 7902 -1 
            8027 7950 8075 -1 
            7950 7998 8075 -1 
            7873 7950 7825 -1 
            7950 7873 7998 -1 
            7873 7748 7796 -1 
            7873 7825 7748 -1 
            8046 7921 7969 -1 
            7998 7921 8046 -1 
            7873 7921 7998 -1 
            7969 7921 7844 -1 
            7844 7921 7796 -1 
            7921 7873 7796 -1 
            6766 6843 6718 -1 
            6843 6795 6718 -1 
            6843 6766 6891 -1 
            6795 6843 6920 -1 
            6843 6891 6968 -1 
            6920 6843 6968 -1 
            7112 7160 7237 -1 
            7160 7285 7237 -1 
            7285 7160 7208 -1 
            7208 7160 7083 -1 
            7083 7160 7035 -1 
            7160 7112 7035 -1 
            7554 7631 7506 -1 
            7554 7477 7602 -1 
            7679 7554 7602 -1 
            7631 7554 7679 -1 
            7554 7429 7477 -1 
            7554 7506 7429 -1 
            4400 4448 4525 -1 
            4573 4448 4496 -1 
            4448 4573 4525 -1 
            4448 4371 4496 -1 
            4371 4448 4323 -1 
            4448 4400 4323 -1 
            3247 3295 3372 -1 
            3295 3420 3372 -1 
            3420 3295 3343 -1 
            3343 3295 3218 -1 
            3295 3170 3218 -1 
            3295 3247 3170 -1 
            3238 3286 3363 -1 
            3411 3286 3334 -1 
            3286 3411 3363 -1 
            3286 3209 3334 -1 
            3286 3161 3209 -1 
            3286 3238 3161 -1 
            5188 5063 5111 -1 
            5111 5063 4986 -1 
            5063 5015 4938 -1 
            4986 5063 4938 -1 
            5217 5140 5265 -1 
            5140 5188 5265 -1 
            5092 5140 5217 -1 
            5140 5063 5188 -1 
            5140 5092 5015 -1 
            5063 5140 5015 -1 
            5043 5168 5120 -1 
            5043 5091 5168 -1 
            5043 5120 4995 -1 
            5091 5043 4966 -1 
            4918 5043 4995 -1 
            5043 4918 4966 -1 
            8387 8435 8464 -1 
            8339 8387 8464 -1 
            8387 8310 8435 -1 
            8214 8291 8166 -1 
            8214 8339 8291 -1 
            7936 7984 8061 -1 
            7984 7936 7859 -1 
            7936 7888 7811 -1 
            7859 7936 7811 -1 
            7110 7158 7235 -1 
            7158 7283 7235 -1 
            7283 7158 7206 -1 
            7158 7110 7033 -1 
            7389 7312 7437 -1 
            7312 7360 7437 -1 
            7264 7312 7389 -1 
            7360 7312 7235 -1 
            7312 7187 7235 -1 
            7187 7312 7264 -1 
            7677 7600 7725 -1 
            7629 7677 7754 -1 
            7677 7629 7552 -1 
            7600 7677 7552 -1 
            7677 7802 7754 -1 
            7677 7725 7802 -1 
            7369 7446 7321 -1 
            7494 7446 7369 -1 
            7571 7446 7494 -1 
            7571 7696 7648 -1 
            7129 7081 7004 -1 
            6956 7081 7033 -1 
            7004 7081 6956 -1 
            7081 7158 7033 -1 
            7081 7129 7206 -1 
            7158 7081 7206 -1 
            6686 6561 6609 -1 
            6561 6484 6609 -1 
            6484 6561 6436 -1 
            6561 6513 6436 -1 
            6590 6638 6715 -1 
            6638 6763 6715 -1 
            6763 6638 6686 -1 
            6638 6561 6686 -1 
            6638 6590 6513 -1 
            6561 6638 6513 -1 
            6840 6763 6888 -1 
            6840 6917 6792 -1 
            6715 6840 6792 -1 
            6763 6840 6715 -1 
            6917 6840 6965 -1 
            6840 6888 6965 -1 
            7965 8013 8090 -1 
            8013 8061 8138 -1 
            8090 8013 8138 -1 
            8013 7936 8061 -1 
            8013 7965 7888 -1 
            7936 8013 7888 -1 
            8042 7965 8090 -1 
            7994 8042 8119 -1 
            8042 8167 8119 -1 
            8042 8090 8167 -1 
            7792 7869 7744 -1 
            8148 8071 8196 -1 
            8148 8225 8100 -1 
            8023 8148 8100 -1 
            8071 8148 8023 -1 
            8225 8148 8273 -1 
            8148 8196 8273 -1 
            7898 7946 8023 -1 
            8071 7946 7994 -1 
            7946 8071 8023 -1 
            7946 7869 7994 -1 
            6505 6553 6630 -1 
            6601 6553 6476 -1 
            6553 6428 6476 -1 
            6553 6505 6428 -1 
            6649 6726 6601 -1 
            6649 6572 6697 -1 
            6524 6649 6601 -1 
            6649 6524 6572 -1 
            6822 6774 6697 -1 
            6774 6649 6697 -1 
            6649 6774 6726 -1 
            6726 6774 6851 -1 
            6851 6774 6899 -1 
            6774 6822 6899 -1 
            6726 6678 6601 -1 
            6553 6678 6630 -1 
            6678 6553 6601 -1 
            6630 6678 6755 -1 
            6755 6678 6803 -1 
            6678 6726 6803 -1 
            6495 6543 6620 -1 
            6668 6543 6591 -1 
            6620 6543 6668 -1 
            6543 6466 6591 -1 
            6466 6543 6418 -1 
            6543 6495 6418 -1 
            6524 6447 6572 -1 
            6447 6495 6572 -1 
            6447 6524 6399 -1 
            6495 6447 6370 -1 
            6370 6447 6322 -1 
            6447 6399 6322 -1 
            3225 3148 3273 -1 
            3177 3225 3302 -1 
            3100 3225 3177 -1 
            3148 3225 3100 -1 
            3225 3350 3302 -1 
            3225 3273 3350 -1 
            3321 3369 3446 -1 
            3369 3494 3446 -1 
            3494 3369 3417 -1 
            3369 3292 3417 -1 
            3292 3369 3244 -1 
            3369 3321 3244 -1 
            8320 8397 8272 -1 
            8445 8397 8320 -1 
            8397 8445 8474 -1 
            8147 8022 8070 -1 
            8099 8022 8147 -1 
            8022 7945 8070 -1 
            7945 8022 7897 -1 
            8041 8089 8166 -1 
            8089 8214 8166 -1 
            8214 8089 8137 -1 
            8137 8089 8012 -1 
            8089 7964 8012 -1 
            8089 8041 7964 -1 
            7993 8118 8070 -1 
            7993 8041 8118 -1 
            7945 7993 8070 -1 
            7993 7945 7868 -1 
            7378 7426 7503 -1 
            7426 7474 7551 -1 
            7503 7426 7551 -1 
            7426 7349 7474 -1 
            7349 7426 7301 -1 
            7426 7378 7301 -1 
            2026 2151 2103 -1 
            1978 2026 2103 -1 
            1949 2026 1901 -1 
            2026 1978 1901 -1 
            1959 1882 2007 -1 
            1834 1882 1959 -1 
            1805 1882 1757 -1 
            1882 1834 1757 -1 
            2284 2207 2332 -1 
            2236 2284 2361 -1 
            2284 2236 2159 -1 
            2207 2284 2159 -1 
            2361 2284 2409 -1 
            2284 2332 2409 -1 
            890 938 1015 -1 
            967 890 1015 -1 
            890 967 842 -1 
            890 813 938 -1 
            1092 1015 1140 -1 
            1092 967 1015 -1 
            1169 1092 1217 -1 
            1092 1140 1217 -1 
            1207 1255 1332 -1 
            1255 1380 1332 -1 
            1380 1255 1303 -1 
            1303 1255 1178 -1 
            1178 1255 1130 -1 
            1255 1207 1130 -1 
            717 765 842 -1 
            765 890 842 -1 
            890 765 813 -1 
            813 765 688 -1 
            765 640 688 -1 
            765 717 640 -1 
            813 861 938 -1 
            736 861 813 -1 
            1207 1159 1082 -1 
            1159 1034 1082 -1 
            1159 1284 1236 -1 
            1159 1207 1284 -1 
            343 420 295 -1 
            420 372 295 -1 
            420 343 468 -1 
            372 420 497 -1 
            497 420 545 -1 
            420 468 545 -1 
            138 186 263 -1 
            263 186 311 -1 
            186 234 311 -1 
            234 186 109 -1 
            186 61 109 -1 
            186 138 61 -1 
            1158 1283 1235 -1 
            1283 1158 1206 -1 
            1081 1158 1033 -1 
            1206 1158 1081 -1 
            1283 1331 1408 -1 
            1456 1331 1379 -1 
            1331 1456 1408 -1 
            1379 1331 1254 -1 
            1331 1206 1254 -1 
            1331 1283 1206 -1 
            1831 1908 1783 -1 
            1908 1860 1783 -1 
            1908 1831 1956 -1 
            2033 1908 1956 -1 
            1860 1812 1735 -1 
            1812 1764 1687 -1 
            1735 1812 1687 -1 
            1812 1889 1764 -1 
            1812 1860 1937 -1 
            1889 1812 1937 -1 
            1918 1841 1966 -1 
            1841 1889 1966 -1 
            1841 1716 1764 -1 
            1889 1841 1764 -1 
            1101 1226 1178 -1 
            1322 1370 1447 -1 
            1447 1370 1495 -1 
            1370 1418 1495 -1 
            1370 1322 1245 -1 
            1274 1399 1351 -1 
            1274 1322 1399 -1 
            1226 1274 1351 -1 
            1389 1341 1264 -1 
            1389 1466 1341 -1 
            1466 1514 1591 -1 
            1514 1639 1591 -1 
            1639 1514 1562 -1 
            1562 1514 1437 -1 
            1514 1389 1437 -1 
            1389 1514 1466 -1 
            1005 957 880 -1 
            928 1005 880 -1 
            1082 1005 1130 -1 
            1005 1082 957 -1 
            1139 1014 1062 -1 
            1139 1091 1014 -1 
            1216 1139 1264 -1 
            1139 1216 1091 -1 
            1341 1216 1264 -1 
            947 995 1072 -1 
            1360 1312 1235 -1 
            1312 1389 1264 -1 
            1312 1360 1437 -1 
            1389 1312 1437 -1 
            1438 1563 1515 -1 
            1438 1486 1563 -1 
            1390 1438 1515 -1 
            1486 1438 1361 -1 
            1438 1313 1361 -1 
            1438 1390 1313 -1 
            1582 1630 1707 -1 
            1755 1630 1678 -1 
            1630 1755 1707 -1 
            1630 1553 1678 -1 
            1553 1630 1505 -1 
            1630 1582 1505 -1 
            620 697 572 -1 
            745 697 620 -1 
            822 697 745 -1 
            822 947 899 -1 
            1268 1316 1393 -1 
            1441 1316 1364 -1 
            1316 1441 1393 -1 
            1364 1316 1239 -1 
            1239 1316 1191 -1 
            1316 1268 1191 -1 
            1142 1065 1190 -1 
            1017 1065 1142 -1 
            1065 1017 940 -1 
            3120 3197 3072 -1 
            3197 3149 3072 -1 
            3197 3120 3245 -1 
            3149 3197 3274 -1 
            3197 3322 3274 -1 
            3197 3245 3322 -1 
            3370 3293 3418 -1 
            3322 3370 3447 -1 
            3245 3370 3322 -1 
            3293 3370 3245 -1 
            3370 3495 3447 -1 
            3370 3418 3495 -1 
            3967 3890 4015 -1 
            3842 3890 3967 -1 
            3717 3794 3669 -1 
            3717 3842 3794 -1 
            3592 3717 3669 -1 
            3640 3717 3592 -1 
            3707 3784 3659 -1 
            3784 3736 3659 -1 
            3832 3784 3707 -1 
            3784 3861 3736 -1 
            3909 3784 3832 -1 
            3861 3784 3909 -1 
            3688 3736 3813 -1 
            3736 3688 3611 -1 
            3688 3563 3611 -1 
            3563 3688 3640 -1 
            3010 3058 3135 -1 
            3183 3058 3106 -1 
            3135 3058 3183 -1 
            3058 2981 3106 -1 
            2981 3058 2933 -1 
            3058 3010 2933 -1 
            2962 2914 2837 -1 
            2914 2789 2837 -1 
            2991 2914 3039 -1 
            2914 2962 3039 -1 
            2663 2615 2538 -1 
            2490 2615 2567 -1 
            2615 2490 2538 -1 
            2567 2615 2692 -1 
            2692 2615 2740 -1 
            2615 2663 2740 -1 
            2577 2500 2625 -1 
            2577 2654 2529 -1 
            2577 2529 2452 -1 
            2500 2577 2452 -1 
            2654 2577 2702 -1 
            2577 2625 2702 -1 
            2029 1952 2077 -1 
            1981 2029 2106 -1 
            2029 1981 1904 -1 
            1952 2029 1904 -1 
            2029 2154 2106 -1 
            2029 2077 2154 -1 
            2509 2432 2557 -1 
            2509 2586 2461 -1 
            2384 2509 2461 -1 
            2432 2509 2384 -1 
            2509 2557 2634 -1 
            2586 2509 2634 -1 
            2163 2086 2211 -1 
            2086 2134 2211 -1 
            2086 2163 2038 -1 
            2134 2086 2009 -1 
            2086 2038 1961 -1 
            2009 2086 1961 -1 
            3022 2974 2897 -1 
            2945 3022 2897 -1 
            3022 2945 3070 -1 
            2974 3022 3099 -1 
            3022 3147 3099 -1 
            3147 3022 3070 -1 
            2743 2695 2618 -1 
            2570 2695 2647 -1 
            2695 2570 2618 -1 
            2695 2772 2647 -1 
            2695 2820 2772 -1 
            2695 2743 2820 -1 
            550 598 675 -1 
            598 723 675 -1 
            723 598 646 -1 
            646 598 521 -1 
            521 598 473 -1 
            598 550 473 -1 
            694 646 569 -1 
            694 742 819 -1 
            886 838 761 -1 
            838 713 761 -1 
            636 588 511 -1 
            588 463 511 -1 
            463 588 540 -1 
            588 665 540 -1 
            588 636 713 -1 
            665 588 713 -1 
            1156 1108 1031 -1 
            1156 1233 1108 -1 
            1233 1156 1281 -1 
            1156 1204 1281 -1 
            1300 1223 1348 -1 
            1252 1300 1377 -1 
            1300 1425 1377 -1 
            1425 1300 1348 -1 
            1175 1300 1252 -1 
            1300 1175 1223 -1 
            1175 1050 1098 -1 
            1223 1175 1098 -1 
            896 819 944 -1 
            848 896 973 -1 
            896 1021 973 -1 
            896 944 1021 -1 
            963 886 1011 -1 
            963 838 886 -1 
            963 1088 1040 -1 
            1088 963 1011 -1 
            963 915 838 -1 
            992 915 1040 -1 
            915 963 1040 -1 
            1964 2041 1916 -1 
            2041 1993 1916 -1 
            2089 2041 1964 -1 
            1993 2041 2118 -1 
            2041 2166 2118 -1 
            2041 2089 2166 -1 
            1945 2070 2022 -1 
            1993 2070 1945 -1 
            2070 1993 2118 -1 
            2022 2070 2147 -1 
            2195 2070 2118 -1 
            2070 2195 2147 -1 
            1876 1799 1924 -1 
            1828 1876 1953 -1 
            1751 1876 1828 -1 
            1799 1876 1751 -1 
            1876 2001 1953 -1 
            1876 1924 2001 -1 
            453 501 578 -1 
            626 501 549 -1 
            578 501 626 -1 
            501 424 549 -1 
            424 501 376 -1 
            501 453 376 -1 
            213 290 165 -1 
            261 213 136 -1 
            338 261 386 -1 
            290 338 415 -1 
            338 213 261 -1 
            213 338 290 -1 
            463 338 386 -1 
            338 463 415 -1 
            40 88 165 -1 
            213 88 136 -1 
            88 213 165 -1 
            136 88 11 -1 
            88 40 11 -1 
            693 741 818 -1 
            741 866 818 -1 
            866 741 789 -1 
            789 741 664 -1 
            616 693 568 -1 
            664 616 539 -1 
            741 616 664 -1 
            616 741 693 -1 
            616 568 491 -1 
            539 616 491 -1 
            905 857 780 -1 
            780 857 732 -1 
            857 809 732 -1 
            1770 1722 1645 -1 
            1693 1770 1645 -1 
            1770 1693 1818 -1 
            1722 1770 1847 -1 
            1770 1895 1847 -1 
            1770 1818 1895 -1 
            4320 4272 4195 -1 
            4147 4272 4224 -1 
            4195 4272 4147 -1 
            4224 4272 4349 -1 
            4349 4272 4397 -1 
            4272 4320 4397 -1 
            4243 4320 4195 -1 
            4291 4243 4166 -1 
            4166 4243 4118 -1 
            4243 4195 4118 -1 
            4320 4368 4445 -1 
            4368 4493 4445 -1 
            4368 4416 4493 -1 
            4368 4291 4416 -1 
            4368 4243 4291 -1 
            4243 4368 4320 -1 
            4839 4791 4714 -1 
            4666 4791 4743 -1 
            4791 4666 4714 -1 
            4791 4868 4743 -1 
            4916 4993 4868 -1 
            4791 4916 4868 -1 
            4916 4791 4839 -1 
            4993 5118 5070 -1 
            5118 5195 5070 -1 
            5195 5118 5243 -1 
            5118 5166 5243 -1 
            4800 4675 4723 -1 
            4800 4752 4675 -1 
            4800 4723 4848 -1 
            4752 4800 4877 -1 
            4877 4800 4925 -1 
            4800 4848 4925 -1 
            4532 4455 4580 -1 
            4455 4532 4407 -1 
            4455 4330 4378 -1 
            4455 4407 4330 -1 
            6105 6153 6230 -1 
            6230 6153 6278 -1 
            6153 6201 6278 -1 
            6201 6153 6076 -1 
            6153 6028 6076 -1 
            6153 6105 6028 -1 
            5182 5259 5134 -1 
            5182 5105 5230 -1 
            5307 5182 5230 -1 
            5259 5182 5307 -1 
            5182 5134 5057 -1 
            5105 5182 5057 -1 
            4769 4846 4721 -1 
            4817 4769 4692 -1 
            4644 4769 4721 -1 
            4769 4644 4692 -1 
            4894 4817 4942 -1 
            4894 4971 4846 -1 
            4769 4894 4846 -1 
            4894 4769 4817 -1 
            4894 4942 5019 -1 
            4971 4894 5019 -1 
            6367 6415 6492 -1 
            6540 6415 6463 -1 
            6415 6540 6492 -1 
            6415 6338 6463 -1 
            6415 6290 6338 -1 
            6415 6367 6290 -1 
            7099 7147 7224 -1 
            7272 7147 7195 -1 
            7147 7272 7224 -1 
            7147 7070 7195 -1 
            6974 6849 6897 -1 
            7051 7099 7176 -1 
            7128 7051 7176 -1 
            7051 6974 7099 -1 
            7186 7109 7234 -1 
            7109 7157 7234 -1 
            7109 7186 7061 -1 
            6984 7109 7061 -1 
            7013 6936 7061 -1 
            6936 6984 7061 -1 
            6888 6936 7013 -1 
            6984 6936 6859 -1 
            6936 6888 6811 -1 
            6859 6936 6811 -1 
            7356 7433 7308 -1 
            7356 7279 7404 -1 
            7481 7356 7404 -1 
            7433 7356 7481 -1 
            7356 7308 7231 -1 
            7279 7356 7231 -1 
            7116 7068 6991 -1 
            7068 7193 7145 -1 
            7068 7116 7193 -1 
            6895 6972 6847 -1 
            6770 6895 6847 -1 
            7164 7212 7289 -1 
            7212 7337 7289 -1 
            7212 7260 7337 -1 
            7260 7212 7135 -1 
            7039 7116 6991 -1 
            7039 7164 7116 -1 
            6914 7039 6991 -1 
            6962 7039 6914 -1 
            6770 6645 6693 -1 
            6693 6645 6568 -1 
            6645 6520 6568 -1 
            6789 6741 6664 -1 
            6741 6616 6664 -1 
            6616 6741 6693 -1 
            7250 7202 7125 -1 
            7077 7202 7154 -1 
            7202 7077 7125 -1 
            7202 7279 7154 -1 
            7279 7202 7327 -1 
            7202 7250 7327 -1 
            7298 7423 7375 -1 
            7250 7298 7375 -1 
            7298 7250 7173 -1 
            6338 6386 6463 -1 
            6434 6386 6309 -1 
            6309 6386 6261 -1 
            6386 6338 6261 -1 
            6511 6434 6559 -1 
            6463 6511 6588 -1 
            6386 6511 6463 -1 
            6511 6386 6434 -1 
            6511 6636 6588 -1 
            6511 6559 6636 -1 
            7077 7000 7125 -1 
            7000 7048 7125 -1 
            7000 7077 6952 -1 
            7048 7000 6923 -1 
            7000 6875 6923 -1 
            6875 7000 6952 -1 
            6971 7048 6923 -1 
            7019 6971 6894 -1 
            6971 6923 6846 -1 
            6894 6971 6846 -1 
            7269 7317 7394 -1 
            7442 7317 7365 -1 
            7317 7442 7394 -1 
            7317 7240 7365 -1 
            7144 7019 7067 -1 
            7952 7875 8000 -1 
            7875 7923 8000 -1 
            7827 7875 7952 -1 
            7923 7875 7798 -1 
            7875 7750 7798 -1 
            7875 7827 7750 -1 
            8019 7942 8067 -1 
            7971 8019 8096 -1 
            7894 8019 7971 -1 
            8019 7894 7942 -1 
            8096 8019 8144 -1 
            8019 8067 8144 -1 
            7942 7990 8067 -1 
            8115 7990 8038 -1 
            8067 7990 8115 -1 
            7990 7913 8038 -1 
            7913 7990 7865 -1 
            7990 7942 7865 -1 
            7818 7895 7770 -1 
            7895 7847 7770 -1 
            7895 7818 7943 -1 
            7847 7895 7972 -1 
            7895 8020 7972 -1 
            7895 7943 8020 -1 
            5325 5402 5277 -1 
            5402 5354 5277 -1 
            5402 5325 5450 -1 
            5354 5402 5479 -1 
            5402 5527 5479 -1 
            5527 5402 5450 -1 
            5741 5789 5866 -1 
            5914 5789 5837 -1 
            5866 5789 5914 -1 
            5837 5789 5712 -1 
            5789 5664 5712 -1 
            5789 5741 5664 -1 
            5693 5818 5770 -1 
            5693 5741 5818 -1 
            5645 5693 5770 -1 
            5741 5693 5616 -1 
            5693 5568 5616 -1 
            5693 5645 5568 -1 
            5056 5008 4931 -1 
            4883 5008 4960 -1 
            5008 4883 4931 -1 
            5008 5085 4960 -1 
            5085 5008 5133 -1 
            5008 5056 5133 -1 
            5557 5509 5432 -1 
            5509 5384 5432 -1 
            5509 5461 5384 -1 
            5461 5509 5586 -1 
            5586 5509 5634 -1 
            5509 5557 5634 -1 
            5191 5143 5066 -1 
            5018 5143 5095 -1 
            5143 5018 5066 -1 
            5095 5143 5220 -1 
            5220 5143 5268 -1 
            5143 5191 5268 -1 
            8214 8262 8339 -1 
            8387 8262 8310 -1 
            8262 8387 8339 -1 
            8262 8185 8310 -1 
            8262 8137 8185 -1 
            8262 8214 8137 -1 
            7446 7398 7321 -1 
            7398 7273 7321 -1 
            7398 7350 7273 -1 
            7350 7398 7475 -1 
            7571 7523 7446 -1 
            7398 7523 7475 -1 
            7523 7398 7446 -1 
            7523 7600 7475 -1 
            7600 7523 7648 -1 
            7523 7571 7648 -1 
            7667 7542 7590 -1 
            7715 7667 7590 -1 
            7667 7792 7744 -1 
            7667 7715 7792 -1 
            7869 7917 7994 -1 
            7917 8042 7994 -1 
            8042 7917 7965 -1 
            7965 7917 7840 -1 
            7840 7917 7792 -1 
            7917 7869 7792 -1 
            7821 7898 7773 -1 
            7869 7821 7744 -1 
            7821 7946 7898 -1 
            7946 7821 7869 -1 
            7821 7696 7744 -1 
            7696 7821 7773 -1 
            8397 8349 8272 -1 
            8272 8349 8224 -1 
            8349 8301 8224 -1 
            8301 8349 8426 -1 
            8349 8474 8426 -1 
            8349 8397 8474 -1 
            7974 8099 8051 -1 
            7974 8022 8099 -1 
            7974 8051 7926 -1 
            8022 7974 7897 -1 
            7849 7974 7926 -1 
            7974 7849 7897 -1 
            8041 7916 7964 -1 
            7993 7916 8041 -1 
            7916 7993 7868 -1 
            7964 7916 7839 -1 
            7916 7868 7791 -1 
            7839 7916 7791 -1 
            2026 2074 2151 -1 
            2199 2074 2122 -1 
            2151 2074 2199 -1 
            2074 1997 2122 -1 
            2074 1949 1997 -1 
            2074 2026 1949 -1 
            1882 1930 2007 -1 
            2007 1930 2055 -1 
            1930 1978 2055 -1 
            1978 1930 1853 -1 
            1930 1805 1853 -1 
            1930 1882 1805 -1 
            1092 1044 967 -1 
            919 1044 996 -1 
            967 1044 919 -1 
            996 1044 1121 -1 
            1044 1169 1121 -1 
            1044 1092 1169 -1 
            861 986 938 -1 
            938 986 1063 -1 
            909 832 957 -1 
            909 986 861 -1 
            1034 909 957 -1 
            986 909 1034 -1 
            784 861 736 -1 
            784 707 832 -1 
            909 784 832 -1 
            784 909 861 -1 
            707 784 659 -1 
            784 736 659 -1 
            1159 1111 1034 -1 
            986 1111 1063 -1 
            1111 986 1034 -1 
            1111 1188 1063 -1 
            1188 1111 1236 -1 
            1111 1159 1236 -1 
            1110 1158 1235 -1 
            1158 1110 1033 -1 
            985 1110 1062 -1 
            1110 985 1033 -1 
            1985 1908 2033 -1 
            1937 1985 2062 -1 
            1860 1985 1937 -1 
            1908 1985 1860 -1 
            2062 1985 2110 -1 
            1985 2033 2110 -1 
            1793 1918 1870 -1 
            1793 1841 1918 -1 
            1745 1793 1870 -1 
            1841 1793 1716 -1 
            1668 1793 1745 -1 
            1716 1793 1668 -1 
            947 1024 899 -1 
            1024 947 1072 -1 
            1149 1024 1072 -1 
            1024 1149 1101 -1 
            1101 1149 1226 -1 
            1149 1274 1226 -1 
            1053 1178 1130 -1 
            1053 1101 1178 -1 
            1005 1053 1130 -1 
            1053 1005 928 -1 
            1293 1341 1418 -1 
            1370 1293 1418 -1 
            1293 1370 1245 -1 
            1293 1216 1341 -1 
            1216 1168 1091 -1 
            1043 1168 1120 -1 
            1091 1168 1043 -1 
            1120 1168 1245 -1 
            1168 1293 1245 -1 
            1293 1168 1216 -1 
            697 649 572 -1 
            649 524 572 -1 
            524 649 601 -1 
            601 649 726 -1 
            870 745 793 -1 
            870 822 745 -1 
            870 793 918 -1 
            822 870 947 -1 
            995 870 918 -1 
            947 870 995 -1 
            822 774 697 -1 
            649 774 726 -1 
            774 649 697 -1 
            774 851 726 -1 
            851 774 899 -1 
            774 822 899 -1 
            1113 1238 1190 -1 
            1065 1113 1190 -1 
            3890 3938 4015 -1 
            3938 3986 4063 -1 
            4015 3938 4063 -1 
            3938 3861 3986 -1 
            3861 3938 3813 -1 
            3938 3890 3813 -1 
            3717 3765 3842 -1 
            3890 3765 3813 -1 
            3765 3890 3842 -1 
            3765 3688 3813 -1 
            3765 3717 3640 -1 
            3688 3765 3640 -1 
            2818 2866 2943 -1 
            2943 2866 2991 -1 
            2866 2914 2991 -1 
            2914 2866 2789 -1 
            2789 2866 2741 -1 
            2866 2818 2741 -1 
            617 694 569 -1 
            665 617 540 -1 
            617 665 742 -1 
            694 617 742 -1 
            492 617 569 -1 
            540 617 492 -1 
            771 694 819 -1 
            723 771 848 -1 
            771 723 646 -1 
            694 771 646 -1 
            896 771 819 -1 
            771 896 848 -1 
            838 790 713 -1 
            790 665 713 -1 
            665 790 742 -1 
            915 790 838 -1 
            1079 1156 1031 -1 
            1156 1079 1204 -1 
            954 1079 1031 -1 
            1079 954 1002 -1 
            809 934 886 -1 
            857 934 809 -1 
            886 934 1011 -1 
            934 1059 1011 -1 
            4964 4839 4887 -1 
            4964 4916 4839 -1 
            4964 4887 5012 -1 
            5089 4964 5012 -1 
            4455 4503 4580 -1 
            4503 4628 4580 -1 
            4503 4551 4628 -1 
            4551 4503 4426 -1 
            4426 4503 4378 -1 
            4503 4455 4378 -1 
            7032 6984 6907 -1 
            6955 7032 6907 -1 
            7032 6955 7080 -1 
            7032 7109 6984 -1 
            7157 7032 7080 -1 
            7109 7032 7157 -1 
            6830 6955 6907 -1 
            6830 6705 6753 -1 
            6974 7022 7099 -1 
            7147 7022 7070 -1 
            7022 7147 7099 -1 
            7070 7022 6945 -1 
            6945 7022 6897 -1 
            7022 6974 6897 -1 
            7051 6926 6974 -1 
            6926 6801 6849 -1 
            6974 6926 6849 -1 
            7003 7128 7080 -1 
            7003 7051 7128 -1 
            6955 7003 7080 -1 
            7003 6926 7051 -1 
            6895 7020 6972 -1 
            6972 7020 7097 -1 
            7020 7145 7097 -1 
            7020 7068 7145 -1 
            6818 6895 6770 -1 
            6818 6770 6693 -1 
            6741 6818 6693 -1 
            7039 7087 7164 -1 
            7212 7087 7135 -1 
            7087 7212 7164 -1 
            7087 7010 7135 -1 
            7087 6962 7010 -1 
            7087 7039 6962 -1 
            6597 6674 6549 -1 
            6645 6597 6520 -1 
            6520 6597 6472 -1 
            6597 6549 6472 -1 
            6722 6645 6770 -1 
            6674 6722 6799 -1 
            6597 6722 6674 -1 
            6722 6597 6645 -1 
            6722 6847 6799 -1 
            6722 6770 6847 -1 
            7298 7346 7423 -1 
            7423 7346 7471 -1 
            7346 7394 7471 -1 
            7346 7269 7394 -1 
            7221 7298 7173 -1 
            7221 7144 7269 -1 
            7346 7221 7269 -1 
            7221 7346 7298 -1 
            7144 7192 7269 -1 
            7317 7192 7240 -1 
            7192 7317 7269 -1 
            7240 7192 7115 -1 
            7115 7192 7067 -1 
            7192 7144 7067 -1 
            7048 7096 7173 -1 
            7096 7221 7173 -1 
            7221 7096 7144 -1 
            7144 7096 7019 -1 
            7096 6971 7019 -1 
            6971 7096 7048 -1 
            7923 7846 7971 -1 
            7846 7894 7971 -1 
            7846 7923 7798 -1 
            7894 7846 7769 -1 
            7721 7846 7798 -1 
            7846 7721 7769 -1 
            7942 7817 7865 -1 
            7894 7817 7942 -1 
            7817 7894 7769 -1 
            7817 7740 7865 -1 
            7740 7817 7692 -1 
            7817 7769 7692 -1 
            7571 7619 7696 -1 
            7696 7619 7744 -1 
            7619 7667 7744 -1 
            7667 7619 7542 -1 
            7619 7494 7542 -1 
            7619 7571 7494 -1 
            1187 1110 1235 -1 
            1139 1187 1264 -1 
            1187 1139 1062 -1 
            1110 1187 1062 -1 
            1312 1187 1235 -1 
            1187 1312 1264 -1 
            1149 1197 1274 -1 
            1322 1197 1245 -1 
            1274 1197 1322 -1 
            1197 1120 1245 -1 
            1197 1072 1120 -1 
            1197 1149 1072 -1 
            976 1024 1101 -1 
            1053 976 1101 -1 
            976 1053 928 -1 
            1024 976 899 -1 
            976 851 899 -1 
            976 928 851 -1 
            1113 1161 1238 -1 
            1286 1161 1209 -1 
            1238 1161 1286 -1 
            1209 1161 1084 -1 
            1161 1036 1084 -1 
            1036 1161 1113 -1 
            959 1036 911 -1 
            1084 1036 959 -1 
            867 790 915 -1 
            819 867 944 -1 
            742 867 819 -1 
            790 867 742 -1 
            867 992 944 -1 
            867 915 992 -1 
            1079 1127 1204 -1 
            1204 1127 1252 -1 
            1127 1175 1252 -1 
            1175 1127 1050 -1 
            1050 1127 1002 -1 
            1127 1079 1002 -1 
            982 857 905 -1 
            982 934 857 -1 
            982 905 1030 -1 
            934 982 1059 -1 
            1059 982 1107 -1 
            982 1030 1107 -1 
            4916 5041 4993 -1 
            4964 5041 4916 -1 
            5041 4964 5089 -1 
            5041 5118 4993 -1 
            5041 5089 5166 -1 
            5118 5041 5166 -1 
            6830 6782 6705 -1 
            6657 6782 6734 -1 
            6782 6657 6705 -1 
            6782 6859 6734 -1 
            6859 6782 6907 -1 
            6782 6830 6907 -1 
            6830 6878 6955 -1 
            6878 7003 6955 -1 
            7003 6878 6926 -1 
            6926 6878 6801 -1 
            6801 6878 6753 -1 
            6878 6830 6753 -1 
            6943 7020 6895 -1 
            6818 6943 6895 -1 
            7068 6943 6991 -1 
            7020 6943 7068 -1 
            988 1113 1065 -1 
            988 1036 1113 -1 
            988 1065 940 -1 
            1036 988 911 -1 
            988 863 911 -1 
            988 940 863 -1 
            6866 6943 6818 -1 
            6866 6789 6914 -1 
            6866 6914 6991 -1 
            6943 6866 6991 -1 
            6866 6741 6789 -1 
            6866 6818 6741 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0001"
              point="
              4.29915000000000000e+04 8.77741000000000058e+04 1.40681089999999995e+03,
              4.79915000000000000e+04 8.77741000000000058e+04 1.34490069999999992e+03,
              5.29915000000000000e+04 8.77741000000000058e+04 1.34734130000000005e+03,
              5.79915000000000000e+04 8.77741000000000058e+04 1.37174820000000000e+03,
              6.29915000000000000e+04 8.77741000000000058e+04 1.27991530000000012e+03,
              6.79915000000000000e+04 8.77741000000000058e+04 1.36084009999999989e+03,
              7.29915000000000000e+04 8.77741000000000058e+04 1.33809549999999990e+03,
              7.79915000000000000e+04 8.77741000000000058e+04 1.35228199999999993e+03,
              8.29915000000000000e+04 8.77741000000000058e+04 1.30395849999999996e+03,
              8.79915000000000000e+04 8.77741000000000058e+04 1.35783789999999999e+03,
              4.49415000000000000e+04 8.77241000000000058e+04 1.41252330000000006e+03,
              4.99415000000000000e+04 8.77241000000000058e+04 1.34847309999999993e+03,
              5.49415000000000000e+04 8.77241000000000058e+04 1.33021640000000002e+03,
              5.99415000000000000e+04 8.77241000000000058e+04 1.31575180000000000e+03,
              6.49415000000000000e+04 8.77241000000000058e+04 1.29006539999999995e+03,
              6.99415000000000000e+04 8.77241000000000058e+04 1.36337290000000007e+03,
              7.49415000000000000e+04 8.77241000000000058e+04 1.32868840000000000e+03,
              7.99415000000000000e+04 8.77241000000000058e+04 1.40345129999999995e+03,
              8.49415000000000000e+04 8.77241000000000058e+04 1.35856500000000005e+03,
              8.99415000000000000e+04 8.77241000000000058e+04 1.31622260000000006e+03,
              4.68915000000000000e+04 8.76741000000000058e+04 1.38612259999999992e+03,
              5.18915000000000000e+04 8.76741000000000058e+04 1.39375689999999986e+03,
              5.68915000000000000e+04 8.76741000000000058e+04 1.31778199999999993e+03,
              6.18915000000000000e+04 8.76741000000000058e+04 1.27357310000000007e+03,
              6.68915000000000000e+04 8.76741000000000058e+04 1.37615540000000010e+03,
              7.18915000000000000e+04 8.76741000000000058e+04 1.29068329999999992e+03,
              7.68915000000000000e+04 8.76741000000000058e+04 1.22294929999999999e+03,
              8.18915000000000000e+04 8.76741000000000058e+04 1.33180160000000001e+03,
              8.68915000000000000e+04 8.76741000000000058e+04 1.35381719999999996e+03,
              4.38415000000000000e+04 8.76241000000000058e+04 1.41337840000000006e+03,
              4.88415000000000000e+04 8.76241000000000058e+04 1.36388360000000011e+03,
              5.38415000000000000e+04 8.76241000000000058e+04 1.35359750000000008e+03,
              5.88415000000000000e+04 8.76241000000000058e+04 1.39064460000000008e+03,
              6.38415000000000000e+04 8.76241000000000058e+04 1.27320219999999995e+03,
              6.88415000000000000e+04 8.76241000000000058e+04 1.33486900000000014e+03,
              7.38415000000000000e+04 8.76241000000000058e+04 1.31029739999999993e+03,
              7.88415000000000000e+04 8.76241000000000058e+04 1.37208930000000009e+03,
              8.38415000000000000e+04 8.76241000000000058e+04 1.30765010000000007e+03,
              8.88415000000000000e+04 8.76241000000000058e+04 1.30413249999999994e+03,
              4.57915000000000000e+04 8.75741000000000058e+04 1.39188319999999999e+03,
              5.07915000000000000e+04 8.75741000000000058e+04 1.34450029999999992e+03,
              5.57915000000000000e+04 8.75741000000000058e+04 1.30484069999999997e+03,
              6.07915000000000000e+04 8.75741000000000058e+04 1.30885580000000004e+03,
              6.57915000000000000e+04 8.75741000000000058e+04 1.37878089999999997e+03,
              7.07915000000000000e+04 8.75741000000000058e+04 1.34777489999999989e+03,
              7.57915000000000000e+04 8.75741000000000058e+04 1.37740949999999998e+03,
              8.07915000000000000e+04 8.75741000000000058e+04 1.40742849999999999e+03,
              8.57915000000000000e+04 8.75741000000000058e+04 1.30388750000000005e+03,
              9.07915000000000000e+04 8.75741000000000058e+04 1.30380670000000009e+03,
              4.77415000000000000e+04 8.75241000000000058e+04 1.37128559999999993e+03,
              5.27415000000000000e+04 8.75241000000000058e+04 1.35733310000000006e+03,
              5.77415000000000000e+04 8.75241000000000058e+04 1.38919880000000012e+03,
              6.27415000000000000e+04 8.75241000000000058e+04 1.29604970000000003e+03,
              6.77415000000000000e+04 8.75241000000000058e+04 1.35986680000000001e+03,
              7.27415000000000000e+04 8.75241000000000058e+04 1.32514550000000008e+03,
              7.77415000000000000e+04 8.75241000000000058e+04 1.25022939999999994e+03,
              8.27415000000000000e+04 8.75241000000000058e+04 1.30660709999999995e+03,
              8.77415000000000000e+04 8.75241000000000058e+04 1.35297280000000001e+03,
              4.46915000000000000e+04 8.74741000000000058e+04 1.41043190000000004e+03,
              4.96915000000000000e+04 8.74741000000000058e+04 1.34252939999999990e+03,
              5.46915000000000000e+04 8.74741000000000058e+04 1.33462259999999992e+03,
              5.96915000000000000e+04 8.74741000000000058e+04 1.36367349999999988e+03,
              6.46915000000000000e+04 8.74741000000000058e+04 1.28360509999999999e+03,
              6.96915000000000000e+04 8.74741000000000058e+04 1.36901859999999988e+03,
              7.46915000000000000e+04 8.74741000000000058e+04 1.27462640000000010e+03,
              7.96915000000000000e+04 8.74741000000000058e+04 1.39747119999999995e+03,
              8.46915000000000000e+04 8.74741000000000058e+04 1.36574980000000005e+03,
              8.96915000000000000e+04 8.74741000000000058e+04 1.29082549999999992e+03,
              4.66415000000000000e+04 8.74241000000000058e+04 1.38740529999999990e+03,
              5.16415000000000000e+04 8.74241000000000058e+04 1.41795519999999988e+03,
              5.66415000000000000e+04 8.74241000000000058e+04 1.32543789999999990e+03,
              6.16415000000000000e+04 8.74241000000000058e+04 1.29101760000000013e+03,
              6.66415000000000000e+04 8.74241000000000058e+04 1.37645609999999988e+03,
              7.16415000000000000e+04 8.74241000000000058e+04 1.34146280000000002e+03,
              7.66415000000000000e+04 8.74241000000000058e+04 1.22127629999999999e+03,
              8.16415000000000000e+04 8.74241000000000058e+04 1.33255680000000007e+03,
              8.66415000000000000e+04 8.74241000000000058e+04 1.32059560000000010e+03,
              4.35915000000000000e+04 8.73741000000000058e+04 1.40819270000000006e+03,
              4.85915000000000000e+04 8.73741000000000058e+04 1.35911650000000009e+03,
              5.35915000000000000e+04 8.73741000000000058e+04 1.37449620000000004e+03,
              5.85915000000000000e+04 8.73741000000000058e+04 1.39160469999999987e+03,
              6.35915000000000000e+04 8.73741000000000058e+04 1.27303690000000006e+03,
              6.85915000000000000e+04 8.73741000000000058e+04 1.33239069999999992e+03,
              7.35915000000000000e+04 8.73741000000000058e+04 1.28181330000000003e+03,
              7.85915000000000000e+04 8.73741000000000058e+04 1.28803350000000000e+03,
              8.35915000000000000e+04 8.73741000000000058e+04 1.30329269999999997e+03,
              8.85915000000000000e+04 8.73741000000000058e+04 1.31601949999999988e+03,
              4.55415000000000000e+04 8.73241000000000058e+04 1.39466910000000007e+03,
              5.05415000000000000e+04 8.73241000000000058e+04 1.34147649999999999e+03,
              5.55415000000000000e+04 8.73241000000000058e+04 1.31760109999999986e+03,
              6.05415000000000000e+04 8.73241000000000058e+04 1.37692740000000003e+03,
              6.55415000000000000e+04 8.73241000000000058e+04 1.38288979999999992e+03,
              7.05415000000000000e+04 8.73241000000000058e+04 1.35354259999999999e+03,
              7.55415000000000000e+04 8.73241000000000058e+04 1.37062660000000005e+03,
              8.05415000000000000e+04 8.73241000000000058e+04 1.40812760000000003e+03,
              8.55415000000000000e+04 8.73241000000000058e+04 1.30271209999999996e+03,
              9.05415000000000000e+04 8.73241000000000058e+04 1.29463679999999999e+03,
              4.74915000000000000e+04 8.72741000000000058e+04 1.38341679999999997e+03,
              5.24915000000000000e+04 8.72741000000000058e+04 1.40829430000000002e+03,
              5.74915000000000000e+04 8.72741000000000058e+04 1.38938889999999992e+03,
              6.24915000000000000e+04 8.72741000000000058e+04 1.31767679999999996e+03,
              6.74915000000000000e+04 8.72741000000000058e+04 1.34871309999999994e+03,
              7.24915000000000000e+04 8.72741000000000058e+04 1.30718190000000004e+03,
              7.74915000000000000e+04 8.72741000000000058e+04 1.31959349999999995e+03,
              8.24915000000000000e+04 8.72741000000000058e+04 1.31400759999999991e+03,
              8.74915000000000000e+04 8.72741000000000058e+04 1.35287650000000008e+03,
              4.44415000000000000e+04 8.72241000000000058e+04 1.40684059999999999e+03,
              4.94415000000000000e+04 8.72241000000000058e+04 1.37671980000000008e+03,
              5.44415000000000000e+04 8.72241000000000058e+04 1.39024400000000014e+03,
              5.94415000000000000e+04 8.72241000000000058e+04 1.39691409999999996e+03,
              6.44415000000000000e+04 8.72241000000000058e+04 1.29021720000000005e+03,
              6.94415000000000000e+04 8.72241000000000058e+04 1.36140039999999999e+03,
              7.44415000000000000e+04 8.72241000000000058e+04 1.25816239999999993e+03,
              7.94415000000000000e+04 8.72241000000000058e+04 1.39790499999999997e+03,
              8.44415000000000000e+04 8.72241000000000058e+04 1.37120859999999993e+03,
              8.94415000000000000e+04 8.72241000000000058e+04 1.28985269999999991e+03,
              4.63915000000000000e+04 8.71741000000000058e+04 1.37586329999999998e+03,
              5.13915000000000000e+04 8.71741000000000058e+04 1.41883950000000004e+03,
              5.63915000000000000e+04 8.71741000000000058e+04 1.39112529999999992e+03,
              6.13915000000000000e+04 8.71741000000000058e+04 1.31107960000000003e+03,
              6.63915000000000000e+04 8.71741000000000058e+04 1.37163300000000004e+03,
              7.13915000000000000e+04 8.71741000000000058e+04 1.35862570000000005e+03,
              7.63915000000000000e+04 8.71741000000000058e+04 1.22995730000000003e+03,
              8.13915000000000000e+04 8.71741000000000058e+04 1.32574589999999989e+03,
              8.63915000000000000e+04 8.71741000000000058e+04 1.32170820000000003e+03,
              4.33415000000000000e+04 8.71241000000000058e+04 1.38766039999999998e+03,
              4.83415000000000000e+04 8.71241000000000058e+04 1.36126130000000012e+03,
              5.33415000000000000e+04 8.71241000000000058e+04 1.39411490000000003e+03,
              5.83415000000000000e+04 8.71241000000000058e+04 1.37921640000000002e+03,
              6.33415000000000000e+04 8.71241000000000058e+04 1.27590509999999995e+03,
              6.83415000000000000e+04 8.71241000000000058e+04 1.32679639999999995e+03,
              7.33415000000000000e+04 8.71241000000000058e+04 1.26571659999999997e+03,
              7.83415000000000000e+04 8.71241000000000058e+04 1.31437770000000000e+03,
              8.33415000000000000e+04 8.71241000000000058e+04 1.30656960000000004e+03,
              8.83415000000000000e+04 8.71241000000000058e+04 1.31450289999999995e+03,
              4.52915000000000000e+04 8.70741000000000058e+04 1.39130709999999999e+03,
              5.02915000000000000e+04 8.70741000000000058e+04 1.36283310000000006e+03,
              5.52915000000000000e+04 8.70741000000000058e+04 1.31715460000000007e+03,
              6.02915000000000000e+04 8.70741000000000058e+04 1.38042810000000009e+03,
              6.52915000000000000e+04 8.70741000000000058e+04 1.39985300000000007e+03,
              7.02915000000000000e+04 8.70741000000000058e+04 1.36074240000000009e+03,
              7.52915000000000000e+04 8.70741000000000058e+04 1.38698550000000000e+03,
              8.02915000000000000e+04 8.70741000000000058e+04 1.40137230000000000e+03,
              8.52915000000000000e+04 8.70741000000000058e+04 1.30879849999999988e+03,
              9.02915000000000000e+04 8.70741000000000058e+04 1.28596550000000002e+03,
              4.72415000000000000e+04 8.70241000000000058e+04 1.38910680000000002e+03,
              5.22415000000000000e+04 8.70241000000000058e+04 1.40109729999999990e+03,
              5.72415000000000000e+04 8.70241000000000058e+04 1.39093730000000005e+03,
              6.22415000000000000e+04 8.70241000000000058e+04 1.36437059999999997e+03,
              6.72415000000000000e+04 8.70241000000000058e+04 1.33901710000000003e+03,
              7.22415000000000000e+04 8.70241000000000058e+04 1.27363630000000012e+03,
              7.72415000000000000e+04 8.70241000000000058e+04 1.25699949999999990e+03,
              8.22415000000000000e+04 8.70241000000000058e+04 1.31621800000000007e+03,
              8.72415000000000000e+04 8.70241000000000058e+04 1.35154330000000004e+03,
              4.41915000000000000e+04 8.69741000000000058e+04 1.38814850000000001e+03,
              4.91915000000000000e+04 8.69741000000000058e+04 1.36877379999999994e+03,
              5.41915000000000000e+04 8.69741000000000058e+04 1.40507619999999997e+03,
              5.91915000000000000e+04 8.69741000000000058e+04 1.39163599999999997e+03,
              6.41915000000000000e+04 8.69741000000000058e+04 1.28477770000000010e+03,
              6.91915000000000000e+04 8.69741000000000058e+04 1.34522849999999994e+03,
              7.41915000000000000e+04 8.69741000000000058e+04 1.29167769999999996e+03,
              7.91915000000000000e+04 8.69741000000000058e+04 1.35566290000000004e+03,
              8.41915000000000000e+04 8.69741000000000058e+04 1.36308750000000009e+03,
              8.91915000000000000e+04 8.69741000000000058e+04 1.30409330000000000e+03,
              4.61415000000000000e+04 8.69241000000000058e+04 1.38218890000000010e+03,
              5.11415000000000000e+04 8.69241000000000058e+04 1.42825109999999995e+03,
              5.61415000000000000e+04 8.69241000000000058e+04 1.36048029999999994e+03,
              6.11415000000000000e+04 8.69241000000000058e+04 1.32854639999999995e+03,
              6.61415000000000000e+04 8.69241000000000058e+04 1.35946219999999994e+03,
              7.11415000000000000e+04 8.69241000000000058e+04 1.34857459999999992e+03,
              7.61415000000000000e+04 8.69241000000000058e+04 1.32971610000000010e+03,
              8.11415000000000000e+04 8.69241000000000058e+04 1.33379739999999993e+03,
              8.61415000000000000e+04 8.69241000000000058e+04 1.30425700000000006e+03,
              4.30915000000000000e+04 8.68741000000000058e+04 1.37964609999999993e+03,
              4.80915000000000000e+04 8.68741000000000058e+04 1.37518730000000005e+03,
              5.30915000000000000e+04 8.68741000000000058e+04 1.39693010000000004e+03,
              5.80915000000000000e+04 8.68741000000000058e+04 1.37037390000000005e+03,
              6.30915000000000000e+04 8.68741000000000058e+04 1.32748540000000003e+03,
              6.80915000000000000e+04 8.68741000000000058e+04 1.31818519999999990e+03,
              7.30915000000000000e+04 8.68741000000000058e+04 1.31132790000000000e+03,
              7.80915000000000000e+04 8.68741000000000058e+04 1.26881070000000000e+03,
              8.30915000000000000e+04 8.68741000000000058e+04 1.31103140000000008e+03,
              8.80915000000000000e+04 8.68741000000000058e+04 1.30898890000000006e+03,
              4.50415000000000000e+04 8.68241000000000058e+04 1.38356870000000004e+03,
              5.00415000000000000e+04 8.68241000000000058e+04 1.40725530000000003e+03,
              5.50415000000000000e+04 8.68241000000000058e+04 1.37849279999999999e+03,
              6.00415000000000000e+04 8.68241000000000058e+04 1.35177150000000006e+03,
              6.50415000000000000e+04 8.68241000000000058e+04 1.38137660000000005e+03,
              7.00415000000000000e+04 8.68241000000000058e+04 1.34897620000000006e+03,
              7.50415000000000000e+04 8.68241000000000058e+04 1.37319170000000008e+03,
              8.00415000000000000e+04 8.68241000000000058e+04 1.40548540000000003e+03,
              8.50415000000000000e+04 8.68241000000000058e+04 1.33170020000000000e+03,
              9.00415000000000000e+04 8.68241000000000058e+04 1.28492930000000001e+03,
              4.69915000000000000e+04 8.67741000000000058e+04 1.37831709999999998e+03,
              5.19915000000000000e+04 8.67741000000000058e+04 1.38798209999999995e+03,
              5.69915000000000000e+04 8.67741000000000058e+04 1.39236840000000007e+03,
              6.19915000000000000e+04 8.67741000000000058e+04 1.37448880000000008e+03,
              6.69915000000000000e+04 8.67741000000000058e+04 1.33692509999999993e+03,
              7.19915000000000000e+04 8.67741000000000058e+04 1.25782770000000005e+03,
              7.69915000000000000e+04 8.67741000000000058e+04 1.22103929999999991e+03,
              8.19915000000000000e+04 8.67741000000000058e+04 1.31406140000000005e+03,
              8.69915000000000000e+04 8.67741000000000058e+04 1.34163920000000007e+03,
              4.39415000000000000e+04 8.67241000000000058e+04 1.38002070000000003e+03,
              4.89415000000000000e+04 8.67241000000000058e+04 1.37038619999999992e+03,
              5.39415000000000000e+04 8.67241000000000058e+04 1.40017499999999995e+03,
              5.89415000000000000e+04 8.67241000000000058e+04 1.38565059999999994e+03,
              6.39415000000000000e+04 8.67241000000000058e+04 1.29474319999999989e+03,
              6.89415000000000000e+04 8.67241000000000058e+04 1.33742319999999995e+03,
              7.39415000000000000e+04 8.67241000000000058e+04 1.33723860000000013e+03,
              7.89415000000000000e+04 8.67241000000000058e+04 1.35911410000000001e+03,
              8.39415000000000000e+04 8.67241000000000058e+04 1.33914710000000014e+03,
              8.89415000000000000e+04 8.67241000000000058e+04 1.30204309999999987e+03,
              4.58915000000000000e+04 8.66741000000000058e+04 1.38573849999999993e+03,
              5.08915000000000000e+04 8.66741000000000058e+04 1.42293650000000002e+03,
              5.58915000000000000e+04 8.66741000000000058e+04 1.36025649999999996e+03,
              6.08915000000000000e+04 8.66741000000000058e+04 1.30917689999999993e+03,
              6.58915000000000000e+04 8.66741000000000058e+04 1.36057680000000005e+03,
              7.08915000000000000e+04 8.66741000000000058e+04 1.33820710000000008e+03,
              7.58915000000000000e+04 8.66741000000000058e+04 1.31865079999999989e+03,
              8.08915000000000000e+04 8.66741000000000058e+04 1.37821070000000009e+03,
              8.58915000000000000e+04 8.66741000000000058e+04 1.30721540000000005e+03,
              9.08915000000000000e+04 8.66741000000000058e+04 1.33371830000000000e+03,
              4.78415000000000000e+04 8.66241000000000058e+04 1.36548820000000001e+03,
              5.28415000000000000e+04 8.66241000000000058e+04 1.40204459999999995e+03,
              5.78415000000000000e+04 8.66241000000000058e+04 1.35287049999999999e+03,
              6.28415000000000000e+04 8.66241000000000058e+04 1.36878430000000003e+03,
              6.78415000000000000e+04 8.66241000000000058e+04 1.30953700000000003e+03,
              7.28415000000000000e+04 8.66241000000000058e+04 1.31915219999999999e+03,
              7.78415000000000000e+04 8.66241000000000058e+04 1.33396180000000004e+03,
              8.28415000000000000e+04 8.66241000000000058e+04 1.30697309999999993e+03,
              8.78415000000000000e+04 8.66241000000000058e+04 1.30595039999999995e+03,
              4.47915000000000000e+04 8.65741000000000058e+04 1.38852600000000007e+03,
              4.97915000000000000e+04 8.65741000000000058e+04 1.38994700000000012e+03,
              5.47915000000000000e+04 8.65741000000000058e+04 1.37714339999999993e+03,
              5.97915000000000000e+04 8.65741000000000058e+04 1.39979809999999998e+03,
              6.47915000000000000e+04 8.65741000000000058e+04 1.38045890000000009e+03,
              6.97915000000000000e+04 8.65741000000000058e+04 1.34601639999999998e+03,
              7.47915000000000000e+04 8.65741000000000058e+04 1.25252129999999988e+03,
              7.97915000000000000e+04 8.65741000000000058e+04 1.40919059999999990e+03,
              8.47915000000000000e+04 8.65741000000000058e+04 1.34807400000000007e+03,
              8.97915000000000000e+04 8.65741000000000058e+04 1.29227719999999999e+03,
              4.67415000000000000e+04 8.65241000000000058e+04 1.36879019999999991e+03,
              5.17415000000000000e+04 8.65241000000000058e+04 1.40235390000000007e+03,
              5.67415000000000000e+04 8.65241000000000058e+04 1.37932459999999992e+03,
              6.17415000000000000e+04 8.65241000000000058e+04 1.40254019999999991e+03,
              6.67415000000000000e+04 8.65241000000000058e+04 1.34390910000000008e+03,
              7.17415000000000000e+04 8.65241000000000058e+04 1.31207650000000012e+03,
              7.67415000000000000e+04 8.65241000000000058e+04 1.22245990000000006e+03,
              8.17415000000000000e+04 8.65241000000000058e+04 1.31720249999999987e+03,
              8.67415000000000000e+04 8.65241000000000058e+04 1.34658850000000007e+03,
              4.36915000000000000e+04 8.64741000000000058e+04 1.38583969999999999e+03,
              4.86915000000000000e+04 8.64741000000000058e+04 1.37769370000000004e+03,
              5.36915000000000000e+04 8.64741000000000058e+04 1.40910069999999996e+03,
              5.86915000000000000e+04 8.64741000000000058e+04 1.39220679999999993e+03,
              6.36915000000000000e+04 8.64741000000000058e+04 1.28439139999999998e+03,
              6.86915000000000000e+04 8.64741000000000058e+04 1.33292529999999988e+03,
              7.36915000000000000e+04 8.64741000000000058e+04 1.34491660000000002e+03,
              7.86915000000000000e+04 8.64741000000000058e+04 1.35638249999999994e+03,
              8.36915000000000000e+04 8.64741000000000058e+04 1.31212439999999992e+03,
              8.86915000000000000e+04 8.64741000000000058e+04 1.30216830000000004e+03,
              4.56415000000000000e+04 8.64241000000000058e+04 1.38699700000000007e+03,
              5.06415000000000000e+04 8.64241000000000058e+04 1.41645650000000001e+03,
              5.56415000000000000e+04 8.64241000000000058e+04 1.38405299999999988e+03,
              6.06415000000000000e+04 8.64241000000000058e+04 1.37207259999999997e+03,
              6.56415000000000000e+04 8.64241000000000058e+04 1.37467720000000008e+03,
              7.06415000000000000e+04 8.64241000000000058e+04 1.34155670000000009e+03,
              7.56415000000000000e+04 8.64241000000000058e+04 1.37708609999999999e+03,
              8.06415000000000000e+04 8.64241000000000058e+04 1.36290439999999990e+03,
              8.56415000000000000e+04 8.64241000000000058e+04 1.31910680000000002e+03,
              9.06415000000000000e+04 8.64241000000000058e+04 1.31564879999999994e+03,
              4.75915000000000000e+04 8.63741000000000058e+04 1.37051330000000007e+03,
              5.25915000000000000e+04 8.63741000000000058e+04 1.39712370000000010e+03,
              5.75915000000000000e+04 8.63741000000000058e+04 1.35939609999999993e+03,
              6.25915000000000000e+04 8.63741000000000058e+04 1.33991560000000004e+03,
              6.75915000000000000e+04 8.63741000000000058e+04 1.31457240000000002e+03,
              7.25915000000000000e+04 8.63741000000000058e+04 1.27230739999999992e+03,
              7.75915000000000000e+04 8.63741000000000058e+04 1.34400420000000008e+03,
              8.25915000000000000e+04 8.63741000000000058e+04 1.31125779999999986e+03,
              8.75915000000000000e+04 8.63741000000000058e+04 1.30561900000000014e+03,
              4.45415000000000000e+04 8.63241000000000058e+04 1.38807209999999986e+03,
              4.95415000000000000e+04 8.63241000000000058e+04 1.37878659999999991e+03,
              5.45415000000000000e+04 8.63241000000000058e+04 1.39769299999999998e+03,
              5.95415000000000000e+04 8.63241000000000058e+04 1.40336339999999996e+03,
              6.45415000000000000e+04 8.63241000000000058e+04 1.37630839999999989e+03,
              6.95415000000000000e+04 8.63241000000000058e+04 1.36646270000000004e+03,
              7.45415000000000000e+04 8.63241000000000058e+04 1.23240730000000008e+03,
              7.95415000000000000e+04 8.63241000000000058e+04 1.38248880000000008e+03,
              8.45415000000000000e+04 8.63241000000000058e+04 1.37443209999999999e+03,
              8.95415000000000000e+04 8.63241000000000058e+04 1.27765039999999999e+03,
              4.64915000000000000e+04 8.62741000000000058e+04 1.37755829999999992e+03,
              5.14915000000000000e+04 8.62741000000000058e+04 1.39383979999999997e+03,
              5.64915000000000000e+04 8.62741000000000058e+04 1.33938319999999999e+03,
              6.14915000000000000e+04 8.62741000000000058e+04 1.40683199999999988e+03,
              6.64915000000000000e+04 8.62741000000000058e+04 1.32939499999999998e+03,
              7.14915000000000000e+04 8.62741000000000058e+04 1.33920290000000000e+03,
              7.64915000000000000e+04 8.62741000000000058e+04 1.25520070000000010e+03,
              8.14915000000000000e+04 8.62741000000000058e+04 1.32735690000000000e+03,
              8.64915000000000000e+04 8.62741000000000058e+04 1.33327099999999996e+03,
              4.34415000000000000e+04 8.62241000000000058e+04 1.38713750000000005e+03,
              4.84415000000000000e+04 8.62241000000000058e+04 1.35923419999999987e+03,
              5.34415000000000000e+04 8.62241000000000058e+04 1.39850720000000001e+03,
              5.84415000000000000e+04 8.62241000000000058e+04 1.37786789999999996e+03,
              6.34415000000000000e+04 8.62241000000000058e+04 1.31191640000000007e+03,
              6.84415000000000000e+04 8.62241000000000058e+04 1.33095450000000005e+03,
              7.34415000000000000e+04 8.62241000000000058e+04 1.33619720000000007e+03,
              7.84415000000000000e+04 8.62241000000000058e+04 1.31623649999999998e+03,
              8.34415000000000000e+04 8.62241000000000058e+04 1.31119749999999999e+03,
              8.84415000000000000e+04 8.62241000000000058e+04 1.29075540000000001e+03,
              4.53915000000000000e+04 8.61741000000000058e+04 1.38077929999999992e+03,
              5.03915000000000000e+04 8.61741000000000058e+04 1.43400500000000011e+03,
              5.53915000000000000e+04 8.61741000000000058e+04 1.37669540000000006e+03,
              6.03915000000000000e+04 8.61741000000000058e+04 1.39225009999999997e+03,
              6.53915000000000000e+04 8.61741000000000058e+04 1.34664869999999996e+03,
              7.03915000000000000e+04 8.61741000000000058e+04 1.33679590000000007e+03,
              7.53915000000000000e+04 8.61741000000000058e+04 1.38971090000000004e+03,
              8.03915000000000000e+04 8.61741000000000058e+04 1.37078610000000003e+03,
              8.53915000000000000e+04 8.61741000000000058e+04 1.34779420000000005e+03,
              9.03915000000000000e+04 8.61741000000000058e+04 1.28542070000000012e+03,
              4.73415000000000000e+04 8.61241000000000058e+04 1.37490000000000009e+03,
              5.23415000000000000e+04 8.61241000000000058e+04 1.38195560000000000e+03,
              5.73415000000000000e+04 8.61241000000000058e+04 1.33067700000000013e+03,
              6.23415000000000000e+04 8.61241000000000058e+04 1.32566650000000004e+03,
              6.73415000000000000e+04 8.61241000000000058e+04 1.31036380000000008e+03,
              7.23415000000000000e+04 8.61241000000000058e+04 1.27158690000000001e+03,
              7.73415000000000000e+04 8.61241000000000058e+04 1.23298160000000007e+03,
              8.23415000000000000e+04 8.61241000000000058e+04 1.31329610000000002e+03,
              8.73415000000000000e+04 8.61241000000000058e+04 1.33850559999999996e+03,
              4.42915000000000000e+04 8.60741000000000058e+04 1.36420569999999998e+03,
              4.92915000000000000e+04 8.60741000000000058e+04 1.38881089999999995e+03,
              5.42915000000000000e+04 8.60741000000000058e+04 1.39396520000000010e+03,
              5.92915000000000000e+04 8.60741000000000058e+04 1.38655850000000009e+03,
              6.42915000000000000e+04 8.60741000000000058e+04 1.37620600000000013e+03,
              6.92915000000000000e+04 8.60741000000000058e+04 1.34617740000000003e+03,
              7.42915000000000000e+04 8.60741000000000058e+04 1.29657029999999986e+03,
              7.92915000000000000e+04 8.60741000000000058e+04 1.35607140000000004e+03,
              8.42915000000000000e+04 8.60741000000000058e+04 1.39833909999999992e+03,
              8.92915000000000000e+04 8.60741000000000058e+04 1.28241959999999995e+03,
              4.62415000000000000e+04 8.60241000000000058e+04 1.37543660000000000e+03,
              5.12415000000000000e+04 8.60241000000000058e+04 1.40064789999999994e+03,
              5.62415000000000000e+04 8.60241000000000058e+04 1.38074990000000003e+03,
              6.12415000000000000e+04 8.60241000000000058e+04 1.38516959999999995e+03,
              6.62415000000000000e+04 8.60241000000000058e+04 1.33986290000000008e+03,
              7.12415000000000000e+04 8.60241000000000058e+04 1.33450019999999995e+03,
              7.62415000000000000e+04 8.60241000000000058e+04 1.33782770000000005e+03,
              8.12415000000000000e+04 8.60241000000000058e+04 1.32068890000000010e+03,
              8.62415000000000000e+04 8.60241000000000058e+04 1.30857670000000007e+03,
              4.31915000000000000e+04 8.59741000000000058e+04 1.37458719999999994e+03,
              4.81915000000000000e+04 8.59741000000000058e+04 1.35779120000000012e+03,
              5.31915000000000000e+04 8.59741000000000058e+04 1.39004690000000005e+03,
              5.81915000000000000e+04 8.59741000000000058e+04 1.37733919999999989e+03,
              6.31915000000000000e+04 8.59741000000000058e+04 1.36407310000000007e+03,
              6.81915000000000000e+04 8.59741000000000058e+04 1.32602420000000006e+03,
              7.31915000000000000e+04 8.59741000000000058e+04 1.35775950000000012e+03,
              7.81915000000000000e+04 8.59741000000000058e+04 1.26153150000000005e+03,
              8.31915000000000000e+04 8.59741000000000058e+04 1.31239139999999998e+03,
              8.81915000000000000e+04 8.59741000000000058e+04 1.30133240000000001e+03,
              4.51415000000000000e+04 8.59241000000000058e+04 1.36671589999999992e+03,
              5.01415000000000000e+04 8.59241000000000058e+04 1.43793720000000008e+03,
              5.51415000000000000e+04 8.59241000000000058e+04 1.39426600000000008e+03,
              6.01415000000000000e+04 8.59241000000000058e+04 1.39950980000000004e+03,
              6.51415000000000000e+04 8.59241000000000058e+04 1.35620319999999992e+03,
              7.01415000000000000e+04 8.59241000000000058e+04 1.34198199999999997e+03,
              7.51415000000000000e+04 8.59241000000000058e+04 1.34952559999999994e+03,
              8.01415000000000000e+04 8.59241000000000058e+04 1.37126679999999988e+03,
              8.51415000000000000e+04 8.59241000000000058e+04 1.38774310000000014e+03,
              9.01415000000000000e+04 8.59241000000000058e+04 1.31297129999999993e+03,
              4.70915000000000000e+04 8.58741000000000058e+04 1.37173800000000006e+03,
              5.20915000000000000e+04 8.58741000000000058e+04 1.37778700000000003e+03,
              5.70915000000000000e+04 8.58741000000000058e+04 1.32312320000000000e+03,
              6.20915000000000000e+04 8.58741000000000058e+04 1.36314149999999995e+03,
              6.70915000000000000e+04 8.58741000000000058e+04 1.31007410000000004e+03,
              7.20915000000000000e+04 8.58741000000000058e+04 1.31321430000000009e+03,
              7.70915000000000000e+04 8.58741000000000058e+04 1.22196910000000003e+03,
              8.20915000000000000e+04 8.58741000000000058e+04 1.32198520000000008e+03,
              8.70915000000000000e+04 8.58741000000000058e+04 1.35678769999999986e+03,
              4.40415000000000000e+04 8.58241000000000058e+04 1.36920710000000008e+03,
              4.90415000000000000e+04 8.58241000000000058e+04 1.37036069999999995e+03,
              5.40415000000000000e+04 8.58241000000000058e+04 1.40257029999999986e+03,
              5.90415000000000000e+04 8.58241000000000058e+04 1.38920949999999993e+03,
              6.40415000000000000e+04 8.58241000000000058e+04 1.35174430000000007e+03,
              6.90415000000000000e+04 8.58241000000000058e+04 1.33500459999999998e+03,
              7.40415000000000000e+04 8.58241000000000058e+04 1.32996460000000002e+03,
              7.90415000000000000e+04 8.58241000000000058e+04 1.33413400000000001e+03,
              8.40415000000000000e+04 8.58241000000000058e+04 1.38206930000000011e+03,
              8.90415000000000000e+04 8.58241000000000058e+04 1.29359950000000003e+03,
              4.59915000000000000e+04 8.57741000000000058e+04 1.36575459999999998e+03,
              5.09915000000000000e+04 8.57741000000000058e+04 1.42605500000000006e+03,
              5.59915000000000000e+04 8.57741000000000058e+04 1.37444329999999991e+03,
              6.09915000000000000e+04 8.57741000000000058e+04 1.38487670000000003e+03,
              6.59915000000000000e+04 8.57741000000000058e+04 1.34054660000000013e+03,
              7.09915000000000000e+04 8.57741000000000058e+04 1.34012410000000000e+03,
              7.59915000000000000e+04 8.57741000000000058e+04 1.30188270000000011e+03,
              8.09915000000000000e+04 8.57741000000000058e+04 1.32320990000000006e+03,
              8.59915000000000000e+04 8.57741000000000058e+04 1.32422780000000012e+03,
              9.09915000000000000e+04 8.57741000000000058e+04 1.27774549999999999e+03,
              4.79415000000000000e+04 8.57241000000000058e+04 1.35871329999999989e+03,
              5.29415000000000000e+04 8.57241000000000058e+04 1.38964010000000007e+03,
              5.79415000000000000e+04 8.57241000000000058e+04 1.36888480000000004e+03,
              6.29415000000000000e+04 8.57241000000000058e+04 1.36565069999999992e+03,
              6.79415000000000000e+04 8.57241000000000058e+04 1.32981529999999998e+03,
              7.29415000000000000e+04 8.57241000000000058e+04 1.34640280000000007e+03,
              7.79415000000000000e+04 8.57241000000000058e+04 1.22541409999999996e+03,
              8.29415000000000000e+04 8.57241000000000058e+04 1.31582749999999987e+03,
              8.79415000000000000e+04 8.57241000000000058e+04 1.30912339999999995e+03,
              4.48915000000000000e+04 8.56741000000000058e+04 1.37049690000000010e+03,
              4.98915000000000000e+04 8.56741000000000058e+04 1.43059169999999995e+03,
              5.48915000000000000e+04 8.56741000000000058e+04 1.40907200000000012e+03,
              5.98915000000000000e+04 8.56741000000000058e+04 1.40452330000000006e+03,
              6.48915000000000000e+04 8.56741000000000058e+04 1.34867529999999988e+03,
              6.98915000000000000e+04 8.56741000000000058e+04 1.33872679999999991e+03,
              7.48915000000000000e+04 8.56741000000000058e+04 1.34113779999999997e+03,
              7.98915000000000000e+04 8.56741000000000058e+04 1.36017030000000000e+03,
              8.48915000000000000e+04 8.56741000000000058e+04 1.38996260000000007e+03,
              8.98915000000000000e+04 8.56741000000000058e+04 1.30797049999999990e+03,
              4.68415000000000000e+04 8.56241000000000058e+04 1.36725070000000005e+03,
              5.18415000000000000e+04 8.56241000000000058e+04 1.37058590000000004e+03,
              5.68415000000000000e+04 8.56241000000000058e+04 1.37454099999999994e+03,
              6.18415000000000000e+04 8.56241000000000058e+04 1.38708240000000001e+03,
              6.68415000000000000e+04 8.56241000000000058e+04 1.31185169999999994e+03,
              7.18415000000000000e+04 8.56241000000000058e+04 1.26366589999999997e+03,
              7.68415000000000000e+04 8.56241000000000058e+04 1.22444360000000006e+03,
              8.18415000000000000e+04 8.56241000000000058e+04 1.31374420000000009e+03,
              8.68415000000000000e+04 8.56241000000000058e+04 1.33205860000000007e+03,
              4.37915000000000000e+04 8.55741000000000058e+04 1.37489719999999988e+03,
              4.87915000000000000e+04 8.55741000000000058e+04 1.35523000000000002e+03,
              5.37915000000000000e+04 8.55741000000000058e+04 1.38938120000000004e+03,
              5.87915000000000000e+04 8.55741000000000058e+04 1.36517470000000003e+03,
              6.37915000000000000e+04 8.55741000000000058e+04 1.36945100000000002e+03,
              6.87915000000000000e+04 8.55741000000000058e+04 1.29524360000000001e+03,
              7.37915000000000000e+04 8.55741000000000058e+04 1.28291180000000008e+03,
              7.87915000000000000e+04 8.55741000000000058e+04 1.29708940000000007e+03,
              8.37915000000000000e+04 8.55741000000000058e+04 1.35114670000000001e+03,
              8.87915000000000000e+04 8.55741000000000058e+04 1.31285670000000005e+03,
              4.57415000000000000e+04 8.55241000000000058e+04 1.37650849999999991e+03,
              5.07415000000000000e+04 8.55241000000000058e+04 1.41274240000000009e+03,
              5.57415000000000000e+04 8.55241000000000058e+04 1.37048659999999995e+03,
              6.07415000000000000e+04 8.55241000000000058e+04 1.39675990000000002e+03,
              6.57415000000000000e+04 8.55241000000000058e+04 1.33273360000000002e+03,
              7.07415000000000000e+04 8.55241000000000058e+04 1.29591890000000012e+03,
              7.57415000000000000e+04 8.55241000000000058e+04 1.36350350000000003e+03,
              8.07415000000000000e+04 8.55241000000000058e+04 1.32094119999999998e+03,
              8.57415000000000000e+04 8.55241000000000058e+04 1.33865390000000002e+03,
              9.07415000000000000e+04 8.55241000000000058e+04 1.28815640000000008e+03,
              4.76915000000000000e+04 8.54741000000000058e+04 1.35952340000000004e+03,
              5.26915000000000000e+04 8.54741000000000058e+04 1.38436670000000004e+03,
              5.76915000000000000e+04 8.54741000000000058e+04 1.35530320000000006e+03,
              6.26915000000000000e+04 8.54741000000000058e+04 1.37569280000000003e+03,
              6.76915000000000000e+04 8.54741000000000058e+04 1.33903289999999993e+03,
              7.26915000000000000e+04 8.54741000000000058e+04 1.34899980000000005e+03,
              7.76915000000000000e+04 8.54741000000000058e+04 1.22159979999999996e+03,
              8.26915000000000000e+04 8.54741000000000058e+04 1.31707320000000004e+03,
              8.76915000000000000e+04 8.54741000000000058e+04 1.32861200000000008e+03,
              4.46415000000000000e+04 8.54241000000000058e+04 1.35629829999999993e+03,
              4.96415000000000000e+04 8.54241000000000058e+04 1.41023009999999999e+03,
              5.46415000000000000e+04 8.54241000000000058e+04 1.39917329999999993e+03,
              5.96415000000000000e+04 8.54241000000000058e+04 1.39705510000000004e+03,
              6.46415000000000000e+04 8.54241000000000058e+04 1.31221970000000010e+03,
              6.96415000000000000e+04 8.54241000000000058e+04 1.33585419999999999e+03,
              7.46415000000000000e+04 8.54241000000000058e+04 1.28208539999999994e+03,
              7.96415000000000000e+04 8.54241000000000058e+04 1.37492970000000014e+03,
              8.46415000000000000e+04 8.54241000000000058e+04 1.40785320000000002e+03,
              8.96415000000000000e+04 8.54241000000000058e+04 1.30979660000000013e+03,
              4.65915000000000000e+04 8.53741000000000058e+04 1.35161869999999999e+03,
              5.15915000000000000e+04 8.53741000000000058e+04 1.37726170000000002e+03,
              5.65915000000000000e+04 8.53741000000000058e+04 1.38708699999999999e+03,
              6.15915000000000000e+04 8.53741000000000058e+04 1.38165760000000000e+03,
              6.65915000000000000e+04 8.53741000000000058e+04 1.26284410000000003e+03,
              7.15915000000000000e+04 8.53741000000000058e+04 1.24980659999999989e+03,
              7.65915000000000000e+04 8.53741000000000058e+04 1.26155050000000006e+03,
              8.15915000000000000e+04 8.53741000000000058e+04 1.31046749999999997e+03,
              8.65915000000000000e+04 8.53741000000000058e+04 1.31378369999999995e+03,
              4.35415000000000000e+04 8.53241000000000058e+04 1.37102960000000007e+03,
              4.85415000000000000e+04 8.53241000000000058e+04 1.35784660000000008e+03,
              5.35415000000000000e+04 8.53241000000000058e+04 1.37850399999999991e+03,
              5.85415000000000000e+04 8.53241000000000058e+04 1.35367110000000002e+03,
              6.35415000000000000e+04 8.53241000000000058e+04 1.37786300000000006e+03,
              6.85415000000000000e+04 8.53241000000000058e+04 1.33458550000000014e+03,
              7.35415000000000000e+04 8.53241000000000058e+04 1.33513930000000005e+03,
              7.85415000000000000e+04 8.53241000000000058e+04 1.24891630000000009e+03,
              8.35415000000000000e+04 8.53241000000000058e+04 1.37479819999999995e+03,
              8.85415000000000000e+04 8.53241000000000058e+04 1.30697559999999999e+03,
              4.54915000000000000e+04 8.52741000000000058e+04 1.37138239999999996e+03,
              5.04915000000000000e+04 8.52741000000000058e+04 1.39460709999999995e+03,
              5.54915000000000000e+04 8.52741000000000058e+04 1.38218730000000005e+03,
              6.04915000000000000e+04 8.52741000000000058e+04 1.37296720000000005e+03,
              6.54915000000000000e+04 8.52741000000000058e+04 1.27372859999999991e+03,
              7.04915000000000000e+04 8.52741000000000058e+04 1.31428510000000006e+03,
              7.54915000000000000e+04 8.52741000000000058e+04 1.36843239999999992e+03,
              8.04915000000000000e+04 8.52741000000000058e+04 1.32940059999999994e+03,
              8.54915000000000000e+04 8.52741000000000058e+04 1.34860210000000006e+03,
              9.04915000000000000e+04 8.52741000000000058e+04 1.30851469999999995e+03,
              4.74415000000000000e+04 8.52241000000000058e+04 1.36041219999999998e+03,
              5.24415000000000000e+04 8.52241000000000058e+04 1.37225559999999996e+03,
              5.74415000000000000e+04 8.52241000000000058e+04 1.33534059999999999e+03,
              6.24415000000000000e+04 8.52241000000000058e+04 1.36620470000000000e+03,
              6.74415000000000000e+04 8.52241000000000058e+04 1.35205870000000004e+03,
              7.24415000000000000e+04 8.52241000000000058e+04 1.34234339999999997e+03,
              7.74415000000000000e+04 8.52241000000000058e+04 1.22397720000000004e+03,
              8.24415000000000000e+04 8.52241000000000058e+04 1.32041969999999992e+03,
              8.74415000000000000e+04 8.52241000000000058e+04 1.32394039999999995e+03,
              4.43915000000000000e+04 8.51741000000000058e+04 1.35919059999999990e+03,
              4.93915000000000000e+04 8.51741000000000058e+04 1.39630999999999995e+03,
              5.43915000000000000e+04 8.51741000000000058e+04 1.38322659999999996e+03,
              5.93915000000000000e+04 8.51741000000000058e+04 1.38685100000000011e+03,
              6.43915000000000000e+04 8.51741000000000058e+04 1.36221080000000006e+03,
              6.93915000000000000e+04 8.51741000000000058e+04 1.27303430000000003e+03,
              7.43915000000000000e+04 8.51741000000000058e+04 1.23578350000000000e+03,
              7.93915000000000000e+04 8.51741000000000058e+04 1.38445780000000013e+03,
              8.43915000000000000e+04 8.51741000000000058e+04 1.41082839999999987e+03,
              8.93915000000000000e+04 8.51741000000000058e+04 1.30173590000000013e+03,
              4.63415000000000000e+04 8.51241000000000058e+04 1.36336580000000004e+03,
              5.13415000000000000e+04 8.51241000000000058e+04 1.39128269999999998e+03,
              5.63415000000000000e+04 8.51241000000000058e+04 1.39427759999999989e+03,
              6.13415000000000000e+04 8.51241000000000058e+04 1.36723610000000008e+03,
              6.63415000000000000e+04 8.51241000000000058e+04 1.28617799999999988e+03,
              7.13415000000000000e+04 8.51241000000000058e+04 1.24341869999999994e+03,
              7.63415000000000000e+04 8.51241000000000058e+04 1.33866139999999996e+03,
              8.13415000000000000e+04 8.51241000000000058e+04 1.31455629999999996e+03,
              8.63415000000000000e+04 8.51241000000000058e+04 1.34789470000000006e+03,
              4.32915000000000000e+04 8.50741000000000058e+04 1.36287519999999995e+03,
              4.82915000000000000e+04 8.50741000000000058e+04 1.34924229999999989e+03,
              5.32915000000000000e+04 8.50741000000000058e+04 1.37392070000000012e+03,
              5.82915000000000000e+04 8.50741000000000058e+04 1.34088830000000007e+03,
              6.32915000000000000e+04 8.50741000000000058e+04 1.37641390000000001e+03,
              6.82915000000000000e+04 8.50741000000000058e+04 1.32690550000000007e+03,
              7.32915000000000000e+04 8.50741000000000058e+04 1.36742959999999994e+03,
              7.82915000000000000e+04 8.50741000000000058e+04 1.23349060000000009e+03,
              8.32915000000000000e+04 8.50741000000000058e+04 1.40535439999999994e+03,
              8.82915000000000000e+04 8.50741000000000058e+04 1.30982050000000004e+03,
              4.52415000000000000e+04 8.50241000000000058e+04 1.35863110000000006e+03,
              5.02415000000000000e+04 8.50241000000000058e+04 1.37673320000000012e+03,
              5.52415000000000000e+04 8.50241000000000058e+04 1.38858580000000006e+03,
              6.02415000000000000e+04 8.50241000000000058e+04 1.37210609999999997e+03,
              6.52415000000000000e+04 8.50241000000000058e+04 1.27927759999999989e+03,
              7.02415000000000000e+04 8.50241000000000058e+04 1.32247990000000004e+03,
              7.52415000000000000e+04 8.50241000000000058e+04 1.35943110000000001e+03,
              8.02415000000000000e+04 8.50241000000000058e+04 1.35695870000000014e+03,
              8.52415000000000000e+04 8.50241000000000058e+04 1.34475080000000003e+03,
              9.02415000000000000e+04 8.50241000000000058e+04 1.32209339999999997e+03,
              4.71915000000000000e+04 8.49741000000000058e+04 1.34805299999999988e+03,
              5.21915000000000000e+04 8.49741000000000058e+04 1.37060259999999994e+03,
              5.71915000000000000e+04 8.49741000000000058e+04 1.37394659999999999e+03,
              6.21915000000000000e+04 8.49741000000000058e+04 1.35752279999999996e+03,
              6.71915000000000000e+04 8.49741000000000058e+04 1.32242759999999998e+03,
              7.21915000000000000e+04 8.49741000000000058e+04 1.37287619999999993e+03,
              7.71915000000000000e+04 8.49741000000000058e+04 1.23536830000000009e+03,
              8.21915000000000000e+04 8.49741000000000058e+04 1.31920399999999995e+03,
              8.71915000000000000e+04 8.49741000000000058e+04 1.30918949999999995e+03,
              4.41415000000000000e+04 8.49241000000000058e+04 1.36265200000000004e+03,
              4.91415000000000000e+04 8.49241000000000058e+04 1.39346659999999997e+03,
              5.41415000000000000e+04 8.49241000000000058e+04 1.37329060000000004e+03,
              5.91415000000000000e+04 8.49241000000000058e+04 1.37631629999999996e+03,
              6.41415000000000000e+04 8.49241000000000058e+04 1.35721350000000007e+03,
              6.91415000000000000e+04 8.49241000000000058e+04 1.27070949999999993e+03,
              7.41415000000000000e+04 8.49241000000000058e+04 1.27769340000000011e+03,
              7.91415000000000000e+04 8.49241000000000058e+04 1.39625880000000006e+03,
              8.41415000000000000e+04 8.49241000000000058e+04 1.40650360000000001e+03,
              8.91415000000000000e+04 8.49241000000000058e+04 1.29629060000000004e+03,
              4.60915000000000000e+04 8.48741000000000058e+04 1.37155870000000004e+03,
              5.10915000000000000e+04 8.48741000000000058e+04 1.37660449999999992e+03,
              5.60915000000000000e+04 8.48741000000000058e+04 1.37213709999999992e+03,
              6.10915000000000000e+04 8.48741000000000058e+04 1.33751400000000012e+03,
              6.60915000000000000e+04 8.48741000000000058e+04 1.36055590000000007e+03,
              7.10915000000000000e+04 8.48741000000000058e+04 1.25498250000000007e+03,
              7.60915000000000000e+04 8.48741000000000058e+04 1.31920420000000013e+03,
              8.10915000000000000e+04 8.48741000000000058e+04 1.31871749999999997e+03,
              8.60915000000000000e+04 8.48741000000000058e+04 1.33056410000000005e+03,
              4.30415000000000000e+04 8.48241000000000058e+04 1.36189400000000001e+03,
              4.80415000000000000e+04 8.48241000000000058e+04 1.34904600000000005e+03,
              5.30415000000000000e+04 8.48241000000000058e+04 1.36377179999999998e+03,
              5.80415000000000000e+04 8.48241000000000058e+04 1.30815169999999989e+03,
              6.30415000000000000e+04 8.48241000000000058e+04 1.35811519999999996e+03,
              6.80415000000000000e+04 8.48241000000000058e+04 1.27113720000000012e+03,
              7.30415000000000000e+04 8.48241000000000058e+04 1.37347330000000011e+03,
              7.80415000000000000e+04 8.48241000000000058e+04 1.22879780000000005e+03,
              8.30415000000000000e+04 8.48241000000000058e+04 1.40038059999999996e+03,
              8.80415000000000000e+04 8.48241000000000058e+04 1.29396530000000007e+03,
              4.49915000000000000e+04 8.47741000000000058e+04 1.36157999999999993e+03,
              4.99915000000000000e+04 8.47741000000000058e+04 1.37451170000000002e+03,
              5.49915000000000000e+04 8.47741000000000058e+04 1.39322029999999995e+03,
              5.99915000000000000e+04 8.47741000000000058e+04 1.35924919999999997e+03,
              6.49915000000000000e+04 8.47741000000000058e+04 1.30349780000000010e+03,
              6.99915000000000000e+04 8.47741000000000058e+04 1.24775749999999994e+03,
              7.49915000000000000e+04 8.47741000000000058e+04 1.34755940000000010e+03,
              7.99915000000000000e+04 8.47741000000000058e+04 1.38092430000000013e+03,
              8.49915000000000000e+04 8.47741000000000058e+04 1.36201610000000005e+03,
              8.99915000000000000e+04 8.47741000000000058e+04 1.30421360000000004e+03,
              4.69415000000000000e+04 8.47241000000000058e+04 1.35948820000000001e+03,
              5.19415000000000000e+04 8.47241000000000058e+04 1.35665830000000005e+03,
              5.69415000000000000e+04 8.47241000000000058e+04 1.37167180000000008e+03,
              6.19415000000000000e+04 8.47241000000000058e+04 1.35698730000000000e+03,
              6.69415000000000000e+04 8.47241000000000058e+04 1.25275939999999991e+03,
              7.19415000000000000e+04 8.47241000000000058e+04 1.38286519999999996e+03,
              7.69415000000000000e+04 8.47241000000000058e+04 1.24801179999999999e+03,
              8.19415000000000000e+04 8.47241000000000058e+04 1.31169839999999999e+03,
              8.69415000000000000e+04 8.47241000000000058e+04 1.30056649999999991e+03,
              4.38915000000000000e+04 8.46741000000000058e+04 1.35526679999999988e+03,
              4.88915000000000000e+04 8.46741000000000058e+04 1.38278700000000003e+03,
              5.38915000000000000e+04 8.46741000000000058e+04 1.36594560000000001e+03,
              5.88915000000000000e+04 8.46741000000000058e+04 1.35707130000000006e+03,
              6.38915000000000000e+04 8.46741000000000058e+04 1.34760960000000000e+03,
              6.88915000000000000e+04 8.46741000000000058e+04 1.26892290000000003e+03,
              7.38915000000000000e+04 8.46741000000000058e+04 1.31665879999999993e+03,
              7.88915000000000000e+04 8.46741000000000058e+04 1.40960930000000008e+03,
              8.38915000000000000e+04 8.46741000000000058e+04 1.40172820000000002e+03,
              8.88915000000000000e+04 8.46741000000000058e+04 1.28868810000000008e+03,
              4.58415000000000000e+04 8.46241000000000058e+04 1.37120759999999996e+03,
              5.08415000000000000e+04 8.46241000000000058e+04 1.36334999999999991e+03,
              5.58415000000000000e+04 8.46241000000000058e+04 1.36931979999999999e+03,
              6.08415000000000000e+04 8.46241000000000058e+04 1.34311680000000001e+03,
              6.58415000000000000e+04 8.46241000000000058e+04 1.37234089999999992e+03,
              7.08415000000000000e+04 8.46241000000000058e+04 1.28484369999999990e+03,
              7.58415000000000000e+04 8.46241000000000058e+04 1.36268450000000007e+03,
              8.08415000000000000e+04 8.46241000000000058e+04 1.32462740000000008e+03,
              8.58415000000000000e+04 8.46241000000000058e+04 1.32407899999999995e+03,
              9.08415000000000000e+04 8.46241000000000058e+04 1.29726659999999993e+03,
              4.77915000000000000e+04 8.45741000000000058e+04 1.35192790000000014e+03,
              5.27915000000000000e+04 8.45741000000000058e+04 1.35984089999999992e+03,
              5.77915000000000000e+04 8.45741000000000058e+04 1.35739419999999996e+03,
              6.27915000000000000e+04 8.45741000000000058e+04 1.35408150000000001e+03,
              6.77915000000000000e+04 8.45741000000000058e+04 1.24904340000000002e+03,
              7.27915000000000000e+04 8.45741000000000058e+04 1.37592939999999999e+03,
              7.77915000000000000e+04 8.45741000000000058e+04 1.22455680000000007e+03,
              8.27915000000000000e+04 8.45741000000000058e+04 1.39326790000000005e+03,
              8.77915000000000000e+04 8.45741000000000058e+04 1.27971550000000002e+03,
              4.47415000000000000e+04 8.45241000000000058e+04 1.35004469999999992e+03,
              4.97415000000000000e+04 8.45241000000000058e+04 1.37984870000000001e+03,
              5.47415000000000000e+04 8.45241000000000058e+04 1.40415229999999997e+03,
              5.97415000000000000e+04 8.45241000000000058e+04 1.35573000000000002e+03,
              6.47415000000000000e+04 8.45241000000000058e+04 1.30836869999999999e+03,
              6.97415000000000000e+04 8.45241000000000058e+04 1.27089879999999994e+03,
              7.47415000000000000e+04 8.45241000000000058e+04 1.31710820000000012e+03,
              7.97415000000000000e+04 8.45241000000000058e+04 1.41868200000000002e+03,
              8.47415000000000000e+04 8.45241000000000058e+04 1.39287760000000003e+03,
              8.97415000000000000e+04 8.45241000000000058e+04 1.30610780000000000e+03,
              4.66915000000000000e+04 8.44741000000000058e+04 1.36054399999999987e+03,
              5.16915000000000000e+04 8.44741000000000058e+04 1.35774669999999992e+03,
              5.66915000000000000e+04 8.44741000000000058e+04 1.36860010000000011e+03,
              6.16915000000000000e+04 8.44741000000000058e+04 1.36175279999999998e+03,
              6.66915000000000000e+04 8.44741000000000058e+04 1.35163810000000012e+03,
              7.16915000000000000e+04 8.44741000000000058e+04 1.37025330000000008e+03,
              7.66915000000000000e+04 8.44741000000000058e+04 1.29426919999999996e+03,
              8.16915000000000000e+04 8.44741000000000058e+04 1.32112490000000003e+03,
              8.66915000000000000e+04 8.44741000000000058e+04 1.32973230000000012e+03,
              4.36415000000000000e+04 8.44241000000000058e+04 1.36428040000000010e+03,
              4.86415000000000000e+04 8.44241000000000058e+04 1.37012730000000010e+03,
              5.36415000000000000e+04 8.44241000000000058e+04 1.37028909999999996e+03,
              5.86415000000000000e+04 8.44241000000000058e+04 1.29317979999999989e+03,
              6.36415000000000000e+04 8.44241000000000058e+04 1.32336959999999999e+03,
              6.86415000000000000e+04 8.44241000000000058e+04 1.29767190000000005e+03,
              7.36415000000000000e+04 8.44241000000000058e+04 1.36374800000000005e+03,
              7.86415000000000000e+04 8.44241000000000058e+04 1.32048540000000003e+03,
              8.36415000000000000e+04 8.44241000000000058e+04 1.40581559999999990e+03,
              8.86415000000000000e+04 8.44241000000000058e+04 1.27594529999999986e+03,
              4.55915000000000000e+04 8.43741000000000058e+04 1.35666030000000001e+03,
              5.05915000000000000e+04 8.43741000000000058e+04 1.35692129999999997e+03,
              5.55915000000000000e+04 8.43741000000000058e+04 1.38543010000000004e+03,
              6.05915000000000000e+04 8.43741000000000058e+04 1.32504790000000003e+03,
              6.55915000000000000e+04 8.43741000000000058e+04 1.35818969999999990e+03,
              7.05915000000000000e+04 8.43741000000000058e+04 1.28717930000000001e+03,
              7.55915000000000000e+04 8.43741000000000058e+04 1.35510469999999987e+03,
              8.05915000000000000e+04 8.43741000000000058e+04 1.34740429999999992e+03,
              8.55915000000000000e+04 8.43741000000000058e+04 1.33644820000000004e+03,
              9.05915000000000000e+04 8.43741000000000058e+04 1.27940229999999997e+03,
              4.75415000000000000e+04 8.43241000000000058e+04 1.35150209999999993e+03,
              5.25415000000000000e+04 8.43241000000000058e+04 1.36031490000000008e+03,
              5.75415000000000000e+04 8.43241000000000058e+04 1.37839120000000003e+03,
              6.25415000000000000e+04 8.43241000000000058e+04 1.29716049999999996e+03,
              6.75415000000000000e+04 8.43241000000000058e+04 1.28952320000000009e+03,
              7.25415000000000000e+04 8.43241000000000058e+04 1.36512599999999998e+03,
              7.75415000000000000e+04 8.43241000000000058e+04 1.22567889999999989e+03,
              8.25415000000000000e+04 8.43241000000000058e+04 1.37650340000000006e+03,
              8.75415000000000000e+04 8.43241000000000058e+04 1.29093840000000000e+03,
              4.44915000000000000e+04 8.42741000000000058e+04 1.34940480000000002e+03,
              4.94915000000000000e+04 8.42741000000000058e+04 1.35971039999999994e+03,
              5.44915000000000000e+04 8.42741000000000058e+04 1.38117900000000009e+03,
              5.94915000000000000e+04 8.42741000000000058e+04 1.28541449999999986e+03,
              6.44915000000000000e+04 8.42741000000000058e+04 1.26346769999999992e+03,
              6.94915000000000000e+04 8.42741000000000058e+04 1.35825659999999993e+03,
              7.44915000000000000e+04 8.42741000000000058e+04 1.25731829999999991e+03,
              7.94915000000000000e+04 8.42741000000000058e+04 1.40590550000000007e+03,
              8.44915000000000000e+04 8.42741000000000058e+04 1.39237439999999992e+03,
              8.94915000000000000e+04 8.42741000000000058e+04 1.30203040000000010e+03,
              4.64415000000000000e+04 8.42241000000000058e+04 1.35751139999999987e+03,
              5.14415000000000000e+04 8.42241000000000058e+04 1.36385159999999996e+03,
              5.64415000000000000e+04 8.42241000000000058e+04 1.37708079999999995e+03,
              6.14415000000000000e+04 8.42241000000000058e+04 1.35452739999999994e+03,
              6.64415000000000000e+04 8.42241000000000058e+04 1.35414620000000014e+03,
              7.14415000000000000e+04 8.42241000000000058e+04 1.36820640000000003e+03,
              7.64415000000000000e+04 8.42241000000000058e+04 1.31310130000000004e+03,
              8.14415000000000000e+04 8.42241000000000058e+04 1.34297579999999994e+03,
              8.64415000000000000e+04 8.42241000000000058e+04 1.33377790000000005e+03,
              4.33915000000000000e+04 8.41741000000000058e+04 1.35631709999999998e+03,
              4.83915000000000000e+04 8.41741000000000058e+04 1.35683680000000004e+03,
              5.33915000000000000e+04 8.41741000000000058e+04 1.36298739999999998e+03,
              5.83915000000000000e+04 8.41741000000000058e+04 1.32224739999999997e+03,
              6.33915000000000000e+04 8.41741000000000058e+04 1.34504649999999992e+03,
              6.83915000000000000e+04 8.41741000000000058e+04 1.33563619999999992e+03,
              7.33915000000000000e+04 8.41741000000000058e+04 1.37908529999999996e+03,
              7.83915000000000000e+04 8.41741000000000058e+04 1.24603919999999994e+03,
              8.33915000000000000e+04 8.41741000000000058e+04 1.39399209999999994e+03,
              8.83915000000000000e+04 8.41741000000000058e+04 1.26380909999999994e+03,
              4.53415000000000000e+04 8.41241000000000058e+04 1.35030520000000001e+03,
              5.03415000000000000e+04 8.41241000000000058e+04 1.35213369999999986e+03,
              5.53415000000000000e+04 8.41241000000000058e+04 1.41336540000000014e+03,
              6.03415000000000000e+04 8.41241000000000058e+04 1.30053580000000011e+03,
              6.53415000000000000e+04 8.41241000000000058e+04 1.35553250000000003e+03,
              7.03415000000000000e+04 8.41241000000000058e+04 1.26743789999999990e+03,
              7.53415000000000000e+04 8.41241000000000058e+04 1.35219679999999994e+03,
              8.03415000000000000e+04 8.41241000000000058e+04 1.35853670000000011e+03,
              8.53415000000000000e+04 8.41241000000000058e+04 1.33175819999999999e+03,
              9.03415000000000000e+04 8.41241000000000058e+04 1.29450700000000006e+03,
              4.72915000000000000e+04 8.40741000000000058e+04 1.35051379999999995e+03,
              5.22915000000000000e+04 8.40741000000000058e+04 1.36544720000000007e+03,
              5.72915000000000000e+04 8.40741000000000058e+04 1.37253449999999998e+03,
              6.22915000000000000e+04 8.40741000000000058e+04 1.29700549999999998e+03,
              6.72915000000000000e+04 8.40741000000000058e+04 1.33597360000000003e+03,
              7.22915000000000000e+04 8.40741000000000058e+04 1.35912869999999998e+03,
              7.72915000000000000e+04 8.40741000000000058e+04 1.22563990000000013e+03,
              8.22915000000000000e+04 8.40741000000000058e+04 1.35881690000000003e+03,
              8.72915000000000000e+04 8.40741000000000058e+04 1.30166599999999994e+03,
              4.42415000000000000e+04 8.40241000000000058e+04 1.35691750000000002e+03,
              4.92415000000000000e+04 8.40241000000000058e+04 1.35030850000000009e+03,
              5.42415000000000000e+04 8.40241000000000058e+04 1.38350090000000000e+03,
              5.92415000000000000e+04 8.40241000000000058e+04 1.30491249999999991e+03,
              6.42415000000000000e+04 8.40241000000000058e+04 1.26514400000000001e+03,
              6.92415000000000000e+04 8.40241000000000058e+04 1.35242340000000013e+03,
              7.42415000000000000e+04 8.40241000000000058e+04 1.34651179999999999e+03,
              7.92415000000000000e+04 8.40241000000000058e+04 1.38068450000000007e+03,
              8.42415000000000000e+04 8.40241000000000058e+04 1.40373530000000005e+03,
              8.92415000000000000e+04 8.40241000000000058e+04 1.28525559999999996e+03,
              4.61915000000000000e+04 8.39741000000000058e+04 1.34859609999999998e+03,
              5.11915000000000000e+04 8.39741000000000058e+04 1.37084380000000010e+03,
              5.61915000000000000e+04 8.39741000000000058e+04 1.38952469999999994e+03,
              6.11915000000000000e+04 8.39741000000000058e+04 1.31939830000000006e+03,
              6.61915000000000000e+04 8.39741000000000058e+04 1.35099520000000007e+03,
              7.11915000000000000e+04 8.39741000000000058e+04 1.35212950000000001e+03,
              7.61915000000000000e+04 8.39741000000000058e+04 1.27557269999999994e+03,
              8.11915000000000000e+04 8.39741000000000058e+04 1.32978109999999992e+03,
              8.61915000000000000e+04 8.39741000000000058e+04 1.33654330000000004e+03,
              4.31415000000000000e+04 8.39241000000000058e+04 1.35435590000000002e+03,
              4.81415000000000000e+04 8.39241000000000058e+04 1.36593200000000002e+03,
              5.31415000000000000e+04 8.39241000000000058e+04 1.37148379999999997e+03,
              5.81415000000000000e+04 8.39241000000000058e+04 1.35216910000000007e+03,
              6.31415000000000000e+04 8.39241000000000058e+04 1.32952359999999999e+03,
              6.81415000000000000e+04 8.39241000000000058e+04 1.35151780000000008e+03,
              7.31415000000000000e+04 8.39241000000000058e+04 1.38342239999999993e+03,
              7.81415000000000000e+04 8.39241000000000058e+04 1.23765850000000000e+03,
              8.31415000000000000e+04 8.39241000000000058e+04 1.38197919999999999e+03,
              8.81415000000000000e+04 8.39241000000000058e+04 1.28760329999999999e+03,
              4.50915000000000000e+04 8.38741000000000058e+04 1.35269219999999996e+03,
              5.00915000000000000e+04 8.38741000000000058e+04 1.35636950000000002e+03,
              5.50915000000000000e+04 8.38741000000000058e+04 1.42401580000000013e+03,
              6.00915000000000000e+04 8.38741000000000058e+04 1.27915329999999994e+03,
              6.50915000000000000e+04 8.38741000000000058e+04 1.34665149999999994e+03,
              7.00915000000000000e+04 8.38741000000000058e+04 1.26784580000000005e+03,
              7.50915000000000000e+04 8.38741000000000058e+04 1.35466390000000001e+03,
              8.00915000000000000e+04 8.38741000000000058e+04 1.38930150000000003e+03,
              8.50915000000000000e+04 8.38741000000000058e+04 1.32941129999999998e+03,
              9.00915000000000000e+04 8.38741000000000058e+04 1.27445119999999997e+03,
              4.70415000000000000e+04 8.38241000000000058e+04 1.34403420000000006e+03,
              5.20415000000000000e+04 8.38241000000000058e+04 1.36888470000000007e+03,
              5.70415000000000000e+04 8.38241000000000058e+04 1.37349420000000009e+03,
              6.20415000000000000e+04 8.38241000000000058e+04 1.27268530000000010e+03,
              6.70415000000000000e+04 8.38241000000000058e+04 1.34697919999999999e+03,
              7.20415000000000000e+04 8.38241000000000058e+04 1.34575129999999990e+03,
              7.70415000000000000e+04 8.38241000000000058e+04 1.22765709999999990e+03,
              8.20415000000000000e+04 8.38241000000000058e+04 1.34746900000000005e+03,
              8.70415000000000000e+04 8.38241000000000058e+04 1.31344830000000002e+03,
              4.39915000000000000e+04 8.37741000000000058e+04 1.35014899999999989e+03,
              4.89915000000000000e+04 8.37741000000000058e+04 1.36330150000000003e+03,
              5.39915000000000000e+04 8.37741000000000058e+04 1.38833190000000013e+03,
              5.89915000000000000e+04 8.37741000000000058e+04 1.33023890000000006e+03,
              6.39915000000000000e+04 8.37741000000000058e+04 1.26928629999999998e+03,
              6.89915000000000000e+04 8.37741000000000058e+04 1.33422240000000011e+03,
              7.39915000000000000e+04 8.37741000000000058e+04 1.33686249999999995e+03,
              7.89915000000000000e+04 8.37741000000000058e+04 1.38096970000000010e+03,
              8.39915000000000000e+04 8.37741000000000058e+04 1.40945579999999995e+03,
              8.89915000000000000e+04 8.37741000000000058e+04 1.26007159999999999e+03,
              4.59415000000000000e+04 8.37241000000000058e+04 1.35487830000000008e+03,
              5.09415000000000000e+04 8.37241000000000058e+04 1.36690910000000008e+03,
              5.59415000000000000e+04 8.37241000000000058e+04 1.41131889999999999e+03,
              6.09415000000000000e+04 8.37241000000000058e+04 1.28635370000000012e+03,
              6.59415000000000000e+04 8.37241000000000058e+04 1.35177880000000005e+03,
              7.09415000000000000e+04 8.37241000000000058e+04 1.33737850000000003e+03,
              7.59415000000000000e+04 8.37241000000000058e+04 1.31808439999999996e+03,
              8.09415000000000000e+04 8.37241000000000058e+04 1.32409780000000001e+03,
              8.59415000000000000e+04 8.37241000000000058e+04 1.31783629999999994e+03,
              9.09415000000000000e+04 8.37241000000000058e+04 1.25791210000000001e+03,
              4.78915000000000000e+04 8.36741000000000058e+04 1.36409760000000006e+03,
              5.28915000000000000e+04 8.36741000000000058e+04 1.38124769999999990e+03,
              5.78915000000000000e+04 8.36741000000000058e+04 1.36809130000000005e+03,
              6.28915000000000000e+04 8.36741000000000058e+04 1.32419790000000012e+03,
              6.78915000000000000e+04 8.36741000000000058e+04 1.34490589999999997e+03,
              7.28915000000000000e+04 8.36741000000000058e+04 1.37769080000000008e+03,
              7.78915000000000000e+04 8.36741000000000058e+04 1.23520190000000002e+03,
              8.28915000000000000e+04 8.36741000000000058e+04 1.39390409999999997e+03,
              8.78915000000000000e+04 8.36741000000000058e+04 1.28235339999999997e+03,
              4.48415000000000000e+04 8.36241000000000058e+04 1.34497659999999996e+03,
              4.98415000000000000e+04 8.36241000000000058e+04 1.37726810000000000e+03,
              5.48415000000000000e+04 8.36241000000000058e+04 1.41760529999999994e+03,
              5.98415000000000000e+04 8.36241000000000058e+04 1.31863920000000007e+03,
              6.48415000000000000e+04 8.36241000000000058e+04 1.35908760000000007e+03,
              6.98415000000000000e+04 8.36241000000000058e+04 1.33319209999999998e+03,
              7.48415000000000000e+04 8.36241000000000058e+04 1.34782279999999992e+03,
              7.98415000000000000e+04 8.36241000000000058e+04 1.39688189999999986e+03,
              8.48415000000000000e+04 8.36241000000000058e+04 1.33829050000000007e+03,
              8.98415000000000000e+04 8.36241000000000058e+04 1.28690339999999992e+03,
              4.67915000000000000e+04 8.35741000000000058e+04 1.34486840000000007e+03,
              5.17915000000000000e+04 8.35741000000000058e+04 1.37437269999999990e+03,
              5.67915000000000000e+04 8.35741000000000058e+04 1.35130470000000014e+03,
              6.17915000000000000e+04 8.35741000000000058e+04 1.27470540000000005e+03,
              6.67915000000000000e+04 8.35741000000000058e+04 1.34737490000000003e+03,
              7.17915000000000000e+04 8.35741000000000058e+04 1.34703029999999990e+03,
              7.67915000000000000e+04 8.35741000000000058e+04 1.24329999999999995e+03,
              8.17915000000000000e+04 8.35741000000000058e+04 1.38987230000000000e+03,
              8.67915000000000000e+04 8.35741000000000058e+04 1.31204359999999997e+03,
              4.37415000000000000e+04 8.35241000000000058e+04 1.35784580000000005e+03,
              4.87415000000000000e+04 8.35241000000000058e+04 1.37472640000000001e+03,
              5.37415000000000000e+04 8.35241000000000058e+04 1.38341049999999996e+03,
              5.87415000000000000e+04 8.35241000000000058e+04 1.30679680000000008e+03,
              6.37415000000000000e+04 8.35241000000000058e+04 1.27586900000000014e+03,
              6.87415000000000000e+04 8.35241000000000058e+04 1.34191910000000007e+03,
              7.37415000000000000e+04 8.35241000000000058e+04 1.37086490000000003e+03,
              7.87415000000000000e+04 8.35241000000000058e+04 1.34518800000000010e+03,
              8.37415000000000000e+04 8.35241000000000058e+04 1.39050860000000011e+03,
              8.87415000000000000e+04 8.35241000000000058e+04 1.25803449999999998e+03,
              4.56915000000000000e+04 8.34741000000000058e+04 1.35386909999999989e+03,
              5.06915000000000000e+04 8.34741000000000058e+04 1.37299379999999996e+03,
              5.56915000000000000e+04 8.34741000000000058e+04 1.39866000000000008e+03,
              6.06915000000000000e+04 8.34741000000000058e+04 1.29821970000000010e+03,
              6.56915000000000000e+04 8.34741000000000058e+04 1.35957999999999993e+03,
              7.06915000000000000e+04 8.34741000000000058e+04 1.30376990000000001e+03,
              7.56915000000000000e+04 8.34741000000000058e+04 1.36705320000000006e+03,
              8.06915000000000000e+04 8.34741000000000058e+04 1.33701440000000002e+03,
              8.56915000000000000e+04 8.34741000000000058e+04 1.32384670000000006e+03,
              9.06915000000000000e+04 8.34741000000000058e+04 1.25704520000000002e+03,
              4.76415000000000000e+04 8.34241000000000058e+04 1.38109940000000006e+03,
              5.26415000000000000e+04 8.34241000000000058e+04 1.38857490000000007e+03,
              5.76415000000000000e+04 8.34241000000000058e+04 1.35584480000000008e+03,
              6.26415000000000000e+04 8.34241000000000058e+04 1.29024759999999992e+03,
              6.76415000000000000e+04 8.34241000000000058e+04 1.34411760000000004e+03,
              7.26415000000000000e+04 8.34241000000000058e+04 1.38810349999999994e+03,
              7.76415000000000000e+04 8.34241000000000058e+04 1.22672900000000004e+03,
              8.26415000000000000e+04 8.34241000000000058e+04 1.40219100000000003e+03,
              8.76415000000000000e+04 8.34241000000000058e+04 1.29289120000000003e+03,
              4.45915000000000000e+04 8.33741000000000058e+04 1.34904819999999995e+03,
              4.95915000000000000e+04 8.33741000000000058e+04 1.37560400000000004e+03,
              5.45915000000000000e+04 8.33741000000000058e+04 1.41859809999999993e+03,
              5.95915000000000000e+04 8.33741000000000058e+04 1.31491269999999986e+03,
              6.45915000000000000e+04 8.33741000000000058e+04 1.35272440000000006e+03,
              6.95915000000000000e+04 8.33741000000000058e+04 1.32741039999999998e+03,
              7.45915000000000000e+04 8.33741000000000058e+04 1.30558739999999989e+03,
              7.95915000000000000e+04 8.33741000000000058e+04 1.40183190000000013e+03,
              8.45915000000000000e+04 8.33741000000000058e+04 1.36889509999999996e+03,
              8.95915000000000000e+04 8.33741000000000058e+04 1.25009400000000005e+03,
              4.65415000000000000e+04 8.33241000000000058e+04 1.34305490000000009e+03,
              5.15415000000000000e+04 8.33241000000000058e+04 1.37826530000000002e+03,
              5.65415000000000000e+04 8.33241000000000058e+04 1.34918329999999992e+03,
              6.15415000000000000e+04 8.33241000000000058e+04 1.30108989999999994e+03,
              6.65415000000000000e+04 8.33241000000000058e+04 1.34487950000000001e+03,
              7.15415000000000000e+04 8.33241000000000058e+04 1.33697280000000001e+03,
              7.65415000000000000e+04 8.33241000000000058e+04 1.28679179999999997e+03,
              8.15415000000000000e+04 8.33241000000000058e+04 1.39144250000000011e+03,
              8.65415000000000000e+04 8.33241000000000058e+04 1.30646890000000008e+03,
              4.34915000000000000e+04 8.32741000000000058e+04 1.36082140000000004e+03,
              4.84915000000000000e+04 8.32741000000000058e+04 1.39067219999999998e+03,
              5.34915000000000000e+04 8.32741000000000058e+04 1.37904960000000005e+03,
              5.84915000000000000e+04 8.32741000000000058e+04 1.31284830000000011e+03,
              6.34915000000000000e+04 8.32741000000000058e+04 1.32290709999999990e+03,
              6.84915000000000000e+04 8.32741000000000058e+04 1.34244880000000012e+03,
              7.34915000000000000e+04 8.32741000000000058e+04 1.36302700000000004e+03,
              7.84915000000000000e+04 8.32741000000000058e+04 1.33060670000000005e+03,
              8.34915000000000000e+04 8.32741000000000058e+04 1.39820010000000002e+03,
              8.84915000000000000e+04 8.32741000000000058e+04 1.28483469999999988e+03,
              4.54415000000000000e+04 8.32241000000000058e+04 1.34745000000000005e+03,
              5.04415000000000000e+04 8.32241000000000058e+04 1.37895920000000001e+03,
              5.54415000000000000e+04 8.32241000000000058e+04 1.39530619999999999e+03,
              6.04415000000000000e+04 8.32241000000000058e+04 1.36033410000000003e+03,
              6.54415000000000000e+04 8.32241000000000058e+04 1.35223739999999998e+03,
              7.04415000000000000e+04 8.32241000000000058e+04 1.34801859999999988e+03,
              7.54415000000000000e+04 8.32241000000000058e+04 1.35974990000000003e+03,
              8.04415000000000000e+04 8.32241000000000058e+04 1.34517700000000013e+03,
              8.54415000000000000e+04 8.32241000000000058e+04 1.33805220000000008e+03,
              9.04415000000000000e+04 8.32241000000000058e+04 1.25240460000000007e+03,
              4.73915000000000000e+04 8.31741000000000058e+04 1.36572000000000003e+03,
              5.23915000000000000e+04 8.31741000000000058e+04 1.39198810000000003e+03,
              5.73915000000000000e+04 8.31741000000000058e+04 1.33324710000000005e+03,
              6.23915000000000000e+04 8.31741000000000058e+04 1.29038679999999999e+03,
              6.73915000000000000e+04 8.31741000000000058e+04 1.36286360000000013e+03,
              7.23915000000000000e+04 8.31741000000000058e+04 1.38537719999999990e+03,
              7.73915000000000000e+04 8.31741000000000058e+04 1.22878510000000006e+03,
              8.23915000000000000e+04 8.31741000000000058e+04 1.40731610000000001e+03,
              8.73915000000000000e+04 8.31741000000000058e+04 1.30921440000000007e+03,
              4.43415000000000000e+04 8.31241000000000058e+04 1.34456449999999995e+03,
              4.93415000000000000e+04 8.31241000000000058e+04 1.39509380000000010e+03,
              5.43415000000000000e+04 8.31241000000000058e+04 1.40266359999999986e+03,
              5.93415000000000000e+04 8.31241000000000058e+04 1.29630150000000003e+03,
              6.43415000000000000e+04 8.31241000000000058e+04 1.33767030000000000e+03,
              6.93415000000000000e+04 8.31241000000000058e+04 1.35019100000000003e+03,
              7.43415000000000000e+04 8.31241000000000058e+04 1.27195290000000000e+03,
              7.93415000000000000e+04 8.31241000000000058e+04 1.39723880000000008e+03,
              8.43415000000000000e+04 8.31241000000000058e+04 1.38041519999999991e+03,
              8.93415000000000000e+04 8.31241000000000058e+04 1.26775080000000003e+03,
              4.62915000000000000e+04 8.30741000000000058e+04 1.34327819999999997e+03,
              5.12915000000000000e+04 8.30741000000000058e+04 1.38618370000000004e+03,
              5.62915000000000000e+04 8.30741000000000058e+04 1.34966339999999991e+03,
              6.12915000000000000e+04 8.30741000000000058e+04 1.31951150000000007e+03,
              6.62915000000000000e+04 8.30741000000000058e+04 1.34293389999999999e+03,
              7.12915000000000000e+04 8.30741000000000058e+04 1.33912480000000005e+03,
              7.62915000000000000e+04 8.30741000000000058e+04 1.37839080000000013e+03,
              8.12915000000000000e+04 8.30741000000000058e+04 1.37897450000000003e+03,
              8.62915000000000000e+04 8.30741000000000058e+04 1.36478490000000011e+03,
              4.32415000000000000e+04 8.30241000000000058e+04 1.35489089999999987e+03,
              4.82415000000000000e+04 8.30241000000000058e+04 1.38242409999999995e+03,
              5.32415000000000000e+04 8.30241000000000058e+04 1.37451409999999987e+03,
              5.82415000000000000e+04 8.30241000000000058e+04 1.31253269999999998e+03,
              6.32415000000000000e+04 8.30241000000000058e+04 1.28676810000000000e+03,
              6.82415000000000000e+04 8.30241000000000058e+04 1.34210500000000002e+03,
              7.32415000000000000e+04 8.30241000000000058e+04 1.35336940000000004e+03,
              7.82415000000000000e+04 8.30241000000000058e+04 1.31180240000000003e+03,
              8.32415000000000000e+04 8.30241000000000058e+04 1.41574949999999990e+03,
              8.82415000000000000e+04 8.30241000000000058e+04 1.28432899999999995e+03,
              4.51915000000000000e+04 8.29741000000000058e+04 1.34343419999999992e+03,
              5.01915000000000000e+04 8.29741000000000058e+04 1.40799450000000002e+03,
              5.51915000000000000e+04 8.29741000000000058e+04 1.41212010000000009e+03,
              6.01915000000000000e+04 8.29741000000000058e+04 1.35485149999999999e+03,
              6.51915000000000000e+04 8.29741000000000058e+04 1.33143849999999998e+03,
              7.01915000000000000e+04 8.29741000000000058e+04 1.34515699999999993e+03,
              7.51915000000000000e+04 8.29741000000000058e+04 1.36224139999999989e+03,
              8.01915000000000000e+04 8.29741000000000058e+04 1.37081680000000006e+03,
              8.51915000000000000e+04 8.29741000000000058e+04 1.37121029999999996e+03,
              9.01915000000000000e+04 8.29741000000000058e+04 1.24481840000000011e+03,
              4.71415000000000000e+04 8.29241000000000058e+04 1.38150060000000008e+03,
              5.21415000000000000e+04 8.29241000000000058e+04 1.39915229999999997e+03,
              5.71415000000000000e+04 8.29241000000000058e+04 1.34972519999999986e+03,
              6.21415000000000000e+04 8.29241000000000058e+04 1.30635100000000011e+03,
              6.71415000000000000e+04 8.29241000000000058e+04 1.36566930000000002e+03,
              7.21415000000000000e+04 8.29241000000000058e+04 1.37671299999999997e+03,
              7.71415000000000000e+04 8.29241000000000058e+04 1.23370249999999987e+03,
              8.21415000000000000e+04 8.29241000000000058e+04 1.41878330000000005e+03,
              8.71415000000000000e+04 8.29241000000000058e+04 1.29173710000000005e+03,
              4.40915000000000000e+04 8.28741000000000058e+04 1.35013020000000006e+03,
              4.90915000000000000e+04 8.28741000000000058e+04 1.40602390000000014e+03,
              5.40915000000000000e+04 8.28741000000000058e+04 1.38241730000000007e+03,
              5.90915000000000000e+04 8.28741000000000058e+04 1.28493209999999999e+03,
              6.40915000000000000e+04 8.28741000000000058e+04 1.30073900000000003e+03,
              6.90915000000000000e+04 8.28741000000000058e+04 1.33886380000000008e+03,
              7.40915000000000000e+04 8.28741000000000058e+04 1.27753330000000005e+03,
              7.90915000000000000e+04 8.28741000000000058e+04 1.39345450000000005e+03,
              8.40915000000000000e+04 8.28741000000000058e+04 1.35811619999999994e+03,
              8.90915000000000000e+04 8.28741000000000058e+04 1.30670180000000005e+03,
              4.60415000000000000e+04 8.28241000000000058e+04 1.34139170000000013e+03,
              5.10415000000000000e+04 8.28241000000000058e+04 1.40028330000000005e+03,
              5.60415000000000000e+04 8.28241000000000058e+04 1.35486480000000006e+03,
              6.10415000000000000e+04 8.28241000000000058e+04 1.35005330000000004e+03,
              6.60415000000000000e+04 8.28241000000000058e+04 1.33931060000000002e+03,
              7.10415000000000000e+04 8.28241000000000058e+04 1.34455919999999992e+03,
              7.60415000000000000e+04 8.28241000000000058e+04 1.38463950000000000e+03,
              8.10415000000000000e+04 8.28241000000000058e+04 1.35789509999999996e+03,
              8.60415000000000000e+04 8.28241000000000058e+04 1.35849060000000009e+03,
              4.29915000000000000e+04 8.27741000000000058e+04 1.34909729999999990e+03,
              4.79915000000000000e+04 8.27741000000000058e+04 1.39480560000000014e+03,
              5.29915000000000000e+04 8.27741000000000058e+04 1.37442160000000013e+03,
              5.79915000000000000e+04 8.27741000000000058e+04 1.31538180000000011e+03,
              6.29915000000000000e+04 8.27741000000000058e+04 1.30599800000000005e+03,
              6.79915000000000000e+04 8.27741000000000058e+04 1.33973640000000000e+03,
              7.29915000000000000e+04 8.27741000000000058e+04 1.35201670000000013e+03,
              7.79915000000000000e+04 8.27741000000000058e+04 1.23672489999999993e+03,
              8.29915000000000000e+04 8.27741000000000058e+04 1.43370479999999998e+03,
              8.79915000000000000e+04 8.27741000000000058e+04 1.28805029999999988e+03,
              4.49415000000000000e+04 8.27241000000000058e+04 1.34240249999999992e+03,
              4.99415000000000000e+04 8.27241000000000058e+04 1.42166470000000004e+03,
              5.49415000000000000e+04 8.27241000000000058e+04 1.39961310000000003e+03,
              5.99415000000000000e+04 8.27241000000000058e+04 1.35963619999999992e+03,
              6.49415000000000000e+04 8.27241000000000058e+04 1.31334159999999997e+03,
              6.99415000000000000e+04 8.27241000000000058e+04 1.34629880000000003e+03,
              7.49415000000000000e+04 8.27241000000000058e+04 1.36431670000000008e+03,
              7.99415000000000000e+04 8.27241000000000058e+04 1.38668200000000002e+03,
              8.49415000000000000e+04 8.27241000000000058e+04 1.34222430000000008e+03,
              8.99415000000000000e+04 8.27241000000000058e+04 1.25244380000000001e+03,
              4.68915000000000000e+04 8.26741000000000058e+04 1.39848090000000002e+03,
              5.18915000000000000e+04 8.26741000000000058e+04 1.38977350000000001e+03,
              5.68915000000000000e+04 8.26741000000000058e+04 1.35132960000000003e+03,
              6.18915000000000000e+04 8.26741000000000058e+04 1.33657940000000008e+03,
              6.68915000000000000e+04 8.26741000000000058e+04 1.37120110000000000e+03,
              7.18915000000000000e+04 8.26741000000000058e+04 1.38407020000000011e+03,
              7.68915000000000000e+04 8.26741000000000058e+04 1.24630590000000007e+03,
              8.18915000000000000e+04 8.26741000000000058e+04 1.38792180000000008e+03,
              8.68915000000000000e+04 8.26741000000000058e+04 1.29741380000000004e+03,
              4.38415000000000000e+04 8.26241000000000058e+04 1.35220470000000000e+03,
              4.88415000000000000e+04 8.26241000000000058e+04 1.40012030000000004e+03,
              5.38415000000000000e+04 8.26241000000000058e+04 1.38003430000000003e+03,
              5.88415000000000000e+04 8.26241000000000058e+04 1.33758230000000003e+03,
              6.38415000000000000e+04 8.26241000000000058e+04 1.31248460000000000e+03,
              6.88415000000000000e+04 8.26241000000000058e+04 1.32997260000000006e+03,
              7.38415000000000000e+04 8.26241000000000058e+04 1.28733799999999997e+03,
              7.88415000000000000e+04 8.26241000000000058e+04 1.39072759999999994e+03,
              8.38415000000000000e+04 8.26241000000000058e+04 1.36400160000000005e+03,
              8.88415000000000000e+04 8.26241000000000058e+04 1.30644380000000001e+03,
              4.57915000000000000e+04 8.25741000000000058e+04 1.33984069999999997e+03,
              5.07915000000000000e+04 8.25741000000000058e+04 1.38363549999999987e+03,
              5.57915000000000000e+04 8.25741000000000058e+04 1.38151490000000013e+03,
              6.07915000000000000e+04 8.25741000000000058e+04 1.35926260000000002e+03,
              6.57915000000000000e+04 8.25741000000000058e+04 1.33844810000000007e+03,
              7.07915000000000000e+04 8.25741000000000058e+04 1.34071980000000008e+03,
              7.57915000000000000e+04 8.25741000000000058e+04 1.38668100000000004e+03,
              8.07915000000000000e+04 8.25741000000000058e+04 1.36118650000000002e+03,
              8.57915000000000000e+04 8.25741000000000058e+04 1.35822579999999994e+03,
              9.07915000000000000e+04 8.25741000000000058e+04 1.23628929999999991e+03,
              4.77415000000000000e+04 8.25241000000000058e+04 1.38371839999999997e+03,
              5.27415000000000000e+04 8.25241000000000058e+04 1.38483940000000007e+03,
              5.77415000000000000e+04 8.25241000000000058e+04 1.34085059999999999e+03,
              6.27415000000000000e+04 8.25241000000000058e+04 1.31002729999999997e+03,
              6.77415000000000000e+04 8.25241000000000058e+04 1.34167970000000014e+03,
              7.27415000000000000e+04 8.25241000000000058e+04 1.37341200000000003e+03,
              7.77415000000000000e+04 8.25241000000000058e+04 1.23193090000000007e+03,
              8.27415000000000000e+04 8.25241000000000058e+04 1.44771710000000007e+03,
              8.77415000000000000e+04 8.25241000000000058e+04 1.30268710000000010e+03,
              4.46915000000000000e+04 8.24741000000000058e+04 1.33898689999999988e+03,
              4.96915000000000000e+04 8.24741000000000058e+04 1.39244190000000003e+03,
              5.46915000000000000e+04 8.24741000000000058e+04 1.38586509999999998e+03,
              5.96915000000000000e+04 8.24741000000000058e+04 1.35192959999999994e+03,
              6.46915000000000000e+04 8.24741000000000058e+04 1.32164490000000001e+03,
              6.96915000000000000e+04 8.24741000000000058e+04 1.34773379999999997e+03,
              7.46915000000000000e+04 8.24741000000000058e+04 1.35162169999999992e+03,
              7.96915000000000000e+04 8.24741000000000058e+04 1.39210919999999987e+03,
              8.46915000000000000e+04 8.24741000000000058e+04 1.33953469999999993e+03,
              8.96915000000000000e+04 8.24741000000000058e+04 1.24970599999999990e+03,
              4.66415000000000000e+04 8.24241000000000058e+04 1.38209110000000010e+03,
              5.16415000000000000e+04 8.24241000000000058e+04 1.39417859999999996e+03,
              5.66415000000000000e+04 8.24241000000000058e+04 1.35651919999999996e+03,
              6.16415000000000000e+04 8.24241000000000058e+04 1.34769299999999998e+03,
              6.66415000000000000e+04 8.24241000000000058e+04 1.35186220000000003e+03,
              7.16415000000000000e+04 8.24241000000000058e+04 1.38241879999999992e+03,
              7.66415000000000000e+04 8.24241000000000058e+04 1.25299679999999989e+03,
              8.16415000000000000e+04 8.24241000000000058e+04 1.41753569999999991e+03,
              8.66415000000000000e+04 8.24241000000000058e+04 1.30708829999999989e+03,
              4.35915000000000000e+04 8.23741000000000058e+04 1.36237370000000010e+03,
              4.85915000000000000e+04 8.23741000000000058e+04 1.37869299999999998e+03,
              5.35915000000000000e+04 8.23741000000000058e+04 1.39379840000000013e+03,
              5.85915000000000000e+04 8.23741000000000058e+04 1.34937269999999990e+03,
              6.35915000000000000e+04 8.23741000000000058e+04 1.32151229999999987e+03,
              6.85915000000000000e+04 8.23741000000000058e+04 1.32954759999999987e+03,
              7.35915000000000000e+04 8.23741000000000058e+04 1.29361900000000014e+03,
              7.85915000000000000e+04 8.23741000000000058e+04 1.33100260000000003e+03,
              8.35915000000000000e+04 8.23741000000000058e+04 1.39142939999999999e+03,
              8.85915000000000000e+04 8.23741000000000058e+04 1.29582240000000002e+03,
              4.55415000000000000e+04 8.23241000000000058e+04 1.34238319999999999e+03,
              5.05415000000000000e+04 8.23241000000000058e+04 1.37954169999999999e+03,
              5.55415000000000000e+04 8.23241000000000058e+04 1.39253410000000008e+03,
              6.05415000000000000e+04 8.23241000000000058e+04 1.35694020000000000e+03,
              6.55415000000000000e+04 8.23241000000000058e+04 1.33147600000000011e+03,
              7.05415000000000000e+04 8.23241000000000058e+04 1.32723820000000001e+03,
              7.55415000000000000e+04 8.23241000000000058e+04 1.37684410000000003e+03,
              8.05415000000000000e+04 8.23241000000000058e+04 1.37580209999999988e+03,
              8.55415000000000000e+04 8.23241000000000058e+04 1.36385310000000004e+03,
              9.05415000000000000e+04 8.23241000000000058e+04 1.23594669999999996e+03,
              4.74915000000000000e+04 8.22741000000000058e+04 1.38677690000000007e+03,
              5.24915000000000000e+04 8.22741000000000058e+04 1.39900520000000006e+03,
              5.74915000000000000e+04 8.22741000000000058e+04 1.33986159999999995e+03,
              6.24915000000000000e+04 8.22741000000000058e+04 1.33215949999999998e+03,
              6.74915000000000000e+04 8.22741000000000058e+04 1.34286439999999993e+03,
              7.24915000000000000e+04 8.22741000000000058e+04 1.35846149999999989e+03,
              7.74915000000000000e+04 8.22741000000000058e+04 1.22759040000000005e+03,
              8.24915000000000000e+04 8.22741000000000058e+04 1.44876089999999999e+03,
              8.74915000000000000e+04 8.22741000000000058e+04 1.29411850000000004e+03,
              4.44415000000000000e+04 8.22241000000000058e+04 1.33950019999999995e+03,
              4.94415000000000000e+04 8.22241000000000058e+04 1.39282889999999998e+03,
              5.44415000000000000e+04 8.22241000000000058e+04 1.39928980000000001e+03,
              5.94415000000000000e+04 8.22241000000000058e+04 1.30523090000000002e+03,
              6.44415000000000000e+04 8.22241000000000058e+04 1.32654999999999995e+03,
              6.94415000000000000e+04 8.22241000000000058e+04 1.34128719999999998e+03,
              7.44415000000000000e+04 8.22241000000000058e+04 1.34469070000000011e+03,
              7.94415000000000000e+04 8.22241000000000058e+04 1.40659249999999997e+03,
              8.44415000000000000e+04 8.22241000000000058e+04 1.36961930000000007e+03,
              8.94415000000000000e+04 8.22241000000000058e+04 1.25804030000000012e+03,
              4.63915000000000000e+04 8.21741000000000058e+04 1.38840000000000009e+03,
              5.13915000000000000e+04 8.21741000000000058e+04 1.37822569999999996e+03,
              5.63915000000000000e+04 8.21741000000000058e+04 1.36588480000000004e+03,
              6.13915000000000000e+04 8.21741000000000058e+04 1.34739699999999993e+03,
              6.63915000000000000e+04 8.21741000000000058e+04 1.34173149999999987e+03,
              7.13915000000000000e+04 8.21741000000000058e+04 1.37901780000000008e+03,
              7.63915000000000000e+04 8.21741000000000058e+04 1.29356089999999995e+03,
              8.13915000000000000e+04 8.21741000000000058e+04 1.41559369999999990e+03,
              8.63915000000000000e+04 8.21741000000000058e+04 1.36961860000000001e+03,
              4.33415000000000000e+04 8.21241000000000058e+04 1.34808190000000013e+03,
              4.83415000000000000e+04 8.21241000000000058e+04 1.37470339999999987e+03,
              5.33415000000000000e+04 8.21241000000000058e+04 1.39664699999999993e+03,
              5.83415000000000000e+04 8.21241000000000058e+04 1.34825219999999990e+03,
              6.33415000000000000e+04 8.21241000000000058e+04 1.30842509999999993e+03,
              6.83415000000000000e+04 8.21241000000000058e+04 1.32207369999999992e+03,
              7.33415000000000000e+04 8.21241000000000058e+04 1.33735960000000000e+03,
              7.83415000000000000e+04 8.21241000000000058e+04 1.25625250000000005e+03,
              8.33415000000000000e+04 8.21241000000000058e+04 1.38004610000000002e+03,
              8.83415000000000000e+04 8.21241000000000058e+04 1.28044000000000005e+03,
              4.52915000000000000e+04 8.20741000000000058e+04 1.36871689999999990e+03,
              5.02915000000000000e+04 8.20741000000000058e+04 1.37035969999999998e+03,
              5.52915000000000000e+04 8.20741000000000058e+04 1.39729030000000012e+03,
              6.02915000000000000e+04 8.20741000000000058e+04 1.34559480000000008e+03,
              6.52915000000000000e+04 8.20741000000000058e+04 1.31591499999999996e+03,
              7.02915000000000000e+04 8.20741000000000058e+04 1.32345879999999988e+03,
              7.52915000000000000e+04 8.20741000000000058e+04 1.38421329999999989e+03,
              8.02915000000000000e+04 8.20741000000000058e+04 1.40229480000000012e+03,
              8.52915000000000000e+04 8.20741000000000058e+04 1.35682799999999997e+03,
              9.02915000000000000e+04 8.20741000000000058e+04 1.23734050000000002e+03,
              4.72415000000000000e+04 8.20241000000000058e+04 1.38116069999999991e+03,
              5.22415000000000000e+04 8.20241000000000058e+04 1.40734200000000010e+03,
              5.72415000000000000e+04 8.20241000000000058e+04 1.34080209999999988e+03,
              6.22415000000000000e+04 8.20241000000000058e+04 1.33805909999999994e+03,
              6.72415000000000000e+04 8.20241000000000058e+04 1.34794900000000007e+03,
              7.22415000000000000e+04 8.20241000000000058e+04 1.35636059999999998e+03,
              7.72415000000000000e+04 8.20241000000000058e+04 1.22913220000000001e+03,
              8.22415000000000000e+04 8.20241000000000058e+04 1.44489390000000003e+03,
              8.72415000000000000e+04 8.20241000000000058e+04 1.29407999999999993e+03,
              4.41915000000000000e+04 8.19741000000000058e+04 1.33850219999999990e+03,
              4.91915000000000000e+04 8.19741000000000058e+04 1.37595180000000005e+03,
              5.41915000000000000e+04 8.19741000000000058e+04 1.39976700000000005e+03,
              5.91915000000000000e+04 8.19741000000000058e+04 1.30792100000000005e+03,
              6.41915000000000000e+04 8.19741000000000058e+04 1.30621270000000004e+03,
              6.91915000000000000e+04 8.19741000000000058e+04 1.33104660000000013e+03,
              7.41915000000000000e+04 8.19741000000000058e+04 1.34189850000000001e+03,
              7.91915000000000000e+04 8.19741000000000058e+04 1.39789010000000007e+03,
              8.41915000000000000e+04 8.19741000000000058e+04 1.37583799999999997e+03,
              8.91915000000000000e+04 8.19741000000000058e+04 1.28807929999999988e+03,
              4.61415000000000000e+04 8.19241000000000058e+04 1.37784210000000007e+03,
              5.11415000000000000e+04 8.19241000000000058e+04 1.38645769999999993e+03,
              5.61415000000000000e+04 8.19241000000000058e+04 1.34567169999999987e+03,
              6.11415000000000000e+04 8.19241000000000058e+04 1.34646830000000000e+03,
              6.61415000000000000e+04 8.19241000000000058e+04 1.34110050000000001e+03,
              7.11415000000000000e+04 8.19241000000000058e+04 1.36907200000000012e+03,
              7.61415000000000000e+04 8.19241000000000058e+04 1.31072780000000012e+03,
              8.11415000000000000e+04 8.19241000000000058e+04 1.38445209999999997e+03,
              8.61415000000000000e+04 8.19241000000000058e+04 1.34804420000000005e+03,
              4.30915000000000000e+04 8.18741000000000058e+04 1.34947060000000010e+03,
              4.80915000000000000e+04 8.18741000000000058e+04 1.36484870000000001e+03,
              5.30915000000000000e+04 8.18741000000000058e+04 1.40822350000000006e+03,
              5.80915000000000000e+04 8.18741000000000058e+04 1.35853040000000010e+03,
              6.30915000000000000e+04 8.18741000000000058e+04 1.32324610000000007e+03,
              6.80915000000000000e+04 8.18741000000000058e+04 1.32108199999999988e+03,
              7.30915000000000000e+04 8.18741000000000058e+04 1.34769100000000003e+03,
              7.80915000000000000e+04 8.18741000000000058e+04 1.23747630000000004e+03,
              8.30915000000000000e+04 8.18741000000000058e+04 1.38841879999999992e+03,
              8.80915000000000000e+04 8.18741000000000058e+04 1.29502559999999994e+03,
              4.50415000000000000e+04 8.18241000000000058e+04 1.36245409999999993e+03,
              5.00415000000000000e+04 8.18241000000000058e+04 1.37081979999999999e+03,
              5.50415000000000000e+04 8.18241000000000058e+04 1.39401150000000007e+03,
              6.00415000000000000e+04 8.18241000000000058e+04 1.30313860000000000e+03,
              6.50415000000000000e+04 8.18241000000000058e+04 1.30675230000000010e+03,
              7.00415000000000000e+04 8.18241000000000058e+04 1.31842030000000000e+03,
              7.50415000000000000e+04 8.18241000000000058e+04 1.38716049999999996e+03,
              8.00415000000000000e+04 8.18241000000000058e+04 1.38857159999999999e+03,
              8.50415000000000000e+04 8.18241000000000058e+04 1.34507249999999999e+03,
              9.00415000000000000e+04 8.18241000000000058e+04 1.23709130000000005e+03,
              4.69915000000000000e+04 8.17741000000000058e+04 1.38929739999999993e+03,
              5.19915000000000000e+04 8.17741000000000058e+04 1.41251199999999994e+03,
              5.69915000000000000e+04 8.17741000000000058e+04 1.33163439999999991e+03,
              6.19915000000000000e+04 8.17741000000000058e+04 1.34160799999999995e+03,
              6.69915000000000000e+04 8.17741000000000058e+04 1.34369610000000011e+03,
              7.19915000000000000e+04 8.17741000000000058e+04 1.38056259999999997e+03,
              7.69915000000000000e+04 8.17741000000000058e+04 1.23112010000000009e+03,
              8.19915000000000000e+04 8.17741000000000058e+04 1.41379039999999986e+03,
              8.69915000000000000e+04 8.17741000000000058e+04 1.30400680000000011e+03,
              4.39415000000000000e+04 8.17241000000000058e+04 1.33817399999999998e+03,
              4.89415000000000000e+04 8.17241000000000058e+04 1.38601850000000013e+03,
              5.39415000000000000e+04 8.17241000000000058e+04 1.38990499999999997e+03,
              5.89415000000000000e+04 8.17241000000000058e+04 1.30970440000000008e+03,
              6.39415000000000000e+04 8.17241000000000058e+04 1.30017470000000003e+03,
              6.89415000000000000e+04 8.17241000000000058e+04 1.32061770000000001e+03,
              7.39415000000000000e+04 8.17241000000000058e+04 1.34201379999999995e+03,
              7.89415000000000000e+04 8.17241000000000058e+04 1.41230070000000001e+03,
              8.39415000000000000e+04 8.17241000000000058e+04 1.37108619999999996e+03,
              8.89415000000000000e+04 8.17241000000000058e+04 1.30624700000000007e+03,
              4.58915000000000000e+04 8.16741000000000058e+04 1.38200540000000001e+03,
              5.08915000000000000e+04 8.16741000000000058e+04 1.38763270000000011e+03,
              5.58915000000000000e+04 8.16741000000000058e+04 1.37765159999999992e+03,
              6.08915000000000000e+04 8.16741000000000058e+04 1.31024040000000014e+03,
              6.58915000000000000e+04 8.16741000000000058e+04 1.32746949999999993e+03,
              7.08915000000000000e+04 8.16741000000000058e+04 1.35572810000000004e+03,
              7.58915000000000000e+04 8.16741000000000058e+04 1.26368699999999990e+03,
              8.08915000000000000e+04 8.16741000000000058e+04 1.37294820000000004e+03,
              8.58915000000000000e+04 8.16741000000000058e+04 1.30953859999999986e+03,
              9.08915000000000000e+04 8.16741000000000058e+04 1.28798669999999993e+03,
              4.78415000000000000e+04 8.16241000000000058e+04 1.35374780000000010e+03,
              5.28415000000000000e+04 8.16241000000000058e+04 1.43795440000000008e+03,
              5.78415000000000000e+04 8.16241000000000058e+04 1.35811840000000007e+03,
              6.28415000000000000e+04 8.16241000000000058e+04 1.32949260000000004e+03,
              6.78415000000000000e+04 8.16241000000000058e+04 1.32739560000000006e+03,
              7.28415000000000000e+04 8.16241000000000058e+04 1.32817489999999998e+03,
              7.78415000000000000e+04 8.16241000000000058e+04 1.23386490000000003e+03,
              8.28415000000000000e+04 8.16241000000000058e+04 1.42141149999999993e+03,
              8.78415000000000000e+04 8.16241000000000058e+04 1.29107429999999999e+03,
              4.47915000000000000e+04 8.15741000000000058e+04 1.36149019999999996e+03,
              4.97915000000000000e+04 8.15741000000000058e+04 1.37492920000000004e+03,
              5.47915000000000000e+04 8.15741000000000058e+04 1.39889429999999993e+03,
              5.97915000000000000e+04 8.15741000000000058e+04 1.31134950000000003e+03,
              6.47915000000000000e+04 8.15741000000000058e+04 1.32143209999999999e+03,
              6.97915000000000000e+04 8.15741000000000058e+04 1.31621389999999997e+03,
              7.47915000000000000e+04 8.15741000000000058e+04 1.37604539999999997e+03,
              7.97915000000000000e+04 8.15741000000000058e+04 1.38582569999999987e+03,
              8.47915000000000000e+04 8.15741000000000058e+04 1.36616640000000007e+03,
              8.97915000000000000e+04 8.15741000000000058e+04 1.24282940000000008e+03,
              4.67415000000000000e+04 8.15241000000000058e+04 1.38590290000000005e+03,
              5.17415000000000000e+04 8.15241000000000058e+04 1.42015630000000010e+03,
              5.67415000000000000e+04 8.15241000000000058e+04 1.33902549999999997e+03,
              6.17415000000000000e+04 8.15241000000000058e+04 1.32855269999999996e+03,
              6.67415000000000000e+04 8.15241000000000058e+04 1.33378629999999998e+03,
              7.17415000000000000e+04 8.15241000000000058e+04 1.36611009999999987e+03,
              7.67415000000000000e+04 8.15241000000000058e+04 1.22813259999999991e+03,
              8.17415000000000000e+04 8.15241000000000058e+04 1.38716200000000003e+03,
              8.67415000000000000e+04 8.15241000000000058e+04 1.31940460000000007e+03,
              4.36915000000000000e+04 8.14741000000000058e+04 1.33805060000000003e+03,
              4.86915000000000000e+04 8.14741000000000058e+04 1.36766679999999997e+03,
              5.36915000000000000e+04 8.14741000000000058e+04 1.41035040000000004e+03,
              5.86915000000000000e+04 8.14741000000000058e+04 1.34412020000000007e+03,
              6.36915000000000000e+04 8.14741000000000058e+04 1.32611189999999988e+03,
              6.86915000000000000e+04 8.14741000000000058e+04 1.31463570000000004e+03,
              7.36915000000000000e+04 8.14741000000000058e+04 1.33898219999999992e+03,
              7.86915000000000000e+04 8.14741000000000058e+04 1.39539449999999988e+03,
              8.36915000000000000e+04 8.14741000000000058e+04 1.37241930000000002e+03,
              8.86915000000000000e+04 8.14741000000000058e+04 1.29499669999999992e+03,
              4.56415000000000000e+04 8.14241000000000058e+04 1.37584140000000002e+03,
              5.06415000000000000e+04 8.14241000000000058e+04 1.39644450000000006e+03,
              5.56415000000000000e+04 8.14241000000000058e+04 1.37808439999999996e+03,
              6.06415000000000000e+04 8.14241000000000058e+04 1.30307580000000007e+03,
              6.56415000000000000e+04 8.14241000000000058e+04 1.30868769999999995e+03,
              7.06415000000000000e+04 8.14241000000000058e+04 1.34007060000000001e+03,
              7.56415000000000000e+04 8.14241000000000058e+04 1.28253230000000008e+03,
              8.06415000000000000e+04 8.14241000000000058e+04 1.39249960000000010e+03,
              8.56415000000000000e+04 8.14241000000000058e+04 1.28416229999999996e+03,
              9.06415000000000000e+04 8.14241000000000058e+04 1.28802579999999989e+03,
              4.75915000000000000e+04 8.13741000000000058e+04 1.34956390000000010e+03,
              5.25915000000000000e+04 8.13741000000000058e+04 1.44902279999999996e+03,
              5.75915000000000000e+04 8.13741000000000058e+04 1.33518869999999993e+03,
              6.25915000000000000e+04 8.13741000000000058e+04 1.32516059999999993e+03,
              6.75915000000000000e+04 8.13741000000000058e+04 1.32037320000000000e+03,
              7.25915000000000000e+04 8.13741000000000058e+04 1.32878989999999999e+03,
              7.75915000000000000e+04 8.13741000000000058e+04 1.23426140000000009e+03,
              8.25915000000000000e+04 8.13741000000000058e+04 1.42779109999999991e+03,
              8.75915000000000000e+04 8.13741000000000058e+04 1.30486930000000007e+03,
              4.45415000000000000e+04 8.13241000000000058e+04 1.34566419999999994e+03,
              4.95415000000000000e+04 8.13241000000000058e+04 1.38050739999999996e+03,
              5.45415000000000000e+04 8.13241000000000058e+04 1.40345450000000005e+03,
              5.95415000000000000e+04 8.13241000000000058e+04 1.34557920000000013e+03,
              6.45415000000000000e+04 8.13241000000000058e+04 1.31179250000000002e+03,
              6.95415000000000000e+04 8.13241000000000058e+04 1.30961630000000014e+03,
              7.45415000000000000e+04 8.13241000000000058e+04 1.36600549999999998e+03,
              7.95415000000000000e+04 8.13241000000000058e+04 1.40246779999999990e+03,
              8.45415000000000000e+04 8.13241000000000058e+04 1.35964949999999999e+03,
              8.95415000000000000e+04 8.13241000000000058e+04 1.25931390000000010e+03,
              4.64915000000000000e+04 8.12741000000000058e+04 1.37978420000000006e+03,
              5.14915000000000000e+04 8.12741000000000058e+04 1.40797489999999993e+03,
              5.64915000000000000e+04 8.12741000000000058e+04 1.37428009999999995e+03,
              6.14915000000000000e+04 8.12741000000000058e+04 1.30998749999999995e+03,
              6.64915000000000000e+04 8.12741000000000058e+04 1.32318579999999997e+03,
              7.14915000000000000e+04 8.12741000000000058e+04 1.36183240000000001e+03,
              7.64915000000000000e+04 8.12741000000000058e+04 1.23204620000000000e+03,
              8.14915000000000000e+04 8.12741000000000058e+04 1.39209249999999997e+03,
              8.64915000000000000e+04 8.12741000000000058e+04 1.31879549999999995e+03,
              4.34415000000000000e+04 8.12241000000000058e+04 1.34333899999999994e+03,
              4.84415000000000000e+04 8.12241000000000058e+04 1.36519349999999986e+03,
              5.34415000000000000e+04 8.12241000000000058e+04 1.42968180000000007e+03,
              5.84415000000000000e+04 8.12241000000000058e+04 1.34412010000000009e+03,
              6.34415000000000000e+04 8.12241000000000058e+04 1.32329629999999997e+03,
              6.84415000000000000e+04 8.12241000000000058e+04 1.31540750000000003e+03,
              7.34415000000000000e+04 8.12241000000000058e+04 1.31716849999999999e+03,
              7.84415000000000000e+04 8.12241000000000058e+04 1.32345360000000005e+03,
              8.34415000000000000e+04 8.12241000000000058e+04 1.38618519999999990e+03,
              8.84415000000000000e+04 8.12241000000000058e+04 1.28200819999999999e+03,
              4.53915000000000000e+04 8.11741000000000058e+04 1.36079320000000007e+03,
              5.03915000000000000e+04 8.11741000000000058e+04 1.38592520000000013e+03,
              5.53915000000000000e+04 8.11741000000000058e+04 1.39659339999999997e+03,
              6.03915000000000000e+04 8.11741000000000058e+04 1.33092810000000009e+03,
              6.53915000000000000e+04 8.11741000000000058e+04 1.32208010000000013e+03,
              7.03915000000000000e+04 8.11741000000000058e+04 1.33594599999999991e+03,
              7.53915000000000000e+04 8.11741000000000058e+04 1.25923649999999998e+03,
              8.03915000000000000e+04 8.11741000000000058e+04 1.43027590000000009e+03,
              8.53915000000000000e+04 8.11741000000000058e+04 1.28611689999999999e+03,
              9.03915000000000000e+04 8.11741000000000058e+04 1.29460400000000004e+03,
              4.73415000000000000e+04 8.11241000000000058e+04 1.35776739999999995e+03,
              5.23415000000000000e+04 8.11241000000000058e+04 1.46612300000000005e+03,
              5.73415000000000000e+04 8.11241000000000058e+04 1.34078230000000008e+03,
              6.23415000000000000e+04 8.11241000000000058e+04 1.31838650000000007e+03,
              6.73415000000000000e+04 8.11241000000000058e+04 1.31482600000000002e+03,
              7.23415000000000000e+04 8.11241000000000058e+04 1.35771379999999999e+03,
              7.73415000000000000e+04 8.11241000000000058e+04 1.23110230000000001e+03,
              8.23415000000000000e+04 8.11241000000000058e+04 1.43979669999999987e+03,
              8.73415000000000000e+04 8.11241000000000058e+04 1.31533999999999992e+03,
              4.42915000000000000e+04 8.10741000000000058e+04 1.34429960000000005e+03,
              4.92915000000000000e+04 8.10741000000000058e+04 1.37192399999999998e+03,
              5.42915000000000000e+04 8.10741000000000058e+04 1.40457150000000001e+03,
              5.92915000000000000e+04 8.10741000000000058e+04 1.34202129999999988e+03,
              6.42915000000000000e+04 8.10741000000000058e+04 1.33300360000000001e+03,
              6.92915000000000000e+04 8.10741000000000058e+04 1.31423680000000013e+03,
              7.42915000000000000e+04 8.10741000000000058e+04 1.35927990000000000e+03,
              7.92915000000000000e+04 8.10741000000000058e+04 1.38145710000000008e+03,
              8.42915000000000000e+04 8.10741000000000058e+04 1.39713959999999997e+03,
              8.92915000000000000e+04 8.10741000000000058e+04 1.24809290000000010e+03,
              4.62415000000000000e+04 8.10241000000000058e+04 1.37195299999999997e+03,
              5.12415000000000000e+04 8.10241000000000058e+04 1.38770800000000008e+03,
              5.62415000000000000e+04 8.10241000000000058e+04 1.35842169999999987e+03,
              6.12415000000000000e+04 8.10241000000000058e+04 1.32211349999999993e+03,
              6.62415000000000000e+04 8.10241000000000058e+04 1.31253479999999990e+03,
              7.12415000000000000e+04 8.10241000000000058e+04 1.35863319999999999e+03,
              7.62415000000000000e+04 8.10241000000000058e+04 1.26041049999999996e+03,
              8.12415000000000000e+04 8.10241000000000058e+04 1.41743949999999995e+03,
              8.62415000000000000e+04 8.10241000000000058e+04 1.32274220000000014e+03,
              4.31915000000000000e+04 8.09741000000000058e+04 1.34323160000000007e+03,
              4.81915000000000000e+04 8.09741000000000058e+04 1.36377669999999989e+03,
              5.31915000000000000e+04 8.09741000000000058e+04 1.42724299999999994e+03,
              5.81915000000000000e+04 8.09741000000000058e+04 1.33167540000000008e+03,
              6.31915000000000000e+04 8.09741000000000058e+04 1.31961869999999999e+03,
              6.81915000000000000e+04 8.09741000000000058e+04 1.31620360000000005e+03,
              7.31915000000000000e+04 8.09741000000000058e+04 1.26266350000000011e+03,
              7.81915000000000000e+04 8.09741000000000058e+04 1.27357629999999995e+03,
              8.31915000000000000e+04 8.09741000000000058e+04 1.40080749999999989e+03,
              8.81915000000000000e+04 8.09741000000000058e+04 1.28955340000000001e+03,
              4.51415000000000000e+04 8.09241000000000058e+04 1.35910550000000012e+03,
              5.01415000000000000e+04 8.09241000000000058e+04 1.37627510000000007e+03,
              5.51415000000000000e+04 8.09241000000000058e+04 1.38702620000000002e+03,
              6.01415000000000000e+04 8.09241000000000058e+04 1.33457690000000002e+03,
              6.51415000000000000e+04 8.09241000000000058e+04 1.32598080000000004e+03,
              7.01415000000000000e+04 8.09241000000000058e+04 1.31407809999999995e+03,
              7.51415000000000000e+04 8.09241000000000058e+04 1.27629669999999987e+03,
              8.01415000000000000e+04 8.09241000000000058e+04 1.42373160000000007e+03,
              8.51415000000000000e+04 8.09241000000000058e+04 1.30527950000000010e+03,
              9.01415000000000000e+04 8.09241000000000058e+04 1.26985899999999992e+03,
              4.70915000000000000e+04 8.08741000000000058e+04 1.34900090000000000e+03,
              5.20915000000000000e+04 8.08741000000000058e+04 1.43677379999999994e+03,
              5.70915000000000000e+04 8.08741000000000058e+04 1.37916560000000004e+03,
              6.20915000000000000e+04 8.08741000000000058e+04 1.30976540000000000e+03,
              6.70915000000000000e+04 8.08741000000000058e+04 1.31727849999999989e+03,
              7.20915000000000000e+04 8.08741000000000058e+04 1.37833989999999994e+03,
              7.70915000000000000e+04 8.08741000000000058e+04 1.23146990000000005e+03,
              8.20915000000000000e+04 8.08741000000000058e+04 1.43866249999999991e+03,
              8.70915000000000000e+04 8.08741000000000058e+04 1.29384020000000010e+03,
              4.40415000000000000e+04 8.08241000000000058e+04 1.35116830000000004e+03,
              4.90415000000000000e+04 8.08241000000000058e+04 1.36519239999999991e+03,
              5.40415000000000000e+04 8.08241000000000058e+04 1.39264239999999995e+03,
              5.90415000000000000e+04 8.08241000000000058e+04 1.34369419999999991e+03,
              6.40415000000000000e+04 8.08241000000000058e+04 1.31861300000000006e+03,
              6.90415000000000000e+04 8.08241000000000058e+04 1.30470830000000001e+03,
              7.40415000000000000e+04 8.08241000000000058e+04 1.34716090000000008e+03,
              7.90415000000000000e+04 8.08241000000000058e+04 1.39815280000000007e+03,
              8.40415000000000000e+04 8.08241000000000058e+04 1.40315779999999995e+03,
              8.90415000000000000e+04 8.08241000000000058e+04 1.26286429999999996e+03,
              4.59915000000000000e+04 8.07741000000000058e+04 1.35053170000000000e+03,
              5.09915000000000000e+04 8.07741000000000058e+04 1.38244499999999994e+03,
              5.59915000000000000e+04 8.07741000000000058e+04 1.36122469999999998e+03,
              6.09915000000000000e+04 8.07741000000000058e+04 1.32138720000000012e+03,
              6.59915000000000000e+04 8.07741000000000058e+04 1.31392570000000001e+03,
              7.09915000000000000e+04 8.07741000000000058e+04 1.32856369999999993e+03,
              7.59915000000000000e+04 8.07741000000000058e+04 1.27298379999999997e+03,
              8.09915000000000000e+04 8.07741000000000058e+04 1.40029109999999991e+03,
              8.59915000000000000e+04 8.07741000000000058e+04 1.30187280000000010e+03,
              9.09915000000000000e+04 8.07741000000000058e+04 1.36829039999999986e+03,
              4.79415000000000000e+04 8.07241000000000058e+04 1.36556739999999991e+03,
              5.29415000000000000e+04 8.07241000000000058e+04 1.42227080000000001e+03,
              5.79415000000000000e+04 8.07241000000000058e+04 1.33876649999999995e+03,
              6.29415000000000000e+04 8.07241000000000058e+04 1.32847399999999993e+03,
              6.79415000000000000e+04 8.07241000000000058e+04 1.31129169999999999e+03,
              7.29415000000000000e+04 8.07241000000000058e+04 1.26350999999999999e+03,
              7.79415000000000000e+04 8.07241000000000058e+04 1.25181919999999991e+03,
              8.29415000000000000e+04 8.07241000000000058e+04 1.41591309999999999e+03,
              8.79415000000000000e+04 8.07241000000000058e+04 1.30254109999999991e+03,
              4.48915000000000000e+04 8.06741000000000058e+04 1.35352990000000000e+03,
              4.98915000000000000e+04 8.06741000000000058e+04 1.38063149999999996e+03,
              5.48915000000000000e+04 8.06741000000000058e+04 1.38455310000000009e+03,
              5.98915000000000000e+04 8.06741000000000058e+04 1.32908339999999998e+03,
              6.48915000000000000e+04 8.06741000000000058e+04 1.32000929999999994e+03,
              6.98915000000000000e+04 8.06741000000000058e+04 1.30402250000000004e+03,
              7.48915000000000000e+04 8.06741000000000058e+04 1.27515889999999990e+03,
              7.98915000000000000e+04 8.06741000000000058e+04 1.41794399999999996e+03,
              8.48915000000000000e+04 8.06741000000000058e+04 1.31394820000000004e+03,
              8.98915000000000000e+04 8.06741000000000058e+04 1.24978600000000006e+03,
              4.68415000000000000e+04 8.06241000000000058e+04 1.36268060000000014e+03,
              5.18415000000000000e+04 8.06241000000000058e+04 1.41538549999999987e+03,
              5.68415000000000000e+04 8.06241000000000058e+04 1.37516010000000006e+03,
              6.18415000000000000e+04 8.06241000000000058e+04 1.30288750000000005e+03,
              6.68415000000000000e+04 8.06241000000000058e+04 1.31672929999999997e+03,
              7.18415000000000000e+04 8.06241000000000058e+04 1.36924939999999992e+03,
              7.68415000000000000e+04 8.06241000000000058e+04 1.23297630000000004e+03,
              8.18415000000000000e+04 8.06241000000000058e+04 1.42546090000000004e+03,
              8.68415000000000000e+04 8.06241000000000058e+04 1.28252559999999994e+03,
              4.37915000000000000e+04 8.05741000000000058e+04 1.35630880000000002e+03,
              4.87915000000000000e+04 8.05741000000000058e+04 1.36396160000000009e+03,
              5.37915000000000000e+04 8.05741000000000058e+04 1.38713349999999991e+03,
              5.87915000000000000e+04 8.05741000000000058e+04 1.34077610000000004e+03,
              6.37915000000000000e+04 8.05741000000000058e+04 1.32906760000000008e+03,
              6.87915000000000000e+04 8.05741000000000058e+04 1.30633590000000004e+03,
              7.37915000000000000e+04 8.05741000000000058e+04 1.35453449999999998e+03,
              7.87915000000000000e+04 8.05741000000000058e+04 1.40500860000000011e+03,
              8.37915000000000000e+04 8.05741000000000058e+04 1.39747360000000003e+03,
              8.87915000000000000e+04 8.05741000000000058e+04 1.26868280000000004e+03,
              4.57415000000000000e+04 8.05241000000000058e+04 1.35450410000000011e+03,
              5.07415000000000000e+04 8.05241000000000058e+04 1.38243730000000005e+03,
              5.57415000000000000e+04 8.05241000000000058e+04 1.37581230000000005e+03,
              6.07415000000000000e+04 8.05241000000000058e+04 1.32893370000000004e+03,
              6.57415000000000000e+04 8.05241000000000058e+04 1.31594889999999987e+03,
              7.07415000000000000e+04 8.05241000000000058e+04 1.33509200000000010e+03,
              7.57415000000000000e+04 8.05241000000000058e+04 1.29181979999999999e+03,
              8.07415000000000000e+04 8.05241000000000058e+04 1.42720069999999987e+03,
              8.57415000000000000e+04 8.05241000000000058e+04 1.26527960000000007e+03,
              9.07415000000000000e+04 8.05241000000000058e+04 1.33379449999999997e+03,
              4.76915000000000000e+04 8.04741000000000058e+04 1.37079750000000013e+03,
              5.26915000000000000e+04 8.04741000000000058e+04 1.40628739999999993e+03,
              5.76915000000000000e+04 8.04741000000000058e+04 1.34278769999999986e+03,
              6.26915000000000000e+04 8.04741000000000058e+04 1.32340000000000009e+03,
              6.76915000000000000e+04 8.04741000000000058e+04 1.31102510000000007e+03,
              7.26915000000000000e+04 8.04741000000000058e+04 1.30014730000000009e+03,
              7.76915000000000000e+04 8.04741000000000058e+04 1.23851880000000006e+03,
              8.26915000000000000e+04 8.04741000000000058e+04 1.41087179999999989e+03,
              8.76915000000000000e+04 8.04741000000000058e+04 1.29079999999999995e+03,
              4.46415000000000000e+04 8.04241000000000058e+04 1.33971489999999994e+03,
              4.96415000000000000e+04 8.04241000000000058e+04 1.37724569999999994e+03,
              5.46415000000000000e+04 8.04241000000000058e+04 1.38020499999999993e+03,
              5.96415000000000000e+04 8.04241000000000058e+04 1.32487239999999997e+03,
              6.46415000000000000e+04 8.04241000000000058e+04 1.33306880000000001e+03,
              6.96415000000000000e+04 8.04241000000000058e+04 1.29957200000000012e+03,
              7.46415000000000000e+04 8.04241000000000058e+04 1.28630040000000008e+03,
              7.96415000000000000e+04 8.04241000000000058e+04 1.41982359999999994e+03,
              8.46415000000000000e+04 8.04241000000000058e+04 1.34042689999999993e+03,
              8.96415000000000000e+04 8.04241000000000058e+04 1.24278089999999997e+03,
              4.65915000000000000e+04 8.03741000000000058e+04 1.37460649999999987e+03,
              5.15915000000000000e+04 8.03741000000000058e+04 1.39864779999999996e+03,
              5.65915000000000000e+04 8.03741000000000058e+04 1.34583169999999996e+03,
              6.15915000000000000e+04 8.03741000000000058e+04 1.31311689999999999e+03,
              6.65915000000000000e+04 8.03741000000000058e+04 1.32327030000000013e+03,
              7.15915000000000000e+04 8.03741000000000058e+04 1.34161720000000014e+03,
              7.65915000000000000e+04 8.03741000000000058e+04 1.25012889999999993e+03,
              8.15915000000000000e+04 8.03741000000000058e+04 1.40839159999999993e+03,
              8.65915000000000000e+04 8.03741000000000058e+04 1.27167830000000004e+03,
              4.35415000000000000e+04 8.03241000000000058e+04 1.35178179999999998e+03,
              4.85415000000000000e+04 8.03241000000000058e+04 1.35949990000000003e+03,
              5.35415000000000000e+04 8.03241000000000058e+04 1.34702080000000001e+03,
              5.85415000000000000e+04 8.03241000000000058e+04 1.34991370000000006e+03,
              6.35415000000000000e+04 8.03241000000000058e+04 1.33168530000000010e+03,
              6.85415000000000000e+04 8.03241000000000058e+04 1.31059940000000006e+03,
              7.35415000000000000e+04 8.03241000000000058e+04 1.35019389999999999e+03,
              7.85415000000000000e+04 8.03241000000000058e+04 1.37932650000000012e+03,
              8.35415000000000000e+04 8.03241000000000058e+04 1.39885570000000007e+03,
              8.85415000000000000e+04 8.03241000000000058e+04 1.25864049999999997e+03,
              4.54915000000000000e+04 8.02741000000000058e+04 1.34897379999999998e+03,
              5.04915000000000000e+04 8.02741000000000058e+04 1.38751580000000013e+03,
              5.54915000000000000e+04 8.02741000000000058e+04 1.35883969999999999e+03,
              6.04915000000000000e+04 8.02741000000000058e+04 1.32810940000000005e+03,
              6.54915000000000000e+04 8.02741000000000058e+04 1.31951009999999997e+03,
              7.04915000000000000e+04 8.02741000000000058e+04 1.32899730000000000e+03,
              7.54915000000000000e+04 8.02741000000000058e+04 1.31267630000000008e+03,
              8.04915000000000000e+04 8.02741000000000058e+04 1.40673149999999987e+03,
              8.54915000000000000e+04 8.02741000000000058e+04 1.27145190000000002e+03,
              9.04915000000000000e+04 8.02741000000000058e+04 1.27235429999999997e+03,
              4.74415000000000000e+04 8.02241000000000058e+04 1.37455749999999989e+03,
              5.24415000000000000e+04 8.02241000000000058e+04 1.37727279999999996e+03,
              5.74415000000000000e+04 8.02241000000000058e+04 1.37311249999999995e+03,
              6.24415000000000000e+04 8.02241000000000058e+04 1.30434850000000006e+03,
              6.74415000000000000e+04 8.02241000000000058e+04 1.31086090000000013e+03,
              7.24415000000000000e+04 8.02241000000000058e+04 1.32636650000000009e+03,
              7.74415000000000000e+04 8.02241000000000058e+04 1.23561020000000008e+03,
              8.24415000000000000e+04 8.02241000000000058e+04 1.40564490000000001e+03,
              8.74415000000000000e+04 8.02241000000000058e+04 1.27373929999999996e+03,
              4.43915000000000000e+04 8.01741000000000058e+04 1.35401540000000000e+03,
              4.93915000000000000e+04 8.01741000000000058e+04 1.38371730000000002e+03,
              5.43915000000000000e+04 8.01741000000000058e+04 1.37284650000000011e+03,
              5.93915000000000000e+04 8.01741000000000058e+04 1.32124720000000002e+03,
              6.43915000000000000e+04 8.01741000000000058e+04 1.32219049999999993e+03,
              6.93915000000000000e+04 8.01741000000000058e+04 1.30479160000000002e+03,
              7.43915000000000000e+04 8.01741000000000058e+04 1.29297309999999993e+03,
              7.93915000000000000e+04 8.01741000000000058e+04 1.40223810000000003e+03,
              8.43915000000000000e+04 8.01741000000000058e+04 1.39355650000000014e+03,
              8.93915000000000000e+04 8.01741000000000058e+04 1.24394139999999993e+03,
              4.63415000000000000e+04 8.01241000000000058e+04 1.36909069999999997e+03,
              5.13415000000000000e+04 8.01241000000000058e+04 1.36479639999999995e+03,
              5.63415000000000000e+04 8.01241000000000058e+04 1.34496070000000009e+03,
              6.13415000000000000e+04 8.01241000000000058e+04 1.33404060000000004e+03,
              6.63415000000000000e+04 8.01241000000000058e+04 1.33031939999999986e+03,
              7.13415000000000000e+04 8.01241000000000058e+04 1.32118630000000007e+03,
              7.63415000000000000e+04 8.01241000000000058e+04 1.26093599999999992e+03,
              8.13415000000000000e+04 8.01241000000000058e+04 1.41136609999999996e+03,
              8.63415000000000000e+04 8.01241000000000058e+04 1.26600779999999986e+03,
              4.32915000000000000e+04 8.00741000000000058e+04 1.35442920000000004e+03,
              4.82915000000000000e+04 8.00741000000000058e+04 1.35889509999999996e+03,
              5.32915000000000000e+04 8.00741000000000058e+04 1.35533460000000014e+03,
              5.82915000000000000e+04 8.00741000000000058e+04 1.32884240000000000e+03,
              6.32915000000000000e+04 8.00741000000000058e+04 1.31218699999999990e+03,
              6.82915000000000000e+04 8.00741000000000058e+04 1.32318299999999999e+03,
              7.32915000000000000e+04 8.00741000000000058e+04 1.35257140000000004e+03,
              7.82915000000000000e+04 8.00741000000000058e+04 1.30289269999999988e+03,
              8.32915000000000000e+04 8.00741000000000058e+04 1.39149009999999998e+03,
              8.82915000000000000e+04 8.00741000000000058e+04 1.25551150000000007e+03,
              4.52415000000000000e+04 8.00241000000000058e+04 1.35134619999999995e+03,
              5.02415000000000000e+04 8.00241000000000058e+04 1.38951160000000004e+03,
              5.52415000000000000e+04 8.00241000000000058e+04 1.36826659999999993e+03,
              6.02415000000000000e+04 8.00241000000000058e+04 1.32416409999999996e+03,
              6.52415000000000000e+04 8.00241000000000058e+04 1.32221630000000005e+03,
              7.02415000000000000e+04 8.00241000000000058e+04 1.32447849999999994e+03,
              7.52415000000000000e+04 8.00241000000000058e+04 1.37694520000000011e+03,
              8.02415000000000000e+04 8.00241000000000058e+04 1.39766599999999994e+03,
              8.52415000000000000e+04 8.00241000000000058e+04 1.33129809999999998e+03,
              9.02415000000000000e+04 8.00241000000000058e+04 1.26444329999999991e+03,
              4.71915000000000000e+04 7.99741000000000058e+04 1.36612850000000003e+03,
              5.21915000000000000e+04 7.99741000000000058e+04 1.35015329999999994e+03,
              5.71915000000000000e+04 7.99741000000000058e+04 1.37061729999999989e+03,
              6.21915000000000000e+04 7.99741000000000058e+04 1.31382860000000005e+03,
              6.71915000000000000e+04 7.99741000000000058e+04 1.32235870000000000e+03,
              7.21915000000000000e+04 7.99741000000000058e+04 1.28414069999999992e+03,
              7.71915000000000000e+04 7.99741000000000058e+04 1.23261989999999992e+03,
              8.21915000000000000e+04 7.99741000000000058e+04 1.41003970000000004e+03,
              8.71915000000000000e+04 7.99741000000000058e+04 1.26954250000000002e+03,
              4.41415000000000000e+04 7.99241000000000058e+04 1.36381690000000003e+03,
              4.91415000000000000e+04 7.99241000000000058e+04 1.38409429999999998e+03,
              5.41415000000000000e+04 7.99241000000000058e+04 1.38142880000000014e+03,
              5.91415000000000000e+04 7.99241000000000058e+04 1.34270939999999996e+03,
              6.41415000000000000e+04 7.99241000000000058e+04 1.32968720000000008e+03,
              6.91415000000000000e+04 7.99241000000000058e+04 1.32052739999999994e+03,
              7.41415000000000000e+04 7.99241000000000058e+04 1.35755400000000009e+03,
              7.91415000000000000e+04 7.99241000000000058e+04 1.39031089999999995e+03,
              8.41415000000000000e+04 7.99241000000000058e+04 1.39855349999999999e+03,
              8.91415000000000000e+04 7.99241000000000058e+04 1.27401670000000013e+03,
              4.60915000000000000e+04 7.98741000000000058e+04 1.37801970000000006e+03,
              5.10915000000000000e+04 7.98741000000000058e+04 1.37469560000000001e+03,
              5.60915000000000000e+04 7.98741000000000058e+04 1.34290670000000000e+03,
              6.10915000000000000e+04 7.98741000000000058e+04 1.32722029999999995e+03,
              6.60915000000000000e+04 7.98741000000000058e+04 1.32428940000000011e+03,
              7.10915000000000000e+04 7.98741000000000058e+04 1.34738120000000004e+03,
              7.60915000000000000e+04 7.98741000000000058e+04 1.27185780000000000e+03,
              8.10915000000000000e+04 7.98741000000000058e+04 1.41198320000000012e+03,
              8.60915000000000000e+04 7.98741000000000058e+04 1.25659349999999995e+03,
              4.30415000000000000e+04 7.98241000000000058e+04 1.34558190000000013e+03,
              4.80415000000000000e+04 7.98241000000000058e+04 1.35453330000000005e+03,
              5.30415000000000000e+04 7.98241000000000058e+04 1.35493789999999990e+03,
              5.80415000000000000e+04 7.98241000000000058e+04 1.32525779999999986e+03,
              6.30415000000000000e+04 7.98241000000000058e+04 1.30362910000000011e+03,
              6.80415000000000000e+04 7.98241000000000058e+04 1.33567520000000013e+03,
              7.30415000000000000e+04 7.98241000000000058e+04 1.35111349999999993e+03,
              7.80415000000000000e+04 7.98241000000000058e+04 1.29506520000000000e+03,
              8.30415000000000000e+04 7.98241000000000058e+04 1.39072769999999991e+03,
              8.80415000000000000e+04 7.98241000000000058e+04 1.26685320000000002e+03,
              4.49915000000000000e+04 7.97741000000000058e+04 1.35108899999999994e+03,
              4.99915000000000000e+04 7.97741000000000058e+04 1.37974009999999998e+03,
              5.49915000000000000e+04 7.97741000000000058e+04 1.36880680000000007e+03,
              5.99915000000000000e+04 7.97741000000000058e+04 1.33618599999999992e+03,
              6.49915000000000000e+04 7.97741000000000058e+04 1.32445589999999993e+03,
              6.99915000000000000e+04 7.97741000000000058e+04 1.30408140000000003e+03,
              7.49915000000000000e+04 7.97741000000000058e+04 1.38313229999999999e+03,
              7.99915000000000000e+04 7.97741000000000058e+04 1.38112550000000010e+03,
              8.49915000000000000e+04 7.97741000000000058e+04 1.37902790000000005e+03,
              8.99915000000000000e+04 7.97741000000000058e+04 1.25220460000000003e+03,
              4.69415000000000000e+04 7.97241000000000058e+04 1.36297640000000001e+03,
              5.19415000000000000e+04 7.97241000000000058e+04 1.33806600000000003e+03,
              5.69415000000000000e+04 7.97241000000000058e+04 1.33799600000000009e+03,
              6.19415000000000000e+04 7.97241000000000058e+04 1.31871730000000002e+03,
              6.69415000000000000e+04 7.97241000000000058e+04 1.31605749999999989e+03,
              7.19415000000000000e+04 7.97241000000000058e+04 1.34247579999999994e+03,
              7.69415000000000000e+04 7.97241000000000058e+04 1.23704639999999995e+03,
              8.19415000000000000e+04 7.97241000000000058e+04 1.40282079999999996e+03,
              8.69415000000000000e+04 7.97241000000000058e+04 1.26417049999999995e+03,
              4.38915000000000000e+04 7.96741000000000058e+04 1.35969170000000008e+03,
              4.88915000000000000e+04 7.96741000000000058e+04 1.38010040000000004e+03,
              5.38915000000000000e+04 7.96741000000000058e+04 1.37303700000000003e+03,
              5.88915000000000000e+04 7.96741000000000058e+04 1.33852129999999988e+03,
              6.38915000000000000e+04 7.96741000000000058e+04 1.32654729999999995e+03,
              6.88915000000000000e+04 7.96741000000000058e+04 1.31499870000000010e+03,
              7.38915000000000000e+04 7.96741000000000058e+04 1.38152960000000007e+03,
              7.88915000000000000e+04 7.96741000000000058e+04 1.38671350000000007e+03,
              8.38915000000000000e+04 7.96741000000000058e+04 1.40320200000000000e+03,
              8.88915000000000000e+04 7.96741000000000058e+04 1.27075459999999998e+03,
              4.58415000000000000e+04 7.96241000000000058e+04 1.36590100000000007e+03,
              5.08415000000000000e+04 7.96241000000000058e+04 1.35832299999999987e+03,
              5.58415000000000000e+04 7.96241000000000058e+04 1.35573410000000013e+03,
              6.08415000000000000e+04 7.96241000000000058e+04 1.32667990000000009e+03,
              6.58415000000000000e+04 7.96241000000000058e+04 1.32271509999999989e+03,
              7.08415000000000000e+04 7.96241000000000058e+04 1.34438770000000000e+03,
              7.58415000000000000e+04 7.96241000000000058e+04 1.33665270000000010e+03,
              8.08415000000000000e+04 7.96241000000000058e+04 1.39812979999999993e+03,
              8.58415000000000000e+04 7.96241000000000058e+04 1.27303539999999998e+03,
              9.08415000000000000e+04 7.96241000000000058e+04 1.32501019999999994e+03,
              4.77915000000000000e+04 7.95741000000000058e+04 1.35334120000000007e+03,
              5.27915000000000000e+04 7.95741000000000058e+04 1.36226700000000005e+03,
              5.77915000000000000e+04 7.95741000000000058e+04 1.34585979999999995e+03,
              6.27915000000000000e+04 7.95741000000000058e+04 1.31284690000000001e+03,
              6.77915000000000000e+04 7.95741000000000058e+04 1.31360960000000000e+03,
              7.27915000000000000e+04 7.95741000000000058e+04 1.35669100000000003e+03,
              7.77915000000000000e+04 7.95741000000000058e+04 1.23826060000000007e+03,
              8.27915000000000000e+04 7.95741000000000058e+04 1.37827099999999996e+03,
              8.77915000000000000e+04 7.95741000000000058e+04 1.25670200000000000e+03,
              4.47415000000000000e+04 7.95241000000000058e+04 1.35285079999999994e+03,
              4.97415000000000000e+04 7.95241000000000058e+04 1.38164879999999994e+03,
              5.47415000000000000e+04 7.95241000000000058e+04 1.36309830000000011e+03,
              5.97415000000000000e+04 7.95241000000000058e+04 1.33845129999999995e+03,
              6.47415000000000000e+04 7.95241000000000058e+04 1.33041020000000003e+03,
              6.97415000000000000e+04 7.95241000000000058e+04 1.29805150000000003e+03,
              7.47415000000000000e+04 7.95241000000000058e+04 1.38383519999999999e+03,
              7.97415000000000000e+04 7.95241000000000058e+04 1.39413200000000006e+03,
              8.47415000000000000e+04 7.95241000000000058e+04 1.36187699999999995e+03,
              8.97415000000000000e+04 7.95241000000000058e+04 1.24877060000000006e+03,
              4.66915000000000000e+04 7.94741000000000058e+04 1.37016560000000004e+03,
              5.16915000000000000e+04 7.94741000000000058e+04 1.34174409999999989e+03,
              5.66915000000000000e+04 7.94741000000000058e+04 1.34390300000000002e+03,
              6.16915000000000000e+04 7.94741000000000058e+04 1.33520280000000002e+03,
              6.66915000000000000e+04 7.94741000000000058e+04 1.31996910000000003e+03,
              7.16915000000000000e+04 7.94741000000000058e+04 1.35387689999999998e+03,
              7.66915000000000000e+04 7.94741000000000058e+04 1.25358330000000001e+03,
              8.16915000000000000e+04 7.94741000000000058e+04 1.39784130000000005e+03,
              8.66915000000000000e+04 7.94741000000000058e+04 1.25960509999999999e+03,
              4.36415000000000000e+04 7.94241000000000058e+04 1.36396749999999997e+03,
              4.86415000000000000e+04 7.94241000000000058e+04 1.38089380000000006e+03,
              5.36415000000000000e+04 7.94241000000000058e+04 1.32733889999999997e+03,
              5.86415000000000000e+04 7.94241000000000058e+04 1.32278220000000010e+03,
              6.36415000000000000e+04 7.94241000000000058e+04 1.30941470000000004e+03,
              6.86415000000000000e+04 7.94241000000000058e+04 1.31802199999999993e+03,
              7.36415000000000000e+04 7.94241000000000058e+04 1.39352340000000004e+03,
              7.86415000000000000e+04 7.94241000000000058e+04 1.39015660000000003e+03,
              8.36415000000000000e+04 7.94241000000000058e+04 1.40638709999999992e+03,
              8.86415000000000000e+04 7.94241000000000058e+04 1.25415490000000000e+03,
              4.55915000000000000e+04 7.93741000000000058e+04 1.36100399999999991e+03,
              5.05915000000000000e+04 7.93741000000000058e+04 1.37180459999999994e+03,
              5.55915000000000000e+04 7.93741000000000058e+04 1.36759729999999990e+03,
              6.05915000000000000e+04 7.93741000000000058e+04 1.32454930000000013e+03,
              6.55915000000000000e+04 7.93741000000000058e+04 1.32161180000000013e+03,
              7.05915000000000000e+04 7.93741000000000058e+04 1.33142859999999996e+03,
              7.55915000000000000e+04 7.93741000000000058e+04 1.36951260000000002e+03,
              8.05915000000000000e+04 7.93741000000000058e+04 1.37963259999999991e+03,
              8.55915000000000000e+04 7.93741000000000058e+04 1.27696409999999992e+03,
              9.05915000000000000e+04 7.93741000000000058e+04 1.29130600000000004e+03,
              4.75415000000000000e+04 7.93241000000000058e+04 1.35747980000000007e+03,
              5.25415000000000000e+04 7.93241000000000058e+04 1.33697450000000003e+03,
              5.75415000000000000e+04 7.93241000000000058e+04 1.33161850000000004e+03,
              6.25415000000000000e+04 7.93241000000000058e+04 1.31896970000000010e+03,
              6.75415000000000000e+04 7.93241000000000058e+04 1.31270830000000001e+03,
              7.25415000000000000e+04 7.93241000000000058e+04 1.35143890000000010e+03,
              7.75415000000000000e+04 7.93241000000000058e+04 1.23229839999999990e+03,
              8.25415000000000000e+04 7.93241000000000058e+04 1.36761779999999999e+03,
              8.75415000000000000e+04 7.93241000000000058e+04 1.24822890000000007e+03,
              4.44915000000000000e+04 7.92741000000000058e+04 1.35931379999999990e+03,
              4.94915000000000000e+04 7.92741000000000058e+04 1.36973779999999988e+03,
              5.44915000000000000e+04 7.92741000000000058e+04 1.36760310000000004e+03,
              5.94915000000000000e+04 7.92741000000000058e+04 1.31970830000000001e+03,
              6.44915000000000000e+04 7.92741000000000058e+04 1.32897060000000010e+03,
              6.94915000000000000e+04 7.92741000000000058e+04 1.30216800000000012e+03,
              7.44915000000000000e+04 7.92741000000000058e+04 1.38157470000000012e+03,
              7.94915000000000000e+04 7.92741000000000058e+04 1.39253629999999998e+03,
              8.44915000000000000e+04 7.92741000000000058e+04 1.40245710000000008e+03,
              8.94915000000000000e+04 7.92741000000000058e+04 1.25150369999999998e+03,
              4.64415000000000000e+04 7.92241000000000058e+04 1.35302489999999989e+03,
              5.14415000000000000e+04 7.92241000000000058e+04 1.34145319999999992e+03,
              5.64415000000000000e+04 7.92241000000000058e+04 1.35746949999999993e+03,
              6.14415000000000000e+04 7.92241000000000058e+04 1.31645929999999998e+03,
              6.64415000000000000e+04 7.92241000000000058e+04 1.32049769999999990e+03,
              7.14415000000000000e+04 7.92241000000000058e+04 1.35899939999999992e+03,
              7.64415000000000000e+04 7.92241000000000058e+04 1.33882999999999993e+03,
              8.14415000000000000e+04 7.92241000000000058e+04 1.37144049999999993e+03,
              8.64415000000000000e+04 7.92241000000000058e+04 1.26622659999999996e+03,
              4.33915000000000000e+04 7.91741000000000058e+04 1.36011729999999989e+03,
              4.83915000000000000e+04 7.91741000000000058e+04 1.37178629999999998e+03,
              5.33915000000000000e+04 7.91741000000000058e+04 1.33205040000000008e+03,
              5.83915000000000000e+04 7.91741000000000058e+04 1.32768409999999994e+03,
              6.33915000000000000e+04 7.91741000000000058e+04 1.30468730000000005e+03,
              6.83915000000000000e+04 7.91741000000000058e+04 1.30358490000000006e+03,
              7.33915000000000000e+04 7.91741000000000058e+04 1.38383539999999994e+03,
              7.83915000000000000e+04 7.91741000000000058e+04 1.37128970000000004e+03,
              8.33915000000000000e+04 7.91741000000000058e+04 1.39677099999999996e+03,
              8.83915000000000000e+04 7.91741000000000058e+04 1.24481960000000004e+03,
              4.53415000000000000e+04 7.91241000000000058e+04 1.36237689999999998e+03,
              5.03415000000000000e+04 7.91241000000000058e+04 1.35361900000000014e+03,
              5.53415000000000000e+04 7.91241000000000058e+04 1.35408269999999993e+03,
              6.03415000000000000e+04 7.91241000000000058e+04 1.31732400000000007e+03,
              6.53415000000000000e+04 7.91241000000000058e+04 1.32857470000000012e+03,
              7.03415000000000000e+04 7.91241000000000058e+04 1.32156680000000006e+03,
              7.53415000000000000e+04 7.91241000000000058e+04 1.37794939999999997e+03,
              8.03415000000000000e+04 7.91241000000000058e+04 1.38367390000000000e+03,
              8.53415000000000000e+04 7.91241000000000058e+04 1.28132799999999997e+03,
              9.03415000000000000e+04 7.91241000000000058e+04 1.27882560000000012e+03,
              4.72915000000000000e+04 7.90741000000000058e+04 1.35325849999999991e+03,
              5.22915000000000000e+04 7.90741000000000058e+04 1.33436770000000001e+03,
              5.72915000000000000e+04 7.90741000000000058e+04 1.33727120000000014e+03,
              6.22915000000000000e+04 7.90741000000000058e+04 1.31053299999999990e+03,
              6.72915000000000000e+04 7.90741000000000058e+04 1.31789080000000013e+03,
              7.22915000000000000e+04 7.90741000000000058e+04 1.34719550000000004e+03,
              7.72915000000000000e+04 7.90741000000000058e+04 1.23743720000000008e+03,
              8.22915000000000000e+04 7.90741000000000058e+04 1.36505189999999993e+03,
              8.72915000000000000e+04 7.90741000000000058e+04 1.25106940000000009e+03,
              4.42415000000000000e+04 7.90241000000000058e+04 1.34235100000000011e+03,
              4.92415000000000000e+04 7.90241000000000058e+04 1.37013310000000001e+03,
              5.42415000000000000e+04 7.90241000000000058e+04 1.35500459999999998e+03,
              5.92415000000000000e+04 7.90241000000000058e+04 1.33416290000000004e+03,
              6.42415000000000000e+04 7.90241000000000058e+04 1.31717360000000008e+03,
              6.92415000000000000e+04 7.90241000000000058e+04 1.30030420000000004e+03,
              7.42415000000000000e+04 7.90241000000000058e+04 1.38167570000000001e+03,
              7.92415000000000000e+04 7.90241000000000058e+04 1.36781410000000005e+03,
              8.42415000000000000e+04 7.90241000000000058e+04 1.39717679999999996e+03,
              8.92415000000000000e+04 7.90241000000000058e+04 1.25977469999999994e+03,
              4.61915000000000000e+04 7.89741000000000058e+04 1.35605020000000013e+03,
              5.11915000000000000e+04 7.89741000000000058e+04 1.33203940000000011e+03,
              5.61915000000000000e+04 7.89741000000000058e+04 1.35579530000000000e+03,
              6.11915000000000000e+04 7.89741000000000058e+04 1.31630770000000007e+03,
              6.61915000000000000e+04 7.89741000000000058e+04 1.31688279999999986e+03,
              7.11915000000000000e+04 7.89741000000000058e+04 1.34611360000000013e+03,
              7.61915000000000000e+04 7.89741000000000058e+04 1.34956600000000003e+03,
              8.11915000000000000e+04 7.89741000000000058e+04 1.34452459999999996e+03,
              8.61915000000000000e+04 7.89741000000000058e+04 1.28403719999999998e+03,
              4.31415000000000000e+04 7.89241000000000058e+04 1.36513200000000006e+03,
              4.81415000000000000e+04 7.89241000000000058e+04 1.35468589999999995e+03,
              5.31415000000000000e+04 7.89241000000000058e+04 1.32897519999999986e+03,
              5.81415000000000000e+04 7.89241000000000058e+04 1.33646080000000006e+03,
              6.31415000000000000e+04 7.89241000000000058e+04 1.31234840000000008e+03,
              6.81415000000000000e+04 7.89241000000000058e+04 1.30723009999999999e+03,
              7.31415000000000000e+04 7.89241000000000058e+04 1.37837560000000008e+03,
              7.81415000000000000e+04 7.89241000000000058e+04 1.30724330000000009e+03,
              8.31415000000000000e+04 7.89241000000000058e+04 1.38819439999999986e+03,
              8.81415000000000000e+04 7.89241000000000058e+04 1.24642609999999991e+03,
              4.50915000000000000e+04 7.88741000000000058e+04 1.36729530000000000e+03,
              5.00915000000000000e+04 7.88741000000000058e+04 1.37337069999999994e+03,
              5.50915000000000000e+04 7.88741000000000058e+04 1.35064769999999999e+03,
              6.00915000000000000e+04 7.88741000000000058e+04 1.32825869999999986e+03,
              6.50915000000000000e+04 7.88741000000000058e+04 1.33350739999999996e+03,
              7.00915000000000000e+04 7.88741000000000058e+04 1.31368550000000005e+03,
              7.50915000000000000e+04 7.88741000000000058e+04 1.36778410000000008e+03,
              8.00915000000000000e+04 7.88741000000000058e+04 1.38891669999999999e+03,
              8.50915000000000000e+04 7.88741000000000058e+04 1.30588670000000002e+03,
              9.00915000000000000e+04 7.88741000000000058e+04 1.27591899999999987e+03,
              4.70415000000000000e+04 7.88241000000000058e+04 1.34929590000000007e+03,
              5.20415000000000000e+04 7.88241000000000058e+04 1.32435410000000002e+03,
              5.70415000000000000e+04 7.88241000000000058e+04 1.34065580000000000e+03,
              6.20415000000000000e+04 7.88241000000000058e+04 1.31882819999999992e+03,
              6.70415000000000000e+04 7.88241000000000058e+04 1.30834259999999995e+03,
              7.20415000000000000e+04 7.88241000000000058e+04 1.34119409999999993e+03,
              7.70415000000000000e+04 7.88241000000000058e+04 1.25912509999999997e+03,
              8.20415000000000000e+04 7.88241000000000058e+04 1.37760860000000002e+03,
              8.70415000000000000e+04 7.88241000000000058e+04 1.26008150000000001e+03,
              4.39915000000000000e+04 7.87741000000000058e+04 1.34729710000000000e+03,
              4.89915000000000000e+04 7.87741000000000058e+04 1.35996090000000004e+03,
              5.39915000000000000e+04 7.87741000000000058e+04 1.34343119999999999e+03,
              5.89915000000000000e+04 7.87741000000000058e+04 1.33397280000000001e+03,
              6.39915000000000000e+04 7.87741000000000058e+04 1.31729739999999993e+03,
              6.89915000000000000e+04 7.87741000000000058e+04 1.31104169999999999e+03,
              7.39915000000000000e+04 7.87741000000000058e+04 1.36462550000000010e+03,
              7.89915000000000000e+04 7.87741000000000058e+04 1.34091139999999996e+03,
              8.39915000000000000e+04 7.87741000000000058e+04 1.38461540000000014e+03,
              8.89915000000000000e+04 7.87741000000000058e+04 1.27036439999999993e+03,
              4.59415000000000000e+04 7.87241000000000058e+04 1.38210550000000012e+03,
              5.09415000000000000e+04 7.87241000000000058e+04 1.33572280000000001e+03,
              5.59415000000000000e+04 7.87241000000000058e+04 1.34195800000000008e+03,
              6.09415000000000000e+04 7.87241000000000058e+04 1.33014899999999989e+03,
              6.59415000000000000e+04 7.87241000000000058e+04 1.31120749999999998e+03,
              7.09415000000000000e+04 7.87241000000000058e+04 1.31721119999999996e+03,
              7.59415000000000000e+04 7.87241000000000058e+04 1.36764400000000001e+03,
              8.09415000000000000e+04 7.87241000000000058e+04 1.37279169999999999e+03,
              8.59415000000000000e+04 7.87241000000000058e+04 1.29802019999999993e+03,
              9.09415000000000000e+04 7.87241000000000058e+04 1.30577230000000009e+03,
              4.78915000000000000e+04 7.86741000000000058e+04 1.35664920000000006e+03,
              5.28915000000000000e+04 7.86741000000000058e+04 1.31770859999999993e+03,
              5.78915000000000000e+04 7.86741000000000058e+04 1.34294129999999996e+03,
              6.28915000000000000e+04 7.86741000000000058e+04 1.31575440000000003e+03,
              6.78915000000000000e+04 7.86741000000000058e+04 1.31192139999999995e+03,
              7.28915000000000000e+04 7.86741000000000058e+04 1.37356559999999990e+03,
              7.78915000000000000e+04 7.86741000000000058e+04 1.26205119999999988e+03,
              8.28915000000000000e+04 7.86741000000000058e+04 1.38942540000000008e+03,
              8.78915000000000000e+04 7.86741000000000058e+04 1.24763490000000002e+03,
              4.48415000000000000e+04 7.86241000000000058e+04 1.34554060000000004e+03,
              4.98415000000000000e+04 7.86241000000000058e+04 1.35675000000000000e+03,
              5.48415000000000000e+04 7.86241000000000058e+04 1.34662940000000003e+03,
              5.98415000000000000e+04 7.86241000000000058e+04 1.33777909999999997e+03,
              6.48415000000000000e+04 7.86241000000000058e+04 1.33283060000000000e+03,
              6.98415000000000000e+04 7.86241000000000058e+04 1.33978410000000008e+03,
              7.48415000000000000e+04 7.86241000000000058e+04 1.35827729999999997e+03,
              7.98415000000000000e+04 7.86241000000000058e+04 1.31099360000000001e+03,
              8.48415000000000000e+04 7.86241000000000058e+04 1.38070229999999992e+03,
              8.98415000000000000e+04 7.86241000000000058e+04 1.29639480000000003e+03,
              4.67915000000000000e+04 7.85741000000000058e+04 1.37271849999999995e+03,
              5.17915000000000000e+04 7.85741000000000058e+04 1.33218939999999998e+03,
              5.67915000000000000e+04 7.85741000000000058e+04 1.34562629999999990e+03,
              6.17915000000000000e+04 7.85741000000000058e+04 1.32378040000000010e+03,
              6.67915000000000000e+04 7.85741000000000058e+04 1.30284969999999998e+03,
              7.17915000000000000e+04 7.85741000000000058e+04 1.31692689999999993e+03,
              7.67915000000000000e+04 7.85741000000000058e+04 1.34187869999999998e+03,
              8.17915000000000000e+04 7.85741000000000058e+04 1.37121890000000008e+03,
              8.67915000000000000e+04 7.85741000000000058e+04 1.26893190000000004e+03,
              4.37415000000000000e+04 7.85241000000000058e+04 1.33800970000000007e+03,
              4.87415000000000000e+04 7.85241000000000058e+04 1.36335310000000004e+03,
              5.37415000000000000e+04 7.85241000000000058e+04 1.31183010000000013e+03,
              5.87415000000000000e+04 7.85241000000000058e+04 1.33576220000000012e+03,
              6.37415000000000000e+04 7.85241000000000058e+04 1.31205400000000009e+03,
              6.87415000000000000e+04 7.85241000000000058e+04 1.30502990000000000e+03,
              7.37415000000000000e+04 7.85241000000000058e+04 1.34556199999999990e+03,
              7.87415000000000000e+04 7.85241000000000058e+04 1.31226520000000005e+03,
              8.37415000000000000e+04 7.85241000000000058e+04 1.38095730000000003e+03,
              8.87415000000000000e+04 7.85241000000000058e+04 1.27611670000000004e+03,
              4.56915000000000000e+04 7.84741000000000058e+04 1.35940879999999993e+03,
              5.06915000000000000e+04 7.84741000000000058e+04 1.33304919999999993e+03,
              5.56915000000000000e+04 7.84741000000000058e+04 1.33516769999999997e+03,
              6.06915000000000000e+04 7.84741000000000058e+04 1.33854539999999997e+03,
              6.56915000000000000e+04 7.84741000000000058e+04 1.30632590000000005e+03,
              7.06915000000000000e+04 7.84741000000000058e+04 1.31999009999999998e+03,
              7.56915000000000000e+04 7.84741000000000058e+04 1.36707580000000007e+03,
              8.06915000000000000e+04 7.84741000000000058e+04 1.37875360000000001e+03,
              8.56915000000000000e+04 7.84741000000000058e+04 1.35749080000000004e+03,
              9.06915000000000000e+04 7.84741000000000058e+04 1.29973710000000005e+03,
              4.76415000000000000e+04 7.84241000000000058e+04 1.35440370000000007e+03,
              5.26415000000000000e+04 7.84241000000000058e+04 1.32104189999999994e+03,
              5.76415000000000000e+04 7.84241000000000058e+04 1.33528780000000006e+03,
              6.26415000000000000e+04 7.84241000000000058e+04 1.31471499999999992e+03,
              6.76415000000000000e+04 7.84241000000000058e+04 1.30135699999999997e+03,
              7.26415000000000000e+04 7.84241000000000058e+04 1.37756320000000005e+03,
              7.76415000000000000e+04 7.84241000000000058e+04 1.23745509999999990e+03,
              8.26415000000000000e+04 7.84241000000000058e+04 1.37491869999999994e+03,
              8.76415000000000000e+04 7.84241000000000058e+04 1.25139550000000008e+03,
              4.45915000000000000e+04 7.83741000000000058e+04 1.35320479999999998e+03,
              4.95915000000000000e+04 7.83741000000000058e+04 1.36131529999999998e+03,
              5.45915000000000000e+04 7.83741000000000058e+04 1.34043939999999998e+03,
              5.95915000000000000e+04 7.83741000000000058e+04 1.34119820000000004e+03,
              6.45915000000000000e+04 7.83741000000000058e+04 1.32536519999999996e+03,
              6.95915000000000000e+04 7.83741000000000058e+04 1.33541399999999999e+03,
              7.45915000000000000e+04 7.83741000000000058e+04 1.36050599999999986e+03,
              7.95915000000000000e+04 7.83741000000000058e+04 1.36672150000000011e+03,
              8.45915000000000000e+04 7.83741000000000058e+04 1.37370659999999998e+03,
              8.95915000000000000e+04 7.83741000000000058e+04 1.33995910000000003e+03,
              4.65415000000000000e+04 7.83241000000000058e+04 1.36409979999999996e+03,
              5.15415000000000000e+04 7.83241000000000058e+04 1.32532479999999987e+03,
              5.65415000000000000e+04 7.83241000000000058e+04 1.33843409999999994e+03,
              6.15415000000000000e+04 7.83241000000000058e+04 1.33265159999999992e+03,
              6.65415000000000000e+04 7.83241000000000058e+04 1.30373050000000012e+03,
              7.15415000000000000e+04 7.83241000000000058e+04 1.31455430000000001e+03,
              7.65415000000000000e+04 7.83241000000000058e+04 1.33521900000000005e+03,
              8.15415000000000000e+04 7.83241000000000058e+04 1.36716849999999999e+03,
              8.65415000000000000e+04 7.83241000000000058e+04 1.28811169999999993e+03,
              4.34915000000000000e+04 7.82741000000000058e+04 1.33360960000000000e+03,
              4.84915000000000000e+04 7.82741000000000058e+04 1.37396790000000010e+03,
              5.34915000000000000e+04 7.82741000000000058e+04 1.31498270000000002e+03,
              5.84915000000000000e+04 7.82741000000000058e+04 1.32531230000000005e+03,
              6.34915000000000000e+04 7.82741000000000058e+04 1.31411020000000008e+03,
              6.84915000000000000e+04 7.82741000000000058e+04 1.30214200000000005e+03,
              7.34915000000000000e+04 7.82741000000000058e+04 1.34832200000000012e+03,
              7.84915000000000000e+04 7.82741000000000058e+04 1.28111760000000004e+03,
              8.34915000000000000e+04 7.82741000000000058e+04 1.39159930000000008e+03,
              8.84915000000000000e+04 7.82741000000000058e+04 1.27978019999999992e+03,
              4.54415000000000000e+04 7.82241000000000058e+04 1.34860860000000002e+03,
              5.04415000000000000e+04 7.82241000000000058e+04 1.34108690000000001e+03,
              5.54415000000000000e+04 7.82241000000000058e+04 1.33175909999999999e+03,
              6.04415000000000000e+04 7.82241000000000058e+04 1.32643710000000010e+03,
              6.54415000000000000e+04 7.82241000000000058e+04 1.31009699999999998e+03,
              7.04415000000000000e+04 7.82241000000000058e+04 1.32306930000000011e+03,
              7.54415000000000000e+04 7.82241000000000058e+04 1.36312950000000001e+03,
              8.04415000000000000e+04 7.82241000000000058e+04 1.36523460000000000e+03,
              8.54415000000000000e+04 7.82241000000000058e+04 1.36219619999999986e+03,
              9.04415000000000000e+04 7.82241000000000058e+04 1.32342110000000002e+03,
              4.73915000000000000e+04 7.81741000000000058e+04 1.35832500000000005e+03,
              5.23915000000000000e+04 7.81741000000000058e+04 1.30988349999999991e+03,
              5.73915000000000000e+04 7.81741000000000058e+04 1.33472309999999993e+03,
              6.23915000000000000e+04 7.81741000000000058e+04 1.31134809999999993e+03,
              6.73915000000000000e+04 7.81741000000000058e+04 1.31521749999999997e+03,
              7.23915000000000000e+04 7.81741000000000058e+04 1.36221080000000006e+03,
              7.73915000000000000e+04 7.81741000000000058e+04 1.25346019999999999e+03,
              8.23915000000000000e+04 7.81741000000000058e+04 1.37278659999999991e+03,
              8.73915000000000000e+04 7.81741000000000058e+04 1.26458379999999988e+03,
              4.43415000000000000e+04 7.81241000000000058e+04 1.33850869999999986e+03,
              4.93415000000000000e+04 7.81241000000000058e+04 1.37254150000000004e+03,
              5.43415000000000000e+04 7.81241000000000058e+04 1.33838499999999999e+03,
              5.93415000000000000e+04 7.81241000000000058e+04 1.33857920000000013e+03,
              6.43415000000000000e+04 7.81241000000000058e+04 1.32699469999999997e+03,
              6.93415000000000000e+04 7.81241000000000058e+04 1.32260879999999997e+03,
              7.43415000000000000e+04 7.81241000000000058e+04 1.35846839999999997e+03,
              7.93415000000000000e+04 7.81241000000000058e+04 1.36420910000000003e+03,
              8.43415000000000000e+04 7.81241000000000058e+04 1.36762599999999998e+03,
              8.93415000000000000e+04 7.81241000000000058e+04 1.34957909999999993e+03,
              4.62915000000000000e+04 7.80741000000000058e+04 1.37883120000000008e+03,
              5.12915000000000000e+04 7.80741000000000058e+04 1.31700090000000000e+03,
              5.62915000000000000e+04 7.80741000000000058e+04 1.32873759999999993e+03,
              6.12915000000000000e+04 7.80741000000000058e+04 1.32423260000000005e+03,
              6.62915000000000000e+04 7.80741000000000058e+04 1.30807349999999997e+03,
              7.12915000000000000e+04 7.80741000000000058e+04 1.31314969999999994e+03,
              7.62915000000000000e+04 7.80741000000000058e+04 1.31825299999999993e+03,
              8.12915000000000000e+04 7.80741000000000058e+04 1.36717259999999987e+03,
              8.62915000000000000e+04 7.80741000000000058e+04 1.28287699999999995e+03,
              4.32415000000000000e+04 7.80241000000000058e+04 1.33952189999999996e+03,
              4.82415000000000000e+04 7.80241000000000058e+04 1.36916640000000007e+03,
              5.32415000000000000e+04 7.80241000000000058e+04 1.30695080000000007e+03,
              5.82415000000000000e+04 7.80241000000000058e+04 1.32784449999999993e+03,
              6.32415000000000000e+04 7.80241000000000058e+04 1.30983280000000013e+03,
              6.82415000000000000e+04 7.80241000000000058e+04 1.30086410000000001e+03,
              7.32415000000000000e+04 7.80241000000000058e+04 1.35686329999999998e+03,
              7.82415000000000000e+04 7.80241000000000058e+04 1.25060220000000004e+03,
              8.32415000000000000e+04 7.80241000000000058e+04 1.38031340000000000e+03,
              8.82415000000000000e+04 7.80241000000000058e+04 1.26244560000000001e+03,
              4.51915000000000000e+04 7.79741000000000058e+04 1.35288319999999999e+03,
              5.01915000000000000e+04 7.79741000000000058e+04 1.33497990000000004e+03,
              5.51915000000000000e+04 7.79741000000000058e+04 1.32878729999999996e+03,
              6.01915000000000000e+04 7.79741000000000058e+04 1.33388239999999996e+03,
              6.51915000000000000e+04 7.79741000000000058e+04 1.31406600000000003e+03,
              7.01915000000000000e+04 7.79741000000000058e+04 1.32017329999999993e+03,
              7.51915000000000000e+04 7.79741000000000058e+04 1.34961339999999996e+03,
              8.01915000000000000e+04 7.79741000000000058e+04 1.36179549999999995e+03,
              8.51915000000000000e+04 7.79741000000000058e+04 1.36947080000000005e+03,
              9.01915000000000000e+04 7.79741000000000058e+04 1.33049340000000007e+03,
              4.71415000000000000e+04 7.79241000000000058e+04 1.36880290000000014e+03,
              5.21415000000000000e+04 7.79241000000000058e+04 1.30351760000000013e+03,
              5.71415000000000000e+04 7.79241000000000058e+04 1.33269550000000004e+03,
              6.21415000000000000e+04 7.79241000000000058e+04 1.30877390000000014e+03,
              6.71415000000000000e+04 7.79241000000000058e+04 1.31112480000000005e+03,
              7.21415000000000000e+04 7.79241000000000058e+04 1.35756400000000008e+03,
              7.71415000000000000e+04 7.79241000000000058e+04 1.34568920000000003e+03,
              8.21415000000000000e+04 7.79241000000000058e+04 1.36763810000000012e+03,
              8.71415000000000000e+04 7.79241000000000058e+04 1.25753269999999998e+03,
              4.40915000000000000e+04 7.78741000000000058e+04 1.34388270000000011e+03,
              4.90915000000000000e+04 7.78741000000000058e+04 1.36641149999999993e+03,
              5.40915000000000000e+04 7.78741000000000058e+04 1.33414670000000001e+03,
              5.90915000000000000e+04 7.78741000000000058e+04 1.32411580000000004e+03,
              6.40915000000000000e+04 7.78741000000000058e+04 1.34177790000000005e+03,
              6.90915000000000000e+04 7.78741000000000058e+04 1.30588909999999987e+03,
              7.40915000000000000e+04 7.78741000000000058e+04 1.31990329999999994e+03,
              7.90915000000000000e+04 7.78741000000000058e+04 1.34984819999999991e+03,
              8.40915000000000000e+04 7.78741000000000058e+04 1.36054620000000000e+03,
              8.90915000000000000e+04 7.78741000000000058e+04 1.32360030000000006e+03,
              4.60415000000000000e+04 7.78241000000000058e+04 1.37412570000000005e+03,
              5.10415000000000000e+04 7.78241000000000058e+04 1.31565309999999999e+03,
              5.60415000000000000e+04 7.78241000000000058e+04 1.31811220000000003e+03,
              6.10415000000000000e+04 7.78241000000000058e+04 1.31671090000000004e+03,
              6.60415000000000000e+04 7.78241000000000058e+04 1.31904849999999988e+03,
              7.10415000000000000e+04 7.78241000000000058e+04 1.32232929999999988e+03,
              7.60415000000000000e+04 7.78241000000000058e+04 1.32747109999999998e+03,
              8.10415000000000000e+04 7.78241000000000058e+04 1.35641759999999999e+03,
              8.60415000000000000e+04 7.78241000000000058e+04 1.28815550000000007e+03,
              4.29915000000000000e+04 7.77741000000000058e+04 1.32203220000000010e+03,
              4.79915000000000000e+04 7.77741000000000058e+04 1.36593509999999992e+03,
              5.29915000000000000e+04 7.77741000000000058e+04 1.30706799999999998e+03,
              5.79915000000000000e+04 7.77741000000000058e+04 1.32852449999999999e+03,
              6.29915000000000000e+04 7.77741000000000058e+04 1.31167129999999997e+03,
              6.79915000000000000e+04 7.77741000000000058e+04 1.30519630000000006e+03,
              7.29915000000000000e+04 7.77741000000000058e+04 1.34851310000000012e+03,
              7.79915000000000000e+04 7.77741000000000058e+04 1.24816470000000004e+03,
              8.29915000000000000e+04 7.77741000000000058e+04 1.38140949999999998e+03,
              8.79915000000000000e+04 7.77741000000000058e+04 1.25466450000000009e+03,
              4.49415000000000000e+04 7.77241000000000058e+04 1.33931809999999996e+03,
              4.99415000000000000e+04 7.77241000000000058e+04 1.34936390000000006e+03,
              5.49415000000000000e+04 7.77241000000000058e+04 1.32288069999999993e+03,
              5.99415000000000000e+04 7.77241000000000058e+04 1.32378179999999998e+03,
              6.49415000000000000e+04 7.77241000000000058e+04 1.31911999999999989e+03,
              6.99415000000000000e+04 7.77241000000000058e+04 1.31463779999999997e+03,
              7.49415000000000000e+04 7.77241000000000058e+04 1.34220049999999992e+03,
              7.99415000000000000e+04 7.77241000000000058e+04 1.35170600000000013e+03,
              8.49415000000000000e+04 7.77241000000000058e+04 1.37107089999999994e+03,
              8.99415000000000000e+04 7.77241000000000058e+04 1.31840509999999995e+03,
              4.68915000000000000e+04 7.76741000000000058e+04 1.36388619999999992e+03,
              5.18915000000000000e+04 7.76741000000000058e+04 1.31568440000000010e+03,
              5.68915000000000000e+04 7.76741000000000058e+04 1.33308210000000008e+03,
              6.18915000000000000e+04 7.76741000000000058e+04 1.31092550000000006e+03,
              6.68915000000000000e+04 7.76741000000000058e+04 1.32321650000000000e+03,
              7.18915000000000000e+04 7.76741000000000058e+04 1.34050590000000011e+03,
              7.68915000000000000e+04 7.76741000000000058e+04 1.33526530000000002e+03,
              8.18915000000000000e+04 7.76741000000000058e+04 1.35926340000000005e+03,
              8.68915000000000000e+04 7.76741000000000058e+04 1.25619439999999986e+03,
              4.38415000000000000e+04 7.76241000000000058e+04 1.34256269999999995e+03,
              4.88415000000000000e+04 7.76241000000000058e+04 1.36609930000000008e+03,
              5.38415000000000000e+04 7.76241000000000058e+04 1.32680220000000008e+03,
              5.88415000000000000e+04 7.76241000000000058e+04 1.32696880000000010e+03,
              6.38415000000000000e+04 7.76241000000000058e+04 1.35887670000000003e+03,
              6.88415000000000000e+04 7.76241000000000058e+04 1.29625710000000004e+03,
              7.38415000000000000e+04 7.76241000000000058e+04 1.34168679999999995e+03,
              7.88415000000000000e+04 7.76241000000000058e+04 1.34526150000000007e+03,
              8.38415000000000000e+04 7.76241000000000058e+04 1.36884989999999993e+03,
              8.88415000000000000e+04 7.76241000000000058e+04 1.29482799999999997e+03,
              4.57915000000000000e+04 7.75741000000000058e+04 1.34847609999999986e+03,
              5.07915000000000000e+04 7.75741000000000058e+04 1.32431459999999993e+03,
              5.57915000000000000e+04 7.75741000000000058e+04 1.31347800000000007e+03,
              6.07915000000000000e+04 7.75741000000000058e+04 1.31263720000000012e+03,
              6.57915000000000000e+04 7.75741000000000058e+04 1.31663130000000001e+03,
              7.07915000000000000e+04 7.75741000000000058e+04 1.31062419999999997e+03,
              7.57915000000000000e+04 7.75741000000000058e+04 1.32342070000000012e+03,
              8.07915000000000000e+04 7.75741000000000058e+04 1.33838810000000012e+03,
              8.57915000000000000e+04 7.75741000000000058e+04 1.33214809999999989e+03,
              9.07915000000000000e+04 7.75741000000000058e+04 1.30323680000000013e+03,
              4.77415000000000000e+04 7.75241000000000058e+04 1.37330860000000007e+03,
              5.27415000000000000e+04 7.75241000000000058e+04 1.30030950000000007e+03,
              5.77415000000000000e+04 7.75241000000000058e+04 1.33739760000000001e+03,
              6.27415000000000000e+04 7.75241000000000058e+04 1.31278600000000006e+03,
              6.77415000000000000e+04 7.75241000000000058e+04 1.31067669999999998e+03,
              7.27415000000000000e+04 7.75241000000000058e+04 1.34187750000000005e+03,
              7.77415000000000000e+04 7.75241000000000058e+04 1.24465820000000008e+03,
              8.27415000000000000e+04 7.75241000000000058e+04 1.37941260000000011e+03,
              8.77415000000000000e+04 7.75241000000000058e+04 1.25019049999999993e+03,
              4.46915000000000000e+04 7.74741000000000058e+04 1.32490540000000010e+03,
              4.96915000000000000e+04 7.74741000000000058e+04 1.37025180000000000e+03,
              5.46915000000000000e+04 7.74741000000000058e+04 1.31617679999999996e+03,
              5.96915000000000000e+04 7.74741000000000058e+04 1.31462230000000000e+03,
              6.46915000000000000e+04 7.74741000000000058e+04 1.31823170000000005e+03,
              6.96915000000000000e+04 7.74741000000000058e+04 1.30729070000000002e+03,
              7.46915000000000000e+04 7.74741000000000058e+04 1.32135690000000000e+03,
              7.96915000000000000e+04 7.74741000000000058e+04 1.35456939999999986e+03,
              8.46915000000000000e+04 7.74741000000000058e+04 1.37254210000000012e+03,
              8.96915000000000000e+04 7.74741000000000058e+04 1.30940480000000002e+03,
              4.66415000000000000e+04 7.74241000000000058e+04 1.34426829999999995e+03,
              5.16415000000000000e+04 7.74241000000000058e+04 1.31729489999999987e+03,
              5.66415000000000000e+04 7.74241000000000058e+04 1.32365249999999992e+03,
              6.16415000000000000e+04 7.74241000000000058e+04 1.31726360000000000e+03,
              6.66415000000000000e+04 7.74241000000000058e+04 1.31784809999999993e+03,
              7.16415000000000000e+04 7.74241000000000058e+04 1.33319389999999999e+03,
              7.66415000000000000e+04 7.74241000000000058e+04 1.27858359999999993e+03,
              8.16415000000000000e+04 7.74241000000000058e+04 1.36385719999999992e+03,
              8.66415000000000000e+04 7.74241000000000058e+04 1.26137449999999990e+03,
              4.35915000000000000e+04 7.73741000000000058e+04 1.34065219999999999e+03,
              4.85915000000000000e+04 7.73741000000000058e+04 1.37480200000000013e+03,
              5.35915000000000000e+04 7.73741000000000058e+04 1.31413750000000005e+03,
              5.85915000000000000e+04 7.73741000000000058e+04 1.33804999999999995e+03,
              6.35915000000000000e+04 7.73741000000000058e+04 1.36643190000000004e+03,
              6.85915000000000000e+04 7.73741000000000058e+04 1.30005839999999989e+03,
              7.35915000000000000e+04 7.73741000000000058e+04 1.33557600000000002e+03,
              7.85915000000000000e+04 7.73741000000000058e+04 1.34366339999999991e+03,
              8.35915000000000000e+04 7.73741000000000058e+04 1.37152379999999994e+03,
              8.85915000000000000e+04 7.73741000000000058e+04 1.27446550000000002e+03,
              4.55415000000000000e+04 7.73241000000000058e+04 1.33505250000000001e+03,
              5.05415000000000000e+04 7.73241000000000058e+04 1.34627839999999992e+03,
              5.55415000000000000e+04 7.73241000000000058e+04 1.30586959999999999e+03,
              6.05415000000000000e+04 7.73241000000000058e+04 1.31284920000000011e+03,
              6.55415000000000000e+04 7.73241000000000058e+04 1.31949739999999997e+03,
              7.05415000000000000e+04 7.73241000000000058e+04 1.30094839999999999e+03,
              7.55415000000000000e+04 7.73241000000000058e+04 1.31361599999999999e+03,
              8.05415000000000000e+04 7.73241000000000058e+04 1.34316589999999997e+03,
              8.55415000000000000e+04 7.73241000000000058e+04 1.36030310000000009e+03,
              9.05415000000000000e+04 7.73241000000000058e+04 1.30876170000000002e+03,
              4.74915000000000000e+04 7.72741000000000058e+04 1.37310449999999992e+03,
              5.24915000000000000e+04 7.72741000000000058e+04 1.31332690000000002e+03,
              5.74915000000000000e+04 7.72741000000000058e+04 1.34011290000000008e+03,
              6.24915000000000000e+04 7.72741000000000058e+04 1.32002530000000002e+03,
              6.74915000000000000e+04 7.72741000000000058e+04 1.31632400000000007e+03,
              7.24915000000000000e+04 7.72741000000000058e+04 1.33803170000000000e+03,
              7.74915000000000000e+04 7.72741000000000058e+04 1.25110789999999997e+03,
              8.24915000000000000e+04 7.72741000000000058e+04 1.37674600000000009e+03,
              8.74915000000000000e+04 7.72741000000000058e+04 1.24724880000000007e+03,
              4.44415000000000000e+04 7.72241000000000058e+04 1.33099829999999997e+03,
              4.94415000000000000e+04 7.72241000000000058e+04 1.37904440000000000e+03,
              5.44415000000000000e+04 7.72241000000000058e+04 1.31790949999999998e+03,
              5.94415000000000000e+04 7.72241000000000058e+04 1.32005760000000009e+03,
              6.44415000000000000e+04 7.72241000000000058e+04 1.33159529999999995e+03,
              6.94415000000000000e+04 7.72241000000000058e+04 1.29799649999999997e+03,
              7.44415000000000000e+04 7.72241000000000058e+04 1.32710469999999987e+03,
              7.94415000000000000e+04 7.72241000000000058e+04 1.34885109999999986e+03,
              8.44415000000000000e+04 7.72241000000000058e+04 1.33992640000000006e+03,
              8.94415000000000000e+04 7.72241000000000058e+04 1.29167419999999993e+03,
              4.63915000000000000e+04 7.71741000000000058e+04 1.32676189999999997e+03,
              5.13915000000000000e+04 7.71741000000000058e+04 1.34130749999999989e+03,
              5.63915000000000000e+04 7.71741000000000058e+04 1.32002390000000014e+03,
              6.13915000000000000e+04 7.71741000000000058e+04 1.31559960000000001e+03,
              6.63915000000000000e+04 7.71741000000000058e+04 1.30928940000000011e+03,
              7.13915000000000000e+04 7.71741000000000058e+04 1.31331089999999995e+03,
              7.63915000000000000e+04 7.71741000000000058e+04 1.27209259999999995e+03,
              8.13915000000000000e+04 7.71741000000000058e+04 1.34740949999999998e+03,
              8.63915000000000000e+04 7.71741000000000058e+04 1.27348540000000003e+03,
              4.33415000000000000e+04 7.71241000000000058e+04 1.32510609999999997e+03,
              4.83415000000000000e+04 7.71241000000000058e+04 1.35960840000000007e+03,
              5.33415000000000000e+04 7.71241000000000058e+04 1.29608240000000001e+03,
              5.83415000000000000e+04 7.71241000000000058e+04 1.33316180000000008e+03,
              6.33415000000000000e+04 7.71241000000000058e+04 1.35561930000000007e+03,
              6.83415000000000000e+04 7.71241000000000058e+04 1.30418740000000003e+03,
              7.33415000000000000e+04 7.71241000000000058e+04 1.31337789999999995e+03,
              7.83415000000000000e+04 7.71241000000000058e+04 1.33860840000000007e+03,
              8.33415000000000000e+04 7.71241000000000058e+04 1.37446959999999990e+03,
              8.83415000000000000e+04 7.71241000000000058e+04 1.26672240000000011e+03,
              4.52915000000000000e+04 7.70741000000000058e+04 1.32768440000000010e+03,
              5.02915000000000000e+04 7.70741000000000058e+04 1.36176040000000012e+03,
              5.52915000000000000e+04 7.70741000000000058e+04 1.30661439999999993e+03,
              6.02915000000000000e+04 7.70741000000000058e+04 1.32554770000000008e+03,
              6.52915000000000000e+04 7.70741000000000058e+04 1.32942200000000003e+03,
              7.02915000000000000e+04 7.70741000000000058e+04 1.29035050000000001e+03,
              7.52915000000000000e+04 7.70741000000000058e+04 1.28387460000000010e+03,
              8.02915000000000000e+04 7.70741000000000058e+04 1.33673959999999988e+03,
              8.52915000000000000e+04 7.70741000000000058e+04 1.36677109999999993e+03,
              9.02915000000000000e+04 7.70741000000000058e+04 1.31616579999999999e+03,
              4.72415000000000000e+04 7.70241000000000058e+04 1.37069920000000002e+03,
              5.22415000000000000e+04 7.70241000000000058e+04 1.29891200000000003e+03,
              5.72415000000000000e+04 7.70241000000000058e+04 1.33788010000000008e+03,
              6.22415000000000000e+04 7.70241000000000058e+04 1.32807290000000012e+03,
              6.72415000000000000e+04 7.70241000000000058e+04 1.32449109999999996e+03,
              7.22415000000000000e+04 7.70241000000000058e+04 1.33272260000000006e+03,
              7.72415000000000000e+04 7.70241000000000058e+04 1.25414920000000006e+03,
              8.22415000000000000e+04 7.70241000000000058e+04 1.36277929999999992e+03,
              8.72415000000000000e+04 7.70241000000000058e+04 1.26864010000000007e+03,
              4.41915000000000000e+04 7.69741000000000058e+04 1.30114380000000006e+03,
              4.91915000000000000e+04 7.69741000000000058e+04 1.39476760000000013e+03,
              5.41915000000000000e+04 7.69741000000000058e+04 1.30768370000000004e+03,
              5.91915000000000000e+04 7.69741000000000058e+04 1.31766149999999993e+03,
              6.41915000000000000e+04 7.69741000000000058e+04 1.33530340000000001e+03,
              6.91915000000000000e+04 7.69741000000000058e+04 1.30941399999999999e+03,
              7.41915000000000000e+04 7.69741000000000058e+04 1.32551029999999992e+03,
              7.91915000000000000e+04 7.69741000000000058e+04 1.34464789999999994e+03,
              8.41915000000000000e+04 7.69741000000000058e+04 1.36423090000000002e+03,
              8.91915000000000000e+04 7.69741000000000058e+04 1.27943750000000000e+03,
              4.61415000000000000e+04 7.69241000000000058e+04 1.31160210000000006e+03,
              5.11415000000000000e+04 7.69241000000000058e+04 1.31385899999999992e+03,
              5.61415000000000000e+04 7.69241000000000058e+04 1.31860529999999994e+03,
              6.11415000000000000e+04 7.69241000000000058e+04 1.32212580000000003e+03,
              6.61415000000000000e+04 7.69241000000000058e+04 1.31548939999999993e+03,
              7.11415000000000000e+04 7.69241000000000058e+04 1.28977960000000007e+03,
              7.61415000000000000e+04 7.69241000000000058e+04 1.32525739999999996e+03,
              8.11415000000000000e+04 7.69241000000000058e+04 1.33066660000000002e+03,
              8.61415000000000000e+04 7.69241000000000058e+04 1.30911189999999988e+03,
              4.30915000000000000e+04 7.68741000000000058e+04 1.33997550000000001e+03,
              4.80915000000000000e+04 7.68741000000000058e+04 1.37084719999999993e+03,
              5.30915000000000000e+04 7.68741000000000058e+04 1.29186519999999996e+03,
              5.80915000000000000e+04 7.68741000000000058e+04 1.34747420000000011e+03,
              6.30915000000000000e+04 7.68741000000000058e+04 1.36049720000000002e+03,
              6.80915000000000000e+04 7.68741000000000058e+04 1.34106019999999990e+03,
              7.30915000000000000e+04 7.68741000000000058e+04 1.32767360000000008e+03,
              7.80915000000000000e+04 7.68741000000000058e+04 1.32942709999999988e+03,
              8.30915000000000000e+04 7.68741000000000058e+04 1.37430780000000004e+03,
              8.80915000000000000e+04 7.68741000000000058e+04 1.25874839999999995e+03,
              4.50415000000000000e+04 7.68241000000000058e+04 1.29877970000000005e+03,
              5.00415000000000000e+04 7.68241000000000058e+04 1.38445440000000008e+03,
              5.50415000000000000e+04 7.68241000000000058e+04 1.30509680000000003e+03,
              6.00415000000000000e+04 7.68241000000000058e+04 1.34014210000000003e+03,
              6.50415000000000000e+04 7.68241000000000058e+04 1.33739280000000008e+03,
              7.00415000000000000e+04 7.68241000000000058e+04 1.30367020000000002e+03,
              7.50415000000000000e+04 7.68241000000000058e+04 1.30007029999999986e+03,
              8.00415000000000000e+04 7.68241000000000058e+04 1.32980369999999994e+03,
              8.50415000000000000e+04 7.68241000000000058e+04 1.35180310000000009e+03,
              9.00415000000000000e+04 7.68241000000000058e+04 1.29460190000000011e+03,
              4.69915000000000000e+04 7.67741000000000058e+04 1.34735109999999986e+03,
              5.19915000000000000e+04 7.67741000000000058e+04 1.29744290000000001e+03,
              5.69915000000000000e+04 7.67741000000000058e+04 1.34227070000000003e+03,
              6.19915000000000000e+04 7.67741000000000058e+04 1.33189670000000001e+03,
              6.69915000000000000e+04 7.67741000000000058e+04 1.32400170000000003e+03,
              7.19915000000000000e+04 7.67741000000000058e+04 1.31184089999999992e+03,
              7.69915000000000000e+04 7.67741000000000058e+04 1.33327070000000003e+03,
              8.19915000000000000e+04 7.67741000000000058e+04 1.35935840000000007e+03,
              8.69915000000000000e+04 7.67741000000000058e+04 1.26833979999999997e+03,
              4.39415000000000000e+04 7.67241000000000058e+04 1.29940689999999995e+03,
              4.89415000000000000e+04 7.67241000000000058e+04 1.40122980000000007e+03,
              5.39415000000000000e+04 7.67241000000000058e+04 1.30887889999999993e+03,
              5.89415000000000000e+04 7.67241000000000058e+04 1.32081060000000002e+03,
              6.39415000000000000e+04 7.67241000000000058e+04 1.34048410000000013e+03,
              6.89415000000000000e+04 7.67241000000000058e+04 1.30992360000000008e+03,
              7.39415000000000000e+04 7.67241000000000058e+04 1.32293429999999989e+03,
              7.89415000000000000e+04 7.67241000000000058e+04 1.33849250000000006e+03,
              8.39415000000000000e+04 7.67241000000000058e+04 1.34822019999999998e+03,
              8.89415000000000000e+04 7.67241000000000058e+04 1.26403279999999995e+03,
              4.58915000000000000e+04 7.66741000000000058e+04 1.31696559999999999e+03,
              5.08915000000000000e+04 7.66741000000000058e+04 1.35502600000000007e+03,
              5.58915000000000000e+04 7.66741000000000058e+04 1.32696830000000000e+03,
              6.08915000000000000e+04 7.66741000000000058e+04 1.35016920000000005e+03,
              6.58915000000000000e+04 7.66741000000000058e+04 1.32897270000000003e+03,
              7.08915000000000000e+04 7.66741000000000058e+04 1.30491660000000002e+03,
              7.58915000000000000e+04 7.66741000000000058e+04 1.32329390000000012e+03,
              8.08915000000000000e+04 7.66741000000000058e+04 1.33071839999999997e+03,
              8.58915000000000000e+04 7.66741000000000058e+04 1.34834920000000011e+03,
              9.08915000000000000e+04 7.66741000000000058e+04 1.30936279999999988e+03,
              4.78415000000000000e+04 7.66241000000000058e+04 1.36034020000000010e+03,
              5.28415000000000000e+04 7.66241000000000058e+04 1.32242210000000000e+03,
              5.78415000000000000e+04 7.66241000000000058e+04 1.35037529999999992e+03,
              6.28415000000000000e+04 7.66241000000000058e+04 1.37575549999999998e+03,
              6.78415000000000000e+04 7.66241000000000058e+04 1.32780880000000002e+03,
              7.28415000000000000e+04 7.66241000000000058e+04 1.32102060000000006e+03,
              7.78415000000000000e+04 7.66241000000000058e+04 1.31700779999999986e+03,
              8.28415000000000000e+04 7.66241000000000058e+04 1.37203179999999998e+03,
              8.78415000000000000e+04 7.66241000000000058e+04 1.25428919999999994e+03,
              4.47915000000000000e+04 7.65741000000000058e+04 1.30991779999999994e+03,
              4.97915000000000000e+04 7.65741000000000058e+04 1.39258539999999994e+03,
              5.47915000000000000e+04 7.65741000000000058e+04 1.29514589999999998e+03,
              5.97915000000000000e+04 7.65741000000000058e+04 1.34509410000000003e+03,
              6.47915000000000000e+04 7.65741000000000058e+04 1.34643769999999995e+03,
              6.97915000000000000e+04 7.65741000000000058e+04 1.29456880000000001e+03,
              7.47915000000000000e+04 7.65741000000000058e+04 1.32126649999999995e+03,
              7.97915000000000000e+04 7.65741000000000058e+04 1.30196659999999997e+03,
              8.47915000000000000e+04 7.65741000000000058e+04 1.33847250000000008e+03,
              8.97915000000000000e+04 7.65741000000000058e+04 1.28944759999999997e+03,
              4.67415000000000000e+04 7.65241000000000058e+04 1.34816460000000006e+03,
              5.17415000000000000e+04 7.65241000000000058e+04 1.30069849999999997e+03,
              5.67415000000000000e+04 7.65241000000000058e+04 1.34499299999999994e+03,
              6.17415000000000000e+04 7.65241000000000058e+04 1.33675540000000001e+03,
              6.67415000000000000e+04 7.65241000000000058e+04 1.32635190000000011e+03,
              7.17415000000000000e+04 7.65241000000000058e+04 1.33169569999999999e+03,
              7.67415000000000000e+04 7.65241000000000058e+04 1.33108930000000009e+03,
              8.17415000000000000e+04 7.65241000000000058e+04 1.35352129999999988e+03,
              8.67415000000000000e+04 7.65241000000000058e+04 1.33156619999999998e+03,
              4.36915000000000000e+04 7.64741000000000058e+04 1.29244239999999991e+03,
              4.86915000000000000e+04 7.64741000000000058e+04 1.39185320000000002e+03,
              5.36915000000000000e+04 7.64741000000000058e+04 1.29930970000000002e+03,
              5.86915000000000000e+04 7.64741000000000058e+04 1.32799379999999996e+03,
              6.36915000000000000e+04 7.64741000000000058e+04 1.36346309999999994e+03,
              6.86915000000000000e+04 7.64741000000000058e+04 1.30106750000000011e+03,
              7.36915000000000000e+04 7.64741000000000058e+04 1.31609799999999996e+03,
              7.86915000000000000e+04 7.64741000000000058e+04 1.33580600000000004e+03,
              8.36915000000000000e+04 7.64741000000000058e+04 1.34649960000000010e+03,
              8.86915000000000000e+04 7.64741000000000058e+04 1.25735570000000007e+03,
              4.56415000000000000e+04 7.64241000000000058e+04 1.31383649999999989e+03,
              5.06415000000000000e+04 7.64241000000000058e+04 1.35013349999999991e+03,
              5.56415000000000000e+04 7.64241000000000058e+04 1.32114040000000000e+03,
              6.06415000000000000e+04 7.64241000000000058e+04 1.36508230000000003e+03,
              6.56415000000000000e+04 7.64241000000000058e+04 1.32250260000000003e+03,
              7.06415000000000000e+04 7.64241000000000058e+04 1.29862989999999991e+03,
              7.56415000000000000e+04 7.64241000000000058e+04 1.29356449999999995e+03,
              8.06415000000000000e+04 7.64241000000000058e+04 1.34460019999999986e+03,
              8.56415000000000000e+04 7.64241000000000058e+04 1.36015540000000010e+03,
              9.06415000000000000e+04 7.64241000000000058e+04 1.28818969999999990e+03,
              4.75915000000000000e+04 7.63741000000000058e+04 1.36580539999999996e+03,
              5.25915000000000000e+04 7.63741000000000058e+04 1.32258480000000009e+03,
              5.75915000000000000e+04 7.63741000000000058e+04 1.35898170000000005e+03,
              6.25915000000000000e+04 7.63741000000000058e+04 1.33707569999999987e+03,
              6.75915000000000000e+04 7.63741000000000058e+04 1.32050639999999999e+03,
              7.25915000000000000e+04 7.63741000000000058e+04 1.31976810000000000e+03,
              7.75915000000000000e+04 7.63741000000000058e+04 1.26139670000000001e+03,
              8.25915000000000000e+04 7.63741000000000058e+04 1.37147769999999991e+03,
              8.75915000000000000e+04 7.63741000000000058e+04 1.24981040000000007e+03,
              4.45415000000000000e+04 7.63241000000000058e+04 1.31449540000000002e+03,
              4.95415000000000000e+04 7.63241000000000058e+04 1.37690400000000000e+03,
              5.45415000000000000e+04 7.63241000000000058e+04 1.29359979999999996e+03,
              5.95415000000000000e+04 7.63241000000000058e+04 1.38184359999999992e+03,
              6.45415000000000000e+04 7.63241000000000058e+04 1.34542769999999996e+03,
              6.95415000000000000e+04 7.63241000000000058e+04 1.29960709999999995e+03,
              7.45415000000000000e+04 7.63241000000000058e+04 1.32004559999999992e+03,
              7.95415000000000000e+04 7.63241000000000058e+04 1.29801409999999987e+03,
              8.45415000000000000e+04 7.63241000000000058e+04 1.35587460000000010e+03,
              8.95415000000000000e+04 7.63241000000000058e+04 1.27439010000000007e+03,
              4.64915000000000000e+04 7.62741000000000058e+04 1.33815120000000002e+03,
              5.14915000000000000e+04 7.62741000000000058e+04 1.29884380000000010e+03,
              5.64915000000000000e+04 7.62741000000000058e+04 1.35680819999999994e+03,
              6.14915000000000000e+04 7.62741000000000058e+04 1.36226060000000007e+03,
              6.64915000000000000e+04 7.62741000000000058e+04 1.31378350000000000e+03,
              7.14915000000000000e+04 7.62741000000000058e+04 1.32031250000000000e+03,
              7.64915000000000000e+04 7.62741000000000058e+04 1.32408109999999988e+03,
              8.14915000000000000e+04 7.62741000000000058e+04 1.35318080000000009e+03,
              8.64915000000000000e+04 7.62741000000000058e+04 1.32959089999999992e+03,
              4.34415000000000000e+04 7.62241000000000058e+04 1.29438789999999995e+03,
              4.84415000000000000e+04 7.62241000000000058e+04 1.38777210000000014e+03,
              5.34415000000000000e+04 7.62241000000000058e+04 1.28893759999999997e+03,
              5.84415000000000000e+04 7.62241000000000058e+04 1.33852340000000004e+03,
              6.34415000000000000e+04 7.62241000000000058e+04 1.38788360000000011e+03,
              6.84415000000000000e+04 7.62241000000000058e+04 1.29882639999999992e+03,
              7.34415000000000000e+04 7.62241000000000058e+04 1.30960580000000004e+03,
              7.84415000000000000e+04 7.62241000000000058e+04 1.32695600000000013e+03,
              8.34415000000000000e+04 7.62241000000000058e+04 1.36629639999999995e+03,
              8.84415000000000000e+04 7.62241000000000058e+04 1.26430799999999999e+03,
              4.53915000000000000e+04 7.61741000000000058e+04 1.32684879999999998e+03,
              5.03915000000000000e+04 7.61741000000000058e+04 1.34762190000000010e+03,
              5.53915000000000000e+04 7.61741000000000058e+04 1.30544450000000006e+03,
              6.03915000000000000e+04 7.61741000000000058e+04 1.35718890000000010e+03,
              6.53915000000000000e+04 7.61741000000000058e+04 1.32808140000000003e+03,
              7.03915000000000000e+04 7.61741000000000058e+04 1.29709269999999992e+03,
              7.53915000000000000e+04 7.61741000000000058e+04 1.32025219999999990e+03,
              8.03915000000000000e+04 7.61741000000000058e+04 1.33497789999999986e+03,
              8.53915000000000000e+04 7.61741000000000058e+04 1.32850500000000011e+03,
              9.03915000000000000e+04 7.61741000000000058e+04 1.26419200000000001e+03,
              4.73415000000000000e+04 7.61241000000000058e+04 1.37237809999999990e+03,
              5.23415000000000000e+04 7.61241000000000058e+04 1.30917210000000000e+03,
              5.73415000000000000e+04 7.61241000000000058e+04 1.35008320000000003e+03,
              6.23415000000000000e+04 7.61241000000000058e+04 1.35246270000000004e+03,
              6.73415000000000000e+04 7.61241000000000058e+04 1.31231819999999993e+03,
              7.23415000000000000e+04 7.61241000000000058e+04 1.30818450000000007e+03,
              7.73415000000000000e+04 7.61241000000000058e+04 1.29991329999999994e+03,
              8.23415000000000000e+04 7.61241000000000058e+04 1.36143350000000009e+03,
              8.73415000000000000e+04 7.61241000000000058e+04 1.24953070000000002e+03,
              4.42915000000000000e+04 7.60741000000000058e+04 1.31326459999999997e+03,
              4.92915000000000000e+04 7.60741000000000058e+04 1.37601029999999992e+03,
              5.42915000000000000e+04 7.60741000000000058e+04 1.29125689999999986e+03,
              5.92915000000000000e+04 7.60741000000000058e+04 1.35521199999999999e+03,
              6.42915000000000000e+04 7.60741000000000058e+04 1.35313169999999991e+03,
              6.92915000000000000e+04 7.60741000000000058e+04 1.30485410000000002e+03,
              7.42915000000000000e+04 7.60741000000000058e+04 1.31502999999999997e+03,
              7.92915000000000000e+04 7.60741000000000058e+04 1.30994389999999999e+03,
              8.42915000000000000e+04 7.60741000000000058e+04 1.35437390000000005e+03,
              8.92915000000000000e+04 7.60741000000000058e+04 1.27768910000000005e+03,
              4.62415000000000000e+04 7.60241000000000058e+04 1.36941579999999999e+03,
              5.12415000000000000e+04 7.60241000000000058e+04 1.30356390000000010e+03,
              5.62415000000000000e+04 7.60241000000000058e+04 1.37555940000000010e+03,
              6.12415000000000000e+04 7.60241000000000058e+04 1.35519270000000006e+03,
              6.62415000000000000e+04 7.60241000000000058e+04 1.30923659999999995e+03,
              7.12415000000000000e+04 7.60241000000000058e+04 1.31393460000000005e+03,
              7.62415000000000000e+04 7.60241000000000058e+04 1.29209999999999991e+03,
              8.12415000000000000e+04 7.60241000000000058e+04 1.34468219999999997e+03,
              8.62415000000000000e+04 7.60241000000000058e+04 1.33782490000000007e+03,
              4.31915000000000000e+04 7.59741000000000058e+04 1.29107029999999986e+03,
              4.81915000000000000e+04 7.59741000000000058e+04 1.38680410000000006e+03,
              5.31915000000000000e+04 7.59741000000000058e+04 1.28943939999999998e+03,
              5.81915000000000000e+04 7.59741000000000058e+04 1.33692509999999993e+03,
              6.31915000000000000e+04 7.59741000000000058e+04 1.38614460000000008e+03,
              6.81915000000000000e+04 7.59741000000000058e+04 1.29574180000000001e+03,
              7.31915000000000000e+04 7.59741000000000058e+04 1.30053040000000010e+03,
              7.81915000000000000e+04 7.59741000000000058e+04 1.32518370000000004e+03,
              8.31915000000000000e+04 7.59741000000000058e+04 1.37335870000000000e+03,
              8.81915000000000000e+04 7.59741000000000058e+04 1.27614210000000003e+03,
              4.51415000000000000e+04 7.59241000000000058e+04 1.30476330000000007e+03,
              5.01415000000000000e+04 7.59241000000000058e+04 1.34016370000000006e+03,
              5.51415000000000000e+04 7.59241000000000058e+04 1.29967129999999997e+03,
              6.01415000000000000e+04 7.59241000000000058e+04 1.35072240000000011e+03,
              6.51415000000000000e+04 7.59241000000000058e+04 1.32933390000000009e+03,
              7.01415000000000000e+04 7.59241000000000058e+04 1.30337670000000003e+03,
              7.51415000000000000e+04 7.59241000000000058e+04 1.32952520000000004e+03,
              8.01415000000000000e+04 7.59241000000000058e+04 1.32396579999999994e+03,
              8.51415000000000000e+04 7.59241000000000058e+04 1.34362590000000000e+03,
              9.01415000000000000e+04 7.59241000000000058e+04 1.27133550000000014e+03,
              4.70915000000000000e+04 7.58741000000000058e+04 1.38489560000000006e+03,
              5.20915000000000000e+04 7.58741000000000058e+04 1.30453949999999986e+03,
              5.70915000000000000e+04 7.58741000000000058e+04 1.34802150000000006e+03,
              6.20915000000000000e+04 7.58741000000000058e+04 1.39209099999999989e+03,
              6.70915000000000000e+04 7.58741000000000058e+04 1.31437210000000005e+03,
              7.20915000000000000e+04 7.58741000000000058e+04 1.29587570000000005e+03,
              7.70915000000000000e+04 7.58741000000000058e+04 1.31666689999999994e+03,
              8.20915000000000000e+04 7.58741000000000058e+04 1.35549199999999996e+03,
              8.70915000000000000e+04 7.58741000000000058e+04 1.25009030000000007e+03,
              4.40415000000000000e+04 7.58241000000000058e+04 1.30211639999999989e+03,
              4.90415000000000000e+04 7.58241000000000058e+04 1.35580490000000009e+03,
              5.40415000000000000e+04 7.58241000000000058e+04 1.29251340000000005e+03,
              5.90415000000000000e+04 7.58241000000000058e+04 1.32787370000000010e+03,
              6.40415000000000000e+04 7.58241000000000058e+04 1.35929840000000013e+03,
              6.90415000000000000e+04 7.58241000000000058e+04 1.33263020000000006e+03,
              7.40415000000000000e+04 7.58241000000000058e+04 1.30853700000000003e+03,
              7.90415000000000000e+04 7.58241000000000058e+04 1.32570499999999993e+03,
              8.40415000000000000e+04 7.58241000000000058e+04 1.34227469999999994e+03,
              8.90415000000000000e+04 7.58241000000000058e+04 1.28688879999999995e+03,
              4.59915000000000000e+04 7.57741000000000058e+04 1.37901919999999996e+03,
              5.09915000000000000e+04 7.57741000000000058e+04 1.30420399999999995e+03,
              5.59915000000000000e+04 7.57741000000000058e+04 1.32739400000000001e+03,
              6.09915000000000000e+04 7.57741000000000058e+04 1.34932310000000007e+03,
              6.59915000000000000e+04 7.57741000000000058e+04 1.30580089999999996e+03,
              7.09915000000000000e+04 7.57741000000000058e+04 1.30849800000000005e+03,
              7.59915000000000000e+04 7.57741000000000058e+04 1.31532819999999992e+03,
              8.09915000000000000e+04 7.57741000000000058e+04 1.32912410000000000e+03,
              8.59915000000000000e+04 7.57741000000000058e+04 1.28903410000000008e+03,
              9.09915000000000000e+04 7.57741000000000058e+04 1.25524970000000008e+03,
              4.79415000000000000e+04 7.57241000000000058e+04 1.37246370000000002e+03,
              5.29415000000000000e+04 7.57241000000000058e+04 1.29955650000000014e+03,
              5.79415000000000000e+04 7.57241000000000058e+04 1.34054899999999998e+03,
              6.29415000000000000e+04 7.57241000000000058e+04 1.38188450000000012e+03,
              6.79415000000000000e+04 7.57241000000000058e+04 1.29674520000000007e+03,
              7.29415000000000000e+04 7.57241000000000058e+04 1.29112410000000000e+03,
              7.79415000000000000e+04 7.57241000000000058e+04 1.27786130000000003e+03,
              8.29415000000000000e+04 7.57241000000000058e+04 1.36713909999999987e+03,
              8.79415000000000000e+04 7.57241000000000058e+04 1.28352780000000007e+03,
              4.48915000000000000e+04 7.56741000000000058e+04 1.31831310000000008e+03,
              4.98915000000000000e+04 7.56741000000000058e+04 1.34607159999999999e+03,
              5.48915000000000000e+04 7.56741000000000058e+04 1.28916779999999994e+03,
              5.98915000000000000e+04 7.56741000000000058e+04 1.32767599999999993e+03,
              6.48915000000000000e+04 7.56741000000000058e+04 1.31473419999999987e+03,
              6.98915000000000000e+04 7.56741000000000058e+04 1.30791419999999994e+03,
              7.48915000000000000e+04 7.56741000000000058e+04 1.31466309999999999e+03,
              7.98915000000000000e+04 7.56741000000000058e+04 1.31173489999999993e+03,
              8.48915000000000000e+04 7.56741000000000058e+04 1.34147890000000007e+03,
              8.98915000000000000e+04 7.56741000000000058e+04 1.28720589999999993e+03,
              4.68415000000000000e+04 7.56241000000000058e+04 1.39146929999999998e+03,
              5.18415000000000000e+04 7.56241000000000058e+04 1.33515529999999990e+03,
              5.68415000000000000e+04 7.56241000000000058e+04 1.35071569999999997e+03,
              6.18415000000000000e+04 7.56241000000000058e+04 1.38817030000000000e+03,
              6.68415000000000000e+04 7.56241000000000058e+04 1.30075659999999993e+03,
              7.18415000000000000e+04 7.56241000000000058e+04 1.29368939999999998e+03,
              7.68415000000000000e+04 7.56241000000000058e+04 1.31231189999999992e+03,
              8.18415000000000000e+04 7.56241000000000058e+04 1.35413130000000001e+03,
              8.68415000000000000e+04 7.56241000000000058e+04 1.24992439999999988e+03,
              4.37915000000000000e+04 7.55741000000000058e+04 1.29152399999999989e+03,
              4.87915000000000000e+04 7.55741000000000058e+04 1.37065830000000005e+03,
              5.37915000000000000e+04 7.55741000000000058e+04 1.29732850000000008e+03,
              5.87915000000000000e+04 7.55741000000000058e+04 1.32099170000000004e+03,
              6.37915000000000000e+04 7.55741000000000058e+04 1.33976109999999994e+03,
              6.87915000000000000e+04 7.55741000000000058e+04 1.34140480000000002e+03,
              7.37915000000000000e+04 7.55741000000000058e+04 1.30352060000000006e+03,
              7.87915000000000000e+04 7.55741000000000058e+04 1.32552330000000006e+03,
              8.37915000000000000e+04 7.55741000000000058e+04 1.33620249999999987e+03,
              8.87915000000000000e+04 7.55741000000000058e+04 1.26594659999999999e+03,
              4.57415000000000000e+04 7.55241000000000058e+04 1.39096949999999993e+03,
              5.07415000000000000e+04 7.55241000000000058e+04 1.30686540000000014e+03,
              5.57415000000000000e+04 7.55241000000000058e+04 1.38209650000000011e+03,
              6.07415000000000000e+04 7.55241000000000058e+04 1.36233850000000007e+03,
              6.57415000000000000e+04 7.55241000000000058e+04 1.32957500000000005e+03,
              7.07415000000000000e+04 7.55241000000000058e+04 1.30439069999999992e+03,
              7.57415000000000000e+04 7.55241000000000058e+04 1.30414030000000002e+03,
              8.07415000000000000e+04 7.55241000000000058e+04 1.32455549999999994e+03,
              8.57415000000000000e+04 7.55241000000000058e+04 1.35260320000000002e+03,
              9.07415000000000000e+04 7.55241000000000058e+04 1.25675530000000003e+03,
              4.76915000000000000e+04 7.54741000000000058e+04 1.37285919999999987e+03,
              5.26915000000000000e+04 7.54741000000000058e+04 1.31151919999999996e+03,
              5.76915000000000000e+04 7.54741000000000058e+04 1.34643530000000010e+03,
              6.26915000000000000e+04 7.54741000000000058e+04 1.38891849999999999e+03,
              6.76915000000000000e+04 7.54741000000000058e+04 1.30590940000000001e+03,
              7.26915000000000000e+04 7.54741000000000058e+04 1.28754660000000013e+03,
              7.76915000000000000e+04 7.54741000000000058e+04 1.26172690000000011e+03,
              8.26915000000000000e+04 7.54741000000000058e+04 1.35511149999999998e+03,
              8.76915000000000000e+04 7.54741000000000058e+04 1.28207970000000000e+03,
              4.46415000000000000e+04 7.54241000000000058e+04 1.31722969999999987e+03,
              4.96415000000000000e+04 7.54241000000000058e+04 1.34569020000000000e+03,
              5.46415000000000000e+04 7.54241000000000058e+04 1.28467210000000000e+03,
              5.96415000000000000e+04 7.54241000000000058e+04 1.32687590000000000e+03,
              6.46415000000000000e+04 7.54241000000000058e+04 1.32030119999999988e+03,
              6.96415000000000000e+04 7.54241000000000058e+04 1.30988390000000004e+03,
              7.46415000000000000e+04 7.54241000000000058e+04 1.30692370000000005e+03,
              7.96415000000000000e+04 7.54241000000000058e+04 1.31303690000000006e+03,
              8.46415000000000000e+04 7.54241000000000058e+04 1.33480220000000008e+03,
              8.96415000000000000e+04 7.54241000000000058e+04 1.26452819999999997e+03,
              4.65915000000000000e+04 7.53741000000000058e+04 1.38441269999999986e+03,
              5.15915000000000000e+04 7.53741000000000058e+04 1.34363799999999992e+03,
              5.65915000000000000e+04 7.53741000000000058e+04 1.36391779999999994e+03,
              6.15915000000000000e+04 7.53741000000000058e+04 1.37260310000000004e+03,
              6.65915000000000000e+04 7.53741000000000058e+04 1.30493840000000000e+03,
              7.15915000000000000e+04 7.53741000000000058e+04 1.28783100000000013e+03,
              7.65915000000000000e+04 7.53741000000000058e+04 1.30742200000000003e+03,
              8.15915000000000000e+04 7.53741000000000058e+04 1.33883449999999993e+03,
              8.65915000000000000e+04 7.53741000000000058e+04 1.25388180000000011e+03,
              4.35415000000000000e+04 7.53241000000000058e+04 1.26969239999999991e+03,
              4.85415000000000000e+04 7.53241000000000058e+04 1.38727620000000002e+03,
              5.35415000000000000e+04 7.53241000000000058e+04 1.30061459999999988e+03,
              5.85415000000000000e+04 7.53241000000000058e+04 1.33171149999999989e+03,
              6.35415000000000000e+04 7.53241000000000058e+04 1.34736500000000001e+03,
              6.85415000000000000e+04 7.53241000000000058e+04 1.33246720000000005e+03,
              7.35415000000000000e+04 7.53241000000000058e+04 1.29393589999999995e+03,
              7.85415000000000000e+04 7.53241000000000058e+04 1.30729169999999999e+03,
              8.35415000000000000e+04 7.53241000000000058e+04 1.34547810000000004e+03,
              8.85415000000000000e+04 7.53241000000000058e+04 1.26208130000000006e+03,
              4.54915000000000000e+04 7.52741000000000058e+04 1.38280629999999996e+03,
              5.04915000000000000e+04 7.52741000000000058e+04 1.31716339999999991e+03,
              5.54915000000000000e+04 7.52741000000000058e+04 1.37643389999999999e+03,
              6.04915000000000000e+04 7.52741000000000058e+04 1.37106160000000000e+03,
              6.54915000000000000e+04 7.52741000000000058e+04 1.32660860000000002e+03,
              7.04915000000000000e+04 7.52741000000000058e+04 1.29803369999999995e+03,
              7.54915000000000000e+04 7.52741000000000058e+04 1.30454099999999994e+03,
              8.04915000000000000e+04 7.52741000000000058e+04 1.31735979999999995e+03,
              8.54915000000000000e+04 7.52741000000000058e+04 1.34618380000000002e+03,
              9.04915000000000000e+04 7.52741000000000058e+04 1.23923209999999995e+03,
              4.74415000000000000e+04 7.52241000000000058e+04 1.37363390000000004e+03,
              5.24415000000000000e+04 7.52241000000000058e+04 1.30341570000000002e+03,
              5.74415000000000000e+04 7.52241000000000058e+04 1.34081500000000005e+03,
              6.24415000000000000e+04 7.52241000000000058e+04 1.37356619999999998e+03,
              6.74415000000000000e+04 7.52241000000000058e+04 1.31325839999999994e+03,
              7.24415000000000000e+04 7.52241000000000058e+04 1.29379680000000008e+03,
              7.74415000000000000e+04 7.52241000000000058e+04 1.31573829999999998e+03,
              8.24415000000000000e+04 7.52241000000000058e+04 1.35388660000000004e+03,
              8.74415000000000000e+04 7.52241000000000058e+04 1.27798299999999995e+03,
              4.43915000000000000e+04 7.51741000000000058e+04 1.29297250000000008e+03,
              4.93915000000000000e+04 7.51741000000000058e+04 1.36538909999999987e+03,
              5.43915000000000000e+04 7.51741000000000058e+04 1.28471000000000004e+03,
              5.93915000000000000e+04 7.51741000000000058e+04 1.32866000000000008e+03,
              6.43915000000000000e+04 7.51741000000000058e+04 1.32313879999999995e+03,
              6.93915000000000000e+04 7.51741000000000058e+04 1.32890180000000009e+03,
              7.43915000000000000e+04 7.51741000000000058e+04 1.30873299999999995e+03,
              7.93915000000000000e+04 7.51741000000000058e+04 1.30579690000000005e+03,
              8.43915000000000000e+04 7.51741000000000058e+04 1.32662249999999995e+03,
              8.93915000000000000e+04 7.51741000000000058e+04 1.26867149999999992e+03,
              4.63415000000000000e+04 7.51241000000000058e+04 1.37016920000000005e+03,
              5.13415000000000000e+04 7.51241000000000058e+04 1.36410230000000001e+03,
              5.63415000000000000e+04 7.51241000000000058e+04 1.38686079999999993e+03,
              6.13415000000000000e+04 7.51241000000000058e+04 1.35607249999999999e+03,
              6.63415000000000000e+04 7.51241000000000058e+04 1.30835280000000012e+03,
              7.13415000000000000e+04 7.51241000000000058e+04 1.28837139999999999e+03,
              7.63415000000000000e+04 7.51241000000000058e+04 1.30430389999999989e+03,
              8.13415000000000000e+04 7.51241000000000058e+04 1.33218810000000008e+03,
              8.63415000000000000e+04 7.51241000000000058e+04 1.27948039999999992e+03,
              4.32915000000000000e+04 7.50741000000000058e+04 1.27776160000000004e+03,
              4.82915000000000000e+04 7.50741000000000058e+04 1.35559050000000002e+03,
              5.32915000000000000e+04 7.50741000000000058e+04 1.31737899999999991e+03,
              5.82915000000000000e+04 7.50741000000000058e+04 1.33794709999999986e+03,
              6.32915000000000000e+04 7.50741000000000058e+04 1.36392589999999996e+03,
              6.82915000000000000e+04 7.50741000000000058e+04 1.33452480000000014e+03,
              7.32915000000000000e+04 7.50741000000000058e+04 1.28323209999999995e+03,
              7.82915000000000000e+04 7.50741000000000058e+04 1.26551270000000000e+03,
              8.32915000000000000e+04 7.50741000000000058e+04 1.35017849999999999e+03,
              8.82915000000000000e+04 7.50741000000000058e+04 1.27345519999999988e+03,
              4.52415000000000000e+04 7.50241000000000058e+04 1.34050119999999993e+03,
              5.02415000000000000e+04 7.50241000000000058e+04 1.33603970000000004e+03,
              5.52415000000000000e+04 7.50241000000000058e+04 1.33571399999999994e+03,
              6.02415000000000000e+04 7.50241000000000058e+04 1.39138189999999986e+03,
              6.52415000000000000e+04 7.50241000000000058e+04 1.33138459999999986e+03,
              7.02415000000000000e+04 7.50241000000000058e+04 1.29592300000000000e+03,
              7.52415000000000000e+04 7.50241000000000058e+04 1.30177919999999995e+03,
              8.02415000000000000e+04 7.50241000000000058e+04 1.30605240000000003e+03,
              8.52415000000000000e+04 7.50241000000000058e+04 1.33677729999999997e+03,
              9.02415000000000000e+04 7.50241000000000058e+04 1.24936650000000009e+03,
              4.71915000000000000e+04 7.49741000000000058e+04 1.33709390000000008e+03,
              5.21915000000000000e+04 7.49741000000000058e+04 1.31009860000000003e+03,
              5.71915000000000000e+04 7.49741000000000058e+04 1.34419080000000008e+03,
              6.21915000000000000e+04 7.49741000000000058e+04 1.37819959999999992e+03,
              6.71915000000000000e+04 7.49741000000000058e+04 1.32253520000000003e+03,
              7.21915000000000000e+04 7.49741000000000058e+04 1.28246250000000009e+03,
              7.71915000000000000e+04 7.49741000000000058e+04 1.28217100000000005e+03,
              8.21915000000000000e+04 7.49741000000000058e+04 1.32932839999999987e+03,
              8.71915000000000000e+04 7.49741000000000058e+04 1.26703999999999996e+03,
              4.41415000000000000e+04 7.49241000000000058e+04 1.28495029999999997e+03,
              4.91415000000000000e+04 7.49241000000000058e+04 1.33361570000000006e+03,
              5.41415000000000000e+04 7.49241000000000058e+04 1.28821749999999997e+03,
              5.91415000000000000e+04 7.49241000000000058e+04 1.32831559999999990e+03,
              6.41415000000000000e+04 7.49241000000000058e+04 1.33886239999999998e+03,
              6.91415000000000000e+04 7.49241000000000058e+04 1.31167979999999989e+03,
              7.41415000000000000e+04 7.49241000000000058e+04 1.29961149999999998e+03,
              7.91415000000000000e+04 7.49241000000000058e+04 1.30121530000000007e+03,
              8.41415000000000000e+04 7.49241000000000058e+04 1.33967360000000008e+03,
              8.91415000000000000e+04 7.49241000000000058e+04 1.28432880000000000e+03,
              4.60915000000000000e+04 7.48741000000000058e+04 1.34557780000000002e+03,
              5.10915000000000000e+04 7.48741000000000058e+04 1.37291660000000002e+03,
              5.60915000000000000e+04 7.48741000000000058e+04 1.36592239999999993e+03,
              6.10915000000000000e+04 7.48741000000000058e+04 1.38280829999999992e+03,
              6.60915000000000000e+04 7.48741000000000058e+04 1.32779800000000000e+03,
              7.10915000000000000e+04 7.48741000000000058e+04 1.29011020000000008e+03,
              7.60915000000000000e+04 7.48741000000000058e+04 1.29237840000000006e+03,
              8.10915000000000000e+04 7.48741000000000058e+04 1.31166660000000002e+03,
              8.60915000000000000e+04 7.48741000000000058e+04 1.33986480000000006e+03,
              4.30415000000000000e+04 7.48241000000000058e+04 1.26773360000000002e+03,
              4.80415000000000000e+04 7.48241000000000058e+04 1.34760300000000007e+03,
              5.30415000000000000e+04 7.48241000000000058e+04 1.33315570000000002e+03,
              5.80415000000000000e+04 7.48241000000000058e+04 1.36146839999999997e+03,
              6.30415000000000000e+04 7.48241000000000058e+04 1.34048739999999998e+03,
              6.80415000000000000e+04 7.48241000000000058e+04 1.34808490000000006e+03,
              7.30415000000000000e+04 7.48241000000000058e+04 1.29089740000000006e+03,
              7.80415000000000000e+04 7.48241000000000058e+04 1.28604870000000005e+03,
              8.30415000000000000e+04 7.48241000000000058e+04 1.34040650000000005e+03,
              8.80415000000000000e+04 7.48241000000000058e+04 1.28163909999999987e+03,
              4.49915000000000000e+04 7.47741000000000058e+04 1.31454050000000007e+03,
              4.99915000000000000e+04 7.47741000000000058e+04 1.36026490000000013e+03,
              5.49915000000000000e+04 7.47741000000000058e+04 1.30473180000000002e+03,
              5.99915000000000000e+04 7.47741000000000058e+04 1.37368399999999997e+03,
              6.49915000000000000e+04 7.47741000000000058e+04 1.35304989999999998e+03,
              6.99915000000000000e+04 7.47741000000000058e+04 1.30420000000000005e+03,
              7.49915000000000000e+04 7.47741000000000058e+04 1.28684320000000002e+03,
              7.99915000000000000e+04 7.47741000000000058e+04 1.26514800000000014e+03,
              8.49915000000000000e+04 7.47741000000000058e+04 1.33940979999999990e+03,
              8.99915000000000000e+04 7.47741000000000058e+04 1.24938220000000001e+03,
              4.69415000000000000e+04 7.47241000000000058e+04 1.32533690000000001e+03,
              5.19415000000000000e+04 7.47241000000000058e+04 1.35519759999999997e+03,
              5.69415000000000000e+04 7.47241000000000058e+04 1.36964959999999996e+03,
              6.19415000000000000e+04 7.47241000000000058e+04 1.37306780000000003e+03,
              6.69415000000000000e+04 7.47241000000000058e+04 1.32756799999999998e+03,
              7.19415000000000000e+04 7.47241000000000058e+04 1.28894849999999997e+03,
              7.69415000000000000e+04 7.47241000000000058e+04 1.27867579999999998e+03,
              8.19415000000000000e+04 7.47241000000000058e+04 1.31992720000000008e+03,
              8.69415000000000000e+04 7.47241000000000058e+04 1.25051620000000003e+03,
              4.38915000000000000e+04 7.46741000000000058e+04 1.30020920000000001e+03,
              4.88915000000000000e+04 7.46741000000000058e+04 1.33980490000000009e+03,
              5.38915000000000000e+04 7.46741000000000058e+04 1.29105209999999988e+03,
              5.88915000000000000e+04 7.46741000000000058e+04 1.33277289999999994e+03,
              6.38915000000000000e+04 7.46741000000000058e+04 1.37164820000000009e+03,
              6.88915000000000000e+04 7.46741000000000058e+04 1.30766449999999986e+03,
              7.38915000000000000e+04 7.46741000000000058e+04 1.28918049999999994e+03,
              7.88915000000000000e+04 7.46741000000000058e+04 1.29731480000000010e+03,
              8.38915000000000000e+04 7.46741000000000058e+04 1.32826440000000002e+03,
              8.88915000000000000e+04 7.46741000000000058e+04 1.27468769999999995e+03,
              4.58415000000000000e+04 7.46241000000000058e+04 1.34641309999999999e+03,
              5.08415000000000000e+04 7.46241000000000058e+04 1.36463639999999987e+03,
              5.58415000000000000e+04 7.46241000000000058e+04 1.34775090000000000e+03,
              6.08415000000000000e+04 7.46241000000000058e+04 1.36586419999999998e+03,
              6.58415000000000000e+04 7.46241000000000058e+04 1.31902449999999999e+03,
              7.08415000000000000e+04 7.46241000000000058e+04 1.29055150000000003e+03,
              7.58415000000000000e+04 7.46241000000000058e+04 1.29095839999999998e+03,
              8.08415000000000000e+04 7.46241000000000058e+04 1.32251250000000005e+03,
              8.58415000000000000e+04 7.46241000000000058e+04 1.34249870000000010e+03,
              9.08415000000000000e+04 7.46241000000000058e+04 1.25924479999999994e+03,
              4.77915000000000000e+04 7.45741000000000058e+04 1.35297820000000002e+03,
              5.27915000000000000e+04 7.45741000000000058e+04 1.35113419999999996e+03,
              5.77915000000000000e+04 7.45741000000000058e+04 1.37122440000000006e+03,
              6.27915000000000000e+04 7.45741000000000058e+04 1.36302669999999989e+03,
              6.77915000000000000e+04 7.45741000000000058e+04 1.34344290000000001e+03,
              7.27915000000000000e+04 7.45741000000000058e+04 1.27818830000000003e+03,
              7.77915000000000000e+04 7.45741000000000058e+04 1.29450559999999996e+03,
              8.27915000000000000e+04 7.45741000000000058e+04 1.34245969999999988e+03,
              8.77915000000000000e+04 7.45741000000000058e+04 1.29730899999999997e+03,
              4.47415000000000000e+04 7.45241000000000058e+04 1.33041339999999991e+03,
              4.97415000000000000e+04 7.45241000000000058e+04 1.35257069999999999e+03,
              5.47415000000000000e+04 7.45241000000000058e+04 1.28254999999999995e+03,
              5.97415000000000000e+04 7.45241000000000058e+04 1.33515249999999992e+03,
              6.47415000000000000e+04 7.45241000000000058e+04 1.37011650000000009e+03,
              6.97415000000000000e+04 7.45241000000000058e+04 1.30002219999999988e+03,
              7.47415000000000000e+04 7.45241000000000058e+04 1.28636740000000009e+03,
              7.97415000000000000e+04 7.45241000000000058e+04 1.30425080000000003e+03,
              8.47415000000000000e+04 7.45241000000000058e+04 1.34464400000000001e+03,
              8.97415000000000000e+04 7.45241000000000058e+04 1.24618589999999995e+03,
              4.66915000000000000e+04 7.44741000000000058e+04 1.35313239999999996e+03,
              5.16915000000000000e+04 7.44741000000000058e+04 1.37040139999999997e+03,
              5.66915000000000000e+04 7.44741000000000058e+04 1.37455500000000006e+03,
              6.16915000000000000e+04 7.44741000000000058e+04 1.36294779999999992e+03,
              6.66915000000000000e+04 7.44741000000000058e+04 1.32251170000000002e+03,
              7.16915000000000000e+04 7.44741000000000058e+04 1.29445180000000005e+03,
              7.66915000000000000e+04 7.44741000000000058e+04 1.28247430000000008e+03,
              8.16915000000000000e+04 7.44741000000000058e+04 1.32911320000000001e+03,
              8.66915000000000000e+04 7.44741000000000058e+04 1.25856369999999993e+03,
              4.36415000000000000e+04 7.44241000000000058e+04 1.29357079999999996e+03,
              4.86415000000000000e+04 7.44241000000000058e+04 1.32456989999999996e+03,
              5.36415000000000000e+04 7.44241000000000058e+04 1.30218119999999999e+03,
              5.86415000000000000e+04 7.44241000000000058e+04 1.34472129999999993e+03,
              6.36415000000000000e+04 7.44241000000000058e+04 1.36027399999999989e+03,
              6.86415000000000000e+04 7.44241000000000058e+04 1.31926070000000004e+03,
              7.36415000000000000e+04 7.44241000000000058e+04 1.27893329999999992e+03,
              7.86415000000000000e+04 7.44241000000000058e+04 1.28736909999999989e+03,
              8.36415000000000000e+04 7.44241000000000058e+04 1.33793689999999992e+03,
              8.86415000000000000e+04 7.44241000000000058e+04 1.26312930000000006e+03,
              4.55915000000000000e+04 7.43741000000000058e+04 1.33715309999999999e+03,
              5.05915000000000000e+04 7.43741000000000058e+04 1.33739809999999989e+03,
              5.55915000000000000e+04 7.43741000000000058e+04 1.35696730000000002e+03,
              6.05915000000000000e+04 7.43741000000000058e+04 1.35215670000000000e+03,
              6.55915000000000000e+04 7.43741000000000058e+04 1.33437419999999997e+03,
              7.05915000000000000e+04 7.43741000000000058e+04 1.29961099999999988e+03,
              7.55915000000000000e+04 7.43741000000000058e+04 1.28761830000000009e+03,
              8.05915000000000000e+04 7.43741000000000058e+04 1.31238090000000011e+03,
              8.55915000000000000e+04 7.43741000000000058e+04 1.34021309999999994e+03,
              9.05915000000000000e+04 7.43741000000000058e+04 1.26652199999999993e+03,
              4.75415000000000000e+04 7.43241000000000058e+04 1.33374949999999990e+03,
              5.25415000000000000e+04 7.43241000000000058e+04 1.36682970000000000e+03,
              5.75415000000000000e+04 7.43241000000000058e+04 1.36783519999999999e+03,
              6.25415000000000000e+04 7.43241000000000058e+04 1.36703469999999993e+03,
              6.75415000000000000e+04 7.43241000000000058e+04 1.35072170000000006e+03,
              7.25415000000000000e+04 7.43241000000000058e+04 1.29098710000000005e+03,
              7.75415000000000000e+04 7.43241000000000058e+04 1.29646350000000007e+03,
              8.25415000000000000e+04 7.43241000000000058e+04 1.32798050000000012e+03,
              8.75415000000000000e+04 7.43241000000000058e+04 1.27066890000000012e+03,
              4.44915000000000000e+04 7.42741000000000058e+04 1.34467759999999998e+03,
              4.94915000000000000e+04 7.42741000000000058e+04 1.33482909999999993e+03,
              5.44915000000000000e+04 7.42741000000000058e+04 1.28308739999999989e+03,
              5.94915000000000000e+04 7.42741000000000058e+04 1.35021749999999997e+03,
              6.44915000000000000e+04 7.42741000000000058e+04 1.36373129999999992e+03,
              6.94915000000000000e+04 7.42741000000000058e+04 1.30292660000000001e+03,
              7.44915000000000000e+04 7.42741000000000058e+04 1.27389139999999998e+03,
              7.94915000000000000e+04 7.42741000000000058e+04 1.30191899999999987e+03,
              8.44915000000000000e+04 7.42741000000000058e+04 1.32619560000000001e+03,
              8.94915000000000000e+04 7.42741000000000058e+04 1.28686819999999989e+03,
              4.64415000000000000e+04 7.42241000000000058e+04 1.31686069999999995e+03,
              5.14415000000000000e+04 7.42241000000000058e+04 1.35079999999999995e+03,
              5.64415000000000000e+04 7.42241000000000058e+04 1.36771100000000001e+03,
              6.14415000000000000e+04 7.42241000000000058e+04 1.36478929999999991e+03,
              6.64415000000000000e+04 7.42241000000000058e+04 1.32594110000000001e+03,
              7.14415000000000000e+04 7.42241000000000058e+04 1.29927369999999996e+03,
              7.64415000000000000e+04 7.42241000000000058e+04 1.27189760000000001e+03,
              8.14415000000000000e+04 7.42241000000000058e+04 1.32936599999999999e+03,
              8.64415000000000000e+04 7.42241000000000058e+04 1.26935559999999987e+03,
              4.33915000000000000e+04 7.41741000000000058e+04 1.29063499999999999e+03,
              4.83915000000000000e+04 7.41741000000000058e+04 1.29624479999999994e+03,
              5.33915000000000000e+04 7.41741000000000058e+04 1.33747219999999993e+03,
              5.83915000000000000e+04 7.41741000000000058e+04 1.35277070000000003e+03,
              6.33915000000000000e+04 7.41741000000000058e+04 1.36539830000000006e+03,
              6.83915000000000000e+04 7.41741000000000058e+04 1.32996880000000010e+03,
              7.33915000000000000e+04 7.41741000000000058e+04 1.28363639999999987e+03,
              7.83915000000000000e+04 7.41741000000000058e+04 1.26279570000000012e+03,
              8.33915000000000000e+04 7.41741000000000058e+04 1.33631979999999999e+03,
              8.83915000000000000e+04 7.41741000000000058e+04 1.28009999999999991e+03,
              4.53415000000000000e+04 7.41241000000000058e+04 1.32361090000000013e+03,
              5.03415000000000000e+04 7.41241000000000058e+04 1.36287130000000002e+03,
              5.53415000000000000e+04 7.41241000000000058e+04 1.31173440000000005e+03,
              6.03415000000000000e+04 7.41241000000000058e+04 1.36365020000000004e+03,
              6.53415000000000000e+04 7.41241000000000058e+04 1.34849820000000000e+03,
              7.03415000000000000e+04 7.41241000000000058e+04 1.29932089999999994e+03,
              7.53415000000000000e+04 7.41241000000000058e+04 1.27014020000000005e+03,
              8.03415000000000000e+04 7.41241000000000058e+04 1.30866170000000011e+03,
              8.53415000000000000e+04 7.41241000000000058e+04 1.32800009999999997e+03,
              9.03415000000000000e+04 7.41241000000000058e+04 1.27097939999999994e+03,
              4.72915000000000000e+04 7.40741000000000058e+04 1.32964760000000001e+03,
              5.22915000000000000e+04 7.40741000000000058e+04 1.33700970000000007e+03,
              5.72915000000000000e+04 7.40741000000000058e+04 1.36397720000000004e+03,
              6.22915000000000000e+04 7.40741000000000058e+04 1.36153099999999995e+03,
              6.72915000000000000e+04 7.40741000000000058e+04 1.37415329999999994e+03,
              7.22915000000000000e+04 7.40741000000000058e+04 1.29230420000000004e+03,
              7.72915000000000000e+04 7.40741000000000058e+04 1.28763660000000004e+03,
              8.22915000000000000e+04 7.40741000000000058e+04 1.31981829999999991e+03,
              8.72915000000000000e+04 7.40741000000000058e+04 1.25807140000000004e+03,
              4.42415000000000000e+04 7.40241000000000058e+04 1.30895620000000008e+03,
              4.92415000000000000e+04 7.40241000000000058e+04 1.33110349999999994e+03,
              5.42415000000000000e+04 7.40241000000000058e+04 1.29957790000000000e+03,
              5.92415000000000000e+04 7.40241000000000058e+04 1.35283480000000009e+03,
              6.42415000000000000e+04 7.40241000000000058e+04 1.35724299999999994e+03,
              6.92415000000000000e+04 7.40241000000000058e+04 1.31329829999999993e+03,
              7.42415000000000000e+04 7.40241000000000058e+04 1.28492730000000006e+03,
              7.92415000000000000e+04 7.40241000000000058e+04 1.30421650000000000e+03,
              8.42415000000000000e+04 7.40241000000000058e+04 1.31589339999999993e+03,
              8.92415000000000000e+04 7.40241000000000058e+04 1.29548129999999992e+03,
              4.61915000000000000e+04 7.39741000000000058e+04 1.30882200000000012e+03,
              5.11915000000000000e+04 7.39741000000000058e+04 1.37848219999999992e+03,
              5.61915000000000000e+04 7.39741000000000058e+04 1.32463490000000002e+03,
              6.11915000000000000e+04 7.39741000000000058e+04 1.35762370000000010e+03,
              6.61915000000000000e+04 7.39741000000000058e+04 1.35983930000000009e+03,
              7.11915000000000000e+04 7.39741000000000058e+04 1.29424150000000009e+03,
              7.61915000000000000e+04 7.39741000000000058e+04 1.26710019999999986e+03,
              8.11915000000000000e+04 7.39741000000000058e+04 1.33203080000000000e+03,
              8.61915000000000000e+04 7.39741000000000058e+04 1.28005119999999988e+03,
              4.31415000000000000e+04 7.39241000000000058e+04 1.25811140000000000e+03,
              4.81415000000000000e+04 7.39241000000000058e+04 1.28664840000000004e+03,
              5.31415000000000000e+04 7.39241000000000058e+04 1.34764589999999998e+03,
              5.81415000000000000e+04 7.39241000000000058e+04 1.36248320000000012e+03,
              6.31415000000000000e+04 7.39241000000000058e+04 1.36034639999999990e+03,
              6.81415000000000000e+04 7.39241000000000058e+04 1.33894110000000001e+03,
              7.31415000000000000e+04 7.39241000000000058e+04 1.30472109999999998e+03,
              7.81415000000000000e+04 7.39241000000000058e+04 1.27926109999999994e+03,
              8.31415000000000000e+04 7.39241000000000058e+04 1.32777440000000001e+03,
              8.81415000000000000e+04 7.39241000000000058e+04 1.30166910000000007e+03,
              4.50915000000000000e+04 7.38741000000000058e+04 1.28845100000000002e+03,
              5.00915000000000000e+04 7.38741000000000058e+04 1.35313000000000011e+03,
              5.50915000000000000e+04 7.38741000000000058e+04 1.28994959999999992e+03,
              6.00915000000000000e+04 7.38741000000000058e+04 1.35328060000000005e+03,
              6.50915000000000000e+04 7.38741000000000058e+04 1.35478539999999998e+03,
              7.00915000000000000e+04 7.38741000000000058e+04 1.29630659999999989e+03,
              7.50915000000000000e+04 7.38741000000000058e+04 1.27124739999999997e+03,
              8.00915000000000000e+04 7.38741000000000058e+04 1.31011149999999998e+03,
              8.50915000000000000e+04 7.38741000000000058e+04 1.33849379999999996e+03,
              9.00915000000000000e+04 7.38741000000000058e+04 1.25892810000000009e+03,
              4.70415000000000000e+04 7.38241000000000058e+04 1.32182639999999992e+03,
              5.20415000000000000e+04 7.38241000000000058e+04 1.36493750000000000e+03,
              5.70415000000000000e+04 7.38241000000000058e+04 1.35599080000000004e+03,
              6.20415000000000000e+04 7.38241000000000058e+04 1.35268489999999997e+03,
              6.70415000000000000e+04 7.38241000000000058e+04 1.36196190000000001e+03,
              7.20415000000000000e+04 7.38241000000000058e+04 1.28397060000000010e+03,
              7.70415000000000000e+04 7.38241000000000058e+04 1.27587100000000009e+03,
              8.20415000000000000e+04 7.38241000000000058e+04 1.30432999999999993e+03,
              8.70415000000000000e+04 7.38241000000000058e+04 1.25267859999999996e+03,
              4.39915000000000000e+04 7.37741000000000058e+04 1.28537329999999997e+03,
              4.89915000000000000e+04 7.37741000000000058e+04 1.30202219999999988e+03,
              5.39915000000000000e+04 7.37741000000000058e+04 1.30732349999999997e+03,
              5.89915000000000000e+04 7.37741000000000058e+04 1.33448430000000008e+03,
              6.39915000000000000e+04 7.37741000000000058e+04 1.36939859999999999e+03,
              6.89915000000000000e+04 7.37741000000000058e+04 1.33147720000000004e+03,
              7.39915000000000000e+04 7.37741000000000058e+04 1.28913229999999999e+03,
              7.89915000000000000e+04 7.37741000000000058e+04 1.30583609999999999e+03,
              8.39915000000000000e+04 7.37741000000000058e+04 1.31606179999999995e+03,
              8.89915000000000000e+04 7.37741000000000058e+04 1.28091609999999991e+03,
              4.59415000000000000e+04 7.37241000000000058e+04 1.31106619999999998e+03,
              5.09415000000000000e+04 7.37241000000000058e+04 1.35990480000000002e+03,
              5.59415000000000000e+04 7.37241000000000058e+04 1.35591280000000006e+03,
              6.09415000000000000e+04 7.37241000000000058e+04 1.35159490000000005e+03,
              6.59415000000000000e+04 7.37241000000000058e+04 1.35857479999999987e+03,
              7.09415000000000000e+04 7.37241000000000058e+04 1.29641120000000001e+03,
              7.59415000000000000e+04 7.37241000000000058e+04 1.27503199999999993e+03,
              8.09415000000000000e+04 7.37241000000000058e+04 1.32905009999999993e+03,
              8.59415000000000000e+04 7.37241000000000058e+04 1.29559400000000005e+03,
              9.09415000000000000e+04 7.37241000000000058e+04 1.27496830000000000e+03,
              4.78915000000000000e+04 7.36741000000000058e+04 1.30854939999999988e+03,
              5.28915000000000000e+04 7.36741000000000058e+04 1.37337220000000002e+03,
              5.78915000000000000e+04 7.36741000000000058e+04 1.35498440000000005e+03,
              6.28915000000000000e+04 7.36741000000000058e+04 1.35281189999999992e+03,
              6.78915000000000000e+04 7.36741000000000058e+04 1.35358140000000003e+03,
              7.28915000000000000e+04 7.36741000000000058e+04 1.28954840000000013e+03,
              7.78915000000000000e+04 7.36741000000000058e+04 1.27935829999999987e+03,
              8.28915000000000000e+04 7.36741000000000058e+04 1.33484390000000008e+03,
              8.78915000000000000e+04 7.36741000000000058e+04 1.28475959999999986e+03,
              4.48415000000000000e+04 7.36241000000000058e+04 1.27704250000000002e+03,
              4.98415000000000000e+04 7.36241000000000058e+04 1.33244569999999999e+03,
              5.48415000000000000e+04 7.36241000000000058e+04 1.28034189999999990e+03,
              5.98415000000000000e+04 7.36241000000000058e+04 1.35056169999999997e+03,
              6.48415000000000000e+04 7.36241000000000058e+04 1.35791350000000011e+03,
              6.98415000000000000e+04 7.36241000000000058e+04 1.31344010000000003e+03,
              7.48415000000000000e+04 7.36241000000000058e+04 1.28443119999999999e+03,
              7.98415000000000000e+04 7.36241000000000058e+04 1.32784699999999998e+03,
              8.48415000000000000e+04 7.36241000000000058e+04 1.33039980000000014e+03,
              8.98415000000000000e+04 7.36241000000000058e+04 1.26412230000000000e+03,
              4.67915000000000000e+04 7.35741000000000058e+04 1.32555619999999999e+03,
              5.17915000000000000e+04 7.35741000000000058e+04 1.37224710000000005e+03,
              5.67915000000000000e+04 7.35741000000000058e+04 1.34867679999999996e+03,
              6.17915000000000000e+04 7.35741000000000058e+04 1.34481559999999990e+03,
              6.67915000000000000e+04 7.35741000000000058e+04 1.33809480000000008e+03,
              7.17915000000000000e+04 7.35741000000000058e+04 1.28425890000000004e+03,
              7.67915000000000000e+04 7.35741000000000058e+04 1.28471569999999997e+03,
              8.17915000000000000e+04 7.35741000000000058e+04 1.30278230000000008e+03,
              8.67915000000000000e+04 7.35741000000000058e+04 1.26491300000000001e+03,
              4.37415000000000000e+04 7.35241000000000058e+04 1.28181220000000008e+03,
              4.87415000000000000e+04 7.35241000000000058e+04 1.29902729999999997e+03,
              5.37415000000000000e+04 7.35241000000000058e+04 1.33433069999999998e+03,
              5.87415000000000000e+04 7.35241000000000058e+04 1.35457790000000000e+03,
              6.37415000000000000e+04 7.35241000000000058e+04 1.37207700000000000e+03,
              6.87415000000000000e+04 7.35241000000000058e+04 1.32016910000000007e+03,
              7.37415000000000000e+04 7.35241000000000058e+04 1.29706880000000001e+03,
              7.87415000000000000e+04 7.35241000000000058e+04 1.30171800000000007e+03,
              8.37415000000000000e+04 7.35241000000000058e+04 1.31353130000000010e+03,
              8.87415000000000000e+04 7.35241000000000058e+04 1.28412979999999993e+03,
              4.56915000000000000e+04 7.34741000000000058e+04 1.33973149999999987e+03,
              5.06915000000000000e+04 7.34741000000000058e+04 1.34941550000000007e+03,
              5.56915000000000000e+04 7.34741000000000058e+04 1.33045990000000006e+03,
              6.06915000000000000e+04 7.34741000000000058e+04 1.34482099999999991e+03,
              6.56915000000000000e+04 7.34741000000000058e+04 1.36143480000000000e+03,
              7.06915000000000000e+04 7.34741000000000058e+04 1.29582529999999997e+03,
              7.56915000000000000e+04 7.34741000000000058e+04 1.28145020000000000e+03,
              8.06915000000000000e+04 7.34741000000000058e+04 1.32432700000000000e+03,
              8.56915000000000000e+04 7.34741000000000058e+04 1.33357709999999997e+03,
              9.06915000000000000e+04 7.34741000000000058e+04 1.26652500000000009e+03,
              4.76415000000000000e+04 7.34241000000000058e+04 1.28770810000000006e+03,
              5.26415000000000000e+04 7.34241000000000058e+04 1.36284339999999997e+03,
              5.76415000000000000e+04 7.34241000000000058e+04 1.34270049999999992e+03,
              6.26415000000000000e+04 7.34241000000000058e+04 1.34574559999999997e+03,
              6.76415000000000000e+04 7.34241000000000058e+04 1.33755529999999999e+03,
              7.26415000000000000e+04 7.34241000000000058e+04 1.28708879999999999e+03,
              7.76415000000000000e+04 7.34241000000000058e+04 1.28489010000000007e+03,
              8.26415000000000000e+04 7.34241000000000058e+04 1.32873730000000000e+03,
              8.76415000000000000e+04 7.34241000000000058e+04 1.27488689999999997e+03,
              4.45915000000000000e+04 7.33741000000000058e+04 1.29671270000000004e+03,
              4.95915000000000000e+04 7.33741000000000058e+04 1.35763950000000000e+03,
              5.45915000000000000e+04 7.33741000000000058e+04 1.28740799999999990e+03,
              5.95915000000000000e+04 7.33741000000000058e+04 1.34115030000000002e+03,
              6.45915000000000000e+04 7.33741000000000058e+04 1.36562650000000008e+03,
              6.95915000000000000e+04 7.33741000000000058e+04 1.30413400000000001e+03,
              7.45915000000000000e+04 7.33741000000000058e+04 1.28437230000000000e+03,
              7.95915000000000000e+04 7.33741000000000058e+04 1.28019849999999997e+03,
              8.45915000000000000e+04 7.33741000000000058e+04 1.31108359999999993e+03,
              8.95915000000000000e+04 7.33741000000000058e+04 1.27320280000000002e+03,
              4.65415000000000000e+04 7.33241000000000058e+04 1.28303880000000004e+03,
              5.15415000000000000e+04 7.33241000000000058e+04 1.34289190000000008e+03,
              5.65415000000000000e+04 7.33241000000000058e+04 1.34817589999999996e+03,
              6.15415000000000000e+04 7.33241000000000058e+04 1.34481750000000011e+03,
              6.65415000000000000e+04 7.33241000000000058e+04 1.34476409999999987e+03,
              7.15415000000000000e+04 7.33241000000000058e+04 1.28962480000000005e+03,
              7.65415000000000000e+04 7.33241000000000058e+04 1.29847469999999998e+03,
              8.15415000000000000e+04 7.33241000000000058e+04 1.29237889999999993e+03,
              8.65415000000000000e+04 7.33241000000000058e+04 1.28056950000000006e+03,
              4.34915000000000000e+04 7.32741000000000058e+04 1.27945569999999998e+03,
              4.84915000000000000e+04 7.32741000000000058e+04 1.31802880000000005e+03,
              5.34915000000000000e+04 7.32741000000000058e+04 1.36106889999999999e+03,
              5.84915000000000000e+04 7.32741000000000058e+04 1.36386220000000003e+03,
              6.34915000000000000e+04 7.32741000000000058e+04 1.40422980000000007e+03,
              6.84915000000000000e+04 7.32741000000000058e+04 1.30988639999999987e+03,
              7.34915000000000000e+04 7.32741000000000058e+04 1.30146630000000005e+03,
              7.84915000000000000e+04 7.32741000000000058e+04 1.29523669999999993e+03,
              8.34915000000000000e+04 7.32741000000000058e+04 1.32880439999999999e+03,
              8.84915000000000000e+04 7.32741000000000058e+04 1.28585149999999999e+03,
              4.54415000000000000e+04 7.32241000000000058e+04 1.32237979999999993e+03,
              5.04415000000000000e+04 7.32241000000000058e+04 1.34238369999999986e+03,
              5.54415000000000000e+04 7.32241000000000058e+04 1.33007240000000002e+03,
              6.04415000000000000e+04 7.32241000000000058e+04 1.35118380000000002e+03,
              6.54415000000000000e+04 7.32241000000000058e+04 1.36073919999999998e+03,
              7.04415000000000000e+04 7.32241000000000058e+04 1.29247620000000006e+03,
              7.54415000000000000e+04 7.32241000000000058e+04 1.27579349999999999e+03,
              8.04415000000000000e+04 7.32241000000000058e+04 1.32589640000000009e+03,
              8.54415000000000000e+04 7.32241000000000058e+04 1.32130299999999988e+03,
              9.04415000000000000e+04 7.32241000000000058e+04 1.26230889999999999e+03,
              4.73915000000000000e+04 7.31741000000000058e+04 1.28933680000000004e+03,
              5.23915000000000000e+04 7.31741000000000058e+04 1.32408580000000006e+03,
              5.73915000000000000e+04 7.31741000000000058e+04 1.33446250000000009e+03,
              6.23915000000000000e+04 7.31741000000000058e+04 1.33523479999999995e+03,
              6.73915000000000000e+04 7.31741000000000058e+04 1.37319849999999997e+03,
              7.23915000000000000e+04 7.31741000000000058e+04 1.29267520000000013e+03,
              7.73915000000000000e+04 7.31741000000000058e+04 1.29108240000000001e+03,
              8.23915000000000000e+04 7.31741000000000058e+04 1.32325189999999998e+03,
              8.73915000000000000e+04 7.31741000000000058e+04 1.28462650000000008e+03,
              4.43415000000000000e+04 7.31241000000000058e+04 1.27858979999999997e+03,
              4.93415000000000000e+04 7.31241000000000058e+04 1.34011819999999989e+03,
              5.43415000000000000e+04 7.31241000000000058e+04 1.31729019999999991e+03,
              5.93415000000000000e+04 7.31241000000000058e+04 1.33471340000000009e+03,
              6.43415000000000000e+04 7.31241000000000058e+04 1.35398119999999994e+03,
              6.93415000000000000e+04 7.31241000000000058e+04 1.30082259999999997e+03,
              7.43415000000000000e+04 7.31241000000000058e+04 1.28691399999999999e+03,
              7.93415000000000000e+04 7.31241000000000058e+04 1.28075610000000006e+03,
              8.43415000000000000e+04 7.31241000000000058e+04 1.32708100000000013e+03,
              8.93415000000000000e+04 7.31241000000000058e+04 1.27786130000000003e+03,
              4.62915000000000000e+04 7.30741000000000058e+04 1.29322170000000006e+03,
              5.12915000000000000e+04 7.30741000000000058e+04 1.34997640000000001e+03,
              5.62915000000000000e+04 7.30741000000000058e+04 1.33704970000000003e+03,
              6.12915000000000000e+04 7.30741000000000058e+04 1.34752539999999999e+03,
              6.62915000000000000e+04 7.30741000000000058e+04 1.37070650000000001e+03,
              7.12915000000000000e+04 7.30741000000000058e+04 1.30649620000000004e+03,
              7.62915000000000000e+04 7.30741000000000058e+04 1.28891159999999991e+03,
              8.12915000000000000e+04 7.30741000000000058e+04 1.27780670000000009e+03,
              8.62915000000000000e+04 7.30741000000000058e+04 1.27784110000000010e+03,
              4.32415000000000000e+04 7.30241000000000058e+04 1.29171289999999999e+03,
              4.82415000000000000e+04 7.30241000000000058e+04 1.29432200000000012e+03,
              5.32415000000000000e+04 7.30241000000000058e+04 1.34915730000000008e+03,
              5.82415000000000000e+04 7.30241000000000058e+04 1.35459639999999990e+03,
              6.32415000000000000e+04 7.30241000000000058e+04 1.38029099999999994e+03,
              6.82415000000000000e+04 7.30241000000000058e+04 1.32145139999999992e+03,
              7.32415000000000000e+04 7.30241000000000058e+04 1.31539280000000008e+03,
              7.82415000000000000e+04 7.30241000000000058e+04 1.29234449999999993e+03,
              8.32415000000000000e+04 7.30241000000000058e+04 1.33196579999999994e+03,
              8.82415000000000000e+04 7.30241000000000058e+04 1.28431340000000000e+03,
              4.51915000000000000e+04 7.29741000000000058e+04 1.30296430000000009e+03,
              5.01915000000000000e+04 7.29741000000000058e+04 1.29469689999999991e+03,
              5.51915000000000000e+04 7.29741000000000058e+04 1.32683840000000009e+03,
              6.01915000000000000e+04 7.29741000000000058e+04 1.34466370000000006e+03,
              6.51915000000000000e+04 7.29741000000000058e+04 1.36322859999999991e+03,
              7.01915000000000000e+04 7.29741000000000058e+04 1.29626960000000008e+03,
              7.51915000000000000e+04 7.29741000000000058e+04 1.27700440000000003e+03,
              8.01915000000000000e+04 7.29741000000000058e+04 1.32605330000000004e+03,
              8.51915000000000000e+04 7.29741000000000058e+04 1.30663570000000004e+03,
              9.01915000000000000e+04 7.29741000000000058e+04 1.28079999999999995e+03,
              4.71415000000000000e+04 7.29241000000000058e+04 1.31095939999999996e+03,
              5.21415000000000000e+04 7.29241000000000058e+04 1.34479050000000007e+03,
              5.71415000000000000e+04 7.29241000000000058e+04 1.31866800000000012e+03,
              6.21415000000000000e+04 7.29241000000000058e+04 1.33286860000000001e+03,
              6.71415000000000000e+04 7.29241000000000058e+04 1.37366319999999996e+03,
              7.21415000000000000e+04 7.29241000000000058e+04 1.30657490000000007e+03,
              7.71415000000000000e+04 7.29241000000000058e+04 1.30347969999999987e+03,
              8.21415000000000000e+04 7.29241000000000058e+04 1.30572240000000011e+03,
              8.71415000000000000e+04 7.29241000000000058e+04 1.27207680000000005e+03,
              4.40915000000000000e+04 7.28741000000000058e+04 1.26048880000000008e+03,
              4.90915000000000000e+04 7.28741000000000058e+04 1.34415239999999994e+03,
              5.40915000000000000e+04 7.28741000000000058e+04 1.33959780000000001e+03,
              5.90915000000000000e+04 7.28741000000000058e+04 1.35489210000000003e+03,
              6.40915000000000000e+04 7.28741000000000058e+04 1.37251919999999996e+03,
              6.90915000000000000e+04 7.28741000000000058e+04 1.32370010000000002e+03,
              7.40915000000000000e+04 7.28741000000000058e+04 1.28613139999999999e+03,
              7.90915000000000000e+04 7.28741000000000058e+04 1.28867750000000001e+03,
              8.40915000000000000e+04 7.28741000000000058e+04 1.32886529999999993e+03,
              8.90915000000000000e+04 7.28741000000000058e+04 1.28672270000000003e+03,
              4.60415000000000000e+04 7.28241000000000058e+04 1.28962359999999990e+03,
              5.10415000000000000e+04 7.28241000000000058e+04 1.33418219999999997e+03,
              5.60415000000000000e+04 7.28241000000000058e+04 1.32821589999999992e+03,
              6.10415000000000000e+04 7.28241000000000058e+04 1.36928170000000000e+03,
              6.60415000000000000e+04 7.28241000000000058e+04 1.38199209999999994e+03,
              7.10415000000000000e+04 7.28241000000000058e+04 1.31638889999999992e+03,
              7.60415000000000000e+04 7.28241000000000058e+04 1.29482069999999999e+03,
              8.10415000000000000e+04 7.28241000000000058e+04 1.27780070000000001e+03,
              8.60415000000000000e+04 7.28241000000000058e+04 1.27092250000000013e+03,
              4.29915000000000000e+04 7.27741000000000058e+04 1.28165349999999989e+03,
              4.79915000000000000e+04 7.27741000000000058e+04 1.26767509999999993e+03,
              5.29915000000000000e+04 7.27741000000000058e+04 1.34222659999999996e+03,
              5.79915000000000000e+04 7.27741000000000058e+04 1.34356240000000003e+03,
              6.29915000000000000e+04 7.27741000000000058e+04 1.35656629999999996e+03,
              6.79915000000000000e+04 7.27741000000000058e+04 1.33276209999999992e+03,
              7.29915000000000000e+04 7.27741000000000058e+04 1.30746839999999997e+03,
              7.79915000000000000e+04 7.27741000000000058e+04 1.29155130000000008e+03,
              8.29915000000000000e+04 7.27741000000000058e+04 1.32848569999999995e+03,
              8.79915000000000000e+04 7.27741000000000058e+04 1.28315120000000002e+03,
              4.49415000000000000e+04 7.27241000000000058e+04 1.27252900000000000e+03,
              4.99415000000000000e+04 7.27241000000000058e+04 1.32849000000000001e+03,
              5.49415000000000000e+04 7.27241000000000058e+04 1.28035889999999995e+03,
              5.99415000000000000e+04 7.27241000000000058e+04 1.33700779999999986e+03,
              6.49415000000000000e+04 7.27241000000000058e+04 1.35472620000000006e+03,
              6.99415000000000000e+04 7.27241000000000058e+04 1.30206169999999997e+03,
              7.49415000000000000e+04 7.27241000000000058e+04 1.27788589999999999e+03,
              7.99415000000000000e+04 7.27241000000000058e+04 1.32009079999999994e+03,
              8.49415000000000000e+04 7.27241000000000058e+04 1.31808819999999992e+03,
              8.99415000000000000e+04 7.27241000000000058e+04 1.28030240000000003e+03,
              4.68915000000000000e+04 7.26741000000000058e+04 1.30822209999999995e+03,
              5.18915000000000000e+04 7.26741000000000058e+04 1.32384619999999995e+03,
              5.68915000000000000e+04 7.26741000000000058e+04 1.31786040000000003e+03,
              6.18915000000000000e+04 7.26741000000000058e+04 1.33039989999999989e+03,
              6.68915000000000000e+04 7.26741000000000058e+04 1.37048109999999997e+03,
              7.18915000000000000e+04 7.26741000000000058e+04 1.32377060000000006e+03,
              7.68915000000000000e+04 7.26741000000000058e+04 1.29332970000000000e+03,
              8.18915000000000000e+04 7.26741000000000058e+04 1.30192570000000001e+03,
              8.68915000000000000e+04 7.26741000000000058e+04 1.26308370000000014e+03,
              4.38415000000000000e+04 7.26241000000000058e+04 1.29350759999999991e+03,
              4.88415000000000000e+04 7.26241000000000058e+04 1.34657110000000011e+03,
              5.38415000000000000e+04 7.26241000000000058e+04 1.33722190000000001e+03,
              5.88415000000000000e+04 7.26241000000000058e+04 1.36866689999999994e+03,
              6.38415000000000000e+04 7.26241000000000058e+04 1.35535210000000006e+03,
              6.88415000000000000e+04 7.26241000000000058e+04 1.32943260000000009e+03,
              7.38415000000000000e+04 7.26241000000000058e+04 1.28694270000000006e+03,
              7.88415000000000000e+04 7.26241000000000058e+04 1.27991170000000011e+03,
              8.38415000000000000e+04 7.26241000000000058e+04 1.32199800000000005e+03,
              8.88415000000000000e+04 7.26241000000000058e+04 1.28280520000000001e+03,
              4.57915000000000000e+04 7.25741000000000058e+04 1.28639400000000001e+03,
              5.07915000000000000e+04 7.25741000000000058e+04 1.32263139999999999e+03,
              5.57915000000000000e+04 7.25741000000000058e+04 1.31826769999999988e+03,
              6.07915000000000000e+04 7.25741000000000058e+04 1.36923800000000006e+03,
              6.57915000000000000e+04 7.25741000000000058e+04 1.37030189999999993e+03,
              7.07915000000000000e+04 7.25741000000000058e+04 1.32950140000000010e+03,
              7.57915000000000000e+04 7.25741000000000058e+04 1.30493440000000010e+03,
              8.07915000000000000e+04 7.25741000000000058e+04 1.27712619999999993e+03,
              8.57915000000000000e+04 7.25741000000000058e+04 1.26902729999999997e+03,
              9.07915000000000000e+04 7.25741000000000058e+04 1.27542979999999989e+03,
              4.77415000000000000e+04 7.25241000000000058e+04 1.26431019999999990e+03,
              5.27415000000000000e+04 7.25241000000000058e+04 1.30443859999999995e+03,
              5.77415000000000000e+04 7.25241000000000058e+04 1.33488030000000003e+03,
              6.27415000000000000e+04 7.25241000000000058e+04 1.33482850000000008e+03,
              6.77415000000000000e+04 7.25241000000000058e+04 1.35225129999999990e+03,
              7.27415000000000000e+04 7.25241000000000058e+04 1.30734179999999992e+03,
              7.77415000000000000e+04 7.25241000000000058e+04 1.28423950000000013e+03,
              8.27415000000000000e+04 7.25241000000000058e+04 1.32558100000000013e+03,
              8.77415000000000000e+04 7.25241000000000058e+04 1.29523779999999988e+03,
              4.46915000000000000e+04 7.24741000000000058e+04 1.27803479999999990e+03,
              4.96915000000000000e+04 7.24741000000000058e+04 1.33192129999999997e+03,
              5.46915000000000000e+04 7.24741000000000058e+04 1.29066110000000003e+03,
              5.96915000000000000e+04 7.24741000000000058e+04 1.33751049999999987e+03,
              6.46915000000000000e+04 7.24741000000000058e+04 1.35437590000000000e+03,
              6.96915000000000000e+04 7.24741000000000058e+04 1.30497060000000010e+03,
              7.46915000000000000e+04 7.24741000000000058e+04 1.27718969999999990e+03,
              7.96915000000000000e+04 7.24741000000000058e+04 1.32404909999999995e+03,
              8.46915000000000000e+04 7.24741000000000058e+04 1.32744949999999994e+03,
              8.96915000000000000e+04 7.24741000000000058e+04 1.28681600000000003e+03,
              4.66415000000000000e+04 7.24241000000000058e+04 1.31009719999999993e+03,
              5.16415000000000000e+04 7.24241000000000058e+04 1.33759770000000003e+03,
              5.66415000000000000e+04 7.24241000000000058e+04 1.29698260000000005e+03,
              6.16415000000000000e+04 7.24241000000000058e+04 1.32523779999999988e+03,
              6.66415000000000000e+04 7.24241000000000058e+04 1.34559899999999993e+03,
              7.16415000000000000e+04 7.24241000000000058e+04 1.31192200000000003e+03,
              7.66415000000000000e+04 7.24241000000000058e+04 1.28832200000000012e+03,
              8.16415000000000000e+04 7.24241000000000058e+04 1.29348379999999997e+03,
              8.66415000000000000e+04 7.24241000000000058e+04 1.26138310000000001e+03,
              4.35915000000000000e+04 7.23741000000000058e+04 1.28100779999999986e+03,
              4.85915000000000000e+04 7.23741000000000058e+04 1.33910059999999999e+03,
              5.35915000000000000e+04 7.23741000000000058e+04 1.33009310000000005e+03,
              5.85915000000000000e+04 7.23741000000000058e+04 1.38039179999999988e+03,
              6.35915000000000000e+04 7.23741000000000058e+04 1.35950630000000001e+03,
              6.85915000000000000e+04 7.23741000000000058e+04 1.33955369999999994e+03,
              7.35915000000000000e+04 7.23741000000000058e+04 1.27762550000000010e+03,
              7.85915000000000000e+04 7.23741000000000058e+04 1.28436959999999999e+03,
              8.35915000000000000e+04 7.23741000000000058e+04 1.30853520000000003e+03,
              8.85915000000000000e+04 7.23741000000000058e+04 1.28224910000000000e+03,
              4.55415000000000000e+04 7.23241000000000058e+04 1.28680980000000000e+03,
              5.05415000000000000e+04 7.23241000000000058e+04 1.28935460000000012e+03,
              5.55415000000000000e+04 7.23241000000000058e+04 1.29622929999999997e+03,
              6.05415000000000000e+04 7.23241000000000058e+04 1.37206750000000011e+03,
              6.55415000000000000e+04 7.23241000000000058e+04 1.35240730000000008e+03,
              7.05415000000000000e+04 7.23241000000000058e+04 1.35395100000000002e+03,
              7.55415000000000000e+04 7.23241000000000058e+04 1.30271059999999989e+03,
              8.05415000000000000e+04 7.23241000000000058e+04 1.28353310000000010e+03,
              8.55415000000000000e+04 7.23241000000000058e+04 1.26132200000000012e+03,
              9.05415000000000000e+04 7.23241000000000058e+04 1.29952449999999999e+03,
              4.74915000000000000e+04 7.22741000000000058e+04 1.27487820000000011e+03,
              5.24915000000000000e+04 7.22741000000000058e+04 1.32704129999999986e+03,
              5.74915000000000000e+04 7.22741000000000058e+04 1.33001659999999993e+03,
              6.24915000000000000e+04 7.22741000000000058e+04 1.32467959999999994e+03,
              6.74915000000000000e+04 7.22741000000000058e+04 1.34100279999999998e+03,
              7.24915000000000000e+04 7.22741000000000058e+04 1.28999949999999990e+03,
              7.74915000000000000e+04 7.22741000000000058e+04 1.28906520000000000e+03,
              8.24915000000000000e+04 7.22741000000000058e+04 1.31372479999999996e+03,
              8.74915000000000000e+04 7.22741000000000058e+04 1.29047049999999990e+03,
              4.44415000000000000e+04 7.22241000000000058e+04 1.25096849999999995e+03,
              4.94415000000000000e+04 7.22241000000000058e+04 1.32642349999999988e+03,
              5.44415000000000000e+04 7.22241000000000058e+04 1.32098399999999992e+03,
              5.94415000000000000e+04 7.22241000000000058e+04 1.34485660000000007e+03,
              6.44415000000000000e+04 7.22241000000000058e+04 1.34317820000000006e+03,
              6.94415000000000000e+04 7.22241000000000058e+04 1.31023360000000002e+03,
              7.44415000000000000e+04 7.22241000000000058e+04 1.28696730000000002e+03,
              7.94415000000000000e+04 7.22241000000000058e+04 1.32250610000000006e+03,
              8.44415000000000000e+04 7.22241000000000058e+04 1.32746149999999989e+03,
              8.94415000000000000e+04 7.22241000000000058e+04 1.27873160000000007e+03,
              4.63915000000000000e+04 7.21741000000000058e+04 1.29125590000000011e+03,
              5.13915000000000000e+04 7.21741000000000058e+04 1.34912230000000000e+03,
              5.63915000000000000e+04 7.21741000000000058e+04 1.31016239999999993e+03,
              6.13915000000000000e+04 7.21741000000000058e+04 1.32188159999999993e+03,
              6.63915000000000000e+04 7.21741000000000058e+04 1.35629259999999999e+03,
              7.13915000000000000e+04 7.21741000000000058e+04 1.32017319999999995e+03,
              7.63915000000000000e+04 7.21741000000000058e+04 1.29419119999999998e+03,
              8.13915000000000000e+04 7.21741000000000058e+04 1.29184719999999993e+03,
              8.63915000000000000e+04 7.21741000000000058e+04 1.27603080000000000e+03,
              4.33415000000000000e+04 7.21241000000000058e+04 1.27656130000000007e+03,
              4.83415000000000000e+04 7.21241000000000058e+04 1.30296209999999996e+03,
              5.33415000000000000e+04 7.21241000000000058e+04 1.31749520000000007e+03,
              5.83415000000000000e+04 7.21241000000000058e+04 1.39079549999999995e+03,
              6.33415000000000000e+04 7.21241000000000058e+04 1.36314339999999993e+03,
              6.83415000000000000e+04 7.21241000000000058e+04 1.34398080000000004e+03,
              7.33415000000000000e+04 7.21241000000000058e+04 1.28596579999999994e+03,
              7.83415000000000000e+04 7.21241000000000058e+04 1.30141599999999994e+03,
              8.33415000000000000e+04 7.21241000000000058e+04 1.31443039999999996e+03,
              8.83415000000000000e+04 7.21241000000000058e+04 1.24703299999999990e+03,
              4.52915000000000000e+04 7.20741000000000058e+04 1.29957259999999997e+03,
              5.02915000000000000e+04 7.20741000000000058e+04 1.28209760000000006e+03,
              5.52915000000000000e+04 7.20741000000000058e+04 1.27469360000000006e+03,
              6.02915000000000000e+04 7.20741000000000058e+04 1.36948160000000007e+03,
              6.52915000000000000e+04 7.20741000000000058e+04 1.33587940000000003e+03,
              7.02915000000000000e+04 7.20741000000000058e+04 1.32638120000000004e+03,
              7.52915000000000000e+04 7.20741000000000058e+04 1.30069070000000011e+03,
              8.02915000000000000e+04 7.20741000000000058e+04 1.28032040000000006e+03,
              8.52915000000000000e+04 7.20741000000000058e+04 1.27221440000000007e+03,
              9.02915000000000000e+04 7.20741000000000058e+04 1.29881230000000005e+03,
              4.72415000000000000e+04 7.20241000000000058e+04 1.26064710000000014e+03,
              5.22415000000000000e+04 7.20241000000000058e+04 1.33131719999999996e+03,
              5.72415000000000000e+04 7.20241000000000058e+04 1.31807819999999992e+03,
              6.22415000000000000e+04 7.20241000000000058e+04 1.31916159999999991e+03,
              6.72415000000000000e+04 7.20241000000000058e+04 1.32736000000000013e+03,
              7.22415000000000000e+04 7.20241000000000058e+04 1.29508870000000002e+03,
              7.72415000000000000e+04 7.20241000000000058e+04 1.29417160000000013e+03,
              8.22415000000000000e+04 7.20241000000000058e+04 1.30683019999999988e+03,
              8.72415000000000000e+04 7.20241000000000058e+04 1.27831199999999990e+03,
              4.41915000000000000e+04 7.19741000000000058e+04 1.25202459999999996e+03,
              4.91915000000000000e+04 7.19741000000000058e+04 1.28920859999999993e+03,
              5.41915000000000000e+04 7.19741000000000058e+04 1.31884059999999999e+03,
              5.91915000000000000e+04 7.19741000000000058e+04 1.35746170000000006e+03,
              6.41915000000000000e+04 7.19741000000000058e+04 1.33977480000000014e+03,
              6.91915000000000000e+04 7.19741000000000058e+04 1.34512159999999994e+03,
              7.41915000000000000e+04 7.19741000000000058e+04 1.30466280000000006e+03,
              7.91915000000000000e+04 7.19741000000000058e+04 1.31215190000000007e+03,
              8.41915000000000000e+04 7.19741000000000058e+04 1.32305899999999997e+03,
              8.91915000000000000e+04 7.19741000000000058e+04 1.26000170000000003e+03,
              4.61415000000000000e+04 7.19241000000000058e+04 1.26099360000000001e+03,
              5.11415000000000000e+04 7.19241000000000058e+04 1.33952379999999994e+03,
              5.61415000000000000e+04 7.19241000000000058e+04 1.28472060000000010e+03,
              6.11415000000000000e+04 7.19241000000000058e+04 1.33240869999999995e+03,
              6.61415000000000000e+04 7.19241000000000058e+04 1.33640120000000002e+03,
              7.11415000000000000e+04 7.19241000000000058e+04 1.31599369999999999e+03,
              7.61415000000000000e+04 7.19241000000000058e+04 1.29402579999999989e+03,
              8.11415000000000000e+04 7.19241000000000058e+04 1.27321479999999997e+03,
              8.61415000000000000e+04 7.19241000000000058e+04 1.28526430000000005e+03,
              4.30915000000000000e+04 7.18741000000000058e+04 1.30223190000000000e+03,
              4.80915000000000000e+04 7.18741000000000058e+04 1.31775250000000005e+03,
              5.30915000000000000e+04 7.18741000000000058e+04 1.29096160000000009e+03,
              5.80915000000000000e+04 7.18741000000000058e+04 1.35675340000000006e+03,
              6.30915000000000000e+04 7.18741000000000058e+04 1.34211480000000006e+03,
              6.80915000000000000e+04 7.18741000000000058e+04 1.35803119999999990e+03,
              7.30915000000000000e+04 7.18741000000000058e+04 1.29987449999999990e+03,
              7.80915000000000000e+04 7.18741000000000058e+04 1.28825080000000003e+03,
              8.30915000000000000e+04 7.18741000000000058e+04 1.30684889999999996e+03,
              8.80915000000000000e+04 7.18741000000000058e+04 1.23050320000000011e+03,
              4.50415000000000000e+04 7.18241000000000058e+04 1.29427060000000006e+03,
              5.00415000000000000e+04 7.18241000000000058e+04 1.30778109999999992e+03,
              5.50415000000000000e+04 7.18241000000000058e+04 1.30808870000000002e+03,
              6.00415000000000000e+04 7.18241000000000058e+04 1.35483280000000013e+03,
              6.50415000000000000e+04 7.18241000000000058e+04 1.33106070000000000e+03,
              7.00415000000000000e+04 7.18241000000000058e+04 1.33645569999999998e+03,
              7.50415000000000000e+04 7.18241000000000058e+04 1.29636349999999993e+03,
              8.00415000000000000e+04 7.18241000000000058e+04 1.27282369999999992e+03,
              8.50415000000000000e+04 7.18241000000000058e+04 1.26746170000000006e+03,
              9.00415000000000000e+04 7.18241000000000058e+04 1.28977260000000001e+03,
              4.69915000000000000e+04 7.17741000000000058e+04 1.27117229999999995e+03,
              5.19915000000000000e+04 7.17741000000000058e+04 1.32608640000000014e+03,
              5.69915000000000000e+04 7.17741000000000058e+04 1.31363049999999998e+03,
              6.19915000000000000e+04 7.17741000000000058e+04 1.31220339999999987e+03,
              6.69915000000000000e+04 7.17741000000000058e+04 1.34131019999999990e+03,
              7.19915000000000000e+04 7.17741000000000058e+04 1.28823599999999988e+03,
              7.69915000000000000e+04 7.17741000000000058e+04 1.29765169999999989e+03,
              8.19915000000000000e+04 7.17741000000000058e+04 1.28584359999999992e+03,
              8.69915000000000000e+04 7.17741000000000058e+04 1.25289010000000007e+03,
              4.39415000000000000e+04 7.17241000000000058e+04 1.27556269999999995e+03,
              4.89415000000000000e+04 7.17241000000000058e+04 1.32154790000000003e+03,
              5.39415000000000000e+04 7.17241000000000058e+04 1.31363660000000004e+03,
              5.89415000000000000e+04 7.17241000000000058e+04 1.32196149999999989e+03,
              6.39415000000000000e+04 7.17241000000000058e+04 1.33688959999999997e+03,
              6.89415000000000000e+04 7.17241000000000058e+04 1.35511429999999996e+03,
              7.39415000000000000e+04 7.17241000000000058e+04 1.30168969999999990e+03,
              7.89415000000000000e+04 7.17241000000000058e+04 1.28102269999999999e+03,
              8.39415000000000000e+04 7.17241000000000058e+04 1.32304320000000007e+03,
              8.89415000000000000e+04 7.17241000000000058e+04 1.22213180000000011e+03,
              4.58915000000000000e+04 7.16741000000000058e+04 1.27341229999999996e+03,
              5.08915000000000000e+04 7.16741000000000058e+04 1.31725739999999996e+03,
              5.58915000000000000e+04 7.16741000000000058e+04 1.27817399999999998e+03,
              6.08915000000000000e+04 7.16741000000000058e+04 1.32297100000000000e+03,
              6.58915000000000000e+04 7.16741000000000058e+04 1.31909789999999998e+03,
              7.08915000000000000e+04 7.16741000000000058e+04 1.31074569999999994e+03,
              7.58915000000000000e+04 7.16741000000000058e+04 1.29551980000000003e+03,
              8.08915000000000000e+04 7.16741000000000058e+04 1.27964470000000006e+03,
              8.58915000000000000e+04 7.16741000000000058e+04 1.27474980000000005e+03,
              9.08915000000000000e+04 7.16741000000000058e+04 1.27522900000000004e+03,
              4.78415000000000000e+04 7.16241000000000058e+04 1.33322519999999986e+03,
              5.28415000000000000e+04 7.16241000000000058e+04 1.31731160000000000e+03,
              5.78415000000000000e+04 7.16241000000000058e+04 1.35468249999999989e+03,
              6.28415000000000000e+04 7.16241000000000058e+04 1.31965069999999992e+03,
              6.78415000000000000e+04 7.16241000000000058e+04 1.33081120000000010e+03,
              7.28415000000000000e+04 7.16241000000000058e+04 1.30983199999999988e+03,
              7.78415000000000000e+04 7.16241000000000058e+04 1.29698569999999995e+03,
              8.28415000000000000e+04 7.16241000000000058e+04 1.31373029999999994e+03,
              8.78415000000000000e+04 7.16241000000000058e+04 1.21746589999999992e+03,
              4.47915000000000000e+04 7.15741000000000058e+04 1.28405420000000004e+03,
              4.97915000000000000e+04 7.15741000000000058e+04 1.28563969999999995e+03,
              5.47915000000000000e+04 7.15741000000000058e+04 1.31050440000000003e+03,
              5.97915000000000000e+04 7.15741000000000058e+04 1.34347479999999996e+03,
              6.47915000000000000e+04 7.15741000000000058e+04 1.33935120000000006e+03,
              6.97915000000000000e+04 7.15741000000000058e+04 1.37231050000000005e+03,
              7.47915000000000000e+04 7.15741000000000058e+04 1.29673109999999997e+03,
              7.97915000000000000e+04 7.15741000000000058e+04 1.27467759999999998e+03,
              8.47915000000000000e+04 7.15741000000000058e+04 1.27065439999999990e+03,
              8.97915000000000000e+04 7.15741000000000058e+04 1.26221209999999996e+03,
              4.67415000000000000e+04 7.15241000000000058e+04 1.29372780000000012e+03,
              5.17415000000000000e+04 7.15241000000000058e+04 1.31907299999999987e+03,
              5.67415000000000000e+04 7.15241000000000058e+04 1.30891550000000007e+03,
              6.17415000000000000e+04 7.15241000000000058e+04 1.30016359999999986e+03,
              6.67415000000000000e+04 7.15241000000000058e+04 1.33359410000000003e+03,
              7.17415000000000000e+04 7.15241000000000058e+04 1.29687210000000005e+03,
              7.67415000000000000e+04 7.15241000000000058e+04 1.30084490000000005e+03,
              8.17415000000000000e+04 7.15241000000000058e+04 1.27989930000000004e+03,
              8.67415000000000000e+04 7.15241000000000058e+04 1.27925849999999991e+03,
              4.36915000000000000e+04 7.14741000000000058e+04 1.27190480000000002e+03,
              4.86915000000000000e+04 7.14741000000000058e+04 1.30357159999999999e+03,
              5.36915000000000000e+04 7.14741000000000058e+04 1.30754939999999988e+03,
              5.86915000000000000e+04 7.14741000000000058e+04 1.33530850000000009e+03,
              6.36915000000000000e+04 7.14741000000000058e+04 1.32865910000000008e+03,
              6.86915000000000000e+04 7.14741000000000058e+04 1.35714539999999988e+03,
              7.36915000000000000e+04 7.14741000000000058e+04 1.30196920000000000e+03,
              7.86915000000000000e+04 7.14741000000000058e+04 1.31415830000000005e+03,
              8.36915000000000000e+04 7.14741000000000058e+04 1.31487550000000010e+03,
              8.86915000000000000e+04 7.14741000000000058e+04 1.23233729999999991e+03,
              4.56415000000000000e+04 7.14241000000000058e+04 1.27833169999999996e+03,
              5.06415000000000000e+04 7.14241000000000058e+04 1.28661480000000006e+03,
              5.56415000000000000e+04 7.14241000000000058e+04 1.28231790000000001e+03,
              6.06415000000000000e+04 7.14241000000000058e+04 1.30359930000000008e+03,
              6.56415000000000000e+04 7.14241000000000058e+04 1.33533879999999999e+03,
              7.06415000000000000e+04 7.14241000000000058e+04 1.31659230000000002e+03,
              7.56415000000000000e+04 7.14241000000000058e+04 1.30236500000000001e+03,
              8.06415000000000000e+04 7.14241000000000058e+04 1.28221810000000005e+03,
              8.56415000000000000e+04 7.14241000000000058e+04 1.30526379999999995e+03,
              9.06415000000000000e+04 7.14241000000000058e+04 1.28560640000000012e+03,
              4.75915000000000000e+04 7.13741000000000058e+04 1.30396579999999994e+03,
              5.25915000000000000e+04 7.13741000000000058e+04 1.30494509999999991e+03,
              5.75915000000000000e+04 7.13741000000000058e+04 1.33971830000000000e+03,
              6.25915000000000000e+04 7.13741000000000058e+04 1.31025029999999992e+03,
              6.75915000000000000e+04 7.13741000000000058e+04 1.31728179999999998e+03,
              7.25915000000000000e+04 7.13741000000000058e+04 1.30986220000000003e+03,
              7.75915000000000000e+04 7.13741000000000058e+04 1.29544270000000006e+03,
              8.25915000000000000e+04 7.13741000000000058e+04 1.31391959999999995e+03,
              8.75915000000000000e+04 7.13741000000000058e+04 1.21463159999999993e+03,
              4.45415000000000000e+04 7.13241000000000058e+04 1.25268499999999995e+03,
              4.95415000000000000e+04 7.13241000000000058e+04 1.26997180000000003e+03,
              5.45415000000000000e+04 7.13241000000000058e+04 1.30137220000000002e+03,
              5.95415000000000000e+04 7.13241000000000058e+04 1.31238570000000004e+03,
              6.45415000000000000e+04 7.13241000000000058e+04 1.33397489999999993e+03,
              6.95415000000000000e+04 7.13241000000000058e+04 1.35770810000000006e+03,
              7.45415000000000000e+04 7.13241000000000058e+04 1.29930430000000001e+03,
              7.95415000000000000e+04 7.13241000000000058e+04 1.29804539999999997e+03,
              8.45415000000000000e+04 7.13241000000000058e+04 1.27537280000000010e+03,
              8.95415000000000000e+04 7.13241000000000058e+04 1.25602500000000009e+03,
              4.64915000000000000e+04 7.12741000000000058e+04 1.26816039999999998e+03,
              5.14915000000000000e+04 7.12741000000000058e+04 1.31289380000000006e+03,
              5.64915000000000000e+04 7.12741000000000058e+04 1.29439910000000009e+03,
              6.14915000000000000e+04 7.12741000000000058e+04 1.29363869999999997e+03,
              6.64915000000000000e+04 7.12741000000000058e+04 1.31068010000000004e+03,
              7.14915000000000000e+04 7.12741000000000058e+04 1.31271329999999989e+03,
              7.64915000000000000e+04 7.12741000000000058e+04 1.29935660000000007e+03,
              8.14915000000000000e+04 7.12741000000000058e+04 1.29236159999999995e+03,
              8.64915000000000000e+04 7.12741000000000058e+04 1.31064319999999998e+03,
              4.34415000000000000e+04 7.12241000000000058e+04 1.26516460000000006e+03,
              4.84415000000000000e+04 7.12241000000000058e+04 1.29057749999999987e+03,
              5.34415000000000000e+04 7.12241000000000058e+04 1.30155659999999989e+03,
              5.84415000000000000e+04 7.12241000000000058e+04 1.38350880000000006e+03,
              6.34415000000000000e+04 7.12241000000000058e+04 1.31752279999999996e+03,
              6.84415000000000000e+04 7.12241000000000058e+04 1.35240609999999992e+03,
              7.34415000000000000e+04 7.12241000000000058e+04 1.30883819999999992e+03,
              7.84415000000000000e+04 7.12241000000000058e+04 1.29248500000000013e+03,
              8.34415000000000000e+04 7.12241000000000058e+04 1.30810349999999994e+03,
              8.84415000000000000e+04 7.12241000000000058e+04 1.24758889999999997e+03,
              4.53915000000000000e+04 7.11741000000000058e+04 1.28217830000000004e+03,
              5.03915000000000000e+04 7.11741000000000058e+04 1.27035809999999992e+03,
              5.53915000000000000e+04 7.11741000000000058e+04 1.28900299999999993e+03,
              6.03915000000000000e+04 7.11741000000000058e+04 1.29953670000000011e+03,
              6.53915000000000000e+04 7.11741000000000058e+04 1.32998710000000005e+03,
              7.03915000000000000e+04 7.11741000000000058e+04 1.32799829999999997e+03,
              7.53915000000000000e+04 7.11741000000000058e+04 1.30219520000000011e+03,
              8.03915000000000000e+04 7.11741000000000058e+04 1.28348190000000000e+03,
              8.53915000000000000e+04 7.11741000000000058e+04 1.33100770000000011e+03,
              9.03915000000000000e+04 7.11741000000000058e+04 1.26576790000000005e+03,
              4.73415000000000000e+04 7.11241000000000058e+04 1.30255690000000004e+03,
              5.23415000000000000e+04 7.11241000000000058e+04 1.31103410000000008e+03,
              5.73415000000000000e+04 7.11241000000000058e+04 1.33166449999999986e+03,
              6.23415000000000000e+04 7.11241000000000058e+04 1.31557899999999995e+03,
              6.73415000000000000e+04 7.11241000000000058e+04 1.33080780000000004e+03,
              7.23415000000000000e+04 7.11241000000000058e+04 1.31210030000000006e+03,
              7.73415000000000000e+04 7.11241000000000058e+04 1.30436470000000008e+03,
              8.23415000000000000e+04 7.11241000000000058e+04 1.31131259999999997e+03,
              8.73415000000000000e+04 7.11241000000000058e+04 1.22613730000000010e+03,
              4.42915000000000000e+04 7.10741000000000058e+04 1.25784809999999993e+03,
              4.92915000000000000e+04 7.10741000000000058e+04 1.28263709999999992e+03,
              5.42915000000000000e+04 7.10741000000000058e+04 1.29472299999999996e+03,
              5.92915000000000000e+04 7.10741000000000058e+04 1.29405119999999988e+03,
              6.42915000000000000e+04 7.10741000000000058e+04 1.32570350000000008e+03,
              6.92915000000000000e+04 7.10741000000000058e+04 1.34181420000000003e+03,
              7.42915000000000000e+04 7.10741000000000058e+04 1.30317280000000005e+03,
              7.92915000000000000e+04 7.10741000000000058e+04 1.27196559999999999e+03,
              8.42915000000000000e+04 7.10741000000000058e+04 1.28804199999999992e+03,
              8.92915000000000000e+04 7.10741000000000058e+04 1.26487989999999991e+03,
              4.62415000000000000e+04 7.10241000000000058e+04 1.25390309999999999e+03,
              5.12415000000000000e+04 7.10241000000000058e+04 1.30008840000000009e+03,
              5.62415000000000000e+04 7.10241000000000058e+04 1.28497430000000008e+03,
              6.12415000000000000e+04 7.10241000000000058e+04 1.28555870000000004e+03,
              6.62415000000000000e+04 7.10241000000000058e+04 1.32126949999999988e+03,
              7.12415000000000000e+04 7.10241000000000058e+04 1.31332439999999997e+03,
              7.62415000000000000e+04 7.10241000000000058e+04 1.30973209999999995e+03,
              8.12415000000000000e+04 7.10241000000000058e+04 1.28850770000000011e+03,
              8.62415000000000000e+04 7.10241000000000058e+04 1.35079520000000002e+03,
              4.31915000000000000e+04 7.09741000000000058e+04 1.25364989999999989e+03,
              4.81915000000000000e+04 7.09741000000000058e+04 1.27097389999999996e+03,
              5.31915000000000000e+04 7.09741000000000058e+04 1.29231790000000001e+03,
              5.81915000000000000e+04 7.09741000000000058e+04 1.38377839999999992e+03,
              6.31915000000000000e+04 7.09741000000000058e+04 1.32985649999999987e+03,
              6.81915000000000000e+04 7.09741000000000058e+04 1.31583629999999994e+03,
              7.31915000000000000e+04 7.09741000000000058e+04 1.31746659999999997e+03,
              7.81915000000000000e+04 7.09741000000000058e+04 1.30862329999999997e+03,
              8.31915000000000000e+04 7.09741000000000058e+04 1.30196000000000004e+03,
              8.81915000000000000e+04 7.09741000000000058e+04 1.19488529999999992e+03,
              4.51415000000000000e+04 7.09241000000000058e+04 1.26931160000000000e+03,
              5.01415000000000000e+04 7.09241000000000058e+04 1.26600199999999995e+03,
              5.51415000000000000e+04 7.09241000000000058e+04 1.29455299999999988e+03,
              6.01415000000000000e+04 7.09241000000000058e+04 1.29037249999999995e+03,
              6.51415000000000000e+04 7.09241000000000058e+04 1.34059770000000003e+03,
              7.01415000000000000e+04 7.09241000000000058e+04 1.32896160000000009e+03,
              7.51415000000000000e+04 7.09241000000000058e+04 1.29982809999999995e+03,
              8.01415000000000000e+04 7.09241000000000058e+04 1.27541239999999993e+03,
              8.51415000000000000e+04 7.09241000000000058e+04 1.32956770000000006e+03,
              9.01415000000000000e+04 7.09241000000000058e+04 1.22334470000000010e+03,
              4.70915000000000000e+04 7.08741000000000058e+04 1.28463850000000002e+03,
              5.20915000000000000e+04 7.08741000000000058e+04 1.30849990000000003e+03,
              5.70915000000000000e+04 7.08741000000000058e+04 1.31817370000000005e+03,
              6.20915000000000000e+04 7.08741000000000058e+04 1.30804240000000004e+03,
              6.70915000000000000e+04 7.08741000000000058e+04 1.30815319999999997e+03,
              7.20915000000000000e+04 7.08741000000000058e+04 1.32130569999999989e+03,
              7.70915000000000000e+04 7.08741000000000058e+04 1.29099189999999999e+03,
              8.20915000000000000e+04 7.08741000000000058e+04 1.31314879999999994e+03,
              8.70915000000000000e+04 7.08741000000000058e+04 1.26037560000000008e+03,
              4.40415000000000000e+04 7.08241000000000058e+04 1.24187850000000003e+03,
              4.90415000000000000e+04 7.08241000000000058e+04 1.27833230000000003e+03,
              5.40415000000000000e+04 7.08241000000000058e+04 1.28548119999999994e+03,
              5.90415000000000000e+04 7.08241000000000058e+04 1.29972530000000006e+03,
              6.40415000000000000e+04 7.08241000000000058e+04 1.32251459999999997e+03,
              6.90415000000000000e+04 7.08241000000000058e+04 1.31726960000000008e+03,
              7.40415000000000000e+04 7.08241000000000058e+04 1.30463400000000001e+03,
              7.90415000000000000e+04 7.08241000000000058e+04 1.26690319999999997e+03,
              8.40415000000000000e+04 7.08241000000000058e+04 1.27795939999999996e+03,
              8.90415000000000000e+04 7.08241000000000058e+04 1.24439830000000006e+03,
              4.59915000000000000e+04 7.07741000000000058e+04 1.27060750000000007e+03,
              5.09915000000000000e+04 7.07741000000000058e+04 1.29067370000000005e+03,
              5.59915000000000000e+04 7.07741000000000058e+04 1.27829639999999995e+03,
              6.09915000000000000e+04 7.07741000000000058e+04 1.28866550000000007e+03,
              6.59915000000000000e+04 7.07741000000000058e+04 1.33501600000000008e+03,
              7.09915000000000000e+04 7.07741000000000058e+04 1.30294309999999996e+03,
              7.59915000000000000e+04 7.07741000000000058e+04 1.32629880000000003e+03,
              8.09915000000000000e+04 7.07741000000000058e+04 1.29901760000000013e+03,
              8.59915000000000000e+04 7.07741000000000058e+04 1.36205960000000005e+03,
              9.09915000000000000e+04 7.07741000000000058e+04 1.22952909999999997e+03,
              4.79415000000000000e+04 7.07241000000000058e+04 1.25731999999999994e+03,
              5.29415000000000000e+04 7.07241000000000058e+04 1.29514769999999999e+03,
              5.79415000000000000e+04 7.07241000000000058e+04 1.35943540000000007e+03,
              6.29415000000000000e+04 7.07241000000000058e+04 1.31215769999999998e+03,
              6.79415000000000000e+04 7.07241000000000058e+04 1.30801170000000002e+03,
              7.29415000000000000e+04 7.07241000000000058e+04 1.32085310000000004e+03,
              7.79415000000000000e+04 7.07241000000000058e+04 1.29795969999999988e+03,
              8.29415000000000000e+04 7.07241000000000058e+04 1.31220209999999997e+03,
              8.79415000000000000e+04 7.07241000000000058e+04 1.21785110000000009e+03,
              4.48915000000000000e+04 7.06741000000000058e+04 1.27099780000000010e+03,
              4.98915000000000000e+04 7.06741000000000058e+04 1.28307889999999998e+03,
              5.48915000000000000e+04 7.06741000000000058e+04 1.30816759999999999e+03,
              5.98915000000000000e+04 7.06741000000000058e+04 1.28264300000000003e+03,
              6.48915000000000000e+04 7.06741000000000058e+04 1.34137370000000010e+03,
              6.98915000000000000e+04 7.06741000000000058e+04 1.34903999999999996e+03,
              7.48915000000000000e+04 7.06741000000000058e+04 1.30492399999999998e+03,
              7.98915000000000000e+04 7.06741000000000058e+04 1.27519979999999987e+03,
              8.48915000000000000e+04 7.06741000000000058e+04 1.30349820000000000e+03,
              8.98915000000000000e+04 7.06741000000000058e+04 1.23809850000000006e+03,
              4.68415000000000000e+04 7.06241000000000058e+04 1.26188470000000007e+03,
              5.18415000000000000e+04 7.06241000000000058e+04 1.30953970000000004e+03,
              5.68415000000000000e+04 7.06241000000000058e+04 1.32025970000000007e+03,
              6.18415000000000000e+04 7.06241000000000058e+04 1.29808109999999988e+03,
              6.68415000000000000e+04 7.06241000000000058e+04 1.32654079999999999e+03,
              7.18415000000000000e+04 7.06241000000000058e+04 1.31821180000000004e+03,
              7.68415000000000000e+04 7.06241000000000058e+04 1.29038930000000005e+03,
              8.18415000000000000e+04 7.06241000000000058e+04 1.29717820000000006e+03,
              8.68415000000000000e+04 7.06241000000000058e+04 1.32924510000000009e+03,
              4.37915000000000000e+04 7.05741000000000058e+04 1.24105230000000006e+03,
              4.87915000000000000e+04 7.05741000000000058e+04 1.26333819999999992e+03,
              5.37915000000000000e+04 7.05741000000000058e+04 1.28081619999999998e+03,
              5.87915000000000000e+04 7.05741000000000058e+04 1.32569290000000001e+03,
              6.37915000000000000e+04 7.05741000000000058e+04 1.32444509999999991e+03,
              6.87915000000000000e+04 7.05741000000000058e+04 1.32922810000000004e+03,
              7.37915000000000000e+04 7.05741000000000058e+04 1.30325659999999993e+03,
              7.87915000000000000e+04 7.05741000000000058e+04 1.27004320000000007e+03,
              8.37915000000000000e+04 7.05741000000000058e+04 1.28420910000000003e+03,
              8.87915000000000000e+04 7.05741000000000058e+04 1.21227860000000010e+03,
              4.57415000000000000e+04 7.05241000000000058e+04 1.24530430000000001e+03,
              5.07415000000000000e+04 7.05241000000000058e+04 1.27446440000000007e+03,
              5.57415000000000000e+04 7.05241000000000058e+04 1.26992290000000003e+03,
              6.07415000000000000e+04 7.05241000000000058e+04 1.28402320000000009e+03,
              6.57415000000000000e+04 7.05241000000000058e+04 1.34407989999999995e+03,
              7.07415000000000000e+04 7.05241000000000058e+04 1.31442579999999998e+03,
              7.57415000000000000e+04 7.05241000000000058e+04 1.30674849999999992e+03,
              8.07415000000000000e+04 7.05241000000000058e+04 1.28189539999999988e+03,
              8.57415000000000000e+04 7.05241000000000058e+04 1.35344219999999996e+03,
              9.07415000000000000e+04 7.05241000000000058e+04 1.23061760000000004e+03,
              4.76915000000000000e+04 7.04741000000000058e+04 1.26799379999999996e+03,
              5.26915000000000000e+04 7.04741000000000058e+04 1.28937449999999990e+03,
              5.76915000000000000e+04 7.04741000000000058e+04 1.34798119999999994e+03,
              6.26915000000000000e+04 7.04741000000000058e+04 1.30766599999999994e+03,
              6.76915000000000000e+04 7.04741000000000058e+04 1.29974469999999997e+03,
              7.26915000000000000e+04 7.04741000000000058e+04 1.31656880000000001e+03,
              7.76915000000000000e+04 7.04741000000000058e+04 1.29633089999999993e+03,
              8.26915000000000000e+04 7.04741000000000058e+04 1.31030330000000004e+03,
              8.76915000000000000e+04 7.04741000000000058e+04 1.23586040000000003e+03,
              4.46415000000000000e+04 7.04241000000000058e+04 1.25964550000000008e+03,
              4.96415000000000000e+04 7.04241000000000058e+04 1.28282500000000005e+03,
              5.46415000000000000e+04 7.04241000000000058e+04 1.28399009999999998e+03,
              5.96415000000000000e+04 7.04241000000000058e+04 1.28158050000000003e+03,
              6.46415000000000000e+04 7.04241000000000058e+04 1.36554980000000000e+03,
              6.96415000000000000e+04 7.04241000000000058e+04 1.34317100000000005e+03,
              7.46415000000000000e+04 7.04241000000000058e+04 1.31066399999999999e+03,
              7.96415000000000000e+04 7.04241000000000058e+04 1.27008559999999989e+03,
              8.46415000000000000e+04 7.04241000000000058e+04 1.31623649999999998e+03,
              8.96415000000000000e+04 7.04241000000000058e+04 1.24274369999999999e+03,
              4.65915000000000000e+04 7.03741000000000058e+04 1.25518519999999990e+03,
              5.15915000000000000e+04 7.03741000000000058e+04 1.30664689999999996e+03,
              5.65915000000000000e+04 7.03741000000000058e+04 1.31131340000000000e+03,
              6.15915000000000000e+04 7.03741000000000058e+04 1.29023520000000008e+03,
              6.65915000000000000e+04 7.03741000000000058e+04 1.32392610000000013e+03,
              7.15915000000000000e+04 7.03741000000000058e+04 1.32299730000000000e+03,
              7.65915000000000000e+04 7.03741000000000058e+04 1.30891789999999992e+03,
              8.15915000000000000e+04 7.03741000000000058e+04 1.29241170000000011e+03,
              8.65915000000000000e+04 7.03741000000000058e+04 1.37657490000000007e+03,
              4.35415000000000000e+04 7.03241000000000058e+04 1.25307159999999999e+03,
              4.85415000000000000e+04 7.03241000000000058e+04 1.25186529999999993e+03,
              5.35415000000000000e+04 7.03241000000000058e+04 1.28145190000000002e+03,
              5.85415000000000000e+04 7.03241000000000058e+04 1.30131300000000010e+03,
              6.35415000000000000e+04 7.03241000000000058e+04 1.32109840000000008e+03,
              6.85415000000000000e+04 7.03241000000000058e+04 1.30747730000000001e+03,
              7.35415000000000000e+04 7.03241000000000058e+04 1.31220470000000000e+03,
              7.85415000000000000e+04 7.03241000000000058e+04 1.27185789999999997e+03,
              8.35415000000000000e+04 7.03241000000000058e+04 1.28744000000000005e+03,
              8.85415000000000000e+04 7.03241000000000058e+04 1.19646830000000000e+03,
              4.54915000000000000e+04 7.02741000000000058e+04 1.23965959999999995e+03,
              5.04915000000000000e+04 7.02741000000000058e+04 1.29292570000000001e+03,
              5.54915000000000000e+04 7.02741000000000058e+04 1.27231739999999991e+03,
              6.04915000000000000e+04 7.02741000000000058e+04 1.27591859999999997e+03,
              6.54915000000000000e+04 7.02741000000000058e+04 1.33822510000000011e+03,
              7.04915000000000000e+04 7.02741000000000058e+04 1.31591779999999994e+03,
              7.54915000000000000e+04 7.02741000000000058e+04 1.29978359999999998e+03,
              8.04915000000000000e+04 7.02741000000000058e+04 1.27166210000000001e+03,
              8.54915000000000000e+04 7.02741000000000058e+04 1.34714339999999993e+03,
              9.04915000000000000e+04 7.02741000000000058e+04 1.20485030000000006e+03,
              4.74415000000000000e+04 7.02241000000000058e+04 1.26963419999999996e+03,
              5.24415000000000000e+04 7.02241000000000058e+04 1.28246129999999994e+03,
              5.74415000000000000e+04 7.02241000000000058e+04 1.32332880000000000e+03,
              6.24415000000000000e+04 7.02241000000000058e+04 1.29931670000000008e+03,
              6.74415000000000000e+04 7.02241000000000058e+04 1.31358060000000000e+03,
              7.24415000000000000e+04 7.02241000000000058e+04 1.31894309999999996e+03,
              7.74415000000000000e+04 7.02241000000000058e+04 1.30056639999999993e+03,
              8.24415000000000000e+04 7.02241000000000058e+04 1.30741309999999999e+03,
              8.74415000000000000e+04 7.02241000000000058e+04 1.32436069999999995e+03,
              4.43915000000000000e+04 7.01741000000000058e+04 1.26620209999999997e+03,
              4.93915000000000000e+04 7.01741000000000058e+04 1.27858930000000009e+03,
              5.43915000000000000e+04 7.01741000000000058e+04 1.27565059999999994e+03,
              5.93915000000000000e+04 7.01741000000000058e+04 1.27625199999999995e+03,
              6.43915000000000000e+04 7.01741000000000058e+04 1.34773710000000005e+03,
              6.93915000000000000e+04 7.01741000000000058e+04 1.33113049999999998e+03,
              7.43915000000000000e+04 7.01741000000000058e+04 1.31080130000000008e+03,
              7.93915000000000000e+04 7.01741000000000058e+04 1.26068480000000000e+03,
              8.43915000000000000e+04 7.01741000000000058e+04 1.30472690000000011e+03,
              8.93915000000000000e+04 7.01741000000000058e+04 1.20525160000000005e+03,
              4.63415000000000000e+04 7.01241000000000058e+04 1.28585400000000004e+03,
              5.13415000000000000e+04 7.01241000000000058e+04 1.30869020000000000e+03,
              5.63415000000000000e+04 7.01241000000000058e+04 1.28570100000000002e+03,
              6.13415000000000000e+04 7.01241000000000058e+04 1.28264660000000003e+03,
              6.63415000000000000e+04 7.01241000000000058e+04 1.33179930000000013e+03,
              7.13415000000000000e+04 7.01241000000000058e+04 1.32712339999999995e+03,
              7.63415000000000000e+04 7.01241000000000058e+04 1.29969619999999986e+03,
              8.13415000000000000e+04 7.01241000000000058e+04 1.31206750000000011e+03,
              8.63415000000000000e+04 7.01241000000000058e+04 1.37396880000000010e+03,
              4.32915000000000000e+04 7.00741000000000058e+04 1.25311290000000008e+03,
              4.82915000000000000e+04 7.00741000000000058e+04 1.26679359999999997e+03,
              5.32915000000000000e+04 7.00741000000000058e+04 1.27714570000000003e+03,
              5.82915000000000000e+04 7.00741000000000058e+04 1.30328559999999993e+03,
              6.32915000000000000e+04 7.00741000000000058e+04 1.31140730000000008e+03,
              6.82915000000000000e+04 7.00741000000000058e+04 1.29534719999999993e+03,
              7.32915000000000000e+04 7.00741000000000058e+04 1.32397990000000004e+03,
              7.82915000000000000e+04 7.00741000000000058e+04 1.28329629999999997e+03,
              8.32915000000000000e+04 7.00741000000000058e+04 1.31758760000000007e+03,
              8.82915000000000000e+04 7.00741000000000058e+04 1.21508310000000006e+03,
              4.52415000000000000e+04 7.00241000000000058e+04 1.23957929999999988e+03,
              5.02415000000000000e+04 7.00241000000000058e+04 1.29035730000000012e+03,
              5.52415000000000000e+04 7.00241000000000058e+04 1.27989400000000001e+03,
              6.02415000000000000e+04 7.00241000000000058e+04 1.27371129999999994e+03,
              6.52415000000000000e+04 7.00241000000000058e+04 1.33182889999999998e+03,
              7.02415000000000000e+04 7.00241000000000058e+04 1.34777900000000000e+03,
              7.52415000000000000e+04 7.00241000000000058e+04 1.31905909999999994e+03,
              8.02415000000000000e+04 7.00241000000000058e+04 1.26655359999999996e+03,
              8.52415000000000000e+04 7.00241000000000058e+04 1.35249559999999997e+03,
              9.02415000000000000e+04 7.00241000000000058e+04 1.22211369999999988e+03,
              4.71915000000000000e+04 6.99741000000000058e+04 1.26420499999999993e+03,
              5.21915000000000000e+04 6.99741000000000058e+04 1.29352260000000001e+03,
              5.71915000000000000e+04 6.99741000000000058e+04 1.32580020000000013e+03,
              6.21915000000000000e+04 6.99741000000000058e+04 1.29285069999999996e+03,
              6.71915000000000000e+04 6.99741000000000058e+04 1.34097859999999991e+03,
              7.21915000000000000e+04 6.99741000000000058e+04 1.33173680000000013e+03,
              7.71915000000000000e+04 6.99741000000000058e+04 1.29649980000000005e+03,
              8.21915000000000000e+04 6.99741000000000058e+04 1.29536509999999998e+03,
              8.71915000000000000e+04 6.99741000000000058e+04 1.35264769999999999e+03,
              4.41415000000000000e+04 6.99241000000000058e+04 1.26514539999999988e+03,
              4.91415000000000000e+04 6.99241000000000058e+04 1.27202999999999997e+03,
              5.41415000000000000e+04 6.99241000000000058e+04 1.27490599999999995e+03,
              5.91415000000000000e+04 6.99241000000000058e+04 1.27782130000000006e+03,
              6.41415000000000000e+04 6.99241000000000058e+04 1.34311149999999998e+03,
              6.91415000000000000e+04 6.99241000000000058e+04 1.32378729999999996e+03,
              7.41415000000000000e+04 6.99241000000000058e+04 1.32782410000000004e+03,
              7.91415000000000000e+04 6.99241000000000058e+04 1.26435930000000008e+03,
              8.41415000000000000e+04 6.99241000000000058e+04 1.29793750000000000e+03,
              8.91415000000000000e+04 6.99241000000000058e+04 1.19801610000000005e+03,
              4.60915000000000000e+04 6.98741000000000058e+04 1.28434680000000003e+03,
              5.10915000000000000e+04 6.98741000000000058e+04 1.32058460000000014e+03,
              5.60915000000000000e+04 6.98741000000000058e+04 1.26817450000000008e+03,
              6.10915000000000000e+04 6.98741000000000058e+04 1.27733410000000003e+03,
              6.60915000000000000e+04 6.98741000000000058e+04 1.33004880000000003e+03,
              7.10915000000000000e+04 6.98741000000000058e+04 1.31172789999999986e+03,
              7.60915000000000000e+04 6.98741000000000058e+04 1.30006930000000011e+03,
              8.10915000000000000e+04 6.98741000000000058e+04 1.28280349999999999e+03,
              8.60915000000000000e+04 6.98741000000000058e+04 1.36668110000000001e+03,
              4.30415000000000000e+04 6.98241000000000058e+04 1.24866930000000002e+03,
              4.80415000000000000e+04 6.98241000000000058e+04 1.26590219999999999e+03,
              5.30415000000000000e+04 6.98241000000000058e+04 1.27625189999999998e+03,
              5.80415000000000000e+04 6.98241000000000058e+04 1.27802980000000002e+03,
              6.30415000000000000e+04 6.98241000000000058e+04 1.30713720000000012e+03,
              6.80415000000000000e+04 6.98241000000000058e+04 1.30489879999999994e+03,
              7.30415000000000000e+04 6.98241000000000058e+04 1.32824340000000007e+03,
              7.80415000000000000e+04 6.98241000000000058e+04 1.27788699999999994e+03,
              8.30415000000000000e+04 6.98241000000000058e+04 1.32090570000000002e+03,
              8.80415000000000000e+04 6.98241000000000058e+04 1.26740239999999994e+03,
              4.49915000000000000e+04 6.97741000000000058e+04 1.24491239999999993e+03,
              4.99915000000000000e+04 6.97741000000000058e+04 1.28862259999999992e+03,
              5.49915000000000000e+04 6.97741000000000058e+04 1.28271620000000007e+03,
              5.99915000000000000e+04 6.97741000000000058e+04 1.27975299999999993e+03,
              6.49915000000000000e+04 6.97741000000000058e+04 1.33380189999999993e+03,
              6.99915000000000000e+04 6.97741000000000058e+04 1.34866879999999992e+03,
              7.49915000000000000e+04 6.97741000000000058e+04 1.31996640000000002e+03,
              7.99915000000000000e+04 6.97741000000000058e+04 1.28011940000000004e+03,
              8.49915000000000000e+04 6.97741000000000058e+04 1.33129980000000000e+03,
              8.99915000000000000e+04 6.97741000000000058e+04 1.20539699999999993e+03,
              4.69415000000000000e+04 6.97241000000000058e+04 1.26363799999999992e+03,
              5.19415000000000000e+04 6.97241000000000058e+04 1.30586979999999994e+03,
              5.69415000000000000e+04 6.97241000000000058e+04 1.29905389999999989e+03,
              6.19415000000000000e+04 6.97241000000000058e+04 1.28705729999999994e+03,
              6.69415000000000000e+04 6.97241000000000058e+04 1.33584979999999996e+03,
              7.19415000000000000e+04 6.97241000000000058e+04 1.35262400000000002e+03,
              7.69415000000000000e+04 6.97241000000000058e+04 1.29672049999999990e+03,
              8.19415000000000000e+04 6.97241000000000058e+04 1.31236549999999988e+03,
              8.69415000000000000e+04 6.97241000000000058e+04 1.35503639999999996e+03,
              4.38915000000000000e+04 6.96741000000000058e+04 1.26623440000000005e+03,
              4.88915000000000000e+04 6.96741000000000058e+04 1.26164290000000005e+03,
              5.38915000000000000e+04 6.96741000000000058e+04 1.26748129999999992e+03,
              5.88915000000000000e+04 6.96741000000000058e+04 1.27350970000000007e+03,
              6.38915000000000000e+04 6.96741000000000058e+04 1.33260359999999991e+03,
              6.88915000000000000e+04 6.96741000000000058e+04 1.32503089999999997e+03,
              7.38915000000000000e+04 6.96741000000000058e+04 1.32891249999999991e+03,
              7.88915000000000000e+04 6.96741000000000058e+04 1.26118440000000010e+03,
              8.38915000000000000e+04 6.96741000000000058e+04 1.29573569999999995e+03,
              8.88915000000000000e+04 6.96741000000000058e+04 1.21235280000000012e+03,
              4.58415000000000000e+04 6.96241000000000058e+04 1.27452549999999997e+03,
              5.08415000000000000e+04 6.96241000000000058e+04 1.31639989999999989e+03,
              5.58415000000000000e+04 6.96241000000000058e+04 1.27254899999999998e+03,
              6.08415000000000000e+04 6.96241000000000058e+04 1.27688979999999992e+03,
              6.58415000000000000e+04 6.96241000000000058e+04 1.33349569999999994e+03,
              7.08415000000000000e+04 6.96241000000000058e+04 1.35659830000000011e+03,
              7.58415000000000000e+04 6.96241000000000058e+04 1.31030380000000014e+03,
              8.08415000000000000e+04 6.96241000000000058e+04 1.27585860000000002e+03,
              8.58415000000000000e+04 6.96241000000000058e+04 1.35144569999999999e+03,
              9.08415000000000000e+04 6.96241000000000058e+04 1.19670800000000008e+03,
              4.77915000000000000e+04 6.95741000000000058e+04 1.25630790000000002e+03,
              5.27915000000000000e+04 6.95741000000000058e+04 1.29490120000000002e+03,
              5.77915000000000000e+04 6.95741000000000058e+04 1.29576160000000004e+03,
              6.27915000000000000e+04 6.95741000000000058e+04 1.30438480000000004e+03,
              6.77915000000000000e+04 6.95741000000000058e+04 1.32700119999999993e+03,
              7.27915000000000000e+04 6.95741000000000058e+04 1.31695470000000000e+03,
              7.77915000000000000e+04 6.95741000000000058e+04 1.27453739999999993e+03,
              8.27915000000000000e+04 6.95741000000000058e+04 1.30727070000000003e+03,
              8.77915000000000000e+04 6.95741000000000058e+04 1.29661349999999993e+03,
              4.47415000000000000e+04 6.95241000000000058e+04 1.23514059999999995e+03,
              4.97415000000000000e+04 6.95241000000000058e+04 1.27019450000000006e+03,
              5.47415000000000000e+04 6.95241000000000058e+04 1.26977330000000006e+03,
              5.97415000000000000e+04 6.95241000000000058e+04 1.28740339999999992e+03,
              6.47415000000000000e+04 6.95241000000000058e+04 1.34491049999999996e+03,
              6.97415000000000000e+04 6.95241000000000058e+04 1.35662959999999998e+03,
              7.47415000000000000e+04 6.95241000000000058e+04 1.32907639999999992e+03,
              7.97415000000000000e+04 6.95241000000000058e+04 1.27485619999999994e+03,
              8.47415000000000000e+04 6.95241000000000058e+04 1.32075520000000006e+03,
              8.97415000000000000e+04 6.95241000000000058e+04 1.18733559999999989e+03,
              4.66915000000000000e+04 6.94741000000000058e+04 1.24440049999999997e+03,
              5.16915000000000000e+04 6.94741000000000058e+04 1.28368440000000010e+03,
              5.66915000000000000e+04 6.94741000000000058e+04 1.27280639999999994e+03,
              6.16915000000000000e+04 6.94741000000000058e+04 1.28498450000000003e+03,
              6.66915000000000000e+04 6.94741000000000058e+04 1.31852770000000010e+03,
              7.16915000000000000e+04 6.94741000000000058e+04 1.36338650000000007e+03,
              7.66915000000000000e+04 6.94741000000000058e+04 1.29957099999999991e+03,
              8.16915000000000000e+04 6.94741000000000058e+04 1.31909899999999993e+03,
              8.66915000000000000e+04 6.94741000000000058e+04 1.35808370000000014e+03,
              4.36415000000000000e+04 6.94241000000000058e+04 1.23518859999999995e+03,
              4.86415000000000000e+04 6.94241000000000058e+04 1.26867990000000009e+03,
              5.36415000000000000e+04 6.94241000000000058e+04 1.26846869999999990e+03,
              5.86415000000000000e+04 6.94241000000000058e+04 1.27408190000000013e+03,
              6.36415000000000000e+04 6.94241000000000058e+04 1.33223880000000008e+03,
              6.86415000000000000e+04 6.94241000000000058e+04 1.30263169999999991e+03,
              7.36415000000000000e+04 6.94241000000000058e+04 1.31424350000000004e+03,
              7.86415000000000000e+04 6.94241000000000058e+04 1.26295849999999996e+03,
              8.36415000000000000e+04 6.94241000000000058e+04 1.29306950000000006e+03,
              8.86415000000000000e+04 6.94241000000000058e+04 1.19040069999999992e+03,
              4.55915000000000000e+04 6.93741000000000058e+04 1.24239760000000001e+03,
              5.05915000000000000e+04 6.93741000000000058e+04 1.31706189999999992e+03,
              5.55915000000000000e+04 6.93741000000000058e+04 1.27846000000000004e+03,
              6.05915000000000000e+04 6.93741000000000058e+04 1.28018180000000007e+03,
              6.55915000000000000e+04 6.93741000000000058e+04 1.32580980000000000e+03,
              7.05915000000000000e+04 6.93741000000000058e+04 1.36890940000000001e+03,
              7.55915000000000000e+04 6.93741000000000058e+04 1.30678089999999997e+03,
              8.05915000000000000e+04 6.93741000000000058e+04 1.28959369999999990e+03,
              8.55915000000000000e+04 6.93741000000000058e+04 1.34021890000000008e+03,
              9.05915000000000000e+04 6.93741000000000058e+04 1.20468200000000002e+03,
              4.75415000000000000e+04 6.93241000000000058e+04 1.25384619999999995e+03,
              5.25415000000000000e+04 6.93241000000000058e+04 1.28621710000000007e+03,
              5.75415000000000000e+04 6.93241000000000058e+04 1.27110719999999992e+03,
              6.25415000000000000e+04 6.93241000000000058e+04 1.31551240000000007e+03,
              6.75415000000000000e+04 6.93241000000000058e+04 1.30971540000000005e+03,
              7.25415000000000000e+04 6.93241000000000058e+04 1.32788819999999987e+03,
              7.75415000000000000e+04 6.93241000000000058e+04 1.28415450000000010e+03,
              8.25415000000000000e+04 6.93241000000000058e+04 1.31094880000000012e+03,
              8.75415000000000000e+04 6.93241000000000058e+04 1.32099759999999992e+03,
              4.44915000000000000e+04 6.92741000000000058e+04 1.23398009999999999e+03,
              4.94915000000000000e+04 6.92741000000000058e+04 1.27135770000000002e+03,
              5.44915000000000000e+04 6.92741000000000058e+04 1.26834009999999989e+03,
              5.94915000000000000e+04 6.92741000000000058e+04 1.28300389999999993e+03,
              6.44915000000000000e+04 6.92741000000000058e+04 1.33805829999999992e+03,
              6.94915000000000000e+04 6.92741000000000058e+04 1.32656739999999991e+03,
              7.44915000000000000e+04 6.92741000000000058e+04 1.32279469999999992e+03,
              7.94915000000000000e+04 6.92741000000000058e+04 1.26914350000000013e+03,
              8.44915000000000000e+04 6.92741000000000058e+04 1.29936369999999988e+03,
              8.94915000000000000e+04 6.92741000000000058e+04 1.17685320000000002e+03,
              4.64415000000000000e+04 6.92241000000000058e+04 1.24758220000000006e+03,
              5.14415000000000000e+04 6.92241000000000058e+04 1.29875590000000011e+03,
              5.64415000000000000e+04 6.92241000000000058e+04 1.26871329999999989e+03,
              6.14415000000000000e+04 6.92241000000000058e+04 1.28511419999999998e+03,
              6.64415000000000000e+04 6.92241000000000058e+04 1.32429099999999994e+03,
              7.14415000000000000e+04 6.92241000000000058e+04 1.36481310000000008e+03,
              7.64415000000000000e+04 6.92241000000000058e+04 1.30688990000000013e+03,
              8.14415000000000000e+04 6.92241000000000058e+04 1.31833559999999989e+03,
              8.64415000000000000e+04 6.92241000000000058e+04 1.36245530000000008e+03,
              4.33915000000000000e+04 6.91741000000000058e+04 1.23311680000000001e+03,
              4.83915000000000000e+04 6.91741000000000058e+04 1.27069349999999986e+03,
              5.33915000000000000e+04 6.91741000000000058e+04 1.29184400000000005e+03,
              5.83915000000000000e+04 6.91741000000000058e+04 1.27445119999999997e+03,
              6.33915000000000000e+04 6.91741000000000058e+04 1.30960969999999998e+03,
              6.83915000000000000e+04 6.91741000000000058e+04 1.30476760000000013e+03,
              7.33915000000000000e+04 6.91741000000000058e+04 1.29539130000000000e+03,
              7.83915000000000000e+04 6.91741000000000058e+04 1.26957839999999987e+03,
              8.33915000000000000e+04 6.91741000000000058e+04 1.29290670000000000e+03,
              8.83915000000000000e+04 6.91741000000000058e+04 1.20650610000000006e+03,
              4.53415000000000000e+04 6.91241000000000058e+04 1.23899129999999991e+03,
              5.03415000000000000e+04 6.91241000000000058e+04 1.32780980000000000e+03,
              5.53415000000000000e+04 6.91241000000000058e+04 1.27320659999999998e+03,
              6.03415000000000000e+04 6.91241000000000058e+04 1.28512280000000010e+03,
              6.53415000000000000e+04 6.91241000000000058e+04 1.33601009999999997e+03,
              7.03415000000000000e+04 6.91241000000000058e+04 1.33189100000000008e+03,
              7.53415000000000000e+04 6.91241000000000058e+04 1.29508629999999994e+03,
              8.03415000000000000e+04 6.91241000000000058e+04 1.28761689999999999e+03,
              8.53415000000000000e+04 6.91241000000000058e+04 1.32637410000000000e+03,
              9.03415000000000000e+04 6.91241000000000058e+04 1.17049219999999991e+03,
              4.72915000000000000e+04 6.90741000000000058e+04 1.26589010000000007e+03,
              5.22915000000000000e+04 6.90741000000000058e+04 1.27602109999999993e+03,
              5.72915000000000000e+04 6.90741000000000058e+04 1.27435719999999992e+03,
              6.22915000000000000e+04 6.90741000000000058e+04 1.33265300000000002e+03,
              6.72915000000000000e+04 6.90741000000000058e+04 1.31709310000000005e+03,
              7.22915000000000000e+04 6.90741000000000058e+04 1.36121769999999992e+03,
              7.72915000000000000e+04 6.90741000000000058e+04 1.27731009999999992e+03,
              8.22915000000000000e+04 6.90741000000000058e+04 1.33894569999999999e+03,
              8.72915000000000000e+04 6.90741000000000058e+04 1.30273559999999998e+03,
              4.42415000000000000e+04 6.90241000000000058e+04 1.23495519999999988e+03,
              4.92415000000000000e+04 6.90241000000000058e+04 1.28381780000000003e+03,
              5.42415000000000000e+04 6.90241000000000058e+04 1.27186789999999996e+03,
              5.92415000000000000e+04 6.90241000000000058e+04 1.29027469999999994e+03,
              6.42415000000000000e+04 6.90241000000000058e+04 1.31923250000000007e+03,
              6.92415000000000000e+04 6.90241000000000058e+04 1.31077999999999997e+03,
              7.42415000000000000e+04 6.90241000000000058e+04 1.29533370000000014e+03,
              7.92415000000000000e+04 6.90241000000000058e+04 1.27302590000000009e+03,
              8.42415000000000000e+04 6.90241000000000058e+04 1.30701499999999987e+03,
              8.92415000000000000e+04 6.90241000000000058e+04 1.19043759999999997e+03,
              4.61915000000000000e+04 6.89741000000000058e+04 1.25294020000000000e+03,
              5.11915000000000000e+04 6.89741000000000058e+04 1.31814879999999994e+03,
              5.61915000000000000e+04 6.89741000000000058e+04 1.26637699999999995e+03,
              6.11915000000000000e+04 6.89741000000000058e+04 1.30124870000000010e+03,
              6.61915000000000000e+04 6.89741000000000058e+04 1.32262370000000010e+03,
              7.11915000000000000e+04 6.89741000000000058e+04 1.34119059999999990e+03,
              7.61915000000000000e+04 6.89741000000000058e+04 1.30505919999999992e+03,
              8.11915000000000000e+04 6.89741000000000058e+04 1.29629410000000007e+03,
              8.61915000000000000e+04 6.89741000000000058e+04 1.33562820000000011e+03,
              4.31415000000000000e+04 6.89241000000000058e+04 1.23336509999999998e+03,
              4.81415000000000000e+04 6.89241000000000058e+04 1.28665190000000007e+03,
              5.31415000000000000e+04 6.89241000000000058e+04 1.29283439999999996e+03,
              5.81415000000000000e+04 6.89241000000000058e+04 1.27969219999999996e+03,
              6.31415000000000000e+04 6.89241000000000058e+04 1.31880320000000006e+03,
              6.81415000000000000e+04 6.89241000000000058e+04 1.32228769999999986e+03,
              7.31415000000000000e+04 6.89241000000000058e+04 1.32641309999999999e+03,
              7.81415000000000000e+04 6.89241000000000058e+04 1.27735619999999994e+03,
              8.31415000000000000e+04 6.89241000000000058e+04 1.30273340000000007e+03,
              8.81415000000000000e+04 6.89241000000000058e+04 1.19816059999999993e+03,
              4.50915000000000000e+04 6.88741000000000058e+04 1.24379060000000004e+03,
              5.00915000000000000e+04 6.88741000000000058e+04 1.30221299999999997e+03,
              5.50915000000000000e+04 6.88741000000000058e+04 1.27523500000000013e+03,
              6.00915000000000000e+04 6.88741000000000058e+04 1.29550720000000001e+03,
              6.50915000000000000e+04 6.88741000000000058e+04 1.33518239999999992e+03,
              7.00915000000000000e+04 6.88741000000000058e+04 1.32780780000000004e+03,
              7.50915000000000000e+04 6.88741000000000058e+04 1.30444200000000001e+03,
              8.00915000000000000e+04 6.88741000000000058e+04 1.27736439999999993e+03,
              8.50915000000000000e+04 6.88741000000000058e+04 1.31385190000000011e+03,
              9.00915000000000000e+04 6.88741000000000058e+04 1.18547080000000005e+03,
              4.70415000000000000e+04 6.88241000000000058e+04 1.27943150000000014e+03,
              5.20415000000000000e+04 6.88241000000000058e+04 1.28667810000000009e+03,
              5.70415000000000000e+04 6.88241000000000058e+04 1.27202070000000003e+03,
              6.20415000000000000e+04 6.88241000000000058e+04 1.31726139999999987e+03,
              6.70415000000000000e+04 6.88241000000000058e+04 1.31978080000000000e+03,
              7.20415000000000000e+04 6.88241000000000058e+04 1.34764269999999988e+03,
              7.70415000000000000e+04 6.88241000000000058e+04 1.28862689999999998e+03,
              8.20415000000000000e+04 6.88241000000000058e+04 1.32178260000000000e+03,
              8.70415000000000000e+04 6.88241000000000058e+04 1.33740650000000005e+03,
              4.39915000000000000e+04 6.87741000000000058e+04 1.23322960000000012e+03,
              4.89915000000000000e+04 6.87741000000000058e+04 1.29123970000000008e+03,
              5.39915000000000000e+04 6.87741000000000058e+04 1.27572010000000000e+03,
              5.89915000000000000e+04 6.87741000000000058e+04 1.29597060000000010e+03,
              6.39915000000000000e+04 6.87741000000000058e+04 1.31517160000000013e+03,
              6.89915000000000000e+04 6.87741000000000058e+04 1.30616370000000006e+03,
              7.39915000000000000e+04 6.87741000000000058e+04 1.30644880000000012e+03,
              7.89915000000000000e+04 6.87741000000000058e+04 1.26932729999999992e+03,
              8.39915000000000000e+04 6.87741000000000058e+04 1.31658909999999992e+03,
              8.89915000000000000e+04 6.87741000000000058e+04 1.19641180000000008e+03,
              4.59415000000000000e+04 6.87241000000000058e+04 1.25950270000000000e+03,
              5.09415000000000000e+04 6.87241000000000058e+04 1.32207629999999995e+03,
              5.59415000000000000e+04 6.87241000000000058e+04 1.27221469999999999e+03,
              6.09415000000000000e+04 6.87241000000000058e+04 1.27720659999999998e+03,
              6.59415000000000000e+04 6.87241000000000058e+04 1.32076369999999997e+03,
              7.09415000000000000e+04 6.87241000000000058e+04 1.32103780000000006e+03,
              7.59415000000000000e+04 6.87241000000000058e+04 1.32296219999999994e+03,
              8.09415000000000000e+04 6.87241000000000058e+04 1.30446700000000010e+03,
              8.59415000000000000e+04 6.87241000000000058e+04 1.32456279999999992e+03,
              9.09415000000000000e+04 6.87241000000000058e+04 1.16355989999999997e+03,
              4.78915000000000000e+04 6.86741000000000058e+04 1.32147710000000006e+03,
              5.28915000000000000e+04 6.86741000000000058e+04 1.26881739999999991e+03,
              5.78915000000000000e+04 6.86741000000000058e+04 1.28836189999999988e+03,
              6.28915000000000000e+04 6.86741000000000058e+04 1.31051099999999997e+03,
              6.78915000000000000e+04 6.86741000000000058e+04 1.30707050000000004e+03,
              7.28915000000000000e+04 6.86741000000000058e+04 1.32674669999999992e+03,
              7.78915000000000000e+04 6.86741000000000058e+04 1.27255790000000002e+03,
              8.28915000000000000e+04 6.86741000000000058e+04 1.30788000000000011e+03,
              8.78915000000000000e+04 6.86741000000000058e+04 1.21421530000000007e+03,
              4.48415000000000000e+04 6.86241000000000058e+04 1.26425309999999990e+03,
              4.98415000000000000e+04 6.86241000000000058e+04 1.32997299999999996e+03,
              5.48415000000000000e+04 6.86241000000000058e+04 1.26787879999999996e+03,
              5.98415000000000000e+04 6.86241000000000058e+04 1.31448479999999995e+03,
              6.48415000000000000e+04 6.86241000000000058e+04 1.32278749999999991e+03,
              6.98415000000000000e+04 6.86241000000000058e+04 1.32097559999999999e+03,
              7.48415000000000000e+04 6.86241000000000058e+04 1.28322379999999998e+03,
              7.98415000000000000e+04 6.86241000000000058e+04 1.28182639999999992e+03,
              8.48415000000000000e+04 6.86241000000000058e+04 1.30322919999999999e+03,
              8.98415000000000000e+04 6.86241000000000058e+04 1.20063889999999992e+03,
              4.67915000000000000e+04 6.85741000000000058e+04 1.30322589999999991e+03,
              5.17915000000000000e+04 6.85741000000000058e+04 1.29286220000000003e+03,
              5.67915000000000000e+04 6.85741000000000058e+04 1.28116689999999994e+03,
              6.17915000000000000e+04 6.85741000000000058e+04 1.30835310000000004e+03,
              6.67915000000000000e+04 6.85741000000000058e+04 1.30920389999999998e+03,
              7.17915000000000000e+04 6.85741000000000058e+04 1.35006469999999990e+03,
              7.67915000000000000e+04 6.85741000000000058e+04 1.28950459999999998e+03,
              8.17915000000000000e+04 6.85741000000000058e+04 1.29830889999999999e+03,
              8.67915000000000000e+04 6.85741000000000058e+04 1.32199739999999997e+03,
              4.37415000000000000e+04 6.85241000000000058e+04 1.25108940000000007e+03,
              4.87415000000000000e+04 6.85241000000000058e+04 1.33449659999999994e+03,
              5.37415000000000000e+04 6.85241000000000058e+04 1.27041719999999987e+03,
              5.87415000000000000e+04 6.85241000000000058e+04 1.32455380000000014e+03,
              6.37415000000000000e+04 6.85241000000000058e+04 1.30696800000000007e+03,
              6.87415000000000000e+04 6.85241000000000058e+04 1.29096399999999994e+03,
              7.37415000000000000e+04 6.85241000000000058e+04 1.30657889999999998e+03,
              7.87415000000000000e+04 6.85241000000000058e+04 1.26490429999999992e+03,
              8.37415000000000000e+04 6.85241000000000058e+04 1.31687519999999995e+03,
              8.87415000000000000e+04 6.85241000000000058e+04 1.23997910000000002e+03,
              4.56915000000000000e+04 6.84741000000000058e+04 1.27403379999999993e+03,
              5.06915000000000000e+04 6.84741000000000058e+04 1.40232950000000005e+03,
              5.56915000000000000e+04 6.84741000000000058e+04 1.27129459999999995e+03,
              6.06915000000000000e+04 6.84741000000000058e+04 1.28143869999999993e+03,
              6.56915000000000000e+04 6.84741000000000058e+04 1.31373939999999993e+03,
              7.06915000000000000e+04 6.84741000000000058e+04 1.33404919999999993e+03,
              7.56915000000000000e+04 6.84741000000000058e+04 1.30205760000000009e+03,
              8.06915000000000000e+04 6.84741000000000058e+04 1.29641269999999986e+03,
              8.56915000000000000e+04 6.84741000000000058e+04 1.31479919999999993e+03,
              9.06915000000000000e+04 6.84741000000000058e+04 1.18310799999999995e+03,
              4.76415000000000000e+04 6.84241000000000058e+04 1.36274800000000005e+03,
              5.26415000000000000e+04 6.84241000000000058e+04 1.28406899999999996e+03,
              5.76415000000000000e+04 6.84241000000000058e+04 1.28305050000000006e+03,
              6.26415000000000000e+04 6.84241000000000058e+04 1.29657979999999998e+03,
              6.76415000000000000e+04 6.84241000000000058e+04 1.30984870000000001e+03,
              7.26415000000000000e+04 6.84241000000000058e+04 1.33785349999999994e+03,
              7.76415000000000000e+04 6.84241000000000058e+04 1.26791110000000003e+03,
              8.26415000000000000e+04 6.84241000000000058e+04 1.28728510000000006e+03,
              8.76415000000000000e+04 6.84241000000000058e+04 1.26056169999999997e+03,
              4.45915000000000000e+04 6.83741000000000058e+04 1.26892550000000006e+03,
              4.95915000000000000e+04 6.83741000000000058e+04 1.40197350000000006e+03,
              5.45915000000000000e+04 6.83741000000000058e+04 1.26864840000000004e+03,
              5.95915000000000000e+04 6.83741000000000058e+04 1.35981700000000001e+03,
              6.45915000000000000e+04 6.83741000000000058e+04 1.31586969999999997e+03,
              6.95915000000000000e+04 6.83741000000000058e+04 1.30359279999999990e+03,
              7.45915000000000000e+04 6.83741000000000058e+04 1.30006420000000003e+03,
              7.95915000000000000e+04 6.83741000000000058e+04 1.28677309999999989e+03,
              8.45915000000000000e+04 6.83741000000000058e+04 1.30700530000000003e+03,
              8.95915000000000000e+04 6.83741000000000058e+04 1.22626410000000010e+03,
              4.65415000000000000e+04 6.83241000000000058e+04 1.34461040000000003e+03,
              5.15415000000000000e+04 6.83241000000000058e+04 1.31455560000000014e+03,
              5.65415000000000000e+04 6.83241000000000058e+04 1.29293440000000010e+03,
              6.15415000000000000e+04 6.83241000000000058e+04 1.29097530000000006e+03,
              6.65415000000000000e+04 6.83241000000000058e+04 1.31642280000000005e+03,
              7.15415000000000000e+04 6.83241000000000058e+04 1.33523209999999995e+03,
              7.65415000000000000e+04 6.83241000000000058e+04 1.29345420000000013e+03,
              8.15415000000000000e+04 6.83241000000000058e+04 1.28168409999999994e+03,
              8.65415000000000000e+04 6.83241000000000058e+04 1.32009870000000001e+03,
              4.34915000000000000e+04 6.82741000000000058e+04 1.24190259999999989e+03,
              4.84915000000000000e+04 6.82741000000000058e+04 1.38906629999999996e+03,
              5.34915000000000000e+04 6.82741000000000058e+04 1.26685109999999986e+03,
              5.84915000000000000e+04 6.82741000000000058e+04 1.34097289999999998e+03,
              6.34915000000000000e+04 6.82741000000000058e+04 1.32103839999999991e+03,
              6.84915000000000000e+04 6.82741000000000058e+04 1.29373029999999994e+03,
              7.34915000000000000e+04 6.82741000000000058e+04 1.32986979999999994e+03,
              7.84915000000000000e+04 6.82741000000000058e+04 1.26493920000000003e+03,
              8.34915000000000000e+04 6.82741000000000058e+04 1.30263750000000005e+03,
              8.84915000000000000e+04 6.82741000000000058e+04 1.32432880000000000e+03,
              4.54415000000000000e+04 6.82241000000000058e+04 1.32091059999999993e+03,
              5.04415000000000000e+04 6.82241000000000058e+04 1.40834210000000007e+03,
              5.54415000000000000e+04 6.82241000000000058e+04 1.27501839999999993e+03,
              6.04415000000000000e+04 6.82241000000000058e+04 1.28967129999999997e+03,
              6.54415000000000000e+04 6.82241000000000058e+04 1.31138319999999999e+03,
              7.04415000000000000e+04 6.82241000000000058e+04 1.32160969999999998e+03,
              7.54415000000000000e+04 6.82241000000000058e+04 1.27749350000000004e+03,
              8.04415000000000000e+04 6.82241000000000058e+04 1.28902140000000009e+03,
              8.54415000000000000e+04 6.82241000000000058e+04 1.30938519999999994e+03,
              9.04415000000000000e+04 6.82241000000000058e+04 1.18957790000000000e+03,
              4.73915000000000000e+04 6.81741000000000058e+04 1.39015959999999995e+03,
              5.23915000000000000e+04 6.81741000000000058e+04 1.28254030000000012e+03,
              5.73915000000000000e+04 6.81741000000000058e+04 1.29640499999999997e+03,
              6.23915000000000000e+04 6.81741000000000058e+04 1.28875770000000011e+03,
              6.73915000000000000e+04 6.81741000000000058e+04 1.30706899999999996e+03,
              7.23915000000000000e+04 6.81741000000000058e+04 1.35006849999999986e+03,
              7.73915000000000000e+04 6.81741000000000058e+04 1.27947540000000004e+03,
              8.23915000000000000e+04 6.81741000000000058e+04 1.27802620000000002e+03,
              8.73915000000000000e+04 6.81741000000000058e+04 1.28177289999999994e+03,
              4.43415000000000000e+04 6.81241000000000058e+04 1.24283449999999993e+03,
              4.93415000000000000e+04 6.81241000000000058e+04 1.41029309999999987e+03,
              5.43415000000000000e+04 6.81241000000000058e+04 1.26077129999999988e+03,
              5.93415000000000000e+04 6.81241000000000058e+04 1.38116560000000004e+03,
              6.43415000000000000e+04 6.81241000000000058e+04 1.31276690000000008e+03,
              6.93415000000000000e+04 6.81241000000000058e+04 1.31064949999999999e+03,
              7.43415000000000000e+04 6.81241000000000058e+04 1.31399589999999989e+03,
              7.93415000000000000e+04 6.81241000000000058e+04 1.28386169999999993e+03,
              8.43415000000000000e+04 6.81241000000000058e+04 1.29637789999999995e+03,
              8.93415000000000000e+04 6.81241000000000058e+04 1.24065509999999995e+03,
              4.62915000000000000e+04 6.80741000000000058e+04 1.36747060000000010e+03,
              5.12915000000000000e+04 6.80741000000000058e+04 1.30149780000000010e+03,
              5.62915000000000000e+04 6.80741000000000058e+04 1.30686879999999996e+03,
              6.12915000000000000e+04 6.80741000000000058e+04 1.28192880000000014e+03,
              6.62915000000000000e+04 6.80741000000000058e+04 1.30692100000000005e+03,
              7.12915000000000000e+04 6.80741000000000058e+04 1.30553070000000002e+03,
              7.62915000000000000e+04 6.80741000000000058e+04 1.28455770000000007e+03,
              8.12915000000000000e+04 6.80741000000000058e+04 1.28739429999999993e+03,
              8.62915000000000000e+04 6.80741000000000058e+04 1.33060820000000012e+03,
              4.32415000000000000e+04 6.80241000000000058e+04 1.25740799999999990e+03,
              4.82415000000000000e+04 6.80241000000000058e+04 1.39598929999999996e+03,
              5.32415000000000000e+04 6.80241000000000058e+04 1.28080870000000004e+03,
              5.82415000000000000e+04 6.80241000000000058e+04 1.29483500000000004e+03,
              6.32415000000000000e+04 6.80241000000000058e+04 1.31713290000000006e+03,
              6.82415000000000000e+04 6.80241000000000058e+04 1.29234240000000000e+03,
              7.32415000000000000e+04 6.80241000000000058e+04 1.32706019999999990e+03,
              7.82415000000000000e+04 6.80241000000000058e+04 1.26373340000000007e+03,
              8.32415000000000000e+04 6.80241000000000058e+04 1.28643800000000010e+03,
              8.82415000000000000e+04 6.80241000000000058e+04 1.32942049999999995e+03,
              4.51915000000000000e+04 6.79741000000000058e+04 1.30058680000000004e+03,
              5.01915000000000000e+04 6.79741000000000058e+04 1.39745060000000012e+03,
              5.51915000000000000e+04 6.79741000000000058e+04 1.27702500000000009e+03,
              6.01915000000000000e+04 6.79741000000000058e+04 1.30104199999999992e+03,
              6.51915000000000000e+04 6.79741000000000058e+04 1.30828230000000008e+03,
              7.01915000000000000e+04 6.79741000000000058e+04 1.30540499999999997e+03,
              7.51915000000000000e+04 6.79741000000000058e+04 1.28328320000000008e+03,
              8.01915000000000000e+04 6.79741000000000058e+04 1.30070429999999988e+03,
              8.51915000000000000e+04 6.79741000000000058e+04 1.30635140000000001e+03,
              9.01915000000000000e+04 6.79741000000000058e+04 1.19593149999999991e+03,
              4.71415000000000000e+04 6.79241000000000058e+04 1.36284290000000010e+03,
              5.21415000000000000e+04 6.79241000000000058e+04 1.29087830000000008e+03,
              5.71415000000000000e+04 6.79241000000000058e+04 1.31385619999999994e+03,
              6.21415000000000000e+04 6.79241000000000058e+04 1.29023939999999993e+03,
              6.71415000000000000e+04 6.79241000000000058e+04 1.30181729999999993e+03,
              7.21415000000000000e+04 6.79241000000000058e+04 1.34441959999999995e+03,
              7.71415000000000000e+04 6.79241000000000058e+04 1.28274759999999992e+03,
              8.21415000000000000e+04 6.79241000000000058e+04 1.27368830000000003e+03,
              8.71415000000000000e+04 6.79241000000000058e+04 1.26381179999999995e+03,
              4.40915000000000000e+04 6.78741000000000058e+04 1.24796119999999996e+03,
              4.90915000000000000e+04 6.78741000000000058e+04 1.40391789999999992e+03,
              5.40915000000000000e+04 6.78741000000000058e+04 1.26230619999999999e+03,
              5.90915000000000000e+04 6.78741000000000058e+04 1.38613900000000012e+03,
              6.40915000000000000e+04 6.78741000000000058e+04 1.32249910000000000e+03,
              6.90915000000000000e+04 6.78741000000000058e+04 1.28880560000000014e+03,
              7.40915000000000000e+04 6.78741000000000058e+04 1.33448849999999993e+03,
              7.90915000000000000e+04 6.78741000000000058e+04 1.27165589999999997e+03,
              8.40915000000000000e+04 6.78741000000000058e+04 1.28298250000000007e+03,
              8.90915000000000000e+04 6.78741000000000058e+04 1.32379780000000005e+03,
              4.60415000000000000e+04 6.78241000000000058e+04 1.39288189999999986e+03,
              5.10415000000000000e+04 6.78241000000000058e+04 1.37542700000000013e+03,
              5.60415000000000000e+04 6.78241000000000058e+04 1.33702199999999993e+03,
              6.10415000000000000e+04 6.78241000000000058e+04 1.28628769999999986e+03,
              6.60415000000000000e+04 6.78241000000000058e+04 1.30174829999999997e+03,
              7.10415000000000000e+04 6.78241000000000058e+04 1.31784690000000001e+03,
              7.60415000000000000e+04 6.78241000000000058e+04 1.27980349999999999e+03,
              8.10415000000000000e+04 6.78241000000000058e+04 1.29408280000000013e+03,
              8.60415000000000000e+04 6.78241000000000058e+04 1.32180829999999992e+03,
              4.29915000000000000e+04 6.77741000000000058e+04 1.27277620000000002e+03,
              4.79915000000000000e+04 6.77741000000000058e+04 1.34830870000000004e+03,
              5.29915000000000000e+04 6.77741000000000058e+04 1.29066090000000008e+03,
              5.79915000000000000e+04 6.77741000000000058e+04 1.34814670000000001e+03,
              6.29915000000000000e+04 6.77741000000000058e+04 1.30628859999999986e+03,
              6.79915000000000000e+04 6.77741000000000058e+04 1.29441629999999986e+03,
              7.29915000000000000e+04 6.77741000000000058e+04 1.31580070000000001e+03,
              7.79915000000000000e+04 6.77741000000000058e+04 1.26608999999999992e+03,
              8.29915000000000000e+04 6.77741000000000058e+04 1.26904420000000005e+03,
              8.79915000000000000e+04 6.77741000000000058e+04 1.33218399999999997e+03,
              4.49415000000000000e+04 6.77241000000000058e+04 1.26455110000000013e+03,
              4.99415000000000000e+04 6.77241000000000058e+04 1.39638159999999993e+03,
              5.49415000000000000e+04 6.77241000000000058e+04 1.28352590000000009e+03,
              5.99415000000000000e+04 6.77241000000000058e+04 1.30861580000000004e+03,
              6.49415000000000000e+04 6.77241000000000058e+04 1.31313979999999992e+03,
              6.99415000000000000e+04 6.77241000000000058e+04 1.31185640000000012e+03,
              7.49415000000000000e+04 6.77241000000000058e+04 1.30786400000000003e+03,
              7.99415000000000000e+04 6.77241000000000058e+04 1.31133739999999989e+03,
              8.49415000000000000e+04 6.77241000000000058e+04 1.29816480000000001e+03,
              8.99415000000000000e+04 6.77241000000000058e+04 1.21803989999999999e+03,
              4.68915000000000000e+04 6.76741000000000058e+04 1.33630179999999996e+03,
              5.18915000000000000e+04 6.76741000000000058e+04 1.30387010000000009e+03,
              5.68915000000000000e+04 6.76741000000000058e+04 1.29772550000000001e+03,
              6.18915000000000000e+04 6.76741000000000058e+04 1.29363329999999996e+03,
              6.68915000000000000e+04 6.76741000000000058e+04 1.31137850000000003e+03,
              7.18915000000000000e+04 6.76741000000000058e+04 1.32130380000000014e+03,
              7.68915000000000000e+04 6.76741000000000058e+04 1.28878809999999999e+03,
              8.18915000000000000e+04 6.76741000000000058e+04 1.27831750000000011e+03,
              8.68915000000000000e+04 6.76741000000000058e+04 1.31402289999999994e+03,
              4.38415000000000000e+04 6.76241000000000058e+04 1.26144409999999993e+03,
              4.88415000000000000e+04 6.76241000000000058e+04 1.39915589999999997e+03,
              5.38415000000000000e+04 6.76241000000000058e+04 1.26561329999999998e+03,
              5.88415000000000000e+04 6.76241000000000058e+04 1.35930809999999997e+03,
              6.38415000000000000e+04 6.76241000000000058e+04 1.31051440000000002e+03,
              6.88415000000000000e+04 6.76241000000000058e+04 1.30433130000000006e+03,
              7.38415000000000000e+04 6.76241000000000058e+04 1.33383400000000006e+03,
              7.88415000000000000e+04 6.76241000000000058e+04 1.28442849999999999e+03,
              8.38415000000000000e+04 6.76241000000000058e+04 1.27811439999999993e+03,
              8.88415000000000000e+04 6.76241000000000058e+04 1.31044799999999987e+03,
              4.57915000000000000e+04 6.75741000000000058e+04 1.38530259999999998e+03,
              5.07915000000000000e+04 6.75741000000000058e+04 1.39381739999999991e+03,
              5.57915000000000000e+04 6.75741000000000058e+04 1.37713310000000001e+03,
              6.07915000000000000e+04 6.75741000000000058e+04 1.28651639999999998e+03,
              6.57915000000000000e+04 6.75741000000000058e+04 1.30749700000000007e+03,
              7.07915000000000000e+04 6.75741000000000058e+04 1.31993409999999994e+03,
              7.57915000000000000e+04 6.75741000000000058e+04 1.27445010000000002e+03,
              8.07915000000000000e+04 6.75741000000000058e+04 1.27915930000000003e+03,
              8.57915000000000000e+04 6.75741000000000058e+04 1.31338990000000013e+03,
              9.07915000000000000e+04 6.75741000000000058e+04 1.19511879999999996e+03,
              4.77415000000000000e+04 6.75241000000000058e+04 1.35304169999999999e+03,
              5.27415000000000000e+04 6.75241000000000058e+04 1.29251080000000002e+03,
              5.77415000000000000e+04 6.75241000000000058e+04 1.39020949999999993e+03,
              6.27415000000000000e+04 6.75241000000000058e+04 1.30258249999999998e+03,
              6.77415000000000000e+04 6.75241000000000058e+04 1.30806889999999999e+03,
              7.27415000000000000e+04 6.75241000000000058e+04 1.31649679999999989e+03,
              7.77415000000000000e+04 6.75241000000000058e+04 1.27199119999999994e+03,
              8.27415000000000000e+04 6.75241000000000058e+04 1.27295350000000008e+03,
              8.77415000000000000e+04 6.75241000000000058e+04 1.33787779999999998e+03,
              4.46915000000000000e+04 6.74741000000000058e+04 1.24537869999999998e+03,
              4.96915000000000000e+04 6.74741000000000058e+04 1.40315159999999992e+03,
              5.46915000000000000e+04 6.74741000000000058e+04 1.28293949999999995e+03,
              5.96915000000000000e+04 6.74741000000000058e+04 1.33691249999999991e+03,
              6.46915000000000000e+04 6.74741000000000058e+04 1.31651080000000002e+03,
              6.96915000000000000e+04 6.74741000000000058e+04 1.30542489999999998e+03,
              7.46915000000000000e+04 6.74741000000000058e+04 1.32855829999999992e+03,
              7.96915000000000000e+04 6.74741000000000058e+04 1.29893710000000010e+03,
              8.46915000000000000e+04 6.74741000000000058e+04 1.29170870000000014e+03,
              8.96915000000000000e+04 6.74741000000000058e+04 1.25598320000000012e+03,
              4.66415000000000000e+04 6.74241000000000058e+04 1.29648520000000008e+03,
              5.16415000000000000e+04 6.74241000000000058e+04 1.36488139999999999e+03,
              5.66415000000000000e+04 6.74241000000000058e+04 1.29301089999999999e+03,
              6.16415000000000000e+04 6.74241000000000058e+04 1.29485950000000003e+03,
              6.66415000000000000e+04 6.74241000000000058e+04 1.30827430000000004e+03,
              7.16415000000000000e+04 6.74241000000000058e+04 1.31531269999999995e+03,
              7.66415000000000000e+04 6.74241000000000058e+04 1.29571869999999990e+03,
              8.16415000000000000e+04 6.74241000000000058e+04 1.27881570000000011e+03,
              8.66415000000000000e+04 6.74241000000000058e+04 1.31118740000000003e+03,
              4.35915000000000000e+04 6.73741000000000058e+04 1.27410930000000008e+03,
              4.85915000000000000e+04 6.73741000000000058e+04 1.39975689999999986e+03,
              5.35915000000000000e+04 6.73741000000000058e+04 1.26304930000000013e+03,
              5.85915000000000000e+04 6.73741000000000058e+04 1.34703520000000003e+03,
              6.35915000000000000e+04 6.73741000000000058e+04 1.30867180000000008e+03,
              6.85915000000000000e+04 6.73741000000000058e+04 1.31242650000000003e+03,
              7.35915000000000000e+04 6.73741000000000058e+04 1.33396820000000002e+03,
              7.85915000000000000e+04 6.73741000000000058e+04 1.27459960000000001e+03,
              8.35915000000000000e+04 6.73741000000000058e+04 1.28581590000000006e+03,
              8.85915000000000000e+04 6.73741000000000058e+04 1.30309740000000011e+03,
              4.55415000000000000e+04 6.73241000000000058e+04 1.33055690000000004e+03,
              5.05415000000000000e+04 6.73241000000000058e+04 1.40280070000000001e+03,
              5.55415000000000000e+04 6.73241000000000058e+04 1.37968650000000002e+03,
              6.05415000000000000e+04 6.73241000000000058e+04 1.29395839999999998e+03,
              6.55415000000000000e+04 6.73241000000000058e+04 1.31151890000000003e+03,
              7.05415000000000000e+04 6.73241000000000058e+04 1.31025700000000006e+03,
              7.55415000000000000e+04 6.73241000000000058e+04 1.27965169999999989e+03,
              8.05415000000000000e+04 6.73241000000000058e+04 1.26426400000000012e+03,
              8.55415000000000000e+04 6.73241000000000058e+04 1.30487670000000003e+03,
              9.05415000000000000e+04 6.73241000000000058e+04 1.19630739999999992e+03,
              4.74915000000000000e+04 6.72741000000000058e+04 1.33148620000000005e+03,
              5.24915000000000000e+04 6.72741000000000058e+04 1.28801670000000013e+03,
              5.74915000000000000e+04 6.72741000000000058e+04 1.38797620000000006e+03,
              6.24915000000000000e+04 6.72741000000000058e+04 1.30727160000000003e+03,
              6.74915000000000000e+04 6.72741000000000058e+04 1.30727809999999999e+03,
              7.24915000000000000e+04 6.72741000000000058e+04 1.32966409999999996e+03,
              7.74915000000000000e+04 6.72741000000000058e+04 1.27393219999999997e+03,
              8.24915000000000000e+04 6.72741000000000058e+04 1.26034059999999999e+03,
              8.74915000000000000e+04 6.72741000000000058e+04 1.33470290000000000e+03,
              4.44415000000000000e+04 6.72241000000000058e+04 1.26617880000000014e+03,
              4.94415000000000000e+04 6.72241000000000058e+04 1.39130950000000007e+03,
              5.44415000000000000e+04 6.72241000000000058e+04 1.29500340000000006e+03,
              5.94415000000000000e+04 6.72241000000000058e+04 1.39711819999999989e+03,
              6.44415000000000000e+04 6.72241000000000058e+04 1.32079500000000007e+03,
              6.94415000000000000e+04 6.72241000000000058e+04 1.28679559999999992e+03,
              7.44415000000000000e+04 6.72241000000000058e+04 1.32912950000000001e+03,
              7.94415000000000000e+04 6.72241000000000058e+04 1.31461239999999998e+03,
              8.44415000000000000e+04 6.72241000000000058e+04 1.28719830000000002e+03,
              8.94415000000000000e+04 6.72241000000000058e+04 1.30042769999999996e+03,
              4.63915000000000000e+04 6.71741000000000058e+04 1.29873450000000003e+03,
              5.13915000000000000e+04 6.71741000000000058e+04 1.37205929999999989e+03,
              5.63915000000000000e+04 6.71741000000000058e+04 1.35360770000000002e+03,
              6.13915000000000000e+04 6.71741000000000058e+04 1.29713130000000001e+03,
              6.63915000000000000e+04 6.71741000000000058e+04 1.29966699999999992e+03,
              7.13915000000000000e+04 6.71741000000000058e+04 1.29876850000000013e+03,
              7.63915000000000000e+04 6.71741000000000058e+04 1.29647949999999992e+03,
              8.13915000000000000e+04 6.71741000000000058e+04 1.26392900000000009e+03,
              8.63915000000000000e+04 6.71741000000000058e+04 1.32017840000000001e+03,
              4.33415000000000000e+04 6.71241000000000058e+04 1.33063259999999991e+03,
              4.83415000000000000e+04 6.71241000000000058e+04 1.38886329999999998e+03,
              5.33415000000000000e+04 6.71241000000000058e+04 1.25888760000000002e+03,
              5.83415000000000000e+04 6.71241000000000058e+04 1.39232860000000005e+03,
              6.33415000000000000e+04 6.71241000000000058e+04 1.31030490000000009e+03,
              6.83415000000000000e+04 6.71241000000000058e+04 1.30281279999999992e+03,
              7.33415000000000000e+04 6.71241000000000058e+04 1.31270499999999993e+03,
              7.83415000000000000e+04 6.71241000000000058e+04 1.26883950000000004e+03,
              8.33415000000000000e+04 6.71241000000000058e+04 1.27906310000000008e+03,
              8.83415000000000000e+04 6.71241000000000058e+04 1.30402919999999995e+03,
              4.52915000000000000e+04 6.70741000000000058e+04 1.28176870000000008e+03,
              5.02915000000000000e+04 6.70741000000000058e+04 1.40069560000000001e+03,
              5.52915000000000000e+04 6.70741000000000058e+04 1.32189400000000001e+03,
              6.02915000000000000e+04 6.70741000000000058e+04 1.30229410000000007e+03,
              6.52915000000000000e+04 6.70741000000000058e+04 1.32709290000000010e+03,
              7.02915000000000000e+04 6.70741000000000058e+04 1.30569920000000002e+03,
              7.52915000000000000e+04 6.70741000000000058e+04 1.29141480000000001e+03,
              8.02915000000000000e+04 6.70741000000000058e+04 1.27299459999999999e+03,
              8.52915000000000000e+04 6.70741000000000058e+04 1.29843000000000006e+03,
              9.02915000000000000e+04 6.70741000000000058e+04 1.21229009999999994e+03,
              4.72415000000000000e+04 6.70241000000000058e+04 1.32145309999999995e+03,
              5.22415000000000000e+04 6.70241000000000058e+04 1.28123770000000013e+03,
              5.72415000000000000e+04 6.70241000000000058e+04 1.33624189999999999e+03,
              6.22415000000000000e+04 6.70241000000000058e+04 1.31784339999999997e+03,
              6.72415000000000000e+04 6.70241000000000058e+04 1.30252849999999989e+03,
              7.22415000000000000e+04 6.70241000000000058e+04 1.33115149999999994e+03,
              7.72415000000000000e+04 6.70241000000000058e+04 1.26790419999999995e+03,
              8.22415000000000000e+04 6.70241000000000058e+04 1.25623669999999993e+03,
              8.72415000000000000e+04 6.70241000000000058e+04 1.32707119999999986e+03,
              4.41915000000000000e+04 6.69741000000000058e+04 1.31733510000000001e+03,
              4.91915000000000000e+04 6.69741000000000058e+04 1.38462490000000003e+03,
              5.41915000000000000e+04 6.69741000000000058e+04 1.30377250000000004e+03,
              5.91915000000000000e+04 6.69741000000000058e+04 1.40916380000000004e+03,
              6.41915000000000000e+04 6.69741000000000058e+04 1.32829999999999995e+03,
              6.91915000000000000e+04 6.69741000000000058e+04 1.29109040000000005e+03,
              7.41915000000000000e+04 6.69741000000000058e+04 1.30563869999999997e+03,
              7.91915000000000000e+04 6.69741000000000058e+04 1.30412879999999996e+03,
              8.41915000000000000e+04 6.69741000000000058e+04 1.27896199999999999e+03,
              8.91915000000000000e+04 6.69741000000000058e+04 1.30338470000000007e+03,
              4.61415000000000000e+04 6.69241000000000058e+04 1.29027140000000009e+03,
              5.11415000000000000e+04 6.69241000000000058e+04 1.38378070000000002e+03,
              5.61415000000000000e+04 6.69241000000000058e+04 1.38220609999999988e+03,
              6.11415000000000000e+04 6.69241000000000058e+04 1.30255340000000001e+03,
              6.61415000000000000e+04 6.69241000000000058e+04 1.30224260000000004e+03,
              7.11415000000000000e+04 6.69241000000000058e+04 1.30494790000000012e+03,
              7.61415000000000000e+04 6.69241000000000058e+04 1.27774299999999994e+03,
              8.11415000000000000e+04 6.69241000000000058e+04 1.25991159999999991e+03,
              8.61415000000000000e+04 6.69241000000000058e+04 1.30717470000000003e+03,
              4.30915000000000000e+04 6.68741000000000058e+04 1.39619779999999992e+03,
              4.80915000000000000e+04 6.68741000000000058e+04 1.32998119999999994e+03,
              5.30915000000000000e+04 6.68741000000000058e+04 1.26092280000000005e+03,
              5.80915000000000000e+04 6.68741000000000058e+04 1.38944550000000004e+03,
              6.30915000000000000e+04 6.68741000000000058e+04 1.30849209999999994e+03,
              6.80915000000000000e+04 6.68741000000000058e+04 1.30773129999999992e+03,
              7.30915000000000000e+04 6.68741000000000058e+04 1.30418020000000001e+03,
              7.80915000000000000e+04 6.68741000000000058e+04 1.26525849999999991e+03,
              8.30915000000000000e+04 6.68741000000000058e+04 1.25255179999999996e+03,
              8.80915000000000000e+04 6.68741000000000058e+04 1.30278289999999993e+03,
              4.50415000000000000e+04 6.68241000000000058e+04 1.25733370000000014e+03,
              5.00415000000000000e+04 6.68241000000000058e+04 1.39475980000000004e+03,
              5.50415000000000000e+04 6.68241000000000058e+04 1.32817360000000008e+03,
              6.00415000000000000e+04 6.68241000000000058e+04 1.30315400000000000e+03,
              6.50415000000000000e+04 6.68241000000000058e+04 1.32640959999999995e+03,
              7.00415000000000000e+04 6.68241000000000058e+04 1.29574489999999992e+03,
              7.50415000000000000e+04 6.68241000000000058e+04 1.30271180000000004e+03,
              8.00415000000000000e+04 6.68241000000000058e+04 1.27140979999999990e+03,
              8.50415000000000000e+04 6.68241000000000058e+04 1.29174620000000004e+03,
              9.00415000000000000e+04 6.68241000000000058e+04 1.23161380000000008e+03,
              4.69915000000000000e+04 6.67741000000000058e+04 1.28352489999999989e+03,
              5.19915000000000000e+04 6.67741000000000058e+04 1.28034920000000011e+03,
              5.69915000000000000e+04 6.67741000000000058e+04 1.38768039999999996e+03,
              6.19915000000000000e+04 6.67741000000000058e+04 1.30052849999999989e+03,
              6.69915000000000000e+04 6.67741000000000058e+04 1.29652120000000014e+03,
              7.19915000000000000e+04 6.67741000000000058e+04 1.30844139999999993e+03,
              7.69915000000000000e+04 6.67741000000000058e+04 1.27093869999999993e+03,
              8.19915000000000000e+04 6.67741000000000058e+04 1.26542740000000003e+03,
              8.69915000000000000e+04 6.67741000000000058e+04 1.31890979999999990e+03,
              4.39415000000000000e+04 6.67241000000000058e+04 1.36233730000000014e+03,
              4.89415000000000000e+04 6.67241000000000058e+04 1.33267900000000009e+03,
              5.39415000000000000e+04 6.67241000000000058e+04 1.28052739999999994e+03,
              5.89415000000000000e+04 6.67241000000000058e+04 1.41075810000000001e+03,
              6.39415000000000000e+04 6.67241000000000058e+04 1.34004719999999998e+03,
              6.89415000000000000e+04 6.67241000000000058e+04 1.30033490000000006e+03,
              7.39415000000000000e+04 6.67241000000000058e+04 1.31338360000000011e+03,
              7.89415000000000000e+04 6.67241000000000058e+04 1.29382560000000012e+03,
              8.39415000000000000e+04 6.67241000000000058e+04 1.26295080000000007e+03,
              8.89415000000000000e+04 6.67241000000000058e+04 1.27804440000000000e+03,
              4.58915000000000000e+04 6.66741000000000058e+04 1.27578320000000008e+03,
              5.08915000000000000e+04 6.66741000000000058e+04 1.39008100000000013e+03,
              5.58915000000000000e+04 6.66741000000000058e+04 1.35781919999999991e+03,
              6.08915000000000000e+04 6.66741000000000058e+04 1.29845039999999995e+03,
              6.58915000000000000e+04 6.66741000000000058e+04 1.30349409999999989e+03,
              7.08915000000000000e+04 6.66741000000000058e+04 1.31509089999999992e+03,
              7.58915000000000000e+04 6.66741000000000058e+04 1.27538490000000002e+03,
              8.08915000000000000e+04 6.66741000000000058e+04 1.25699229999999989e+03,
              8.58915000000000000e+04 6.66741000000000058e+04 1.29543470000000002e+03,
              9.08915000000000000e+04 6.66741000000000058e+04 1.17777770000000010e+03,
              4.78415000000000000e+04 6.66241000000000058e+04 1.30497659999999996e+03,
              5.28415000000000000e+04 6.66241000000000058e+04 1.26046720000000005e+03,
              5.78415000000000000e+04 6.66241000000000058e+04 1.38678410000000008e+03,
              6.28415000000000000e+04 6.66241000000000058e+04 1.31656490000000008e+03,
              6.78415000000000000e+04 6.66241000000000058e+04 1.29926040000000012e+03,
              7.28415000000000000e+04 6.66241000000000058e+04 1.29997569999999996e+03,
              7.78415000000000000e+04 6.66241000000000058e+04 1.26631700000000001e+03,
              8.28415000000000000e+04 6.66241000000000058e+04 1.25057749999999987e+03,
              8.78415000000000000e+04 6.66241000000000058e+04 1.29686290000000008e+03,
              4.47915000000000000e+04 6.65741000000000058e+04 1.30693299999999999e+03,
              4.97915000000000000e+04 6.65741000000000058e+04 1.38458230000000003e+03,
              5.47915000000000000e+04 6.65741000000000058e+04 1.35480479999999989e+03,
              5.97915000000000000e+04 6.65741000000000058e+04 1.33147150000000011e+03,
              6.47915000000000000e+04 6.65741000000000058e+04 1.33700180000000000e+03,
              6.97915000000000000e+04 6.65741000000000058e+04 1.28107819999999992e+03,
              7.47915000000000000e+04 6.65741000000000058e+04 1.28286819999999989e+03,
              7.97915000000000000e+04 6.65741000000000058e+04 1.26871219999999994e+03,
              8.47915000000000000e+04 6.65741000000000058e+04 1.28137329999999997e+03,
              8.97915000000000000e+04 6.65741000000000058e+04 1.21440750000000003e+03,
              4.67415000000000000e+04 6.65241000000000058e+04 1.32223959999999988e+03,
              5.17415000000000000e+04 6.65241000000000058e+04 1.32003999999999996e+03,
              5.67415000000000000e+04 6.65241000000000058e+04 1.39806150000000002e+03,
              6.17415000000000000e+04 6.65241000000000058e+04 1.30689809999999989e+03,
              6.67415000000000000e+04 6.65241000000000058e+04 1.29529289999999992e+03,
              7.17415000000000000e+04 6.65241000000000058e+04 1.29084159999999997e+03,
              7.67415000000000000e+04 6.65241000000000058e+04 1.27364539999999988e+03,
              8.17415000000000000e+04 6.65241000000000058e+04 1.25066069999999991e+03,
              8.67415000000000000e+04 6.65241000000000058e+04 1.30675900000000001e+03,
              4.36915000000000000e+04 6.64741000000000058e+04 1.39354930000000013e+03,
              4.86915000000000000e+04 6.64741000000000058e+04 1.37997839999999997e+03,
              5.36915000000000000e+04 6.64741000000000058e+04 1.29945600000000013e+03,
              5.86915000000000000e+04 6.64741000000000058e+04 1.41608230000000003e+03,
              6.36915000000000000e+04 6.64741000000000058e+04 1.33194080000000008e+03,
              6.86915000000000000e+04 6.64741000000000058e+04 1.30564570000000003e+03,
              7.36915000000000000e+04 6.64741000000000058e+04 1.30144749999999999e+03,
              7.86915000000000000e+04 6.64741000000000058e+04 1.27928109999999992e+03,
              8.36915000000000000e+04 6.64741000000000058e+04 1.25859510000000000e+03,
              8.86915000000000000e+04 6.64741000000000058e+04 1.27703230000000008e+03,
              4.56415000000000000e+04 6.64241000000000058e+04 1.30444889999999987e+03,
              5.06415000000000000e+04 6.64241000000000058e+04 1.39256729999999993e+03,
              5.56415000000000000e+04 6.64241000000000058e+04 1.29481950000000006e+03,
              6.06415000000000000e+04 6.64241000000000058e+04 1.29284899999999993e+03,
              6.56415000000000000e+04 6.64241000000000058e+04 1.30956439999999998e+03,
              7.06415000000000000e+04 6.64241000000000058e+04 1.30442720000000008e+03,
              7.56415000000000000e+04 6.64241000000000058e+04 1.29658770000000004e+03,
              8.06415000000000000e+04 6.64241000000000058e+04 1.26143910000000005e+03,
              8.56415000000000000e+04 6.64241000000000058e+04 1.28760460000000012e+03,
              9.06415000000000000e+04 6.64241000000000058e+04 1.18387750000000005e+03,
              4.75915000000000000e+04 6.63741000000000058e+04 1.30747649999999999e+03,
              5.25915000000000000e+04 6.63741000000000058e+04 1.25988499999999999e+03,
              5.75915000000000000e+04 6.63741000000000058e+04 1.40829369999999994e+03,
              6.25915000000000000e+04 6.63741000000000058e+04 1.32078490000000011e+03,
              6.75915000000000000e+04 6.63741000000000058e+04 1.29149919999999997e+03,
              7.25915000000000000e+04 6.63741000000000058e+04 1.30830729999999994e+03,
              7.75915000000000000e+04 6.63741000000000058e+04 1.26859330000000000e+03,
              8.25915000000000000e+04 6.63741000000000058e+04 1.25499229999999989e+03,
              8.75915000000000000e+04 6.63741000000000058e+04 1.29539429999999993e+03,
              4.45415000000000000e+04 6.63241000000000058e+04 1.37250469999999996e+03,
              4.95415000000000000e+04 6.63241000000000058e+04 1.39165509999999995e+03,
              5.45415000000000000e+04 6.63241000000000058e+04 1.37077060000000006e+03,
              5.95415000000000000e+04 6.63241000000000058e+04 1.38125720000000001e+03,
              6.45415000000000000e+04 6.63241000000000058e+04 1.33209439999999995e+03,
              6.95415000000000000e+04 6.63241000000000058e+04 1.29497289999999998e+03,
              7.45415000000000000e+04 6.63241000000000058e+04 1.29209500000000003e+03,
              7.95415000000000000e+04 6.63241000000000058e+04 1.28803780000000006e+03,
              8.45415000000000000e+04 6.63241000000000058e+04 1.26539699999999993e+03,
              8.95415000000000000e+04 6.63241000000000058e+04 1.23405029999999988e+03,
              4.64915000000000000e+04 6.62741000000000058e+04 1.37197789999999986e+03,
              5.14915000000000000e+04 6.62741000000000058e+04 1.36563390000000004e+03,
              5.64915000000000000e+04 6.62741000000000058e+04 1.38685010000000011e+03,
              6.14915000000000000e+04 6.62741000000000058e+04 1.31506259999999997e+03,
              6.64915000000000000e+04 6.62741000000000058e+04 1.29299180000000001e+03,
              7.14915000000000000e+04 6.62741000000000058e+04 1.29036969999999997e+03,
              7.64915000000000000e+04 6.62741000000000058e+04 1.27333619999999996e+03,
              8.14915000000000000e+04 6.62741000000000058e+04 1.25573029999999994e+03,
              8.64915000000000000e+04 6.62741000000000058e+04 1.29754050000000007e+03,
              4.34415000000000000e+04 6.62241000000000058e+04 1.34068270000000007e+03,
              4.84415000000000000e+04 6.62241000000000058e+04 1.39474870000000010e+03,
              5.34415000000000000e+04 6.62241000000000058e+04 1.34170309999999995e+03,
              5.84415000000000000e+04 6.62241000000000058e+04 1.41455580000000009e+03,
              6.34415000000000000e+04 6.62241000000000058e+04 1.33810359999999991e+03,
              6.84415000000000000e+04 6.62241000000000058e+04 1.29452359999999999e+03,
              7.34415000000000000e+04 6.62241000000000058e+04 1.28489149999999995e+03,
              7.84415000000000000e+04 6.62241000000000058e+04 1.28546029999999996e+03,
              8.34415000000000000e+04 6.62241000000000058e+04 1.25760930000000008e+03,
              8.84415000000000000e+04 6.62241000000000058e+04 1.28117090000000007e+03,
              4.53915000000000000e+04 6.61741000000000058e+04 1.28547119999999995e+03,
              5.03915000000000000e+04 6.61741000000000058e+04 1.39092270000000008e+03,
              5.53915000000000000e+04 6.61741000000000058e+04 1.34759969999999998e+03,
              6.03915000000000000e+04 6.61741000000000058e+04 1.29584459999999990e+03,
              6.53915000000000000e+04 6.61741000000000058e+04 1.30625689999999986e+03,
              7.03915000000000000e+04 6.61741000000000058e+04 1.28619409999999993e+03,
              7.53915000000000000e+04 6.61741000000000058e+04 1.28748810000000003e+03,
              8.03915000000000000e+04 6.61741000000000058e+04 1.27194090000000006e+03,
              8.53915000000000000e+04 6.61741000000000058e+04 1.28648869999999988e+03,
              9.03915000000000000e+04 6.61741000000000058e+04 1.20070589999999993e+03,
              4.73415000000000000e+04 6.61241000000000058e+04 1.36950990000000002e+03,
              5.23415000000000000e+04 6.61241000000000058e+04 1.26930220000000008e+03,
              5.73415000000000000e+04 6.61241000000000058e+04 1.41134249999999997e+03,
              6.23415000000000000e+04 6.61241000000000058e+04 1.33080160000000001e+03,
              6.73415000000000000e+04 6.61241000000000058e+04 1.28849040000000014e+03,
              7.23415000000000000e+04 6.61241000000000058e+04 1.30648090000000002e+03,
              7.73415000000000000e+04 6.61241000000000058e+04 1.26715499999999997e+03,
              8.23415000000000000e+04 6.61241000000000058e+04 1.24495939999999996e+03,
              8.73415000000000000e+04 6.61241000000000058e+04 1.29795339999999987e+03,
              4.42915000000000000e+04 6.60741000000000058e+04 1.39851049999999987e+03,
              4.92915000000000000e+04 6.60741000000000058e+04 1.39252929999999992e+03,
              5.42915000000000000e+04 6.60741000000000058e+04 1.33430870000000004e+03,
              5.92915000000000000e+04 6.60741000000000058e+04 1.41347640000000001e+03,
              6.42915000000000000e+04 6.60741000000000058e+04 1.34343280000000004e+03,
              6.92915000000000000e+04 6.60741000000000058e+04 1.30886349999999993e+03,
              7.42915000000000000e+04 6.60741000000000058e+04 1.29699430000000007e+03,
              7.92915000000000000e+04 6.60741000000000058e+04 1.30369149999999991e+03,
              8.42915000000000000e+04 6.60741000000000058e+04 1.25654120000000012e+03,
              8.92915000000000000e+04 6.60741000000000058e+04 1.26708670000000006e+03,
              4.62415000000000000e+04 6.60241000000000058e+04 1.37376130000000012e+03,
              5.12415000000000000e+04 6.60241000000000058e+04 1.38464490000000001e+03,
              5.62415000000000000e+04 6.60241000000000058e+04 1.33329250000000002e+03,
              6.12415000000000000e+04 6.60241000000000058e+04 1.31167589999999996e+03,
              6.62415000000000000e+04 6.60241000000000058e+04 1.29708210000000008e+03,
              7.12415000000000000e+04 6.60241000000000058e+04 1.29692349999999988e+03,
              7.62415000000000000e+04 6.60241000000000058e+04 1.27521119999999996e+03,
              8.12415000000000000e+04 6.60241000000000058e+04 1.27112349999999992e+03,
              8.62415000000000000e+04 6.60241000000000058e+04 1.28653070000000002e+03,
              4.31915000000000000e+04 6.59741000000000058e+04 1.33361580000000004e+03,
              4.81915000000000000e+04 6.59741000000000058e+04 1.38865830000000005e+03,
              5.31915000000000000e+04 6.59741000000000058e+04 1.28613889999999992e+03,
              5.81915000000000000e+04 6.59741000000000058e+04 1.40685349999999994e+03,
              6.31915000000000000e+04 6.59741000000000058e+04 1.36597540000000004e+03,
              6.81915000000000000e+04 6.59741000000000058e+04 1.28306999999999994e+03,
              7.31915000000000000e+04 6.59741000000000058e+04 1.29350860000000011e+03,
              7.81915000000000000e+04 6.59741000000000058e+04 1.29215319999999997e+03,
              8.31915000000000000e+04 6.59741000000000058e+04 1.24476970000000006e+03,
              8.81915000000000000e+04 6.59741000000000058e+04 1.27345069999999987e+03,
              4.51415000000000000e+04 6.59241000000000058e+04 1.32937940000000003e+03,
              5.01415000000000000e+04 6.59241000000000058e+04 1.38660509999999999e+03,
              5.51415000000000000e+04 6.59241000000000058e+04 1.37590499999999997e+03,
              6.01415000000000000e+04 6.59241000000000058e+04 1.30823149999999987e+03,
              6.51415000000000000e+04 6.59241000000000058e+04 1.30804510000000005e+03,
              7.01415000000000000e+04 6.59241000000000058e+04 1.28101139999999987e+03,
              7.51415000000000000e+04 6.59241000000000058e+04 1.27467709999999988e+03,
              8.01415000000000000e+04 6.59241000000000058e+04 1.27053520000000003e+03,
              8.51415000000000000e+04 6.59241000000000058e+04 1.27678029999999990e+03,
              9.01415000000000000e+04 6.59241000000000058e+04 1.23811380000000008e+03,
              4.70915000000000000e+04 6.58741000000000058e+04 1.38387200000000007e+03,
              5.20915000000000000e+04 6.58741000000000058e+04 1.27318630000000007e+03,
              5.70915000000000000e+04 6.58741000000000058e+04 1.41047969999999987e+03,
              6.20915000000000000e+04 6.58741000000000058e+04 1.33672450000000003e+03,
              6.70915000000000000e+04 6.58741000000000058e+04 1.28862049999999999e+03,
              7.20915000000000000e+04 6.58741000000000058e+04 1.29252669999999989e+03,
              7.70915000000000000e+04 6.58741000000000058e+04 1.26788300000000004e+03,
              8.20915000000000000e+04 6.58741000000000058e+04 1.25079050000000007e+03,
              8.70915000000000000e+04 6.58741000000000058e+04 1.31257510000000002e+03,
              4.40415000000000000e+04 6.58241000000000058e+04 1.40466560000000004e+03,
              4.90415000000000000e+04 6.58241000000000058e+04 1.38989100000000008e+03,
              5.40415000000000000e+04 6.58241000000000058e+04 1.31135300000000007e+03,
              5.90415000000000000e+04 6.58241000000000058e+04 1.39771900000000005e+03,
              6.40415000000000000e+04 6.58241000000000058e+04 1.33102219999999988e+03,
              6.90415000000000000e+04 6.58241000000000058e+04 1.30066239999999993e+03,
              7.40415000000000000e+04 6.58241000000000058e+04 1.29489280000000008e+03,
              7.90415000000000000e+04 6.58241000000000058e+04 1.28695000000000005e+03,
              8.40415000000000000e+04 6.58241000000000058e+04 1.24648250000000007e+03,
              8.90415000000000000e+04 6.58241000000000058e+04 1.27491080000000011e+03,
              4.59915000000000000e+04 6.57741000000000058e+04 1.37295190000000002e+03,
              5.09915000000000000e+04 6.57741000000000058e+04 1.38927520000000004e+03,
              5.59915000000000000e+04 6.57741000000000058e+04 1.29472919999999999e+03,
              6.09915000000000000e+04 6.57741000000000058e+04 1.31846080000000006e+03,
              6.59915000000000000e+04 6.57741000000000058e+04 1.29596749999999997e+03,
              7.09915000000000000e+04 6.57741000000000058e+04 1.30834580000000005e+03,
              7.59915000000000000e+04 6.57741000000000058e+04 1.26878500000000008e+03,
              8.09915000000000000e+04 6.57741000000000058e+04 1.26427929999999992e+03,
              8.59915000000000000e+04 6.57741000000000058e+04 1.27673320000000012e+03,
              9.09915000000000000e+04 6.57741000000000058e+04 1.24887300000000005e+03,
              4.79415000000000000e+04 6.57241000000000058e+04 1.37550990000000002e+03,
              5.29415000000000000e+04 6.57241000000000058e+04 1.30921589999999992e+03,
              5.79415000000000000e+04 6.57241000000000058e+04 1.41580340000000001e+03,
              6.29415000000000000e+04 6.57241000000000058e+04 1.34204849999999988e+03,
              6.79415000000000000e+04 6.57241000000000058e+04 1.28717889999999989e+03,
              7.29415000000000000e+04 6.57241000000000058e+04 1.28564100000000008e+03,
              7.79415000000000000e+04 6.57241000000000058e+04 1.29700669999999991e+03,
              8.29415000000000000e+04 6.57241000000000058e+04 1.24824880000000007e+03,
              8.79415000000000000e+04 6.57241000000000058e+04 1.25825849999999991e+03,
              4.48915000000000000e+04 6.56741000000000058e+04 1.37638339999999994e+03,
              4.98915000000000000e+04 6.56741000000000058e+04 1.38026960000000008e+03,
              5.48915000000000000e+04 6.56741000000000058e+04 1.33039779999999996e+03,
              5.98915000000000000e+04 6.56741000000000058e+04 1.31519799999999987e+03,
              6.48915000000000000e+04 6.56741000000000058e+04 1.30582729999999992e+03,
              6.98915000000000000e+04 6.56741000000000058e+04 1.28595280000000002e+03,
              7.48915000000000000e+04 6.56741000000000058e+04 1.27051209999999992e+03,
              7.98915000000000000e+04 6.56741000000000058e+04 1.28562059999999997e+03,
              8.48915000000000000e+04 6.56741000000000058e+04 1.26353230000000008e+03,
              8.98915000000000000e+04 6.56741000000000058e+04 1.22378289999999993e+03,
              4.68415000000000000e+04 6.56241000000000058e+04 1.38384179999999992e+03,
              5.18415000000000000e+04 6.56241000000000058e+04 1.26177480000000014e+03,
              5.68415000000000000e+04 6.56241000000000058e+04 1.40161159999999995e+03,
              6.18415000000000000e+04 6.56241000000000058e+04 1.31797360000000003e+03,
              6.68415000000000000e+04 6.56241000000000058e+04 1.29065439999999990e+03,
              7.18415000000000000e+04 6.56241000000000058e+04 1.28509459999999990e+03,
              7.68415000000000000e+04 6.56241000000000058e+04 1.26793209999999999e+03,
              8.18415000000000000e+04 6.56241000000000058e+04 1.27642930000000001e+03,
              8.68415000000000000e+04 6.56241000000000058e+04 1.30138369999999986e+03,
              4.37915000000000000e+04 6.55741000000000058e+04 1.38104909999999995e+03,
              4.87915000000000000e+04 6.55741000000000058e+04 1.38380839999999989e+03,
              5.37915000000000000e+04 6.55741000000000058e+04 1.28809169999999995e+03,
              5.87915000000000000e+04 6.55741000000000058e+04 1.39888099999999986e+03,
              6.37915000000000000e+04 6.55741000000000058e+04 1.33663130000000001e+03,
              6.87915000000000000e+04 6.55741000000000058e+04 1.29011050000000000e+03,
              7.37915000000000000e+04 6.55741000000000058e+04 1.27789429999999993e+03,
              7.87915000000000000e+04 6.55741000000000058e+04 1.27663040000000001e+03,
              8.37915000000000000e+04 6.55741000000000058e+04 1.24439179999999988e+03,
              8.87915000000000000e+04 6.55741000000000058e+04 1.27418200000000002e+03,
              4.57415000000000000e+04 6.55241000000000058e+04 1.31918769999999995e+03,
              5.07415000000000000e+04 6.55241000000000058e+04 1.38345209999999997e+03,
              5.57415000000000000e+04 6.55241000000000058e+04 1.38627140000000009e+03,
              6.07415000000000000e+04 6.55241000000000058e+04 1.29871900000000005e+03,
              6.57415000000000000e+04 6.55241000000000058e+04 1.29246140000000014e+03,
              7.07415000000000000e+04 6.55241000000000058e+04 1.29757459999999992e+03,
              7.57415000000000000e+04 6.55241000000000058e+04 1.27054700000000003e+03,
              8.07415000000000000e+04 6.55241000000000058e+04 1.27954829999999993e+03,
              8.57415000000000000e+04 6.55241000000000058e+04 1.27695540000000005e+03,
              9.07415000000000000e+04 6.55241000000000058e+04 1.25668849999999998e+03,
              4.76915000000000000e+04 6.54741000000000058e+04 1.37535120000000006e+03,
              5.26915000000000000e+04 6.54741000000000058e+04 1.35548939999999993e+03,
              5.76915000000000000e+04 6.54741000000000058e+04 1.40574340000000007e+03,
              6.26915000000000000e+04 6.54741000000000058e+04 1.33067970000000014e+03,
              6.76915000000000000e+04 6.54741000000000058e+04 1.28844949999999994e+03,
              7.26915000000000000e+04 6.54741000000000058e+04 1.29348289999999997e+03,
              7.76915000000000000e+04 6.54741000000000058e+04 1.29293849999999998e+03,
              8.26915000000000000e+04 6.54741000000000058e+04 1.25847360000000003e+03,
              8.76915000000000000e+04 6.54741000000000058e+04 1.24890100000000007e+03,
              4.46415000000000000e+04 6.54241000000000058e+04 1.38695859999999993e+03,
              4.96415000000000000e+04 6.54241000000000058e+04 1.37114609999999993e+03,
              5.46415000000000000e+04 6.54241000000000058e+04 1.38615480000000002e+03,
              5.96415000000000000e+04 6.54241000000000058e+04 1.31178209999999990e+03,
              6.46415000000000000e+04 6.54241000000000058e+04 1.30493980000000010e+03,
              6.96415000000000000e+04 6.54241000000000058e+04 1.28906849999999986e+03,
              7.46415000000000000e+04 6.54241000000000058e+04 1.26959539999999993e+03,
              7.96415000000000000e+04 6.54241000000000058e+04 1.29524579999999992e+03,
              8.46415000000000000e+04 6.54241000000000058e+04 1.25550890000000004e+03,
              8.96415000000000000e+04 6.54241000000000058e+04 1.22027309999999989e+03,
              4.65915000000000000e+04 6.53741000000000058e+04 1.37291669999999999e+03,
              5.15915000000000000e+04 6.53741000000000058e+04 1.26199639999999999e+03,
              5.65915000000000000e+04 6.53741000000000058e+04 1.37101540000000000e+03,
              6.15915000000000000e+04 6.53741000000000058e+04 1.30561599999999999e+03,
              6.65915000000000000e+04 6.53741000000000058e+04 1.29958870000000002e+03,
              7.15915000000000000e+04 6.53741000000000058e+04 1.28754179999999997e+03,
              7.65915000000000000e+04 6.53741000000000058e+04 1.27043509999999992e+03,
              8.15915000000000000e+04 6.53741000000000058e+04 1.25695990000000006e+03,
              8.65915000000000000e+04 6.53741000000000058e+04 1.29092730000000006e+03,
              4.35415000000000000e+04 6.53241000000000058e+04 1.40721440000000007e+03,
              4.85415000000000000e+04 6.53241000000000058e+04 1.37514280000000008e+03,
              5.35415000000000000e+04 6.53241000000000058e+04 1.27222229999999990e+03,
              5.85415000000000000e+04 6.53241000000000058e+04 1.42365990000000011e+03,
              6.35415000000000000e+04 6.53241000000000058e+04 1.34055870000000004e+03,
              6.85415000000000000e+04 6.53241000000000058e+04 1.28169139999999993e+03,
              7.35415000000000000e+04 6.53241000000000058e+04 1.28925260000000003e+03,
              7.85415000000000000e+04 6.53241000000000058e+04 1.29825620000000004e+03,
              8.35415000000000000e+04 6.53241000000000058e+04 1.25424060000000009e+03,
              8.85415000000000000e+04 6.53241000000000058e+04 1.26530560000000014e+03,
              4.54915000000000000e+04 6.52741000000000058e+04 1.36922579999999994e+03,
              5.04915000000000000e+04 6.52741000000000058e+04 1.36945339999999987e+03,
              5.54915000000000000e+04 6.52741000000000058e+04 1.41301279999999997e+03,
              6.04915000000000000e+04 6.52741000000000058e+04 1.30070000000000005e+03,
              6.54915000000000000e+04 6.52741000000000058e+04 1.29710500000000002e+03,
              7.04915000000000000e+04 6.52741000000000058e+04 1.28080700000000002e+03,
              7.54915000000000000e+04 6.52741000000000058e+04 1.27259719999999993e+03,
              8.04915000000000000e+04 6.52741000000000058e+04 1.27048019999999997e+03,
              8.54915000000000000e+04 6.52741000000000058e+04 1.27682050000000004e+03,
              9.04915000000000000e+04 6.52741000000000058e+04 1.24707009999999991e+03,
              4.74415000000000000e+04 6.52241000000000058e+04 1.37949630000000002e+03,
              5.24415000000000000e+04 6.52241000000000058e+04 1.30908370000000014e+03,
              5.74415000000000000e+04 6.52241000000000058e+04 1.40895020000000000e+03,
              6.24415000000000000e+04 6.52241000000000058e+04 1.32320789999999988e+03,
              6.74415000000000000e+04 6.52241000000000058e+04 1.30038650000000007e+03,
              7.24415000000000000e+04 6.52241000000000058e+04 1.28069119999999998e+03,
              7.74415000000000000e+04 6.52241000000000058e+04 1.28073720000000003e+03,
              8.24415000000000000e+04 6.52241000000000058e+04 1.26695540000000005e+03,
              8.74415000000000000e+04 6.52241000000000058e+04 1.24294049999999993e+03,
              4.43915000000000000e+04 6.51741000000000058e+04 1.39237689999999998e+03,
              4.93915000000000000e+04 6.51741000000000058e+04 1.36379390000000012e+03,
              5.43915000000000000e+04 6.51741000000000058e+04 1.29514449999999988e+03,
              5.93915000000000000e+04 6.51741000000000058e+04 1.32548720000000003e+03,
              6.43915000000000000e+04 6.51741000000000058e+04 1.31732410000000004e+03,
              6.93915000000000000e+04 6.51741000000000058e+04 1.28559590000000003e+03,
              7.43915000000000000e+04 6.51741000000000058e+04 1.26991370000000006e+03,
              7.93915000000000000e+04 6.51741000000000058e+04 1.27075060000000008e+03,
              8.43915000000000000e+04 6.51741000000000058e+04 1.24188259999999991e+03,
              8.93915000000000000e+04 6.51741000000000058e+04 1.22897970000000009e+03,
              4.63415000000000000e+04 6.51241000000000058e+04 1.36813130000000001e+03,
              5.13415000000000000e+04 6.51241000000000058e+04 1.25938509999999997e+03,
              5.63415000000000000e+04 6.51241000000000058e+04 1.32594370000000004e+03,
              6.13415000000000000e+04 6.51241000000000058e+04 1.30830479999999989e+03,
              6.63415000000000000e+04 6.51241000000000058e+04 1.29379349999999999e+03,
              7.13415000000000000e+04 6.51241000000000058e+04 1.29975540000000001e+03,
              7.63415000000000000e+04 6.51241000000000058e+04 1.27948280000000000e+03,
              8.13415000000000000e+04 6.51241000000000058e+04 1.24463419999999996e+03,
              8.63415000000000000e+04 6.51241000000000058e+04 1.27728350000000000e+03,
              4.32915000000000000e+04 6.50741000000000058e+04 1.40225489999999991e+03,
              4.82915000000000000e+04 6.50741000000000058e+04 1.38291489999999999e+03,
              5.32915000000000000e+04 6.50741000000000058e+04 1.27821399999999994e+03,
              5.82915000000000000e+04 6.50741000000000058e+04 1.41232909999999993e+03,
              6.32915000000000000e+04 6.50741000000000058e+04 1.38195020000000000e+03,
              6.82915000000000000e+04 6.50741000000000058e+04 1.28046260000000007e+03,
              7.32915000000000000e+04 6.50741000000000058e+04 1.27696620000000007e+03,
              7.82915000000000000e+04 6.50741000000000058e+04 1.29257040000000006e+03,
              8.32915000000000000e+04 6.50741000000000058e+04 1.24635419999999999e+03,
              8.82915000000000000e+04 6.50741000000000058e+04 1.28066049999999996e+03,
              4.52415000000000000e+04 6.50241000000000058e+04 1.36731909999999993e+03,
              5.02415000000000000e+04 6.50241000000000058e+04 1.36990370000000007e+03,
              5.52415000000000000e+04 6.50241000000000058e+04 1.41342489999999998e+03,
              6.02415000000000000e+04 6.50241000000000058e+04 1.30194029999999998e+03,
              6.52415000000000000e+04 6.50241000000000058e+04 1.30222450000000003e+03,
              7.02415000000000000e+04 6.50241000000000058e+04 1.28918270000000007e+03,
              7.52415000000000000e+04 6.50241000000000058e+04 1.27913210000000004e+03,
              8.02415000000000000e+04 6.50241000000000058e+04 1.27790439999999990e+03,
              8.52415000000000000e+04 6.50241000000000058e+04 1.26692470000000003e+03,
              9.02415000000000000e+04 6.50241000000000058e+04 1.24047389999999996e+03,
              4.71915000000000000e+04 6.49741000000000058e+04 1.37970029999999997e+03,
              5.21915000000000000e+04 6.49741000000000058e+04 1.28126499999999987e+03,
              5.71915000000000000e+04 6.49741000000000058e+04 1.39442869999999994e+03,
              6.21915000000000000e+04 6.49741000000000058e+04 1.31966269999999986e+03,
              6.71915000000000000e+04 6.49741000000000058e+04 1.29980569999999989e+03,
              7.21915000000000000e+04 6.49741000000000058e+04 1.28167650000000003e+03,
              7.71915000000000000e+04 6.49741000000000058e+04 1.27550510000000008e+03,
              8.21915000000000000e+04 6.49741000000000058e+04 1.24683420000000001e+03,
              8.71915000000000000e+04 6.49741000000000058e+04 1.23165460000000007e+03,
              4.41415000000000000e+04 6.49241000000000058e+04 1.39083760000000007e+03,
              4.91415000000000000e+04 6.49241000000000058e+04 1.36632169999999996e+03,
              5.41415000000000000e+04 6.49241000000000058e+04 1.34155230000000006e+03,
              5.91415000000000000e+04 6.49241000000000058e+04 1.36796740000000000e+03,
              6.41415000000000000e+04 6.49241000000000058e+04 1.33149800000000005e+03,
              6.91415000000000000e+04 6.49241000000000058e+04 1.27799659999999994e+03,
              7.41415000000000000e+04 6.49241000000000058e+04 1.27294799999999987e+03,
              7.91415000000000000e+04 6.49241000000000058e+04 1.25650649999999996e+03,
              8.41415000000000000e+04 6.49241000000000058e+04 1.24168450000000007e+03,
              8.91415000000000000e+04 6.49241000000000058e+04 1.23850870000000009e+03,
              4.60915000000000000e+04 6.48741000000000058e+04 1.35890900000000011e+03,
              5.10915000000000000e+04 6.48741000000000058e+04 1.26472129999999993e+03,
              5.60915000000000000e+04 6.48741000000000058e+04 1.40183780000000002e+03,
              6.10915000000000000e+04 6.48741000000000058e+04 1.31572640000000001e+03,
              6.60915000000000000e+04 6.48741000000000058e+04 1.29759860000000003e+03,
              7.10915000000000000e+04 6.48741000000000058e+04 1.28129939999999988e+03,
              7.60915000000000000e+04 6.48741000000000058e+04 1.29075170000000003e+03,
              8.10915000000000000e+04 6.48741000000000058e+04 1.24469409999999993e+03,
              8.60915000000000000e+04 6.48741000000000058e+04 1.27138810000000012e+03,
              4.30415000000000000e+04 6.48241000000000058e+04 1.39655899999999997e+03,
              4.80415000000000000e+04 6.48241000000000058e+04 1.38501250000000005e+03,
              5.30415000000000000e+04 6.48241000000000058e+04 1.34920749999999998e+03,
              5.80415000000000000e+04 6.48241000000000058e+04 1.42281690000000003e+03,
              6.30415000000000000e+04 6.48241000000000058e+04 1.36317930000000001e+03,
              6.80415000000000000e+04 6.48241000000000058e+04 1.29171309999999994e+03,
              7.30415000000000000e+04 6.48241000000000058e+04 1.27642959999999994e+03,
              7.80415000000000000e+04 6.48241000000000058e+04 1.28796830000000000e+03,
              8.30415000000000000e+04 6.48241000000000058e+04 1.23706889999999999e+03,
              8.80415000000000000e+04 6.48241000000000058e+04 1.25496589999999992e+03,
              4.49915000000000000e+04 6.47741000000000058e+04 1.36626829999999995e+03,
              4.99915000000000000e+04 6.47741000000000058e+04 1.36268660000000000e+03,
              5.49915000000000000e+04 6.47741000000000058e+04 1.40230470000000014e+03,
              5.99915000000000000e+04 6.47741000000000058e+04 1.30910439999999994e+03,
              6.49915000000000000e+04 6.47741000000000058e+04 1.32463999999999987e+03,
              6.99915000000000000e+04 6.47741000000000058e+04 1.27790959999999995e+03,
              7.49915000000000000e+04 6.47741000000000058e+04 1.27510860000000002e+03,
              7.99915000000000000e+04 6.47741000000000058e+04 1.24919479999999999e+03,
              8.49915000000000000e+04 6.47741000000000058e+04 1.24153170000000000e+03,
              8.99915000000000000e+04 6.47741000000000058e+04 1.23733349999999996e+03,
              4.69415000000000000e+04 6.47241000000000058e+04 1.36970180000000005e+03,
              5.19415000000000000e+04 6.47241000000000058e+04 1.26060739999999987e+03,
              5.69415000000000000e+04 6.47241000000000058e+04 1.33485590000000002e+03,
              6.19415000000000000e+04 6.47241000000000058e+04 1.33102369999999996e+03,
              6.69415000000000000e+04 6.47241000000000058e+04 1.30043589999999995e+03,
              7.19415000000000000e+04 6.47241000000000058e+04 1.27180220000000008e+03,
              7.69415000000000000e+04 6.47241000000000058e+04 1.27495060000000012e+03,
              8.19415000000000000e+04 6.47241000000000058e+04 1.25286110000000008e+03,
              8.69415000000000000e+04 6.47241000000000058e+04 1.23157770000000005e+03,
              4.38915000000000000e+04 6.46741000000000058e+04 1.39896109999999999e+03,
              4.88915000000000000e+04 6.46741000000000058e+04 1.37513180000000011e+03,
              5.38915000000000000e+04 6.46741000000000058e+04 1.38253119999999990e+03,
              5.88915000000000000e+04 6.46741000000000058e+04 1.38842990000000009e+03,
              6.38915000000000000e+04 6.46741000000000058e+04 1.32045039999999995e+03,
              6.88915000000000000e+04 6.46741000000000058e+04 1.28282629999999995e+03,
              7.38915000000000000e+04 6.46741000000000058e+04 1.27209979999999996e+03,
              7.88915000000000000e+04 6.46741000000000058e+04 1.25837339999999995e+03,
              8.38915000000000000e+04 6.46741000000000058e+04 1.24785650000000010e+03,
              8.88915000000000000e+04 6.46741000000000058e+04 1.23503019999999992e+03,
              4.58415000000000000e+04 6.46241000000000058e+04 1.36235059999999999e+03,
              5.08415000000000000e+04 6.46241000000000058e+04 1.33447489999999993e+03,
              5.58415000000000000e+04 6.46241000000000058e+04 1.39563030000000003e+03,
              6.08415000000000000e+04 6.46241000000000058e+04 1.33887590000000000e+03,
              6.58415000000000000e+04 6.46241000000000058e+04 1.29373790000000008e+03,
              7.08415000000000000e+04 6.46241000000000058e+04 1.27623109999999997e+03,
              7.58415000000000000e+04 6.46241000000000058e+04 1.29288509999999997e+03,
              8.08415000000000000e+04 6.46241000000000058e+04 1.25037500000000000e+03,
              8.58415000000000000e+04 6.46241000000000058e+04 1.26393750000000000e+03,
              9.08415000000000000e+04 6.46241000000000058e+04 1.27525810000000001e+03,
              4.77915000000000000e+04 6.45741000000000058e+04 1.37897499999999991e+03,
              5.27915000000000000e+04 6.45741000000000058e+04 1.36823730000000000e+03,
              5.77915000000000000e+04 6.45741000000000058e+04 1.41800710000000004e+03,
              6.27915000000000000e+04 6.45741000000000058e+04 1.36296599999999989e+03,
              6.77915000000000000e+04 6.45741000000000058e+04 1.29438909999999987e+03,
              7.27915000000000000e+04 6.45741000000000058e+04 1.27609169999999995e+03,
              7.77915000000000000e+04 6.45741000000000058e+04 1.30761349999999993e+03,
              8.27915000000000000e+04 6.45741000000000058e+04 1.24586159999999995e+03,
              8.77915000000000000e+04 6.45741000000000058e+04 1.23942339999999990e+03,
              4.47415000000000000e+04 6.45241000000000058e+04 1.37618560000000002e+03,
              4.97415000000000000e+04 6.45241000000000058e+04 1.34285950000000003e+03,
              5.47415000000000000e+04 6.45241000000000058e+04 1.37057780000000002e+03,
              5.97415000000000000e+04 6.45241000000000058e+04 1.32474910000000000e+03,
              6.47415000000000000e+04 6.45241000000000058e+04 1.31922980000000007e+03,
              6.97415000000000000e+04 6.45241000000000058e+04 1.27864550000000008e+03,
              7.47415000000000000e+04 6.45241000000000058e+04 1.28040920000000006e+03,
              7.97415000000000000e+04 6.45241000000000058e+04 1.25917959999999994e+03,
              8.47415000000000000e+04 6.45241000000000058e+04 1.24889290000000005e+03,
              8.97415000000000000e+04 6.45241000000000058e+04 1.23264859999999999e+03,
              4.66915000000000000e+04 6.44741000000000058e+04 1.36355250000000001e+03,
              5.16915000000000000e+04 6.44741000000000058e+04 1.26933559999999989e+03,
              5.66915000000000000e+04 6.44741000000000058e+04 1.38924790000000007e+03,
              6.16915000000000000e+04 6.44741000000000058e+04 1.34782629999999995e+03,
              6.66915000000000000e+04 6.44741000000000058e+04 1.29575209999999993e+03,
              7.16915000000000000e+04 6.44741000000000058e+04 1.27321399999999994e+03,
              7.66915000000000000e+04 6.44741000000000058e+04 1.29783259999999996e+03,
              8.16915000000000000e+04 6.44741000000000058e+04 1.26537290000000007e+03,
              8.66915000000000000e+04 6.44741000000000058e+04 1.23085310000000004e+03,
              4.36415000000000000e+04 6.44241000000000058e+04 1.39399119999999994e+03,
              4.86415000000000000e+04 6.44241000000000058e+04 1.37321509999999989e+03,
              5.36415000000000000e+04 6.44241000000000058e+04 1.37129259999999999e+03,
              5.86415000000000000e+04 6.44241000000000058e+04 1.39472250000000008e+03,
              6.36415000000000000e+04 6.44241000000000058e+04 1.32540560000000005e+03,
              6.86415000000000000e+04 6.44241000000000058e+04 1.28894419999999991e+03,
              7.36415000000000000e+04 6.44241000000000058e+04 1.28269149999999991e+03,
              7.86415000000000000e+04 6.44241000000000058e+04 1.26287529999999992e+03,
              8.36415000000000000e+04 6.44241000000000058e+04 1.25701490000000013e+03,
              8.86415000000000000e+04 6.44241000000000058e+04 1.22117450000000008e+03,
              4.55915000000000000e+04 6.43741000000000058e+04 1.37025669999999991e+03,
              5.05915000000000000e+04 6.43741000000000058e+04 1.29800999999999999e+03,
              5.55915000000000000e+04 6.43741000000000058e+04 1.37775160000000005e+03,
              6.05915000000000000e+04 6.43741000000000058e+04 1.34498309999999992e+03,
              6.55915000000000000e+04 6.43741000000000058e+04 1.29085239999999999e+03,
              7.05915000000000000e+04 6.43741000000000058e+04 1.27720450000000005e+03,
              7.55915000000000000e+04 6.43741000000000058e+04 1.28103089999999997e+03,
              8.05915000000000000e+04 6.43741000000000058e+04 1.24748150000000010e+03,
              8.55915000000000000e+04 6.43741000000000058e+04 1.26197309999999993e+03,
              9.05915000000000000e+04 6.43741000000000058e+04 1.26895489999999995e+03,
              4.75415000000000000e+04 6.43241000000000058e+04 1.36968119999999999e+03,
              5.25415000000000000e+04 6.43241000000000058e+04 1.36722640000000001e+03,
              5.75415000000000000e+04 6.43241000000000058e+04 1.40876389999999992e+03,
              6.25415000000000000e+04 6.43241000000000058e+04 1.38261529999999993e+03,
              6.75415000000000000e+04 6.43241000000000058e+04 1.28568800000000010e+03,
              7.25415000000000000e+04 6.43241000000000058e+04 1.27548129999999992e+03,
              7.75415000000000000e+04 6.43241000000000058e+04 1.31790609999999992e+03,
              8.25415000000000000e+04 6.43241000000000058e+04 1.25534220000000005e+03,
              8.75415000000000000e+04 6.43241000000000058e+04 1.23311740000000009e+03,
              4.44915000000000000e+04 6.42741000000000058e+04 1.36555250000000001e+03,
              4.94915000000000000e+04 6.42741000000000058e+04 1.35741080000000011e+03,
              5.44915000000000000e+04 6.42741000000000058e+04 1.29292540000000008e+03,
              5.94915000000000000e+04 6.42741000000000058e+04 1.38806619999999998e+03,
              6.44915000000000000e+04 6.42741000000000058e+04 1.32842619999999988e+03,
              6.94915000000000000e+04 6.42741000000000058e+04 1.28919630000000006e+03,
              7.44915000000000000e+04 6.42741000000000058e+04 1.29085940000000005e+03,
              7.94915000000000000e+04 6.42741000000000058e+04 1.25173970000000008e+03,
              8.44915000000000000e+04 6.42741000000000058e+04 1.24123970000000008e+03,
              8.94915000000000000e+04 6.42741000000000058e+04 1.22917509999999993e+03,
              4.64415000000000000e+04 6.42241000000000058e+04 1.36513529999999992e+03,
              5.14415000000000000e+04 6.42241000000000058e+04 1.28950410000000011e+03,
              5.64415000000000000e+04 6.42241000000000058e+04 1.40812179999999989e+03,
              6.14415000000000000e+04 6.42241000000000058e+04 1.35268440000000010e+03,
              6.64415000000000000e+04 6.42241000000000058e+04 1.28638770000000000e+03,
              7.14415000000000000e+04 6.42241000000000058e+04 1.27522060000000010e+03,
              7.64415000000000000e+04 6.42241000000000058e+04 1.28889049999999997e+03,
              8.14415000000000000e+04 6.42241000000000058e+04 1.25212799999999993e+03,
              8.64415000000000000e+04 6.42241000000000058e+04 1.22248620000000005e+03,
              4.33915000000000000e+04 6.41741000000000058e+04 1.39282279999999992e+03,
              4.83915000000000000e+04 6.41741000000000058e+04 1.36291149999999993e+03,
              5.33915000000000000e+04 6.41741000000000058e+04 1.37522690000000011e+03,
              5.83915000000000000e+04 6.41741000000000058e+04 1.40797379999999998e+03,
              6.33915000000000000e+04 6.41741000000000058e+04 1.31871010000000001e+03,
              6.83915000000000000e+04 6.41741000000000058e+04 1.28445029999999997e+03,
              7.33915000000000000e+04 6.41741000000000058e+04 1.27822029999999995e+03,
              7.83915000000000000e+04 6.41741000000000058e+04 1.27011580000000004e+03,
              8.33915000000000000e+04 6.41741000000000058e+04 1.25692250000000013e+03,
              8.83915000000000000e+04 6.41741000000000058e+04 1.21514249999999993e+03,
              4.53415000000000000e+04 6.41241000000000058e+04 1.37113670000000002e+03,
              5.03415000000000000e+04 6.41241000000000058e+04 1.31538760000000002e+03,
              5.53415000000000000e+04 6.41241000000000058e+04 1.39918509999999992e+03,
              6.03415000000000000e+04 6.41241000000000058e+04 1.31371109999999999e+03,
              6.53415000000000000e+04 6.41241000000000058e+04 1.29445929999999998e+03,
              7.03415000000000000e+04 6.41241000000000058e+04 1.29226659999999993e+03,
              7.53415000000000000e+04 6.41241000000000058e+04 1.27766269999999986e+03,
              8.03415000000000000e+04 6.41241000000000058e+04 1.25367869999999994e+03,
              8.53415000000000000e+04 6.41241000000000058e+04 1.25812470000000008e+03,
              9.03415000000000000e+04 6.41241000000000058e+04 1.26001610000000005e+03,
              4.72915000000000000e+04 6.40741000000000058e+04 1.36731220000000008e+03,
              5.22915000000000000e+04 6.40741000000000058e+04 1.34799319999999989e+03,
              5.72915000000000000e+04 6.40741000000000058e+04 1.39082600000000002e+03,
              6.22915000000000000e+04 6.40741000000000058e+04 1.35277530000000002e+03,
              6.72915000000000000e+04 6.40741000000000058e+04 1.28689620000000014e+03,
              7.22915000000000000e+04 6.40741000000000058e+04 1.28062509999999997e+03,
              7.72915000000000000e+04 6.40741000000000058e+04 1.29593249999999989e+03,
              8.22915000000000000e+04 6.40741000000000058e+04 1.26042160000000013e+03,
              8.72915000000000000e+04 6.40741000000000058e+04 1.22649569999999994e+03,
              4.42415000000000000e+04 6.40241000000000058e+04 1.34987820000000011e+03,
              4.92415000000000000e+04 6.40241000000000058e+04 1.35495139999999992e+03,
              5.42415000000000000e+04 6.40241000000000058e+04 1.36845929999999998e+03,
              5.92415000000000000e+04 6.40241000000000058e+04 1.41539850000000001e+03,
              6.42415000000000000e+04 6.40241000000000058e+04 1.30780600000000004e+03,
              6.92415000000000000e+04 6.40241000000000058e+04 1.28527369999999996e+03,
              7.42415000000000000e+04 6.40241000000000058e+04 1.29006700000000001e+03,
              7.92415000000000000e+04 6.40241000000000058e+04 1.25087380000000007e+03,
              8.42415000000000000e+04 6.40241000000000058e+04 1.26368570000000000e+03,
              8.92415000000000000e+04 6.40241000000000058e+04 1.22614779999999996e+03,
              4.61915000000000000e+04 6.39741000000000058e+04 1.36731189999999992e+03,
              5.11915000000000000e+04 6.39741000000000058e+04 1.28462539999999990e+03,
              5.61915000000000000e+04 6.39741000000000058e+04 1.40585429999999997e+03,
              6.11915000000000000e+04 6.39741000000000058e+04 1.34548749999999995e+03,
              6.61915000000000000e+04 6.39741000000000058e+04 1.28657819999999992e+03,
              7.11915000000000000e+04 6.39741000000000058e+04 1.27826870000000008e+03,
              7.61915000000000000e+04 6.39741000000000058e+04 1.28413249999999994e+03,
              8.11915000000000000e+04 6.39741000000000058e+04 1.25398739999999998e+03,
              8.61915000000000000e+04 6.39741000000000058e+04 1.21850479999999993e+03,
              4.31415000000000000e+04 6.39241000000000058e+04 1.37375279999999998e+03,
              4.81415000000000000e+04 6.39241000000000058e+04 1.35937370000000010e+03,
              5.31415000000000000e+04 6.39241000000000058e+04 1.37081729999999993e+03,
              5.81415000000000000e+04 6.39241000000000058e+04 1.39485750000000007e+03,
              6.31415000000000000e+04 6.39241000000000058e+04 1.31532590000000005e+03,
              6.81415000000000000e+04 6.39241000000000058e+04 1.28462609999999995e+03,
              7.31415000000000000e+04 6.39241000000000058e+04 1.27627749999999992e+03,
              7.81415000000000000e+04 6.39241000000000058e+04 1.26112230000000000e+03,
              8.31415000000000000e+04 6.39241000000000058e+04 1.26331289999999990e+03,
              8.81415000000000000e+04 6.39241000000000058e+04 1.19903729999999996e+03,
              4.50915000000000000e+04 6.38741000000000058e+04 1.36569090000000006e+03,
              5.00915000000000000e+04 6.38741000000000058e+04 1.31644229999999993e+03,
              5.50915000000000000e+04 6.38741000000000058e+04 1.38629520000000002e+03,
              6.00915000000000000e+04 6.38741000000000058e+04 1.35289069999999992e+03,
              6.50915000000000000e+04 6.38741000000000058e+04 1.29345830000000001e+03,
              7.00915000000000000e+04 6.38741000000000058e+04 1.29778839999999991e+03,
              7.50915000000000000e+04 6.38741000000000058e+04 1.27848070000000007e+03,
              8.00915000000000000e+04 6.38741000000000058e+04 1.24881009999999992e+03,
              8.50915000000000000e+04 6.38741000000000058e+04 1.25541300000000001e+03,
              9.00915000000000000e+04 6.38741000000000058e+04 1.24416460000000006e+03,
              4.70415000000000000e+04 6.38241000000000058e+04 1.36193299999999999e+03,
              5.20415000000000000e+04 6.38241000000000058e+04 1.33103340000000003e+03,
              5.70415000000000000e+04 6.38241000000000058e+04 1.36996650000000000e+03,
              6.20415000000000000e+04 6.38241000000000058e+04 1.38211320000000001e+03,
              6.70415000000000000e+04 6.38241000000000058e+04 1.29184580000000005e+03,
              7.20415000000000000e+04 6.38241000000000058e+04 1.28024080000000004e+03,
              7.70415000000000000e+04 6.38241000000000058e+04 1.30094679999999994e+03,
              8.20415000000000000e+04 6.38241000000000058e+04 1.25898450000000003e+03,
              8.70415000000000000e+04 6.38241000000000058e+04 1.22523689999999988e+03,
              4.39915000000000000e+04 6.37741000000000058e+04 1.35084570000000008e+03,
              4.89915000000000000e+04 6.37741000000000058e+04 1.35543170000000009e+03,
              5.39915000000000000e+04 6.37741000000000058e+04 1.38891560000000004e+03,
              5.89915000000000000e+04 6.37741000000000058e+04 1.41713010000000008e+03,
              6.39915000000000000e+04 6.37741000000000058e+04 1.30860649999999987e+03,
              6.89915000000000000e+04 6.37741000000000058e+04 1.29152009999999996e+03,
              7.39915000000000000e+04 6.37741000000000058e+04 1.30164980000000014e+03,
              7.89915000000000000e+04 6.37741000000000058e+04 1.25360120000000006e+03,
              8.39915000000000000e+04 6.37741000000000058e+04 1.26163290000000006e+03,
              8.89915000000000000e+04 6.37741000000000058e+04 1.21338750000000005e+03,
              4.59415000000000000e+04 6.37241000000000058e+04 1.36505760000000009e+03,
              5.09415000000000000e+04 6.37241000000000058e+04 1.31435899999999992e+03,
              5.59415000000000000e+04 6.37241000000000058e+04 1.41441030000000001e+03,
              6.09415000000000000e+04 6.37241000000000058e+04 1.36912049999999999e+03,
              6.59415000000000000e+04 6.37241000000000058e+04 1.28586969999999997e+03,
              7.09415000000000000e+04 6.37241000000000058e+04 1.29395229999999992e+03,
              7.59415000000000000e+04 6.37241000000000058e+04 1.28335539999999992e+03,
              8.09415000000000000e+04 6.37241000000000058e+04 1.25249119999999994e+03,
              8.59415000000000000e+04 6.37241000000000058e+04 1.23652109999999993e+03,
              9.09415000000000000e+04 6.37241000000000058e+04 1.25239480000000003e+03,
              4.78915000000000000e+04 6.36741000000000058e+04 1.35255729999999994e+03,
              5.28915000000000000e+04 6.36741000000000058e+04 1.36626049999999987e+03,
              5.78915000000000000e+04 6.36741000000000058e+04 1.37927369999999996e+03,
              6.28915000000000000e+04 6.36741000000000058e+04 1.32292599999999993e+03,
              6.78915000000000000e+04 6.36741000000000058e+04 1.28649880000000007e+03,
              7.28915000000000000e+04 6.36741000000000058e+04 1.27702260000000001e+03,
              7.78915000000000000e+04 6.36741000000000058e+04 1.26264239999999995e+03,
              8.28915000000000000e+04 6.36741000000000058e+04 1.27110709999999995e+03,
              8.78915000000000000e+04 6.36741000000000058e+04 1.19457220000000007e+03,
              4.48415000000000000e+04 6.36241000000000058e+04 1.35590309999999999e+03,
              4.98415000000000000e+04 6.36241000000000058e+04 1.34886810000000014e+03,
              5.48415000000000000e+04 6.36241000000000058e+04 1.35873860000000013e+03,
              5.98415000000000000e+04 6.36241000000000058e+04 1.37579750000000013e+03,
              6.48415000000000000e+04 6.36241000000000058e+04 1.29700330000000008e+03,
              6.98415000000000000e+04 6.36241000000000058e+04 1.30814370000000008e+03,
              7.48415000000000000e+04 6.36241000000000058e+04 1.27768399999999997e+03,
              7.98415000000000000e+04 6.36241000000000058e+04 1.24950729999999999e+03,
              8.48415000000000000e+04 6.36241000000000058e+04 1.24350180000000000e+03,
              8.98415000000000000e+04 6.36241000000000058e+04 1.23599530000000004e+03,
              4.67915000000000000e+04 6.35741000000000058e+04 1.34990000000000009e+03,
              5.17915000000000000e+04 6.35741000000000058e+04 1.36473710000000005e+03,
              5.67915000000000000e+04 6.35741000000000058e+04 1.41591489999999999e+03,
              6.17915000000000000e+04 6.35741000000000058e+04 1.35522240000000011e+03,
              6.67915000000000000e+04 6.35741000000000058e+04 1.28557690000000002e+03,
              7.17915000000000000e+04 6.35741000000000058e+04 1.27452199999999993e+03,
              7.67915000000000000e+04 6.35741000000000058e+04 1.30771990000000005e+03,
              8.17915000000000000e+04 6.35741000000000058e+04 1.25329109999999991e+03,
              8.67915000000000000e+04 6.35741000000000058e+04 1.22325309999999990e+03,
              4.37415000000000000e+04 6.35241000000000058e+04 1.34626409999999987e+03,
              4.87415000000000000e+04 6.35241000000000058e+04 1.36521059999999989e+03,
              5.37415000000000000e+04 6.35241000000000058e+04 1.39216059999999993e+03,
              5.87415000000000000e+04 6.35241000000000058e+04 1.41680439999999999e+03,
              6.37415000000000000e+04 6.35241000000000058e+04 1.30482600000000002e+03,
              6.87415000000000000e+04 6.35241000000000058e+04 1.29609300000000007e+03,
              7.37415000000000000e+04 6.35241000000000058e+04 1.28768460000000005e+03,
              7.87415000000000000e+04 6.35241000000000058e+04 1.25900790000000006e+03,
              8.37415000000000000e+04 6.35241000000000058e+04 1.26547100000000000e+03,
              8.87415000000000000e+04 6.35241000000000058e+04 1.20378770000000009e+03,
              4.56915000000000000e+04 6.34741000000000058e+04 1.36296379999999999e+03,
              5.06915000000000000e+04 6.34741000000000058e+04 1.34344029999999998e+03,
              5.56915000000000000e+04 6.34741000000000058e+04 1.40948500000000013e+03,
              6.06915000000000000e+04 6.34741000000000058e+04 1.34144949999999994e+03,
              6.56915000000000000e+04 6.34741000000000058e+04 1.29368450000000007e+03,
              7.06915000000000000e+04 6.34741000000000058e+04 1.29141550000000007e+03,
              7.56915000000000000e+04 6.34741000000000058e+04 1.28307290000000012e+03,
              8.06915000000000000e+04 6.34741000000000058e+04 1.25481670000000008e+03,
              8.56915000000000000e+04 6.34741000000000058e+04 1.22741789999999992e+03,
              9.06915000000000000e+04 6.34741000000000058e+04 1.26141930000000002e+03,
              4.76415000000000000e+04 6.34241000000000058e+04 1.34481169999999997e+03,
              5.26415000000000000e+04 6.34241000000000058e+04 1.36230340000000001e+03,
              5.76415000000000000e+04 6.34241000000000058e+04 1.37397990000000004e+03,
              6.26415000000000000e+04 6.34241000000000058e+04 1.31590129999999999e+03,
              6.76415000000000000e+04 6.34241000000000058e+04 1.29258439999999996e+03,
              7.26415000000000000e+04 6.34241000000000058e+04 1.27951970000000006e+03,
              7.76415000000000000e+04 6.34241000000000058e+04 1.28255400000000009e+03,
              8.26415000000000000e+04 6.34241000000000058e+04 1.26452449999999999e+03,
              8.76415000000000000e+04 6.34241000000000058e+04 1.19450009999999997e+03,
              4.45915000000000000e+04 6.33741000000000058e+04 1.34295290000000000e+03,
              4.95915000000000000e+04 6.33741000000000058e+04 1.29678559999999993e+03,
              5.45915000000000000e+04 6.33741000000000058e+04 1.29176340000000005e+03,
              5.95915000000000000e+04 6.33741000000000058e+04 1.41276859999999988e+03,
              6.45915000000000000e+04 6.33741000000000058e+04 1.30853379999999993e+03,
              6.95915000000000000e+04 6.33741000000000058e+04 1.31997260000000006e+03,
              7.45915000000000000e+04 6.33741000000000058e+04 1.28069319999999993e+03,
              7.95915000000000000e+04 6.33741000000000058e+04 1.26001990000000001e+03,
              8.45915000000000000e+04 6.33741000000000058e+04 1.24043249999999989e+03,
              8.95915000000000000e+04 6.33741000000000058e+04 1.23598209999999995e+03,
              4.65415000000000000e+04 6.33241000000000058e+04 1.33863609999999994e+03,
              5.15415000000000000e+04 6.33241000000000058e+04 1.36683709999999996e+03,
              5.65415000000000000e+04 6.33241000000000058e+04 1.41488490000000002e+03,
              6.15415000000000000e+04 6.33241000000000058e+04 1.40107740000000013e+03,
              6.65415000000000000e+04 6.33241000000000058e+04 1.28909590000000003e+03,
              7.15415000000000000e+04 6.33241000000000058e+04 1.27694100000000003e+03,
              7.65415000000000000e+04 6.33241000000000058e+04 1.29690580000000000e+03,
              8.15415000000000000e+04 6.33241000000000058e+04 1.25505040000000008e+03,
              8.65415000000000000e+04 6.33241000000000058e+04 1.23077999999999997e+03,
              4.34915000000000000e+04 6.32741000000000058e+04 1.33791800000000012e+03,
              4.84915000000000000e+04 6.32741000000000058e+04 1.36261869999999999e+03,
              5.34915000000000000e+04 6.32741000000000058e+04 1.39040400000000000e+03,
              5.84915000000000000e+04 6.32741000000000058e+04 1.42337040000000002e+03,
              6.34915000000000000e+04 6.32741000000000058e+04 1.30754790000000003e+03,
              6.84915000000000000e+04 6.32741000000000058e+04 1.32818090000000007e+03,
              7.34915000000000000e+04 6.32741000000000058e+04 1.28815930000000003e+03,
              7.84915000000000000e+04 6.32741000000000058e+04 1.26658640000000014e+03,
              8.34915000000000000e+04 6.32741000000000058e+04 1.26132140000000004e+03,
              8.84915000000000000e+04 6.32741000000000058e+04 1.19637410000000000e+03,
              4.54415000000000000e+04 6.32241000000000058e+04 1.35694309999999996e+03,
              5.04415000000000000e+04 6.32241000000000058e+04 1.30971640000000002e+03,
              5.54415000000000000e+04 6.32241000000000058e+04 1.37987920000000008e+03,
              6.04415000000000000e+04 6.32241000000000058e+04 1.36241159999999991e+03,
              6.54415000000000000e+04 6.32241000000000058e+04 1.30548700000000008e+03,
              7.04415000000000000e+04 6.32241000000000058e+04 1.31929489999999987e+03,
              7.54415000000000000e+04 6.32241000000000058e+04 1.27781520000000000e+03,
              8.04415000000000000e+04 6.32241000000000058e+04 1.25376569999999992e+03,
              8.54415000000000000e+04 6.32241000000000058e+04 1.22774289999999996e+03,
              9.04415000000000000e+04 6.32241000000000058e+04 1.24752389999999991e+03,
              4.73915000000000000e+04 6.31741000000000058e+04 1.33425599999999986e+03,
              5.23915000000000000e+04 6.31741000000000058e+04 1.37577210000000014e+03,
              5.73915000000000000e+04 6.31741000000000058e+04 1.41716930000000002e+03,
              6.23915000000000000e+04 6.31741000000000058e+04 1.33125170000000003e+03,
              6.73915000000000000e+04 6.31741000000000058e+04 1.29902890000000002e+03,
              7.23915000000000000e+04 6.31741000000000058e+04 1.28794029999999998e+03,
              7.73915000000000000e+04 6.31741000000000058e+04 1.28302710000000002e+03,
              8.23915000000000000e+04 6.31741000000000058e+04 1.24776810000000000e+03,
              8.73915000000000000e+04 6.31741000000000058e+04 1.20818959999999993e+03,
              4.43415000000000000e+04 6.31241000000000058e+04 1.33372450000000003e+03,
              4.93415000000000000e+04 6.31241000000000058e+04 1.30241350000000011e+03,
              5.43415000000000000e+04 6.31241000000000058e+04 1.38546119999999996e+03,
              5.93415000000000000e+04 6.31241000000000058e+04 1.40449369999999999e+03,
              6.43415000000000000e+04 6.31241000000000058e+04 1.31455749999999989e+03,
              6.93415000000000000e+04 6.31241000000000058e+04 1.31640380000000005e+03,
              7.43415000000000000e+04 6.31241000000000058e+04 1.28627219999999988e+03,
              7.93415000000000000e+04 6.31241000000000058e+04 1.26893660000000000e+03,
              8.43415000000000000e+04 6.31241000000000058e+04 1.24960509999999999e+03,
              8.93415000000000000e+04 6.31241000000000058e+04 1.24142300000000000e+03,
              4.62915000000000000e+04 6.30741000000000058e+04 1.32912310000000002e+03,
              5.12915000000000000e+04 6.30741000000000058e+04 1.36408950000000004e+03,
              5.62915000000000000e+04 6.30741000000000058e+04 1.41773170000000005e+03,
              6.12915000000000000e+04 6.30741000000000058e+04 1.38829500000000007e+03,
              6.62915000000000000e+04 6.30741000000000058e+04 1.29211680000000001e+03,
              7.12915000000000000e+04 6.30741000000000058e+04 1.27925340000000006e+03,
              7.62915000000000000e+04 6.30741000000000058e+04 1.29000090000000000e+03,
              8.12915000000000000e+04 6.30741000000000058e+04 1.25800489999999991e+03,
              8.62915000000000000e+04 6.30741000000000058e+04 1.23102340000000004e+03,
              4.32415000000000000e+04 6.30241000000000058e+04 1.33028140000000008e+03,
              4.82415000000000000e+04 6.30241000000000058e+04 1.35066930000000002e+03,
              5.32415000000000000e+04 6.30241000000000058e+04 1.37493049999999994e+03,
              5.82415000000000000e+04 6.30241000000000058e+04 1.36072990000000004e+03,
              6.32415000000000000e+04 6.30241000000000058e+04 1.31465249999999992e+03,
              6.82415000000000000e+04 6.30241000000000058e+04 1.36327039999999988e+03,
              7.32415000000000000e+04 6.30241000000000058e+04 1.28825090000000000e+03,
              7.82415000000000000e+04 6.30241000000000058e+04 1.26464560000000006e+03,
              8.32415000000000000e+04 6.30241000000000058e+04 1.23912680000000000e+03,
              8.82415000000000000e+04 6.30241000000000058e+04 1.19382449999999994e+03,
              4.51915000000000000e+04 6.29741000000000058e+04 1.34775479999999993e+03,
              5.01915000000000000e+04 6.29741000000000058e+04 1.25416769999999997e+03,
              5.51915000000000000e+04 6.29741000000000058e+04 1.38968769999999995e+03,
              6.01915000000000000e+04 6.29741000000000058e+04 1.41221640000000002e+03,
              6.51915000000000000e+04 6.29741000000000058e+04 1.30995429999999988e+03,
              7.01915000000000000e+04 6.29741000000000058e+04 1.36667799999999988e+03,
              7.51915000000000000e+04 6.29741000000000058e+04 1.27874240000000009e+03,
              8.01915000000000000e+04 6.29741000000000058e+04 1.25697569999999996e+03,
              8.51915000000000000e+04 6.29741000000000058e+04 1.24191720000000009e+03,
              9.01915000000000000e+04 6.29741000000000058e+04 1.23419589999999994e+03,
              4.71415000000000000e+04 6.29241000000000058e+04 1.34228880000000004e+03,
              5.21415000000000000e+04 6.29241000000000058e+04 1.37097430000000008e+03,
              5.71415000000000000e+04 6.29241000000000058e+04 1.39655299999999988e+03,
              6.21415000000000000e+04 6.29241000000000058e+04 1.34689580000000001e+03,
              6.71415000000000000e+04 6.29241000000000058e+04 1.28755439999999999e+03,
              7.21415000000000000e+04 6.29241000000000058e+04 1.28054980000000000e+03,
              7.71415000000000000e+04 6.29241000000000058e+04 1.29426749999999993e+03,
              8.21415000000000000e+04 6.29241000000000058e+04 1.25220299999999997e+03,
              8.71415000000000000e+04 6.29241000000000058e+04 1.23687349999999992e+03,
              4.40915000000000000e+04 6.28741000000000058e+04 1.33609920000000011e+03,
              4.90915000000000000e+04 6.28741000000000058e+04 1.32456130000000007e+03,
              5.40915000000000000e+04 6.28741000000000058e+04 1.38079899999999998e+03,
              5.90915000000000000e+04 6.28741000000000058e+04 1.39541660000000002e+03,
              6.40915000000000000e+04 6.28741000000000058e+04 1.30890830000000005e+03,
              6.90915000000000000e+04 6.28741000000000058e+04 1.34474800000000005e+03,
              7.40915000000000000e+04 6.28741000000000058e+04 1.28689640000000009e+03,
              7.90915000000000000e+04 6.28741000000000058e+04 1.27323460000000000e+03,
              8.40915000000000000e+04 6.28741000000000058e+04 1.23943350000000009e+03,
              8.90915000000000000e+04 6.28741000000000058e+04 1.22730680000000007e+03,
              4.60415000000000000e+04 6.28241000000000058e+04 1.32787159999999994e+03,
              5.10415000000000000e+04 6.28241000000000058e+04 1.28609169999999995e+03,
              5.60415000000000000e+04 6.28241000000000058e+04 1.40750180000000000e+03,
              6.10415000000000000e+04 6.28241000000000058e+04 1.39332110000000011e+03,
              6.60415000000000000e+04 6.28241000000000058e+04 1.29711500000000001e+03,
              7.10415000000000000e+04 6.28241000000000058e+04 1.28452210000000014e+03,
              7.60415000000000000e+04 6.28241000000000058e+04 1.28209809999999993e+03,
              8.10415000000000000e+04 6.28241000000000058e+04 1.26401029999999992e+03,
              8.60415000000000000e+04 6.28241000000000058e+04 1.23575960000000009e+03,
              4.29915000000000000e+04 6.27741000000000058e+04 1.31655089999999996e+03,
              4.79915000000000000e+04 6.27741000000000058e+04 1.33477510000000007e+03,
              5.29915000000000000e+04 6.27741000000000058e+04 1.37111660000000006e+03,
              5.79915000000000000e+04 6.27741000000000058e+04 1.38909999999999991e+03,
              6.29915000000000000e+04 6.27741000000000058e+04 1.33137799999999993e+03,
              6.79915000000000000e+04 6.27741000000000058e+04 1.35051099999999997e+03,
              7.29915000000000000e+04 6.27741000000000058e+04 1.29605610000000001e+03,
              7.79915000000000000e+04 6.27741000000000058e+04 1.28525000000000000e+03,
              8.29915000000000000e+04 6.27741000000000058e+04 1.23685069999999996e+03,
              8.79915000000000000e+04 6.27741000000000058e+04 1.19317630000000008e+03,
              4.49415000000000000e+04 6.27241000000000058e+04 1.35227060000000006e+03,
              4.99415000000000000e+04 6.27241000000000058e+04 1.27735760000000005e+03,
              5.49415000000000000e+04 6.27241000000000058e+04 1.31551520000000005e+03,
              5.99415000000000000e+04 6.27241000000000058e+04 1.38068990000000008e+03,
              6.49415000000000000e+04 6.27241000000000058e+04 1.31192219999999998e+03,
              6.99415000000000000e+04 6.27241000000000058e+04 1.32988189999999986e+03,
              7.49415000000000000e+04 6.27241000000000058e+04 1.27831150000000002e+03,
              7.99415000000000000e+04 6.27241000000000058e+04 1.25997759999999994e+03,
              8.49415000000000000e+04 6.27241000000000058e+04 1.26170060000000012e+03,
              8.99415000000000000e+04 6.27241000000000058e+04 1.22183699999999999e+03,
              4.68915000000000000e+04 6.26741000000000058e+04 1.33065270000000010e+03,
              5.18915000000000000e+04 6.26741000000000058e+04 1.36573329999999987e+03,
              5.68915000000000000e+04 6.26741000000000058e+04 1.38850900000000001e+03,
              6.18915000000000000e+04 6.26741000000000058e+04 1.33872820000000002e+03,
              6.68915000000000000e+04 6.26741000000000058e+04 1.29181750000000011e+03,
              7.18915000000000000e+04 6.26741000000000058e+04 1.29189650000000006e+03,
              7.68915000000000000e+04 6.26741000000000058e+04 1.29259480000000008e+03,
              8.18915000000000000e+04 6.26741000000000058e+04 1.24569749999999999e+03,
              8.68915000000000000e+04 6.26741000000000058e+04 1.23813560000000007e+03,
              4.38415000000000000e+04 6.26241000000000058e+04 1.33789620000000014e+03,
              4.88415000000000000e+04 6.26241000000000058e+04 1.31330479999999989e+03,
              5.38415000000000000e+04 6.26241000000000058e+04 1.37064310000000000e+03,
              5.88415000000000000e+04 6.26241000000000058e+04 1.35486169999999993e+03,
              6.38415000000000000e+04 6.26241000000000058e+04 1.30853299999999990e+03,
              6.88415000000000000e+04 6.26241000000000058e+04 1.38276580000000013e+03,
              7.38415000000000000e+04 6.26241000000000058e+04 1.29713000000000011e+03,
              7.88415000000000000e+04 6.26241000000000058e+04 1.26801900000000001e+03,
              8.38415000000000000e+04 6.26241000000000058e+04 1.22230999999999995e+03,
              8.88415000000000000e+04 6.26241000000000058e+04 1.21055719999999997e+03,
              4.57915000000000000e+04 6.25741000000000058e+04 1.32380369999999994e+03,
              5.07915000000000000e+04 6.25741000000000058e+04 1.35678479999999990e+03,
              5.57915000000000000e+04 6.25741000000000058e+04 1.40001379999999995e+03,
              6.07915000000000000e+04 6.25741000000000058e+04 1.39148880000000008e+03,
              6.57915000000000000e+04 6.25741000000000058e+04 1.30095409999999993e+03,
              7.07915000000000000e+04 6.25741000000000058e+04 1.29091959999999995e+03,
              7.57915000000000000e+04 6.25741000000000058e+04 1.27803029999999990e+03,
              8.07915000000000000e+04 6.25741000000000058e+04 1.26719540000000006e+03,
              8.57915000000000000e+04 6.25741000000000058e+04 1.23844319999999993e+03,
              9.07915000000000000e+04 6.25741000000000058e+04 1.23966280000000006e+03,
              4.77415000000000000e+04 6.25241000000000058e+04 1.32326310000000012e+03,
              5.27415000000000000e+04 6.25241000000000058e+04 1.36600360000000001e+03,
              5.77415000000000000e+04 6.25241000000000058e+04 1.40367439999999988e+03,
              6.27415000000000000e+04 6.25241000000000058e+04 1.34508460000000014e+03,
              6.77415000000000000e+04 6.25241000000000058e+04 1.34387190000000010e+03,
              7.27415000000000000e+04 6.25241000000000058e+04 1.29815589999999997e+03,
              7.77415000000000000e+04 6.25241000000000058e+04 1.30156610000000001e+03,
              8.27415000000000000e+04 6.25241000000000058e+04 1.23639899999999989e+03,
              8.77415000000000000e+04 6.25241000000000058e+04 1.19962269999999990e+03,
              4.46915000000000000e+04 6.24741000000000058e+04 1.33865689999999995e+03,
              4.96915000000000000e+04 6.24741000000000058e+04 1.31107610000000000e+03,
              5.46915000000000000e+04 6.24741000000000058e+04 1.33134729999999990e+03,
              5.96915000000000000e+04 6.24741000000000058e+04 1.37698730000000000e+03,
              6.46915000000000000e+04 6.24741000000000058e+04 1.30170740000000001e+03,
              6.96915000000000000e+04 6.24741000000000058e+04 1.34363519999999994e+03,
              7.46915000000000000e+04 6.24741000000000058e+04 1.27769090000000006e+03,
              7.96915000000000000e+04 6.24741000000000058e+04 1.26518080000000009e+03,
              8.46915000000000000e+04 6.24741000000000058e+04 1.23885680000000002e+03,
              8.96915000000000000e+04 6.24741000000000058e+04 1.20829150000000004e+03,
              4.66415000000000000e+04 6.24241000000000058e+04 1.32806160000000000e+03,
              5.16415000000000000e+04 6.24241000000000058e+04 1.36088609999999994e+03,
              5.66415000000000000e+04 6.24241000000000058e+04 1.39108559999999989e+03,
              6.16415000000000000e+04 6.24241000000000058e+04 1.33264800000000014e+03,
              6.66415000000000000e+04 6.24241000000000058e+04 1.29528080000000000e+03,
              7.16415000000000000e+04 6.24241000000000058e+04 1.28858159999999998e+03,
              7.66415000000000000e+04 6.24241000000000058e+04 1.28595650000000001e+03,
              8.16415000000000000e+04 6.24241000000000058e+04 1.25428220000000010e+03,
              8.66415000000000000e+04 6.24241000000000058e+04 1.23439719999999988e+03,
              4.35915000000000000e+04 6.23741000000000058e+04 1.34625289999999995e+03,
              4.85915000000000000e+04 6.23741000000000058e+04 1.30720980000000009e+03,
              5.35915000000000000e+04 6.23741000000000058e+04 1.36944170000000008e+03,
              5.85915000000000000e+04 6.23741000000000058e+04 1.38701389999999992e+03,
              6.35915000000000000e+04 6.23741000000000058e+04 1.31239179999999988e+03,
              6.85915000000000000e+04 6.23741000000000058e+04 1.36944740000000002e+03,
              7.35915000000000000e+04 6.23741000000000058e+04 1.29561819999999989e+03,
              7.85915000000000000e+04 6.23741000000000058e+04 1.27486670000000004e+03,
              8.35915000000000000e+04 6.23741000000000058e+04 1.23353659999999991e+03,
              8.85915000000000000e+04 6.23741000000000058e+04 1.19330709999999999e+03,
              4.55415000000000000e+04 6.23241000000000058e+04 1.32648419999999987e+03,
              5.05415000000000000e+04 6.23241000000000058e+04 1.36440249999999992e+03,
              5.55415000000000000e+04 6.23241000000000058e+04 1.38771219999999994e+03,
              6.05415000000000000e+04 6.23241000000000058e+04 1.39067070000000012e+03,
              6.55415000000000000e+04 6.23241000000000058e+04 1.30004289999999992e+03,
              7.05415000000000000e+04 6.23241000000000058e+04 1.28886369999999988e+03,
              7.55415000000000000e+04 6.23241000000000058e+04 1.28294460000000004e+03,
              8.05415000000000000e+04 6.23241000000000058e+04 1.25040210000000002e+03,
              8.55415000000000000e+04 6.23241000000000058e+04 1.22718589999999995e+03,
              9.05415000000000000e+04 6.23241000000000058e+04 1.22625180000000000e+03,
              4.74915000000000000e+04 6.22741000000000058e+04 1.29191460000000006e+03,
              5.24915000000000000e+04 6.22741000000000058e+04 1.35748950000000013e+03,
              5.74915000000000000e+04 6.22741000000000058e+04 1.39779660000000013e+03,
              6.24915000000000000e+04 6.22741000000000058e+04 1.31898839999999996e+03,
              6.74915000000000000e+04 6.22741000000000058e+04 1.31772330000000011e+03,
              7.24915000000000000e+04 6.22741000000000058e+04 1.31577179999999998e+03,
              7.74915000000000000e+04 6.22741000000000058e+04 1.30093699999999990e+03,
              8.24915000000000000e+04 6.22741000000000058e+04 1.24435570000000007e+03,
              8.74915000000000000e+04 6.22741000000000058e+04 1.20746839999999997e+03,
              4.44415000000000000e+04 6.22241000000000058e+04 1.32284950000000003e+03,
              4.94415000000000000e+04 6.22241000000000058e+04 1.34142990000000009e+03,
              5.44415000000000000e+04 6.22241000000000058e+04 1.37276739999999995e+03,
              5.94415000000000000e+04 6.22241000000000058e+04 1.40292270000000008e+03,
              6.44415000000000000e+04 6.22241000000000058e+04 1.29730340000000001e+03,
              6.94415000000000000e+04 6.22241000000000058e+04 1.33602549999999997e+03,
              7.44415000000000000e+04 6.22241000000000058e+04 1.28084549999999990e+03,
              7.94415000000000000e+04 6.22241000000000058e+04 1.27256809999999996e+03,
              8.44415000000000000e+04 6.22241000000000058e+04 1.21635329999999999e+03,
              8.94415000000000000e+04 6.22241000000000058e+04 1.20631559999999990e+03,
              4.63915000000000000e+04 6.21741000000000058e+04 1.32218039999999996e+03,
              5.13915000000000000e+04 6.21741000000000058e+04 1.28582749999999987e+03,
              5.63915000000000000e+04 6.21741000000000058e+04 1.36621679999999992e+03,
              6.13915000000000000e+04 6.21741000000000058e+04 1.38670049999999992e+03,
              6.63915000000000000e+04 6.21741000000000058e+04 1.29647530000000006e+03,
              7.13915000000000000e+04 6.21741000000000058e+04 1.28196280000000002e+03,
              7.63915000000000000e+04 6.21741000000000058e+04 1.28380029999999988e+03,
              8.13915000000000000e+04 6.21741000000000058e+04 1.25334940000000006e+03,
              8.63915000000000000e+04 6.21741000000000058e+04 1.25140959999999995e+03,
              4.33415000000000000e+04 6.21241000000000058e+04 1.32851360000000000e+03,
              4.83415000000000000e+04 6.21241000000000058e+04 1.25007369999999992e+03,
              5.33415000000000000e+04 6.21241000000000058e+04 1.37519149999999991e+03,
              5.83415000000000000e+04 6.21241000000000058e+04 1.41113670000000002e+03,
              6.33415000000000000e+04 6.21241000000000058e+04 1.30497049999999990e+03,
              6.83415000000000000e+04 6.21241000000000058e+04 1.37026369999999997e+03,
              7.33415000000000000e+04 6.21241000000000058e+04 1.29977379999999994e+03,
              7.83415000000000000e+04 6.21241000000000058e+04 1.29042280000000005e+03,
              8.33415000000000000e+04 6.21241000000000058e+04 1.23664300000000003e+03,
              8.83415000000000000e+04 6.21241000000000058e+04 1.18918820000000005e+03,
              4.52915000000000000e+04 6.20741000000000058e+04 1.32224360000000001e+03,
              5.02915000000000000e+04 6.20741000000000058e+04 1.35717210000000000e+03,
              5.52915000000000000e+04 6.20741000000000058e+04 1.34955259999999998e+03,
              6.02915000000000000e+04 6.20741000000000058e+04 1.39584629999999993e+03,
              6.52915000000000000e+04 6.20741000000000058e+04 1.30242650000000003e+03,
              7.02915000000000000e+04 6.20741000000000058e+04 1.29490120000000002e+03,
              7.52915000000000000e+04 6.20741000000000058e+04 1.29914149999999995e+03,
              8.02915000000000000e+04 6.20741000000000058e+04 1.25487580000000003e+03,
              8.52915000000000000e+04 6.20741000000000058e+04 1.21515779999999995e+03,
              9.02915000000000000e+04 6.20741000000000058e+04 1.22043859999999995e+03,
              4.72415000000000000e+04 6.20241000000000058e+04 1.24940920000000006e+03,
              5.22415000000000000e+04 6.20241000000000058e+04 1.32751499999999987e+03,
              5.72415000000000000e+04 6.20241000000000058e+04 1.40222440000000006e+03,
              6.22415000000000000e+04 6.20241000000000058e+04 1.32948029999999994e+03,
              6.72415000000000000e+04 6.20241000000000058e+04 1.33641800000000012e+03,
              7.22415000000000000e+04 6.20241000000000058e+04 1.36129759999999987e+03,
              7.72415000000000000e+04 6.20241000000000058e+04 1.29632560000000012e+03,
              8.22415000000000000e+04 6.20241000000000058e+04 1.25223099999999999e+03,
              8.72415000000000000e+04 6.20241000000000058e+04 1.21178420000000006e+03,
              4.41915000000000000e+04 6.19741000000000058e+04 1.32954690000000005e+03,
              4.91915000000000000e+04 6.19741000000000058e+04 1.33055400000000009e+03,
              5.41915000000000000e+04 6.19741000000000058e+04 1.37722990000000004e+03,
              5.91915000000000000e+04 6.19741000000000058e+04 1.41689400000000001e+03,
              6.41915000000000000e+04 6.19741000000000058e+04 1.30079030000000012e+03,
              6.91915000000000000e+04 6.19741000000000058e+04 1.39982490000000007e+03,
              7.41915000000000000e+04 6.19741000000000058e+04 1.28792329999999993e+03,
              7.91915000000000000e+04 6.19741000000000058e+04 1.29181230000000005e+03,
              8.41915000000000000e+04 6.19741000000000058e+04 1.22770280000000002e+03,
              8.91915000000000000e+04 6.19741000000000058e+04 1.20221270000000004e+03,
              4.61415000000000000e+04 6.19241000000000058e+04 1.30182670000000007e+03,
              5.11415000000000000e+04 6.19241000000000058e+04 1.35605719999999997e+03,
              5.61415000000000000e+04 6.19241000000000058e+04 1.33002870000000007e+03,
              6.11415000000000000e+04 6.19241000000000058e+04 1.38312590000000000e+03,
              6.61415000000000000e+04 6.19241000000000058e+04 1.29511180000000013e+03,
              7.11415000000000000e+04 6.19241000000000058e+04 1.27944820000000004e+03,
              7.61415000000000000e+04 6.19241000000000058e+04 1.28280790000000002e+03,
              8.11415000000000000e+04 6.19241000000000058e+04 1.23902279999999996e+03,
              8.61415000000000000e+04 6.19241000000000058e+04 1.25343329999999992e+03,
              4.30915000000000000e+04 6.18741000000000058e+04 1.32259300000000007e+03,
              4.80915000000000000e+04 6.18741000000000058e+04 1.28097960000000012e+03,
              5.30915000000000000e+04 6.18741000000000058e+04 1.36225739999999996e+03,
              5.80915000000000000e+04 6.18741000000000058e+04 1.41141399999999999e+03,
              6.30915000000000000e+04 6.18741000000000058e+04 1.31461999999999989e+03,
              6.80915000000000000e+04 6.18741000000000058e+04 1.36182029999999986e+03,
              7.30915000000000000e+04 6.18741000000000058e+04 1.30418360000000007e+03,
              7.80915000000000000e+04 6.18741000000000058e+04 1.27948379999999997e+03,
              8.30915000000000000e+04 6.18741000000000058e+04 1.24560940000000005e+03,
              8.80915000000000000e+04 6.18741000000000058e+04 1.20617679999999996e+03,
              4.50415000000000000e+04 6.18241000000000058e+04 1.32163540000000012e+03,
              5.00415000000000000e+04 6.18241000000000058e+04 1.35638900000000012e+03,
              5.50415000000000000e+04 6.18241000000000058e+04 1.35789879999999994e+03,
              6.00415000000000000e+04 6.18241000000000058e+04 1.41320049999999992e+03,
              6.50415000000000000e+04 6.18241000000000058e+04 1.30092790000000014e+03,
              7.00415000000000000e+04 6.18241000000000058e+04 1.30660349999999994e+03,
              7.50415000000000000e+04 6.18241000000000058e+04 1.29244020000000000e+03,
              8.00415000000000000e+04 6.18241000000000058e+04 1.24868069999999989e+03,
              8.50415000000000000e+04 6.18241000000000058e+04 1.22247630000000004e+03,
              9.00415000000000000e+04 6.18241000000000058e+04 1.21288660000000004e+03,
              4.69915000000000000e+04 6.17741000000000058e+04 1.24404070000000002e+03,
              5.19915000000000000e+04 6.17741000000000058e+04 1.36183580000000006e+03,
              5.69915000000000000e+04 6.17741000000000058e+04 1.40827659999999992e+03,
              6.19915000000000000e+04 6.17741000000000058e+04 1.39051420000000007e+03,
              6.69915000000000000e+04 6.17741000000000058e+04 1.35336140000000000e+03,
              7.19915000000000000e+04 6.17741000000000058e+04 1.35788020000000006e+03,
              7.69915000000000000e+04 6.17741000000000058e+04 1.31663670000000002e+03,
              8.19915000000000000e+04 6.17741000000000058e+04 1.24443360000000007e+03,
              8.69915000000000000e+04 6.17741000000000058e+04 1.19052389999999991e+03,
              4.39415000000000000e+04 6.17241000000000058e+04 1.33329459999999995e+03,
              4.89415000000000000e+04 6.17241000000000058e+04 1.32498739999999998e+03,
              5.39415000000000000e+04 6.17241000000000058e+04 1.38568699999999990e+03,
              5.89415000000000000e+04 6.17241000000000058e+04 1.42266640000000007e+03,
              6.39415000000000000e+04 6.17241000000000058e+04 1.30974319999999989e+03,
              6.89415000000000000e+04 6.17241000000000058e+04 1.34045620000000008e+03,
              7.39415000000000000e+04 6.17241000000000058e+04 1.28873599999999988e+03,
              7.89415000000000000e+04 6.17241000000000058e+04 1.27999310000000014e+03,
              8.39415000000000000e+04 6.17241000000000058e+04 1.21278970000000004e+03,
              8.89415000000000000e+04 6.17241000000000058e+04 1.19404359999999997e+03,
              4.58915000000000000e+04 6.16741000000000058e+04 1.25303449999999998e+03,
              5.08915000000000000e+04 6.16741000000000058e+04 1.36236750000000006e+03,
              5.58915000000000000e+04 6.16741000000000058e+04 1.33344129999999996e+03,
              6.08915000000000000e+04 6.16741000000000058e+04 1.42142239999999993e+03,
              6.58915000000000000e+04 6.16741000000000058e+04 1.29686369999999988e+03,
              7.08915000000000000e+04 6.16741000000000058e+04 1.28384230000000002e+03,
              7.58915000000000000e+04 6.16741000000000058e+04 1.28874679999999989e+03,
              8.08915000000000000e+04 6.16741000000000058e+04 1.23573540000000003e+03,
              8.58915000000000000e+04 6.16741000000000058e+04 1.24043840000000000e+03,
              9.08915000000000000e+04 6.16741000000000058e+04 1.22671569999999997e+03,
              4.78415000000000000e+04 6.16241000000000058e+04 1.24978299999999990e+03,
              5.28415000000000000e+04 6.16241000000000058e+04 1.36874929999999995e+03,
              5.78415000000000000e+04 6.16241000000000058e+04 1.40706430000000000e+03,
              6.28415000000000000e+04 6.16241000000000058e+04 1.32118879999999990e+03,
              6.78415000000000000e+04 6.16241000000000058e+04 1.34010019999999986e+03,
              7.28415000000000000e+04 6.16241000000000058e+04 1.31644669999999996e+03,
              7.78415000000000000e+04 6.16241000000000058e+04 1.29191110000000003e+03,
              8.28415000000000000e+04 6.16241000000000058e+04 1.23710609999999997e+03,
              8.78415000000000000e+04 6.16241000000000058e+04 1.18561830000000009e+03,
              4.47915000000000000e+04 6.15741000000000058e+04 1.30336670000000004e+03,
              4.97915000000000000e+04 6.15741000000000058e+04 1.35591660000000002e+03,
              5.47915000000000000e+04 6.15741000000000058e+04 1.36779750000000013e+03,
              5.97915000000000000e+04 6.15741000000000058e+04 1.40437770000000000e+03,
              6.47915000000000000e+04 6.15741000000000058e+04 1.30802539999999999e+03,
              6.97915000000000000e+04 6.15741000000000058e+04 1.33337599999999998e+03,
              7.47915000000000000e+04 6.15741000000000058e+04 1.28925330000000008e+03,
              7.97915000000000000e+04 6.15741000000000058e+04 1.24795200000000000e+03,
              8.47915000000000000e+04 6.15741000000000058e+04 1.20674690000000010e+03,
              8.97915000000000000e+04 6.15741000000000058e+04 1.19622510000000011e+03,
              4.67415000000000000e+04 6.15241000000000058e+04 1.24855140000000006e+03,
              5.17415000000000000e+04 6.15241000000000058e+04 1.35899620000000004e+03,
              5.67415000000000000e+04 6.15241000000000058e+04 1.36016429999999991e+03,
              6.17415000000000000e+04 6.15241000000000058e+04 1.39706960000000004e+03,
              6.67415000000000000e+04 6.15241000000000058e+04 1.37868879999999990e+03,
              7.17415000000000000e+04 6.15241000000000058e+04 1.30042720000000008e+03,
              7.67415000000000000e+04 6.15241000000000058e+04 1.30854359999999997e+03,
              8.17415000000000000e+04 6.15241000000000058e+04 1.23096790000000010e+03,
              8.67415000000000000e+04 6.15241000000000058e+04 1.18465940000000001e+03,
              4.36915000000000000e+04 6.14741000000000058e+04 1.32904050000000007e+03,
              4.86915000000000000e+04 6.14741000000000058e+04 1.33203019999999992e+03,
              5.36915000000000000e+04 6.14741000000000058e+04 1.38470540000000005e+03,
              5.86915000000000000e+04 6.14741000000000058e+04 1.41410059999999999e+03,
              6.36915000000000000e+04 6.14741000000000058e+04 1.32486270000000013e+03,
              6.86915000000000000e+04 6.14741000000000058e+04 1.35539269999999988e+03,
              7.36915000000000000e+04 6.14741000000000058e+04 1.30671920000000000e+03,
              7.86915000000000000e+04 6.14741000000000058e+04 1.26687449999999990e+03,
              8.36915000000000000e+04 6.14741000000000058e+04 1.22597949999999992e+03,
              8.86915000000000000e+04 6.14741000000000058e+04 1.18444470000000001e+03,
              4.56415000000000000e+04 6.14241000000000058e+04 1.25341429999999991e+03,
              5.06415000000000000e+04 6.14241000000000058e+04 1.35806860000000006e+03,
              5.56415000000000000e+04 6.14241000000000058e+04 1.37664940000000001e+03,
              6.06415000000000000e+04 6.14241000000000058e+04 1.42925729999999999e+03,
              6.56415000000000000e+04 6.14241000000000058e+04 1.29780529999999999e+03,
              7.06415000000000000e+04 6.14241000000000058e+04 1.29481220000000008e+03,
              7.56415000000000000e+04 6.14241000000000058e+04 1.29426330000000007e+03,
              8.06415000000000000e+04 6.14241000000000058e+04 1.23816980000000012e+03,
              8.56415000000000000e+04 6.14241000000000058e+04 1.21591180000000008e+03,
              9.06415000000000000e+04 6.14241000000000058e+04 1.21772530000000006e+03,
              4.75915000000000000e+04 6.13741000000000058e+04 1.30587200000000007e+03,
              5.25915000000000000e+04 6.13741000000000058e+04 1.36817399999999998e+03,
              5.75915000000000000e+04 6.13741000000000058e+04 1.40816059999999993e+03,
              6.25915000000000000e+04 6.13741000000000058e+04 1.37037670000000003e+03,
              6.75915000000000000e+04 6.13741000000000058e+04 1.32867880000000014e+03,
              7.25915000000000000e+04 6.13741000000000058e+04 1.37824859999999990e+03,
              7.75915000000000000e+04 6.13741000000000058e+04 1.31714789999999994e+03,
              8.25915000000000000e+04 6.13741000000000058e+04 1.23092270000000008e+03,
              8.75915000000000000e+04 6.13741000000000058e+04 1.17647620000000006e+03,
              4.45415000000000000e+04 6.13241000000000058e+04 1.30368029999999999e+03,
              4.95415000000000000e+04 6.13241000000000058e+04 1.35048160000000007e+03,
              5.45415000000000000e+04 6.13241000000000058e+04 1.38287560000000008e+03,
              5.95415000000000000e+04 6.13241000000000058e+04 1.41786660000000006e+03,
              6.45415000000000000e+04 6.13241000000000058e+04 1.31681819999999993e+03,
              6.95415000000000000e+04 6.13241000000000058e+04 1.30469880000000012e+03,
              7.45415000000000000e+04 6.13241000000000058e+04 1.28606680000000006e+03,
              7.95415000000000000e+04 6.13241000000000058e+04 1.24921389999999997e+03,
              8.45415000000000000e+04 6.13241000000000058e+04 1.20837910000000011e+03,
              8.95415000000000000e+04 6.13241000000000058e+04 1.17211529999999993e+03,
              4.64915000000000000e+04 6.12741000000000058e+04 1.24604240000000004e+03,
              5.14915000000000000e+04 6.12741000000000058e+04 1.35459729999999990e+03,
              5.64915000000000000e+04 6.12741000000000058e+04 1.38555060000000003e+03,
              6.14915000000000000e+04 6.12741000000000058e+04 1.37014449999999988e+03,
              6.64915000000000000e+04 6.12741000000000058e+04 1.33148749999999995e+03,
              7.14915000000000000e+04 6.12741000000000058e+04 1.29544790000000012e+03,
              7.64915000000000000e+04 6.12741000000000058e+04 1.29443280000000004e+03,
              8.14915000000000000e+04 6.12741000000000058e+04 1.23788419999999996e+03,
              8.64915000000000000e+04 6.12741000000000058e+04 1.18053209999999990e+03,
              4.34415000000000000e+04 6.12241000000000058e+04 1.32204770000000008e+03,
              4.84415000000000000e+04 6.12241000000000058e+04 1.33384449999999993e+03,
              5.34415000000000000e+04 6.12241000000000058e+04 1.37667750000000001e+03,
              5.84415000000000000e+04 6.12241000000000058e+04 1.40618090000000007e+03,
              6.34415000000000000e+04 6.12241000000000058e+04 1.37828080000000000e+03,
              6.84415000000000000e+04 6.12241000000000058e+04 1.37340580000000000e+03,
              7.34415000000000000e+04 6.12241000000000058e+04 1.31466380000000004e+03,
              7.84415000000000000e+04 6.12241000000000058e+04 1.27317859999999996e+03,
              8.34415000000000000e+04 6.12241000000000058e+04 1.22925180000000000e+03,
              8.84415000000000000e+04 6.12241000000000058e+04 1.19694720000000007e+03,
              4.53915000000000000e+04 6.11741000000000058e+04 1.30954320000000007e+03,
              5.03915000000000000e+04 6.11741000000000058e+04 1.35510140000000001e+03,
              5.53915000000000000e+04 6.11741000000000058e+04 1.37410109999999986e+03,
              6.03915000000000000e+04 6.11741000000000058e+04 1.42321199999999999e+03,
              6.53915000000000000e+04 6.11741000000000058e+04 1.30257310000000007e+03,
              7.03915000000000000e+04 6.11741000000000058e+04 1.28862249999999995e+03,
              7.53915000000000000e+04 6.11741000000000058e+04 1.31920039999999995e+03,
              8.03915000000000000e+04 6.11741000000000058e+04 1.23654480000000012e+03,
              8.53915000000000000e+04 6.11741000000000058e+04 1.21797460000000001e+03,
              9.03915000000000000e+04 6.11741000000000058e+04 1.20926430000000005e+03,
              4.73415000000000000e+04 6.11241000000000058e+04 1.31058690000000001e+03,
              5.23415000000000000e+04 6.11241000000000058e+04 1.36715339999999992e+03,
              5.73415000000000000e+04 6.11241000000000058e+04 1.39483819999999992e+03,
              6.23415000000000000e+04 6.11241000000000058e+04 1.39611810000000014e+03,
              6.73415000000000000e+04 6.11241000000000058e+04 1.35309650000000011e+03,
              7.23415000000000000e+04 6.11241000000000058e+04 1.39991319999999996e+03,
              7.73415000000000000e+04 6.11241000000000058e+04 1.31060259999999994e+03,
              8.23415000000000000e+04 6.11241000000000058e+04 1.23416869999999994e+03,
              8.73415000000000000e+04 6.11241000000000058e+04 1.18469970000000012e+03,
              4.42915000000000000e+04 6.10741000000000058e+04 1.30818440000000010e+03,
              4.92915000000000000e+04 6.10741000000000058e+04 1.34566239999999993e+03,
              5.42915000000000000e+04 6.10741000000000058e+04 1.37787570000000005e+03,
              5.92915000000000000e+04 6.10741000000000058e+04 1.42335069999999996e+03,
              6.42915000000000000e+04 6.10741000000000058e+04 1.35696900000000005e+03,
              6.92915000000000000e+04 6.10741000000000058e+04 1.31022070000000008e+03,
              7.42915000000000000e+04 6.10741000000000058e+04 1.28816110000000003e+03,
              7.92915000000000000e+04 6.10741000000000058e+04 1.25400320000000011e+03,
              8.42915000000000000e+04 6.10741000000000058e+04 1.21965519999999992e+03,
              8.92915000000000000e+04 6.10741000000000058e+04 1.16950849999999991e+03,
              4.62415000000000000e+04 6.10241000000000058e+04 1.25077950000000010e+03,
              5.12415000000000000e+04 6.10241000000000058e+04 1.35820550000000003e+03,
              5.62415000000000000e+04 6.10241000000000058e+04 1.40960539999999992e+03,
              6.12415000000000000e+04 6.10241000000000058e+04 1.42232880000000000e+03,
              6.62415000000000000e+04 6.10241000000000058e+04 1.31284189999999990e+03,
              7.12415000000000000e+04 6.10241000000000058e+04 1.29134809999999993e+03,
              7.62415000000000000e+04 6.10241000000000058e+04 1.28466560000000004e+03,
              8.12415000000000000e+04 6.10241000000000058e+04 1.23951260000000002e+03,
              8.62415000000000000e+04 6.10241000000000058e+04 1.18645350000000008e+03,
              4.31915000000000000e+04 6.09741000000000058e+04 1.29369100000000003e+03,
              4.81915000000000000e+04 6.09741000000000058e+04 1.32758739999999989e+03,
              5.31915000000000000e+04 6.09741000000000058e+04 1.36545710000000008e+03,
              5.81915000000000000e+04 6.09741000000000058e+04 1.36947319999999991e+03,
              6.31915000000000000e+04 6.09741000000000058e+04 1.38053269999999998e+03,
              6.81915000000000000e+04 6.09741000000000058e+04 1.39920110000000000e+03,
              7.31915000000000000e+04 6.09741000000000058e+04 1.31780899999999997e+03,
              7.81915000000000000e+04 6.09741000000000058e+04 1.27035809999999992e+03,
              8.31915000000000000e+04 6.09741000000000058e+04 1.23810590000000002e+03,
              8.81915000000000000e+04 6.09741000000000058e+04 1.18280420000000004e+03,
              4.51415000000000000e+04 6.09241000000000058e+04 1.30750500000000011e+03,
              5.01415000000000000e+04 6.09241000000000058e+04 1.35559420000000000e+03,
              5.51415000000000000e+04 6.09241000000000058e+04 1.37672789999999986e+03,
              6.01415000000000000e+04 6.09241000000000058e+04 1.42365990000000011e+03,
              6.51415000000000000e+04 6.09241000000000058e+04 1.29974189999999999e+03,
              7.01415000000000000e+04 6.09241000000000058e+04 1.30206089999999995e+03,
              7.51415000000000000e+04 6.09241000000000058e+04 1.32266329999999994e+03,
              8.01415000000000000e+04 6.09241000000000058e+04 1.25773370000000000e+03,
              8.51415000000000000e+04 6.09241000000000058e+04 1.22459809999999993e+03,
              9.01415000000000000e+04 6.09241000000000058e+04 1.19505970000000002e+03,
              4.70915000000000000e+04 6.08741000000000058e+04 1.31310449999999992e+03,
              5.20915000000000000e+04 6.08741000000000058e+04 1.37236429999999996e+03,
              5.70915000000000000e+04 6.08741000000000058e+04 1.38249340000000007e+03,
              6.20915000000000000e+04 6.08741000000000058e+04 1.38374000000000001e+03,
              6.70915000000000000e+04 6.08741000000000058e+04 1.37493509999999992e+03,
              7.20915000000000000e+04 6.08741000000000058e+04 1.40515149999999994e+03,
              7.70915000000000000e+04 6.08741000000000058e+04 1.30950689999999986e+03,
              8.20915000000000000e+04 6.08741000000000058e+04 1.24074700000000007e+03,
              8.70915000000000000e+04 6.08741000000000058e+04 1.18515750000000003e+03,
              4.40415000000000000e+04 6.08241000000000058e+04 1.30125779999999986e+03,
              4.90415000000000000e+04 6.08241000000000058e+04 1.35066539999999986e+03,
              5.40415000000000000e+04 6.08241000000000058e+04 1.36598239999999987e+03,
              5.90415000000000000e+04 6.08241000000000058e+04 1.42824109999999996e+03,
              6.40415000000000000e+04 6.08241000000000058e+04 1.34210149999999999e+03,
              6.90415000000000000e+04 6.08241000000000058e+04 1.35663560000000007e+03,
              7.40415000000000000e+04 6.08241000000000058e+04 1.28897460000000001e+03,
              7.90415000000000000e+04 6.08241000000000058e+04 1.27546039999999994e+03,
              8.40415000000000000e+04 6.08241000000000058e+04 1.21680549999999994e+03,
              8.90415000000000000e+04 6.08241000000000058e+04 1.17238490000000002e+03,
              4.59915000000000000e+04 6.07741000000000058e+04 1.25791350000000011e+03,
              5.09915000000000000e+04 6.07741000000000058e+04 1.35432809999999995e+03,
              5.59915000000000000e+04 6.07741000000000058e+04 1.38706909999999993e+03,
              6.09915000000000000e+04 6.07741000000000058e+04 1.42403260000000000e+03,
              6.59915000000000000e+04 6.07741000000000058e+04 1.37434619999999995e+03,
              7.09915000000000000e+04 6.07741000000000058e+04 1.28665949999999998e+03,
              7.59915000000000000e+04 6.07741000000000058e+04 1.30003009999999995e+03,
              8.09915000000000000e+04 6.07741000000000058e+04 1.24062490000000003e+03,
              8.59915000000000000e+04 6.07741000000000058e+04 1.21174600000000009e+03,
              9.09915000000000000e+04 6.07741000000000058e+04 1.21626810000000000e+03,
              4.79415000000000000e+04 6.07241000000000058e+04 1.32032880000000000e+03,
              5.29415000000000000e+04 6.07241000000000058e+04 1.36292979999999989e+03,
              5.79415000000000000e+04 6.07241000000000058e+04 1.37657060000000001e+03,
              6.29415000000000000e+04 6.07241000000000058e+04 1.37430819999999994e+03,
              6.79415000000000000e+04 6.07241000000000058e+04 1.39636009999999987e+03,
              7.29415000000000000e+04 6.07241000000000058e+04 1.36707770000000005e+03,
              7.79415000000000000e+04 6.07241000000000058e+04 1.29448770000000013e+03,
              8.29415000000000000e+04 6.07241000000000058e+04 1.23763200000000006e+03,
              8.79415000000000000e+04 6.07241000000000058e+04 1.17647720000000004e+03,
              4.48915000000000000e+04 6.06741000000000058e+04 1.29025360000000001e+03,
              4.98915000000000000e+04 6.06741000000000058e+04 1.36492679999999996e+03,
              5.48915000000000000e+04 6.06741000000000058e+04 1.38571810000000005e+03,
              5.98915000000000000e+04 6.06741000000000058e+04 1.42217520000000013e+03,
              6.48915000000000000e+04 6.06741000000000058e+04 1.30130909999999994e+03,
              6.98915000000000000e+04 6.06741000000000058e+04 1.36012869999999998e+03,
              7.48915000000000000e+04 6.06741000000000058e+04 1.32669360000000006e+03,
              7.98915000000000000e+04 6.06741000000000058e+04 1.26295699999999988e+03,
              8.48915000000000000e+04 6.06741000000000058e+04 1.22075289999999995e+03,
              8.98915000000000000e+04 6.06741000000000058e+04 1.17815159999999992e+03,
              4.68415000000000000e+04 6.06241000000000058e+04 1.31256240000000003e+03,
              5.18415000000000000e+04 6.06241000000000058e+04 1.36737490000000003e+03,
              5.68415000000000000e+04 6.06241000000000058e+04 1.40910870000000000e+03,
              6.18415000000000000e+04 6.06241000000000058e+04 1.36247430000000008e+03,
              6.68415000000000000e+04 6.06241000000000058e+04 1.36639170000000013e+03,
              7.18415000000000000e+04 6.06241000000000058e+04 1.39280870000000004e+03,
              7.68415000000000000e+04 6.06241000000000058e+04 1.29635089999999991e+03,
              8.18415000000000000e+04 6.06241000000000058e+04 1.24846029999999996e+03,
              8.68415000000000000e+04 6.06241000000000058e+04 1.20463999999999987e+03,
              4.37915000000000000e+04 6.05741000000000058e+04 1.29465709999999990e+03,
              4.87915000000000000e+04 6.05741000000000058e+04 1.34242480000000000e+03,
              5.37915000000000000e+04 6.05741000000000058e+04 1.36377199999999993e+03,
              5.87915000000000000e+04 6.05741000000000058e+04 1.41786680000000001e+03,
              6.37915000000000000e+04 6.05741000000000058e+04 1.32968209999999999e+03,
              6.87915000000000000e+04 6.05741000000000058e+04 1.38778890000000001e+03,
              7.37915000000000000e+04 6.05741000000000058e+04 1.29831690000000003e+03,
              7.87915000000000000e+04 6.05741000000000058e+04 1.27102369999999996e+03,
              8.37915000000000000e+04 6.05741000000000058e+04 1.22446430000000009e+03,
              8.87915000000000000e+04 6.05741000000000058e+04 1.18737079999999992e+03,
              4.57415000000000000e+04 6.05241000000000058e+04 1.30612259999999992e+03,
              5.07415000000000000e+04 6.05241000000000058e+04 1.34014609999999993e+03,
              5.57415000000000000e+04 6.05241000000000058e+04 1.39022589999999991e+03,
              6.07415000000000000e+04 6.05241000000000058e+04 1.41683339999999998e+03,
              6.57415000000000000e+04 6.05241000000000058e+04 1.39628279999999995e+03,
              7.07415000000000000e+04 6.05241000000000058e+04 1.28490120000000002e+03,
              7.57415000000000000e+04 6.05241000000000058e+04 1.29751909999999998e+03,
              8.07415000000000000e+04 6.05241000000000058e+04 1.24349530000000004e+03,
              8.57415000000000000e+04 6.05241000000000058e+04 1.22506220000000008e+03,
              9.07415000000000000e+04 6.05241000000000058e+04 1.20210069999999996e+03,
              4.76915000000000000e+04 6.04741000000000058e+04 1.31785889999999995e+03,
              5.26915000000000000e+04 6.04741000000000058e+04 1.35712179999999989e+03,
              5.76915000000000000e+04 6.04741000000000058e+04 1.41269280000000003e+03,
              6.26915000000000000e+04 6.04741000000000058e+04 1.33896029999999996e+03,
              6.76915000000000000e+04 6.04741000000000058e+04 1.36741489999999999e+03,
              7.26915000000000000e+04 6.04741000000000058e+04 1.37005919999999992e+03,
              7.76915000000000000e+04 6.04741000000000058e+04 1.30666329999999994e+03,
              8.26915000000000000e+04 6.04741000000000058e+04 1.23768110000000001e+03,
              8.76915000000000000e+04 6.04741000000000058e+04 1.18805899999999997e+03,
              4.46415000000000000e+04 6.04241000000000058e+04 1.25288910000000010e+03,
              4.96415000000000000e+04 6.04241000000000058e+04 1.36014760000000001e+03,
              5.46415000000000000e+04 6.04241000000000058e+04 1.38160329999999999e+03,
              5.96415000000000000e+04 6.04241000000000058e+04 1.41830680000000007e+03,
              6.46415000000000000e+04 6.04241000000000058e+04 1.30308320000000003e+03,
              6.96415000000000000e+04 6.04241000000000058e+04 1.36762110000000007e+03,
              7.46415000000000000e+04 6.04241000000000058e+04 1.31234030000000007e+03,
              7.96415000000000000e+04 6.04241000000000058e+04 1.27039339999999993e+03,
              8.46415000000000000e+04 6.04241000000000058e+04 1.22322910000000002e+03,
              8.96415000000000000e+04 6.04241000000000058e+04 1.18579549999999995e+03,
              4.65915000000000000e+04 6.03741000000000058e+04 1.30786329999999998e+03,
              5.15915000000000000e+04 6.03741000000000058e+04 1.36555860000000007e+03,
              5.65915000000000000e+04 6.03741000000000058e+04 1.39522640000000001e+03,
              6.15915000000000000e+04 6.03741000000000058e+04 1.41172519999999986e+03,
              6.65915000000000000e+04 6.03741000000000058e+04 1.32967399999999998e+03,
              7.15915000000000000e+04 6.03741000000000058e+04 1.34394929999999999e+03,
              7.65915000000000000e+04 6.03741000000000058e+04 1.29239480000000003e+03,
              8.15915000000000000e+04 6.03741000000000058e+04 1.25102749999999992e+03,
              8.65915000000000000e+04 6.03741000000000058e+04 1.21836000000000013e+03,
              4.35415000000000000e+04 6.03241000000000058e+04 1.28384719999999993e+03,
              4.85415000000000000e+04 6.03241000000000058e+04 1.34318429999999989e+03,
              5.35415000000000000e+04 6.03241000000000058e+04 1.36377030000000013e+03,
              5.85415000000000000e+04 6.03241000000000058e+04 1.41402340000000004e+03,
              6.35415000000000000e+04 6.03241000000000058e+04 1.32610789999999997e+03,
              6.85415000000000000e+04 6.03241000000000058e+04 1.39609169999999995e+03,
              7.35415000000000000e+04 6.03241000000000058e+04 1.31877129999999988e+03,
              7.85415000000000000e+04 6.03241000000000058e+04 1.26953230000000008e+03,
              8.35415000000000000e+04 6.03241000000000058e+04 1.22697900000000004e+03,
              8.85415000000000000e+04 6.03241000000000058e+04 1.16898959999999988e+03,
              4.54915000000000000e+04 6.02741000000000058e+04 1.31997559999999999e+03,
              5.04915000000000000e+04 6.02741000000000058e+04 1.35281089999999995e+03,
              5.54915000000000000e+04 6.02741000000000058e+04 1.39241959999999995e+03,
              6.04915000000000000e+04 6.02741000000000058e+04 1.43039840000000004e+03,
              6.54915000000000000e+04 6.02741000000000058e+04 1.38193440000000010e+03,
              7.04915000000000000e+04 6.02741000000000058e+04 1.28935780000000000e+03,
              7.54915000000000000e+04 6.02741000000000058e+04 1.30824279999999999e+03,
              8.04915000000000000e+04 6.02741000000000058e+04 1.25550360000000001e+03,
              8.54915000000000000e+04 6.02741000000000058e+04 1.21230529999999999e+03,
              9.04915000000000000e+04 6.02741000000000058e+04 1.18366080000000011e+03,
              4.74415000000000000e+04 6.02241000000000058e+04 1.31630610000000001e+03,
              5.24415000000000000e+04 6.02241000000000058e+04 1.35171430000000009e+03,
              5.74415000000000000e+04 6.02241000000000058e+04 1.42151119999999992e+03,
              6.24415000000000000e+04 6.02241000000000058e+04 1.35815419999999995e+03,
              6.74415000000000000e+04 6.02241000000000058e+04 1.38330410000000006e+03,
              7.24415000000000000e+04 6.02241000000000058e+04 1.37959490000000005e+03,
              7.74415000000000000e+04 6.02241000000000058e+04 1.30801880000000006e+03,
              8.24415000000000000e+04 6.02241000000000058e+04 1.23629050000000007e+03,
              8.74415000000000000e+04 6.02241000000000058e+04 1.19223299999999995e+03,
              4.43915000000000000e+04 6.01741000000000058e+04 1.25210719999999992e+03,
              4.93915000000000000e+04 6.01741000000000058e+04 1.38824929999999995e+03,
              5.43915000000000000e+04 6.01741000000000058e+04 1.38658919999999989e+03,
              5.93915000000000000e+04 6.01741000000000058e+04 1.42691129999999998e+03,
              6.43915000000000000e+04 6.01741000000000058e+04 1.30964069999999992e+03,
              6.93915000000000000e+04 6.01741000000000058e+04 1.35583950000000004e+03,
              7.43915000000000000e+04 6.01741000000000058e+04 1.33034429999999998e+03,
              7.93915000000000000e+04 6.01741000000000058e+04 1.26430650000000014e+03,
              8.43915000000000000e+04 6.01741000000000058e+04 1.21700579999999991e+03,
              8.93915000000000000e+04 6.01741000000000058e+04 1.19611470000000008e+03,
              4.63415000000000000e+04 6.01241000000000058e+04 1.30504320000000007e+03,
              5.13415000000000000e+04 6.01241000000000058e+04 1.36182159999999999e+03,
              5.63415000000000000e+04 6.01241000000000058e+04 1.38189959999999996e+03,
              6.13415000000000000e+04 6.01241000000000058e+04 1.38389380000000006e+03,
              6.63415000000000000e+04 6.01241000000000058e+04 1.34954030000000012e+03,
              7.13415000000000000e+04 6.01241000000000058e+04 1.37582320000000004e+03,
              7.63415000000000000e+04 6.01241000000000058e+04 1.30857520000000000e+03,
              8.13415000000000000e+04 6.01241000000000058e+04 1.24626289999999995e+03,
              8.63415000000000000e+04 6.01241000000000058e+04 1.22820000000000005e+03,
              4.32915000000000000e+04 6.00741000000000058e+04 1.26330500000000006e+03,
              4.82915000000000000e+04 6.00741000000000058e+04 1.34028620000000001e+03,
              5.32915000000000000e+04 6.00741000000000058e+04 1.37269460000000004e+03,
              5.82915000000000000e+04 6.00741000000000058e+04 1.38108490000000006e+03,
              6.32915000000000000e+04 6.00741000000000058e+04 1.31803929999999991e+03,
              6.82915000000000000e+04 6.00741000000000058e+04 1.38722160000000008e+03,
              7.32915000000000000e+04 6.00741000000000058e+04 1.34756819999999993e+03,
              7.82915000000000000e+04 6.00741000000000058e+04 1.27715499999999997e+03,
              8.32915000000000000e+04 6.00741000000000058e+04 1.23299039999999991e+03,
              8.82915000000000000e+04 6.00741000000000058e+04 1.18454539999999997e+03,
              4.52415000000000000e+04 6.00241000000000058e+04 1.31731379999999990e+03,
              5.02415000000000000e+04 6.00241000000000058e+04 1.36668589999999995e+03,
              5.52415000000000000e+04 6.00241000000000058e+04 1.39424389999999994e+03,
              6.02415000000000000e+04 6.00241000000000058e+04 1.42320730000000003e+03,
              6.52415000000000000e+04 6.00241000000000058e+04 1.37034099999999989e+03,
              7.02415000000000000e+04 6.00241000000000058e+04 1.30113010000000008e+03,
              7.52415000000000000e+04 6.00241000000000058e+04 1.35112609999999995e+03,
              8.02415000000000000e+04 6.00241000000000058e+04 1.24879099999999994e+03,
              8.52415000000000000e+04 6.00241000000000058e+04 1.20045240000000013e+03,
              9.02415000000000000e+04 6.00241000000000058e+04 1.17056520000000000e+03,
              4.71915000000000000e+04 5.99741000000000058e+04 1.31164840000000004e+03,
              5.21915000000000000e+04 5.99741000000000058e+04 1.35644630000000006e+03,
              5.71915000000000000e+04 5.99741000000000058e+04 1.41301490000000013e+03,
              6.21915000000000000e+04 5.99741000000000058e+04 1.39474579999999992e+03,
              6.71915000000000000e+04 5.99741000000000058e+04 1.36092390000000000e+03,
              7.21915000000000000e+04 5.99741000000000058e+04 1.39294880000000012e+03,
              7.71915000000000000e+04 5.99741000000000058e+04 1.28890249999999992e+03,
              8.21915000000000000e+04 5.99741000000000058e+04 1.24022759999999994e+03,
              8.71915000000000000e+04 5.99741000000000058e+04 1.21664719999999988e+03,
              4.41415000000000000e+04 5.99241000000000058e+04 1.28542390000000000e+03,
              4.91415000000000000e+04 5.99241000000000058e+04 1.38010660000000007e+03,
              5.41415000000000000e+04 5.99241000000000058e+04 1.38378060000000005e+03,
              5.91415000000000000e+04 5.99241000000000058e+04 1.40660979999999995e+03,
              6.41415000000000000e+04 5.99241000000000058e+04 1.32951739999999995e+03,
              6.91415000000000000e+04 5.99241000000000058e+04 1.36697190000000001e+03,
              7.41415000000000000e+04 5.99241000000000058e+04 1.32322720000000004e+03,
              7.91415000000000000e+04 5.99241000000000058e+04 1.25585300000000007e+03,
              8.41415000000000000e+04 5.99241000000000058e+04 1.21185100000000011e+03,
              8.91415000000000000e+04 5.99241000000000058e+04 1.16552870000000007e+03,
              4.60915000000000000e+04 5.98741000000000058e+04 1.30048350000000005e+03,
              5.10915000000000000e+04 5.98741000000000058e+04 1.35869920000000002e+03,
              5.60915000000000000e+04 5.98741000000000058e+04 1.38930389999999989e+03,
              6.10915000000000000e+04 5.98741000000000058e+04 1.37543219999999997e+03,
              6.60915000000000000e+04 5.98741000000000058e+04 1.34218260000000009e+03,
              7.10915000000000000e+04 5.98741000000000058e+04 1.30470100000000002e+03,
              7.60915000000000000e+04 5.98741000000000058e+04 1.27289989999999989e+03,
              8.10915000000000000e+04 5.98741000000000058e+04 1.24829770000000008e+03,
              8.60915000000000000e+04 5.98741000000000058e+04 1.22507999999999993e+03,
              4.30415000000000000e+04 5.98241000000000058e+04 1.29517529999999988e+03,
              4.80415000000000000e+04 5.98241000000000058e+04 1.32903220000000010e+03,
              5.30415000000000000e+04 5.98241000000000058e+04 1.36789789999999994e+03,
              5.80415000000000000e+04 5.98241000000000058e+04 1.41240460000000007e+03,
              6.30415000000000000e+04 5.98241000000000058e+04 1.32832259999999997e+03,
              6.80415000000000000e+04 5.98241000000000058e+04 1.43590830000000005e+03,
              7.30415000000000000e+04 5.98241000000000058e+04 1.32856970000000001e+03,
              7.80415000000000000e+04 5.98241000000000058e+04 1.26116740000000004e+03,
              8.30415000000000000e+04 5.98241000000000058e+04 1.23512149999999997e+03,
              8.80415000000000000e+04 5.98241000000000058e+04 1.18668389999999999e+03,
              4.49915000000000000e+04 5.97741000000000058e+04 1.30806300000000010e+03,
              4.99915000000000000e+04 5.97741000000000058e+04 1.36079330000000004e+03,
              5.49915000000000000e+04 5.97741000000000058e+04 1.38707539999999995e+03,
              5.99915000000000000e+04 5.97741000000000058e+04 1.41929410000000007e+03,
              6.49915000000000000e+04 5.97741000000000058e+04 1.33407950000000005e+03,
              6.99915000000000000e+04 5.97741000000000058e+04 1.39948019999999997e+03,
              7.49915000000000000e+04 5.97741000000000058e+04 1.37530999999999995e+03,
              7.99915000000000000e+04 5.97741000000000058e+04 1.24134609999999998e+03,
              8.49915000000000000e+04 5.97741000000000058e+04 1.21364599999999996e+03,
              8.99915000000000000e+04 5.97741000000000058e+04 1.16736310000000003e+03,
              4.69415000000000000e+04 5.97241000000000058e+04 1.31404510000000005e+03,
              5.19415000000000000e+04 5.97241000000000058e+04 1.36009009999999989e+03,
              5.69415000000000000e+04 5.97241000000000058e+04 1.40765619999999990e+03,
              6.19415000000000000e+04 5.97241000000000058e+04 1.34963130000000001e+03,
              6.69415000000000000e+04 5.97241000000000058e+04 1.39002739999999994e+03,
              7.19415000000000000e+04 5.97241000000000058e+04 1.37917920000000004e+03,
              7.69415000000000000e+04 5.97241000000000058e+04 1.27193399999999997e+03,
              8.19415000000000000e+04 5.97241000000000058e+04 1.23842579999999998e+03,
              8.69415000000000000e+04 5.97241000000000058e+04 1.21588460000000009e+03,
              4.38915000000000000e+04 5.96741000000000058e+04 1.23625279999999998e+03,
              4.88915000000000000e+04 5.96741000000000058e+04 1.37134249999999997e+03,
              5.38915000000000000e+04 5.96741000000000058e+04 1.37546450000000004e+03,
              5.88915000000000000e+04 5.96741000000000058e+04 1.40988860000000000e+03,
              6.38915000000000000e+04 5.96741000000000058e+04 1.39221900000000005e+03,
              6.88915000000000000e+04 5.96741000000000058e+04 1.40519820000000004e+03,
              7.38915000000000000e+04 5.96741000000000058e+04 1.30061670000000004e+03,
              7.88915000000000000e+04 5.96741000000000058e+04 1.25636020000000008e+03,
              8.38915000000000000e+04 5.96741000000000058e+04 1.21089329999999995e+03,
              8.88915000000000000e+04 5.96741000000000058e+04 1.16356549999999993e+03,
              4.58415000000000000e+04 5.96241000000000058e+04 1.29084920000000011e+03,
              5.08415000000000000e+04 5.96241000000000058e+04 1.35104459999999995e+03,
              5.58415000000000000e+04 5.96241000000000058e+04 1.39456480000000010e+03,
              6.08415000000000000e+04 5.96241000000000058e+04 1.41640049999999997e+03,
              6.58415000000000000e+04 5.96241000000000058e+04 1.39601649999999995e+03,
              7.08415000000000000e+04 5.96241000000000058e+04 1.33451430000000005e+03,
              7.58415000000000000e+04 5.96241000000000058e+04 1.28017830000000004e+03,
              8.08415000000000000e+04 5.96241000000000058e+04 1.24324029999999993e+03,
              8.58415000000000000e+04 5.96241000000000058e+04 1.20465020000000004e+03,
              9.08415000000000000e+04 5.96241000000000058e+04 1.20794560000000001e+03,
              4.77915000000000000e+04 5.95741000000000058e+04 1.32097129999999993e+03,
              5.27915000000000000e+04 5.95741000000000058e+04 1.35797820000000002e+03,
              5.77915000000000000e+04 5.95741000000000058e+04 1.40968879999999990e+03,
              6.27915000000000000e+04 5.95741000000000058e+04 1.33023839999999996e+03,
              6.77915000000000000e+04 5.95741000000000058e+04 1.43353109999999992e+03,
              7.27915000000000000e+04 5.95741000000000058e+04 1.35031660000000011e+03,
              7.77915000000000000e+04 5.95741000000000058e+04 1.25075780000000009e+03,
              8.27915000000000000e+04 5.95741000000000058e+04 1.23264869999999996e+03,
              8.77915000000000000e+04 5.95741000000000058e+04 1.19623090000000002e+03,
              4.47415000000000000e+04 5.95241000000000058e+04 1.30237040000000002e+03,
              4.97415000000000000e+04 5.95241000000000058e+04 1.36153379999999993e+03,
              5.47415000000000000e+04 5.95241000000000058e+04 1.38016789999999992e+03,
              5.97415000000000000e+04 5.95241000000000058e+04 1.41672939999999994e+03,
              6.47415000000000000e+04 5.95241000000000058e+04 1.31782560000000012e+03,
              6.97415000000000000e+04 5.95241000000000058e+04 1.36427269999999999e+03,
              7.47415000000000000e+04 5.95241000000000058e+04 1.36510400000000004e+03,
              7.97415000000000000e+04 5.95241000000000058e+04 1.24508269999999993e+03,
              8.47415000000000000e+04 5.95241000000000058e+04 1.20791900000000010e+03,
              8.97415000000000000e+04 5.95241000000000058e+04 1.18204700000000003e+03,
              4.66915000000000000e+04 5.94741000000000058e+04 1.30494630000000006e+03,
              5.16915000000000000e+04 5.94741000000000058e+04 1.36603269999999998e+03,
              5.66915000000000000e+04 5.94741000000000058e+04 1.40087069999999994e+03,
              6.16915000000000000e+04 5.94741000000000058e+04 1.35739290000000005e+03,
              6.66915000000000000e+04 5.94741000000000058e+04 1.37366090000000008e+03,
              7.16915000000000000e+04 5.94741000000000058e+04 1.39641940000000000e+03,
              7.66915000000000000e+04 5.94741000000000058e+04 1.25766210000000001e+03,
              8.16915000000000000e+04 5.94741000000000058e+04 1.22632549999999992e+03,
              8.66915000000000000e+04 5.94741000000000058e+04 1.20111320000000001e+03,
              4.36415000000000000e+04 5.94241000000000058e+04 1.29746190000000001e+03,
              4.86415000000000000e+04 5.94241000000000058e+04 1.35173630000000003e+03,
              5.36415000000000000e+04 5.94241000000000058e+04 1.36782700000000000e+03,
              5.86415000000000000e+04 5.94241000000000058e+04 1.39617640000000006e+03,
              6.36415000000000000e+04 5.94241000000000058e+04 1.41617450000000008e+03,
              6.86415000000000000e+04 5.94241000000000058e+04 1.34412159999999994e+03,
              7.36415000000000000e+04 5.94241000000000058e+04 1.30522479999999996e+03,
              7.86415000000000000e+04 5.94241000000000058e+04 1.23030179999999996e+03,
              8.36415000000000000e+04 5.94241000000000058e+04 1.21494029999999998e+03,
              8.86415000000000000e+04 5.94241000000000058e+04 1.17078459999999995e+03,
              4.55915000000000000e+04 5.93741000000000058e+04 1.28539249999999993e+03,
              5.05915000000000000e+04 5.93741000000000058e+04 1.35561729999999989e+03,
              5.55915000000000000e+04 5.93741000000000058e+04 1.38354469999999992e+03,
              6.05915000000000000e+04 5.93741000000000058e+04 1.42279530000000000e+03,
              6.55915000000000000e+04 5.93741000000000058e+04 1.41574859999999990e+03,
              7.05915000000000000e+04 5.93741000000000058e+04 1.30843679999999995e+03,
              7.55915000000000000e+04 5.93741000000000058e+04 1.29232130000000006e+03,
              8.05915000000000000e+04 5.93741000000000058e+04 1.23296949999999993e+03,
              8.55915000000000000e+04 5.93741000000000058e+04 1.18297070000000008e+03,
              9.05915000000000000e+04 5.93741000000000058e+04 1.15962179999999989e+03,
              4.75415000000000000e+04 5.93241000000000058e+04 1.32508210000000008e+03,
              5.25415000000000000e+04 5.93241000000000058e+04 1.34761249999999995e+03,
              5.75415000000000000e+04 5.93241000000000058e+04 1.41551810000000000e+03,
              6.25415000000000000e+04 5.93241000000000058e+04 1.36780950000000007e+03,
              6.75415000000000000e+04 5.93241000000000058e+04 1.43535580000000004e+03,
              7.25415000000000000e+04 5.93241000000000058e+04 1.36571129999999994e+03,
              7.75415000000000000e+04 5.93241000000000058e+04 1.23923039999999992e+03,
              8.25415000000000000e+04 5.93241000000000058e+04 1.23624289999999996e+03,
              8.75415000000000000e+04 5.93241000000000058e+04 1.21730250000000001e+03,
              4.44915000000000000e+04 5.92741000000000058e+04 1.28600810000000001e+03,
              4.94915000000000000e+04 5.92741000000000058e+04 1.36848250000000007e+03,
              5.44915000000000000e+04 5.92741000000000058e+04 1.36331819999999993e+03,
              5.94915000000000000e+04 5.92741000000000058e+04 1.41562560000000008e+03,
              6.44915000000000000e+04 5.92741000000000058e+04 1.32074800000000005e+03,
              6.94915000000000000e+04 5.92741000000000058e+04 1.37452700000000004e+03,
              7.44915000000000000e+04 5.92741000000000058e+04 1.36077729999999997e+03,
              7.94915000000000000e+04 5.92741000000000058e+04 1.24541100000000006e+03,
              8.44915000000000000e+04 5.92741000000000058e+04 1.20587339999999995e+03,
              8.94915000000000000e+04 5.92741000000000058e+04 1.16657099999999991e+03,
              4.64415000000000000e+04 5.92241000000000058e+04 1.31281379999999990e+03,
              5.14415000000000000e+04 5.92241000000000058e+04 1.36489550000000008e+03,
              5.64415000000000000e+04 5.92241000000000058e+04 1.39130320000000006e+03,
              6.14415000000000000e+04 5.92241000000000058e+04 1.38214239999999995e+03,
              6.64415000000000000e+04 5.92241000000000058e+04 1.37980790000000002e+03,
              7.14415000000000000e+04 5.92241000000000058e+04 1.40818229999999994e+03,
              7.64415000000000000e+04 5.92241000000000058e+04 1.24209069999999997e+03,
              8.14415000000000000e+04 5.92241000000000058e+04 1.22336670000000004e+03,
              8.64415000000000000e+04 5.92241000000000058e+04 1.19484899999999993e+03,
              4.33915000000000000e+04 5.91741000000000058e+04 1.30467030000000000e+03,
              4.83915000000000000e+04 5.91741000000000058e+04 1.36406420000000003e+03,
              5.33915000000000000e+04 5.91741000000000058e+04 1.36874139999999989e+03,
              5.83915000000000000e+04 5.91741000000000058e+04 1.41678289999999993e+03,
              6.33915000000000000e+04 5.91741000000000058e+04 1.40557040000000006e+03,
              6.83915000000000000e+04 5.91741000000000058e+04 1.33097049999999990e+03,
              7.33915000000000000e+04 5.91741000000000058e+04 1.37235079999999994e+03,
              7.83915000000000000e+04 5.91741000000000058e+04 1.23616219999999998e+03,
              8.33915000000000000e+04 5.91741000000000058e+04 1.22518879999999990e+03,
              8.83915000000000000e+04 5.91741000000000058e+04 1.17260889999999995e+03,
              4.53415000000000000e+04 5.91241000000000058e+04 1.29791759999999999e+03,
              5.03415000000000000e+04 5.91241000000000058e+04 1.35665589999999997e+03,
              5.53415000000000000e+04 5.91241000000000058e+04 1.38409490000000005e+03,
              6.03415000000000000e+04 5.91241000000000058e+04 1.43282050000000004e+03,
              6.53415000000000000e+04 5.91241000000000058e+04 1.41397270000000003e+03,
              7.03415000000000000e+04 5.91241000000000058e+04 1.29335089999999991e+03,
              7.53415000000000000e+04 5.91241000000000058e+04 1.31981849999999986e+03,
              8.03415000000000000e+04 5.91241000000000058e+04 1.22817789999999991e+03,
              8.53415000000000000e+04 5.91241000000000058e+04 1.18529289999999992e+03,
              9.03415000000000000e+04 5.91241000000000058e+04 1.16473589999999990e+03,
              4.72915000000000000e+04 5.90741000000000058e+04 1.32578130000000010e+03,
              5.22915000000000000e+04 5.90741000000000058e+04 1.35332929999999988e+03,
              5.72915000000000000e+04 5.90741000000000058e+04 1.40752530000000002e+03,
              6.22915000000000000e+04 5.90741000000000058e+04 1.42292090000000007e+03,
              6.72915000000000000e+04 5.90741000000000058e+04 1.43812230000000000e+03,
              7.22915000000000000e+04 5.90741000000000058e+04 1.42448000000000002e+03,
              7.72915000000000000e+04 5.90741000000000058e+04 1.23193669999999997e+03,
              8.22915000000000000e+04 5.90741000000000058e+04 1.22799420000000009e+03,
              8.72915000000000000e+04 5.90741000000000058e+04 1.23113720000000012e+03,
              4.42415000000000000e+04 5.90241000000000058e+04 1.30640360000000010e+03,
              4.92415000000000000e+04 5.90241000000000058e+04 1.39218389999999999e+03,
              5.42415000000000000e+04 5.90241000000000058e+04 1.35832770000000005e+03,
              5.92415000000000000e+04 5.90241000000000058e+04 1.42191840000000002e+03,
              6.42415000000000000e+04 5.90241000000000058e+04 1.34164120000000003e+03,
              6.92415000000000000e+04 5.90241000000000058e+04 1.34832320000000004e+03,
              7.42415000000000000e+04 5.90241000000000058e+04 1.34939139999999998e+03,
              7.92415000000000000e+04 5.90241000000000058e+04 1.22865879999999993e+03,
              8.42415000000000000e+04 5.90241000000000058e+04 1.21110230000000001e+03,
              8.92415000000000000e+04 5.90241000000000058e+04 1.15966039999999998e+03,
              4.61915000000000000e+04 5.89741000000000058e+04 1.31096019999999999e+03,
              5.11915000000000000e+04 5.89741000000000058e+04 1.35887930000000006e+03,
              5.61915000000000000e+04 5.89741000000000058e+04 1.39984750000000008e+03,
              6.11915000000000000e+04 5.89741000000000058e+04 1.43468869999999993e+03,
              6.61915000000000000e+04 5.89741000000000058e+04 1.37756989999999996e+03,
              7.11915000000000000e+04 5.89741000000000058e+04 1.38127680000000009e+03,
              7.61915000000000000e+04 5.89741000000000058e+04 1.24891000000000008e+03,
              8.11915000000000000e+04 5.89741000000000058e+04 1.22874549999999999e+03,
              8.61915000000000000e+04 5.89741000000000058e+04 1.17007380000000012e+03,
              4.31415000000000000e+04 5.89241000000000058e+04 1.31580940000000010e+03,
              4.81415000000000000e+04 5.89241000000000058e+04 1.35435439999999994e+03,
              5.31415000000000000e+04 5.89241000000000058e+04 1.36640429999999992e+03,
              5.81415000000000000e+04 5.89241000000000058e+04 1.42293890000000010e+03,
              6.31415000000000000e+04 5.89241000000000058e+04 1.37667560000000003e+03,
              6.81415000000000000e+04 5.89241000000000058e+04 1.34428449999999998e+03,
              7.31415000000000000e+04 5.89241000000000058e+04 1.41397990000000004e+03,
              7.81415000000000000e+04 5.89241000000000058e+04 1.22771430000000009e+03,
              8.31415000000000000e+04 5.89241000000000058e+04 1.22813750000000005e+03,
              8.81415000000000000e+04 5.89241000000000058e+04 1.18224929999999995e+03,
              4.50915000000000000e+04 5.88741000000000058e+04 1.31125720000000001e+03,
              5.00915000000000000e+04 5.88741000000000058e+04 1.36696740000000000e+03,
              5.50915000000000000e+04 5.88741000000000058e+04 1.37502140000000009e+03,
              6.00915000000000000e+04 5.88741000000000058e+04 1.42071280000000002e+03,
              6.50915000000000000e+04 5.88741000000000058e+04 1.34729410000000007e+03,
              7.00915000000000000e+04 5.88741000000000058e+04 1.32524730000000000e+03,
              7.50915000000000000e+04 5.88741000000000058e+04 1.37002469999999994e+03,
              8.00915000000000000e+04 5.88741000000000058e+04 1.21266000000000008e+03,
              8.50915000000000000e+04 5.88741000000000058e+04 1.18952719999999999e+03,
              9.00915000000000000e+04 5.88741000000000058e+04 1.18318370000000004e+03,
              4.70415000000000000e+04 5.88241000000000058e+04 1.31514879999999994e+03,
              5.20415000000000000e+04 5.88241000000000058e+04 1.36069869999999992e+03,
              5.70415000000000000e+04 5.88241000000000058e+04 1.40662750000000005e+03,
              6.20415000000000000e+04 5.88241000000000058e+04 1.43127849999999989e+03,
              6.70415000000000000e+04 5.88241000000000058e+04 1.43611760000000004e+03,
              7.20415000000000000e+04 5.88241000000000058e+04 1.41456500000000005e+03,
              7.70415000000000000e+04 5.88241000000000058e+04 1.24764329999999995e+03,
              8.20415000000000000e+04 5.88241000000000058e+04 1.22047690000000011e+03,
              8.70415000000000000e+04 5.88241000000000058e+04 1.21128230000000008e+03,
              4.39915000000000000e+04 5.87741000000000058e+04 1.31858460000000014e+03,
              4.89915000000000000e+04 5.87741000000000058e+04 1.39912519999999995e+03,
              5.39915000000000000e+04 5.87741000000000058e+04 1.35122090000000003e+03,
              5.89915000000000000e+04 5.87741000000000058e+04 1.42088779999999997e+03,
              6.39915000000000000e+04 5.87741000000000058e+04 1.40535979999999995e+03,
              6.89915000000000000e+04 5.87741000000000058e+04 1.32232459999999992e+03,
              7.39915000000000000e+04 5.87741000000000058e+04 1.32466899999999987e+03,
              7.89915000000000000e+04 5.87741000000000058e+04 1.21870049999999992e+03,
              8.39915000000000000e+04 5.87741000000000058e+04 1.21781140000000005e+03,
              8.89915000000000000e+04 5.87741000000000058e+04 1.15873050000000012e+03,
              4.59415000000000000e+04 5.87241000000000058e+04 1.30141499999999996e+03,
              5.09415000000000000e+04 5.87241000000000058e+04 1.36705230000000006e+03,
              5.59415000000000000e+04 5.87241000000000058e+04 1.40187410000000000e+03,
              6.09415000000000000e+04 5.87241000000000058e+04 1.44001490000000013e+03,
              6.59415000000000000e+04 5.87241000000000058e+04 1.40002120000000014e+03,
              7.09415000000000000e+04 5.87241000000000058e+04 1.36286030000000005e+03,
              7.59415000000000000e+04 5.87241000000000058e+04 1.26341110000000003e+03,
              8.09415000000000000e+04 5.87241000000000058e+04 1.23211290000000008e+03,
              8.59415000000000000e+04 5.87241000000000058e+04 1.17232680000000005e+03,
              9.09415000000000000e+04 5.87241000000000058e+04 1.16681060000000002e+03,
              4.78915000000000000e+04 5.86741000000000058e+04 1.32847160000000008e+03,
              5.28915000000000000e+04 5.86741000000000058e+04 1.37793900000000008e+03,
              5.78915000000000000e+04 5.86741000000000058e+04 1.41807220000000007e+03,
              6.28915000000000000e+04 5.86741000000000058e+04 1.40588499999999999e+03,
              6.78915000000000000e+04 5.86741000000000058e+04 1.35988259999999991e+03,
              7.28915000000000000e+04 5.86741000000000058e+04 1.40355520000000001e+03,
              7.78915000000000000e+04 5.86741000000000058e+04 1.23234680000000003e+03,
              8.28915000000000000e+04 5.86741000000000058e+04 1.23575440000000003e+03,
              8.78915000000000000e+04 5.86741000000000058e+04 1.21771740000000000e+03,
              4.48415000000000000e+04 5.86241000000000058e+04 1.29210220000000004e+03,
              4.98415000000000000e+04 5.86241000000000058e+04 1.38491779999999994e+03,
              5.48415000000000000e+04 5.86241000000000058e+04 1.37915370000000007e+03,
              5.98415000000000000e+04 5.86241000000000058e+04 1.41449589999999989e+03,
              6.48415000000000000e+04 5.86241000000000058e+04 1.39751459999999997e+03,
              6.98415000000000000e+04 5.86241000000000058e+04 1.31183699999999999e+03,
              7.48415000000000000e+04 5.86241000000000058e+04 1.43199040000000014e+03,
              7.98415000000000000e+04 5.86241000000000058e+04 1.21852129999999988e+03,
              8.48415000000000000e+04 5.86241000000000058e+04 1.20702250000000004e+03,
              8.98415000000000000e+04 5.86241000000000058e+04 1.18766679999999997e+03,
              4.67915000000000000e+04 5.85741000000000058e+04 1.30653760000000011e+03,
              5.17915000000000000e+04 5.85741000000000058e+04 1.36306199999999990e+03,
              5.67915000000000000e+04 5.85741000000000058e+04 1.39894659999999999e+03,
              6.17915000000000000e+04 5.85741000000000058e+04 1.42763969999999995e+03,
              6.67915000000000000e+04 5.85741000000000058e+04 1.43456559999999990e+03,
              7.17915000000000000e+04 5.85741000000000058e+04 1.39192390000000000e+03,
              7.67915000000000000e+04 5.85741000000000058e+04 1.25075620000000004e+03,
              8.17915000000000000e+04 5.85741000000000058e+04 1.22127759999999989e+03,
              8.67915000000000000e+04 5.85741000000000058e+04 1.19826029999999992e+03,
              4.37415000000000000e+04 5.85241000000000058e+04 1.31770589999999993e+03,
              4.87415000000000000e+04 5.85241000000000058e+04 1.39128610000000003e+03,
              5.37415000000000000e+04 5.85241000000000058e+04 1.36548869999999988e+03,
              5.87415000000000000e+04 5.85241000000000058e+04 1.40462049999999999e+03,
              6.37415000000000000e+04 5.85241000000000058e+04 1.44394479999999999e+03,
              6.87415000000000000e+04 5.85241000000000058e+04 1.36567910000000006e+03,
              7.37415000000000000e+04 5.85241000000000058e+04 1.30396239999999989e+03,
              7.87415000000000000e+04 5.85241000000000058e+04 1.22854999999999995e+03,
              8.37415000000000000e+04 5.85241000000000058e+04 1.22137139999999999e+03,
              8.87415000000000000e+04 5.85241000000000058e+04 1.16149479999999994e+03,
              4.56915000000000000e+04 5.84741000000000058e+04 1.28831469999999990e+03,
              5.06915000000000000e+04 5.84741000000000058e+04 1.37661189999999988e+03,
              5.56915000000000000e+04 5.84741000000000058e+04 1.39113000000000011e+03,
              6.06915000000000000e+04 5.84741000000000058e+04 1.43893190000000004e+03,
              6.56915000000000000e+04 5.84741000000000058e+04 1.41332870000000003e+03,
              7.06915000000000000e+04 5.84741000000000058e+04 1.35703859999999986e+03,
              7.56915000000000000e+04 5.84741000000000058e+04 1.28935870000000000e+03,
              8.06915000000000000e+04 5.84741000000000058e+04 1.21992900000000009e+03,
              8.56915000000000000e+04 5.84741000000000058e+04 1.17741620000000012e+03,
              9.06915000000000000e+04 5.84741000000000058e+04 1.16782279999999992e+03,
              4.76415000000000000e+04 5.84241000000000058e+04 1.31890090000000009e+03,
              5.26415000000000000e+04 5.84241000000000058e+04 1.36565990000000011e+03,
              5.76415000000000000e+04 5.84241000000000058e+04 1.42021199999999999e+03,
              6.26415000000000000e+04 5.84241000000000058e+04 1.41844290000000001e+03,
              6.76415000000000000e+04 5.84241000000000058e+04 1.39535940000000005e+03,
              7.26415000000000000e+04 5.84241000000000058e+04 1.36887069999999994e+03,
              7.76415000000000000e+04 5.84241000000000058e+04 1.23470389999999998e+03,
              8.26415000000000000e+04 5.84241000000000058e+04 1.23516599999999994e+03,
              8.76415000000000000e+04 5.84241000000000058e+04 1.21742120000000000e+03,
              4.45915000000000000e+04 5.83741000000000058e+04 1.30060760000000005e+03,
              4.95915000000000000e+04 5.83741000000000058e+04 1.35284459999999990e+03,
              5.45915000000000000e+04 5.83741000000000058e+04 1.37274430000000007e+03,
              5.95915000000000000e+04 5.83741000000000058e+04 1.41787239999999997e+03,
              6.45915000000000000e+04 5.83741000000000058e+04 1.39272519999999986e+03,
              6.95915000000000000e+04 5.83741000000000058e+04 1.34604870000000005e+03,
              7.45915000000000000e+04 5.83741000000000058e+04 1.37840390000000002e+03,
              7.95915000000000000e+04 5.83741000000000058e+04 1.22726580000000013e+03,
              8.45915000000000000e+04 5.83741000000000058e+04 1.19489169999999990e+03,
              8.95915000000000000e+04 5.83741000000000058e+04 1.17421559999999999e+03,
              4.65415000000000000e+04 5.83241000000000058e+04 1.29694160000000011e+03,
              5.15415000000000000e+04 5.83241000000000058e+04 1.36125270000000000e+03,
              5.65415000000000000e+04 5.83241000000000058e+04 1.40542329999999993e+03,
              6.15415000000000000e+04 5.83241000000000058e+04 1.41733680000000004e+03,
              6.65415000000000000e+04 5.83241000000000058e+04 1.41580850000000009e+03,
              7.15415000000000000e+04 5.83241000000000058e+04 1.42997070000000008e+03,
              7.65415000000000000e+04 5.83241000000000058e+04 1.25841010000000006e+03,
              8.15415000000000000e+04 5.83241000000000058e+04 1.21550150000000008e+03,
              8.65415000000000000e+04 5.83241000000000058e+04 1.18767720000000008e+03,
              4.34915000000000000e+04 5.82741000000000058e+04 1.33626690000000008e+03,
              4.84915000000000000e+04 5.82741000000000058e+04 1.36212920000000008e+03,
              5.34915000000000000e+04 5.82741000000000058e+04 1.36159439999999995e+03,
              5.84915000000000000e+04 5.82741000000000058e+04 1.41819720000000007e+03,
              6.34915000000000000e+04 5.82741000000000058e+04 1.44340959999999995e+03,
              6.84915000000000000e+04 5.82741000000000058e+04 1.41029790000000003e+03,
              7.34915000000000000e+04 5.82741000000000058e+04 1.32516989999999987e+03,
              7.84915000000000000e+04 5.82741000000000058e+04 1.22737429999999995e+03,
              8.34915000000000000e+04 5.82741000000000058e+04 1.22727240000000006e+03,
              8.84915000000000000e+04 5.82741000000000058e+04 1.17267169999999987e+03,
              4.54415000000000000e+04 5.82241000000000058e+04 1.29347530000000006e+03,
              5.04415000000000000e+04 5.82241000000000058e+04 1.37926220000000012e+03,
              5.54415000000000000e+04 5.82241000000000058e+04 1.37598599999999988e+03,
              6.04415000000000000e+04 5.82241000000000058e+04 1.43851080000000002e+03,
              6.54415000000000000e+04 5.82241000000000058e+04 1.41210899999999992e+03,
              7.04415000000000000e+04 5.82241000000000058e+04 1.32491000000000008e+03,
              7.54415000000000000e+04 5.82241000000000058e+04 1.32852520000000004e+03,
              8.04415000000000000e+04 5.82241000000000058e+04 1.21839670000000001e+03,
              8.54415000000000000e+04 5.82241000000000058e+04 1.19123440000000005e+03,
              9.04415000000000000e+04 5.82241000000000058e+04 1.22911809999999991e+03,
              4.73915000000000000e+04 5.81741000000000058e+04 1.30755189999999993e+03,
              5.23915000000000000e+04 5.81741000000000058e+04 1.34060799999999995e+03,
              5.73915000000000000e+04 5.81741000000000058e+04 1.41276739999999995e+03,
              6.23915000000000000e+04 5.81741000000000058e+04 1.43014020000000005e+03,
              6.73915000000000000e+04 5.81741000000000058e+04 1.41646019999999999e+03,
              7.23915000000000000e+04 5.81741000000000058e+04 1.35782999999999993e+03,
              7.73915000000000000e+04 5.81741000000000058e+04 1.23601150000000007e+03,
              8.23915000000000000e+04 5.81741000000000058e+04 1.23147640000000001e+03,
              8.73915000000000000e+04 5.81741000000000058e+04 1.19524090000000001e+03,
              4.43415000000000000e+04 5.81241000000000058e+04 1.29842650000000003e+03,
              4.93415000000000000e+04 5.81241000000000058e+04 1.35010780000000000e+03,
              5.43415000000000000e+04 5.81241000000000058e+04 1.35709420000000000e+03,
              5.93415000000000000e+04 5.81241000000000058e+04 1.41571209999999996e+03,
              6.43415000000000000e+04 5.81241000000000058e+04 1.39845949999999993e+03,
              6.93415000000000000e+04 5.81241000000000058e+04 1.36157249999999999e+03,
              7.43415000000000000e+04 5.81241000000000058e+04 1.34278240000000005e+03,
              7.93415000000000000e+04 5.81241000000000058e+04 1.25346239999999989e+03,
              8.43415000000000000e+04 5.81241000000000058e+04 1.18650649999999996e+03,
              8.93415000000000000e+04 5.81241000000000058e+04 1.16138249999999994e+03,
              4.62915000000000000e+04 5.80741000000000058e+04 1.29590789999999993e+03,
              5.12915000000000000e+04 5.80741000000000058e+04 1.35763369999999986e+03,
              5.62915000000000000e+04 5.80741000000000058e+04 1.39580340000000001e+03,
              6.12915000000000000e+04 5.80741000000000058e+04 1.43793879999999990e+03,
              6.62915000000000000e+04 5.80741000000000058e+04 1.41736380000000008e+03,
              7.12915000000000000e+04 5.80741000000000058e+04 1.42069610000000011e+03,
              7.62915000000000000e+04 5.80741000000000058e+04 1.26548520000000008e+03,
              8.12915000000000000e+04 5.80741000000000058e+04 1.20635529999999994e+03,
              8.62915000000000000e+04 5.80741000000000058e+04 1.16724520000000007e+03,
              4.32415000000000000e+04 5.80241000000000058e+04 1.32454320000000007e+03,
              4.82415000000000000e+04 5.80241000000000058e+04 1.33604970000000003e+03,
              5.32415000000000000e+04 5.80241000000000058e+04 1.34754379999999992e+03,
              5.82415000000000000e+04 5.80241000000000058e+04 1.43208310000000006e+03,
              6.32415000000000000e+04 5.80241000000000058e+04 1.43810860000000002e+03,
              6.82415000000000000e+04 5.80241000000000058e+04 1.41935109999999986e+03,
              7.32415000000000000e+04 5.80241000000000058e+04 1.31627530000000002e+03,
              7.82415000000000000e+04 5.80241000000000058e+04 1.22853389999999990e+03,
              8.32415000000000000e+04 5.80241000000000058e+04 1.22890219999999999e+03,
              8.82415000000000000e+04 5.80241000000000058e+04 1.17149700000000007e+03,
              4.51915000000000000e+04 5.79741000000000058e+04 1.29967589999999996e+03,
              5.01915000000000000e+04 5.79741000000000058e+04 1.35027759999999989e+03,
              5.51915000000000000e+04 5.79741000000000058e+04 1.37763329999999996e+03,
              6.01915000000000000e+04 5.79741000000000058e+04 1.43372109999999998e+03,
              6.51915000000000000e+04 5.79741000000000058e+04 1.39993280000000004e+03,
              7.01915000000000000e+04 5.79741000000000058e+04 1.32355009999999993e+03,
              7.51915000000000000e+04 5.79741000000000058e+04 1.40517090000000007e+03,
              8.01915000000000000e+04 5.79741000000000058e+04 1.22873330000000010e+03,
              8.51915000000000000e+04 5.79741000000000058e+04 1.19154950000000008e+03,
              9.01915000000000000e+04 5.79741000000000058e+04 1.20750459999999998e+03,
              4.71415000000000000e+04 5.79241000000000058e+04 1.30768570000000000e+03,
              5.21415000000000000e+04 5.79241000000000058e+04 1.35406970000000001e+03,
              5.71415000000000000e+04 5.79241000000000058e+04 1.40420209999999997e+03,
              6.21415000000000000e+04 5.79241000000000058e+04 1.42620499999999993e+03,
              6.71415000000000000e+04 5.79241000000000058e+04 1.43958809999999994e+03,
              7.21415000000000000e+04 5.79241000000000058e+04 1.41228170000000000e+03,
              7.71415000000000000e+04 5.79241000000000058e+04 1.24450000000000000e+03,
              8.21415000000000000e+04 5.79241000000000058e+04 1.22794010000000003e+03,
              8.71415000000000000e+04 5.79241000000000058e+04 1.20107349999999997e+03,
              4.40915000000000000e+04 5.78741000000000058e+04 1.31807259999999997e+03,
              4.90915000000000000e+04 5.78741000000000058e+04 1.35553250000000003e+03,
              5.40915000000000000e+04 5.78741000000000058e+04 1.35423270000000002e+03,
              5.90915000000000000e+04 5.78741000000000058e+04 1.41720470000000000e+03,
              6.40915000000000000e+04 5.78741000000000058e+04 1.40945879999999988e+03,
              6.90915000000000000e+04 5.78741000000000058e+04 1.38196219999999994e+03,
              7.40915000000000000e+04 5.78741000000000058e+04 1.35117370000000005e+03,
              7.90915000000000000e+04 5.78741000000000058e+04 1.24789419999999996e+03,
              8.40915000000000000e+04 5.78741000000000058e+04 1.18265529999999990e+03,
              8.90915000000000000e+04 5.78741000000000058e+04 1.15602330000000006e+03,
              4.60415000000000000e+04 5.78241000000000058e+04 1.29990850000000000e+03,
              5.10415000000000000e+04 5.78241000000000058e+04 1.36995010000000002e+03,
              5.60415000000000000e+04 5.78241000000000058e+04 1.39086020000000008e+03,
              6.10415000000000000e+04 5.78241000000000058e+04 1.42897289999999998e+03,
              6.60415000000000000e+04 5.78241000000000058e+04 1.43466669999999999e+03,
              7.10415000000000000e+04 5.78241000000000058e+04 1.41313990000000013e+03,
              7.60415000000000000e+04 5.78241000000000058e+04 1.27541249999999991e+03,
              8.10415000000000000e+04 5.78241000000000058e+04 1.21725129999999990e+03,
              8.60415000000000000e+04 5.78241000000000058e+04 1.18824129999999991e+03,
              4.29915000000000000e+04 5.77741000000000058e+04 1.30289789999999994e+03,
              4.79915000000000000e+04 5.77741000000000058e+04 1.32115319999999997e+03,
              5.29915000000000000e+04 5.77741000000000058e+04 1.33435030000000006e+03,
              5.79915000000000000e+04 5.77741000000000058e+04 1.42243519999999990e+03,
              6.29915000000000000e+04 5.77741000000000058e+04 1.43086349999999993e+03,
              6.79915000000000000e+04 5.77741000000000058e+04 1.43241769999999997e+03,
              7.29915000000000000e+04 5.77741000000000058e+04 1.34094170000000008e+03,
              7.79915000000000000e+04 5.77741000000000058e+04 1.23378250000000003e+03,
              8.29915000000000000e+04 5.77741000000000058e+04 1.22503739999999993e+03,
              8.79915000000000000e+04 5.77741000000000058e+04 1.19069589999999994e+03,
              4.49415000000000000e+04 5.77241000000000058e+04 1.32579999999999995e+03,
              4.99415000000000000e+04 5.77241000000000058e+04 1.34490239999999994e+03,
              5.49415000000000000e+04 5.77241000000000058e+04 1.37840879999999993e+03,
              5.99415000000000000e+04 5.77241000000000058e+04 1.42333390000000009e+03,
              6.49415000000000000e+04 5.77241000000000058e+04 1.40492619999999988e+03,
              6.99415000000000000e+04 5.77241000000000058e+04 1.31057209999999986e+03,
              7.49415000000000000e+04 5.77241000000000058e+04 1.42253989999999999e+03,
              7.99415000000000000e+04 5.77241000000000058e+04 1.22759410000000003e+03,
              8.49415000000000000e+04 5.77241000000000058e+04 1.17469039999999995e+03,
              8.99415000000000000e+04 5.77241000000000058e+04 1.18465039999999999e+03,
              4.68915000000000000e+04 5.76741000000000058e+04 1.32487930000000006e+03,
              5.18915000000000000e+04 5.76741000000000058e+04 1.36179030000000012e+03,
              5.68915000000000000e+04 5.76741000000000058e+04 1.40220710000000008e+03,
              6.18915000000000000e+04 5.76741000000000058e+04 1.43231079999999997e+03,
              6.68915000000000000e+04 5.76741000000000058e+04 1.43177950000000010e+03,
              7.18915000000000000e+04 5.76741000000000058e+04 1.43009549999999990e+03,
              7.68915000000000000e+04 5.76741000000000058e+04 1.25662879999999996e+03,
              8.18915000000000000e+04 5.76741000000000058e+04 1.22117810000000009e+03,
              8.68915000000000000e+04 5.76741000000000058e+04 1.16373309999999992e+03,
              4.38415000000000000e+04 5.76241000000000058e+04 1.32672430000000008e+03,
              4.88415000000000000e+04 5.76241000000000058e+04 1.35326310000000012e+03,
              5.38415000000000000e+04 5.76241000000000058e+04 1.36137940000000003e+03,
              5.88415000000000000e+04 5.76241000000000058e+04 1.41988750000000005e+03,
              6.38415000000000000e+04 5.76241000000000058e+04 1.40343959999999993e+03,
              6.88415000000000000e+04 5.76241000000000058e+04 1.40776589999999987e+03,
              7.38415000000000000e+04 5.76241000000000058e+04 1.34908940000000007e+03,
              7.88415000000000000e+04 5.76241000000000058e+04 1.24988200000000006e+03,
              8.38415000000000000e+04 5.76241000000000058e+04 1.19343499999999995e+03,
              8.88415000000000000e+04 5.76241000000000058e+04 1.15527119999999991e+03,
              4.57915000000000000e+04 5.75741000000000058e+04 1.32899299999999994e+03,
              5.07915000000000000e+04 5.75741000000000058e+04 1.38165290000000005e+03,
              5.57915000000000000e+04 5.75741000000000058e+04 1.39334699999999998e+03,
              6.07915000000000000e+04 5.75741000000000058e+04 1.43473350000000005e+03,
              6.57915000000000000e+04 5.75741000000000058e+04 1.45880650000000014e+03,
              7.07915000000000000e+04 5.75741000000000058e+04 1.40584189999999990e+03,
              7.57915000000000000e+04 5.75741000000000058e+04 1.27330200000000013e+03,
              8.07915000000000000e+04 5.75741000000000058e+04 1.23164329999999995e+03,
              8.57915000000000000e+04 5.75741000000000058e+04 1.17338380000000006e+03,
              9.07915000000000000e+04 5.75741000000000058e+04 1.15252410000000009e+03,
              4.77415000000000000e+04 5.75241000000000058e+04 1.34491719999999987e+03,
              5.27415000000000000e+04 5.75241000000000058e+04 1.32684809999999993e+03,
              5.77415000000000000e+04 5.75241000000000058e+04 1.41248149999999987e+03,
              6.27415000000000000e+04 5.75241000000000058e+04 1.43316229999999996e+03,
              6.77415000000000000e+04 5.75241000000000058e+04 1.43217610000000013e+03,
              7.27415000000000000e+04 5.75241000000000058e+04 1.37550309999999990e+03,
              7.77415000000000000e+04 5.75241000000000058e+04 1.24360210000000006e+03,
              8.27415000000000000e+04 5.75241000000000058e+04 1.22128620000000001e+03,
              8.77415000000000000e+04 5.75241000000000058e+04 1.21464959999999996e+03,
              4.46915000000000000e+04 5.74741000000000058e+04 1.31920029999999997e+03,
              4.96915000000000000e+04 5.74741000000000058e+04 1.32990959999999995e+03,
              5.46915000000000000e+04 5.74741000000000058e+04 1.37576749999999993e+03,
              5.96915000000000000e+04 5.74741000000000058e+04 1.41800749999999994e+03,
              6.46915000000000000e+04 5.74741000000000058e+04 1.41098869999999988e+03,
              6.96915000000000000e+04 5.74741000000000058e+04 1.30865499999999997e+03,
              7.46915000000000000e+04 5.74741000000000058e+04 1.40896810000000005e+03,
              7.96915000000000000e+04 5.74741000000000058e+04 1.25022820000000002e+03,
              8.46915000000000000e+04 5.74741000000000058e+04 1.17742660000000001e+03,
              8.96915000000000000e+04 5.74741000000000058e+04 1.17554420000000005e+03,
              4.66415000000000000e+04 5.74241000000000058e+04 1.32898219999999992e+03,
              5.16415000000000000e+04 5.74241000000000058e+04 1.36202129999999988e+03,
              5.66415000000000000e+04 5.74241000000000058e+04 1.39046659999999997e+03,
              6.16415000000000000e+04 5.74241000000000058e+04 1.43537169999999992e+03,
              6.66415000000000000e+04 5.74241000000000058e+04 1.42758879999999999e+03,
              7.16415000000000000e+04 5.74241000000000058e+04 1.42856359999999995e+03,
              7.66415000000000000e+04 5.74241000000000058e+04 1.23698389999999995e+03,
              8.16415000000000000e+04 5.74241000000000058e+04 1.22765210000000002e+03,
              8.66415000000000000e+04 5.74241000000000058e+04 1.16906140000000005e+03,
              4.35915000000000000e+04 5.73741000000000058e+04 1.29819900000000007e+03,
              4.85915000000000000e+04 5.73741000000000058e+04 1.33360719999999992e+03,
              5.35915000000000000e+04 5.73741000000000058e+04 1.36795749999999998e+03,
              5.85915000000000000e+04 5.73741000000000058e+04 1.42078340000000003e+03,
              6.35915000000000000e+04 5.73741000000000058e+04 1.41704950000000008e+03,
              6.85915000000000000e+04 5.73741000000000058e+04 1.41736400000000003e+03,
              7.35915000000000000e+04 5.73741000000000058e+04 1.31781559999999990e+03,
              7.85915000000000000e+04 5.73741000000000058e+04 1.26538560000000007e+03,
              8.35915000000000000e+04 5.73741000000000058e+04 1.18062490000000003e+03,
              8.85915000000000000e+04 5.73741000000000058e+04 1.16299880000000007e+03,
              4.55415000000000000e+04 5.73241000000000058e+04 1.32611869999999999e+03,
              5.05415000000000000e+04 5.73241000000000058e+04 1.38245669999999996e+03,
              5.55415000000000000e+04 5.73241000000000058e+04 1.38575680000000011e+03,
              6.05415000000000000e+04 5.73241000000000058e+04 1.42583269999999993e+03,
              6.55415000000000000e+04 5.73241000000000058e+04 1.45835960000000000e+03,
              7.05415000000000000e+04 5.73241000000000058e+04 1.40128970000000004e+03,
              7.55415000000000000e+04 5.73241000000000058e+04 1.29083699999999999e+03,
              8.05415000000000000e+04 5.73241000000000058e+04 1.24543119999999999e+03,
              8.55415000000000000e+04 5.73241000000000058e+04 1.16607860000000005e+03,
              9.05415000000000000e+04 5.73241000000000058e+04 1.18928670000000011e+03,
              4.74915000000000000e+04 5.72741000000000058e+04 1.35707929999999988e+03,
              5.24915000000000000e+04 5.72741000000000058e+04 1.34382449999999994e+03,
              5.74915000000000000e+04 5.72741000000000058e+04 1.41402109999999993e+03,
              6.24915000000000000e+04 5.72741000000000058e+04 1.43035780000000000e+03,
              6.74915000000000000e+04 5.72741000000000058e+04 1.41148070000000007e+03,
              7.24915000000000000e+04 5.72741000000000058e+04 1.37039329999999995e+03,
              7.74915000000000000e+04 5.72741000000000058e+04 1.23794929999999999e+03,
              8.24915000000000000e+04 5.72741000000000058e+04 1.20167750000000001e+03,
              8.74915000000000000e+04 5.72741000000000058e+04 1.19686740000000009e+03,
              4.44415000000000000e+04 5.72241000000000058e+04 1.31768129999999996e+03,
              4.94415000000000000e+04 5.72241000000000058e+04 1.32390949999999998e+03,
              5.44415000000000000e+04 5.72241000000000058e+04 1.37418849999999998e+03,
              5.94415000000000000e+04 5.72241000000000058e+04 1.42266440000000011e+03,
              6.44415000000000000e+04 5.72241000000000058e+04 1.41650860000000011e+03,
              6.94415000000000000e+04 5.72241000000000058e+04 1.34179309999999987e+03,
              7.44415000000000000e+04 5.72241000000000058e+04 1.35329980000000000e+03,
              7.94415000000000000e+04 5.72241000000000058e+04 1.24365589999999997e+03,
              8.44415000000000000e+04 5.72241000000000058e+04 1.18212799999999993e+03,
              8.94415000000000000e+04 5.72241000000000058e+04 1.19941380000000004e+03,
              4.63915000000000000e+04 5.71741000000000058e+04 1.29808619999999996e+03,
              5.13915000000000000e+04 5.71741000000000058e+04 1.35753449999999998e+03,
              5.63915000000000000e+04 5.71741000000000058e+04 1.38161290000000008e+03,
              6.13915000000000000e+04 5.71741000000000058e+04 1.43503940000000011e+03,
              6.63915000000000000e+04 5.71741000000000058e+04 1.41888400000000001e+03,
              7.13915000000000000e+04 5.71741000000000058e+04 1.43269929999999999e+03,
              7.63915000000000000e+04 5.71741000000000058e+04 1.23925009999999997e+03,
              8.13915000000000000e+04 5.71741000000000058e+04 1.23168769999999995e+03,
              8.63915000000000000e+04 5.71741000000000058e+04 1.16192969999999991e+03,
              4.33415000000000000e+04 5.71241000000000058e+04 1.28403019999999992e+03,
              4.83415000000000000e+04 5.71241000000000058e+04 1.34754800000000000e+03,
              5.33415000000000000e+04 5.71241000000000058e+04 1.36071129999999994e+03,
              5.83415000000000000e+04 5.71241000000000058e+04 1.41884660000000008e+03,
              6.33415000000000000e+04 5.71241000000000058e+04 1.41249479999999994e+03,
              6.83415000000000000e+04 5.71241000000000058e+04 1.41312310000000002e+03,
              7.33415000000000000e+04 5.71241000000000058e+04 1.34146800000000007e+03,
              7.83415000000000000e+04 5.71241000000000058e+04 1.25266940000000000e+03,
              8.33415000000000000e+04 5.71241000000000058e+04 1.18559539999999993e+03,
              8.83415000000000000e+04 5.71241000000000058e+04 1.15521479999999997e+03,
              4.52915000000000000e+04 5.70741000000000058e+04 1.30497519999999986e+03,
              5.02915000000000000e+04 5.70741000000000058e+04 1.35879179999999997e+03,
              5.52915000000000000e+04 5.70741000000000058e+04 1.37699510000000009e+03,
              6.02915000000000000e+04 5.70741000000000058e+04 1.43100009999999997e+03,
              6.52915000000000000e+04 5.70741000000000058e+04 1.43966989999999987e+03,
              7.02915000000000000e+04 5.70741000000000058e+04 1.40229899999999998e+03,
              7.52915000000000000e+04 5.70741000000000058e+04 1.36259590000000003e+03,
              8.02915000000000000e+04 5.70741000000000058e+04 1.23675199999999995e+03,
              8.52915000000000000e+04 5.70741000000000058e+04 1.16244110000000001e+03,
              9.02915000000000000e+04 5.70741000000000058e+04 1.18778670000000011e+03,
              4.72415000000000000e+04 5.70241000000000058e+04 1.31858190000000013e+03,
              5.22415000000000000e+04 5.70241000000000058e+04 1.35999060000000009e+03,
              5.72415000000000000e+04 5.70241000000000058e+04 1.40330099999999993e+03,
              6.22415000000000000e+04 5.70241000000000058e+04 1.42286789999999996e+03,
              6.72415000000000000e+04 5.70241000000000058e+04 1.40578829999999994e+03,
              7.22415000000000000e+04 5.70241000000000058e+04 1.36846140000000014e+03,
              7.72415000000000000e+04 5.70241000000000058e+04 1.22334979999999996e+03,
              8.22415000000000000e+04 5.70241000000000058e+04 1.23190540000000010e+03,
              8.72415000000000000e+04 5.70241000000000058e+04 1.15744630000000006e+03,
              4.41915000000000000e+04 5.69741000000000058e+04 1.29836969999999997e+03,
              4.91915000000000000e+04 5.69741000000000058e+04 1.31996100000000001e+03,
              5.41915000000000000e+04 5.69741000000000058e+04 1.36954899999999998e+03,
              5.91915000000000000e+04 5.69741000000000058e+04 1.41862809999999990e+03,
              6.41915000000000000e+04 5.69741000000000058e+04 1.40707999999999993e+03,
              6.91915000000000000e+04 5.69741000000000058e+04 1.38461689999999999e+03,
              7.41915000000000000e+04 5.69741000000000058e+04 1.34856349999999998e+03,
              7.91915000000000000e+04 5.69741000000000058e+04 1.23842419999999993e+03,
              8.41915000000000000e+04 5.69741000000000058e+04 1.16811509999999998e+03,
              8.91915000000000000e+04 5.69741000000000058e+04 1.16463789999999995e+03,
              4.61415000000000000e+04 5.69241000000000058e+04 1.29150729999999999e+03,
              5.11415000000000000e+04 5.69241000000000058e+04 1.36900919999999996e+03,
              5.61415000000000000e+04 5.69241000000000058e+04 1.38783010000000013e+03,
              6.11415000000000000e+04 5.69241000000000058e+04 1.43670010000000002e+03,
              6.61415000000000000e+04 5.69241000000000058e+04 1.41489220000000000e+03,
              7.11415000000000000e+04 5.69241000000000058e+04 1.41082529999999997e+03,
              7.61415000000000000e+04 5.69241000000000058e+04 1.24708179999999993e+03,
              8.11415000000000000e+04 5.69241000000000058e+04 1.25353350000000000e+03,
              8.61415000000000000e+04 5.69241000000000058e+04 1.17427170000000001e+03,
              4.30915000000000000e+04 5.68741000000000058e+04 1.27575950000000012e+03,
              4.80915000000000000e+04 5.68741000000000058e+04 1.35854109999999991e+03,
              5.30915000000000000e+04 5.68741000000000058e+04 1.35814660000000003e+03,
              5.80915000000000000e+04 5.68741000000000058e+04 1.40884140000000002e+03,
              6.30915000000000000e+04 5.68741000000000058e+04 1.40353420000000006e+03,
              6.80915000000000000e+04 5.68741000000000058e+04 1.34744250000000011e+03,
              7.30915000000000000e+04 5.68741000000000058e+04 1.39790129999999999e+03,
              7.80915000000000000e+04 5.68741000000000058e+04 1.23621319999999992e+03,
              8.30915000000000000e+04 5.68741000000000058e+04 1.22164499999999998e+03,
              8.80915000000000000e+04 5.68741000000000058e+04 1.15562390000000005e+03,
              4.50415000000000000e+04 5.68241000000000058e+04 1.29350810000000001e+03,
              5.00415000000000000e+04 5.68241000000000058e+04 1.33870830000000001e+03,
              5.50415000000000000e+04 5.68241000000000058e+04 1.37080299999999988e+03,
              6.00415000000000000e+04 5.68241000000000058e+04 1.42800140000000010e+03,
              6.50415000000000000e+04 5.68241000000000058e+04 1.42341599999999994e+03,
              7.00415000000000000e+04 5.68241000000000058e+04 1.39061900000000014e+03,
              7.50415000000000000e+04 5.68241000000000058e+04 1.41986599999999999e+03,
              8.00415000000000000e+04 5.68241000000000058e+04 1.22772929999999997e+03,
              8.50415000000000000e+04 5.68241000000000058e+04 1.16238390000000004e+03,
              9.00415000000000000e+04 5.68241000000000058e+04 1.20348340000000007e+03,
              4.69915000000000000e+04 5.67741000000000058e+04 1.29079590000000007e+03,
              5.19915000000000000e+04 5.67741000000000058e+04 1.35773270000000002e+03,
              5.69915000000000000e+04 5.67741000000000058e+04 1.40432500000000005e+03,
              6.19915000000000000e+04 5.67741000000000058e+04 1.42786400000000003e+03,
              6.69915000000000000e+04 5.67741000000000058e+04 1.40857339999999999e+03,
              7.19915000000000000e+04 5.67741000000000058e+04 1.35416640000000007e+03,
              7.69915000000000000e+04 5.67741000000000058e+04 1.22120190000000002e+03,
              8.19915000000000000e+04 5.67741000000000058e+04 1.23950600000000009e+03,
              8.69915000000000000e+04 5.67741000000000058e+04 1.15660529999999994e+03,
              4.39415000000000000e+04 5.67241000000000058e+04 1.28398080000000004e+03,
              4.89415000000000000e+04 5.67241000000000058e+04 1.32535320000000002e+03,
              5.39415000000000000e+04 5.67241000000000058e+04 1.37596299999999997e+03,
              5.89415000000000000e+04 5.67241000000000058e+04 1.41464710000000014e+03,
              6.39415000000000000e+04 5.67241000000000058e+04 1.40495910000000003e+03,
              6.89415000000000000e+04 5.67241000000000058e+04 1.33286519999999996e+03,
              7.39415000000000000e+04 5.67241000000000058e+04 1.31892070000000012e+03,
              7.89415000000000000e+04 5.67241000000000058e+04 1.25124610000000007e+03,
              8.39415000000000000e+04 5.67241000000000058e+04 1.17793059999999991e+03,
              8.89415000000000000e+04 5.67241000000000058e+04 1.15282009999999991e+03,
              4.58915000000000000e+04 5.66741000000000058e+04 1.28255240000000003e+03,
              5.08915000000000000e+04 5.66741000000000058e+04 1.34763589999999999e+03,
              5.58915000000000000e+04 5.66741000000000058e+04 1.37573340000000007e+03,
              6.08915000000000000e+04 5.66741000000000058e+04 1.43545090000000005e+03,
              6.58915000000000000e+04 5.66741000000000058e+04 1.42444329999999991e+03,
              7.08915000000000000e+04 5.66741000000000058e+04 1.40804189999999994e+03,
              7.58915000000000000e+04 5.66741000000000058e+04 1.25443039999999996e+03,
              8.08915000000000000e+04 5.66741000000000058e+04 1.25105760000000009e+03,
              8.58915000000000000e+04 5.66741000000000058e+04 1.19054770000000008e+03,
              9.08915000000000000e+04 5.66741000000000058e+04 1.20884279999999990e+03,
              4.78415000000000000e+04 5.66241000000000058e+04 1.33304790000000003e+03,
              5.28415000000000000e+04 5.66241000000000058e+04 1.34025240000000008e+03,
              5.78415000000000000e+04 5.66241000000000058e+04 1.40218329999999992e+03,
              6.28415000000000000e+04 5.66241000000000058e+04 1.40500199999999995e+03,
              6.78415000000000000e+04 5.66241000000000058e+04 1.39318750000000000e+03,
              7.28415000000000000e+04 5.66241000000000058e+04 1.40769450000000006e+03,
              7.78415000000000000e+04 5.66241000000000058e+04 1.21863010000000008e+03,
              8.28415000000000000e+04 5.66241000000000058e+04 1.21687950000000001e+03,
              8.78415000000000000e+04 5.66241000000000058e+04 1.15632979999999998e+03,
              4.47915000000000000e+04 5.65741000000000058e+04 1.28593599999999992e+03,
              4.97915000000000000e+04 5.65741000000000058e+04 1.33482400000000007e+03,
              5.47915000000000000e+04 5.65741000000000058e+04 1.35911630000000014e+03,
              5.97915000000000000e+04 5.65741000000000058e+04 1.42069599999999991e+03,
              6.47915000000000000e+04 5.65741000000000058e+04 1.40431909999999993e+03,
              6.97915000000000000e+04 5.65741000000000058e+04 1.35141350000000011e+03,
              7.47915000000000000e+04 5.65741000000000058e+04 1.39538010000000008e+03,
              7.97915000000000000e+04 5.65741000000000058e+04 1.22806750000000011e+03,
              8.47915000000000000e+04 5.65741000000000058e+04 1.16263069999999993e+03,
              8.97915000000000000e+04 5.65741000000000058e+04 1.17182899999999995e+03,
              4.67415000000000000e+04 5.65241000000000058e+04 1.28082850000000008e+03,
              5.17415000000000000e+04 5.65241000000000058e+04 1.34718409999999994e+03,
              5.67415000000000000e+04 5.65241000000000058e+04 1.40324579999999992e+03,
              6.17415000000000000e+04 5.65241000000000058e+04 1.42248160000000007e+03,
              6.67415000000000000e+04 5.65241000000000058e+04 1.38381729999999993e+03,
              7.17415000000000000e+04 5.65241000000000058e+04 1.39620600000000013e+03,
              7.67415000000000000e+04 5.65241000000000058e+04 1.22272579999999994e+03,
              8.17415000000000000e+04 5.65241000000000058e+04 1.25058079999999995e+03,
              8.67415000000000000e+04 5.65241000000000058e+04 1.17512740000000008e+03,
              4.36915000000000000e+04 5.64741000000000058e+04 1.28824109999999996e+03,
              4.86915000000000000e+04 5.64741000000000058e+04 1.33133370000000014e+03,
              5.36915000000000000e+04 5.64741000000000058e+04 1.36515190000000007e+03,
              5.86915000000000000e+04 5.64741000000000058e+04 1.41691640000000007e+03,
              6.36915000000000000e+04 5.64741000000000058e+04 1.40907410000000004e+03,
              6.86915000000000000e+04 5.64741000000000058e+04 1.39291149999999993e+03,
              7.36915000000000000e+04 5.64741000000000058e+04 1.31494679999999994e+03,
              7.86915000000000000e+04 5.64741000000000058e+04 1.23831899999999996e+03,
              8.36915000000000000e+04 5.64741000000000058e+04 1.19125150000000008e+03,
              8.86915000000000000e+04 5.64741000000000058e+04 1.15582169999999996e+03,
              4.56415000000000000e+04 5.64241000000000058e+04 1.29327340000000004e+03,
              5.06415000000000000e+04 5.64241000000000058e+04 1.34130359999999996e+03,
              5.56415000000000000e+04 5.64241000000000058e+04 1.36663999999999987e+03,
              6.06415000000000000e+04 5.64241000000000058e+04 1.43375289999999995e+03,
              6.56415000000000000e+04 5.64241000000000058e+04 1.41542849999999999e+03,
              7.06415000000000000e+04 5.64241000000000058e+04 1.40816170000000011e+03,
              7.56415000000000000e+04 5.64241000000000058e+04 1.25675170000000003e+03,
              8.06415000000000000e+04 5.64241000000000058e+04 1.24196440000000007e+03,
              8.56415000000000000e+04 5.64241000000000058e+04 1.20017090000000007e+03,
              9.06415000000000000e+04 5.64241000000000058e+04 1.20960550000000012e+03,
              4.75915000000000000e+04 5.63741000000000058e+04 1.32016149999999993e+03,
              5.25915000000000000e+04 5.63741000000000058e+04 1.33785190000000011e+03,
              5.75915000000000000e+04 5.63741000000000058e+04 1.39840169999999989e+03,
              6.25915000000000000e+04 5.63741000000000058e+04 1.40338470000000007e+03,
              6.75915000000000000e+04 5.63741000000000058e+04 1.40203359999999998e+03,
              7.25915000000000000e+04 5.63741000000000058e+04 1.39870290000000000e+03,
              7.75915000000000000e+04 5.63741000000000058e+04 1.21299870000000010e+03,
              8.25915000000000000e+04 5.63741000000000058e+04 1.19125559999999996e+03,
              8.75915000000000000e+04 5.63741000000000058e+04 1.15440229999999997e+03,
              4.45415000000000000e+04 5.63241000000000058e+04 1.27378350000000000e+03,
              4.95415000000000000e+04 5.63241000000000058e+04 1.32800980000000004e+03,
              5.45415000000000000e+04 5.63241000000000058e+04 1.34793620000000010e+03,
              5.95415000000000000e+04 5.63241000000000058e+04 1.42064470000000006e+03,
              6.45415000000000000e+04 5.63241000000000058e+04 1.40557099999999991e+03,
              6.95415000000000000e+04 5.63241000000000058e+04 1.33509220000000005e+03,
              7.45415000000000000e+04 5.63241000000000058e+04 1.34674530000000004e+03,
              7.95415000000000000e+04 5.63241000000000058e+04 1.23183760000000007e+03,
              8.45415000000000000e+04 5.63241000000000058e+04 1.16665190000000007e+03,
              8.95415000000000000e+04 5.63241000000000058e+04 1.15946599999999989e+03,
              4.64915000000000000e+04 5.62741000000000058e+04 1.28192149999999992e+03,
              5.14915000000000000e+04 5.62741000000000058e+04 1.35350869999999986e+03,
              5.64915000000000000e+04 5.62741000000000058e+04 1.40728850000000011e+03,
              6.14915000000000000e+04 5.62741000000000058e+04 1.43083349999999996e+03,
              6.64915000000000000e+04 5.62741000000000058e+04 1.41289609999999993e+03,
              7.14915000000000000e+04 5.62741000000000058e+04 1.41271360000000004e+03,
              7.64915000000000000e+04 5.62741000000000058e+04 1.22606449999999995e+03,
              8.14915000000000000e+04 5.62741000000000058e+04 1.24290940000000001e+03,
              8.64915000000000000e+04 5.62741000000000058e+04 1.19035100000000011e+03,
              4.34415000000000000e+04 5.62241000000000058e+04 1.27078170000000000e+03,
              4.84415000000000000e+04 5.62241000000000058e+04 1.32871489999999994e+03,
              5.34415000000000000e+04 5.62241000000000058e+04 1.35881160000000000e+03,
              5.84415000000000000e+04 5.62241000000000058e+04 1.42836169999999993e+03,
              6.34415000000000000e+04 5.62241000000000058e+04 1.40804750000000013e+03,
              6.84415000000000000e+04 5.62241000000000058e+04 1.40933510000000001e+03,
              7.34415000000000000e+04 5.62241000000000058e+04 1.33535770000000002e+03,
              7.84415000000000000e+04 5.62241000000000058e+04 1.22376330000000007e+03,
              8.34415000000000000e+04 5.62241000000000058e+04 1.19432929999999988e+03,
              8.84415000000000000e+04 5.62241000000000058e+04 1.15398829999999998e+03,
              4.53915000000000000e+04 5.61741000000000058e+04 1.27040519999999992e+03,
              5.03915000000000000e+04 5.61741000000000058e+04 1.33495039999999995e+03,
              5.53915000000000000e+04 5.61741000000000058e+04 1.35756950000000006e+03,
              6.03915000000000000e+04 5.61741000000000058e+04 1.42905829999999992e+03,
              6.53915000000000000e+04 5.61741000000000058e+04 1.40422659999999996e+03,
              7.03915000000000000e+04 5.61741000000000058e+04 1.40464400000000001e+03,
              7.53915000000000000e+04 5.61741000000000058e+04 1.25558459999999991e+03,
              8.03915000000000000e+04 5.61741000000000058e+04 1.21944560000000001e+03,
              8.53915000000000000e+04 5.61741000000000058e+04 1.21328839999999991e+03,
              9.03915000000000000e+04 5.61741000000000058e+04 1.18557889999999998e+03,
              4.73415000000000000e+04 5.61241000000000058e+04 1.30544730000000004e+03,
              5.23415000000000000e+04 5.61241000000000058e+04 1.35556120000000010e+03,
              5.73415000000000000e+04 5.61241000000000058e+04 1.39254600000000005e+03,
              6.23415000000000000e+04 5.61241000000000058e+04 1.39954459999999995e+03,
              6.73415000000000000e+04 5.61241000000000058e+04 1.40058909999999992e+03,
              7.23415000000000000e+04 5.61241000000000058e+04 1.38576630000000000e+03,
              7.73415000000000000e+04 5.61241000000000058e+04 1.22043530000000010e+03,
              8.23415000000000000e+04 5.61241000000000058e+04 1.21030389999999989e+03,
              8.73415000000000000e+04 5.61241000000000058e+04 1.16495640000000003e+03,
              4.42915000000000000e+04 5.60741000000000058e+04 1.25413210000000004e+03,
              4.92915000000000000e+04 5.60741000000000058e+04 1.31012840000000006e+03,
              5.42915000000000000e+04 5.60741000000000058e+04 1.34924220000000014e+03,
              5.92915000000000000e+04 5.60741000000000058e+04 1.42105230000000006e+03,
              6.42915000000000000e+04 5.60741000000000058e+04 1.40260130000000004e+03,
              6.92915000000000000e+04 5.60741000000000058e+04 1.31270470000000000e+03,
              7.42915000000000000e+04 5.60741000000000058e+04 1.33502260000000001e+03,
              7.92915000000000000e+04 5.60741000000000058e+04 1.25842560000000003e+03,
              8.42915000000000000e+04 5.60741000000000058e+04 1.16862349999999992e+03,
              8.92915000000000000e+04 5.60741000000000058e+04 1.15303950000000009e+03,
              4.62415000000000000e+04 5.60241000000000058e+04 1.28068489999999997e+03,
              5.12415000000000000e+04 5.60241000000000058e+04 1.34802340000000004e+03,
              5.62415000000000000e+04 5.60241000000000058e+04 1.38801260000000002e+03,
              6.12415000000000000e+04 5.60241000000000058e+04 1.43078350000000000e+03,
              6.62415000000000000e+04 5.60241000000000058e+04 1.41762590000000000e+03,
              7.12415000000000000e+04 5.60241000000000058e+04 1.41803960000000006e+03,
              7.62415000000000000e+04 5.60241000000000058e+04 1.23682269999999994e+03,
              8.12415000000000000e+04 5.60241000000000058e+04 1.23126520000000005e+03,
              8.62415000000000000e+04 5.60241000000000058e+04 1.19974049999999988e+03,
              4.31915000000000000e+04 5.59741000000000058e+04 1.25469540000000006e+03,
              4.81915000000000000e+04 5.59741000000000058e+04 1.32988639999999987e+03,
              5.31915000000000000e+04 5.59741000000000058e+04 1.35686979999999994e+03,
              5.81915000000000000e+04 5.59741000000000058e+04 1.42508870000000002e+03,
              6.31915000000000000e+04 5.59741000000000058e+04 1.41264710000000014e+03,
              6.81915000000000000e+04 5.59741000000000058e+04 1.40709680000000003e+03,
              7.31915000000000000e+04 5.59741000000000058e+04 1.36801179999999999e+03,
              7.81915000000000000e+04 5.59741000000000058e+04 1.20312920000000008e+03,
              8.31915000000000000e+04 5.59741000000000058e+04 1.19950500000000011e+03,
              8.81915000000000000e+04 5.59741000000000058e+04 1.15507410000000004e+03,
              4.51415000000000000e+04 5.59241000000000058e+04 1.25979890000000000e+03,
              5.01415000000000000e+04 5.59241000000000058e+04 1.32464830000000006e+03,
              5.51415000000000000e+04 5.59241000000000058e+04 1.37070370000000003e+03,
              6.01415000000000000e+04 5.59241000000000058e+04 1.42257500000000005e+03,
              6.51415000000000000e+04 5.59241000000000058e+04 1.39130110000000013e+03,
              7.01415000000000000e+04 5.59241000000000058e+04 1.41755870000000004e+03,
              7.51415000000000000e+04 5.59241000000000058e+04 1.27679259999999999e+03,
              8.01415000000000000e+04 5.59241000000000058e+04 1.23073579999999993e+03,
              8.51415000000000000e+04 5.59241000000000058e+04 1.20416000000000008e+03,
              9.01415000000000000e+04 5.59241000000000058e+04 1.16060110000000009e+03,
              4.70915000000000000e+04 5.58741000000000058e+04 1.29026119999999992e+03,
              5.20915000000000000e+04 5.58741000000000058e+04 1.33920150000000012e+03,
              5.70915000000000000e+04 5.58741000000000058e+04 1.40349659999999994e+03,
              6.20915000000000000e+04 5.58741000000000058e+04 1.38496890000000008e+03,
              6.70915000000000000e+04 5.58741000000000058e+04 1.39403340000000003e+03,
              7.20915000000000000e+04 5.58741000000000058e+04 1.39185690000000000e+03,
              7.70915000000000000e+04 5.58741000000000058e+04 1.21995609999999988e+03,
              8.20915000000000000e+04 5.58741000000000058e+04 1.21862249999999995e+03,
              8.70915000000000000e+04 5.58741000000000058e+04 1.16875309999999990e+03,
              4.40415000000000000e+04 5.58241000000000058e+04 1.25618800000000010e+03,
              4.90415000000000000e+04 5.58241000000000058e+04 1.32156330000000003e+03,
              5.40415000000000000e+04 5.58241000000000058e+04 1.36678420000000006e+03,
              5.90415000000000000e+04 5.58241000000000058e+04 1.40958150000000001e+03,
              6.40415000000000000e+04 5.58241000000000058e+04 1.39730680000000007e+03,
              6.90415000000000000e+04 5.58241000000000058e+04 1.31683040000000005e+03,
              7.40415000000000000e+04 5.58241000000000058e+04 1.32772100000000000e+03,
              7.90415000000000000e+04 5.58241000000000058e+04 1.23811400000000003e+03,
              8.40415000000000000e+04 5.58241000000000058e+04 1.17378890000000001e+03,
              8.90415000000000000e+04 5.58241000000000058e+04 1.15109539999999993e+03,
              4.59915000000000000e+04 5.57741000000000058e+04 1.26603600000000006e+03,
              5.09915000000000000e+04 5.57741000000000058e+04 1.34532580000000007e+03,
              5.59915000000000000e+04 5.57741000000000058e+04 1.38683130000000006e+03,
              6.09915000000000000e+04 5.57741000000000058e+04 1.43540370000000007e+03,
              6.59915000000000000e+04 5.57741000000000058e+04 1.40949549999999999e+03,
              7.09915000000000000e+04 5.57741000000000058e+04 1.40823479999999995e+03,
              7.59915000000000000e+04 5.57741000000000058e+04 1.24088969999999995e+03,
              8.09915000000000000e+04 5.57741000000000058e+04 1.21959780000000001e+03,
              8.59915000000000000e+04 5.57741000000000058e+04 1.21809310000000005e+03,
              9.09915000000000000e+04 5.57741000000000058e+04 1.16536069999999995e+03,
              4.79415000000000000e+04 5.57241000000000058e+04 1.34835410000000002e+03,
              5.29415000000000000e+04 5.57241000000000058e+04 1.35456970000000001e+03,
              5.79415000000000000e+04 5.57241000000000058e+04 1.42417319999999995e+03,
              6.29415000000000000e+04 5.57241000000000058e+04 1.40647440000000006e+03,
              6.79415000000000000e+04 5.57241000000000058e+04 1.40213279999999986e+03,
              7.29415000000000000e+04 5.57241000000000058e+04 1.39599479999999994e+03,
              7.79415000000000000e+04 5.57241000000000058e+04 1.20840370000000007e+03,
              8.29415000000000000e+04 5.57241000000000058e+04 1.19567229999999995e+03,
              8.79415000000000000e+04 5.57241000000000058e+04 1.15515450000000010e+03,
              4.48915000000000000e+04 5.56741000000000058e+04 1.25064539999999988e+03,
              4.98915000000000000e+04 5.56741000000000058e+04 1.32519769999999994e+03,
              5.48915000000000000e+04 5.56741000000000058e+04 1.35787210000000005e+03,
              5.98915000000000000e+04 5.56741000000000058e+04 1.41982799999999997e+03,
              6.48915000000000000e+04 5.56741000000000058e+04 1.39167650000000003e+03,
              6.98915000000000000e+04 5.56741000000000058e+04 1.41131840000000011e+03,
              7.48915000000000000e+04 5.56741000000000058e+04 1.37792949999999996e+03,
              7.98915000000000000e+04 5.56741000000000058e+04 1.22987860000000001e+03,
              8.48915000000000000e+04 5.56741000000000058e+04 1.21088229999999999e+03,
              8.98915000000000000e+04 5.56741000000000058e+04 1.14718170000000009e+03,
              4.68415000000000000e+04 5.56241000000000058e+04 1.29651620000000003e+03,
              5.18415000000000000e+04 5.56241000000000058e+04 1.36080250000000001e+03,
              5.68415000000000000e+04 5.56241000000000058e+04 1.39328060000000005e+03,
              6.18415000000000000e+04 5.56241000000000058e+04 1.37436239999999998e+03,
              6.68415000000000000e+04 5.56241000000000058e+04 1.41605659999999989e+03,
              7.18415000000000000e+04 5.56241000000000058e+04 1.40321170000000006e+03,
              7.68415000000000000e+04 5.56241000000000058e+04 1.21523280000000000e+03,
              8.18415000000000000e+04 5.56241000000000058e+04 1.21764789999999994e+03,
              8.68415000000000000e+04 5.56241000000000058e+04 1.17719209999999998e+03,
              4.37915000000000000e+04 5.55741000000000058e+04 1.24615900000000011e+03,
              4.87915000000000000e+04 5.55741000000000058e+04 1.32511220000000003e+03,
              5.37915000000000000e+04 5.55741000000000058e+04 1.36177489999999989e+03,
              5.87915000000000000e+04 5.55741000000000058e+04 1.41750880000000006e+03,
              6.37915000000000000e+04 5.55741000000000058e+04 1.40336879999999996e+03,
              6.87915000000000000e+04 5.55741000000000058e+04 1.37714670000000001e+03,
              7.37915000000000000e+04 5.55741000000000058e+04 1.32916719999999987e+03,
              7.87915000000000000e+04 5.55741000000000058e+04 1.22825639999999999e+03,
              8.37915000000000000e+04 5.55741000000000058e+04 1.16761300000000006e+03,
              8.87915000000000000e+04 5.55741000000000058e+04 1.15341869999999994e+03,
              4.57415000000000000e+04 5.55241000000000058e+04 1.27803150000000005e+03,
              5.07415000000000000e+04 5.55241000000000058e+04 1.34005410000000006e+03,
              5.57415000000000000e+04 5.55241000000000058e+04 1.38659930000000008e+03,
              6.07415000000000000e+04 5.55241000000000058e+04 1.43244700000000012e+03,
              6.57415000000000000e+04 5.55241000000000058e+04 1.40819650000000001e+03,
              7.07415000000000000e+04 5.55241000000000058e+04 1.40996599999999989e+03,
              7.57415000000000000e+04 5.55241000000000058e+04 1.25290380000000005e+03,
              8.07415000000000000e+04 5.55241000000000058e+04 1.22128860000000009e+03,
              8.57415000000000000e+04 5.55241000000000058e+04 1.21844180000000006e+03,
              9.07415000000000000e+04 5.55241000000000058e+04 1.17827970000000005e+03,
              4.76915000000000000e+04 5.54741000000000058e+04 1.34315049999999997e+03,
              5.26915000000000000e+04 5.54741000000000058e+04 1.35650700000000006e+03,
              5.76915000000000000e+04 5.54741000000000058e+04 1.41937899999999991e+03,
              6.26915000000000000e+04 5.54741000000000058e+04 1.39883240000000001e+03,
              6.76915000000000000e+04 5.54741000000000058e+04 1.41042270000000008e+03,
              7.26915000000000000e+04 5.54741000000000058e+04 1.41415540000000010e+03,
              7.76915000000000000e+04 5.54741000000000058e+04 1.21079109999999991e+03,
              8.26915000000000000e+04 5.54741000000000058e+04 1.17657259999999997e+03,
              8.76915000000000000e+04 5.54741000000000058e+04 1.15433420000000001e+03,
              4.46415000000000000e+04 5.54241000000000058e+04 1.25807819999999992e+03,
              4.96415000000000000e+04 5.54241000000000058e+04 1.30217229999999995e+03,
              5.46415000000000000e+04 5.54241000000000058e+04 1.35882729999999992e+03,
              5.96415000000000000e+04 5.54241000000000058e+04 1.42181729999999993e+03,
              6.46415000000000000e+04 5.54241000000000058e+04 1.39635249999999996e+03,
              6.96415000000000000e+04 5.54241000000000058e+04 1.38919720000000007e+03,
              7.46415000000000000e+04 5.54241000000000058e+04 1.38453680000000008e+03,
              7.96415000000000000e+04 5.54241000000000058e+04 1.23091360000000009e+03,
              8.46415000000000000e+04 5.54241000000000058e+04 1.20465370000000007e+03,
              8.96415000000000000e+04 5.54241000000000058e+04 1.14704449999999997e+03,
              4.65915000000000000e+04 5.53741000000000058e+04 1.30792550000000006e+03,
              5.15915000000000000e+04 5.53741000000000058e+04 1.36307590000000005e+03,
              5.65915000000000000e+04 5.53741000000000058e+04 1.38111900000000014e+03,
              6.15915000000000000e+04 5.53741000000000058e+04 1.37432629999999995e+03,
              6.65915000000000000e+04 5.53741000000000058e+04 1.40509069999999997e+03,
              7.15915000000000000e+04 5.53741000000000058e+04 1.39323260000000005e+03,
              7.65915000000000000e+04 5.53741000000000058e+04 1.21950050000000010e+03,
              8.15915000000000000e+04 5.53741000000000058e+04 1.20794119999999998e+03,
              8.65915000000000000e+04 5.53741000000000058e+04 1.19450050000000010e+03,
              4.35415000000000000e+04 5.53241000000000058e+04 1.24674109999999996e+03,
              4.85415000000000000e+04 5.53241000000000058e+04 1.31071980000000008e+03,
              5.35415000000000000e+04 5.53241000000000058e+04 1.34247309999999993e+03,
              5.85415000000000000e+04 5.53241000000000058e+04 1.40179970000000003e+03,
              6.35415000000000000e+04 5.53241000000000058e+04 1.40832960000000003e+03,
              6.85415000000000000e+04 5.53241000000000058e+04 1.38037789999999995e+03,
              7.35415000000000000e+04 5.53241000000000058e+04 1.38953260000000000e+03,
              7.85415000000000000e+04 5.53241000000000058e+04 1.21310059999999999e+03,
              8.35415000000000000e+04 5.53241000000000058e+04 1.18860619999999994e+03,
              8.85415000000000000e+04 5.53241000000000058e+04 1.14983390000000009e+03,
              4.54915000000000000e+04 5.52741000000000058e+04 1.27364519999999993e+03,
              5.04915000000000000e+04 5.52741000000000058e+04 1.32725009999999997e+03,
              5.54915000000000000e+04 5.52741000000000058e+04 1.37866899999999987e+03,
              6.04915000000000000e+04 5.52741000000000058e+04 1.42460559999999987e+03,
              6.54915000000000000e+04 5.52741000000000058e+04 1.41281950000000006e+03,
              7.04915000000000000e+04 5.52741000000000058e+04 1.41004129999999986e+03,
              7.54915000000000000e+04 5.52741000000000058e+04 1.27264110000000005e+03,
              8.04915000000000000e+04 5.52741000000000058e+04 1.22187599999999998e+03,
              8.54915000000000000e+04 5.52741000000000058e+04 1.21386979999999994e+03,
              9.04915000000000000e+04 5.52741000000000058e+04 1.17478199999999993e+03,
              4.74415000000000000e+04 5.52241000000000058e+04 1.33661770000000001e+03,
              5.24415000000000000e+04 5.52241000000000058e+04 1.34033590000000004e+03,
              5.74415000000000000e+04 5.52241000000000058e+04 1.40536969999999997e+03,
              6.24415000000000000e+04 5.52241000000000058e+04 1.38626540000000000e+03,
              6.74415000000000000e+04 5.52241000000000058e+04 1.40811509999999998e+03,
              7.24415000000000000e+04 5.52241000000000058e+04 1.42561329999999998e+03,
              7.74415000000000000e+04 5.52241000000000058e+04 1.20641129999999998e+03,
              8.24415000000000000e+04 5.52241000000000058e+04 1.17623970000000008e+03,
              8.74415000000000000e+04 5.52241000000000058e+04 1.15521749999999997e+03,
              4.43915000000000000e+04 5.51741000000000058e+04 1.26195389999999998e+03,
              4.93915000000000000e+04 5.51741000000000058e+04 1.32205479999999989e+03,
              5.43915000000000000e+04 5.51741000000000058e+04 1.34999279999999999e+03,
              5.93915000000000000e+04 5.51741000000000058e+04 1.41425549999999998e+03,
              6.43915000000000000e+04 5.51741000000000058e+04 1.39907119999999986e+03,
              6.93915000000000000e+04 5.51741000000000058e+04 1.36685730000000012e+03,
              7.43915000000000000e+04 5.51741000000000058e+04 1.39831979999999999e+03,
              7.93915000000000000e+04 5.51741000000000058e+04 1.21759169999999995e+03,
              8.43915000000000000e+04 5.51741000000000058e+04 1.20434169999999995e+03,
              8.93915000000000000e+04 5.51741000000000058e+04 1.14659359999999992e+03,
              4.63415000000000000e+04 5.51241000000000058e+04 1.29261169999999993e+03,
              5.13415000000000000e+04 5.51241000000000058e+04 1.34006819999999993e+03,
              5.63415000000000000e+04 5.51241000000000058e+04 1.37693920000000003e+03,
              6.13415000000000000e+04 5.51241000000000058e+04 1.41292110000000002e+03,
              6.63415000000000000e+04 5.51241000000000058e+04 1.38955200000000013e+03,
              7.13415000000000000e+04 5.51241000000000058e+04 1.40556369999999993e+03,
              7.63415000000000000e+04 5.51241000000000058e+04 1.22536809999999991e+03,
              8.13415000000000000e+04 5.51241000000000058e+04 1.19352629999999999e+03,
              8.63415000000000000e+04 5.51241000000000058e+04 1.23804240000000004e+03,
              4.32915000000000000e+04 5.50741000000000058e+04 1.24958390000000009e+03,
              4.82915000000000000e+04 5.50741000000000058e+04 1.31583989999999994e+03,
              5.32915000000000000e+04 5.50741000000000058e+04 1.30495450000000005e+03,
              5.82915000000000000e+04 5.50741000000000058e+04 1.38600909999999999e+03,
              6.32915000000000000e+04 5.50741000000000058e+04 1.40280099999999993e+03,
              6.82915000000000000e+04 5.50741000000000058e+04 1.37531840000000011e+03,
              7.32915000000000000e+04 5.50741000000000058e+04 1.42719709999999986e+03,
              7.82915000000000000e+04 5.50741000000000058e+04 1.19434590000000003e+03,
              8.32915000000000000e+04 5.50741000000000058e+04 1.19114539999999988e+03,
              8.82915000000000000e+04 5.50741000000000058e+04 1.15003199999999993e+03,
              4.52415000000000000e+04 5.50241000000000058e+04 1.26454510000000005e+03,
              5.02415000000000000e+04 5.50241000000000058e+04 1.26537699999999995e+03,
              5.52415000000000000e+04 5.50241000000000058e+04 1.36379999999999995e+03,
              6.02415000000000000e+04 5.50241000000000058e+04 1.41286940000000004e+03,
              6.52415000000000000e+04 5.50241000000000058e+04 1.38736000000000013e+03,
              7.02415000000000000e+04 5.50241000000000058e+04 1.41332310000000007e+03,
              7.52415000000000000e+04 5.50241000000000058e+04 1.30055780000000004e+03,
              8.02415000000000000e+04 5.50241000000000058e+04 1.22951299999999992e+03,
              8.52415000000000000e+04 5.50241000000000058e+04 1.19910629999999992e+03,
              9.02415000000000000e+04 5.50241000000000058e+04 1.14514169999999990e+03,
              4.71915000000000000e+04 5.49741000000000058e+04 1.31036819999999989e+03,
              5.21915000000000000e+04 5.49741000000000058e+04 1.28613909999999987e+03,
              5.71915000000000000e+04 5.49741000000000058e+04 1.40005629999999996e+03,
              6.21915000000000000e+04 5.49741000000000058e+04 1.36492930000000001e+03,
              6.71915000000000000e+04 5.49741000000000058e+04 1.38631359999999995e+03,
              7.21915000000000000e+04 5.49741000000000058e+04 1.42626330000000007e+03,
              7.71915000000000000e+04 5.49741000000000058e+04 1.21325469999999996e+03,
              8.21915000000000000e+04 5.49741000000000058e+04 1.18177800000000002e+03,
              8.71915000000000000e+04 5.49741000000000058e+04 1.16388889999999992e+03,
              4.41415000000000000e+04 5.49241000000000058e+04 1.25298980000000006e+03,
              4.91415000000000000e+04 5.49241000000000058e+04 1.30993910000000005e+03,
              5.41415000000000000e+04 5.49241000000000058e+04 1.35819250000000011e+03,
              5.91415000000000000e+04 5.49241000000000058e+04 1.41332500000000005e+03,
              6.41415000000000000e+04 5.49241000000000058e+04 1.39722270000000003e+03,
              6.91415000000000000e+04 5.49241000000000058e+04 1.36120219999999995e+03,
              7.41415000000000000e+04 5.49241000000000058e+04 1.34325890000000004e+03,
              7.91415000000000000e+04 5.49241000000000058e+04 1.21457480000000010e+03,
              8.41415000000000000e+04 5.49241000000000058e+04 1.20450749999999994e+03,
              8.91415000000000000e+04 5.49241000000000058e+04 1.14812519999999995e+03,
              4.60915000000000000e+04 5.48741000000000058e+04 1.28230330000000004e+03,
              5.10915000000000000e+04 5.48741000000000058e+04 1.32131610000000001e+03,
              5.60915000000000000e+04 5.48741000000000058e+04 1.37303430000000003e+03,
              6.10915000000000000e+04 5.48741000000000058e+04 1.43002790000000005e+03,
              6.60915000000000000e+04 5.48741000000000058e+04 1.39543389999999999e+03,
              7.10915000000000000e+04 5.48741000000000058e+04 1.41345659999999998e+03,
              7.60915000000000000e+04 5.48741000000000058e+04 1.23571209999999996e+03,
              8.10915000000000000e+04 5.48741000000000058e+04 1.20532449999999994e+03,
              8.60915000000000000e+04 5.48741000000000058e+04 1.19361040000000003e+03,
              4.30415000000000000e+04 5.48241000000000058e+04 1.22647029999999995e+03,
              4.80415000000000000e+04 5.48241000000000058e+04 1.30871450000000004e+03,
              5.30415000000000000e+04 5.48241000000000058e+04 1.34531819999999993e+03,
              5.80415000000000000e+04 5.48241000000000058e+04 1.39821350000000007e+03,
              6.30415000000000000e+04 5.48241000000000058e+04 1.39704999999999995e+03,
              6.80415000000000000e+04 5.48241000000000058e+04 1.37849949999999990e+03,
              7.30415000000000000e+04 5.48241000000000058e+04 1.41906410000000005e+03,
              7.80415000000000000e+04 5.48241000000000058e+04 1.20164229999999998e+03,
              8.30415000000000000e+04 5.48241000000000058e+04 1.18882490000000007e+03,
              8.80415000000000000e+04 5.48241000000000058e+04 1.15083179999999993e+03,
              4.49915000000000000e+04 5.47741000000000058e+04 1.26559580000000005e+03,
              4.99915000000000000e+04 5.47741000000000058e+04 1.31305770000000007e+03,
              5.49915000000000000e+04 5.47741000000000058e+04 1.34776160000000004e+03,
              5.99915000000000000e+04 5.47741000000000058e+04 1.41671230000000014e+03,
              6.49915000000000000e+04 5.47741000000000058e+04 1.39126170000000002e+03,
              6.99915000000000000e+04 5.47741000000000058e+04 1.39800070000000005e+03,
              7.49915000000000000e+04 5.47741000000000058e+04 1.30378549999999996e+03,
              7.99915000000000000e+04 5.47741000000000058e+04 1.22512490000000003e+03,
              8.49915000000000000e+04 5.47741000000000058e+04 1.18208009999999990e+03,
              8.99915000000000000e+04 5.47741000000000058e+04 1.14633109999999988e+03,
              4.69415000000000000e+04 5.47241000000000058e+04 1.28926209999999992e+03,
              5.19415000000000000e+04 5.47241000000000058e+04 1.31943010000000004e+03,
              5.69415000000000000e+04 5.47241000000000058e+04 1.39924450000000002e+03,
              6.19415000000000000e+04 5.47241000000000058e+04 1.37031449999999995e+03,
              6.69415000000000000e+04 5.47241000000000058e+04 1.37038499999999999e+03,
              7.19415000000000000e+04 5.47241000000000058e+04 1.41605410000000006e+03,
              7.69415000000000000e+04 5.47241000000000058e+04 1.22782580000000007e+03,
              8.19415000000000000e+04 5.47241000000000058e+04 1.19526890000000003e+03,
              8.69415000000000000e+04 5.47241000000000058e+04 1.19345879999999988e+03,
              4.38915000000000000e+04 5.46741000000000058e+04 1.23845200000000000e+03,
              4.88915000000000000e+04 5.46741000000000058e+04 1.30648209999999995e+03,
              5.38915000000000000e+04 5.46741000000000058e+04 1.35527700000000004e+03,
              5.88915000000000000e+04 5.46741000000000058e+04 1.40582809999999995e+03,
              6.38915000000000000e+04 5.46741000000000058e+04 1.39095229999999992e+03,
              6.88915000000000000e+04 5.46741000000000058e+04 1.32871389999999997e+03,
              7.38915000000000000e+04 5.46741000000000058e+04 1.32230999999999995e+03,
              7.88915000000000000e+04 5.46741000000000058e+04 1.20524260000000004e+03,
              8.38915000000000000e+04 5.46741000000000058e+04 1.21110050000000001e+03,
              8.88915000000000000e+04 5.46741000000000058e+04 1.14912010000000009e+03,
              4.58415000000000000e+04 5.46241000000000058e+04 1.27663270000000011e+03,
              5.08415000000000000e+04 5.46241000000000058e+04 1.28091989999999987e+03,
              5.58415000000000000e+04 5.46241000000000058e+04 1.36936099999999988e+03,
              6.08415000000000000e+04 5.46241000000000058e+04 1.43007880000000000e+03,
              6.58415000000000000e+04 5.46241000000000058e+04 1.38588000000000011e+03,
              7.08415000000000000e+04 5.46241000000000058e+04 1.41319810000000007e+03,
              7.58415000000000000e+04 5.46241000000000058e+04 1.23961239999999998e+03,
              8.08415000000000000e+04 5.46241000000000058e+04 1.20699839999999995e+03,
              8.58415000000000000e+04 5.46241000000000058e+04 1.17789059999999995e+03,
              9.08415000000000000e+04 5.46241000000000058e+04 1.15127430000000004e+03,
              4.77915000000000000e+04 5.45741000000000058e+04 1.31140409999999997e+03,
              5.27915000000000000e+04 5.45741000000000058e+04 1.34964689999999996e+03,
              5.77915000000000000e+04 5.45741000000000058e+04 1.38772759999999994e+03,
              6.27915000000000000e+04 5.45741000000000058e+04 1.38886310000000003e+03,
              6.77915000000000000e+04 5.45741000000000058e+04 1.36386570000000006e+03,
              7.27915000000000000e+04 5.45741000000000058e+04 1.41511239999999998e+03,
              7.77915000000000000e+04 5.45741000000000058e+04 1.20532549999999992e+03,
              8.27915000000000000e+04 5.45741000000000058e+04 1.20237210000000005e+03,
              8.77915000000000000e+04 5.45741000000000058e+04 1.14666599999999994e+03,
              4.47415000000000000e+04 5.45241000000000058e+04 1.25827800000000002e+03,
              4.97415000000000000e+04 5.45241000000000058e+04 1.34203870000000006e+03,
              5.47415000000000000e+04 5.45241000000000058e+04 1.36159969999999998e+03,
              5.97415000000000000e+04 5.45241000000000058e+04 1.41188450000000012e+03,
              6.47415000000000000e+04 5.45241000000000058e+04 1.38502620000000002e+03,
              6.97415000000000000e+04 5.45241000000000058e+04 1.38496839999999997e+03,
              7.47415000000000000e+04 5.45241000000000058e+04 1.39648059999999987e+03,
              7.97415000000000000e+04 5.45241000000000058e+04 1.21212359999999990e+03,
              8.47415000000000000e+04 5.45241000000000058e+04 1.17717660000000001e+03,
              8.97415000000000000e+04 5.45241000000000058e+04 1.15183349999999996e+03,
              4.66915000000000000e+04 5.44741000000000058e+04 1.27737640000000010e+03,
              5.16915000000000000e+04 5.44741000000000058e+04 1.34208130000000006e+03,
              5.66915000000000000e+04 5.44741000000000058e+04 1.38858559999999989e+03,
              6.16915000000000000e+04 5.44741000000000058e+04 1.37070980000000009e+03,
              6.66915000000000000e+04 5.44741000000000058e+04 1.38598500000000013e+03,
              7.16915000000000000e+04 5.44741000000000058e+04 1.41643239999999992e+03,
              7.66915000000000000e+04 5.44741000000000058e+04 1.22496650000000000e+03,
              8.16915000000000000e+04 5.44741000000000058e+04 1.19077209999999991e+03,
              8.66915000000000000e+04 5.44741000000000058e+04 1.23446700000000010e+03,
              4.36415000000000000e+04 5.44241000000000058e+04 1.23509349999999995e+03,
              4.86415000000000000e+04 5.44241000000000058e+04 1.28425630000000001e+03,
              5.36415000000000000e+04 5.44241000000000058e+04 1.35699659999999994e+03,
              5.86415000000000000e+04 5.44241000000000058e+04 1.38678749999999991e+03,
              6.36415000000000000e+04 5.44241000000000058e+04 1.38859560000000010e+03,
              6.86415000000000000e+04 5.44241000000000058e+04 1.35580220000000008e+03,
              7.36415000000000000e+04 5.44241000000000058e+04 1.32753029999999990e+03,
              7.86415000000000000e+04 5.44241000000000058e+04 1.18904880000000003e+03,
              8.36415000000000000e+04 5.44241000000000058e+04 1.21226829999999995e+03,
              8.86415000000000000e+04 5.44241000000000058e+04 1.14769340000000011e+03,
              4.55915000000000000e+04 5.43741000000000058e+04 1.26941940000000000e+03,
              5.05915000000000000e+04 5.43741000000000058e+04 1.34578340000000003e+03,
              5.55915000000000000e+04 5.43741000000000058e+04 1.37833580000000006e+03,
              6.05915000000000000e+04 5.43741000000000058e+04 1.42342049999999995e+03,
              6.55915000000000000e+04 5.43741000000000058e+04 1.36851999999999998e+03,
              7.05915000000000000e+04 5.43741000000000058e+04 1.40815039999999999e+03,
              7.55915000000000000e+04 5.43741000000000058e+04 1.25497630000000004e+03,
              8.05915000000000000e+04 5.43741000000000058e+04 1.22239580000000001e+03,
              8.55915000000000000e+04 5.43741000000000058e+04 1.18647180000000003e+03,
              9.05915000000000000e+04 5.43741000000000058e+04 1.14508989999999994e+03,
              4.75415000000000000e+04 5.43241000000000058e+04 1.29846720000000005e+03,
              5.25415000000000000e+04 5.43241000000000058e+04 1.35672929999999997e+03,
              5.75415000000000000e+04 5.43241000000000058e+04 1.38277890000000002e+03,
              6.25415000000000000e+04 5.43241000000000058e+04 1.38122160000000008e+03,
              6.75415000000000000e+04 5.43241000000000058e+04 1.38133979999999997e+03,
              7.25415000000000000e+04 5.43241000000000058e+04 1.41634140000000002e+03,
              7.75415000000000000e+04 5.43241000000000058e+04 1.21449170000000004e+03,
              8.25415000000000000e+04 5.43241000000000058e+04 1.20483709999999996e+03,
              8.75415000000000000e+04 5.43241000000000058e+04 1.14666599999999994e+03,
              4.44915000000000000e+04 5.42741000000000058e+04 1.23666039999999998e+03,
              4.94915000000000000e+04 5.42741000000000058e+04 1.33486789999999996e+03,
              5.44915000000000000e+04 5.42741000000000058e+04 1.36762920000000008e+03,
              5.94915000000000000e+04 5.42741000000000058e+04 1.40529169999999999e+03,
              6.44915000000000000e+04 5.42741000000000058e+04 1.38467329999999993e+03,
              6.94915000000000000e+04 5.42741000000000058e+04 1.37750379999999996e+03,
              7.44915000000000000e+04 5.42741000000000058e+04 1.38422139999999990e+03,
              7.94915000000000000e+04 5.42741000000000058e+04 1.19970429999999988e+03,
              8.44915000000000000e+04 5.42741000000000058e+04 1.18791570000000002e+03,
              8.94915000000000000e+04 5.42741000000000058e+04 1.15928130000000010e+03,
              4.64415000000000000e+04 5.42241000000000058e+04 1.26651479999999992e+03,
              5.14415000000000000e+04 5.42241000000000058e+04 1.34201080000000002e+03,
              5.64415000000000000e+04 5.42241000000000058e+04 1.37887500000000000e+03,
              6.14415000000000000e+04 5.42241000000000058e+04 1.37671199999999999e+03,
              6.64415000000000000e+04 5.42241000000000058e+04 1.38878539999999998e+03,
              7.14415000000000000e+04 5.42241000000000058e+04 1.41096790000000010e+03,
              7.64415000000000000e+04 5.42241000000000058e+04 1.23562770000000000e+03,
              8.14415000000000000e+04 5.42241000000000058e+04 1.20257169999999996e+03,
              8.64415000000000000e+04 5.42241000000000058e+04 1.20464989999999989e+03,
              4.33915000000000000e+04 5.41741000000000058e+04 1.25410950000000003e+03,
              4.83915000000000000e+04 5.41741000000000058e+04 1.27774730000000000e+03,
              5.33915000000000000e+04 5.41741000000000058e+04 1.36124009999999998e+03,
              5.83915000000000000e+04 5.41741000000000058e+04 1.36428620000000001e+03,
              6.33915000000000000e+04 5.41741000000000058e+04 1.39487830000000008e+03,
              6.83915000000000000e+04 5.41741000000000058e+04 1.35812380000000007e+03,
              7.33915000000000000e+04 5.41741000000000058e+04 1.37277770000000010e+03,
              7.83915000000000000e+04 5.41741000000000058e+04 1.19967990000000009e+03,
              8.33915000000000000e+04 5.41741000000000058e+04 1.20074530000000004e+03,
              8.83915000000000000e+04 5.41741000000000058e+04 1.14715049999999997e+03,
              4.53415000000000000e+04 5.41241000000000058e+04 1.26137190000000010e+03,
              5.03415000000000000e+04 5.41241000000000058e+04 1.34378859999999986e+03,
              5.53415000000000000e+04 5.41241000000000058e+04 1.36945720000000006e+03,
              6.03415000000000000e+04 5.41241000000000058e+04 1.41116020000000003e+03,
              6.53415000000000000e+04 5.41241000000000058e+04 1.36915670000000000e+03,
              7.03415000000000000e+04 5.41241000000000058e+04 1.39657140000000004e+03,
              7.53415000000000000e+04 5.41241000000000058e+04 1.28253160000000003e+03,
              8.03415000000000000e+04 5.41241000000000058e+04 1.23252489999999989e+03,
              8.53415000000000000e+04 5.41241000000000058e+04 1.17717660000000001e+03,
              9.03415000000000000e+04 5.41241000000000058e+04 1.16295049999999992e+03,
              4.72915000000000000e+04 5.40741000000000058e+04 1.26370190000000002e+03,
              5.22915000000000000e+04 5.40741000000000058e+04 1.35566100000000006e+03,
              5.72915000000000000e+04 5.40741000000000058e+04 1.38078179999999998e+03,
              6.22915000000000000e+04 5.40741000000000058e+04 1.38096409999999992e+03,
              6.72915000000000000e+04 5.40741000000000058e+04 1.37217650000000003e+03,
              7.22915000000000000e+04 5.40741000000000058e+04 1.42421160000000009e+03,
              7.72915000000000000e+04 5.40741000000000058e+04 1.22391239999999993e+03,
              8.22915000000000000e+04 5.40741000000000058e+04 1.20087400000000002e+03,
              8.72915000000000000e+04 5.40741000000000058e+04 1.14666599999999994e+03,
              4.42415000000000000e+04 5.40241000000000058e+04 1.23635110000000009e+03,
              4.92415000000000000e+04 5.40241000000000058e+04 1.31780299999999988e+03,
              5.42415000000000000e+04 5.40241000000000058e+04 1.35864290000000005e+03,
              5.92415000000000000e+04 5.40241000000000058e+04 1.38623029999999994e+03,
              6.42415000000000000e+04 5.40241000000000058e+04 1.38155340000000001e+03,
              6.92415000000000000e+04 5.40241000000000058e+04 1.37396360000000004e+03,
              7.42415000000000000e+04 5.40241000000000058e+04 1.37430299999999988e+03,
              7.92415000000000000e+04 5.40241000000000058e+04 1.19443110000000001e+03,
              8.42415000000000000e+04 5.40241000000000058e+04 1.18510239999999999e+03,
              8.92415000000000000e+04 5.40241000000000058e+04 1.14653369999999995e+03,
              4.61915000000000000e+04 5.39741000000000058e+04 1.23851299999999992e+03,
              5.11915000000000000e+04 5.39741000000000058e+04 1.34241059999999993e+03,
              5.61915000000000000e+04 5.39741000000000058e+04 1.36862590000000000e+03,
              6.11915000000000000e+04 5.39741000000000058e+04 1.36884609999999998e+03,
              6.61915000000000000e+04 5.39741000000000058e+04 1.38617169999999987e+03,
              7.11915000000000000e+04 5.39741000000000058e+04 1.40257839999999987e+03,
              7.61915000000000000e+04 5.39741000000000058e+04 1.21754700000000003e+03,
              8.11915000000000000e+04 5.39741000000000058e+04 1.21870749999999998e+03,
              8.61915000000000000e+04 5.39741000000000058e+04 1.19639719999999988e+03,
              4.31415000000000000e+04 5.39241000000000058e+04 1.27713310000000001e+03,
              4.81415000000000000e+04 5.39241000000000058e+04 1.29339830000000006e+03,
              5.31415000000000000e+04 5.39241000000000058e+04 1.35979790000000003e+03,
              5.81415000000000000e+04 5.39241000000000058e+04 1.39228350000000000e+03,
              6.31415000000000000e+04 5.39241000000000058e+04 1.39014730000000009e+03,
              6.81415000000000000e+04 5.39241000000000058e+04 1.35874610000000007e+03,
              7.31415000000000000e+04 5.39241000000000058e+04 1.37785599999999999e+03,
              7.81415000000000000e+04 5.39241000000000058e+04 1.20724330000000009e+03,
              8.31415000000000000e+04 5.39241000000000058e+04 1.19668200000000002e+03,
              8.81415000000000000e+04 5.39241000000000058e+04 1.14704050000000007e+03,
              4.50915000000000000e+04 5.38741000000000058e+04 1.24784510000000000e+03,
              5.00915000000000000e+04 5.38741000000000058e+04 1.35983210000000008e+03,
              5.50915000000000000e+04 5.38741000000000058e+04 1.37029909999999995e+03,
              6.00915000000000000e+04 5.38741000000000058e+04 1.40091010000000006e+03,
              6.50915000000000000e+04 5.38741000000000058e+04 1.37754129999999986e+03,
              7.00915000000000000e+04 5.38741000000000058e+04 1.38652440000000001e+03,
              7.50915000000000000e+04 5.38741000000000058e+04 1.31936189999999988e+03,
              8.00915000000000000e+04 5.38741000000000058e+04 1.23164419999999996e+03,
              8.50915000000000000e+04 5.38741000000000058e+04 1.17717660000000001e+03,
              9.00915000000000000e+04 5.38741000000000058e+04 1.16659009999999989e+03,
              4.70415000000000000e+04 5.38241000000000058e+04 1.23552809999999999e+03,
              5.20415000000000000e+04 5.38241000000000058e+04 1.34858819999999992e+03,
              5.70415000000000000e+04 5.38241000000000058e+04 1.38595669999999996e+03,
              6.20415000000000000e+04 5.38241000000000058e+04 1.37739280000000008e+03,
              6.70415000000000000e+04 5.38241000000000058e+04 1.37606340000000000e+03,
              7.20415000000000000e+04 5.38241000000000058e+04 1.40638879999999995e+03,
              7.70415000000000000e+04 5.38241000000000058e+04 1.20865000000000009e+03,
              8.20415000000000000e+04 5.38241000000000058e+04 1.19421000000000004e+03,
              8.70415000000000000e+04 5.38241000000000058e+04 1.14705880000000002e+03,
              4.39915000000000000e+04 5.37741000000000058e+04 1.24479420000000005e+03,
              4.89915000000000000e+04 5.37741000000000058e+04 1.31342300000000000e+03,
              5.39915000000000000e+04 5.37741000000000058e+04 1.35396039999999994e+03,
              5.89915000000000000e+04 5.37741000000000058e+04 1.38592820000000006e+03,
              6.39915000000000000e+04 5.37741000000000058e+04 1.38262320000000000e+03,
              6.89915000000000000e+04 5.37741000000000058e+04 1.35629259999999999e+03,
              7.39915000000000000e+04 5.37741000000000058e+04 1.35871329999999989e+03,
              7.89915000000000000e+04 5.37741000000000058e+04 1.19193370000000004e+03,
              8.39915000000000000e+04 5.37741000000000058e+04 1.18667319999999995e+03,
              8.89915000000000000e+04 5.37741000000000058e+04 1.14982490000000007e+03,
              4.59415000000000000e+04 5.37241000000000058e+04 1.24751410000000010e+03,
              5.09415000000000000e+04 5.37241000000000058e+04 1.33749679999999989e+03,
              5.59415000000000000e+04 5.37241000000000058e+04 1.37002610000000004e+03,
              6.09415000000000000e+04 5.37241000000000058e+04 1.36261429999999996e+03,
              6.59415000000000000e+04 5.37241000000000058e+04 1.38429610000000002e+03,
              7.09415000000000000e+04 5.37241000000000058e+04 1.39440090000000009e+03,
              7.59415000000000000e+04 5.37241000000000058e+04 1.22920489999999995e+03,
              8.09415000000000000e+04 5.37241000000000058e+04 1.24100890000000004e+03,
              8.59415000000000000e+04 5.37241000000000058e+04 1.16023080000000004e+03,
              9.09415000000000000e+04 5.37241000000000058e+04 1.17793879999999990e+03,
              4.78915000000000000e+04 5.36741000000000058e+04 1.28818339999999989e+03,
              5.28915000000000000e+04 5.36741000000000058e+04 1.35682299999999987e+03,
              5.78915000000000000e+04 5.36741000000000058e+04 1.37860169999999994e+03,
              6.28915000000000000e+04 5.36741000000000058e+04 1.38815599999999995e+03,
              6.78915000000000000e+04 5.36741000000000058e+04 1.35367439999999988e+03,
              7.28915000000000000e+04 5.36741000000000058e+04 1.35764920000000006e+03,
              7.78915000000000000e+04 5.36741000000000058e+04 1.20650009999999997e+03,
              8.28915000000000000e+04 5.36741000000000058e+04 1.20406940000000009e+03,
              8.78915000000000000e+04 5.36741000000000058e+04 1.14851659999999993e+03,
              4.48415000000000000e+04 5.36241000000000058e+04 1.23949540000000002e+03,
              4.98415000000000000e+04 5.36241000000000058e+04 1.34784830000000011e+03,
              5.48415000000000000e+04 5.36241000000000058e+04 1.36033780000000002e+03,
              5.98415000000000000e+04 5.36241000000000058e+04 1.38458680000000004e+03,
              6.48415000000000000e+04 5.36241000000000058e+04 1.37800810000000001e+03,
              6.98415000000000000e+04 5.36241000000000058e+04 1.38064290000000005e+03,
              7.48415000000000000e+04 5.36241000000000058e+04 1.40743949999999995e+03,
              7.98415000000000000e+04 5.36241000000000058e+04 1.21763940000000002e+03,
              8.48415000000000000e+04 5.36241000000000058e+04 1.18974279999999999e+03,
              8.98415000000000000e+04 5.36241000000000058e+04 1.16623500000000013e+03,
              4.67915000000000000e+04 5.35741000000000058e+04 1.23591200000000003e+03,
              5.17915000000000000e+04 5.35741000000000058e+04 1.34151659999999993e+03,
              5.67915000000000000e+04 5.35741000000000058e+04 1.38292880000000014e+03,
              6.17915000000000000e+04 5.35741000000000058e+04 1.36497450000000003e+03,
              6.67915000000000000e+04 5.35741000000000058e+04 1.38495360000000005e+03,
              7.17915000000000000e+04 5.35741000000000058e+04 1.39992840000000001e+03,
              7.67915000000000000e+04 5.35741000000000058e+04 1.21480209999999988e+03,
              8.17915000000000000e+04 5.35741000000000058e+04 1.21503410000000008e+03,
              8.67915000000000000e+04 5.35741000000000058e+04 1.15309099999999989e+03,
              4.37415000000000000e+04 5.35241000000000058e+04 1.23479520000000002e+03,
              4.87415000000000000e+04 5.35241000000000058e+04 1.30947699999999986e+03,
              5.37415000000000000e+04 5.35241000000000058e+04 1.34366460000000006e+03,
              5.87415000000000000e+04 5.35241000000000058e+04 1.35277939999999990e+03,
              6.37415000000000000e+04 5.35241000000000058e+04 1.38656809999999996e+03,
              6.87415000000000000e+04 5.35241000000000058e+04 1.32856109999999990e+03,
              7.37415000000000000e+04 5.35241000000000058e+04 1.33681880000000001e+03,
              7.87415000000000000e+04 5.35241000000000058e+04 1.19844540000000006e+03,
              8.37415000000000000e+04 5.35241000000000058e+04 1.17448039999999992e+03,
              8.87415000000000000e+04 5.35241000000000058e+04 1.14812680000000000e+03,
              4.56915000000000000e+04 5.34741000000000058e+04 1.25353899999999999e+03,
              5.06915000000000000e+04 5.34741000000000058e+04 1.33162169999999992e+03,
              5.56915000000000000e+04 5.34741000000000058e+04 1.36214080000000013e+03,
              6.06915000000000000e+04 5.34741000000000058e+04 1.36282069999999999e+03,
              6.56915000000000000e+04 5.34741000000000058e+04 1.38220679999999993e+03,
              7.06915000000000000e+04 5.34741000000000058e+04 1.39286570000000006e+03,
              7.56915000000000000e+04 5.34741000000000058e+04 1.23242270000000008e+03,
              8.06915000000000000e+04 5.34741000000000058e+04 1.24245609999999988e+03,
              8.56915000000000000e+04 5.34741000000000058e+04 1.16284420000000000e+03,
              9.06915000000000000e+04 5.34741000000000058e+04 1.17729130000000009e+03,
              4.76415000000000000e+04 5.34241000000000058e+04 1.27144309999999996e+03,
              5.26415000000000000e+04 5.34241000000000058e+04 1.35131379999999990e+03,
              5.76415000000000000e+04 5.34241000000000058e+04 1.35775350000000003e+03,
              6.26415000000000000e+04 5.34241000000000058e+04 1.38510820000000012e+03,
              6.76415000000000000e+04 5.34241000000000058e+04 1.35962010000000009e+03,
              7.26415000000000000e+04 5.34241000000000058e+04 1.36240519999999992e+03,
              7.76415000000000000e+04 5.34241000000000058e+04 1.19355479999999989e+03,
              8.26415000000000000e+04 5.34241000000000058e+04 1.20397109999999998e+03,
              8.76415000000000000e+04 5.34241000000000058e+04 1.14988450000000012e+03,
              4.45915000000000000e+04 5.33741000000000058e+04 1.24170090000000005e+03,
              4.95915000000000000e+04 5.33741000000000058e+04 1.32696090000000004e+03,
              5.45915000000000000e+04 5.33741000000000058e+04 1.35327030000000013e+03,
              5.95915000000000000e+04 5.33741000000000058e+04 1.38404950000000008e+03,
              6.45915000000000000e+04 5.33741000000000058e+04 1.37973239999999987e+03,
              6.95915000000000000e+04 5.33741000000000058e+04 1.37436609999999996e+03,
              7.45915000000000000e+04 5.33741000000000058e+04 1.40371749999999997e+03,
              7.95915000000000000e+04 5.33741000000000058e+04 1.21534560000000010e+03,
              8.45915000000000000e+04 5.33741000000000058e+04 1.17266370000000006e+03,
              8.95915000000000000e+04 5.33741000000000058e+04 1.15870969999999988e+03,
              4.65415000000000000e+04 5.33241000000000058e+04 1.26104099999999994e+03,
              5.15415000000000000e+04 5.33241000000000058e+04 1.33737660000000005e+03,
              5.65415000000000000e+04 5.33241000000000058e+04 1.37021199999999999e+03,
              6.15415000000000000e+04 5.33241000000000058e+04 1.36640619999999990e+03,
              6.65415000000000000e+04 5.33241000000000058e+04 1.37161529999999993e+03,
              7.15415000000000000e+04 5.33241000000000058e+04 1.39774939999999992e+03,
              7.65415000000000000e+04 5.33241000000000058e+04 1.20809179999999992e+03,
              8.15415000000000000e+04 5.33241000000000058e+04 1.20496119999999996e+03,
              8.65415000000000000e+04 5.33241000000000058e+04 1.15126720000000000e+03,
              4.34915000000000000e+04 5.32741000000000058e+04 1.23627710000000002e+03,
              4.84915000000000000e+04 5.32741000000000058e+04 1.30517830000000004e+03,
              5.34915000000000000e+04 5.32741000000000058e+04 1.35215100000000007e+03,
              5.84915000000000000e+04 5.32741000000000058e+04 1.37215920000000006e+03,
              6.34915000000000000e+04 5.32741000000000058e+04 1.38506420000000003e+03,
              6.84915000000000000e+04 5.32741000000000058e+04 1.35853320000000008e+03,
              7.34915000000000000e+04 5.32741000000000058e+04 1.33808379999999988e+03,
              7.84915000000000000e+04 5.32741000000000058e+04 1.18552029999999991e+03,
              8.34915000000000000e+04 5.32741000000000058e+04 1.18008780000000002e+03,
              8.84915000000000000e+04 5.32741000000000058e+04 1.14525900000000001e+03,
              4.54415000000000000e+04 5.32241000000000058e+04 1.25112310000000002e+03,
              5.04415000000000000e+04 5.32241000000000058e+04 1.33459709999999995e+03,
              5.54415000000000000e+04 5.32241000000000058e+04 1.35410629999999992e+03,
              6.04415000000000000e+04 5.32241000000000058e+04 1.37760840000000007e+03,
              6.54415000000000000e+04 5.32241000000000058e+04 1.37488439999999991e+03,
              7.04415000000000000e+04 5.32241000000000058e+04 1.38857860000000005e+03,
              7.54415000000000000e+04 5.32241000000000058e+04 1.24767059999999992e+03,
              8.04415000000000000e+04 5.32241000000000058e+04 1.25062580000000003e+03,
              8.54415000000000000e+04 5.32241000000000058e+04 1.16184240000000000e+03,
              9.04415000000000000e+04 5.32241000000000058e+04 1.17806140000000005e+03,
              4.73915000000000000e+04 5.31741000000000058e+04 1.26324800000000005e+03,
              5.23915000000000000e+04 5.31741000000000058e+04 1.34545180000000005e+03,
              5.73915000000000000e+04 5.31741000000000058e+04 1.37079189999999994e+03,
              6.23915000000000000e+04 5.31741000000000058e+04 1.37944939999999997e+03,
              6.73915000000000000e+04 5.31741000000000058e+04 1.35849949999999990e+03,
              7.23915000000000000e+04 5.31741000000000058e+04 1.39373659999999995e+03,
              7.73915000000000000e+04 5.31741000000000058e+04 1.18773360000000002e+03,
              8.23915000000000000e+04 5.31741000000000058e+04 1.19686419999999998e+03,
              8.73915000000000000e+04 5.31741000000000058e+04 1.14905359999999996e+03,
              4.43415000000000000e+04 5.31241000000000058e+04 1.27946610000000010e+03,
              4.93415000000000000e+04 5.31241000000000058e+04 1.31979120000000012e+03,
              5.43415000000000000e+04 5.31241000000000058e+04 1.34592079999999987e+03,
              5.93415000000000000e+04 5.31241000000000058e+04 1.38635190000000011e+03,
              6.43415000000000000e+04 5.31241000000000058e+04 1.38361279999999988e+03,
              6.93415000000000000e+04 5.31241000000000058e+04 1.36717079999999987e+03,
              7.43415000000000000e+04 5.31241000000000058e+04 1.39807140000000004e+03,
              7.93415000000000000e+04 5.31241000000000058e+04 1.20507230000000004e+03,
              8.43415000000000000e+04 5.31241000000000058e+04 1.16520810000000006e+03,
              8.93415000000000000e+04 5.31241000000000058e+04 1.15453719999999998e+03,
              4.62915000000000000e+04 5.30741000000000058e+04 1.26874240000000009e+03,
              5.12915000000000000e+04 5.30741000000000058e+04 1.33168530000000010e+03,
              5.62915000000000000e+04 5.30741000000000058e+04 1.36836650000000009e+03,
              6.12915000000000000e+04 5.30741000000000058e+04 1.37239300000000003e+03,
              6.62915000000000000e+04 5.30741000000000058e+04 1.36229840000000013e+03,
              7.12915000000000000e+04 5.30741000000000058e+04 1.39133989999999994e+03,
              7.62915000000000000e+04 5.30741000000000058e+04 1.20885619999999994e+03,
              8.12915000000000000e+04 5.30741000000000058e+04 1.20882989999999995e+03,
              8.62915000000000000e+04 5.30741000000000058e+04 1.15257880000000000e+03,
              4.32415000000000000e+04 5.30241000000000058e+04 1.25076970000000006e+03,
              4.82415000000000000e+04 5.30241000000000058e+04 1.29785509999999999e+03,
              5.32415000000000000e+04 5.30241000000000058e+04 1.34775500000000011e+03,
              5.82415000000000000e+04 5.30241000000000058e+04 1.35241640000000007e+03,
              6.32415000000000000e+04 5.30241000000000058e+04 1.38801839999999993e+03,
              6.82415000000000000e+04 5.30241000000000058e+04 1.35299910000000000e+03,
              7.32415000000000000e+04 5.30241000000000058e+04 1.34413040000000001e+03,
              7.82415000000000000e+04 5.30241000000000058e+04 1.18298309999999992e+03,
              8.32415000000000000e+04 5.30241000000000058e+04 1.17742339999999990e+03,
              8.82415000000000000e+04 5.30241000000000058e+04 1.14659390000000008e+03,
              4.51915000000000000e+04 5.29741000000000058e+04 1.26330529999999999e+03,
              5.01915000000000000e+04 5.29741000000000058e+04 1.32842079999999987e+03,
              5.51915000000000000e+04 5.29741000000000058e+04 1.34728890000000001e+03,
              6.01915000000000000e+04 5.29741000000000058e+04 1.38007600000000002e+03,
              6.51915000000000000e+04 5.29741000000000058e+04 1.37281449999999995e+03,
              7.01915000000000000e+04 5.29741000000000058e+04 1.38092079999999987e+03,
              7.51915000000000000e+04 5.29741000000000058e+04 1.25959860000000003e+03,
              8.01915000000000000e+04 5.29741000000000058e+04 1.23954410000000007e+03,
              8.51915000000000000e+04 5.29741000000000058e+04 1.16783570000000009e+03,
              9.01915000000000000e+04 5.29741000000000058e+04 1.17015769999999998e+03,
              4.71415000000000000e+04 5.29241000000000058e+04 1.24668170000000009e+03,
              5.21415000000000000e+04 5.29241000000000058e+04 1.33629530000000000e+03,
              5.71415000000000000e+04 5.29241000000000058e+04 1.36553099999999995e+03,
              6.21415000000000000e+04 5.29241000000000058e+04 1.38521260000000007e+03,
              6.71415000000000000e+04 5.29241000000000058e+04 1.34839899999999989e+03,
              7.21415000000000000e+04 5.29241000000000058e+04 1.39458289999999988e+03,
              7.71415000000000000e+04 5.29241000000000058e+04 1.19879989999999998e+03,
              8.21415000000000000e+04 5.29241000000000058e+04 1.19471160000000009e+03,
              8.71415000000000000e+04 5.29241000000000058e+04 1.14810789999999997e+03,
              4.40915000000000000e+04 5.28741000000000058e+04 1.26383699999999999e+03,
              4.90915000000000000e+04 5.28741000000000058e+04 1.30905410000000006e+03,
              5.40915000000000000e+04 5.28741000000000058e+04 1.33794129999999996e+03,
              5.90915000000000000e+04 5.28741000000000058e+04 1.39074910000000000e+03,
              6.40915000000000000e+04 5.28741000000000058e+04 1.38336320000000001e+03,
              6.90915000000000000e+04 5.28741000000000058e+04 1.35906449999999995e+03,
              7.40915000000000000e+04 5.28741000000000058e+04 1.39747919999999999e+03,
              7.90915000000000000e+04 5.28741000000000058e+04 1.19066589999999997e+03,
              8.40915000000000000e+04 5.28741000000000058e+04 1.17016290000000004e+03,
              8.90915000000000000e+04 5.28741000000000058e+04 1.14944380000000001e+03,
              4.60415000000000000e+04 5.28241000000000058e+04 1.25479690000000005e+03,
              5.10415000000000000e+04 5.28241000000000058e+04 1.32380400000000009e+03,
              5.60415000000000000e+04 5.28241000000000058e+04 1.36519000000000005e+03,
              6.10415000000000000e+04 5.28241000000000058e+04 1.37455549999999994e+03,
              6.60415000000000000e+04 5.28241000000000058e+04 1.36631670000000008e+03,
              7.10415000000000000e+04 5.28241000000000058e+04 1.38721119999999996e+03,
              7.60415000000000000e+04 5.28241000000000058e+04 1.21377649999999994e+03,
              8.10415000000000000e+04 5.28241000000000058e+04 1.20180099999999993e+03,
              8.60415000000000000e+04 5.28241000000000058e+04 1.14899109999999996e+03,
              4.29915000000000000e+04 5.27741000000000058e+04 1.25521309999999994e+03,
              4.79915000000000000e+04 5.27741000000000058e+04 1.28301610000000005e+03,
              5.29915000000000000e+04 5.27741000000000058e+04 1.33818010000000004e+03,
              5.79915000000000000e+04 5.27741000000000058e+04 1.32822270000000003e+03,
              6.29915000000000000e+04 5.27741000000000058e+04 1.38753819999999996e+03,
              6.79915000000000000e+04 5.27741000000000058e+04 1.35152549999999997e+03,
              7.29915000000000000e+04 5.27741000000000058e+04 1.37459009999999989e+03,
              7.79915000000000000e+04 5.27741000000000058e+04 1.18166840000000002e+03,
              8.29915000000000000e+04 5.27741000000000058e+04 1.18868519999999990e+03,
              8.79915000000000000e+04 5.27741000000000058e+04 1.14838940000000002e+03,
              4.49415000000000000e+04 5.27241000000000058e+04 1.28957429999999999e+03,
              4.99415000000000000e+04 5.27241000000000058e+04 1.32235460000000012e+03,
              5.49415000000000000e+04 5.27241000000000058e+04 1.34064490000000001e+03,
              5.99415000000000000e+04 5.27241000000000058e+04 1.36734899999999993e+03,
              6.49415000000000000e+04 5.27241000000000058e+04 1.37333819999999992e+03,
              6.99415000000000000e+04 5.27241000000000058e+04 1.37177399999999989e+03,
              7.49415000000000000e+04 5.27241000000000058e+04 1.29331950000000006e+03,
              7.99415000000000000e+04 5.27241000000000058e+04 1.23324620000000004e+03,
              8.49415000000000000e+04 5.27241000000000058e+04 1.16233789999999999e+03,
              8.99415000000000000e+04 5.27241000000000058e+04 1.16402999999999997e+03,
              4.68915000000000000e+04 5.26741000000000058e+04 1.27980809999999997e+03,
              5.18915000000000000e+04 5.26741000000000058e+04 1.31779860000000008e+03,
              5.68915000000000000e+04 5.26741000000000058e+04 1.36967169999999987e+03,
              6.18915000000000000e+04 5.26741000000000058e+04 1.38378639999999996e+03,
              6.68915000000000000e+04 5.26741000000000058e+04 1.34456780000000003e+03,
              7.18915000000000000e+04 5.26741000000000058e+04 1.39195540000000005e+03,
              7.68915000000000000e+04 5.26741000000000058e+04 1.19298309999999992e+03,
              8.18915000000000000e+04 5.26741000000000058e+04 1.19723769999999990e+03,
              8.68915000000000000e+04 5.26741000000000058e+04 1.14703700000000003e+03,
              4.38415000000000000e+04 5.26241000000000058e+04 1.24587079999999992e+03,
              4.88415000000000000e+04 5.26241000000000058e+04 1.30446180000000004e+03,
              5.38415000000000000e+04 5.26241000000000058e+04 1.34095929999999998e+03,
              5.88415000000000000e+04 5.26241000000000058e+04 1.37658169999999996e+03,
              6.38415000000000000e+04 5.26241000000000058e+04 1.38063889999999992e+03,
              6.88415000000000000e+04 5.26241000000000058e+04 1.35430999999999995e+03,
              7.38415000000000000e+04 5.26241000000000058e+04 1.39545280000000002e+03,
              7.88415000000000000e+04 5.26241000000000058e+04 1.17727369999999996e+03,
              8.38415000000000000e+04 5.26241000000000058e+04 1.17041280000000006e+03,
              8.88415000000000000e+04 5.26241000000000058e+04 1.14828099999999995e+03,
              4.57915000000000000e+04 5.25741000000000058e+04 1.27368440000000010e+03,
              5.07915000000000000e+04 5.25741000000000058e+04 1.32271990000000005e+03,
              5.57915000000000000e+04 5.25741000000000058e+04 1.36163819999999987e+03,
              6.07915000000000000e+04 5.25741000000000058e+04 1.36578189999999995e+03,
              6.57915000000000000e+04 5.25741000000000058e+04 1.36038139999999999e+03,
              7.07915000000000000e+04 5.25741000000000058e+04 1.38763290000000006e+03,
              7.57915000000000000e+04 5.25741000000000058e+04 1.21618620000000010e+03,
              8.07915000000000000e+04 5.25741000000000058e+04 1.20406490000000008e+03,
              8.57915000000000000e+04 5.25741000000000058e+04 1.15362169999999992e+03,
              9.07915000000000000e+04 5.25741000000000058e+04 1.18845560000000000e+03,
              4.77415000000000000e+04 5.25241000000000058e+04 1.25401260000000002e+03,
              5.27415000000000000e+04 5.25241000000000058e+04 1.32478899999999999e+03,
              5.77415000000000000e+04 5.25241000000000058e+04 1.34383269999999993e+03,
              6.27415000000000000e+04 5.25241000000000058e+04 1.38529819999999995e+03,
              6.77415000000000000e+04 5.25241000000000058e+04 1.33760539999999992e+03,
              7.27415000000000000e+04 5.25241000000000058e+04 1.37169450000000006e+03,
              7.77415000000000000e+04 5.25241000000000058e+04 1.19171340000000009e+03,
              8.27415000000000000e+04 5.25241000000000058e+04 1.18815309999999999e+03,
              8.77415000000000000e+04 5.25241000000000058e+04 1.14837200000000007e+03,
              4.46915000000000000e+04 5.24741000000000058e+04 1.28326340000000005e+03,
              4.96915000000000000e+04 5.24741000000000058e+04 1.31416049999999996e+03,
              5.46915000000000000e+04 5.24741000000000058e+04 1.32703999999999996e+03,
              5.96915000000000000e+04 5.24741000000000058e+04 1.36644880000000012e+03,
              6.46915000000000000e+04 5.24741000000000058e+04 1.37591719999999987e+03,
              6.96915000000000000e+04 5.24741000000000058e+04 1.36715580000000000e+03,
              7.46915000000000000e+04 5.24741000000000058e+04 1.28416940000000000e+03,
              7.96915000000000000e+04 5.24741000000000058e+04 1.21146090000000004e+03,
              8.46915000000000000e+04 5.24741000000000058e+04 1.16447319999999991e+03,
              8.96915000000000000e+04 5.24741000000000058e+04 1.16707510000000002e+03,
              4.66415000000000000e+04 5.24241000000000058e+04 1.27859459999999990e+03,
              5.16415000000000000e+04 5.24241000000000058e+04 1.32020939999999996e+03,
              5.66415000000000000e+04 5.24241000000000058e+04 1.36203539999999998e+03,
              6.16415000000000000e+04 5.24241000000000058e+04 1.37227880000000005e+03,
              6.66415000000000000e+04 5.24241000000000058e+04 1.34487410000000000e+03,
              7.16415000000000000e+04 5.24241000000000058e+04 1.39127330000000006e+03,
              7.66415000000000000e+04 5.24241000000000058e+04 1.19680580000000009e+03,
              8.16415000000000000e+04 5.24241000000000058e+04 1.19219160000000011e+03,
              8.66415000000000000e+04 5.24241000000000058e+04 1.14597399999999993e+03,
              4.35915000000000000e+04 5.23741000000000058e+04 1.24290249999999992e+03,
              4.85915000000000000e+04 5.23741000000000058e+04 1.29318460000000005e+03,
              5.35915000000000000e+04 5.23741000000000058e+04 1.33054719999999998e+03,
              5.85915000000000000e+04 5.23741000000000058e+04 1.33081539999999995e+03,
              6.35915000000000000e+04 5.23741000000000058e+04 1.37926569999999992e+03,
              6.85915000000000000e+04 5.23741000000000058e+04 1.33308280000000013e+03,
              7.35915000000000000e+04 5.23741000000000058e+04 1.38636689999999999e+03,
              7.85915000000000000e+04 5.23741000000000058e+04 1.17838820000000010e+03,
              8.35915000000000000e+04 5.23741000000000058e+04 1.16099139999999989e+03,
              8.85915000000000000e+04 5.23741000000000058e+04 1.14864899999999989e+03,
              4.55415000000000000e+04 5.23241000000000058e+04 1.28933559999999989e+03,
              5.05415000000000000e+04 5.23241000000000058e+04 1.31499530000000004e+03,
              5.55415000000000000e+04 5.23241000000000058e+04 1.35340010000000007e+03,
              6.05415000000000000e+04 5.23241000000000058e+04 1.34956330000000003e+03,
              6.55415000000000000e+04 5.23241000000000058e+04 1.36578189999999995e+03,
              7.05415000000000000e+04 5.23241000000000058e+04 1.38301929999999993e+03,
              7.55415000000000000e+04 5.23241000000000058e+04 1.22802839999999992e+03,
              8.05415000000000000e+04 5.23241000000000058e+04 1.19513419999999996e+03,
              8.55415000000000000e+04 5.23241000000000058e+04 1.15501880000000006e+03,
              9.05415000000000000e+04 5.23241000000000058e+04 1.19417660000000001e+03,
              4.74915000000000000e+04 5.22741000000000058e+04 1.28299540000000002e+03,
              5.24915000000000000e+04 5.22741000000000058e+04 1.31145630000000006e+03,
              5.74915000000000000e+04 5.22741000000000058e+04 1.34914280000000008e+03,
              6.24915000000000000e+04 5.22741000000000058e+04 1.38986540000000014e+03,
              6.74915000000000000e+04 5.22741000000000058e+04 1.34291750000000002e+03,
              7.24915000000000000e+04 5.22741000000000058e+04 1.39803590000000008e+03,
              7.74915000000000000e+04 5.22741000000000058e+04 1.20473209999999995e+03,
              8.24915000000000000e+04 5.22741000000000058e+04 1.16854639999999995e+03,
              8.74915000000000000e+04 5.22741000000000058e+04 1.14746100000000001e+03,
              4.44415000000000000e+04 5.22241000000000058e+04 1.27282709999999997e+03,
              4.94415000000000000e+04 5.22241000000000058e+04 1.30553800000000001e+03,
              5.44415000000000000e+04 5.22241000000000058e+04 1.33708719999999994e+03,
              5.94415000000000000e+04 5.22241000000000058e+04 1.37194200000000001e+03,
              6.44415000000000000e+04 5.22241000000000058e+04 1.37085130000000004e+03,
              6.94415000000000000e+04 5.22241000000000058e+04 1.35997420000000011e+03,
              7.44415000000000000e+04 5.22241000000000058e+04 1.30095380000000000e+03,
              7.94415000000000000e+04 5.22241000000000058e+04 1.17245000000000005e+03,
              8.44415000000000000e+04 5.22241000000000058e+04 1.16016920000000005e+03,
              8.94415000000000000e+04 5.22241000000000058e+04 1.16089349999999990e+03,
              4.63915000000000000e+04 5.21741000000000058e+04 1.26641899999999987e+03,
              5.13915000000000000e+04 5.21741000000000058e+04 1.29563419999999996e+03,
              5.63915000000000000e+04 5.21741000000000058e+04 1.35409410000000003e+03,
              6.13915000000000000e+04 5.21741000000000058e+04 1.38605430000000001e+03,
              6.63915000000000000e+04 5.21741000000000058e+04 1.34569489999999996e+03,
              7.13915000000000000e+04 5.21741000000000058e+04 1.38881099999999992e+03,
              7.63915000000000000e+04 5.21741000000000058e+04 1.19455220000000008e+03,
              8.13915000000000000e+04 5.21741000000000058e+04 1.20677260000000001e+03,
              8.63915000000000000e+04 5.21741000000000058e+04 1.15128729999999996e+03,
              4.33415000000000000e+04 5.21241000000000058e+04 1.25343000000000006e+03,
              4.83415000000000000e+04 5.21241000000000058e+04 1.28884780000000001e+03,
              5.33415000000000000e+04 5.21241000000000058e+04 1.32157179999999994e+03,
              5.83415000000000000e+04 5.21241000000000058e+04 1.36002369999999996e+03,
              6.33415000000000000e+04 5.21241000000000058e+04 1.37942190000000005e+03,
              6.83415000000000000e+04 5.21241000000000058e+04 1.33168740000000003e+03,
              7.33415000000000000e+04 5.21241000000000058e+04 1.38428829999999994e+03,
              7.83415000000000000e+04 5.21241000000000058e+04 1.17539699999999993e+03,
              8.33415000000000000e+04 5.21241000000000058e+04 1.15734709999999995e+03,
              8.83415000000000000e+04 5.21241000000000058e+04 1.14730250000000001e+03,
              4.52915000000000000e+04 5.20741000000000058e+04 1.29383370000000014e+03,
              5.02915000000000000e+04 5.20741000000000058e+04 1.30384269999999992e+03,
              5.52915000000000000e+04 5.20741000000000058e+04 1.34829950000000008e+03,
              6.02915000000000000e+04 5.20741000000000058e+04 1.35684110000000010e+03,
              6.52915000000000000e+04 5.20741000000000058e+04 1.35866000000000008e+03,
              7.02915000000000000e+04 5.20741000000000058e+04 1.38216190000000006e+03,
              7.52915000000000000e+04 5.20741000000000058e+04 1.22748919999999998e+03,
              8.02915000000000000e+04 5.20741000000000058e+04 1.17556799999999998e+03,
              8.52915000000000000e+04 5.20741000000000058e+04 1.15350410000000011e+03,
              9.02915000000000000e+04 5.20741000000000058e+04 1.18921129999999994e+03,
              4.72415000000000000e+04 5.20241000000000058e+04 1.28150959999999986e+03,
              5.22415000000000000e+04 5.20241000000000058e+04 1.30923569999999995e+03,
              5.72415000000000000e+04 5.20241000000000058e+04 1.35220290000000000e+03,
              6.22415000000000000e+04 5.20241000000000058e+04 1.38521880000000010e+03,
              6.72415000000000000e+04 5.20241000000000058e+04 1.33738139999999999e+03,
              7.22415000000000000e+04 5.20241000000000058e+04 1.39731310000000008e+03,
              7.72415000000000000e+04 5.20241000000000058e+04 1.19886740000000009e+03,
              8.22415000000000000e+04 5.20241000000000058e+04 1.16845409999999993e+03,
              8.72415000000000000e+04 5.20241000000000058e+04 1.14972229999999990e+03,
              4.41915000000000000e+04 5.19741000000000058e+04 1.25149960000000010e+03,
              4.91915000000000000e+04 5.19741000000000058e+04 1.25222360000000003e+03,
              5.41915000000000000e+04 5.19741000000000058e+04 1.33933629999999994e+03,
              5.91915000000000000e+04 5.19741000000000058e+04 1.36049180000000001e+03,
              6.41915000000000000e+04 5.19741000000000058e+04 1.36920669999999996e+03,
              6.91915000000000000e+04 5.19741000000000058e+04 1.36916399999999999e+03,
              7.41915000000000000e+04 5.19741000000000058e+04 1.31732569999999987e+03,
              7.91915000000000000e+04 5.19741000000000058e+04 1.16795070000000010e+03,
              8.41915000000000000e+04 5.19741000000000058e+04 1.15542070000000012e+03,
              8.91915000000000000e+04 5.19741000000000058e+04 1.15548029999999994e+03,
              4.61415000000000000e+04 5.19241000000000058e+04 1.27107740000000013e+03,
              5.11415000000000000e+04 5.19241000000000058e+04 1.28056199999999990e+03,
              5.61415000000000000e+04 5.19241000000000058e+04 1.35109369999999990e+03,
              6.11415000000000000e+04 5.19241000000000058e+04 1.38632320000000004e+03,
              6.61415000000000000e+04 5.19241000000000058e+04 1.33927770000000010e+03,
              7.11415000000000000e+04 5.19241000000000058e+04 1.38317540000000008e+03,
              7.61415000000000000e+04 5.19241000000000058e+04 1.20079430000000002e+03,
              8.11415000000000000e+04 5.19241000000000058e+04 1.18698340000000007e+03,
              8.61415000000000000e+04 5.19241000000000058e+04 1.14681300000000010e+03,
              4.30915000000000000e+04 5.18741000000000058e+04 1.26587740000000008e+03,
              4.80915000000000000e+04 5.18741000000000058e+04 1.25047610000000009e+03,
              5.30915000000000000e+04 5.18741000000000058e+04 1.31708249999999998e+03,
              5.80915000000000000e+04 5.18741000000000058e+04 1.38199700000000007e+03,
              6.30915000000000000e+04 5.18741000000000058e+04 1.37727279999999996e+03,
              6.80915000000000000e+04 5.18741000000000058e+04 1.33330999999999995e+03,
              7.30915000000000000e+04 5.18741000000000058e+04 1.38415059999999994e+03,
              7.80915000000000000e+04 5.18741000000000058e+04 1.17536020000000008e+03,
              8.30915000000000000e+04 5.18741000000000058e+04 1.15667579999999998e+03,
              8.80915000000000000e+04 5.18741000000000058e+04 1.14775019999999995e+03,
              4.50415000000000000e+04 5.18241000000000058e+04 1.28462300000000005e+03,
              5.00415000000000000e+04 5.18241000000000058e+04 1.28103539999999998e+03,
              5.50415000000000000e+04 5.18241000000000058e+04 1.34171790000000010e+03,
              6.00415000000000000e+04 5.18241000000000058e+04 1.36421280000000002e+03,
              6.50415000000000000e+04 5.18241000000000058e+04 1.35451720000000000e+03,
              7.00415000000000000e+04 5.18241000000000058e+04 1.37422730000000001e+03,
              7.50415000000000000e+04 5.18241000000000058e+04 1.22841429999999991e+03,
              8.00415000000000000e+04 5.18241000000000058e+04 1.17907700000000000e+03,
              8.50415000000000000e+04 5.18241000000000058e+04 1.15356400000000008e+03,
              9.00415000000000000e+04 5.18241000000000058e+04 1.17249669999999992e+03,
              4.69915000000000000e+04 5.17741000000000058e+04 1.28878999999999996e+03,
              5.19915000000000000e+04 5.17741000000000058e+04 1.28095159999999987e+03,
              5.69915000000000000e+04 5.17741000000000058e+04 1.35918470000000002e+03,
              6.19915000000000000e+04 5.17741000000000058e+04 1.38109960000000001e+03,
              6.69915000000000000e+04 5.17741000000000058e+04 1.33138940000000002e+03,
              7.19915000000000000e+04 5.17741000000000058e+04 1.40023160000000007e+03,
              7.69915000000000000e+04 5.17741000000000058e+04 1.19447970000000009e+03,
              8.19915000000000000e+04 5.17741000000000058e+04 1.18627240000000006e+03,
              8.69915000000000000e+04 5.17741000000000058e+04 1.14936650000000009e+03,
              4.39415000000000000e+04 5.17241000000000058e+04 1.24403389999999990e+03,
              4.89415000000000000e+04 5.17241000000000058e+04 1.27897870000000012e+03,
              5.39415000000000000e+04 5.17241000000000058e+04 1.32793509999999992e+03,
              5.89415000000000000e+04 5.17241000000000058e+04 1.39361120000000005e+03,
              6.39415000000000000e+04 5.17241000000000058e+04 1.36135059999999999e+03,
              6.89415000000000000e+04 5.17241000000000058e+04 1.35381700000000001e+03,
              7.39415000000000000e+04 5.17241000000000058e+04 1.35175569999999993e+03,
              7.89415000000000000e+04 5.17241000000000058e+04 1.17246710000000007e+03,
              8.39415000000000000e+04 5.17241000000000058e+04 1.15112789999999995e+03,
              8.89415000000000000e+04 5.17241000000000058e+04 1.16245699999999988e+03,
              4.58915000000000000e+04 5.16741000000000058e+04 1.29922340000000008e+03,
              5.08915000000000000e+04 5.16741000000000058e+04 1.26481230000000005e+03,
              5.58915000000000000e+04 5.16741000000000058e+04 1.34553760000000011e+03,
              6.08915000000000000e+04 5.16741000000000058e+04 1.38139040000000000e+03,
              6.58915000000000000e+04 5.16741000000000058e+04 1.33770540000000005e+03,
              7.08915000000000000e+04 5.16741000000000058e+04 1.37834680000000003e+03,
              7.58915000000000000e+04 5.16741000000000058e+04 1.20407050000000004e+03,
              8.08915000000000000e+04 5.16741000000000058e+04 1.17536629999999991e+03,
              8.58915000000000000e+04 5.16741000000000058e+04 1.14890810000000010e+03,
              9.08915000000000000e+04 5.16741000000000058e+04 1.18484140000000002e+03,
              4.78415000000000000e+04 5.16241000000000058e+04 1.29877219999999988e+03,
              5.28415000000000000e+04 5.16241000000000058e+04 1.28885519999999997e+03,
              5.78415000000000000e+04 5.16241000000000058e+04 1.37834979999999996e+03,
              6.28415000000000000e+04 5.16241000000000058e+04 1.37885400000000004e+03,
              6.78415000000000000e+04 5.16241000000000058e+04 1.32937439999999992e+03,
              7.28415000000000000e+04 5.16241000000000058e+04 1.39828610000000003e+03,
              7.78415000000000000e+04 5.16241000000000058e+04 1.17970309999999995e+03,
              8.28415000000000000e+04 5.16241000000000058e+04 1.15555829999999992e+03,
              8.78415000000000000e+04 5.16241000000000058e+04 1.14790730000000008e+03,
              4.47915000000000000e+04 5.15741000000000058e+04 1.27385500000000002e+03,
              4.97915000000000000e+04 5.15741000000000058e+04 1.26995879999999988e+03,
              5.47915000000000000e+04 5.15741000000000058e+04 1.33258950000000004e+03,
              5.97915000000000000e+04 5.15741000000000058e+04 1.36305479999999989e+03,
              6.47915000000000000e+04 5.15741000000000058e+04 1.35413930000000005e+03,
              6.97915000000000000e+04 5.15741000000000058e+04 1.36989059999999995e+03,
              7.47915000000000000e+04 5.15741000000000058e+04 1.23056140000000005e+03,
              7.97915000000000000e+04 5.15741000000000058e+04 1.16857680000000005e+03,
              8.47915000000000000e+04 5.15741000000000058e+04 1.15205549999999994e+03,
              8.97915000000000000e+04 5.15741000000000058e+04 1.18397600000000011e+03,
              4.67415000000000000e+04 5.15241000000000058e+04 1.28117669999999998e+03,
              5.17415000000000000e+04 5.15241000000000058e+04 1.28784359999999992e+03,
              5.67415000000000000e+04 5.15241000000000058e+04 1.35037079999999992e+03,
              6.17415000000000000e+04 5.15241000000000058e+04 1.37355629999999996e+03,
              6.67415000000000000e+04 5.15241000000000058e+04 1.32981340000000000e+03,
              7.17415000000000000e+04 5.15241000000000058e+04 1.38636380000000008e+03,
              7.67415000000000000e+04 5.15241000000000058e+04 1.18864010000000007e+03,
              8.17415000000000000e+04 5.15241000000000058e+04 1.17685899999999992e+03,
              8.67415000000000000e+04 5.15241000000000058e+04 1.14867360000000008e+03,
              4.36915000000000000e+04 5.14741000000000058e+04 1.25592959999999994e+03,
              4.86915000000000000e+04 5.14741000000000058e+04 1.27784259999999995e+03,
              5.36915000000000000e+04 5.14741000000000058e+04 1.29191599999999994e+03,
              5.86915000000000000e+04 5.14741000000000058e+04 1.39326080000000002e+03,
              6.36915000000000000e+04 5.14741000000000058e+04 1.36371450000000004e+03,
              6.86915000000000000e+04 5.14741000000000058e+04 1.34564689999999996e+03,
              7.36915000000000000e+04 5.14741000000000058e+04 1.40044020000000000e+03,
              7.86915000000000000e+04 5.14741000000000058e+04 1.18756950000000006e+03,
              8.36915000000000000e+04 5.14741000000000058e+04 1.15297409999999991e+03,
              8.86915000000000000e+04 5.14741000000000058e+04 1.18022800000000007e+03,
              4.56415000000000000e+04 5.14241000000000058e+04 1.30167309999999998e+03,
              5.06415000000000000e+04 5.14241000000000058e+04 1.29561720000000014e+03,
              5.56415000000000000e+04 5.14241000000000058e+04 1.33434999999999991e+03,
              6.06415000000000000e+04 5.14241000000000058e+04 1.36896659999999997e+03,
              6.56415000000000000e+04 5.14241000000000058e+04 1.34141740000000004e+03,
              7.06415000000000000e+04 5.14241000000000058e+04 1.37418429999999989e+03,
              7.56415000000000000e+04 5.14241000000000058e+04 1.21112959999999998e+03,
              8.06415000000000000e+04 5.14241000000000058e+04 1.16904729999999995e+03,
              8.56415000000000000e+04 5.14241000000000058e+04 1.14969160000000011e+03,
              9.06415000000000000e+04 5.14241000000000058e+04 1.16870519999999988e+03,
              4.75915000000000000e+04 5.13741000000000058e+04 1.30101620000000003e+03,
              5.25915000000000000e+04 5.13741000000000058e+04 1.29920200000000000e+03,
              5.75915000000000000e+04 5.13741000000000058e+04 1.37429480000000012e+03,
              6.25915000000000000e+04 5.13741000000000058e+04 1.38490830000000005e+03,
              6.75915000000000000e+04 5.13741000000000058e+04 1.32870219999999995e+03,
              7.25915000000000000e+04 5.13741000000000058e+04 1.38683010000000013e+03,
              7.75915000000000000e+04 5.13741000000000058e+04 1.18968159999999989e+03,
              8.25915000000000000e+04 5.13741000000000058e+04 1.15784740000000011e+03,
              8.75915000000000000e+04 5.13741000000000058e+04 1.15085050000000001e+03,
              4.45415000000000000e+04 5.13241000000000058e+04 1.27226019999999994e+03,
              4.95415000000000000e+04 5.13241000000000058e+04 1.30023199999999997e+03,
              5.45415000000000000e+04 5.13241000000000058e+04 1.31224180000000001e+03,
              5.95415000000000000e+04 5.13241000000000058e+04 1.35456030000000010e+03,
              6.45415000000000000e+04 5.13241000000000058e+04 1.35498450000000003e+03,
              6.95415000000000000e+04 5.13241000000000058e+04 1.36886189999999988e+03,
              7.45415000000000000e+04 5.13241000000000058e+04 1.23665149999999994e+03,
              7.95415000000000000e+04 5.13241000000000058e+04 1.16920720000000006e+03,
              8.45415000000000000e+04 5.13241000000000058e+04 1.15065280000000007e+03,
              8.95415000000000000e+04 5.13241000000000058e+04 1.18457819999999992e+03,
              4.64915000000000000e+04 5.12741000000000058e+04 1.27374669999999992e+03,
              5.14915000000000000e+04 5.12741000000000058e+04 1.30249009999999998e+03,
              5.64915000000000000e+04 5.12741000000000058e+04 1.34246260000000007e+03,
              6.14915000000000000e+04 5.12741000000000058e+04 1.37631199999999990e+03,
              6.64915000000000000e+04 5.12741000000000058e+04 1.33097180000000003e+03,
              7.14915000000000000e+04 5.12741000000000058e+04 1.37970090000000005e+03,
              7.64915000000000000e+04 5.12741000000000058e+04 1.19196409999999992e+03,
              8.14915000000000000e+04 5.12741000000000058e+04 1.16762370000000010e+03,
              8.64915000000000000e+04 5.12741000000000058e+04 1.14640039999999999e+03,
              4.34415000000000000e+04 5.12241000000000058e+04 1.27301240000000007e+03,
              4.84415000000000000e+04 5.12241000000000058e+04 1.25913319999999999e+03,
              5.34415000000000000e+04 5.12241000000000058e+04 1.29795150000000012e+03,
              5.84415000000000000e+04 5.12241000000000058e+04 1.36796479999999997e+03,
              6.34415000000000000e+04 5.12241000000000058e+04 1.36429150000000004e+03,
              6.84415000000000000e+04 5.12241000000000058e+04 1.34344799999999987e+03,
              7.34415000000000000e+04 5.12241000000000058e+04 1.42824800000000005e+03,
              7.84415000000000000e+04 5.12241000000000058e+04 1.17755970000000002e+03,
              8.34415000000000000e+04 5.12241000000000058e+04 1.15011660000000006e+03,
              8.84415000000000000e+04 5.12241000000000058e+04 1.17359770000000003e+03,
              4.53915000000000000e+04 5.11741000000000058e+04 1.30018930000000000e+03,
              5.03915000000000000e+04 5.11741000000000058e+04 1.29699180000000001e+03,
              5.53915000000000000e+04 5.11741000000000058e+04 1.30925170000000003e+03,
              6.03915000000000000e+04 5.11741000000000058e+04 1.37105829999999992e+03,
              6.53915000000000000e+04 5.11741000000000058e+04 1.33895720000000006e+03,
              7.03915000000000000e+04 5.11741000000000058e+04 1.37287370000000010e+03,
              7.53915000000000000e+04 5.11741000000000058e+04 1.23264059999999995e+03,
              8.03915000000000000e+04 5.11741000000000058e+04 1.16538079999999991e+03,
              8.53915000000000000e+04 5.11741000000000058e+04 1.14801749999999993e+03,
              9.03915000000000000e+04 5.11741000000000058e+04 1.20773360000000002e+03,
              4.73415000000000000e+04 5.11241000000000058e+04 1.29839449999999988e+03,
              5.23415000000000000e+04 5.11241000000000058e+04 1.31796920000000000e+03,
              5.73415000000000000e+04 5.11241000000000058e+04 1.34741329999999994e+03,
              6.23415000000000000e+04 5.11241000000000058e+04 1.38077399999999989e+03,
              6.73415000000000000e+04 5.11241000000000058e+04 1.33035439999999994e+03,
              7.23415000000000000e+04 5.11241000000000058e+04 1.36271489999999994e+03,
              7.73415000000000000e+04 5.11241000000000058e+04 1.18865300000000002e+03,
              8.23415000000000000e+04 5.11241000000000058e+04 1.15560799999999995e+03,
              8.73415000000000000e+04 5.11241000000000058e+04 1.15337869999999998e+03,
              4.42915000000000000e+04 5.10741000000000058e+04 1.27273779999999988e+03,
              4.92915000000000000e+04 5.10741000000000058e+04 1.29923680000000013e+03,
              5.42915000000000000e+04 5.10741000000000058e+04 1.27252790000000005e+03,
              5.92915000000000000e+04 5.10741000000000058e+04 1.36404070000000002e+03,
              6.42915000000000000e+04 5.10741000000000058e+04 1.35125189999999998e+03,
              6.92915000000000000e+04 5.10741000000000058e+04 1.36237190000000010e+03,
              7.42915000000000000e+04 5.10741000000000058e+04 1.24562390000000005e+03,
              7.92915000000000000e+04 5.10741000000000058e+04 1.18550050000000010e+03,
              8.42915000000000000e+04 5.10741000000000058e+04 1.14921900000000005e+03,
              8.92915000000000000e+04 5.10741000000000058e+04 1.19961000000000013e+03,
              4.62415000000000000e+04 5.10241000000000058e+04 1.28441679999999997e+03,
              5.12415000000000000e+04 5.10241000000000058e+04 1.30218419999999992e+03,
              5.62415000000000000e+04 5.10241000000000058e+04 1.34072450000000003e+03,
              6.12415000000000000e+04 5.10241000000000058e+04 1.37946000000000004e+03,
              6.62415000000000000e+04 5.10241000000000058e+04 1.33019779999999992e+03,
              7.12415000000000000e+04 5.10241000000000058e+04 1.38338279999999986e+03,
              7.62415000000000000e+04 5.10241000000000058e+04 1.19469650000000001e+03,
              8.12415000000000000e+04 5.10241000000000058e+04 1.16341200000000003e+03,
              8.62415000000000000e+04 5.10241000000000058e+04 1.15231559999999990e+03,
              4.31915000000000000e+04 5.09741000000000058e+04 1.26407819999999992e+03,
              4.81915000000000000e+04 5.09741000000000058e+04 1.29422489999999993e+03,
              5.31915000000000000e+04 5.09741000000000058e+04 1.32542740000000003e+03,
              5.81915000000000000e+04 5.09741000000000058e+04 1.35157349999999997e+03,
              6.31915000000000000e+04 5.09741000000000058e+04 1.36034400000000005e+03,
              6.81915000000000000e+04 5.09741000000000058e+04 1.34166309999999999e+03,
              7.31915000000000000e+04 5.09741000000000058e+04 1.43046650000000000e+03,
              7.81915000000000000e+04 5.09741000000000058e+04 1.17943689999999992e+03,
              8.31915000000000000e+04 5.09741000000000058e+04 1.15127489999999989e+03,
              8.81915000000000000e+04 5.09741000000000058e+04 1.16387830000000008e+03,
              4.51415000000000000e+04 5.09241000000000058e+04 1.29983289999999988e+03,
              5.01415000000000000e+04 5.09241000000000058e+04 1.29832539999999995e+03,
              5.51415000000000000e+04 5.09241000000000058e+04 1.29141269999999986e+03,
              6.01415000000000000e+04 5.09241000000000058e+04 1.36359619999999995e+03,
              6.51415000000000000e+04 5.09241000000000058e+04 1.33768789999999990e+03,
              7.01415000000000000e+04 5.09241000000000058e+04 1.36671319999999992e+03,
              7.51415000000000000e+04 5.09241000000000058e+04 1.23510460000000012e+03,
              8.01415000000000000e+04 5.09241000000000058e+04 1.17038789999999995e+03,
              8.51415000000000000e+04 5.09241000000000058e+04 1.14982700000000000e+03,
              9.01415000000000000e+04 5.09241000000000058e+04 1.21782629999999995e+03,
              4.70915000000000000e+04 5.08741000000000058e+04 1.29153850000000011e+03,
              5.20915000000000000e+04 5.08741000000000058e+04 1.30934009999999989e+03,
              5.70915000000000000e+04 5.08741000000000058e+04 1.37137249999999995e+03,
              6.20915000000000000e+04 5.08741000000000058e+04 1.37904690000000005e+03,
              6.70915000000000000e+04 5.08741000000000058e+04 1.32973779999999988e+03,
              7.20915000000000000e+04 5.08741000000000058e+04 1.37127539999999999e+03,
              7.70915000000000000e+04 5.08741000000000058e+04 1.21070270000000005e+03,
              8.20915000000000000e+04 5.08741000000000058e+04 1.15333649999999989e+03,
              8.70915000000000000e+04 5.08741000000000058e+04 1.15266759999999999e+03,
              4.40415000000000000e+04 5.08241000000000058e+04 1.25303379999999993e+03,
              4.90415000000000000e+04 5.08241000000000058e+04 1.28844849999999997e+03,
              5.40415000000000000e+04 5.08241000000000058e+04 1.32058960000000002e+03,
              5.90415000000000000e+04 5.08241000000000058e+04 1.39453510000000006e+03,
              6.40415000000000000e+04 5.08241000000000058e+04 1.34908169999999996e+03,
              6.90415000000000000e+04 5.08241000000000058e+04 1.37456750000000011e+03,
              7.40415000000000000e+04 5.08241000000000058e+04 1.26000749999999994e+03,
              7.90415000000000000e+04 5.08241000000000058e+04 1.18627800000000002e+03,
              8.40415000000000000e+04 5.08241000000000058e+04 1.15064380000000006e+03,
              8.90415000000000000e+04 5.08241000000000058e+04 1.20619679999999994e+03,
              4.59915000000000000e+04 5.07741000000000058e+04 1.29934770000000003e+03,
              5.09915000000000000e+04 5.07741000000000058e+04 1.30244749999999999e+03,
              5.59915000000000000e+04 5.07741000000000058e+04 1.33044529999999986e+03,
              6.09915000000000000e+04 5.07741000000000058e+04 1.38019059999999990e+03,
              6.59915000000000000e+04 5.07741000000000058e+04 1.33224080000000004e+03,
              7.09915000000000000e+04 5.07741000000000058e+04 1.37617139999999995e+03,
              7.59915000000000000e+04 5.07741000000000058e+04 1.20456709999999998e+03,
              8.09915000000000000e+04 5.07741000000000058e+04 1.16087869999999998e+03,
              8.59915000000000000e+04 5.07741000000000058e+04 1.15064599999999996e+03,
              9.09915000000000000e+04 5.07741000000000058e+04 1.17311830000000009e+03,
              4.79415000000000000e+04 5.07241000000000058e+04 1.28918660000000000e+03,
              5.29415000000000000e+04 5.07241000000000058e+04 1.32226479999999992e+03,
              5.79415000000000000e+04 5.07241000000000058e+04 1.38428800000000001e+03,
              6.29415000000000000e+04 5.07241000000000058e+04 1.36928629999999998e+03,
              6.79415000000000000e+04 5.07241000000000058e+04 1.33632830000000013e+03,
              7.29415000000000000e+04 5.07241000000000058e+04 1.41123610000000008e+03,
              7.79415000000000000e+04 5.07241000000000058e+04 1.19962789999999995e+03,
              8.29415000000000000e+04 5.07241000000000058e+04 1.15165280000000007e+03,
              8.79415000000000000e+04 5.07241000000000058e+04 1.15494209999999998e+03,
              4.48915000000000000e+04 5.06741000000000058e+04 1.29337730000000010e+03,
              4.98915000000000000e+04 5.06741000000000058e+04 1.29517049999999995e+03,
              5.48915000000000000e+04 5.06741000000000058e+04 1.33120980000000009e+03,
              5.98915000000000000e+04 5.06741000000000058e+04 1.37015699999999993e+03,
              6.48915000000000000e+04 5.06741000000000058e+04 1.33683590000000004e+03,
              6.98915000000000000e+04 5.06741000000000058e+04 1.36346479999999997e+03,
              7.48915000000000000e+04 5.06741000000000058e+04 1.24485870000000000e+03,
              7.98915000000000000e+04 5.06741000000000058e+04 1.18319440000000009e+03,
              8.48915000000000000e+04 5.06741000000000058e+04 1.14597499999999991e+03,
              8.98915000000000000e+04 5.06741000000000058e+04 1.22487619999999993e+03,
              4.68415000000000000e+04 5.06241000000000058e+04 1.28594479999999999e+03,
              5.18415000000000000e+04 5.06241000000000058e+04 1.29591359999999986e+03,
              5.68415000000000000e+04 5.06241000000000058e+04 1.36968069999999989e+03,
              6.18415000000000000e+04 5.06241000000000058e+04 1.37933159999999998e+03,
              6.68415000000000000e+04 5.06241000000000058e+04 1.33193890000000010e+03,
              7.18415000000000000e+04 5.06241000000000058e+04 1.36734120000000007e+03,
              7.68415000000000000e+04 5.06241000000000058e+04 1.22651679999999988e+03,
              8.18415000000000000e+04 5.06241000000000058e+04 1.15329140000000007e+03,
              8.68415000000000000e+04 5.06241000000000058e+04 1.14999299999999994e+03,
              4.37915000000000000e+04 5.05741000000000058e+04 1.25203310000000010e+03,
              4.87915000000000000e+04 5.05741000000000058e+04 1.28442709999999988e+03,
              5.37915000000000000e+04 5.05741000000000058e+04 1.33163850000000002e+03,
              5.87915000000000000e+04 5.05741000000000058e+04 1.38887640000000010e+03,
              6.37915000000000000e+04 5.05741000000000058e+04 1.35363229999999999e+03,
              6.87915000000000000e+04 5.05741000000000058e+04 1.35109069999999997e+03,
              7.37915000000000000e+04 5.05741000000000058e+04 1.29788189999999986e+03,
              7.87915000000000000e+04 5.05741000000000058e+04 1.18948939999999993e+03,
              8.37915000000000000e+04 5.05741000000000058e+04 1.15080209999999988e+03,
              8.87915000000000000e+04 5.05741000000000058e+04 1.18788840000000005e+03,
              4.57415000000000000e+04 5.05241000000000058e+04 1.29663810000000012e+03,
              5.07415000000000000e+04 5.05241000000000058e+04 1.30805140000000006e+03,
              5.57415000000000000e+04 5.05241000000000058e+04 1.28761830000000009e+03,
              6.07415000000000000e+04 5.05241000000000058e+04 1.38405330000000004e+03,
              6.57415000000000000e+04 5.05241000000000058e+04 1.33577800000000002e+03,
              7.07415000000000000e+04 5.05241000000000058e+04 1.37120900000000006e+03,
              7.57415000000000000e+04 5.05241000000000058e+04 1.23370080000000007e+03,
              8.07415000000000000e+04 5.05241000000000058e+04 1.18910789999999997e+03,
              8.57415000000000000e+04 5.05241000000000058e+04 1.15265589999999997e+03,
              9.07415000000000000e+04 5.05241000000000058e+04 1.19001469999999995e+03,
              4.76915000000000000e+04 5.04741000000000058e+04 1.31365509999999995e+03,
              5.26915000000000000e+04 5.04741000000000058e+04 1.30609169999999995e+03,
              5.76915000000000000e+04 5.04741000000000058e+04 1.40572309999999993e+03,
              6.26915000000000000e+04 5.04741000000000058e+04 1.37011360000000013e+03,
              6.76915000000000000e+04 5.04741000000000058e+04 1.33467390000000000e+03,
              7.26915000000000000e+04 5.04741000000000058e+04 1.39247139999999990e+03,
              7.76915000000000000e+04 5.04741000000000058e+04 1.20741149999999993e+03,
              8.26915000000000000e+04 5.04741000000000058e+04 1.15073689999999988e+03,
              8.76915000000000000e+04 5.04741000000000058e+04 1.16014820000000009e+03,
              4.46415000000000000e+04 5.04241000000000058e+04 1.28722219999999993e+03,
              4.96415000000000000e+04 5.04241000000000058e+04 1.29215750000000003e+03,
              5.46415000000000000e+04 5.04241000000000058e+04 1.33632269999999994e+03,
              5.96415000000000000e+04 5.04241000000000058e+04 1.37344000000000005e+03,
              6.46415000000000000e+04 5.04241000000000058e+04 1.33932909999999993e+03,
              6.96415000000000000e+04 5.04241000000000058e+04 1.36098700000000008e+03,
              7.46415000000000000e+04 5.04241000000000058e+04 1.27978919999999994e+03,
              7.96415000000000000e+04 5.04241000000000058e+04 1.17862329999999997e+03,
              8.46415000000000000e+04 5.04241000000000058e+04 1.14611140000000000e+03,
              8.96415000000000000e+04 5.04241000000000058e+04 1.24880029999999988e+03,
              4.65915000000000000e+04 5.03741000000000058e+04 1.30159920000000011e+03,
              5.15915000000000000e+04 5.03741000000000058e+04 1.30297389999999996e+03,
              5.65915000000000000e+04 5.03741000000000058e+04 1.34716689999999994e+03,
              6.15915000000000000e+04 5.03741000000000058e+04 1.38132970000000000e+03,
              6.65915000000000000e+04 5.03741000000000058e+04 1.34463229999999999e+03,
              7.15915000000000000e+04 5.03741000000000058e+04 1.37037930000000006e+03,
              7.65915000000000000e+04 5.03741000000000058e+04 1.23770550000000003e+03,
              8.15915000000000000e+04 5.03741000000000058e+04 1.15173410000000013e+03,
              8.65915000000000000e+04 5.03741000000000058e+04 1.15222939999999994e+03,
              4.35415000000000000e+04 5.03241000000000058e+04 1.27363650000000007e+03,
              4.85415000000000000e+04 5.03241000000000058e+04 1.29369209999999998e+03,
              5.35415000000000000e+04 5.03241000000000058e+04 1.32307529999999997e+03,
              5.85415000000000000e+04 5.03241000000000058e+04 1.38733040000000005e+03,
              6.35415000000000000e+04 5.03241000000000058e+04 1.35103350000000000e+03,
              6.85415000000000000e+04 5.03241000000000058e+04 1.34476199999999994e+03,
              7.35415000000000000e+04 5.03241000000000058e+04 1.41944389999999999e+03,
              7.85415000000000000e+04 5.03241000000000058e+04 1.20118550000000005e+03,
              8.35415000000000000e+04 5.03241000000000058e+04 1.14816650000000004e+03,
              8.85415000000000000e+04 5.03241000000000058e+04 1.20114290000000005e+03,
              4.54915000000000000e+04 5.02741000000000058e+04 1.27798050000000012e+03,
              5.04915000000000000e+04 5.02741000000000058e+04 1.29891930000000002e+03,
              5.54915000000000000e+04 5.02741000000000058e+04 1.33741620000000012e+03,
              6.04915000000000000e+04 5.02741000000000058e+04 1.38660809999999992e+03,
              6.54915000000000000e+04 5.02741000000000058e+04 1.34008919999999989e+03,
              7.04915000000000000e+04 5.02741000000000058e+04 1.37309699999999998e+03,
              7.54915000000000000e+04 5.02741000000000058e+04 1.25331160000000000e+03,
              8.04915000000000000e+04 5.02741000000000058e+04 1.17162879999999996e+03,
              8.54915000000000000e+04 5.02741000000000058e+04 1.15312590000000000e+03,
              9.04915000000000000e+04 5.02741000000000058e+04 1.20427549999999997e+03,
              4.74415000000000000e+04 5.02241000000000058e+04 1.29799540000000002e+03,
              5.24415000000000000e+04 5.02241000000000058e+04 1.29983220000000006e+03,
              5.74415000000000000e+04 5.02241000000000058e+04 1.38845929999999998e+03,
              6.24415000000000000e+04 5.02241000000000058e+04 1.36870859999999993e+03,
              6.74415000000000000e+04 5.02241000000000058e+04 1.33268190000000004e+03,
              7.24415000000000000e+04 5.02241000000000058e+04 1.40397530000000006e+03,
              7.74415000000000000e+04 5.02241000000000058e+04 1.21839779999999996e+03,
              8.24415000000000000e+04 5.02241000000000058e+04 1.15116409999999996e+03,
              8.74415000000000000e+04 5.02241000000000058e+04 1.20090709999999990e+03,
              4.43915000000000000e+04 5.01741000000000058e+04 1.27899870000000010e+03,
              4.93915000000000000e+04 5.01741000000000058e+04 1.27488879999999995e+03,
              5.43915000000000000e+04 5.01741000000000058e+04 1.33576999999999998e+03,
              5.93915000000000000e+04 5.01741000000000058e+04 1.37585149999999999e+03,
              6.43915000000000000e+04 5.01741000000000058e+04 1.33990219999999999e+03,
              6.93915000000000000e+04 5.01741000000000058e+04 1.35988220000000001e+03,
              7.43915000000000000e+04 5.01741000000000058e+04 1.29280009999999993e+03,
              7.93915000000000000e+04 5.01741000000000058e+04 1.19566440000000011e+03,
              8.43915000000000000e+04 5.01741000000000058e+04 1.15094399999999996e+03,
              8.93915000000000000e+04 5.01741000000000058e+04 1.26098820000000001e+03,
              4.63415000000000000e+04 5.01241000000000058e+04 1.31026019999999994e+03,
              5.13415000000000000e+04 5.01241000000000058e+04 1.30667129999999997e+03,
              5.63415000000000000e+04 5.01241000000000058e+04 1.31169520000000011e+03,
              6.13415000000000000e+04 5.01241000000000058e+04 1.38190930000000003e+03,
              6.63415000000000000e+04 5.01241000000000058e+04 1.36229379999999992e+03,
              7.13415000000000000e+04 5.01241000000000058e+04 1.37415460000000007e+03,
              7.63415000000000000e+04 5.01241000000000058e+04 1.22848029999999994e+03,
              8.13415000000000000e+04 5.01241000000000058e+04 1.15163999999999987e+03,
              8.63415000000000000e+04 5.01241000000000058e+04 1.15105929999999989e+03,
              4.32915000000000000e+04 5.00741000000000058e+04 1.26723239999999987e+03,
              4.82915000000000000e+04 5.00741000000000058e+04 1.28300880000000006e+03,
              5.32915000000000000e+04 5.00741000000000058e+04 1.30360300000000007e+03,
              5.82915000000000000e+04 5.00741000000000058e+04 1.38968370000000004e+03,
              6.32915000000000000e+04 5.00741000000000058e+04 1.34948280000000000e+03,
              6.82915000000000000e+04 5.00741000000000058e+04 1.36269849999999997e+03,
              7.32915000000000000e+04 5.00741000000000058e+04 1.40366159999999991e+03,
              7.82915000000000000e+04 5.00741000000000058e+04 1.22737210000000005e+03,
              8.32915000000000000e+04 5.00741000000000058e+04 1.14773479999999995e+03,
              8.82915000000000000e+04 5.00741000000000058e+04 1.18298890000000006e+03,
              4.52415000000000000e+04 5.00241000000000058e+04 1.26865300000000002e+03,
              5.02415000000000000e+04 5.00241000000000058e+04 1.29289519999999993e+03,
              5.52415000000000000e+04 5.00241000000000058e+04 1.34119200000000001e+03,
              6.02415000000000000e+04 5.00241000000000058e+04 1.38863959999999997e+03,
              6.52415000000000000e+04 5.00241000000000058e+04 1.35041879999999992e+03,
              7.02415000000000000e+04 5.00241000000000058e+04 1.35744679999999994e+03,
              7.52415000000000000e+04 5.00241000000000058e+04 1.24821650000000000e+03,
              8.02415000000000000e+04 5.00241000000000058e+04 1.16785410000000002e+03,
              8.52415000000000000e+04 5.00241000000000058e+04 1.15251949999999988e+03,
              9.02415000000000000e+04 5.00241000000000058e+04 1.22406590000000006e+03,
              4.71915000000000000e+04 4.99741000000000058e+04 1.29866300000000001e+03,
              5.21915000000000000e+04 4.99741000000000058e+04 1.31689789999999994e+03,
              5.71915000000000000e+04 4.99741000000000058e+04 1.36435249999999996e+03,
              6.21915000000000000e+04 4.99741000000000058e+04 1.35326880000000006e+03,
              6.71915000000000000e+04 4.99741000000000058e+04 1.33201260000000002e+03,
              7.21915000000000000e+04 4.99741000000000058e+04 1.37790349999999989e+03,
              7.71915000000000000e+04 4.99741000000000058e+04 1.22813730000000010e+03,
              8.21915000000000000e+04 4.99741000000000058e+04 1.15015210000000002e+03,
              8.71915000000000000e+04 4.99741000000000058e+04 1.20636689999999999e+03,
              4.41415000000000000e+04 4.99241000000000058e+04 1.25424109999999996e+03,
              4.91415000000000000e+04 4.99241000000000058e+04 1.28305349999999999e+03,
              5.41415000000000000e+04 4.99241000000000058e+04 1.33211210000000005e+03,
              5.91415000000000000e+04 4.99241000000000058e+04 1.39558490000000006e+03,
              6.41415000000000000e+04 4.99241000000000058e+04 1.34200050000000010e+03,
              6.91415000000000000e+04 4.99241000000000058e+04 1.37205439999999999e+03,
              7.41415000000000000e+04 4.99241000000000058e+04 1.31872350000000006e+03,
              7.91415000000000000e+04 4.99241000000000058e+04 1.20022119999999995e+03,
              8.41415000000000000e+04 4.99241000000000058e+04 1.15001700000000005e+03,
              8.91415000000000000e+04 4.99241000000000058e+04 1.22322900000000004e+03,
              4.60915000000000000e+04 4.98741000000000058e+04 1.30216750000000002e+03,
              5.10915000000000000e+04 4.98741000000000058e+04 1.30814609999999993e+03,
              5.60915000000000000e+04 4.98741000000000058e+04 1.34881019999999990e+03,
              6.10915000000000000e+04 4.98741000000000058e+04 1.38151400000000012e+03,
              6.60915000000000000e+04 4.98741000000000058e+04 1.33795679999999993e+03,
              7.10915000000000000e+04 4.98741000000000058e+04 1.36972469999999998e+03,
              7.60915000000000000e+04 4.98741000000000058e+04 1.22044350000000009e+03,
              8.10915000000000000e+04 4.98741000000000058e+04 1.15362400000000002e+03,
              8.60915000000000000e+04 4.98741000000000058e+04 1.15378919999999994e+03,
              4.30415000000000000e+04 4.98241000000000058e+04 1.26147569999999996e+03,
              4.80415000000000000e+04 4.98241000000000058e+04 1.28652950000000010e+03,
              5.30415000000000000e+04 4.98241000000000058e+04 1.31661709999999994e+03,
              5.80415000000000000e+04 4.98241000000000058e+04 1.39207060000000001e+03,
              6.30415000000000000e+04 4.98241000000000058e+04 1.35584359999999992e+03,
              6.80415000000000000e+04 4.98241000000000058e+04 1.34124669999999992e+03,
              7.30415000000000000e+04 4.98241000000000058e+04 1.38572749999999996e+03,
              7.80415000000000000e+04 4.98241000000000058e+04 1.24399800000000005e+03,
              8.30415000000000000e+04 4.98241000000000058e+04 1.15198379999999997e+03,
              8.80415000000000000e+04 4.98241000000000058e+04 1.15998199999999997e+03,
              4.49915000000000000e+04 4.97741000000000058e+04 1.26773320000000012e+03,
              4.99915000000000000e+04 4.97741000000000058e+04 1.28550710000000004e+03,
              5.49915000000000000e+04 4.97741000000000058e+04 1.34971010000000001e+03,
              5.99915000000000000e+04 4.97741000000000058e+04 1.38701430000000005e+03,
              6.49915000000000000e+04 4.97741000000000058e+04 1.31897209999999995e+03,
              6.99915000000000000e+04 4.97741000000000058e+04 1.34059760000000006e+03,
              7.49915000000000000e+04 4.97741000000000058e+04 1.25789969999999994e+03,
              7.99915000000000000e+04 4.97741000000000058e+04 1.18271970000000010e+03,
              8.49915000000000000e+04 4.97741000000000058e+04 1.15287069999999994e+03,
              8.99915000000000000e+04 4.97741000000000058e+04 1.24062110000000007e+03,
              4.69415000000000000e+04 4.97241000000000058e+04 1.31062940000000003e+03,
              5.19415000000000000e+04 4.97241000000000058e+04 1.32028520000000003e+03,
              5.69415000000000000e+04 4.97241000000000058e+04 1.34784840000000008e+03,
              6.19415000000000000e+04 4.97241000000000058e+04 1.35173440000000005e+03,
              6.69415000000000000e+04 4.97241000000000058e+04 1.33825150000000008e+03,
              7.19415000000000000e+04 4.97241000000000058e+04 1.39723980000000006e+03,
              7.69415000000000000e+04 4.97241000000000058e+04 1.25273559999999998e+03,
              8.19415000000000000e+04 4.97241000000000058e+04 1.15249399999999991e+03,
              8.69415000000000000e+04 4.97241000000000058e+04 1.21306670000000008e+03,
              4.38915000000000000e+04 4.96741000000000058e+04 1.27554399999999987e+03,
              4.88915000000000000e+04 4.96741000000000058e+04 1.28276960000000008e+03,
              5.38915000000000000e+04 4.96741000000000058e+04 1.32196949999999993e+03,
              5.88915000000000000e+04 4.96741000000000058e+04 1.38942799999999988e+03,
              6.38915000000000000e+04 4.96741000000000058e+04 1.33995479999999998e+03,
              6.88915000000000000e+04 4.96741000000000058e+04 1.36788999999999987e+03,
              7.38915000000000000e+04 4.96741000000000058e+04 1.34646620000000007e+03,
              7.88915000000000000e+04 4.96741000000000058e+04 1.19572389999999996e+03,
              8.38915000000000000e+04 4.96741000000000058e+04 1.15259300000000007e+03,
              8.88915000000000000e+04 4.96741000000000058e+04 1.20468879999999990e+03,
              4.58415000000000000e+04 4.96241000000000058e+04 1.29182740000000013e+03,
              5.08415000000000000e+04 4.96241000000000058e+04 1.29796100000000001e+03,
              5.58415000000000000e+04 4.96241000000000058e+04 1.35800829999999996e+03,
              6.08415000000000000e+04 4.96241000000000058e+04 1.38077340000000004e+03,
              6.58415000000000000e+04 4.96241000000000058e+04 1.34692030000000000e+03,
              7.08415000000000000e+04 4.96241000000000058e+04 1.37027870000000007e+03,
              7.58415000000000000e+04 4.96241000000000058e+04 1.22554919999999993e+03,
              8.08415000000000000e+04 4.96241000000000058e+04 1.15376819999999998e+03,
              8.58415000000000000e+04 4.96241000000000058e+04 1.15212429999999995e+03,
              9.08415000000000000e+04 4.96241000000000058e+04 1.20036619999999994e+03,
              4.77915000000000000e+04 4.95741000000000058e+04 1.29176900000000001e+03,
              5.27915000000000000e+04 4.95741000000000058e+04 1.31893620000000010e+03,
              5.77915000000000000e+04 4.95741000000000058e+04 1.38333089999999993e+03,
              6.27915000000000000e+04 4.95741000000000058e+04 1.35613689999999997e+03,
              6.77915000000000000e+04 4.95741000000000058e+04 1.33521309999999994e+03,
              7.27915000000000000e+04 4.95741000000000058e+04 1.30794049999999993e+03,
              7.77915000000000000e+04 4.95741000000000058e+04 1.26313120000000004e+03,
              8.27915000000000000e+04 4.95741000000000058e+04 1.14595699999999988e+03,
              8.77915000000000000e+04 4.95741000000000058e+04 1.15694290000000001e+03,
              4.47415000000000000e+04 4.95241000000000058e+04 1.26631089999999995e+03,
              4.97415000000000000e+04 4.95241000000000058e+04 1.25981379999999990e+03,
              5.47415000000000000e+04 4.95241000000000058e+04 1.34275610000000006e+03,
              5.97415000000000000e+04 4.95241000000000058e+04 1.38562259999999992e+03,
              6.47415000000000000e+04 4.95241000000000058e+04 1.31657279999999992e+03,
              6.97415000000000000e+04 4.95241000000000058e+04 1.33374510000000009e+03,
              7.47415000000000000e+04 4.95241000000000058e+04 1.25852039999999988e+03,
              7.97415000000000000e+04 4.95241000000000058e+04 1.17039899999999989e+03,
              8.47415000000000000e+04 4.95241000000000058e+04 1.15538450000000012e+03,
              8.97415000000000000e+04 4.95241000000000058e+04 1.26396990000000005e+03,
              4.66915000000000000e+04 4.94741000000000058e+04 1.31156140000000005e+03,
              5.16915000000000000e+04 4.94741000000000058e+04 1.30023820000000001e+03,
              5.66915000000000000e+04 4.94741000000000058e+04 1.31865879999999993e+03,
              6.16915000000000000e+04 4.94741000000000058e+04 1.35824739999999997e+03,
              6.66915000000000000e+04 4.94741000000000058e+04 1.33828940000000011e+03,
              7.16915000000000000e+04 4.94741000000000058e+04 1.39832709999999997e+03,
              7.66915000000000000e+04 4.94741000000000058e+04 1.22104829999999993e+03,
              8.16915000000000000e+04 4.94741000000000058e+04 1.15111410000000001e+03,
              8.66915000000000000e+04 4.94741000000000058e+04 1.20997219999999993e+03,
              4.36415000000000000e+04 4.94241000000000058e+04 1.26999250000000006e+03,
              4.86415000000000000e+04 4.94241000000000058e+04 1.28544569999999999e+03,
              5.36415000000000000e+04 4.94241000000000058e+04 1.31753590000000008e+03,
              5.86415000000000000e+04 4.94241000000000058e+04 1.39059279999999990e+03,
              6.36415000000000000e+04 4.94241000000000058e+04 1.33933019999999988e+03,
              6.86415000000000000e+04 4.94241000000000058e+04 1.36670440000000008e+03,
              7.36415000000000000e+04 4.94241000000000058e+04 1.38431400000000008e+03,
              7.86415000000000000e+04 4.94241000000000058e+04 1.21245170000000007e+03,
              8.36415000000000000e+04 4.94241000000000058e+04 1.15219560000000001e+03,
              8.86415000000000000e+04 4.94241000000000058e+04 1.18703029999999990e+03,
              4.55915000000000000e+04 4.93741000000000058e+04 1.28390889999999990e+03,
              5.05915000000000000e+04 4.93741000000000058e+04 1.27636609999999996e+03,
              5.55915000000000000e+04 4.93741000000000058e+04 1.35982850000000008e+03,
              6.05915000000000000e+04 4.93741000000000058e+04 1.37783850000000007e+03,
              6.55915000000000000e+04 4.93741000000000058e+04 1.34750919999999996e+03,
              7.05915000000000000e+04 4.93741000000000058e+04 1.36583030000000008e+03,
              7.55915000000000000e+04 4.93741000000000058e+04 1.21481520000000000e+03,
              8.05915000000000000e+04 4.93741000000000058e+04 1.14844000000000005e+03,
              8.55915000000000000e+04 4.93741000000000058e+04 1.15218450000000007e+03,
              9.05915000000000000e+04 4.93741000000000058e+04 1.21600860000000011e+03,
              4.75415000000000000e+04 4.93241000000000058e+04 1.31234449999999993e+03,
              5.25415000000000000e+04 4.93241000000000058e+04 1.31446209999999996e+03,
              5.75415000000000000e+04 4.93241000000000058e+04 1.35896800000000007e+03,
              6.25415000000000000e+04 4.93241000000000058e+04 1.33794389999999999e+03,
              6.75415000000000000e+04 4.93241000000000058e+04 1.32507670000000007e+03,
              7.25415000000000000e+04 4.93241000000000058e+04 1.30081660000000011e+03,
              7.75415000000000000e+04 4.93241000000000058e+04 1.29951379999999995e+03,
              8.25415000000000000e+04 4.93241000000000058e+04 1.15111259999999993e+03,
              8.75415000000000000e+04 4.93241000000000058e+04 1.17897690000000011e+03,
              4.44915000000000000e+04 4.92741000000000058e+04 1.25857089999999994e+03,
              4.94915000000000000e+04 4.92741000000000058e+04 1.28894309999999996e+03,
              5.44915000000000000e+04 4.92741000000000058e+04 1.33467880000000014e+03,
              5.94915000000000000e+04 4.92741000000000058e+04 1.38989629999999988e+03,
              6.44915000000000000e+04 4.92741000000000058e+04 1.31723849999999993e+03,
              6.94915000000000000e+04 4.92741000000000058e+04 1.33615560000000005e+03,
              7.44915000000000000e+04 4.92741000000000058e+04 1.32483320000000003e+03,
              7.94915000000000000e+04 4.92741000000000058e+04 1.20081629999999996e+03,
              8.44915000000000000e+04 4.92741000000000058e+04 1.15494769999999994e+03,
              8.94915000000000000e+04 4.92741000000000058e+04 1.24019110000000001e+03,
              4.64415000000000000e+04 4.92241000000000058e+04 1.30170720000000006e+03,
              5.14415000000000000e+04 4.92241000000000058e+04 1.29436810000000014e+03,
              5.64415000000000000e+04 4.92241000000000058e+04 1.35896749999999997e+03,
              6.14415000000000000e+04 4.92241000000000058e+04 1.35740030000000002e+03,
              6.64415000000000000e+04 4.92241000000000058e+04 1.32245800000000008e+03,
              7.14415000000000000e+04 4.92241000000000058e+04 1.39527320000000009e+03,
              7.64415000000000000e+04 4.92241000000000058e+04 1.20144010000000003e+03,
              8.14415000000000000e+04 4.92241000000000058e+04 1.15229950000000008e+03,
              8.64415000000000000e+04 4.92241000000000058e+04 1.17400990000000002e+03,
              4.33915000000000000e+04 4.91741000000000058e+04 1.25869619999999986e+03,
              4.83915000000000000e+04 4.91741000000000058e+04 1.29213830000000007e+03,
              5.33915000000000000e+04 4.91741000000000058e+04 1.32537490000000003e+03,
              5.83915000000000000e+04 4.91741000000000058e+04 1.38140149999999994e+03,
              6.33915000000000000e+04 4.91741000000000058e+04 1.33995020000000000e+03,
              6.83915000000000000e+04 4.91741000000000058e+04 1.35973649999999998e+03,
              7.33915000000000000e+04 4.91741000000000058e+04 1.34371980000000008e+03,
              7.83915000000000000e+04 4.91741000000000058e+04 1.23330569999999989e+03,
              8.33915000000000000e+04 4.91741000000000058e+04 1.15205529999999999e+03,
              8.83915000000000000e+04 4.91741000000000058e+04 1.16769650000000001e+03,
              4.53415000000000000e+04 4.91241000000000058e+04 1.27172949999999992e+03,
              5.03415000000000000e+04 4.91241000000000058e+04 1.27626189999999997e+03,
              5.53415000000000000e+04 4.91241000000000058e+04 1.34898129999999992e+03,
              6.03415000000000000e+04 4.91241000000000058e+04 1.37015519999999992e+03,
              6.53415000000000000e+04 4.91241000000000058e+04 1.33161720000000014e+03,
              7.03415000000000000e+04 4.91241000000000058e+04 1.36926240000000007e+03,
              7.53415000000000000e+04 4.91241000000000058e+04 1.21998119999999994e+03,
              8.03415000000000000e+04 4.91241000000000058e+04 1.15434179999999992e+03,
              8.53415000000000000e+04 4.91241000000000058e+04 1.15243499999999995e+03,
              9.03415000000000000e+04 4.91241000000000058e+04 1.27117300000000000e+03,
              4.72915000000000000e+04 4.90741000000000058e+04 1.30775510000000008e+03,
              5.22915000000000000e+04 4.90741000000000058e+04 1.30455009999999993e+03,
              5.72915000000000000e+04 4.90741000000000058e+04 1.32521790000000010e+03,
              6.22915000000000000e+04 4.90741000000000058e+04 1.33563930000000005e+03,
              6.72915000000000000e+04 4.90741000000000058e+04 1.31647620000000006e+03,
              7.22915000000000000e+04 4.90741000000000058e+04 1.27502579999999989e+03,
              7.72915000000000000e+04 4.90741000000000058e+04 1.29950939999999991e+03,
              8.22915000000000000e+04 4.90741000000000058e+04 1.15202099999999996e+03,
              8.72915000000000000e+04 4.90741000000000058e+04 1.21354199999999992e+03,
              4.42415000000000000e+04 4.90241000000000058e+04 1.27445699999999988e+03,
              4.92415000000000000e+04 4.90241000000000058e+04 1.29774040000000014e+03,
              5.42415000000000000e+04 4.90241000000000058e+04 1.31657920000000013e+03,
              5.92415000000000000e+04 4.90241000000000058e+04 1.38880829999999992e+03,
              6.42415000000000000e+04 4.90241000000000058e+04 1.31712969999999996e+03,
              6.92415000000000000e+04 4.90241000000000058e+04 1.32185400000000004e+03,
              7.42415000000000000e+04 4.90241000000000058e+04 1.36957940000000008e+03,
              7.92415000000000000e+04 4.90241000000000058e+04 1.19754839999999990e+03,
              8.42415000000000000e+04 4.90241000000000058e+04 1.15412570000000005e+03,
              8.92415000000000000e+04 4.90241000000000058e+04 1.19307580000000007e+03,
              4.61915000000000000e+04 4.89741000000000058e+04 1.29044759999999997e+03,
              5.11915000000000000e+04 4.89741000000000058e+04 1.30023900000000003e+03,
              5.61915000000000000e+04 4.89741000000000058e+04 1.36843930000000000e+03,
              6.11915000000000000e+04 4.89741000000000058e+04 1.35662259999999992e+03,
              6.61915000000000000e+04 4.89741000000000058e+04 1.32673190000000000e+03,
              7.11915000000000000e+04 4.89741000000000058e+04 1.39363679999999999e+03,
              7.61915000000000000e+04 4.89741000000000058e+04 1.19641319999999996e+03,
              8.11915000000000000e+04 4.89741000000000058e+04 1.15193609999999990e+03,
              8.61915000000000000e+04 4.89741000000000058e+04 1.15712740000000008e+03,
              4.31415000000000000e+04 4.89241000000000058e+04 1.24747319999999991e+03,
              4.81415000000000000e+04 4.89241000000000058e+04 1.29592219999999998e+03,
              5.31415000000000000e+04 4.89241000000000058e+04 1.32990239999999994e+03,
              5.81415000000000000e+04 4.89241000000000058e+04 1.35873329999999987e+03,
              6.31415000000000000e+04 4.89241000000000058e+04 1.34945969999999988e+03,
              6.81415000000000000e+04 4.89241000000000058e+04 1.34386429999999996e+03,
              7.31415000000000000e+04 4.89241000000000058e+04 1.34466910000000007e+03,
              7.81415000000000000e+04 4.89241000000000058e+04 1.21290509999999995e+03,
              8.31415000000000000e+04 4.89241000000000058e+04 1.15120240000000013e+03,
              8.81415000000000000e+04 4.89241000000000058e+04 1.16247620000000006e+03,
              4.50915000000000000e+04 4.88741000000000058e+04 1.26350739999999996e+03,
              5.00915000000000000e+04 4.88741000000000058e+04 1.28207590000000005e+03,
              5.50915000000000000e+04 4.88741000000000058e+04 1.33998649999999998e+03,
              6.00915000000000000e+04 4.88741000000000058e+04 1.36431320000000005e+03,
              6.50915000000000000e+04 4.88741000000000058e+04 1.30961230000000000e+03,
              7.00915000000000000e+04 4.88741000000000058e+04 1.36845170000000007e+03,
              7.50915000000000000e+04 4.88741000000000058e+04 1.24604060000000004e+03,
              8.00915000000000000e+04 4.88741000000000058e+04 1.15579800000000000e+03,
              8.50915000000000000e+04 4.88741000000000058e+04 1.15519530000000009e+03,
              9.00915000000000000e+04 4.88741000000000058e+04 1.28164580000000001e+03,
              4.70415000000000000e+04 4.88241000000000058e+04 1.31731169999999997e+03,
              5.20415000000000000e+04 4.88241000000000058e+04 1.30777759999999989e+03,
              5.70415000000000000e+04 4.88241000000000058e+04 1.33030659999999989e+03,
              6.20415000000000000e+04 4.88241000000000058e+04 1.33224260000000004e+03,
              6.70415000000000000e+04 4.88241000000000058e+04 1.31767360000000008e+03,
              7.20415000000000000e+04 4.88241000000000058e+04 1.30002950000000010e+03,
              7.70415000000000000e+04 4.88241000000000058e+04 1.25716820000000007e+03,
              8.20415000000000000e+04 4.88241000000000058e+04 1.15170450000000005e+03,
              8.70415000000000000e+04 4.88241000000000058e+04 1.19328870000000006e+03,
              4.39915000000000000e+04 4.87741000000000058e+04 1.26644010000000003e+03,
              4.89915000000000000e+04 4.87741000000000058e+04 1.29201040000000012e+03,
              5.39915000000000000e+04 4.87741000000000058e+04 1.32859169999999995e+03,
              5.89915000000000000e+04 4.87741000000000058e+04 1.36507659999999987e+03,
              6.39915000000000000e+04 4.87741000000000058e+04 1.31708789999999999e+03,
              6.89915000000000000e+04 4.87741000000000058e+04 1.32982979999999998e+03,
              7.39915000000000000e+04 4.87741000000000058e+04 1.27634279999999990e+03,
              7.89915000000000000e+04 4.87741000000000058e+04 1.17487470000000008e+03,
              8.39915000000000000e+04 4.87741000000000058e+04 1.15439789999999994e+03,
              8.89915000000000000e+04 4.87741000000000058e+04 1.18674189999999999e+03,
              4.59415000000000000e+04 4.87241000000000058e+04 1.27758449999999993e+03,
              5.09415000000000000e+04 4.87241000000000058e+04 1.30017939999999999e+03,
              5.59415000000000000e+04 4.87241000000000058e+04 1.36264149999999995e+03,
              6.09415000000000000e+04 4.87241000000000058e+04 1.36192110000000002e+03,
              6.59415000000000000e+04 4.87241000000000058e+04 1.32020290000000000e+03,
              7.09415000000000000e+04 4.87241000000000058e+04 1.38741219999999998e+03,
              7.59415000000000000e+04 4.87241000000000058e+04 1.18966499999999996e+03,
              8.09415000000000000e+04 4.87241000000000058e+04 1.15402250000000004e+03,
              8.59415000000000000e+04 4.87241000000000058e+04 1.15727489999999989e+03,
              9.09415000000000000e+04 4.87241000000000058e+04 1.22616049999999996e+03,
              4.78915000000000000e+04 4.86741000000000058e+04 1.30739080000000013e+03,
              5.28915000000000000e+04 4.86741000000000058e+04 1.33474379999999996e+03,
              5.78915000000000000e+04 4.86741000000000058e+04 1.32991669999999999e+03,
              6.28915000000000000e+04 4.86741000000000058e+04 1.34288679999999999e+03,
              6.78915000000000000e+04 4.86741000000000058e+04 1.32225510000000008e+03,
              7.28915000000000000e+04 4.86741000000000058e+04 1.37195650000000001e+03,
              7.78915000000000000e+04 4.86741000000000058e+04 1.20995710000000008e+03,
              8.28915000000000000e+04 4.86741000000000058e+04 1.14598700000000008e+03,
              8.78915000000000000e+04 4.86741000000000058e+04 1.19319759999999997e+03,
              4.48415000000000000e+04 4.86241000000000058e+04 1.27932600000000002e+03,
              4.98415000000000000e+04 4.86241000000000058e+04 1.26701440000000002e+03,
              5.48415000000000000e+04 4.86241000000000058e+04 1.34527939999999990e+03,
              5.98415000000000000e+04 4.86241000000000058e+04 1.35721479999999997e+03,
              6.48415000000000000e+04 4.86241000000000058e+04 1.29651780000000008e+03,
              6.98415000000000000e+04 4.86241000000000058e+04 1.36396000000000004e+03,
              7.48415000000000000e+04 4.86241000000000058e+04 1.25118560000000002e+03,
              7.98415000000000000e+04 4.86241000000000058e+04 1.15540740000000005e+03,
              8.48415000000000000e+04 4.86241000000000058e+04 1.15791630000000009e+03,
              8.98415000000000000e+04 4.86241000000000058e+04 1.27516319999999996e+03,
              4.67915000000000000e+04 4.85741000000000058e+04 1.31136189999999988e+03,
              5.17915000000000000e+04 4.85741000000000058e+04 1.31783909999999992e+03,
              5.67915000000000000e+04 4.85741000000000058e+04 1.35563499999999999e+03,
              6.17915000000000000e+04 4.85741000000000058e+04 1.33573649999999998e+03,
              6.67915000000000000e+04 4.85741000000000058e+04 1.31772299999999996e+03,
              7.17915000000000000e+04 4.85741000000000058e+04 1.29323450000000003e+03,
              7.67915000000000000e+04 4.85741000000000058e+04 1.22211770000000001e+03,
              8.17915000000000000e+04 4.85741000000000058e+04 1.15282740000000013e+03,
              8.67915000000000000e+04 4.85741000000000058e+04 1.17598569999999995e+03,
              4.37415000000000000e+04 4.85241000000000058e+04 1.25736360000000013e+03,
              4.87415000000000000e+04 4.85241000000000058e+04 1.28616180000000008e+03,
              5.37415000000000000e+04 4.85241000000000058e+04 1.32928530000000001e+03,
              5.87415000000000000e+04 4.85241000000000058e+04 1.34582320000000004e+03,
              6.37415000000000000e+04 4.85241000000000058e+04 1.32156739999999991e+03,
              6.87415000000000000e+04 4.85241000000000058e+04 1.32162789999999995e+03,
              7.37415000000000000e+04 4.85241000000000058e+04 1.23495589999999993e+03,
              7.87415000000000000e+04 4.85241000000000058e+04 1.16865709999999990e+03,
              8.37415000000000000e+04 4.85241000000000058e+04 1.15535480000000007e+03,
              8.87415000000000000e+04 4.85241000000000058e+04 1.17018890000000010e+03,
              4.56915000000000000e+04 4.84741000000000058e+04 1.27492169999999987e+03,
              5.06915000000000000e+04 4.84741000000000058e+04 1.27746090000000004e+03,
              5.56915000000000000e+04 4.84741000000000058e+04 1.35377770000000010e+03,
              6.06915000000000000e+04 4.84741000000000058e+04 1.35859510000000000e+03,
              6.56915000000000000e+04 4.84741000000000058e+04 1.31538000000000011e+03,
              7.06915000000000000e+04 4.84741000000000058e+04 1.38808809999999994e+03,
              7.56915000000000000e+04 4.84741000000000058e+04 1.19778790000000004e+03,
              8.06915000000000000e+04 4.84741000000000058e+04 1.15296190000000001e+03,
              8.56915000000000000e+04 4.84741000000000058e+04 1.16989400000000001e+03,
              9.06915000000000000e+04 4.84741000000000058e+04 1.26426530000000002e+03,
              4.76415000000000000e+04 4.84241000000000058e+04 1.31176469999999995e+03,
              5.26415000000000000e+04 4.84241000000000058e+04 1.31808770000000004e+03,
              5.76415000000000000e+04 4.84241000000000058e+04 1.35573370000000000e+03,
              6.26415000000000000e+04 4.84241000000000058e+04 1.33620659999999998e+03,
              6.76415000000000000e+04 4.84241000000000058e+04 1.31831490000000008e+03,
              7.26415000000000000e+04 4.84241000000000058e+04 1.34321340000000009e+03,
              7.76415000000000000e+04 4.84241000000000058e+04 1.19687979999999993e+03,
              8.26415000000000000e+04 4.84241000000000058e+04 1.14643859999999995e+03,
              8.76415000000000000e+04 4.84241000000000058e+04 1.25005269999999996e+03,
              4.45915000000000000e+04 4.83741000000000058e+04 1.27703960000000006e+03,
              4.95915000000000000e+04 4.83741000000000058e+04 1.27814840000000004e+03,
              5.45915000000000000e+04 4.83741000000000058e+04 1.33406889999999999e+03,
              5.95915000000000000e+04 4.83741000000000058e+04 1.34935670000000005e+03,
              6.45915000000000000e+04 4.83741000000000058e+04 1.29844949999999994e+03,
              6.95915000000000000e+04 4.83741000000000058e+04 1.35545470000000000e+03,
              7.45915000000000000e+04 4.83741000000000058e+04 1.22647460000000001e+03,
              7.95915000000000000e+04 4.83741000000000058e+04 1.15731600000000003e+03,
              8.45915000000000000e+04 4.83741000000000058e+04 1.16036619999999994e+03,
              8.95915000000000000e+04 4.83741000000000058e+04 1.20431799999999998e+03,
              4.65415000000000000e+04 4.83241000000000058e+04 1.30887400000000002e+03,
              5.15415000000000000e+04 4.83241000000000058e+04 1.29744090000000006e+03,
              5.65415000000000000e+04 4.83241000000000058e+04 1.36683730000000014e+03,
              6.15415000000000000e+04 4.83241000000000058e+04 1.33282240000000002e+03,
              6.65415000000000000e+04 4.83241000000000058e+04 1.29436000000000013e+03,
              7.15415000000000000e+04 4.83241000000000058e+04 1.29472789999999986e+03,
              7.65415000000000000e+04 4.83241000000000058e+04 1.20880109999999991e+03,
              8.15415000000000000e+04 4.83241000000000058e+04 1.15485050000000001e+03,
              8.65415000000000000e+04 4.83241000000000058e+04 1.17180770000000007e+03,
              4.34915000000000000e+04 4.82741000000000058e+04 1.24960780000000000e+03,
              4.84915000000000000e+04 4.82741000000000058e+04 1.28600469999999996e+03,
              5.34915000000000000e+04 4.82741000000000058e+04 1.32027819999999997e+03,
              5.84915000000000000e+04 4.82741000000000058e+04 1.37416560000000004e+03,
              6.34915000000000000e+04 4.82741000000000058e+04 1.32467120000000000e+03,
              6.84915000000000000e+04 4.82741000000000058e+04 1.31176289999999995e+03,
              7.34915000000000000e+04 4.82741000000000058e+04 1.23180269999999996e+03,
              7.84915000000000000e+04 4.82741000000000058e+04 1.17026749999999993e+03,
              8.34915000000000000e+04 4.82741000000000058e+04 1.15516080000000011e+03,
              8.84915000000000000e+04 4.82741000000000058e+04 1.16149820000000000e+03,
              4.54415000000000000e+04 4.82241000000000058e+04 1.27787110000000007e+03,
              5.04415000000000000e+04 4.82241000000000058e+04 1.26454770000000008e+03,
              5.54415000000000000e+04 4.82241000000000058e+04 1.35993380000000002e+03,
              6.04415000000000000e+04 4.82241000000000058e+04 1.34960379999999986e+03,
              6.54415000000000000e+04 4.82241000000000058e+04 1.31346389999999997e+03,
              7.04415000000000000e+04 4.82241000000000058e+04 1.38396640000000002e+03,
              7.54415000000000000e+04 4.82241000000000058e+04 1.20802649999999994e+03,
              8.04415000000000000e+04 4.82241000000000058e+04 1.15531089999999995e+03,
              8.54415000000000000e+04 4.82241000000000058e+04 1.16755940000000010e+03,
              9.04415000000000000e+04 4.82241000000000058e+04 1.25000129999999990e+03,
              4.73915000000000000e+04 4.81741000000000058e+04 1.30688819999999987e+03,
              5.23915000000000000e+04 4.81741000000000058e+04 1.31575250000000005e+03,
              5.73915000000000000e+04 4.81741000000000058e+04 1.37196820000000002e+03,
              6.23915000000000000e+04 4.81741000000000058e+04 1.33387719999999990e+03,
              6.73915000000000000e+04 4.81741000000000058e+04 1.30798770000000013e+03,
              7.23915000000000000e+04 4.81741000000000058e+04 1.36620780000000013e+03,
              7.73915000000000000e+04 4.81741000000000058e+04 1.21747980000000007e+03,
              8.23915000000000000e+04 4.81741000000000058e+04 1.14700000000000000e+03,
              8.73915000000000000e+04 4.81741000000000058e+04 1.24146270000000004e+03,
              4.43415000000000000e+04 4.81241000000000058e+04 1.27634850000000006e+03,
              4.93415000000000000e+04 4.81241000000000058e+04 1.29018830000000003e+03,
              5.43415000000000000e+04 4.81241000000000058e+04 1.33299700000000007e+03,
              5.93415000000000000e+04 4.81241000000000058e+04 1.34730479999999989e+03,
              6.43415000000000000e+04 4.81241000000000058e+04 1.29666899999999987e+03,
              6.93415000000000000e+04 4.81241000000000058e+04 1.34277819999999997e+03,
              7.43415000000000000e+04 4.81241000000000058e+04 1.22623500000000013e+03,
              7.93415000000000000e+04 4.81241000000000058e+04 1.15791290000000004e+03,
              8.43415000000000000e+04 4.81241000000000058e+04 1.18405799999999999e+03,
              8.93415000000000000e+04 4.81241000000000058e+04 1.19597029999999995e+03,
              4.62915000000000000e+04 4.80741000000000058e+04 1.28896440000000007e+03,
              5.12915000000000000e+04 4.80741000000000058e+04 1.28864840000000004e+03,
              5.62915000000000000e+04 4.80741000000000058e+04 1.37440079999999989e+03,
              6.12915000000000000e+04 4.80741000000000058e+04 1.33248569999999995e+03,
              6.62915000000000000e+04 4.80741000000000058e+04 1.30681089999999995e+03,
              7.12915000000000000e+04 4.80741000000000058e+04 1.32733349999999996e+03,
              7.62915000000000000e+04 4.80741000000000058e+04 1.18708379999999988e+03,
              8.12915000000000000e+04 4.80741000000000058e+04 1.15358150000000001e+03,
              8.62915000000000000e+04 4.80741000000000058e+04 1.16270579999999995e+03,
              4.32415000000000000e+04 4.80241000000000058e+04 1.25045800000000008e+03,
              4.82415000000000000e+04 4.80241000000000058e+04 1.29688729999999987e+03,
              5.32415000000000000e+04 4.80241000000000058e+04 1.31865889999999990e+03,
              5.82415000000000000e+04 4.80241000000000058e+04 1.36156279999999992e+03,
              6.32415000000000000e+04 4.80241000000000058e+04 1.32428050000000007e+03,
              6.82415000000000000e+04 4.80241000000000058e+04 1.30787280000000010e+03,
              7.32415000000000000e+04 4.80241000000000058e+04 1.23510480000000007e+03,
              7.82415000000000000e+04 4.80241000000000058e+04 1.16404310000000009e+03,
              8.32415000000000000e+04 4.80241000000000058e+04 1.15540210000000002e+03,
              8.82415000000000000e+04 4.80241000000000058e+04 1.21398869999999988e+03,
              4.51915000000000000e+04 4.79741000000000058e+04 1.29512940000000003e+03,
              5.01915000000000000e+04 4.79741000000000058e+04 1.26932020000000011e+03,
              5.51915000000000000e+04 4.79741000000000058e+04 1.35489570000000003e+03,
              6.01915000000000000e+04 4.79741000000000058e+04 1.33871370000000002e+03,
              6.51915000000000000e+04 4.79741000000000058e+04 1.29724220000000014e+03,
              7.01915000000000000e+04 4.79741000000000058e+04 1.38450869999999986e+03,
              7.51915000000000000e+04 4.79741000000000058e+04 1.19565810000000010e+03,
              8.01915000000000000e+04 4.79741000000000058e+04 1.15654259999999999e+03,
              8.51915000000000000e+04 4.79741000000000058e+04 1.17867489999999998e+03,
              9.01915000000000000e+04 4.79741000000000058e+04 1.25646129999999994e+03,
              4.71415000000000000e+04 4.79241000000000058e+04 1.30102030000000013e+03,
              5.21415000000000000e+04 4.79241000000000058e+04 1.29745620000000008e+03,
              5.71415000000000000e+04 4.79241000000000058e+04 1.36563750000000005e+03,
              6.21415000000000000e+04 4.79241000000000058e+04 1.32968560000000002e+03,
              6.71415000000000000e+04 4.79241000000000058e+04 1.30418820000000005e+03,
              7.21415000000000000e+04 4.79241000000000058e+04 1.35716090000000008e+03,
              7.71415000000000000e+04 4.79241000000000058e+04 1.21267609999999991e+03,
              8.21415000000000000e+04 4.79241000000000058e+04 1.15353060000000005e+03,
              8.71415000000000000e+04 4.79241000000000058e+04 1.20905019999999990e+03,
              4.40915000000000000e+04 4.78741000000000058e+04 1.26241269999999986e+03,
              4.90915000000000000e+04 4.78741000000000058e+04 1.28907889999999998e+03,
              5.40915000000000000e+04 4.78741000000000058e+04 1.33419349999999986e+03,
              5.90915000000000000e+04 4.78741000000000058e+04 1.36687249999999995e+03,
              6.40915000000000000e+04 4.78741000000000058e+04 1.29624409999999989e+03,
              6.90915000000000000e+04 4.78741000000000058e+04 1.34434500000000003e+03,
              7.40915000000000000e+04 4.78741000000000058e+04 1.20291969999999992e+03,
              7.90915000000000000e+04 4.78741000000000058e+04 1.15911149999999998e+03,
              8.40915000000000000e+04 4.78741000000000058e+04 1.16705659999999989e+03,
              8.90915000000000000e+04 4.78741000000000058e+04 1.17451060000000007e+03,
              4.60415000000000000e+04 4.78241000000000058e+04 1.27519119999999998e+03,
              5.10415000000000000e+04 4.78241000000000058e+04 1.28387130000000002e+03,
              5.60415000000000000e+04 4.78241000000000058e+04 1.37164110000000005e+03,
              6.10415000000000000e+04 4.78241000000000058e+04 1.33742450000000008e+03,
              6.60415000000000000e+04 4.78241000000000058e+04 1.28953089999999997e+03,
              7.10415000000000000e+04 4.78241000000000058e+04 1.38091449999999986e+03,
              7.60415000000000000e+04 4.78241000000000058e+04 1.18567200000000003e+03,
              8.10415000000000000e+04 4.78241000000000058e+04 1.15657009999999991e+03,
              8.60415000000000000e+04 4.78241000000000058e+04 1.15899510000000009e+03,
              4.29915000000000000e+04 4.77741000000000058e+04 1.24705780000000004e+03,
              4.79915000000000000e+04 4.77741000000000058e+04 1.30697720000000004e+03,
              5.29915000000000000e+04 4.77741000000000058e+04 1.32177109999999993e+03,
              5.79915000000000000e+04 4.77741000000000058e+04 1.33903340000000003e+03,
              6.29915000000000000e+04 4.77741000000000058e+04 1.32606459999999993e+03,
              6.79915000000000000e+04 4.77741000000000058e+04 1.30205250000000001e+03,
              7.29915000000000000e+04 4.77741000000000058e+04 1.25085200000000009e+03,
              7.79915000000000000e+04 4.77741000000000058e+04 1.16773340000000007e+03,
              8.29915000000000000e+04 4.77741000000000058e+04 1.15883480000000009e+03,
              8.79915000000000000e+04 4.77741000000000058e+04 1.26168779999999992e+03,
              4.49415000000000000e+04 4.77241000000000058e+04 1.27405940000000010e+03,
              4.99415000000000000e+04 4.77241000000000058e+04 1.28682449999999994e+03,
              5.49415000000000000e+04 4.77241000000000058e+04 1.34814170000000013e+03,
              5.99415000000000000e+04 4.77241000000000058e+04 1.35144889999999987e+03,
              6.49415000000000000e+04 4.77241000000000058e+04 1.29319720000000007e+03,
              6.99415000000000000e+04 4.77241000000000058e+04 1.36314930000000004e+03,
              7.49415000000000000e+04 4.77241000000000058e+04 1.18965599999999995e+03,
              7.99415000000000000e+04 4.77241000000000058e+04 1.15634009999999989e+03,
              8.49415000000000000e+04 4.77241000000000058e+04 1.18257899999999995e+03,
              8.99415000000000000e+04 4.77241000000000058e+04 1.20875489999999991e+03,
              4.68915000000000000e+04 4.76741000000000058e+04 1.30807940000000008e+03,
              5.18915000000000000e+04 4.76741000000000058e+04 1.29018759999999997e+03,
              5.68915000000000000e+04 4.76741000000000058e+04 1.36488239999999996e+03,
              6.18915000000000000e+04 4.76741000000000058e+04 1.32974189999999999e+03,
              6.68915000000000000e+04 4.76741000000000058e+04 1.28895429999999988e+03,
              7.18915000000000000e+04 4.76741000000000058e+04 1.31383799999999997e+03,
              7.68915000000000000e+04 4.76741000000000058e+04 1.20352539999999999e+03,
              8.18915000000000000e+04 4.76741000000000058e+04 1.15626769999999988e+03,
              8.68915000000000000e+04 4.76741000000000058e+04 1.19117579999999998e+03,
              4.38415000000000000e+04 4.76241000000000058e+04 1.26075599999999986e+03,
              4.88415000000000000e+04 4.76241000000000058e+04 1.30576769999999988e+03,
              5.38415000000000000e+04 4.76241000000000058e+04 1.34258400000000006e+03,
              5.88415000000000000e+04 4.76241000000000058e+04 1.37246479999999997e+03,
              6.38415000000000000e+04 4.76241000000000058e+04 1.29655790000000002e+03,
              6.88415000000000000e+04 4.76241000000000058e+04 1.32241949999999997e+03,
              7.38415000000000000e+04 4.76241000000000058e+04 1.20821939999999995e+03,
              7.88415000000000000e+04 4.76241000000000058e+04 1.15896730000000002e+03,
              8.38415000000000000e+04 4.76241000000000058e+04 1.15856310000000008e+03,
              8.88415000000000000e+04 4.76241000000000058e+04 1.16338010000000008e+03,
              4.57915000000000000e+04 4.75741000000000058e+04 1.28755950000000007e+03,
              5.07915000000000000e+04 4.75741000000000058e+04 1.27434059999999999e+03,
              5.57915000000000000e+04 4.75741000000000058e+04 1.36566249999999991e+03,
              6.07915000000000000e+04 4.75741000000000058e+04 1.33995290000000000e+03,
              6.57915000000000000e+04 4.75741000000000058e+04 1.28373849999999993e+03,
              7.07915000000000000e+04 4.75741000000000058e+04 1.37404549999999995e+03,
              7.57915000000000000e+04 4.75741000000000058e+04 1.19018959999999993e+03,
              8.07915000000000000e+04 4.75741000000000058e+04 1.15288570000000004e+03,
              8.57915000000000000e+04 4.75741000000000058e+04 1.15945029999999997e+03,
              9.07915000000000000e+04 4.75741000000000058e+04 1.27143579999999997e+03,
              4.77415000000000000e+04 4.75241000000000058e+04 1.31400759999999991e+03,
              5.27415000000000000e+04 4.75241000000000058e+04 1.31188169999999991e+03,
              5.77415000000000000e+04 4.75241000000000058e+04 1.34072350000000006e+03,
              6.27415000000000000e+04 4.75241000000000058e+04 1.32747260000000006e+03,
              6.77415000000000000e+04 4.75241000000000058e+04 1.29115529999999990e+03,
              7.27415000000000000e+04 4.75241000000000058e+04 1.26113990000000013e+03,
              7.77415000000000000e+04 4.75241000000000058e+04 1.16620849999999996e+03,
              8.27415000000000000e+04 4.75241000000000058e+04 1.15793910000000005e+03,
              8.77415000000000000e+04 4.75241000000000058e+04 1.24146299999999997e+03,
              4.46915000000000000e+04 4.74741000000000058e+04 1.26354269999999997e+03,
              4.96915000000000000e+04 4.74741000000000058e+04 1.29037359999999990e+03,
              5.46915000000000000e+04 4.74741000000000058e+04 1.34084439999999995e+03,
              5.96915000000000000e+04 4.74741000000000058e+04 1.35269010000000003e+03,
              6.46915000000000000e+04 4.74741000000000058e+04 1.28849970000000008e+03,
              6.96915000000000000e+04 4.74741000000000058e+04 1.30527749999999992e+03,
              7.46915000000000000e+04 4.74741000000000058e+04 1.20202870000000007e+03,
              7.96915000000000000e+04 4.74741000000000058e+04 1.15632369999999992e+03,
              8.46915000000000000e+04 4.74741000000000058e+04 1.17213699999999994e+03,
              8.96915000000000000e+04 4.74741000000000058e+04 1.19708220000000006e+03,
              4.66415000000000000e+04 4.74241000000000058e+04 1.30377980000000002e+03,
              5.16415000000000000e+04 4.74241000000000058e+04 1.28506700000000001e+03,
              5.66415000000000000e+04 4.74241000000000058e+04 1.36646559999999999e+03,
              6.16415000000000000e+04 4.74241000000000058e+04 1.32702489999999989e+03,
              6.66415000000000000e+04 4.74241000000000058e+04 1.28761500000000001e+03,
              7.16415000000000000e+04 4.74241000000000058e+04 1.26919059999999990e+03,
              7.66415000000000000e+04 4.74241000000000058e+04 1.19753569999999991e+03,
              8.16415000000000000e+04 4.74241000000000058e+04 1.15650410000000011e+03,
              8.66415000000000000e+04 4.74241000000000058e+04 1.17939339999999993e+03,
              4.35915000000000000e+04 4.73741000000000058e+04 1.25675080000000003e+03,
              4.85915000000000000e+04 4.73741000000000058e+04 1.30825050000000010e+03,
              5.35915000000000000e+04 4.73741000000000058e+04 1.33463740000000007e+03,
              5.85915000000000000e+04 4.73741000000000058e+04 1.37870190000000002e+03,
              6.35915000000000000e+04 4.73741000000000058e+04 1.31306189999999992e+03,
              6.85915000000000000e+04 4.73741000000000058e+04 1.31033619999999996e+03,
              7.35915000000000000e+04 4.73741000000000058e+04 1.22775700000000006e+03,
              7.85915000000000000e+04 4.73741000000000058e+04 1.15768740000000003e+03,
              8.35915000000000000e+04 4.73741000000000058e+04 1.15991750000000002e+03,
              8.85915000000000000e+04 4.73741000000000058e+04 1.18390079999999989e+03,
              4.55415000000000000e+04 4.73241000000000058e+04 1.28897640000000001e+03,
              5.05415000000000000e+04 4.73241000000000058e+04 1.26557819999999992e+03,
              5.55415000000000000e+04 4.73241000000000058e+04 1.36765640000000008e+03,
              6.05415000000000000e+04 4.73241000000000058e+04 1.34025959999999986e+03,
              6.55415000000000000e+04 4.73241000000000058e+04 1.28405610000000001e+03,
              7.05415000000000000e+04 4.73241000000000058e+04 1.25931500000000005e+03,
              7.55415000000000000e+04 4.73241000000000058e+04 1.18091800000000012e+03,
              8.05415000000000000e+04 4.73241000000000058e+04 1.15096880000000010e+03,
              8.55415000000000000e+04 4.73241000000000058e+04 1.19838439999999991e+03,
              9.05415000000000000e+04 4.73241000000000058e+04 1.22514760000000001e+03,
              4.74915000000000000e+04 4.72741000000000058e+04 1.30738679999999999e+03,
              5.24915000000000000e+04 4.72741000000000058e+04 1.31283709999999996e+03,
              5.74915000000000000e+04 4.72741000000000058e+04 1.34758060000000000e+03,
              6.24915000000000000e+04 4.72741000000000058e+04 1.32306510000000003e+03,
              6.74915000000000000e+04 4.72741000000000058e+04 1.27741399999999999e+03,
              7.24915000000000000e+04 4.72741000000000058e+04 1.26319059999999990e+03,
              7.74915000000000000e+04 4.72741000000000058e+04 1.16666219999999998e+03,
              8.24915000000000000e+04 4.72741000000000058e+04 1.15766499999999996e+03,
              8.74915000000000000e+04 4.72741000000000058e+04 1.22035220000000004e+03,
              4.44415000000000000e+04 4.72241000000000058e+04 1.26053410000000008e+03,
              4.94415000000000000e+04 4.72241000000000058e+04 1.31769499999999994e+03,
              5.44415000000000000e+04 4.72241000000000058e+04 1.33917679999999996e+03,
              5.94415000000000000e+04 4.72241000000000058e+04 1.35206750000000011e+03,
              6.44415000000000000e+04 4.72241000000000058e+04 1.30094489999999996e+03,
              6.94415000000000000e+04 4.72241000000000058e+04 1.35041609999999991e+03,
              7.44415000000000000e+04 4.72241000000000058e+04 1.21231109999999990e+03,
              7.94415000000000000e+04 4.72241000000000058e+04 1.15592570000000001e+03,
              8.44415000000000000e+04 4.72241000000000058e+04 1.16806770000000006e+03,
              8.94415000000000000e+04 4.72241000000000058e+04 1.16831199999999990e+03,
              4.63915000000000000e+04 4.71741000000000058e+04 1.29655860000000007e+03,
              5.13915000000000000e+04 4.71741000000000058e+04 1.28219790000000012e+03,
              5.63915000000000000e+04 4.71741000000000058e+04 1.35962650000000008e+03,
              6.13915000000000000e+04 4.71741000000000058e+04 1.32890799999999990e+03,
              6.63915000000000000e+04 4.71741000000000058e+04 1.28513149999999996e+03,
              7.13915000000000000e+04 4.71741000000000058e+04 1.25720039999999995e+03,
              7.63915000000000000e+04 4.71741000000000058e+04 1.17981979999999999e+03,
              8.13915000000000000e+04 4.71741000000000058e+04 1.15734179999999992e+03,
              8.63915000000000000e+04 4.71741000000000058e+04 1.17496869999999990e+03,
              4.33415000000000000e+04 4.71241000000000058e+04 1.25167730000000006e+03,
              4.83415000000000000e+04 4.71241000000000058e+04 1.31488020000000006e+03,
              5.33415000000000000e+04 4.71241000000000058e+04 1.33331760000000008e+03,
              5.83415000000000000e+04 4.71241000000000058e+04 1.37103919999999994e+03,
              6.33415000000000000e+04 4.71241000000000058e+04 1.31825749999999994e+03,
              6.83415000000000000e+04 4.71241000000000058e+04 1.31182850000000008e+03,
              7.33415000000000000e+04 4.71241000000000058e+04 1.24304559999999992e+03,
              7.83415000000000000e+04 4.71241000000000058e+04 1.16436889999999994e+03,
              8.33415000000000000e+04 4.71241000000000058e+04 1.16007950000000005e+03,
              8.83415000000000000e+04 4.71241000000000058e+04 1.26241539999999986e+03,
              4.52915000000000000e+04 4.70741000000000058e+04 1.27714589999999998e+03,
              5.02915000000000000e+04 4.70741000000000058e+04 1.27694439999999986e+03,
              5.52915000000000000e+04 4.70741000000000058e+04 1.36232330000000002e+03,
              6.02915000000000000e+04 4.70741000000000058e+04 1.34202210000000014e+03,
              6.52915000000000000e+04 4.70741000000000058e+04 1.28114969999999994e+03,
              7.02915000000000000e+04 4.70741000000000058e+04 1.28353019999999992e+03,
              7.52915000000000000e+04 4.70741000000000058e+04 1.19317229999999995e+03,
              8.02915000000000000e+04 4.70741000000000058e+04 1.15097199999999998e+03,
              8.52915000000000000e+04 4.70741000000000058e+04 1.20941730000000007e+03,
              9.02915000000000000e+04 4.70741000000000058e+04 1.20856369999999993e+03,
              4.72415000000000000e+04 4.70241000000000058e+04 1.29913069999999993e+03,
              5.22415000000000000e+04 4.70241000000000058e+04 1.29748080000000004e+03,
              5.72415000000000000e+04 4.70241000000000058e+04 1.35893200000000002e+03,
              6.22415000000000000e+04 4.70241000000000058e+04 1.32625620000000004e+03,
              6.72415000000000000e+04 4.70241000000000058e+04 1.27135490000000004e+03,
              7.22415000000000000e+04 4.70241000000000058e+04 1.31207279999999992e+03,
              7.72415000000000000e+04 4.70241000000000058e+04 1.16849150000000009e+03,
              8.22415000000000000e+04 4.70241000000000058e+04 1.15680899999999997e+03,
              8.72415000000000000e+04 4.70241000000000058e+04 1.20452510000000007e+03,
              4.41915000000000000e+04 4.69741000000000058e+04 1.25848839999999996e+03,
              4.91915000000000000e+04 4.69741000000000058e+04 1.31427569999999992e+03,
              5.41915000000000000e+04 4.69741000000000058e+04 1.33475160000000005e+03,
              5.91915000000000000e+04 4.69741000000000058e+04 1.35859410000000003e+03,
              6.41915000000000000e+04 4.69741000000000058e+04 1.29515200000000004e+03,
              6.91915000000000000e+04 4.69741000000000058e+04 1.35641550000000007e+03,
              7.41915000000000000e+04 4.69741000000000058e+04 1.27161130000000003e+03,
              7.91915000000000000e+04 4.69741000000000058e+04 1.15835349999999994e+03,
              8.41915000000000000e+04 4.69741000000000058e+04 1.17303160000000003e+03,
              8.91915000000000000e+04 4.69741000000000058e+04 1.16597839999999997e+03,
              4.61415000000000000e+04 4.69241000000000058e+04 1.29302150000000006e+03,
              5.11415000000000000e+04 4.69241000000000058e+04 1.27823829999999998e+03,
              5.61415000000000000e+04 4.69241000000000058e+04 1.34839239999999995e+03,
              6.11415000000000000e+04 4.69241000000000058e+04 1.32941579999999999e+03,
              6.61415000000000000e+04 4.69241000000000058e+04 1.27451559999999995e+03,
              7.11415000000000000e+04 4.69241000000000058e+04 1.25242660000000001e+03,
              7.61415000000000000e+04 4.69241000000000058e+04 1.17356430000000000e+03,
              8.11415000000000000e+04 4.69241000000000058e+04 1.15777179999999998e+03,
              8.61415000000000000e+04 4.69241000000000058e+04 1.16222090000000003e+03,
              4.30915000000000000e+04 4.68741000000000058e+04 1.24320640000000003e+03,
              4.80915000000000000e+04 4.68741000000000058e+04 1.29257860000000005e+03,
              5.30915000000000000e+04 4.68741000000000058e+04 1.32906919999999991e+03,
              5.80915000000000000e+04 4.68741000000000058e+04 1.36402009999999996e+03,
              6.30915000000000000e+04 4.68741000000000058e+04 1.30256430000000000e+03,
              6.80915000000000000e+04 4.68741000000000058e+04 1.31583670000000006e+03,
              7.30915000000000000e+04 4.68741000000000058e+04 1.24789670000000001e+03,
              7.80915000000000000e+04 4.68741000000000058e+04 1.16226330000000007e+03,
              8.30915000000000000e+04 4.68741000000000058e+04 1.15571880000000010e+03,
              8.80915000000000000e+04 4.68741000000000058e+04 1.26460169999999994e+03,
              4.50415000000000000e+04 4.68241000000000058e+04 1.26606340000000000e+03,
              5.00415000000000000e+04 4.68241000000000058e+04 1.28051949999999988e+03,
              5.50415000000000000e+04 4.68241000000000058e+04 1.35822579999999994e+03,
              6.00415000000000000e+04 4.68241000000000058e+04 1.34289869999999996e+03,
              6.50415000000000000e+04 4.68241000000000058e+04 1.29071509999999989e+03,
              7.00415000000000000e+04 4.68241000000000058e+04 1.38669929999999999e+03,
              7.50415000000000000e+04 4.68241000000000058e+04 1.21520240000000013e+03,
              8.00415000000000000e+04 4.68241000000000058e+04 1.15125459999999998e+03,
              8.50415000000000000e+04 4.68241000000000058e+04 1.19862969999999996e+03,
              9.00415000000000000e+04 4.68241000000000058e+04 1.18236840000000007e+03,
              4.69915000000000000e+04 4.67741000000000058e+04 1.29781780000000003e+03,
              5.19915000000000000e+04 4.67741000000000058e+04 1.29257339999999999e+03,
              5.69915000000000000e+04 4.67741000000000058e+04 1.36573649999999998e+03,
              6.19915000000000000e+04 4.67741000000000058e+04 1.32387940000000003e+03,
              6.69915000000000000e+04 4.67741000000000058e+04 1.27509619999999995e+03,
              7.19915000000000000e+04 4.67741000000000058e+04 1.33205520000000001e+03,
              7.69915000000000000e+04 4.67741000000000058e+04 1.17239159999999993e+03,
              8.19915000000000000e+04 4.67741000000000058e+04 1.15804259999999999e+03,
              8.69915000000000000e+04 4.67741000000000058e+04 1.19038130000000001e+03,
              4.39415000000000000e+04 4.67241000000000058e+04 1.25082259999999997e+03,
              4.89415000000000000e+04 4.67241000000000058e+04 1.30351909999999998e+03,
              5.39415000000000000e+04 4.67241000000000058e+04 1.32798109999999997e+03,
              5.89415000000000000e+04 4.67241000000000058e+04 1.35960899999999992e+03,
              6.39415000000000000e+04 4.67241000000000058e+04 1.28596620000000007e+03,
              6.89415000000000000e+04 4.67241000000000058e+04 1.34767579999999998e+03,
              7.39415000000000000e+04 4.67241000000000058e+04 1.31224530000000004e+03,
              7.89415000000000000e+04 4.67241000000000058e+04 1.15684500000000003e+03,
              8.39415000000000000e+04 4.67241000000000058e+04 1.16630199999999991e+03,
              8.89415000000000000e+04 4.67241000000000058e+04 1.19499910000000000e+03,
              4.58915000000000000e+04 4.66741000000000058e+04 1.30656510000000003e+03,
              5.08915000000000000e+04 4.66741000000000058e+04 1.27173550000000000e+03,
              5.58915000000000000e+04 4.66741000000000058e+04 1.34376630000000000e+03,
              6.08915000000000000e+04 4.66741000000000058e+04 1.32954250000000002e+03,
              6.58915000000000000e+04 4.66741000000000058e+04 1.27333390000000009e+03,
              7.08915000000000000e+04 4.66741000000000058e+04 1.23575649999999996e+03,
              7.58915000000000000e+04 4.66741000000000058e+04 1.18008539999999994e+03,
              8.08915000000000000e+04 4.66741000000000058e+04 1.16192419999999993e+03,
              8.58915000000000000e+04 4.66741000000000058e+04 1.16169290000000001e+03,
              9.08915000000000000e+04 4.66741000000000058e+04 1.21089359999999988e+03,
              4.78415000000000000e+04 4.66241000000000058e+04 1.29019779999999992e+03,
              5.28415000000000000e+04 4.66241000000000058e+04 1.31800140000000010e+03,
              5.78415000000000000e+04 4.66241000000000058e+04 1.33880650000000014e+03,
              6.28415000000000000e+04 4.66241000000000058e+04 1.30444959999999992e+03,
              6.78415000000000000e+04 4.66241000000000058e+04 1.23811899999999991e+03,
              7.28415000000000000e+04 4.66241000000000058e+04 1.24069929999999999e+03,
              7.78415000000000000e+04 4.66241000000000058e+04 1.16197299999999996e+03,
              8.28415000000000000e+04 4.66241000000000058e+04 1.15795609999999988e+03,
              8.78415000000000000e+04 4.66241000000000058e+04 1.25724960000000010e+03,
              4.47915000000000000e+04 4.65741000000000058e+04 1.26663850000000002e+03,
              4.97915000000000000e+04 4.65741000000000058e+04 1.28405209999999988e+03,
              5.47915000000000000e+04 4.65741000000000058e+04 1.35342640000000006e+03,
              5.97915000000000000e+04 4.65741000000000058e+04 1.34452379999999994e+03,
              6.47915000000000000e+04 4.65741000000000058e+04 1.28169139999999993e+03,
              6.97915000000000000e+04 4.65741000000000058e+04 1.37732159999999999e+03,
              7.47915000000000000e+04 4.65741000000000058e+04 1.21823099999999999e+03,
              7.97915000000000000e+04 4.65741000000000058e+04 1.15159069999999997e+03,
              8.47915000000000000e+04 4.65741000000000058e+04 1.18283930000000009e+03,
              8.97915000000000000e+04 4.65741000000000058e+04 1.17380029999999988e+03,
              4.67415000000000000e+04 4.65241000000000058e+04 1.30070939999999996e+03,
              5.17415000000000000e+04 4.65241000000000058e+04 1.28633320000000003e+03,
              5.67415000000000000e+04 4.65241000000000058e+04 1.36084279999999990e+03,
              6.17415000000000000e+04 4.65241000000000058e+04 1.32146039999999994e+03,
              6.67415000000000000e+04 4.65241000000000058e+04 1.27665990000000011e+03,
              7.17415000000000000e+04 4.65241000000000058e+04 1.31647389999999996e+03,
              7.67415000000000000e+04 4.65241000000000058e+04 1.19089679999999998e+03,
              8.17415000000000000e+04 4.65241000000000058e+04 1.15916949999999997e+03,
              8.67415000000000000e+04 4.65241000000000058e+04 1.18072060000000010e+03,
              4.36915000000000000e+04 4.64741000000000058e+04 1.23978590000000008e+03,
              4.86915000000000000e+04 4.64741000000000058e+04 1.29123329999999987e+03,
              5.36915000000000000e+04 4.64741000000000058e+04 1.32391489999999999e+03,
              5.86915000000000000e+04 4.64741000000000058e+04 1.35487290000000007e+03,
              6.36915000000000000e+04 4.64741000000000058e+04 1.29429939999999988e+03,
              6.86915000000000000e+04 4.64741000000000058e+04 1.30793150000000014e+03,
              7.36915000000000000e+04 4.64741000000000058e+04 1.32609400000000005e+03,
              7.86915000000000000e+04 4.64741000000000058e+04 1.15588300000000004e+03,
              8.36915000000000000e+04 4.64741000000000058e+04 1.16345090000000005e+03,
              8.86915000000000000e+04 4.64741000000000058e+04 1.22891840000000002e+03,
              4.56415000000000000e+04 4.64241000000000058e+04 1.29795789999999988e+03,
              5.06415000000000000e+04 4.64241000000000058e+04 1.28577050000000008e+03,
              5.56415000000000000e+04 4.64241000000000058e+04 1.33988149999999996e+03,
              6.06415000000000000e+04 4.64241000000000058e+04 1.33333370000000014e+03,
              6.56415000000000000e+04 4.64241000000000058e+04 1.29011670000000004e+03,
              7.06415000000000000e+04 4.64241000000000058e+04 1.23055760000000009e+03,
              7.56415000000000000e+04 4.64241000000000058e+04 1.18736689999999999e+03,
              8.06415000000000000e+04 4.64241000000000058e+04 1.16330719999999997e+03,
              8.56415000000000000e+04 4.64241000000000058e+04 1.16658300000000008e+03,
              9.06415000000000000e+04 4.64241000000000058e+04 1.21985799999999995e+03,
              4.75915000000000000e+04 4.63741000000000058e+04 1.26735960000000000e+03,
              5.25915000000000000e+04 4.63741000000000058e+04 1.30137509999999997e+03,
              5.75915000000000000e+04 4.63741000000000058e+04 1.34721599999999989e+03,
              6.25915000000000000e+04 4.63741000000000058e+04 1.30800330000000008e+03,
              6.75915000000000000e+04 4.63741000000000058e+04 1.31907590000000005e+03,
              7.25915000000000000e+04 4.63741000000000058e+04 1.23264200000000005e+03,
              7.75915000000000000e+04 4.63741000000000058e+04 1.15997299999999996e+03,
              8.25915000000000000e+04 4.63741000000000058e+04 1.15741499999999996e+03,
              8.75915000000000000e+04 4.63741000000000058e+04 1.22724000000000001e+03,
              4.45415000000000000e+04 4.63241000000000058e+04 1.26976580000000013e+03,
              4.95415000000000000e+04 4.63241000000000058e+04 1.30115920000000006e+03,
              5.45415000000000000e+04 4.63241000000000058e+04 1.34583809999999994e+03,
              5.95415000000000000e+04 4.63241000000000058e+04 1.34254710000000000e+03,
              6.45415000000000000e+04 4.63241000000000058e+04 1.28042439999999988e+03,
              6.95415000000000000e+04 4.63241000000000058e+04 1.36800800000000004e+03,
              7.45415000000000000e+04 4.63241000000000058e+04 1.20550530000000003e+03,
              7.95415000000000000e+04 4.63241000000000058e+04 1.15186830000000009e+03,
              8.45415000000000000e+04 4.63241000000000058e+04 1.16689979999999991e+03,
              8.95415000000000000e+04 4.63241000000000058e+04 1.16761369999999988e+03,
              4.64915000000000000e+04 4.62741000000000058e+04 1.28932279999999992e+03,
              5.14915000000000000e+04 4.62741000000000058e+04 1.27209719999999993e+03,
              5.64915000000000000e+04 4.62741000000000058e+04 1.36985690000000000e+03,
              6.14915000000000000e+04 4.62741000000000058e+04 1.32254849999999988e+03,
              6.64915000000000000e+04 4.62741000000000058e+04 1.26341020000000003e+03,
              7.14915000000000000e+04 4.62741000000000058e+04 1.34265400000000000e+03,
              7.64915000000000000e+04 4.62741000000000058e+04 1.19266959999999995e+03,
              8.14915000000000000e+04 4.62741000000000058e+04 1.16702420000000006e+03,
              8.64915000000000000e+04 4.62741000000000058e+04 1.20435169999999994e+03,
              4.34415000000000000e+04 4.62241000000000058e+04 1.23796610000000010e+03,
              4.84415000000000000e+04 4.62241000000000058e+04 1.28781469999999990e+03,
              5.34415000000000000e+04 4.62241000000000058e+04 1.31752729999999997e+03,
              5.84415000000000000e+04 4.62241000000000058e+04 1.34232700000000000e+03,
              6.34415000000000000e+04 4.62241000000000058e+04 1.28594380000000001e+03,
              6.84415000000000000e+04 4.62241000000000058e+04 1.26238689999999997e+03,
              7.34415000000000000e+04 4.62241000000000058e+04 1.32242799999999988e+03,
              7.84415000000000000e+04 4.62241000000000058e+04 1.15989580000000001e+03,
              8.34415000000000000e+04 4.62241000000000058e+04 1.15882830000000013e+03,
              8.84415000000000000e+04 4.62241000000000058e+04 1.24784680000000003e+03,
              4.53915000000000000e+04 4.61741000000000058e+04 1.27862560000000008e+03,
              5.03915000000000000e+04 4.61741000000000058e+04 1.29028119999999990e+03,
              5.53915000000000000e+04 4.61741000000000058e+04 1.34138459999999986e+03,
              6.03915000000000000e+04 4.61741000000000058e+04 1.35209189999999990e+03,
              6.53915000000000000e+04 4.61741000000000058e+04 1.27279369999999994e+03,
              7.03915000000000000e+04 4.61741000000000058e+04 1.24592110000000002e+03,
              7.53915000000000000e+04 4.61741000000000058e+04 1.18727099999999996e+03,
              8.03915000000000000e+04 4.61741000000000058e+04 1.15517499999999995e+03,
              8.53915000000000000e+04 4.61741000000000058e+04 1.17948440000000005e+03,
              9.03915000000000000e+04 4.61741000000000058e+04 1.19821409999999992e+03,
              4.73415000000000000e+04 4.61241000000000058e+04 1.26468460000000005e+03,
              5.23415000000000000e+04 4.61241000000000058e+04 1.29234750000000008e+03,
              5.73415000000000000e+04 4.61241000000000058e+04 1.35971599999999989e+03,
              6.23415000000000000e+04 4.61241000000000058e+04 1.30719080000000008e+03,
              6.73415000000000000e+04 4.61241000000000058e+04 1.31053629999999998e+03,
              7.23415000000000000e+04 4.61241000000000058e+04 1.24656819999999993e+03,
              7.73415000000000000e+04 4.61241000000000058e+04 1.16079849999999988e+03,
              8.23415000000000000e+04 4.61241000000000058e+04 1.16082339999999999e+03,
              8.73415000000000000e+04 4.61241000000000058e+04 1.22331379999999990e+03,
              4.42915000000000000e+04 4.60741000000000058e+04 1.25639429999999993e+03,
              4.92915000000000000e+04 4.60741000000000058e+04 1.31041139999999996e+03,
              5.42915000000000000e+04 4.60741000000000058e+04 1.34004999999999995e+03,
              5.92915000000000000e+04 4.60741000000000058e+04 1.33727489999999989e+03,
              6.42915000000000000e+04 4.60741000000000058e+04 1.27725489999999991e+03,
              6.92915000000000000e+04 4.60741000000000058e+04 1.36212149999999997e+03,
              7.42915000000000000e+04 4.60741000000000058e+04 1.22461359999999991e+03,
              7.92915000000000000e+04 4.60741000000000058e+04 1.15793910000000005e+03,
              8.42915000000000000e+04 4.60741000000000058e+04 1.17463529999999992e+03,
              8.92915000000000000e+04 4.60741000000000058e+04 1.18115589999999997e+03,
              4.62415000000000000e+04 4.60241000000000058e+04 1.27471469999999999e+03,
              5.12415000000000000e+04 4.60241000000000058e+04 1.28565169999999989e+03,
              5.62415000000000000e+04 4.60241000000000058e+04 1.35594020000000000e+03,
              6.12415000000000000e+04 4.60241000000000058e+04 1.31732269999999994e+03,
              6.62415000000000000e+04 4.60241000000000058e+04 1.28060960000000000e+03,
              7.12415000000000000e+04 4.60241000000000058e+04 1.34690169999999989e+03,
              7.62415000000000000e+04 4.60241000000000058e+04 1.16951320000000010e+03,
              8.12415000000000000e+04 4.60241000000000058e+04 1.16905330000000004e+03,
              8.62415000000000000e+04 4.60241000000000058e+04 1.19457290000000012e+03,
              4.31915000000000000e+04 4.59741000000000058e+04 1.23516370000000006e+03,
              4.81915000000000000e+04 4.59741000000000058e+04 1.26899479999999994e+03,
              5.31915000000000000e+04 4.59741000000000058e+04 1.31114789999999994e+03,
              5.81915000000000000e+04 4.59741000000000058e+04 1.33645609999999988e+03,
              6.31915000000000000e+04 4.59741000000000058e+04 1.29144759999999997e+03,
              6.81915000000000000e+04 4.59741000000000058e+04 1.25369810000000007e+03,
              7.31915000000000000e+04 4.59741000000000058e+04 1.30889689999999996e+03,
              7.81915000000000000e+04 4.59741000000000058e+04 1.15996900000000005e+03,
              8.31915000000000000e+04 4.59741000000000058e+04 1.16412139999999999e+03,
              8.81915000000000000e+04 4.59741000000000058e+04 1.24922730000000001e+03,
              4.51415000000000000e+04 4.59241000000000058e+04 1.26997609999999986e+03,
              5.01415000000000000e+04 4.59241000000000058e+04 1.29148309999999992e+03,
              5.51415000000000000e+04 4.59241000000000058e+04 1.34112599999999998e+03,
              6.01415000000000000e+04 4.59241000000000058e+04 1.34294979999999987e+03,
              6.51415000000000000e+04 4.59241000000000058e+04 1.27054369999999994e+03,
              7.01415000000000000e+04 4.59241000000000058e+04 1.23676819999999998e+03,
              7.51415000000000000e+04 4.59241000000000058e+04 1.21192000000000007e+03,
              8.01415000000000000e+04 4.59241000000000058e+04 1.15494170000000008e+03,
              8.51415000000000000e+04 4.59241000000000058e+04 1.17596569999999997e+03,
              9.01415000000000000e+04 4.59241000000000058e+04 1.18075739999999996e+03,
              4.70915000000000000e+04 4.58741000000000058e+04 1.25715200000000004e+03,
              5.20915000000000000e+04 4.58741000000000058e+04 1.28567759999999998e+03,
              5.70915000000000000e+04 4.58741000000000058e+04 1.35767709999999988e+03,
              6.20915000000000000e+04 4.58741000000000058e+04 1.31201949999999988e+03,
              6.70915000000000000e+04 4.58741000000000058e+04 1.30299820000000000e+03,
              7.20915000000000000e+04 4.58741000000000058e+04 1.27603140000000008e+03,
              7.70915000000000000e+04 4.58741000000000058e+04 1.16448170000000005e+03,
              8.20915000000000000e+04 4.58741000000000058e+04 1.16623959999999988e+03,
              8.70915000000000000e+04 4.58741000000000058e+04 1.19455089999999996e+03,
              4.40415000000000000e+04 4.58241000000000058e+04 1.24254210000000012e+03,
              4.90415000000000000e+04 4.58241000000000058e+04 1.31118000000000006e+03,
              5.40415000000000000e+04 4.58241000000000058e+04 1.33501060000000007e+03,
              5.90415000000000000e+04 4.58241000000000058e+04 1.33807650000000012e+03,
              6.40415000000000000e+04 4.58241000000000058e+04 1.27563879999999995e+03,
              6.90415000000000000e+04 4.58241000000000058e+04 1.30794419999999991e+03,
              7.40415000000000000e+04 4.58241000000000058e+04 1.25549489999999992e+03,
              7.90415000000000000e+04 4.58241000000000058e+04 1.15639359999999988e+03,
              8.40415000000000000e+04 4.58241000000000058e+04 1.16938699999999994e+03,
              8.90415000000000000e+04 4.58241000000000058e+04 1.23669149999999991e+03,
              4.59915000000000000e+04 4.57741000000000058e+04 1.26587259999999992e+03,
              5.09915000000000000e+04 4.57741000000000058e+04 1.27357839999999987e+03,
              5.59915000000000000e+04 4.57741000000000058e+04 1.35027129999999988e+03,
              6.09915000000000000e+04 4.57741000000000058e+04 1.32754430000000002e+03,
              6.59915000000000000e+04 4.57741000000000058e+04 1.25897699999999986e+03,
              7.09915000000000000e+04 4.57741000000000058e+04 1.26891530000000012e+03,
              7.59915000000000000e+04 4.57741000000000058e+04 1.17779420000000005e+03,
              8.09915000000000000e+04 4.57741000000000058e+04 1.16666429999999991e+03,
              8.59915000000000000e+04 4.57741000000000058e+04 1.16516849999999999e+03,
              9.09915000000000000e+04 4.57741000000000058e+04 1.22636179999999990e+03,
              4.79415000000000000e+04 4.57241000000000058e+04 1.28812809999999990e+03,
              5.29415000000000000e+04 4.57241000000000058e+04 1.30629240000000004e+03,
              5.79415000000000000e+04 4.57241000000000058e+04 1.33878220000000010e+03,
              6.29415000000000000e+04 4.57241000000000058e+04 1.31036660000000006e+03,
              6.79415000000000000e+04 4.57241000000000058e+04 1.33223289999999997e+03,
              7.29415000000000000e+04 4.57241000000000058e+04 1.22159940000000006e+03,
              7.79415000000000000e+04 4.57241000000000058e+04 1.16133269999999993e+03,
              8.29415000000000000e+04 4.57241000000000058e+04 1.16671209999999996e+03,
              8.79415000000000000e+04 4.57241000000000058e+04 1.23726839999999993e+03,
              4.48915000000000000e+04 4.56741000000000058e+04 1.27049360000000001e+03,
              4.98915000000000000e+04 4.56741000000000058e+04 1.28297399999999993e+03,
              5.48915000000000000e+04 4.56741000000000058e+04 1.34612280000000010e+03,
              5.98915000000000000e+04 4.56741000000000058e+04 1.33281230000000005e+03,
              6.48915000000000000e+04 4.56741000000000058e+04 1.28736830000000009e+03,
              6.98915000000000000e+04 4.56741000000000058e+04 1.20981880000000001e+03,
              7.48915000000000000e+04 4.56741000000000058e+04 1.22703269999999998e+03,
              7.98915000000000000e+04 4.56741000000000058e+04 1.15393579999999997e+03,
              8.48915000000000000e+04 4.56741000000000058e+04 1.16742910000000006e+03,
              8.98915000000000000e+04 4.56741000000000058e+04 1.16962609999999995e+03,
              4.68415000000000000e+04 4.56241000000000058e+04 1.26460480000000007e+03,
              5.18415000000000000e+04 4.56241000000000058e+04 1.28342020000000002e+03,
              5.68415000000000000e+04 4.56241000000000058e+04 1.35521109999999999e+03,
              6.18415000000000000e+04 4.56241000000000058e+04 1.31905150000000003e+03,
              6.68415000000000000e+04 4.56241000000000058e+04 1.30106379999999990e+03,
              7.18415000000000000e+04 4.56241000000000058e+04 1.27675999999999999e+03,
              7.68415000000000000e+04 4.56241000000000058e+04 1.17090200000000004e+03,
              8.18415000000000000e+04 4.56241000000000058e+04 1.18770370000000003e+03,
              8.68415000000000000e+04 4.56241000000000058e+04 1.18076220000000012e+03,
              4.37915000000000000e+04 4.55741000000000058e+04 1.24136140000000000e+03,
              4.87915000000000000e+04 4.55741000000000058e+04 1.30029500000000007e+03,
              5.37915000000000000e+04 4.55741000000000058e+04 1.32797919999999999e+03,
              5.87915000000000000e+04 4.55741000000000058e+04 1.33445890000000009e+03,
              6.37915000000000000e+04 4.55741000000000058e+04 1.27656549999999993e+03,
              6.87915000000000000e+04 4.55741000000000058e+04 1.34845560000000000e+03,
              7.37915000000000000e+04 4.55741000000000058e+04 1.23378559999999993e+03,
              7.87915000000000000e+04 4.55741000000000058e+04 1.15750900000000001e+03,
              8.37915000000000000e+04 4.55741000000000058e+04 1.16588889999999992e+03,
              8.87915000000000000e+04 4.55741000000000058e+04 1.26285870000000000e+03,
              4.57415000000000000e+04 4.55241000000000058e+04 1.26877639999999997e+03,
              5.07415000000000000e+04 4.55241000000000058e+04 1.27881799999999998e+03,
              5.57415000000000000e+04 4.55241000000000058e+04 1.34284369999999990e+03,
              6.07415000000000000e+04 4.55241000000000058e+04 1.31610449999999992e+03,
              6.57415000000000000e+04 4.55241000000000058e+04 1.27574240000000009e+03,
              7.07415000000000000e+04 4.55241000000000058e+04 1.22117169999999987e+03,
              7.57415000000000000e+04 4.55241000000000058e+04 1.21788789999999995e+03,
              8.07415000000000000e+04 4.55241000000000058e+04 1.17160140000000001e+03,
              8.57415000000000000e+04 4.55241000000000058e+04 1.16797170000000006e+03,
              9.07415000000000000e+04 4.55241000000000058e+04 1.18651340000000005e+03,
              4.76915000000000000e+04 4.54741000000000058e+04 1.26734580000000005e+03,
              5.26915000000000000e+04 4.54741000000000058e+04 1.29599659999999994e+03,
              5.76915000000000000e+04 4.54741000000000058e+04 1.34435780000000000e+03,
              6.26915000000000000e+04 4.54741000000000058e+04 1.29353440000000001e+03,
              6.76915000000000000e+04 4.54741000000000058e+04 1.30939400000000001e+03,
              7.26915000000000000e+04 4.54741000000000058e+04 1.28388869999999997e+03,
              7.76915000000000000e+04 4.54741000000000058e+04 1.16428279999999995e+03,
              8.26915000000000000e+04 4.54741000000000058e+04 1.19008259999999996e+03,
              8.76915000000000000e+04 4.54741000000000058e+04 1.23175250000000005e+03,
              4.46415000000000000e+04 4.54241000000000058e+04 1.26166120000000001e+03,
              4.96415000000000000e+04 4.54241000000000058e+04 1.28628960000000006e+03,
              5.46415000000000000e+04 4.54241000000000058e+04 1.34296340000000009e+03,
              5.96415000000000000e+04 4.54241000000000058e+04 1.33082809999999995e+03,
              6.46415000000000000e+04 4.54241000000000058e+04 1.29286900000000014e+03,
              6.96415000000000000e+04 4.54241000000000058e+04 1.21983680000000004e+03,
              7.46415000000000000e+04 4.54241000000000058e+04 1.22844309999999996e+03,
              7.96415000000000000e+04 4.54241000000000058e+04 1.15584390000000008e+03,
              8.46415000000000000e+04 4.54241000000000058e+04 1.16963470000000007e+03,
              8.96415000000000000e+04 4.54241000000000058e+04 1.20595890000000009e+03,
              4.65915000000000000e+04 4.53741000000000058e+04 1.25945560000000000e+03,
              5.15915000000000000e+04 4.53741000000000058e+04 1.30069399999999996e+03,
              5.65915000000000000e+04 4.53741000000000058e+04 1.36288239999999996e+03,
              6.15915000000000000e+04 4.53741000000000058e+04 1.30564229999999998e+03,
              6.65915000000000000e+04 4.53741000000000058e+04 1.28486930000000007e+03,
              7.15915000000000000e+04 4.53741000000000058e+04 1.23702770000000010e+03,
              7.65915000000000000e+04 4.53741000000000058e+04 1.16733690000000001e+03,
              8.15915000000000000e+04 4.53741000000000058e+04 1.20986639999999989e+03,
              8.65915000000000000e+04 4.53741000000000058e+04 1.16875469999999996e+03,
              4.35415000000000000e+04 4.53241000000000058e+04 1.23748559999999998e+03,
              4.85415000000000000e+04 4.53241000000000058e+04 1.27891800000000012e+03,
              5.35415000000000000e+04 4.53241000000000058e+04 1.31937599999999998e+03,
              5.85415000000000000e+04 4.53241000000000058e+04 1.32041409999999996e+03,
              6.35415000000000000e+04 4.53241000000000058e+04 1.28821309999999994e+03,
              6.85415000000000000e+04 4.53241000000000058e+04 1.27649040000000014e+03,
              7.35415000000000000e+04 4.53241000000000058e+04 1.21400000000000000e+03,
              7.85415000000000000e+04 4.53241000000000058e+04 1.16100399999999991e+03,
              8.35415000000000000e+04 4.53241000000000058e+04 1.18958580000000006e+03,
              8.85415000000000000e+04 4.53241000000000058e+04 1.26005349999999999e+03,
              4.54915000000000000e+04 4.52741000000000058e+04 1.27137349999999992e+03,
              5.04915000000000000e+04 4.52741000000000058e+04 1.28333539999999994e+03,
              5.54915000000000000e+04 4.52741000000000058e+04 1.33523029999999994e+03,
              6.04915000000000000e+04 4.52741000000000058e+04 1.32091609999999991e+03,
              6.54915000000000000e+04 4.52741000000000058e+04 1.27125299999999993e+03,
              7.04915000000000000e+04 4.52741000000000058e+04 1.21166190000000006e+03,
              7.54915000000000000e+04 4.52741000000000058e+04 1.22092550000000006e+03,
              8.04915000000000000e+04 4.52741000000000058e+04 1.18323340000000007e+03,
              8.54915000000000000e+04 4.52741000000000058e+04 1.18141460000000006e+03,
              9.04915000000000000e+04 4.52741000000000058e+04 1.17390609999999992e+03,
              4.74415000000000000e+04 4.52241000000000058e+04 1.26316859999999997e+03,
              5.24415000000000000e+04 4.52241000000000058e+04 1.29496370000000002e+03,
              5.74415000000000000e+04 4.52241000000000058e+04 1.34519349999999986e+03,
              6.24415000000000000e+04 4.52241000000000058e+04 1.31086349999999993e+03,
              6.74415000000000000e+04 4.52241000000000058e+04 1.32697669999999994e+03,
              7.24415000000000000e+04 4.52241000000000058e+04 1.27514620000000014e+03,
              7.74415000000000000e+04 4.52241000000000058e+04 1.16167229999999995e+03,
              8.24415000000000000e+04 4.52241000000000058e+04 1.20561799999999994e+03,
              8.74415000000000000e+04 4.52241000000000058e+04 1.22148410000000013e+03,
              4.43915000000000000e+04 4.51741000000000058e+04 1.25277890000000002e+03,
              4.93915000000000000e+04 4.51741000000000058e+04 1.29665879999999993e+03,
              5.43915000000000000e+04 4.51741000000000058e+04 1.32107439999999997e+03,
              5.93915000000000000e+04 4.51741000000000058e+04 1.31537570000000005e+03,
              6.43915000000000000e+04 4.51741000000000058e+04 1.29184189999999990e+03,
              6.93915000000000000e+04 4.51741000000000058e+04 1.25767000000000007e+03,
              7.43915000000000000e+04 4.51741000000000058e+04 1.22115339999999992e+03,
              7.93915000000000000e+04 4.51741000000000058e+04 1.15772430000000008e+03,
              8.43915000000000000e+04 4.51741000000000058e+04 1.17033609999999999e+03,
              8.93915000000000000e+04 4.51741000000000058e+04 1.22085059999999999e+03,
              4.63415000000000000e+04 4.51241000000000058e+04 1.24966879999999992e+03,
              5.13415000000000000e+04 4.51241000000000058e+04 1.27889800000000014e+03,
              5.63415000000000000e+04 4.51241000000000058e+04 1.35641899999999987e+03,
              6.13415000000000000e+04 4.51241000000000058e+04 1.29552189999999996e+03,
              6.63415000000000000e+04 4.51241000000000058e+04 1.28315779999999995e+03,
              7.13415000000000000e+04 4.51241000000000058e+04 1.21190450000000010e+03,
              7.63415000000000000e+04 4.51241000000000058e+04 1.20984539999999993e+03,
              8.13415000000000000e+04 4.51241000000000058e+04 1.18765200000000004e+03,
              8.63415000000000000e+04 4.51241000000000058e+04 1.16633629999999994e+03,
              4.32915000000000000e+04 4.50741000000000058e+04 1.23305310000000009e+03,
              4.82915000000000000e+04 4.50741000000000058e+04 1.29428389999999990e+03,
              5.32915000000000000e+04 4.50741000000000058e+04 1.31324949999999990e+03,
              5.82915000000000000e+04 4.50741000000000058e+04 1.32511740000000009e+03,
              6.32915000000000000e+04 4.50741000000000058e+04 1.30313480000000004e+03,
              6.82915000000000000e+04 4.50741000000000058e+04 1.24715069999999992e+03,
              7.32915000000000000e+04 4.50741000000000058e+04 1.20920080000000007e+03,
              7.82915000000000000e+04 4.50741000000000058e+04 1.15817080000000010e+03,
              8.32915000000000000e+04 4.50741000000000058e+04 1.17482279999999992e+03,
              8.82915000000000000e+04 4.50741000000000058e+04 1.25000720000000001e+03,
              4.52415000000000000e+04 4.50241000000000058e+04 1.26806899999999996e+03,
              5.02415000000000000e+04 4.50241000000000058e+04 1.28565059999999994e+03,
              5.52415000000000000e+04 4.50241000000000058e+04 1.32951220000000012e+03,
              6.02415000000000000e+04 4.50241000000000058e+04 1.31725620000000004e+03,
              6.52415000000000000e+04 4.50241000000000058e+04 1.27071129999999994e+03,
              7.02415000000000000e+04 4.50241000000000058e+04 1.33189030000000002e+03,
              7.52415000000000000e+04 4.50241000000000058e+04 1.21938580000000002e+03,
              8.02415000000000000e+04 4.50241000000000058e+04 1.15312339999999995e+03,
              8.52415000000000000e+04 4.50241000000000058e+04 1.19860159999999996e+03,
              9.02415000000000000e+04 4.50241000000000058e+04 1.17390769999999998e+03,
              4.71915000000000000e+04 4.49741000000000058e+04 1.26378739999999993e+03,
              5.21915000000000000e+04 4.49741000000000058e+04 1.30263169999999991e+03,
              5.71915000000000000e+04 4.49741000000000058e+04 1.33921920000000000e+03,
              6.21915000000000000e+04 4.49741000000000058e+04 1.31433889999999997e+03,
              6.71915000000000000e+04 4.49741000000000058e+04 1.32951729999999998e+03,
              7.21915000000000000e+04 4.49741000000000058e+04 1.22389769999999999e+03,
              7.71915000000000000e+04 4.49741000000000058e+04 1.16315820000000008e+03,
              8.21915000000000000e+04 4.49741000000000058e+04 1.20862059999999997e+03,
              8.71915000000000000e+04 4.49741000000000058e+04 1.21683249999999998e+03,
              4.41415000000000000e+04 4.49241000000000058e+04 1.24553440000000001e+03,
              4.91415000000000000e+04 4.49241000000000058e+04 1.30786950000000002e+03,
              5.41415000000000000e+04 4.49241000000000058e+04 1.33453819999999996e+03,
              5.91415000000000000e+04 4.49241000000000058e+04 1.30766589999999997e+03,
              6.41415000000000000e+04 4.49241000000000058e+04 1.29799849999999992e+03,
              6.91415000000000000e+04 4.49241000000000058e+04 1.33638490000000002e+03,
              7.41415000000000000e+04 4.49241000000000058e+04 1.21437570000000005e+03,
              7.91415000000000000e+04 4.49241000000000058e+04 1.15650060000000008e+03,
              8.41415000000000000e+04 4.49241000000000058e+04 1.17949620000000004e+03,
              8.91415000000000000e+04 4.49241000000000058e+04 1.20955150000000003e+03,
              4.60915000000000000e+04 4.48741000000000058e+04 1.24449180000000001e+03,
              5.10915000000000000e+04 4.48741000000000058e+04 1.28401499999999987e+03,
              5.60915000000000000e+04 4.48741000000000058e+04 1.34891589999999997e+03,
              6.10915000000000000e+04 4.48741000000000058e+04 1.29542480000000000e+03,
              6.60915000000000000e+04 4.48741000000000058e+04 1.28990650000000005e+03,
              7.10915000000000000e+04 4.48741000000000058e+04 1.19891920000000005e+03,
              7.60915000000000000e+04 4.48741000000000058e+04 1.20018810000000008e+03,
              8.10915000000000000e+04 4.48741000000000058e+04 1.19898389999999995e+03,
              8.60915000000000000e+04 4.48741000000000058e+04 1.17805659999999989e+03,
              4.30415000000000000e+04 4.48241000000000058e+04 1.23648500000000013e+03,
              4.80415000000000000e+04 4.48241000000000058e+04 1.30057380000000012e+03,
              5.30415000000000000e+04 4.48241000000000058e+04 1.29191049999999996e+03,
              5.80415000000000000e+04 4.48241000000000058e+04 1.32093480000000000e+03,
              6.30415000000000000e+04 4.48241000000000058e+04 1.30329289999999992e+03,
              6.80415000000000000e+04 4.48241000000000058e+04 1.34750639999999999e+03,
              7.30415000000000000e+04 4.48241000000000058e+04 1.28158400000000006e+03,
              7.80415000000000000e+04 4.48241000000000058e+04 1.16142650000000003e+03,
              8.30415000000000000e+04 4.48241000000000058e+04 1.18131700000000001e+03,
              8.80415000000000000e+04 4.48241000000000058e+04 1.23662269999999990e+03,
              4.49915000000000000e+04 4.47741000000000058e+04 1.25970180000000005e+03,
              4.99915000000000000e+04 4.47741000000000058e+04 1.28658069999999998e+03,
              5.49915000000000000e+04 4.47741000000000058e+04 1.32708619999999996e+03,
              5.99915000000000000e+04 4.47741000000000058e+04 1.32012529999999992e+03,
              6.49915000000000000e+04 4.47741000000000058e+04 1.27629379999999992e+03,
              6.99915000000000000e+04 4.47741000000000058e+04 1.36436329999999998e+03,
              7.49915000000000000e+04 4.47741000000000058e+04 1.22687640000000010e+03,
              7.99915000000000000e+04 4.47741000000000058e+04 1.15198199999999997e+03,
              8.49915000000000000e+04 4.47741000000000058e+04 1.20018139999999994e+03,
              8.99915000000000000e+04 4.47741000000000058e+04 1.22924929999999995e+03,
              4.69415000000000000e+04 4.47241000000000058e+04 1.25684300000000007e+03,
              5.19415000000000000e+04 4.47241000000000058e+04 1.30103119999999990e+03,
              5.69415000000000000e+04 4.47241000000000058e+04 1.33925680000000011e+03,
              6.19415000000000000e+04 4.47241000000000058e+04 1.29743820000000005e+03,
              6.69415000000000000e+04 4.47241000000000058e+04 1.31803880000000004e+03,
              7.19415000000000000e+04 4.47241000000000058e+04 1.20748720000000003e+03,
              7.69415000000000000e+04 4.47241000000000058e+04 1.17016239999999993e+03,
              8.19415000000000000e+04 4.47241000000000058e+04 1.21065120000000002e+03,
              8.69415000000000000e+04 4.47241000000000058e+04 1.19891110000000003e+03,
              4.38915000000000000e+04 4.46741000000000058e+04 1.24123859999999991e+03,
              4.88915000000000000e+04 4.46741000000000058e+04 1.30094830000000002e+03,
              5.38915000000000000e+04 4.46741000000000058e+04 1.33157680000000005e+03,
              5.88915000000000000e+04 4.46741000000000058e+04 1.29482979999999998e+03,
              6.38915000000000000e+04 4.46741000000000058e+04 1.28505880000000002e+03,
              6.88915000000000000e+04 4.46741000000000058e+04 1.35818249999999989e+03,
              7.38915000000000000e+04 4.46741000000000058e+04 1.19778179999999998e+03,
              7.88915000000000000e+04 4.46741000000000058e+04 1.15666020000000003e+03,
              8.38915000000000000e+04 4.46741000000000058e+04 1.19507150000000001e+03,
              8.88915000000000000e+04 4.46741000000000058e+04 1.23603029999999990e+03,
              4.58415000000000000e+04 4.46241000000000058e+04 1.24190100000000007e+03,
              5.08415000000000000e+04 4.46241000000000058e+04 1.29546430000000009e+03,
              5.58415000000000000e+04 4.46241000000000058e+04 1.34403530000000001e+03,
              6.08415000000000000e+04 4.46241000000000058e+04 1.29195789999999988e+03,
              6.58415000000000000e+04 4.46241000000000058e+04 1.28431670000000008e+03,
              7.08415000000000000e+04 4.46241000000000058e+04 1.23328089999999997e+03,
              7.58415000000000000e+04 4.46241000000000058e+04 1.20549639999999999e+03,
              8.08415000000000000e+04 4.46241000000000058e+04 1.21852530000000002e+03,
              8.58415000000000000e+04 4.46241000000000058e+04 1.18729899999999998e+03,
              9.08415000000000000e+04 4.46241000000000058e+04 1.17143399999999997e+03,
              4.77915000000000000e+04 4.45741000000000058e+04 1.29257639999999992e+03,
              5.27915000000000000e+04 4.45741000000000058e+04 1.31143389999999999e+03,
              5.77915000000000000e+04 4.45741000000000058e+04 1.32632140000000004e+03,
              6.27915000000000000e+04 4.45741000000000058e+04 1.29484200000000010e+03,
              6.77915000000000000e+04 4.45741000000000058e+04 1.34846900000000005e+03,
              7.27915000000000000e+04 4.45741000000000058e+04 1.24884059999999999e+03,
              7.77915000000000000e+04 4.45741000000000058e+04 1.16135210000000006e+03,
              8.27915000000000000e+04 4.45741000000000058e+04 1.19536860000000001e+03,
              8.77915000000000000e+04 4.45741000000000058e+04 1.22890550000000007e+03,
              4.47415000000000000e+04 4.45241000000000058e+04 1.25425019999999995e+03,
              4.97415000000000000e+04 4.45241000000000058e+04 1.28984089999999992e+03,
              5.47415000000000000e+04 4.45241000000000058e+04 1.33475810000000001e+03,
              5.97415000000000000e+04 4.45241000000000058e+04 1.30908420000000001e+03,
              6.47415000000000000e+04 4.45241000000000058e+04 1.28113429999999994e+03,
              6.97415000000000000e+04 4.45241000000000058e+04 1.36070180000000005e+03,
              7.47415000000000000e+04 4.45241000000000058e+04 1.22000199999999995e+03,
              7.97415000000000000e+04 4.45241000000000058e+04 1.15560660000000007e+03,
              8.47415000000000000e+04 4.45241000000000058e+04 1.19818939999999998e+03,
              8.97415000000000000e+04 4.45241000000000058e+04 1.24299369999999999e+03,
              4.66915000000000000e+04 4.44741000000000058e+04 1.25377240000000006e+03,
              5.16915000000000000e+04 4.44741000000000058e+04 1.28567129999999997e+03,
              5.66915000000000000e+04 4.44741000000000058e+04 1.33590249999999992e+03,
              6.16915000000000000e+04 4.44741000000000058e+04 1.27977309999999989e+03,
              6.66915000000000000e+04 4.44741000000000058e+04 1.30395110000000000e+03,
              7.16915000000000000e+04 4.44741000000000058e+04 1.19714069999999992e+03,
              7.66915000000000000e+04 4.44741000000000058e+04 1.16892000000000007e+03,
              8.16915000000000000e+04 4.44741000000000058e+04 1.19977179999999998e+03,
              8.66915000000000000e+04 4.44741000000000058e+04 1.19004950000000008e+03,
              4.36415000000000000e+04 4.44241000000000058e+04 1.23713180000000011e+03,
              4.86415000000000000e+04 4.44241000000000058e+04 1.28207320000000004e+03,
              5.36415000000000000e+04 4.44241000000000058e+04 1.32166669999999999e+03,
              5.86415000000000000e+04 4.44241000000000058e+04 1.30020640000000003e+03,
              6.36415000000000000e+04 4.44241000000000058e+04 1.29784500000000003e+03,
              6.86415000000000000e+04 4.44241000000000058e+04 1.35679349999999999e+03,
              7.36415000000000000e+04 4.44241000000000058e+04 1.21303140000000008e+03,
              7.86415000000000000e+04 4.44241000000000058e+04 1.15781999999999994e+03,
              8.36415000000000000e+04 4.44241000000000058e+04 1.18368470000000002e+03,
              8.86415000000000000e+04 4.44241000000000058e+04 1.24374440000000004e+03,
              4.55915000000000000e+04 4.43741000000000058e+04 1.23930709999999999e+03,
              5.05915000000000000e+04 4.43741000000000058e+04 1.28849980000000005e+03,
              5.55915000000000000e+04 4.43741000000000058e+04 1.33785699999999997e+03,
              6.05915000000000000e+04 4.43741000000000058e+04 1.29749209999999994e+03,
              6.55915000000000000e+04 4.43741000000000058e+04 1.28701160000000004e+03,
              7.05915000000000000e+04 4.43741000000000058e+04 1.32946019999999999e+03,
              7.55915000000000000e+04 4.43741000000000058e+04 1.20775399999999991e+03,
              8.05915000000000000e+04 4.43741000000000058e+04 1.22030369999999994e+03,
              8.55915000000000000e+04 4.43741000000000058e+04 1.20391730000000007e+03,
              9.05915000000000000e+04 4.43741000000000058e+04 1.18697119999999995e+03,
              4.75415000000000000e+04 4.43241000000000058e+04 1.28086000000000013e+03,
              5.25415000000000000e+04 4.43241000000000058e+04 1.31958079999999995e+03,
              5.75415000000000000e+04 4.43241000000000058e+04 1.33427729999999997e+03,
              6.25415000000000000e+04 4.43241000000000058e+04 1.30814280000000008e+03,
              6.75415000000000000e+04 4.43241000000000058e+04 1.33399260000000004e+03,
              7.25415000000000000e+04 4.43241000000000058e+04 1.24062979999999993e+03,
              7.75415000000000000e+04 4.43241000000000058e+04 1.16261310000000003e+03,
              8.25415000000000000e+04 4.43241000000000058e+04 1.19941519999999991e+03,
              8.75415000000000000e+04 4.43241000000000058e+04 1.21987650000000008e+03,
              4.44915000000000000e+04 4.42741000000000058e+04 1.24577839999999992e+03,
              4.94915000000000000e+04 4.42741000000000058e+04 1.30681529999999998e+03,
              5.44915000000000000e+04 4.42741000000000058e+04 1.33878700000000003e+03,
              5.94915000000000000e+04 4.42741000000000058e+04 1.28829169999999999e+03,
              6.44915000000000000e+04 4.42741000000000058e+04 1.28330670000000009e+03,
              6.94915000000000000e+04 4.42741000000000058e+04 1.34687590000000000e+03,
              7.44915000000000000e+04 4.42741000000000058e+04 1.21151999999999998e+03,
              7.94915000000000000e+04 4.42741000000000058e+04 1.15837290000000007e+03,
              8.44915000000000000e+04 4.42741000000000058e+04 1.19015370000000007e+03,
              8.94915000000000000e+04 4.42741000000000058e+04 1.25134729999999990e+03,
              4.64415000000000000e+04 4.42241000000000058e+04 1.27030970000000002e+03,
              5.14415000000000000e+04 4.42241000000000058e+04 1.28008840000000009e+03,
              5.64415000000000000e+04 4.42241000000000058e+04 1.33041429999999991e+03,
              6.14415000000000000e+04 4.42241000000000058e+04 1.27322720000000004e+03,
              6.64415000000000000e+04 4.42241000000000058e+04 1.28612560000000008e+03,
              7.14415000000000000e+04 4.42241000000000058e+04 1.21359200000000010e+03,
              7.64415000000000000e+04 4.42241000000000058e+04 1.16913319999999999e+03,
              8.14415000000000000e+04 4.42241000000000058e+04 1.19981019999999990e+03,
              8.64415000000000000e+04 4.42241000000000058e+04 1.16872949999999992e+03,
              4.33915000000000000e+04 4.41741000000000058e+04 1.23760239999999999e+03,
              4.83915000000000000e+04 4.41741000000000058e+04 1.26605420000000004e+03,
              5.33915000000000000e+04 4.41741000000000058e+04 1.30099389999999994e+03,
              5.83915000000000000e+04 4.41741000000000058e+04 1.29996640000000002e+03,
              6.33915000000000000e+04 4.41741000000000058e+04 1.28929099999999994e+03,
              6.83915000000000000e+04 4.41741000000000058e+04 1.35572289999999998e+03,
              7.33915000000000000e+04 4.41741000000000058e+04 1.20366620000000012e+03,
              7.83915000000000000e+04 4.41741000000000058e+04 1.16055269999999996e+03,
              8.33915000000000000e+04 4.41741000000000058e+04 1.19484169999999995e+03,
              8.83915000000000000e+04 4.41741000000000058e+04 1.23676410000000010e+03,
              4.53415000000000000e+04 4.41241000000000058e+04 1.23413180000000011e+03,
              5.03415000000000000e+04 4.41241000000000058e+04 1.29739310000000000e+03,
              5.53415000000000000e+04 4.41241000000000058e+04 1.33826790000000005e+03,
              6.03415000000000000e+04 4.41241000000000058e+04 1.28289940000000001e+03,
              6.53415000000000000e+04 4.41241000000000058e+04 1.28481700000000001e+03,
              7.03415000000000000e+04 4.41241000000000058e+04 1.32415979999999990e+03,
              7.53415000000000000e+04 4.41241000000000058e+04 1.23842370000000005e+03,
              8.03415000000000000e+04 4.41241000000000058e+04 1.15353299999999990e+03,
              8.53415000000000000e+04 4.41241000000000058e+04 1.18990439999999990e+03,
              9.03415000000000000e+04 4.41241000000000058e+04 1.24281610000000001e+03,
              4.72915000000000000e+04 4.40741000000000058e+04 1.29360379999999986e+03,
              5.22915000000000000e+04 4.40741000000000058e+04 1.30184549999999990e+03,
              5.72915000000000000e+04 4.40741000000000058e+04 1.33694439999999986e+03,
              6.22915000000000000e+04 4.40741000000000058e+04 1.30081950000000006e+03,
              6.72915000000000000e+04 4.40741000000000058e+04 1.33097739999999999e+03,
              7.22915000000000000e+04 4.40741000000000058e+04 1.19309040000000005e+03,
              7.72915000000000000e+04 4.40741000000000058e+04 1.16583809999999994e+03,
              8.22915000000000000e+04 4.40741000000000058e+04 1.19641650000000004e+03,
              8.72915000000000000e+04 4.40741000000000058e+04 1.20756670000000008e+03,
              4.42415000000000000e+04 4.40241000000000058e+04 1.23140580000000000e+03,
              4.92415000000000000e+04 4.40241000000000058e+04 1.30112280000000010e+03,
              5.42415000000000000e+04 4.40241000000000058e+04 1.33252960000000007e+03,
              5.92415000000000000e+04 4.40241000000000058e+04 1.29935750000000007e+03,
              6.42415000000000000e+04 4.40241000000000058e+04 1.27644589999999994e+03,
              6.92415000000000000e+04 4.40241000000000058e+04 1.34666200000000003e+03,
              7.42415000000000000e+04 4.40241000000000058e+04 1.20150099999999998e+03,
              7.92415000000000000e+04 4.40241000000000058e+04 1.15627209999999991e+03,
              8.42415000000000000e+04 4.40241000000000058e+04 1.19556469999999990e+03,
              8.92415000000000000e+04 4.40241000000000058e+04 1.25083969999999999e+03,
              4.61915000000000000e+04 4.39741000000000058e+04 1.26923440000000005e+03,
              5.11915000000000000e+04 4.39741000000000058e+04 1.28546779999999990e+03,
              5.61915000000000000e+04 4.39741000000000058e+04 1.33542450000000008e+03,
              6.11915000000000000e+04 4.39741000000000058e+04 1.27411069999999995e+03,
              6.61915000000000000e+04 4.39741000000000058e+04 1.29747139999999990e+03,
              7.11915000000000000e+04 4.39741000000000058e+04 1.28172640000000001e+03,
              7.61915000000000000e+04 4.39741000000000058e+04 1.17135490000000004e+03,
              8.11915000000000000e+04 4.39741000000000058e+04 1.19923569999999995e+03,
              8.61915000000000000e+04 4.39741000000000058e+04 1.17311809999999991e+03,
              4.31415000000000000e+04 4.39241000000000058e+04 1.22769309999999996e+03,
              4.81415000000000000e+04 4.39241000000000058e+04 1.25988540000000012e+03,
              5.31415000000000000e+04 4.39241000000000058e+04 1.30940000000000009e+03,
              5.81415000000000000e+04 4.39241000000000058e+04 1.30392889999999989e+03,
              6.31415000000000000e+04 4.39241000000000058e+04 1.28957259999999997e+03,
              6.81415000000000000e+04 4.39241000000000058e+04 1.35531320000000005e+03,
              7.31415000000000000e+04 4.39241000000000058e+04 1.19192000000000007e+03,
              7.81415000000000000e+04 4.39241000000000058e+04 1.17465820000000008e+03,
              8.31415000000000000e+04 4.39241000000000058e+04 1.20339509999999996e+03,
              8.81415000000000000e+04 4.39241000000000058e+04 1.21750739999999996e+03,
              4.50915000000000000e+04 4.38741000000000058e+04 1.23937439999999992e+03,
              5.00915000000000000e+04 4.38741000000000058e+04 1.30495309999999995e+03,
              5.50915000000000000e+04 4.38741000000000058e+04 1.32139310000000000e+03,
              6.00915000000000000e+04 4.38741000000000058e+04 1.28816239999999993e+03,
              6.50915000000000000e+04 4.38741000000000058e+04 1.28024710000000005e+03,
              7.00915000000000000e+04 4.38741000000000058e+04 1.29226160000000004e+03,
              7.50915000000000000e+04 4.38741000000000058e+04 1.21800180000000000e+03,
              8.00915000000000000e+04 4.38741000000000058e+04 1.15412779999999998e+03,
              8.50915000000000000e+04 4.38741000000000058e+04 1.18937560000000008e+03,
              9.00915000000000000e+04 4.38741000000000058e+04 1.25071010000000001e+03,
              4.70415000000000000e+04 4.38241000000000058e+04 1.27784230000000002e+03,
              5.20415000000000000e+04 4.38241000000000058e+04 1.29325129999999990e+03,
              5.70415000000000000e+04 4.38241000000000058e+04 1.32112509999999997e+03,
              6.20415000000000000e+04 4.38241000000000058e+04 1.30950909999999999e+03,
              6.70415000000000000e+04 4.38241000000000058e+04 1.32583719999999994e+03,
              7.20415000000000000e+04 4.38241000000000058e+04 1.22713270000000011e+03,
              7.70415000000000000e+04 4.38241000000000058e+04 1.16767169999999987e+03,
              8.20415000000000000e+04 4.38241000000000058e+04 1.19463319999999999e+03,
              8.70415000000000000e+04 4.38241000000000058e+04 1.19429040000000009e+03,
              4.39915000000000000e+04 4.37741000000000058e+04 1.23035120000000006e+03,
              4.89915000000000000e+04 4.37741000000000058e+04 1.27969599999999991e+03,
              5.39915000000000000e+04 4.37741000000000058e+04 1.32490730000000008e+03,
              5.89915000000000000e+04 4.37741000000000058e+04 1.32877549999999997e+03,
              6.39915000000000000e+04 4.37741000000000058e+04 1.28623090000000002e+03,
              6.89915000000000000e+04 4.37741000000000058e+04 1.35127909999999997e+03,
              7.39915000000000000e+04 4.37741000000000058e+04 1.19140000000000009e+03,
              7.89915000000000000e+04 4.37741000000000058e+04 1.15668489999999997e+03,
              8.39915000000000000e+04 4.37741000000000058e+04 1.20928250000000003e+03,
              8.89915000000000000e+04 4.37741000000000058e+04 1.24270740000000001e+03,
              4.59415000000000000e+04 4.37241000000000058e+04 1.25922839999999997e+03,
              5.09415000000000000e+04 4.37241000000000058e+04 1.29421839999999997e+03,
              5.59415000000000000e+04 4.37241000000000058e+04 1.33698119999999994e+03,
              6.09415000000000000e+04 4.37241000000000058e+04 1.26961750000000006e+03,
              6.59415000000000000e+04 4.37241000000000058e+04 1.29625279999999998e+03,
              7.09415000000000000e+04 4.37241000000000058e+04 1.25065910000000008e+03,
              7.59415000000000000e+04 4.37241000000000058e+04 1.16676220000000012e+03,
              8.09415000000000000e+04 4.37241000000000058e+04 1.19623679999999990e+03,
              8.59415000000000000e+04 4.37241000000000058e+04 1.18091059999999993e+03,
              9.09415000000000000e+04 4.37241000000000058e+04 1.20619100000000003e+03,
              4.78915000000000000e+04 4.36741000000000058e+04 1.25667820000000006e+03,
              5.28915000000000000e+04 4.36741000000000058e+04 1.31433559999999989e+03,
              5.78915000000000000e+04 4.36741000000000058e+04 1.32854620000000000e+03,
              6.28915000000000000e+04 4.36741000000000058e+04 1.29784909999999991e+03,
              6.78915000000000000e+04 4.36741000000000058e+04 1.34606449999999995e+03,
              7.28915000000000000e+04 4.36741000000000058e+04 1.19266599999999994e+03,
              7.78915000000000000e+04 4.36741000000000058e+04 1.16145910000000003e+03,
              8.28915000000000000e+04 4.36741000000000058e+04 1.19503620000000001e+03,
              8.78915000000000000e+04 4.36741000000000058e+04 1.20804289999999992e+03,
              4.48415000000000000e+04 4.36241000000000058e+04 1.23737640000000010e+03,
              4.98415000000000000e+04 4.36241000000000058e+04 1.29381880000000001e+03,
              5.48415000000000000e+04 4.36241000000000058e+04 1.32129539999999997e+03,
              5.98415000000000000e+04 4.36241000000000058e+04 1.29027680000000009e+03,
              6.48415000000000000e+04 4.36241000000000058e+04 1.27226379999999995e+03,
              6.98415000000000000e+04 4.36241000000000058e+04 1.27687899999999991e+03,
              7.48415000000000000e+04 4.36241000000000058e+04 1.19009870000000001e+03,
              7.98415000000000000e+04 4.36241000000000058e+04 1.15255140000000006e+03,
              8.48415000000000000e+04 4.36241000000000058e+04 1.18918020000000001e+03,
              8.98415000000000000e+04 4.36241000000000058e+04 1.24720339999999987e+03,
              4.67915000000000000e+04 4.35741000000000058e+04 1.25661989999999992e+03,
              5.17915000000000000e+04 4.35741000000000058e+04 1.28602330000000006e+03,
              5.67915000000000000e+04 4.35741000000000058e+04 1.32481459999999993e+03,
              6.17915000000000000e+04 4.35741000000000058e+04 1.28306240000000003e+03,
              6.67915000000000000e+04 4.35741000000000058e+04 1.29447299999999996e+03,
              7.17915000000000000e+04 4.35741000000000058e+04 1.21520650000000001e+03,
              7.67915000000000000e+04 4.35741000000000058e+04 1.17123589999999990e+03,
              8.17915000000000000e+04 4.35741000000000058e+04 1.19399450000000002e+03,
              8.67915000000000000e+04 4.35741000000000058e+04 1.17615049999999997e+03,
              "
            />
            <Color
              DEF="VTKcolors0001"
              color="
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              8.90196078431372495e-01 8.58823529411764652e-01 8.50980392156862719e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              8.98039215686274539e-01 8.70588235294117663e-01 8.62745098039215730e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.74509803921568629e-01 8.39215686274509820e-01 8.31372549019607887e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.98039215686274539e-01 8.70588235294117663e-01 8.62745098039215730e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.72549019607843168e-01 7.09803921568627483e-01 6.94117647058823506e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.43137254901960786e-01 8.00000000000000044e-01 7.88235294117647034e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.19607843137254877e-01 7.68627450980392202e-01 7.56862745098039191e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.35294117647058854e-01 7.88235294117647034e-01 7.80392156862745101e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              7.49019607843137258e-01 6.78431372549019640e-01 6.62745098039215663e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.72549019607843168e-01 7.09803921568627483e-01 6.94117647058823506e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              7.88235294117647034e-01 7.29411764705882315e-01 7.17647058823529416e-01,
              8.19607843137254877e-01 7.68627450980392202e-01 7.56862745098039191e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              8.43137254901960786e-01 8.00000000000000044e-01 7.88235294117647034e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.88235294117647034e-01 7.29411764705882315e-01 7.17647058823529416e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              8.66666666666666696e-01 8.27450980392156810e-01 8.19607843137254877e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.35294117647058854e-01 7.88235294117647034e-01 7.80392156862745101e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              8.58823529411764652e-01 8.19607843137254877e-01 8.11764705882352944e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.88235294117647034e-01 7.29411764705882315e-01 7.17647058823529416e-01,
              8.27450980392156810e-01 7.80392156862745101e-01 7.68627450980392202e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              8.35294117647058854e-01 7.88235294117647034e-01 7.80392156862745101e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.72549019607843168e-01 7.09803921568627483e-01 6.94117647058823506e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              7.88235294117647034e-01 7.29411764705882315e-01 7.17647058823529416e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              9.60784313725490224e-01 9.49019607843137214e-01 9.49019607843137214e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              7.88235294117647034e-01 7.29411764705882315e-01 7.17647058823529416e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              7.72549019607843168e-01 7.09803921568627483e-01 6.94117647058823506e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.49019607843137258e-01 6.78431372549019640e-01 6.62745098039215663e-01,
              9.52941176470588180e-01 9.41176470588235281e-01 9.37254901960784315e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              8.35294117647058854e-01 7.88235294117647034e-01 7.80392156862745101e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              8.19607843137254877e-01 7.68627450980392202e-01 7.56862745098039191e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.03921568627451011e-01 7.49019607843137258e-01 7.37254901960784359e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.72549019607843168e-01 7.09803921568627483e-01 6.94117647058823506e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.49019607843137258e-01 6.78431372549019640e-01 6.62745098039215663e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.11764705882352944e-01 7.60784313725490158e-01 7.49019607843137258e-01,
              6.47058823529411797e-01 5.49019607843137303e-01 5.25490196078431393e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.17647058823529416e-01 6.39215686274509753e-01 6.23529411764705888e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.01960784313725439e-01 6.19607843137254921e-01 5.99999999999999978e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.96078431372548967e-01 7.37254901960784359e-01 7.25490196078431349e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.25490196078431349e-01 6.47058823529411797e-01 6.31372549019607820e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.99999999999999978e-01 4.86274509803921562e-01 4.62745098039215708e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              6.54901960784313730e-01 5.56862745098039236e-01 5.37254901960784292e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              7.49019607843137258e-01 6.78431372549019640e-01 6.62745098039215663e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.56862745098039191e-01 6.90196078431372539e-01 6.74509803921568674e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.78431372549019640e-01 5.88235294117647078e-01 5.68627450980392135e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.84313725490196112e-01 4.66666666666666674e-01 4.43137254901960764e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.70588235294117596e-01 5.76470588235294068e-01 5.56862745098039236e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.86274509803921573e-01 5.99999999999999978e-01 5.80392156862745145e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.33333333333333282e-01 6.58823529411764697e-01 6.43137254901960831e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.94117647058823506e-01 6.07843137254901911e-01 5.88235294117647078e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.68627450980392135e-01 4.47058823529411786e-01 4.23529411764705876e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.41176470588235325e-01 6.70588235294117596e-01 6.54901960784313730e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.07843137254901911e-01 4.98039215686274517e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.31372549019607820e-01 5.29411764705882359e-01 5.05882352941176450e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.39215686274509753e-01 5.37254901960784292e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.64705882352941169e-01 4.43137254901960764e-01 3.64705882352941158e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.92156862745098045e-01 4.78431372549019629e-01 4.54901960784313719e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.64705882352941124e-01 6.98039215686274472e-01 6.86274509803921573e-01,
              7.05882352941176461e-02 4.62745098039215708e-01 8.62745098039215730e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.80392156862745101e-01 7.17647058823529416e-01 7.05882352941176516e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.62745098039215663e-01 5.68627450980392135e-01 5.49019607843137303e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              2.35294117647058820e-02 5.56862745098039236e-01 9.56862745098039258e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.23529411764705888e-01 5.17647058823529460e-01 4.94117647058823550e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.45098039215686225e-01 4.19607843137254910e-01 3.92156862745098034e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              7.09803921568627483e-01 6.27450980392156854e-01 6.11764705882352988e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              5.56862745098039236e-01 4.31372549019607865e-01 3.60784313725490191e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.15686274509803955e-01 5.09803921568627416e-01 4.86274509803921562e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              1.84313725490196090e-01 2.31372549019607854e-01 6.31372549019607820e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.37254901960784292e-01 4.07843137254901955e-01 3.80392156862745079e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              5.33333333333333326e-01 4.03921568627450989e-01 3.49019607843137236e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              5.17647058823529460e-01 3.84313725490196101e-01 3.41176470588235303e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.01960784313725483e-01 3.60784313725490191e-01 3.29411764705882348e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              5.76470588235294068e-01 4.58823529411764686e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              1.88235294117647056e-01 2.19607843137254899e-01 6.19607843137254921e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 7.41176470588235325e-01 5.84313725490196112e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.29411764705882359e-01 3.96078431372549000e-01 3.68627450980392180e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.60784313725490202e-01 4.39215686274509798e-01 4.11764705882352922e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.21568627450980427e-01 3.88235294117647067e-01 3.60784313725490191e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.52941176470588269e-01 4.27450980392156843e-01 4.00000000000000022e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              6.03921568627450944e-01 4.94117647058823550e-01 3.84313725490196101e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              2.00000000000000011e-01 2.00000000000000011e-01 5.99999999999999978e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              5.13725490196078383e-01 3.76470588235294112e-01 3.49019607843137236e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              1.96078431372549017e-01 2.11764705882352938e-01 6.11764705882352988e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              3.92156862745098034e-02 5.25490196078431393e-01 9.25490196078431415e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              5.09803921568627416e-01 3.72549019607843146e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.25490196078431393e-01 3.92156862745098034e-01 3.45098039215686270e-01,
              3.13725490196078427e-02 5.33333333333333326e-01 9.33333333333333348e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.80392156862745145e-01 4.62745098039215708e-01 3.72549019607843146e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              5.72549019607843102e-01 4.50980392156862753e-01 3.68627450980392180e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              0.00000000000000000e+00 6.50980392156862764e-01 8.43137254901960786e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.19607843137254921e-01 5.13725490196078383e-01 3.96078431372549000e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.41176470588235259e-01 4.11764705882352922e-01 3.52941176470588258e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              5.88235294117647078e-01 4.74509803921568607e-01 3.76470588235294112e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              9.13725490196078405e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.66666666666666630e-01 5.72549019607843102e-01 4.19607843137254910e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              5.05882352941176450e-01 3.68627450980392180e-01 3.37254901960784337e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              1.80392156862745096e-01 2.43137254901960781e-01 6.43137254901960831e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              4.70588235294117641e-02 5.01960784313725483e-01 9.01960784313725505e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.27450980392156854e-01 5.21568627450980427e-01 4.00000000000000022e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.98039215686274472e-01 6.11764705882352988e-01 4.35294117647058831e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              5.49019607843137303e-01 4.23529411764705876e-01 3.56862745098039225e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.50980392156862764e-01 5.52941176470588269e-01 4.11764705882352922e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.11764705882352988e-01 5.01960784313725483e-01 3.92156862745098034e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              5.96078431372549011e-01 4.82352941176470595e-01 3.80392156862745079e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              0.00000000000000000e+00 6.70588235294117596e-01 7.96078431372548967e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              8.94117647058823573e-01 8.62745098039215730e-01 5.41176470588235259e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.05882352941176516e-01 6.23529411764705888e-01 4.43137254901960764e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.84313725490196068e-01 7.25490196078431349e-01 4.82352941176470595e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              0.00000000000000000e+00 7.96078431372548967e-01 4.19607843137254910e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              1.41176470588235292e-01 3.13725490196078427e-01 7.13725490196078449e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.19607843137254877e-01 9.64705882352941191e-01 5.64705882352941169e-01,
              7.76470588235294135e-01 7.13725490196078449e-01 4.78431372549019629e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              9.56862745098039258e-01 9.45098039215686248e-01 5.76470588235294068e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.21568627450980382e-01 6.43137254901960831e-01 4.50980392156862753e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              4.11764705882352922e-01 8.82352941176470562e-01 4.82352941176470595e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              3.92156862745098034e-03 8.00000000000000044e-01 4.00000000000000022e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              0.00000000000000000e+00 7.80392156862745101e-01 4.66666666666666674e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              4.90196078431372528e-01 8.98039215686274539e-01 4.98039215686274517e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              4.58823529411764686e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              9.33333333333333348e-01 9.13725490196078405e-01 5.64705882352941169e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              5.21568627450980427e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              1.21568627450980390e-01 3.56862745098039225e-01 7.56862745098039191e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              8.23529411764705871e-02 8.15686274509803910e-01 4.15686274509803944e-01,
              4.43137254901960764e-01 8.90196078431372495e-01 4.90196078431372528e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.68627450980392202e-01 7.01960784313725439e-01 4.74509803921568607e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              6.43137254901960831e-01 5.45098039215686225e-01 4.07843137254901955e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              9.17647058823529371e-01 8.94117647058823573e-01 5.56862745098039236e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.52941176470588225e-01 6.82352941176470607e-01 4.66666666666666674e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              6.15686274509803955e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              3.33333333333333315e-01 8.66666666666666696e-01 4.66666666666666674e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.41176470588235281e-01 9.25490196078431415e-01 5.68627450980392135e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              0.00000000000000000e+00 7.72549019607843168e-01 4.90196078431372528e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              6.62745098039215663e-01 9.33333333333333348e-01 5.33333333333333326e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              0.00000000000000000e+00 7.13725490196078449e-01 6.54901960784313730e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              3.01960784313725472e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              5.68627450980392135e-01 9.13725490196078405e-01 5.13725490196078383e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              4.74509803921568607e-01 8.94117647058823573e-01 4.94117647058823550e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 7.88235294117647034e-01 4.43137254901960764e-01,
              1.25490196078431371e-01 3.45098039215686270e-01 7.45098039215686292e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.88235294117647101e-01 9.84313725490196023e-01 5.92156862745098045e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              5.88235294117647051e-02 4.82352941176470595e-01 8.82352941176470562e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              2.39215686274509814e-01 8.47058823529411753e-01 4.47058823529411786e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              6.27450980392156854e-02 4.70588235294117641e-01 8.70588235294117663e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.37254901960784359e-01 6.62745098039215663e-01 4.58823529411764686e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.62745098039215730e-01 8.23529411764705843e-01 5.25490196078431393e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              6.35294117647058787e-01 5.33333333333333326e-01 4.03921568627450989e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              3.96078431372549000e-01 8.78431372549019596e-01 4.78431372549019629e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              9.25490196078431415e-01 9.05882352941176472e-01 5.60784313725490202e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              6.74509803921568674e-01 5.84313725490196112e-01 4.23529411764705876e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.29411764705882365e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.60784313725490158e-01 6.94117647058823506e-01 4.70588235294117641e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              9.80392156862745084e-02 8.19607843137254877e-01 4.19607843137254910e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              9.01960784313725478e-02 4.19607843137254910e-01 8.19607843137254877e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.64705882352941191e-01 9.52941176470588180e-01 5.80392156862745145e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              7.29411764705882315e-01 6.54901960784313730e-01 4.54901960784313719e-01,
              2.86274509803921551e-01 8.58823529411764652e-01 4.58823529411764686e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              3.52941176470588230e-02 8.07843137254901977e-01 4.07843137254901955e-01,
              3.64705882352941158e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              6.58823529411764697e-01 5.64705882352941169e-01 4.15686274509803944e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              0.00000000000000000e+00 6.31372549019607820e-01 9.13725490196078405e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              3.49019607843137236e-01 8.70588235294117663e-01 4.70588235294117641e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              0.00000000000000000e+00 6.19607843137254921e-01 9.37254901960784315e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.82352941176470607e-01 5.92156862745098045e-01 4.27450980392156843e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              4.31372549019607837e-02 5.13725490196078383e-01 9.13725490196078405e-01,
              2.23529411764705893e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.72549019607843168e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              2.74509803921568624e-02 5.45098039215686225e-01 9.45098039215686248e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.09803921568627438e-01 8.86274509803921529e-01 5.52941176470588269e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              9.80392156862745057e-01 9.76470588235294090e-01 5.88235294117647078e-01,
              8.23529411764705843e-01 7.72549019607843168e-01 5.05882352941176450e-01,
              1.92156862745098050e-01 8.39215686274509820e-01 4.39215686274509798e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              0.00000000000000000e+00 7.64705882352941124e-01 5.13725490196078383e-01,
              2.54901960784313708e-01 8.50980392156862719e-01 4.50980392156862753e-01,
              9.96078431372549034e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              0.00000000000000000e+00 7.09803921568627483e-01 6.78431372549019640e-01,
              1.49019607843137253e-01 3.05882352941176494e-01 7.05882352941176516e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.47058823529411753e-01 8.03921568627451011e-01 5.17647058823529460e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              0.00000000000000000e+00 7.25490196078431349e-01 6.31372549019607820e-01,
              1.01960784313725489e-01 4.00000000000000022e-01 8.00000000000000044e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              5.05882352941176450e-01 9.01960784313725505e-01 5.01960784313725483e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              1.37254901960784326e-01 3.25490196078431382e-01 7.25490196078431349e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              1.13725490196078430e-01 8.23529411764705843e-01 4.23529411764705876e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.92156862745098000e-01 7.33333333333333282e-01 4.86274509803921562e-01,
              7.09803921568627483e-01 9.41176470588235281e-01 5.41176470588235259e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.86274509803921529e-01 8.54901960784313686e-01 5.37254901960784292e-01,
              1.60784313725490208e-01 8.31372549019607887e-01 4.31372549019607865e-01,
              1.68627450980392168e-01 2.62745098039215697e-01 6.62745098039215663e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.60784313725490224e-01 9.92156862745098067e-01 5.92156862745098045e-01,
              8.07843137254901977e-01 7.52941176470588225e-01 4.98039215686274517e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.39215686274509820e-01 7.96078431372548967e-01 5.13725490196078383e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              1.17647058823529410e-01 3.68627450980392180e-01 7.68627450980392202e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              0.00000000000000000e+00 6.86274509803921573e-01 7.49019607843137258e-01,
              6.66666666666666657e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              9.45098039215686248e-01 9.88235294117647101e-01 5.88235294117647078e-01,
              8.31372549019607887e-01 7.84313725490196068e-01 5.09803921568627416e-01,
              9.29411764705882382e-01 9.84313725490196023e-01 5.84313725490196112e-01,
              6.31372549019607820e-01 9.25490196078431415e-01 5.25490196078431393e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              0.00000000000000000e+00 6.47058823529411797e-01 8.66666666666666696e-01,
              1.56862745098039214e-01 2.82352941176470584e-01 6.82352941176470607e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              5.37254901960784292e-01 9.05882352941176472e-01 5.05882352941176450e-01,
              7.41176470588235325e-01 9.49019607843137214e-01 5.49019607843137303e-01,
              8.15686274509803910e-01 7.64705882352941124e-01 5.01960784313725483e-01,
              5.99999999999999978e-01 9.21568627450980338e-01 5.21568627450980427e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              6.94117647058823506e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              0.00000000000000000e+00 6.39215686274509753e-01 8.90196078431372495e-01,
              8.62745098039215674e-02 4.31372549019607865e-01 8.31372549019607887e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              9.76470588235294090e-01 9.96078431372549034e-01 5.96078431372549011e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              6.90196078431372539e-01 6.03921568627450944e-01 4.31372549019607865e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              7.84313725490196068e-02 4.39215686274509798e-01 8.39215686274509820e-01,
              0.00000000000000000e+00 6.62745098039215663e-01 8.19607843137254877e-01,
              0.00000000000000000e+00 7.49019607843137258e-01 5.60784313725490202e-01,
              1.76470588235294129e-01 8.35294117647058854e-01 4.35294117647058831e-01,
              9.92156862745098067e-01 1.00000000000000000e+00 5.99999999999999978e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              7.88235294117647034e-01 9.56862745098039258e-01 5.56862745098039236e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.35294117647058854e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              0.00000000000000000e+00 7.56862745098039191e-01 5.37254901960784292e-01,
              1.64705882352941174e-01 2.74509803921568651e-01 6.74509803921568674e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              9.72549019607843124e-01 9.64705882352941191e-01 5.84313725490196112e-01,
              8.70588235294117663e-01 8.35294117647058854e-01 5.29411764705882359e-01,
              1.96078431372549017e-02 8.03921568627451011e-01 4.03921568627450989e-01,
              1.05882352941176469e-01 3.88235294117647067e-01 7.88235294117647034e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              5.09803921568627444e-02 8.11764705882352944e-01 4.11764705882352922e-01,
              6.78431372549019640e-01 9.37254901960784315e-01 5.37254901960784292e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              7.13725490196078449e-01 6.35294117647058787e-01 4.47058823529411786e-01,
              1.17647058823529410e-02 5.76470588235294068e-01 9.76470588235294090e-01,
              1.52941176470588247e-01 2.94117647058823539e-01 6.94117647058823506e-01,
              0.00000000000000000e+00 7.01960784313725439e-01 7.01960784313725439e-01,
              2.07843137254901972e-01 8.43137254901960786e-01 4.43137254901960764e-01,
              4.27450980392156843e-01 8.86274509803921529e-01 4.86274509803921562e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              8.00000000000000044e-01 7.45098039215686292e-01 4.90196078431372528e-01,
              5.52941176470588269e-01 9.09803921568627438e-01 5.09803921568627416e-01,
              8.82352941176470562e-01 9.76470588235294090e-01 5.76470588235294068e-01,
              3.17647058823529393e-01 8.62745098039215730e-01 4.62745098039215708e-01,
              1.09803921568627449e-01 3.76470588235294112e-01 7.76470588235294135e-01,
              0.00000000000000000e+00 6.11764705882352988e-01 9.60784313725490224e-01,
              5.49019607843137247e-02 4.94117647058823550e-01 8.94117647058823573e-01,
              0.00000000000000000e+00 6.78431372549019640e-01 7.72549019607843168e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              9.49019607843137214e-01 9.33333333333333348e-01 5.72549019607843102e-01,
              8.54901960784313686e-01 8.15686274509803910e-01 5.21568627450980427e-01,
              8.98039215686274539e-01 9.80392156862745057e-01 5.80392156862745145e-01,
              7.45098039215686292e-01 6.74509803921568674e-01 4.62745098039215708e-01,
              7.84313725490196068e-03 5.88235294117647078e-01 9.88235294117647101e-01,
              1.33333333333333331e-01 3.37254901960784337e-01 7.37254901960784359e-01,
              0.00000000000000000e+00 6.07843137254901911e-01 9.84313725490196023e-01,
              0.00000000000000000e+00 6.94117647058823506e-01 7.25490196078431349e-01,
              1.45098039215686286e-01 8.27450980392156810e-01 4.27450980392156843e-01,
              8.50980392156862719e-01 9.68627450980392157e-01 5.68627450980392135e-01,
              9.01960784313725505e-01 8.74509803921568629e-01 5.45098039215686225e-01,
              8.03921568627451011e-01 9.60784313725490224e-01 5.60784313725490202e-01,
              5.84313725490196112e-01 9.17647058823529371e-01 5.17647058823529460e-01,
              6.47058823529411797e-01 9.29411764705882382e-01 5.29411764705882359e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              1.72549019607843135e-01 2.50980392156862742e-01 6.50980392156862764e-01,
              1.56862745098039214e-02 5.64705882352941169e-01 9.64705882352941191e-01,
              2.70588235294117629e-01 8.54901960784313686e-01 4.54901960784313719e-01,
              3.80392156862745079e-01 8.74509803921568629e-01 4.74509803921568607e-01,
              7.56862745098039191e-01 9.52941176470588180e-01 5.52941176470588269e-01,
              8.78431372549019596e-01 8.43137254901960786e-01 5.33333333333333326e-01,
              7.25490196078431349e-01 9.45098039215686248e-01 5.45098039215686225e-01,
              8.66666666666666696e-01 9.72549019607843124e-01 5.72549019607843102e-01,
              0.00000000000000000e+00 7.33333333333333282e-01 6.07843137254901911e-01,
              9.41176470588235281e-02 4.07843137254901955e-01 8.07843137254901977e-01,
              0.00000000000000000e+00 5.96078431372549011e-01 9.96078431372549034e-01,
              7.45098039215686264e-02 4.50980392156862753e-01 8.50980392156862719e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+01"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="6.78431372549019640e-01 5.13725490196078383e-01 1.96078431372549017e-02"
              specularColor="3.39215686274509862e-02 2.56862745098039198e-02 9.80392156862745084e-04"
              shininess="3.90625000000000000e-02"
              transparency="2.00000002980232239e-01"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            0 1446 1447 1 -1 
            2 1448 1447 1 -1 
            0 1446 1448 2 -1 
            0 1446 1448 2 -1 
            0 1 2 -1 
            1446 1447 1448 -1 
            3 1449 1450 4 -1 
            5 1451 1450 4 -1 
            3 1449 1451 5 -1 
            3 1449 1451 5 -1 
            3 4 5 -1 
            1449 1450 1451 -1 
            6 1452 1453 7 -1 
            8 1454 1453 7 -1 
            6 1452 1454 8 -1 
            6 1452 1454 8 -1 
            6 7 8 -1 
            1452 1453 1454 -1 
            9 1455 1456 10 -1 
            11 1457 1456 10 -1 
            9 1455 1457 11 -1 
            9 1455 1457 11 -1 
            9 10 11 -1 
            1455 1456 1457 -1 
            12 1458 1459 13 -1 
            14 1460 1459 13 -1 
            12 1458 1460 14 -1 
            12 1458 1460 14 -1 
            12 13 14 -1 
            1458 1459 1460 -1 
            15 1461 1462 16 -1 
            17 1463 1462 16 -1 
            15 1461 1463 17 -1 
            15 1461 1463 17 -1 
            15 16 17 -1 
            1461 1462 1463 -1 
            18 1464 1465 19 -1 
            20 1466 1465 19 -1 
            18 1464 1466 20 -1 
            18 1464 1466 20 -1 
            18 19 20 -1 
            1464 1465 1466 -1 
            21 1467 1468 22 -1 
            23 1469 1468 22 -1 
            21 1467 1469 23 -1 
            21 1467 1469 23 -1 
            21 22 23 -1 
            1467 1468 1469 -1 
            24 1470 1471 25 -1 
            26 1472 1471 25 -1 
            24 1470 1472 26 -1 
            24 1470 1472 26 -1 
            24 25 26 -1 
            1470 1471 1472 -1 
            27 1473 1474 28 -1 
            29 1475 1474 28 -1 
            27 1473 1475 29 -1 
            27 1473 1475 29 -1 
            27 28 29 -1 
            1473 1474 1475 -1 
            30 1476 1477 31 -1 
            32 1478 1477 31 -1 
            30 1476 1478 32 -1 
            30 1476 1478 32 -1 
            30 31 32 -1 
            1476 1477 1478 -1 
            33 1479 1480 34 -1 
            35 1481 1480 34 -1 
            33 1479 1481 35 -1 
            33 1479 1481 35 -1 
            33 34 35 -1 
            1479 1480 1481 -1 
            36 1482 1483 37 -1 
            38 1484 1483 37 -1 
            36 1482 1484 38 -1 
            36 1482 1484 38 -1 
            36 37 38 -1 
            1482 1483 1484 -1 
            39 1485 1486 40 -1 
            41 1487 1486 40 -1 
            39 1485 1487 41 -1 
            39 1485 1487 41 -1 
            39 40 41 -1 
            1485 1486 1487 -1 
            42 1488 1489 43 -1 
            44 1490 1489 43 -1 
            42 1488 1490 44 -1 
            42 1488 1490 44 -1 
            42 43 44 -1 
            1488 1489 1490 -1 
            45 1491 1492 46 -1 
            47 1493 1492 46 -1 
            45 1491 1493 47 -1 
            45 1491 1493 47 -1 
            45 46 47 -1 
            1491 1492 1493 -1 
            48 1494 1495 49 -1 
            50 1496 1495 49 -1 
            48 1494 1496 50 -1 
            48 1494 1496 50 -1 
            48 49 50 -1 
            1494 1495 1496 -1 
            51 1497 1498 52 -1 
            53 1499 1498 52 -1 
            51 1497 1499 53 -1 
            51 1497 1499 53 -1 
            51 52 53 -1 
            1497 1498 1499 -1 
            54 1500 1501 55 -1 
            56 1502 1501 55 -1 
            54 1500 1502 56 -1 
            54 1500 1502 56 -1 
            54 55 56 -1 
            1500 1501 1502 -1 
            57 1503 1504 58 -1 
            59 1505 1504 58 -1 
            57 1503 1505 59 -1 
            57 1503 1505 59 -1 
            57 58 59 -1 
            1503 1504 1505 -1 
            60 1506 1507 61 -1 
            62 1508 1507 61 -1 
            60 1506 1508 62 -1 
            60 1506 1508 62 -1 
            60 61 62 -1 
            1506 1507 1508 -1 
            63 1509 1510 64 -1 
            65 1511 1510 64 -1 
            63 1509 1511 65 -1 
            63 1509 1511 65 -1 
            63 64 65 -1 
            1509 1510 1511 -1 
            66 1512 1513 67 -1 
            68 1514 1513 67 -1 
            66 1512 1514 68 -1 
            66 1512 1514 68 -1 
            66 67 68 -1 
            1512 1513 1514 -1 
            69 1515 1516 70 -1 
            71 1517 1516 70 -1 
            69 1515 1517 71 -1 
            69 1515 1517 71 -1 
            69 70 71 -1 
            1515 1516 1517 -1 
            72 1518 1519 73 -1 
            74 1520 1519 73 -1 
            72 1518 1520 74 -1 
            72 1518 1520 74 -1 
            72 73 74 -1 
            1518 1519 1520 -1 
            75 1521 1522 76 -1 
            77 1523 1522 76 -1 
            75 1521 1523 77 -1 
            75 1521 1523 77 -1 
            75 76 77 -1 
            1521 1522 1523 -1 
            78 1524 1525 79 -1 
            80 1526 1525 79 -1 
            78 1524 1526 80 -1 
            78 1524 1526 80 -1 
            78 79 80 -1 
            1524 1525 1526 -1 
            81 1527 1528 82 -1 
            83 1529 1528 82 -1 
            81 1527 1529 83 -1 
            81 1527 1529 83 -1 
            81 82 83 -1 
            1527 1528 1529 -1 
            84 1530 1531 85 -1 
            86 1532 1531 85 -1 
            84 1530 1532 86 -1 
            84 1530 1532 86 -1 
            84 85 86 -1 
            1530 1531 1532 -1 
            87 1533 1534 88 -1 
            89 1535 1534 88 -1 
            87 1533 1535 89 -1 
            87 1533 1535 89 -1 
            87 88 89 -1 
            1533 1534 1535 -1 
            90 1536 1537 91 -1 
            92 1538 1537 91 -1 
            90 1536 1538 92 -1 
            90 1536 1538 92 -1 
            90 91 92 -1 
            1536 1537 1538 -1 
            93 1539 1540 94 -1 
            95 1541 1540 94 -1 
            93 1539 1541 95 -1 
            93 1539 1541 95 -1 
            93 94 95 -1 
            1539 1540 1541 -1 
            96 1542 1543 97 -1 
            98 1544 1543 97 -1 
            96 1542 1544 98 -1 
            96 1542 1544 98 -1 
            96 97 98 -1 
            1542 1543 1544 -1 
            99 1545 1546 100 -1 
            101 1547 1546 100 -1 
            99 1545 1547 101 -1 
            99 1545 1547 101 -1 
            99 100 101 -1 
            1545 1546 1547 -1 
            102 1548 1549 103 -1 
            104 1550 1549 103 -1 
            102 1548 1550 104 -1 
            102 1548 1550 104 -1 
            102 103 104 -1 
            1548 1549 1550 -1 
            105 1551 1552 106 -1 
            107 1553 1552 106 -1 
            105 1551 1553 107 -1 
            105 1551 1553 107 -1 
            105 106 107 -1 
            1551 1552 1553 -1 
            108 1554 1555 109 -1 
            110 1556 1555 109 -1 
            108 1554 1556 110 -1 
            108 1554 1556 110 -1 
            108 109 110 -1 
            1554 1555 1556 -1 
            111 1557 1558 112 -1 
            113 1559 1558 112 -1 
            111 1557 1559 113 -1 
            111 1557 1559 113 -1 
            111 112 113 -1 
            1557 1558 1559 -1 
            114 1560 1561 115 -1 
            116 1562 1561 115 -1 
            114 1560 1562 116 -1 
            114 1560 1562 116 -1 
            114 115 116 -1 
            1560 1561 1562 -1 
            117 1563 1564 118 -1 
            119 1565 1564 118 -1 
            117 1563 1565 119 -1 
            117 1563 1565 119 -1 
            117 118 119 -1 
            1563 1564 1565 -1 
            120 1566 1567 121 -1 
            122 1568 1567 121 -1 
            120 1566 1568 122 -1 
            120 1566 1568 122 -1 
            120 121 122 -1 
            1566 1567 1568 -1 
            123 1569 1570 124 -1 
            125 1571 1570 124 -1 
            123 1569 1571 125 -1 
            123 1569 1571 125 -1 
            123 124 125 -1 
            1569 1570 1571 -1 
            126 1572 1573 127 -1 
            128 1574 1573 127 -1 
            126 1572 1574 128 -1 
            126 1572 1574 128 -1 
            126 127 128 -1 
            1572 1573 1574 -1 
            129 1575 1576 130 -1 
            131 1577 1576 130 -1 
            129 1575 1577 131 -1 
            129 1575 1577 131 -1 
            129 130 131 -1 
            1575 1576 1577 -1 
            132 1578 1579 133 -1 
            134 1580 1579 133 -1 
            132 1578 1580 134 -1 
            132 1578 1580 134 -1 
            132 133 134 -1 
            1578 1579 1580 -1 
            135 1581 1582 136 -1 
            137 1583 1582 136 -1 
            135 1581 1583 137 -1 
            135 1581 1583 137 -1 
            135 136 137 -1 
            1581 1582 1583 -1 
            138 1584 1585 139 -1 
            140 1586 1585 139 -1 
            138 1584 1586 140 -1 
            138 1584 1586 140 -1 
            138 139 140 -1 
            1584 1585 1586 -1 
            141 1587 1588 142 -1 
            143 1589 1588 142 -1 
            141 1587 1589 143 -1 
            141 1587 1589 143 -1 
            141 142 143 -1 
            1587 1588 1589 -1 
            144 1590 1591 145 -1 
            146 1592 1591 145 -1 
            144 1590 1592 146 -1 
            144 1590 1592 146 -1 
            144 145 146 -1 
            1590 1591 1592 -1 
            147 1593 1594 148 -1 
            149 1595 1594 148 -1 
            147 1593 1595 149 -1 
            147 1593 1595 149 -1 
            147 148 149 -1 
            1593 1594 1595 -1 
            150 1596 1597 151 -1 
            152 1598 1597 151 -1 
            150 1596 1598 152 -1 
            150 1596 1598 152 -1 
            150 151 152 -1 
            1596 1597 1598 -1 
            153 1599 1600 154 -1 
            155 1601 1600 154 -1 
            153 1599 1601 155 -1 
            153 1599 1601 155 -1 
            153 154 155 -1 
            1599 1600 1601 -1 
            156 1602 1603 157 -1 
            158 1604 1603 157 -1 
            156 1602 1604 158 -1 
            156 1602 1604 158 -1 
            156 157 158 -1 
            1602 1603 1604 -1 
            159 1605 1606 160 -1 
            161 1607 1606 160 -1 
            159 1605 1607 161 -1 
            159 1605 1607 161 -1 
            159 160 161 -1 
            1605 1606 1607 -1 
            162 1608 1609 163 -1 
            164 1610 1609 163 -1 
            162 1608 1610 164 -1 
            162 1608 1610 164 -1 
            162 163 164 -1 
            1608 1609 1610 -1 
            165 1611 1612 166 -1 
            167 1613 1612 166 -1 
            165 1611 1613 167 -1 
            165 1611 1613 167 -1 
            165 166 167 -1 
            1611 1612 1613 -1 
            168 1614 1615 169 -1 
            170 1616 1615 169 -1 
            168 1614 1616 170 -1 
            168 1614 1616 170 -1 
            168 169 170 -1 
            1614 1615 1616 -1 
            171 1617 1618 172 -1 
            173 1619 1618 172 -1 
            171 1617 1619 173 -1 
            171 1617 1619 173 -1 
            171 172 173 -1 
            1617 1618 1619 -1 
            174 1620 1621 175 -1 
            176 1622 1621 175 -1 
            174 1620 1622 176 -1 
            174 1620 1622 176 -1 
            174 175 176 -1 
            1620 1621 1622 -1 
            177 1623 1624 178 -1 
            179 1625 1624 178 -1 
            177 1623 1625 179 -1 
            177 1623 1625 179 -1 
            177 178 179 -1 
            1623 1624 1625 -1 
            180 1626 1627 181 -1 
            182 1628 1627 181 -1 
            180 1626 1628 182 -1 
            180 1626 1628 182 -1 
            180 181 182 -1 
            1626 1627 1628 -1 
            183 1629 1630 184 -1 
            185 1631 1630 184 -1 
            183 1629 1631 185 -1 
            183 1629 1631 185 -1 
            183 184 185 -1 
            1629 1630 1631 -1 
            186 1632 1633 187 -1 
            188 1634 1633 187 -1 
            186 1632 1634 188 -1 
            186 1632 1634 188 -1 
            186 187 188 -1 
            1632 1633 1634 -1 
            189 1635 1636 190 -1 
            191 1637 1636 190 -1 
            189 1635 1637 191 -1 
            189 1635 1637 191 -1 
            189 190 191 -1 
            1635 1636 1637 -1 
            192 1638 1639 193 -1 
            194 1640 1639 193 -1 
            192 1638 1640 194 -1 
            192 1638 1640 194 -1 
            192 193 194 -1 
            1638 1639 1640 -1 
            195 1641 1642 196 -1 
            197 1643 1642 196 -1 
            195 1641 1643 197 -1 
            195 1641 1643 197 -1 
            195 196 197 -1 
            1641 1642 1643 -1 
            198 1644 1645 199 -1 
            200 1646 1645 199 -1 
            198 1644 1646 200 -1 
            198 1644 1646 200 -1 
            198 199 200 -1 
            1644 1645 1646 -1 
            201 1647 1648 202 -1 
            203 1649 1648 202 -1 
            201 1647 1649 203 -1 
            201 1647 1649 203 -1 
            201 202 203 -1 
            1647 1648 1649 -1 
            204 1650 1651 205 -1 
            206 1652 1651 205 -1 
            204 1650 1652 206 -1 
            204 1650 1652 206 -1 
            204 205 206 -1 
            1650 1651 1652 -1 
            207 1653 1654 208 -1 
            209 1655 1654 208 -1 
            207 1653 1655 209 -1 
            207 1653 1655 209 -1 
            207 208 209 -1 
            1653 1654 1655 -1 
            210 1656 1657 211 -1 
            212 1658 1657 211 -1 
            210 1656 1658 212 -1 
            210 1656 1658 212 -1 
            210 211 212 -1 
            1656 1657 1658 -1 
            213 1659 1660 214 -1 
            215 1661 1660 214 -1 
            213 1659 1661 215 -1 
            213 1659 1661 215 -1 
            213 214 215 -1 
            1659 1660 1661 -1 
            216 1662 1663 217 -1 
            218 1664 1663 217 -1 
            216 1662 1664 218 -1 
            216 1662 1664 218 -1 
            216 217 218 -1 
            1662 1663 1664 -1 
            219 1665 1666 220 -1 
            221 1667 1666 220 -1 
            219 1665 1667 221 -1 
            219 1665 1667 221 -1 
            219 220 221 -1 
            1665 1666 1667 -1 
            222 1668 1669 223 -1 
            224 1670 1669 223 -1 
            222 1668 1670 224 -1 
            222 1668 1670 224 -1 
            222 223 224 -1 
            1668 1669 1670 -1 
            225 1671 1672 226 -1 
            227 1673 1672 226 -1 
            225 1671 1673 227 -1 
            225 1671 1673 227 -1 
            225 226 227 -1 
            1671 1672 1673 -1 
            228 1674 1675 229 -1 
            230 1676 1675 229 -1 
            228 1674 1676 230 -1 
            228 1674 1676 230 -1 
            228 229 230 -1 
            1674 1675 1676 -1 
            231 1677 1678 232 -1 
            233 1679 1678 232 -1 
            231 1677 1679 233 -1 
            231 1677 1679 233 -1 
            231 232 233 -1 
            1677 1678 1679 -1 
            234 1680 1681 235 -1 
            236 1682 1681 235 -1 
            234 1680 1682 236 -1 
            234 1680 1682 236 -1 
            234 235 236 -1 
            1680 1681 1682 -1 
            237 1683 1684 238 -1 
            239 1685 1684 238 -1 
            237 1683 1685 239 -1 
            237 1683 1685 239 -1 
            237 238 239 -1 
            1683 1684 1685 -1 
            240 1686 1687 241 -1 
            242 1688 1687 241 -1 
            240 1686 1688 242 -1 
            240 1686 1688 242 -1 
            240 241 242 -1 
            1686 1687 1688 -1 
            243 1689 1690 244 -1 
            245 1691 1690 244 -1 
            243 1689 1691 245 -1 
            243 1689 1691 245 -1 
            243 244 245 -1 
            1689 1690 1691 -1 
            246 1692 1693 247 -1 
            248 1694 1693 247 -1 
            246 1692 1694 248 -1 
            246 1692 1694 248 -1 
            246 247 248 -1 
            1692 1693 1694 -1 
            249 1695 1696 250 -1 
            251 1697 1696 250 -1 
            249 1695 1697 251 -1 
            249 1695 1697 251 -1 
            249 250 251 -1 
            1695 1696 1697 -1 
            252 1698 1699 253 -1 
            254 1700 1699 253 -1 
            252 1698 1700 254 -1 
            252 1698 1700 254 -1 
            252 253 254 -1 
            1698 1699 1700 -1 
            255 1701 1702 256 -1 
            257 1703 1702 256 -1 
            255 1701 1703 257 -1 
            255 1701 1703 257 -1 
            255 256 257 -1 
            1701 1702 1703 -1 
            258 1704 1705 259 -1 
            260 1706 1705 259 -1 
            258 1704 1706 260 -1 
            258 1704 1706 260 -1 
            258 259 260 -1 
            1704 1705 1706 -1 
            261 1707 1708 262 -1 
            263 1709 1708 262 -1 
            261 1707 1709 263 -1 
            261 1707 1709 263 -1 
            261 262 263 -1 
            1707 1708 1709 -1 
            264 1710 1711 265 -1 
            266 1712 1711 265 -1 
            264 1710 1712 266 -1 
            264 1710 1712 266 -1 
            264 265 266 -1 
            1710 1711 1712 -1 
            267 1713 1714 268 -1 
            269 1715 1714 268 -1 
            267 1713 1715 269 -1 
            267 1713 1715 269 -1 
            267 268 269 -1 
            1713 1714 1715 -1 
            270 1716 1717 271 -1 
            272 1718 1717 271 -1 
            270 1716 1718 272 -1 
            270 1716 1718 272 -1 
            270 271 272 -1 
            1716 1717 1718 -1 
            273 1719 1720 274 -1 
            275 1721 1720 274 -1 
            273 1719 1721 275 -1 
            273 1719 1721 275 -1 
            273 274 275 -1 
            1719 1720 1721 -1 
            276 1722 1723 277 -1 
            278 1724 1723 277 -1 
            276 1722 1724 278 -1 
            276 1722 1724 278 -1 
            276 277 278 -1 
            1722 1723 1724 -1 
            279 1725 1726 280 -1 
            281 1727 1726 280 -1 
            279 1725 1727 281 -1 
            279 1725 1727 281 -1 
            279 280 281 -1 
            1725 1726 1727 -1 
            282 1728 1729 283 -1 
            284 1730 1729 283 -1 
            282 1728 1730 284 -1 
            282 1728 1730 284 -1 
            282 283 284 -1 
            1728 1729 1730 -1 
            285 1731 1732 286 -1 
            287 1733 1732 286 -1 
            285 1731 1733 287 -1 
            285 1731 1733 287 -1 
            285 286 287 -1 
            1731 1732 1733 -1 
            288 1734 1735 289 -1 
            290 1736 1735 289 -1 
            288 1734 1736 290 -1 
            288 1734 1736 290 -1 
            288 289 290 -1 
            1734 1735 1736 -1 
            291 1737 1738 292 -1 
            293 1739 1738 292 -1 
            291 1737 1739 293 -1 
            291 1737 1739 293 -1 
            291 292 293 -1 
            1737 1738 1739 -1 
            294 1740 1741 295 -1 
            296 1742 1741 295 -1 
            294 1740 1742 296 -1 
            294 1740 1742 296 -1 
            294 295 296 -1 
            1740 1741 1742 -1 
            297 1743 1744 298 -1 
            299 1745 1744 298 -1 
            297 1743 1745 299 -1 
            297 1743 1745 299 -1 
            297 298 299 -1 
            1743 1744 1745 -1 
            300 1746 1747 301 -1 
            302 1748 1747 301 -1 
            300 1746 1748 302 -1 
            300 1746 1748 302 -1 
            300 301 302 -1 
            1746 1747 1748 -1 
            303 1749 1750 304 -1 
            305 1751 1750 304 -1 
            303 1749 1751 305 -1 
            303 1749 1751 305 -1 
            303 304 305 -1 
            1749 1750 1751 -1 
            306 1752 1753 307 -1 
            308 1754 1753 307 -1 
            306 1752 1754 308 -1 
            306 1752 1754 308 -1 
            306 307 308 -1 
            1752 1753 1754 -1 
            309 1755 1756 310 -1 
            311 1757 1756 310 -1 
            309 1755 1757 311 -1 
            309 1755 1757 311 -1 
            309 310 311 -1 
            1755 1756 1757 -1 
            312 1758 1759 313 -1 
            314 1760 1759 313 -1 
            312 1758 1760 314 -1 
            312 1758 1760 314 -1 
            312 313 314 -1 
            1758 1759 1760 -1 
            315 1761 1762 316 -1 
            317 1763 1762 316 -1 
            315 1761 1763 317 -1 
            315 1761 1763 317 -1 
            315 316 317 -1 
            1761 1762 1763 -1 
            318 1764 1765 319 -1 
            320 1766 1765 319 -1 
            318 1764 1766 320 -1 
            318 1764 1766 320 -1 
            318 319 320 -1 
            1764 1765 1766 -1 
            321 1767 1768 322 -1 
            323 1769 1768 322 -1 
            321 1767 1769 323 -1 
            321 1767 1769 323 -1 
            321 322 323 -1 
            1767 1768 1769 -1 
            324 1770 1771 325 -1 
            326 1772 1771 325 -1 
            324 1770 1772 326 -1 
            324 1770 1772 326 -1 
            324 325 326 -1 
            1770 1771 1772 -1 
            327 1773 1774 328 -1 
            329 1775 1774 328 -1 
            327 1773 1775 329 -1 
            327 1773 1775 329 -1 
            327 328 329 -1 
            1773 1774 1775 -1 
            330 1776 1777 331 -1 
            332 1778 1777 331 -1 
            330 1776 1778 332 -1 
            330 1776 1778 332 -1 
            330 331 332 -1 
            1776 1777 1778 -1 
            333 1779 1780 334 -1 
            335 1781 1780 334 -1 
            333 1779 1781 335 -1 
            333 1779 1781 335 -1 
            333 334 335 -1 
            1779 1780 1781 -1 
            336 1782 1783 337 -1 
            338 1784 1783 337 -1 
            336 1782 1784 338 -1 
            336 1782 1784 338 -1 
            336 337 338 -1 
            1782 1783 1784 -1 
            339 1785 1786 340 -1 
            341 1787 1786 340 -1 
            339 1785 1787 341 -1 
            339 1785 1787 341 -1 
            339 340 341 -1 
            1785 1786 1787 -1 
            342 1788 1789 343 -1 
            344 1790 1789 343 -1 
            342 1788 1790 344 -1 
            342 1788 1790 344 -1 
            342 343 344 -1 
            1788 1789 1790 -1 
            345 1791 1792 346 -1 
            347 1793 1792 346 -1 
            345 1791 1793 347 -1 
            345 1791 1793 347 -1 
            345 346 347 -1 
            1791 1792 1793 -1 
            348 1794 1795 349 -1 
            350 1796 1795 349 -1 
            348 1794 1796 350 -1 
            348 1794 1796 350 -1 
            348 349 350 -1 
            1794 1795 1796 -1 
            351 1797 1798 352 -1 
            353 1799 1798 352 -1 
            351 1797 1799 353 -1 
            351 1797 1799 353 -1 
            351 352 353 -1 
            1797 1798 1799 -1 
            354 1800 1801 355 -1 
            356 1802 1801 355 -1 
            354 1800 1802 356 -1 
            354 1800 1802 356 -1 
            354 355 356 -1 
            1800 1801 1802 -1 
            357 1803 1804 358 -1 
            359 1805 1804 358 -1 
            357 1803 1805 359 -1 
            357 1803 1805 359 -1 
            357 358 359 -1 
            1803 1804 1805 -1 
            360 1806 1807 361 -1 
            362 1808 1807 361 -1 
            360 1806 1808 362 -1 
            360 1806 1808 362 -1 
            360 361 362 -1 
            1806 1807 1808 -1 
            363 1809 1810 364 -1 
            365 1811 1810 364 -1 
            363 1809 1811 365 -1 
            363 1809 1811 365 -1 
            363 364 365 -1 
            1809 1810 1811 -1 
            366 1812 1813 367 -1 
            368 1814 1813 367 -1 
            366 1812 1814 368 -1 
            366 1812 1814 368 -1 
            366 367 368 -1 
            1812 1813 1814 -1 
            369 1815 1816 370 -1 
            371 1817 1816 370 -1 
            369 1815 1817 371 -1 
            369 1815 1817 371 -1 
            369 370 371 -1 
            1815 1816 1817 -1 
            372 1818 1819 373 -1 
            374 1820 1819 373 -1 
            372 1818 1820 374 -1 
            372 1818 1820 374 -1 
            372 373 374 -1 
            1818 1819 1820 -1 
            375 1821 1822 376 -1 
            377 1823 1822 376 -1 
            375 1821 1823 377 -1 
            375 1821 1823 377 -1 
            375 376 377 -1 
            1821 1822 1823 -1 
            378 1824 1825 379 -1 
            380 1826 1825 379 -1 
            378 1824 1826 380 -1 
            378 1824 1826 380 -1 
            378 379 380 -1 
            1824 1825 1826 -1 
            381 1827 1828 382 -1 
            383 1829 1828 382 -1 
            381 1827 1829 383 -1 
            381 1827 1829 383 -1 
            381 382 383 -1 
            1827 1828 1829 -1 
            384 1830 1831 385 -1 
            386 1832 1831 385 -1 
            384 1830 1832 386 -1 
            384 1830 1832 386 -1 
            384 385 386 -1 
            1830 1831 1832 -1 
            387 1833 1834 388 -1 
            389 1835 1834 388 -1 
            387 1833 1835 389 -1 
            387 1833 1835 389 -1 
            387 388 389 -1 
            1833 1834 1835 -1 
            390 1836 1837 391 -1 
            392 1838 1837 391 -1 
            390 1836 1838 392 -1 
            390 1836 1838 392 -1 
            390 391 392 -1 
            1836 1837 1838 -1 
            393 1839 1840 394 -1 
            395 1841 1840 394 -1 
            393 1839 1841 395 -1 
            393 1839 1841 395 -1 
            393 394 395 -1 
            1839 1840 1841 -1 
            396 1842 1843 397 -1 
            398 1844 1843 397 -1 
            396 1842 1844 398 -1 
            396 1842 1844 398 -1 
            396 397 398 -1 
            1842 1843 1844 -1 
            399 1845 1846 400 -1 
            401 1847 1846 400 -1 
            399 1845 1847 401 -1 
            399 1845 1847 401 -1 
            399 400 401 -1 
            1845 1846 1847 -1 
            402 1848 1849 403 -1 
            404 1850 1849 403 -1 
            402 1848 1850 404 -1 
            402 1848 1850 404 -1 
            402 403 404 -1 
            1848 1849 1850 -1 
            405 1851 1852 406 -1 
            407 1853 1852 406 -1 
            405 1851 1853 407 -1 
            405 1851 1853 407 -1 
            405 406 407 -1 
            1851 1852 1853 -1 
            408 1854 1855 409 -1 
            410 1856 1855 409 -1 
            408 1854 1856 410 -1 
            408 1854 1856 410 -1 
            408 409 410 -1 
            1854 1855 1856 -1 
            411 1857 1858 412 -1 
            413 1859 1858 412 -1 
            411 1857 1859 413 -1 
            411 1857 1859 413 -1 
            411 412 413 -1 
            1857 1858 1859 -1 
            414 1860 1861 415 -1 
            416 1862 1861 415 -1 
            414 1860 1862 416 -1 
            414 1860 1862 416 -1 
            414 415 416 -1 
            1860 1861 1862 -1 
            417 1863 1864 418 -1 
            419 1865 1864 418 -1 
            417 1863 1865 419 -1 
            417 1863 1865 419 -1 
            417 418 419 -1 
            1863 1864 1865 -1 
            420 1866 1867 421 -1 
            422 1868 1867 421 -1 
            420 1866 1868 422 -1 
            420 1866 1868 422 -1 
            420 421 422 -1 
            1866 1867 1868 -1 
            423 1869 1870 424 -1 
            425 1871 1870 424 -1 
            423 1869 1871 425 -1 
            423 1869 1871 425 -1 
            423 424 425 -1 
            1869 1870 1871 -1 
            426 1872 1873 427 -1 
            428 1874 1873 427 -1 
            426 1872 1874 428 -1 
            426 1872 1874 428 -1 
            426 427 428 -1 
            1872 1873 1874 -1 
            429 1875 1876 430 -1 
            431 1877 1876 430 -1 
            429 1875 1877 431 -1 
            429 1875 1877 431 -1 
            429 430 431 -1 
            1875 1876 1877 -1 
            432 1878 1879 433 -1 
            434 1880 1879 433 -1 
            432 1878 1880 434 -1 
            432 1878 1880 434 -1 
            432 433 434 -1 
            1878 1879 1880 -1 
            435 1881 1882 436 -1 
            437 1883 1882 436 -1 
            435 1881 1883 437 -1 
            435 1881 1883 437 -1 
            435 436 437 -1 
            1881 1882 1883 -1 
            438 1884 1885 439 -1 
            440 1886 1885 439 -1 
            438 1884 1886 440 -1 
            438 1884 1886 440 -1 
            438 439 440 -1 
            1884 1885 1886 -1 
            441 1887 1888 442 -1 
            443 1889 1888 442 -1 
            441 1887 1889 443 -1 
            441 1887 1889 443 -1 
            441 442 443 -1 
            1887 1888 1889 -1 
            444 1890 1891 445 -1 
            446 1892 1891 445 -1 
            444 1890 1892 446 -1 
            444 1890 1892 446 -1 
            444 445 446 -1 
            1890 1891 1892 -1 
            447 1893 1894 448 -1 
            449 1895 1894 448 -1 
            447 1893 1895 449 -1 
            447 1893 1895 449 -1 
            447 448 449 -1 
            1893 1894 1895 -1 
            450 1896 1897 451 -1 
            452 1898 1897 451 -1 
            450 1896 1898 452 -1 
            450 1896 1898 452 -1 
            450 451 452 -1 
            1896 1897 1898 -1 
            453 1899 1900 454 -1 
            455 1901 1900 454 -1 
            453 1899 1901 455 -1 
            453 1899 1901 455 -1 
            453 454 455 -1 
            1899 1900 1901 -1 
            456 1902 1903 457 -1 
            458 1904 1903 457 -1 
            456 1902 1904 458 -1 
            456 1902 1904 458 -1 
            456 457 458 -1 
            1902 1903 1904 -1 
            459 1905 1906 460 -1 
            461 1907 1906 460 -1 
            459 1905 1907 461 -1 
            459 1905 1907 461 -1 
            459 460 461 -1 
            1905 1906 1907 -1 
            462 1908 1909 463 -1 
            464 1910 1909 463 -1 
            462 1908 1910 464 -1 
            462 1908 1910 464 -1 
            462 463 464 -1 
            1908 1909 1910 -1 
            465 1911 1912 466 -1 
            467 1913 1912 466 -1 
            465 1911 1913 467 -1 
            465 1911 1913 467 -1 
            465 466 467 -1 
            1911 1912 1913 -1 
            468 1914 1915 469 -1 
            470 1916 1915 469 -1 
            468 1914 1916 470 -1 
            468 1914 1916 470 -1 
            468 469 470 -1 
            1914 1915 1916 -1 
            471 1917 1918 472 -1 
            473 1919 1918 472 -1 
            471 1917 1919 473 -1 
            471 1917 1919 473 -1 
            471 472 473 -1 
            1917 1918 1919 -1 
            474 1920 1921 475 -1 
            476 1922 1921 475 -1 
            474 1920 1922 476 -1 
            474 1920 1922 476 -1 
            474 475 476 -1 
            1920 1921 1922 -1 
            477 1923 1924 478 -1 
            479 1925 1924 478 -1 
            477 1923 1925 479 -1 
            477 1923 1925 479 -1 
            477 478 479 -1 
            1923 1924 1925 -1 
            480 1926 1927 481 -1 
            482 1928 1927 481 -1 
            480 1926 1928 482 -1 
            480 1926 1928 482 -1 
            480 481 482 -1 
            1926 1927 1928 -1 
            483 1929 1930 484 -1 
            485 1931 1930 484 -1 
            483 1929 1931 485 -1 
            483 1929 1931 485 -1 
            483 484 485 -1 
            1929 1930 1931 -1 
            486 1932 1933 487 -1 
            488 1934 1933 487 -1 
            486 1932 1934 488 -1 
            486 1932 1934 488 -1 
            486 487 488 -1 
            1932 1933 1934 -1 
            489 1935 1936 490 -1 
            491 1937 1936 490 -1 
            489 1935 1937 491 -1 
            489 1935 1937 491 -1 
            489 490 491 -1 
            1935 1936 1937 -1 
            492 1938 1939 493 -1 
            494 1940 1939 493 -1 
            492 1938 1940 494 -1 
            492 1938 1940 494 -1 
            492 493 494 -1 
            1938 1939 1940 -1 
            495 1941 1942 496 -1 
            497 1943 1942 496 -1 
            495 1941 1943 497 -1 
            495 1941 1943 497 -1 
            495 496 497 -1 
            1941 1942 1943 -1 
            498 1944 1945 499 -1 
            500 1946 1945 499 -1 
            498 1944 1946 500 -1 
            498 1944 1946 500 -1 
            498 499 500 -1 
            1944 1945 1946 -1 
            501 1947 1948 502 -1 
            503 1949 1948 502 -1 
            501 1947 1949 503 -1 
            501 1947 1949 503 -1 
            501 502 503 -1 
            1947 1948 1949 -1 
            504 1950 1951 505 -1 
            506 1952 1951 505 -1 
            504 1950 1952 506 -1 
            504 1950 1952 506 -1 
            504 505 506 -1 
            1950 1951 1952 -1 
            507 1953 1954 508 -1 
            509 1955 1954 508 -1 
            507 1953 1955 509 -1 
            507 1953 1955 509 -1 
            507 508 509 -1 
            1953 1954 1955 -1 
            510 1956 1957 511 -1 
            512 1958 1957 511 -1 
            510 1956 1958 512 -1 
            510 1956 1958 512 -1 
            510 511 512 -1 
            1956 1957 1958 -1 
            513 1959 1960 514 -1 
            515 1961 1960 514 -1 
            513 1959 1961 515 -1 
            513 1959 1961 515 -1 
            513 514 515 -1 
            1959 1960 1961 -1 
            516 1962 1963 517 -1 
            518 1964 1963 517 -1 
            516 1962 1964 518 -1 
            516 1962 1964 518 -1 
            516 517 518 -1 
            1962 1963 1964 -1 
            519 1965 1966 520 -1 
            521 1967 1966 520 -1 
            519 1965 1967 521 -1 
            519 1965 1967 521 -1 
            519 520 521 -1 
            1965 1966 1967 -1 
            522 1968 1969 523 -1 
            524 1970 1969 523 -1 
            522 1968 1970 524 -1 
            522 1968 1970 524 -1 
            522 523 524 -1 
            1968 1969 1970 -1 
            525 1971 1972 526 -1 
            527 1973 1972 526 -1 
            525 1971 1973 527 -1 
            525 1971 1973 527 -1 
            525 526 527 -1 
            1971 1972 1973 -1 
            528 1974 1975 529 -1 
            530 1976 1975 529 -1 
            528 1974 1976 530 -1 
            528 1974 1976 530 -1 
            528 529 530 -1 
            1974 1975 1976 -1 
            531 1977 1978 532 -1 
            533 1979 1978 532 -1 
            531 1977 1979 533 -1 
            531 1977 1979 533 -1 
            531 532 533 -1 
            1977 1978 1979 -1 
            534 1980 1981 535 -1 
            536 1982 1981 535 -1 
            534 1980 1982 536 -1 
            534 1980 1982 536 -1 
            534 535 536 -1 
            1980 1981 1982 -1 
            537 1983 1984 538 -1 
            539 1985 1984 538 -1 
            537 1983 1985 539 -1 
            537 1983 1985 539 -1 
            537 538 539 -1 
            1983 1984 1985 -1 
            540 1986 1987 541 -1 
            542 1988 1987 541 -1 
            540 1986 1988 542 -1 
            540 1986 1988 542 -1 
            540 541 542 -1 
            1986 1987 1988 -1 
            543 1989 1990 544 -1 
            545 1991 1990 544 -1 
            543 1989 1991 545 -1 
            543 1989 1991 545 -1 
            543 544 545 -1 
            1989 1990 1991 -1 
            546 1992 1993 547 -1 
            548 1994 1993 547 -1 
            546 1992 1994 548 -1 
            546 1992 1994 548 -1 
            546 547 548 -1 
            1992 1993 1994 -1 
            549 1995 1996 550 -1 
            551 1997 1996 550 -1 
            549 1995 1997 551 -1 
            549 1995 1997 551 -1 
            549 550 551 -1 
            1995 1996 1997 -1 
            552 1998 1999 553 -1 
            554 2000 1999 553 -1 
            552 1998 2000 554 -1 
            552 1998 2000 554 -1 
            552 553 554 -1 
            1998 1999 2000 -1 
            555 2001 2002 556 -1 
            557 2003 2002 556 -1 
            555 2001 2003 557 -1 
            555 2001 2003 557 -1 
            555 556 557 -1 
            2001 2002 2003 -1 
            558 2004 2005 559 -1 
            560 2006 2005 559 -1 
            558 2004 2006 560 -1 
            558 2004 2006 560 -1 
            558 559 560 -1 
            2004 2005 2006 -1 
            561 2007 2008 562 -1 
            563 2009 2008 562 -1 
            561 2007 2009 563 -1 
            561 2007 2009 563 -1 
            561 562 563 -1 
            2007 2008 2009 -1 
            564 2010 2011 565 -1 
            566 2012 2011 565 -1 
            564 2010 2012 566 -1 
            564 2010 2012 566 -1 
            564 565 566 -1 
            2010 2011 2012 -1 
            567 2013 2014 568 -1 
            569 2015 2014 568 -1 
            567 2013 2015 569 -1 
            567 2013 2015 569 -1 
            567 568 569 -1 
            2013 2014 2015 -1 
            570 2016 2017 571 -1 
            572 2018 2017 571 -1 
            570 2016 2018 572 -1 
            570 2016 2018 572 -1 
            570 571 572 -1 
            2016 2017 2018 -1 
            573 2019 2020 574 -1 
            575 2021 2020 574 -1 
            573 2019 2021 575 -1 
            573 2019 2021 575 -1 
            573 574 575 -1 
            2019 2020 2021 -1 
            576 2022 2023 577 -1 
            578 2024 2023 577 -1 
            576 2022 2024 578 -1 
            576 2022 2024 578 -1 
            576 577 578 -1 
            2022 2023 2024 -1 
            579 2025 2026 580 -1 
            581 2027 2026 580 -1 
            579 2025 2027 581 -1 
            579 2025 2027 581 -1 
            579 580 581 -1 
            2025 2026 2027 -1 
            582 2028 2029 583 -1 
            584 2030 2029 583 -1 
            582 2028 2030 584 -1 
            582 2028 2030 584 -1 
            582 583 584 -1 
            2028 2029 2030 -1 
            585 2031 2032 586 -1 
            587 2033 2032 586 -1 
            585 2031 2033 587 -1 
            585 2031 2033 587 -1 
            585 586 587 -1 
            2031 2032 2033 -1 
            588 2034 2035 589 -1 
            590 2036 2035 589 -1 
            588 2034 2036 590 -1 
            588 2034 2036 590 -1 
            588 589 590 -1 
            2034 2035 2036 -1 
            591 2037 2038 592 -1 
            593 2039 2038 592 -1 
            591 2037 2039 593 -1 
            591 2037 2039 593 -1 
            591 592 593 -1 
            2037 2038 2039 -1 
            594 2040 2041 595 -1 
            596 2042 2041 595 -1 
            594 2040 2042 596 -1 
            594 2040 2042 596 -1 
            594 595 596 -1 
            2040 2041 2042 -1 
            597 2043 2044 598 -1 
            599 2045 2044 598 -1 
            597 2043 2045 599 -1 
            597 2043 2045 599 -1 
            597 598 599 -1 
            2043 2044 2045 -1 
            600 2046 2047 601 -1 
            602 2048 2047 601 -1 
            600 2046 2048 602 -1 
            600 2046 2048 602 -1 
            600 601 602 -1 
            2046 2047 2048 -1 
            603 2049 2050 604 -1 
            605 2051 2050 604 -1 
            603 2049 2051 605 -1 
            603 2049 2051 605 -1 
            603 604 605 -1 
            2049 2050 2051 -1 
            606 2052 2053 607 -1 
            608 2054 2053 607 -1 
            606 2052 2054 608 -1 
            606 2052 2054 608 -1 
            606 607 608 -1 
            2052 2053 2054 -1 
            609 2055 2056 610 -1 
            611 2057 2056 610 -1 
            609 2055 2057 611 -1 
            609 2055 2057 611 -1 
            609 610 611 -1 
            2055 2056 2057 -1 
            612 2058 2059 613 -1 
            614 2060 2059 613 -1 
            612 2058 2060 614 -1 
            612 2058 2060 614 -1 
            612 613 614 -1 
            2058 2059 2060 -1 
            615 2061 2062 616 -1 
            617 2063 2062 616 -1 
            615 2061 2063 617 -1 
            615 2061 2063 617 -1 
            615 616 617 -1 
            2061 2062 2063 -1 
            618 2064 2065 619 -1 
            620 2066 2065 619 -1 
            618 2064 2066 620 -1 
            618 2064 2066 620 -1 
            618 619 620 -1 
            2064 2065 2066 -1 
            621 2067 2068 622 -1 
            623 2069 2068 622 -1 
            621 2067 2069 623 -1 
            621 2067 2069 623 -1 
            621 622 623 -1 
            2067 2068 2069 -1 
            624 2070 2071 625 -1 
            626 2072 2071 625 -1 
            624 2070 2072 626 -1 
            624 2070 2072 626 -1 
            624 625 626 -1 
            2070 2071 2072 -1 
            627 2073 2074 628 -1 
            629 2075 2074 628 -1 
            627 2073 2075 629 -1 
            627 2073 2075 629 -1 
            627 628 629 -1 
            2073 2074 2075 -1 
            630 2076 2077 631 -1 
            632 2078 2077 631 -1 
            630 2076 2078 632 -1 
            630 2076 2078 632 -1 
            630 631 632 -1 
            2076 2077 2078 -1 
            633 2079 2080 634 -1 
            635 2081 2080 634 -1 
            633 2079 2081 635 -1 
            633 2079 2081 635 -1 
            633 634 635 -1 
            2079 2080 2081 -1 
            636 2082 2083 637 -1 
            638 2084 2083 637 -1 
            636 2082 2084 638 -1 
            636 2082 2084 638 -1 
            636 637 638 -1 
            2082 2083 2084 -1 
            639 2085 2086 640 -1 
            641 2087 2086 640 -1 
            639 2085 2087 641 -1 
            639 2085 2087 641 -1 
            639 640 641 -1 
            2085 2086 2087 -1 
            642 2088 2089 643 -1 
            644 2090 2089 643 -1 
            642 2088 2090 644 -1 
            642 2088 2090 644 -1 
            642 643 644 -1 
            2088 2089 2090 -1 
            645 2091 2092 646 -1 
            647 2093 2092 646 -1 
            645 2091 2093 647 -1 
            645 2091 2093 647 -1 
            645 646 647 -1 
            2091 2092 2093 -1 
            648 2094 2095 649 -1 
            650 2096 2095 649 -1 
            648 2094 2096 650 -1 
            648 2094 2096 650 -1 
            648 649 650 -1 
            2094 2095 2096 -1 
            651 2097 2098 652 -1 
            653 2099 2098 652 -1 
            651 2097 2099 653 -1 
            651 2097 2099 653 -1 
            651 652 653 -1 
            2097 2098 2099 -1 
            654 2100 2101 655 -1 
            656 2102 2101 655 -1 
            654 2100 2102 656 -1 
            654 2100 2102 656 -1 
            654 655 656 -1 
            2100 2101 2102 -1 
            657 2103 2104 658 -1 
            659 2105 2104 658 -1 
            657 2103 2105 659 -1 
            657 2103 2105 659 -1 
            657 658 659 -1 
            2103 2104 2105 -1 
            660 2106 2107 661 -1 
            662 2108 2107 661 -1 
            660 2106 2108 662 -1 
            660 2106 2108 662 -1 
            660 661 662 -1 
            2106 2107 2108 -1 
            663 2109 2110 664 -1 
            665 2111 2110 664 -1 
            663 2109 2111 665 -1 
            663 2109 2111 665 -1 
            663 664 665 -1 
            2109 2110 2111 -1 
            666 2112 2113 667 -1 
            668 2114 2113 667 -1 
            666 2112 2114 668 -1 
            666 2112 2114 668 -1 
            666 667 668 -1 
            2112 2113 2114 -1 
            669 2115 2116 670 -1 
            671 2117 2116 670 -1 
            669 2115 2117 671 -1 
            669 2115 2117 671 -1 
            669 670 671 -1 
            2115 2116 2117 -1 
            672 2118 2119 673 -1 
            674 2120 2119 673 -1 
            672 2118 2120 674 -1 
            672 2118 2120 674 -1 
            672 673 674 -1 
            2118 2119 2120 -1 
            675 2121 2122 676 -1 
            677 2123 2122 676 -1 
            675 2121 2123 677 -1 
            675 2121 2123 677 -1 
            675 676 677 -1 
            2121 2122 2123 -1 
            678 2124 2125 679 -1 
            680 2126 2125 679 -1 
            678 2124 2126 680 -1 
            678 2124 2126 680 -1 
            678 679 680 -1 
            2124 2125 2126 -1 
            681 2127 2128 682 -1 
            683 2129 2128 682 -1 
            681 2127 2129 683 -1 
            681 2127 2129 683 -1 
            681 682 683 -1 
            2127 2128 2129 -1 
            684 2130 2131 685 -1 
            686 2132 2131 685 -1 
            684 2130 2132 686 -1 
            684 2130 2132 686 -1 
            684 685 686 -1 
            2130 2131 2132 -1 
            687 2133 2134 688 -1 
            689 2135 2134 688 -1 
            687 2133 2135 689 -1 
            687 2133 2135 689 -1 
            687 688 689 -1 
            2133 2134 2135 -1 
            690 2136 2137 691 -1 
            692 2138 2137 691 -1 
            690 2136 2138 692 -1 
            690 2136 2138 692 -1 
            690 691 692 -1 
            2136 2137 2138 -1 
            693 2139 2140 694 -1 
            695 2141 2140 694 -1 
            693 2139 2141 695 -1 
            693 2139 2141 695 -1 
            693 694 695 -1 
            2139 2140 2141 -1 
            696 2142 2143 697 -1 
            698 2144 2143 697 -1 
            696 2142 2144 698 -1 
            696 2142 2144 698 -1 
            696 697 698 -1 
            2142 2143 2144 -1 
            699 2145 2146 700 -1 
            701 2147 2146 700 -1 
            699 2145 2147 701 -1 
            699 2145 2147 701 -1 
            699 700 701 -1 
            2145 2146 2147 -1 
            702 2148 2149 703 -1 
            704 2150 2149 703 -1 
            702 2148 2150 704 -1 
            702 2148 2150 704 -1 
            702 703 704 -1 
            2148 2149 2150 -1 
            705 2151 2152 706 -1 
            707 2153 2152 706 -1 
            705 2151 2153 707 -1 
            705 2151 2153 707 -1 
            705 706 707 -1 
            2151 2152 2153 -1 
            708 2154 2155 709 -1 
            710 2156 2155 709 -1 
            708 2154 2156 710 -1 
            708 2154 2156 710 -1 
            708 709 710 -1 
            2154 2155 2156 -1 
            711 2157 2158 712 -1 
            713 2159 2158 712 -1 
            711 2157 2159 713 -1 
            711 2157 2159 713 -1 
            711 712 713 -1 
            2157 2158 2159 -1 
            714 2160 2161 715 -1 
            716 2162 2161 715 -1 
            714 2160 2162 716 -1 
            714 2160 2162 716 -1 
            714 715 716 -1 
            2160 2161 2162 -1 
            717 2163 2164 718 -1 
            719 2165 2164 718 -1 
            717 2163 2165 719 -1 
            717 2163 2165 719 -1 
            717 718 719 -1 
            2163 2164 2165 -1 
            720 2166 2167 721 -1 
            722 2168 2167 721 -1 
            720 2166 2168 722 -1 
            720 2166 2168 722 -1 
            720 721 722 -1 
            2166 2167 2168 -1 
            723 2169 2170 724 -1 
            725 2171 2170 724 -1 
            723 2169 2171 725 -1 
            723 2169 2171 725 -1 
            723 724 725 -1 
            2169 2170 2171 -1 
            726 2172 2173 727 -1 
            728 2174 2173 727 -1 
            726 2172 2174 728 -1 
            726 2172 2174 728 -1 
            726 727 728 -1 
            2172 2173 2174 -1 
            729 2175 2176 730 -1 
            731 2177 2176 730 -1 
            729 2175 2177 731 -1 
            729 2175 2177 731 -1 
            729 730 731 -1 
            2175 2176 2177 -1 
            732 2178 2179 733 -1 
            734 2180 2179 733 -1 
            732 2178 2180 734 -1 
            732 2178 2180 734 -1 
            732 733 734 -1 
            2178 2179 2180 -1 
            735 2181 2182 736 -1 
            737 2183 2182 736 -1 
            735 2181 2183 737 -1 
            735 2181 2183 737 -1 
            735 736 737 -1 
            2181 2182 2183 -1 
            738 2184 2185 739 -1 
            740 2186 2185 739 -1 
            738 2184 2186 740 -1 
            738 2184 2186 740 -1 
            738 739 740 -1 
            2184 2185 2186 -1 
            741 2187 2188 742 -1 
            743 2189 2188 742 -1 
            741 2187 2189 743 -1 
            741 2187 2189 743 -1 
            741 742 743 -1 
            2187 2188 2189 -1 
            744 2190 2191 745 -1 
            746 2192 2191 745 -1 
            744 2190 2192 746 -1 
            744 2190 2192 746 -1 
            744 745 746 -1 
            2190 2191 2192 -1 
            747 2193 2194 748 -1 
            749 2195 2194 748 -1 
            747 2193 2195 749 -1 
            747 2193 2195 749 -1 
            747 748 749 -1 
            2193 2194 2195 -1 
            750 2196 2197 751 -1 
            752 2198 2197 751 -1 
            750 2196 2198 752 -1 
            750 2196 2198 752 -1 
            750 751 752 -1 
            2196 2197 2198 -1 
            753 2199 2200 754 -1 
            755 2201 2200 754 -1 
            753 2199 2201 755 -1 
            753 2199 2201 755 -1 
            753 754 755 -1 
            2199 2200 2201 -1 
            756 2202 2203 757 -1 
            758 2204 2203 757 -1 
            756 2202 2204 758 -1 
            756 2202 2204 758 -1 
            756 757 758 -1 
            2202 2203 2204 -1 
            759 2205 2206 760 -1 
            761 2207 2206 760 -1 
            759 2205 2207 761 -1 
            759 2205 2207 761 -1 
            759 760 761 -1 
            2205 2206 2207 -1 
            762 2208 2209 763 -1 
            764 2210 2209 763 -1 
            762 2208 2210 764 -1 
            762 2208 2210 764 -1 
            762 763 764 -1 
            2208 2209 2210 -1 
            765 2211 2212 766 -1 
            767 2213 2212 766 -1 
            765 2211 2213 767 -1 
            765 2211 2213 767 -1 
            765 766 767 -1 
            2211 2212 2213 -1 
            768 2214 2215 769 -1 
            770 2216 2215 769 -1 
            768 2214 2216 770 -1 
            768 2214 2216 770 -1 
            768 769 770 -1 
            2214 2215 2216 -1 
            771 2217 2218 772 -1 
            773 2219 2218 772 -1 
            771 2217 2219 773 -1 
            771 2217 2219 773 -1 
            771 772 773 -1 
            2217 2218 2219 -1 
            774 2220 2221 775 -1 
            776 2222 2221 775 -1 
            774 2220 2222 776 -1 
            774 2220 2222 776 -1 
            774 775 776 -1 
            2220 2221 2222 -1 
            777 2223 2224 778 -1 
            779 2225 2224 778 -1 
            777 2223 2225 779 -1 
            777 2223 2225 779 -1 
            777 778 779 -1 
            2223 2224 2225 -1 
            780 2226 2227 781 -1 
            782 2228 2227 781 -1 
            780 2226 2228 782 -1 
            780 2226 2228 782 -1 
            780 781 782 -1 
            2226 2227 2228 -1 
            783 2229 2230 784 -1 
            785 2231 2230 784 -1 
            783 2229 2231 785 -1 
            783 2229 2231 785 -1 
            783 784 785 -1 
            2229 2230 2231 -1 
            786 2232 2233 787 -1 
            788 2234 2233 787 -1 
            786 2232 2234 788 -1 
            786 2232 2234 788 -1 
            786 787 788 -1 
            2232 2233 2234 -1 
            789 2235 2236 790 -1 
            791 2237 2236 790 -1 
            789 2235 2237 791 -1 
            789 2235 2237 791 -1 
            789 790 791 -1 
            2235 2236 2237 -1 
            792 2238 2239 793 -1 
            794 2240 2239 793 -1 
            792 2238 2240 794 -1 
            792 2238 2240 794 -1 
            792 793 794 -1 
            2238 2239 2240 -1 
            795 2241 2242 796 -1 
            797 2243 2242 796 -1 
            795 2241 2243 797 -1 
            795 2241 2243 797 -1 
            795 796 797 -1 
            2241 2242 2243 -1 
            798 2244 2245 799 -1 
            800 2246 2245 799 -1 
            798 2244 2246 800 -1 
            798 2244 2246 800 -1 
            798 799 800 -1 
            2244 2245 2246 -1 
            801 2247 2248 802 -1 
            803 2249 2248 802 -1 
            801 2247 2249 803 -1 
            801 2247 2249 803 -1 
            801 802 803 -1 
            2247 2248 2249 -1 
            804 2250 2251 805 -1 
            806 2252 2251 805 -1 
            804 2250 2252 806 -1 
            804 2250 2252 806 -1 
            804 805 806 -1 
            2250 2251 2252 -1 
            807 2253 2254 808 -1 
            809 2255 2254 808 -1 
            807 2253 2255 809 -1 
            807 2253 2255 809 -1 
            807 808 809 -1 
            2253 2254 2255 -1 
            810 2256 2257 811 -1 
            812 2258 2257 811 -1 
            810 2256 2258 812 -1 
            810 2256 2258 812 -1 
            810 811 812 -1 
            2256 2257 2258 -1 
            813 2259 2260 814 -1 
            815 2261 2260 814 -1 
            813 2259 2261 815 -1 
            813 2259 2261 815 -1 
            813 814 815 -1 
            2259 2260 2261 -1 
            816 2262 2263 817 -1 
            818 2264 2263 817 -1 
            816 2262 2264 818 -1 
            816 2262 2264 818 -1 
            816 817 818 -1 
            2262 2263 2264 -1 
            819 2265 2266 820 -1 
            821 2267 2266 820 -1 
            819 2265 2267 821 -1 
            819 2265 2267 821 -1 
            819 820 821 -1 
            2265 2266 2267 -1 
            822 2268 2269 823 -1 
            824 2270 2269 823 -1 
            822 2268 2270 824 -1 
            822 2268 2270 824 -1 
            822 823 824 -1 
            2268 2269 2270 -1 
            825 2271 2272 826 -1 
            827 2273 2272 826 -1 
            825 2271 2273 827 -1 
            825 2271 2273 827 -1 
            825 826 827 -1 
            2271 2272 2273 -1 
            828 2274 2275 829 -1 
            830 2276 2275 829 -1 
            828 2274 2276 830 -1 
            828 2274 2276 830 -1 
            828 829 830 -1 
            2274 2275 2276 -1 
            831 2277 2278 832 -1 
            833 2279 2278 832 -1 
            831 2277 2279 833 -1 
            831 2277 2279 833 -1 
            831 832 833 -1 
            2277 2278 2279 -1 
            834 2280 2281 835 -1 
            836 2282 2281 835 -1 
            834 2280 2282 836 -1 
            834 2280 2282 836 -1 
            834 835 836 -1 
            2280 2281 2282 -1 
            837 2283 2284 838 -1 
            839 2285 2284 838 -1 
            837 2283 2285 839 -1 
            837 2283 2285 839 -1 
            837 838 839 -1 
            2283 2284 2285 -1 
            840 2286 2287 841 -1 
            842 2288 2287 841 -1 
            840 2286 2288 842 -1 
            840 2286 2288 842 -1 
            840 841 842 -1 
            2286 2287 2288 -1 
            843 2289 2290 844 -1 
            845 2291 2290 844 -1 
            843 2289 2291 845 -1 
            843 2289 2291 845 -1 
            843 844 845 -1 
            2289 2290 2291 -1 
            846 2292 2293 847 -1 
            848 2294 2293 847 -1 
            846 2292 2294 848 -1 
            846 2292 2294 848 -1 
            846 847 848 -1 
            2292 2293 2294 -1 
            849 2295 2296 850 -1 
            851 2297 2296 850 -1 
            849 2295 2297 851 -1 
            849 2295 2297 851 -1 
            849 850 851 -1 
            2295 2296 2297 -1 
            852 2298 2299 853 -1 
            854 2300 2299 853 -1 
            852 2298 2300 854 -1 
            852 2298 2300 854 -1 
            852 853 854 -1 
            2298 2299 2300 -1 
            855 2301 2302 856 -1 
            857 2303 2302 856 -1 
            855 2301 2303 857 -1 
            855 2301 2303 857 -1 
            855 856 857 -1 
            2301 2302 2303 -1 
            858 2304 2305 859 -1 
            860 2306 2305 859 -1 
            858 2304 2306 860 -1 
            858 2304 2306 860 -1 
            858 859 860 -1 
            2304 2305 2306 -1 
            861 2307 2308 862 -1 
            863 2309 2308 862 -1 
            861 2307 2309 863 -1 
            861 2307 2309 863 -1 
            861 862 863 -1 
            2307 2308 2309 -1 
            864 2310 2311 865 -1 
            866 2312 2311 865 -1 
            864 2310 2312 866 -1 
            864 2310 2312 866 -1 
            864 865 866 -1 
            2310 2311 2312 -1 
            867 2313 2314 868 -1 
            869 2315 2314 868 -1 
            867 2313 2315 869 -1 
            867 2313 2315 869 -1 
            867 868 869 -1 
            2313 2314 2315 -1 
            870 2316 2317 871 -1 
            872 2318 2317 871 -1 
            870 2316 2318 872 -1 
            870 2316 2318 872 -1 
            870 871 872 -1 
            2316 2317 2318 -1 
            873 2319 2320 874 -1 
            875 2321 2320 874 -1 
            873 2319 2321 875 -1 
            873 2319 2321 875 -1 
            873 874 875 -1 
            2319 2320 2321 -1 
            876 2322 2323 877 -1 
            878 2324 2323 877 -1 
            876 2322 2324 878 -1 
            876 2322 2324 878 -1 
            876 877 878 -1 
            2322 2323 2324 -1 
            879 2325 2326 880 -1 
            881 2327 2326 880 -1 
            879 2325 2327 881 -1 
            879 2325 2327 881 -1 
            879 880 881 -1 
            2325 2326 2327 -1 
            882 2328 2329 883 -1 
            884 2330 2329 883 -1 
            882 2328 2330 884 -1 
            882 2328 2330 884 -1 
            882 883 884 -1 
            2328 2329 2330 -1 
            885 2331 2332 886 -1 
            887 2333 2332 886 -1 
            885 2331 2333 887 -1 
            885 2331 2333 887 -1 
            885 886 887 -1 
            2331 2332 2333 -1 
            888 2334 2335 889 -1 
            890 2336 2335 889 -1 
            888 2334 2336 890 -1 
            888 2334 2336 890 -1 
            888 889 890 -1 
            2334 2335 2336 -1 
            891 2337 2338 892 -1 
            893 2339 2338 892 -1 
            891 2337 2339 893 -1 
            891 2337 2339 893 -1 
            891 892 893 -1 
            2337 2338 2339 -1 
            894 2340 2341 895 -1 
            896 2342 2341 895 -1 
            894 2340 2342 896 -1 
            894 2340 2342 896 -1 
            894 895 896 -1 
            2340 2341 2342 -1 
            897 2343 2344 898 -1 
            899 2345 2344 898 -1 
            897 2343 2345 899 -1 
            897 2343 2345 899 -1 
            897 898 899 -1 
            2343 2344 2345 -1 
            900 2346 2347 901 -1 
            902 2348 2347 901 -1 
            900 2346 2348 902 -1 
            900 2346 2348 902 -1 
            900 901 902 -1 
            2346 2347 2348 -1 
            903 2349 2350 904 -1 
            905 2351 2350 904 -1 
            903 2349 2351 905 -1 
            903 2349 2351 905 -1 
            903 904 905 -1 
            2349 2350 2351 -1 
            906 2352 2353 907 -1 
            908 2354 2353 907 -1 
            906 2352 2354 908 -1 
            906 2352 2354 908 -1 
            906 907 908 -1 
            2352 2353 2354 -1 
            909 2355 2356 910 -1 
            911 2357 2356 910 -1 
            909 2355 2357 911 -1 
            909 2355 2357 911 -1 
            909 910 911 -1 
            2355 2356 2357 -1 
            912 2358 2359 913 -1 
            914 2360 2359 913 -1 
            912 2358 2360 914 -1 
            912 2358 2360 914 -1 
            912 913 914 -1 
            2358 2359 2360 -1 
            915 2361 2362 916 -1 
            917 2363 2362 916 -1 
            915 2361 2363 917 -1 
            915 2361 2363 917 -1 
            915 916 917 -1 
            2361 2362 2363 -1 
            918 2364 2365 919 -1 
            920 2366 2365 919 -1 
            918 2364 2366 920 -1 
            918 2364 2366 920 -1 
            918 919 920 -1 
            2364 2365 2366 -1 
            921 2367 2368 922 -1 
            923 2369 2368 922 -1 
            921 2367 2369 923 -1 
            921 2367 2369 923 -1 
            921 922 923 -1 
            2367 2368 2369 -1 
            924 2370 2371 925 -1 
            926 2372 2371 925 -1 
            924 2370 2372 926 -1 
            924 2370 2372 926 -1 
            924 925 926 -1 
            2370 2371 2372 -1 
            927 2373 2374 928 -1 
            929 2375 2374 928 -1 
            927 2373 2375 929 -1 
            927 2373 2375 929 -1 
            927 928 929 -1 
            2373 2374 2375 -1 
            930 2376 2377 931 -1 
            932 2378 2377 931 -1 
            930 2376 2378 932 -1 
            930 2376 2378 932 -1 
            930 931 932 -1 
            2376 2377 2378 -1 
            933 2379 2380 934 -1 
            935 2381 2380 934 -1 
            933 2379 2381 935 -1 
            933 2379 2381 935 -1 
            933 934 935 -1 
            2379 2380 2381 -1 
            936 2382 2383 937 -1 
            938 2384 2383 937 -1 
            936 2382 2384 938 -1 
            936 2382 2384 938 -1 
            936 937 938 -1 
            2382 2383 2384 -1 
            939 2385 2386 940 -1 
            941 2387 2386 940 -1 
            939 2385 2387 941 -1 
            939 2385 2387 941 -1 
            939 940 941 -1 
            2385 2386 2387 -1 
            942 2388 2389 943 -1 
            944 2390 2389 943 -1 
            942 2388 2390 944 -1 
            942 2388 2390 944 -1 
            942 943 944 -1 
            2388 2389 2390 -1 
            945 2391 2392 946 -1 
            947 2393 2392 946 -1 
            945 2391 2393 947 -1 
            945 2391 2393 947 -1 
            945 946 947 -1 
            2391 2392 2393 -1 
            948 2394 2395 949 -1 
            950 2396 2395 949 -1 
            948 2394 2396 950 -1 
            948 2394 2396 950 -1 
            948 949 950 -1 
            2394 2395 2396 -1 
            951 2397 2398 952 -1 
            953 2399 2398 952 -1 
            951 2397 2399 953 -1 
            951 2397 2399 953 -1 
            951 952 953 -1 
            2397 2398 2399 -1 
            954 2400 2401 955 -1 
            956 2402 2401 955 -1 
            954 2400 2402 956 -1 
            954 2400 2402 956 -1 
            954 955 956 -1 
            2400 2401 2402 -1 
            957 2403 2404 958 -1 
            959 2405 2404 958 -1 
            957 2403 2405 959 -1 
            957 2403 2405 959 -1 
            957 958 959 -1 
            2403 2404 2405 -1 
            960 2406 2407 961 -1 
            962 2408 2407 961 -1 
            960 2406 2408 962 -1 
            960 2406 2408 962 -1 
            960 961 962 -1 
            2406 2407 2408 -1 
            963 2409 2410 964 -1 
            965 2411 2410 964 -1 
            963 2409 2411 965 -1 
            963 2409 2411 965 -1 
            963 964 965 -1 
            2409 2410 2411 -1 
            966 2412 2413 967 -1 
            968 2414 2413 967 -1 
            966 2412 2414 968 -1 
            966 2412 2414 968 -1 
            966 967 968 -1 
            2412 2413 2414 -1 
            969 2415 2416 970 -1 
            971 2417 2416 970 -1 
            969 2415 2417 971 -1 
            969 2415 2417 971 -1 
            969 970 971 -1 
            2415 2416 2417 -1 
            972 2418 2419 973 -1 
            974 2420 2419 973 -1 
            972 2418 2420 974 -1 
            972 2418 2420 974 -1 
            972 973 974 -1 
            2418 2419 2420 -1 
            975 2421 2422 976 -1 
            977 2423 2422 976 -1 
            975 2421 2423 977 -1 
            975 2421 2423 977 -1 
            975 976 977 -1 
            2421 2422 2423 -1 
            978 2424 2425 979 -1 
            980 2426 2425 979 -1 
            978 2424 2426 980 -1 
            978 2424 2426 980 -1 
            978 979 980 -1 
            2424 2425 2426 -1 
            981 2427 2428 982 -1 
            983 2429 2428 982 -1 
            981 2427 2429 983 -1 
            981 2427 2429 983 -1 
            981 982 983 -1 
            2427 2428 2429 -1 
            984 2430 2431 985 -1 
            986 2432 2431 985 -1 
            984 2430 2432 986 -1 
            984 2430 2432 986 -1 
            984 985 986 -1 
            2430 2431 2432 -1 
            987 2433 2434 988 -1 
            989 2435 2434 988 -1 
            987 2433 2435 989 -1 
            987 2433 2435 989 -1 
            987 988 989 -1 
            2433 2434 2435 -1 
            990 2436 2437 991 -1 
            992 2438 2437 991 -1 
            990 2436 2438 992 -1 
            990 2436 2438 992 -1 
            990 991 992 -1 
            2436 2437 2438 -1 
            993 2439 2440 994 -1 
            995 2441 2440 994 -1 
            993 2439 2441 995 -1 
            993 2439 2441 995 -1 
            993 994 995 -1 
            2439 2440 2441 -1 
            996 2442 2443 997 -1 
            998 2444 2443 997 -1 
            996 2442 2444 998 -1 
            996 2442 2444 998 -1 
            996 997 998 -1 
            2442 2443 2444 -1 
            999 2445 2446 1000 -1 
            1001 2447 2446 1000 -1 
            999 2445 2447 1001 -1 
            999 2445 2447 1001 -1 
            999 1000 1001 -1 
            2445 2446 2447 -1 
            1002 2448 2449 1003 -1 
            1004 2450 2449 1003 -1 
            1002 2448 2450 1004 -1 
            1002 2448 2450 1004 -1 
            1002 1003 1004 -1 
            2448 2449 2450 -1 
            1005 2451 2452 1006 -1 
            1007 2453 2452 1006 -1 
            1005 2451 2453 1007 -1 
            1005 2451 2453 1007 -1 
            1005 1006 1007 -1 
            2451 2452 2453 -1 
            1008 2454 2455 1009 -1 
            1010 2456 2455 1009 -1 
            1008 2454 2456 1010 -1 
            1008 2454 2456 1010 -1 
            1008 1009 1010 -1 
            2454 2455 2456 -1 
            1011 2457 2458 1012 -1 
            1013 2459 2458 1012 -1 
            1011 2457 2459 1013 -1 
            1011 2457 2459 1013 -1 
            1011 1012 1013 -1 
            2457 2458 2459 -1 
            1014 2460 2461 1015 -1 
            1016 2462 2461 1015 -1 
            1014 2460 2462 1016 -1 
            1014 2460 2462 1016 -1 
            1014 1015 1016 -1 
            2460 2461 2462 -1 
            1017 2463 2464 1018 -1 
            1019 2465 2464 1018 -1 
            1017 2463 2465 1019 -1 
            1017 2463 2465 1019 -1 
            1017 1018 1019 -1 
            2463 2464 2465 -1 
            1020 2466 2467 1021 -1 
            1022 2468 2467 1021 -1 
            1020 2466 2468 1022 -1 
            1020 2466 2468 1022 -1 
            1020 1021 1022 -1 
            2466 2467 2468 -1 
            1023 2469 2470 1024 -1 
            1025 2471 2470 1024 -1 
            1023 2469 2471 1025 -1 
            1023 2469 2471 1025 -1 
            1023 1024 1025 -1 
            2469 2470 2471 -1 
            1026 2472 2473 1027 -1 
            1028 2474 2473 1027 -1 
            1026 2472 2474 1028 -1 
            1026 2472 2474 1028 -1 
            1026 1027 1028 -1 
            2472 2473 2474 -1 
            1029 2475 2476 1030 -1 
            1031 2477 2476 1030 -1 
            1029 2475 2477 1031 -1 
            1029 2475 2477 1031 -1 
            1029 1030 1031 -1 
            2475 2476 2477 -1 
            1032 2478 2479 1033 -1 
            1034 2480 2479 1033 -1 
            1032 2478 2480 1034 -1 
            1032 2478 2480 1034 -1 
            1032 1033 1034 -1 
            2478 2479 2480 -1 
            1035 2481 2482 1036 -1 
            1037 2483 2482 1036 -1 
            1035 2481 2483 1037 -1 
            1035 2481 2483 1037 -1 
            1035 1036 1037 -1 
            2481 2482 2483 -1 
            1038 2484 2485 1039 -1 
            1040 2486 2485 1039 -1 
            1038 2484 2486 1040 -1 
            1038 2484 2486 1040 -1 
            1038 1039 1040 -1 
            2484 2485 2486 -1 
            1041 2487 2488 1042 -1 
            1043 2489 2488 1042 -1 
            1041 2487 2489 1043 -1 
            1041 2487 2489 1043 -1 
            1041 1042 1043 -1 
            2487 2488 2489 -1 
            1044 2490 2491 1045 -1 
            1046 2492 2491 1045 -1 
            1044 2490 2492 1046 -1 
            1044 2490 2492 1046 -1 
            1044 1045 1046 -1 
            2490 2491 2492 -1 
            1047 2493 2494 1048 -1 
            1049 2495 2494 1048 -1 
            1047 2493 2495 1049 -1 
            1047 2493 2495 1049 -1 
            1047 1048 1049 -1 
            2493 2494 2495 -1 
            1050 2496 2497 1051 -1 
            1052 2498 2497 1051 -1 
            1050 2496 2498 1052 -1 
            1050 2496 2498 1052 -1 
            1050 1051 1052 -1 
            2496 2497 2498 -1 
            1053 2499 2500 1054 -1 
            1055 2501 2500 1054 -1 
            1053 2499 2501 1055 -1 
            1053 2499 2501 1055 -1 
            1053 1054 1055 -1 
            2499 2500 2501 -1 
            1056 2502 2503 1057 -1 
            1058 2504 2503 1057 -1 
            1056 2502 2504 1058 -1 
            1056 2502 2504 1058 -1 
            1056 1057 1058 -1 
            2502 2503 2504 -1 
            1059 2505 2506 1060 -1 
            1061 2507 2506 1060 -1 
            1059 2505 2507 1061 -1 
            1059 2505 2507 1061 -1 
            1059 1060 1061 -1 
            2505 2506 2507 -1 
            1062 2508 2509 1063 -1 
            1064 2510 2509 1063 -1 
            1062 2508 2510 1064 -1 
            1062 2508 2510 1064 -1 
            1062 1063 1064 -1 
            2508 2509 2510 -1 
            1065 2511 2512 1066 -1 
            1067 2513 2512 1066 -1 
            1065 2511 2513 1067 -1 
            1065 2511 2513 1067 -1 
            1065 1066 1067 -1 
            2511 2512 2513 -1 
            1068 2514 2515 1069 -1 
            1070 2516 2515 1069 -1 
            1068 2514 2516 1070 -1 
            1068 2514 2516 1070 -1 
            1068 1069 1070 -1 
            2514 2515 2516 -1 
            1071 2517 2518 1072 -1 
            1073 2519 2518 1072 -1 
            1071 2517 2519 1073 -1 
            1071 2517 2519 1073 -1 
            1071 1072 1073 -1 
            2517 2518 2519 -1 
            1074 2520 2521 1075 -1 
            1076 2522 2521 1075 -1 
            1074 2520 2522 1076 -1 
            1074 2520 2522 1076 -1 
            1074 1075 1076 -1 
            2520 2521 2522 -1 
            1077 2523 2524 1078 -1 
            1079 2525 2524 1078 -1 
            1077 2523 2525 1079 -1 
            1077 2523 2525 1079 -1 
            1077 1078 1079 -1 
            2523 2524 2525 -1 
            1080 2526 2527 1081 -1 
            1082 2528 2527 1081 -1 
            1080 2526 2528 1082 -1 
            1080 2526 2528 1082 -1 
            1080 1081 1082 -1 
            2526 2527 2528 -1 
            1083 2529 2530 1084 -1 
            1085 2531 2530 1084 -1 
            1083 2529 2531 1085 -1 
            1083 2529 2531 1085 -1 
            1083 1084 1085 -1 
            2529 2530 2531 -1 
            1086 2532 2533 1087 -1 
            1088 2534 2533 1087 -1 
            1086 2532 2534 1088 -1 
            1086 2532 2534 1088 -1 
            1086 1087 1088 -1 
            2532 2533 2534 -1 
            1089 2535 2536 1090 -1 
            1091 2537 2536 1090 -1 
            1089 2535 2537 1091 -1 
            1089 2535 2537 1091 -1 
            1089 1090 1091 -1 
            2535 2536 2537 -1 
            1092 2538 2539 1093 -1 
            1094 2540 2539 1093 -1 
            1092 2538 2540 1094 -1 
            1092 2538 2540 1094 -1 
            1092 1093 1094 -1 
            2538 2539 2540 -1 
            1095 2541 2542 1096 -1 
            1097 2543 2542 1096 -1 
            1095 2541 2543 1097 -1 
            1095 2541 2543 1097 -1 
            1095 1096 1097 -1 
            2541 2542 2543 -1 
            1098 2544 2545 1099 -1 
            1100 2546 2545 1099 -1 
            1098 2544 2546 1100 -1 
            1098 2544 2546 1100 -1 
            1098 1099 1100 -1 
            2544 2545 2546 -1 
            1101 2547 2548 1102 -1 
            1103 2549 2548 1102 -1 
            1101 2547 2549 1103 -1 
            1101 2547 2549 1103 -1 
            1101 1102 1103 -1 
            2547 2548 2549 -1 
            1104 2550 2551 1105 -1 
            1106 2552 2551 1105 -1 
            1104 2550 2552 1106 -1 
            1104 2550 2552 1106 -1 
            1104 1105 1106 -1 
            2550 2551 2552 -1 
            1107 2553 2554 1108 -1 
            1109 2555 2554 1108 -1 
            1107 2553 2555 1109 -1 
            1107 2553 2555 1109 -1 
            1107 1108 1109 -1 
            2553 2554 2555 -1 
            1110 2556 2557 1111 -1 
            1112 2558 2557 1111 -1 
            1110 2556 2558 1112 -1 
            1110 2556 2558 1112 -1 
            1110 1111 1112 -1 
            2556 2557 2558 -1 
            1113 2559 2560 1114 -1 
            1115 2561 2560 1114 -1 
            1113 2559 2561 1115 -1 
            1113 2559 2561 1115 -1 
            1113 1114 1115 -1 
            2559 2560 2561 -1 
            1116 2562 2563 1117 -1 
            1118 2564 2563 1117 -1 
            1116 2562 2564 1118 -1 
            1116 2562 2564 1118 -1 
            1116 1117 1118 -1 
            2562 2563 2564 -1 
            1119 2565 2566 1120 -1 
            1121 2567 2566 1120 -1 
            1119 2565 2567 1121 -1 
            1119 2565 2567 1121 -1 
            1119 1120 1121 -1 
            2565 2566 2567 -1 
            1122 2568 2569 1123 -1 
            1124 2570 2569 1123 -1 
            1122 2568 2570 1124 -1 
            1122 2568 2570 1124 -1 
            1122 1123 1124 -1 
            2568 2569 2570 -1 
            1125 2571 2572 1126 -1 
            1127 2573 2572 1126 -1 
            1125 2571 2573 1127 -1 
            1125 2571 2573 1127 -1 
            1125 1126 1127 -1 
            2571 2572 2573 -1 
            1128 2574 2575 1129 -1 
            1130 2576 2575 1129 -1 
            1128 2574 2576 1130 -1 
            1128 2574 2576 1130 -1 
            1128 1129 1130 -1 
            2574 2575 2576 -1 
            1131 2577 2578 1132 -1 
            1133 2579 2578 1132 -1 
            1131 2577 2579 1133 -1 
            1131 2577 2579 1133 -1 
            1131 1132 1133 -1 
            2577 2578 2579 -1 
            1134 2580 2581 1135 -1 
            1136 2582 2581 1135 -1 
            1134 2580 2582 1136 -1 
            1134 2580 2582 1136 -1 
            1134 1135 1136 -1 
            2580 2581 2582 -1 
            1137 2583 2584 1138 -1 
            1139 2585 2584 1138 -1 
            1137 2583 2585 1139 -1 
            1137 2583 2585 1139 -1 
            1137 1138 1139 -1 
            2583 2584 2585 -1 
            1140 2586 2587 1141 -1 
            1142 2588 2587 1141 -1 
            1140 2586 2588 1142 -1 
            1140 2586 2588 1142 -1 
            1140 1141 1142 -1 
            2586 2587 2588 -1 
            1143 2589 2590 1144 -1 
            1145 2591 2590 1144 -1 
            1143 2589 2591 1145 -1 
            1143 2589 2591 1145 -1 
            1143 1144 1145 -1 
            2589 2590 2591 -1 
            1146 2592 2593 1147 -1 
            1148 2594 2593 1147 -1 
            1146 2592 2594 1148 -1 
            1146 2592 2594 1148 -1 
            1146 1147 1148 -1 
            2592 2593 2594 -1 
            1149 2595 2596 1150 -1 
            1151 2597 2596 1150 -1 
            1149 2595 2597 1151 -1 
            1149 2595 2597 1151 -1 
            1149 1150 1151 -1 
            2595 2596 2597 -1 
            1152 2598 2599 1153 -1 
            1154 2600 2599 1153 -1 
            1152 2598 2600 1154 -1 
            1152 2598 2600 1154 -1 
            1152 1153 1154 -1 
            2598 2599 2600 -1 
            1155 2601 2602 1156 -1 
            1157 2603 2602 1156 -1 
            1155 2601 2603 1157 -1 
            1155 2601 2603 1157 -1 
            1155 1156 1157 -1 
            2601 2602 2603 -1 
            1158 2604 2605 1159 -1 
            1160 2606 2605 1159 -1 
            1158 2604 2606 1160 -1 
            1158 2604 2606 1160 -1 
            1158 1159 1160 -1 
            2604 2605 2606 -1 
            1161 2607 2608 1162 -1 
            1163 2609 2608 1162 -1 
            1161 2607 2609 1163 -1 
            1161 2607 2609 1163 -1 
            1161 1162 1163 -1 
            2607 2608 2609 -1 
            1164 2610 2611 1165 -1 
            1166 2612 2611 1165 -1 
            1164 2610 2612 1166 -1 
            1164 2610 2612 1166 -1 
            1164 1165 1166 -1 
            2610 2611 2612 -1 
            1167 2613 2614 1168 -1 
            1169 2615 2614 1168 -1 
            1167 2613 2615 1169 -1 
            1167 2613 2615 1169 -1 
            1167 1168 1169 -1 
            2613 2614 2615 -1 
            1170 2616 2617 1171 -1 
            1172 2618 2617 1171 -1 
            1170 2616 2618 1172 -1 
            1170 2616 2618 1172 -1 
            1170 1171 1172 -1 
            2616 2617 2618 -1 
            1173 2619 2620 1174 -1 
            1175 2621 2620 1174 -1 
            1173 2619 2621 1175 -1 
            1173 2619 2621 1175 -1 
            1173 1174 1175 -1 
            2619 2620 2621 -1 
            1176 2622 2623 1177 -1 
            1178 2624 2623 1177 -1 
            1176 2622 2624 1178 -1 
            1176 2622 2624 1178 -1 
            1176 1177 1178 -1 
            2622 2623 2624 -1 
            1179 2625 2626 1180 -1 
            1181 2627 2626 1180 -1 
            1179 2625 2627 1181 -1 
            1179 2625 2627 1181 -1 
            1179 1180 1181 -1 
            2625 2626 2627 -1 
            1182 2628 2629 1183 -1 
            1184 2630 2629 1183 -1 
            1182 2628 2630 1184 -1 
            1182 2628 2630 1184 -1 
            1182 1183 1184 -1 
            2628 2629 2630 -1 
            1185 2631 2632 1186 -1 
            1187 2633 2632 1186 -1 
            1185 2631 2633 1187 -1 
            1185 2631 2633 1187 -1 
            1185 1186 1187 -1 
            2631 2632 2633 -1 
            1188 2634 2635 1189 -1 
            1190 2636 2635 1189 -1 
            1188 2634 2636 1190 -1 
            1188 2634 2636 1190 -1 
            1188 1189 1190 -1 
            2634 2635 2636 -1 
            1191 2637 2638 1192 -1 
            1193 2639 2638 1192 -1 
            1191 2637 2639 1193 -1 
            1191 2637 2639 1193 -1 
            1191 1192 1193 -1 
            2637 2638 2639 -1 
            1194 2640 2641 1195 -1 
            1196 2642 2641 1195 -1 
            1194 2640 2642 1196 -1 
            1194 2640 2642 1196 -1 
            1194 1195 1196 -1 
            2640 2641 2642 -1 
            1197 2643 2644 1198 -1 
            1199 2645 2644 1198 -1 
            1197 2643 2645 1199 -1 
            1197 2643 2645 1199 -1 
            1197 1198 1199 -1 
            2643 2644 2645 -1 
            1200 2646 2647 1201 -1 
            1202 2648 2647 1201 -1 
            1200 2646 2648 1202 -1 
            1200 2646 2648 1202 -1 
            1200 1201 1202 -1 
            2646 2647 2648 -1 
            1203 2649 2650 1204 -1 
            1205 2651 2650 1204 -1 
            1203 2649 2651 1205 -1 
            1203 2649 2651 1205 -1 
            1203 1204 1205 -1 
            2649 2650 2651 -1 
            1206 2652 2653 1207 -1 
            1208 2654 2653 1207 -1 
            1206 2652 2654 1208 -1 
            1206 2652 2654 1208 -1 
            1206 1207 1208 -1 
            2652 2653 2654 -1 
            1209 2655 2656 1210 -1 
            1211 2657 2656 1210 -1 
            1209 2655 2657 1211 -1 
            1209 2655 2657 1211 -1 
            1209 1210 1211 -1 
            2655 2656 2657 -1 
            1212 2658 2659 1213 -1 
            1214 2660 2659 1213 -1 
            1212 2658 2660 1214 -1 
            1212 2658 2660 1214 -1 
            1212 1213 1214 -1 
            2658 2659 2660 -1 
            1215 2661 2662 1216 -1 
            1217 2663 2662 1216 -1 
            1215 2661 2663 1217 -1 
            1215 2661 2663 1217 -1 
            1215 1216 1217 -1 
            2661 2662 2663 -1 
            1218 2664 2665 1219 -1 
            1220 2666 2665 1219 -1 
            1218 2664 2666 1220 -1 
            1218 2664 2666 1220 -1 
            1218 1219 1220 -1 
            2664 2665 2666 -1 
            1221 2667 2668 1222 -1 
            1223 2669 2668 1222 -1 
            1221 2667 2669 1223 -1 
            1221 2667 2669 1223 -1 
            1221 1222 1223 -1 
            2667 2668 2669 -1 
            1224 2670 2671 1225 -1 
            1226 2672 2671 1225 -1 
            1224 2670 2672 1226 -1 
            1224 2670 2672 1226 -1 
            1224 1225 1226 -1 
            2670 2671 2672 -1 
            1227 2673 2674 1228 -1 
            1229 2675 2674 1228 -1 
            1227 2673 2675 1229 -1 
            1227 2673 2675 1229 -1 
            1227 1228 1229 -1 
            2673 2674 2675 -1 
            1230 2676 2677 1231 -1 
            1232 2678 2677 1231 -1 
            1230 2676 2678 1232 -1 
            1230 2676 2678 1232 -1 
            1230 1231 1232 -1 
            2676 2677 2678 -1 
            1233 2679 2680 1234 -1 
            1235 2681 2680 1234 -1 
            1233 2679 2681 1235 -1 
            1233 2679 2681 1235 -1 
            1233 1234 1235 -1 
            2679 2680 2681 -1 
            1236 2682 2683 1237 -1 
            1238 2684 2683 1237 -1 
            1236 2682 2684 1238 -1 
            1236 2682 2684 1238 -1 
            1236 1237 1238 -1 
            2682 2683 2684 -1 
            1239 2685 2686 1240 -1 
            1241 2687 2686 1240 -1 
            1239 2685 2687 1241 -1 
            1239 2685 2687 1241 -1 
            1239 1240 1241 -1 
            2685 2686 2687 -1 
            1242 2688 2689 1243 -1 
            1244 2690 2689 1243 -1 
            1242 2688 2690 1244 -1 
            1242 2688 2690 1244 -1 
            1242 1243 1244 -1 
            2688 2689 2690 -1 
            1245 2691 2692 1246 -1 
            1247 2693 2692 1246 -1 
            1245 2691 2693 1247 -1 
            1245 2691 2693 1247 -1 
            1245 1246 1247 -1 
            2691 2692 2693 -1 
            1248 2694 2695 1249 -1 
            1250 2696 2695 1249 -1 
            1248 2694 2696 1250 -1 
            1248 2694 2696 1250 -1 
            1248 1249 1250 -1 
            2694 2695 2696 -1 
            1251 2697 2698 1252 -1 
            1253 2699 2698 1252 -1 
            1251 2697 2699 1253 -1 
            1251 2697 2699 1253 -1 
            1251 1252 1253 -1 
            2697 2698 2699 -1 
            1254 2700 2701 1255 -1 
            1256 2702 2701 1255 -1 
            1254 2700 2702 1256 -1 
            1254 2700 2702 1256 -1 
            1254 1255 1256 -1 
            2700 2701 2702 -1 
            1257 2703 2704 1258 -1 
            1259 2705 2704 1258 -1 
            1257 2703 2705 1259 -1 
            1257 2703 2705 1259 -1 
            1257 1258 1259 -1 
            2703 2704 2705 -1 
            1260 2706 2707 1261 -1 
            1262 2708 2707 1261 -1 
            1260 2706 2708 1262 -1 
            1260 2706 2708 1262 -1 
            1260 1261 1262 -1 
            2706 2707 2708 -1 
            1263 2709 2710 1264 -1 
            1265 2711 2710 1264 -1 
            1263 2709 2711 1265 -1 
            1263 2709 2711 1265 -1 
            1263 1264 1265 -1 
            2709 2710 2711 -1 
            1266 2712 2713 1267 -1 
            1268 2714 2713 1267 -1 
            1266 2712 2714 1268 -1 
            1266 2712 2714 1268 -1 
            1266 1267 1268 -1 
            2712 2713 2714 -1 
            1269 2715 2716 1270 -1 
            1271 2717 2716 1270 -1 
            1269 2715 2717 1271 -1 
            1269 2715 2717 1271 -1 
            1269 1270 1271 -1 
            2715 2716 2717 -1 
            1272 2718 2719 1273 -1 
            1274 2720 2719 1273 -1 
            1272 2718 2720 1274 -1 
            1272 2718 2720 1274 -1 
            1272 1273 1274 -1 
            2718 2719 2720 -1 
            1275 2721 2722 1276 -1 
            1277 2723 2722 1276 -1 
            1275 2721 2723 1277 -1 
            1275 2721 2723 1277 -1 
            1275 1276 1277 -1 
            2721 2722 2723 -1 
            1278 2724 2725 1279 -1 
            1280 2726 2725 1279 -1 
            1278 2724 2726 1280 -1 
            1278 2724 2726 1280 -1 
            1278 1279 1280 -1 
            2724 2725 2726 -1 
            1281 2727 2728 1282 -1 
            1283 2729 2728 1282 -1 
            1281 2727 2729 1283 -1 
            1281 2727 2729 1283 -1 
            1281 1282 1283 -1 
            2727 2728 2729 -1 
            1284 2730 2731 1285 -1 
            1286 2732 2731 1285 -1 
            1284 2730 2732 1286 -1 
            1284 2730 2732 1286 -1 
            1284 1285 1286 -1 
            2730 2731 2732 -1 
            1287 2733 2734 1288 -1 
            1289 2735 2734 1288 -1 
            1287 2733 2735 1289 -1 
            1287 2733 2735 1289 -1 
            1287 1288 1289 -1 
            2733 2734 2735 -1 
            1290 2736 2737 1291 -1 
            1292 2738 2737 1291 -1 
            1290 2736 2738 1292 -1 
            1290 2736 2738 1292 -1 
            1290 1291 1292 -1 
            2736 2737 2738 -1 
            1293 2739 2740 1294 -1 
            1295 2741 2740 1294 -1 
            1293 2739 2741 1295 -1 
            1293 2739 2741 1295 -1 
            1293 1294 1295 -1 
            2739 2740 2741 -1 
            1296 2742 2743 1297 -1 
            1298 2744 2743 1297 -1 
            1296 2742 2744 1298 -1 
            1296 2742 2744 1298 -1 
            1296 1297 1298 -1 
            2742 2743 2744 -1 
            1299 2745 2746 1300 -1 
            1301 2747 2746 1300 -1 
            1299 2745 2747 1301 -1 
            1299 2745 2747 1301 -1 
            1299 1300 1301 -1 
            2745 2746 2747 -1 
            1302 2748 2749 1303 -1 
            1304 2750 2749 1303 -1 
            1302 2748 2750 1304 -1 
            1302 2748 2750 1304 -1 
            1302 1303 1304 -1 
            2748 2749 2750 -1 
            1305 2751 2752 1306 -1 
            1307 2753 2752 1306 -1 
            1305 2751 2753 1307 -1 
            1305 2751 2753 1307 -1 
            1305 1306 1307 -1 
            2751 2752 2753 -1 
            1308 2754 2755 1309 -1 
            1310 2756 2755 1309 -1 
            1308 2754 2756 1310 -1 
            1308 2754 2756 1310 -1 
            1308 1309 1310 -1 
            2754 2755 2756 -1 
            1311 2757 2758 1312 -1 
            1313 2759 2758 1312 -1 
            1311 2757 2759 1313 -1 
            1311 2757 2759 1313 -1 
            1311 1312 1313 -1 
            2757 2758 2759 -1 
            1314 2760 2761 1315 -1 
            1316 2762 2761 1315 -1 
            1314 2760 2762 1316 -1 
            1314 2760 2762 1316 -1 
            1314 1315 1316 -1 
            2760 2761 2762 -1 
            1317 2763 2764 1318 -1 
            1319 2765 2764 1318 -1 
            1317 2763 2765 1319 -1 
            1317 2763 2765 1319 -1 
            1317 1318 1319 -1 
            2763 2764 2765 -1 
            1320 2766 2767 1321 -1 
            1322 2768 2767 1321 -1 
            1320 2766 2768 1322 -1 
            1320 2766 2768 1322 -1 
            1320 1321 1322 -1 
            2766 2767 2768 -1 
            1323 2769 2770 1324 -1 
            1325 2771 2770 1324 -1 
            1323 2769 2771 1325 -1 
            1323 2769 2771 1325 -1 
            1323 1324 1325 -1 
            2769 2770 2771 -1 
            1326 2772 2773 1327 -1 
            1328 2774 2773 1327 -1 
            1326 2772 2774 1328 -1 
            1326 2772 2774 1328 -1 
            1326 1327 1328 -1 
            2772 2773 2774 -1 
            1329 2775 2776 1330 -1 
            1331 2777 2776 1330 -1 
            1329 2775 2777 1331 -1 
            1329 2775 2777 1331 -1 
            1329 1330 1331 -1 
            2775 2776 2777 -1 
            1332 2778 2779 1333 -1 
            1334 2780 2779 1333 -1 
            1332 2778 2780 1334 -1 
            1332 2778 2780 1334 -1 
            1332 1333 1334 -1 
            2778 2779 2780 -1 
            1335 2781 2782 1336 -1 
            1337 2783 2782 1336 -1 
            1335 2781 2783 1337 -1 
            1335 2781 2783 1337 -1 
            1335 1336 1337 -1 
            2781 2782 2783 -1 
            1338 2784 2785 1339 -1 
            1340 2786 2785 1339 -1 
            1338 2784 2786 1340 -1 
            1338 2784 2786 1340 -1 
            1338 1339 1340 -1 
            2784 2785 2786 -1 
            1341 2787 2788 1342 -1 
            1343 2789 2788 1342 -1 
            1341 2787 2789 1343 -1 
            1341 2787 2789 1343 -1 
            1341 1342 1343 -1 
            2787 2788 2789 -1 
            1344 2790 2791 1345 -1 
            1346 2792 2791 1345 -1 
            1344 2790 2792 1346 -1 
            1344 2790 2792 1346 -1 
            1344 1345 1346 -1 
            2790 2791 2792 -1 
            1347 2793 2794 1348 -1 
            1349 2795 2794 1348 -1 
            1347 2793 2795 1349 -1 
            1347 2793 2795 1349 -1 
            1347 1348 1349 -1 
            2793 2794 2795 -1 
            1350 2796 2797 1351 -1 
            1352 2798 2797 1351 -1 
            1350 2796 2798 1352 -1 
            1350 2796 2798 1352 -1 
            1350 1351 1352 -1 
            2796 2797 2798 -1 
            1353 2799 2800 1354 -1 
            1355 2801 2800 1354 -1 
            1353 2799 2801 1355 -1 
            1353 2799 2801 1355 -1 
            1353 1354 1355 -1 
            2799 2800 2801 -1 
            1356 2802 2803 1357 -1 
            1358 2804 2803 1357 -1 
            1356 2802 2804 1358 -1 
            1356 2802 2804 1358 -1 
            1356 1357 1358 -1 
            2802 2803 2804 -1 
            1359 2805 2806 1360 -1 
            1361 2807 2806 1360 -1 
            1359 2805 2807 1361 -1 
            1359 2805 2807 1361 -1 
            1359 1360 1361 -1 
            2805 2806 2807 -1 
            1362 2808 2809 1363 -1 
            1364 2810 2809 1363 -1 
            1362 2808 2810 1364 -1 
            1362 2808 2810 1364 -1 
            1362 1363 1364 -1 
            2808 2809 2810 -1 
            1365 2811 2812 1366 -1 
            1367 2813 2812 1366 -1 
            1365 2811 2813 1367 -1 
            1365 2811 2813 1367 -1 
            1365 1366 1367 -1 
            2811 2812 2813 -1 
            1368 2814 2815 1369 -1 
            1370 2816 2815 1369 -1 
            1368 2814 2816 1370 -1 
            1368 2814 2816 1370 -1 
            1368 1369 1370 -1 
            2814 2815 2816 -1 
            1371 2817 2818 1372 -1 
            1373 2819 2818 1372 -1 
            1371 2817 2819 1373 -1 
            1371 2817 2819 1373 -1 
            1371 1372 1373 -1 
            2817 2818 2819 -1 
            1374 2820 2821 1375 -1 
            1376 2822 2821 1375 -1 
            1374 2820 2822 1376 -1 
            1374 2820 2822 1376 -1 
            1374 1375 1376 -1 
            2820 2821 2822 -1 
            1377 2823 2824 1378 -1 
            1379 2825 2824 1378 -1 
            1377 2823 2825 1379 -1 
            1377 2823 2825 1379 -1 
            1377 1378 1379 -1 
            2823 2824 2825 -1 
            1380 2826 2827 1381 -1 
            1382 2828 2827 1381 -1 
            1380 2826 2828 1382 -1 
            1380 2826 2828 1382 -1 
            1380 1381 1382 -1 
            2826 2827 2828 -1 
            1383 2829 2830 1384 -1 
            1385 2831 2830 1384 -1 
            1383 2829 2831 1385 -1 
            1383 2829 2831 1385 -1 
            1383 1384 1385 -1 
            2829 2830 2831 -1 
            1386 2832 2833 1387 -1 
            1388 2834 2833 1387 -1 
            1386 2832 2834 1388 -1 
            1386 2832 2834 1388 -1 
            1386 1387 1388 -1 
            2832 2833 2834 -1 
            1389 2835 2836 1390 -1 
            1391 2837 2836 1390 -1 
            1389 2835 2837 1391 -1 
            1389 2835 2837 1391 -1 
            1389 1390 1391 -1 
            2835 2836 2837 -1 
            1392 2838 2839 1393 -1 
            1394 2840 2839 1393 -1 
            1392 2838 2840 1394 -1 
            1392 2838 2840 1394 -1 
            1392 1393 1394 -1 
            2838 2839 2840 -1 
            1395 2841 2842 1396 -1 
            1397 2843 2842 1396 -1 
            1395 2841 2843 1397 -1 
            1395 2841 2843 1397 -1 
            1395 1396 1397 -1 
            2841 2842 2843 -1 
            1398 2844 2845 1399 -1 
            1400 2846 2845 1399 -1 
            1398 2844 2846 1400 -1 
            1398 2844 2846 1400 -1 
            1398 1399 1400 -1 
            2844 2845 2846 -1 
            1401 2847 2848 1402 -1 
            1403 2849 2848 1402 -1 
            1401 2847 2849 1403 -1 
            1401 2847 2849 1403 -1 
            1401 1402 1403 -1 
            2847 2848 2849 -1 
            1404 2850 2851 1405 -1 
            1406 2852 2851 1405 -1 
            1404 2850 2852 1406 -1 
            1404 2850 2852 1406 -1 
            1404 1405 1406 -1 
            2850 2851 2852 -1 
            1407 2853 2854 1408 -1 
            1409 2855 2854 1408 -1 
            1407 2853 2855 1409 -1 
            1407 2853 2855 1409 -1 
            1407 1408 1409 -1 
            2853 2854 2855 -1 
            1410 2856 2857 1411 -1 
            1412 2858 2857 1411 -1 
            1410 2856 2858 1412 -1 
            1410 2856 2858 1412 -1 
            1410 1411 1412 -1 
            2856 2857 2858 -1 
            1413 2859 2860 1414 -1 
            1415 2861 2860 1414 -1 
            1413 2859 2861 1415 -1 
            1413 2859 2861 1415 -1 
            1413 1414 1415 -1 
            2859 2860 2861 -1 
            1416 2862 2863 1417 -1 
            1418 2864 2863 1417 -1 
            1416 2862 2864 1418 -1 
            1416 2862 2864 1418 -1 
            1416 1417 1418 -1 
            2862 2863 2864 -1 
            1419 2865 2866 1420 -1 
            1421 2867 2866 1420 -1 
            1419 2865 2867 1421 -1 
            1419 2865 2867 1421 -1 
            1419 1420 1421 -1 
            2865 2866 2867 -1 
            1422 2868 2869 1423 -1 
            1424 2870 2869 1423 -1 
            1422 2868 2870 1424 -1 
            1422 2868 2870 1424 -1 
            1422 1423 1424 -1 
            2868 2869 2870 -1 
            1425 2871 2872 1426 -1 
            1427 2873 2872 1426 -1 
            1425 2871 2873 1427 -1 
            1425 2871 2873 1427 -1 
            1425 1426 1427 -1 
            2871 2872 2873 -1 
            1428 2874 2875 1429 -1 
            1430 2876 2875 1429 -1 
            1428 2874 2876 1430 -1 
            1428 2874 2876 1430 -1 
            1428 1429 1430 -1 
            2874 2875 2876 -1 
            1431 2877 2878 1432 -1 
            1433 2879 2878 1432 -1 
            1431 2877 2879 1433 -1 
            1431 2877 2879 1433 -1 
            1431 1432 1433 -1 
            2877 2878 2879 -1 
            1434 2880 2881 1435 -1 
            1436 2882 2881 1435 -1 
            1434 2880 2882 1436 -1 
            1434 2880 2882 1436 -1 
            1434 1435 1436 -1 
            2880 2881 2882 -1 
            1437 2883 2884 1438 -1 
            1439 2885 2884 1438 -1 
            1437 2883 2885 1439 -1 
            1437 2883 2885 1439 -1 
            1437 1438 1439 -1 
            2883 2884 2885 -1 
            1440 2886 2887 1441 -1 
            1442 2888 2887 1441 -1 
            1440 2886 2888 1442 -1 
            1440 2886 2888 1442 -1 
            1440 1441 1442 -1 
            2886 2887 2888 -1 
            1443 2889 2890 1444 -1 
            1445 2891 2890 1444 -1 
            1443 2889 2891 1445 -1 
            1443 2889 2891 1445 -1 
            1443 1444 1445 -1 
            2889 2890 2891 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0002"
              point="
              5.73275000000000000e+04 4.55720999999999985e+04 -1.54989902785930235e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.54989902785930235e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.54989902785930235e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -6.37915474587697759e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.37915474587697759e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -6.37915474587697759e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.04780239737094121e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.04780239737094121e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.04780239737094121e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.55375960112860594e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.55375960112860594e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.55375960112860594e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.14890111425296118e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.14890111425296118e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.14890111425296118e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.24389535015825845e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.24389535015825845e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.24389535015825845e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.47590422769903307e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.47590422769903307e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.47590422769903307e+03,
              4.51237802144240559e+04 5.33675903752420927e+04 -8.62141801960242674e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.62141801960242674e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -8.62141801960242674e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.09047261461959283e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.09047261461959283e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.09047261461959283e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.59718813948743127e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.59718813948743127e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.59718813948743127e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.58035137888453301e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.58035137888453301e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.58035137888453301e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.14818589255428446e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.14818589255428446e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.14818589255428446e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.04006446005133421e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.04006446005133421e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -1.04006446005133421e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.61166239576147336e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.61166239576147336e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.61166239576147336e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.11386646080834225e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.11386646080834225e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.11386646080834225e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.51670919908269707e+03,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.51670919908269707e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.51670919908269707e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.53445382971280878e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.53445382971280878e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.53445382971280878e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.39122720749252926e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.39122720749252926e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.39122720749252926e+03,
              4.51237802144240559e+04 5.33675903752420927e+04 -8.31306244081998102e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 -8.31306244081998102e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -8.31306244081998102e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.50175125037945872e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.50175125037945872e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.50175125037945872e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.59711834103915862e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.59711834103915862e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.59711834103915862e+03,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.15626161052963926e+03,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.15626161052963926e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.15626161052963926e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.32679009775515283e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.32679009775515283e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.32679009775515283e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.56671379156926923e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.56671379156926923e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.56671379156926923e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.10320744533348943e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -9.10320744533348943e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -9.10320744533348943e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.43355560665987173e+03,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.43355560665987173e+03,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.43355560665987173e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.47103884896571253e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.47103884896571253e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.47103884896571253e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.37737143031206733e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.37737143031206733e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.37737143031206733e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.36778279519609259e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.36778279519609259e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.36778279519609259e+03,
              4.29915000000000000e+04 4.35240999999999985e+04 -1.13908722694784456e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.13908722694784456e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.13908722694784456e+03,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.92218095587967014e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 -9.92218095587967014e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 -9.92218095587967014e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.61064224971352724e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.61064224971352724e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.61064224971352724e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.37947750177749435e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.37947750177749435e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.37947750177749435e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.62269336471706879e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.62269336471706879e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.62269336471706879e+03,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.36965115944821150e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 -6.36965115944821150e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.36965115944821150e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.76365654535559543e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 -9.76365654535559543e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.76365654535559543e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.05561085119020549e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.05561085119020549e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.05561085119020549e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.20659823182302716e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.20659823182302716e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -9.20659823182302716e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.17891137577845711e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -9.17891137577845711e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.17891137577845711e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.67571184066967135e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -8.67571184066967135e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.67571184066967135e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.05759665847356587e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.05759665847356587e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.05759665847356587e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -7.25505900948060798e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.25505900948060798e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -7.25505900948060798e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.10355853236112921e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.10355853236112921e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.10355853236112921e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.42126969679353579e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.42126969679353579e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.42126969679353579e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.03119104663403664e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.03119104663403664e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -1.03119104663403664e+03,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.74420964540049681e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -7.74420964540049681e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -7.74420964540049681e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.07538072020943537e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.07538072020943537e+03,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.07538072020943537e+03,
              4.29915000000000000e+04 5.17160999999999985e+04 -9.42231932448533144e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.42231932448533144e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 -9.42231932448533144e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.44182194990245080e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.44182194990245080e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.44182194990245080e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.09473186162482648e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.09473186162482648e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.09473186162482648e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.62738005578175262e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -8.62738005578175262e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.62738005578175262e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.74768076201346048e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.74768076201346048e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.74768076201346048e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.25451233617229764e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.25451233617229764e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.25451233617229764e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -7.46474058891736490e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -7.46474058891736490e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -7.46474058891736490e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.51029062173571833e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.51029062173571833e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.51029062173571833e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.86989455860936573e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -8.86989455860936573e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -8.86989455860936573e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.55568592138692884e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.55568592138692884e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.55568592138692884e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.45226636298966673e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.45226636298966673e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.45226636298966673e+03,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.24313400368322164e+03,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.24313400368322164e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.24313400368322164e+03,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.61463790362102486e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.61463790362102486e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -6.61463790362102486e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -2.77450958665124972e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.77450958665124972e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -2.77450958665124972e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.61204532756237995e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.61204532756237995e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.61204532756237995e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.15909906536080712e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.15909906536080712e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.15909906536080712e+03,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.29617726578382644e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.29617726578382644e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.29617726578382644e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.38204538820663538e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.38204538820663538e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.38204538820663538e+03,
              4.29915000000000000e+04 6.68746000000000058e+04 -4.46191524702484116e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -4.46191524702484116e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.46191524702484116e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -7.37660055255678799e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -7.37660055255678799e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -7.37660055255678799e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -6.06002076377194385e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.06002076377194385e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.06002076377194385e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -6.03253248269489745e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -6.03253248269489745e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.03253248269489745e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -8.56781490203755993e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -8.56781490203755993e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -8.56781490203755993e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -8.51517486308060143e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -8.51517486308060143e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.51517486308060143e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.63190561522233907e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -6.63190561522233907e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.63190561522233907e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.18907693536632109e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.18907693536632109e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.18907693536632109e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -5.70552429577283533e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.70552429577283533e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.70552429577283533e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.30872309089060536e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.30872309089060536e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.30872309089060536e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.39764465349409511e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.39764465349409511e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.39764465349409511e+03,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.05289347604601403e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.05289347604601403e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -3.05289347604601403e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -5.73268602176656714e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.73268602176656714e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.73268602176656714e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.76497572638319298e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -6.76497572638319298e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -6.76497572638319298e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -5.10421504239243859e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.10421504239243859e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.10421504239243859e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.20916253045452549e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.20916253045452549e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -5.20916253045452549e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 -8.00345891681496141e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -8.00345891681496141e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.00345891681496141e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -5.34489832969704025e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -5.34489832969704025e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.34489832969704025e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -5.79094711490698273e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -5.79094711490698273e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.79094711490698273e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.96960041531049626e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -4.96960041531049626e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.96960041531049626e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -8.04207439423549886e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -8.04207439423549886e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -8.04207439423549886e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -2.35446135549918040e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 -2.35446135549918040e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.35446135549918040e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -3.78687850032053120e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.78687850032053120e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.78687850032053120e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.60407700415179988e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.60407700415179988e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.60407700415179988e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.39785070382808499e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -5.39785070382808499e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.39785070382808499e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.41804810611357539e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.41804810611357539e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.41804810611357539e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.51372170900116566e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.51372170900116566e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.51372170900116566e+03,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.33157941701915820e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.33157941701915820e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.33157941701915820e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.44902506352976025e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.44902506352976025e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.44902506352976025e+03,
              7.12502500000000000e+04 4.35240999999999985e+04 -9.34479599838987383e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 -9.34479599838987383e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -9.34479599838987383e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.25991903203037327e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.25991903203037327e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.25991903203037327e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.17310940902124889e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.17310940902124889e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.17310940902124889e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.07594135540700063e+03,
              7.12502500000000000e+04 4.35240999999999985e+04 -1.07594135540700063e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.07594135540700063e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.59407505635596158e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.59407505635596158e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.59407505635596158e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.22820201000691100e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.22820201000691100e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.22820201000691100e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.33982461667277721e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.33982461667277721e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.33982461667277721e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -8.51291965160175209e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -8.51291965160175209e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -8.51291965160175209e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.13584992454976532e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.13584992454976532e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.13584992454976532e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.36301550809882497e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.36301550809882497e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.36301550809882497e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.62309678232317947e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.62309678232317947e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.62309678232317947e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.50284540725725174e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.50284540725725174e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.50284540725725174e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.56696564073695527e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.56696564073695527e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.56696564073695527e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.19596889452451046e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.19596889452451046e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.19596889452451046e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.20766360110590517e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.20766360110590517e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.20766360110590517e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.48759893428044347e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.48759893428044347e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.48759893428044347e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.17170361855763031e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.17170361855763031e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.17170361855763031e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.20360367966930994e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.20360367966930994e+03,
              4.29915000000000000e+04 4.35240999999999985e+04 -1.20360367966930994e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.08268815581459944e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.08268815581459944e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.08268815581459944e+03,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.90232312651461257e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 -9.90232312651461257e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 -9.90232312651461257e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.02163784419466276e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.02163784419466276e+03,
              4.29915000000000000e+04 4.96680999999999985e+04 -1.02163784419466276e+03,
              4.47835000000000000e+04 5.06920999999999985e+04 -1.00490515101210883e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.00490515101210883e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.00490515101210883e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -9.68252759306182952e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.68252759306182952e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.68252759306182952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.93741950518555768e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.93741950518555768e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -7.93741950518555768e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -9.04300163874872169e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.04300163874872169e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 -9.04300163874872169e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.44721764955241952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.44721764955241952e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.44721764955241952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -6.28983400844030371e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.28983400844030371e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -6.28983400844030371e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -6.93945069837851293e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.93945069837851293e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.93945069837851293e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.08001485842184707e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.08001485842184707e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -6.08001485842184707e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.57704336271015336e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.57704336271015336e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.57704336271015336e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.22582601566710878e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.22582601566710878e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -7.22582601566710878e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -7.05777280950994964e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -7.05777280950994964e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.05777280950994964e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.41153582263419480e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.41153582263419480e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.41153582263419480e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.30373620879683585e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.30373620879683585e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.30373620879683585e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.84875903915528397e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.84875903915528397e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -5.84875903915528397e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.74098494540717184e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -5.74098494540717184e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -5.74098494540717184e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.74173633628618404e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.74173633628618404e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.74173633628618404e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -4.92201556391211398e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 -4.92201556391211398e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.92201556391211398e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -5.22796202954084492e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -5.22796202954084492e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -5.22796202954084492e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -8.38578400801222301e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.38578400801222301e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.38578400801222301e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -7.44151689076554362e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -7.44151689076554362e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 -7.44151689076554362e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -6.56929116542612178e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -6.56929116542612178e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.56929116542612178e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.43062834066491928e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.43062834066491928e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.43062834066491928e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.05545595002935443e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.05545595002935443e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.05545595002935443e+03,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.28679229918452393e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.28679229918452393e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.28679229918452393e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.53400137528681398e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.53400137528681398e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -4.53400137528681398e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 -9.62671146957702263e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -9.62671146957702263e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -9.62671146957702263e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -9.71966047507317171e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -9.71966047507317171e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 -9.71966047507317171e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -5.53577545522798459e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -5.53577545522798459e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.53577545522798459e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.54233262785559009e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.54233262785559009e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.54233262785559009e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.02855407036119595e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.02855407036119595e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -1.02855407036119595e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.40184870204428171e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.40184870204428171e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.40184870204428171e+03,
              9.10415000000000000e+04 6.56491000000000058e+04 -2.50447288387922669e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -2.50447288387922669e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -2.50447288387922669e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 -9.22515200773750848e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 -9.22515200773750848e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -9.22515200773750848e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.25174421949356952e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.25174421949356952e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.25174421949356952e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -7.63692424972593813e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -7.63692424972593813e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -7.63692424972593813e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -1.00051184910443976e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.00051184910443976e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.00051184910443976e+03,
              8.28433143261273362e+04 5.35435587279313040e+04 -8.99167333721740988e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -8.99167333721740988e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -8.99167333721740988e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -7.51158173597061932e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -7.51158173597061932e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 -7.51158173597061932e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.18633362159612125e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.18633362159612125e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.18633362159612125e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -9.41845783338410229e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 -9.41845783338410229e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -9.41845783338410229e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -5.14789205786542084e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.14789205786542084e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.14789205786542084e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.27908766324879866e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.27908766324879866e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.27908766324879866e+03,
              8.11458750000000000e+04 4.55484482850344648e+04 -4.11486233346094480e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -4.11486233346094480e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -4.11486233346094480e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.41147048340537026e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.41147048340537026e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.41147048340537026e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.54156439600595809e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.54156439600595809e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.54156439600595809e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.59626490451779659e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.59626490451779659e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.59626490451779659e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.19869310141632218e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.19869310141632218e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.19869310141632218e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.55234980606984595e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.55234980606984595e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.55234980606984595e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.42631925827065334e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.42631925827065334e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.42631925827065334e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.38995725775938649e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.38995725775938649e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.38995725775938649e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.27396206664154352e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.27396206664154352e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.27396206664154352e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.36382691095044493e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.36382691095044493e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.36382691095044493e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.18126919686537576e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.18126919686537576e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.18126919686537576e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.22770553576312159e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.22770553576312159e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.22770553576312159e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.47675201584509546e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.47675201584509546e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.47675201584509546e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.46961267051658615e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.46961267051658615e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.46961267051658615e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.48068400749846819e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.48068400749846819e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.48068400749846819e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.38175522225175951e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.38175522225175951e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.38175522225175951e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.34880061985139218e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.34880061985139218e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.34880061985139218e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.32106622639239617e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.32106622639239617e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.32106622639239617e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.32084747284081209e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.32084747284081209e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.32084747284081209e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.28882946861265464e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.28882946861265464e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.28882946861265464e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.22773566775754193e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.22773566775754193e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.22773566775754193e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.15155057869729944e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.15155057869729944e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.15155057869729944e+03,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.54500478129625208e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -6.54500478129625208e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -6.54500478129625208e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -6.94544228481156438e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.94544228481156438e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -6.94544228481156438e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.52964010662053738e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.52964010662053738e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -2.52964010662053738e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.60502555152936566e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.60502555152936566e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.60502555152936566e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.75032515032106062e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.75032515032106062e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -2.75032515032106062e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -5.97745580807628471e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.97745580807628471e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.97745580807628471e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.74799283498481543e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -2.74799283498481543e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -2.74799283498481543e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -3.21048623397981771e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -3.21048623397981771e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -3.21048623397981771e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -5.75814137731806113e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -5.75814137731806113e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.75814137731806113e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -4.71128251186164903e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -4.71128251186164903e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -4.71128251186164903e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -4.91159865798663418e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -4.91159865798663418e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -4.91159865798663418e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -1.65411945040553150e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.65411945040553150e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.65411945040553150e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.17127387245225145e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.17127387245225145e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.17127387245225145e+03,
              8.08537882636642753e+04 6.57374252120232413e+04 -8.74335467038114075e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -8.74335467038114075e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.74335467038114075e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.13613714170831031e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.13613714170831031e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.13613714170831031e+03,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.71781287890125839e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 -1.71781287890125839e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 -1.71781287890125839e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.02725330639301433e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.02725330639301433e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -1.02725330639301433e+03,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.26202918026849062e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.26202918026849062e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.26202918026849062e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -1.63994919593031852e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.63994919593031852e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.63994919593031852e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -7.72616780275687461e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -7.72616780275687461e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.72616780275687461e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -1.00632959970353795e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.00632959970353795e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.00632959970353795e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -9.70464329656160771e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 -9.70464329656160771e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -9.70464329656160771e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -9.07321110829576355e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -9.07321110829576355e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.07321110829576355e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -3.06256635620653526e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -3.06256635620653526e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -3.06256635620653526e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.34136871805149553e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.34136871805149553e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 -1.34136871805149553e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.27738159811700513e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 -1.27738159811700513e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.27738159811700513e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -9.04246338181003807e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -9.04246338181003807e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -9.04246338181003807e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.99120696550614639e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -6.99120696550614639e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.99120696550614639e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.19821450240310810e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -7.19821450240310810e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -7.19821450240310810e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -9.73920688139467529e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -9.73920688139467529e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -9.73920688139467529e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -1.05093226547629274e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.05093226547629274e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.05093226547629274e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -8.55559546867083213e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -8.55559546867083213e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -8.55559546867083213e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -7.80870107069015830e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -7.80870107069015830e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -7.80870107069015830e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 -8.40353868782808263e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -8.40353868782808263e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.40353868782808263e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.18401224899737372e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -4.18401224899737372e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -4.18401224899737372e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -1.45935141253962968e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -1.45935141253962968e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -1.45935141253962968e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.48430310936736760e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -5.48430310936736760e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -5.48430310936736760e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.07508302582244983e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.07508302582244983e+03,
              7.80244469299197954e+04 6.52032686577075292e+04 -1.07508302582244983e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.30005009289198756e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.30005009289198756e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.30005009289198756e+03,
              8.16333676824811409e+04 5.97976004010879988e+04 -7.18771258153142526e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -7.18771258153142526e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -7.18771258153142526e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -6.56049466418250063e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -6.56049466418250063e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -6.56049466418250063e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -2.48916247089439508e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -2.48916247089439508e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -2.48916247089439508e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -5.28927823952723315e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -5.28927823952723315e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -5.28927823952723315e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.32789048198373848e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.32789048198373848e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.32789048198373848e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.25212812499425672e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.25212812499425672e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.25212812499425672e+03,
              8.32611584966219380e+04 6.22254986442461886e+04 -7.73752121909965922e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -7.73752121909965922e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -7.73752121909965922e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -6.04791338227878668e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -6.04791338227878668e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -6.04791338227878668e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -6.98657895022819048e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -6.98657895022819048e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -6.98657895022819048e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.09472019074785521e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.09472019074785521e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -5.09472019074785521e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 -1.09797861564776241e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.09797861564776241e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.09797861564776241e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -4.33629779244460451e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.33629779244460451e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -4.33629779244460451e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -6.94060534868092873e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -6.94060534868092873e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -6.94060534868092873e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -1.88608819237264697e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -1.88608819237264697e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.88608819237264697e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.85940179182455665e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.85940179182455665e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.85940179182455665e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.73842073386209904e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.73842073386209904e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -5.73842073386209904e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -2.83585757331886043e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -2.83585757331886043e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -2.83585757331886043e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -6.70579829515112124e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -6.70579829515112124e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -6.70579829515112124e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -5.51293988649818857e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -5.51293988649818857e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -5.51293988649818857e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -6.95807937292608699e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -6.95807937292608699e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.95807937292608699e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -6.01235618984170742e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.01235618984170742e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.01235618984170742e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -5.12380567480501441e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -5.12380567480501441e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -5.12380567480501441e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -6.15323947799511984e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.15323947799511984e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -6.15323947799511984e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -5.42469899036878701e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -5.42469899036878701e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -5.42469899036878701e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -7.45292215479388233e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -7.45292215479388233e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -7.45292215479388233e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -1.75689303076548839e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.75689303076548839e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -1.75689303076548839e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -1.58626335702772792e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -1.58626335702772792e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -1.58626335702772792e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -9.43048889831737824e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -9.43048889831737824e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -9.43048889831737824e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -7.66253242345806825e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -7.66253242345806825e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.66253242345806825e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -5.52814726986519759e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.52814726986519759e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.52814726986519759e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -1.44345615463714637e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.44345615463714637e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -1.44345615463714637e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.69288171915683733e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.69288171915683733e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -4.69288171915683733e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 6.72129483803797569e+01,
              4.29915000000000000e+04 8.08076000000000058e+04 6.72129483803797569e+01,
              4.68635539404924566e+04 8.35416926830068551e+04 6.72129483803797569e+01,
              6.03973817143073538e+04 7.27813878736088955e+04 -4.39634656352286925e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.39634656352286925e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.39634656352286925e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -3.91416883787495465e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -3.91416883787495465e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.91416883787495465e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 -1.05740762526366041e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.05740762526366041e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.05740762526366041e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.29458475348189836e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.29458475348189836e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -4.29458475348189836e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -3.81310680035209259e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.81310680035209259e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 -3.81310680035209259e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.49827543315429182e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -2.49827543315429182e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -2.49827543315429182e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.85223017262790279e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.85223017262790279e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -3.85223017262790279e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.40106563187701170e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.40106563187701170e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -3.40106563187701170e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -3.79777912269632111e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.79777912269632111e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.79777912269632111e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.05590707394267326e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.05590707394267326e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -3.05590707394267326e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.07290917884902171e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.07290917884902171e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -4.07290917884902171e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.46272512474281086e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -1.46272512474281086e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.46272512474281086e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 -2.90085876279641752e+00,
              4.53027798571047970e+04 7.90659750000000058e+04 -2.90085876279641752e+00,
              4.29915000000000000e+04 7.73243500000000058e+04 -2.90085876279641752e+00,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.52237195587251904e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 -1.52237195587251904e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -1.52237195587251904e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.35687990056871513e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -1.35687990056871513e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.35687990056871513e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.36782409242099504e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.36782409242099504e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -1.36782409242099504e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.70660145389498894e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.70660145389498894e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -1.70660145389498894e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -1.91273514670424333e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.91273514670424333e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.91273514670424333e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.32095101912305267e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 1.32095101912305267e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 1.32095101912305267e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.31949999713019963e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -2.31949999713019963e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -2.31949999713019963e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 -6.27152217550164153e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 -6.27152217550164153e+01,
              4.77584339542222879e+04 7.56540985605456081e+04 -6.27152217550164153e+01,
              5.77128434890498829e+04 7.28292648936219484e+04 -2.85219537080508417e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.85219537080508417e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.85219537080508417e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -1.84415914645255071e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.84415914645255071e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.84415914645255071e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.72908617916845401e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.72908617916845401e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -4.72908617916845401e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -3.65699925363983255e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.65699925363983255e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -3.65699925363983255e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -2.25220953557626586e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.25220953557626586e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.25220953557626586e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.48457754366606196e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -3.48457754366606196e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.48457754366606196e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -6.95605931952111405e+01,
              4.53027798571047970e+04 7.90659750000000058e+04 -6.95605931952111405e+01,
              4.77584339542222879e+04 7.56540985605456081e+04 -6.95605931952111405e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 4.71446288093625583e+01,
              5.06225252789799124e+04 7.93839231851334625e+04 4.71446288093625583e+01,
              4.86229215617078662e+04 8.12219413673433592e+04 4.71446288093625583e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 2.86205148207011462e+00,
              4.53027798571047970e+04 7.90659750000000058e+04 2.86205148207011462e+00,
              4.86229215617078662e+04 8.12219413673433592e+04 2.86205148207011462e+00,
              4.86229215617078662e+04 8.12219413673433592e+04 1.13907342553920969e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.13907342553920969e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 1.13907342553920969e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 2.33186961239230101e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 2.33186961239230101e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.33186961239230101e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -3.00418488234638176e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.00418488234638176e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -3.00418488234638176e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.53904601400236714e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -2.53904601400236714e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.53904601400236714e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.13621816586127068e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.13621816586127068e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -2.13621816586127068e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.16414178233629428e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.16414178233629428e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.16414178233629428e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.31957475347395160e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.31957475347395160e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.31957475347395160e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 1.10140027131294289e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 1.10140027131294289e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.10140027131294289e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 2.31390073535880560e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 2.31390073535880560e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 2.31390073535880560e+01,
              4.89977500000000000e+04 8.77741000000000058e+04 2.00506197316279128e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 2.00506197316279128e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.00506197316279128e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.92610286663583764e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.92610286663583764e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -1.92610286663583764e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.00671211094553414e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.00671211094553414e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.00671211094553414e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -1.62750609264671368e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.62750609264671368e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.62750609264671368e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -6.79185001595731990e+01,
              5.52263343014311831e+04 8.04312537877296854e+04 -6.79185001595731990e+01,
              5.59416064564449553e+04 7.77443652293567866e+04 -6.79185001595731990e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 8.31047349349756104e+00,
              5.52263343014311831e+04 8.04312537877296854e+04 8.31047349349756104e+00,
              5.31712790675824799e+04 7.84455309487277991e+04 8.31047349349756104e+00,
              5.06225252789799124e+04 7.93839231851334625e+04 -7.72143989394103585e+01,
              5.31712790675824799e+04 7.84455309487277991e+04 -7.72143989394103585e+01,
              5.16410222818254188e+04 7.60320974242347147e+04 -7.72143989394103585e+01,
              5.52263343014311831e+04 8.04312537877296854e+04 -5.74525441574589735e+00,
              5.50589499526593063e+04 8.29185620004188240e+04 -5.74525441574589735e+00,
              5.79559215132922254e+04 7.99987952834800672e+04 -5.74525441574589735e+00,
              4.59946250000000000e+04 8.77741000000000058e+04 2.17545226124599083e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 2.17545226124599083e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.17545226124599083e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -6.89962457236648106e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -6.89962457236648106e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -6.89962457236648106e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -6.83919816251171142e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -6.83919816251171142e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -6.83919816251171142e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.58626902859957227e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 1.58626902859957227e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.58626902859957227e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 -6.81118353624262340e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 -6.81118353624262340e+01,
              5.59416064564449553e+04 7.77443652293567866e+04 -6.81118353624262340e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 -1.97282032571461627e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.97282032571461627e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.97282032571461627e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 -1.05860985513656551e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 -1.05860985513656551e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -1.05860985513656551e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 4.54968327639450365e+00,
              5.72171465710359116e+04 8.41663228034385247e+04 4.54968327639450365e+00,
              6.05799435922970297e+04 8.44286472202762234e+04 4.54968327639450365e+00,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.28068293724087056e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -2.28068293724087056e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.28068293724087056e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.64710674825756541e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.64710674825756541e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -1.64710674825756541e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -3.32554607022782989e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -3.32554607022782989e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -3.32554607022782989e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.06914633193009827e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 3.06914633193009827e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 3.06914633193009827e+01,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.44409907921055537e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.44409907921055537e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.44409907921055537e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.38816871352411272e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.38816871352411272e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.38816871352411272e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -8.92656511961674596e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.92656511961674596e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -8.92656511961674596e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.36022587871006226e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.36022587871006226e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.36022587871006226e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -7.69525129733161180e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -7.69525129733161180e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -7.69525129733161180e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.08450391642766226e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.08450391642766226e+03,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.08450391642766226e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.43093354714030170e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.43093354714030170e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.43093354714030170e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.28058166845976189e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.28058166845976189e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.28058166845976189e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -1.03398878114219497e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.03398878114219497e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.03398878114219497e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -9.56502404166317774e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -9.56502404166317774e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 -9.56502404166317774e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -9.53151022174442346e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -9.53151022174442346e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 -9.53151022174442346e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.00584849887217570e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.00584849887217570e+03,
              6.64749768790189410e+04 6.99144954876966513e+04 -1.00584849887217570e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.07242631483253331e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -1.07242631483253331e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.07242631483253331e+03,
              6.64749768790189410e+04 6.99144954876966513e+04 -7.76067355406011075e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -7.76067355406011075e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -7.76067355406011075e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.42052949907918583e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.42052949907918583e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.42052949907918583e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.13914044435214760e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.13914044435214760e+03,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.13914044435214760e+03,
              6.93061383463380189e+04 7.23374074222391209e+04 -6.87212559351575692e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -6.87212559351575692e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -6.87212559351575692e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -4.04369770007552688e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -4.04369770007552688e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.04369770007552688e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.36453781653367105e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.36453781653367105e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.36453781653367105e+03,
              7.12115982768096728e+04 6.91350333107481420e+04 -1.12007787773389100e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.12007787773389100e+03,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.12007787773389100e+03,
              7.85990063731790578e+04 6.99202809302571986e+04 -7.33671050597323642e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.33671050597323642e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -7.33671050597323642e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -2.12003136904954630e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.12003136904954630e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.12003136904954630e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.25291859262093340e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.25291859262093340e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.25291859262093340e+03,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.94698825159162993e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -9.94698825159162993e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.94698825159162993e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.27872851437105237e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.27872851437105237e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.27872851437105237e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.16318790082322334e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.16318790082322334e+03,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.16318790082322334e+03,
              7.45080858675325871e+04 7.32645676197651628e+04 -7.64060344348075660e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.64060344348075660e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -7.64060344348075660e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -9.02423480904717621e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.02423480904717621e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.02423480904717621e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -5.51843264146671686e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -5.51843264146671686e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -5.51843264146671686e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -5.60572729033713131e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -5.60572729033713131e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -5.60572729033713131e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.77248150389767886e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -2.77248150389767886e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.77248150389767886e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.04985076162832911e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.04985076162832911e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -4.04985076162832911e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.50190103608573281e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -4.50190103608573281e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.50190103608573281e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -5.36969490691020383e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -5.36969490691020383e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -5.36969490691020383e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.31893200289073161e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.31893200289073161e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.31893200289073161e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.38598729641224395e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.38598729641224395e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.38598729641224395e+03,
              7.85990063731790578e+04 6.99202809302571986e+04 -6.14984679088943039e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -6.14984679088943039e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -6.14984679088943039e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -3.56227038417081758e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -3.56227038417081758e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -3.56227038417081758e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -3.06110485376227984e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -3.06110485376227984e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -3.06110485376227984e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -2.93038804144138737e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.93038804144138737e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.93038804144138737e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -6.93161318651184274e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -6.93161318651184274e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -6.93161318651184274e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -7.73763705916300296e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -7.73763705916300296e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -7.73763705916300296e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -7.50855865674396114e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -7.50855865674396114e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -7.50855865674396114e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -4.88122630344954075e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -4.88122630344954075e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -4.88122630344954075e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -5.75760069848496641e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -5.75760069848496641e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -5.75760069848496641e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -3.02122265371212052e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -3.02122265371212052e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -3.02122265371212052e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -8.93545689656723653e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -8.93545689656723653e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -8.93545689656723653e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -6.65368586204731741e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -6.65368586204731741e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -6.65368586204731741e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -9.74334086064275539e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -9.74334086064275539e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.74334086064275539e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.90687448928779645e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.90687448928779645e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 -8.90687448928779645e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -5.23883262957193210e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -5.23883262957193210e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.23883262957193210e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -7.14288539468739714e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.14288539468739714e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -7.14288539468739714e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.24263400313443270e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.24263400313443270e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.24263400313443270e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -5.33683186562928427e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -5.33683186562928427e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -5.33683186562928427e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -2.09487953017886099e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.09487953017886099e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.09487953017886099e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -4.89091112474133467e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -4.89091112474133467e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -4.89091112474133467e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -2.46498335547314412e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -2.46498335547314412e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -2.46498335547314412e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -3.71924403739652121e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -3.71924403739652121e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -3.71924403739652121e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 -7.18057263871904183e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -7.18057263871904183e+01,
              6.42553218253216837e+04 8.10436695143234538e+04 -7.18057263871904183e+01,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.67650589496035138e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.67650589496035138e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.67650589496035138e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.70158064366086705e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.70158064366086705e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.70158064366086705e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -3.47396499770066100e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -3.47396499770066100e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -3.47396499770066100e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -2.70985412791819385e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -2.70985412791819385e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -2.70985412791819385e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64685574738124018e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -1.64685574738124018e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.64685574738124018e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64707789964546123e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.64707789964546123e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.64707789964546123e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.59221289602839391e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.59221289602839391e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.59221289602839391e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -1.09092154205322913e+03,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.09092154205322913e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.09092154205322913e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.13968825323506599e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.13968825323506599e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.13968825323506599e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -9.63134049440409399e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -9.63134049440409399e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -9.63134049440409399e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.08827626529792587e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.08827626529792587e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.08827626529792587e+03,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.83380888366875183e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.83380888366875183e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.83380888366875183e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.43347969579428337e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 -1.43347969579428337e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.43347969579428337e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.73702819801288427e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.73702819801288427e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.73702819801288427e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.81361989785026225e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.81361989785026225e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.81361989785026225e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.70525876104331587e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.70525876104331587e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.70525876104331587e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.36237517617569893e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.36237517617569893e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.36237517617569893e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.10191807089762676e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -2.10191807089762676e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -2.10191807089762676e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 -5.21026485665665575e+01,
              6.70165000000000000e+04 8.77741000000000058e+04 -5.21026485665665575e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -5.21026485665665575e+01,
              9.10415000000000000e+04 7.39459750000000058e+04 -1.72919144001822701e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.72919144001822701e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.72919144001822701e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.49578726174376612e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.49578726174376612e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.49578726174376612e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 -9.74875449346252339e+01,
              6.67146997723299282e+04 8.12790927655865235e+04 -9.74875449346252339e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -9.74875449346252339e+01,
              8.15333633687027614e+04 8.45600519562402333e+04 -1.99802916121509497e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.99802916121509497e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.99802916121509497e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.94983752695736626e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.94983752695736626e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.94983752695736626e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.67534392005801806e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.67534392005801806e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.67534392005801806e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.90673455019445782e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.90673455019445782e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.90673455019445782e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.96298797205456935e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.96298797205456935e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -1.96298797205456935e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.24148102348222153e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.24148102348222153e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.24148102348222153e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.07139749094150034e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -2.07139749094150034e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.07139749094150034e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.37082145182504860e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.37082145182504860e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.37082145182504860e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.12876773968545336e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.12876773968545336e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.12876773968545336e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.17197958127931230e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.17197958127931230e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.17197958127931230e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.89534563885402662e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.89534563885402662e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.89534563885402662e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -2.16607334066419725e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.16607334066419725e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -2.16607334066419725e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.48583004345229654e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.48583004345229654e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.48583004345229654e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.12094068752339183e+02,
              9.10415000000000000e+04 8.77741000000000058e+04 -2.12094068752339183e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.12094068752339183e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.77491267537893435e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.77491267537893435e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.77491267537893435e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.86079568644681444e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.86079568644681444e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.86079568644681444e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.79354997941707495e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.79354997941707495e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.79354997941707495e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 -1.99001741655346279e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.99001741655346279e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -1.99001741655346279e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.29313914072681882e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.29313914072681882e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.29313914072681882e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.06055616287748705e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -2.06055616287748705e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.06055616287748705e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.38015307998809732e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.38015307998809732e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.38015307998809732e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.64375388660796972e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.64375388660796972e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.64375388660796972e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -2.47682112201220718e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -2.47682112201220718e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -2.47682112201220718e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.59001262064154957e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.59001262064154957e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.59001262064154957e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.73055949993051001e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.73055949993051001e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.73055949993051001e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -3.18721214510841719e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -3.18721214510841719e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -3.18721214510841719e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.59100694659225127e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.59100694659225127e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.59100694659225127e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -4.24124603248016456e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -4.24124603248016456e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -4.24124603248016456e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -5.00494736438102677e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.00494736438102677e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.00494736438102677e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -4.22486325219090531e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -4.22486325219090531e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -4.22486325219090531e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -6.03546477455562581e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -6.03546477455562581e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -6.03546477455562581e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -7.25434670792037878e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -7.25434670792037878e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -7.25434670792037878e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -5.76452916831043808e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.76452916831043808e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.76452916831043808e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -3.88888064983857248e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -3.88888064983857248e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -3.88888064983857248e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -3.28345775332834819e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -3.28345775332834819e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -3.28345775332834819e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -2.27833917527543747e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -2.27833917527543747e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -2.27833917527543747e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.85669574042907101e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -1.85669574042907101e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.85669574042907101e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.18576757566417996e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.18576757566417996e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.18576757566417996e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -2.90337333546041748e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -2.90337333546041748e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -2.90337333546041748e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.15101273313695884e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.15101273313695884e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 -2.15101273313695884e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -1.59650943613394901e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.59650943613394901e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.59650943613394901e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 -1.29872247738333414e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.29872247738333414e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.29872247738333414e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -4.13077205046171628e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.13077205046171628e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -4.13077205046171628e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -3.04390926750512222e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -3.04390926750512222e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -3.04390926750512222e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -4.76619608548695396e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -4.76619608548695396e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.76619608548695396e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -3.09278417708754148e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -3.09278417708754148e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -3.09278417708754148e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.76454782373826362e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.76454782373826362e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 1.76454782373826362e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.10580062674775036e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.10580062674775036e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 2.10580062674775036e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.84671006639418579e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 1.84671006639418579e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 1.84671006639418579e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 2.50723657803919224e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.50723657803919224e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 2.50723657803919224e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 6.61304871851742888e+01,
              4.86229215617078662e+04 8.12219413673433592e+04 6.61304871851742888e+01,
              4.53027798571047970e+04 7.90659750000000058e+04 6.61304871851742888e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 9.62069689948187090e-01,
              4.86229215617078662e+04 8.12219413673433592e+04 9.62069689948187090e-01,
              5.06225252789799124e+04 7.93839231851334625e+04 9.62069689948187090e-01,
              4.81084225482705006e+04 7.83562980960558052e+04 -6.42586934370094980e+01,
              5.06225252789799124e+04 7.93839231851334625e+04 -6.42586934370094980e+01,
              5.16410222818254188e+04 7.60320974242347147e+04 -6.42586934370094980e+01,
              5.31712790675824799e+04 7.84455309487277991e+04 9.11133436383096651e+00,
              5.06225252789799124e+04 7.93839231851334625e+04 9.11133436383096651e+00,
              5.12372078190610482e+04 8.25033967734024045e+04 9.11133436383096651e+00,
              4.68635539404924566e+04 8.35416926830068551e+04 1.30993219137182791e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.30993219137182791e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 1.30993219137182791e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.63413535120320944e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 1.63413535120320944e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.63413535120320944e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 5.37909311605495546e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 5.37909311605495546e+01,
              5.50589499526593063e+04 8.29185620004188240e+04 5.37909311605495546e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 1.28711377245984437e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.28711377245984437e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.28711377245984437e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.10348396638688115e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.10348396638688115e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 1.10348396638688115e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 7.66631560291530434e+01,
              5.35024375000000000e+04 8.48658649172350706e+04 7.66631560291530434e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 7.66631560291530434e+01,
              5.35024375000000000e+04 8.48658649172350706e+04 9.20747902947402963e+01,
              5.50589499526593063e+04 8.29185620004188240e+04 9.20747902947402963e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 9.20747902947402963e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -2.89221040748758611e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 -2.89221040748758611e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -2.89221040748758611e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 6.98580805202344521e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 6.98580805202344521e+01,
              5.80071250000000000e+04 8.77741000000000058e+04 6.98580805202344521e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -9.20080555750582789e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -9.20080555750582789e+01,
              6.26362041036404407e+04 7.91775447069208167e+04 -9.20080555750582789e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -3.62588355127309754e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -3.62588355127309754e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 -3.62588355127309754e+01,
              6.83044480184609274e+04 7.73047289581401710e+04 -5.54214923735086813e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -5.54214923735086813e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -5.54214923735086813e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 -5.20247738385857872e+00,
              6.70165000000000000e+04 8.77741000000000058e+04 -5.20247738385857872e+00,
              6.48307093999745339e+04 8.34736021066768299e+04 -5.20247738385857872e+00,
              5.72171465710359116e+04 8.41663228034385247e+04 6.20523060009657144e+01,
              5.80071250000000000e+04 8.77741000000000058e+04 6.20523060009657144e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 6.20523060009657144e+01,
              7.12992873738839990e+04 7.62112637298534974e+04 -4.86594235323843066e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -4.86594235323843066e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -4.86594235323843066e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -4.49708211538523017e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -4.49708211538523017e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -4.49708211538523017e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.33514191698142383e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 -1.33514191698142383e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.33514191698142383e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.63140738778870656e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.63140738778870656e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.63140738778870656e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -2.63184938125339556e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -2.63184938125339556e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -2.63184938125339556e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.88173480503788795e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.88173480503788795e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.88173480503788795e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 -6.53407412440755593e+01,
              7.30227500000000000e+04 8.77741000000000058e+04 -6.53407412440755593e+01,
              7.08349265632997267e+04 8.51163379562475893e+04 -6.53407412440755593e+01,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.22789464294145034e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.22789464294145034e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 -1.22789464294145034e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.52236836794941155e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.52236836794941155e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.52236836794941155e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 -3.88777631104503243e+01,
              7.00196250000000000e+04 8.77741000000000058e+04 -3.88777631104503243e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -3.88777631104503243e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -1.21603323424540662e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.21603323424540662e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.21603323424540662e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 -7.56125861026865209e+01,
              7.08349265632997267e+04 8.51163379562475893e+04 -7.56125861026865209e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -7.56125861026865209e+01,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.37847361855229082e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -2.37847361855229082e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -2.37847361855229082e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -2.20058198183438520e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -2.20058198183438520e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -2.20058198183438520e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.69140131578751664e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.69140131578751664e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -1.69140131578751664e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.08032921315032596e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.08032921315032596e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 -1.08032921315032596e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64726401463330262e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.64726401463330262e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -1.64726401463330262e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -2.16184772258452085e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -2.16184772258452085e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -2.16184772258452085e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 -8.24479057959157302e+00,
              6.40133750000000000e+04 8.77741000000000058e+04 -8.24479057959157302e+00,
              6.48307093999745339e+04 8.34736021066768299e+04 -8.24479057959157302e+00,
              9.10415000000000000e+04 7.94772250000000058e+04 -1.93503438901436965e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.93503438901436965e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.93503438901436965e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 4.16084212001112235e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 4.16084212001112235e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 4.16084212001112235e+01,
              9.10415000000000000e+04 7.39459750000000058e+04 -1.59729839397002792e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.59729839397002792e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.59729839397002792e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.60851239010682832e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.60851239010682832e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.60851239010682832e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.86424762597990252e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.86424762597990252e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.86424762597990252e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.38339803512773699e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.38339803512773699e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.38339803512773699e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.53821278078684600e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.53821278078684600e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.53821278078684600e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.37065420027865571e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.37065420027865571e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.37065420027865571e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 3.01072532933333207e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 3.01072532933333207e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 3.01072532933333207e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.30271435400001394e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.30271435400001394e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.30271435400001394e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.42104248995329328e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.42104248995329328e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.42104248995329328e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.80972448400000133e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.80972448400000133e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.80972448400000133e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.64816765239895744e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.64816765239895744e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.64816765239895744e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.48665799293333635e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.48665799293333635e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.48665799293333635e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 3.01214793746664554e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.01214793746664554e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.01214793746664554e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.04472330826666052e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 3.04472330826666052e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.04472330826666052e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.42689800959999900e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.42689800959999900e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.42689800959999900e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.79419687606687546e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.79419687606687546e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.79419687606687546e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.36422922066666501e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 3.36422922066666501e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.36422922066666501e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.52804657242687767e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.52804657242687767e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.52804657242687767e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.81236800346687119e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.81236800346687119e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.81236800346687119e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.38125089807442976e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.38125089807442976e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.38125089807442976e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 3.15789693574727949e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.15789693574727949e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.15789693574727949e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.99695186559662716e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.99695186559662716e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.99695186559662716e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 4.12452616076809022e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.12452616076809022e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.12452616076809022e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.75483195064881215e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.75483195064881215e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.75483195064881215e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.50324682742912444e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.50324682742912444e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.50324682742912444e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.37188970902710139e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.37188970902710139e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.37188970902710139e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 2.87091911903388620e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 2.87091911903388620e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 2.87091911903388620e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.25775478083738562e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.25775478083738562e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 3.25775478083738562e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.44515912197648049e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.44515912197648049e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.44515912197648049e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.23891715573332760e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.23891715573332760e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.23891715573332760e+02,
              4.29915000000000000e+04 4.35240999999999985e+04 2.39900377906666506e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.39900377906666506e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.39900377906666506e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.93002533826666252e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.93002533826666252e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.93002533826666252e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.04498263360000465e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.04498263360000465e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 3.04498263360000465e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.72911137333330146e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 2.72911137333330146e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.72911137333330146e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.67424170767326927e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.67424170767326927e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.67424170767326927e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.95952213136610567e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.95952213136610567e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.95952213136610567e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 2.99089457840000421e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.99089457840000421e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.99089457840000421e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.92273849653333400e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.92273849653333400e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.92273849653333400e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.22171328516908375e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 3.22171328516908375e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.22171328516908375e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.71139293489142574e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.71139293489142574e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.71139293489142574e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.58246477598822821e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.58246477598822821e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.58246477598822821e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 3.09815295160011829e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 3.09815295160011829e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 3.09815295160011829e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.56516419223056687e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.56516419223056687e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.56516419223056687e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.93035144333342316e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.93035144333342316e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.93035144333342316e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 2.96095852399949365e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 2.96095852399949365e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.96095852399949365e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 2.99217278512590553e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 2.99217278512590553e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 2.99217278512590553e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.60302878327358826e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.60302878327358826e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.60302878327358826e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.67686213449206662e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.67686213449206662e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.67686213449206662e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.56782741693513628e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56782741693513628e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.56782741693513628e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.39752170091121400e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.39752170091121400e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.39752170091121400e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 3.20272171353086776e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.20272171353086776e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.20272171353086776e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 2.85891329724487605e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.85891329724487605e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.85891329724487605e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.31273600041525526e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.31273600041525526e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.31273600041525526e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.84671605651273126e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.84671605651273126e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.84671605651273126e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.94418375146688390e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.94418375146688390e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 3.94418375146688390e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.58117476160887065e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.58117476160887065e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.58117476160887065e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.81677188701055684e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 3.81677188701055684e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.81677188701055684e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.69993274333706267e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.69993274333706267e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.69993274333706267e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.42216217943509776e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.42216217943509776e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.42216217943509776e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.15216354952263259e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.15216354952263259e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.15216354952263259e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.62615053752732251e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.62615053752732251e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.62615053752732251e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.88249173802736266e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.88249173802736266e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.88249173802736266e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.10728794112533592e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.10728794112533592e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.10728794112533592e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.25323757806361755e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.25323757806361755e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.25323757806361755e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.42068508828264726e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.42068508828264726e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.42068508828264726e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 2.94237282913417459e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.94237282913417459e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.94237282913417459e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 3.10881293892446479e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 3.10881293892446479e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.10881293892446479e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.17295833357514027e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.17295833357514027e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.17295833357514027e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.50151072677203956e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.50151072677203956e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.50151072677203956e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 2.69856809356281701e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 2.69856809356281701e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 2.69856809356281701e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.12835913814865819e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 4.12835913814865819e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.12835913814865819e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.49706144209265119e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.49706144209265119e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.49706144209265119e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.77377020649952783e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.77377020649952783e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.77377020649952783e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.45895824951189184e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.45895824951189184e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.45895824951189184e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.14798461904966757e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.14798461904966757e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.14798461904966757e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.30157930480486129e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.30157930480486129e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.30157930480486129e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.71751914111556630e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.71751914111556630e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.71751914111556630e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 2.89724257722336631e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.89724257722336631e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.89724257722336631e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 2.60609990458346601e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 2.60609990458346601e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 2.60609990458346601e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.14547250201866632e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 3.14547250201866632e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 3.14547250201866632e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.85774259936580052e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.85774259936580052e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.85774259936580052e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.74365168226166190e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 2.74365168226166190e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.74365168226166190e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.94650626326653139e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.94650626326653139e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.94650626326653139e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.84746145169773968e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.84746145169773968e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.84746145169773968e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.73962732928351556e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.73962732928351556e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.73962732928351556e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.94674581485440115e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.94674581485440115e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.94674581485440115e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.17397202494405406e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.17397202494405406e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.17397202494405406e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.80603481223435949e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 2.80603481223435949e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.80603481223435949e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.68675825501134568e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.68675825501134568e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.68675825501134568e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.72079592493876589e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.72079592493876589e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.72079592493876589e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.39361319640573015e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 3.39361319640573015e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.39361319640573015e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 3.04258677841460326e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.04258677841460326e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 3.04258677841460326e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.50037881957915943e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.50037881957915943e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.50037881957915943e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 2.82015143450889468e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.82015143450889468e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.82015143450889468e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.99025067602795502e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 2.99025067602795502e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.99025067602795502e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 2.29249399930201378e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.29249399930201378e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.29249399930201378e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 3.10409827063563171e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.10409827063563171e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.10409827063563171e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.55931310132598867e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.55931310132598867e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.55931310132598867e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.52370227712429141e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 3.52370227712429141e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 3.52370227712429141e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 3.97184272124841527e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.97184272124841527e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 3.97184272124841527e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.98798291804651967e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.98798291804651967e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.98798291804651967e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.71755772535116989e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.71755772535116989e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.71755772535116989e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 2.44085432042270924e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.44085432042270924e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.44085432042270924e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.75747007008806008e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.75747007008806008e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.75747007008806008e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.90792217174441532e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.90792217174441532e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.90792217174441532e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.73137266482337225e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.73137266482337225e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.73137266482337225e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.42679820963636075e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.42679820963636075e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.42679820963636075e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.80827675853276958e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.80827675853276958e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.80827675853276958e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.84784407506669424e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.84784407506669424e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.84784407506669424e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 2.95682962533268835e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.95682962533268835e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.95682962533268835e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 2.84004552586671480e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.84004552586671480e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 2.84004552586671480e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.62691909786666770e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.62691909786666770e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.62691909786666770e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.43363770346666058e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.43363770346666058e+02,
              4.29915000000000000e+04 4.35240999999999985e+04 2.43363770346666058e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.60556289746657512e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.60556289746657512e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.60556289746657512e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56346981960156882e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.56346981960156882e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.56346981960156882e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.57533931199919664e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.57533931199919664e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.57533931199919664e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.84307848000000604e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.84307848000000604e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.84307848000000604e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.82353184453333085e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.82353184453333085e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.82353184453333085e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.75061178423562183e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.75061178423562183e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.75061178423562183e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.56196663851987012e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56196663851987012e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.56196663851987012e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.88612729414016826e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.88612729414016826e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 2.88612729414016826e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 3.11057767774157639e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.11057767774157639e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 3.11057767774157639e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 3.12146548223079890e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.12146548223079890e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.12146548223079890e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.13349647332102165e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.13349647332102165e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.13349647332102165e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.54601429460738871e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.54601429460738871e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 3.54601429460738871e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.56130010299696323e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.56130010299696323e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.56130010299696323e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.72845834030930405e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.72845834030930405e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.72845834030930405e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.46362350745074423e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.46362350745074423e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.46362350745074423e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.54635109024970006e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.54635109024970006e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.54635109024970006e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 2.57636520767776688e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 2.57636520767776688e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 2.57636520767776688e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.29112901579343770e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 3.29112901579343770e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 3.29112901579343770e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.74145545311169997e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.74145545311169997e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.74145545311169997e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 4.01347699654367261e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 4.01347699654367261e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 4.01347699654367261e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.47612305370983279e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.47612305370983279e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.47612305370983279e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 3.02735191168284530e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 3.02735191168284530e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 3.02735191168284530e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.19573821522632983e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 2.19573821522632983e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 2.19573821522632983e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 2.21557550311759144e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.21557550311759144e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 2.21557550311759144e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.64738962113641605e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.64738962113641605e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.64738962113641605e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.08932800417852491e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 4.08932800417852491e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.08932800417852491e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.74653436785547740e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.74653436785547740e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.74653436785547740e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.90091600580079785e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 2.90091600580079785e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.90091600580079785e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.97877350055881834e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 2.97877350055881834e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.97877350055881834e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 3.57170447841505847e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.57170447841505847e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.57170447841505847e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 1.67027218670398639e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 1.67027218670398639e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.67027218670398639e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.14187700595865522e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 4.14187700595865522e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.14187700595865522e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 2.28215898656901288e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.28215898656901288e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.28215898656901288e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.70599290142608538e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.70599290142608538e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.70599290142608538e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.27327747538001887e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 2.27327747538001887e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.27327747538001887e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 2.68382598688630196e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.68382598688630196e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.68382598688630196e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 3.46992310042905501e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.46992310042905501e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.46992310042905501e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.20240218191962299e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.20240218191962299e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 2.20240218191962299e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.01253725962071883e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.01253725962071883e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.01253725962071883e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.38995069945036477e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.38995069945036477e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.38995069945036477e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 2.33603417560908440e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.33603417560908440e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.33603417560908440e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.21235846943740853e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.21235846943740853e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.21235846943740853e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 1.96732807888802483e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 1.96732807888802483e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 1.96732807888802483e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 1.56535686826428559e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.56535686826428559e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.56535686826428559e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.46490770085840268e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 3.46490770085840268e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 3.46490770085840268e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 2.04564400272392618e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 2.04564400272392618e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 2.04564400272392618e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.54288808948337731e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.54288808948337731e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.54288808948337731e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.83034484936951856e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.83034484936951856e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 3.83034484936951856e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 3.68446450426633305e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.68446450426633305e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.68446450426633305e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.62039628592360714e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.62039628592360714e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 3.62039628592360714e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.12421464035965300e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.12421464035965300e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.12421464035965300e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.41630183621660365e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.41630183621660365e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.41630183621660365e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.14008166502428480e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.14008166502428480e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.14008166502428480e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.94706517965903572e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.94706517965903572e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 2.94706517965903572e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.23183724346857730e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.23183724346857730e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.23183724346857730e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 3.08688759776300117e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 3.08688759776300117e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.08688759776300117e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 2.97231265230909685e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.97231265230909685e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.97231265230909685e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.25264628543285539e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 4.25264628543285539e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 4.25264628543285539e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.45657552529896122e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.45657552529896122e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.45657552529896122e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.09302552698117950e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 4.09302552698117950e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 4.09302552698117950e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.97575086056033911e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.97575086056033911e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.97575086056033911e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.92322675168015962e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.92322675168015962e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.92322675168015962e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.22170355752503383e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.22170355752503383e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.22170355752503383e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 4.17461516979020757e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 4.17461516979020757e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 4.17461516979020757e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 3.95845903891251282e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.95845903891251282e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.95845903891251282e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.63798019143778049e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.63798019143778049e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.63798019143778049e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.82847590755247836e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.82847590755247836e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.82847590755247836e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.81987559530426864e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 1.81987559530426864e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.81987559530426864e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.14276138822615110e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 2.14276138822615110e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.14276138822615110e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.68096949803360076e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.68096949803360076e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.68096949803360076e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.56533972463468018e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.56533972463468018e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.56533972463468018e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.96060663517173225e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 1.96060663517173225e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 1.96060663517173225e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.51786340140452296e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.51786340140452296e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.51786340140452296e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.51866246476342809e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.51866246476342809e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.51866246476342809e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 1.78484616514962653e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.78484616514962653e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.78484616514962653e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.47170564820540164e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.47170564820540164e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.47170564820540164e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.57850981088630760e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.57850981088630760e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.57850981088630760e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.59800477175039589e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.59800477175039589e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.59800477175039589e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.71301471942823980e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 1.71301471942823980e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.71301471942823980e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.24007554253118087e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.24007554253118087e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.24007554253118087e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.58110719025515607e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.58110719025515607e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.58110719025515607e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.23194145995102758e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.23194145995102758e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.23194145995102758e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.71810376635528371e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 1.71810376635528371e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 1.71810376635528371e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.72493083356662453e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.72493083356662453e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.72493083356662453e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 1.81365645349325405e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.81365645349325405e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.81365645349325405e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.48435747286891228e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.48435747286891228e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.48435747286891228e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 2.05163743202745422e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.05163743202745422e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.05163743202745422e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.32412641146311358e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.32412641146311358e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.32412641146311358e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.64624035408333953e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.64624035408333953e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.64624035408333953e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 2.99767905129055350e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 2.99767905129055350e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 2.99767905129055350e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.60537566931757453e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.60537566931757453e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.60537566931757453e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 2.02958870305464018e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.02958870305464018e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 2.02958870305464018e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 2.13888753770535942e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 2.13888753770535942e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.13888753770535942e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.04280887075466325e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.04280887075466325e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.04280887075466325e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.67405716984560257e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.67405716984560257e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.67405716984560257e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.76272424259244787e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.76272424259244787e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.76272424259244787e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.58765095365674711e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.58765095365674711e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.58765095365674711e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.90583387546054382e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.90583387546054382e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.90583387546054382e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.36033812767985751e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.36033812767985751e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.36033812767985751e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.62277212023801496e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.62277212023801496e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.62277212023801496e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.97590976382023882e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 2.97590976382023882e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.97590976382023882e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.61974534700814161e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.61974534700814161e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.61974534700814161e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 2.23134897286204136e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 2.23134897286204136e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.23134897286204136e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.75601298299687471e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.75601298299687471e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.75601298299687471e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.94820417031516172e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.94820417031516172e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.94820417031516172e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 3.13641429389788243e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.13641429389788243e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 3.13641429389788243e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.21061213623305747e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.21061213623305747e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.21061213623305747e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.50091186565772546e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.50091186565772546e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.50091186565772546e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.81589136215283816e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.81589136215283816e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.81589136215283816e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 2.94437626388489548e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.94437626388489548e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.94437626388489548e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.61453400387348040e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.61453400387348040e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.61453400387348040e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.83170854255987535e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.83170854255987535e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.83170854255987535e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.57713335690160534e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.57713335690160534e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.57713335690160534e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.14316152707005074e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.14316152707005074e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.14316152707005074e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.81313607667933752e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.81313607667933752e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.81313607667933752e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.03303986540091728e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.03303986540091728e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.03303986540091728e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.21303825714762979e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.21303825714762979e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.21303825714762979e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.39629848251240588e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.39629848251240588e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.39629848251240588e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.49169730327234902e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.49169730327234902e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.49169730327234902e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.12714061380216378e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.12714061380216378e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.12714061380216378e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 2.59433794441872237e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.59433794441872237e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.59433794441872237e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.80569521794795946e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.80569521794795946e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.80569521794795946e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 2.78388256498426131e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.78388256498426131e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.78388256498426131e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.63777398420230099e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 1.63777398420230099e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.63777398420230099e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.91558268595615544e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.91558268595615544e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.91558268595615544e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.73270085162941200e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 1.73270085162941200e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.73270085162941200e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.50315361445990703e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.50315361445990703e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.50315361445990703e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.52799713403038510e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.52799713403038510e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.52799713403038510e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.60956176797493129e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.60956176797493129e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.60956176797493129e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 2.30538204661583023e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 2.30538204661583023e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 2.30538204661583023e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.26297800666694826e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.26297800666694826e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.26297800666694826e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.48232257164592625e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.48232257164592625e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.48232257164592625e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 1.69430272919841315e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.69430272919841315e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.69430272919841315e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.18413417831535526e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.18413417831535526e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.18413417831535526e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 1.96946807391077016e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.96946807391077016e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.96946807391077016e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.61347557322047294e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.61347557322047294e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.61347557322047294e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.55526657151459688e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.55526657151459688e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.55526657151459688e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.74900018377789991e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.74900018377789991e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.74900018377789991e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.59944919493042789e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.59944919493042789e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.59944919493042789e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.20886939450341174e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.20886939450341174e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.20886939450341174e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.82025089460646313e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.82025089460646313e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 2.82025089460646313e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.92951806425943403e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.92951806425943403e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.92951806425943403e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.45624597405682039e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.45624597405682039e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 3.45624597405682039e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.50928165617601223e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.50928165617601223e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 2.50928165617601223e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.92295883562970232e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.92295883562970232e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.92295883562970232e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.81310480065791694e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.81310480065791694e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.81310480065791694e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.44643006914531611e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.44643006914531611e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.44643006914531611e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.06588478703366832e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 3.06588478703366832e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.06588478703366832e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.94417702978591251e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.94417702978591251e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.94417702978591251e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.95351242598370447e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.95351242598370447e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 2.95351242598370447e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 2.90488537999087953e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 2.90488537999087953e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 2.90488537999087953e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.55264992143409700e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.55264992143409700e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.55264992143409700e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.86647491919760171e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 2.86647491919760171e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 2.86647491919760171e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 3.74664507371530419e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.74664507371530419e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.74664507371530419e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.12557032926830686e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.12557032926830686e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.12557032926830686e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.57476540797931079e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.57476540797931079e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.57476540797931079e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.28035875997128414e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 3.28035875997128414e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.28035875997128414e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.46294103952652506e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.46294103952652506e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.46294103952652506e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.70856530131683485e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.70856530131683485e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.70856530131683485e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.66752538576023312e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.66752538576023312e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.66752538576023312e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.09681824722852639e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.09681824722852639e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.09681824722852639e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.58100867407115288e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.58100867407115288e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.58100867407115288e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.67370508177588590e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.67370508177588590e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.67370508177588590e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.53848173178933166e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.53848173178933166e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.53848173178933166e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.36723188563009558e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.36723188563009558e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.36723188563009558e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.07840244926945559e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 3.07840244926945559e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.07840244926945559e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.34146263191608227e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.34146263191608227e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.34146263191608227e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.78153854776101298e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.78153854776101298e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.78153854776101298e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.60494428954937405e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.60494428954937405e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.60494428954937405e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 4.12006004404229770e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 4.12006004404229770e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 4.12006004404229770e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.77546602931659436e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 3.77546602931659436e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.77546602931659436e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.46456439704216734e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.46456439704216734e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.46456439704216734e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.70025598766917312e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.70025598766917312e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.70025598766917312e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.58528761816180975e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.58528761816180975e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.58528761816180975e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.40791971572200453e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.40791971572200453e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.40791971572200453e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.22850514804470151e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.22850514804470151e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.22850514804470151e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.80777568730067287e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.80777568730067287e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 3.80777568730067287e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.53851861940349522e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.53851861940349522e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.53851861940349522e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 4.01772995168980401e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 4.01772995168980401e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 4.01772995168980401e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.16710089480078523e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.16710089480078523e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.16710089480078523e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.38531751062196065e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.38531751062196065e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.38531751062196065e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.38478168869445199e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.38478168869445199e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.38478168869445199e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.53919127074424352e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.53919127074424352e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.53919127074424352e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.83751697410930660e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.83751697410930660e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.83751697410930660e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.16913603478190737e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.16913603478190737e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.16913603478190737e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.63503223116375693e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.63503223116375693e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.63503223116375693e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.74584015328493081e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 3.74584015328493081e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.74584015328493081e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.18894245715969760e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.18894245715969760e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.18894245715969760e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.06374627165195307e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.06374627165195307e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.06374627165195307e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 4.01394222627619229e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 4.01394222627619229e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 4.01394222627619229e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.53238276723399508e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.53238276723399508e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.53238276723399508e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.44624932827741134e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.44624932827741134e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.44624932827741134e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.15270042353393819e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.15270042353393819e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.15270042353393819e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.03310925737985713e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.03310925737985713e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.03310925737985713e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.28267102103591128e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.28267102103591128e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.28267102103591128e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.19985633942971333e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.19985633942971333e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.19985633942971333e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.64460785562632680e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.64460785562632680e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.64460785562632680e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.87438246530448623e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.87438246530448623e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.87438246530448623e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.31788666190430206e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.31788666190430206e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.31788666190430206e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.23266844878079496e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.23266844878079496e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.23266844878079496e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 4.20063627812252719e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 4.20063627812252719e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.20063627812252719e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.34124782841208798e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.34124782841208798e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.34124782841208798e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.80503781701772937e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 3.80503781701772937e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.80503781701772937e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.18256590228277901e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.18256590228277901e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.18256590228277901e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.33848136922997014e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.33848136922997014e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.33848136922997014e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.78885171121294547e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.78885171121294547e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.78885171121294547e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.06248688845255515e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.06248688845255515e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.06248688845255515e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.34939330262444798e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.34939330262444798e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.34939330262444798e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.07920487624422719e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.07920487624422719e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.07920487624422719e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.10965669273886078e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.10965669273886078e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.10965669273886078e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 2.99915629062743733e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 2.99915629062743733e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.99915629062743733e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.45341413416447494e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.45341413416447494e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.45341413416447494e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.67346797827043588e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.67346797827043588e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 3.67346797827043588e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.07783380306745130e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.07783380306745130e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.07783380306745130e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.69333284491961308e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.69333284491961308e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.69333284491961308e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.45783547897049743e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.45783547897049743e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.45783547897049743e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 2.89480866910967052e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 2.89480866910967052e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.89480866910967052e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.09366963219862669e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.09366963219862669e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.09366963219862669e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 3.06875486959386535e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.06875486959386535e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 3.06875486959386535e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.22097719984308071e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.22097719984308071e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.22097719984308071e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.98826242663196751e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.98826242663196751e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 2.98826242663196751e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.42467265835497528e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.42467265835497528e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.42467265835497528e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 2.88256172015735103e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.88256172015735103e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.88256172015735103e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 3.02663991738087930e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.02663991738087930e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.02663991738087930e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 3.02027217227337587e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.02027217227337587e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.02027217227337587e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.10397679020358055e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.10397679020358055e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.10397679020358055e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.54038888482357208e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.54038888482357208e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.54038888482357208e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.54291632079306737e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.54291632079306737e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.54291632079306737e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.53409689838539748e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.53409689838539748e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.53409689838539748e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.54021352212897000e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.54021352212897000e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.54021352212897000e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 2.93571145283167539e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 2.93571145283167539e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.93571145283167539e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 2.76977324341659880e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 2.76977324341659880e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 2.76977324341659880e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.77850194315246256e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.77850194315246256e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.77850194315246256e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 3.19904755315466673e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 3.19904755315466673e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.19904755315466673e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.97552064652023489e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.97552064652023489e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.97552064652023489e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.14210496710745588e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.14210496710745588e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.14210496710745588e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.79002860571427050e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.79002860571427050e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.79002860571427050e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.40046376935038893e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.40046376935038893e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.40046376935038893e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.38973771869893426e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.38973771869893426e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.38973771869893426e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.37332986733491452e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.37332986733491452e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.37332986733491452e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.98359070901811947e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 2.98359070901811947e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 2.98359070901811947e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.04041615755429120e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.04041615755429120e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.04041615755429120e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.88738293588960858e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.88738293588960858e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.88738293588960858e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.12053323238473126e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.12053323238473126e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.12053323238473126e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.22057073261710457e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.22057073261710457e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.22057073261710457e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.90031677473965090e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 2.90031677473965090e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.90031677473965090e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.27477400063222831e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.27477400063222831e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.27477400063222831e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.74133354985446260e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.74133354985446260e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.74133354985446260e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.92639075936007430e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.92639075936007430e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.92639075936007430e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 3.00390509596517120e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.00390509596517120e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 3.00390509596517120e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.79668264370791405e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.79668264370791405e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.79668264370791405e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.89974496020584922e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.89974496020584922e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.89974496020584922e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.77298517223852741e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.77298517223852741e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.77298517223852741e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 2.96395164825749703e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.96395164825749703e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.96395164825749703e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.01096088545182567e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.01096088545182567e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.01096088545182567e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.10361880361639237e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 3.10361880361639237e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.10361880361639237e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.14002631074139629e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.14002631074139629e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.14002631074139629e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.75850644492675883e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 3.75850644492675883e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.75850644492675883e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.66124960695397590e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.66124960695397590e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.66124960695397590e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.90066747720425610e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 2.90066747720425610e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.90066747720425610e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.45934640790459810e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.45934640790459810e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.45934640790459810e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 2.47528017879597968e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 2.47528017879597968e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.47528017879597968e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 2.34963953723073473e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.34963953723073473e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.34963953723073473e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.63415126572797021e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.63415126572797021e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.63415126572797021e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.13938285609476964e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.13938285609476964e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.13938285609476964e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.79987332871660840e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.79987332871660840e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.79987332871660840e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 3.11519040065451179e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.11519040065451179e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.11519040065451179e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.00736960385631392e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 3.00736960385631392e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 3.00736960385631392e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.75421359033133172e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 3.75421359033133172e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 3.75421359033133172e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.19687530344245431e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.19687530344245431e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.19687530344245431e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 4.01339368324445672e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 4.01339368324445672e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.01339368324445672e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 2.31808129984582592e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.31808129984582592e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.31808129984582592e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.74794906552405678e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.74794906552405678e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.74794906552405678e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.34553018690106228e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.34553018690106228e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.34553018690106228e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.17215401430717691e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.17215401430717691e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.17215401430717691e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 2.69861696810639330e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 2.69861696810639330e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 2.69861696810639330e+02,
              9.10415000000000000e+04 7.39459750000000058e+04 2.58258311484634646e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.58258311484634646e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.58258311484634646e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 2.92631995904411156e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 2.92631995904411156e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.92631995904411156e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.51562046171014686e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.51562046171014686e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.51562046171014686e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 4.11408132358276362e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 4.11408132358276362e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.11408132358276362e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.80244387041294203e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 2.80244387041294203e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 2.80244387041294203e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.97208807816391811e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 2.97208807816391811e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 2.97208807816391811e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 4.35434408600185520e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.35434408600185520e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 4.35434408600185520e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 3.73180653359545090e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.73180653359545090e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.73180653359545090e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.15746900157180448e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.15746900157180448e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.15746900157180448e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.21665630363575758e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.21665630363575758e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.21665630363575758e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.44424854072983578e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.44424854072983578e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.44424854072983578e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.87280973913400089e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.87280973913400089e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.87280973913400089e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.36312829733374258e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.36312829733374258e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.36312829733374258e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.55332821864543064e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.55332821864543064e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.55332821864543064e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.94790803588623987e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.94790803588623987e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.94790803588623987e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 3.29968414328849576e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.29968414328849576e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.29968414328849576e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 2.85139266499993312e+02,
              9.10415000000000000e+04 8.77741000000000058e+04 2.85139266499993312e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.85139266499993312e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.12697723159727161e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 4.12697723159727161e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 4.12697723159727161e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.94773165418951635e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.94773165418951635e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.94773165418951635e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.48151689938417690e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.48151689938417690e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.48151689938417690e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 2.49038870672254575e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.49038870672254575e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.49038870672254575e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.31952555676926181e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.31952555676926181e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.31952555676926181e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.75302163772032145e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.75302163772032145e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.75302163772032145e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.82856007671769703e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 2.82856007671769703e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.82856007671769703e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.53486969820152581e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.53486969820152581e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.53486969820152581e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 3.21167181547075984e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.21167181547075984e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.21167181547075984e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.69221110547391049e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.69221110547391049e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.69221110547391049e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.78914789214872769e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 2.78914789214872769e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.78914789214872769e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.09638809274265327e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.09638809274265327e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.09638809274265327e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.42612299578042325e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.42612299578042325e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 3.42612299578042325e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.78815653987857786e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.78815653987857786e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.78815653987857786e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.06276217406285184e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 3.06276217406285184e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.06276217406285184e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.95968992420603740e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.95968992420603740e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.95968992420603740e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.71287694847396892e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.71287694847396892e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.71287694847396892e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.87857916746791034e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.87857916746791034e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.87857916746791034e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 3.18067689588692133e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.18067689588692133e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.18067689588692133e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 3.38603327852362668e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.38603327852362668e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 3.38603327852362668e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 2.80201266699869734e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.80201266699869734e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.80201266699869734e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.59152093008803490e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.59152093008803490e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.59152093008803490e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.32364728964261644e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.32364728964261644e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.32364728964261644e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.91808628160234804e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.91808628160234804e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.91808628160234804e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.29225398280126456e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.29225398280126456e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.29225398280126456e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 2.43126513114781943e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 2.43126513114781943e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 2.43126513114781943e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 2.17288047880662873e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 2.17288047880662873e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.17288047880662873e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 1.78563428928276664e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.78563428928276664e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.78563428928276664e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.98216017876445846e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 1.98216017876445846e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 1.98216017876445846e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.75942907650029269e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.75942907650029269e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.75942907650029269e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.84870539682273005e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.84870539682273005e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 1.84870539682273005e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.68995330945012142e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 1.68995330945012142e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.68995330945012142e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.55232173213310034e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.55232173213310034e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.55232173213310034e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.73341597606747087e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.73341597606747087e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.73341597606747087e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.52303931640484393e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.52303931640484393e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.52303931640484393e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.94997313162964304e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.94997313162964304e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 3.94997313162964304e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.71072869480658142e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.71072869480658142e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.71072869480658142e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.78800960910844083e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.78800960910844083e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.78800960910844083e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.38720762161718653e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.38720762161718653e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.38720762161718653e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.72580071271984878e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.72580071271984878e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.72580071271984878e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.84484054854011504e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.84484054854011504e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.84484054854011504e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.80770966451595541e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 3.80770966451595541e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.80770966451595541e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.91733093510155300e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.91733093510155300e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.91733093510155300e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.67014153366340224e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.67014153366340224e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.67014153366340224e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.92009249626335929e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 3.92009249626335929e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.92009249626335929e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 4.16870255670401434e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 4.16870255670401434e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 4.16870255670401434e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.79769378483586081e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.79769378483586081e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.79769378483586081e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.49517397254801892e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.49517397254801892e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.49517397254801892e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.84788190547380282e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.84788190547380282e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.84788190547380282e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.08989201444380114e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.08989201444380114e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.08989201444380114e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.14122762448499202e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.14122762448499202e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.14122762448499202e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.27347141807742446e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.27347141807742446e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.27347141807742446e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.56029043397759722e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 3.56029043397759722e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.56029043397759722e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.59305246254036888e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.59305246254036888e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.59305246254036888e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.09850278204363974e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.09850278204363974e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.09850278204363974e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.38119653504090138e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.38119653504090138e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.38119653504090138e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.32607765147381031e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.32607765147381031e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.32607765147381031e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.69506398724088456e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.69506398724088456e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 3.69506398724088456e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.54839552467666124e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.54839552467666124e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.54839552467666124e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 3.37995920586035595e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.37995920586035595e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.37995920586035595e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.43315544831593229e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.43315544831593229e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.43315544831593229e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.30895893762968512e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.30895893762968512e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.30895893762968512e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.46100204840795129e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.46100204840795129e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.46100204840795129e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 3.26300946008469737e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.26300946008469737e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.26300946008469737e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.27727875777292297e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.27727875777292297e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.27727875777292297e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.38446393623290078e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.38446393623290078e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.38446393623290078e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.14160766709268501e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.14160766709268501e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.14160766709268501e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.11548512344594258e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.11548512344594258e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.11548512344594258e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.27962831626762522e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.27962831626762522e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.27962831626762522e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.12659525830042242e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.12659525830042242e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.12659525830042242e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 3.48683622917772084e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.48683622917772084e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.48683622917772084e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.41344550759914853e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.41344550759914853e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.41344550759914853e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.65004661808166929e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.65004661808166929e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.65004661808166929e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 3.17041735372502444e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 3.17041735372502444e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.17041735372502444e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.40252381901020044e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.40252381901020044e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.40252381901020044e+02,
              9.10415000000000000e+04 7.39459750000000058e+04 2.77543628190308937e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.77543628190308937e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.77543628190308937e+02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+01"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 5.00000000000000028e-02"
              shininess="3.90625000000000000e-02"
              transparency="2.00000002980232239e-01"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1446 1447 1 0 -1 
            2 1448 1447 1 2 -1 
            0 1446 1448 2 0 -1 
            0 1446 1448 2 0 -1 
            0 1 2 0 -1 
            1446 1447 1448 1446 -1 
            3 1449 1450 4 3 -1 
            5 1451 1450 4 5 -1 
            3 1449 1451 5 3 -1 
            3 1449 1451 5 3 -1 
            3 4 5 3 -1 
            1449 1450 1451 1449 -1 
            6 1452 1453 7 6 -1 
            8 1454 1453 7 8 -1 
            6 1452 1454 8 6 -1 
            6 1452 1454 8 6 -1 
            6 7 8 6 -1 
            1452 1453 1454 1452 -1 
            9 1455 1456 10 9 -1 
            11 1457 1456 10 11 -1 
            9 1455 1457 11 9 -1 
            9 1455 1457 11 9 -1 
            9 10 11 9 -1 
            1455 1456 1457 1455 -1 
            12 1458 1459 13 12 -1 
            14 1460 1459 13 14 -1 
            12 1458 1460 14 12 -1 
            12 1458 1460 14 12 -1 
            12 13 14 12 -1 
            1458 1459 1460 1458 -1 
            15 1461 1462 16 15 -1 
            17 1463 1462 16 17 -1 
            15 1461 1463 17 15 -1 
            15 1461 1463 17 15 -1 
            15 16 17 15 -1 
            1461 1462 1463 1461 -1 
            18 1464 1465 19 18 -1 
            20 1466 1465 19 20 -1 
            18 1464 1466 20 18 -1 
            18 1464 1466 20 18 -1 
            18 19 20 18 -1 
            1464 1465 1466 1464 -1 
            21 1467 1468 22 21 -1 
            23 1469 1468 22 23 -1 
            21 1467 1469 23 21 -1 
            21 1467 1469 23 21 -1 
            21 22 23 21 -1 
            1467 1468 1469 1467 -1 
            24 1470 1471 25 24 -1 
            26 1472 1471 25 26 -1 
            24 1470 1472 26 24 -1 
            24 1470 1472 26 24 -1 
            24 25 26 24 -1 
            1470 1471 1472 1470 -1 
            27 1473 1474 28 27 -1 
            29 1475 1474 28 29 -1 
            27 1473 1475 29 27 -1 
            27 1473 1475 29 27 -1 
            27 28 29 27 -1 
            1473 1474 1475 1473 -1 
            30 1476 1477 31 30 -1 
            32 1478 1477 31 32 -1 
            30 1476 1478 32 30 -1 
            30 1476 1478 32 30 -1 
            30 31 32 30 -1 
            1476 1477 1478 1476 -1 
            33 1479 1480 34 33 -1 
            35 1481 1480 34 35 -1 
            33 1479 1481 35 33 -1 
            33 1479 1481 35 33 -1 
            33 34 35 33 -1 
            1479 1480 1481 1479 -1 
            36 1482 1483 37 36 -1 
            38 1484 1483 37 38 -1 
            36 1482 1484 38 36 -1 
            36 1482 1484 38 36 -1 
            36 37 38 36 -1 
            1482 1483 1484 1482 -1 
            39 1485 1486 40 39 -1 
            41 1487 1486 40 41 -1 
            39 1485 1487 41 39 -1 
            39 1485 1487 41 39 -1 
            39 40 41 39 -1 
            1485 1486 1487 1485 -1 
            42 1488 1489 43 42 -1 
            44 1490 1489 43 44 -1 
            42 1488 1490 44 42 -1 
            42 1488 1490 44 42 -1 
            42 43 44 42 -1 
            1488 1489 1490 1488 -1 
            45 1491 1492 46 45 -1 
            47 1493 1492 46 47 -1 
            45 1491 1493 47 45 -1 
            45 1491 1493 47 45 -1 
            45 46 47 45 -1 
            1491 1492 1493 1491 -1 
            48 1494 1495 49 48 -1 
            50 1496 1495 49 50 -1 
            48 1494 1496 50 48 -1 
            48 1494 1496 50 48 -1 
            48 49 50 48 -1 
            1494 1495 1496 1494 -1 
            51 1497 1498 52 51 -1 
            53 1499 1498 52 53 -1 
            51 1497 1499 53 51 -1 
            51 1497 1499 53 51 -1 
            51 52 53 51 -1 
            1497 1498 1499 1497 -1 
            54 1500 1501 55 54 -1 
            56 1502 1501 55 56 -1 
            54 1500 1502 56 54 -1 
            54 1500 1502 56 54 -1 
            54 55 56 54 -1 
            1500 1501 1502 1500 -1 
            57 1503 1504 58 57 -1 
            59 1505 1504 58 59 -1 
            57 1503 1505 59 57 -1 
            57 1503 1505 59 57 -1 
            57 58 59 57 -1 
            1503 1504 1505 1503 -1 
            60 1506 1507 61 60 -1 
            62 1508 1507 61 62 -1 
            60 1506 1508 62 60 -1 
            60 1506 1508 62 60 -1 
            60 61 62 60 -1 
            1506 1507 1508 1506 -1 
            63 1509 1510 64 63 -1 
            65 1511 1510 64 65 -1 
            63 1509 1511 65 63 -1 
            63 1509 1511 65 63 -1 
            63 64 65 63 -1 
            1509 1510 1511 1509 -1 
            66 1512 1513 67 66 -1 
            68 1514 1513 67 68 -1 
            66 1512 1514 68 66 -1 
            66 1512 1514 68 66 -1 
            66 67 68 66 -1 
            1512 1513 1514 1512 -1 
            69 1515 1516 70 69 -1 
            71 1517 1516 70 71 -1 
            69 1515 1517 71 69 -1 
            69 1515 1517 71 69 -1 
            69 70 71 69 -1 
            1515 1516 1517 1515 -1 
            72 1518 1519 73 72 -1 
            74 1520 1519 73 74 -1 
            72 1518 1520 74 72 -1 
            72 1518 1520 74 72 -1 
            72 73 74 72 -1 
            1518 1519 1520 1518 -1 
            75 1521 1522 76 75 -1 
            77 1523 1522 76 77 -1 
            75 1521 1523 77 75 -1 
            75 1521 1523 77 75 -1 
            75 76 77 75 -1 
            1521 1522 1523 1521 -1 
            78 1524 1525 79 78 -1 
            80 1526 1525 79 80 -1 
            78 1524 1526 80 78 -1 
            78 1524 1526 80 78 -1 
            78 79 80 78 -1 
            1524 1525 1526 1524 -1 
            81 1527 1528 82 81 -1 
            83 1529 1528 82 83 -1 
            81 1527 1529 83 81 -1 
            81 1527 1529 83 81 -1 
            81 82 83 81 -1 
            1527 1528 1529 1527 -1 
            84 1530 1531 85 84 -1 
            86 1532 1531 85 86 -1 
            84 1530 1532 86 84 -1 
            84 1530 1532 86 84 -1 
            84 85 86 84 -1 
            1530 1531 1532 1530 -1 
            87 1533 1534 88 87 -1 
            89 1535 1534 88 89 -1 
            87 1533 1535 89 87 -1 
            87 1533 1535 89 87 -1 
            87 88 89 87 -1 
            1533 1534 1535 1533 -1 
            90 1536 1537 91 90 -1 
            92 1538 1537 91 92 -1 
            90 1536 1538 92 90 -1 
            90 1536 1538 92 90 -1 
            90 91 92 90 -1 
            1536 1537 1538 1536 -1 
            93 1539 1540 94 93 -1 
            95 1541 1540 94 95 -1 
            93 1539 1541 95 93 -1 
            93 1539 1541 95 93 -1 
            93 94 95 93 -1 
            1539 1540 1541 1539 -1 
            96 1542 1543 97 96 -1 
            98 1544 1543 97 98 -1 
            96 1542 1544 98 96 -1 
            96 1542 1544 98 96 -1 
            96 97 98 96 -1 
            1542 1543 1544 1542 -1 
            99 1545 1546 100 99 -1 
            101 1547 1546 100 101 -1 
            99 1545 1547 101 99 -1 
            99 1545 1547 101 99 -1 
            99 100 101 99 -1 
            1545 1546 1547 1545 -1 
            102 1548 1549 103 102 -1 
            104 1550 1549 103 104 -1 
            102 1548 1550 104 102 -1 
            102 1548 1550 104 102 -1 
            102 103 104 102 -1 
            1548 1549 1550 1548 -1 
            105 1551 1552 106 105 -1 
            107 1553 1552 106 107 -1 
            105 1551 1553 107 105 -1 
            105 1551 1553 107 105 -1 
            105 106 107 105 -1 
            1551 1552 1553 1551 -1 
            108 1554 1555 109 108 -1 
            110 1556 1555 109 110 -1 
            108 1554 1556 110 108 -1 
            108 1554 1556 110 108 -1 
            108 109 110 108 -1 
            1554 1555 1556 1554 -1 
            111 1557 1558 112 111 -1 
            113 1559 1558 112 113 -1 
            111 1557 1559 113 111 -1 
            111 1557 1559 113 111 -1 
            111 112 113 111 -1 
            1557 1558 1559 1557 -1 
            114 1560 1561 115 114 -1 
            116 1562 1561 115 116 -1 
            114 1560 1562 116 114 -1 
            114 1560 1562 116 114 -1 
            114 115 116 114 -1 
            1560 1561 1562 1560 -1 
            117 1563 1564 118 117 -1 
            119 1565 1564 118 119 -1 
            117 1563 1565 119 117 -1 
            117 1563 1565 119 117 -1 
            117 118 119 117 -1 
            1563 1564 1565 1563 -1 
            120 1566 1567 121 120 -1 
            122 1568 1567 121 122 -1 
            120 1566 1568 122 120 -1 
            120 1566 1568 122 120 -1 
            120 121 122 120 -1 
            1566 1567 1568 1566 -1 
            123 1569 1570 124 123 -1 
            125 1571 1570 124 125 -1 
            123 1569 1571 125 123 -1 
            123 1569 1571 125 123 -1 
            123 124 125 123 -1 
            1569 1570 1571 1569 -1 
            126 1572 1573 127 126 -1 
            128 1574 1573 127 128 -1 
            126 1572 1574 128 126 -1 
            126 1572 1574 128 126 -1 
            126 127 128 126 -1 
            1572 1573 1574 1572 -1 
            129 1575 1576 130 129 -1 
            131 1577 1576 130 131 -1 
            129 1575 1577 131 129 -1 
            129 1575 1577 131 129 -1 
            129 130 131 129 -1 
            1575 1576 1577 1575 -1 
            132 1578 1579 133 132 -1 
            134 1580 1579 133 134 -1 
            132 1578 1580 134 132 -1 
            132 1578 1580 134 132 -1 
            132 133 134 132 -1 
            1578 1579 1580 1578 -1 
            135 1581 1582 136 135 -1 
            137 1583 1582 136 137 -1 
            135 1581 1583 137 135 -1 
            135 1581 1583 137 135 -1 
            135 136 137 135 -1 
            1581 1582 1583 1581 -1 
            138 1584 1585 139 138 -1 
            140 1586 1585 139 140 -1 
            138 1584 1586 140 138 -1 
            138 1584 1586 140 138 -1 
            138 139 140 138 -1 
            1584 1585 1586 1584 -1 
            141 1587 1588 142 141 -1 
            143 1589 1588 142 143 -1 
            141 1587 1589 143 141 -1 
            141 1587 1589 143 141 -1 
            141 142 143 141 -1 
            1587 1588 1589 1587 -1 
            144 1590 1591 145 144 -1 
            146 1592 1591 145 146 -1 
            144 1590 1592 146 144 -1 
            144 1590 1592 146 144 -1 
            144 145 146 144 -1 
            1590 1591 1592 1590 -1 
            147 1593 1594 148 147 -1 
            149 1595 1594 148 149 -1 
            147 1593 1595 149 147 -1 
            147 1593 1595 149 147 -1 
            147 148 149 147 -1 
            1593 1594 1595 1593 -1 
            150 1596 1597 151 150 -1 
            152 1598 1597 151 152 -1 
            150 1596 1598 152 150 -1 
            150 1596 1598 152 150 -1 
            150 151 152 150 -1 
            1596 1597 1598 1596 -1 
            153 1599 1600 154 153 -1 
            155 1601 1600 154 155 -1 
            153 1599 1601 155 153 -1 
            153 1599 1601 155 153 -1 
            153 154 155 153 -1 
            1599 1600 1601 1599 -1 
            156 1602 1603 157 156 -1 
            158 1604 1603 157 158 -1 
            156 1602 1604 158 156 -1 
            156 1602 1604 158 156 -1 
            156 157 158 156 -1 
            1602 1603 1604 1602 -1 
            159 1605 1606 160 159 -1 
            161 1607 1606 160 161 -1 
            159 1605 1607 161 159 -1 
            159 1605 1607 161 159 -1 
            159 160 161 159 -1 
            1605 1606 1607 1605 -1 
            162 1608 1609 163 162 -1 
            164 1610 1609 163 164 -1 
            162 1608 1610 164 162 -1 
            162 1608 1610 164 162 -1 
            162 163 164 162 -1 
            1608 1609 1610 1608 -1 
            165 1611 1612 166 165 -1 
            167 1613 1612 166 167 -1 
            165 1611 1613 167 165 -1 
            165 1611 1613 167 165 -1 
            165 166 167 165 -1 
            1611 1612 1613 1611 -1 
            168 1614 1615 169 168 -1 
            170 1616 1615 169 170 -1 
            168 1614 1616 170 168 -1 
            168 1614 1616 170 168 -1 
            168 169 170 168 -1 
            1614 1615 1616 1614 -1 
            171 1617 1618 172 171 -1 
            173 1619 1618 172 173 -1 
            171 1617 1619 173 171 -1 
            171 1617 1619 173 171 -1 
            171 172 173 171 -1 
            1617 1618 1619 1617 -1 
            174 1620 1621 175 174 -1 
            176 1622 1621 175 176 -1 
            174 1620 1622 176 174 -1 
            174 1620 1622 176 174 -1 
            174 175 176 174 -1 
            1620 1621 1622 1620 -1 
            177 1623 1624 178 177 -1 
            179 1625 1624 178 179 -1 
            177 1623 1625 179 177 -1 
            177 1623 1625 179 177 -1 
            177 178 179 177 -1 
            1623 1624 1625 1623 -1 
            180 1626 1627 181 180 -1 
            182 1628 1627 181 182 -1 
            180 1626 1628 182 180 -1 
            180 1626 1628 182 180 -1 
            180 181 182 180 -1 
            1626 1627 1628 1626 -1 
            183 1629 1630 184 183 -1 
            185 1631 1630 184 185 -1 
            183 1629 1631 185 183 -1 
            183 1629 1631 185 183 -1 
            183 184 185 183 -1 
            1629 1630 1631 1629 -1 
            186 1632 1633 187 186 -1 
            188 1634 1633 187 188 -1 
            186 1632 1634 188 186 -1 
            186 1632 1634 188 186 -1 
            186 187 188 186 -1 
            1632 1633 1634 1632 -1 
            189 1635 1636 190 189 -1 
            191 1637 1636 190 191 -1 
            189 1635 1637 191 189 -1 
            189 1635 1637 191 189 -1 
            189 190 191 189 -1 
            1635 1636 1637 1635 -1 
            192 1638 1639 193 192 -1 
            194 1640 1639 193 194 -1 
            192 1638 1640 194 192 -1 
            192 1638 1640 194 192 -1 
            192 193 194 192 -1 
            1638 1639 1640 1638 -1 
            195 1641 1642 196 195 -1 
            197 1643 1642 196 197 -1 
            195 1641 1643 197 195 -1 
            195 1641 1643 197 195 -1 
            195 196 197 195 -1 
            1641 1642 1643 1641 -1 
            198 1644 1645 199 198 -1 
            200 1646 1645 199 200 -1 
            198 1644 1646 200 198 -1 
            198 1644 1646 200 198 -1 
            198 199 200 198 -1 
            1644 1645 1646 1644 -1 
            201 1647 1648 202 201 -1 
            203 1649 1648 202 203 -1 
            201 1647 1649 203 201 -1 
            201 1647 1649 203 201 -1 
            201 202 203 201 -1 
            1647 1648 1649 1647 -1 
            204 1650 1651 205 204 -1 
            206 1652 1651 205 206 -1 
            204 1650 1652 206 204 -1 
            204 1650 1652 206 204 -1 
            204 205 206 204 -1 
            1650 1651 1652 1650 -1 
            207 1653 1654 208 207 -1 
            209 1655 1654 208 209 -1 
            207 1653 1655 209 207 -1 
            207 1653 1655 209 207 -1 
            207 208 209 207 -1 
            1653 1654 1655 1653 -1 
            210 1656 1657 211 210 -1 
            212 1658 1657 211 212 -1 
            210 1656 1658 212 210 -1 
            210 1656 1658 212 210 -1 
            210 211 212 210 -1 
            1656 1657 1658 1656 -1 
            213 1659 1660 214 213 -1 
            215 1661 1660 214 215 -1 
            213 1659 1661 215 213 -1 
            213 1659 1661 215 213 -1 
            213 214 215 213 -1 
            1659 1660 1661 1659 -1 
            216 1662 1663 217 216 -1 
            218 1664 1663 217 218 -1 
            216 1662 1664 218 216 -1 
            216 1662 1664 218 216 -1 
            216 217 218 216 -1 
            1662 1663 1664 1662 -1 
            219 1665 1666 220 219 -1 
            221 1667 1666 220 221 -1 
            219 1665 1667 221 219 -1 
            219 1665 1667 221 219 -1 
            219 220 221 219 -1 
            1665 1666 1667 1665 -1 
            222 1668 1669 223 222 -1 
            224 1670 1669 223 224 -1 
            222 1668 1670 224 222 -1 
            222 1668 1670 224 222 -1 
            222 223 224 222 -1 
            1668 1669 1670 1668 -1 
            225 1671 1672 226 225 -1 
            227 1673 1672 226 227 -1 
            225 1671 1673 227 225 -1 
            225 1671 1673 227 225 -1 
            225 226 227 225 -1 
            1671 1672 1673 1671 -1 
            228 1674 1675 229 228 -1 
            230 1676 1675 229 230 -1 
            228 1674 1676 230 228 -1 
            228 1674 1676 230 228 -1 
            228 229 230 228 -1 
            1674 1675 1676 1674 -1 
            231 1677 1678 232 231 -1 
            233 1679 1678 232 233 -1 
            231 1677 1679 233 231 -1 
            231 1677 1679 233 231 -1 
            231 232 233 231 -1 
            1677 1678 1679 1677 -1 
            234 1680 1681 235 234 -1 
            236 1682 1681 235 236 -1 
            234 1680 1682 236 234 -1 
            234 1680 1682 236 234 -1 
            234 235 236 234 -1 
            1680 1681 1682 1680 -1 
            237 1683 1684 238 237 -1 
            239 1685 1684 238 239 -1 
            237 1683 1685 239 237 -1 
            237 1683 1685 239 237 -1 
            237 238 239 237 -1 
            1683 1684 1685 1683 -1 
            240 1686 1687 241 240 -1 
            242 1688 1687 241 242 -1 
            240 1686 1688 242 240 -1 
            240 1686 1688 242 240 -1 
            240 241 242 240 -1 
            1686 1687 1688 1686 -1 
            243 1689 1690 244 243 -1 
            245 1691 1690 244 245 -1 
            243 1689 1691 245 243 -1 
            243 1689 1691 245 243 -1 
            243 244 245 243 -1 
            1689 1690 1691 1689 -1 
            246 1692 1693 247 246 -1 
            248 1694 1693 247 248 -1 
            246 1692 1694 248 246 -1 
            246 1692 1694 248 246 -1 
            246 247 248 246 -1 
            1692 1693 1694 1692 -1 
            249 1695 1696 250 249 -1 
            251 1697 1696 250 251 -1 
            249 1695 1697 251 249 -1 
            249 1695 1697 251 249 -1 
            249 250 251 249 -1 
            1695 1696 1697 1695 -1 
            252 1698 1699 253 252 -1 
            254 1700 1699 253 254 -1 
            252 1698 1700 254 252 -1 
            252 1698 1700 254 252 -1 
            252 253 254 252 -1 
            1698 1699 1700 1698 -1 
            255 1701 1702 256 255 -1 
            257 1703 1702 256 257 -1 
            255 1701 1703 257 255 -1 
            255 1701 1703 257 255 -1 
            255 256 257 255 -1 
            1701 1702 1703 1701 -1 
            258 1704 1705 259 258 -1 
            260 1706 1705 259 260 -1 
            258 1704 1706 260 258 -1 
            258 1704 1706 260 258 -1 
            258 259 260 258 -1 
            1704 1705 1706 1704 -1 
            261 1707 1708 262 261 -1 
            263 1709 1708 262 263 -1 
            261 1707 1709 263 261 -1 
            261 1707 1709 263 261 -1 
            261 262 263 261 -1 
            1707 1708 1709 1707 -1 
            264 1710 1711 265 264 -1 
            266 1712 1711 265 266 -1 
            264 1710 1712 266 264 -1 
            264 1710 1712 266 264 -1 
            264 265 266 264 -1 
            1710 1711 1712 1710 -1 
            267 1713 1714 268 267 -1 
            269 1715 1714 268 269 -1 
            267 1713 1715 269 267 -1 
            267 1713 1715 269 267 -1 
            267 268 269 267 -1 
            1713 1714 1715 1713 -1 
            270 1716 1717 271 270 -1 
            272 1718 1717 271 272 -1 
            270 1716 1718 272 270 -1 
            270 1716 1718 272 270 -1 
            270 271 272 270 -1 
            1716 1717 1718 1716 -1 
            273 1719 1720 274 273 -1 
            275 1721 1720 274 275 -1 
            273 1719 1721 275 273 -1 
            273 1719 1721 275 273 -1 
            273 274 275 273 -1 
            1719 1720 1721 1719 -1 
            276 1722 1723 277 276 -1 
            278 1724 1723 277 278 -1 
            276 1722 1724 278 276 -1 
            276 1722 1724 278 276 -1 
            276 277 278 276 -1 
            1722 1723 1724 1722 -1 
            279 1725 1726 280 279 -1 
            281 1727 1726 280 281 -1 
            279 1725 1727 281 279 -1 
            279 1725 1727 281 279 -1 
            279 280 281 279 -1 
            1725 1726 1727 1725 -1 
            282 1728 1729 283 282 -1 
            284 1730 1729 283 284 -1 
            282 1728 1730 284 282 -1 
            282 1728 1730 284 282 -1 
            282 283 284 282 -1 
            1728 1729 1730 1728 -1 
            285 1731 1732 286 285 -1 
            287 1733 1732 286 287 -1 
            285 1731 1733 287 285 -1 
            285 1731 1733 287 285 -1 
            285 286 287 285 -1 
            1731 1732 1733 1731 -1 
            288 1734 1735 289 288 -1 
            290 1736 1735 289 290 -1 
            288 1734 1736 290 288 -1 
            288 1734 1736 290 288 -1 
            288 289 290 288 -1 
            1734 1735 1736 1734 -1 
            291 1737 1738 292 291 -1 
            293 1739 1738 292 293 -1 
            291 1737 1739 293 291 -1 
            291 1737 1739 293 291 -1 
            291 292 293 291 -1 
            1737 1738 1739 1737 -1 
            294 1740 1741 295 294 -1 
            296 1742 1741 295 296 -1 
            294 1740 1742 296 294 -1 
            294 1740 1742 296 294 -1 
            294 295 296 294 -1 
            1740 1741 1742 1740 -1 
            297 1743 1744 298 297 -1 
            299 1745 1744 298 299 -1 
            297 1743 1745 299 297 -1 
            297 1743 1745 299 297 -1 
            297 298 299 297 -1 
            1743 1744 1745 1743 -1 
            300 1746 1747 301 300 -1 
            302 1748 1747 301 302 -1 
            300 1746 1748 302 300 -1 
            300 1746 1748 302 300 -1 
            300 301 302 300 -1 
            1746 1747 1748 1746 -1 
            303 1749 1750 304 303 -1 
            305 1751 1750 304 305 -1 
            303 1749 1751 305 303 -1 
            303 1749 1751 305 303 -1 
            303 304 305 303 -1 
            1749 1750 1751 1749 -1 
            306 1752 1753 307 306 -1 
            308 1754 1753 307 308 -1 
            306 1752 1754 308 306 -1 
            306 1752 1754 308 306 -1 
            306 307 308 306 -1 
            1752 1753 1754 1752 -1 
            309 1755 1756 310 309 -1 
            311 1757 1756 310 311 -1 
            309 1755 1757 311 309 -1 
            309 1755 1757 311 309 -1 
            309 310 311 309 -1 
            1755 1756 1757 1755 -1 
            312 1758 1759 313 312 -1 
            314 1760 1759 313 314 -1 
            312 1758 1760 314 312 -1 
            312 1758 1760 314 312 -1 
            312 313 314 312 -1 
            1758 1759 1760 1758 -1 
            315 1761 1762 316 315 -1 
            317 1763 1762 316 317 -1 
            315 1761 1763 317 315 -1 
            315 1761 1763 317 315 -1 
            315 316 317 315 -1 
            1761 1762 1763 1761 -1 
            318 1764 1765 319 318 -1 
            320 1766 1765 319 320 -1 
            318 1764 1766 320 318 -1 
            318 1764 1766 320 318 -1 
            318 319 320 318 -1 
            1764 1765 1766 1764 -1 
            321 1767 1768 322 321 -1 
            323 1769 1768 322 323 -1 
            321 1767 1769 323 321 -1 
            321 1767 1769 323 321 -1 
            321 322 323 321 -1 
            1767 1768 1769 1767 -1 
            324 1770 1771 325 324 -1 
            326 1772 1771 325 326 -1 
            324 1770 1772 326 324 -1 
            324 1770 1772 326 324 -1 
            324 325 326 324 -1 
            1770 1771 1772 1770 -1 
            327 1773 1774 328 327 -1 
            329 1775 1774 328 329 -1 
            327 1773 1775 329 327 -1 
            327 1773 1775 329 327 -1 
            327 328 329 327 -1 
            1773 1774 1775 1773 -1 
            330 1776 1777 331 330 -1 
            332 1778 1777 331 332 -1 
            330 1776 1778 332 330 -1 
            330 1776 1778 332 330 -1 
            330 331 332 330 -1 
            1776 1777 1778 1776 -1 
            333 1779 1780 334 333 -1 
            335 1781 1780 334 335 -1 
            333 1779 1781 335 333 -1 
            333 1779 1781 335 333 -1 
            333 334 335 333 -1 
            1779 1780 1781 1779 -1 
            336 1782 1783 337 336 -1 
            338 1784 1783 337 338 -1 
            336 1782 1784 338 336 -1 
            336 1782 1784 338 336 -1 
            336 337 338 336 -1 
            1782 1783 1784 1782 -1 
            339 1785 1786 340 339 -1 
            341 1787 1786 340 341 -1 
            339 1785 1787 341 339 -1 
            339 1785 1787 341 339 -1 
            339 340 341 339 -1 
            1785 1786 1787 1785 -1 
            342 1788 1789 343 342 -1 
            344 1790 1789 343 344 -1 
            342 1788 1790 344 342 -1 
            342 1788 1790 344 342 -1 
            342 343 344 342 -1 
            1788 1789 1790 1788 -1 
            345 1791 1792 346 345 -1 
            347 1793 1792 346 347 -1 
            345 1791 1793 347 345 -1 
            345 1791 1793 347 345 -1 
            345 346 347 345 -1 
            1791 1792 1793 1791 -1 
            348 1794 1795 349 348 -1 
            350 1796 1795 349 350 -1 
            348 1794 1796 350 348 -1 
            348 1794 1796 350 348 -1 
            348 349 350 348 -1 
            1794 1795 1796 1794 -1 
            351 1797 1798 352 351 -1 
            353 1799 1798 352 353 -1 
            351 1797 1799 353 351 -1 
            351 1797 1799 353 351 -1 
            351 352 353 351 -1 
            1797 1798 1799 1797 -1 
            354 1800 1801 355 354 -1 
            356 1802 1801 355 356 -1 
            354 1800 1802 356 354 -1 
            354 1800 1802 356 354 -1 
            354 355 356 354 -1 
            1800 1801 1802 1800 -1 
            357 1803 1804 358 357 -1 
            359 1805 1804 358 359 -1 
            357 1803 1805 359 357 -1 
            357 1803 1805 359 357 -1 
            357 358 359 357 -1 
            1803 1804 1805 1803 -1 
            360 1806 1807 361 360 -1 
            362 1808 1807 361 362 -1 
            360 1806 1808 362 360 -1 
            360 1806 1808 362 360 -1 
            360 361 362 360 -1 
            1806 1807 1808 1806 -1 
            363 1809 1810 364 363 -1 
            365 1811 1810 364 365 -1 
            363 1809 1811 365 363 -1 
            363 1809 1811 365 363 -1 
            363 364 365 363 -1 
            1809 1810 1811 1809 -1 
            366 1812 1813 367 366 -1 
            368 1814 1813 367 368 -1 
            366 1812 1814 368 366 -1 
            366 1812 1814 368 366 -1 
            366 367 368 366 -1 
            1812 1813 1814 1812 -1 
            369 1815 1816 370 369 -1 
            371 1817 1816 370 371 -1 
            369 1815 1817 371 369 -1 
            369 1815 1817 371 369 -1 
            369 370 371 369 -1 
            1815 1816 1817 1815 -1 
            372 1818 1819 373 372 -1 
            374 1820 1819 373 374 -1 
            372 1818 1820 374 372 -1 
            372 1818 1820 374 372 -1 
            372 373 374 372 -1 
            1818 1819 1820 1818 -1 
            375 1821 1822 376 375 -1 
            377 1823 1822 376 377 -1 
            375 1821 1823 377 375 -1 
            375 1821 1823 377 375 -1 
            375 376 377 375 -1 
            1821 1822 1823 1821 -1 
            378 1824 1825 379 378 -1 
            380 1826 1825 379 380 -1 
            378 1824 1826 380 378 -1 
            378 1824 1826 380 378 -1 
            378 379 380 378 -1 
            1824 1825 1826 1824 -1 
            381 1827 1828 382 381 -1 
            383 1829 1828 382 383 -1 
            381 1827 1829 383 381 -1 
            381 1827 1829 383 381 -1 
            381 382 383 381 -1 
            1827 1828 1829 1827 -1 
            384 1830 1831 385 384 -1 
            386 1832 1831 385 386 -1 
            384 1830 1832 386 384 -1 
            384 1830 1832 386 384 -1 
            384 385 386 384 -1 
            1830 1831 1832 1830 -1 
            387 1833 1834 388 387 -1 
            389 1835 1834 388 389 -1 
            387 1833 1835 389 387 -1 
            387 1833 1835 389 387 -1 
            387 388 389 387 -1 
            1833 1834 1835 1833 -1 
            390 1836 1837 391 390 -1 
            392 1838 1837 391 392 -1 
            390 1836 1838 392 390 -1 
            390 1836 1838 392 390 -1 
            390 391 392 390 -1 
            1836 1837 1838 1836 -1 
            393 1839 1840 394 393 -1 
            395 1841 1840 394 395 -1 
            393 1839 1841 395 393 -1 
            393 1839 1841 395 393 -1 
            393 394 395 393 -1 
            1839 1840 1841 1839 -1 
            396 1842 1843 397 396 -1 
            398 1844 1843 397 398 -1 
            396 1842 1844 398 396 -1 
            396 1842 1844 398 396 -1 
            396 397 398 396 -1 
            1842 1843 1844 1842 -1 
            399 1845 1846 400 399 -1 
            401 1847 1846 400 401 -1 
            399 1845 1847 401 399 -1 
            399 1845 1847 401 399 -1 
            399 400 401 399 -1 
            1845 1846 1847 1845 -1 
            402 1848 1849 403 402 -1 
            404 1850 1849 403 404 -1 
            402 1848 1850 404 402 -1 
            402 1848 1850 404 402 -1 
            402 403 404 402 -1 
            1848 1849 1850 1848 -1 
            405 1851 1852 406 405 -1 
            407 1853 1852 406 407 -1 
            405 1851 1853 407 405 -1 
            405 1851 1853 407 405 -1 
            405 406 407 405 -1 
            1851 1852 1853 1851 -1 
            408 1854 1855 409 408 -1 
            410 1856 1855 409 410 -1 
            408 1854 1856 410 408 -1 
            408 1854 1856 410 408 -1 
            408 409 410 408 -1 
            1854 1855 1856 1854 -1 
            411 1857 1858 412 411 -1 
            413 1859 1858 412 413 -1 
            411 1857 1859 413 411 -1 
            411 1857 1859 413 411 -1 
            411 412 413 411 -1 
            1857 1858 1859 1857 -1 
            414 1860 1861 415 414 -1 
            416 1862 1861 415 416 -1 
            414 1860 1862 416 414 -1 
            414 1860 1862 416 414 -1 
            414 415 416 414 -1 
            1860 1861 1862 1860 -1 
            417 1863 1864 418 417 -1 
            419 1865 1864 418 419 -1 
            417 1863 1865 419 417 -1 
            417 1863 1865 419 417 -1 
            417 418 419 417 -1 
            1863 1864 1865 1863 -1 
            420 1866 1867 421 420 -1 
            422 1868 1867 421 422 -1 
            420 1866 1868 422 420 -1 
            420 1866 1868 422 420 -1 
            420 421 422 420 -1 
            1866 1867 1868 1866 -1 
            423 1869 1870 424 423 -1 
            425 1871 1870 424 425 -1 
            423 1869 1871 425 423 -1 
            423 1869 1871 425 423 -1 
            423 424 425 423 -1 
            1869 1870 1871 1869 -1 
            426 1872 1873 427 426 -1 
            428 1874 1873 427 428 -1 
            426 1872 1874 428 426 -1 
            426 1872 1874 428 426 -1 
            426 427 428 426 -1 
            1872 1873 1874 1872 -1 
            429 1875 1876 430 429 -1 
            431 1877 1876 430 431 -1 
            429 1875 1877 431 429 -1 
            429 1875 1877 431 429 -1 
            429 430 431 429 -1 
            1875 1876 1877 1875 -1 
            432 1878 1879 433 432 -1 
            434 1880 1879 433 434 -1 
            432 1878 1880 434 432 -1 
            432 1878 1880 434 432 -1 
            432 433 434 432 -1 
            1878 1879 1880 1878 -1 
            435 1881 1882 436 435 -1 
            437 1883 1882 436 437 -1 
            435 1881 1883 437 435 -1 
            435 1881 1883 437 435 -1 
            435 436 437 435 -1 
            1881 1882 1883 1881 -1 
            438 1884 1885 439 438 -1 
            440 1886 1885 439 440 -1 
            438 1884 1886 440 438 -1 
            438 1884 1886 440 438 -1 
            438 439 440 438 -1 
            1884 1885 1886 1884 -1 
            441 1887 1888 442 441 -1 
            443 1889 1888 442 443 -1 
            441 1887 1889 443 441 -1 
            441 1887 1889 443 441 -1 
            441 442 443 441 -1 
            1887 1888 1889 1887 -1 
            444 1890 1891 445 444 -1 
            446 1892 1891 445 446 -1 
            444 1890 1892 446 444 -1 
            444 1890 1892 446 444 -1 
            444 445 446 444 -1 
            1890 1891 1892 1890 -1 
            447 1893 1894 448 447 -1 
            449 1895 1894 448 449 -1 
            447 1893 1895 449 447 -1 
            447 1893 1895 449 447 -1 
            447 448 449 447 -1 
            1893 1894 1895 1893 -1 
            450 1896 1897 451 450 -1 
            452 1898 1897 451 452 -1 
            450 1896 1898 452 450 -1 
            450 1896 1898 452 450 -1 
            450 451 452 450 -1 
            1896 1897 1898 1896 -1 
            453 1899 1900 454 453 -1 
            455 1901 1900 454 455 -1 
            453 1899 1901 455 453 -1 
            453 1899 1901 455 453 -1 
            453 454 455 453 -1 
            1899 1900 1901 1899 -1 
            456 1902 1903 457 456 -1 
            458 1904 1903 457 458 -1 
            456 1902 1904 458 456 -1 
            456 1902 1904 458 456 -1 
            456 457 458 456 -1 
            1902 1903 1904 1902 -1 
            459 1905 1906 460 459 -1 
            461 1907 1906 460 461 -1 
            459 1905 1907 461 459 -1 
            459 1905 1907 461 459 -1 
            459 460 461 459 -1 
            1905 1906 1907 1905 -1 
            462 1908 1909 463 462 -1 
            464 1910 1909 463 464 -1 
            462 1908 1910 464 462 -1 
            462 1908 1910 464 462 -1 
            462 463 464 462 -1 
            1908 1909 1910 1908 -1 
            465 1911 1912 466 465 -1 
            467 1913 1912 466 467 -1 
            465 1911 1913 467 465 -1 
            465 1911 1913 467 465 -1 
            465 466 467 465 -1 
            1911 1912 1913 1911 -1 
            468 1914 1915 469 468 -1 
            470 1916 1915 469 470 -1 
            468 1914 1916 470 468 -1 
            468 1914 1916 470 468 -1 
            468 469 470 468 -1 
            1914 1915 1916 1914 -1 
            471 1917 1918 472 471 -1 
            473 1919 1918 472 473 -1 
            471 1917 1919 473 471 -1 
            471 1917 1919 473 471 -1 
            471 472 473 471 -1 
            1917 1918 1919 1917 -1 
            474 1920 1921 475 474 -1 
            476 1922 1921 475 476 -1 
            474 1920 1922 476 474 -1 
            474 1920 1922 476 474 -1 
            474 475 476 474 -1 
            1920 1921 1922 1920 -1 
            477 1923 1924 478 477 -1 
            479 1925 1924 478 479 -1 
            477 1923 1925 479 477 -1 
            477 1923 1925 479 477 -1 
            477 478 479 477 -1 
            1923 1924 1925 1923 -1 
            480 1926 1927 481 480 -1 
            482 1928 1927 481 482 -1 
            480 1926 1928 482 480 -1 
            480 1926 1928 482 480 -1 
            480 481 482 480 -1 
            1926 1927 1928 1926 -1 
            483 1929 1930 484 483 -1 
            485 1931 1930 484 485 -1 
            483 1929 1931 485 483 -1 
            483 1929 1931 485 483 -1 
            483 484 485 483 -1 
            1929 1930 1931 1929 -1 
            486 1932 1933 487 486 -1 
            488 1934 1933 487 488 -1 
            486 1932 1934 488 486 -1 
            486 1932 1934 488 486 -1 
            486 487 488 486 -1 
            1932 1933 1934 1932 -1 
            489 1935 1936 490 489 -1 
            491 1937 1936 490 491 -1 
            489 1935 1937 491 489 -1 
            489 1935 1937 491 489 -1 
            489 490 491 489 -1 
            1935 1936 1937 1935 -1 
            492 1938 1939 493 492 -1 
            494 1940 1939 493 494 -1 
            492 1938 1940 494 492 -1 
            492 1938 1940 494 492 -1 
            492 493 494 492 -1 
            1938 1939 1940 1938 -1 
            495 1941 1942 496 495 -1 
            497 1943 1942 496 497 -1 
            495 1941 1943 497 495 -1 
            495 1941 1943 497 495 -1 
            495 496 497 495 -1 
            1941 1942 1943 1941 -1 
            498 1944 1945 499 498 -1 
            500 1946 1945 499 500 -1 
            498 1944 1946 500 498 -1 
            498 1944 1946 500 498 -1 
            498 499 500 498 -1 
            1944 1945 1946 1944 -1 
            501 1947 1948 502 501 -1 
            503 1949 1948 502 503 -1 
            501 1947 1949 503 501 -1 
            501 1947 1949 503 501 -1 
            501 502 503 501 -1 
            1947 1948 1949 1947 -1 
            504 1950 1951 505 504 -1 
            506 1952 1951 505 506 -1 
            504 1950 1952 506 504 -1 
            504 1950 1952 506 504 -1 
            504 505 506 504 -1 
            1950 1951 1952 1950 -1 
            507 1953 1954 508 507 -1 
            509 1955 1954 508 509 -1 
            507 1953 1955 509 507 -1 
            507 1953 1955 509 507 -1 
            507 508 509 507 -1 
            1953 1954 1955 1953 -1 
            510 1956 1957 511 510 -1 
            512 1958 1957 511 512 -1 
            510 1956 1958 512 510 -1 
            510 1956 1958 512 510 -1 
            510 511 512 510 -1 
            1956 1957 1958 1956 -1 
            513 1959 1960 514 513 -1 
            515 1961 1960 514 515 -1 
            513 1959 1961 515 513 -1 
            513 1959 1961 515 513 -1 
            513 514 515 513 -1 
            1959 1960 1961 1959 -1 
            516 1962 1963 517 516 -1 
            518 1964 1963 517 518 -1 
            516 1962 1964 518 516 -1 
            516 1962 1964 518 516 -1 
            516 517 518 516 -1 
            1962 1963 1964 1962 -1 
            519 1965 1966 520 519 -1 
            521 1967 1966 520 521 -1 
            519 1965 1967 521 519 -1 
            519 1965 1967 521 519 -1 
            519 520 521 519 -1 
            1965 1966 1967 1965 -1 
            522 1968 1969 523 522 -1 
            524 1970 1969 523 524 -1 
            522 1968 1970 524 522 -1 
            522 1968 1970 524 522 -1 
            522 523 524 522 -1 
            1968 1969 1970 1968 -1 
            525 1971 1972 526 525 -1 
            527 1973 1972 526 527 -1 
            525 1971 1973 527 525 -1 
            525 1971 1973 527 525 -1 
            525 526 527 525 -1 
            1971 1972 1973 1971 -1 
            528 1974 1975 529 528 -1 
            530 1976 1975 529 530 -1 
            528 1974 1976 530 528 -1 
            528 1974 1976 530 528 -1 
            528 529 530 528 -1 
            1974 1975 1976 1974 -1 
            531 1977 1978 532 531 -1 
            533 1979 1978 532 533 -1 
            531 1977 1979 533 531 -1 
            531 1977 1979 533 531 -1 
            531 532 533 531 -1 
            1977 1978 1979 1977 -1 
            534 1980 1981 535 534 -1 
            536 1982 1981 535 536 -1 
            534 1980 1982 536 534 -1 
            534 1980 1982 536 534 -1 
            534 535 536 534 -1 
            1980 1981 1982 1980 -1 
            537 1983 1984 538 537 -1 
            539 1985 1984 538 539 -1 
            537 1983 1985 539 537 -1 
            537 1983 1985 539 537 -1 
            537 538 539 537 -1 
            1983 1984 1985 1983 -1 
            540 1986 1987 541 540 -1 
            542 1988 1987 541 542 -1 
            540 1986 1988 542 540 -1 
            540 1986 1988 542 540 -1 
            540 541 542 540 -1 
            1986 1987 1988 1986 -1 
            543 1989 1990 544 543 -1 
            545 1991 1990 544 545 -1 
            543 1989 1991 545 543 -1 
            543 1989 1991 545 543 -1 
            543 544 545 543 -1 
            1989 1990 1991 1989 -1 
            546 1992 1993 547 546 -1 
            548 1994 1993 547 548 -1 
            546 1992 1994 548 546 -1 
            546 1992 1994 548 546 -1 
            546 547 548 546 -1 
            1992 1993 1994 1992 -1 
            549 1995 1996 550 549 -1 
            551 1997 1996 550 551 -1 
            549 1995 1997 551 549 -1 
            549 1995 1997 551 549 -1 
            549 550 551 549 -1 
            1995 1996 1997 1995 -1 
            552 1998 1999 553 552 -1 
            554 2000 1999 553 554 -1 
            552 1998 2000 554 552 -1 
            552 1998 2000 554 552 -1 
            552 553 554 552 -1 
            1998 1999 2000 1998 -1 
            555 2001 2002 556 555 -1 
            557 2003 2002 556 557 -1 
            555 2001 2003 557 555 -1 
            555 2001 2003 557 555 -1 
            555 556 557 555 -1 
            2001 2002 2003 2001 -1 
            558 2004 2005 559 558 -1 
            560 2006 2005 559 560 -1 
            558 2004 2006 560 558 -1 
            558 2004 2006 560 558 -1 
            558 559 560 558 -1 
            2004 2005 2006 2004 -1 
            561 2007 2008 562 561 -1 
            563 2009 2008 562 563 -1 
            561 2007 2009 563 561 -1 
            561 2007 2009 563 561 -1 
            561 562 563 561 -1 
            2007 2008 2009 2007 -1 
            564 2010 2011 565 564 -1 
            566 2012 2011 565 566 -1 
            564 2010 2012 566 564 -1 
            564 2010 2012 566 564 -1 
            564 565 566 564 -1 
            2010 2011 2012 2010 -1 
            567 2013 2014 568 567 -1 
            569 2015 2014 568 569 -1 
            567 2013 2015 569 567 -1 
            567 2013 2015 569 567 -1 
            567 568 569 567 -1 
            2013 2014 2015 2013 -1 
            570 2016 2017 571 570 -1 
            572 2018 2017 571 572 -1 
            570 2016 2018 572 570 -1 
            570 2016 2018 572 570 -1 
            570 571 572 570 -1 
            2016 2017 2018 2016 -1 
            573 2019 2020 574 573 -1 
            575 2021 2020 574 575 -1 
            573 2019 2021 575 573 -1 
            573 2019 2021 575 573 -1 
            573 574 575 573 -1 
            2019 2020 2021 2019 -1 
            576 2022 2023 577 576 -1 
            578 2024 2023 577 578 -1 
            576 2022 2024 578 576 -1 
            576 2022 2024 578 576 -1 
            576 577 578 576 -1 
            2022 2023 2024 2022 -1 
            579 2025 2026 580 579 -1 
            581 2027 2026 580 581 -1 
            579 2025 2027 581 579 -1 
            579 2025 2027 581 579 -1 
            579 580 581 579 -1 
            2025 2026 2027 2025 -1 
            582 2028 2029 583 582 -1 
            584 2030 2029 583 584 -1 
            582 2028 2030 584 582 -1 
            582 2028 2030 584 582 -1 
            582 583 584 582 -1 
            2028 2029 2030 2028 -1 
            585 2031 2032 586 585 -1 
            587 2033 2032 586 587 -1 
            585 2031 2033 587 585 -1 
            585 2031 2033 587 585 -1 
            585 586 587 585 -1 
            2031 2032 2033 2031 -1 
            588 2034 2035 589 588 -1 
            590 2036 2035 589 590 -1 
            588 2034 2036 590 588 -1 
            588 2034 2036 590 588 -1 
            588 589 590 588 -1 
            2034 2035 2036 2034 -1 
            591 2037 2038 592 591 -1 
            593 2039 2038 592 593 -1 
            591 2037 2039 593 591 -1 
            591 2037 2039 593 591 -1 
            591 592 593 591 -1 
            2037 2038 2039 2037 -1 
            594 2040 2041 595 594 -1 
            596 2042 2041 595 596 -1 
            594 2040 2042 596 594 -1 
            594 2040 2042 596 594 -1 
            594 595 596 594 -1 
            2040 2041 2042 2040 -1 
            597 2043 2044 598 597 -1 
            599 2045 2044 598 599 -1 
            597 2043 2045 599 597 -1 
            597 2043 2045 599 597 -1 
            597 598 599 597 -1 
            2043 2044 2045 2043 -1 
            600 2046 2047 601 600 -1 
            602 2048 2047 601 602 -1 
            600 2046 2048 602 600 -1 
            600 2046 2048 602 600 -1 
            600 601 602 600 -1 
            2046 2047 2048 2046 -1 
            603 2049 2050 604 603 -1 
            605 2051 2050 604 605 -1 
            603 2049 2051 605 603 -1 
            603 2049 2051 605 603 -1 
            603 604 605 603 -1 
            2049 2050 2051 2049 -1 
            606 2052 2053 607 606 -1 
            608 2054 2053 607 608 -1 
            606 2052 2054 608 606 -1 
            606 2052 2054 608 606 -1 
            606 607 608 606 -1 
            2052 2053 2054 2052 -1 
            609 2055 2056 610 609 -1 
            611 2057 2056 610 611 -1 
            609 2055 2057 611 609 -1 
            609 2055 2057 611 609 -1 
            609 610 611 609 -1 
            2055 2056 2057 2055 -1 
            612 2058 2059 613 612 -1 
            614 2060 2059 613 614 -1 
            612 2058 2060 614 612 -1 
            612 2058 2060 614 612 -1 
            612 613 614 612 -1 
            2058 2059 2060 2058 -1 
            615 2061 2062 616 615 -1 
            617 2063 2062 616 617 -1 
            615 2061 2063 617 615 -1 
            615 2061 2063 617 615 -1 
            615 616 617 615 -1 
            2061 2062 2063 2061 -1 
            618 2064 2065 619 618 -1 
            620 2066 2065 619 620 -1 
            618 2064 2066 620 618 -1 
            618 2064 2066 620 618 -1 
            618 619 620 618 -1 
            2064 2065 2066 2064 -1 
            621 2067 2068 622 621 -1 
            623 2069 2068 622 623 -1 
            621 2067 2069 623 621 -1 
            621 2067 2069 623 621 -1 
            621 622 623 621 -1 
            2067 2068 2069 2067 -1 
            624 2070 2071 625 624 -1 
            626 2072 2071 625 626 -1 
            624 2070 2072 626 624 -1 
            624 2070 2072 626 624 -1 
            624 625 626 624 -1 
            2070 2071 2072 2070 -1 
            627 2073 2074 628 627 -1 
            629 2075 2074 628 629 -1 
            627 2073 2075 629 627 -1 
            627 2073 2075 629 627 -1 
            627 628 629 627 -1 
            2073 2074 2075 2073 -1 
            630 2076 2077 631 630 -1 
            632 2078 2077 631 632 -1 
            630 2076 2078 632 630 -1 
            630 2076 2078 632 630 -1 
            630 631 632 630 -1 
            2076 2077 2078 2076 -1 
            633 2079 2080 634 633 -1 
            635 2081 2080 634 635 -1 
            633 2079 2081 635 633 -1 
            633 2079 2081 635 633 -1 
            633 634 635 633 -1 
            2079 2080 2081 2079 -1 
            636 2082 2083 637 636 -1 
            638 2084 2083 637 638 -1 
            636 2082 2084 638 636 -1 
            636 2082 2084 638 636 -1 
            636 637 638 636 -1 
            2082 2083 2084 2082 -1 
            639 2085 2086 640 639 -1 
            641 2087 2086 640 641 -1 
            639 2085 2087 641 639 -1 
            639 2085 2087 641 639 -1 
            639 640 641 639 -1 
            2085 2086 2087 2085 -1 
            642 2088 2089 643 642 -1 
            644 2090 2089 643 644 -1 
            642 2088 2090 644 642 -1 
            642 2088 2090 644 642 -1 
            642 643 644 642 -1 
            2088 2089 2090 2088 -1 
            645 2091 2092 646 645 -1 
            647 2093 2092 646 647 -1 
            645 2091 2093 647 645 -1 
            645 2091 2093 647 645 -1 
            645 646 647 645 -1 
            2091 2092 2093 2091 -1 
            648 2094 2095 649 648 -1 
            650 2096 2095 649 650 -1 
            648 2094 2096 650 648 -1 
            648 2094 2096 650 648 -1 
            648 649 650 648 -1 
            2094 2095 2096 2094 -1 
            651 2097 2098 652 651 -1 
            653 2099 2098 652 653 -1 
            651 2097 2099 653 651 -1 
            651 2097 2099 653 651 -1 
            651 652 653 651 -1 
            2097 2098 2099 2097 -1 
            654 2100 2101 655 654 -1 
            656 2102 2101 655 656 -1 
            654 2100 2102 656 654 -1 
            654 2100 2102 656 654 -1 
            654 655 656 654 -1 
            2100 2101 2102 2100 -1 
            657 2103 2104 658 657 -1 
            659 2105 2104 658 659 -1 
            657 2103 2105 659 657 -1 
            657 2103 2105 659 657 -1 
            657 658 659 657 -1 
            2103 2104 2105 2103 -1 
            660 2106 2107 661 660 -1 
            662 2108 2107 661 662 -1 
            660 2106 2108 662 660 -1 
            660 2106 2108 662 660 -1 
            660 661 662 660 -1 
            2106 2107 2108 2106 -1 
            663 2109 2110 664 663 -1 
            665 2111 2110 664 665 -1 
            663 2109 2111 665 663 -1 
            663 2109 2111 665 663 -1 
            663 664 665 663 -1 
            2109 2110 2111 2109 -1 
            666 2112 2113 667 666 -1 
            668 2114 2113 667 668 -1 
            666 2112 2114 668 666 -1 
            666 2112 2114 668 666 -1 
            666 667 668 666 -1 
            2112 2113 2114 2112 -1 
            669 2115 2116 670 669 -1 
            671 2117 2116 670 671 -1 
            669 2115 2117 671 669 -1 
            669 2115 2117 671 669 -1 
            669 670 671 669 -1 
            2115 2116 2117 2115 -1 
            672 2118 2119 673 672 -1 
            674 2120 2119 673 674 -1 
            672 2118 2120 674 672 -1 
            672 2118 2120 674 672 -1 
            672 673 674 672 -1 
            2118 2119 2120 2118 -1 
            675 2121 2122 676 675 -1 
            677 2123 2122 676 677 -1 
            675 2121 2123 677 675 -1 
            675 2121 2123 677 675 -1 
            675 676 677 675 -1 
            2121 2122 2123 2121 -1 
            678 2124 2125 679 678 -1 
            680 2126 2125 679 680 -1 
            678 2124 2126 680 678 -1 
            678 2124 2126 680 678 -1 
            678 679 680 678 -1 
            2124 2125 2126 2124 -1 
            681 2127 2128 682 681 -1 
            683 2129 2128 682 683 -1 
            681 2127 2129 683 681 -1 
            681 2127 2129 683 681 -1 
            681 682 683 681 -1 
            2127 2128 2129 2127 -1 
            684 2130 2131 685 684 -1 
            686 2132 2131 685 686 -1 
            684 2130 2132 686 684 -1 
            684 2130 2132 686 684 -1 
            684 685 686 684 -1 
            2130 2131 2132 2130 -1 
            687 2133 2134 688 687 -1 
            689 2135 2134 688 689 -1 
            687 2133 2135 689 687 -1 
            687 2133 2135 689 687 -1 
            687 688 689 687 -1 
            2133 2134 2135 2133 -1 
            690 2136 2137 691 690 -1 
            692 2138 2137 691 692 -1 
            690 2136 2138 692 690 -1 
            690 2136 2138 692 690 -1 
            690 691 692 690 -1 
            2136 2137 2138 2136 -1 
            693 2139 2140 694 693 -1 
            695 2141 2140 694 695 -1 
            693 2139 2141 695 693 -1 
            693 2139 2141 695 693 -1 
            693 694 695 693 -1 
            2139 2140 2141 2139 -1 
            696 2142 2143 697 696 -1 
            698 2144 2143 697 698 -1 
            696 2142 2144 698 696 -1 
            696 2142 2144 698 696 -1 
            696 697 698 696 -1 
            2142 2143 2144 2142 -1 
            699 2145 2146 700 699 -1 
            701 2147 2146 700 701 -1 
            699 2145 2147 701 699 -1 
            699 2145 2147 701 699 -1 
            699 700 701 699 -1 
            2145 2146 2147 2145 -1 
            702 2148 2149 703 702 -1 
            704 2150 2149 703 704 -1 
            702 2148 2150 704 702 -1 
            702 2148 2150 704 702 -1 
            702 703 704 702 -1 
            2148 2149 2150 2148 -1 
            705 2151 2152 706 705 -1 
            707 2153 2152 706 707 -1 
            705 2151 2153 707 705 -1 
            705 2151 2153 707 705 -1 
            705 706 707 705 -1 
            2151 2152 2153 2151 -1 
            708 2154 2155 709 708 -1 
            710 2156 2155 709 710 -1 
            708 2154 2156 710 708 -1 
            708 2154 2156 710 708 -1 
            708 709 710 708 -1 
            2154 2155 2156 2154 -1 
            711 2157 2158 712 711 -1 
            713 2159 2158 712 713 -1 
            711 2157 2159 713 711 -1 
            711 2157 2159 713 711 -1 
            711 712 713 711 -1 
            2157 2158 2159 2157 -1 
            714 2160 2161 715 714 -1 
            716 2162 2161 715 716 -1 
            714 2160 2162 716 714 -1 
            714 2160 2162 716 714 -1 
            714 715 716 714 -1 
            2160 2161 2162 2160 -1 
            717 2163 2164 718 717 -1 
            719 2165 2164 718 719 -1 
            717 2163 2165 719 717 -1 
            717 2163 2165 719 717 -1 
            717 718 719 717 -1 
            2163 2164 2165 2163 -1 
            720 2166 2167 721 720 -1 
            722 2168 2167 721 722 -1 
            720 2166 2168 722 720 -1 
            720 2166 2168 722 720 -1 
            720 721 722 720 -1 
            2166 2167 2168 2166 -1 
            723 2169 2170 724 723 -1 
            725 2171 2170 724 725 -1 
            723 2169 2171 725 723 -1 
            723 2169 2171 725 723 -1 
            723 724 725 723 -1 
            2169 2170 2171 2169 -1 
            726 2172 2173 727 726 -1 
            728 2174 2173 727 728 -1 
            726 2172 2174 728 726 -1 
            726 2172 2174 728 726 -1 
            726 727 728 726 -1 
            2172 2173 2174 2172 -1 
            729 2175 2176 730 729 -1 
            731 2177 2176 730 731 -1 
            729 2175 2177 731 729 -1 
            729 2175 2177 731 729 -1 
            729 730 731 729 -1 
            2175 2176 2177 2175 -1 
            732 2178 2179 733 732 -1 
            734 2180 2179 733 734 -1 
            732 2178 2180 734 732 -1 
            732 2178 2180 734 732 -1 
            732 733 734 732 -1 
            2178 2179 2180 2178 -1 
            735 2181 2182 736 735 -1 
            737 2183 2182 736 737 -1 
            735 2181 2183 737 735 -1 
            735 2181 2183 737 735 -1 
            735 736 737 735 -1 
            2181 2182 2183 2181 -1 
            738 2184 2185 739 738 -1 
            740 2186 2185 739 740 -1 
            738 2184 2186 740 738 -1 
            738 2184 2186 740 738 -1 
            738 739 740 738 -1 
            2184 2185 2186 2184 -1 
            741 2187 2188 742 741 -1 
            743 2189 2188 742 743 -1 
            741 2187 2189 743 741 -1 
            741 2187 2189 743 741 -1 
            741 742 743 741 -1 
            2187 2188 2189 2187 -1 
            744 2190 2191 745 744 -1 
            746 2192 2191 745 746 -1 
            744 2190 2192 746 744 -1 
            744 2190 2192 746 744 -1 
            744 745 746 744 -1 
            2190 2191 2192 2190 -1 
            747 2193 2194 748 747 -1 
            749 2195 2194 748 749 -1 
            747 2193 2195 749 747 -1 
            747 2193 2195 749 747 -1 
            747 748 749 747 -1 
            2193 2194 2195 2193 -1 
            750 2196 2197 751 750 -1 
            752 2198 2197 751 752 -1 
            750 2196 2198 752 750 -1 
            750 2196 2198 752 750 -1 
            750 751 752 750 -1 
            2196 2197 2198 2196 -1 
            753 2199 2200 754 753 -1 
            755 2201 2200 754 755 -1 
            753 2199 2201 755 753 -1 
            753 2199 2201 755 753 -1 
            753 754 755 753 -1 
            2199 2200 2201 2199 -1 
            756 2202 2203 757 756 -1 
            758 2204 2203 757 758 -1 
            756 2202 2204 758 756 -1 
            756 2202 2204 758 756 -1 
            756 757 758 756 -1 
            2202 2203 2204 2202 -1 
            759 2205 2206 760 759 -1 
            761 2207 2206 760 761 -1 
            759 2205 2207 761 759 -1 
            759 2205 2207 761 759 -1 
            759 760 761 759 -1 
            2205 2206 2207 2205 -1 
            762 2208 2209 763 762 -1 
            764 2210 2209 763 764 -1 
            762 2208 2210 764 762 -1 
            762 2208 2210 764 762 -1 
            762 763 764 762 -1 
            2208 2209 2210 2208 -1 
            765 2211 2212 766 765 -1 
            767 2213 2212 766 767 -1 
            765 2211 2213 767 765 -1 
            765 2211 2213 767 765 -1 
            765 766 767 765 -1 
            2211 2212 2213 2211 -1 
            768 2214 2215 769 768 -1 
            770 2216 2215 769 770 -1 
            768 2214 2216 770 768 -1 
            768 2214 2216 770 768 -1 
            768 769 770 768 -1 
            2214 2215 2216 2214 -1 
            771 2217 2218 772 771 -1 
            773 2219 2218 772 773 -1 
            771 2217 2219 773 771 -1 
            771 2217 2219 773 771 -1 
            771 772 773 771 -1 
            2217 2218 2219 2217 -1 
            774 2220 2221 775 774 -1 
            776 2222 2221 775 776 -1 
            774 2220 2222 776 774 -1 
            774 2220 2222 776 774 -1 
            774 775 776 774 -1 
            2220 2221 2222 2220 -1 
            777 2223 2224 778 777 -1 
            779 2225 2224 778 779 -1 
            777 2223 2225 779 777 -1 
            777 2223 2225 779 777 -1 
            777 778 779 777 -1 
            2223 2224 2225 2223 -1 
            780 2226 2227 781 780 -1 
            782 2228 2227 781 782 -1 
            780 2226 2228 782 780 -1 
            780 2226 2228 782 780 -1 
            780 781 782 780 -1 
            2226 2227 2228 2226 -1 
            783 2229 2230 784 783 -1 
            785 2231 2230 784 785 -1 
            783 2229 2231 785 783 -1 
            783 2229 2231 785 783 -1 
            783 784 785 783 -1 
            2229 2230 2231 2229 -1 
            786 2232 2233 787 786 -1 
            788 2234 2233 787 788 -1 
            786 2232 2234 788 786 -1 
            786 2232 2234 788 786 -1 
            786 787 788 786 -1 
            2232 2233 2234 2232 -1 
            789 2235 2236 790 789 -1 
            791 2237 2236 790 791 -1 
            789 2235 2237 791 789 -1 
            789 2235 2237 791 789 -1 
            789 790 791 789 -1 
            2235 2236 2237 2235 -1 
            792 2238 2239 793 792 -1 
            794 2240 2239 793 794 -1 
            792 2238 2240 794 792 -1 
            792 2238 2240 794 792 -1 
            792 793 794 792 -1 
            2238 2239 2240 2238 -1 
            795 2241 2242 796 795 -1 
            797 2243 2242 796 797 -1 
            795 2241 2243 797 795 -1 
            795 2241 2243 797 795 -1 
            795 796 797 795 -1 
            2241 2242 2243 2241 -1 
            798 2244 2245 799 798 -1 
            800 2246 2245 799 800 -1 
            798 2244 2246 800 798 -1 
            798 2244 2246 800 798 -1 
            798 799 800 798 -1 
            2244 2245 2246 2244 -1 
            801 2247 2248 802 801 -1 
            803 2249 2248 802 803 -1 
            801 2247 2249 803 801 -1 
            801 2247 2249 803 801 -1 
            801 802 803 801 -1 
            2247 2248 2249 2247 -1 
            804 2250 2251 805 804 -1 
            806 2252 2251 805 806 -1 
            804 2250 2252 806 804 -1 
            804 2250 2252 806 804 -1 
            804 805 806 804 -1 
            2250 2251 2252 2250 -1 
            807 2253 2254 808 807 -1 
            809 2255 2254 808 809 -1 
            807 2253 2255 809 807 -1 
            807 2253 2255 809 807 -1 
            807 808 809 807 -1 
            2253 2254 2255 2253 -1 
            810 2256 2257 811 810 -1 
            812 2258 2257 811 812 -1 
            810 2256 2258 812 810 -1 
            810 2256 2258 812 810 -1 
            810 811 812 810 -1 
            2256 2257 2258 2256 -1 
            813 2259 2260 814 813 -1 
            815 2261 2260 814 815 -1 
            813 2259 2261 815 813 -1 
            813 2259 2261 815 813 -1 
            813 814 815 813 -1 
            2259 2260 2261 2259 -1 
            816 2262 2263 817 816 -1 
            818 2264 2263 817 818 -1 
            816 2262 2264 818 816 -1 
            816 2262 2264 818 816 -1 
            816 817 818 816 -1 
            2262 2263 2264 2262 -1 
            819 2265 2266 820 819 -1 
            821 2267 2266 820 821 -1 
            819 2265 2267 821 819 -1 
            819 2265 2267 821 819 -1 
            819 820 821 819 -1 
            2265 2266 2267 2265 -1 
            822 2268 2269 823 822 -1 
            824 2270 2269 823 824 -1 
            822 2268 2270 824 822 -1 
            822 2268 2270 824 822 -1 
            822 823 824 822 -1 
            2268 2269 2270 2268 -1 
            825 2271 2272 826 825 -1 
            827 2273 2272 826 827 -1 
            825 2271 2273 827 825 -1 
            825 2271 2273 827 825 -1 
            825 826 827 825 -1 
            2271 2272 2273 2271 -1 
            828 2274 2275 829 828 -1 
            830 2276 2275 829 830 -1 
            828 2274 2276 830 828 -1 
            828 2274 2276 830 828 -1 
            828 829 830 828 -1 
            2274 2275 2276 2274 -1 
            831 2277 2278 832 831 -1 
            833 2279 2278 832 833 -1 
            831 2277 2279 833 831 -1 
            831 2277 2279 833 831 -1 
            831 832 833 831 -1 
            2277 2278 2279 2277 -1 
            834 2280 2281 835 834 -1 
            836 2282 2281 835 836 -1 
            834 2280 2282 836 834 -1 
            834 2280 2282 836 834 -1 
            834 835 836 834 -1 
            2280 2281 2282 2280 -1 
            837 2283 2284 838 837 -1 
            839 2285 2284 838 839 -1 
            837 2283 2285 839 837 -1 
            837 2283 2285 839 837 -1 
            837 838 839 837 -1 
            2283 2284 2285 2283 -1 
            840 2286 2287 841 840 -1 
            842 2288 2287 841 842 -1 
            840 2286 2288 842 840 -1 
            840 2286 2288 842 840 -1 
            840 841 842 840 -1 
            2286 2287 2288 2286 -1 
            843 2289 2290 844 843 -1 
            845 2291 2290 844 845 -1 
            843 2289 2291 845 843 -1 
            843 2289 2291 845 843 -1 
            843 844 845 843 -1 
            2289 2290 2291 2289 -1 
            846 2292 2293 847 846 -1 
            848 2294 2293 847 848 -1 
            846 2292 2294 848 846 -1 
            846 2292 2294 848 846 -1 
            846 847 848 846 -1 
            2292 2293 2294 2292 -1 
            849 2295 2296 850 849 -1 
            851 2297 2296 850 851 -1 
            849 2295 2297 851 849 -1 
            849 2295 2297 851 849 -1 
            849 850 851 849 -1 
            2295 2296 2297 2295 -1 
            852 2298 2299 853 852 -1 
            854 2300 2299 853 854 -1 
            852 2298 2300 854 852 -1 
            852 2298 2300 854 852 -1 
            852 853 854 852 -1 
            2298 2299 2300 2298 -1 
            855 2301 2302 856 855 -1 
            857 2303 2302 856 857 -1 
            855 2301 2303 857 855 -1 
            855 2301 2303 857 855 -1 
            855 856 857 855 -1 
            2301 2302 2303 2301 -1 
            858 2304 2305 859 858 -1 
            860 2306 2305 859 860 -1 
            858 2304 2306 860 858 -1 
            858 2304 2306 860 858 -1 
            858 859 860 858 -1 
            2304 2305 2306 2304 -1 
            861 2307 2308 862 861 -1 
            863 2309 2308 862 863 -1 
            861 2307 2309 863 861 -1 
            861 2307 2309 863 861 -1 
            861 862 863 861 -1 
            2307 2308 2309 2307 -1 
            864 2310 2311 865 864 -1 
            866 2312 2311 865 866 -1 
            864 2310 2312 866 864 -1 
            864 2310 2312 866 864 -1 
            864 865 866 864 -1 
            2310 2311 2312 2310 -1 
            867 2313 2314 868 867 -1 
            869 2315 2314 868 869 -1 
            867 2313 2315 869 867 -1 
            867 2313 2315 869 867 -1 
            867 868 869 867 -1 
            2313 2314 2315 2313 -1 
            870 2316 2317 871 870 -1 
            872 2318 2317 871 872 -1 
            870 2316 2318 872 870 -1 
            870 2316 2318 872 870 -1 
            870 871 872 870 -1 
            2316 2317 2318 2316 -1 
            873 2319 2320 874 873 -1 
            875 2321 2320 874 875 -1 
            873 2319 2321 875 873 -1 
            873 2319 2321 875 873 -1 
            873 874 875 873 -1 
            2319 2320 2321 2319 -1 
            876 2322 2323 877 876 -1 
            878 2324 2323 877 878 -1 
            876 2322 2324 878 876 -1 
            876 2322 2324 878 876 -1 
            876 877 878 876 -1 
            2322 2323 2324 2322 -1 
            879 2325 2326 880 879 -1 
            881 2327 2326 880 881 -1 
            879 2325 2327 881 879 -1 
            879 2325 2327 881 879 -1 
            879 880 881 879 -1 
            2325 2326 2327 2325 -1 
            882 2328 2329 883 882 -1 
            884 2330 2329 883 884 -1 
            882 2328 2330 884 882 -1 
            882 2328 2330 884 882 -1 
            882 883 884 882 -1 
            2328 2329 2330 2328 -1 
            885 2331 2332 886 885 -1 
            887 2333 2332 886 887 -1 
            885 2331 2333 887 885 -1 
            885 2331 2333 887 885 -1 
            885 886 887 885 -1 
            2331 2332 2333 2331 -1 
            888 2334 2335 889 888 -1 
            890 2336 2335 889 890 -1 
            888 2334 2336 890 888 -1 
            888 2334 2336 890 888 -1 
            888 889 890 888 -1 
            2334 2335 2336 2334 -1 
            891 2337 2338 892 891 -1 
            893 2339 2338 892 893 -1 
            891 2337 2339 893 891 -1 
            891 2337 2339 893 891 -1 
            891 892 893 891 -1 
            2337 2338 2339 2337 -1 
            894 2340 2341 895 894 -1 
            896 2342 2341 895 896 -1 
            894 2340 2342 896 894 -1 
            894 2340 2342 896 894 -1 
            894 895 896 894 -1 
            2340 2341 2342 2340 -1 
            897 2343 2344 898 897 -1 
            899 2345 2344 898 899 -1 
            897 2343 2345 899 897 -1 
            897 2343 2345 899 897 -1 
            897 898 899 897 -1 
            2343 2344 2345 2343 -1 
            900 2346 2347 901 900 -1 
            902 2348 2347 901 902 -1 
            900 2346 2348 902 900 -1 
            900 2346 2348 902 900 -1 
            900 901 902 900 -1 
            2346 2347 2348 2346 -1 
            903 2349 2350 904 903 -1 
            905 2351 2350 904 905 -1 
            903 2349 2351 905 903 -1 
            903 2349 2351 905 903 -1 
            903 904 905 903 -1 
            2349 2350 2351 2349 -1 
            906 2352 2353 907 906 -1 
            908 2354 2353 907 908 -1 
            906 2352 2354 908 906 -1 
            906 2352 2354 908 906 -1 
            906 907 908 906 -1 
            2352 2353 2354 2352 -1 
            909 2355 2356 910 909 -1 
            911 2357 2356 910 911 -1 
            909 2355 2357 911 909 -1 
            909 2355 2357 911 909 -1 
            909 910 911 909 -1 
            2355 2356 2357 2355 -1 
            912 2358 2359 913 912 -1 
            914 2360 2359 913 914 -1 
            912 2358 2360 914 912 -1 
            912 2358 2360 914 912 -1 
            912 913 914 912 -1 
            2358 2359 2360 2358 -1 
            915 2361 2362 916 915 -1 
            917 2363 2362 916 917 -1 
            915 2361 2363 917 915 -1 
            915 2361 2363 917 915 -1 
            915 916 917 915 -1 
            2361 2362 2363 2361 -1 
            918 2364 2365 919 918 -1 
            920 2366 2365 919 920 -1 
            918 2364 2366 920 918 -1 
            918 2364 2366 920 918 -1 
            918 919 920 918 -1 
            2364 2365 2366 2364 -1 
            921 2367 2368 922 921 -1 
            923 2369 2368 922 923 -1 
            921 2367 2369 923 921 -1 
            921 2367 2369 923 921 -1 
            921 922 923 921 -1 
            2367 2368 2369 2367 -1 
            924 2370 2371 925 924 -1 
            926 2372 2371 925 926 -1 
            924 2370 2372 926 924 -1 
            924 2370 2372 926 924 -1 
            924 925 926 924 -1 
            2370 2371 2372 2370 -1 
            927 2373 2374 928 927 -1 
            929 2375 2374 928 929 -1 
            927 2373 2375 929 927 -1 
            927 2373 2375 929 927 -1 
            927 928 929 927 -1 
            2373 2374 2375 2373 -1 
            930 2376 2377 931 930 -1 
            932 2378 2377 931 932 -1 
            930 2376 2378 932 930 -1 
            930 2376 2378 932 930 -1 
            930 931 932 930 -1 
            2376 2377 2378 2376 -1 
            933 2379 2380 934 933 -1 
            935 2381 2380 934 935 -1 
            933 2379 2381 935 933 -1 
            933 2379 2381 935 933 -1 
            933 934 935 933 -1 
            2379 2380 2381 2379 -1 
            936 2382 2383 937 936 -1 
            938 2384 2383 937 938 -1 
            936 2382 2384 938 936 -1 
            936 2382 2384 938 936 -1 
            936 937 938 936 -1 
            2382 2383 2384 2382 -1 
            939 2385 2386 940 939 -1 
            941 2387 2386 940 941 -1 
            939 2385 2387 941 939 -1 
            939 2385 2387 941 939 -1 
            939 940 941 939 -1 
            2385 2386 2387 2385 -1 
            942 2388 2389 943 942 -1 
            944 2390 2389 943 944 -1 
            942 2388 2390 944 942 -1 
            942 2388 2390 944 942 -1 
            942 943 944 942 -1 
            2388 2389 2390 2388 -1 
            945 2391 2392 946 945 -1 
            947 2393 2392 946 947 -1 
            945 2391 2393 947 945 -1 
            945 2391 2393 947 945 -1 
            945 946 947 945 -1 
            2391 2392 2393 2391 -1 
            948 2394 2395 949 948 -1 
            950 2396 2395 949 950 -1 
            948 2394 2396 950 948 -1 
            948 2394 2396 950 948 -1 
            948 949 950 948 -1 
            2394 2395 2396 2394 -1 
            951 2397 2398 952 951 -1 
            953 2399 2398 952 953 -1 
            951 2397 2399 953 951 -1 
            951 2397 2399 953 951 -1 
            951 952 953 951 -1 
            2397 2398 2399 2397 -1 
            954 2400 2401 955 954 -1 
            956 2402 2401 955 956 -1 
            954 2400 2402 956 954 -1 
            954 2400 2402 956 954 -1 
            954 955 956 954 -1 
            2400 2401 2402 2400 -1 
            957 2403 2404 958 957 -1 
            959 2405 2404 958 959 -1 
            957 2403 2405 959 957 -1 
            957 2403 2405 959 957 -1 
            957 958 959 957 -1 
            2403 2404 2405 2403 -1 
            960 2406 2407 961 960 -1 
            962 2408 2407 961 962 -1 
            960 2406 2408 962 960 -1 
            960 2406 2408 962 960 -1 
            960 961 962 960 -1 
            2406 2407 2408 2406 -1 
            963 2409 2410 964 963 -1 
            965 2411 2410 964 965 -1 
            963 2409 2411 965 963 -1 
            963 2409 2411 965 963 -1 
            963 964 965 963 -1 
            2409 2410 2411 2409 -1 
            966 2412 2413 967 966 -1 
            968 2414 2413 967 968 -1 
            966 2412 2414 968 966 -1 
            966 2412 2414 968 966 -1 
            966 967 968 966 -1 
            2412 2413 2414 2412 -1 
            969 2415 2416 970 969 -1 
            971 2417 2416 970 971 -1 
            969 2415 2417 971 969 -1 
            969 2415 2417 971 969 -1 
            969 970 971 969 -1 
            2415 2416 2417 2415 -1 
            972 2418 2419 973 972 -1 
            974 2420 2419 973 974 -1 
            972 2418 2420 974 972 -1 
            972 2418 2420 974 972 -1 
            972 973 974 972 -1 
            2418 2419 2420 2418 -1 
            975 2421 2422 976 975 -1 
            977 2423 2422 976 977 -1 
            975 2421 2423 977 975 -1 
            975 2421 2423 977 975 -1 
            975 976 977 975 -1 
            2421 2422 2423 2421 -1 
            978 2424 2425 979 978 -1 
            980 2426 2425 979 980 -1 
            978 2424 2426 980 978 -1 
            978 2424 2426 980 978 -1 
            978 979 980 978 -1 
            2424 2425 2426 2424 -1 
            981 2427 2428 982 981 -1 
            983 2429 2428 982 983 -1 
            981 2427 2429 983 981 -1 
            981 2427 2429 983 981 -1 
            981 982 983 981 -1 
            2427 2428 2429 2427 -1 
            984 2430 2431 985 984 -1 
            986 2432 2431 985 986 -1 
            984 2430 2432 986 984 -1 
            984 2430 2432 986 984 -1 
            984 985 986 984 -1 
            2430 2431 2432 2430 -1 
            987 2433 2434 988 987 -1 
            989 2435 2434 988 989 -1 
            987 2433 2435 989 987 -1 
            987 2433 2435 989 987 -1 
            987 988 989 987 -1 
            2433 2434 2435 2433 -1 
            990 2436 2437 991 990 -1 
            992 2438 2437 991 992 -1 
            990 2436 2438 992 990 -1 
            990 2436 2438 992 990 -1 
            990 991 992 990 -1 
            2436 2437 2438 2436 -1 
            993 2439 2440 994 993 -1 
            995 2441 2440 994 995 -1 
            993 2439 2441 995 993 -1 
            993 2439 2441 995 993 -1 
            993 994 995 993 -1 
            2439 2440 2441 2439 -1 
            996 2442 2443 997 996 -1 
            998 2444 2443 997 998 -1 
            996 2442 2444 998 996 -1 
            996 2442 2444 998 996 -1 
            996 997 998 996 -1 
            2442 2443 2444 2442 -1 
            999 2445 2446 1000 999 -1 
            1001 2447 2446 1000 1001 -1 
            999 2445 2447 1001 999 -1 
            999 2445 2447 1001 999 -1 
            999 1000 1001 999 -1 
            2445 2446 2447 2445 -1 
            1002 2448 2449 1003 1002 -1 
            1004 2450 2449 1003 1004 -1 
            1002 2448 2450 1004 1002 -1 
            1002 2448 2450 1004 1002 -1 
            1002 1003 1004 1002 -1 
            2448 2449 2450 2448 -1 
            1005 2451 2452 1006 1005 -1 
            1007 2453 2452 1006 1007 -1 
            1005 2451 2453 1007 1005 -1 
            1005 2451 2453 1007 1005 -1 
            1005 1006 1007 1005 -1 
            2451 2452 2453 2451 -1 
            1008 2454 2455 1009 1008 -1 
            1010 2456 2455 1009 1010 -1 
            1008 2454 2456 1010 1008 -1 
            1008 2454 2456 1010 1008 -1 
            1008 1009 1010 1008 -1 
            2454 2455 2456 2454 -1 
            1011 2457 2458 1012 1011 -1 
            1013 2459 2458 1012 1013 -1 
            1011 2457 2459 1013 1011 -1 
            1011 2457 2459 1013 1011 -1 
            1011 1012 1013 1011 -1 
            2457 2458 2459 2457 -1 
            1014 2460 2461 1015 1014 -1 
            1016 2462 2461 1015 1016 -1 
            1014 2460 2462 1016 1014 -1 
            1014 2460 2462 1016 1014 -1 
            1014 1015 1016 1014 -1 
            2460 2461 2462 2460 -1 
            1017 2463 2464 1018 1017 -1 
            1019 2465 2464 1018 1019 -1 
            1017 2463 2465 1019 1017 -1 
            1017 2463 2465 1019 1017 -1 
            1017 1018 1019 1017 -1 
            2463 2464 2465 2463 -1 
            1020 2466 2467 1021 1020 -1 
            1022 2468 2467 1021 1022 -1 
            1020 2466 2468 1022 1020 -1 
            1020 2466 2468 1022 1020 -1 
            1020 1021 1022 1020 -1 
            2466 2467 2468 2466 -1 
            1023 2469 2470 1024 1023 -1 
            1025 2471 2470 1024 1025 -1 
            1023 2469 2471 1025 1023 -1 
            1023 2469 2471 1025 1023 -1 
            1023 1024 1025 1023 -1 
            2469 2470 2471 2469 -1 
            1026 2472 2473 1027 1026 -1 
            1028 2474 2473 1027 1028 -1 
            1026 2472 2474 1028 1026 -1 
            1026 2472 2474 1028 1026 -1 
            1026 1027 1028 1026 -1 
            2472 2473 2474 2472 -1 
            1029 2475 2476 1030 1029 -1 
            1031 2477 2476 1030 1031 -1 
            1029 2475 2477 1031 1029 -1 
            1029 2475 2477 1031 1029 -1 
            1029 1030 1031 1029 -1 
            2475 2476 2477 2475 -1 
            1032 2478 2479 1033 1032 -1 
            1034 2480 2479 1033 1034 -1 
            1032 2478 2480 1034 1032 -1 
            1032 2478 2480 1034 1032 -1 
            1032 1033 1034 1032 -1 
            2478 2479 2480 2478 -1 
            1035 2481 2482 1036 1035 -1 
            1037 2483 2482 1036 1037 -1 
            1035 2481 2483 1037 1035 -1 
            1035 2481 2483 1037 1035 -1 
            1035 1036 1037 1035 -1 
            2481 2482 2483 2481 -1 
            1038 2484 2485 1039 1038 -1 
            1040 2486 2485 1039 1040 -1 
            1038 2484 2486 1040 1038 -1 
            1038 2484 2486 1040 1038 -1 
            1038 1039 1040 1038 -1 
            2484 2485 2486 2484 -1 
            1041 2487 2488 1042 1041 -1 
            1043 2489 2488 1042 1043 -1 
            1041 2487 2489 1043 1041 -1 
            1041 2487 2489 1043 1041 -1 
            1041 1042 1043 1041 -1 
            2487 2488 2489 2487 -1 
            1044 2490 2491 1045 1044 -1 
            1046 2492 2491 1045 1046 -1 
            1044 2490 2492 1046 1044 -1 
            1044 2490 2492 1046 1044 -1 
            1044 1045 1046 1044 -1 
            2490 2491 2492 2490 -1 
            1047 2493 2494 1048 1047 -1 
            1049 2495 2494 1048 1049 -1 
            1047 2493 2495 1049 1047 -1 
            1047 2493 2495 1049 1047 -1 
            1047 1048 1049 1047 -1 
            2493 2494 2495 2493 -1 
            1050 2496 2497 1051 1050 -1 
            1052 2498 2497 1051 1052 -1 
            1050 2496 2498 1052 1050 -1 
            1050 2496 2498 1052 1050 -1 
            1050 1051 1052 1050 -1 
            2496 2497 2498 2496 -1 
            1053 2499 2500 1054 1053 -1 
            1055 2501 2500 1054 1055 -1 
            1053 2499 2501 1055 1053 -1 
            1053 2499 2501 1055 1053 -1 
            1053 1054 1055 1053 -1 
            2499 2500 2501 2499 -1 
            1056 2502 2503 1057 1056 -1 
            1058 2504 2503 1057 1058 -1 
            1056 2502 2504 1058 1056 -1 
            1056 2502 2504 1058 1056 -1 
            1056 1057 1058 1056 -1 
            2502 2503 2504 2502 -1 
            1059 2505 2506 1060 1059 -1 
            1061 2507 2506 1060 1061 -1 
            1059 2505 2507 1061 1059 -1 
            1059 2505 2507 1061 1059 -1 
            1059 1060 1061 1059 -1 
            2505 2506 2507 2505 -1 
            1062 2508 2509 1063 1062 -1 
            1064 2510 2509 1063 1064 -1 
            1062 2508 2510 1064 1062 -1 
            1062 2508 2510 1064 1062 -1 
            1062 1063 1064 1062 -1 
            2508 2509 2510 2508 -1 
            1065 2511 2512 1066 1065 -1 
            1067 2513 2512 1066 1067 -1 
            1065 2511 2513 1067 1065 -1 
            1065 2511 2513 1067 1065 -1 
            1065 1066 1067 1065 -1 
            2511 2512 2513 2511 -1 
            1068 2514 2515 1069 1068 -1 
            1070 2516 2515 1069 1070 -1 
            1068 2514 2516 1070 1068 -1 
            1068 2514 2516 1070 1068 -1 
            1068 1069 1070 1068 -1 
            2514 2515 2516 2514 -1 
            1071 2517 2518 1072 1071 -1 
            1073 2519 2518 1072 1073 -1 
            1071 2517 2519 1073 1071 -1 
            1071 2517 2519 1073 1071 -1 
            1071 1072 1073 1071 -1 
            2517 2518 2519 2517 -1 
            1074 2520 2521 1075 1074 -1 
            1076 2522 2521 1075 1076 -1 
            1074 2520 2522 1076 1074 -1 
            1074 2520 2522 1076 1074 -1 
            1074 1075 1076 1074 -1 
            2520 2521 2522 2520 -1 
            1077 2523 2524 1078 1077 -1 
            1079 2525 2524 1078 1079 -1 
            1077 2523 2525 1079 1077 -1 
            1077 2523 2525 1079 1077 -1 
            1077 1078 1079 1077 -1 
            2523 2524 2525 2523 -1 
            1080 2526 2527 1081 1080 -1 
            1082 2528 2527 1081 1082 -1 
            1080 2526 2528 1082 1080 -1 
            1080 2526 2528 1082 1080 -1 
            1080 1081 1082 1080 -1 
            2526 2527 2528 2526 -1 
            1083 2529 2530 1084 1083 -1 
            1085 2531 2530 1084 1085 -1 
            1083 2529 2531 1085 1083 -1 
            1083 2529 2531 1085 1083 -1 
            1083 1084 1085 1083 -1 
            2529 2530 2531 2529 -1 
            1086 2532 2533 1087 1086 -1 
            1088 2534 2533 1087 1088 -1 
            1086 2532 2534 1088 1086 -1 
            1086 2532 2534 1088 1086 -1 
            1086 1087 1088 1086 -1 
            2532 2533 2534 2532 -1 
            1089 2535 2536 1090 1089 -1 
            1091 2537 2536 1090 1091 -1 
            1089 2535 2537 1091 1089 -1 
            1089 2535 2537 1091 1089 -1 
            1089 1090 1091 1089 -1 
            2535 2536 2537 2535 -1 
            1092 2538 2539 1093 1092 -1 
            1094 2540 2539 1093 1094 -1 
            1092 2538 2540 1094 1092 -1 
            1092 2538 2540 1094 1092 -1 
            1092 1093 1094 1092 -1 
            2538 2539 2540 2538 -1 
            1095 2541 2542 1096 1095 -1 
            1097 2543 2542 1096 1097 -1 
            1095 2541 2543 1097 1095 -1 
            1095 2541 2543 1097 1095 -1 
            1095 1096 1097 1095 -1 
            2541 2542 2543 2541 -1 
            1098 2544 2545 1099 1098 -1 
            1100 2546 2545 1099 1100 -1 
            1098 2544 2546 1100 1098 -1 
            1098 2544 2546 1100 1098 -1 
            1098 1099 1100 1098 -1 
            2544 2545 2546 2544 -1 
            1101 2547 2548 1102 1101 -1 
            1103 2549 2548 1102 1103 -1 
            1101 2547 2549 1103 1101 -1 
            1101 2547 2549 1103 1101 -1 
            1101 1102 1103 1101 -1 
            2547 2548 2549 2547 -1 
            1104 2550 2551 1105 1104 -1 
            1106 2552 2551 1105 1106 -1 
            1104 2550 2552 1106 1104 -1 
            1104 2550 2552 1106 1104 -1 
            1104 1105 1106 1104 -1 
            2550 2551 2552 2550 -1 
            1107 2553 2554 1108 1107 -1 
            1109 2555 2554 1108 1109 -1 
            1107 2553 2555 1109 1107 -1 
            1107 2553 2555 1109 1107 -1 
            1107 1108 1109 1107 -1 
            2553 2554 2555 2553 -1 
            1110 2556 2557 1111 1110 -1 
            1112 2558 2557 1111 1112 -1 
            1110 2556 2558 1112 1110 -1 
            1110 2556 2558 1112 1110 -1 
            1110 1111 1112 1110 -1 
            2556 2557 2558 2556 -1 
            1113 2559 2560 1114 1113 -1 
            1115 2561 2560 1114 1115 -1 
            1113 2559 2561 1115 1113 -1 
            1113 2559 2561 1115 1113 -1 
            1113 1114 1115 1113 -1 
            2559 2560 2561 2559 -1 
            1116 2562 2563 1117 1116 -1 
            1118 2564 2563 1117 1118 -1 
            1116 2562 2564 1118 1116 -1 
            1116 2562 2564 1118 1116 -1 
            1116 1117 1118 1116 -1 
            2562 2563 2564 2562 -1 
            1119 2565 2566 1120 1119 -1 
            1121 2567 2566 1120 1121 -1 
            1119 2565 2567 1121 1119 -1 
            1119 2565 2567 1121 1119 -1 
            1119 1120 1121 1119 -1 
            2565 2566 2567 2565 -1 
            1122 2568 2569 1123 1122 -1 
            1124 2570 2569 1123 1124 -1 
            1122 2568 2570 1124 1122 -1 
            1122 2568 2570 1124 1122 -1 
            1122 1123 1124 1122 -1 
            2568 2569 2570 2568 -1 
            1125 2571 2572 1126 1125 -1 
            1127 2573 2572 1126 1127 -1 
            1125 2571 2573 1127 1125 -1 
            1125 2571 2573 1127 1125 -1 
            1125 1126 1127 1125 -1 
            2571 2572 2573 2571 -1 
            1128 2574 2575 1129 1128 -1 
            1130 2576 2575 1129 1130 -1 
            1128 2574 2576 1130 1128 -1 
            1128 2574 2576 1130 1128 -1 
            1128 1129 1130 1128 -1 
            2574 2575 2576 2574 -1 
            1131 2577 2578 1132 1131 -1 
            1133 2579 2578 1132 1133 -1 
            1131 2577 2579 1133 1131 -1 
            1131 2577 2579 1133 1131 -1 
            1131 1132 1133 1131 -1 
            2577 2578 2579 2577 -1 
            1134 2580 2581 1135 1134 -1 
            1136 2582 2581 1135 1136 -1 
            1134 2580 2582 1136 1134 -1 
            1134 2580 2582 1136 1134 -1 
            1134 1135 1136 1134 -1 
            2580 2581 2582 2580 -1 
            1137 2583 2584 1138 1137 -1 
            1139 2585 2584 1138 1139 -1 
            1137 2583 2585 1139 1137 -1 
            1137 2583 2585 1139 1137 -1 
            1137 1138 1139 1137 -1 
            2583 2584 2585 2583 -1 
            1140 2586 2587 1141 1140 -1 
            1142 2588 2587 1141 1142 -1 
            1140 2586 2588 1142 1140 -1 
            1140 2586 2588 1142 1140 -1 
            1140 1141 1142 1140 -1 
            2586 2587 2588 2586 -1 
            1143 2589 2590 1144 1143 -1 
            1145 2591 2590 1144 1145 -1 
            1143 2589 2591 1145 1143 -1 
            1143 2589 2591 1145 1143 -1 
            1143 1144 1145 1143 -1 
            2589 2590 2591 2589 -1 
            1146 2592 2593 1147 1146 -1 
            1148 2594 2593 1147 1148 -1 
            1146 2592 2594 1148 1146 -1 
            1146 2592 2594 1148 1146 -1 
            1146 1147 1148 1146 -1 
            2592 2593 2594 2592 -1 
            1149 2595 2596 1150 1149 -1 
            1151 2597 2596 1150 1151 -1 
            1149 2595 2597 1151 1149 -1 
            1149 2595 2597 1151 1149 -1 
            1149 1150 1151 1149 -1 
            2595 2596 2597 2595 -1 
            1152 2598 2599 1153 1152 -1 
            1154 2600 2599 1153 1154 -1 
            1152 2598 2600 1154 1152 -1 
            1152 2598 2600 1154 1152 -1 
            1152 1153 1154 1152 -1 
            2598 2599 2600 2598 -1 
            1155 2601 2602 1156 1155 -1 
            1157 2603 2602 1156 1157 -1 
            1155 2601 2603 1157 1155 -1 
            1155 2601 2603 1157 1155 -1 
            1155 1156 1157 1155 -1 
            2601 2602 2603 2601 -1 
            1158 2604 2605 1159 1158 -1 
            1160 2606 2605 1159 1160 -1 
            1158 2604 2606 1160 1158 -1 
            1158 2604 2606 1160 1158 -1 
            1158 1159 1160 1158 -1 
            2604 2605 2606 2604 -1 
            1161 2607 2608 1162 1161 -1 
            1163 2609 2608 1162 1163 -1 
            1161 2607 2609 1163 1161 -1 
            1161 2607 2609 1163 1161 -1 
            1161 1162 1163 1161 -1 
            2607 2608 2609 2607 -1 
            1164 2610 2611 1165 1164 -1 
            1166 2612 2611 1165 1166 -1 
            1164 2610 2612 1166 1164 -1 
            1164 2610 2612 1166 1164 -1 
            1164 1165 1166 1164 -1 
            2610 2611 2612 2610 -1 
            1167 2613 2614 1168 1167 -1 
            1169 2615 2614 1168 1169 -1 
            1167 2613 2615 1169 1167 -1 
            1167 2613 2615 1169 1167 -1 
            1167 1168 1169 1167 -1 
            2613 2614 2615 2613 -1 
            1170 2616 2617 1171 1170 -1 
            1172 2618 2617 1171 1172 -1 
            1170 2616 2618 1172 1170 -1 
            1170 2616 2618 1172 1170 -1 
            1170 1171 1172 1170 -1 
            2616 2617 2618 2616 -1 
            1173 2619 2620 1174 1173 -1 
            1175 2621 2620 1174 1175 -1 
            1173 2619 2621 1175 1173 -1 
            1173 2619 2621 1175 1173 -1 
            1173 1174 1175 1173 -1 
            2619 2620 2621 2619 -1 
            1176 2622 2623 1177 1176 -1 
            1178 2624 2623 1177 1178 -1 
            1176 2622 2624 1178 1176 -1 
            1176 2622 2624 1178 1176 -1 
            1176 1177 1178 1176 -1 
            2622 2623 2624 2622 -1 
            1179 2625 2626 1180 1179 -1 
            1181 2627 2626 1180 1181 -1 
            1179 2625 2627 1181 1179 -1 
            1179 2625 2627 1181 1179 -1 
            1179 1180 1181 1179 -1 
            2625 2626 2627 2625 -1 
            1182 2628 2629 1183 1182 -1 
            1184 2630 2629 1183 1184 -1 
            1182 2628 2630 1184 1182 -1 
            1182 2628 2630 1184 1182 -1 
            1182 1183 1184 1182 -1 
            2628 2629 2630 2628 -1 
            1185 2631 2632 1186 1185 -1 
            1187 2633 2632 1186 1187 -1 
            1185 2631 2633 1187 1185 -1 
            1185 2631 2633 1187 1185 -1 
            1185 1186 1187 1185 -1 
            2631 2632 2633 2631 -1 
            1188 2634 2635 1189 1188 -1 
            1190 2636 2635 1189 1190 -1 
            1188 2634 2636 1190 1188 -1 
            1188 2634 2636 1190 1188 -1 
            1188 1189 1190 1188 -1 
            2634 2635 2636 2634 -1 
            1191 2637 2638 1192 1191 -1 
            1193 2639 2638 1192 1193 -1 
            1191 2637 2639 1193 1191 -1 
            1191 2637 2639 1193 1191 -1 
            1191 1192 1193 1191 -1 
            2637 2638 2639 2637 -1 
            1194 2640 2641 1195 1194 -1 
            1196 2642 2641 1195 1196 -1 
            1194 2640 2642 1196 1194 -1 
            1194 2640 2642 1196 1194 -1 
            1194 1195 1196 1194 -1 
            2640 2641 2642 2640 -1 
            1197 2643 2644 1198 1197 -1 
            1199 2645 2644 1198 1199 -1 
            1197 2643 2645 1199 1197 -1 
            1197 2643 2645 1199 1197 -1 
            1197 1198 1199 1197 -1 
            2643 2644 2645 2643 -1 
            1200 2646 2647 1201 1200 -1 
            1202 2648 2647 1201 1202 -1 
            1200 2646 2648 1202 1200 -1 
            1200 2646 2648 1202 1200 -1 
            1200 1201 1202 1200 -1 
            2646 2647 2648 2646 -1 
            1203 2649 2650 1204 1203 -1 
            1205 2651 2650 1204 1205 -1 
            1203 2649 2651 1205 1203 -1 
            1203 2649 2651 1205 1203 -1 
            1203 1204 1205 1203 -1 
            2649 2650 2651 2649 -1 
            1206 2652 2653 1207 1206 -1 
            1208 2654 2653 1207 1208 -1 
            1206 2652 2654 1208 1206 -1 
            1206 2652 2654 1208 1206 -1 
            1206 1207 1208 1206 -1 
            2652 2653 2654 2652 -1 
            1209 2655 2656 1210 1209 -1 
            1211 2657 2656 1210 1211 -1 
            1209 2655 2657 1211 1209 -1 
            1209 2655 2657 1211 1209 -1 
            1209 1210 1211 1209 -1 
            2655 2656 2657 2655 -1 
            1212 2658 2659 1213 1212 -1 
            1214 2660 2659 1213 1214 -1 
            1212 2658 2660 1214 1212 -1 
            1212 2658 2660 1214 1212 -1 
            1212 1213 1214 1212 -1 
            2658 2659 2660 2658 -1 
            1215 2661 2662 1216 1215 -1 
            1217 2663 2662 1216 1217 -1 
            1215 2661 2663 1217 1215 -1 
            1215 2661 2663 1217 1215 -1 
            1215 1216 1217 1215 -1 
            2661 2662 2663 2661 -1 
            1218 2664 2665 1219 1218 -1 
            1220 2666 2665 1219 1220 -1 
            1218 2664 2666 1220 1218 -1 
            1218 2664 2666 1220 1218 -1 
            1218 1219 1220 1218 -1 
            2664 2665 2666 2664 -1 
            1221 2667 2668 1222 1221 -1 
            1223 2669 2668 1222 1223 -1 
            1221 2667 2669 1223 1221 -1 
            1221 2667 2669 1223 1221 -1 
            1221 1222 1223 1221 -1 
            2667 2668 2669 2667 -1 
            1224 2670 2671 1225 1224 -1 
            1226 2672 2671 1225 1226 -1 
            1224 2670 2672 1226 1224 -1 
            1224 2670 2672 1226 1224 -1 
            1224 1225 1226 1224 -1 
            2670 2671 2672 2670 -1 
            1227 2673 2674 1228 1227 -1 
            1229 2675 2674 1228 1229 -1 
            1227 2673 2675 1229 1227 -1 
            1227 2673 2675 1229 1227 -1 
            1227 1228 1229 1227 -1 
            2673 2674 2675 2673 -1 
            1230 2676 2677 1231 1230 -1 
            1232 2678 2677 1231 1232 -1 
            1230 2676 2678 1232 1230 -1 
            1230 2676 2678 1232 1230 -1 
            1230 1231 1232 1230 -1 
            2676 2677 2678 2676 -1 
            1233 2679 2680 1234 1233 -1 
            1235 2681 2680 1234 1235 -1 
            1233 2679 2681 1235 1233 -1 
            1233 2679 2681 1235 1233 -1 
            1233 1234 1235 1233 -1 
            2679 2680 2681 2679 -1 
            1236 2682 2683 1237 1236 -1 
            1238 2684 2683 1237 1238 -1 
            1236 2682 2684 1238 1236 -1 
            1236 2682 2684 1238 1236 -1 
            1236 1237 1238 1236 -1 
            2682 2683 2684 2682 -1 
            1239 2685 2686 1240 1239 -1 
            1241 2687 2686 1240 1241 -1 
            1239 2685 2687 1241 1239 -1 
            1239 2685 2687 1241 1239 -1 
            1239 1240 1241 1239 -1 
            2685 2686 2687 2685 -1 
            1242 2688 2689 1243 1242 -1 
            1244 2690 2689 1243 1244 -1 
            1242 2688 2690 1244 1242 -1 
            1242 2688 2690 1244 1242 -1 
            1242 1243 1244 1242 -1 
            2688 2689 2690 2688 -1 
            1245 2691 2692 1246 1245 -1 
            1247 2693 2692 1246 1247 -1 
            1245 2691 2693 1247 1245 -1 
            1245 2691 2693 1247 1245 -1 
            1245 1246 1247 1245 -1 
            2691 2692 2693 2691 -1 
            1248 2694 2695 1249 1248 -1 
            1250 2696 2695 1249 1250 -1 
            1248 2694 2696 1250 1248 -1 
            1248 2694 2696 1250 1248 -1 
            1248 1249 1250 1248 -1 
            2694 2695 2696 2694 -1 
            1251 2697 2698 1252 1251 -1 
            1253 2699 2698 1252 1253 -1 
            1251 2697 2699 1253 1251 -1 
            1251 2697 2699 1253 1251 -1 
            1251 1252 1253 1251 -1 
            2697 2698 2699 2697 -1 
            1254 2700 2701 1255 1254 -1 
            1256 2702 2701 1255 1256 -1 
            1254 2700 2702 1256 1254 -1 
            1254 2700 2702 1256 1254 -1 
            1254 1255 1256 1254 -1 
            2700 2701 2702 2700 -1 
            1257 2703 2704 1258 1257 -1 
            1259 2705 2704 1258 1259 -1 
            1257 2703 2705 1259 1257 -1 
            1257 2703 2705 1259 1257 -1 
            1257 1258 1259 1257 -1 
            2703 2704 2705 2703 -1 
            1260 2706 2707 1261 1260 -1 
            1262 2708 2707 1261 1262 -1 
            1260 2706 2708 1262 1260 -1 
            1260 2706 2708 1262 1260 -1 
            1260 1261 1262 1260 -1 
            2706 2707 2708 2706 -1 
            1263 2709 2710 1264 1263 -1 
            1265 2711 2710 1264 1265 -1 
            1263 2709 2711 1265 1263 -1 
            1263 2709 2711 1265 1263 -1 
            1263 1264 1265 1263 -1 
            2709 2710 2711 2709 -1 
            1266 2712 2713 1267 1266 -1 
            1268 2714 2713 1267 1268 -1 
            1266 2712 2714 1268 1266 -1 
            1266 2712 2714 1268 1266 -1 
            1266 1267 1268 1266 -1 
            2712 2713 2714 2712 -1 
            1269 2715 2716 1270 1269 -1 
            1271 2717 2716 1270 1271 -1 
            1269 2715 2717 1271 1269 -1 
            1269 2715 2717 1271 1269 -1 
            1269 1270 1271 1269 -1 
            2715 2716 2717 2715 -1 
            1272 2718 2719 1273 1272 -1 
            1274 2720 2719 1273 1274 -1 
            1272 2718 2720 1274 1272 -1 
            1272 2718 2720 1274 1272 -1 
            1272 1273 1274 1272 -1 
            2718 2719 2720 2718 -1 
            1275 2721 2722 1276 1275 -1 
            1277 2723 2722 1276 1277 -1 
            1275 2721 2723 1277 1275 -1 
            1275 2721 2723 1277 1275 -1 
            1275 1276 1277 1275 -1 
            2721 2722 2723 2721 -1 
            1278 2724 2725 1279 1278 -1 
            1280 2726 2725 1279 1280 -1 
            1278 2724 2726 1280 1278 -1 
            1278 2724 2726 1280 1278 -1 
            1278 1279 1280 1278 -1 
            2724 2725 2726 2724 -1 
            1281 2727 2728 1282 1281 -1 
            1283 2729 2728 1282 1283 -1 
            1281 2727 2729 1283 1281 -1 
            1281 2727 2729 1283 1281 -1 
            1281 1282 1283 1281 -1 
            2727 2728 2729 2727 -1 
            1284 2730 2731 1285 1284 -1 
            1286 2732 2731 1285 1286 -1 
            1284 2730 2732 1286 1284 -1 
            1284 2730 2732 1286 1284 -1 
            1284 1285 1286 1284 -1 
            2730 2731 2732 2730 -1 
            1287 2733 2734 1288 1287 -1 
            1289 2735 2734 1288 1289 -1 
            1287 2733 2735 1289 1287 -1 
            1287 2733 2735 1289 1287 -1 
            1287 1288 1289 1287 -1 
            2733 2734 2735 2733 -1 
            1290 2736 2737 1291 1290 -1 
            1292 2738 2737 1291 1292 -1 
            1290 2736 2738 1292 1290 -1 
            1290 2736 2738 1292 1290 -1 
            1290 1291 1292 1290 -1 
            2736 2737 2738 2736 -1 
            1293 2739 2740 1294 1293 -1 
            1295 2741 2740 1294 1295 -1 
            1293 2739 2741 1295 1293 -1 
            1293 2739 2741 1295 1293 -1 
            1293 1294 1295 1293 -1 
            2739 2740 2741 2739 -1 
            1296 2742 2743 1297 1296 -1 
            1298 2744 2743 1297 1298 -1 
            1296 2742 2744 1298 1296 -1 
            1296 2742 2744 1298 1296 -1 
            1296 1297 1298 1296 -1 
            2742 2743 2744 2742 -1 
            1299 2745 2746 1300 1299 -1 
            1301 2747 2746 1300 1301 -1 
            1299 2745 2747 1301 1299 -1 
            1299 2745 2747 1301 1299 -1 
            1299 1300 1301 1299 -1 
            2745 2746 2747 2745 -1 
            1302 2748 2749 1303 1302 -1 
            1304 2750 2749 1303 1304 -1 
            1302 2748 2750 1304 1302 -1 
            1302 2748 2750 1304 1302 -1 
            1302 1303 1304 1302 -1 
            2748 2749 2750 2748 -1 
            1305 2751 2752 1306 1305 -1 
            1307 2753 2752 1306 1307 -1 
            1305 2751 2753 1307 1305 -1 
            1305 2751 2753 1307 1305 -1 
            1305 1306 1307 1305 -1 
            2751 2752 2753 2751 -1 
            1308 2754 2755 1309 1308 -1 
            1310 2756 2755 1309 1310 -1 
            1308 2754 2756 1310 1308 -1 
            1308 2754 2756 1310 1308 -1 
            1308 1309 1310 1308 -1 
            2754 2755 2756 2754 -1 
            1311 2757 2758 1312 1311 -1 
            1313 2759 2758 1312 1313 -1 
            1311 2757 2759 1313 1311 -1 
            1311 2757 2759 1313 1311 -1 
            1311 1312 1313 1311 -1 
            2757 2758 2759 2757 -1 
            1314 2760 2761 1315 1314 -1 
            1316 2762 2761 1315 1316 -1 
            1314 2760 2762 1316 1314 -1 
            1314 2760 2762 1316 1314 -1 
            1314 1315 1316 1314 -1 
            2760 2761 2762 2760 -1 
            1317 2763 2764 1318 1317 -1 
            1319 2765 2764 1318 1319 -1 
            1317 2763 2765 1319 1317 -1 
            1317 2763 2765 1319 1317 -1 
            1317 1318 1319 1317 -1 
            2763 2764 2765 2763 -1 
            1320 2766 2767 1321 1320 -1 
            1322 2768 2767 1321 1322 -1 
            1320 2766 2768 1322 1320 -1 
            1320 2766 2768 1322 1320 -1 
            1320 1321 1322 1320 -1 
            2766 2767 2768 2766 -1 
            1323 2769 2770 1324 1323 -1 
            1325 2771 2770 1324 1325 -1 
            1323 2769 2771 1325 1323 -1 
            1323 2769 2771 1325 1323 -1 
            1323 1324 1325 1323 -1 
            2769 2770 2771 2769 -1 
            1326 2772 2773 1327 1326 -1 
            1328 2774 2773 1327 1328 -1 
            1326 2772 2774 1328 1326 -1 
            1326 2772 2774 1328 1326 -1 
            1326 1327 1328 1326 -1 
            2772 2773 2774 2772 -1 
            1329 2775 2776 1330 1329 -1 
            1331 2777 2776 1330 1331 -1 
            1329 2775 2777 1331 1329 -1 
            1329 2775 2777 1331 1329 -1 
            1329 1330 1331 1329 -1 
            2775 2776 2777 2775 -1 
            1332 2778 2779 1333 1332 -1 
            1334 2780 2779 1333 1334 -1 
            1332 2778 2780 1334 1332 -1 
            1332 2778 2780 1334 1332 -1 
            1332 1333 1334 1332 -1 
            2778 2779 2780 2778 -1 
            1335 2781 2782 1336 1335 -1 
            1337 2783 2782 1336 1337 -1 
            1335 2781 2783 1337 1335 -1 
            1335 2781 2783 1337 1335 -1 
            1335 1336 1337 1335 -1 
            2781 2782 2783 2781 -1 
            1338 2784 2785 1339 1338 -1 
            1340 2786 2785 1339 1340 -1 
            1338 2784 2786 1340 1338 -1 
            1338 2784 2786 1340 1338 -1 
            1338 1339 1340 1338 -1 
            2784 2785 2786 2784 -1 
            1341 2787 2788 1342 1341 -1 
            1343 2789 2788 1342 1343 -1 
            1341 2787 2789 1343 1341 -1 
            1341 2787 2789 1343 1341 -1 
            1341 1342 1343 1341 -1 
            2787 2788 2789 2787 -1 
            1344 2790 2791 1345 1344 -1 
            1346 2792 2791 1345 1346 -1 
            1344 2790 2792 1346 1344 -1 
            1344 2790 2792 1346 1344 -1 
            1344 1345 1346 1344 -1 
            2790 2791 2792 2790 -1 
            1347 2793 2794 1348 1347 -1 
            1349 2795 2794 1348 1349 -1 
            1347 2793 2795 1349 1347 -1 
            1347 2793 2795 1349 1347 -1 
            1347 1348 1349 1347 -1 
            2793 2794 2795 2793 -1 
            1350 2796 2797 1351 1350 -1 
            1352 2798 2797 1351 1352 -1 
            1350 2796 2798 1352 1350 -1 
            1350 2796 2798 1352 1350 -1 
            1350 1351 1352 1350 -1 
            2796 2797 2798 2796 -1 
            1353 2799 2800 1354 1353 -1 
            1355 2801 2800 1354 1355 -1 
            1353 2799 2801 1355 1353 -1 
            1353 2799 2801 1355 1353 -1 
            1353 1354 1355 1353 -1 
            2799 2800 2801 2799 -1 
            1356 2802 2803 1357 1356 -1 
            1358 2804 2803 1357 1358 -1 
            1356 2802 2804 1358 1356 -1 
            1356 2802 2804 1358 1356 -1 
            1356 1357 1358 1356 -1 
            2802 2803 2804 2802 -1 
            1359 2805 2806 1360 1359 -1 
            1361 2807 2806 1360 1361 -1 
            1359 2805 2807 1361 1359 -1 
            1359 2805 2807 1361 1359 -1 
            1359 1360 1361 1359 -1 
            2805 2806 2807 2805 -1 
            1362 2808 2809 1363 1362 -1 
            1364 2810 2809 1363 1364 -1 
            1362 2808 2810 1364 1362 -1 
            1362 2808 2810 1364 1362 -1 
            1362 1363 1364 1362 -1 
            2808 2809 2810 2808 -1 
            1365 2811 2812 1366 1365 -1 
            1367 2813 2812 1366 1367 -1 
            1365 2811 2813 1367 1365 -1 
            1365 2811 2813 1367 1365 -1 
            1365 1366 1367 1365 -1 
            2811 2812 2813 2811 -1 
            1368 2814 2815 1369 1368 -1 
            1370 2816 2815 1369 1370 -1 
            1368 2814 2816 1370 1368 -1 
            1368 2814 2816 1370 1368 -1 
            1368 1369 1370 1368 -1 
            2814 2815 2816 2814 -1 
            1371 2817 2818 1372 1371 -1 
            1373 2819 2818 1372 1373 -1 
            1371 2817 2819 1373 1371 -1 
            1371 2817 2819 1373 1371 -1 
            1371 1372 1373 1371 -1 
            2817 2818 2819 2817 -1 
            1374 2820 2821 1375 1374 -1 
            1376 2822 2821 1375 1376 -1 
            1374 2820 2822 1376 1374 -1 
            1374 2820 2822 1376 1374 -1 
            1374 1375 1376 1374 -1 
            2820 2821 2822 2820 -1 
            1377 2823 2824 1378 1377 -1 
            1379 2825 2824 1378 1379 -1 
            1377 2823 2825 1379 1377 -1 
            1377 2823 2825 1379 1377 -1 
            1377 1378 1379 1377 -1 
            2823 2824 2825 2823 -1 
            1380 2826 2827 1381 1380 -1 
            1382 2828 2827 1381 1382 -1 
            1380 2826 2828 1382 1380 -1 
            1380 2826 2828 1382 1380 -1 
            1380 1381 1382 1380 -1 
            2826 2827 2828 2826 -1 
            1383 2829 2830 1384 1383 -1 
            1385 2831 2830 1384 1385 -1 
            1383 2829 2831 1385 1383 -1 
            1383 2829 2831 1385 1383 -1 
            1383 1384 1385 1383 -1 
            2829 2830 2831 2829 -1 
            1386 2832 2833 1387 1386 -1 
            1388 2834 2833 1387 1388 -1 
            1386 2832 2834 1388 1386 -1 
            1386 2832 2834 1388 1386 -1 
            1386 1387 1388 1386 -1 
            2832 2833 2834 2832 -1 
            1389 2835 2836 1390 1389 -1 
            1391 2837 2836 1390 1391 -1 
            1389 2835 2837 1391 1389 -1 
            1389 2835 2837 1391 1389 -1 
            1389 1390 1391 1389 -1 
            2835 2836 2837 2835 -1 
            1392 2838 2839 1393 1392 -1 
            1394 2840 2839 1393 1394 -1 
            1392 2838 2840 1394 1392 -1 
            1392 2838 2840 1394 1392 -1 
            1392 1393 1394 1392 -1 
            2838 2839 2840 2838 -1 
            1395 2841 2842 1396 1395 -1 
            1397 2843 2842 1396 1397 -1 
            1395 2841 2843 1397 1395 -1 
            1395 2841 2843 1397 1395 -1 
            1395 1396 1397 1395 -1 
            2841 2842 2843 2841 -1 
            1398 2844 2845 1399 1398 -1 
            1400 2846 2845 1399 1400 -1 
            1398 2844 2846 1400 1398 -1 
            1398 2844 2846 1400 1398 -1 
            1398 1399 1400 1398 -1 
            2844 2845 2846 2844 -1 
            1401 2847 2848 1402 1401 -1 
            1403 2849 2848 1402 1403 -1 
            1401 2847 2849 1403 1401 -1 
            1401 2847 2849 1403 1401 -1 
            1401 1402 1403 1401 -1 
            2847 2848 2849 2847 -1 
            1404 2850 2851 1405 1404 -1 
            1406 2852 2851 1405 1406 -1 
            1404 2850 2852 1406 1404 -1 
            1404 2850 2852 1406 1404 -1 
            1404 1405 1406 1404 -1 
            2850 2851 2852 2850 -1 
            1407 2853 2854 1408 1407 -1 
            1409 2855 2854 1408 1409 -1 
            1407 2853 2855 1409 1407 -1 
            1407 2853 2855 1409 1407 -1 
            1407 1408 1409 1407 -1 
            2853 2854 2855 2853 -1 
            1410 2856 2857 1411 1410 -1 
            1412 2858 2857 1411 1412 -1 
            1410 2856 2858 1412 1410 -1 
            1410 2856 2858 1412 1410 -1 
            1410 1411 1412 1410 -1 
            2856 2857 2858 2856 -1 
            1413 2859 2860 1414 1413 -1 
            1415 2861 2860 1414 1415 -1 
            1413 2859 2861 1415 1413 -1 
            1413 2859 2861 1415 1413 -1 
            1413 1414 1415 1413 -1 
            2859 2860 2861 2859 -1 
            1416 2862 2863 1417 1416 -1 
            1418 2864 2863 1417 1418 -1 
            1416 2862 2864 1418 1416 -1 
            1416 2862 2864 1418 1416 -1 
            1416 1417 1418 1416 -1 
            2862 2863 2864 2862 -1 
            1419 2865 2866 1420 1419 -1 
            1421 2867 2866 1420 1421 -1 
            1419 2865 2867 1421 1419 -1 
            1419 2865 2867 1421 1419 -1 
            1419 1420 1421 1419 -1 
            2865 2866 2867 2865 -1 
            1422 2868 2869 1423 1422 -1 
            1424 2870 2869 1423 1424 -1 
            1422 2868 2870 1424 1422 -1 
            1422 2868 2870 1424 1422 -1 
            1422 1423 1424 1422 -1 
            2868 2869 2870 2868 -1 
            1425 2871 2872 1426 1425 -1 
            1427 2873 2872 1426 1427 -1 
            1425 2871 2873 1427 1425 -1 
            1425 2871 2873 1427 1425 -1 
            1425 1426 1427 1425 -1 
            2871 2872 2873 2871 -1 
            1428 2874 2875 1429 1428 -1 
            1430 2876 2875 1429 1430 -1 
            1428 2874 2876 1430 1428 -1 
            1428 2874 2876 1430 1428 -1 
            1428 1429 1430 1428 -1 
            2874 2875 2876 2874 -1 
            1431 2877 2878 1432 1431 -1 
            1433 2879 2878 1432 1433 -1 
            1431 2877 2879 1433 1431 -1 
            1431 2877 2879 1433 1431 -1 
            1431 1432 1433 1431 -1 
            2877 2878 2879 2877 -1 
            1434 2880 2881 1435 1434 -1 
            1436 2882 2881 1435 1436 -1 
            1434 2880 2882 1436 1434 -1 
            1434 2880 2882 1436 1434 -1 
            1434 1435 1436 1434 -1 
            2880 2881 2882 2880 -1 
            1437 2883 2884 1438 1437 -1 
            1439 2885 2884 1438 1439 -1 
            1437 2883 2885 1439 1437 -1 
            1437 2883 2885 1439 1437 -1 
            1437 1438 1439 1437 -1 
            2883 2884 2885 2883 -1 
            1440 2886 2887 1441 1440 -1 
            1442 2888 2887 1441 1442 -1 
            1440 2886 2888 1442 1440 -1 
            1440 2886 2888 1442 1440 -1 
            1440 1441 1442 1440 -1 
            2886 2887 2888 2886 -1 
            1443 2889 2890 1444 1443 -1 
            1445 2891 2890 1444 1445 -1 
            1443 2889 2891 1445 1443 -1 
            1443 2889 2891 1445 1443 -1 
            1443 1444 1445 1443 -1 
            2889 2890 2891 2889 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0003"
              point="
              5.73275000000000000e+04 4.55720999999999985e+04 -1.54989902785930235e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.54989902785930235e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.54989902785930235e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -6.37915474587697759e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.37915474587697759e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -6.37915474587697759e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.04780239737094121e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.04780239737094121e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.04780239737094121e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.55375960112860594e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.55375960112860594e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.55375960112860594e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.14890111425296118e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.14890111425296118e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.14890111425296118e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.24389535015825845e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.24389535015825845e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.24389535015825845e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.47590422769903307e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.47590422769903307e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.47590422769903307e+03,
              4.51237802144240559e+04 5.33675903752420927e+04 -8.62141801960242674e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.62141801960242674e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -8.62141801960242674e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.09047261461959283e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.09047261461959283e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.09047261461959283e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.59718813948743127e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.59718813948743127e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.59718813948743127e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.58035137888453301e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.58035137888453301e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.58035137888453301e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.14818589255428446e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.14818589255428446e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.14818589255428446e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.04006446005133421e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.04006446005133421e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -1.04006446005133421e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.61166239576147336e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.61166239576147336e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.61166239576147336e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.11386646080834225e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.11386646080834225e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.11386646080834225e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.51670919908269707e+03,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.51670919908269707e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.51670919908269707e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.53445382971280878e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.53445382971280878e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.53445382971280878e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.39122720749252926e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.39122720749252926e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.39122720749252926e+03,
              4.51237802144240559e+04 5.33675903752420927e+04 -8.31306244081998102e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 -8.31306244081998102e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -8.31306244081998102e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.50175125037945872e+03,
              5.73275000000000000e+04 4.55720999999999985e+04 -1.50175125037945872e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.50175125037945872e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.59711834103915862e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.59711834103915862e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.59711834103915862e+03,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.15626161052963926e+03,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.15626161052963926e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.15626161052963926e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.32679009775515283e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.32679009775515283e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.32679009775515283e+03,
              5.79743590006702070e+04 4.82669590006702056e+04 -1.56671379156926923e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.56671379156926923e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.56671379156926923e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.10320744533348943e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -9.10320744533348943e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -9.10320744533348943e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.43355560665987173e+03,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.43355560665987173e+03,
              5.93755000000000000e+04 4.35240999999999985e+04 -1.43355560665987173e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.47103884896571253e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.47103884896571253e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.47103884896571253e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.37737143031206733e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.37737143031206733e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.37737143031206733e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.36778279519609259e+03,
              5.52795000000000000e+04 4.76200999999999985e+04 -1.36778279519609259e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.36778279519609259e+03,
              4.29915000000000000e+04 4.35240999999999985e+04 -1.13908722694784456e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.13908722694784456e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.13908722694784456e+03,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.92218095587967014e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 -9.92218095587967014e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 -9.92218095587967014e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.61064224971352724e+03,
              5.52795000000000000e+04 4.35240999999999985e+04 -1.61064224971352724e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.61064224971352724e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.37947750177749435e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.37947750177749435e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.37947750177749435e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.62269336471706879e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.62269336471706879e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.62269336471706879e+03,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.36965115944821150e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 -6.36965115944821150e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.36965115944821150e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.76365654535559543e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 -9.76365654535559543e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.76365654535559543e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.05561085119020549e+03,
              4.96475000000000000e+04 4.96680999999999985e+04 -1.05561085119020549e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.05561085119020549e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -9.20659823182302716e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.20659823182302716e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -9.20659823182302716e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.17891137577845711e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -9.17891137577845711e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.17891137577845711e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.67571184066967135e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -8.67571184066967135e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.67571184066967135e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.05759665847356587e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.05759665847356587e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.05759665847356587e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -7.25505900948060798e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.25505900948060798e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -7.25505900948060798e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.10355853236112921e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.10355853236112921e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.10355853236112921e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.42126969679353579e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.42126969679353579e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.42126969679353579e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.03119104663403664e+03,
              5.32315000000000000e+04 4.96680999999999985e+04 -1.03119104663403664e+03,
              5.11835000000000000e+04 5.17160999999999985e+04 -1.03119104663403664e+03,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.74420964540049681e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -7.74420964540049681e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -7.74420964540049681e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.07538072020943537e+03,
              5.32096991355887149e+04 5.39706557443024576e+04 -1.07538072020943537e+03,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.07538072020943537e+03,
              4.29915000000000000e+04 5.17160999999999985e+04 -9.42231932448533144e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.42231932448533144e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 -9.42231932448533144e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 -8.44182194990245080e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.44182194990245080e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.44182194990245080e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.09473186162482648e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -8.09473186162482648e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.09473186162482648e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 -8.62738005578175262e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -8.62738005578175262e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -8.62738005578175262e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.74768076201346048e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.74768076201346048e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.74768076201346048e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -1.25451233617229764e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.25451233617229764e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.25451233617229764e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -7.46474058891736490e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -7.46474058891736490e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -7.46474058891736490e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.51029062173571833e+03,
              5.58018045982720359e+04 5.23991934280011774e+04 -1.51029062173571833e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.51029062173571833e+03,
              5.38918848789301701e+04 5.81450728434154516e+04 -8.86989455860936573e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -8.86989455860936573e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 -8.86989455860936573e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.55568592138692884e+03,
              5.99340390256030078e+04 5.02266390256030063e+04 -1.55568592138692884e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.55568592138692884e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.45226636298966673e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.45226636298966673e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.45226636298966673e+03,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.24313400368322164e+03,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.24313400368322164e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.24313400368322164e+03,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.61463790362102486e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.61463790362102486e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -6.61463790362102486e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -2.77450958665124972e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.77450958665124972e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -2.77450958665124972e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.61204532756237995e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.61204532756237995e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.61204532756237995e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.15909906536080712e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.15909906536080712e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.15909906536080712e+03,
              5.62388542463878912e+04 6.39891597383460539e+04 -6.29617726578382644e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.29617726578382644e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.29617726578382644e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.38204538820663538e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.38204538820663538e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.38204538820663538e+03,
              4.29915000000000000e+04 6.68746000000000058e+04 -4.46191524702484116e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -4.46191524702484116e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.46191524702484116e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -7.37660055255678799e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -7.37660055255678799e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -7.37660055255678799e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -6.06002076377194385e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.06002076377194385e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.06002076377194385e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -6.03253248269489745e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -6.03253248269489745e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.03253248269489745e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -8.56781490203755993e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -8.56781490203755993e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -8.56781490203755993e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -8.51517486308060143e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -8.51517486308060143e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.51517486308060143e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -6.63190561522233907e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 -6.63190561522233907e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.63190561522233907e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.18907693536632109e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.18907693536632109e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.18907693536632109e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -5.70552429577283533e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.70552429577283533e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.70552429577283533e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.30872309089060536e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -6.30872309089060536e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.30872309089060536e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.39764465349409511e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.39764465349409511e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.39764465349409511e+03,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.05289347604601403e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.05289347604601403e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -3.05289347604601403e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -5.73268602176656714e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.73268602176656714e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.73268602176656714e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.76497572638319298e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -6.76497572638319298e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -6.76497572638319298e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -5.10421504239243859e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.10421504239243859e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.10421504239243859e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.20916253045452549e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.20916253045452549e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -5.20916253045452549e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 -8.00345891681496141e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -8.00345891681496141e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.00345891681496141e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -5.34489832969704025e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -5.34489832969704025e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.34489832969704025e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -5.79094711490698273e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 -5.79094711490698273e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.79094711490698273e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.96960041531049626e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -4.96960041531049626e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.96960041531049626e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -8.04207439423549886e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 -8.04207439423549886e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -8.04207439423549886e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -2.35446135549918040e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 -2.35446135549918040e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.35446135549918040e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -3.78687850032053120e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.78687850032053120e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.78687850032053120e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.60407700415179988e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 -5.60407700415179988e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.60407700415179988e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.39785070382808499e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -5.39785070382808499e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.39785070382808499e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.41804810611357539e+03,
              6.01282800268421852e+04 4.63248800268421837e+04 -1.41804810611357539e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.41804810611357539e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.51372170900116566e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.51372170900116566e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.51372170900116566e+03,
              6.33337500000000000e+04 4.35240999999999985e+04 -1.33157941701915820e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.33157941701915820e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.33157941701915820e+03,
              6.40928094395280787e+04 4.62202124450514166e+04 -1.44902506352976025e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.44902506352976025e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.44902506352976025e+03,
              7.12502500000000000e+04 4.35240999999999985e+04 -9.34479599838987383e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 -9.34479599838987383e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -9.34479599838987383e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.25991903203037327e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.25991903203037327e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.25991903203037327e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.17310940902124889e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.17310940902124889e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.17310940902124889e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.07594135540700063e+03,
              7.12502500000000000e+04 4.35240999999999985e+04 -1.07594135540700063e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.07594135540700063e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.59407505635596158e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.59407505635596158e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.59407505635596158e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.22820201000691100e+03,
              6.72920000000000000e+04 4.35240999999999985e+04 -1.22820201000691100e+03,
              6.69673509977274662e+04 4.63849996890717157e+04 -1.22820201000691100e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.33982461667277721e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.33982461667277721e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.33982461667277721e+03,
              7.19747267795647203e+04 4.84279263615037416e+04 -8.51291965160175209e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -8.51291965160175209e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -8.51291965160175209e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -1.13584992454976532e+03,
              7.01469530831564916e+04 4.65087122237546937e+04 -1.13584992454976532e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.13584992454976532e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.36301550809882497e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.36301550809882497e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.36301550809882497e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.62309678232317947e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.62309678232317947e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.62309678232317947e+03,
              6.23213089173411572e+04 4.83897698766464528e+04 -1.50284540725725174e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.50284540725725174e+03,
              6.53128750000000000e+04 4.87414489625658171e+04 -1.50284540725725174e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.56696564073695527e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.56696564073695527e+03,
              6.31639635133035335e+04 5.19546492002441300e+04 -1.56696564073695527e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.19596889452451046e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.19596889452451046e+03,
              5.11835000000000000e+04 4.76200999999999985e+04 -1.19596889452451046e+03,
              4.91355000000000000e+04 4.35240999999999985e+04 -1.20766360110590517e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.20766360110590517e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.20766360110590517e+03,
              5.11835000000000000e+04 4.35240999999999985e+04 -1.48759893428044347e+03,
              5.01595000000000000e+04 4.55720999999999985e+04 -1.48759893428044347e+03,
              5.32315000000000000e+04 4.55720999999999985e+04 -1.48759893428044347e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.17170361855763031e+03,
              4.83675000000000000e+04 4.71080999999999985e+04 -1.17170361855763031e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.17170361855763031e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.20360367966930994e+03,
              4.70875000000000000e+04 4.35240999999999985e+04 -1.20360367966930994e+03,
              4.29915000000000000e+04 4.35240999999999985e+04 -1.20360367966930994e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.08268815581459944e+03,
              4.50395000000000000e+04 4.55720999999999985e+04 -1.08268815581459944e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.08268815581459944e+03,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.90232312651461257e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 -9.90232312651461257e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 -9.90232312651461257e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.02163784419466276e+03,
              4.29915000000000000e+04 4.76200999999999985e+04 -1.02163784419466276e+03,
              4.29915000000000000e+04 4.96680999999999985e+04 -1.02163784419466276e+03,
              4.47835000000000000e+04 5.06920999999999985e+04 -1.00490515101210883e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -1.00490515101210883e+03,
              4.50395000000000000e+04 4.86440999999999985e+04 -1.00490515101210883e+03,
              4.70875000000000000e+04 4.96680999999999985e+04 -9.68252759306182952e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.68252759306182952e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 -9.68252759306182952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.93741950518555768e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.93741950518555768e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -7.93741950518555768e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 -9.04300163874872169e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 -9.04300163874872169e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 -9.04300163874872169e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.44721764955241952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.44721764955241952e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.44721764955241952e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -7.07953281777347797e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -6.28983400844030371e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.28983400844030371e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 -6.28983400844030371e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 -6.93945069837851293e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.93945069837851293e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -6.93945069837851293e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -6.08001485842184707e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 -6.08001485842184707e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -6.08001485842184707e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.57704336271015336e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.57704336271015336e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 -7.57704336271015336e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.22582601566710878e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 -7.22582601566710878e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -7.22582601566710878e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -7.05777280950994964e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -7.05777280950994964e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 -7.05777280950994964e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.41153582263419480e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.41153582263419480e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.41153582263419480e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 -6.30373620879683585e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 -6.30373620879683585e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -6.30373620879683585e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.84875903915528397e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.84875903915528397e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 -5.84875903915528397e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 -5.74098494540717184e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 -5.74098494540717184e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -5.74098494540717184e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.74173633628618404e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.74173633628618404e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 -5.74173633628618404e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 -4.92201556391211398e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 -4.92201556391211398e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.92201556391211398e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -5.22796202954084492e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -5.22796202954084492e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -5.22796202954084492e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -8.38578400801222301e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.38578400801222301e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.38578400801222301e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -7.44151689076554362e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -7.44151689076554362e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 -7.44151689076554362e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -6.56929116542612178e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -6.56929116542612178e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.56929116542612178e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.43062834066491928e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.43062834066491928e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.43062834066491928e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.05545595002935443e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.05545595002935443e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.05545595002935443e+03,
              4.53015577947763959e+04 6.51329750000000058e+04 -5.28679229918452393e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -5.28679229918452393e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 -5.28679229918452393e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.53400137528681398e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 -4.53400137528681398e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -4.53400137528681398e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 -9.62671146957702263e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -9.62671146957702263e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -9.62671146957702263e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -9.71966047507317171e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 -9.71966047507317171e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 -9.71966047507317171e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -5.53577545522798459e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 -5.53577545522798459e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.53577545522798459e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.54233262785559009e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.54233262785559009e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.54233262785559009e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.02855407036119595e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.02855407036119595e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -1.02855407036119595e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.40184870204428171e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.40184870204428171e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.40184870204428171e+03,
              9.10415000000000000e+04 6.56491000000000058e+04 -2.50447288387922669e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -2.50447288387922669e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -2.50447288387922669e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 -9.22515200773750848e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 -9.22515200773750848e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -9.22515200773750848e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.25174421949356952e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.25174421949356952e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.25174421949356952e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -7.63692424972593813e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -7.63692424972593813e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -7.63692424972593813e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -1.00051184910443976e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.00051184910443976e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.00051184910443976e+03,
              8.28433143261273362e+04 5.35435587279313040e+04 -8.99167333721740988e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -8.99167333721740988e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -8.99167333721740988e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -7.51158173597061932e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -7.51158173597061932e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 -7.51158173597061932e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.18633362159612125e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.18633362159612125e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.18633362159612125e+03,
              7.64477573013165093e+04 5.07791925883061776e+04 -9.41845783338410229e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 -9.41845783338410229e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -9.41845783338410229e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -5.14789205786542084e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.14789205786542084e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.14789205786542084e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.27908766324879866e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.27908766324879866e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.27908766324879866e+03,
              8.11458750000000000e+04 4.55484482850344648e+04 -4.11486233346094480e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -4.11486233346094480e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 -4.11486233346094480e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.41147048340537026e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.41147048340537026e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.41147048340537026e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.54156439600595809e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.54156439600595809e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.54156439600595809e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.59626490451779659e+03,
              6.40137724255962821e+04 5.42287530279939601e+04 -1.59626490451779659e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.59626490451779659e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.19869310141632218e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.19869310141632218e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.19869310141632218e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.55234980606984595e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.55234980606984595e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.55234980606984595e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.42631925827065334e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.42631925827065334e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.42631925827065334e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.38995725775938649e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.38995725775938649e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.38995725775938649e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.27396206664154352e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.27396206664154352e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.27396206664154352e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.36382691095044493e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.36382691095044493e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.36382691095044493e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.18126919686537576e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.18126919686537576e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.18126919686537576e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.22770553576312159e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.22770553576312159e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.22770553576312159e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.47675201584509546e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.47675201584509546e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.47675201584509546e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.46961267051658615e+03,
              6.71327284303460910e+04 5.92688412464384237e+04 -1.46961267051658615e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.46961267051658615e+03,
              6.57771629885424773e+04 5.65667108624828033e+04 -1.48068400749846819e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.48068400749846819e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.48068400749846819e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.38175522225175951e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.38175522225175951e+03,
              6.85836783884220058e+04 5.68498644275479382e+04 -1.38175522225175951e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.34880061985139218e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.34880061985139218e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.34880061985139218e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.32106622639239617e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.32106622639239617e+03,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.32106622639239617e+03,
              7.07983196008750092e+04 5.51028472750062865e+04 -1.32084747284081209e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.32084747284081209e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.32084747284081209e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.28882946861265464e+03,
              7.32788534392044967e+04 5.61954990994093168e+04 -1.28882946861265464e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.28882946861265464e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.22773566775754193e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.22773566775754193e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.22773566775754193e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.15155057869729944e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.15155057869729944e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.15155057869729944e+03,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.54500478129625208e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -6.54500478129625208e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -6.54500478129625208e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 -6.94544228481156438e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -6.94544228481156438e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 -6.94544228481156438e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.52964010662053738e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.52964010662053738e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -2.52964010662053738e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.60502555152936566e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -5.60502555152936566e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 -5.60502555152936566e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.75032515032106062e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.75032515032106062e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -2.75032515032106062e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -5.97745580807628471e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.97745580807628471e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -5.97745580807628471e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.74799283498481543e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -2.74799283498481543e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -2.74799283498481543e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 -3.21048623397981771e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -3.21048623397981771e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -3.21048623397981771e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -5.75814137731806113e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -5.75814137731806113e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -5.75814137731806113e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 -4.71128251186164903e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -4.71128251186164903e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -4.71128251186164903e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -4.91159865798663418e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -4.91159865798663418e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 -4.91159865798663418e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -1.65411945040553150e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.65411945040553150e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.65411945040553150e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.17127387245225145e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.17127387245225145e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.17127387245225145e+03,
              8.08537882636642753e+04 6.57374252120232413e+04 -8.74335467038114075e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -8.74335467038114075e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.74335467038114075e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.13613714170831031e+03,
              7.53199660602289659e+04 5.33234331149488426e+04 -1.13613714170831031e+03,
              7.59840736912662105e+04 5.60260307533853993e+04 -1.13613714170831031e+03,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.71781287890125839e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 -1.71781287890125839e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 -1.71781287890125839e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.02725330639301433e+03,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.02725330639301433e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -1.02725330639301433e+03,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.26202918026849062e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.26202918026849062e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.26202918026849062e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -1.63994919593031852e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.63994919593031852e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.63994919593031852e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -7.72616780275687461e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -7.72616780275687461e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.72616780275687461e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -1.00632959970353795e+03,
              7.94176002086696244e+04 5.54124115490834884e+04 -1.00632959970353795e+03,
              7.82768317177685967e+04 5.87085122944746108e+04 -1.00632959970353795e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -9.70464329656160771e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 -9.70464329656160771e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -9.70464329656160771e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -9.07321110829576355e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -9.07321110829576355e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.07321110829576355e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -3.06256635620653526e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -3.06256635620653526e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -3.06256635620653526e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -1.34136871805149553e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.34136871805149553e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 -1.34136871805149553e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.27738159811700513e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 -1.27738159811700513e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.27738159811700513e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -9.04246338181003807e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 -9.04246338181003807e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -9.04246338181003807e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.99120696550614639e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -6.99120696550614639e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.99120696550614639e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.19821450240310810e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -7.19821450240310810e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -7.19821450240310810e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -9.73920688139467529e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -9.73920688139467529e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -9.73920688139467529e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -1.05093226547629274e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.05093226547629274e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.05093226547629274e+03,
              8.04150160157289356e+04 6.28917311803685370e+04 -8.55559546867083213e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -8.55559546867083213e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -8.55559546867083213e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -7.80870107069015830e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -7.80870107069015830e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -7.80870107069015830e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 -8.40353868782808263e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -8.40353868782808263e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.40353868782808263e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.18401224899737372e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -4.18401224899737372e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -4.18401224899737372e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -1.45935141253962968e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -1.45935141253962968e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -1.45935141253962968e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.48430310936736760e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -5.48430310936736760e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -5.48430310936736760e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 -1.07508302582244983e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.07508302582244983e+03,
              7.80244469299197954e+04 6.52032686577075292e+04 -1.07508302582244983e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.30005009289198756e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.30005009289198756e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.30005009289198756e+03,
              8.16333676824811409e+04 5.97976004010879988e+04 -7.18771258153142526e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -7.18771258153142526e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -7.18771258153142526e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -6.56049466418250063e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -6.56049466418250063e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -6.56049466418250063e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -2.48916247089439508e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -2.48916247089439508e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -2.48916247089439508e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -5.28927823952723315e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -5.28927823952723315e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -5.28927823952723315e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 -1.32789048198373848e+03,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.32789048198373848e+03,
              7.48459019418140524e+04 5.95338409919303376e+04 -1.32789048198373848e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.25212812499425672e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.25212812499425672e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.25212812499425672e+03,
              8.32611584966219380e+04 6.22254986442461886e+04 -7.73752121909965922e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 -7.73752121909965922e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -7.73752121909965922e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -6.04791338227878668e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -6.04791338227878668e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 -6.04791338227878668e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -6.98657895022819048e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -6.98657895022819048e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -6.98657895022819048e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.09472019074785521e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.09472019074785521e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -5.09472019074785521e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 -1.09797861564776241e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.09797861564776241e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.09797861564776241e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -4.33629779244460451e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.33629779244460451e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -4.33629779244460451e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -6.94060534868092873e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -6.94060534868092873e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -6.94060534868092873e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -1.88608819237264697e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -1.88608819237264697e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.88608819237264697e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.85940179182455665e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.85940179182455665e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.85940179182455665e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.73842073386209904e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.73842073386209904e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 -5.73842073386209904e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -2.83585757331886043e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -2.83585757331886043e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -2.83585757331886043e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -6.70579829515112124e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -6.70579829515112124e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -6.70579829515112124e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -5.51293988649818857e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -5.51293988649818857e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -5.51293988649818857e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 -6.95807937292608699e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 -6.95807937292608699e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.95807937292608699e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -6.01235618984170742e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 -6.01235618984170742e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.01235618984170742e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 -5.12380567480501441e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -5.12380567480501441e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -5.12380567480501441e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -6.15323947799511984e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 -6.15323947799511984e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -6.15323947799511984e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -5.42469899036878701e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -5.42469899036878701e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -5.42469899036878701e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -7.45292215479388233e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -7.45292215479388233e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -7.45292215479388233e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -1.75689303076548839e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.75689303076548839e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 -1.75689303076548839e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -1.58626335702772792e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -1.58626335702772792e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -1.58626335702772792e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 -9.43048889831737824e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 -9.43048889831737824e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -9.43048889831737824e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 -7.66253242345806825e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 -7.66253242345806825e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 -7.66253242345806825e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 -5.52814726986519759e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -5.52814726986519759e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 -5.52814726986519759e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 -1.44345615463714637e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.44345615463714637e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 -1.44345615463714637e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.69288171915683733e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.69288171915683733e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -4.69288171915683733e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 6.72129483803797569e+01,
              4.29915000000000000e+04 8.08076000000000058e+04 6.72129483803797569e+01,
              4.68635539404924566e+04 8.35416926830068551e+04 6.72129483803797569e+01,
              6.03973817143073538e+04 7.27813878736088955e+04 -4.39634656352286925e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.39634656352286925e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.39634656352286925e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -3.91416883787495465e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -3.91416883787495465e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.91416883787495465e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 -1.05740762526366041e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.05740762526366041e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.05740762526366041e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.29458475348189836e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -4.29458475348189836e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -4.29458475348189836e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -3.81310680035209259e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.81310680035209259e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 -3.81310680035209259e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -2.49827543315429182e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -2.49827543315429182e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -2.49827543315429182e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.85223017262790279e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.85223017262790279e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 -3.85223017262790279e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.40106563187701170e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 -3.40106563187701170e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 -3.40106563187701170e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -3.79777912269632111e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.79777912269632111e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.79777912269632111e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 -3.05590707394267326e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 -3.05590707394267326e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -3.05590707394267326e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.07290917884902171e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.07290917884902171e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -4.07290917884902171e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.46272512474281086e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -1.46272512474281086e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.46272512474281086e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 -2.90085876279641752e+00,
              4.53027798571047970e+04 7.90659750000000058e+04 -2.90085876279641752e+00,
              4.29915000000000000e+04 7.73243500000000058e+04 -2.90085876279641752e+00,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.52237195587251904e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 -1.52237195587251904e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -1.52237195587251904e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.35687990056871513e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -1.35687990056871513e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.35687990056871513e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.36782409242099504e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.36782409242099504e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -1.36782409242099504e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.70660145389498894e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -1.70660145389498894e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -1.70660145389498894e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -1.91273514670424333e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 -1.91273514670424333e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 -1.91273514670424333e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.32095101912305267e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 1.32095101912305267e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 1.32095101912305267e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.31949999713019963e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 -2.31949999713019963e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -2.31949999713019963e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 -6.27152217550164153e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 -6.27152217550164153e+01,
              4.77584339542222879e+04 7.56540985605456081e+04 -6.27152217550164153e+01,
              5.77128434890498829e+04 7.28292648936219484e+04 -2.85219537080508417e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.85219537080508417e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.85219537080508417e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -1.84415914645255071e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -1.84415914645255071e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.84415914645255071e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 -4.72908617916845401e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -4.72908617916845401e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 -4.72908617916845401e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -3.65699925363983255e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.65699925363983255e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -3.65699925363983255e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 -2.25220953557626586e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.25220953557626586e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -2.25220953557626586e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.48457754366606196e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 -3.48457754366606196e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 -3.48457754366606196e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 -6.95605931952111405e+01,
              4.53027798571047970e+04 7.90659750000000058e+04 -6.95605931952111405e+01,
              4.77584339542222879e+04 7.56540985605456081e+04 -6.95605931952111405e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 4.71446288093625583e+01,
              5.06225252789799124e+04 7.93839231851334625e+04 4.71446288093625583e+01,
              4.86229215617078662e+04 8.12219413673433592e+04 4.71446288093625583e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 2.86205148207011462e+00,
              4.53027798571047970e+04 7.90659750000000058e+04 2.86205148207011462e+00,
              4.86229215617078662e+04 8.12219413673433592e+04 2.86205148207011462e+00,
              4.86229215617078662e+04 8.12219413673433592e+04 1.13907342553920969e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.13907342553920969e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 1.13907342553920969e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 2.33186961239230101e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 2.33186961239230101e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.33186961239230101e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -3.00418488234638176e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -3.00418488234638176e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -3.00418488234638176e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.53904601400236714e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -2.53904601400236714e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 -2.53904601400236714e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.13621816586127068e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.13621816586127068e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -2.13621816586127068e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.16414178233629428e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.16414178233629428e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.16414178233629428e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.31957475347395160e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.31957475347395160e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.31957475347395160e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 1.10140027131294289e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 1.10140027131294289e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.10140027131294289e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 2.31390073535880560e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 2.31390073535880560e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 2.31390073535880560e+01,
              4.89977500000000000e+04 8.77741000000000058e+04 2.00506197316279128e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 2.00506197316279128e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.00506197316279128e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.92610286663583764e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.92610286663583764e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -1.92610286663583764e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.00671211094553414e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.00671211094553414e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.00671211094553414e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -1.62750609264671368e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 -1.62750609264671368e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -1.62750609264671368e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 -6.79185001595731990e+01,
              5.52263343014311831e+04 8.04312537877296854e+04 -6.79185001595731990e+01,
              5.59416064564449553e+04 7.77443652293567866e+04 -6.79185001595731990e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 8.31047349349756104e+00,
              5.52263343014311831e+04 8.04312537877296854e+04 8.31047349349756104e+00,
              5.31712790675824799e+04 7.84455309487277991e+04 8.31047349349756104e+00,
              5.06225252789799124e+04 7.93839231851334625e+04 -7.72143989394103585e+01,
              5.31712790675824799e+04 7.84455309487277991e+04 -7.72143989394103585e+01,
              5.16410222818254188e+04 7.60320974242347147e+04 -7.72143989394103585e+01,
              5.52263343014311831e+04 8.04312537877296854e+04 -5.74525441574589735e+00,
              5.50589499526593063e+04 8.29185620004188240e+04 -5.74525441574589735e+00,
              5.79559215132922254e+04 7.99987952834800672e+04 -5.74525441574589735e+00,
              4.59946250000000000e+04 8.77741000000000058e+04 2.17545226124599083e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 2.17545226124599083e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.17545226124599083e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -6.89962457236648106e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -6.89962457236648106e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -6.89962457236648106e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -6.83919816251171142e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -6.83919816251171142e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -6.83919816251171142e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.58626902859957227e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 1.58626902859957227e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.58626902859957227e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 -6.81118353624262340e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 -6.81118353624262340e+01,
              5.59416064564449553e+04 7.77443652293567866e+04 -6.81118353624262340e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 -1.97282032571461627e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -1.97282032571461627e+01,
              5.79559215132922254e+04 7.99987952834800672e+04 -1.97282032571461627e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 -1.05860985513656551e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 -1.05860985513656551e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -1.05860985513656551e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 4.54968327639450365e+00,
              5.72171465710359116e+04 8.41663228034385247e+04 4.54968327639450365e+00,
              6.05799435922970297e+04 8.44286472202762234e+04 4.54968327639450365e+00,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.28068293724087056e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 -2.28068293724087056e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.28068293724087056e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.64710674825756541e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.64710674825756541e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -1.64710674825756541e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -3.32554607022782989e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -3.32554607022782989e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -3.32554607022782989e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.06914633193009827e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 3.06914633193009827e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 3.06914633193009827e+01,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.44409907921055537e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.44409907921055537e+03,
              5.92101729004537628e+04 5.38659000102998980e+04 -1.44409907921055537e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.38816871352411272e+03,
              6.41120631072935794e+04 5.93898672147942198e+04 -1.38816871352411272e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.38816871352411272e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -8.92656511961674596e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 -8.92656511961674596e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -8.92656511961674596e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.36022587871006226e+03,
              6.13836756015105784e+04 5.90276584692566685e+04 -1.36022587871006226e+03,
              6.28558784486122604e+04 5.63625301824463022e+04 -1.36022587871006226e+03,
              5.87181748172013322e+04 6.20776562233111908e+04 -7.69525129733161180e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 -7.69525129733161180e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 -7.69525129733161180e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 -1.08450391642766226e+03,
              5.89703665896460734e+04 5.96083189957064678e+04 -1.08450391642766226e+03,
              5.99704276363947793e+04 5.66658744515800427e+04 -1.08450391642766226e+03,
              6.63835868025818199e+04 5.37017774826113891e+04 -1.43093354714030170e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.43093354714030170e+03,
              6.80626200683170464e+04 4.89130271149768523e+04 -1.43093354714030170e+03,
              7.03174474955796468e+04 5.04961538554442741e+04 -1.28058166845976189e+03,
              6.90496608097013377e+04 5.29569371856979051e+04 -1.28058166845976189e+03,
              7.34233317617715802e+04 5.12868056873617970e+04 -1.28058166845976189e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -1.03398878114219497e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.03398878114219497e+03,
              6.18309149651218031e+04 6.17434033962874310e+04 -1.03398878114219497e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -9.56502404166317774e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -9.56502404166317774e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 -9.56502404166317774e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -9.53151022174442346e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -9.53151022174442346e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 -9.53151022174442346e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.00584849887217570e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.00584849887217570e+03,
              6.64749768790189410e+04 6.99144954876966513e+04 -1.00584849887217570e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.07242631483253331e+03,
              6.29925708707179147e+04 6.48646240982857780e+04 -1.07242631483253331e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.07242631483253331e+03,
              6.64749768790189410e+04 6.99144954876966513e+04 -7.76067355406011075e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 -7.76067355406011075e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -7.76067355406011075e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.42052949907918583e+03,
              6.60546313016843633e+04 6.35549489360035586e+04 -1.42052949907918583e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.42052949907918583e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.13914044435214760e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.13914044435214760e+03,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.13914044435214760e+03,
              6.93061383463380189e+04 7.23374074222391209e+04 -6.87212559351575692e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -6.87212559351575692e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -6.87212559351575692e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -4.04369770007552688e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -4.04369770007552688e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -4.04369770007552688e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 -1.36453781653367105e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.36453781653367105e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.36453781653367105e+03,
              7.12115982768096728e+04 6.91350333107481420e+04 -1.12007787773389100e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.12007787773389100e+03,
              6.87010588844871381e+04 6.86604716951115843e+04 -1.12007787773389100e+03,
              7.85990063731790578e+04 6.99202809302571986e+04 -7.33671050597323642e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.33671050597323642e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -7.33671050597323642e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -2.12003136904954630e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.12003136904954630e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.12003136904954630e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.25291859262093340e+03,
              6.70863062222572335e+04 6.66804235625501024e+04 -1.25291859262093340e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.25291859262093340e+03,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.94698825159162993e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 -9.94698825159162993e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.94698825159162993e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.27872851437105237e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.27872851437105237e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.27872851437105237e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.16318790082322334e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.16318790082322334e+03,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.16318790082322334e+03,
              7.45080858675325871e+04 7.32645676197651628e+04 -7.64060344348075660e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.64060344348075660e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -7.64060344348075660e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -9.02423480904717621e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.02423480904717621e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -9.02423480904717621e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -5.51843264146671686e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -5.51843264146671686e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -5.51843264146671686e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -5.60572729033713131e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -5.60572729033713131e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -5.60572729033713131e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -2.77248150389767886e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 -2.77248150389767886e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 -2.77248150389767886e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.04985076162832911e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.04985076162832911e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 -4.04985076162832911e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -4.50190103608573281e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -4.50190103608573281e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 -4.50190103608573281e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 -5.36969490691020383e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 -5.36969490691020383e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -5.36969490691020383e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.31893200289073161e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.31893200289073161e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.31893200289073161e+03,
              6.93939625704202335e+04 6.41856909210061058e+04 -1.38598729641224395e+03,
              7.14090038630176859e+04 6.28518056404307354e+04 -1.38598729641224395e+03,
              6.97366489118925965e+04 6.08046356352454095e+04 -1.38598729641224395e+03,
              7.85990063731790578e+04 6.99202809302571986e+04 -6.14984679088943039e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -6.14984679088943039e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -6.14984679088943039e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -3.56227038417081758e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -3.56227038417081758e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -3.56227038417081758e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -3.06110485376227984e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -3.06110485376227984e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 -3.06110485376227984e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -2.93038804144138737e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 -2.93038804144138737e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.93038804144138737e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -6.93161318651184274e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -6.93161318651184274e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 -6.93161318651184274e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 -7.73763705916300296e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 -7.73763705916300296e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -7.73763705916300296e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -7.50855865674396114e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -7.50855865674396114e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 -7.50855865674396114e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -4.88122630344954075e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -4.88122630344954075e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 -4.88122630344954075e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -5.75760069848496641e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -5.75760069848496641e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -5.75760069848496641e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -3.02122265371212052e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -3.02122265371212052e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -3.02122265371212052e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -8.93545689656723653e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -8.93545689656723653e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -8.93545689656723653e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -6.65368586204731741e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 -6.65368586204731741e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -6.65368586204731741e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -9.74334086064275539e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -9.74334086064275539e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -9.74334086064275539e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -8.90687448928779645e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 -8.90687448928779645e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 -8.90687448928779645e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -5.23883262957193210e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -5.23883262957193210e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.23883262957193210e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -7.14288539468739714e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -7.14288539468739714e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -7.14288539468739714e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.24263400313443270e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.24263400313443270e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.24263400313443270e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 -5.33683186562928427e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -5.33683186562928427e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -5.33683186562928427e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -2.09487953017886099e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.09487953017886099e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.09487953017886099e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -4.89091112474133467e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -4.89091112474133467e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 -4.89091112474133467e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -2.46498335547314412e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -2.46498335547314412e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -2.46498335547314412e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -3.71924403739652121e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -3.71924403739652121e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -3.71924403739652121e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 -7.18057263871904183e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -7.18057263871904183e+01,
              6.42553218253216837e+04 8.10436695143234538e+04 -7.18057263871904183e+01,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.67650589496035138e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.67650589496035138e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.67650589496035138e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.70158064366086705e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.70158064366086705e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.70158064366086705e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -3.47396499770066100e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -3.47396499770066100e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -3.47396499770066100e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -2.70985412791819385e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -2.70985412791819385e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -2.70985412791819385e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64685574738124018e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -1.64685574738124018e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.64685574738124018e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64707789964546123e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.64707789964546123e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.64707789964546123e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.59221289602839391e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.59221289602839391e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.59221289602839391e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 -1.09092154205322913e+03,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.09092154205322913e+03,
              7.03849625406481791e+04 6.66301809041961824e+04 -1.09092154205322913e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.13968825323506599e+03,
              7.44662391718107247e+04 6.23026876644261938e+04 -1.13968825323506599e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.13968825323506599e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -9.63134049440409399e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -9.63134049440409399e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 -9.63134049440409399e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 -1.08827626529792587e+03,
              7.58877203068462841e+04 6.68810781485605257e+04 -1.08827626529792587e+03,
              7.29924592704223760e+04 6.46772673150131668e+04 -1.08827626529792587e+03,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.83380888366875183e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.83380888366875183e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.83380888366875183e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.43347969579428337e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 -1.43347969579428337e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.43347969579428337e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.73702819801288427e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.73702819801288427e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.73702819801288427e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.81361989785026225e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 -1.81361989785026225e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.81361989785026225e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.70525876104331587e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.70525876104331587e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.70525876104331587e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.36237517617569893e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.36237517617569893e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.36237517617569893e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 -2.10191807089762676e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -2.10191807089762676e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -2.10191807089762676e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 -5.21026485665665575e+01,
              6.70165000000000000e+04 8.77741000000000058e+04 -5.21026485665665575e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -5.21026485665665575e+01,
              9.10415000000000000e+04 7.39459750000000058e+04 -1.72919144001822701e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.72919144001822701e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.72919144001822701e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.49578726174376612e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.49578726174376612e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.49578726174376612e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 -9.74875449346252339e+01,
              6.67146997723299282e+04 8.12790927655865235e+04 -9.74875449346252339e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -9.74875449346252339e+01,
              8.15333633687027614e+04 8.45600519562402333e+04 -1.99802916121509497e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.99802916121509497e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.99802916121509497e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.94983752695736626e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.94983752695736626e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.94983752695736626e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.67534392005801806e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.67534392005801806e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.67534392005801806e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.90673455019445782e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.90673455019445782e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.90673455019445782e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.96298797205456935e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 -1.96298797205456935e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -1.96298797205456935e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.24148102348222153e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.24148102348222153e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 -2.24148102348222153e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.07139749094150034e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -2.07139749094150034e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.07139749094150034e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.37082145182504860e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.37082145182504860e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.37082145182504860e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.12876773968545336e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.12876773968545336e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 -2.12876773968545336e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.17197958127931230e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -2.17197958127931230e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.17197958127931230e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.89534563885402662e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.89534563885402662e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.89534563885402662e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -2.16607334066419725e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.16607334066419725e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -2.16607334066419725e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.48583004345229654e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.48583004345229654e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 -2.48583004345229654e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 -2.12094068752339183e+02,
              9.10415000000000000e+04 8.77741000000000058e+04 -2.12094068752339183e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.12094068752339183e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 -1.77491267537893435e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.77491267537893435e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.77491267537893435e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 -1.86079568644681444e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 -1.86079568644681444e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.86079568644681444e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.79354997941707495e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.79354997941707495e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.79354997941707495e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 -1.99001741655346279e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.99001741655346279e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -1.99001741655346279e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.29313914072681882e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.29313914072681882e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 -2.29313914072681882e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.06055616287748705e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 -2.06055616287748705e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.06055616287748705e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 -2.38015307998809732e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 -2.38015307998809732e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 -2.38015307998809732e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.64375388660796972e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.64375388660796972e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.64375388660796972e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -2.47682112201220718e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -2.47682112201220718e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -2.47682112201220718e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.59001262064154957e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.59001262064154957e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 -1.59001262064154957e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.73055949993051001e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.73055949993051001e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.73055949993051001e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -3.18721214510841719e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -3.18721214510841719e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -3.18721214510841719e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -1.59100694659225127e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 -1.59100694659225127e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.59100694659225127e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -4.24124603248016456e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -4.24124603248016456e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -4.24124603248016456e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -5.00494736438102677e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -5.00494736438102677e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.00494736438102677e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 -4.22486325219090531e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -4.22486325219090531e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -4.22486325219090531e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -6.03546477455562581e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 -6.03546477455562581e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -6.03546477455562581e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 -7.25434670792037878e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -7.25434670792037878e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 -7.25434670792037878e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 -5.76452916831043808e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -5.76452916831043808e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 -5.76452916831043808e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -3.88888064983857248e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -3.88888064983857248e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -3.88888064983857248e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 -3.28345775332834819e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 -3.28345775332834819e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -3.28345775332834819e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -2.27833917527543747e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -2.27833917527543747e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -2.27833917527543747e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.85669574042907101e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -1.85669574042907101e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.85669574042907101e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -2.18576757566417996e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.18576757566417996e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 -2.18576757566417996e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 -2.90337333546041748e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 -2.90337333546041748e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -2.90337333546041748e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 -2.15101273313695884e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 -2.15101273313695884e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 -2.15101273313695884e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 -1.59650943613394901e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.59650943613394901e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 -1.59650943613394901e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 -1.29872247738333414e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 -1.29872247738333414e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 -1.29872247738333414e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -4.13077205046171628e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.13077205046171628e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -4.13077205046171628e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 -3.04390926750512222e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 -3.04390926750512222e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 -3.04390926750512222e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -4.76619608548695396e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -4.76619608548695396e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 -4.76619608548695396e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 -3.09278417708754148e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 -3.09278417708754148e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 -3.09278417708754148e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.76454782373826362e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.76454782373826362e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 1.76454782373826362e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 2.10580062674775036e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.10580062674775036e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 2.10580062674775036e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 1.84671006639418579e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 1.84671006639418579e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 1.84671006639418579e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 2.50723657803919224e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 2.50723657803919224e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 2.50723657803919224e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 6.61304871851742888e+01,
              4.86229215617078662e+04 8.12219413673433592e+04 6.61304871851742888e+01,
              4.53027798571047970e+04 7.90659750000000058e+04 6.61304871851742888e+01,
              4.81084225482705006e+04 7.83562980960558052e+04 9.62069689948187090e-01,
              4.86229215617078662e+04 8.12219413673433592e+04 9.62069689948187090e-01,
              5.06225252789799124e+04 7.93839231851334625e+04 9.62069689948187090e-01,
              4.81084225482705006e+04 7.83562980960558052e+04 -6.42586934370094980e+01,
              5.06225252789799124e+04 7.93839231851334625e+04 -6.42586934370094980e+01,
              5.16410222818254188e+04 7.60320974242347147e+04 -6.42586934370094980e+01,
              5.31712790675824799e+04 7.84455309487277991e+04 9.11133436383096651e+00,
              5.06225252789799124e+04 7.93839231851334625e+04 9.11133436383096651e+00,
              5.12372078190610482e+04 8.25033967734024045e+04 9.11133436383096651e+00,
              4.68635539404924566e+04 8.35416926830068551e+04 1.30993219137182791e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.30993219137182791e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 1.30993219137182791e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.63413535120320944e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 1.63413535120320944e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.63413535120320944e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 5.37909311605495546e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 5.37909311605495546e+01,
              5.50589499526593063e+04 8.29185620004188240e+04 5.37909311605495546e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 1.28711377245984437e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 1.28711377245984437e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.28711377245984437e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 1.10348396638688115e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 1.10348396638688115e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 1.10348396638688115e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 7.66631560291530434e+01,
              5.35024375000000000e+04 8.48658649172350706e+04 7.66631560291530434e+01,
              5.72171465710359116e+04 8.41663228034385247e+04 7.66631560291530434e+01,
              5.35024375000000000e+04 8.48658649172350706e+04 9.20747902947402963e+01,
              5.50589499526593063e+04 8.29185620004188240e+04 9.20747902947402963e+01,
              5.12372078190610482e+04 8.25033967734024045e+04 9.20747902947402963e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -2.89221040748758611e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 -2.89221040748758611e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -2.89221040748758611e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 6.98580805202344521e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 6.98580805202344521e+01,
              5.80071250000000000e+04 8.77741000000000058e+04 6.98580805202344521e+01,
              6.05091918460729430e+04 8.10563761611414229e+04 -9.20080555750582789e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -9.20080555750582789e+01,
              6.26362041036404407e+04 7.91775447069208167e+04 -9.20080555750582789e+01,
              6.48307093999745339e+04 8.34736021066768299e+04 -3.62588355127309754e+01,
              6.24249165588892865e+04 8.27030611359975883e+04 -3.62588355127309754e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 -3.62588355127309754e+01,
              6.83044480184609274e+04 7.73047289581401710e+04 -5.54214923735086813e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -5.54214923735086813e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 -5.54214923735086813e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 -5.20247738385857872e+00,
              6.70165000000000000e+04 8.77741000000000058e+04 -5.20247738385857872e+00,
              6.48307093999745339e+04 8.34736021066768299e+04 -5.20247738385857872e+00,
              5.72171465710359116e+04 8.41663228034385247e+04 6.20523060009657144e+01,
              5.80071250000000000e+04 8.77741000000000058e+04 6.20523060009657144e+01,
              6.05799435922970297e+04 8.44286472202762234e+04 6.20523060009657144e+01,
              7.12992873738839990e+04 7.62112637298534974e+04 -4.86594235323843066e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -4.86594235323843066e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -4.86594235323843066e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 -4.49708211538523017e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 -4.49708211538523017e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -4.49708211538523017e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.33514191698142383e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 -1.33514191698142383e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.33514191698142383e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 -1.63140738778870656e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -1.63140738778870656e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.63140738778870656e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 -2.63184938125339556e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -2.63184938125339556e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -2.63184938125339556e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 -1.88173480503788795e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.88173480503788795e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 -1.88173480503788795e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 -6.53407412440755593e+01,
              7.30227500000000000e+04 8.77741000000000058e+04 -6.53407412440755593e+01,
              7.08349265632997267e+04 8.51163379562475893e+04 -6.53407412440755593e+01,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.22789464294145034e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.22789464294145034e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 -1.22789464294145034e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.52236836794941155e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.52236836794941155e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 -1.52236836794941155e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 -3.88777631104503243e+01,
              7.00196250000000000e+04 8.77741000000000058e+04 -3.88777631104503243e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -3.88777631104503243e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -1.21603323424540662e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 -1.21603323424540662e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -1.21603323424540662e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 -7.56125861026865209e+01,
              7.08349265632997267e+04 8.51163379562475893e+04 -7.56125861026865209e+01,
              6.79413227024429361e+04 8.38983841122261802e+04 -7.56125861026865209e+01,
              6.54314874736397032e+04 7.86870555035567959e+04 -2.37847361855229082e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -2.37847361855229082e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 -2.37847361855229082e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 -2.20058198183438520e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -2.20058198183438520e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 -2.20058198183438520e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 -1.69140131578751664e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.69140131578751664e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 -1.69140131578751664e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 -1.08032921315032596e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 -1.08032921315032596e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 -1.08032921315032596e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 -1.64726401463330262e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -1.64726401463330262e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 -1.64726401463330262e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 -2.16184772258452085e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 -2.16184772258452085e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 -2.16184772258452085e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 -8.24479057959157302e+00,
              6.40133750000000000e+04 8.77741000000000058e+04 -8.24479057959157302e+00,
              6.48307093999745339e+04 8.34736021066768299e+04 -8.24479057959157302e+00,
              9.10415000000000000e+04 7.94772250000000058e+04 -1.93503438901436965e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 -1.93503438901436965e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 -1.93503438901436965e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 4.16084212001112235e+01,
              6.30213364991572962e+04 8.53576388653714821e+04 4.16084212001112235e+01,
              6.10102500000000000e+04 8.77741000000000058e+04 4.16084212001112235e+01,
              9.10415000000000000e+04 7.39459750000000058e+04 -1.59729839397002792e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 -1.59729839397002792e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 -1.59729839397002792e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.60851239010682832e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.60851239010682832e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.60851239010682832e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.86424762597990252e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.86424762597990252e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.86424762597990252e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.38339803512773699e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.38339803512773699e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.38339803512773699e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.53821278078684600e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.53821278078684600e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.53821278078684600e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.37065420027865571e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.37065420027865571e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.37065420027865571e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 3.01072532933333207e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 3.01072532933333207e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 3.01072532933333207e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.30271435400001394e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.30271435400001394e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.30271435400001394e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.42104248995329328e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.42104248995329328e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.42104248995329328e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.80972448400000133e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.80972448400000133e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.80972448400000133e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.64816765239895744e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.64816765239895744e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.64816765239895744e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.48665799293333635e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.48665799293333635e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.48665799293333635e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 3.01214793746664554e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.01214793746664554e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.01214793746664554e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.04472330826666052e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 3.04472330826666052e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.04472330826666052e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.42689800959999900e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.42689800959999900e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.42689800959999900e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.79419687606687546e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.79419687606687546e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.79419687606687546e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.36422922066666501e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 3.36422922066666501e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.36422922066666501e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.52804657242687767e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.52804657242687767e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.52804657242687767e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.81236800346687119e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.81236800346687119e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.81236800346687119e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.38125089807442976e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.38125089807442976e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.38125089807442976e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 3.15789693574727949e+02,
              5.73275000000000000e+04 4.55720999999999985e+04 3.15789693574727949e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.15789693574727949e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.99695186559662716e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.99695186559662716e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.99695186559662716e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 4.12452616076809022e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.12452616076809022e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.12452616076809022e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.75483195064881215e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.75483195064881215e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.75483195064881215e+02,
              5.79743590006702070e+04 4.82669590006702056e+04 3.50324682742912444e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.50324682742912444e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.50324682742912444e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.37188970902710139e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.37188970902710139e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.37188970902710139e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 2.87091911903388620e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 2.87091911903388620e+02,
              5.93755000000000000e+04 4.35240999999999985e+04 2.87091911903388620e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.25775478083738562e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.25775478083738562e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 3.25775478083738562e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.44515912197648049e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.44515912197648049e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.44515912197648049e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 3.23891715573332760e+02,
              5.52795000000000000e+04 4.76200999999999985e+04 3.23891715573332760e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 3.23891715573332760e+02,
              4.29915000000000000e+04 4.35240999999999985e+04 2.39900377906666506e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.39900377906666506e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.39900377906666506e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.93002533826666252e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.93002533826666252e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.93002533826666252e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 3.04498263360000465e+02,
              5.52795000000000000e+04 4.35240999999999985e+04 3.04498263360000465e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 3.04498263360000465e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.72911137333330146e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 2.72911137333330146e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.72911137333330146e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.67424170767326927e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.67424170767326927e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.67424170767326927e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.95952213136610567e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.95952213136610567e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.95952213136610567e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 2.99089457840000421e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.99089457840000421e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.99089457840000421e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.92273849653333400e+02,
              4.96475000000000000e+04 4.96680999999999985e+04 2.92273849653333400e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.92273849653333400e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 3.22171328516908375e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 3.22171328516908375e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.22171328516908375e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.71139293489142574e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.71139293489142574e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.71139293489142574e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.58246477598822821e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.58246477598822821e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.58246477598822821e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 3.09815295160011829e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 3.09815295160011829e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 3.09815295160011829e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.56516419223056687e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.56516419223056687e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.56516419223056687e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.93035144333342316e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.93035144333342316e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.93035144333342316e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 2.96095852399949365e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 2.96095852399949365e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.96095852399949365e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 2.99217278512590553e+02,
              5.32315000000000000e+04 4.96680999999999985e+04 2.99217278512590553e+02,
              5.11835000000000000e+04 5.17160999999999985e+04 2.99217278512590553e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.60302878327358826e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.60302878327358826e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.60302878327358826e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.67686213449206662e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.67686213449206662e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.67686213449206662e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.56782741693513628e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56782741693513628e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.56782741693513628e+02,
              5.32096991355887149e+04 5.39706557443024576e+04 3.39752170091121400e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.39752170091121400e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.39752170091121400e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 3.20272171353086776e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.20272171353086776e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 3.20272171353086776e+02,
              5.01932147501675499e+04 5.42695311259215305e+04 2.85891329724487605e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.85891329724487605e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.85891329724487605e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.31273600041525526e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.31273600041525526e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.31273600041525526e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.84671605651273126e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.84671605651273126e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.84671605651273126e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.94418375146688390e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.94418375146688390e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 3.94418375146688390e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.58117476160887065e+02,
              5.58018045982720359e+04 5.23991934280011774e+04 3.58117476160887065e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.58117476160887065e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.81677188701055684e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 3.81677188701055684e+02,
              5.57878280170309808e+04 5.52996915505125726e+04 3.81677188701055684e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.69993274333706267e+02,
              5.99340390256030078e+04 5.02266390256030063e+04 3.69993274333706267e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.69993274333706267e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.42216217943509776e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.42216217943509776e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.42216217943509776e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.15216354952263259e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.15216354952263259e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.15216354952263259e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.62615053752732251e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.62615053752732251e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.62615053752732251e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.88249173802736266e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.88249173802736266e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.88249173802736266e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.10728794112533592e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.10728794112533592e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.10728794112533592e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.25323757806361755e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.25323757806361755e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.25323757806361755e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.42068508828264726e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.42068508828264726e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.42068508828264726e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 2.94237282913417459e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.94237282913417459e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.94237282913417459e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 3.10881293892446479e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 3.10881293892446479e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.10881293892446479e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.17295833357514027e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.17295833357514027e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.17295833357514027e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.50151072677203956e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.50151072677203956e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.50151072677203956e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 2.69856809356281701e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 2.69856809356281701e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 2.69856809356281701e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.12835913814865819e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 4.12835913814865819e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.12835913814865819e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.49706144209265119e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.49706144209265119e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.49706144209265119e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.77377020649952783e+02,
              5.38918848789301701e+04 5.81450728434154516e+04 3.77377020649952783e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.77377020649952783e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.45895824951189184e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.45895824951189184e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.45895824951189184e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.14798461904966757e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.14798461904966757e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.14798461904966757e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.30157930480486129e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.30157930480486129e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.30157930480486129e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.71751914111556630e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.71751914111556630e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.71751914111556630e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 2.89724257722336631e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.89724257722336631e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.89724257722336631e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 2.60609990458346601e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 2.60609990458346601e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 2.60609990458346601e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.14547250201866632e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 3.14547250201866632e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 3.14547250201866632e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.85774259936580052e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.85774259936580052e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.85774259936580052e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.74365168226166190e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 2.74365168226166190e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.74365168226166190e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.94650626326653139e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.94650626326653139e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.94650626326653139e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.84746145169773968e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.84746145169773968e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.84746145169773968e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.73962732928351556e+02,
              5.34102165518424226e+04 6.26476363602993661e+04 3.73962732928351556e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.73962732928351556e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.94674581485440115e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.94674581485440115e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.94674581485440115e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.17397202494405406e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.17397202494405406e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.17397202494405406e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.80603481223435949e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 2.80603481223435949e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.80603481223435949e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.68675825501134568e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.68675825501134568e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.68675825501134568e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.72079592493876589e+02,
              5.21265463531419955e+04 6.53542890887040121e+04 3.72079592493876589e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.72079592493876589e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.39361319640573015e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 3.39361319640573015e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.39361319640573015e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 3.04258677841460326e+02,
              6.01282800268421852e+04 4.63248800268421837e+04 3.04258677841460326e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 3.04258677841460326e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.50037881957915943e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.50037881957915943e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.50037881957915943e+02,
              6.33337500000000000e+04 4.35240999999999985e+04 2.82015143450889468e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.82015143450889468e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.82015143450889468e+02,
              6.40928094395280787e+04 4.62202124450514166e+04 2.99025067602795502e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 2.99025067602795502e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 2.99025067602795502e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 2.29249399930201378e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.29249399930201378e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.29249399930201378e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 3.10409827063563171e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.10409827063563171e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.10409827063563171e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.55931310132598867e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.55931310132598867e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.55931310132598867e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.52370227712429141e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 3.52370227712429141e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 3.52370227712429141e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 3.97184272124841527e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.97184272124841527e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 3.97184272124841527e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.98798291804651967e+02,
              6.72920000000000000e+04 4.35240999999999985e+04 2.98798291804651967e+02,
              6.69673509977274662e+04 4.63849996890717157e+04 2.98798291804651967e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.71755772535116989e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.71755772535116989e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.71755772535116989e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 2.44085432042270924e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.44085432042270924e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.44085432042270924e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.75747007008806008e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 3.75747007008806008e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.75747007008806008e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.90792217174441532e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.90792217174441532e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.90792217174441532e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.73137266482337225e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.73137266482337225e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.73137266482337225e+02,
              6.23213089173411572e+04 4.83897698766464528e+04 3.42679820963636075e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.42679820963636075e+02,
              6.53128750000000000e+04 4.87414489625658171e+04 3.42679820963636075e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.80827675853276958e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.80827675853276958e+02,
              6.31639635133035335e+04 5.19546492002441300e+04 3.80827675853276958e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.84784407506669424e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.84784407506669424e+02,
              5.11835000000000000e+04 4.76200999999999985e+04 2.84784407506669424e+02,
              4.91355000000000000e+04 4.35240999999999985e+04 2.95682962533268835e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.95682962533268835e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.95682962533268835e+02,
              5.11835000000000000e+04 4.35240999999999985e+04 2.84004552586671480e+02,
              5.01595000000000000e+04 4.55720999999999985e+04 2.84004552586671480e+02,
              5.32315000000000000e+04 4.55720999999999985e+04 2.84004552586671480e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.62691909786666770e+02,
              4.83675000000000000e+04 4.71080999999999985e+04 2.62691909786666770e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.62691909786666770e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.43363770346666058e+02,
              4.70875000000000000e+04 4.35240999999999985e+04 2.43363770346666058e+02,
              4.29915000000000000e+04 4.35240999999999985e+04 2.43363770346666058e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.60556289746657512e+02,
              4.50395000000000000e+04 4.55720999999999985e+04 2.60556289746657512e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.60556289746657512e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56346981960156882e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.56346981960156882e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.56346981960156882e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.57533931199919664e+02,
              4.29915000000000000e+04 4.76200999999999985e+04 2.57533931199919664e+02,
              4.29915000000000000e+04 4.96680999999999985e+04 2.57533931199919664e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.84307848000000604e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.84307848000000604e+02,
              4.50395000000000000e+04 4.86440999999999985e+04 2.84307848000000604e+02,
              4.70875000000000000e+04 4.96680999999999985e+04 2.82353184453333085e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.82353184453333085e+02,
              4.83675000000000000e+04 5.22280999999999985e+04 2.82353184453333085e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.75061178423562183e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.75061178423562183e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.75061178423562183e+02,
              4.51237802144240559e+04 5.33675903752420927e+04 2.56196663851987012e+02,
              4.47835000000000000e+04 5.06920999999999985e+04 2.56196663851987012e+02,
              4.29915000000000000e+04 5.17160999999999985e+04 2.56196663851987012e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 2.88612729414016826e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.88612729414016826e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 2.88612729414016826e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.58120999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 2.84934129770594325e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 3.11057767774157639e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.11057767774157639e+02,
              4.29915000000000000e+04 5.78600999999999985e+04 3.11057767774157639e+02,
              4.49269927799865036e+04 5.68360999999999985e+04 3.12146548223079890e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.12146548223079890e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.12146548223079890e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 3.13349647332102165e+02,
              4.47718890494047591e+04 5.88840999999999985e+04 3.13349647332102165e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.13349647332102165e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.54601429460738871e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.54601429460738871e+02,
              4.67023633582545226e+04 5.55543950373817788e+04 3.54601429460738871e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.56130010299696323e+02,
              4.70126396186275306e+04 5.78546198008630090e+04 3.56130010299696323e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.56130010299696323e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.72845834030930405e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.72845834030930405e+02,
              5.06831347564007519e+04 5.69640911602041306e+04 3.72845834030930405e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.46362350745074423e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.46362350745074423e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.46362350745074423e+02,
              5.13765108756730697e+04 6.00297837837659608e+04 3.54635109024970006e+02,
              4.91996151389331062e+04 5.88590738574148054e+04 3.54635109024970006e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.54635109024970006e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 2.57636520767776688e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 2.57636520767776688e+02,
              4.71810941175686894e+04 6.01695557399485406e+04 2.57636520767776688e+02,
              4.50045717343095457e+04 6.13484030154401989e+04 3.29112901579343770e+02,
              4.29915000000000000e+04 5.99080999999999985e+04 3.29112901579343770e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 3.29112901579343770e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.74145545311169997e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.74145545311169997e+02,
              5.02974764039219517e+04 6.29818891873231187e+04 3.74145545311169997e+02,
              4.29915000000000000e+04 6.33913500000000058e+04 4.01347699654367261e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 4.01347699654367261e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 4.01347699654367261e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.47612305370983279e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.47612305370983279e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.47612305370983279e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 3.02735191168284530e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 3.02735191168284530e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 3.02735191168284530e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.19573821522632983e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 2.19573821522632983e+02,
              7.12502500000000000e+04 4.35240999999999985e+04 2.19573821522632983e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 2.21557550311759144e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.21557550311759144e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 2.21557550311759144e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.64738962113641605e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.64738962113641605e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.64738962113641605e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.08932800417852491e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 4.08932800417852491e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.08932800417852491e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 3.74653436785547740e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.74653436785547740e+02,
              4.73618812665855803e+04 6.31020412621975265e+04 3.74653436785547740e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.90091600580079785e+02,
              4.53015577947763959e+04 6.51329750000000058e+04 2.90091600580079785e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.90091600580079785e+02,
              7.01469530831564916e+04 4.65087122237546937e+04 2.97877350055881834e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 2.97877350055881834e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.97877350055881834e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 3.57170447841505847e+02,
              7.19747267795647203e+04 4.84279263615037416e+04 3.57170447841505847e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.57170447841505847e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 1.67027218670398639e+02,
              7.52085000000000000e+04 4.35240999999999985e+04 1.67027218670398639e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.67027218670398639e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.14187700595865522e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 4.14187700595865522e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.14187700595865522e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 2.28215898656901288e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.28215898656901288e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.28215898656901288e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.70599290142608538e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.70599290142608538e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.70599290142608538e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.27327747538001887e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 2.27327747538001887e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.27327747538001887e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 2.68382598688630196e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.68382598688630196e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.68382598688630196e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 3.46992310042905501e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.46992310042905501e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.46992310042905501e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.20240218191962299e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.20240218191962299e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 2.20240218191962299e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.01253725962071883e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.01253725962071883e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.01253725962071883e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.38995069945036477e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.38995069945036477e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.38995069945036477e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 2.33603417560908440e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.33603417560908440e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 2.33603417560908440e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.21235846943740853e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.21235846943740853e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.21235846943740853e+02,
              7.64477573013165093e+04 5.07791925883061776e+04 1.96732807888802483e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 1.96732807888802483e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 1.96732807888802483e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 1.56535686826428559e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.56535686826428559e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.56535686826428559e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.46490770085840268e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 3.46490770085840268e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 3.46490770085840268e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 2.04564400272392618e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 2.04564400272392618e+02,
              7.91667500000000000e+04 4.35240999999999985e+04 2.04564400272392618e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.54288808948337731e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.54288808948337731e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.54288808948337731e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.83034484936951856e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.83034484936951856e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 3.83034484936951856e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 3.68446450426633305e+02,
              6.40137724255962821e+04 5.42287530279939601e+04 3.68446450426633305e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 3.68446450426633305e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.62039628592360714e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 3.62039628592360714e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 3.62039628592360714e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.12421464035965300e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.12421464035965300e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.12421464035965300e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.41630183621660365e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.41630183621660365e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.41630183621660365e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.14008166502428480e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.14008166502428480e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.14008166502428480e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.94706517965903572e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.94706517965903572e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 2.94706517965903572e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.23183724346857730e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.23183724346857730e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.23183724346857730e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 3.08688759776300117e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 3.08688759776300117e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.08688759776300117e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 2.97231265230909685e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.97231265230909685e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.97231265230909685e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.25264628543285539e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 4.25264628543285539e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 4.25264628543285539e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.45657552529896122e+02,
              6.71327284303460910e+04 5.92688412464384237e+04 3.45657552529896122e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.45657552529896122e+02,
              6.57771629885424773e+04 5.65667108624828033e+04 4.09302552698117950e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 4.09302552698117950e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 4.09302552698117950e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.97575086056033911e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.97575086056033911e+02,
              6.85836783884220058e+04 5.68498644275479382e+04 3.97575086056033911e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.92322675168015962e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.92322675168015962e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 3.92322675168015962e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.22170355752503383e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.22170355752503383e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.22170355752503383e+02,
              7.07983196008750092e+04 5.51028472750062865e+04 4.17461516979020757e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 4.17461516979020757e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 4.17461516979020757e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 3.95845903891251282e+02,
              7.32788534392044967e+04 5.61954990994093168e+04 3.95845903891251282e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.95845903891251282e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.63798019143778049e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.63798019143778049e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.63798019143778049e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 2.82847590755247836e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.82847590755247836e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.82847590755247836e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.81987559530426864e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 1.81987559530426864e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.81987559530426864e+02,
              7.46226167433981755e+04 4.83147082920636458e+04 2.14276138822615110e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 2.14276138822615110e+02,
              7.30069961100781657e+04 4.58697312406876445e+04 2.14276138822615110e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.68096949803360076e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.68096949803360076e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.68096949803360076e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.56533972463468018e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.56533972463468018e+02,
              7.71876250000000000e+04 4.57061911072983348e+04 1.56533972463468018e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.96060663517173225e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 1.96060663517173225e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 1.96060663517173225e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.51786340140452296e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.51786340140452296e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.51786340140452296e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.51866246476342809e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.51866246476342809e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.51866246476342809e+02,
              8.31250000000000000e+04 4.35240999999999985e+04 1.78484616514962653e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.78484616514962653e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.78484616514962653e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.47170564820540164e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.47170564820540164e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.47170564820540164e+02,
              8.11458750000000000e+04 4.55484482850344648e+04 1.57850981088630760e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.57850981088630760e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.57850981088630760e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.59800477175039589e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.59800477175039589e+02,
              8.20148118818800140e+04 4.81142794373619399e+04 1.59800477175039589e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.71301471942823980e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 1.71301471942823980e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.71301471942823980e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.24007554253118087e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.24007554253118087e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.24007554253118087e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.58110719025515607e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.58110719025515607e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.58110719025515607e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.23194145995102758e+02,
              7.53199660602289659e+04 5.33234331149488426e+04 2.23194145995102758e+02,
              7.59840736912662105e+04 5.60260307533853993e+04 2.23194145995102758e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.71810376635528371e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 1.71810376635528371e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 1.71810376635528371e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.72493083356662453e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.72493083356662453e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.72493083356662453e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 1.81365645349325405e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.81365645349325405e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.81365645349325405e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.48435747286891228e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.48435747286891228e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.48435747286891228e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 2.05163743202745422e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 2.05163743202745422e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.05163743202745422e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.32412641146311358e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.32412641146311358e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.32412641146311358e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.64624035408333953e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.64624035408333953e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.64624035408333953e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 2.99767905129055350e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 2.99767905129055350e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 2.99767905129055350e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.60537566931757453e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.60537566931757453e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.60537566931757453e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 2.02958870305464018e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.02958870305464018e+02,
              9.10415000000000000e+04 4.62897250000000058e+04 2.02958870305464018e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 2.13888753770535942e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 2.13888753770535942e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.13888753770535942e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 2.04280887075466325e+02,
              7.94176002086696244e+04 5.54124115490834884e+04 2.04280887075466325e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.04280887075466325e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.67405716984560257e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.67405716984560257e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.67405716984560257e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.76272424259244787e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.76272424259244787e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.76272424259244787e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.58765095365674711e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.58765095365674711e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.58765095365674711e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.90583387546054382e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.90583387546054382e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.90583387546054382e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.36033812767985751e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.36033812767985751e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.36033812767985751e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.62277212023801496e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.62277212023801496e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.62277212023801496e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.97590976382023882e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 2.97590976382023882e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.97590976382023882e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.61974534700814161e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.61974534700814161e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.61974534700814161e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 2.23134897286204136e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 2.23134897286204136e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.23134897286204136e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.75601298299687471e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.75601298299687471e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.75601298299687471e+02,
              7.72347105224941188e+04 6.19202976865721284e+04 2.94820417031516172e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.94820417031516172e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.94820417031516172e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 3.13641429389788243e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.13641429389788243e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 3.13641429389788243e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.21061213623305747e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.21061213623305747e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.21061213623305747e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.50091186565772546e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.50091186565772546e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.50091186565772546e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.81589136215283816e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.81589136215283816e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.81589136215283816e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 2.94437626388489548e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.94437626388489548e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.94437626388489548e+02,
              7.14201672595904383e+04 5.81683705646042945e+04 3.61453400387348040e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 3.61453400387348040e+02,
              7.48459019418140524e+04 5.95338409919303376e+04 3.61453400387348040e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.83170854255987535e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.83170854255987535e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.83170854255987535e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.57713335690160534e+02,
              8.04150160157289356e+04 6.28917311803685370e+04 2.57713335690160534e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.57713335690160534e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.14316152707005074e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.14316152707005074e+02,
              8.32611584966219380e+04 6.22254986442461886e+04 2.14316152707005074e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.81313607667933752e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.81313607667933752e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.81313607667933752e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.03303986540091728e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.03303986540091728e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.03303986540091728e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.21303825714762979e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.21303825714762979e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.21303825714762979e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.39629848251240588e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 2.39629848251240588e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.39629848251240588e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.49169730327234902e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.49169730327234902e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.49169730327234902e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.12714061380216378e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.12714061380216378e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.12714061380216378e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 2.59433794441872237e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.59433794441872237e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.59433794441872237e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 2.80569521794795946e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.80569521794795946e+02,
              8.37141806272772810e+04 6.50641186034181228e+04 2.80569521794795946e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 2.78388256498426131e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.78388256498426131e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.78388256498426131e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.63777398420230099e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 1.63777398420230099e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.63777398420230099e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.91558268595615544e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.91558268595615544e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.91558268595615544e+02,
              7.93341177749581984e+04 4.77238695091324189e+04 1.73270085162941200e+02,
              7.92916830326253985e+04 5.19267584197004835e+04 1.73270085162941200e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.73270085162941200e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.50315361445990703e+02,
              8.17986388046938227e+04 5.11290603217356038e+04 1.50315361445990703e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.50315361445990703e+02,
              8.40964180921727966e+04 4.98479046541305433e+04 1.52799713403038510e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.52799713403038510e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.52799713403038510e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.60956176797493129e+02,
              8.48847847435548028e+04 5.21754951398557314e+04 1.60956176797493129e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.60956176797493129e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 2.30538204661583023e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 2.30538204661583023e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 2.30538204661583023e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.26297800666694826e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 2.26297800666694826e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.26297800666694826e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.48232257164592625e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.48232257164592625e+02,
              8.71167825635175832e+04 5.32037875000000058e+04 1.48232257164592625e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 1.69430272919841315e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.69430272919841315e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.69430272919841315e+02,
              7.82768317177685967e+04 5.87085122944746108e+04 2.18413417831535526e+02,
              8.16333676824811409e+04 5.97976004010879988e+04 2.18413417831535526e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 2.18413417831535526e+02,
              8.26277156189610396e+04 5.67459104517576779e+04 1.96946807391077016e+02,
              8.50838967563513288e+04 5.46798239129230496e+04 1.96946807391077016e+02,
              8.28433143261273362e+04 5.35435587279313040e+04 1.96946807391077016e+02,
              5.62388542463878912e+04 6.39891597383460539e+04 3.61347557322047294e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 3.61347557322047294e+02,
              5.90380129951289491e+04 6.50561594902477736e+04 3.61347557322047294e+02,
              9.10415000000000000e+04 5.73522250000000058e+04 1.55526657151459688e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.55526657151459688e+02,
              8.75409839207612968e+04 5.59259473206989933e+04 1.55526657151459688e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.74900018377789991e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.74900018377789991e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.74900018377789991e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.59944919493042789e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.59944919493042789e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.59944919493042789e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.20886939450341174e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.20886939450341174e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.20886939450341174e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.82025089460646313e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.82025089460646313e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 2.82025089460646313e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.92951806425943403e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.92951806425943403e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.92951806425943403e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.45624597405682039e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 3.45624597405682039e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 3.45624597405682039e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.50928165617601223e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.50928165617601223e+02,
              4.29915000000000000e+04 6.68746000000000058e+04 2.50928165617601223e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.92295883562970232e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.92295883562970232e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.92295883562970232e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.81310480065791694e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.81310480065791694e+02,
              4.77612351969624724e+04 6.73294698169549374e+04 2.81310480065791694e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.44643006914531611e+02,
              4.52639917351218974e+04 6.82804008733644878e+04 2.44643006914531611e+02,
              4.29915000000000000e+04 7.03578500000000058e+04 2.44643006914531611e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 3.06588478703366832e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 3.06588478703366832e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.06588478703366832e+02,
              4.96543428316500358e+04 6.99299777544403187e+04 2.94417702978591251e+02,
              4.65171968174462527e+04 7.06404796439537458e+04 2.94417702978591251e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 2.94417702978591251e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.95351242598370447e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.95351242598370447e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 2.95351242598370447e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 2.90488537999087953e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 2.90488537999087953e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 2.90488537999087953e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.55264992143409700e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.55264992143409700e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.55264992143409700e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 2.86647491919760171e+02,
              4.29915000000000000e+04 7.38411000000000058e+04 2.86647491919760171e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 2.86647491919760171e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 3.74664507371530419e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.74664507371530419e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.74664507371530419e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.12557032926830686e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.12557032926830686e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.12557032926830686e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.57476540797931079e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.57476540797931079e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.57476540797931079e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.28035875997128414e+02,
              4.59793991185134146e+04 7.35902648950072034e+04 3.28035875997128414e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.28035875997128414e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.46294103952652506e+02,
              4.29915000000000000e+04 8.08076000000000058e+04 3.46294103952652506e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.46294103952652506e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.70856530131683485e+02,
              4.89529708960842981e+04 7.32051281972699799e+04 3.70856530131683485e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.70856530131683485e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.66752538576023312e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.66752538576023312e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.66752538576023312e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.09681824722852639e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.09681824722852639e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.09681824722852639e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.58100867407115288e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.58100867407115288e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.58100867407115288e+02,
              5.24463845310696197e+04 6.83327923556405876e+04 2.67370508177588590e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 2.67370508177588590e+02,
              5.48007139132386437e+04 6.65334251088876335e+04 2.67370508177588590e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.53848173178933166e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.53848173178933166e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.53848173178933166e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.36723188563009558e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.36723188563009558e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.36723188563009558e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.07840244926945559e+02,
              5.44530214554145859e+04 7.01384596514168952e+04 3.07840244926945559e+02,
              5.28823799324431966e+04 7.23339348472455604e+04 3.07840244926945559e+02,
              4.29915000000000000e+04 7.73243500000000058e+04 3.34146263191608227e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.34146263191608227e+02,
              4.77584339542222879e+04 7.56540985605456081e+04 3.34146263191608227e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.78153854776101298e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.78153854776101298e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.78153854776101298e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.60494428954937405e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.60494428954937405e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.60494428954937405e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 4.12006004404229770e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 4.12006004404229770e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 4.12006004404229770e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.77546602931659436e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 3.77546602931659436e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.77546602931659436e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.46456439704216734e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.46456439704216734e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.46456439704216734e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.70025598766917312e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.70025598766917312e+02,
              5.50378416800757113e+04 7.51148798025389406e+04 3.70025598766917312e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.58528761816180975e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.58528761816180975e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.58528761816180975e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.40791971572200453e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.40791971572200453e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.40791971572200453e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.22850514804470151e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.22850514804470151e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.22850514804470151e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.80777568730067287e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.80777568730067287e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 3.80777568730067287e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.53851861940349522e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.53851861940349522e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.53851861940349522e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 4.01772995168980401e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 4.01772995168980401e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 4.01772995168980401e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.16710089480078523e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.16710089480078523e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.16710089480078523e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.38531751062196065e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.38531751062196065e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.38531751062196065e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.38478168869445199e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.38478168869445199e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.38478168869445199e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.53919127074424352e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.53919127074424352e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.53919127074424352e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.83751697410930660e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.83751697410930660e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.83751697410930660e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.16913603478190737e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.16913603478190737e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.16913603478190737e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.63503223116375693e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.63503223116375693e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.63503223116375693e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.74584015328493081e+02,
              4.89977500000000000e+04 8.77741000000000058e+04 3.74584015328493081e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.74584015328493081e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.18894245715969760e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 3.18894245715969760e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.18894245715969760e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.06374627165195307e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.06374627165195307e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.06374627165195307e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 4.01394222627619229e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 4.01394222627619229e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 4.01394222627619229e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.53238276723399508e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.53238276723399508e+02,
              5.59416064564449553e+04 7.77443652293567866e+04 3.53238276723399508e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.44624932827741134e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.44624932827741134e+02,
              5.79559215132922254e+04 7.99987952834800672e+04 3.44624932827741134e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.15270042353393819e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.15270042353393819e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.15270042353393819e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.03310925737985713e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.03310925737985713e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.03310925737985713e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.28267102103591128e+02,
              5.99965969960417788e+04 7.81351133687859256e+04 3.28267102103591128e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.28267102103591128e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.19985633942971333e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.19985633942971333e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.19985633942971333e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.64460785562632680e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.64460785562632680e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.64460785562632680e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.87438246530448623e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.87438246530448623e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.87438246530448623e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.31788666190430206e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.31788666190430206e+02,
              5.92101729004537628e+04 5.38659000102998980e+04 4.31788666190430206e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.23266844878079496e+02,
              6.41120631072935794e+04 5.93898672147942198e+04 4.23266844878079496e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.23266844878079496e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 4.20063627812252719e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 4.20063627812252719e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.20063627812252719e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.34124782841208798e+02,
              6.13836756015105784e+04 5.90276584692566685e+04 4.34124782841208798e+02,
              6.28558784486122604e+04 5.63625301824463022e+04 4.34124782841208798e+02,
              5.87181748172013322e+04 6.20776562233111908e+04 3.80503781701772937e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 3.80503781701772937e+02,
              5.58895371226558564e+04 6.07361328452645030e+04 3.80503781701772937e+02,
              5.72915210400730939e+04 5.77800124966745352e+04 4.18256590228277901e+02,
              5.89703665896460734e+04 5.96083189957064678e+04 4.18256590228277901e+02,
              5.99704276363947793e+04 5.66658744515800427e+04 4.18256590228277901e+02,
              6.63835868025818199e+04 5.37017774826113891e+04 3.33848136922997014e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.33848136922997014e+02,
              6.80626200683170464e+04 4.89130271149768523e+04 3.33848136922997014e+02,
              7.03174474955796468e+04 5.04961538554442741e+04 3.78885171121294547e+02,
              6.90496608097013377e+04 5.29569371856979051e+04 3.78885171121294547e+02,
              7.34233317617715802e+04 5.12868056873617970e+04 3.78885171121294547e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.06248688845255515e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.06248688845255515e+02,
              6.18309149651218031e+04 6.17434033962874310e+04 3.06248688845255515e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 3.34939330262444798e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.34939330262444798e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.34939330262444798e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.07920487624422719e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.07920487624422719e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.07920487624422719e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.10965669273886078e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.10965669273886078e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.10965669273886078e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 2.99915629062743733e+02,
              6.29925708707179147e+04 6.48646240982857780e+04 2.99915629062743733e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.99915629062743733e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.45341413416447494e+02,
              6.40216112639875428e+04 6.78806692022762436e+04 3.45341413416447494e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.45341413416447494e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.67346797827043588e+02,
              6.60546313016843633e+04 6.35549489360035586e+04 3.67346797827043588e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 3.67346797827043588e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.07783380306745130e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 3.07783380306745130e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.07783380306745130e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.69333284491961308e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.69333284491961308e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.69333284491961308e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.45783547897049743e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.45783547897049743e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.45783547897049743e+02,
              7.23898047625812760e+04 6.06432606560521745e+04 2.89480866910967052e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 2.89480866910967052e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.89480866910967052e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.09366963219862669e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.09366963219862669e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.09366963219862669e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 3.06875486959386535e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.06875486959386535e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 3.06875486959386535e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.22097719984308071e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.22097719984308071e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.22097719984308071e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.98826242663196751e+02,
              6.70863062222572335e+04 6.66804235625501024e+04 2.98826242663196751e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 2.98826242663196751e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.42467265835497528e+02,
              6.87010588844871381e+04 6.86604716951115843e+04 3.42467265835497528e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.42467265835497528e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 2.88256172015735103e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.88256172015735103e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.88256172015735103e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 3.02663991738087930e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.02663991738087930e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.02663991738087930e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 3.02027217227337587e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.02027217227337587e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.02027217227337587e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.10397679020358055e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.10397679020358055e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.10397679020358055e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.54038888482357208e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.54038888482357208e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.54038888482357208e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.54291632079306737e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.54291632079306737e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.54291632079306737e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.53409689838539748e+02,
              6.03973817143073538e+04 7.27813878736088955e+04 3.53409689838539748e+02,
              5.80330892961683057e+04 7.59122397118236840e+04 3.53409689838539748e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 3.54021352212897000e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 3.54021352212897000e+02,
              5.77128434890498829e+04 7.28292648936219484e+04 3.54021352212897000e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 2.93571145283167539e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 2.93571145283167539e+02,
              5.67462547764482370e+04 6.87143434116099961e+04 2.93571145283167539e+02,
              6.08670829443490002e+04 6.74285593916286743e+04 2.76977324341659880e+02,
              5.90113814696985719e+04 7.03532544835219451e+04 2.76977324341659880e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 2.76977324341659880e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 2.77850194315246256e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 2.77850194315246256e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.77850194315246256e+02,
              6.93939625704202335e+04 6.41856909210061058e+04 3.19904755315466673e+02,
              7.14090038630176859e+04 6.28518056404307354e+04 3.19904755315466673e+02,
              6.97366489118925965e+04 6.08046356352454095e+04 3.19904755315466673e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.97552064652023489e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.97552064652023489e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.97552064652023489e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.14210496710745588e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.14210496710745588e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.14210496710745588e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.79002860571427050e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.79002860571427050e+02,
              6.10860092232105817e+04 7.53765429452559765e+04 3.79002860571427050e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.40046376935038893e+02,
              6.31266089138745810e+04 7.67609232787094952e+04 3.40046376935038893e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.40046376935038893e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.38973771869893426e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.38973771869893426e+02,
              6.28787850175427520e+04 7.17558756413043302e+04 3.38973771869893426e+02,
              6.64749768790189410e+04 6.99144954876966513e+04 3.37332986733491452e+02,
              6.54708755485221118e+04 7.23858468518231384e+04 3.37332986733491452e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 3.37332986733491452e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.98359070901811947e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 2.98359070901811947e+02,
              6.93061383463380189e+04 7.23374074222391209e+04 2.98359070901811947e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.04041615755429120e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.04041615755429120e+02,
              6.48261971976613204e+04 7.49743187699259433e+04 3.04041615755429120e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.88738293588960858e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.88738293588960858e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.88738293588960858e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.12053323238473126e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.12053323238473126e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.12053323238473126e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.22057073261710457e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.22057073261710457e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 3.22057073261710457e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.90031677473965090e+02,
              7.20817980211454706e+04 7.18208904883232899e+04 2.90031677473965090e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 2.90031677473965090e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.27477400063222831e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.27477400063222831e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.27477400063222831e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.74133354985446260e+02,
              7.80244469299197954e+04 6.52032686577075292e+04 2.74133354985446260e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.74133354985446260e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.92639075936007430e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.92639075936007430e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.92639075936007430e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 3.00390509596517120e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.00390509596517120e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 3.00390509596517120e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.79668264370791405e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.79668264370791405e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.79668264370791405e+02,
              7.65041009096159833e+04 7.15319400984732638e+04 2.89974496020584922e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.89974496020584922e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.89974496020584922e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.77298517223852741e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.77298517223852741e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.77298517223852741e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 2.96395164825749703e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.96395164825749703e+02,
              7.45080858675325871e+04 7.32645676197651628e+04 2.96395164825749703e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.01096088545182567e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.01096088545182567e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.01096088545182567e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.10361880361639237e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 3.10361880361639237e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.10361880361639237e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.14002631074139629e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.14002631074139629e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.14002631074139629e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.75850644492675883e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 3.75850644492675883e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.75850644492675883e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.66124960695397590e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.66124960695397590e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.66124960695397590e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.90066747720425610e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 2.90066747720425610e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.90066747720425610e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.45934640790459810e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.45934640790459810e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.45934640790459810e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 2.47528017879597968e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 2.47528017879597968e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.47528017879597968e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 2.34963953723073473e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 2.34963953723073473e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.34963953723073473e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.63415126572797021e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.63415126572797021e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.63415126572797021e+02,
              7.12115982768096728e+04 6.91350333107481420e+04 3.13938285609476964e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.13938285609476964e+02,
              7.03849625406481791e+04 6.66301809041961824e+04 3.13938285609476964e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 2.79987332871660840e+02,
              7.44662391718107247e+04 6.23026876644261938e+04 2.79987332871660840e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 2.79987332871660840e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 3.11519040065451179e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.11519040065451179e+02,
              7.49169121950905537e+04 6.94184344419290428e+04 3.11519040065451179e+02,
              7.32116639917368302e+04 6.73494454699124326e+04 3.00736960385631392e+02,
              7.58877203068462841e+04 6.68810781485605257e+04 3.00736960385631392e+02,
              7.29924592704223760e+04 6.46772673150131668e+04 3.00736960385631392e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.75421359033133172e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 3.75421359033133172e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 3.75421359033133172e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.19687530344245431e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.19687530344245431e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.19687530344245431e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 4.01339368324445672e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 4.01339368324445672e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.01339368324445672e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 2.31808129984582592e+02,
              7.76728074138022057e+04 8.11886436465059232e+04 2.31808129984582592e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.31808129984582592e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.74794906552405678e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.74794906552405678e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.74794906552405678e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.34553018690106228e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.34553018690106228e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.34553018690106228e+02,
              7.90290000000000000e+04 8.77741000000000058e+04 3.17215401430717691e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.17215401430717691e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.17215401430717691e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 2.69861696810639330e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 2.69861696810639330e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 2.69861696810639330e+02,
              9.10415000000000000e+04 7.39459750000000058e+04 2.58258311484634646e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.58258311484634646e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.58258311484634646e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 2.92631995904411156e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 2.92631995904411156e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.92631995904411156e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.51562046171014686e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.51562046171014686e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.51562046171014686e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 4.11408132358276362e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 4.11408132358276362e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.11408132358276362e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.80244387041294203e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 2.80244387041294203e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 2.80244387041294203e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 2.97208807816391811e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 2.97208807816391811e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 2.97208807816391811e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 4.35434408600185520e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.35434408600185520e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 4.35434408600185520e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 3.73180653359545090e+02,
              7.84706676351404894e+04 8.34651716723416757e+04 3.73180653359545090e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.73180653359545090e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.15746900157180448e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.15746900157180448e+02,
              8.20321250000000000e+04 8.77741000000000058e+04 3.15746900157180448e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.21665630363575758e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.21665630363575758e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.21665630363575758e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.44424854072983578e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.44424854072983578e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.44424854072983578e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.87280973913400089e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.87280973913400089e+02,
              8.15333633687027614e+04 8.45600519562402333e+04 3.87280973913400089e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.36312829733374258e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.36312829733374258e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.36312829733374258e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.55332821864543064e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.55332821864543064e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.55332821864543064e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.94790803588623987e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.94790803588623987e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.94790803588623987e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 3.29968414328849576e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.29968414328849576e+02,
              8.50352500000000000e+04 8.77741000000000058e+04 3.29968414328849576e+02,
              8.80383750000000000e+04 8.77741000000000058e+04 2.85139266499993312e+02,
              9.10415000000000000e+04 8.77741000000000058e+04 2.85139266499993312e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.85139266499993312e+02,
              8.04300321196100413e+04 8.15003905130040948e+04 4.12697723159727161e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 4.12697723159727161e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 4.12697723159727161e+02,
              8.25123150200416567e+04 8.02125675140809471e+04 3.94773165418951635e+02,
              8.43687430474992871e+04 8.18088335109796608e+04 3.94773165418951635e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.94773165418951635e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.48151689938417690e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.48151689938417690e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.48151689938417690e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 2.49038870672254575e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 2.49038870672254575e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.49038870672254575e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 3.31952555676926181e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 3.31952555676926181e+02,
              8.45291191653713904e+04 8.48107825666947756e+04 3.31952555676926181e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.75302163772032145e+02,
              9.10415000000000000e+04 8.22428500000000058e+04 2.75302163772032145e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.75302163772032145e+02,
              8.76326714876313490e+04 8.31397199017061794e+04 2.82856007671769703e+02,
              8.69217446162270790e+04 8.55369040797068301e+04 2.82856007671769703e+02,
              9.10415000000000000e+04 8.50084750000000058e+04 2.82856007671769703e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.53486969820152581e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.53486969820152581e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.53486969820152581e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 3.21167181547075984e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.21167181547075984e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.21167181547075984e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.69221110547391049e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.69221110547391049e+02,
              8.34735665862713504e+04 7.79608158447341266e+04 3.69221110547391049e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.78914789214872769e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 2.78914789214872769e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 2.78914789214872769e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.09638809274265327e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.09638809274265327e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.09638809274265327e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.42612299578042325e+02,
              8.59489368268707767e+04 7.67023149741617235e+04 3.42612299578042325e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 3.42612299578042325e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.78815653987857786e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.78815653987857786e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.78815653987857786e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.06276217406285184e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 3.06276217406285184e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.06276217406285184e+02,
              7.73295275279889174e+04 7.44988795999629219e+04 2.95968992420603740e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 2.95968992420603740e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.95968992420603740e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.71287694847396892e+02,
              7.99809449658524391e+04 7.19889933701697737e+04 2.71287694847396892e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.71287694847396892e+02,
              7.85990063731790578e+04 6.99202809302571986e+04 2.87857916746791034e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 2.87857916746791034e+02,
              8.08537882636642753e+04 6.57374252120232413e+04 2.87857916746791034e+02,
              8.09934466344464890e+04 6.94002545228480740e+04 3.18067689588692133e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 3.18067689588692133e+02,
              8.28552734554595227e+04 6.78073413055830024e+04 3.18067689588692133e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 3.38603327852362668e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 3.38603327852362668e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 3.38603327852362668e+02,
              8.53612476173654723e+04 6.99530749995551305e+04 2.80201266699869734e+02,
              8.23996999855846516e+04 7.14067995987419563e+04 2.80201266699869734e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.80201266699869734e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.59152093008803490e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.59152093008803490e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.59152093008803490e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.32364728964261644e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.32364728964261644e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.32364728964261644e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 1.91808628160234804e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 1.91808628160234804e+02,
              8.51428578513157408e+04 4.64748884749464851e+04 1.91808628160234804e+02,
              8.83265272898006515e+04 6.99874589836077066e+04 2.29225398280126456e+02,
              9.10415000000000000e+04 6.84147250000000058e+04 2.29225398280126456e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.29225398280126456e+02,
              8.70832500000000000e+04 4.35240999999999985e+04 2.43126513114781943e+02,
              8.81402271239105903e+04 4.62267197155591930e+04 2.43126513114781943e+02,
              9.10415000000000000e+04 4.35240999999999985e+04 2.43126513114781943e+02,
              8.75245662461348111e+04 4.90626173954792393e+04 2.17288047880662873e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 2.17288047880662873e+02,
              9.10415000000000000e+04 4.90553500000000058e+04 2.17288047880662873e+02,
              9.10415000000000000e+04 5.18209750000000058e+04 1.78563428928276664e+02,
              8.86389461992709548e+04 5.12630135188768036e+04 1.78563428928276664e+02,
              9.10415000000000000e+04 5.45866000000000058e+04 1.78563428928276664e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.98216017876445846e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 1.98216017876445846e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 1.98216017876445846e+02,
              8.84088127104290470e+04 6.46931020867703046e+04 2.75942907650029269e+02,
              8.72280425309247221e+04 6.72329359707007679e+04 2.75942907650029269e+02,
              9.10415000000000000e+04 6.56491000000000058e+04 2.75942907650029269e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.84870539682273005e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.84870539682273005e+02,
              8.59809803845267015e+04 6.32964616276429078e+04 1.84870539682273005e+02,
              8.85110530286112480e+04 6.17068578392829659e+04 1.68995330945012142e+02,
              9.10415000000000000e+04 6.01178500000000058e+04 1.68995330945012142e+02,
              8.63367490341809607e+04 5.84038333590103139e+04 1.68995330945012142e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.55232173213310034e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.55232173213310034e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.55232173213310034e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.73341597606747087e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.73341597606747087e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.73341597606747087e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.52303931640484393e+02,
              4.29915000000000000e+04 8.42908500000000058e+04 3.52303931640484393e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.52303931640484393e+02,
              4.59946250000000000e+04 8.77741000000000058e+04 3.94997313162964304e+02,
              4.52163197801465867e+04 8.54089102201420465e+04 3.94997313162964304e+02,
              4.29915000000000000e+04 8.77741000000000058e+04 3.94997313162964304e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.71072869480658142e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.71072869480658142e+02,
              4.53027798571047970e+04 7.90659750000000058e+04 3.71072869480658142e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.78800960910844083e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.78800960910844083e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.78800960910844083e+02,
              4.81084225482705006e+04 7.83562980960558052e+04 3.38720762161718653e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.38720762161718653e+02,
              5.16410222818254188e+04 7.60320974242347147e+04 3.38720762161718653e+02,
              5.31712790675824799e+04 7.84455309487277991e+04 3.72580071271984878e+02,
              5.06225252789799124e+04 7.93839231851334625e+04 3.72580071271984878e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.72580071271984878e+02,
              4.68635539404924566e+04 8.35416926830068551e+04 3.84484054854011504e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.84484054854011504e+02,
              4.86229215617078662e+04 8.12219413673433592e+04 3.84484054854011504e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.80770966451595541e+02,
              5.20008750000000000e+04 8.77741000000000058e+04 3.80770966451595541e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.80770966451595541e+02,
              5.52263343014311831e+04 8.04312537877296854e+04 3.91733093510155300e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.91733093510155300e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.91733093510155300e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.67014153366340224e+02,
              4.94894114570914171e+04 8.48718901503723755e+04 3.67014153366340224e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.67014153366340224e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.92009249626335929e+02,
              5.50040000000000000e+04 8.77741000000000058e+04 3.92009249626335929e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.92009249626335929e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 4.16870255670401434e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 4.16870255670401434e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 4.16870255670401434e+02,
              5.35024375000000000e+04 8.48658649172350706e+04 3.79769378483586081e+02,
              5.50589499526593063e+04 8.29185620004188240e+04 3.79769378483586081e+02,
              5.12372078190610482e+04 8.25033967734024045e+04 3.79769378483586081e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.49517397254801892e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.49517397254801892e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.49517397254801892e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.84788190547380282e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.84788190547380282e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.84788190547380282e+02,
              6.05091918460729430e+04 8.10563761611414229e+04 3.08989201444380114e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.08989201444380114e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.08989201444380114e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.14122762448499202e+02,
              6.24249165588892865e+04 8.27030611359975883e+04 3.14122762448499202e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.14122762448499202e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.27347141807742446e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.27347141807742446e+02,
              6.74122798954027094e+04 7.42438851185329113e+04 3.27347141807742446e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.56029043397759722e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 3.56029043397759722e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.56029043397759722e+02,
              5.72171465710359116e+04 8.41663228034385247e+04 3.59305246254036888e+02,
              5.80071250000000000e+04 8.77741000000000058e+04 3.59305246254036888e+02,
              6.05799435922970297e+04 8.44286472202762234e+04 3.59305246254036888e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.09850278204363974e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.09850278204363974e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.09850278204363974e+02,
              7.46922114616622421e+04 7.66855260157121375e+04 3.38119653504090138e+02,
              7.12992873738839990e+04 7.62112637298534974e+04 3.38119653504090138e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.38119653504090138e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.32607765147381031e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.32607765147381031e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.32607765147381031e+02,
              7.53940854225682997e+04 8.03970712418097100e+04 3.69506398724088456e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.69506398724088456e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 3.69506398724088456e+02,
              7.28067880055173446e+04 8.08246634885566746e+04 3.54839552467666124e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.54839552467666124e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.54839552467666124e+02,
              7.57134429293326684e+04 8.31534248058434896e+04 3.37995920586035595e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.37995920586035595e+02,
              7.60258750000000000e+04 8.77741000000000058e+04 3.37995920586035595e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.43315544831593229e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.43315544831593229e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.43315544831593229e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.30895893762968512e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.30895893762968512e+02,
              7.30227500000000000e+04 8.77741000000000058e+04 3.30895893762968512e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.46100204840795129e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.46100204840795129e+02,
              7.30743365147391451e+04 8.34690799953550013e+04 3.46100204840795129e+02,
              6.70165000000000000e+04 8.77741000000000058e+04 3.26300946008469737e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.26300946008469737e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.26300946008469737e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.27727875777292297e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.27727875777292297e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.27727875777292297e+02,
              7.00196250000000000e+04 8.77741000000000058e+04 3.38446393623290078e+02,
              7.08349265632997267e+04 8.51163379562475893e+04 3.38446393623290078e+02,
              6.79413227024429361e+04 8.38983841122261802e+04 3.38446393623290078e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.14160766709268501e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.14160766709268501e+02,
              6.83044480184609274e+04 7.73047289581401710e+04 3.14160766709268501e+02,
              7.07111037273659313e+04 7.92482592066335346e+04 3.11548512344594258e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.11548512344594258e+02,
              7.06675096922666125e+04 8.23413807833125902e+04 3.11548512344594258e+02,
              6.26362041036404407e+04 7.91775447069208167e+04 3.27962831626762522e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.27962831626762522e+02,
              6.54314874736397032e+04 7.86870555035567959e+04 3.27962831626762522e+02,
              6.67146997723299282e+04 8.12790927655865235e+04 3.12659525830042242e+02,
              6.42553218253216837e+04 8.10436695143234538e+04 3.12659525830042242e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.12659525830042242e+02,
              7.89588211680340755e+04 7.91477297803332185e+04 3.48683622917772084e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.48683622917772084e+02,
              7.72821019066069566e+04 7.71254372802602302e+04 3.48683622917772084e+02,
              8.04085807440241333e+04 7.44397999872239743e+04 3.41344550759914853e+02,
              8.08475853284304758e+04 7.71521713571936707e+04 3.41344550759914853e+02,
              8.34626975610945083e+04 7.48757595790681662e+04 3.41344550759914853e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.65004661808166929e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.65004661808166929e+02,
              6.48307093999745339e+04 8.34736021066768299e+04 3.65004661808166929e+02,
              9.10415000000000000e+04 7.94772250000000058e+04 3.17041735372502444e+02,
              9.10415000000000000e+04 7.67116000000000058e+04 3.17041735372502444e+02,
              8.73851588281717559e+04 7.90789825218544574e+04 3.17041735372502444e+02,
              6.40133750000000000e+04 8.77741000000000058e+04 3.40252381901020044e+02,
              6.30213364991572962e+04 8.53576388653714821e+04 3.40252381901020044e+02,
              6.10102500000000000e+04 8.77741000000000058e+04 3.40252381901020044e+02,
              9.10415000000000000e+04 7.39459750000000058e+04 2.77543628190308937e+02,
              9.10415000000000000e+04 7.11803500000000058e+04 2.77543628190308937e+02,
              8.67121743031944061e+04 7.40323429098861525e+04 2.77543628190308937e+02,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="1.02864671235645379e+05 2.22784197398011856e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            53 52 54 -1 
            54 52 55 -1 
            55 52 56 -1 
            56 52 57 -1 
            57 52 58 -1 
            58 52 59 -1 
            52 51 59 -1 
            33 32 34 -1 
            32 31 34 -1 
            31 30 34 -1 
            30 29 34 -1 
            29 28 34 -1 
            28 27 34 -1 
            34 27 35 -1 
            62 61 63 -1 
            63 61 64 -1 
            64 61 65 -1 
            65 61 66 -1 
            66 61 67 -1 
            67 61 68 -1 
            68 61 69 -1 
            61 60 69 -1 
            7 6 8 -1 
            6 5 8 -1 
            5 4 8 -1 
            4 3 8 -1 
            3 2 8 -1 
            2 1 8 -1 
            8 1 9 -1 
            84 83 85 -1 
            83 82 85 -1 
            82 81 85 -1 
            85 81 0 -1 
            70 69 71 -1 
            71 69 72 -1 
            72 69 73 -1 
            73 69 74 -1 
            74 69 75 -1 
            76 75 77 -1 
            77 75 78 -1 
            78 75 79 -1 
            79 75 80 -1 
            80 75 81 -1 
            27 26 35 -1 
            26 25 35 -1 
            35 25 36 -1 
            25 24 36 -1 
            36 24 37 -1 
            24 23 37 -1 
            37 23 38 -1 
            23 22 38 -1 
            38 22 39 -1 
            22 21 39 -1 
            39 21 40 -1 
            21 20 40 -1 
            40 20 41 -1 
            20 19 41 -1 
            41 19 42 -1 
            19 18 42 -1 
            59 51 60 -1 
            60 51 69 -1 
            51 50 69 -1 
            50 49 69 -1 
            49 48 69 -1 
            48 47 69 -1 
            47 46 69 -1 
            46 45 69 -1 
            45 44 69 -1 
            69 44 75 -1 
            1 0 9 -1 
            0 81 9 -1 
            44 43 75 -1 
            43 42 75 -1 
            42 18 75 -1 
            75 18 81 -1 
            18 17 81 -1 
            17 16 81 -1 
            16 15 81 -1 
            15 14 81 -1 
            14 13 81 -1 
            13 12 81 -1 
            12 11 81 -1 
            11 10 81 -1 
            81 10 9 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0004"
              point="
              -4.54747350886464119e-13 -1.70138382292277902e+03 0.00000000000000000e+00,
              -1.00673598989515085e+01 -1.74836483578455204e+03 0.00000000000000000e+00,
              -3.85915462793136612e+01 -1.78863427538035739e+03 0.00000000000000000e+00,
              -8.22167725081030767e+01 -1.81715846176071977e+03 0.00000000000000000e+00,
              -1.32553572002859710e+02 -1.82554792834317914e+03 0.00000000000000000e+00,
              -1.84568264814108716e+02 -1.81715846176071977e+03 0.00000000000000000e+00,
              -2.26515597726405758e+02 -1.78863427538035739e+03 0.00000000000000000e+00,
              -2.56717677423259829e+02 -1.74836483578455204e+03 0.00000000000000000e+00,
              -2.65107144005719420e+02 -1.70138382292277902e+03 0.00000000000000000e+00,
              -2.65107144005719420e+02 -5.08401674897043449e+02 0.00000000000000000e+00,
              -2.71818717271686637e+02 -4.64776448668254488e+02 0.00000000000000000e+00,
              -2.90275543753097736e+02 -4.19473329122973155e+02 0.00000000000000000e+00,
              -3.18799730133459889e+02 -3.77525996210675885e+02 0.00000000000000000e+00,
              -3.57391276412773550e+02 -3.38934449931362451e+02 0.00000000000000000e+00,
              -4.07728075907530410e+02 -3.07054476918016462e+02 0.00000000000000000e+00,
              -4.68132235301238552e+02 -2.81886077170637918e+02 0.00000000000000000e+00,
              -5.36925861277406057e+02 -2.66785037322211110e+02 0.00000000000000000e+00,
              -6.10753167203049316e+02 -2.60073464056243438e+02 0.00000000000000000e+00,
              -6.87936259761676183e+02 -2.66785037322211110e+02 0.00000000000000000e+00,
              -7.56729885737843915e+02 -2.81886077170637918e+02 0.00000000000000000e+00,
              -8.18811938448043975e+02 -3.07054476918016462e+02 0.00000000000000000e+00,
              -8.69148737942800608e+02 -3.38934449931362451e+02 0.00000000000000000e+00,
              -9.11096070855098105e+02 -3.77525996210675885e+02 0.00000000000000000e+00,
              -9.41298150551951949e+02 -4.19473329122973155e+02 0.00000000000000000e+00,
              -9.61432870349854738e+02 -4.64776448668254488e+02 0.00000000000000000e+00,
              -9.66466550299330493e+02 -5.08401674897043449e+02 0.00000000000000000e+00,
              -9.66466550299330493e+02 -1.70138382292277902e+03 0.00000000000000000e+00,
              -9.76533910198281774e+02 -1.75004272910104396e+03 0.00000000000000000e+00,
              -1.00505809657864415e+03 -1.79031216869684931e+03 0.00000000000000000e+00,
              -1.04868332280743311e+03 -1.81715846176071977e+03 0.00000000000000000e+00,
              -1.09902012230218998e+03 -1.82554792834317914e+03 0.00000000000000000e+00,
              -1.15103481511343853e+03 -1.81715846176071977e+03 0.00000000000000000e+00,
              -1.19298214802573602e+03 -1.79031216869684931e+03 0.00000000000000000e+00,
              -1.22318422772258987e+03 -1.75004272910104396e+03 0.00000000000000000e+00,
              -1.23157369430504946e+03 -1.70138382292277902e+03 0.00000000000000000e+00,
              -1.23157369430504946e+03 -5.08401674897043449e+02 0.00000000000000000e+00,
              -1.23828526757101713e+03 -4.64776448668254488e+02 0.00000000000000000e+00,
              -1.25841998736891969e+03 -4.19473329122973155e+02 0.00000000000000000e+00,
              -1.28862206706577376e+03 -3.77525996210675885e+02 0.00000000000000000e+00,
              -1.32889150666157934e+03 -3.38934449931362451e+02 0.00000000000000000e+00,
              -1.38090619947282789e+03 -3.07054476918016462e+02 0.00000000000000000e+00,
              -1.44131035886653603e+03 -2.81886077170637918e+02 0.00000000000000000e+00,
              -1.51178187815919546e+03 -2.66785037322211110e+02 0.00000000000000000e+00,
              -1.58728707740133086e+03 -2.60073464056243438e+02 0.00000000000000000e+00,
              -1.66279227664346581e+03 -2.66785037322211110e+02 0.00000000000000000e+00,
              -1.73158590261963354e+03 -2.81886077170637918e+02 0.00000000000000000e+00,
              -1.79199006201334169e+03 -3.07054476918016462e+02 0.00000000000000000e+00,
              -1.84064896819160640e+03 -3.38934449931362451e+02 0.00000000000000000e+00,
              -1.88091840778741198e+03 -3.77525996210675885e+02 0.00000000000000000e+00,
              -1.90944259416777413e+03 -4.19473329122973155e+02 0.00000000000000000e+00,
              -1.92789942064918478e+03 -4.64776448668254488e+02 0.00000000000000000e+00,
              -1.93293310059866053e+03 -5.08401674897043449e+02 0.00000000000000000e+00,
              -1.93293310059866053e+03 -1.70138382292277902e+03 0.00000000000000000e+00,
              -1.94300046049761204e+03 -1.75004272910104396e+03 0.00000000000000000e+00,
              -1.97152464687797419e+03 -1.79031216869684931e+03 0.00000000000000000e+00,
              -2.01514987310676338e+03 -1.81715846176071977e+03 0.00000000000000000e+00,
              -2.06380877928502832e+03 -1.82554792834317914e+03 0.00000000000000000e+00,
              -2.11750136541276879e+03 -1.81715846176071977e+03 0.00000000000000000e+00,
              -2.15944869832506629e+03 -1.78863427538035739e+03 0.00000000000000000e+00,
              -2.18965077802191990e+03 -1.74836483578455204e+03 0.00000000000000000e+00,
              -2.19804024460437950e+03 -1.70138382292277902e+03 0.00000000000000000e+00,
              -2.19804024460437950e+03 -1.25841998736892037e+02 0.00000000000000000e+00,
              -2.18965077802191990e+03 -7.88609858751190131e+01 0.00000000000000000e+00,
              -2.15944869832506629e+03 -3.69136529628217431e+01 0.00000000000000000e+00,
              -2.11750136541276879e+03 -1.00673598989515085e+01 0.00000000000000000e+00,
              -2.06548667260152024e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.02521723300571466e+03 -6.71157326596744497e+00 0.00000000000000000e+00,
              -1.98830358004289292e+03 -2.68462930638702346e+01 0.00000000000000000e+00,
              -1.95977939366253077e+03 -5.53704794442326147e+01 0.00000000000000000e+00,
              -1.93964467386462820e+03 -9.06062390905624397e+01 0.00000000000000000e+00,
              -1.77688902216491442e+03 -2.34905064308866258e+01 0.00000000000000000e+00,
              -1.59232075735080639e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.45305561208197923e+03 -1.34231465319351173e+01 0.00000000000000000e+00,
              -1.31882414676262783e+03 -5.20146928112485512e+01 0.00000000000000000e+00,
              -1.19969372129170347e+03 -1.12418852204956693e+02 0.00000000000000000e+00,
              -1.09902012230218998e+03 -1.91279838080075706e+02 0.00000000000000000e+00,
              -1.00002441662916817e+03 -1.10740958888464775e+02 0.00000000000000000e+00,
              -8.80893991158244035e+02 -5.03367994947568604e+01 0.00000000000000000e+00,
              -7.48340419155384552e+02 -1.34231465319351173e+01 0.00000000000000000e+00,
              -6.07397380570065479e+02 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.53031195452811289e+02 -1.67789331649189535e+01 0.00000000000000000e+00,
              -3.07054476918016462e+02 -6.37599460266919777e+01 0.00000000000000000e+00,
              -1.82890371497616798e+02 -1.39265145268827155e+02 0.00000000000000000e+00,
              -8.38946658245949948e+01 -2.43294530891324484e+02 0.00000000000000000e+00,
              -2.18126131143949351e+01 -3.65780742995232686e+02 0.00000000000000000e+00,
              -4.54747350886464119e-13 -5.00012208314584086e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="1.96641067522208832e+04 1.00910366235645371e+05 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            53 52 54 -1 
            54 52 55 -1 
            55 52 56 -1 
            56 52 57 -1 
            57 52 58 -1 
            58 52 59 -1 
            52 51 59 -1 
            33 32 34 -1 
            32 31 34 -1 
            31 30 34 -1 
            30 29 34 -1 
            29 28 34 -1 
            28 27 34 -1 
            34 27 35 -1 
            62 61 63 -1 
            63 61 64 -1 
            64 61 65 -1 
            65 61 66 -1 
            66 61 67 -1 
            67 61 68 -1 
            68 61 69 -1 
            61 60 69 -1 
            7 6 8 -1 
            6 5 8 -1 
            5 4 8 -1 
            4 3 8 -1 
            3 2 8 -1 
            2 1 8 -1 
            8 1 9 -1 
            84 83 85 -1 
            83 82 85 -1 
            82 81 85 -1 
            85 81 0 -1 
            70 69 71 -1 
            71 69 72 -1 
            72 69 73 -1 
            73 69 74 -1 
            74 69 75 -1 
            76 75 77 -1 
            77 75 78 -1 
            78 75 79 -1 
            79 75 80 -1 
            80 75 81 -1 
            27 26 35 -1 
            26 25 35 -1 
            35 25 36 -1 
            25 24 36 -1 
            36 24 37 -1 
            24 23 37 -1 
            37 23 38 -1 
            23 22 38 -1 
            38 22 39 -1 
            22 21 39 -1 
            39 21 40 -1 
            21 20 40 -1 
            40 20 41 -1 
            20 19 41 -1 
            41 19 42 -1 
            19 18 42 -1 
            59 51 60 -1 
            60 51 69 -1 
            51 50 69 -1 
            50 49 69 -1 
            49 48 69 -1 
            48 47 69 -1 
            47 46 69 -1 
            46 45 69 -1 
            45 44 69 -1 
            69 44 75 -1 
            1 0 9 -1 
            0 81 9 -1 
            44 43 75 -1 
            43 42 75 -1 
            42 18 75 -1 
            75 18 81 -1 
            18 17 81 -1 
            17 16 81 -1 
            16 15 81 -1 
            15 14 81 -1 
            14 13 81 -1 
            13 12 81 -1 
            12 11 81 -1 
            11 10 81 -1 
            81 10 9 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0005"
              point="
              -4.54747350886464119e-13 -7.88609858751189563e+02 0.00000000000000000e+00,
              -1.00673598989515085e+01 -8.35590871612962474e+02 0.00000000000000000e+00,
              -3.85915462793136612e+01 -8.75860311208767939e+02 0.00000000000000000e+00,
              -8.22167725081030767e+01 -9.04384497589130092e+02 0.00000000000000000e+00,
              -1.32553572002859710e+02 -9.12773964171589569e+02 0.00000000000000000e+00,
              -1.84568264814108716e+02 -9.04384497589130092e+02 0.00000000000000000e+00,
              -2.26515597726405758e+02 -8.75860311208767939e+02 0.00000000000000000e+00,
              -2.56717677423259829e+02 -8.35590871612962474e+02 0.00000000000000000e+00,
              -2.65107144005719420e+02 -7.88609858751189563e+02 0.00000000000000000e+00,
              -2.65107144005719420e+02 4.04372289274546119e+02 0.00000000000000000e+00,
              -2.71818717271686637e+02 4.47997515503335080e+02 0.00000000000000000e+00,
              -2.90275543753097736e+02 4.93300635048616414e+02 0.00000000000000000e+00,
              -3.18799730133459889e+02 5.35247967960913684e+02 0.00000000000000000e+00,
              -3.57391276412773550e+02 5.73839514240227118e+02 0.00000000000000000e+00,
              -4.07728075907530410e+02 6.05719487253573106e+02 0.00000000000000000e+00,
              -4.68132235301238552e+02 6.30887887000951650e+02 0.00000000000000000e+00,
              -5.36925861277406057e+02 6.45988926849378458e+02 0.00000000000000000e+00,
              -6.10753167203049316e+02 6.52700500115346131e+02 0.00000000000000000e+00,
              -6.87936259761676183e+02 6.45988926849378458e+02 0.00000000000000000e+00,
              -7.56729885737843915e+02 6.30887887000951650e+02 0.00000000000000000e+00,
              -8.18811938448043975e+02 6.05719487253573106e+02 0.00000000000000000e+00,
              -8.69148737942800608e+02 5.73839514240227118e+02 0.00000000000000000e+00,
              -9.11096070855098105e+02 5.35247967960913684e+02 0.00000000000000000e+00,
              -9.41298150551951949e+02 4.93300635048616414e+02 0.00000000000000000e+00,
              -9.61432870349854738e+02 4.47997515503335080e+02 0.00000000000000000e+00,
              -9.66466550299330493e+02 4.04372289274546119e+02 0.00000000000000000e+00,
              -9.66466550299330493e+02 -7.88609858751189563e+02 0.00000000000000000e+00,
              -9.76533910198281774e+02 -8.37268764929454392e+02 0.00000000000000000e+00,
              -1.00505809657864415e+03 -8.77538204525259857e+02 0.00000000000000000e+00,
              -1.04868332280743311e+03 -9.04384497589130092e+02 0.00000000000000000e+00,
              -1.09902012230218998e+03 -9.12773964171589569e+02 0.00000000000000000e+00,
              -1.15103481511343853e+03 -9.04384497589130092e+02 0.00000000000000000e+00,
              -1.19298214802573602e+03 -8.77538204525259857e+02 0.00000000000000000e+00,
              -1.22318422772258987e+03 -8.37268764929454392e+02 0.00000000000000000e+00,
              -1.23157369430504946e+03 -7.88609858751189563e+02 0.00000000000000000e+00,
              -1.23157369430504946e+03 4.04372289274546119e+02 0.00000000000000000e+00,
              -1.23828526757101713e+03 4.47997515503335080e+02 0.00000000000000000e+00,
              -1.25841998736891969e+03 4.93300635048616414e+02 0.00000000000000000e+00,
              -1.28862206706577376e+03 5.35247967960913684e+02 0.00000000000000000e+00,
              -1.32889150666157934e+03 5.73839514240227118e+02 0.00000000000000000e+00,
              -1.38090619947282789e+03 6.05719487253573106e+02 0.00000000000000000e+00,
              -1.44131035886653603e+03 6.30887887000951650e+02 0.00000000000000000e+00,
              -1.51178187815919546e+03 6.45988926849378458e+02 0.00000000000000000e+00,
              -1.58728707740133086e+03 6.52700500115346131e+02 0.00000000000000000e+00,
              -1.66279227664346581e+03 6.45988926849378458e+02 0.00000000000000000e+00,
              -1.73158590261963354e+03 6.30887887000951650e+02 0.00000000000000000e+00,
              -1.79199006201334169e+03 6.05719487253573106e+02 0.00000000000000000e+00,
              -1.84064896819160640e+03 5.73839514240227118e+02 0.00000000000000000e+00,
              -1.88091840778741198e+03 5.35247967960913684e+02 0.00000000000000000e+00,
              -1.90944259416777413e+03 4.93300635048616414e+02 0.00000000000000000e+00,
              -1.92789942064918478e+03 4.47997515503335080e+02 0.00000000000000000e+00,
              -1.93293310059866053e+03 4.04372289274546119e+02 0.00000000000000000e+00,
              -1.93293310059866053e+03 -7.88609858751189563e+02 0.00000000000000000e+00,
              -1.94300046049761204e+03 -8.37268764929454392e+02 0.00000000000000000e+00,
              -1.97152464687797419e+03 -8.77538204525259857e+02 0.00000000000000000e+00,
              -2.01514987310676338e+03 -9.04384497589130092e+02 0.00000000000000000e+00,
              -2.06380877928502832e+03 -9.12773964171589569e+02 0.00000000000000000e+00,
              -2.11750136541276879e+03 -9.04384497589130092e+02 0.00000000000000000e+00,
              -2.15944869832506629e+03 -8.75860311208767939e+02 0.00000000000000000e+00,
              -2.18965077802191990e+03 -8.35590871612962474e+02 0.00000000000000000e+00,
              -2.19804024460437950e+03 -7.88609858751189563e+02 0.00000000000000000e+00,
              -2.19804024460437950e+03 7.86931965434697531e+02 0.00000000000000000e+00,
              -2.18965077802191990e+03 8.33912978296470556e+02 0.00000000000000000e+00,
              -2.15944869832506629e+03 8.75860311208767826e+02 0.00000000000000000e+00,
              -2.11750136541276879e+03 9.02706604272638060e+02 0.00000000000000000e+00,
              -2.06548667260152024e+03 9.12773964171589569e+02 0.00000000000000000e+00,
              -2.02521723300571466e+03 9.06062390905622124e+02 0.00000000000000000e+00,
              -1.98830358004289292e+03 8.85927671107719334e+02 0.00000000000000000e+00,
              -1.95977939366253077e+03 8.57403484727356954e+02 0.00000000000000000e+00,
              -1.93964467386462820e+03 8.22167725081027129e+02 0.00000000000000000e+00,
              -1.77688902216491442e+03 8.89283457740702943e+02 0.00000000000000000e+00,
              -1.59232075735080639e+03 9.12773964171589569e+02 0.00000000000000000e+00,
              -1.45305561208197923e+03 8.99350817639654451e+02 0.00000000000000000e+00,
              -1.31882414676262783e+03 8.60759271360341017e+02 0.00000000000000000e+00,
              -1.19969372129170347e+03 8.00355111966632876e+02 0.00000000000000000e+00,
              -1.09902012230218998e+03 7.21494126091513863e+02 0.00000000000000000e+00,
              -1.00002441662916817e+03 8.02033005283124794e+02 0.00000000000000000e+00,
              -8.80893991158244035e+02 8.62437164676832708e+02 0.00000000000000000e+00,
              -7.48340419155384552e+02 8.99350817639654451e+02 0.00000000000000000e+00,
              -6.07397380570065479e+02 9.12773964171589569e+02 0.00000000000000000e+00,
              -4.53031195452811289e+02 8.95995031006670615e+02 0.00000000000000000e+00,
              -3.07054476918016462e+02 8.49014018144897591e+02 0.00000000000000000e+00,
              -1.82890371497616798e+02 7.73508818902762414e+02 0.00000000000000000e+00,
              -8.38946658245949948e+01 6.69479433280265084e+02 0.00000000000000000e+00,
              -2.18126131143949351e+01 5.46993221176356883e+02 0.00000000000000000e+00,
              -4.54747350886464119e-13 4.12761755857005483e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="3.12084512356453779e+04 2.64743512356453757e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0007"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              7.54276015625000000e+04 0.00000000000000000e+00 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="3.12084512356453779e+04 2.64743512356453757e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0008"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 7.83537031250000000e+04 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="3.99910512356453764e+04 2.51548022389700236e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 0 -1 
            18 17 19 -1 
            17 16 19 -1 
            19 16 0 -1 
            12 11 13 -1 
            13 11 14 -1 
            11 10 14 -1 
            14 10 15 -1 
            15 10 16 -1 
            16 10 0 -1 
            0 10 1 -1 
            10 9 1 -1 
            8 7 9 -1 
            9 7 1 -1 
            36 35 37 -1 
            35 34 37 -1 
            34 2 37 -1 
            37 2 3 -1 
            2 1 3 -1 
            1 7 3 -1 
            4 3 7 -1 
            4 7 5 -1 
            7 6 5 -1 
            20 0 21 -1 
            28 27 29 -1 
            27 26 29 -1 
            26 25 29 -1 
            25 24 29 -1 
            29 24 30 -1 
            22 21 23 -1 
            23 21 24 -1 
            24 21 30 -1 
            32 31 33 -1 
            33 31 34 -1 
            34 31 2 -1 
            31 30 2 -1 
            2 30 0 -1 
            30 21 0 -1 
            85 38 86 -1 
            38 67 86 -1 
            53 69 54 -1 
            69 68 54 -1 
            86 67 87 -1 
            87 67 88 -1 
            67 66 88 -1 
            88 66 89 -1 
            89 66 90 -1 
            66 65 90 -1 
            90 65 91 -1 
            65 64 91 -1 
            91 64 92 -1 
            64 63 92 -1 
            92 63 93 -1 
            63 62 93 -1 
            93 62 94 -1 
            94 62 95 -1 
            62 61 95 -1 
            95 61 96 -1 
            61 60 96 -1 
            96 60 97 -1 
            60 59 97 -1 
            97 59 98 -1 
            59 58 98 -1 
            98 58 99 -1 
            54 68 55 -1 
            68 99 55 -1 
            58 57 99 -1 
            57 56 99 -1 
            99 56 55 -1 
            69 53 70 -1 
            85 84 38 -1 
            39 38 84 -1 
            84 83 39 -1 
            39 83 40 -1 
            83 82 40 -1 
            82 81 40 -1 
            53 52 70 -1 
            70 52 71 -1 
            40 81 41 -1 
            81 80 41 -1 
            41 80 42 -1 
            80 79 42 -1 
            42 79 43 -1 
            79 78 43 -1 
            43 78 44 -1 
            78 77 44 -1 
            44 77 45 -1 
            77 76 45 -1 
            45 76 46 -1 
            76 75 46 -1 
            46 75 47 -1 
            75 74 47 -1 
            47 74 48 -1 
            74 73 48 -1 
            48 73 49 -1 
            73 72 49 -1 
            49 72 50 -1 
            72 71 50 -1 
            50 71 51 -1 
            71 52 51 -1 
            147 100 148 -1 
            100 129 148 -1 
            115 131 116 -1 
            131 130 116 -1 
            148 129 149 -1 
            149 129 150 -1 
            129 128 150 -1 
            150 128 151 -1 
            151 128 152 -1 
            128 127 152 -1 
            152 127 153 -1 
            127 126 153 -1 
            153 126 154 -1 
            126 125 154 -1 
            154 125 155 -1 
            125 124 155 -1 
            155 124 156 -1 
            156 124 157 -1 
            124 123 157 -1 
            157 123 158 -1 
            123 122 158 -1 
            158 122 159 -1 
            122 121 159 -1 
            159 121 160 -1 
            121 120 160 -1 
            160 120 161 -1 
            116 130 117 -1 
            130 161 117 -1 
            120 119 161 -1 
            119 118 161 -1 
            161 118 117 -1 
            131 115 132 -1 
            147 146 100 -1 
            101 100 146 -1 
            146 145 101 -1 
            101 145 102 -1 
            145 144 102 -1 
            144 143 102 -1 
            115 114 132 -1 
            132 114 133 -1 
            102 143 103 -1 
            143 142 103 -1 
            103 142 104 -1 
            142 141 104 -1 
            104 141 105 -1 
            141 140 105 -1 
            105 140 106 -1 
            140 139 106 -1 
            106 139 107 -1 
            139 138 107 -1 
            107 138 108 -1 
            138 137 108 -1 
            108 137 109 -1 
            137 136 109 -1 
            109 136 110 -1 
            136 135 110 -1 
            110 135 111 -1 
            135 134 111 -1 
            111 134 112 -1 
            134 133 112 -1 
            112 133 113 -1 
            133 114 113 -1 
            209 162 210 -1 
            162 191 210 -1 
            177 193 178 -1 
            193 192 178 -1 
            210 191 211 -1 
            211 191 212 -1 
            191 190 212 -1 
            212 190 213 -1 
            213 190 214 -1 
            190 189 214 -1 
            214 189 215 -1 
            189 188 215 -1 
            215 188 216 -1 
            188 187 216 -1 
            216 187 217 -1 
            187 186 217 -1 
            217 186 218 -1 
            218 186 219 -1 
            186 185 219 -1 
            219 185 220 -1 
            185 184 220 -1 
            220 184 221 -1 
            184 183 221 -1 
            221 183 222 -1 
            183 182 222 -1 
            222 182 223 -1 
            178 192 179 -1 
            192 223 179 -1 
            182 181 223 -1 
            181 180 223 -1 
            223 180 179 -1 
            193 177 194 -1 
            209 208 162 -1 
            163 162 208 -1 
            208 207 163 -1 
            163 207 164 -1 
            207 206 164 -1 
            206 205 164 -1 
            177 176 194 -1 
            194 176 195 -1 
            164 205 165 -1 
            205 204 165 -1 
            165 204 166 -1 
            204 203 166 -1 
            166 203 167 -1 
            203 202 167 -1 
            167 202 168 -1 
            202 201 168 -1 
            168 201 169 -1 
            201 200 169 -1 
            169 200 170 -1 
            200 199 170 -1 
            170 199 171 -1 
            199 198 171 -1 
            171 198 172 -1 
            198 197 172 -1 
            172 197 173 -1 
            197 196 173 -1 
            173 196 174 -1 
            196 195 174 -1 
            174 195 175 -1 
            195 176 175 -1 
            271 224 272 -1 
            224 253 272 -1 
            239 255 240 -1 
            255 254 240 -1 
            272 253 273 -1 
            273 253 274 -1 
            253 252 274 -1 
            274 252 275 -1 
            275 252 276 -1 
            252 251 276 -1 
            276 251 277 -1 
            251 250 277 -1 
            277 250 278 -1 
            250 249 278 -1 
            278 249 279 -1 
            249 248 279 -1 
            279 248 280 -1 
            280 248 281 -1 
            248 247 281 -1 
            281 247 282 -1 
            247 246 282 -1 
            282 246 283 -1 
            246 245 283 -1 
            283 245 284 -1 
            245 244 284 -1 
            284 244 285 -1 
            240 254 241 -1 
            254 285 241 -1 
            244 243 285 -1 
            243 242 285 -1 
            285 242 241 -1 
            255 239 256 -1 
            271 270 224 -1 
            225 224 270 -1 
            270 269 225 -1 
            225 269 226 -1 
            269 268 226 -1 
            268 267 226 -1 
            239 238 256 -1 
            256 238 257 -1 
            226 267 227 -1 
            267 266 227 -1 
            227 266 228 -1 
            266 265 228 -1 
            228 265 229 -1 
            265 264 229 -1 
            229 264 230 -1 
            264 263 230 -1 
            230 263 231 -1 
            263 262 231 -1 
            231 262 232 -1 
            262 261 232 -1 
            232 261 233 -1 
            261 260 233 -1 
            233 260 234 -1 
            260 259 234 -1 
            234 259 235 -1 
            259 258 235 -1 
            235 258 236 -1 
            258 257 236 -1 
            236 257 237 -1 
            257 238 237 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0010"
              point="
              -2.99604630592792182e+03 -9.62171143409110982e+02 0.00000000000000000e+00,
              -2.99604630592792182e+03 -2.66315227193593273e+02 0.00000000000000000e+00,
              -3.71123155314942642e+03 -9.62171143409110982e+02 0.00000000000000000e+00,
              -2.98101238181215467e+03 -2.57724413413154707e+01 0.00000000000000000e+00,
              -2.95094453358061992e+03 -6.44311033532903821e+00 0.00000000000000000e+00,
              -2.91443357501375613e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.88007031989200277e+03 -6.44311033532903821e+00 0.00000000000000000e+00,
              -2.85215017510557755e+03 -2.36247378962059429e+01 0.00000000000000000e+00,
              -2.83282084409959043e+03 -4.93971792375214136e+01 0.00000000000000000e+00,
              -2.82637773376426185e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              -2.82637773376426185e+03 -9.62171143409110982e+02 0.00000000000000000e+00,
              -2.69429397189002020e+03 -9.62171143409110982e+02 0.00000000000000000e+00,
              -2.66637382710359498e+03 -9.68614253744439793e+02 0.00000000000000000e+00,
              -2.64274908920738926e+03 -9.86869733027871575e+02 0.00000000000000000e+00,
              -2.62664131336906667e+03 -1.01371602609174192e+03 0.00000000000000000e+00,
              -2.62019820303373808e+03 -1.04485772604583144e+03 0.00000000000000000e+00,
              -2.62664131336906667e+03 -1.07707327772247572e+03 0.00000000000000000e+00,
              -2.64274908920738926e+03 -1.10391957078634596e+03 0.00000000000000000e+00,
              -2.66637382710359498e+03 -1.12324890179233262e+03 0.00000000000000000e+00,
              -2.69429397189002020e+03 -1.12861816040510666e+03 0.00000000000000000e+00,
              -2.82637773376426185e+03 -1.12861816040510666e+03 0.00000000000000000e+00,
              -2.82637773376426185e+03 -1.57104507009768895e+03 0.00000000000000000e+00,
              -2.83282084409959043e+03 -1.60111291832922370e+03 0.00000000000000000e+00,
              -2.85107632338302255e+03 -1.62688535967053917e+03 0.00000000000000000e+00,
              -2.87899646816944733e+03 -1.64406698723141608e+03 0.00000000000000000e+00,
              -2.91121201984609206e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -2.94342757152273634e+03 -1.64406698723141608e+03 0.00000000000000000e+00,
              -2.97134771630916111e+03 -1.62688535967053917e+03 0.00000000000000000e+00,
              -2.99067704731514823e+03 -1.60111291832922370e+03 0.00000000000000000e+00,
              -2.99604630592792182e+03 -1.57104507009768895e+03 0.00000000000000000e+00,
              -2.99604630592792182e+03 -1.12861816040510666e+03 0.00000000000000000e+00,
              -3.90989412182206661e+03 -1.12861816040510666e+03 0.00000000000000000e+00,
              -3.94425737694382042e+03 -1.12324890179233262e+03 0.00000000000000000e+00,
              -3.97110367000769065e+03 -1.10499342250890095e+03 0.00000000000000000e+00,
              -3.98935914929112232e+03 -1.07922098116758525e+03 0.00000000000000000e+00,
              -3.99472840790389637e+03 -1.04700542949094097e+03 0.00000000000000000e+00,
              -3.98935914929112232e+03 -1.01801143298196109e+03 0.00000000000000000e+00,
              -3.97217752173024564e+03 -9.93312843363200500e+02 0.00000000000000000e+00,
              -1.53346025980826926e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.76326452843499919e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              -1.90501295581223394e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              -1.99629035222939319e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              -2.07145997280822985e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -2.13052181754874437e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -2.17132818300582721e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -2.19602677262458792e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -2.20354373468247150e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -2.19602677262458792e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -2.17132818300582721e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -2.13052181754874437e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -2.07145997280822985e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -1.99629035222939319e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.90501295581223394e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.79762778355675300e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.66983942857273041e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -1.54312492531126281e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.43466590133322688e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.34446235663862262e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.26929273605978597e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -1.21023089131927100e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -1.16942452586218860e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -1.14472593624342744e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -1.13613512246298933e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -1.14472593624342744e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -1.16942452586218860e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -1.21023089131927100e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -1.26929273605978597e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -1.36916094625738333e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              -1.49265389435118641e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.79333237666653349e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -1.98233027983618058e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              -2.10474937620742912e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -2.20246988295991650e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -2.27763950353875407e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -2.33025823794393909e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -2.36247378962058337e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -2.37321230684613147e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.36247378962058337e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -2.33025823794393909e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              -2.27763950353875407e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              -2.20246988295991650e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -2.10474937620742912e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -1.98233027983618058e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -1.83736029729128086e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.66983942857273041e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.53560796325337924e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              -1.41318886688213024e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              -1.30580369462664976e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              -1.21452629820949051e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              -1.13935667763065339e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -1.07599942599991982e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              -1.02767609848495340e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              -9.93312843363200045e+02 -5.41221268167624885e+02 0.00000000000000000e+00,
              -9.73983512357212476e+02 -6.76526585209531163e+02 0.00000000000000000e+00,
              -9.66466550299329356e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -9.77205067524876995e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -1.00942061920152128e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -1.06311320532926220e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -1.13828282590809931e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -1.27036658778234096e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              1.20271392926139470e+02 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.09532875700590012e+02 -1.76111682498989239e+02 0.00000000000000000e+00,
              -2.51281303077825214e+02 -2.02957975562859247e+02 0.00000000000000000e+00,
              -3.42558699494983557e+02 -2.50207451355271132e+02 0.00000000000000000e+00,
              -4.17728320073820669e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              -4.76790164814335640e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              -5.17596530271418487e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              -5.42295119890179194e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              -5.49812081948062769e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              -5.42295119890179194e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              -5.17596530271418487e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              -4.76790164814335640e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              -4.17728320073820669e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              -3.42558699494983557e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              -2.51281303077825214e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.43896130822343821e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.61077758383216860e+01 -1.48298922884819444e+03 0.00000000000000000e+00,
              1.10606727423145912e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              2.19065751401182297e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.09269296095786103e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.84438916674622760e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              4.43500761415137731e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              4.84307126872220124e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              5.09005716490981740e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              5.17596530271419851e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              5.09005716490981740e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              4.84307126872220124e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              4.43500761415137731e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.84438916674622760e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.84570706477025396e+02 -2.41616637574832566e+02 0.00000000000000000e+00,
              1.61077758383222772e+02 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.39600723932125220e+02 -1.63547617345097751e+03 0.00000000000000000e+00,
              -3.28598627101771399e+02 -1.59359595627133990e+03 0.00000000000000000e+00,
              -4.51017723473019942e+02 -1.52486944602783205e+03 0.00000000000000000e+00,
              -5.48738230225507778e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              -6.23907850804344889e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              -6.76526585209530822e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              -7.08742136886175103e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -7.19480654111722743e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -7.08742136886175103e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              -6.76526585209530822e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              -6.23907850804344889e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              -5.48738230225507778e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              -4.51017723473019942e+02 -1.24566799816358298e+02 0.00000000000000000e+00,
              -3.28598627101771399e+02 -5.58402895728502244e+01 0.00000000000000000e+00,
              -1.83628644556872132e+02 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.61077758383216860e+01 0.00000000000000000e+00 0.00000000000000000e+00,
              1.18123689481029487e+02 -9.66466550299332994e+00 0.00000000000000000e+00,
              2.40542785852278485e+02 -3.65109585668635646e+01 0.00000000000000000e+00,
              3.47927958107759423e+02 -8.05388791916109312e+01 0.00000000000000000e+00,
              4.39205354524918675e+02 -1.41748427377235203e+02 0.00000000000000000e+00,
              5.14374975103755332e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              5.77732226734489359e+02 -3.11416999540895404e+02 0.00000000000000000e+00,
              6.26055554249455326e+02 -4.19876023518931333e+02 0.00000000000000000e+00,
              6.60418809371209591e+02 -5.41221268167624885e+02 0.00000000000000000e+00,
              6.79748140377195796e+02 -6.76526585209531163e+02 0.00000000000000000e+00,
              6.87265102435079370e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              6.76526585209532186e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              6.44311033532886995e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              5.90618447405146526e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              5.15448826826309869e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.83365064952068224e+02 -1.53882951842104467e+03 0.00000000000000000e+00,
              1.77400304566054956e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              1.54419877703381871e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              1.40245034965658351e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              1.31117295323942426e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              1.23600333266058851e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.17694148792007354e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.13613512246299115e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              1.11143653284422953e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              1.10391957078634687e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              1.11143653284422953e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              1.13613512246299115e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              1.17694148792007354e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              1.23600333266058851e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.31117295323942426e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.40245034965658351e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.50983552191206536e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.63762387689608704e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              1.76433838015755509e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.87279740413559057e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.96300094883019528e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              2.03817056940903285e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.09723241414954691e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.13803877960663021e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.16273736922539001e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.17132818300582903e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.16273736922539001e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.13803877960663021e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.09723241414954691e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.03817056940903285e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.93830235921143367e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              1.81480941111763104e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              1.51413092880228351e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              1.32513302563263778e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              1.20271392926138878e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              1.10499342250890140e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              1.02982380193006384e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              9.77205067524877904e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              9.44989515848233623e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              9.34250998622686438e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              9.44989515848233623e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              9.77205067524877904e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              1.02982380193006384e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              1.10499342250890140e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              1.20271392926138878e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              1.32513302563263778e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              1.47010300817753659e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              1.63762387689608704e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              1.77185534221543958e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              1.89427443858668767e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              2.00165961084216860e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              2.09293700725932786e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              2.16810662783816451e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.23146387946889854e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              2.27978720698386451e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              2.31415046210561786e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              2.33347979311160498e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              2.34099675516948855e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.33025823794394046e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.29804268626729618e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.24435010013955571e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.16918047956071905e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.03709671768647740e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              3.42773469839495601e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              3.19793042976822699e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              3.05618200239099178e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              2.96490460597383253e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              2.88973498539499587e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.83067314065448181e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.78986677519739851e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.76516818557863780e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.75765122352075423e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.76516818557863780e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.78986677519739851e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.83067314065448181e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.88973498539499587e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.96490460597383253e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.05618200239099178e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.16356717464647272e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.29135552963049531e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              3.41807003289196246e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.52652905686999884e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.61673260156460265e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.69190222214343930e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.75096406688395427e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.79177043234103667e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              3.81646902195979737e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              3.82505983574023639e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              3.81646902195979737e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              3.79177043234103667e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.75096406688395427e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.69190222214343930e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              3.59203401194584194e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              3.46854106385203931e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              3.16786258153669269e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              2.97886467836704514e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              2.85644558199579706e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              2.75872507524330877e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.68355545466447211e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.63093672025928709e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.59872116858264280e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.58798265135709380e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.59872116858264280e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              2.63093672025928709e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              2.68355545466447211e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              2.75872507524330877e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.85644558199579706e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              2.97886467836704514e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              3.12383466091194487e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              3.29135552963049531e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              3.42558699494984694e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              3.54800609132109503e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              3.65539126357657597e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              3.74666865999373613e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              3.82183828057257188e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              3.88519553220330499e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              3.93351885971827278e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              3.96788211484002613e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              3.98721144584601234e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              3.99472840790389682e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              3.98398989067834782e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              3.95177433900170354e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              3.89808175287396398e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              3.82291213229512641e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.69082837042088477e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.99910512356453764e+04 2.51548022389700236e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            27 83 28 -1 
            83 82 28 -1 
            17 16 70 -1 
            16 15 70 -1 
            15 14 70 -1 
            14 13 70 -1 
            13 12 70 -1 
            71 70 12 -1 
            12 11 71 -1 
            11 10 71 -1 
            71 10 72 -1 
            10 9 72 -1 
            9 8 72 -1 
            8 7 72 -1 
            7 6 72 -1 
            82 81 28 -1 
            47 46 48 -1 
            48 46 49 -1 
            46 45 49 -1 
            49 45 50 -1 
            45 44 50 -1 
            44 43 50 -1 
            51 50 52 -1 
            52 50 53 -1 
            53 50 54 -1 
            54 50 55 -1 
            50 43 55 -1 
            55 43 56 -1 
            56 43 57 -1 
            43 42 57 -1 
            57 42 58 -1 
            42 41 58 -1 
            58 41 59 -1 
            41 40 59 -1 
            59 40 60 -1 
            40 39 60 -1 
            60 39 61 -1 
            39 38 61 -1 
            61 38 62 -1 
            38 37 62 -1 
            62 37 63 -1 
            37 36 63 -1 
            63 36 64 -1 
            36 35 64 -1 
            64 35 65 -1 
            35 34 65 -1 
            65 34 66 -1 
            34 33 66 -1 
            66 33 67 -1 
            33 32 67 -1 
            67 32 68 -1 
            32 31 68 -1 
            31 30 68 -1 
            28 81 29 -1 
            0 69 30 -1 
            69 68 30 -1 
            1 0 30 -1 
            1 30 2 -1 
            2 30 3 -1 
            3 30 4 -1 
            4 30 5 -1 
            5 30 6 -1 
            29 81 30 -1 
            81 80 30 -1 
            72 6 73 -1 
            73 6 74 -1 
            74 6 75 -1 
            75 6 76 -1 
            76 6 77 -1 
            77 6 78 -1 
            78 6 79 -1 
            79 6 80 -1 
            6 30 80 -1 
            27 26 83 -1 
            26 25 83 -1 
            25 24 83 -1 
            24 23 83 -1 
            17 70 18 -1 
            21 20 22 -1 
            20 19 22 -1 
            22 19 23 -1 
            19 18 23 -1 
            70 95 18 -1 
            95 94 18 -1 
            94 93 18 -1 
            93 92 18 -1 
            92 91 18 -1 
            18 91 23 -1 
            83 23 84 -1 
            84 23 85 -1 
            85 23 86 -1 
            86 23 87 -1 
            87 23 88 -1 
            88 23 89 -1 
            89 23 90 -1 
            23 91 90 -1 
            143 96 144 -1 
            96 125 144 -1 
            111 127 112 -1 
            127 126 112 -1 
            144 125 145 -1 
            145 125 146 -1 
            125 124 146 -1 
            146 124 147 -1 
            147 124 148 -1 
            124 123 148 -1 
            148 123 149 -1 
            123 122 149 -1 
            149 122 150 -1 
            122 121 150 -1 
            150 121 151 -1 
            121 120 151 -1 
            151 120 152 -1 
            152 120 153 -1 
            120 119 153 -1 
            153 119 154 -1 
            119 118 154 -1 
            154 118 155 -1 
            118 117 155 -1 
            155 117 156 -1 
            117 116 156 -1 
            156 116 157 -1 
            112 126 113 -1 
            126 157 113 -1 
            116 115 157 -1 
            115 114 157 -1 
            157 114 113 -1 
            127 111 128 -1 
            143 142 96 -1 
            97 96 142 -1 
            142 141 97 -1 
            97 141 98 -1 
            141 140 98 -1 
            140 139 98 -1 
            111 110 128 -1 
            128 110 129 -1 
            98 139 99 -1 
            139 138 99 -1 
            99 138 100 -1 
            138 137 100 -1 
            100 137 101 -1 
            137 136 101 -1 
            101 136 102 -1 
            136 135 102 -1 
            102 135 103 -1 
            135 134 103 -1 
            103 134 104 -1 
            134 133 104 -1 
            104 133 105 -1 
            133 132 105 -1 
            105 132 106 -1 
            132 131 106 -1 
            106 131 107 -1 
            131 130 107 -1 
            107 130 108 -1 
            130 129 108 -1 
            108 129 109 -1 
            129 110 109 -1 
            205 158 206 -1 
            158 187 206 -1 
            173 189 174 -1 
            189 188 174 -1 
            206 187 207 -1 
            207 187 208 -1 
            187 186 208 -1 
            208 186 209 -1 
            209 186 210 -1 
            186 185 210 -1 
            210 185 211 -1 
            185 184 211 -1 
            211 184 212 -1 
            184 183 212 -1 
            212 183 213 -1 
            183 182 213 -1 
            213 182 214 -1 
            214 182 215 -1 
            182 181 215 -1 
            215 181 216 -1 
            181 180 216 -1 
            216 180 217 -1 
            180 179 217 -1 
            217 179 218 -1 
            179 178 218 -1 
            218 178 219 -1 
            174 188 175 -1 
            188 219 175 -1 
            178 177 219 -1 
            177 176 219 -1 
            219 176 175 -1 
            189 173 190 -1 
            205 204 158 -1 
            159 158 204 -1 
            204 203 159 -1 
            159 203 160 -1 
            203 202 160 -1 
            202 201 160 -1 
            173 172 190 -1 
            190 172 191 -1 
            160 201 161 -1 
            201 200 161 -1 
            161 200 162 -1 
            200 199 162 -1 
            162 199 163 -1 
            199 198 163 -1 
            163 198 164 -1 
            198 197 164 -1 
            164 197 165 -1 
            197 196 165 -1 
            165 196 166 -1 
            196 195 166 -1 
            166 195 167 -1 
            195 194 167 -1 
            167 194 168 -1 
            194 193 168 -1 
            168 193 169 -1 
            193 192 169 -1 
            169 192 170 -1 
            192 191 170 -1 
            170 191 171 -1 
            191 172 171 -1 
            267 220 268 -1 
            220 249 268 -1 
            235 251 236 -1 
            251 250 236 -1 
            268 249 269 -1 
            269 249 270 -1 
            249 248 270 -1 
            270 248 271 -1 
            271 248 272 -1 
            248 247 272 -1 
            272 247 273 -1 
            247 246 273 -1 
            273 246 274 -1 
            246 245 274 -1 
            274 245 275 -1 
            245 244 275 -1 
            275 244 276 -1 
            276 244 277 -1 
            244 243 277 -1 
            277 243 278 -1 
            243 242 278 -1 
            278 242 279 -1 
            242 241 279 -1 
            279 241 280 -1 
            241 240 280 -1 
            280 240 281 -1 
            236 250 237 -1 
            250 281 237 -1 
            240 239 281 -1 
            239 238 281 -1 
            281 238 237 -1 
            251 235 252 -1 
            267 266 220 -1 
            221 220 266 -1 
            266 265 221 -1 
            221 265 222 -1 
            265 264 222 -1 
            264 263 222 -1 
            235 234 252 -1 
            252 234 253 -1 
            222 263 223 -1 
            263 262 223 -1 
            223 262 224 -1 
            262 261 224 -1 
            224 261 225 -1 
            261 260 225 -1 
            225 260 226 -1 
            260 259 226 -1 
            226 259 227 -1 
            259 258 227 -1 
            227 258 228 -1 
            258 257 228 -1 
            228 257 229 -1 
            257 256 229 -1 
            229 256 230 -1 
            256 255 230 -1 
            230 255 231 -1 
            255 254 231 -1 
            231 254 232 -1 
            254 253 232 -1 
            232 253 233 -1 
            253 234 233 -1 
            329 282 330 -1 
            282 311 330 -1 
            297 313 298 -1 
            313 312 298 -1 
            330 311 331 -1 
            331 311 332 -1 
            311 310 332 -1 
            332 310 333 -1 
            333 310 334 -1 
            310 309 334 -1 
            334 309 335 -1 
            309 308 335 -1 
            335 308 336 -1 
            308 307 336 -1 
            336 307 337 -1 
            307 306 337 -1 
            337 306 338 -1 
            338 306 339 -1 
            306 305 339 -1 
            339 305 340 -1 
            305 304 340 -1 
            340 304 341 -1 
            304 303 341 -1 
            341 303 342 -1 
            303 302 342 -1 
            342 302 343 -1 
            298 312 299 -1 
            312 343 299 -1 
            302 301 343 -1 
            301 300 343 -1 
            343 300 299 -1 
            313 297 314 -1 
            329 328 282 -1 
            283 282 328 -1 
            328 327 283 -1 
            283 327 284 -1 
            327 326 284 -1 
            326 325 284 -1 
            297 296 314 -1 
            314 296 315 -1 
            284 325 285 -1 
            325 324 285 -1 
            285 324 286 -1 
            324 323 286 -1 
            286 323 287 -1 
            323 322 287 -1 
            287 322 288 -1 
            322 321 288 -1 
            288 321 289 -1 
            321 320 289 -1 
            289 320 290 -1 
            320 319 290 -1 
            290 319 291 -1 
            319 318 291 -1 
            291 318 292 -1 
            318 317 292 -1 
            292 317 293 -1 
            317 316 293 -1 
            293 316 294 -1 
            316 315 294 -1 
            294 315 295 -1 
            315 296 295 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0011"
              point="
              -3.78049498925421267e+03 -8.60155229766403863e+02 0.00000000000000000e+00,
              -3.67096211355362175e+03 -7.88207164355231498e+02 0.00000000000000000e+00,
              -3.53887835167938010e+03 -7.33440726504936151e+02 0.00000000000000000e+00,
              -3.39605607257959036e+03 -6.99077471383182115e+02 0.00000000000000000e+00,
              -3.25108609003469064e+03 -6.87265102435079257e+02 0.00000000000000000e+00,
              -3.13833165916643566e+03 -6.96929767938072587e+02 0.00000000000000000e+00,
              -3.02987263518839973e+03 -7.24849912724497585e+02 0.00000000000000000e+00,
              -2.93107827671335690e+03 -7.68877833349244952e+02 0.00000000000000000e+00,
              -2.84731784235408168e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.77966518383312859e+03 -8.95592336610712664e+02 0.00000000000000000e+00,
              -2.72597259770538767e+03 -9.78278919247433123e+02 0.00000000000000000e+00,
              -2.69268319430618885e+03 -1.07063016738714691e+03 0.00000000000000000e+00,
              -2.68087082535808622e+03 -1.16835067413963475e+03 0.00000000000000000e+00,
              -2.69268319430618885e+03 -1.26607118089212258e+03 0.00000000000000000e+00,
              -2.72597259770538767e+03 -1.35842242903183615e+03 0.00000000000000000e+00,
              -2.77966518383312859e+03 -1.44218286339111160e+03 0.00000000000000000e+00,
              -2.84731784235408168e+03 -1.51198322535717421e+03 0.00000000000000000e+00,
              -2.93107827671335690e+03 -1.56889736665257919e+03 0.00000000000000000e+00,
              -3.02987263518839973e+03 -1.61292528727732656e+03 0.00000000000000000e+00,
              -3.13940551088899065e+03 -1.64084543206375156e+03 0.00000000000000000e+00,
              -3.25215994175724563e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -3.37565288985104871e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -3.49807198622229726e+03 -1.59252210454878514e+03 0.00000000000000000e+00,
              -3.61404797225821676e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -3.71713773762347864e+03 -1.43896130822344708e+03 0.00000000000000000e+00,
              -3.80304587542786385e+03 -1.33909309802584971e+03 0.00000000000000000e+00,
              -3.86532927533604288e+03 -1.22956022232525902e+03 0.00000000000000000e+00,
              -3.89110171667735813e+03 -1.16405526724941546e+03 0.00000000000000000e+00,
              -3.91365260285100931e+03 -1.08351638805780476e+03 0.00000000000000000e+00,
              -3.92009571318633789e+03 -1.06633476049692763e+03 0.00000000000000000e+00,
              -3.92224341663144787e+03 -1.04807928121349596e+03 0.00000000000000000e+00,
              -3.94372045108254406e+03 -9.29955591732466587e+02 0.00000000000000000e+00,
              -3.95016356141787264e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -3.94479430280509860e+03 -7.25923764447052463e+02 0.00000000000000000e+00,
              -3.92653882352166693e+03 -6.16390888746461769e+02 0.00000000000000000e+00,
              -3.89861867873524170e+03 -5.11153419936090131e+02 0.00000000000000000e+00,
              -3.86425542361348789e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              -3.80089817198275387e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              -3.71391618245581412e+03 -2.10474937620743049e+02 0.00000000000000000e+00,
              -3.60975256536799770e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -3.49162887588696822e+03 -5.79879930179599796e+01 0.00000000000000000e+00,
              -3.36813592779316514e+03 -1.50339241157676042e+01 0.00000000000000000e+00,
              -3.24356912797680707e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09322988681913375e+03 -1.18123689481030851e+01 0.00000000000000000e+00,
              -2.93966909049379547e+03 -4.51017723473021306e+01 0.00000000000000000e+00,
              -2.80114221828422478e+03 -9.77205067524878359e+01 0.00000000000000000e+00,
              -2.69483089775129838e+03 -1.63225461828331390e+02 0.00000000000000000e+00,
              -2.67442771502275718e+03 -1.87924051447092097e+02 0.00000000000000000e+00,
              -2.66476304951976363e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -2.66798460468742815e+03 -2.52355154800380660e+02 0.00000000000000000e+00,
              -2.68409238052575029e+03 -2.80275299586805886e+02 0.00000000000000000e+00,
              -2.70879097014451099e+03 -3.01752334037902074e+02 0.00000000000000000e+00,
              -2.74100652182115527e+03 -3.10343147818340412e+02 0.00000000000000000e+00,
              -2.77429592522035455e+03 -3.08195444373230885e+02 0.00000000000000000e+00,
              -2.80221607000677977e+03 -2.92087668534908744e+02 0.00000000000000000e+00,
              -2.87953339403072596e+03 -2.48059747910161605e+02 0.00000000000000000e+00,
              -2.99550938006664546e+03 -2.06179530730523766e+02 0.00000000000000000e+00,
              -3.12544543849577803e+03 -1.77185534221543776e+02 0.00000000000000000e+00,
              -3.24356912797680707e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -3.32410800716841777e+03 -1.77185534221543776e+02 0.00000000000000000e+00,
              -3.41108999669535751e+03 -2.08327234175633521e+02 0.00000000000000000e+00,
              -3.50021968966740678e+03 -2.58798265135709471e+02 0.00000000000000000e+00,
              -3.58505397574923700e+03 -3.25377071934107789e+02 0.00000000000000000e+00,
              -3.65700204116040914e+03 -4.02694395958054201e+02 0.00000000000000000e+00,
              -3.70854692384304053e+03 -4.86454830317329652e+02 0.00000000000000000e+00,
              -3.73539321690691031e+03 -5.58402895728502017e+02 0.00000000000000000e+00,
              -3.75901795480311648e+03 -6.51827995590770570e+02 0.00000000000000000e+00,
              -3.77512573064143862e+03 -7.49548502343258292e+02 0.00000000000000000e+00,
              -3.78049498925421267e+03 -8.29013529812314232e+02 0.00000000000000000e+00,
              -3.78049498925421267e+03 -8.48342860818301006e+02 0.00000000000000000e+00,
              -2.85053939752174574e+03 -1.22956022232525902e+03 0.00000000000000000e+00,
              -2.85913021130218476e+03 -1.12432275351488738e+03 0.00000000000000000e+00,
              -2.88168109747583549e+03 -1.05022698465860549e+03 0.00000000000000000e+00,
              -2.91711820432014429e+03 -9.96534398530864905e+02 0.00000000000000000e+00,
              -2.96436768011255572e+03 -9.49284922738453247e+02 0.00000000000000000e+00,
              -3.02235567313051570e+03 -9.10626260726480041e+02 0.00000000000000000e+00,
              -3.09215603509657876e+03 -8.80558412494945287e+02 0.00000000000000000e+00,
              -3.17054721084307994e+03 -8.61229081488958741e+02 0.00000000000000000e+00,
              -3.25323379347980017e+03 -8.53712119431075052e+02 0.00000000000000000e+00,
              -3.40249918291491895e+03 -8.69819895269397193e+02 0.00000000000000000e+00,
              -3.54746916545981867e+03 -9.15995519339254088e+02 0.00000000000000000e+00,
              -3.66881441010851222e+03 -9.82574326137652406e+02 0.00000000000000000e+00,
              -3.74613173413245886e+03 -1.05667009499393430e+03 0.00000000000000000e+00,
              -3.72787625484902674e+03 -1.11895349490211356e+03 0.00000000000000000e+00,
              -3.70962077556559507e+03 -1.16512911897197023e+03 0.00000000000000000e+00,
              -3.65807589288296413e+03 -1.24888955333124568e+03 0.00000000000000000e+00,
              -3.58720167919434653e+03 -1.32513302563263710e+03 0.00000000000000000e+00,
              -3.50451509655762629e+03 -1.39171183243103542e+03 0.00000000000000000e+00,
              -3.41753310703068655e+03 -1.44110901166855683e+03 0.00000000000000000e+00,
              -3.33162496922630180e+03 -1.47332456334520111e+03 0.00000000000000000e+00,
              -3.25323379347980017e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -3.17054721084307994e+03 -1.47654611851286563e+03 0.00000000000000000e+00,
              -3.09215603509657876e+03 -1.45721678750687897e+03 0.00000000000000000e+00,
              -3.02235567313051570e+03 -1.42714893927534422e+03 0.00000000000000000e+00,
              -2.96436768011255572e+03 -1.38741642554081614e+03 0.00000000000000000e+00,
              -2.90960124226226071e+03 -1.32728072907774686e+03 0.00000000000000000e+00,
              -1.57802510629429480e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.80782937492102474e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              -1.94957780229825948e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              -2.04085519871541851e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              -2.11602481929425539e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -2.17508666403476991e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -2.21589302949185276e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -2.24059161911061346e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -2.24810858116849704e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -2.24059161911061346e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -2.21589302949185276e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -2.17508666403476991e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -2.11602481929425539e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -2.04085519871541851e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.94957780229825948e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.84219263004277855e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.71440427505875596e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -1.58768977179728836e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.47923074781925243e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.38902720312464817e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.31385758254581106e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -1.25479573780529699e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -1.21398937234821369e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -1.18929078272945344e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -1.18069996894901442e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -1.18929078272945344e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -1.21398937234821369e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -1.25479573780529699e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -1.31385758254581106e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -1.41372579274340887e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              -1.53721874083721195e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.83789722315255904e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -2.02689512632220612e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              -2.14931422269345421e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -2.24703472944594250e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -2.32220435002477916e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -2.37482308442996509e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -2.40703863610660937e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -2.41777715333215747e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.40703863610660937e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -2.37482308442996509e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              -2.32220435002477916e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              -2.24703472944594250e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -2.14931422269345421e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -2.02689512632220612e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -1.88192514377730640e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.71440427505875596e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.58017280973940478e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              -1.45775371336815624e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              -1.35036854111267485e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              -1.25909114469551605e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              -1.18392152411667894e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -1.12056427248594537e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              -1.07224094497097894e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              -1.03787768984922513e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              -1.01854835884323847e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              -1.01103139678535490e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -1.02176991401090208e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -1.05398546568754728e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -1.10767805181528774e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -1.18284767239412440e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -1.31493143426836605e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              7.57065464401143799e+01 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.54097722186615101e+02 -1.76111682498989239e+02 0.00000000000000000e+00,
              -2.95846149563850304e+02 -2.02957975562859247e+02 0.00000000000000000e+00,
              -3.87123545981009102e+02 -2.50207451355271132e+02 0.00000000000000000e+00,
              -4.62293166559846213e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              -5.21355011300361184e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              -5.62161376757443577e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              -5.86859966376204284e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              -5.94376928434087858e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              -5.86859966376204284e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              -5.62161376757443577e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              -5.21355011300361184e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              -4.62293166559846213e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              -3.87123545981009102e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              -2.95846149563850304e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.88460977308368911e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              -6.06726223243467757e+01 -1.48298922884819444e+03 0.00000000000000000e+00,
              6.60418809371208226e+01 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.74500904915156752e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              2.64704449609761468e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.39874070188598125e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.98935914929113096e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              4.39742280386195489e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              4.64440870004956196e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              4.73031683785394307e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              4.64440870004956196e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              4.39742280386195489e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.98935914929113096e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.39874070188598125e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.40005859991000762e+02 -2.41616637574832566e+02 0.00000000000000000e+00,
              1.16512911897197228e+02 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.84165570418150310e+02 -1.63547617345097751e+03 0.00000000000000000e+00,
              -3.73163473587796489e+02 -1.59359595627133990e+03 0.00000000000000000e+00,
              -4.95582569959045031e+02 -1.52486944602783205e+03 0.00000000000000000e+00,
              -5.93303076711532867e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              -6.68472697290369979e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              -7.21091431695555912e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              -7.53306983372200193e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -7.64045500597747832e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -7.53306983372200193e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              -7.21091431695555912e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              -6.68472697290369979e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              -5.93303076711532867e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              -4.95582569959045031e+02 -1.24566799816358298e+02 0.00000000000000000e+00,
              -3.73163473587796489e+02 -5.58402895728502244e+01 0.00000000000000000e+00,
              -2.28193491042897222e+02 -1.39600723932126130e+01 0.00000000000000000e+00,
              -6.06726223243467757e+01 0.00000000000000000e+00 0.00000000000000000e+00,
              7.35588429950048521e+01 -9.66466550299332994e+00 0.00000000000000000e+00,
              1.95977939366252940e+02 -3.65109585668635646e+01 0.00000000000000000e+00,
              3.03363111621733879e+02 -8.05388791916109312e+01 0.00000000000000000e+00,
              3.94640508038893131e+02 -1.41748427377235203e+02 0.00000000000000000e+00,
              4.69810128617729788e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              5.33167380248463814e+02 -3.11416999540895404e+02 0.00000000000000000e+00,
              5.81490707763430692e+02 -4.19876023518931333e+02 0.00000000000000000e+00,
              6.15853962885184956e+02 -5.41221268167624885e+02 0.00000000000000000e+00,
              6.35183293891170251e+02 -6.76526585209531163e+02 0.00000000000000000e+00,
              6.42700255949054736e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              6.31961738723506642e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              5.99746187046861451e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              5.46053600919121891e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              4.70883980340284324e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.38800218466042679e+02 -1.53882951842104467e+03 0.00000000000000000e+00,
              1.72943819917452311e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              1.49963393054779408e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              1.35788550317055888e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              1.26660810675339962e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              1.19143848617456297e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.13237664143404800e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.09157027597696560e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              1.06687168635820490e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              1.05935472430032132e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              1.06687168635820490e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              1.09157027597696560e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              1.13237664143404800e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              1.19143848617456297e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.26660810675339962e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.35788550317055888e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.46527067542603982e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.59305903041006241e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              1.71977353367153046e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.82823255764956684e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.91843610234417065e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.99360572292300731e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.05266756766352137e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.09347393312060467e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.11817252273936538e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.12676333651980440e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.11817252273936538e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.09347393312060467e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.05266756766352137e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.99360572292300731e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.89373751272540994e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              1.77024456463160550e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              1.46956608231625887e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              1.28056817914661224e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              1.15814908277536415e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              1.06042857602287586e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              9.85258955444039202e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              9.32640221038853269e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              9.00424669362208988e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              8.89686152136660894e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              9.00424669362208988e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              9.32640221038853269e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              9.85258955444039202e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              1.06042857602287586e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              1.15814908277536415e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              1.28056817914661224e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              1.42553816169151196e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              1.59305903041006241e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              1.72729049572941312e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              1.84970959210066212e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              1.95709476435614397e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              2.04837216077330231e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              2.12354178135213897e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.18689903298287300e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              2.23522236049783896e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              2.26958561561959323e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              2.28891494662558034e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              2.29643190868346392e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.28569339145791582e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.25347783978127154e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.19978525365353016e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.12461563307469441e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              1.99253187120045277e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              3.38316985190893229e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              3.15336558328220326e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              3.01161715590496806e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              2.92033975948780881e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              2.84517013890897215e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.78610829416845718e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.74530192871137479e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.72060333909261408e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.71308637703473050e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.72060333909261408e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.74530192871137479e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.78610829416845718e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.84517013890897215e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.92033975948780881e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.01161715590496806e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.11900232816044900e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.24679068314447159e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              3.37350518640593873e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.48196421038397511e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.57216775507857892e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.64733737565741558e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.70639922039792964e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.74720558585501294e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              3.77190417547377365e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              3.78049498925421267e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              3.77190417547377365e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              3.74720558585501294e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.70639922039792964e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.64733737565741558e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              3.54746916545981821e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              3.42397621736601559e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              3.12329773505066805e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              2.93429983188102051e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              2.81188073550977242e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              2.71416022875728504e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.63899060817844838e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.58637187377326245e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.55415632209661817e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.54341780487107008e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.55415632209661817e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              2.58637187377326245e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              2.63899060817844838e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              2.71416022875728504e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.81188073550977242e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              2.93429983188102051e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              3.07926981442592114e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              3.24679068314447159e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              3.38102214846382230e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              3.50344124483507039e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              3.61082641709055224e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              3.70210381350771149e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              3.77727343408654815e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              3.84063068571728127e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              3.88895401323224814e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              3.92331726835400241e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              3.94264659935998861e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              3.95016356141787219e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              3.93942504419232409e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              3.90720949251567981e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              3.85351690638793934e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              3.77834728580910269e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.64626352393486104e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="7.99910512356453692e+04 2.51548022389700236e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            145 98 146 -1 
            98 127 146 -1 
            113 129 114 -1 
            129 128 114 -1 
            146 127 147 -1 
            147 127 148 -1 
            127 126 148 -1 
            148 126 149 -1 
            149 126 150 -1 
            126 125 150 -1 
            150 125 151 -1 
            125 124 151 -1 
            151 124 152 -1 
            124 123 152 -1 
            152 123 153 -1 
            123 122 153 -1 
            153 122 154 -1 
            154 122 155 -1 
            122 121 155 -1 
            155 121 156 -1 
            121 120 156 -1 
            156 120 157 -1 
            120 119 157 -1 
            157 119 158 -1 
            119 118 158 -1 
            158 118 159 -1 
            114 128 115 -1 
            128 159 115 -1 
            118 117 159 -1 
            117 116 159 -1 
            159 116 115 -1 
            129 113 130 -1 
            145 144 98 -1 
            99 98 144 -1 
            144 143 99 -1 
            99 143 100 -1 
            143 142 100 -1 
            142 141 100 -1 
            113 112 130 -1 
            130 112 131 -1 
            100 141 101 -1 
            141 140 101 -1 
            101 140 102 -1 
            140 139 102 -1 
            102 139 103 -1 
            139 138 103 -1 
            103 138 104 -1 
            138 137 104 -1 
            104 137 105 -1 
            137 136 105 -1 
            105 136 106 -1 
            136 135 106 -1 
            106 135 107 -1 
            135 134 107 -1 
            107 134 108 -1 
            134 133 108 -1 
            108 133 109 -1 
            133 132 109 -1 
            109 132 110 -1 
            132 131 110 -1 
            110 131 111 -1 
            131 112 111 -1 
            207 160 208 -1 
            160 189 208 -1 
            175 191 176 -1 
            191 190 176 -1 
            208 189 209 -1 
            209 189 210 -1 
            189 188 210 -1 
            210 188 211 -1 
            211 188 212 -1 
            188 187 212 -1 
            212 187 213 -1 
            187 186 213 -1 
            213 186 214 -1 
            186 185 214 -1 
            214 185 215 -1 
            185 184 215 -1 
            215 184 216 -1 
            216 184 217 -1 
            184 183 217 -1 
            217 183 218 -1 
            183 182 218 -1 
            218 182 219 -1 
            182 181 219 -1 
            219 181 220 -1 
            181 180 220 -1 
            220 180 221 -1 
            176 190 177 -1 
            190 221 177 -1 
            180 179 221 -1 
            179 178 221 -1 
            221 178 177 -1 
            191 175 192 -1 
            207 206 160 -1 
            161 160 206 -1 
            206 205 161 -1 
            161 205 162 -1 
            205 204 162 -1 
            204 203 162 -1 
            175 174 192 -1 
            192 174 193 -1 
            162 203 163 -1 
            203 202 163 -1 
            163 202 164 -1 
            202 201 164 -1 
            164 201 165 -1 
            201 200 165 -1 
            165 200 166 -1 
            200 199 166 -1 
            166 199 167 -1 
            199 198 167 -1 
            167 198 168 -1 
            198 197 168 -1 
            168 197 169 -1 
            197 196 169 -1 
            169 196 170 -1 
            196 195 170 -1 
            170 195 171 -1 
            195 194 171 -1 
            171 194 172 -1 
            194 193 172 -1 
            172 193 173 -1 
            193 174 173 -1 
            269 222 270 -1 
            222 251 270 -1 
            237 253 238 -1 
            253 252 238 -1 
            270 251 271 -1 
            271 251 272 -1 
            251 250 272 -1 
            272 250 273 -1 
            273 250 274 -1 
            250 249 274 -1 
            274 249 275 -1 
            249 248 275 -1 
            275 248 276 -1 
            248 247 276 -1 
            276 247 277 -1 
            247 246 277 -1 
            277 246 278 -1 
            278 246 279 -1 
            246 245 279 -1 
            279 245 280 -1 
            245 244 280 -1 
            280 244 281 -1 
            244 243 281 -1 
            281 243 282 -1 
            243 242 282 -1 
            282 242 283 -1 
            238 252 239 -1 
            252 283 239 -1 
            242 241 283 -1 
            241 240 283 -1 
            283 240 239 -1 
            253 237 254 -1 
            269 268 222 -1 
            223 222 268 -1 
            268 267 223 -1 
            223 267 224 -1 
            267 266 224 -1 
            266 265 224 -1 
            237 236 254 -1 
            254 236 255 -1 
            224 265 225 -1 
            265 264 225 -1 
            225 264 226 -1 
            264 263 226 -1 
            226 263 227 -1 
            263 262 227 -1 
            227 262 228 -1 
            262 261 228 -1 
            228 261 229 -1 
            261 260 229 -1 
            229 260 230 -1 
            260 259 230 -1 
            230 259 231 -1 
            259 258 231 -1 
            231 258 232 -1 
            258 257 232 -1 
            232 257 233 -1 
            257 256 233 -1 
            233 256 234 -1 
            256 255 234 -1 
            234 255 235 -1 
            255 236 235 -1 
            331 284 332 -1 
            284 313 332 -1 
            299 315 300 -1 
            315 314 300 -1 
            332 313 333 -1 
            333 313 334 -1 
            313 312 334 -1 
            334 312 335 -1 
            335 312 336 -1 
            312 311 336 -1 
            336 311 337 -1 
            311 310 337 -1 
            337 310 338 -1 
            310 309 338 -1 
            338 309 339 -1 
            309 308 339 -1 
            339 308 340 -1 
            340 308 341 -1 
            308 307 341 -1 
            341 307 342 -1 
            307 306 342 -1 
            342 306 343 -1 
            306 305 343 -1 
            343 305 344 -1 
            305 304 344 -1 
            344 304 345 -1 
            300 314 301 -1 
            314 345 301 -1 
            304 303 345 -1 
            303 302 345 -1 
            345 302 301 -1 
            315 299 316 -1 
            331 330 284 -1 
            285 284 330 -1 
            330 329 285 -1 
            285 329 286 -1 
            329 328 286 -1 
            328 327 286 -1 
            299 298 316 -1 
            316 298 317 -1 
            286 327 287 -1 
            327 326 287 -1 
            287 326 288 -1 
            326 325 288 -1 
            288 325 289 -1 
            325 324 289 -1 
            289 324 290 -1 
            324 323 290 -1 
            290 323 291 -1 
            323 322 291 -1 
            291 322 292 -1 
            322 321 292 -1 
            292 321 293 -1 
            321 320 293 -1 
            293 320 294 -1 
            320 319 294 -1 
            294 319 295 -1 
            319 318 295 -1 
            295 318 296 -1 
            318 317 296 -1 
            296 317 297 -1 
            317 298 297 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0012"
              point="
              -3.72197007037497588e+03 -3.73700399449074439e+02 0.00000000000000000e+00,
              -3.71982236692986635e+03 -4.64977795866233237e+02 0.00000000000000000e+00,
              -3.71015770142687279e+03 -5.21891937161638452e+02 0.00000000000000000e+00,
              -3.69404992558855065e+03 -5.60550599173611545e+02 0.00000000000000000e+00,
              -3.66827748424723541e+03 -5.93840002572810818e+02 0.00000000000000000e+00,
              -3.63498808084803613e+03 -6.23907850804345344e+02 0.00000000000000000e+00,
              -3.59203401194584376e+03 -6.46458736977996409e+02 0.00000000000000000e+00,
              -3.53834142581810329e+03 -6.63640364538873428e+02 0.00000000000000000e+00,
              -3.47391032246481427e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              -3.39659299844086809e+03 -6.85117398989969729e+02 0.00000000000000000e+00,
              -3.30638945374626383e+03 -6.87265102435079257e+02 0.00000000000000000e+00,
              -3.19793042976822790e+03 -6.84043547267414851e+02 0.00000000000000000e+00,
              -3.10772688507362363e+03 -6.71157326596757116e+02 0.00000000000000000e+00,
              -3.03577881966245150e+03 -6.50754143868215692e+02 0.00000000000000000e+00,
              -2.97993853008960150e+03 -6.21760147359235816e+02 0.00000000000000000e+00,
              -2.94020601635507319e+03 -5.85249188792372252e+02 0.00000000000000000e+00,
              -2.91335972329120295e+03 -5.42295119890179876e+02 0.00000000000000000e+00,
              -2.89832579917543535e+03 -4.90750237207548707e+02 0.00000000000000000e+00,
              -2.89295654056266176e+03 -4.27392985576814908e+02 0.00000000000000000e+00,
              -2.89617809573032628e+03 -3.77995806339293722e+02 0.00000000000000000e+00,
              -2.90584276123331892e+03 -3.33967885714546355e+02 0.00000000000000000e+00,
              -2.92195053707164152e+03 -2.95309223702573263e+02 0.00000000000000000e+00,
              -2.94664912669040223e+03 -2.60945968580819226e+02 0.00000000000000000e+00,
              -2.98101238181215604e+03 -2.31951972071839236e+02 0.00000000000000000e+00,
              -3.02396645071434841e+03 -2.08327234175633521e+02 0.00000000000000000e+00,
              -3.07765903684208888e+03 -1.91145606614756616e+02 0.00000000000000000e+00,
              -3.14209014019537790e+03 -1.78259385944098767e+02 0.00000000000000000e+00,
              -3.21833361249676955e+03 -1.69668572163660201e+02 0.00000000000000000e+00,
              -3.30746330546881882e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -3.39766685016342308e+03 -1.69668572163660201e+02 0.00000000000000000e+00,
              -3.47391032246481427e+03 -1.78259385944098767e+02 0.00000000000000000e+00,
              -3.53834142581810329e+03 -1.91145606614756616e+02 0.00000000000000000e+00,
              -3.59203401194584376e+03 -2.08327234175633521e+02 0.00000000000000000e+00,
              -3.63498808084803613e+03 -2.31951972071839236e+02 0.00000000000000000e+00,
              -3.66827748424723541e+03 -2.60945968580819226e+02 0.00000000000000000e+00,
              -3.69619762903366018e+03 -3.06047740928121357e+02 0.00000000000000000e+00,
              -3.69190222214344112e+03 -7.74247091962018999e+02 0.00000000000000000e+00,
              -3.77888421167038086e+03 -7.11963692053839850e+02 0.00000000000000000e+00,
              -3.84116761157855990e+03 -6.32498664584783796e+02 0.00000000000000000e+00,
              -3.87982627359053322e+03 -5.36925861277405602e+02 0.00000000000000000e+00,
              -3.89163864253863585e+03 -4.24171430409150616e+02 0.00000000000000000e+00,
              -3.87445701497775917e+03 -2.96383075425128027e+02 0.00000000000000000e+00,
              -3.82183828057257324e+03 -1.88997903169646861e+02 0.00000000000000000e+00,
              -3.73700399449074303e+03 -1.06311320532926402e+02 0.00000000000000000e+00,
              -3.62102800845482352e+03 -4.72494757924118858e+01 0.00000000000000000e+00,
              -3.47820572935503378e+03 -1.18123689481030851e+01 0.00000000000000000e+00,
              -3.30746330546881882e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.13672088158260340e+03 -1.18123689481030851e+01 0.00000000000000000e+00,
              -2.99389860248281366e+03 -4.72494757924118858e+01 0.00000000000000000e+00,
              -2.87899646816944914e+03 -1.06311320532926402e+02 0.00000000000000000e+00,
              -2.79416218208761893e+03 -1.90071754892201625e+02 0.00000000000000000e+00,
              -2.74154344768243300e+03 -2.97456927147682791e+02 0.00000000000000000e+00,
              -2.72328796839900133e+03 -4.26319133854260144e+02 0.00000000000000000e+00,
              -2.73617418906965941e+03 -5.39073564722515357e+02 0.00000000000000000e+00,
              -2.77483285108163227e+03 -6.35720219752448429e+02 0.00000000000000000e+00,
              -2.83819010271236584e+03 -7.16259098944059133e+02 0.00000000000000000e+00,
              -2.92302438879419606e+03 -7.77468647129683404e+02 0.00000000000000000e+00,
              -2.83711625098981131e+03 -8.48342860818301006e+02 0.00000000000000000e+00,
              -2.77375899935907728e+03 -9.37472553790350275e+02 0.00000000000000000e+00,
              -2.73617418906965941e+03 -1.04593157776838621e+03 0.00000000000000000e+00,
              -2.72328796839900133e+03 -1.17049837758474428e+03 0.00000000000000000e+00,
              -2.76194663041097465e+03 -1.37667790831526804e+03 0.00000000000000000e+00,
              -2.87470106127923009e+03 -1.52701714947294158e+03 0.00000000000000000e+00,
              -3.05832970583610268e+03 -1.61936839761265537e+03 0.00000000000000000e+00,
              -3.30746330546881882e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -3.55659690510153496e+03 -1.61936839761265537e+03 0.00000000000000000e+00,
              -3.74022554965840754e+03 -1.52594329775038682e+03 0.00000000000000000e+00,
              -3.85405383224921752e+03 -1.37560405659271328e+03 0.00000000000000000e+00,
              -3.89163864253863585e+03 -1.16835067413963475e+03 0.00000000000000000e+00,
              -3.87982627359053322e+03 -1.04271002260072191e+03 0.00000000000000000e+00,
              -3.84116761157855990e+03 -9.34250998622685870e+02 0.00000000000000000e+00,
              -3.77888421167038086e+03 -8.44047453928081723e+02 0.00000000000000000e+00,
              -3.19041346771034432e+03 -8.53712119431075052e+02 0.00000000000000000e+00,
              -3.38585448121531999e+03 -8.59081378043848986e+02 0.00000000000000000e+00,
              -3.50290431897379449e+03 -8.71967598714506721e+02 0.00000000000000000e+00,
              -3.57377853266241164e+03 -8.95592336610712664e+02 0.00000000000000000e+00,
              -3.63069267395781662e+03 -9.29955591732466587e+02 0.00000000000000000e+00,
              -3.67257289113745446e+03 -9.73983512357213840e+02 0.00000000000000000e+00,
              -3.70049303592387969e+03 -1.02660224676239977e+03 0.00000000000000000e+00,
              -3.71660081176220183e+03 -1.09103335011568834e+03 0.00000000000000000e+00,
              -3.72197007037497588e+03 -1.16835067413963475e+03 0.00000000000000000e+00,
              -3.71230540487198232e+03 -1.26929273605978688e+03 0.00000000000000000e+00,
              -3.68331140836300256e+03 -1.34768391180628805e+03 0.00000000000000000e+00,
              -3.63069267395781662e+03 -1.40781960826935756e+03 0.00000000000000000e+00,
              -3.55230149821131545e+03 -1.44969982544899517e+03 0.00000000000000000e+00,
              -3.44599017767838950e+03 -1.47547226679031064e+03 0.00000000000000000e+00,
              -3.30746330546881882e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -3.16893643325924813e+03 -1.47547226679031064e+03 0.00000000000000000e+00,
              -3.06262511272632173e+03 -1.44969982544899517e+03 0.00000000000000000e+00,
              -2.98423393697982056e+03 -1.40781960826935756e+03 0.00000000000000000e+00,
              -2.93268905429718961e+03 -1.34768391180628805e+03 0.00000000000000000e+00,
              -2.90369505778820985e+03 -1.26929273605978688e+03 0.00000000000000000e+00,
              -2.89295654056266176e+03 -1.16835067413963475e+03 0.00000000000000000e+00,
              -2.89832579917543535e+03 -1.09103335011568834e+03 0.00000000000000000e+00,
              -2.91443357501375795e+03 -1.02660224676239977e+03 0.00000000000000000e+00,
              -2.94235371980018317e+03 -9.73983512357213840e+02 0.00000000000000000e+00,
              -2.98423393697982056e+03 -9.29955591732466587e+02 0.00000000000000000e+00,
              -3.06047740928121220e+03 -8.91296929720493495e+02 0.00000000000000000e+00,
              -1.63655002517353250e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.86635429380026198e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              -2.00810272117749719e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              -2.09938011759465644e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              -2.17454973817349310e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -2.23361158291400761e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -2.27441794837109046e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -2.29911653798985117e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -2.30663350004773474e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -2.29911653798985117e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -2.27441794837109046e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -2.23361158291400761e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -2.17454973817349310e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -2.09938011759465644e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -2.00810272117749719e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.90071754892201625e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.77292919393799366e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -1.64621469067652561e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.53775566669849013e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.44755212200388587e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.37238250142504876e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -1.31332065668453424e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -1.27251429122745139e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -1.24781570160869114e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -1.23922488782825212e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -1.24781570160869114e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -1.27251429122745139e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -1.31332065668453424e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -1.37238250142504876e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -1.47225071162264658e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              -1.59574365971644966e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.89642214203179674e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -2.08542004520144383e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              -2.20783914157269191e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -2.30555964832518021e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -2.38072926890401686e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -2.43334800330920234e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -2.46556355498584662e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -2.47630207221139517e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.46556355498584662e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -2.43334800330920234e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              -2.38072926890401686e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              -2.30555964832518021e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -2.20783914157269191e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -2.08542004520144383e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -1.94045006265654411e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.77292919393799366e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.63869772861864203e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              -1.51627863224739394e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              -1.40889345999191255e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              -1.31761606357475375e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              -1.24244644299591664e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -1.17908919136518307e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              -1.13076586385021665e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              -1.09640260872846284e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              -1.07707327772247572e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              -1.06955631566459215e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -1.08029483289014070e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -1.11251038456678498e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -1.16620297069452545e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -1.24137259127336210e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -1.37345635314760375e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              1.71816275608775868e+01 -1.66447016995995909e+02 0.00000000000000000e+00,
              -2.12622641065851894e+02 -1.76111682498989239e+02 0.00000000000000000e+00,
              -3.54371068443087097e+02 -2.02957975562859247e+02 0.00000000000000000e+00,
              -4.45648464860245895e+02 -2.50207451355271132e+02 0.00000000000000000e+00,
              -5.20818085439083006e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              -5.79879930179597977e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              -6.20686295636680370e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              -6.45384885255441532e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              -6.52901847313324652e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              -6.45384885255441532e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              -6.20686295636680370e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              -5.79879930179597977e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              -5.20818085439083006e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              -4.45648464860245895e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              -3.54371068443087097e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              -2.46985896187606158e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.19197541203583569e+02 -1.48298922884819444e+03 0.00000000000000000e+00,
              7.51696205788357474e+00 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.15975986035919959e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              2.06179530730524675e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              2.81349151309360423e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.40410996049875394e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.81217361506958696e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              4.05915951125719403e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              4.14506764906157514e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              4.05915951125719403e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              3.81217361506958696e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.40410996049875394e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.81349151309360423e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.81480941111763059e+02 -2.41616637574832566e+02 0.00000000000000000e+00,
              5.79879930179604344e+01 -1.64943624584419013e+03 0.00000000000000000e+00,
              -2.42690489297387103e+02 -1.63547617345097751e+03 0.00000000000000000e+00,
              -4.31688392467033282e+02 -1.59359595627133990e+03 0.00000000000000000e+00,
              -5.54107488838282279e+02 -1.52486944602783205e+03 0.00000000000000000e+00,
              -6.51827995590769660e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              -7.26997616169606772e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              -7.79616350574792705e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              -8.11831902251436986e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -8.22570419476985080e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.11831902251436986e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              -7.79616350574792705e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              -7.26997616169606772e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              -6.51827995590769660e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              -5.54107488838282279e+02 -1.24566799816358298e+02 0.00000000000000000e+00,
              -4.31688392467033282e+02 -5.58402895728502244e+01 0.00000000000000000e+00,
              -2.86718409922134015e+02 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.19197541203583569e+02 0.00000000000000000e+00 0.00000000000000000e+00,
              1.50339241157676042e+01 -9.66466550299332994e+00 0.00000000000000000e+00,
              1.37453020487016147e+02 -3.65109585668635646e+01 0.00000000000000000e+00,
              2.44838192742497085e+02 -8.05388791916109312e+01 0.00000000000000000e+00,
              3.36115589159656338e+02 -1.41748427377235203e+02 0.00000000000000000e+00,
              4.11285209738492995e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              4.74642461369227021e+02 -3.11416999540895404e+02 0.00000000000000000e+00,
              5.22965788884193898e+02 -4.19876023518931333e+02 0.00000000000000000e+00,
              5.57329044005947253e+02 -5.41221268167624885e+02 0.00000000000000000e+00,
              5.76658375011933458e+02 -6.76526585209531163e+02 0.00000000000000000e+00,
              5.84175337069817942e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              5.73436819844269849e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              5.41221268167624658e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              4.87528682039885098e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              4.12359061461047531e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.80275299586805886e+02 -1.53882951842104467e+03 0.00000000000000000e+00,
              1.67091328029528631e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              1.44110901166855729e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              1.29936058429132208e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              1.20808318787416283e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              1.13291356729532617e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.07385172255481120e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.03304535709772881e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              1.00834676747896719e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              1.00082980542108453e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              1.00834676747896719e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              1.03304535709772881e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              1.07385172255481120e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              1.13291356729532617e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.20808318787416283e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.29936058429132208e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.40674575654680302e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.53453411153082561e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              1.66124861479229367e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              1.76970763877033005e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.85991118346493295e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.93508080404377051e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.99414264878428457e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.03494901424136788e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.05964760386012858e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.06823841764056669e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.05964760386012858e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.03494901424136788e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              1.99414264878428457e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.93508080404377051e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.83521259384617315e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              1.71171964575236871e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              1.41104116343702208e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              1.22204326026737544e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              1.09962416389612645e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              1.00190365714363907e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              9.26734036564802409e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              8.74115302159616476e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              8.41899750482972195e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              8.31161233257424101e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              8.41899750482972195e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              8.74115302159616476e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              9.26734036564802409e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              1.00190365714363907e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              1.09962416389612645e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              1.22204326026737544e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              1.36701324281227517e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              1.53453411153082561e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              1.66876557685017633e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              1.79118467322142533e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              1.89856984547690718e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              1.98984724189406552e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              2.06501686247290218e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.12837411410363620e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              2.17669744161860217e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              2.21106069674035552e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              2.23039002774634355e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              2.23790698980422712e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.22716847257867903e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.19495292090203475e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.14126033477429337e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.06609071419545762e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              1.93400695232121598e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              3.32464493302969549e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              3.09484066440296556e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              2.95309223702573036e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              2.86181484060857201e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              2.78664522002973536e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.72758337528922038e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.68677700983213708e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.66207842021337729e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.65456145815549280e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.66207842021337729e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.68677700983213708e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.72758337528922038e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.78664522002973536e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.86181484060857201e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              2.95309223702573036e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.06047740928121220e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.18826576426523388e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              3.31498026752670194e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.42343929150473832e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.51364283619934213e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.58881245677817878e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.64787430151869285e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.68868066697577615e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              3.71337925659453686e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              3.72197007037497497e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              3.71337925659453686e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              3.68868066697577615e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.64787430151869285e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.58881245677817878e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              3.48894424658058142e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              3.36545129848677880e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              3.06477281617143126e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              2.87577491300178372e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              2.75335581663053563e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              2.65563530987804825e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.58046568929921068e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.52784695489402566e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.49563140321738138e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.48489288599183328e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.49563140321738138e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              2.52784695489402566e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              2.58046568929921068e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              2.65563530987804825e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.75335581663053563e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              2.87577491300178372e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              3.02074489554668344e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              3.18826576426523388e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              3.32249722958458551e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              3.44491632595583360e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              3.55230149821131545e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              3.64357889462847470e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              3.71874851520731045e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              3.78210576683804447e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              3.83042909435301135e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              3.86479234947476562e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              3.88412168048075182e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              3.89163864253863539e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              3.88090012531308730e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              3.84868457363644302e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              3.79499198750870255e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              3.71982236692986589e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.58773860505562425e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="9.99910512356453837e+04 2.51548022389700236e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            14 13 15 -1 
            13 12 15 -1 
            12 11 15 -1 
            11 10 15 -1 
            10 9 15 -1 
            9 8 15 -1 
            8 7 15 -1 
            15 7 16 -1 
            7 6 16 -1 
            5 4 6 -1 
            6 4 16 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            21 20 25 -1 
            20 19 25 -1 
            19 18 25 -1 
            25 18 0 -1 
            1 0 2 -1 
            2 0 3 -1 
            3 0 4 -1 
            4 0 16 -1 
            16 0 17 -1 
            0 18 17 -1 
            73 26 74 -1 
            26 55 74 -1 
            41 57 42 -1 
            57 56 42 -1 
            74 55 75 -1 
            75 55 76 -1 
            55 54 76 -1 
            76 54 77 -1 
            77 54 78 -1 
            54 53 78 -1 
            78 53 79 -1 
            53 52 79 -1 
            79 52 80 -1 
            52 51 80 -1 
            80 51 81 -1 
            51 50 81 -1 
            81 50 82 -1 
            82 50 83 -1 
            50 49 83 -1 
            83 49 84 -1 
            49 48 84 -1 
            84 48 85 -1 
            48 47 85 -1 
            85 47 86 -1 
            47 46 86 -1 
            86 46 87 -1 
            42 56 43 -1 
            56 87 43 -1 
            46 45 87 -1 
            45 44 87 -1 
            87 44 43 -1 
            57 41 58 -1 
            73 72 26 -1 
            27 26 72 -1 
            72 71 27 -1 
            27 71 28 -1 
            71 70 28 -1 
            70 69 28 -1 
            41 40 58 -1 
            58 40 59 -1 
            28 69 29 -1 
            69 68 29 -1 
            29 68 30 -1 
            68 67 30 -1 
            30 67 31 -1 
            67 66 31 -1 
            31 66 32 -1 
            66 65 32 -1 
            32 65 33 -1 
            65 64 33 -1 
            33 64 34 -1 
            64 63 34 -1 
            34 63 35 -1 
            63 62 35 -1 
            35 62 36 -1 
            62 61 36 -1 
            36 61 37 -1 
            61 60 37 -1 
            37 60 38 -1 
            60 59 38 -1 
            38 59 39 -1 
            59 40 39 -1 
            135 88 136 -1 
            88 117 136 -1 
            103 119 104 -1 
            119 118 104 -1 
            136 117 137 -1 
            137 117 138 -1 
            117 116 138 -1 
            138 116 139 -1 
            139 116 140 -1 
            116 115 140 -1 
            140 115 141 -1 
            115 114 141 -1 
            141 114 142 -1 
            114 113 142 -1 
            142 113 143 -1 
            113 112 143 -1 
            143 112 144 -1 
            144 112 145 -1 
            112 111 145 -1 
            145 111 146 -1 
            111 110 146 -1 
            146 110 147 -1 
            110 109 147 -1 
            147 109 148 -1 
            109 108 148 -1 
            148 108 149 -1 
            104 118 105 -1 
            118 149 105 -1 
            108 107 149 -1 
            107 106 149 -1 
            149 106 105 -1 
            119 103 120 -1 
            135 134 88 -1 
            89 88 134 -1 
            134 133 89 -1 
            89 133 90 -1 
            133 132 90 -1 
            132 131 90 -1 
            103 102 120 -1 
            120 102 121 -1 
            90 131 91 -1 
            131 130 91 -1 
            91 130 92 -1 
            130 129 92 -1 
            92 129 93 -1 
            129 128 93 -1 
            93 128 94 -1 
            128 127 94 -1 
            94 127 95 -1 
            127 126 95 -1 
            95 126 96 -1 
            126 125 96 -1 
            96 125 97 -1 
            125 124 97 -1 
            97 124 98 -1 
            124 123 98 -1 
            98 123 99 -1 
            123 122 99 -1 
            99 122 100 -1 
            122 121 100 -1 
            100 121 101 -1 
            121 102 101 -1 
            197 150 198 -1 
            150 179 198 -1 
            165 181 166 -1 
            181 180 166 -1 
            198 179 199 -1 
            199 179 200 -1 
            179 178 200 -1 
            200 178 201 -1 
            201 178 202 -1 
            178 177 202 -1 
            202 177 203 -1 
            177 176 203 -1 
            203 176 204 -1 
            176 175 204 -1 
            204 175 205 -1 
            175 174 205 -1 
            205 174 206 -1 
            206 174 207 -1 
            174 173 207 -1 
            207 173 208 -1 
            173 172 208 -1 
            208 172 209 -1 
            172 171 209 -1 
            209 171 210 -1 
            171 170 210 -1 
            210 170 211 -1 
            166 180 167 -1 
            180 211 167 -1 
            170 169 211 -1 
            169 168 211 -1 
            211 168 167 -1 
            181 165 182 -1 
            197 196 150 -1 
            151 150 196 -1 
            196 195 151 -1 
            151 195 152 -1 
            195 194 152 -1 
            194 193 152 -1 
            165 164 182 -1 
            182 164 183 -1 
            152 193 153 -1 
            193 192 153 -1 
            153 192 154 -1 
            192 191 154 -1 
            154 191 155 -1 
            191 190 155 -1 
            155 190 156 -1 
            190 189 156 -1 
            156 189 157 -1 
            189 188 157 -1 
            157 188 158 -1 
            188 187 158 -1 
            158 187 159 -1 
            187 186 159 -1 
            159 186 160 -1 
            186 185 160 -1 
            160 185 161 -1 
            185 184 161 -1 
            161 184 162 -1 
            184 183 162 -1 
            162 183 163 -1 
            183 164 163 -1 
            259 212 260 -1 
            212 241 260 -1 
            227 243 228 -1 
            243 242 228 -1 
            260 241 261 -1 
            261 241 262 -1 
            241 240 262 -1 
            262 240 263 -1 
            263 240 264 -1 
            240 239 264 -1 
            264 239 265 -1 
            239 238 265 -1 
            265 238 266 -1 
            238 237 266 -1 
            266 237 267 -1 
            237 236 267 -1 
            267 236 268 -1 
            268 236 269 -1 
            236 235 269 -1 
            269 235 270 -1 
            235 234 270 -1 
            270 234 271 -1 
            234 233 271 -1 
            271 233 272 -1 
            233 232 272 -1 
            272 232 273 -1 
            228 242 229 -1 
            242 273 229 -1 
            232 231 273 -1 
            231 230 273 -1 
            273 230 229 -1 
            243 227 244 -1 
            259 258 212 -1 
            213 212 258 -1 
            258 257 213 -1 
            213 257 214 -1 
            257 256 214 -1 
            256 255 214 -1 
            227 226 244 -1 
            244 226 245 -1 
            214 255 215 -1 
            255 254 215 -1 
            215 254 216 -1 
            254 253 216 -1 
            216 253 217 -1 
            253 252 217 -1 
            217 252 218 -1 
            252 251 218 -1 
            218 251 219 -1 
            251 250 219 -1 
            219 250 220 -1 
            250 249 220 -1 
            220 249 221 -1 
            249 248 221 -1 
            221 248 222 -1 
            248 247 222 -1 
            222 247 223 -1 
            247 246 223 -1 
            223 246 224 -1 
            246 245 224 -1 
            224 245 225 -1 
            245 226 225 -1 
            321 274 322 -1 
            274 303 322 -1 
            289 305 290 -1 
            305 304 290 -1 
            322 303 323 -1 
            323 303 324 -1 
            303 302 324 -1 
            324 302 325 -1 
            325 302 326 -1 
            302 301 326 -1 
            326 301 327 -1 
            301 300 327 -1 
            327 300 328 -1 
            300 299 328 -1 
            328 299 329 -1 
            299 298 329 -1 
            329 298 330 -1 
            330 298 331 -1 
            298 297 331 -1 
            331 297 332 -1 
            297 296 332 -1 
            332 296 333 -1 
            296 295 333 -1 
            333 295 334 -1 
            295 294 334 -1 
            334 294 335 -1 
            290 304 291 -1 
            304 335 291 -1 
            294 293 335 -1 
            293 292 335 -1 
            335 292 291 -1 
            305 289 306 -1 
            321 320 274 -1 
            275 274 320 -1 
            320 319 275 -1 
            275 319 276 -1 
            319 318 276 -1 
            318 317 276 -1 
            289 288 306 -1 
            306 288 307 -1 
            276 317 277 -1 
            317 316 277 -1 
            277 316 278 -1 
            316 315 278 -1 
            278 315 279 -1 
            315 314 279 -1 
            279 314 280 -1 
            314 313 280 -1 
            280 313 281 -1 
            313 312 281 -1 
            281 312 282 -1 
            312 311 282 -1 
            282 311 283 -1 
            311 310 283 -1 
            283 310 284 -1 
            310 309 284 -1 
            284 309 285 -1 
            309 308 285 -1 
            285 308 286 -1 
            308 307 286 -1 
            286 307 287 -1 
            307 288 287 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0013"
              point="
              -3.95606974589192396e+03 -2.57724413413154707e+01 0.00000000000000000e+00,
              -3.92922345282805372e+03 -6.44311033532903821e+00 0.00000000000000000e+00,
              -3.89486019770629991e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.86157079430710064e+03 -6.44311033532903821e+00 0.00000000000000000e+00,
              -3.83365064952067542e+03 -2.36247378962059429e+01 0.00000000000000000e+00,
              -3.81539517023724375e+03 -4.93971792375214136e+01 0.00000000000000000e+00,
              -3.80895205990191471e+03 -7.94650274690561673e+01 0.00000000000000000e+00,
              -3.80895205990191471e+03 -1.56997121837513419e+03 0.00000000000000000e+00,
              -3.81539517023724375e+03 -1.60111291832922370e+03 0.00000000000000000e+00,
              -3.83365064952067542e+03 -1.62688535967053917e+03 0.00000000000000000e+00,
              -3.86157079430710064e+03 -1.64406698723141608e+03 0.00000000000000000e+00,
              -3.89378634598374492e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -3.92707574938294420e+03 -1.64406698723141608e+03 0.00000000000000000e+00,
              -3.95392204244681443e+03 -1.62688535967053917e+03 0.00000000000000000e+00,
              -3.97325137345280109e+03 -1.60111291832922370e+03 0.00000000000000000e+00,
              -3.97862063206557514e+03 -1.56997121837513419e+03 0.00000000000000000e+00,
              -3.97862063206557514e+03 -2.83496854754470178e+02 0.00000000000000000e+00,
              -4.30614540744479200e+03 -6.16390888746461769e+02 0.00000000000000000e+00,
              -4.33299170050866269e+03 -6.35720219752448429e+02 0.00000000000000000e+00,
              -4.36520725218530697e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -4.39849665558450579e+03 -6.35720219752448429e+02 0.00000000000000000e+00,
              -4.42641680037093101e+03 -6.18538592191571297e+02 0.00000000000000000e+00,
              -4.44574613137691813e+03 -5.93840002572810818e+02 0.00000000000000000e+00,
              -4.45218924171224717e+03 -5.62698302618721073e+02 0.00000000000000000e+00,
              -4.44789383482202720e+03 -5.32630454387186546e+02 0.00000000000000000e+00,
              -4.42963835553859553e+03 -5.05784161323316084e+02 0.00000000000000000e+00,
              -2.72973107873432946e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -2.95953534736105939e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              -3.10128377473829414e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              -3.19256117115545294e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              -3.26773079173428960e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -3.32679263647480457e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -3.36759900193188741e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -3.39229759155064767e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -3.39981455360853170e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -3.39229759155064767e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -3.36759900193188741e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -3.32679263647480457e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -3.26773079173428960e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -3.19256117115545294e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -3.10128377473829414e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -2.99389860248281320e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -2.86611024749879061e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -2.73939574423732302e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -2.63093672025928663e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -2.54073317556468282e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -2.46556355498584617e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -2.40650171024533120e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -2.36569534478824880e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -2.34099675516948764e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -2.33240594138904953e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -2.34099675516948764e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -2.36569534478824880e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -2.40650171024533120e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -2.46556355498584617e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -2.56543176518344353e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              -2.68892471327724661e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -2.98960319559259369e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -3.17860109876224078e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              -3.30102019513348932e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -3.39874070188597670e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -3.47391032246481336e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -3.52652905686999929e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -3.55874460854664358e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -3.56948312577219167e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -3.55874460854664358e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -3.52652905686999929e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              -3.47391032246481336e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              -3.39874070188597670e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -3.30102019513348932e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -3.17860109876224078e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -3.03363111621734106e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              -2.86611024749879061e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.73187878217943944e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              -2.60945968580819044e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              -2.50207451355270950e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              -2.41079711713555071e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              -2.33562749655671360e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -2.27227024492597957e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              -2.22394691741101315e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              -2.18958366228925979e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              -2.17025433128327313e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              -2.16273736922538910e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.17347588645093720e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -2.20569143812758193e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -2.25938402425532195e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -2.33455364483415906e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -2.46663740670840070e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              -1.07599942599992028e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              -1.30580369462664976e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              -1.44755212200388542e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              -1.53882951842104376e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              -1.61399913899988087e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              -1.67306098374039539e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              -1.71386734919747823e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              -1.73856593881623894e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              -1.74608290087412252e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              -1.73856593881623894e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              -1.71386734919747823e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              -1.67306098374039539e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              -1.61399913899988087e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              -1.53882951842104376e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              -1.44755212200388542e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              -1.34016694974840402e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -1.21237859476438143e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              -1.08566409150291429e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              -9.77205067524877904e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              -8.87001522830273643e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              -8.11831902251436986e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              -7.52770057510922015e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              -7.11963692053839623e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              -6.87265102435078916e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              -6.78674288654640350e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              -6.87265102435078916e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              -7.11963692053839623e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              -7.52770057510922015e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              -8.11831902251436986e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              -9.11700112449034350e+02 -2.41616637574832566e+02 0.00000000000000000e+00,
              -1.03519306054283743e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              -1.33587154285818497e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              -1.52486944602783160e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              -1.64728854239908014e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              -1.74500904915156798e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              -1.82017866973040509e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              -1.87279740413559057e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              -1.90501295581223485e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              -1.91575147303778294e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -1.90501295581223485e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              -1.87279740413559057e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              -1.82017866973040509e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              -1.74500904915156798e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              -1.64728854239908014e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              -1.52486944602783160e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -1.37989946348293233e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              -1.21237859476438143e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.07814712944503026e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              -9.55728033073781717e+02 -3.65109585668635646e+01 0.00000000000000000e+00,
              -8.48342860818300778e+02 -8.05388791916109312e+01 0.00000000000000000e+00,
              -7.57065464401141526e+02 -1.41748427377235203e+02 0.00000000000000000e+00,
              -6.81895843822305324e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              -6.18538592191570842e+02 -3.11416999540895404e+02 0.00000000000000000e+00,
              -5.70215264676604420e+02 -4.19876023518931333e+02 0.00000000000000000e+00,
              -5.35852009554850156e+02 -5.41221268167624885e+02 0.00000000000000000e+00,
              -5.16522678548864405e+02 -6.76526585209531163e+02 0.00000000000000000e+00,
              -5.09005716490980376e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -5.19744233716528015e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -5.51959785393173206e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              -6.05652371520913221e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              -6.80821992099750332e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              -8.12905753973991978e+02 -1.53882951842104467e+03 0.00000000000000000e+00,
              5.77732226734488904e+02 -1.66447016995995909e+02 0.00000000000000000e+00,
              3.47927958107758968e+02 -1.76111682498989239e+02 0.00000000000000000e+00,
              2.06179530730523766e+02 -2.02957975562859247e+02 0.00000000000000000e+00,
              1.14902134313364513e+02 -2.50207451355271132e+02 0.00000000000000000e+00,
              3.97325137345278563e+01 -3.18933961598778978e+02 0.00000000000000000e+00,
              -1.93293310059871146e+01 -4.09137506293383240e+02 0.00000000000000000e+00,
              -6.01356964630695074e+01 -5.22965788884192989e+02 0.00000000000000000e+00,
              -8.48342860818302142e+01 -6.61492661093763786e+02 0.00000000000000000e+00,
              -9.23512481397137890e+01 -8.25791974644649827e+02 0.00000000000000000e+00,
              -8.48342860818302142e+01 -9.89017436472981217e+02 0.00000000000000000e+00,
              -6.01356964630695074e+01 -1.12754430868255190e+03 0.00000000000000000e+00,
              -1.93293310059871146e+01 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.97325137345278563e+01 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.14902134313364513e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              2.06179530730523766e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.13564702986004704e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              4.41353057970027294e+02 -1.48298922884819444e+03 0.00000000000000000e+00,
              5.68067561231495347e+02 -1.47439841506775588e+03 0.00000000000000000e+00,
              6.76526585209530822e+02 -1.44647827028133088e+03 0.00000000000000000e+00,
              7.66730129904135538e+02 -1.40030264621147398e+03 0.00000000000000000e+00,
              8.41899750482972195e+02 -1.33157613596796591e+03 0.00000000000000000e+00,
              9.00961595223487166e+02 -1.24137259127336188e+03 0.00000000000000000e+00,
              9.41767960680569558e+02 -1.12754430868255190e+03 0.00000000000000000e+00,
              9.66466550299330265e+02 -9.89017436472981217e+02 0.00000000000000000e+00,
              9.75057364079768377e+02 -8.25791974644649827e+02 0.00000000000000000e+00,
              9.66466550299330265e+02 -6.61492661093763786e+02 0.00000000000000000e+00,
              9.41767960680569558e+02 -5.22965788884192989e+02 0.00000000000000000e+00,
              9.00961595223487166e+02 -4.09137506293383240e+02 0.00000000000000000e+00,
              8.41899750482972195e+02 -3.18933961598778978e+02 0.00000000000000000e+00,
              7.42031540285374831e+02 -2.41616637574832566e+02 0.00000000000000000e+00,
              6.18538592191571297e+02 -1.64943624584419013e+03 0.00000000000000000e+00,
              3.17860109876223760e+02 -1.63547617345097751e+03 0.00000000000000000e+00,
              1.28862206706577126e+02 -1.59359595627133990e+03 0.00000000000000000e+00,
              6.44311033532903821e+00 -1.52486944602783205e+03 0.00000000000000000e+00,
              -9.12773964171592525e+01 -1.43037049444300874e+03 0.00000000000000000e+00,
              -1.66447016995995909e+02 -1.31332065668453424e+03 0.00000000000000000e+00,
              -2.19065751401181842e+02 -1.17264608102985403e+03 0.00000000000000000e+00,
              -2.51281303077826124e+02 -1.00942061920152264e+03 0.00000000000000000e+00,
              -2.62019820303374217e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.51281303077826124e+02 -6.41089478365222362e+02 0.00000000000000000e+00,
              -2.19065751401181842e+02 -4.77864016536891086e+02 0.00000000000000000e+00,
              -1.66447016995995909e+02 -3.37189440882210874e+02 0.00000000000000000e+00,
              -9.12773964171592525e+01 -2.19065751401181615e+02 0.00000000000000000e+00,
              6.44311033532903821e+00 -1.24566799816358298e+02 0.00000000000000000e+00,
              1.28862206706577126e+02 -5.58402895728502244e+01 0.00000000000000000e+00,
              2.73832189251476848e+02 -1.39600723932126130e+01 0.00000000000000000e+00,
              4.41353057970027294e+02 0.00000000000000000e+00 0.00000000000000000e+00,
              5.75584523289378922e+02 -9.66466550299332994e+00 0.00000000000000000e+00,
              6.98003619660627010e+02 -3.65109585668635646e+01 0.00000000000000000e+00,
              8.05388791916107948e+02 -8.05388791916109312e+01 0.00000000000000000e+00,
              8.96666188333267201e+02 -1.41748427377235203e+02 0.00000000000000000e+00,
              9.71835808912104767e+02 -2.19065751401181615e+02 0.00000000000000000e+00,
              1.03519306054283788e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              1.08351638805780476e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              1.11787964317955812e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              1.13720897418554523e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              1.14472593624342881e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              1.13398741901788071e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              1.10177186734123643e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              1.04807928121349596e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              9.72909660634658394e+02 -1.43037049444300874e+03 0.00000000000000000e+00,
              8.40825898760416749e+02 -1.53882951842104467e+03 0.00000000000000000e+00,
              2.23146387946889718e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              2.00165961084216815e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              1.85991118346493295e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              1.76863378704777369e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              1.69346416646893704e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              1.63440232172842298e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              1.59359595627133967e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              1.56889736665257897e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              1.56138040459469539e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              1.56889736665257897e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              1.59359595627133967e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              1.63440232172842298e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              1.69346416646893704e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              1.76863378704777369e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              1.85991118346493295e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              1.96729635572041389e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              2.09508471070443647e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              2.22179921396590362e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              2.33025823794394000e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              2.42046178263854381e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              2.49563140321738047e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              2.55469324795789544e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              2.59549961341497783e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              2.62019820303373854e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              2.62878901681417756e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              2.62019820303373854e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              2.59549961341497783e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              2.55469324795789544e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              2.49563140321738047e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              2.39576319301978310e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              2.27227024492598048e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              1.97159176261063385e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              1.78259385944098631e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              1.66017476306973822e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              1.56245425631724993e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              1.48728463573841327e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              1.43466590133322825e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              1.40245034965658397e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              1.39171183243103496e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              1.40245034965658397e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              1.43466590133322825e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              1.48728463573841327e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              1.56245425631724993e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              1.66017476306973822e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              1.78259385944098631e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              1.92756384198588603e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              2.09508471070443647e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              2.22931617602378810e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              2.35173527239503619e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              2.45912044465051713e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              2.55039784106767547e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              2.62556746164651304e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              2.68892471327724616e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              2.73724804079221394e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              2.77161129591396730e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              2.79094062691995350e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              2.79845758897783799e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              2.78771907175229080e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              2.75550352007564470e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              2.70181093394790514e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              2.62664131336906757e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              2.49455755149482593e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              3.88519553220330727e+03 -1.66447016995995909e+02 0.00000000000000000e+00,
              3.65539126357657733e+03 -1.76111682498989239e+02 0.00000000000000000e+00,
              3.51364283619934213e+03 -2.02957975562859247e+02 0.00000000000000000e+00,
              3.42236543978218378e+03 -2.50207451355271132e+02 0.00000000000000000e+00,
              3.34719581920334622e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              3.28813397446283216e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              3.24732760900574885e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              3.22262901938698906e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              3.21511205732910457e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              3.22262901938698906e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              3.24732760900574885e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              3.28813397446283216e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              3.34719581920334622e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              3.42236543978218378e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              3.51364283619934213e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              3.62102800845482398e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.74881636343884657e+03 -1.48298922884819444e+03 0.00000000000000000e+00,
              3.87553086670031280e+03 -1.47439841506775588e+03 0.00000000000000000e+00,
              3.98398989067835009e+03 -1.44647827028133088e+03 0.00000000000000000e+00,
              4.07419343537295299e+03 -1.40030264621147398e+03 0.00000000000000000e+00,
              4.14936305595179056e+03 -1.33157613596796591e+03 0.00000000000000000e+00,
              4.20842490069230462e+03 -1.24137259127336188e+03 0.00000000000000000e+00,
              4.24923126614938792e+03 -1.12754430868255190e+03 0.00000000000000000e+00,
              4.27392985576814772e+03 -9.89017436472981217e+02 0.00000000000000000e+00,
              4.28252066954858765e+03 -8.25791974644649827e+02 0.00000000000000000e+00,
              4.27392985576814772e+03 -6.61492661093763786e+02 0.00000000000000000e+00,
              4.24923126614938792e+03 -5.22965788884192989e+02 0.00000000000000000e+00,
              4.20842490069230462e+03 -4.09137506293383240e+02 0.00000000000000000e+00,
              4.14936305595179056e+03 -3.18933961598778978e+02 0.00000000000000000e+00,
              4.04949484575419319e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              3.92600189766039057e+03 -1.64943624584419013e+03 0.00000000000000000e+00,
              3.62532341534504303e+03 -1.63547617345097751e+03 0.00000000000000000e+00,
              3.43632551217539549e+03 -1.59359595627133990e+03 0.00000000000000000e+00,
              3.31390641580414740e+03 -1.52486944602783205e+03 0.00000000000000000e+00,
              3.21618590905166002e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              3.14101628847282245e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              3.08839755406763743e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              3.05618200239099315e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              3.04544348516544505e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              3.05618200239099315e+03 -6.41089478365222362e+02 0.00000000000000000e+00,
              3.08839755406763743e+03 -4.77864016536891086e+02 0.00000000000000000e+00,
              3.14101628847282245e+03 -3.37189440882210874e+02 0.00000000000000000e+00,
              3.21618590905166002e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              3.31390641580414740e+03 -1.24566799816358298e+02 0.00000000000000000e+00,
              3.43632551217539549e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              3.58129549472029521e+03 -1.39600723932126130e+01 0.00000000000000000e+00,
              3.74881636343884657e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              3.88304782875819819e+03 -9.66466550299332994e+00 0.00000000000000000e+00,
              4.00546692512944628e+03 -3.65109585668635646e+01 0.00000000000000000e+00,
              4.11285209738492722e+03 -8.05388791916109312e+01 0.00000000000000000e+00,
              4.20412949380208647e+03 -1.41748427377235203e+02 0.00000000000000000e+00,
              4.27929911438092222e+03 -2.19065751401181615e+02 0.00000000000000000e+00,
              4.34265636601165625e+03 -3.11416999540895404e+02 0.00000000000000000e+00,
              4.39097969352662312e+03 -4.19876023518931333e+02 0.00000000000000000e+00,
              4.42534294864837739e+03 -5.41221268167624885e+02 0.00000000000000000e+00,
              4.44467227965436268e+03 -6.76526585209531163e+02 0.00000000000000000e+00,
              4.45218924171224808e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              4.44145072448669907e+03 -1.00942061920152264e+03 0.00000000000000000e+00,
              4.40923517281005388e+03 -1.17264608102985403e+03 0.00000000000000000e+00,
              4.35554258668231432e+03 -1.31332065668453424e+03 0.00000000000000000e+00,
              4.28037296610347676e+03 -1.43037049444300874e+03 0.00000000000000000e+00,
              4.14828920422923602e+03 -1.53882951842104467e+03 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="2.98889022389700258e+04 3.99910512356453764e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 0 -1 
            18 17 19 -1 
            17 16 19 -1 
            19 16 0 -1 
            12 11 13 -1 
            13 11 14 -1 
            11 10 14 -1 
            14 10 15 -1 
            15 10 16 -1 
            16 10 0 -1 
            0 10 1 -1 
            10 9 1 -1 
            8 7 9 -1 
            9 7 1 -1 
            36 35 37 -1 
            35 34 37 -1 
            34 2 37 -1 
            37 2 3 -1 
            2 1 3 -1 
            1 7 3 -1 
            4 3 7 -1 
            4 7 5 -1 
            7 6 5 -1 
            20 0 21 -1 
            28 27 29 -1 
            27 26 29 -1 
            26 25 29 -1 
            25 24 29 -1 
            29 24 30 -1 
            22 21 23 -1 
            23 21 24 -1 
            24 21 30 -1 
            32 31 33 -1 
            33 31 34 -1 
            34 31 2 -1 
            31 30 2 -1 
            2 30 0 -1 
            30 21 0 -1 
            85 38 86 -1 
            38 67 86 -1 
            53 69 54 -1 
            69 68 54 -1 
            86 67 87 -1 
            87 67 88 -1 
            67 66 88 -1 
            88 66 89 -1 
            89 66 90 -1 
            66 65 90 -1 
            90 65 91 -1 
            65 64 91 -1 
            91 64 92 -1 
            64 63 92 -1 
            92 63 93 -1 
            63 62 93 -1 
            93 62 94 -1 
            94 62 95 -1 
            62 61 95 -1 
            95 61 96 -1 
            61 60 96 -1 
            96 60 97 -1 
            60 59 97 -1 
            97 59 98 -1 
            59 58 98 -1 
            98 58 99 -1 
            54 68 55 -1 
            68 99 55 -1 
            58 57 99 -1 
            57 56 99 -1 
            99 56 55 -1 
            69 53 70 -1 
            85 84 38 -1 
            39 38 84 -1 
            84 83 39 -1 
            39 83 40 -1 
            83 82 40 -1 
            82 81 40 -1 
            53 52 70 -1 
            70 52 71 -1 
            40 81 41 -1 
            81 80 41 -1 
            41 80 42 -1 
            80 79 42 -1 
            42 79 43 -1 
            79 78 43 -1 
            43 78 44 -1 
            78 77 44 -1 
            44 77 45 -1 
            77 76 45 -1 
            45 76 46 -1 
            76 75 46 -1 
            46 75 47 -1 
            75 74 47 -1 
            47 74 48 -1 
            74 73 48 -1 
            48 73 49 -1 
            73 72 49 -1 
            49 72 50 -1 
            72 71 50 -1 
            50 71 51 -1 
            71 52 51 -1 
            147 100 148 -1 
            100 129 148 -1 
            115 131 116 -1 
            131 130 116 -1 
            148 129 149 -1 
            149 129 150 -1 
            129 128 150 -1 
            150 128 151 -1 
            151 128 152 -1 
            128 127 152 -1 
            152 127 153 -1 
            127 126 153 -1 
            153 126 154 -1 
            126 125 154 -1 
            154 125 155 -1 
            125 124 155 -1 
            155 124 156 -1 
            156 124 157 -1 
            124 123 157 -1 
            157 123 158 -1 
            123 122 158 -1 
            158 122 159 -1 
            122 121 159 -1 
            159 121 160 -1 
            121 120 160 -1 
            160 120 161 -1 
            116 130 117 -1 
            130 161 117 -1 
            120 119 161 -1 
            119 118 161 -1 
            161 118 117 -1 
            131 115 132 -1 
            147 146 100 -1 
            101 100 146 -1 
            146 145 101 -1 
            101 145 102 -1 
            145 144 102 -1 
            144 143 102 -1 
            115 114 132 -1 
            132 114 133 -1 
            102 143 103 -1 
            143 142 103 -1 
            103 142 104 -1 
            142 141 104 -1 
            104 141 105 -1 
            141 140 105 -1 
            105 140 106 -1 
            140 139 106 -1 
            106 139 107 -1 
            139 138 107 -1 
            107 138 108 -1 
            138 137 108 -1 
            108 137 109 -1 
            137 136 109 -1 
            109 136 110 -1 
            136 135 110 -1 
            110 135 111 -1 
            135 134 111 -1 
            111 134 112 -1 
            134 133 112 -1 
            112 133 113 -1 
            133 114 113 -1 
            209 162 210 -1 
            162 191 210 -1 
            177 193 178 -1 
            193 192 178 -1 
            210 191 211 -1 
            211 191 212 -1 
            191 190 212 -1 
            212 190 213 -1 
            213 190 214 -1 
            190 189 214 -1 
            214 189 215 -1 
            189 188 215 -1 
            215 188 216 -1 
            188 187 216 -1 
            216 187 217 -1 
            187 186 217 -1 
            217 186 218 -1 
            218 186 219 -1 
            186 185 219 -1 
            219 185 220 -1 
            185 184 220 -1 
            220 184 221 -1 
            184 183 221 -1 
            221 183 222 -1 
            183 182 222 -1 
            222 182 223 -1 
            178 192 179 -1 
            192 223 179 -1 
            182 181 223 -1 
            181 180 223 -1 
            223 180 179 -1 
            193 177 194 -1 
            209 208 162 -1 
            163 162 208 -1 
            208 207 163 -1 
            163 207 164 -1 
            207 206 164 -1 
            206 205 164 -1 
            177 176 194 -1 
            194 176 195 -1 
            164 205 165 -1 
            205 204 165 -1 
            165 204 166 -1 
            204 203 166 -1 
            166 203 167 -1 
            203 202 167 -1 
            167 202 168 -1 
            202 201 168 -1 
            168 201 169 -1 
            201 200 169 -1 
            169 200 170 -1 
            200 199 170 -1 
            170 199 171 -1 
            199 198 171 -1 
            171 198 172 -1 
            198 197 172 -1 
            172 197 173 -1 
            197 196 173 -1 
            173 196 174 -1 
            196 195 174 -1 
            174 195 175 -1 
            195 176 175 -1 
            271 224 272 -1 
            224 253 272 -1 
            239 255 240 -1 
            255 254 240 -1 
            272 253 273 -1 
            273 253 274 -1 
            253 252 274 -1 
            274 252 275 -1 
            275 252 276 -1 
            252 251 276 -1 
            276 251 277 -1 
            251 250 277 -1 
            277 250 278 -1 
            250 249 278 -1 
            278 249 279 -1 
            249 248 279 -1 
            279 248 280 -1 
            280 248 281 -1 
            248 247 281 -1 
            281 247 282 -1 
            247 246 282 -1 
            282 246 283 -1 
            246 245 283 -1 
            283 245 284 -1 
            245 244 284 -1 
            284 244 285 -1 
            240 254 241 -1 
            254 285 241 -1 
            244 243 285 -1 
            243 242 285 -1 
            285 242 241 -1 
            255 239 256 -1 
            271 270 224 -1 
            225 224 270 -1 
            270 269 225 -1 
            225 269 226 -1 
            269 268 226 -1 
            268 267 226 -1 
            239 238 256 -1 
            256 238 257 -1 
            226 267 227 -1 
            267 266 227 -1 
            227 266 228 -1 
            266 265 228 -1 
            228 265 229 -1 
            265 264 229 -1 
            229 264 230 -1 
            264 263 230 -1 
            230 263 231 -1 
            263 262 231 -1 
            231 262 232 -1 
            262 261 232 -1 
            232 261 233 -1 
            261 260 233 -1 
            233 260 234 -1 
            260 259 234 -1 
            234 259 235 -1 
            259 258 235 -1 
            235 258 236 -1 
            258 257 236 -1 
            236 257 237 -1 
            257 238 237 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0014"
              point="
              -6.99077471383181910e+03 -1.37453020487015920e+02 0.00000000000000000e+00,
              -6.99077471383181910e+03 5.58402895728501790e+02 0.00000000000000000e+00,
              -7.70595996105332324e+03 -1.37453020487015920e+02 0.00000000000000000e+00,
              -6.97574078971605195e+03 7.98945681580779592e+02 0.00000000000000000e+00,
              -6.94567294148451674e+03 8.18275012586766024e+02 0.00000000000000000e+00,
              -6.90916198291765340e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -6.87479872779589914e+03 8.18275012586766024e+02 0.00000000000000000e+00,
              -6.84687858300947482e+03 8.01093385025889120e+02 0.00000000000000000e+00,
              -6.82754925200348771e+03 7.75320943684573649e+02 0.00000000000000000e+00,
              -6.82110614166815867e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -6.82110614166815867e+03 -1.37453020487015920e+02 0.00000000000000000e+00,
              -6.68902237979391703e+03 -1.37453020487015920e+02 0.00000000000000000e+00,
              -6.66110223500749180e+03 -1.43896130822344730e+02 0.00000000000000000e+00,
              -6.63747749711128563e+03 -1.62151610105776513e+02 0.00000000000000000e+00,
              -6.62136972127296394e+03 -1.88997903169646861e+02 0.00000000000000000e+00,
              -6.61492661093763490e+03 -2.20139603123736379e+02 0.00000000000000000e+00,
              -6.62136972127296394e+03 -2.52355154800380660e+02 0.00000000000000000e+00,
              -6.63747749711128563e+03 -2.79201447864251008e+02 0.00000000000000000e+00,
              -6.66110223500749180e+03 -2.98530778870237555e+02 0.00000000000000000e+00,
              -6.68902237979391703e+03 -3.03900037483011602e+02 0.00000000000000000e+00,
              -6.82110614166815867e+03 -3.03900037483011602e+02 0.00000000000000000e+00,
              -6.82110614166815867e+03 -7.46326947175593887e+02 0.00000000000000000e+00,
              -6.82754925200348771e+03 -7.76394795407128527e+02 0.00000000000000000e+00,
              -6.84580473128691938e+03 -8.02167236748443997e+02 0.00000000000000000e+00,
              -6.87372487607334460e+03 -8.19348864309321016e+02 0.00000000000000000e+00,
              -6.90594042774998888e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -6.93815597942663317e+03 -8.19348864309321016e+02 0.00000000000000000e+00,
              -6.96607612421305839e+03 -8.02167236748443997e+02 0.00000000000000000e+00,
              -6.98540545521904460e+03 -7.76394795407128527e+02 0.00000000000000000e+00,
              -6.99077471383181910e+03 -7.46326947175593887e+02 0.00000000000000000e+00,
              -6.99077471383181910e+03 -3.03900037483011602e+02 0.00000000000000000e+00,
              -7.90462252972596343e+03 -3.03900037483011602e+02 0.00000000000000000e+00,
              -7.93898578484771679e+03 -2.98530778870237555e+02 0.00000000000000000e+00,
              -7.96583207791158748e+03 -2.80275299586805772e+02 0.00000000000000000e+00,
              -7.98408755719501914e+03 -2.54502858245490302e+02 0.00000000000000000e+00,
              -7.98945681580779365e+03 -2.22287306568845906e+02 0.00000000000000000e+00,
              -7.98408755719501914e+03 -1.93293310059866030e+02 0.00000000000000000e+00,
              -7.96690592963414201e+03 -1.68594720441105437e+02 0.00000000000000000e+00,
              -5.52818866771216562e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -5.75799293633889647e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -5.89974136371613076e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -5.99101876013329002e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -6.06618838071212667e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -6.12525022545264073e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -6.16605659090972404e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -6.19075518052848474e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -6.19827214258636832e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -6.19075518052848474e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -6.16605659090972404e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -6.12525022545264073e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -6.06618838071212667e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.99101876013329002e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.89974136371613076e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.79235619146064982e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.66456783647662724e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.53785333321516009e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.42939430923712371e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.33919076454251990e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.26402114396368233e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.20495929922316827e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -5.16415293376608497e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -5.13945434414732426e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -5.13086353036688615e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -5.13945434414732426e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -5.16415293376608497e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -5.20495929922316827e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -5.26402114396368233e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -5.36388935416127970e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.48738230225508323e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -5.78806078457043077e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -5.97705868774007740e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -6.09947778411132549e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -6.19719829086381378e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -6.27236791144265044e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -6.32498664584783637e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -6.35720219752448065e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -6.36794071475002875e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.35720219752448065e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -6.32498664584783637e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -6.27236791144265044e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -6.19719829086381378e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.09947778411132549e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -5.97705868774007740e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -5.83208870519517768e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -5.66456783647662724e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.53033637115727652e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -5.40791727478602661e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -5.30053210253054658e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -5.20925470611338733e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -5.13408508553455067e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -5.07072783390381665e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -5.02240450638884977e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -4.98804125126709732e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -4.96871192026110930e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -4.96119495820322663e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.97193347542877382e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -5.00414902710541810e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -5.05784161323315857e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -5.13301123381199614e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -5.26509499568623778e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -3.87445701497775735e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -4.10426128360448638e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -4.24600971098172158e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -4.41245672797771749e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.47151857271823246e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -4.53702352779407556e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -4.54454048985195914e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -4.53702352779407556e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -4.47151857271823246e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -4.41245672797771749e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -4.24600971098172158e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -4.13862453872624064e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.01083618374221851e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -3.88412168048075091e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -3.77566265650271453e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.68545911180811072e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -3.48572269141291508e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -3.47713187763247697e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -3.48572269141291508e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -3.71015770142687143e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -3.83365064952067405e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -4.13432913183602250e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -4.44574613137691631e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -4.67125499311342719e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -4.70347054479007238e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.71420906201561957e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.70347054479007238e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -4.67125499311342719e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -4.44574613137691631e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -4.17835705246076941e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -4.01083618374221851e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -3.87660471842286734e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -3.75418562205161834e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.64680044979613740e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -3.55552305337897815e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -3.48035343280014149e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -3.41699618116940746e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -3.36867285365444150e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -3.33430959853268723e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -3.31498026752670103e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -3.30746330546881745e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.31820182269436464e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.35041737437100983e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.40410996049875030e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.47927958107758695e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.61136334295182860e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -2.22072536224334726e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -2.45052963087007811e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -2.75872507524330831e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.81778691998382328e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -2.85859328544090567e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.88329187505966729e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.89080883711754996e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -2.88329187505966729e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -2.85859328544090567e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -2.81778691998382328e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.75872507524330831e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.48489288599183146e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -2.23039002774634173e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.12193100376830625e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.03172745907370154e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -1.95655783849486397e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.89749599375434991e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.85668962829726661e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.83199103867850681e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.82340022489806779e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.83199103867850681e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.85668962829726661e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.89749599375434991e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.95655783849486397e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.05642604869246316e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -2.17991899678626578e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.48059747910161332e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -2.66959538227125904e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -2.88973498539499542e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.04973889205566320e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.06047740928121038e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.04973889205566320e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -2.88973498539499542e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -2.66959538227125904e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -2.52462539972636023e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -2.22287306568845725e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -2.10045396931720916e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -1.99306879706172822e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -1.90179140064456897e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.82662178006573231e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.76326452843499828e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -1.71494120092003232e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -1.68057794579827896e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -1.66124861479229185e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -1.65373165273440827e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.66447016995995637e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.69668572163660065e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.75037830776434112e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.82554792834317777e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.95763169021741942e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -5.66993709508940810e+02 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.96797978135669837e+02 6.48606440423105823e+02 0.00000000000000000e+00,
              -9.38546405512905039e+02 6.21760147359235816e+02 0.00000000000000000e+00,
              -1.02982380193006429e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -1.10499342250890095e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.20486163270649831e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.22956022232525902e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.23707718438314259e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.22956022232525902e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.20486163270649831e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.10499342250890095e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.02982380193006429e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -9.38546405512905039e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -8.31161233257424101e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.03372878273401511e+02 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.76658375011934368e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.68199351033897983e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.77995806339294177e+02 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.02826185760457520e+02 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.02957975562860156e+02 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.78259385944099449e+02 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.69668572163660428e+02 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.78259385944099449e+02 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.02957975562860156e+02 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.02826185760457520e+02 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.02694395958054884e+02 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.26187344051857508e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.26865826367204136e+02 -8.10758050528882563e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -1.23600333266058806e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.31117295323942471e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.39600723932125402e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.40674575654680302e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.39600723932125402e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -1.31117295323942471e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -1.23600333266058806e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -8.70893746991951957e+02 8.10758050528882450e+02 0.00000000000000000e+00,
              -7.03372878273401511e+02 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.69141412954049883e+02 8.15053457419101733e+02 0.00000000000000000e+00,
              -4.46722316582801795e+02 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.39337144327320857e+02 7.44179243730484131e+02 0.00000000000000000e+00,
              -2.48059747910160695e+02 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.72890127331324948e+02 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.09532875700591831e+02 5.13301123381199659e+02 0.00000000000000000e+00,
              -6.12095481856240440e+01 4.04842099403163729e+02 0.00000000000000000e+00,
              -2.68462930638706894e+01 2.83496854754470178e+02 0.00000000000000000e+00,
              -7.51696205788448424e+00 1.48191537712563900e+02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.07385172255490033e+01 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.29540689021932849e+01 -3.47927958107758855e+02 0.00000000000000000e+00,
              -9.66466550299328446e+01 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.71816275608770411e+02 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.03900037483012056e+02 -7.14111395498949491e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="2.98889022389700258e+04 5.99910512356453692e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            27 83 28 -1 
            83 82 28 -1 
            17 16 70 -1 
            16 15 70 -1 
            15 14 70 -1 
            14 13 70 -1 
            13 12 70 -1 
            71 70 12 -1 
            12 11 71 -1 
            11 10 71 -1 
            71 10 72 -1 
            10 9 72 -1 
            9 8 72 -1 
            8 7 72 -1 
            7 6 72 -1 
            82 81 28 -1 
            47 46 48 -1 
            48 46 49 -1 
            46 45 49 -1 
            49 45 50 -1 
            45 44 50 -1 
            44 43 50 -1 
            51 50 52 -1 
            52 50 53 -1 
            53 50 54 -1 
            54 50 55 -1 
            50 43 55 -1 
            55 43 56 -1 
            56 43 57 -1 
            43 42 57 -1 
            57 42 58 -1 
            42 41 58 -1 
            58 41 59 -1 
            41 40 59 -1 
            59 40 60 -1 
            40 39 60 -1 
            60 39 61 -1 
            39 38 61 -1 
            61 38 62 -1 
            38 37 62 -1 
            62 37 63 -1 
            37 36 63 -1 
            63 36 64 -1 
            36 35 64 -1 
            64 35 65 -1 
            35 34 65 -1 
            65 34 66 -1 
            34 33 66 -1 
            66 33 67 -1 
            33 32 67 -1 
            67 32 68 -1 
            32 31 68 -1 
            31 30 68 -1 
            28 81 29 -1 
            0 69 30 -1 
            69 68 30 -1 
            1 0 30 -1 
            1 30 2 -1 
            2 30 3 -1 
            3 30 4 -1 
            4 30 5 -1 
            5 30 6 -1 
            29 81 30 -1 
            81 80 30 -1 
            72 6 73 -1 
            73 6 74 -1 
            74 6 75 -1 
            75 6 76 -1 
            76 6 77 -1 
            77 6 78 -1 
            78 6 79 -1 
            79 6 80 -1 
            6 30 80 -1 
            27 26 83 -1 
            26 25 83 -1 
            25 24 83 -1 
            24 23 83 -1 
            17 70 18 -1 
            21 20 22 -1 
            20 19 22 -1 
            22 19 23 -1 
            19 18 23 -1 
            70 95 18 -1 
            95 94 18 -1 
            94 93 18 -1 
            93 92 18 -1 
            92 91 18 -1 
            18 91 23 -1 
            83 23 84 -1 
            84 23 85 -1 
            85 23 86 -1 
            86 23 87 -1 
            87 23 88 -1 
            88 23 89 -1 
            89 23 90 -1 
            23 91 90 -1 
            143 96 144 -1 
            96 125 144 -1 
            111 127 112 -1 
            127 126 112 -1 
            144 125 145 -1 
            145 125 146 -1 
            125 124 146 -1 
            146 124 147 -1 
            147 124 148 -1 
            124 123 148 -1 
            148 123 149 -1 
            123 122 149 -1 
            149 122 150 -1 
            122 121 150 -1 
            150 121 151 -1 
            121 120 151 -1 
            151 120 152 -1 
            152 120 153 -1 
            120 119 153 -1 
            153 119 154 -1 
            119 118 154 -1 
            154 118 155 -1 
            118 117 155 -1 
            155 117 156 -1 
            117 116 156 -1 
            156 116 157 -1 
            112 126 113 -1 
            126 157 113 -1 
            116 115 157 -1 
            115 114 157 -1 
            157 114 113 -1 
            127 111 128 -1 
            143 142 96 -1 
            97 96 142 -1 
            142 141 97 -1 
            97 141 98 -1 
            141 140 98 -1 
            140 139 98 -1 
            111 110 128 -1 
            128 110 129 -1 
            98 139 99 -1 
            139 138 99 -1 
            99 138 100 -1 
            138 137 100 -1 
            100 137 101 -1 
            137 136 101 -1 
            101 136 102 -1 
            136 135 102 -1 
            102 135 103 -1 
            135 134 103 -1 
            103 134 104 -1 
            134 133 104 -1 
            104 133 105 -1 
            133 132 105 -1 
            105 132 106 -1 
            132 131 106 -1 
            106 131 107 -1 
            131 130 107 -1 
            107 130 108 -1 
            130 129 108 -1 
            108 129 109 -1 
            129 110 109 -1 
            205 158 206 -1 
            158 187 206 -1 
            173 189 174 -1 
            189 188 174 -1 
            206 187 207 -1 
            207 187 208 -1 
            187 186 208 -1 
            208 186 209 -1 
            209 186 210 -1 
            186 185 210 -1 
            210 185 211 -1 
            185 184 211 -1 
            211 184 212 -1 
            184 183 212 -1 
            212 183 213 -1 
            183 182 213 -1 
            213 182 214 -1 
            214 182 215 -1 
            182 181 215 -1 
            215 181 216 -1 
            181 180 216 -1 
            216 180 217 -1 
            180 179 217 -1 
            217 179 218 -1 
            179 178 218 -1 
            218 178 219 -1 
            174 188 175 -1 
            188 219 175 -1 
            178 177 219 -1 
            177 176 219 -1 
            219 176 175 -1 
            189 173 190 -1 
            205 204 158 -1 
            159 158 204 -1 
            204 203 159 -1 
            159 203 160 -1 
            203 202 160 -1 
            202 201 160 -1 
            173 172 190 -1 
            190 172 191 -1 
            160 201 161 -1 
            201 200 161 -1 
            161 200 162 -1 
            200 199 162 -1 
            162 199 163 -1 
            199 198 163 -1 
            163 198 164 -1 
            198 197 164 -1 
            164 197 165 -1 
            197 196 165 -1 
            165 196 166 -1 
            196 195 166 -1 
            166 195 167 -1 
            195 194 167 -1 
            167 194 168 -1 
            194 193 168 -1 
            168 193 169 -1 
            193 192 169 -1 
            169 192 170 -1 
            192 191 170 -1 
            170 191 171 -1 
            191 172 171 -1 
            267 220 268 -1 
            220 249 268 -1 
            235 251 236 -1 
            251 250 236 -1 
            268 249 269 -1 
            269 249 270 -1 
            249 248 270 -1 
            270 248 271 -1 
            271 248 272 -1 
            248 247 272 -1 
            272 247 273 -1 
            247 246 273 -1 
            273 246 274 -1 
            246 245 274 -1 
            274 245 275 -1 
            245 244 275 -1 
            275 244 276 -1 
            276 244 277 -1 
            244 243 277 -1 
            277 243 278 -1 
            243 242 278 -1 
            278 242 279 -1 
            242 241 279 -1 
            279 241 280 -1 
            241 240 280 -1 
            280 240 281 -1 
            236 250 237 -1 
            250 281 237 -1 
            240 239 281 -1 
            239 238 281 -1 
            281 238 237 -1 
            251 235 252 -1 
            267 266 220 -1 
            221 220 266 -1 
            266 265 221 -1 
            221 265 222 -1 
            265 264 222 -1 
            264 263 222 -1 
            235 234 252 -1 
            252 234 253 -1 
            222 263 223 -1 
            263 262 223 -1 
            223 262 224 -1 
            262 261 224 -1 
            224 261 225 -1 
            261 260 225 -1 
            225 260 226 -1 
            260 259 226 -1 
            226 259 227 -1 
            259 258 227 -1 
            227 258 228 -1 
            258 257 228 -1 
            228 257 229 -1 
            257 256 229 -1 
            229 256 230 -1 
            256 255 230 -1 
            230 255 231 -1 
            255 254 231 -1 
            231 254 232 -1 
            254 253 232 -1 
            232 253 233 -1 
            253 234 233 -1 
            329 282 330 -1 
            282 311 330 -1 
            297 313 298 -1 
            313 312 298 -1 
            330 311 331 -1 
            331 311 332 -1 
            311 310 332 -1 
            332 310 333 -1 
            333 310 334 -1 
            310 309 334 -1 
            334 309 335 -1 
            309 308 335 -1 
            335 308 336 -1 
            308 307 336 -1 
            336 307 337 -1 
            307 306 337 -1 
            337 306 338 -1 
            338 306 339 -1 
            306 305 339 -1 
            339 305 340 -1 
            305 304 340 -1 
            340 304 341 -1 
            304 303 341 -1 
            341 303 342 -1 
            303 302 342 -1 
            342 302 343 -1 
            298 312 299 -1 
            312 343 299 -1 
            302 301 343 -1 
            301 300 343 -1 
            343 300 299 -1 
            313 297 314 -1 
            329 328 282 -1 
            283 282 328 -1 
            328 327 283 -1 
            283 327 284 -1 
            327 326 284 -1 
            326 325 284 -1 
            297 296 314 -1 
            314 296 315 -1 
            284 325 285 -1 
            325 324 285 -1 
            285 324 286 -1 
            324 323 286 -1 
            286 323 287 -1 
            323 322 287 -1 
            287 322 288 -1 
            322 321 288 -1 
            288 321 289 -1 
            321 320 289 -1 
            289 320 290 -1 
            320 319 290 -1 
            290 319 291 -1 
            319 318 291 -1 
            291 318 292 -1 
            318 317 292 -1 
            292 317 293 -1 
            317 316 293 -1 
            293 316 294 -1 
            316 315 294 -1 
            294 315 295 -1 
            315 296 295 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0015"
              point="
              -7.73065855067208486e+03 -3.54371068443088006e+01 0.00000000000000000e+00,
              -7.62112567497149394e+03 3.65109585668635646e+01 0.00000000000000000e+00,
              -7.48904191309725229e+03 9.12773964171589114e+01 0.00000000000000000e+00,
              -7.34621963399746255e+03 1.25640651538912948e+02 0.00000000000000000e+00,
              -7.20124965145256283e+03 1.37453020487015806e+02 0.00000000000000000e+00,
              -7.08849522058430739e+03 1.27788354984022476e+02 0.00000000000000000e+00,
              -6.98003619660627191e+03 9.98682101975974774e+01 0.00000000000000000e+00,
              -6.88124183813122909e+03 5.58402895728501107e+01 0.00000000000000000e+00,
              -6.79748140377195341e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.72982874525100124e+03 -7.08742136886176013e+01 0.00000000000000000e+00,
              -6.67613615912325986e+03 -1.53560796325338060e+02 0.00000000000000000e+00,
              -6.64284675572406104e+03 -2.45912044465051849e+02 0.00000000000000000e+00,
              -6.63103438677595841e+03 -3.43632551217539628e+02 0.00000000000000000e+00,
              -6.64284675572406104e+03 -4.41353057970027464e+02 0.00000000000000000e+00,
              -6.67613615912325986e+03 -5.33704306109741196e+02 0.00000000000000000e+00,
              -6.72982874525100124e+03 -6.17464740469016533e+02 0.00000000000000000e+00,
              -6.79748140377195341e+03 -6.87265102435079257e+02 0.00000000000000000e+00,
              -6.88124183813122909e+03 -7.44179243730484245e+02 0.00000000000000000e+00,
              -6.98003619660627191e+03 -7.88207164355231498e+02 0.00000000000000000e+00,
              -7.08956907230686284e+03 -8.16127309141656610e+02 0.00000000000000000e+00,
              -7.20232350317511737e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -7.32581645126892090e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -7.44823554764016990e+03 -7.67803981626690074e+02 0.00000000000000000e+00,
              -7.56421153367608895e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -7.66730129904135083e+03 -6.14243185301352014e+02 0.00000000000000000e+00,
              -7.75320943684573558e+03 -5.14374975103754650e+02 0.00000000000000000e+00,
              -7.81549283675391507e+03 -4.04842099403163843e+02 0.00000000000000000e+00,
              -7.84126527809523031e+03 -3.39337144327320345e+02 0.00000000000000000e+00,
              -7.86381616426888104e+03 -2.58798265135709585e+02 0.00000000000000000e+00,
              -7.87025927460421008e+03 -2.41616637574832566e+02 0.00000000000000000e+00,
              -7.87240697804932006e+03 -2.23361158291400784e+02 0.00000000000000000e+00,
              -7.89388401250041625e+03 -1.05237468810371524e+02 0.00000000000000000e+00,
              -7.90032712283574529e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.89495786422297078e+03 9.87943584750425998e+01 0.00000000000000000e+00,
              -7.87670238493953912e+03 2.08327234175633293e+02 0.00000000000000000e+00,
              -7.84878224015311389e+03 3.13564702986004932e+02 0.00000000000000000e+00,
              -7.81441898503135963e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -7.75106173340062651e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -7.66407974387368631e+03 6.14243185301352014e+02 0.00000000000000000e+00,
              -7.55991612678586989e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -7.44179243730484086e+03 7.66730129904135083e+02 0.00000000000000000e+00,
              -7.31829948921103733e+03 8.09684198806327458e+02 0.00000000000000000e+00,
              -7.19373268939467926e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -7.04339344823700594e+03 8.12905753973991978e+02 0.00000000000000000e+00,
              -6.88983265191166720e+03 7.79616350574792932e+02 0.00000000000000000e+00,
              -6.75130577970209742e+03 7.26997616169607227e+02 0.00000000000000000e+00,
              -6.64499445916917102e+03 6.61492661093763672e+02 0.00000000000000000e+00,
              -6.62459127644062937e+03 6.36794071475002966e+02 0.00000000000000000e+00,
              -6.61492661093763581e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.61814816610530033e+03 5.72362968121714403e+02 0.00000000000000000e+00,
              -6.63425594194362202e+03 5.44442823335289177e+02 0.00000000000000000e+00,
              -6.65895453156238364e+03 5.22965788884192989e+02 0.00000000000000000e+00,
              -6.69117008323902701e+03 5.14374975103754650e+02 0.00000000000000000e+00,
              -6.72445948663822674e+03 5.16522678548864178e+02 0.00000000000000000e+00,
              -6.75237963142465196e+03 5.32630454387186319e+02 0.00000000000000000e+00,
              -6.82969695544859860e+03 5.76658375011933458e+02 0.00000000000000000e+00,
              -6.94567294148451765e+03 6.18538592191571297e+02 0.00000000000000000e+00,
              -7.07560899991365022e+03 6.47532588700551287e+02 0.00000000000000000e+00,
              -7.19373268939467926e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.27427156858629041e+03 6.47532588700551287e+02 0.00000000000000000e+00,
              -7.36125355811322970e+03 6.16390888746461542e+02 0.00000000000000000e+00,
              -7.45038325108527897e+03 5.65919857786385592e+02 0.00000000000000000e+00,
              -7.53521753716710919e+03 4.99341050987987273e+02 0.00000000000000000e+00,
              -7.60716560257828132e+03 4.22023726964040861e+02 0.00000000000000000e+00,
              -7.65871048526091272e+03 3.38263292604765411e+02 0.00000000000000000e+00,
              -7.68555677832478250e+03 2.66315227193593046e+02 0.00000000000000000e+00,
              -7.70918151622098867e+03 1.72890127331324493e+02 0.00000000000000000e+00,
              -7.72528929205931036e+03 7.51696205788367706e+01 0.00000000000000000e+00,
              -7.73065855067208486e+03 -4.29540689021916933e+00 0.00000000000000000e+00,
              -7.73065855067208486e+03 -2.36247378962059429e+01 0.00000000000000000e+00,
              -6.80070295893961793e+03 -4.04842099403163843e+02 0.00000000000000000e+00,
              -6.80929377272005695e+03 -2.99604630592792319e+02 0.00000000000000000e+00,
              -6.83184465889370767e+03 -2.25508861736510426e+02 0.00000000000000000e+00,
              -6.86728176573801647e+03 -1.71816275608769843e+02 0.00000000000000000e+00,
              -6.91453124153042791e+03 -1.24566799816358184e+02 0.00000000000000000e+00,
              -6.97251923454838834e+03 -8.59081378043849782e+01 0.00000000000000000e+00,
              -7.04231959651445050e+03 -5.58402895728502244e+01 0.00000000000000000e+00,
              -7.12071077226095167e+03 -3.65109585668636782e+01 0.00000000000000000e+00,
              -7.20339735489767281e+03 -2.89939965089799898e+01 0.00000000000000000e+00,
              -7.35266274433279159e+03 -4.51017723473021306e+01 0.00000000000000000e+00,
              -7.49763272687769040e+03 -9.12773964171590251e+01 0.00000000000000000e+00,
              -7.61897797152638486e+03 -1.57856203215557343e+02 0.00000000000000000e+00,
              -7.69629529555033059e+03 -2.31951972071839236e+02 0.00000000000000000e+00,
              -7.67803981626689892e+03 -2.94235371980018385e+02 0.00000000000000000e+00,
              -7.65978433698346726e+03 -3.40410996049875223e+02 0.00000000000000000e+00,
              -7.60823945430083586e+03 -4.24171430409150503e+02 0.00000000000000000e+00,
              -7.53736524061221917e+03 -5.00414902710542094e+02 0.00000000000000000e+00,
              -7.45467865797549803e+03 -5.66993709508940356e+02 0.00000000000000000e+00,
              -7.36769666844855874e+03 -6.16390888746461769e+02 0.00000000000000000e+00,
              -7.28178853064417399e+03 -6.48606440423106051e+02 0.00000000000000000e+00,
              -7.20339735489767281e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -7.12071077226095167e+03 -6.51827995590770456e+02 0.00000000000000000e+00,
              -7.04231959651445050e+03 -6.32498664584783910e+02 0.00000000000000000e+00,
              -6.97251923454838834e+03 -6.02430816353249156e+02 0.00000000000000000e+00,
              -6.91453124153042791e+03 -5.62698302618721073e+02 0.00000000000000000e+00,
              -6.85976480368013290e+03 -5.02562606155651679e+02 0.00000000000000000e+00,
              -5.52818866771216744e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -5.75799293633889647e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -5.89974136371613167e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -5.99101876013329093e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -6.06618838071212758e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -6.12525022545264255e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -6.16605659090972495e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -6.19075518052848565e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -6.19827214258636923e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -6.19075518052848565e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -6.16605659090972495e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -6.12525022545264255e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -6.06618838071212758e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.99101876013329093e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.89974136371613167e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.79235619146065073e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.66456783647662814e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.53785333321516009e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.42939430923712462e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.33919076454251990e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.26402114396368324e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.20495929922316918e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -5.16415293376608588e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -5.13945434414732517e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -5.13086353036688706e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -5.13945434414732517e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -5.16415293376608588e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -5.20495929922316918e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -5.26402114396368324e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -5.36388935416128152e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.48738230225508414e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -5.78806078457043077e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -5.97705868774007831e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -6.09947778411132640e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -6.19719829086381469e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -6.27236791144265135e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -6.35720219752448156e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -6.36794071475002966e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.35720219752448156e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -6.27236791144265135e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -6.19719829086381469e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.09947778411132640e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -5.97705868774007831e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -5.83208870519517859e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -5.66456783647662814e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.53033637115727652e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -5.40791727478602843e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -5.30053210253054749e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -5.20925470611338824e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -5.13408508553455067e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -5.07072783390381755e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -5.02240450638885159e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -4.98804125126709732e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -4.96871192026111021e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -4.96119495820322663e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.97193347542877382e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -5.00414902710541901e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -5.05784161323316039e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -5.13301123381199614e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -5.26509499568623869e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -3.87445701497775781e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -4.10426128360448729e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -4.53702352779407647e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -4.54454048985196005e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -4.53702352779407647e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -4.13862453872624064e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -3.88412168048075137e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -3.77566265650271544e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.68545911180811072e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -3.48572269141291599e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -3.47713187763247788e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -3.48572269141291599e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -3.71015770142687143e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -3.83365064952067496e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -4.13432913183602250e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -4.44574613137691722e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -4.70347054479007238e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.71420906201561957e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.70347054479007238e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -4.44574613137691722e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -4.17835705246076941e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -3.87660471842286734e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -3.75418562205161925e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.64680044979613831e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -3.55552305337897906e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -3.48035343280014240e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -3.41699618116940837e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -3.36867285365444150e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -3.33430959853268723e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -3.31498026752670194e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -3.30746330546881745e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.31820182269436555e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.35041737437101074e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.40410996049875030e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.47927958107758786e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.61136334295182951e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -2.22072536224334908e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -2.45052963087007811e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.81778691998382419e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.88329187505966729e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.89080883711755087e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -2.88329187505966729e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -2.81778691998382419e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.48489288599183237e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -2.23039002774634173e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.12193100376830535e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.03172745907370154e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -1.95655783849486488e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.85668962829726752e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.83199103867850681e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.82340022489806779e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.83199103867850681e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.85668962829726752e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.95655783849486488e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.05642604869246225e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -2.17991899678626669e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.48059747910161332e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.04973889205566320e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.06047740928121129e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.04973889205566320e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -2.52462539972636023e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -2.22287306568845906e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -2.10045396931721007e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -1.99306879706172822e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -1.90179140064456988e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.82662178006573322e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.76326452843499919e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -1.71494120092003322e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -1.68057794579827896e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -1.66124861479229185e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -1.65373165273440827e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.66447016995995637e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.69668572163660065e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.75037830776434203e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.82554792834317777e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.95763169021741942e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -5.66993709508939901e+02 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.96797978135668927e+02 6.48606440423105823e+02 0.00000000000000000e+00,
              -9.38546405512904130e+02 6.21760147359235816e+02 0.00000000000000000e+00,
              -1.02982380193006338e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.20486163270649740e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.22956022232525811e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.23707718438314168e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.22956022232525811e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.20486163270649740e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.02982380193006338e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -9.38546405512904130e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -8.31161233257423191e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.03372878273400602e+02 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.76658375011933458e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.68199351033897074e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.77995806339293267e+02 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.02826185760456610e+02 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.02957975562859247e+02 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.78259385944098540e+02 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.69668572163659519e+02 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.78259385944098540e+02 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.02957975562859247e+02 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.02826185760456610e+02 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.02694395958053974e+02 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.26187344051856599e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.26865826367204136e+02 -8.10758050528882563e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -1.23600333266058715e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.31117295323942380e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.39600723932125402e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.40674575654680211e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.39600723932125402e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -1.31117295323942380e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -1.23600333266058715e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -8.70893746991951048e+02 8.10758050528882450e+02 0.00000000000000000e+00,
              -7.03372878273400602e+02 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.69141412954049883e+02 8.15053457419101733e+02 0.00000000000000000e+00,
              -4.46722316582801795e+02 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.39337144327319947e+02 7.44179243730484131e+02 0.00000000000000000e+00,
              -2.48059747910160695e+02 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.72890127331324038e+02 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.09532875700590921e+02 5.13301123381199659e+02 0.00000000000000000e+00,
              -6.12095481856240440e+01 4.04842099403163729e+02 0.00000000000000000e+00,
              -2.68462930638697799e+01 2.83496854754470178e+02 0.00000000000000000e+00,
              -7.51696205788357474e+00 1.48191537712563900e+02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.07385172255480938e+01 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.29540689021923754e+01 -3.47927958107758855e+02 0.00000000000000000e+00,
              -9.66466550299328446e+01 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.71816275608769502e+02 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.03900037483011147e+02 -7.14111395498949491e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="2.98889022389700258e+04 7.99910512356453692e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            145 98 146 -1 
            98 127 146 -1 
            113 129 114 -1 
            129 128 114 -1 
            146 127 147 -1 
            147 127 148 -1 
            127 126 148 -1 
            148 126 149 -1 
            149 126 150 -1 
            126 125 150 -1 
            150 125 151 -1 
            125 124 151 -1 
            151 124 152 -1 
            124 123 152 -1 
            152 123 153 -1 
            123 122 153 -1 
            153 122 154 -1 
            154 122 155 -1 
            122 121 155 -1 
            155 121 156 -1 
            121 120 156 -1 
            156 120 157 -1 
            120 119 157 -1 
            157 119 158 -1 
            119 118 158 -1 
            158 118 159 -1 
            114 128 115 -1 
            128 159 115 -1 
            118 117 159 -1 
            117 116 159 -1 
            159 116 115 -1 
            129 113 130 -1 
            145 144 98 -1 
            99 98 144 -1 
            144 143 99 -1 
            99 143 100 -1 
            143 142 100 -1 
            142 141 100 -1 
            113 112 130 -1 
            130 112 131 -1 
            100 141 101 -1 
            141 140 101 -1 
            101 140 102 -1 
            140 139 102 -1 
            102 139 103 -1 
            139 138 103 -1 
            103 138 104 -1 
            138 137 104 -1 
            104 137 105 -1 
            137 136 105 -1 
            105 136 106 -1 
            136 135 106 -1 
            106 135 107 -1 
            135 134 107 -1 
            107 134 108 -1 
            134 133 108 -1 
            108 133 109 -1 
            133 132 109 -1 
            109 132 110 -1 
            132 131 110 -1 
            110 131 111 -1 
            131 112 111 -1 
            207 160 208 -1 
            160 189 208 -1 
            175 191 176 -1 
            191 190 176 -1 
            208 189 209 -1 
            209 189 210 -1 
            189 188 210 -1 
            210 188 211 -1 
            211 188 212 -1 
            188 187 212 -1 
            212 187 213 -1 
            187 186 213 -1 
            213 186 214 -1 
            186 185 214 -1 
            214 185 215 -1 
            185 184 215 -1 
            215 184 216 -1 
            216 184 217 -1 
            184 183 217 -1 
            217 183 218 -1 
            183 182 218 -1 
            218 182 219 -1 
            182 181 219 -1 
            219 181 220 -1 
            181 180 220 -1 
            220 180 221 -1 
            176 190 177 -1 
            190 221 177 -1 
            180 179 221 -1 
            179 178 221 -1 
            221 178 177 -1 
            191 175 192 -1 
            207 206 160 -1 
            161 160 206 -1 
            206 205 161 -1 
            161 205 162 -1 
            205 204 162 -1 
            204 203 162 -1 
            175 174 192 -1 
            192 174 193 -1 
            162 203 163 -1 
            203 202 163 -1 
            163 202 164 -1 
            202 201 164 -1 
            164 201 165 -1 
            201 200 165 -1 
            165 200 166 -1 
            200 199 166 -1 
            166 199 167 -1 
            199 198 167 -1 
            167 198 168 -1 
            198 197 168 -1 
            168 197 169 -1 
            197 196 169 -1 
            169 196 170 -1 
            196 195 170 -1 
            170 195 171 -1 
            195 194 171 -1 
            171 194 172 -1 
            194 193 172 -1 
            172 193 173 -1 
            193 174 173 -1 
            269 222 270 -1 
            222 251 270 -1 
            237 253 238 -1 
            253 252 238 -1 
            270 251 271 -1 
            271 251 272 -1 
            251 250 272 -1 
            272 250 273 -1 
            273 250 274 -1 
            250 249 274 -1 
            274 249 275 -1 
            249 248 275 -1 
            275 248 276 -1 
            248 247 276 -1 
            276 247 277 -1 
            247 246 277 -1 
            277 246 278 -1 
            278 246 279 -1 
            246 245 279 -1 
            279 245 280 -1 
            245 244 280 -1 
            280 244 281 -1 
            244 243 281 -1 
            281 243 282 -1 
            243 242 282 -1 
            282 242 283 -1 
            238 252 239 -1 
            252 283 239 -1 
            242 241 283 -1 
            241 240 283 -1 
            283 240 239 -1 
            253 237 254 -1 
            269 268 222 -1 
            223 222 268 -1 
            268 267 223 -1 
            223 267 224 -1 
            267 266 224 -1 
            266 265 224 -1 
            237 236 254 -1 
            254 236 255 -1 
            224 265 225 -1 
            265 264 225 -1 
            225 264 226 -1 
            264 263 226 -1 
            226 263 227 -1 
            263 262 227 -1 
            227 262 228 -1 
            262 261 228 -1 
            228 261 229 -1 
            261 260 229 -1 
            229 260 230 -1 
            260 259 230 -1 
            230 259 231 -1 
            259 258 231 -1 
            231 258 232 -1 
            258 257 232 -1 
            232 257 233 -1 
            257 256 233 -1 
            233 256 234 -1 
            256 255 234 -1 
            234 255 235 -1 
            255 236 235 -1 
            331 284 332 -1 
            284 313 332 -1 
            299 315 300 -1 
            315 314 300 -1 
            332 313 333 -1 
            333 313 334 -1 
            313 312 334 -1 
            334 312 335 -1 
            335 312 336 -1 
            312 311 336 -1 
            336 311 337 -1 
            311 310 337 -1 
            337 310 338 -1 
            310 309 338 -1 
            338 309 339 -1 
            309 308 339 -1 
            339 308 340 -1 
            340 308 341 -1 
            308 307 341 -1 
            341 307 342 -1 
            307 306 342 -1 
            342 306 343 -1 
            306 305 343 -1 
            343 305 344 -1 
            305 304 344 -1 
            344 304 345 -1 
            300 314 301 -1 
            314 345 301 -1 
            304 303 345 -1 
            303 302 345 -1 
            345 302 301 -1 
            315 299 316 -1 
            331 330 284 -1 
            285 284 330 -1 
            330 329 285 -1 
            285 329 286 -1 
            329 328 286 -1 
            328 327 286 -1 
            299 298 316 -1 
            316 298 317 -1 
            286 327 287 -1 
            327 326 287 -1 
            287 326 288 -1 
            326 325 288 -1 
            288 325 289 -1 
            325 324 289 -1 
            289 324 290 -1 
            324 323 290 -1 
            290 323 291 -1 
            323 322 291 -1 
            291 322 292 -1 
            322 321 292 -1 
            292 321 293 -1 
            321 320 293 -1 
            293 320 294 -1 
            320 319 294 -1 
            294 319 295 -1 
            319 318 295 -1 
            295 318 296 -1 
            318 317 296 -1 
            296 317 297 -1 
            317 298 297 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0016"
              point="
              -7.61360871291361127e+03 4.51017723473020624e+02 0.00000000000000000e+00,
              -7.61146100946850129e+03 3.59740327055861826e+02 0.00000000000000000e+00,
              -7.60179634396550864e+03 3.02826185760456610e+02 0.00000000000000000e+00,
              -7.58568856812718604e+03 2.64167523748483518e+02 0.00000000000000000e+00,
              -7.55991612678587080e+03 2.30878120349284245e+02 0.00000000000000000e+00,
              -7.52662672338667107e+03 2.00810272117749719e+02 0.00000000000000000e+00,
              -7.48367265448447870e+03 1.78259385944098653e+02 0.00000000000000000e+00,
              -7.42998006835673823e+03 1.61077758383221635e+02 0.00000000000000000e+00,
              -7.36554896500344967e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -7.28823164097950394e+03 1.39600723932125334e+02 0.00000000000000000e+00,
              -7.19802809628489922e+03 1.37453020487015806e+02 0.00000000000000000e+00,
              -7.08956907230686375e+03 1.40674575654680211e+02 0.00000000000000000e+00,
              -6.99936552761225903e+03 1.53560796325337947e+02 0.00000000000000000e+00,
              -6.92741746220108689e+03 1.73963979053879370e+02 0.00000000000000000e+00,
              -6.87157717262823644e+03 2.02957975562859247e+02 0.00000000000000000e+00,
              -6.83184465889370858e+03 2.39468934129722811e+02 0.00000000000000000e+00,
              -6.80499836582983880e+03 2.82423003031915187e+02 0.00000000000000000e+00,
              -6.78996444171407074e+03 3.33967885714546355e+02 0.00000000000000000e+00,
              -6.78459518310129715e+03 3.97325137345280154e+02 0.00000000000000000e+00,
              -6.78781673826896167e+03 4.46722316582801341e+02 0.00000000000000000e+00,
              -6.79748140377195432e+03 4.90750237207548707e+02 0.00000000000000000e+00,
              -6.81358917961027691e+03 5.29408899219521800e+02 0.00000000000000000e+00,
              -6.83828776922903762e+03 5.63772154341275836e+02 0.00000000000000000e+00,
              -6.87265102435079098e+03 5.92766150850255826e+02 0.00000000000000000e+00,
              -6.91560509325298426e+03 6.16390888746461542e+02 0.00000000000000000e+00,
              -6.96929767938072473e+03 6.33572516307338446e+02 0.00000000000000000e+00,
              -7.03372878273401329e+03 6.46458736977996296e+02 0.00000000000000000e+00,
              -7.10997225503540449e+03 6.55049550758434862e+02 0.00000000000000000e+00,
              -7.19910194800745376e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.28930549270205847e+03 6.55049550758434862e+02 0.00000000000000000e+00,
              -7.36554896500344967e+03 6.46458736977996296e+02 0.00000000000000000e+00,
              -7.42998006835673823e+03 6.33572516307338446e+02 0.00000000000000000e+00,
              -7.48367265448447870e+03 6.16390888746461542e+02 0.00000000000000000e+00,
              -7.52662672338667107e+03 5.92766150850255826e+02 0.00000000000000000e+00,
              -7.55991612678587080e+03 5.63772154341275836e+02 0.00000000000000000e+00,
              -7.58783627157229603e+03 5.18670381993973706e+02 0.00000000000000000e+00,
              -7.58354086468207606e+03 5.04710309600760638e+01 0.00000000000000000e+00,
              -7.67052285420901626e+03 1.12754430868255213e+02 0.00000000000000000e+00,
              -7.73280625411719484e+03 1.92219458337311266e+02 0.00000000000000000e+00,
              -7.77146491612916816e+03 2.87792261644689461e+02 0.00000000000000000e+00,
              -7.78327728507727170e+03 4.00546692512944446e+02 0.00000000000000000e+00,
              -7.76609565751639457e+03 5.28335047496967036e+02 0.00000000000000000e+00,
              -7.71347692311120863e+03 6.35720219752448202e+02 0.00000000000000000e+00,
              -7.62864263702937842e+03 7.18406802389168661e+02 0.00000000000000000e+00,
              -7.51266665099345937e+03 7.77468647129683177e+02 0.00000000000000000e+00,
              -7.36984437189366872e+03 8.12905753973991978e+02 0.00000000000000000e+00,
              -7.19910194800745376e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -7.02835952412123879e+03 8.12905753973991978e+02 0.00000000000000000e+00,
              -6.88553724502144905e+03 7.77468647129683177e+02 0.00000000000000000e+00,
              -6.77063511070808454e+03 7.18406802389168661e+02 0.00000000000000000e+00,
              -6.68580082462625433e+03 6.34646368029893438e+02 0.00000000000000000e+00,
              -6.63318209022106839e+03 5.27261195774412272e+02 0.00000000000000000e+00,
              -6.61492661093763672e+03 3.98398989067834918e+02 0.00000000000000000e+00,
              -6.62781283160829480e+03 2.85644558199579706e+02 0.00000000000000000e+00,
              -6.66647149362026721e+03 1.88997903169646634e+02 0.00000000000000000e+00,
              -6.72982874525100124e+03 1.08459023978035930e+02 0.00000000000000000e+00,
              -6.81466303133283145e+03 4.72494757924116584e+01 0.00000000000000000e+00,
              -6.72875489352844670e+03 -2.36247378962059429e+01 0.00000000000000000e+00,
              -6.66539764189771267e+03 -1.12754430868255213e+02 0.00000000000000000e+00,
              -6.62781283160829480e+03 -2.21213454846291143e+02 0.00000000000000000e+00,
              -6.61492661093763672e+03 -3.45780254662649213e+02 0.00000000000000000e+00,
              -6.65358527294961004e+03 -5.51959785393172979e+02 0.00000000000000000e+00,
              -6.76633970381786548e+03 -7.02299026550846520e+02 0.00000000000000000e+00,
              -6.94996834837473762e+03 -7.94650274690560309e+02 0.00000000000000000e+00,
              -7.19910194800745376e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -7.44823554764016990e+03 -7.94650274690560309e+02 0.00000000000000000e+00,
              -7.63186419219704294e+03 -7.01225174828291756e+02 0.00000000000000000e+00,
              -7.74569247478785292e+03 -5.50885933670618215e+02 0.00000000000000000e+00,
              -7.78327728507727170e+03 -3.43632551217539628e+02 0.00000000000000000e+00,
              -7.77146491612916816e+03 -2.17991899678626737e+02 0.00000000000000000e+00,
              -7.73280625411719484e+03 -1.09532875700590807e+02 0.00000000000000000e+00,
              -7.67052285420901626e+03 -1.93293310059866599e+01 0.00000000000000000e+00,
              -7.08205211024897926e+03 -2.89939965089799898e+01 0.00000000000000000e+00,
              -7.27749312375395493e+03 -3.43632551217539230e+01 0.00000000000000000e+00,
              -7.39454296151242943e+03 -4.72494757924116584e+01 0.00000000000000000e+00,
              -7.46541717520104703e+03 -7.08742136886176013e+01 0.00000000000000000e+00,
              -7.52233131649645202e+03 -1.05237468810371524e+02 0.00000000000000000e+00,
              -7.56421153367608986e+03 -1.49265389435118777e+02 0.00000000000000000e+00,
              -7.59213167846251508e+03 -2.01884123840304596e+02 0.00000000000000000e+00,
              -7.60823945430083677e+03 -2.66315227193593273e+02 0.00000000000000000e+00,
              -7.61360871291361127e+03 -3.43632551217539628e+02 0.00000000000000000e+00,
              -7.60394404741061771e+03 -4.44574613137691870e+02 0.00000000000000000e+00,
              -7.57495005090163795e+03 -5.22965788884193103e+02 0.00000000000000000e+00,
              -7.52233131649645202e+03 -5.83101485347262496e+02 0.00000000000000000e+00,
              -7.44394014074995084e+03 -6.24981702526900108e+02 0.00000000000000000e+00,
              -7.33762882021702444e+03 -6.50754143868215692e+02 0.00000000000000000e+00,
              -7.19910194800745376e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -7.06057507579788398e+03 -6.50754143868215692e+02 0.00000000000000000e+00,
              -6.95426375526495758e+03 -6.24981702526900108e+02 0.00000000000000000e+00,
              -6.87587257951845640e+03 -5.83101485347262496e+02 0.00000000000000000e+00,
              -6.82432769683582501e+03 -5.22965788884193103e+02 0.00000000000000000e+00,
              -6.79533370032684525e+03 -4.44574613137691870e+02 0.00000000000000000e+00,
              -6.78459518310129715e+03 -3.43632551217539628e+02 0.00000000000000000e+00,
              -6.78996444171407074e+03 -2.66315227193593273e+02 0.00000000000000000e+00,
              -6.80607221755239334e+03 -2.01884123840304596e+02 0.00000000000000000e+00,
              -6.83399236233881857e+03 -1.49265389435118777e+02 0.00000000000000000e+00,
              -6.87587257951845640e+03 -1.05237468810371524e+02 0.00000000000000000e+00,
              -6.95211605181984760e+03 -6.65788067983984320e+01 0.00000000000000000e+00,
              -5.52818866771216744e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -5.75799293633889738e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -5.89974136371613258e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -5.99101876013329183e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -6.06618838071212849e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -6.12525022545264255e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -6.16605659090972586e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -6.19075518052848656e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -6.19827214258637014e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -6.19075518052848656e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -6.16605659090972586e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -6.12525022545264255e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -6.06618838071212849e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.99101876013329183e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.89974136371613258e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.79235619146065164e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.66456783647662905e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.53785333321516100e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.42939430923712553e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.33919076454252172e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.26402114396368415e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.20495929922317009e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -5.16415293376608679e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -5.13945434414732699e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -5.13086353036688706e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -5.13945434414732699e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -5.16415293376608679e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -5.20495929922317009e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -5.26402114396368415e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -5.36388935416128152e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.48738230225508505e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -5.78806078457043259e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -5.97705868774007922e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -6.09947778411132731e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -6.19719829086381560e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -6.27236791144265226e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -6.35720219752448247e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -6.36794071475003057e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.35720219752448247e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -6.27236791144265226e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -6.19719829086381560e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.09947778411132731e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -5.97705868774007922e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -5.83208870519517950e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -5.66456783647662905e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.53033637115727743e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -5.40791727478602934e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -5.30053210253054749e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -5.20925470611338915e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -5.13408508553455249e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -5.07072783390381846e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -5.02240450638885159e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -4.98804125126709823e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -4.96871192026111112e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -4.96119495820322754e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.97193347542877564e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -5.00414902710542083e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -5.05784161323316039e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -5.13301123381199795e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -5.26509499568623869e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -3.87445701497775781e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -4.10426128360448729e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -4.53702352779407738e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -4.54454048985196005e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -4.53702352779407738e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -4.33728710739888083e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -4.13862453872624155e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -3.88412168048075182e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -3.77566265650271544e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.68545911180811072e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.61028949122927497e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -3.55122764648876000e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -3.48572269141291599e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -3.47713187763247788e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -3.48572269141291599e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -3.55122764648876000e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.61028949122927497e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -3.71015770142687234e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -3.83365064952067496e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -4.13432913183602250e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -4.44574613137691813e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -4.70347054479007238e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.71420906201562047e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.70347054479007238e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -4.54346663812940460e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -4.44574613137691813e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -4.32332703500566822e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -4.17835705246076941e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -3.87660471842286779e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -3.75418562205161925e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.64680044979613831e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -3.55552305337897906e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -3.48035343280014240e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -3.41699618116940837e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -3.36867285365444150e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -3.33430959853268814e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -3.31498026752670194e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -3.30746330546881745e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.31820182269436555e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.35041737437101074e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.40410996049875030e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.47927958107758786e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.61136334295182951e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -2.22072536224334908e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -2.45052963087007811e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.81778691998382419e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.88329187505966820e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.89080883711755087e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -2.88329187505966820e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -2.81778691998382419e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.48489288599183237e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -2.23039002774634173e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.12193100376830535e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.03172745907370245e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -1.95655783849486488e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.85668962829726752e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.83199103867850681e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.82340022489806870e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.83199103867850681e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.85668962829726752e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.95655783849486488e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.05642604869246225e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -2.17991899678626669e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.48059747910161332e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -2.79201447864250895e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.04973889205566320e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.06047740928121129e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.04973889205566320e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -3.01752334037901892e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -2.79201447864250895e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -2.52462539972636023e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -2.22287306568845906e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -2.10045396931721007e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -1.99306879706172822e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -1.90179140064456988e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.82662178006573322e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.76326452843499919e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -1.71494120092003322e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -1.68057794579827987e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -1.66124861479229185e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -1.65373165273440827e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.66447016995995637e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.69668572163660065e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.75037830776434203e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.82554792834317777e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.95763169021741942e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -5.66993709508939901e+02 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.96797978135669837e+02 6.48606440423105823e+02 0.00000000000000000e+00,
              -9.38546405512905039e+02 6.21760147359235816e+02 0.00000000000000000e+00,
              -1.02982380193006338e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.20486163270649831e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.22956022232525811e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.23707718438314259e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.22956022232525811e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.20486163270649831e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.16405526724941501e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.02982380193006338e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -9.38546405512905039e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -8.31161233257423191e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.03372878273401511e+02 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.76658375011933458e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.68199351033897074e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.77995806339293267e+02 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.02826185760456610e+02 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.02957975562859247e+02 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.78259385944098540e+02 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.69668572163660428e+02 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.78259385944098540e+02 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.02957975562859247e+02 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.43764341019942549e+02 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.02826185760456610e+02 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.02694395958053974e+02 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.26187344051856599e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.26865826367204136e+02 -8.10758050528882563e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -1.23600333266058715e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.31117295323942471e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.39600723932125402e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.40674575654680211e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.39600723932125402e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -1.36379168764460974e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -1.31117295323942471e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -1.23600333266058715e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.13828282590809977e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -1.01586372953685168e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -8.70893746991951957e+02 8.10758050528882450e+02 0.00000000000000000e+00,
              -7.03372878273401511e+02 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.69141412954049883e+02 8.15053457419101733e+02 0.00000000000000000e+00,
              -4.46722316582801795e+02 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.39337144327319947e+02 7.44179243730484131e+02 0.00000000000000000e+00,
              -2.48059747910160695e+02 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.72890127331324948e+02 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.09532875700590921e+02 5.13301123381199659e+02 0.00000000000000000e+00,
              -6.12095481856240440e+01 4.04842099403163729e+02 0.00000000000000000e+00,
              -2.68462930638697799e+01 2.83496854754470178e+02 0.00000000000000000e+00,
              -7.51696205788357474e+00 1.48191537712563900e+02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.07385172255480938e+01 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.29540689021923754e+01 -3.47927958107758855e+02 0.00000000000000000e+00,
              -9.66466550299328446e+01 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.71816275608769502e+02 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.03900037483011147e+02 -7.14111395498949491e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="2.98889022389700258e+04 9.99910512356453692e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            14 13 15 -1 
            13 12 15 -1 
            12 11 15 -1 
            11 10 15 -1 
            10 9 15 -1 
            9 8 15 -1 
            8 7 15 -1 
            15 7 16 -1 
            7 6 16 -1 
            5 4 6 -1 
            6 4 16 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            21 20 25 -1 
            20 19 25 -1 
            19 18 25 -1 
            25 18 0 -1 
            1 0 2 -1 
            2 0 3 -1 
            3 0 4 -1 
            4 0 16 -1 
            16 0 17 -1 
            0 18 17 -1 
            73 26 74 -1 
            26 55 74 -1 
            41 57 42 -1 
            57 56 42 -1 
            74 55 75 -1 
            75 55 76 -1 
            55 54 76 -1 
            76 54 77 -1 
            77 54 78 -1 
            54 53 78 -1 
            78 53 79 -1 
            53 52 79 -1 
            79 52 80 -1 
            52 51 80 -1 
            80 51 81 -1 
            51 50 81 -1 
            81 50 82 -1 
            82 50 83 -1 
            50 49 83 -1 
            83 49 84 -1 
            49 48 84 -1 
            84 48 85 -1 
            48 47 85 -1 
            85 47 86 -1 
            47 46 86 -1 
            86 46 87 -1 
            42 56 43 -1 
            56 87 43 -1 
            46 45 87 -1 
            45 44 87 -1 
            87 44 43 -1 
            57 41 58 -1 
            73 72 26 -1 
            27 26 72 -1 
            72 71 27 -1 
            27 71 28 -1 
            71 70 28 -1 
            70 69 28 -1 
            41 40 58 -1 
            58 40 59 -1 
            28 69 29 -1 
            69 68 29 -1 
            29 68 30 -1 
            68 67 30 -1 
            30 67 31 -1 
            67 66 31 -1 
            31 66 32 -1 
            66 65 32 -1 
            32 65 33 -1 
            65 64 33 -1 
            33 64 34 -1 
            64 63 34 -1 
            34 63 35 -1 
            63 62 35 -1 
            35 62 36 -1 
            62 61 36 -1 
            36 61 37 -1 
            61 60 37 -1 
            37 60 38 -1 
            60 59 38 -1 
            38 59 39 -1 
            59 40 39 -1 
            135 88 136 -1 
            88 117 136 -1 
            103 119 104 -1 
            119 118 104 -1 
            136 117 137 -1 
            137 117 138 -1 
            117 116 138 -1 
            138 116 139 -1 
            139 116 140 -1 
            116 115 140 -1 
            140 115 141 -1 
            115 114 141 -1 
            141 114 142 -1 
            114 113 142 -1 
            142 113 143 -1 
            113 112 143 -1 
            143 112 144 -1 
            144 112 145 -1 
            112 111 145 -1 
            145 111 146 -1 
            111 110 146 -1 
            146 110 147 -1 
            110 109 147 -1 
            147 109 148 -1 
            109 108 148 -1 
            148 108 149 -1 
            104 118 105 -1 
            118 149 105 -1 
            108 107 149 -1 
            107 106 149 -1 
            149 106 105 -1 
            119 103 120 -1 
            135 134 88 -1 
            89 88 134 -1 
            134 133 89 -1 
            89 133 90 -1 
            133 132 90 -1 
            132 131 90 -1 
            103 102 120 -1 
            120 102 121 -1 
            90 131 91 -1 
            131 130 91 -1 
            91 130 92 -1 
            130 129 92 -1 
            92 129 93 -1 
            129 128 93 -1 
            93 128 94 -1 
            128 127 94 -1 
            94 127 95 -1 
            127 126 95 -1 
            95 126 96 -1 
            126 125 96 -1 
            96 125 97 -1 
            125 124 97 -1 
            97 124 98 -1 
            124 123 98 -1 
            98 123 99 -1 
            123 122 99 -1 
            99 122 100 -1 
            122 121 100 -1 
            100 121 101 -1 
            121 102 101 -1 
            197 150 198 -1 
            150 179 198 -1 
            165 181 166 -1 
            181 180 166 -1 
            198 179 199 -1 
            199 179 200 -1 
            179 178 200 -1 
            200 178 201 -1 
            201 178 202 -1 
            178 177 202 -1 
            202 177 203 -1 
            177 176 203 -1 
            203 176 204 -1 
            176 175 204 -1 
            204 175 205 -1 
            175 174 205 -1 
            205 174 206 -1 
            206 174 207 -1 
            174 173 207 -1 
            207 173 208 -1 
            173 172 208 -1 
            208 172 209 -1 
            172 171 209 -1 
            209 171 210 -1 
            171 170 210 -1 
            210 170 211 -1 
            166 180 167 -1 
            180 211 167 -1 
            170 169 211 -1 
            169 168 211 -1 
            211 168 167 -1 
            181 165 182 -1 
            197 196 150 -1 
            151 150 196 -1 
            196 195 151 -1 
            151 195 152 -1 
            195 194 152 -1 
            194 193 152 -1 
            165 164 182 -1 
            182 164 183 -1 
            152 193 153 -1 
            193 192 153 -1 
            153 192 154 -1 
            192 191 154 -1 
            154 191 155 -1 
            191 190 155 -1 
            155 190 156 -1 
            190 189 156 -1 
            156 189 157 -1 
            189 188 157 -1 
            157 188 158 -1 
            188 187 158 -1 
            158 187 159 -1 
            187 186 159 -1 
            159 186 160 -1 
            186 185 160 -1 
            160 185 161 -1 
            185 184 161 -1 
            161 184 162 -1 
            184 183 162 -1 
            162 183 163 -1 
            183 164 163 -1 
            259 212 260 -1 
            212 241 260 -1 
            227 243 228 -1 
            243 242 228 -1 
            260 241 261 -1 
            261 241 262 -1 
            241 240 262 -1 
            262 240 263 -1 
            263 240 264 -1 
            240 239 264 -1 
            264 239 265 -1 
            239 238 265 -1 
            265 238 266 -1 
            238 237 266 -1 
            266 237 267 -1 
            237 236 267 -1 
            267 236 268 -1 
            268 236 269 -1 
            236 235 269 -1 
            269 235 270 -1 
            235 234 270 -1 
            270 234 271 -1 
            234 233 271 -1 
            271 233 272 -1 
            233 232 272 -1 
            272 232 273 -1 
            228 242 229 -1 
            242 273 229 -1 
            232 231 273 -1 
            231 230 273 -1 
            273 230 229 -1 
            243 227 244 -1 
            259 258 212 -1 
            213 212 258 -1 
            258 257 213 -1 
            213 257 214 -1 
            257 256 214 -1 
            256 255 214 -1 
            227 226 244 -1 
            244 226 245 -1 
            214 255 215 -1 
            255 254 215 -1 
            215 254 216 -1 
            254 253 216 -1 
            216 253 217 -1 
            253 252 217 -1 
            217 252 218 -1 
            252 251 218 -1 
            218 251 219 -1 
            251 250 219 -1 
            219 250 220 -1 
            250 249 220 -1 
            220 249 221 -1 
            249 248 221 -1 
            221 248 222 -1 
            248 247 222 -1 
            222 247 223 -1 
            247 246 223 -1 
            223 246 224 -1 
            246 245 224 -1 
            224 245 225 -1 
            245 226 225 -1 
            321 274 322 -1 
            274 303 322 -1 
            289 305 290 -1 
            305 304 290 -1 
            322 303 323 -1 
            323 303 324 -1 
            303 302 324 -1 
            324 302 325 -1 
            325 302 326 -1 
            302 301 326 -1 
            326 301 327 -1 
            301 300 327 -1 
            327 300 328 -1 
            300 299 328 -1 
            328 299 329 -1 
            299 298 329 -1 
            329 298 330 -1 
            330 298 331 -1 
            298 297 331 -1 
            331 297 332 -1 
            297 296 332 -1 
            332 296 333 -1 
            296 295 333 -1 
            333 295 334 -1 
            295 294 334 -1 
            334 294 335 -1 
            290 304 291 -1 
            304 335 291 -1 
            294 293 335 -1 
            293 292 335 -1 
            335 292 291 -1 
            305 289 306 -1 
            321 320 274 -1 
            275 274 320 -1 
            320 319 275 -1 
            275 319 276 -1 
            319 318 276 -1 
            318 317 276 -1 
            289 288 306 -1 
            306 288 307 -1 
            276 317 277 -1 
            317 316 277 -1 
            277 316 278 -1 
            316 315 278 -1 
            278 315 279 -1 
            315 314 279 -1 
            279 314 280 -1 
            314 313 280 -1 
            280 313 281 -1 
            313 312 281 -1 
            281 312 282 -1 
            312 311 282 -1 
            282 311 283 -1 
            311 310 283 -1 
            283 310 284 -1 
            310 309 284 -1 
            284 309 285 -1 
            309 308 285 -1 
            285 308 286 -1 
            308 307 286 -1 
            286 307 287 -1 
            307 288 287 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0017"
              point="
              -8.40825898760417112e+03 7.98945681580779592e+02 0.00000000000000000e+00,
              -8.38141269454030044e+03 8.18275012586766024e+02 0.00000000000000000e+00,
              -8.34704943941854617e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -8.31376003601934644e+03 8.18275012586766024e+02 0.00000000000000000e+00,
              -8.28583989123292122e+03 8.01093385025889120e+02 0.00000000000000000e+00,
              -8.26758441194949046e+03 7.75320943684573649e+02 0.00000000000000000e+00,
              -8.26114130161416142e+03 7.45253095453038895e+02 0.00000000000000000e+00,
              -8.26114130161416142e+03 -7.45253095453039009e+02 0.00000000000000000e+00,
              -8.26758441194949046e+03 -7.76394795407128527e+02 0.00000000000000000e+00,
              -8.28583989123292122e+03 -8.02167236748443997e+02 0.00000000000000000e+00,
              -8.31376003601934644e+03 -8.19348864309321016e+02 0.00000000000000000e+00,
              -8.34597558769599163e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.37926499109518954e+03 -8.19348864309321016e+02 0.00000000000000000e+00,
              -8.40611128415906023e+03 -8.02167236748443997e+02 0.00000000000000000e+00,
              -8.42544061516504735e+03 -7.76394795407128527e+02 0.00000000000000000e+00,
              -8.43080987377782185e+03 -7.45253095453039009e+02 0.00000000000000000e+00,
              -8.43080987377782185e+03 5.41221268167624885e+02 0.00000000000000000e+00,
              -8.75833464915703917e+03 2.08327234175633293e+02 0.00000000000000000e+00,
              -8.78518094222090986e+03 1.88997903169646634e+02 0.00000000000000000e+00,
              -8.81739649389755323e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -8.85068589729675296e+03 1.88997903169646634e+02 0.00000000000000000e+00,
              -8.87860604208317818e+03 2.06179530730523766e+02 0.00000000000000000e+00,
              -8.89793537308916348e+03 2.30878120349284245e+02 0.00000000000000000e+00,
              -8.90437848342449252e+03 2.62019820303373990e+02 0.00000000000000000e+00,
              -8.90008307653427437e+03 2.92087668534908516e+02 0.00000000000000000e+00,
              -8.88182759725084179e+03 3.18933961598778978e+02 0.00000000000000000e+00,
              -7.18192032044657572e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.41172458907330565e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -7.55347301645053994e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -7.64475041286769920e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -7.71992003344653585e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -7.77898187818705082e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -7.81978824364413413e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -7.84448683326289392e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -7.85200379532077750e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -7.84448683326289392e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -7.81978824364413413e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -7.77898187818705082e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -7.71992003344653585e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -7.64475041286769920e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -7.55347301645053994e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -7.44608784419505901e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.31829948921103642e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -7.19158498594956927e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.08312596197153289e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -6.99292241727692908e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -6.91775279669809242e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -6.85869095195757745e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -6.81788458650049506e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -6.79318599688173344e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -6.78459518310129533e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -6.79318599688173344e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -6.81788458650049506e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -6.85869095195757745e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -6.91775279669809242e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -7.01762100689568979e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -7.14111395498949241e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -7.44179243730483995e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -7.63079034047448658e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -7.75320943684573558e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -7.85092994359822296e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -7.92609956417705962e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -7.97871829858224555e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -8.01093385025888983e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -8.02167236748443793e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -8.01093385025888983e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -7.97871829858224555e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -7.92609956417705962e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -7.85092994359822296e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -7.75320943684573558e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -7.63079034047448658e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -7.48582035792958777e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -7.31829948921103642e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -7.18406802389168570e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -7.06164892752043670e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -6.95426375526495576e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -6.86298635884779651e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -6.78781673826895985e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.72445948663822583e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -6.67613615912325986e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -6.64177290400150559e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -6.62244357299551939e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -6.61492661093763490e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.62566512816318391e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -6.65788067983982819e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -6.71157326596756866e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -6.78674288654640532e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -6.91882664842064696e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -5.52818866771216653e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -5.75799293633889647e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -5.89974136371613167e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -5.99101876013329002e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -6.06618838071212667e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -6.12525022545264164e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -6.16605659090972404e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -6.19075518052848565e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -6.19827214258636923e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -6.19075518052848565e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -6.16605659090972404e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -6.12525022545264164e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -6.06618838071212667e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.99101876013329002e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.89974136371613167e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.79235619146065073e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.66456783647662814e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.53785333321516009e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -5.42939430923712462e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -5.33919076454251990e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -5.26402114396368324e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -5.20495929922316827e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -5.16415293376608588e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -5.13945434414732517e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -5.13086353036688706e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -5.13945434414732517e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -5.16415293376608588e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -5.20495929922316827e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -5.26402114396368324e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -5.36388935416128061e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.48738230225508414e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -5.78806078457043077e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -5.97705868774007831e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -6.09947778411132640e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -6.19719829086381469e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -6.27236791144265135e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -6.35720219752448065e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -6.36794071475002966e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -6.35720219752448065e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -6.32498664584783728e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -6.27236791144265135e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -6.19719829086381469e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -6.09947778411132640e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -5.97705868774007831e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -5.83208870519517859e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -5.66456783647662814e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.53033637115727652e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -5.40791727478602843e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -5.30053210253054749e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -5.20925470611338824e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -5.13408508553455158e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -5.07072783390381665e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -5.02240450638885068e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -4.98804125126709641e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -4.96871192026111021e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -4.96119495820322663e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.97193347542877382e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -5.00414902710541901e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -5.05784161323315948e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -5.13301123381199614e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -5.26509499568623869e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -3.87445701497775735e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -4.10426128360448729e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -4.33728710739888174e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -4.53702352779407647e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -4.54454048985196005e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -4.53702352779407647e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -4.51232493817531576e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -4.47151857271823337e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -4.41245672797771840e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -4.33728710739888174e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -4.24600971098172249e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -4.13862453872624155e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -3.88412168048075091e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -3.77566265650271544e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.68545911180811072e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -3.48572269141291599e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -3.47713187763247788e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -3.48572269141291599e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -3.51042128103167670e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -3.55122764648875909e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.61028949122927406e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -3.71015770142687143e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -3.83365064952067496e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -4.13432913183602250e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -4.32332703500566913e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -4.44574613137691722e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -4.54346663812940551e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -4.70347054479007238e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.71420906201562047e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.70347054479007238e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -4.67125499311342810e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -4.61863625870824217e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -4.54346663812940551e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -4.44574613137691722e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -4.32332703500566913e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -4.17835705246076941e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -4.01083618374221896e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -3.87660471842286734e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -3.75418562205161925e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.64680044979613831e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -3.55552305337897906e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -3.48035343280014149e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -3.41699618116940837e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -3.36867285365444150e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -3.33430959853268814e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -3.31498026752670103e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -3.30746330546881745e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.31820182269436555e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.35041737437100983e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.40410996049875030e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.47927958107758786e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.61136334295182951e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -2.22072536224334908e+03 6.58271105926099153e+02 0.00000000000000000e+00,
              -2.45052963087007811e+03 6.48606440423105823e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 6.21760147359235816e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.81778691998382328e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.88329187505966729e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.89080883711755087e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -2.88329187505966729e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -2.85859328544090658e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -2.81778691998382328e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.75872507524330922e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.68355545466447256e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -2.59227805824731331e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.48489288599183237e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 -6.58271105926099381e+02 0.00000000000000000e+00,
              -2.23039002774634264e+03 -6.49680292145660815e+02 0.00000000000000000e+00,
              -2.12193100376830625e+03 -6.21760147359235702e+02 0.00000000000000000e+00,
              -2.03172745907370245e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -1.95655783849486579e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.85668962829726843e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.83199103867850772e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.82340022489806870e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.83199103867850772e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.85668962829726843e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.89749599375435082e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.95655783849486579e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -2.05642604869246316e+03 5.83101485347262496e+02 0.00000000000000000e+00,
              -2.17991899678626578e+03 -8.24718122922095063e+02 0.00000000000000000e+00,
              -2.48059747910161241e+03 -8.10758050528882563e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -3.01752334037901801e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -3.04973889205566229e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -3.06047740928121129e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.04973889205566229e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -3.01752334037901801e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -2.96490460597383299e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -2.88973498539499633e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -2.79201447864250804e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -2.66959538227125995e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -2.52462539972636023e+03 8.10758050528882450e+02 0.00000000000000000e+00,
              -2.35710453100780978e+03 8.24718122922095063e+02 0.00000000000000000e+00,
              -2.22287306568845815e+03 8.15053457419101733e+02 0.00000000000000000e+00,
              -2.10045396931721007e+03 7.88207164355231498e+02 0.00000000000000000e+00,
              -1.99306879706172913e+03 7.44179243730484131e+02 0.00000000000000000e+00,
              -1.90179140064457079e+03 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.82662178006573322e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.76326452843500010e+03 5.13301123381199659e+02 0.00000000000000000e+00,
              -1.71494120092003232e+03 4.04842099403163729e+02 0.00000000000000000e+00,
              -1.68057794579827896e+03 2.83496854754470178e+02 0.00000000000000000e+00,
              -1.66124861479229276e+03 1.48191537712563900e+02 0.00000000000000000e+00,
              -1.65373165273440827e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.66447016995995546e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.69668572163660156e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.75037830776434112e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.82554792834317868e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.95763169021742033e+03 -7.14111395498949491e+02 0.00000000000000000e+00,
              -5.66993709508938991e+02 6.58271105926099153e+02 0.00000000000000000e+00,
              -7.96797978135668927e+02 6.48606440423105823e+02 0.00000000000000000e+00,
              -9.38546405512904130e+02 6.21760147359235816e+02 0.00000000000000000e+00,
              -1.02982380193006247e+03 5.74510671566823930e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 5.05784161323316084e+02 0.00000000000000000e+00,
              -1.16405526724941410e+03 4.15580616628711823e+02 0.00000000000000000e+00,
              -1.20486163270649740e+03 3.01752334037902074e+02 0.00000000000000000e+00,
              -1.22956022232525720e+03 1.63225461828331277e+02 0.00000000000000000e+00,
              -1.23707718438314168e+03 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.22956022232525720e+03 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.20486163270649740e+03 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.16405526724941410e+03 -4.16654468351266758e+02 0.00000000000000000e+00,
              -1.10499342250890004e+03 -5.06858013045870962e+02 0.00000000000000000e+00,
              -1.02982380193006247e+03 -5.75584523289378808e+02 0.00000000000000000e+00,
              -9.38546405512904130e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -8.31161233257422282e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -7.03372878273399692e+02 -6.58271105926099381e+02 0.00000000000000000e+00,
              -5.76658375011933458e+02 -6.49680292145660815e+02 0.00000000000000000e+00,
              -4.68199351033896164e+02 -6.21760147359235702e+02 0.00000000000000000e+00,
              -3.77995806339293267e+02 -5.75584523289378808e+02 0.00000000000000000e+00,
              -3.02826185760455701e+02 -5.06858013045870962e+02 0.00000000000000000e+00,
              -2.43764341019941639e+02 -4.16654468351266758e+02 0.00000000000000000e+00,
              -2.02957975562858337e+02 -3.02826185760456838e+02 0.00000000000000000e+00,
              -1.78259385944098540e+02 -1.64299313550886154e+02 0.00000000000000000e+00,
              -1.69668572163658609e+02 -1.07385172255476391e+00 0.00000000000000000e+00,
              -1.78259385944098540e+02 1.63225461828331277e+02 0.00000000000000000e+00,
              -2.02957975562858337e+02 3.01752334037902074e+02 0.00000000000000000e+00,
              -2.43764341019941639e+02 4.15580616628711823e+02 0.00000000000000000e+00,
              -3.02826185760455701e+02 5.05784161323316084e+02 0.00000000000000000e+00,
              -4.02694395958053065e+02 5.83101485347262496e+02 0.00000000000000000e+00,
              -5.26187344051855689e+02 -8.24718122922095063e+02 0.00000000000000000e+00,
              -8.26865826367203226e+02 -8.10758050528882563e+02 0.00000000000000000e+00,
              -1.01586372953685077e+03 -7.68877833349244838e+02 0.00000000000000000e+00,
              -1.13828282590809886e+03 -7.00151323105736992e+02 0.00000000000000000e+00,
              -1.23600333266058624e+03 -6.05652371520913562e+02 0.00000000000000000e+00,
              -1.31117295323942380e+03 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.36379168764460883e+03 -3.47927958107758855e+02 0.00000000000000000e+00,
              -1.39600723932125311e+03 -1.84702496279427578e+02 0.00000000000000000e+00,
              -1.40674575654680120e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.39600723932125311e+03 1.83628644556872700e+02 0.00000000000000000e+00,
              -1.36379168764460883e+03 3.46854106385203977e+02 0.00000000000000000e+00,
              -1.31117295323942380e+03 4.87528682039884188e+02 0.00000000000000000e+00,
              -1.23600333266058624e+03 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.13828282590809886e+03 7.00151323105736765e+02 0.00000000000000000e+00,
              -1.01586372953685077e+03 7.68877833349244838e+02 0.00000000000000000e+00,
              -8.70893746991951048e+02 8.10758050528882450e+02 0.00000000000000000e+00,
              -7.03372878273399692e+02 8.24718122922095063e+02 0.00000000000000000e+00,
              -5.69141412954048064e+02 8.15053457419101733e+02 0.00000000000000000e+00,
              -4.46722316582799976e+02 7.88207164355231498e+02 0.00000000000000000e+00,
              -3.39337144327319038e+02 7.44179243730484131e+02 0.00000000000000000e+00,
              -2.48059747910159786e+02 6.82969695544859860e+02 0.00000000000000000e+00,
              -1.72890127331324038e+02 6.05652371520913448e+02 0.00000000000000000e+00,
              -1.09532875700590012e+02 5.13301123381199659e+02 0.00000000000000000e+00,
              -6.12095481856231345e+01 4.04842099403163729e+02 0.00000000000000000e+00,
              -2.68462930638688704e+01 2.83496854754470178e+02 0.00000000000000000e+00,
              -7.51696205788357474e+00 1.48191537712563900e+02 0.00000000000000000e+00,
              1.81898940354585648e-12 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.07385172255471844e+01 -1.84702496279427578e+02 0.00000000000000000e+00,
              -4.29540689021923754e+01 -3.47927958107758855e+02 0.00000000000000000e+00,
              -9.66466550299319351e+01 -4.88602533762439123e+02 0.00000000000000000e+00,
              -1.71816275608769502e+02 -6.05652371520913562e+02 0.00000000000000000e+00,
              -3.03900037483010237e+02 -7.14111395498949491e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="3.12084512356453779e+04 2.64743512356453757e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="9.49999988079071045e-01"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            0 1 7 6 -1 
            1 2 8 7 -1 
            2 3 9 8 -1 
            3 4 10 9 -1 
            4 5 11 10 -1 
            6 7 13 12 -1 
            7 8 14 13 -1 
            8 9 15 14 -1 
            9 10 16 15 -1 
            10 11 17 16 -1 
            12 13 19 18 -1 
            13 14 20 19 -1 
            14 15 21 20 -1 
            15 16 22 21 -1 
            16 17 23 22 -1 
            18 19 25 24 -1 
            19 20 26 25 -1 
            20 21 27 26 -1 
            21 22 28 27 -1 
            22 23 29 28 -1 
            24 25 31 30 -1 
            25 26 32 31 -1 
            26 27 33 32 -1 
            27 28 34 33 -1 
            28 29 35 34 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0020"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              8.78259999999999854e+03 0.00000000000000000e+00 0.00000000000000000e+00,
              2.87825999999999985e+04 0.00000000000000000e+00 0.00000000000000000e+00,
              4.87825999999999913e+04 0.00000000000000000e+00 0.00000000000000000e+00,
              6.87826000000000058e+04 0.00000000000000000e+00 0.00000000000000000e+00,
              7.54276000000000058e+04 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 1.35167000000000007e+04 0.00000000000000000e+00,
              8.78259999999999854e+03 1.35167000000000007e+04 0.00000000000000000e+00,
              2.87825999999999985e+04 1.35167000000000007e+04 0.00000000000000000e+00,
              4.87825999999999913e+04 1.35167000000000007e+04 0.00000000000000000e+00,
              6.87826000000000058e+04 1.35167000000000007e+04 0.00000000000000000e+00,
              7.54276000000000058e+04 1.35167000000000007e+04 0.00000000000000000e+00,
              0.00000000000000000e+00 3.35166999999999971e+04 0.00000000000000000e+00,
              8.78259999999999854e+03 3.35166999999999971e+04 0.00000000000000000e+00,
              2.87825999999999985e+04 3.35166999999999971e+04 0.00000000000000000e+00,
              4.87825999999999913e+04 3.35166999999999971e+04 0.00000000000000000e+00,
              6.87826000000000058e+04 3.35166999999999971e+04 0.00000000000000000e+00,
              7.54276000000000058e+04 3.35166999999999971e+04 0.00000000000000000e+00,
              0.00000000000000000e+00 5.35166999999999971e+04 0.00000000000000000e+00,
              8.78259999999999854e+03 5.35166999999999971e+04 0.00000000000000000e+00,
              2.87825999999999985e+04 5.35166999999999971e+04 0.00000000000000000e+00,
              4.87825999999999913e+04 5.35166999999999971e+04 0.00000000000000000e+00,
              6.87826000000000058e+04 5.35166999999999971e+04 0.00000000000000000e+00,
              7.54276000000000058e+04 5.35166999999999971e+04 0.00000000000000000e+00,
              0.00000000000000000e+00 7.35166999999999971e+04 0.00000000000000000e+00,
              8.78259999999999854e+03 7.35166999999999971e+04 0.00000000000000000e+00,
              2.87825999999999985e+04 7.35166999999999971e+04 0.00000000000000000e+00,
              4.87825999999999913e+04 7.35166999999999971e+04 0.00000000000000000e+00,
              6.87826000000000058e+04 7.35166999999999971e+04 0.00000000000000000e+00,
              7.54276000000000058e+04 7.35166999999999971e+04 0.00000000000000000e+00,
              0.00000000000000000e+00 7.83536999999999971e+04 0.00000000000000000e+00,
              8.78259999999999854e+03 7.83536999999999971e+04 0.00000000000000000e+00,
              2.87825999999999985e+04 7.83536999999999971e+04 0.00000000000000000e+00,
              4.87825999999999913e+04 7.83536999999999971e+04 0.00000000000000000e+00,
              6.87826000000000058e+04 7.83536999999999971e+04 0.00000000000000000e+00,
              7.54276000000000058e+04 7.83536999999999971e+04 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="3.12084512356453779e+04 2.64743512356453757e+04 -3.44179487643546236e+04"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            0 1 2 3 -1 
            4 5 6 7 -1 
            8 9 10 11 -1 
            12 13 14 15 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0021"
              point="
              8.67074044556720582e+03 -6.71157326596757002e+02 0.00000000000000000e+00,
              8.89445955443279126e+03 -6.71157326596757002e+02 0.00000000000000000e+00,
              8.89445955443279126e+03 6.71157326596757002e+02 0.00000000000000000e+00,
              8.67074044556720582e+03 6.71157326596757002e+02 0.00000000000000000e+00,
              2.86707404455672040e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              2.88944595544327931e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              2.88944595544327931e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              2.86707404455672040e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              4.86707404455672004e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              4.88944595544327822e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              4.88944595544327822e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              4.86707404455672004e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              6.86707404455672076e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              6.88944595544328040e+04 -6.71157326596757002e+02 0.00000000000000000e+00,
              6.88944595544328040e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              6.86707404455672076e+04 6.71157326596757002e+02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
    </Transform>
  </Scene>
</template>

<script>
export default {
  name: "TriangleXdom",
  mounted: function() {
    this.$nextTick(function() {
      this.$emit("loaded", true);
    });
  },
};
</script>
